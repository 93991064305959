<template>
  <div v-loading="loading" class="special-center">
    <el-row :gutter="20">
      <el-col :span="24" :xs="24">
        <el-card>
          <div slot="header" class="font-xxs">
            课程信息
          </div>
          <!-- <el-page-header content="课程详情" class="page-header" @back="returnPage()" /> -->
          <!-- 课程信息 -->
          <el-form v-show="variable.active == 1" class="m-t-10" :inline="true">
            <el-row :gutter="20">
              <el-col :span="6" :offset="0">
                <h3>课程信息</h3>
              </el-col>
              <el-col :span="18" :offset="0" class="text-right">
                <el-button type="primary" icon="el-icon-edit" @click="modify(0)">修改</el-button>
                <el-button type="info" icon="el-icon-back" @click="returnPage()">返回</el-button>
              </el-col>
            </el-row>
            <el-descriptions class="margin-top" :column="3" border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-s-order" />
                  课程名称
                </template>
                {{ form.name }}
              </el-descriptions-item>
              <el-descriptions-item label="">
                <template slot="label">
                  <i class="el-icon-s-flag" />
                  课程类型
                </template>
                {{ course_type[form.type] }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-s-custom" />
                  教练
                </template>
                <template>
                  <span v-if="form.store_user">{{ form.store_user.real_name }}</span>
                  <span v-else style="color: red;">教练待更新</span>
                </template>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-s-home" />
                  教室
                </template>
                <template>
                  {{ form.classroom ? form.classroom.name : '未知教室' }}
                </template>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user" />
                  容纳人数
                </template>
                {{ form.galleryful }}人
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-star-on" />
                  课程难度
                </template>
                <el-rate :value="form.difficulty" disabled text-color="#ff9900" :title="form.difficulty" />
              </el-descriptions-item>
              <el-descriptions-item label="">
                <template slot="label">
                  <i class="el-icon-time" />
                  课程时长
                </template>
                {{ form.duration }} 分钟
              </el-descriptions-item>
              <el-descriptions-item label="">
                <template slot="label">
                  <i class="el-icon-paperclip" />
                  课程标签
                </template>
                {{ form.label }}
              </el-descriptions-item>

              <el-descriptions-item label="">
                <template slot="label">
                  <i class="el-icon-paperclip" />
                  允许付费约课
                </template>
                {{ form.is_pay == 1 ? '已开启' : '未开启' }}
              </el-descriptions-item>
              <el-descriptions-item v-if="form.is_pay == 1" label="">
                <template slot="label">
                  <i class="el-icon-paperclip" />
                  优惠价(元/节)
                </template>
                {{ form.price }}
              </el-descriptions-item>
              <el-descriptions-item v-if="form.is_pay == 1" label="">
                <template slot="label">
                  <i class="el-icon-paperclip" />
                  原价(元/节)
                </template>
                {{ form.original_price }}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-top" :column="1" border direction="vertical">
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-edit" />
                  课程简介
                </template>
                {{ form.abstract ? form.abstract : '无' }}
              </el-descriptions-item>
            </el-descriptions>
          </el-form>
          <!-- 支持卡类型 -->
          <el-form v-show="variable.active == 1" :model="form">
            <el-row :gutter="20">
              <el-col :span="6" :offset="0">
                <h3>支持卡类型</h3>
              </el-col>
              <el-col :span="18" :offset="0" class="text-right m-t-10">
                <el-button type="primary" icon="el-icon-edit" @click="modify(1)">修改</el-button>
              </el-col>
            </el-row>
            <el-descriptions class="margin-top" :column="3" direction="vertical" border>
              <el-descriptions-item label="期限卡">
                <span v-for="item in selectedCard[0]" :key="item.id">
                  <i class="el-icon-bank-card" />
                  {{ item.name }}
                  <br>
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="次数卡">
                <span v-for="item in selectedCard[1]" :key="item.id">
                  <i class="el-icon-bank-card" />
                  {{ item.name }}(每节课收费 {{ item.charge }} 次/人)
                  <br>
                </span>
              </el-descriptions-item>
              <el-descriptions-item label="储值卡">
                <span v-for="item in selectedCard[2]" :key="item.id">
                  <i class="el-icon-bank-card" />
                  {{ item.name }}(每节课收费￥ {{ item.charge }} 元/人)
                  <br>
                </span>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>
          <!-- 课程信息编辑 -->
          <el-form v-show="variable.type === 0" ref="ruleForm" :rules="rules" label-position="left" :model="form" label-width="120px">
            <h3>课程信息</h3>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item class="line-clear" label="课程图片" prop="image">
                  <el-upload
                    v-loading="upLoading"
                    class="images-uploader"
                    :action="storeUpload"
                    :show-file-list="false"
                    :on-success="upSuccess"
                    :headers="utils.upload_headers()"
                    :on-progress="upProgress"
                    accept=".jpg,.jpeg,.png"
                  >
                    <img v-if="form.image" :src="form.image" class="images" title="点击上传图片">
                    <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item class="line-clear" label="课程名称" prop="name">
                  <el-input v-model="form.name" class="w-660" placeholder="请输入课程名称" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="line-clear" label="课程类型" prop="type">
                  <el-radio v-model="form.type" :label="0" disabled>团课</el-radio>
                  <el-radio v-model="form.type" :label="2" disabled>精品课</el-radio>
                  <el-radio v-model="form.type" :label="1" disabled>私教课</el-radio>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="line-clear" label="课程难度" prop="type">
                  <el-rate v-model="form.difficulty" class="p-t-4 curriculum_rate" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- 教练 -->
                <el-form-item label="教练" prop="store_user_id">
                  <Select ref="coachRef" :type="4" :width="250" :coach-type="coachType" @changeId="handleCoachID" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="line-clear" label="教室" prop="type">
                  <el-select v-model="form.classroom_id" class="w-250" placeholder="请选择教室">
                    <el-option v-for="(item, index) in classroom" :key="index" :label="item.name" :value="item.id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="line-clear" label="课程时长" prop="duration">
                  <el-input v-model="form.duration" class="w-250" type="number" placeholder="请输入课程时长">
                    <template slot="append">分钟</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item v-if="form.type != 1" class="line-clear" label="容纳人数" prop="galleryful">
                  <el-input v-model="form.galleryful" class="w-250" type="number" placeholder="请输入容纳人数">
                    <template slot="append">人</template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item class="line-clear" label="课程标签">
                  <div>
                    <div v-if="!utils.empty(labelList)">
                      <el-tag v-for="(item, index) in labelList" :key="index" class="m-2" type="info">
                        {{ item ? item.name : '' }}
                      </el-tag>
                    </div>
                    <div><el-button type="primary" size="mini" @click="selectLabel">选择标签</el-button></div>
                  </div>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="允许付费约课" prop="is_pay">
                  <el-switch v-model="form.is_pay" />
                </el-form-item>
              </el-col>
              <el-col v-if="form.is_pay == true" :span="12">
                <el-form-item label="优惠价(元/次)" prop="price">
                  <el-input v-model="form.price" type="number" oninput="if(value<0)value=0" placeholder="请输入优惠价" :disabled="form.is_pay == 1 ? false : true">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col v-if="form.is_pay == true" :span="12">
                <el-form-item label="原价(元/次)" prop="original_price">
                  <el-input
                    v-model="form.original_price"
                    type="number"
                    oninput="if(value<0)value=0"
                    placeholder="请输入原价"
                    :disabled="form.is_pay == 1 ? false : true"
                  >
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item class="line-clear" label="简介">
                  <el-input v-model="form.abstract" class="w-660" type="textarea" placeholder="请输入简介" rows="3" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- 支持卡类型编辑 -->
          <el-form v-show="variable.type == 1" :model="form">
            <h3>支持卡类型</h3>
            <el-collapse v-model="activeNames">
              <el-collapse-item title="期限卡" name="1">
                <el-form-item>
                  <div class="tal">
                    <checkboxInput
                      :type="0"
                      :parent-selected.sync="selectedCard[0]"
                      :list.sync="supportCard[0]"
                      :is-course="true"
                    />
                  </div>
                </el-form-item>
              </el-collapse-item>
            </el-collapse>
            <el-collapse v-model="activeNames">
              <el-collapse-item title="次数卡" name="2">
                <el-form-item>
                  <div class="tal">
                    <checkboxInput
                      :type="1"
                      :parent-selected.sync="selectedCard[1]"
                      :list.sync="supportCard[1]"
                      :is-course="true"
                    />
                  </div>
                </el-form-item>
              </el-collapse-item>
            </el-collapse>
            <el-collapse v-model="activeNames">
              <el-collapse-item title="储值卡" name="3">
                <el-form-item>
                  <div class="tal">
                    <checkboxInput
                      :type="2"
                      :parent-selected.sync="selectedCard[2]"
                      :list.sync="supportCard[2]"
                      :is-course="true"
                    />
                  </div>
                </el-form-item>
              </el-collapse-item>
            </el-collapse>
          </el-form>
          <div v-show="variable.active == 2" class="footer">
            <el-button type="primary" @click="submit">修改</el-button>
            <el-button @click="returnClick">返回</el-button>
          </div>

          <!-- 课程标签选择 -->
          <courseLabelView ref="courseLabelView" v-model="labelShow" :c-list="labelList" @setLabel="setLabel" />
        </el-card>
      </el-col>
      <el-col v-if="variable.active == 1" class="m-t-20" :span="24" :xs="24">
        <album :id="id" :type="type" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import courseLabelView from './label/index'
import { getVipCard } from '@/api/store/venues.js'
import { getCourseDetails, editCourse } from '@/api/store/course.js'
import { getClassroom } from '@/api/store/venues.js'
import checkboxInput from '@/components/All/checkboxInput'
import album from '@/views/profile/components/album.vue'
import Select from '@/components/elSelect/Select'

export default {
  components: { checkboxInput, courseLabelView, Select, album },
  data() {
    return {
      labelShow: false,
      labelList: [],
      loading: true,
      form: {},
      variable: { active: 1 },
      // 教室列表
      classroom: [],
      // 支持卡列表
      supportCard: {
        0: [],
        1: [],
        2: []
      },
      // 支持卡选中
      selectedCard: {
        0: [],
        1: [],
        2: []
      },
      rules: {
        name: [{ required: true, message: '请输入课程名称', trigger: ['blur', 'change'] }],
        type: [{ required: true }],
        store_user_id: [{ required: true, message: '请选择教练', trigger: ['blur', 'change'] }],
        duration: [{ required: true, message: '请输入课程时长', trigger: ['blur', 'change'] }],
        galleryful: [{ required: true, message: '请输入容纳人数', trigger: ['blur', 'change'] }]
      },
      pageTitle: '编辑课程',
      defaultCheckLabel: '',
      coachType: 0,
      activeNames: ['1', '2', '3'],
      type: 4,
      id: ''
    }
  },
  created() {
    this.id = this.$route.params.id
  },
  async mounted() {
    this.utils.setPageTitle(this.$route, this.pageTitle, 1)
    this.utils.setTagsViewTitle(this.$route, this.pageTitle)
    // 获取教室列表
    await getClassroom()
      .then(res => {
        this.classroom = res.data.list
      })
      .catch(() => {})
    // 获取支持卡列表
    await getVipCard({ limit: 999 })
      .then(res => {
        const { data } = res
        var limit = []
        var number = []
        var stored = []
        for (var i in data.list) {
          const item = data.list[i]
          if (item.type === 0) {
            limit.push(item)
          } else if (item.type === 1) {
            if (item.charge === undefined) item.charge = 1
            number.push(item)
          } else if (item.type === 2) {
            stored.push(item)
          }
        }
        this.supportCard[0] = limit
        this.supportCard[1] = number
        this.supportCard[2] = stored
      })
      .catch(() => {})
      // 获取详情
    await this.getList()
    console.log(this.form.card)
    if (this.form.card) {
      this.form.card.forEach((val, index) => {
        this.supportCard[val.type].forEach(c_val => {
          if (c_val.id === val.membership_card_id) {
            c_val['charge'] = val['charge'] ? val['charge'] : 0
            this.selectedCard[val.type].push(c_val)
          }
        })
      })
    }
    this.loading = false
  },
  methods: {
    async getList() {
      // getCourseDetails(this.$route.params.id).then(res => {
      //   if (res.data.length === 0) {
      //     this.$message.error('该课程不存在或已删除')
      //     this.returnPage()
      //     return false
      //   }
      //   if (res.data.is_pay === 1) {
      //     res.data.is_pay = true
      //   } else {
      //     res.data.is_pay = false
      //   }
      //   this.form = res.data
      //   this.$refs.courseLabelView.setDefault(this.form.label)
      // })
      const res = await getCourseDetails(this.$route.params.id)
      if (res.data.length === 0) {
        this.$message.error('该课程不存在或已删除')
        this.returnPage()
        return false
      }
      if (res.data.is_pay === 1) {
        res.data.is_pay = true
      } else {
        res.data.is_pay = false
      }
      this.form = res.data
      this.$refs.courseLabelView.setDefault(this.form.label)
    },
    setLabel(row) {
      this.labelList = row
      const data = []
      row.map((item, index) => {
        data.push(item.name)
      })
      this.form.label = data.join(',')
    },
    selectLabel() {
      this.labelShow = true
    },
    // 提交
    submit() {
      let type = true
      const data = JSON.parse(JSON.stringify(this.form))
      this.$refs.ruleForm.validate(valid => {
        type = valid
      })
      data['venuesCard'] = []
      const selectedCard = JSON.parse(JSON.stringify(this.selectedCard))
      for (const i in selectedCard) {
        selectedCard[i].forEach(val => {
          var new_arr = {}
          // 严格键名排序
          new_arr.membership_card_id = val['id']
          new_arr.type = val['type']
          new_arr.charge = val['charge'] ? val['charge'] : 0
          data['venuesCard'].push(new_arr)
        })
      }
      data['venuesCard'] = JSON.stringify(data.venuesCard)
      if (data.is_pay === true) {
        data.is_pay = 1
      } else {
        data.is_pay = 0
        data.original_price = 0
        data.price = 0
      }
      if (type) {
        editCourse(this.$route.params.id, data)
          .then(res => {
            this.$message.success('修改成功')
            this.returnClick()
            this.getList()
          })
          .catch(() => {})
      }
    },
    returnClick() {
      this.variable.active--
      this.variable.type = -1
    },
    // 修改
    modify(type) {
      if (this.form.type === 2) {
        this.coachType = 0
      } else {
        this.coachType = this.form.type
      }
      if (type === 0) this.$refs.coachRef.refreshGetList(this.form.store_user ? this.form.store_user.real_name : '')
      if (!this.form.store_user) this.form.store_user_id = null
      this.variable.type = type
      this.variable.active++
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.form.image = response.data.path
    },
    upProgress() {
      this.upLoading = true
    },
    handleCoachID(val) {
      this.form.store_user_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409eff;
}
.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

::v-deep .el-collapse-item__content {
  padding-bottom: 0px
}
.curriculum_rate {
  ::v-deep .el-rate__icon {
    font-size: 24px;
  }
}
</style>
