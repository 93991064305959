<template>
  <div class="app-container">
    <!-- 搜索 -->
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="left">
        <el-form-item label="请假时间：">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            align="right"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <!-- 选择会员卡 -->
        <el-form-item label="操作人：">
          <el-select v-model="listQueryParams.store_user_id" placeholder="请选择操作人" clearable>
            <el-option v-for="(item, index) in store_user_list" :key="index" :label="item.real_name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </el-form>
    </div>
    <!-- 会员搜索 -->
    <div class="filter-item">
      <el-input
        v-model="listQueryParams.q"
        placeholder="请输入会员名/卡号"
        style="width: 220px;"
        @keyup.enter.native="handleFilter"
      >
        <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
      </el-input>
    </div>
    <!-- 请假列表 -->
    <div class="tableColumn">
      <el-table v-loading="loading" :data="tableData.list" border style="width: 100%" :stripe="true">
        <el-table-column label="请假人" width="200" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.user_membership_card.user" class="photo">
              <el-avatar shape="square" :size="40" :src="scope.row.user_membership_card.user.avatar">
                <img :src="errorAvatarSrc(scope.row.user_membership_card.user, 'img')">
              </el-avatar>
              <span>{{ scope.row.user_membership_card.user.nickname }}</span>
            </div>
            <div v-if="!scope.row.user_membership_card.user" style="color: red;">用户数据丢失</div>
          </template>
        </el-table-column>
        <el-table-column label="请假时间" align="center">
          <template slot-scope="scope">{{ scope.row.start_time }} 至 {{ scope.row.end_time }}</template>
        </el-table-column>
        <el-table-column label="请假事由" align="center">
          <template slot-scope="scope">{{ scope.row.remark ? scope.row.remark : '无' }}</template>
        </el-table-column>
        <el-table-column label="会员卡名称" align="center">
          <template slot-scope="scope">{{ scope.row.user_membership_card.card.name }}</template>
        </el-table-column>
        <el-table-column label="卡号" align="center">
          <template slot-scope="scope">{{ scope.row.user_membership_card.number }}</template>
        </el-table-column>
        <el-table-column label="请假状态" align="center">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.ask_status_text }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="申请时间" prop="create_time" align="center" />
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="toPage(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="tableData.count > 0"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>
<script>
// API
import { getList } from '@/api/store/takeleave'
import { getStoreUser } from '@/api/store/management.js'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
// 方法函数
import { timestampToTime } from '@/utils/takeleave'

export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      }, // 数据
      search: '', // 搜索框
      loading: false, // 表格加载
      searchLoading: false, // 搜索按钮加载
      store_user_list: {}
    }
  },
  created() {
    this.getList()
    // 获取会员卡列表
    getStoreUser({ limit: 999 })
      .then(res => {
        this.store_user_list = res.data.list
      })
      .catch(() => {})
  },
  mounted() {},
  methods: {
    // 获取数据
    getList() {
      this.loading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      if (this.listQueryParams.q) {
        data.q = this.listQueryParams.q
      }

      if (this.listQueryParams.create_time) {
        data.start_time = this.listQueryParams.create_time[0]
        data.end_time = this.listQueryParams.create_time[1]
      }
      if (this.listQueryParams.store_user_id) {
        data.store_user_id = this.listQueryParams.store_user_id
      }

      getList(data).then(res => {
        const list = res.data
        list.list.forEach(item => {
          item.start_time = timestampToTime(item.start_time)
          item.end_time = timestampToTime(item.end_time)
        })
        this.tableData = list
        this.loading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    toPage(id) {
      this.$router.push({ name: 's_takeLeave_edit', params: { id: id }})
    }
  }
}
</script>

<style lang="scss" scoped>
.tableColumn {
  margin-top: 20px;
}

.ssl_input {
  width: 300px;
  margin-right: 20px;
}
.photo {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    margin-left: 10px;
  }
}
</style>
