<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="备注（选填）" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入内容" rows="5" maxlength="30" show-word-limit />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button @click="$emit('back', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { recycleUserMembershipCard } from '@/api/store/user.js'

export default {
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      form: {
        remark: ''
      }
    }
  },
  methods: {
    submit() {
      const data = {
        ...this.form
      }
      recycleUserMembershipCard(this.cardData.id, data).then(res => {
        this.$emit('callback', res.msg)
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
