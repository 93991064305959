<template>
  <el-dialog
    :visible.sync="visible"
    :width="equipment ? '50%' : '100%'"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :show-close="false"
    @open="handleOpen"
    @close="handleClose"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">{{ title }}</div>
        <div class="title_icon cursor" @click="handleCloseDialog">
          <i class="el-icon-close" />
        </div>
      </div>
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <el-form-item label="操作时间" class="window_pc">
            <el-date-picker
              v-model="listQueryParams.update_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <van-form label-width="80px" class="window_phone">
            <!-- 操作时间 -->
            <van-field
              readonly
              clickable
              name="calendar"
              :value="vanCreationTime"
              label="操作时间："
              placeholder="点击选择操作时间"
              @click="vanCreationTimeShow = true"
            />
            <van-calendar
              v-model="vanCreationTimeShow"
              type="range"
              :min-date="minVanCreationTime"
              :max-date="maxVanCreationTime"
              color="#1989fa"
              confirm-text="确 定"
              confirm-disabled-text="请选择结束时间"
              @confirm="onCreation"
            />
          </van-form>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
          <el-button class="m-b-10 window_phone" @click="handleReset">重置</el-button>
        </div>
      </div>
      <div class="filter-item" :style="{width: equipment ? '30%' : '80%'}">
        <el-input v-model="listQueryParams.query" placeholder="搜索详情|URL" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
    </div>
    <div v-loading="capitalLoading">
      <el-table :data="tableData.list" style="width: 100%">
        <el-table-column prop="date" label="品牌" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.app">
              <img v-if="row.app.logo" :src="row.app.logo" style="width: 60px; height: 60px;">
              <img v-else src="@/assets/images/logo.png" style="width: 60px; height: 60px;" alt="">
              <div class="venues_word">{{ row.app.name }}</div>
            </div>
            <div v-else style="color: red;">
              品牌信息丢失
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="场馆" align="center" :min-width="equipment ? '120' : '160'">
          <template slot-scope="{ row }">
            <div v-if="row.venues">
              <div class="venues_word">{{ row.venues.name }}</div>
            </div>
            <div v-else style="color: red;">
              场馆信息丢失
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="store_user_id" label="操作用户" align="center" />
        <!-- <el-table-column prop="store_user_id" label="操作用户" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.store_user">
              <div class="venues_word">{{ row.store_user.real_name }}</div>
            </div>
            <div v-else style="color: red;">
              用户信息丢失
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="title" label="URL标题" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.title">
              <div class="venues_word">{{ row.title }}</div>
            </div>
            <div v-else style="color: red;">
              操作信息丢失
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="URL" align="center" :min-width="equipment ? '120' : '160'">
          <template slot-scope="{ row }">
            <div v-if="row.url">
              <div class="venues_word">{{ row.url }}</div>
            </div>
            <div v-else style="color: red;">
              暂无url信息
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="detail" label="详情" align="center" :min-width="equipment ? '120' : '160'">
          <template slot-scope="{ row }">
            <div v-if="row.detail">
              <div class="venues_word">{{ row.detail }}</div>
            </div>
            <div v-else style="color: red;">
              暂无详情信息
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="操作时间" align="center" :min-width="equipment ? '120' : '160'" />
      </el-table>
      <pagination
        v-show="tableData.count > 0 && equipment"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="handleOpen"
      />
      <van-pagination
        v-show="tableData.count > 0 && !equipment"
        v-model="AccountPage"
        :total-items="tableData.count"
        :show-page-size="4"
        force-ellipses
        class="custom-pagination m-t-20"
        @change="handleAccountChange"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCloseDialog">返 回</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { getBrandOperateLogList } from '@/api/admin/capital.js'
// 分页组件
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination },
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false
    },
    // 传过来的id
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      capitalLoading: false,
      searchLoading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      tableData: {
        list: [],
        count: 0
      },
      title: '',
      timer: '',
      // 日期格式
      minVanCreationTime: new Date(2000, 0, 1),
      maxVanCreationTime: new Date(2099, 12, 31),
      // 创建时间
      vanCreationTime: '',
      vanCreationTimeShow: false,
      equipment: false,
      AccountPage: 1
    }
  },
  computed: {},
  watch: {},
  created() {
    // 判断页面是否大于768
    if (document.body.clientWidth > 768) {
      this.equipment = true
    } else {
      this.equipment = false
    }
  },
  mounted() {
    this.timer = new Date().getTime()
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.searchLoading = true
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      var querys = []
      if (this.equipment) {
        if (this.listQueryParams.update_time) {
          querys.push({ field: 'update_time', type: 'between-time', key: this.listQueryParams.update_time })
        }
      } else {
        if (this.vanCreationTime) {
          const vanCreationTime = this.vanCreationTime.split(' 至 ')
          querys.push({ field: 'update_time', type: 'between-time', key: vanCreationTime })
        }
      }
      if (this.listQueryParams.id) {
        querys.push({ field: 'appid', key: this.listQueryParams.id })
      }
      if (this.listQueryParams.query) {
        querys.push({ field: 'detail|url', type: 'like', key: this.listQueryParams.query })
      }
      data.query = this.utils.getQueryParams(querys)

      getBrandOperateLogList(data, this.id)
        .then(res => {
          this.tableData = res.data
          this.title = res.data.list[0].app.name
        })
        .finally(() => {
          this.capitalLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.handleOpen()
    },
    // 关闭的回调
    handleClose() {
      console.log('关闭')
      this.listQueryParams = {
        update_time: '',
        id: '',
        query: ''
      }
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.$emit('closeDialog')
    },
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    cardId(val) {
      this.listQueryParams.id = val
    },
    handleAccountChange(val) {
      this.listQuery.page = val
      this.handleOpen()
    },
    // 日期格式
    formatDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    // 创建时间
    onCreation(date) {
      const [start, end] = date
      this.vanCreationTimeShow = false
      this.vanCreationTime = `${this.formatDate(start)} 至 ${this.formatDate(end)}`
    },
    // 重置
    handleReset() {
      this.listQueryParams = {
        update_time: '',
        id: '',
        query: ''
      }
      this.vanCreationTime = ''
      this.handleOpen()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.van-cell {
  padding: 10px 0;
  ::v-deep {
    .van-cell__title {
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .window_pc {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .window_phone {
    display: none;
  }

  //弹出层的高度
  ::v-deep .el-dialog.import-dialog {
    height: auto;
    /* max-height: 80vh; */
  }
  //弹出层里内容的高度
  ::v-deep .el-dialog__body {
    max-height: 45vh !important;
    overflow-y: auto;
  }
}
</style>
