var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.menuData.api || _vm.menuData.children
        ? _c(
            "el-submenu",
            { attrs: { index: _vm.characterStr(_vm.menuData.id) } },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "flex row-between" }, [
                  _c("div", [
                    _c("i", { staticClass: "el-icon-files" }),
                    _c("span", { staticClass: "characters" }, [
                      _vm._v(_vm._s(_vm.menuData.name))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "m-r-20" },
                    [
                      _c(
                        "el-link",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.btn_access("system_api_route_edit"),
                              expression: "btn_access('system_api_route_edit')"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleEditClass(_vm.menuData.id)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.btn_access("system_api_route_del"),
                              expression: "btn_access('system_api_route_del')"
                            }
                          ],
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.handleDeleteClass(_vm.menuData.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._l(_vm.menuData.children, function(childMenu) {
                return _c("RecursiveMenu", {
                  key: childMenu.id,
                  attrs: { "menu-data": childMenu },
                  on: {
                    componentsData: _vm.handleComponentsData,
                    refresh: _vm.handleRefresh
                  }
                })
              }),
              _vm._l(_vm.menuData.api, function(childMenu) {
                return _c("RecursiveMenu", {
                  key: childMenu.id,
                  attrs: { "menu-data": childMenu },
                  on: {
                    componentsData: _vm.handleComponentsData,
                    refresh: _vm.handleRefresh
                  }
                })
              })
            ],
            2
          )
        : _c(
            "el-menu-item",
            {
              staticClass: "father",
              attrs: { index: _vm.characterStr(_vm.menuData.id) },
              on: {
                click: function($event) {
                  return _vm.handleClick(_vm.menuData)
                }
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "characters",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [
                  !!!_vm.menuData.hasOwnProperty("classify_id")
                    ? _c("i", { staticClass: "el-icon-files" })
                    : _vm._e(),
                  _vm._l(_vm.method, function(item, index) {
                    return _c(
                      "el-tag",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.menuData.hasOwnProperty("classify_id") &&
                              _vm.menuData.method === item.method,
                            expression:
                              "menuData.hasOwnProperty('classify_id') && menuData.method === item.method"
                          }
                        ],
                        key: index,
                        staticClass: "m-r-4 disable-transitions",
                        attrs: { type: item.type, size: "mini" }
                      },
                      [_vm._v(_vm._s(item.method))]
                    )
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.menuData.name))])
                ],
                2
              ),
              !!!_vm.menuData.hasOwnProperty("classify_id")
                ? _c(
                    "div",
                    { staticClass: "son" },
                    [
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleEditClass(_vm.menuData.id)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.handleDeleteClass(_vm.menuData.id)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
      _c("apiRouteDialog", {
        attrs: {
          "dialog-visible-api-route": _vm.dialogVisibleApiRoute,
          type: _vm.type,
          "type-id": _vm.typeId
        },
        on: { apiRouteVisible: _vm.apiRouteVisible }
      }),
      _c("apiRouteDialogList", {
        attrs: {
          "dialog-visible-api-route-list": _vm.dialogVisibleApiRouteList,
          type: _vm.type,
          "type-id": _vm.typeId
        },
        on: { apiRouteVisibleList: _vm.apiRouteVisibleList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }