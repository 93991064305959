<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules" label-position="left">
      <el-form-item label="现卡折价" prop="remaining">
        <el-input v-model="form.remaining" placeholder="请输入金额" type="number" />
      </el-form-item>
      <el-form-item label="退卡手续费" prop="poundage">
        <el-input v-model="form.poundage" placeholder="请输入金额" type="number" />
      </el-form-item>
      <el-form-item label="协议退款金额" prop="refund">
        <el-input v-model="form.refund" placeholder="请输入金额" type="number" />
      </el-form-item>
      <el-form-item label="退款路径" prop="payment_method_id">
        <el-select v-model="form.payment_method_id" placeholder="退款路径" class="dialog_w100">
          <el-option
            v-for="(item,index) in payment_method"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请填写操作备注"
          rows="5"
          maxlength="300"
          show-word-limit
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button @click="$emit('back', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { refundUserMembershipCard } from '@/api/store/user.js'
export default {
  name: 'CardRefund',
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      // 退卡
      form: {
        remaining: 0,
        poundage: 0,
        refund: 0,
        payment_method_id: 0,
        remark: ''
      },
      rules: {
        remaining: [
          { required: true, message: '请输入现卡折价', trigger: ['blur', 'change'] },
          { validator: (rule, value, callback) => {
            if (this.form.remaining < 0) {
              callback(new Error('请填写正数'))
            }
            callback()
          }, trigger: ['blur', 'change']
          }
        ],
        refund: [
          { required: true, message: '请输入协议退款金额', trigger: ['blur', 'change'] },
          { validator: (rule, value, callback) => {
            if (this.form.refund < 0) {
              callback(new Error('请填写正数'))
            }
            callback()
          }, trigger: ['blur', 'change']
          }
        ],
        poundage: { validator: (rule, value, callback) => {
          if (this.form.poundage < 0) {
            callback(new Error('请填写正数'))
          }
          callback()
        }, trigger: ['blur', 'change']
        }
      }
    }
  },
  created() {

  },
  methods: {
    // 退卡操作
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const data = this.form
          refundUserMembershipCard(this.cardData.id, data).then(res => {
            this.$emit('callback', res.msg)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style>

</style>
