<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="会员手机号" prop="phone">
        <el-input v-model="form.phone" disabled>
          <div slot="prepend">
            <el-select v-model="form.area_code" filterable placeholder="请选择" style="min-width:84px;" disabled>
              <el-option-group
                v-for="(group,index) in areaCodeList"
                :key="index"
                :label="group.label"
                class="min-width:200px"
              >
                <el-option
                  v-for="(item,indexs) in group.options"
                  :key="indexs"
                  :label="item.value"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;padding-left:100px">{{ item.value }}</span>
                </el-option>
              </el-option-group>
            </el-select>
          </div>
          <template slot="append">
            <el-button :loading="isLoading" type="primary" :class="{disabled:sendCoding}" @click="sendCode('loss_un')">{{ getCodeText }}</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="verify_code">
        <el-input v-model="form.verify_code" type="number" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button @click="$emit('back', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { lossUnregisterUserMembershipCard, SendCode } from '@/api/store/user.js'
import areaCode from '@/components/commonData/area_code.json'
export default {
  name: 'CardUnLoss',
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      form: {
        phone: '',
        area_code: '86',
        verify_code: ''
      },
      rules: {
        verify_code: { required: true, message: '请输入验证码', trigger: ['blur', 'change'] }
      },
      getCodeText: '获取验证码',
      sendCoding: false,
      isLoading: false,
      smsTimes: 60,
      dtime: '',
      areaCodeList: areaCode
    }
  },
  created() {
    if (this.cardData.user) {
      this.form.name = this.getUserNames(this.cardData.user)
      this.form.phone = this.cardData.user.phone
      this.form.area_code = this.cardData.user.area_code
    } else {
      this.$message.error('当前此卡会员信息异常')
    }
  },
  methods: {
    // 解挂操作
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          lossUnregisterUserMembershipCard(this.cardData.id, {
            phone: this.form.phone,
            area_code: this.form.area_code.replace(/[^0-9]/ig, ''),
            verify_code: this.form.verify_code
          }).then(res => {
            this.sendCodeCancel()
            this.$emit('callback', res.msg)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 验证码完毕
    sendCodeCancel() {
      this.sendCoding = false
      this.isLoading = false
      clearInterval(this.dtime)
      this.getCodeText = '获取验证码'
    },
    sendCode() {
      if (!this.sendCoding) {
        let data = {}
        data = {
          phone: this.form.phone,
          area_code: this.form.area_code.replace(/[^0-9]/ig, ''),
          scene: (this.form.area_code === '86') ? '' : 'internation'
        }

        SendCode(data).then(res => {
          this.sendCoding = true
          this.isLoading = true
          this.$message({
            type: 'success',
            message: '验证码已发送'
          })
          // 开始计时
          var stime = this.smsTimes
          this.dtime = setInterval(() => {
            stime--
            if (stime === 0) {
              this.sendCodeCancel()
            } else {
              this.getCodeText = stime + ' 秒后重试'
            }
          }, 1000)
        })
      }
    }
  }
}
</script>

<style>

</style>
