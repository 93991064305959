<template>
  <el-card style="margin-bottom:20px;">
    <div slot="header" class="clearfix">
      <div class="header_flex">
        <div class="header_title">
          <span>约课排行</span>
        </div>
        <div class="header_dropdown">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              {{ dropdownName }}
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleTime(1)">正序</el-dropdown-item>
              <el-dropdown-item @click.native="handleTime(2)">倒序</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="time">
          <el-date-picker
            v-model="listQuery.create_time"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            @change="changeTime"
          />
        </div>
        <div class="details" @click="is_display = !is_display">
          {{ is_display ? '切换详情' : '切换图表' }}
          <i class="el-icon-sort" />
        </div>
        <div v-if="!is_display" class="details screen" @click="show = !show">
          {{ show ? '收起' : '筛选' }}
        </div>
      </div>
      <div>
        <el-collapse-transition>
          <div v-show="show && !is_display">
            <el-form ref="form" style="padding-top: 30px;" :inline="true" :model="listQuery" label-width="80px">
              <el-form-item label="会员" prop="memberID">
                <Select :type="2" :width="216" @changeId="handleMemberID" />
              </el-form-item>
              <el-form-item label="筛选方式">
                <el-select v-model="is_timeSelect" placeholder="请选择">
                  <el-option label="预约日期" :value="0" />
                  <el-option label="上课日期" :value="1" />
                </el-select>
              </el-form-item>
              <el-form-item label="预约状态">
                <el-select v-model="listQuery.status" placeholder="请选择" @change="changeSelect">
                  <el-option label="全部" value="" />
                  <el-option label="已预约" value="0" />
                  <el-option label="已签到" value="1" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-transition>
      </div>
    </div>
    <!-- 内容 -->
    <div v-show="is_display" id="classAppointment" :style="{ height: '500px', width: '100%' }" />
    <el-table
      v-show="!is_display"
      v-loading="loadingTable"
      :data="dataTable.list"
      :height="428"
      style="width: 100%; height: 428px;"
      align="center"
    >
      <el-table-column type="index" label="排名" width="100px" align="center">
        <template slot-scope="scope">
          <div v-if="(listQuery.page - 1) * listQuery.limit + scope.$index + 1 === 1">
            <img src="@/assets/images/ranking/rankingNo1.png" alt="">
          </div>
          <div v-else-if="(listQuery.page - 1) * listQuery.limit + scope.$index + 1 === 2">
            <img src="@/assets/images/ranking/rankingNo2.png" alt="">
          </div>
          <div v-else-if="(listQuery.page - 1) * listQuery.limit + scope.$index + 1 === 3">
            <img src="@/assets/images/ranking/rankingNo3.png" alt="">
          </div>
          <div v-else>{{ (listQuery.page - 1) * listQuery.limit + scope.$index + 1 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="groupCount" label="会员" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.user" class="flex">
            <el-avatar shape="square" :size="50" :src="row.user.avatar">
              <img :src="errorMemberSrc()">
            </el-avatar>
            <span class="m-l-10">{{ row.user.nickname ? row.user.nickname : row.user.real_name }}</span><br>
          </div>
          <div v-else style="color: red;">用户信息丢失</div>
        </template>
      </el-table-column>
      <el-table-column prop="groupCount" label="团课" width="100px" align="center" />
      <el-table-column prop="fineCount" label="精品课" width="100px" align="center" />
      <el-table-column prop="privateCount" label="私教课" width="100px" align="center" />
    </el-table>
    <pagination
      v-show="dataTable.count > 0 && !is_display"
      :total="dataTable.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getTable"
    />
  </el-card>
</template>

<script>
import { getAppointment } from '@/api/store/ranking'
import { timestampToTimes } from '@/utils/takeleave.js'
// 基于el-pagination的二级包
import Pagination from '@/components/Pagination'
import Select from '@/components/elSelect/Select'

export default {
  components: { Pagination, Select },
  data() {
    return {
      dataTable: {
        count: 0,
        courseCount: 0,
        dataMax: 0,
        fineMax: 0,
        groupMax: 0,
        privateMax: 0,
        list: []
      }, // 数据
      option: {}, // echarts
      dropdownName: '倒序', // 排序
      dropdownId: 'desc', // 排序id
      end: '', // 日期
      start: '', // 日期
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }, // 快捷选择日期
      dataColor: ['#fb902d', '#3a93ff', '#01cbca'], // 定义颜色数组
      dataCurriculum: ['团课', '精品课', '私教课'],
      key: ['groupCount', 'fineCount', 'privateCount'],
      dataName: [], // 人员
      data: [], // 课程数据
      is_display: true,
      loadingTable: false,
      show: false,
      count: 0,
      courseCount: 0,
      is_time: 'create_time',
      is_timeSelect: 0
    }
  },
  watch: {
    is_timeSelect(val) {
      if (val === 0) this.is_time = 'create_time'
      if (val === 1) this.is_time = 'subscribe_time'
      this.getTable()
    }
  },
  created() {},
  mounted() {
    this.start = timestampToTimes(new Date().getTime() - 3600 * 1000 * 24 * 7)
    this.end = timestampToTimes(Date.parse(new Date()))
    this.getList()
    this.getTable()
  },
  methods: {
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data.limit = 5
      data.offsset = 0
      data.order = `totalCount ${this.dropdownId}`

      var querys = []
      querys.push({
        field: 'create_time',
        type: 'between-time',
        key: [this.start.slice(0, 10), this.end.slice(0, 10)]
      })
      data.query = this.utils.getQueryParams(querys)

      this.dataName = []
      this.data = []
      getAppointment(data)
        .then(res => {
          this.count = res.data.count
          this.courseCount = res.data.courseCount
          const list = res.data.list
          this.key.forEach((key, index) => {
            this.data[index] = []
            list.forEach(item => {
              this.data[index].push(item[key])
            })
          })
          list.forEach(item => {
            if (item.user) {
              if (item.user.nickname) {
                this.dataName.push(item.user.nickname)
              } else if (item.user.real_name) {
                this.dataName.push(item.user.real_name)
              }
            } else {
              this.dataName.push('用户信息丢失')
            }
          })
        })
        .finally(() => {
          this.getOption()
        })
    },
    getTable() {
      this.loadingTable = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.order = `totalCount ${this.dropdownId}`
      var querys = []
      querys.push({
        field: this.is_time,
        type: 'between-time',
        key: [this.start.slice(0, 10), this.end.slice(0, 10)]
      })
      if (this.listQuery.status) querys.push({ field: 'status', key: this.listQuery.status })
      if (this.listQuery.user_id) querys.push({ field: 'user_id', key: this.listQuery.user_id })
      data.query = this.utils.getQueryParams(querys)
      getAppointment(data).then(res => {
        this.dataTable = res.data
        this.loadingTable = false
      })
    },
    // echarts
    getOption() {
      this.option = {
        title: {
          text: '约课排行',
          subtext: `${this.start.slice(0, 10)} - ${this.end.slice(0, 10)}`,
          // '本月数据'
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          show: true, // 是否显示
          bottom: '0%',
          left: 'left'
        },
        xAxis: {
          type: 'category',
          data: this.dataName
        },
        yAxis: {
          type: 'value'
        },
        graphic: {
          elements: [
            {
              type: 'text',
              left: 50,
              top: 20,
              style: {
                text: `预约人数：${this.count}${' '.repeat(6)}预约课程：${this.courseCount}`,
                fontSize: 14
              }
            },
            {
              type: 'text',
              left: 'center',
              top: 'middle',
              style: {
                fill: '#999',
                text: '暂无数据',
                font: '14px Arial',
                opacity: this.data[0].length === 0 ? 1 : 0
              }
            }]

        },
        series: [
          {
            name: this.dataCurriculum[0],
            type: 'bar',
            showBackground: true,
            data: this.data[0],
            itemStyle: {
              // 设置柱子样式
              color: this.dataColor[0]
            }
          },
          {
            name: this.dataCurriculum[1],
            type: 'bar',
            showBackground: true,
            data: this.data[1],
            itemStyle: {
              // 设置柱子样式
              color: this.dataColor[1]
            }
          },
          {
            name: this.dataCurriculum[2],
            type: 'bar',
            showBackground: true,
            data: this.data[2],
            itemStyle: {
              // 设置柱子样式
              color: this.dataColor[2]
            }
          }
        ]
      }

      const myChart = this.$echarts.init(document.getElementById('classAppointment'))
      myChart.setOption(this.option)
    },
    // 点击正序或者倒序
    handleTime(val) {
      if (val === 1) {
        this.dropdownName = '正序'
        this.dropdownId = 'asc'
      }
      if (val === 2) {
        this.dropdownName = '倒序'
        this.dropdownId = 'desc'
      }
      this.getList()
      this.getTable()
    },
    // 监听日期变化
    changeTime() {
      if (this.listQuery.create_time) {
        this.start = this.listQuery.create_time[0]
        this.end = this.listQuery.create_time[1]
        this.getList()
        this.getTable()
      } else {
        this.start = timestampToTimes(new Date().getTime() - 3600 * 1000 * 24 * 7)
        this.end = timestampToTimes(Date.parse(new Date()))
        this.getList()
        this.getTable()
      }
    },
    changeSelect() {
      this.getTable()
    },
    handleMemberID(val) {
      this.listQuery.user_id = val
      this.getTable()
    }
  }
}
</script>

<style lang="scss" scoped>
.header_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_title {
    font-size: 14px;
  }
  .header_dropdown {
    /* margin-left: 10px; */
    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }
    .el-icon-arrow-down {
      font-size: 14px;
    }
  }
  .time {
    /* margin-left: 15px; */
  }
  .details {
    font-size: 14px;
    /* margin-left: 10px; */
    display: flex;
    align-items: center;
    color: #00d8d7;
    cursor: pointer;
    i {
      font-size: 18px;
    }
  }
  .screen {
    color: #000;
  }
}
.pagination-container {
  margin-top: 10px !important;
}
.app-container .el-table {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.venuesList_empty {
  min-height: 500px;
  img {
    -webkit-user-drag: none;
  }
}
</style>
