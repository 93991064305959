import request from '@/utils/request'

// 获取数据总览
export function getAssetStatistics() {
  return request({
    url: `/store/v1/data/overview`,
    method: 'get'
  })
}

// 获取收入统计
export function getIncomeStatisticsList(params) {
  return request({
    url: `/store/v1/data/total`,
    method: 'get',
    params
  })
}

// 获取耗卡金额统计
export function getExpendStatisticsList(params) {
  return request({
    url: `/store/v1/data/consume`,
    method: 'get',
    params
  })
}

// 获取资产统计
export function getAssetStatisticsList(params) {
  return request({
    url: `/store/v1/data/user_balance`,
    method: 'get',
    params
  })
}
