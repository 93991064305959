var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm m-t-20",
          attrs: {
            inline: true,
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "sku资源包", prop: "skuId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择sku资源包" },
                  model: {
                    value: _vm.ruleForm.skuId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "skuId", $$v)
                    },
                    expression: "ruleForm.skuId"
                  }
                },
                _vm._l(_vm.SkusData.skuList, function(item, index) {
                  return _c(
                    "el-option",
                    {
                      key: index,
                      attrs: { label: item.skuName, value: item.skuId }
                    },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c("div", [_vm._v(_vm._s(item.skuName))]),
                        _c("div", { staticClass: "text-info" }, [
                          _vm._v("-" + _vm._s(item.finalPrice / 100) + "元")
                        ])
                      ])
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单", prop: "orderId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择订单" },
                  model: {
                    value: _vm.ruleForm.orderId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "orderId", $$v)
                    },
                    expression: "ruleForm.orderId"
                  }
                },
                _vm._l(_vm.OrderData.orderList, function(item, index) {
                  return _c(
                    "el-option",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.status == 2,
                          expression: "item.status == 2"
                        }
                      ],
                      key: index,
                      attrs: {
                        label: item.orderProductInfo.spuName,
                        value: item.orderId
                      }
                    },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c("div", [
                          _vm._v(_vm._s(item.orderProductInfo.spuName))
                        ])
                      ])
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "m-l-60",
                  attrs: { type: "primary", loading: _vm.buttonLoading },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("立即分配")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticClass: "m-t-20",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.list, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "appid", label: "品牌ID", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orderId", label: "订单号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "receiverAppId",
              label: "小程序ApplD",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "skuId", label: "sku资源包 ", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "quota", label: "配额 ", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 0
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未生效")
                        ])
                      : _vm._e(),
                    row.status == 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("已分配")
                        ])
                      : _vm._e(),
                    row.status == 2
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("已撤回")
                        ])
                      : _vm._e(),
                    row.status == 3
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("失败")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "创建时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 0 || row.status == 1
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "删除后将无法找回，确定继续吗？" },
                            on: {
                              confirm: function($event) {
                                return _vm.handleCancel(row)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  plain: ""
                                },
                                slot: "reference"
                              },
                              [_vm._v("取消分配")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }