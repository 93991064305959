var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "searchBox" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.listQueryParams,
                    "label-width": "100px",
                    "label-position": "left",
                    "label-suffix": "："
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "小程序模板" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择小程序模板",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.release_template_id,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.listQueryParams,
                                "release_template_id",
                                $$v
                              )
                            },
                            expression: "listQueryParams.release_template_id"
                          }
                        },
                        _vm._l(_vm.getTemplateDataList, function(item, index) {
                          return _c(
                            "el-option",
                            {
                              key: index,
                              attrs: {
                                label: item.user_version,
                                value: item.template_id
                              }
                            },
                            [
                              _c("span", { staticClass: "text-info" }, [
                                _vm._v(_vm._s(item.user_version))
                              ]),
                              _vm._v(" - "),
                              _c("span", [
                                _vm._v(_vm._s(item.user_desc.split("\n")[0]))
                              ])
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择状态", clearable: "" },
                          model: {
                            value: _vm.listQueryParams.code_status,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "code_status", $$v)
                            },
                            expression: "listQueryParams.code_status"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "未发布", value: 0 }
                          }),
                          _c("el-option", {
                            attrs: { label: "已发布", value: 1 }
                          }),
                          _c("el-option", {
                            attrs: { label: "审核中", value: 2 }
                          }),
                          _c("el-option", {
                            attrs: { label: "审核失败", value: 3 }
                          }),
                          _c("el-option", {
                            attrs: { label: "已撤回", value: 4 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版本号" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "300px" },
                          attrs: { placeholder: "请输入条数" },
                          model: {
                            value: _vm.listQueryParams.release_version,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.listQueryParams,
                                "release_version",
                                $$v
                              )
                            },
                            expression: "listQueryParams.release_version"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: { slot: "prepend", placeholder: "请选择" },
                              slot: "prepend",
                              model: {
                                value:
                                  _vm.listQueryParams.release_version_where,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.listQueryParams,
                                    "release_version_where",
                                    $$v
                                  )
                                },
                                expression:
                                  "listQueryParams.release_version_where"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "大于", value: "gt" }
                              }),
                              _c("el-option", {
                                attrs: { label: "小于", value: "lt" }
                              }),
                              _c("el-option", {
                                attrs: { label: "等于", value: "=" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "m-b-10",
                      attrs: {
                        icon: "el-icon-search",
                        loading: _vm.searchLoading,
                        type: "success"
                      },
                      on: { click: _vm.handleFilter }
                    },
                    [_vm._v(" 搜索 ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "搜索 品牌名|品牌ID|小程序名" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleFilter($event)
                    }
                  },
                  model: {
                    value: _vm.listQueryParams.query,
                    callback: function($$v) {
                      _vm.$set(_vm.listQueryParams, "query", $$v)
                    },
                    expression: "listQueryParams.query"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      loading: _vm.searchLoading
                    },
                    on: { click: _vm.handleFilter },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "course filter-item m-l-10",
              attrs: { "hide-on-click": false }
            },
            [
              _c("el-button", { attrs: { type: "success" } }, [
                _vm._v(" 批量操作 "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.tagDataList, function(item, index) {
                  return _c(
                    "el-dropdown-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btn_access(item.btn_access),
                          expression: "btn_access(item.btn_access)"
                        }
                      ],
                      key: index,
                      attrs: { disabled: _vm.checkedCities.length === 0 },
                      nativeOn: {
                        click: function($event) {
                          return _vm.handleOperation(index)
                        }
                      }
                    },
                    [[_vm._v(_vm._s(item.name))]],
                    2
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticClass: "m-t-10",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.dataList.list, stripe: "" },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "60",
                  selectable: _vm.isSelectable
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "60" }
              }),
              _c("el-table-column", { attrs: { prop: "name", label: "品牌" } }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "注册", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex m-y-10" }, [
                          _c("div", [_vm._v("注册信息：")]),
                          Array.isArray(row.register_status.fast_create)
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v("暂无注册信息")
                              ])
                            : _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          row.register_status.fast_create.name,
                                        placement: "right"
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              row.register_status.fast_create
                                                .legal_persona_name
                                            ) +
                                            " - " +
                                            _vm._s(
                                              row.register_status.fast_create
                                                .component_phone
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex m-y-10" },
                          [
                            _c("div", [_vm._v("小程序信息：")]),
                            row.register_status.program_name
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content:
                                        row.register_status.fast_create.name,
                                      placement: "right"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "content" }, [
                                      _c("div", { staticClass: "flex" }, [
                                        row.program_open_path
                                          ? _c(
                                              "div",
                                              { staticClass: "m-4" },
                                              [
                                                _c("el-image", {
                                                  staticStyle: {
                                                    width: "100px",
                                                    height: "100px"
                                                  },
                                                  attrs: {
                                                    src: row.program_open_path,
                                                    "preview-src-list": [
                                                      row.program_open_path
                                                    ]
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "m-t-4 text-info",
                                                    staticStyle: {
                                                      "text-align": "center"
                                                    }
                                                  },
                                                  [_vm._v("品牌码")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.qrcode_url
                                          ? _c(
                                              "div",
                                              { staticClass: "m-4" },
                                              [
                                                _c("el-image", {
                                                  staticStyle: {
                                                    width: "100px",
                                                    height: "100px"
                                                  },
                                                  attrs: {
                                                    src: row.qrcode_url,
                                                    "preview-src-list": [
                                                      row.qrcode_url
                                                    ]
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "m-t-4 text-info",
                                                    staticStyle: {
                                                      "text-align": "center"
                                                    }
                                                  },
                                                  [_vm._v("小程序码")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.register_status.program_head
                                          ? _c(
                                              "div",
                                              { staticClass: "m-4" },
                                              [
                                                _c("el-image", {
                                                  staticStyle: {
                                                    width: "100px",
                                                    height: "100px"
                                                  },
                                                  attrs: {
                                                    src:
                                                      row.register_status
                                                        .program_head,
                                                    "preview-src-list": [
                                                      row.register_status
                                                        .program_head
                                                    ]
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "m-t-4 text-info",
                                                    staticStyle: {
                                                      "text-align": "center"
                                                    }
                                                  },
                                                  [_vm._v("头像")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ]),
                                    _c("div", { staticClass: "m-r-6" }, [
                                      _vm._v(
                                        _vm._s(row.register_status.program_name)
                                      )
                                    ])
                                  ],
                                  2
                                )
                              : _c("div", [_vm._v("---")])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex m-y-10" }, [
                          _c("div", [_vm._v("备案信息：")]),
                          Array.isArray(row.register_status.program_icp)
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v("暂无备案信息")
                              ])
                            : _c("div", [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.register_status.program_icp
                                          .status_text
                                      ) +
                                      "（" +
                                      _vm._s(
                                        row.register_status.program_icp.status
                                      ) +
                                      "） "
                                  )
                                ])
                              ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "发布", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex m-y-10" }, [
                          _c("div", [_vm._v("状态：")]),
                          row.release_status.status
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: _vm.check(
                                          row.release_status.status
                                        ).type
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.check(row.release_status.status)
                                              .state
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("div", [_vm._v("---")])
                        ]),
                        _c("div", { staticClass: "flex m-y-10" }, [
                          _c("div", [_vm._v("最新发布的版本：")]),
                          Array.isArray(row.release_status.code_push)
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v("暂无已发布记录")
                              ])
                            : _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.release_status.code_push.version
                                    ) +
                                    " "
                                )
                              ])
                        ]),
                        _c("div", { staticClass: "flex m-y-10" }, [
                          _c("div", [_vm._v("已发布的版本：")]),
                          Array.isArray(row.release_status.code_release)
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v("暂无已发布记录")
                              ])
                            : _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.release_status.code_release.version
                                    ) +
                                    " "
                                )
                              ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "商户号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex m-y-10" }, [
                          Array.isArray(row.wechat_pay)
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v("暂无商户号信息")
                              ])
                            : _c("div", [
                                _c("div", [
                                  _vm._v(
                                    "商户号：" +
                                      _vm._s(row.wechat_pay.sub_mch_id)
                                  )
                                ])
                              ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.code_status
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      type: _vm.conversion(row.code_status).type
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.conversion(row.code_status).state
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _c("div", [_vm._v("---")])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toPage(row)
                              }
                            }
                          },
                          [_vm._v("查 看")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.btn_access("wxapp_update"),
                                expression: "btn_access('wxapp_update')"
                              }
                            ],
                            attrs: { type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.refresh(row)
                              }
                            }
                          },
                          [_vm._v("更 新")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dataList.count > 0,
                expression: "dataList.count > 0"
              }
            ],
            attrs: {
              total: _vm.dataList.count,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "配置业务域名",
            visible: _vm.urlVisible,
            "close-on-click-modal": false,
            width: "785px"
          },
          on: {
            "update:visible": function($event) {
              _vm.urlVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-left": "30px" } },
            [
              _c(
                "div",
                { staticStyle: { "max-height": "400px", overflow: "auto" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        "label-position": "left"
                      }
                    },
                    _vm._l(_vm.domain, function(item, index) {
                      return _c("el-form-item", { key: index }, [
                        _c(
                          "label",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(" 配置业务域名 "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "以https://开头，例:https://www.yuxiaojiu.com",
                                  placement: "top"
                                }
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "520px",
                                "margin-right": "20px"
                              },
                              model: {
                                value: _vm.domain[index],
                                callback: function($$v) {
                                  _vm.$set(_vm.domain, index, $$v)
                                },
                                expression: "domain[index]"
                              }
                            }),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top"
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    src: require("@/assets/images/del.png"),
                                    alt: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.delDomain(index)
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { color: "#999999", margin: "20px 0 10px" } },
                [_vm._v("最多添加20条域名")]
              ),
              _vm.domain.length < 20
                ? _c(
                    "el-button",
                    {
                      staticClass: "add-btn",
                      attrs: { size: "small", plain: "" },
                      on: { click: _vm.addUrl }
                    },
                    [_vm._v("+添加域名")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submitUrl }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "绑定体验者",
            visible: _vm.bindExperienceVisible,
            "close-on-click-modal": false,
            width: "785px"
          },
          on: {
            "update:visible": function($event) {
              _vm.bindExperienceVisible = $event
            },
            close: _vm.cancelBindExperience
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-width": "120px",
                    "label-position": "right"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "体验者微信号" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "520px" },
                        model: {
                          value: _vm.bindExperienceValue,
                          callback: function($$v) {
                            _vm.bindExperienceValue = $$v
                          },
                          expression: "bindExperienceValue"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleWeChat(1)
                    }
                  }
                },
                [_vm._v("绑 定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.handleWeChat(0)
                    }
                  }
                },
                [_vm._v("解 绑")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.bindExperienceVisible = false
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传代码",
            visible: _vm.uploadCodeVisible,
            "close-on-click-modal": false,
            width: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.uploadCodeVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.visibleLoading,
                  expression: "visibleLoading"
                }
              ]
            },
            [
              _c(
                "div",
                {
                  staticClass: "font-14",
                  staticStyle: { "font-weight": "600" }
                },
                [_vm._v("选择版本")]
              ),
              _c(
                "div",
                { staticClass: "flex-col p-l-20 m-t-20" },
                _vm._l(_vm.getTemplateDataList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-bottom": "15px" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: item.template_id },
                          on: {
                            change: function($event) {
                              return _vm.handlePlugin(item)
                            }
                          },
                          model: {
                            value: _vm.is_plugin,
                            callback: function($$v) {
                              _vm.is_plugin = $$v
                            },
                            expression: "is_plugin"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.user_desc.split("\n")[0]) +
                              "(" +
                              _vm._s(item.user_version) +
                              ") "
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.uploadCodeVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSubmitUploadCode }
                },
                [_vm._v("提交上传")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提交审核",
            visible: _vm.auditVisible,
            "close-on-click-modal": false,
            width: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.auditVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.visibleLoading,
                  expression: "visibleLoading"
                }
              ]
            },
            [
              _c(
                "div",
                {
                  staticClass: "font-14",
                  staticStyle: { "font-weight": "600" }
                },
                [_vm._v("选择版本")]
              ),
              _c(
                "div",
                { staticClass: "flex-col p-l-20 m-t-20" },
                _vm._l(_vm.getTemplateDataList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-bottom": "15px" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: item.template_id },
                          on: {
                            change: function($event) {
                              return _vm.handlePlugin(item)
                            }
                          },
                          model: {
                            value: _vm.is_plugin,
                            callback: function($$v) {
                              _vm.is_plugin = $$v
                            },
                            expression: "is_plugin"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.user_desc.split("\n")[0]) +
                              "(" +
                              _vm._s(item.user_version) +
                              ") "
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.auditVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSubmitForReview }
                },
                [_vm._v("提交审核")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.loadComplete ? "加载完成" : "加载中",
            visible: _vm.loadVisible,
            "close-on-click-modal": false,
            "show-close": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.loadVisible = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c(
            "div",
            [
              !_vm.loadComplete
                ? _c("el-progress", {
                    attrs: {
                      "text-inside": true,
                      "stroke-width": 26,
                      percentage: _vm.loadNumber
                    }
                  })
                : _vm._e(),
              _vm.loadComplete
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.errorData }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "id", label: "小程序名称" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.nameFunction(row.id)) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1043693558
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "err", label: "错误信息" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.loadComplete
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          _vm.loadVisible = false
                        }
                      }
                    },
                    [_vm._v("关 闭")]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }