<template>
  <div class="reset-container">
    <div class="reset-header">
      <div class="header-logo">
        <img src="@/assets/images/login_logo_dark.svg" alt="" class="logo-img m-t-40 m-l-60">
      </div>
    </div>

    <div class="p-t-60 center">
      <div class="flex reset-box col-top">
        <div class="box-left">
          <img src="@/assets/images/apply_left.jpg" alt="">
        </div>
        <div class="box-right">
          <div class="reset-logo">
            <div class="reset-title">忘记密码</div>
          </div>
          <div class="m-t-30">
            <el-form
              ref="resetPwdForm"
              :model="resetPwdForm"
              :rules="resetPwdRules"
              class="reset-form"
              autocomplete="on"
              size="medium"
              label-position="left"
              @keyup.enter="handleSubmit('resetPwdForm')"
            >
              <el-form-item prop="phone">
                <div class="fix">
                  <i class="icon-required" />
                  <Code @area_code="area_code" />
                  <el-input
                    ref="phone"
                    v-model="resetPwdForm.phone"
                    placeholder="手机号"
                    name="phone"
                    type="text"
                    tabindex="1"
                    autocomplete="on"
                    class="input-item"
                  />
                </div>
              </el-form-item>
              <el-form-item prop="code">
                <div class="fix">
                  <i class="icon-required" />
                  <el-input
                    ref="code"
                    v-model="resetPwdForm.code"
                    placeholder="请输入验证码"
                    name="code"
                    tabindex="2"
                    autocomplete="on"
                    class="input-item"
                    @keyup.native="checkCapslock"
                    @blur="capsTooltip = false"
                    @keyup.enter.native="submit"
                  />
                  <span v-loading="isLoading" class="get-code" :class="{ disabled: sendCoding }" @click="getCodes()">
                    {{ getCodeText }}
                  </span>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <div class="fix">
                  <i class="icon-required" />
                  <el-input
                    ref="password"
                    v-model="resetPwdForm.password"
                    placeholder="输入新密码"
                    type="password"
                    name="password"
                    tabindex="3"
                    autocomplete="on"
                    class="input-item"
                    @keyup.native="checkCapslock"
                    @blur="capsTooltip = false"
                    @keyup.enter.native="submit"
                  />
                </div>
              </el-form-item>
              <el-form-item prop="password2">
                <div class="fix">
                  <i class="icon-required" />
                  <el-input
                    ref="password2"
                    v-model="resetPwdForm.password2"
                    placeholder="重复输入新密码"
                    type="password"
                    name="password2"
                    tabindex="4"
                    autocomplete="on"
                    class="input-item"
                    @keyup.native="checkCapslock"
                    @blur="capsTooltip = false"
                    @keyup.enter.native="submit"
                  />
                </div>
              </el-form-item>
            </el-form>
            <div class="input-item">
              <el-button :loading="loading" type="primary" size="default" class="submit" @click.native.prevent="submit">
                提交
              </el-button>
            </div>
            <!-- <div class="input-item input-register">
              <el-button type="primary" size="default" class="submit submits">
                <a :href="web_link + '/freeRegister'" target="_blank">
                  还没有账号？立即注册
                </a>
              </el-button>
            </div> -->
            <!-- <div class="input-item">
              <a class="submit">{{ $t("login.logIn") }}</a>
            </div> -->
            <div class="foot-btn m-y-20">
              <div class="flex  flex-wrap row-between col-center">
                <div class="register btn_text">
                  <router-link to="/verificationCode">
                    <i class="el-icon-receiving" />
                    验证码登录
                  </router-link>
                </div>
                <div class="register btn_text">
                  <router-link to="/login">
                    <i class="el-icon-user" />
                    有账号?
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 尾部 -->
    <footer>
      <div class="m-y-10" style="color:#848484">
        瑜小九·知识产品与用户服务的数字化工具
      </div>
      <div class="">
        Copyright © 2015-{{ new Date().getFullYear() }}
        <a :href="web_link" target="_blank" class="link" title="瑜小九">瑜小九预约管理系统</a>
        All Rights Reserved.
        <a target="_blank" href="https://beian.miit.gov.cn/" class="link" rel="nofollow">{{ icpNumber }}</a>
      </div>
    </footer>

    <Verify
      v-if="showVerify"
      ref="verify"
      mode="pop"
      captcha-type="blockPuzzle"
      :img-size="{ width: '330px', height: '155px' }"
      :phone="resetPwdForm.phone"
      @success="captcha_success"
    />
  </div>
</template>

<script>
import Verify from '@/components/verifition/Verify'
import { resetPwd, SendCode } from '@/api/store/user.js'
import { deepClone } from '@/utils'
// 组件
import Code from '@/components/code/index.vue'

export default {
  components: {
    Verify,
    Code
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号!'))
      } else {
        const reg = /^1[23456789]\d{9}$/
        if (value === '' || value === undefined || value == null) {
          callback()
        } else {
          if (this.resetPwdForm.area_code === '+86' && !reg.test(value) && value !== '') {
            callback(new Error('请输入正确的电话号码'))
          } else {
            callback()
          }
        }
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码!'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入新密码!'))
      } else {
        callback()
      }
    }
    const validatePassword2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请重复输入新密码!'))
      }
      if (value !== this.resetPwdForm.password) {
        callback(new Error('两次密码不一致，请检查!'))
      } else {
        callback()
      }
    }
    return {
      resetPwdForm: {
        phone: '',
        code: '',
        password: '',
        password2: '',
        area_code: '+86'
      },
      resetPwdRules: {
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePhone
          }
        ],
        code: [
          {
            required: true,
            trigger: 'blur',
            validator: validateCode
          }
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword
          }
        ],
        password2: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword2
          }
        ]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      show2: false,
      show3: false,
      venues_list: [],
      is_super: 0,
      modals: false,
      appInfo: {},
      app_loading: true,
      venues_list_loading: true,
      is_dev: process.env.NODE_ENV === 'development',
      getCodeText: '获取验证码',
      sendCoding: false,
      isLoading: false,
      smsTimes: 60,
      showVerify: false
    }
  },
  mounted() {
    if (this.resetPwdForm.phone === '') {
      this.$refs.phone.focus()
    } else if (this.resetPwdForm.password === '') {
      this.$refs.password.focus()
    }
  },
  methods: {
    getCodes() {
      // 验证手机号
      this.$refs['resetPwdForm'].validateField('phone', res => {
        if (res) {
          return false
        } else {
          var data = {
            phone: this.resetPwdForm.phone,
            area_code:
              this.resetPwdForm.area_code === '+86' ? '86' : this.resetPwdForm.area_code.replace(/[^0-9]/gi, ''),
            scene: this.resetPwdForm.area_code === '+86' ? '' : 'internation'
          }
          if (!this.sendCoding) {
            this.isLoading = true
            SendCode(data)
              .then(res => {
                this.sendCoding = true
                this.isLoading = false
                this.$message({
                  type: 'success',
                  message: '验证码已发送，请注意查收短信'
                })
                // 开始计时
                var stime = this.smsTimes
                var dtime = setInterval(() => {
                  stime--
                  if (stime === 0) {
                    this.sendCoding = false
                    clearInterval(dtime)
                    this.getCodeText = '获取验证码'
                  } else {
                    this.getCodeText = stime + ' 秒后重试'
                  }
                }, 1000)
              })
              .finally(e => {
                this.isLoading = false
              })
          } else {
            // this.$message('稍候再试')
            return false
          }
        }
      })
    },
    captcha_success() {
      // 验证手机号
      this.$refs['resetPwdForm'].validateField('phone', res => {
        if (res) {
          return false
        } else {
          if (!this.sendCoding) {
            this.$message('正在获取验证码')
            this.sendCoding = true
            this.$message({
              type: 'success',
              message: '验证码已发送，请注意查收短信'
            })
            // 开始计时
            var stime = this.smsTimes
            var dtime = setInterval(() => {
              stime--
              if (stime === 0) {
                this.sendCoding = false
                clearInterval(dtime)
                this.getCodeText = '获取验证码'
              } else {
                this.getCodeText = stime + ' 秒后重试'
              }
            }, 1000)
          } else {
            // this.$message('稍候再试')
            return false
          }
        }
      })
    },
    getCode() {
      // 验证手机号
      this.$refs['resetPwdForm'].validateField('phone', res => {
        if (res) {
          return false
        } else {
          if (!this.sendCoding) {
            this.showVerify = true
            setTimeout(() => {
              this.$refs.verify.show()
            }, 500)
          }
        }
      })
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect' && cur !== 'authority') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    submit() {
      var that = this

      this.$refs.resetPwdForm.validate(valid => {
        if (valid) {
          this.loading = true
          var data = deepClone(this.resetPwdForm)
          data.area_code = this.resetPwdForm.area_code === '+86' ? '86' : this.resetPwdForm.area_code.replace(/[^0-9]/gi, '')
          delete data.password2
          resetPwd(data)
            .then(res => {
              this.$message({
                type: 'success',
                message: res.msg,
                onClose: function() {
                  that.$router.push({
                    path: '/'
                  })
                }
              })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          if (this.errorNum >= 2) {
            this.modals = true
          } else {
            this.modals = false
          }
        }
      })
    },
    area_code(val) {
      this.resetPwdForm.area_code = val
    }
  }
}
</script>

<style lang="scss">
.reset-container {
  .reset-form {
    .el-input {
      input {
        width: 100%;
        height: 42px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        border-color: transparency;
        border: 1px;
        /* border: 1px solid #dae0e6; */
        /* border-radius: 4px; */
        padding: 0 20px;
        line-height: 42px;
        outline: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.reset-container {
  background: #f5f6f9 !important;
  height: inherit;
  // ::v-deep .el-form-item__content{
  //   overflow:hidden;
  // }
  .reset-header {
    .header-logo {
      .logo-img {
        width: 220px;
      }
    }
  }
  @keyframes scalein {
    from {
      -webkit-transform: scale(0.4);
      transform: scale(0.4);
    }
    to {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .reset-box {
    -webkit-box-shadow: 0px 2px 14px 0px rgb(0 0 0 / 6%);
    box-shadow: 0px 2px 14px 0px rgb(0 0 0 / 6%);
    -webkit-animation: scalein 0.3s;
    animation: scalein 0.3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 18px;
    overflow: hidden;
    height: 540px;
    .box-left {
      width: 480px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        display: block;
      }
    }
    .box-right {
      ::v-deep {
        /*** 消除input元素 type="number" 时默认的 加减按钮*/
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
      background: #ffffff;
      width: 420px;
      padding: 40px;
      height: 100%;
      .reset-logo {
        .reset-title {
          font-size: 38px;
          margin: 10px 0;
          text-align: center;
        }
        img {
          width: 100%;
        }
      }
      .input-item {
        // margin:10px 0;
        input {
          width: 100%;
          height: 42px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          border: 1px solid #dae0e6;
          border-radius: 4px;
          padding: 0 20px;
          line-height: 42px;
          outline: none;
        }
      }
      .input-register {
        margin: 10px 0;
      }
      .submit {
        &:hover {
          background: linear-gradient(11deg, #01cbca, #1892aa);
        }
        display: block;
        height: 42px;
        background: linear-gradient(145deg, #01cbca, #1892aa);
        border-radius: 4px;
        margin: 0 auto;
        text-align: center;
        // line-height: 42px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        width: 100%;
        border: unset;
      }
      .submits {
        &:hover {
          background: #fff;
        }
        background: #fff;
        color: #1892aa;
        border: 1px solid #1892aa;
      }
      .get-code {
        color: #fff;
        position: absolute;
        right: 1px;
        top: 0px;
        cursor: pointer;
        user-select: none;
        background: #1892aa;
        padding: 4px 10px;
        border-radius: 4px;
        &:hover {
          background: #01cbca;
        }
      }
      .get-code.disabled {
        color: #9f9f9f;
        background: #d1f0f0;
      }
      .foot-btn {
        .btn_text {
          &:hover {
            color: #ffac5e;
          }
          cursor: pointer;
          font-size: 14px;
          // margin-bottom: 10px;

          span {
            &:first-of-type {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }

  footer {
    text-align: center;
    margin: 100px 20px 0 20px;
  }
}
@media screen and (max-width: 900px) {
  .box-left {
    display: none;
  }
  .header-logo {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .reset-box {
    box-shadow: unset !important;
    border-radius: unset;
  }
  .reset-container {
    background: unset !important;
  }
}

img {
  -webkit-user-drag: none;
}

.center {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-required {
  &:after {
    content: '*';
    color: red;
    line-height: 46px;
  }
  position: absolute;
  left: 8px;
  top: 2px;
  bottom: 0;
  vertical-align: middle;
  z-index: 99;
}

.two {
  .logout {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #909399;
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;
  }
  .logout:hover {
    color: #ff8719;
  }
  .venues_logo {
    width: 60px;
    height: 60px;
    // border: 1px solid #e2e2e2;
    border-radius: 6px;
  }
  .venues_selector {
    padding: 10px 20px;
  }

  .venues_name {
    padding-left: 20px;
  }

  .venues_app {
    padding-bottom: 10px;
  }

  .venues_app_management {
    background: #ff8719;
    color: #fff;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
  }
  .venues_list {
    .venues_list_empty {
      padding-top: 30px;
      font-size: 15px;
      text-align: center;
      // color:#f1c40f;
    }
    ul {
      list-style: none;
      padding: 0;
    }
    li {
      span {
        cursor: pointer;
        background: #41c3a8;
        color: #fff;
        display: inline-block;
        width: 213px;
        height: 48px;
        border-radius: 4px;
        border: 1px solid #41c3a8;
        line-height: 48px;
      }
      float: left;
      margin: 0;
      margin-right: 10px;
      margin-bottom: 15px;
      text-align: center;
    }
  }
}
.fix {
  height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid #dae0e6;
  border-radius: 4px;
  .CodeList {
    width: 100px;
    background: transparent;
    border-right: none;
    .el-input {
      .el-input__inner {
        border: none !important;
      }
    }
  }
}
</style>
