var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox",
        {
          attrs: { indeterminate: _vm.isIndeterminate },
          model: {
            value: _vm.checkAll,
            callback: function($$v) {
              _vm.checkAll = $$v
            },
            expression: "checkAll"
          }
        },
        [_vm._v("全选")]
      ),
      _c(
        "el-checkbox-group",
        {
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        _vm._l(_vm.list, function(item, value) {
          return _c("el-checkbox", { key: value, attrs: { label: item.id } }, [
            item.role_name
              ? _c("span", [_vm._v(_vm._s(item.role_name))])
              : _vm._e()
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }