var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      attrs: { "tab-position": "top" },
      model: {
        value: _vm.activeNameS,
        callback: function($$v) {
          _vm.activeNameS = $$v
        },
        expression: "activeNameS"
      }
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "公众号信息", name: "publicAccountInformation" } },
        [
          _vm.activeNameS == "publicAccountInformation"
            ? _c("publicAccountInformation", { on: { toPage: _vm.toPage } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "公众号配置", name: "PublicNumberConfiguration" } },
        [
          _vm.activeNameS == "PublicNumberConfiguration"
            ? _c("PublicNumberConfiguration")
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "公众号菜单", name: "publicAccountAllocation" } },
        [
          _vm.activeNameS == "publicAccountAllocation"
            ? _c("publicAccountAllocation")
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }