// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/active_bg4.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".course_list .venuesList_empty[data-v-7dbe1de1] {\n  min-height: 400px;\n}\n.course_list .venuesList_empty img[data-v-7dbe1de1] {\n  -webkit-user-drag: none;\n}\n.course_list .button[data-v-7dbe1de1] {\n  padding: 0;\n}\n.course_list .course_item[data-v-7dbe1de1] {\n  cursor: pointer;\n}\n.course_list .course_item .disabled[data-v-7dbe1de1] {\n  background-color: #eee;\n  color: #82848a;\n}\n.course_list .course_item .course_card_text[data-v-7dbe1de1] {\n  padding: 8px;\n  min-height: 170px;\n}\n.course_list .course_item .time[data-v-7dbe1de1] {\n  font-size: 13px;\n  color: #999;\n}\n.course_list .course_item .course_color[data-v-7dbe1de1] {\n  width: 30px;\n  height: 30px;\n  position: absolute;\n  right: 20px;\n  top: 20px;\n}\n.course_list .course_card[data-v-7dbe1de1] {\n  margin-bottom: 10px;\n  background-origin: no-repeat;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 0% 200%;\n}\n.course_list .active[data-v-7dbe1de1] {\n  z-index: 999;\n  border: 1px solid #c1c1c1;\n  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);\n          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);\n  background-position: 80% 140%;\n}", ""]);
// Exports
module.exports = exports;
