var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.name === "add"
            ? "新增班课"
            : _vm.name === "check"
            ? "查看详情"
            : "编辑班课",
        visible: _vm.dialog,
        width: "30%",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialog = $event
        },
        open: _vm.dialogOpen,
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "ruleForm",
          attrs: { rules: _vm.rules, model: _vm.form, "label-width": "80px" }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "line-clear",
              attrs: { label: "课程名称", prop: "name" }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入课程名称",
                  disabled: _vm.name === "check" ? true : false
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "line-clear", attrs: { label: "课程类型" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "小班课", disabled: true }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "line-clear",
                      attrs: { label: "课程时长", prop: "duration" }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            disabled: _vm.name === "check" ? true : false,
                            type: "number",
                            placeholder: "输入课程时长"
                          },
                          model: {
                            value: _vm.form.duration,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "duration", $$v)
                            },
                            expression: "form.duration"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("分钟")])],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "教练", prop: "store_user_id" } },
                    [
                      _c("Select", {
                        ref: "coachRef",
                        attrs: {
                          type: 4,
                          width: 180,
                          disabled: _vm.name === "check" ? true : false
                        },
                        on: { changeId: _vm.handleCoachID }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "教室", prop: "classroom_id" } },
                    [
                      _c("Select", {
                        ref: "classroomRef",
                        attrs: {
                          type: 8,
                          width: 180,
                          disabled: _vm.name === "check" ? true : false
                        },
                        on: { changeId: _vm.handleClassroomID }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _vm.name !== "check"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleYes("ruleForm")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.handleToPage("ruleForm")
                }
              }
            },
            [_vm._v("返 回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }