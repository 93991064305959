var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container flex row-center" }, [
    _c("div", { staticClass: "filter-container" }, [
      _c("div", { staticStyle: { width: "500px", margin: "0 auto" } }, [
        _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: _vm.empty_pause_img, alt: "" }
        })
      ]),
      _c(
        "div",
        {
          staticClass: "font-22 m-t-10",
          staticStyle: { "text-align": "center", color: "#09AEB7" }
        },
        [_vm._v(_vm._s(_vm.msg))]
      ),
      _c(
        "div",
        {
          staticClass: "font-16 m-t-10",
          staticStyle: { "text-align": "center", color: "color: #999999" }
        },
        [_vm._v("您可以通过切换其他品牌或联系客服解决问题")]
      ),
      _c("div", { staticStyle: { width: "120px", margin: "10px auto 0px" } }, [
        _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: _vm.wx_qrcode_img, alt: "" }
        })
      ]),
      _c(
        "div",
        {
          staticClass: "font-16 m-t-10",
          staticStyle: { "text-align": "center", color: "#999999" }
        },
        [
          _c("svg-icon", { attrs: { "icon-class": "phone" } }),
          _vm._v(" " + _vm._s(_vm.callNumber))
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex row-center m-t-20" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleToPath } },
            [_vm._v("去升级套餐")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }