var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "searchBox" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.listQueryParams,
                  "label-width": "110px",
                  "label-position": "left",
                  "label-suffix": "："
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "邀请者品牌" } },
                  [
                    _c("Select", {
                      attrs: { type: 1 },
                      on: { changeId: _vm.cardIdI }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "被邀请者品牌" } },
                  [
                    _c("Select", {
                      attrs: { type: 1 },
                      on: { changeId: _vm.cardIdC }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "返现时间" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "picker-options": _vm.dateShortcutChooser
                      },
                      model: {
                        value: _vm.listQueryParams.cashback_time,
                        callback: function($$v) {
                          _vm.$set(_vm.listQueryParams, "cashback_time", $$v)
                        },
                        expression: "listQueryParams.cashback_time"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "邀请类型" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择邀请类型", clearable: "" },
                        model: {
                          value: _vm.listQueryParams.type,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "type", $$v)
                          },
                          expression: "listQueryParams.type"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "链接邀请", value: 1 }
                        }),
                        _c("el-option", {
                          attrs: { label: "二维码邀请", value: 2 }
                        }),
                        _c("el-option", {
                          attrs: { label: "邀请码", value: 0 }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择状态", clearable: "" },
                        model: {
                          value: _vm.listQueryParams.is_complete,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "is_complete", $$v)
                          },
                          expression: "listQueryParams.is_complete"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "已完成", value: 1 }
                        }),
                        _c("el-option", {
                          attrs: { label: "未完成", value: 0 }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "线上/线下" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择状态", clearable: "" },
                        model: {
                          value: _vm.listQueryParams.is_offline,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "is_offline", $$v)
                          },
                          expression: "listQueryParams.is_offline"
                        }
                      },
                      [
                        _c("el-option", { attrs: { label: "线上", value: 0 } }),
                        _c("el-option", { attrs: { label: "线下", value: 1 } })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "m-b-10",
                attrs: {
                  icon: "el-icon-search",
                  loading: _vm.searchLoading,
                  type: "success"
                },
                on: { click: _vm.handleFilter }
              },
              [_vm._v(" 搜索 ")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("activity_invite_add"),
                  expression: "btn_access('activity_invite_add')"
                },
                { name: "waves", rawName: "v-waves" }
              ],
              staticClass: "filter-item m-r-10",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleOperation }
            },
            [_vm._v(" 添加 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { stripe: "", data: _vm.tableData.list }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "app", label: "邀请者品牌信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.store_user.app
                      ? _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            row.store_user.app.logo
                              ? _c("el-avatar", {
                                  staticClass: "m-r-6",
                                  attrs: {
                                    shape: "square",
                                    src: row.store_user.app.logo
                                  }
                                })
                              : _vm._e(),
                            _c("span", [
                              _vm._v(_vm._s(row.store_user.app.name))
                            ])
                          ],
                          1
                        )
                      : _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v("品牌信息丢失")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "app", label: "邀请者人员信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-avatar",
                          {
                            staticClass: "m-r-6",
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: { src: row.store_user.avatar }
                          },
                          [_c("img", { attrs: { src: _vm.errorMemberSrc() } })]
                        ),
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(row.store_user.real_name))]),
                          _c("div", [_vm._v(_vm._s(row.store_user.phone))])
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "app", label: "受邀请者品牌信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.invited_app
                      ? _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            row.invited_app.logo
                              ? _c("el-avatar", {
                                  staticClass: "m-r-6",
                                  attrs: {
                                    shape: "square",
                                    src: row.invited_app.logo
                                  }
                                })
                              : _vm._e(),
                            _c("span", [_vm._v(_vm._s(row.invited_app.name))])
                          ],
                          1
                        )
                      : _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v("品牌信息丢失")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "app", label: "受邀请者人员信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.invited_store_user
                      ? _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-avatar",
                              {
                                staticClass: "m-r-6",
                                staticStyle: { width: "40px", height: "40px" },
                                attrs: { src: row.invited_store_user.avatar }
                              },
                              [
                                _c("img", {
                                  attrs: { src: _vm.errorMemberSrc() }
                                })
                              ]
                            ),
                            _c("div", [
                              _c("div", [
                                _vm._v(_vm._s(row.invited_store_user.real_name))
                              ]),
                              _c("div", [
                                _vm._v(_vm._s(row.invited_store_user.phone))
                              ])
                            ])
                          ],
                          1
                        )
                      : _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v("用户信息丢失")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "邀请类型", "min-width": "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.type === 0
                      ? _c("el-tag", [_vm._v("邀请码")])
                      : _vm._e(),
                    row.type === 1
                      ? _c("el-tag", [_vm._v("链接邀请")])
                      : _vm._e(),
                    row.type === 2
                      ? _c("el-tag", [_vm._v("二维码邀请")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "cashback", label: "返现金额", "min-width": "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(row.cashback) + " 元")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "cashback", label: "返现凭证" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.proof
                      ? _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: row.proof ? row.proof[0] : "",
                            "preview-src-list": row.proof
                          }
                        })
                      : _c("div", [_vm._v("暂无凭证")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "is_complete", label: "状态", "min-width": "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticClass: "m-2",
                        attrs: { type: row.is_complete ? "" : "danger" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.is_complete ? "已完成" : "未完成") +
                            " "
                        )
                      ]
                    ),
                    _c("el-tag", { staticClass: "m-2" }, [
                      _vm._v(_vm._s(row.is_offline ? "线下" : "线上"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cashback_time",
              label: "返现时间",
              "min-width": "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(_vm._s(row.is_complete ? row.cashback_time : "---"))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    !row.is_offline &&
                    !row.is_complete &&
                    _vm.btn_access("activity_invite_complete")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: {
                              type: "primary",
                              icon: "el-icon-circle-check"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleWriteOff(row.id)
                              }
                            }
                          },
                          [_vm._v(" 核销 ")]
                        )
                      : _vm._e(),
                    row.is_offline && !row.is_complete
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确定通过该记录吗？" },
                            on: {
                              confirm: function($event) {
                                return _vm.handleAudit(row.id)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "m-2",
                                attrs: {
                                  slot: "reference",
                                  type: "primary",
                                  icon: "el-icon-circle-check"
                                },
                                slot: "reference"
                              },
                              [_vm._v("通过审核")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("activity_invite_del"),
                            expression: "btn_access('activity_invite_del')"
                          }
                        ],
                        attrs: { title: "确定删除该记录吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDelete(row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删 除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: { visible: _vm.dialogVisible },
            on: { close: _vm.close }
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择核销的套餐",
            visible: _vm.writeDialogVisible,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.writeDialogVisible = $event
            },
            open: _vm.handleOpen,
            close: _vm.handleClose
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.writeLoading,
                  expression: "writeLoading"
                }
              ],
              staticClass: "flex flex-wrap"
            },
            _vm._l(_vm.writeDataList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "p-y-8 p-x-18 m-6 cursor",
                  staticStyle: { "border-radius": "8px" },
                  style: {
                    border:
                      _vm.selectId === item.id
                        ? "2px solid #01cbca"
                        : "2px solid #dfe6ec"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleClick(item.id)
                    }
                  }
                },
                [
                  _c("span", [_vm._v(_vm._s(item.name))]),
                  _vm._v(" - "),
                  _c("span", { staticClass: "text-info" }, [
                    _vm._v(_vm._s(_vm.packageVersion[item.brand_level]))
                  ])
                ]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.writeDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleWriteConfirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }