var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleAccount,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "30%",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleAccount = $event
            },
            open: _vm.handleOpen,
            close: function($event) {
              return _vm.handleClose("ruleForm")
            }
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("div", { staticClass: "title" }, [
                _c("div", { staticClass: "title_title" }, [
                  _vm._v(_vm._s(_vm.type === "add" ? "新增角色" : "编辑角色"))
                ]),
                _c(
                  "div",
                  {
                    staticClass: "title_icon cursor",
                    on: {
                      click: function($event) {
                        return _vm.handleClose("ruleForm")
                      }
                    }
                  },
                  [_c("i", { staticClass: "el-icon-close" })]
                )
              ]),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading"
                    }
                  ],
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm m-t-40",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单路由" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.handleAllocation }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ruleForm.menu_route_ids.length > 0
                                  ? "更改菜单路由"
                                  : "配置菜单路由"
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading },
                  on: {
                    click: function($event) {
                      return _vm.handleClose("ruleForm")
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading, type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.menuRout,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "30%",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.menuRout = $event
            }
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "title_title" }, [
                _vm._v("配置菜单路由")
              ]),
              _c(
                "div",
                {
                  staticClass: "title_icon cursor",
                  on: { click: _vm.handleMenuRout }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "tree-height" },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.menuList,
                  "show-checkbox": true,
                  "node-key": "id",
                  "highlight-current": "",
                  "expand-on-click-node": false,
                  props: _vm.defaultProps,
                  "check-strictly": true,
                  "default-checked-keys": _vm.checked_keys
                },
                on: { check: _vm.nodeClick }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading },
                  on: { click: _vm.handleMenuRout }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading, type: "primary" },
                  on: { click: _vm.submitMenuRout }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }