<template>
  <div>
    <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" class="m-b-10">全选</el-checkbox>
    <el-checkbox-group v-model="selected">
      <el-row :gutter="20">
        <el-col v-for="item in list" :key="item.id" class="m-b-10" :span="8">
          <!-- <el-checkbox :label="item.id">{{ item.name.length > 14 ? item.name.slice(0, 14) + '...' : item.name }}（{{ card_type[item.type] }}）</el-checkbox> -->
          <el-checkbox :label="item.id">{{ item.name.length > 14 ? item.name.slice(0, 16) + '...' : item.name }}</el-checkbox>
        </el-col>
      </el-row>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    // 选中列表
    parentSelected: {
      type: Array,
      default() {
        return []
      }
    },
    // 全部列表
    list: {
      type: [Array, Object],
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    checkAll: {
      get() {
        return this.parentSelected.length === this.list.length
      },
      set(val) {
        const list = []
        this.list.forEach(item => {
          list.push(item.id)
        })
        this.$emit('update:parentSelected', val ? list : [])
      }
    },
    selected: {
      get() {
        return this.parentSelected
      },
      set(v) {
        this.$emit('update:parentSelected', v)
      }
    },
    isIndeterminate: {
      get() {
        const checkedCount = this.parentSelected.length
        return checkedCount > 0 && checkedCount < this.list.length
      }
    }
  },
  watch: {
    parentSelected: function(v, ov) {
    }
  },
  mounted() {
  },
  methods: {
    inputChange(val) {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
	.input {
		width: 140px;
		margin: 0 10px;
	}
	.el-checkbox ::v-deep .el-checkbox__label{
		color: #606266 !important;
	}

</style>
