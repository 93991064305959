<template>
  <!-- 班课数据统计 -->
  <div class="app-container">
    <el-radio-group v-model="type" @change="handleChange">
      <el-radio-button :label="1">收费明细</el-radio-button>
      <el-radio-button :label="2">退费明细</el-radio-button>
    </el-radio-group>
    <!-- 搜索 -->
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="left">
        <!-- 选择班级 -->
        <el-form-item label="选择班级：">
          <el-select v-model="listQueryParams.class_id" placeholder="请选选择班级" clearable>
            <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <!-- 报班时间 -->
        <el-form-item label="时间：">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            align="right"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <!-- 销售状态 -->
        <el-form-item v-if="type === 1" label="销售状态：">
          <el-select v-model="listQueryParams.operator_type" placeholder="销售状态" clearable>
            <el-option label="全部" value="" />
            <el-option label="报班" value="bb" />
            <el-option label="转班" value="zb" />
            <el-option label="续班" value="xb" />
            <el-option label="分课" value="fk" />
          </el-select>
        </el-form-item>
        <!-- 支付方式 -->
        <el-form-item label="支付方式">
          <el-select v-model="listQueryParams.charge_type" placeholder="请选支付方式" clearable>
            <el-option v-for="(item, index) in payment_method" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </el-form>
    </div>
    <div class="total m-y-20 font-16">
      {{ type === 1 ? '收费统计' : '退费统计' }}
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="money" :duration="2000" :decimals="0" />
      </span>
    </div>
    <!-- 列表 -->
    <div class="tableColumn">
      <el-table v-loading="loading" :data="tableData.list" border style="width: 100%" :stripe="true">
        <el-table-column label="时间" prop="update_time" align="center" />
        <el-table-column label="会员" align="center">
          <template slot-scope="scope">
            <div class="user">
              <span>{{ scope.row.user.nickname ? scope.row.user.nickname : '瑜小九' }}</span>
              <span v-if="scope.row.user.phone">：{{ scope.row.user.phone }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="班级" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.class ? scope.row.class.name : '瑜小九' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类型" align="center">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.operator_type_text }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="type === 1 ? '收入' : '支出'" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.charge_money }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
// API
import { getDataList, getMclassData } from '@/api/store/course'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import CountTo from 'vue-count-to'

export default {
  components: { Pagination, CountTo },
  data() {
    return {
      type: 1,
      classList: [],
      searchLoading: false, // 搜索按钮加载
      loading: false, // 表格加载
      tableData: {
        list: [],
        count: 0
      }, // 数据
      money: 0
    }
  },
  watch: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList() {
      this.loading = true
      this.money = 0

      getDataList().then(res => {
        this.classList = res.data.list
      })

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.q) querys.push({ field: 'q', key: this.listQueryParams.q })
      if (this.listQueryParams.class_id) querys.push({ field: 'class_id', key: this.listQueryParams.class_id })
      if (this.listQueryParams.operator_type) querys.push({ field: 'operator_type', key: this.listQueryParams.operator_type })
      if (this.listQueryParams.charge_type) querys.push({ field: 'charge_type', key: this.listQueryParams.charge_type })

      data.query = this.utils.getQueryParams(querys)

      getMclassData(this.type, data).then(res => {
        const data = res.data
        data.list.forEach(item => {
          this.money += item.charge_money
        })
        this.tableData = data
        this.loading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleChange() {
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.tableColumn {
  margin-top: 20px;
}
.user {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
