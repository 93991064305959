<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button v-show="btn_access('s_venues_classroom_add')" type="primary" icon="el-icon-plus" @click="dialogVisible()">添加</el-button>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData.list"
      row-key="id"
    >
      <!-- <el-table-column prop="id" label="ID" width="180" /> -->
      <el-table-column prop="name" label="名称" min-width="150px" />
      <el-table-column prop="galleryful" label="容纳人数" min-width="150px" />
      <el-table-column prop="create_time" label="创建时间" width="200" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('s_venues_classroom_edit')" class="m-2" type="primary" icon="el-icon-edit" @click="dialogVisible(scope.row.id)">编辑</el-button>
          <el-popconfirm
            title="确定删除该教室吗？"
            @confirm="classroom_delete(scope.row.id)"
          >
            <el-button v-show="btn_access('s_venues_classroom_delete')" slot="reference" class="m-2" type="danger" icon="el-icon-delete">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :before-close="cancelDialog"
      :visible.sync="dialog.visible"
      :title="dialog.id === null ? '添加教室':'编辑教室'"
      class="dialog_w580"
    >
      <el-form ref="ruleForm" :inline="true" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="教室名称" prop="name">
          <el-input v-model="form.name" placeholder="教室名称" class="dialog_w50" />
        </el-form-item>
        <el-form-item label="容纳人数" prop="galleryful">
          <el-input v-model="form.galleryful" type="number" placeholder="容纳人数" class="dialog_w50" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button type="text" @click="cancelDialog">取消</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { getClassroom, addClassroom, getClassroomDetails, editClassroom, delClassroom } from '@/api/store/venues.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  components: { Pagination },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 数据
      form: {},
      // 弹窗
      dialog: {
        visible: false,
        id: null
      },
      rules: {
        name: [{ required: true, message: '请输入教室名称', trigger: 'blur' }],
        galleryful: [{ required: true, message: '请输入容纳人数', trigger: 'blur' }]
      },
      venues: [],
      apiData: { url: '/v1/classroom' }
    }
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    dialogVisible(id) {
      if (id) {
        this.dialog.id = id
        getClassroomDetails(id).then(res => {
          this.form = res.data
        }).catch(() => {})
      } else {
        this.dialog.id = null
        this.form = {}
      }
      this.dialog.visible = true
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.dialog.id === null) {
            const data = Object.assign(this.form, { venues_id: this.utils.getVenues().id })
            addClassroom(data).then(res => {
              this.callback('添加成功')
              this.cancelDialog()
            }).catch(() => {})
          } else {
            const data = {
              name: this.form.name,
              venues_id: this.utils.getVenues().id,
              galleryful: this.form.galleryful
            }
            editClassroom(this.dialog.id, data).then(res => {
              this.callback('修改成功')
              this.cancelDialog()
            }).catch(() => {})
          }
        }
      })
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      return getClassroom(data).then(res => {
        this.tableData = res.data
      }).catch(() => {})
    },
    /**
			 * 关闭弹窗
			 */
    cancelDialog() {
      this.$refs['ruleForm'].resetFields()
      this.dialog.visible = false
    },
    // 回调
    callback(msg) {
      this.$message.success(msg)
      this.getList()
    },
    classroom_delete(id) {
      this.$confirm('操作成功。是否将更新同步到课表/课程中？', '提示', {
        confirmButtonText: '同步',
        cancelButtonText: '不同步',
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        delClassroom(id, 1).then(res => {
          this.$message.success(res.msg)
          this.getList()
        })
      }).catch(() => {
        delClassroom(id, 0).then(res => {
          this.$message.success(res.msg)
          this.getList()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
