<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      yxj: true
    }
  },
  created() {
    // console.log('app')
    if (window.addEventListener) {
      // 所有主流浏览器，除了 IE 8 及更早版本
      window.addEventListener('message', this.messageHandle, false)
    } else if (window.attachEvent) {
      // IE 8 及更早版本
      window.attachEvent('onmessage', this.messageHandle)
    }
  },
  mounted() {
    // 监听滚轮事件
    window.addEventListener('wheel', this.handleWheelEvent, { passive: false })
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听
    window.removeEventListener('wheel', this.handleWheelEvent)
  },
  methods: {
    // 禁止用户使用Ctrl+滚轮缩放页面
    handleWheelEvent(event) {
      // 检查是否同时按下了Ctrl键
      if (event.ctrlKey) {
        // 阻止浏览器的默认缩放行为
        event.preventDefault()
        // 提示用户页面静止缩放
        if (this.yxj) {
          this.yxj = false
          this.$message({
            message: '当前页面不允许缩放噢！！！',
            type: 'warning',
            duration: 3000
          })
          setTimeout(() => {
            this.yxj = true
          }, 3000)
        }
      }
    },
    // 一键登录处理
    messageHandle(data) {
      var that = this
      // const origin = data.origin
      const p_data = data.data
      if (p_data.IframePasswordtoken) {
        var t = JSON.parse(p_data.IframePasswordtoken)
        if (t && t.token && t.refresh_token) {
          // 先执行退出
          this.$store.dispatch('user/logout').then(res => {
            // setTimeout(() => {
            that.$store
              .dispatch('user/fastLogin', t)
              .then(() => {
                console.log('$router.push')
                that.$router.push({
                  path: '/'
                })
              })
              .catch(() => {})
            // }, 100)
          })
        }
      }
    }
  }
}
</script>
<style lang="scss"></style>
