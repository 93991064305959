import request from '@/utils/request'
import Utils from '@/utils/utils.js'

/**
 * 获取公告列表
 */
export function getQrcode(data) {
  return request({
    url: `/store/v1/qr_code/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

/**
 * 刷新二维码
 * @param {object} data
 * @returns
 */
export function RefreshQrcode(data) {
  return request({
    url: `/store/v1/qr_code/venues_id/${Utils.getVenues().id}`,
    method: 'put',
    params: data
  })
}

