var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      directives: [
        {
          name: "loadmore",
          rawName: "v-loadmore",
          value: _vm.loadMore,
          expression: "loadMore"
        }
      ],
      attrs: { label: _vm.title }
    },
    [
      _c(
        "el-select",
        {
          attrs: {
            filterable: "",
            clearable: "",
            remote: "",
            "reserve-keyword": "",
            disabled: _vm.displays,
            placeholder: "可搜索昵称|姓名|手机号",
            "remote-method": _vm.selectChanged
          },
          on: { "visible-change": _vm.visibleChanged },
          model: {
            value: _vm.data_id,
            callback: function($$v) {
              _vm.data_id = $$v
            },
            expression: "data_id"
          }
        },
        [
          _vm._l(_vm.dataList, function(item, index) {
            return _c(
              "el-option",
              {
                key: index,
                attrs: { label: _vm.getUserNames(item), value: item.id }
              },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-avatar",
                      {
                        staticClass: "m-r-6",
                        staticStyle: { width: "25px", height: "25px" },
                        attrs: { src: item.avatar }
                      },
                      [_c("img", { attrs: { src: _vm.errorMemberSrc() } })]
                    ),
                    _c("div", [_vm._v(_vm._s(_vm.getUserNames(item)))]),
                    _c("div", { staticClass: "m-x-4 text-info" }, [
                      _vm._v("-")
                    ]),
                    _c("div", { class: item.phone ? "" : "text-info" }, [
                      _vm._v(_vm._s(item.phone ? item.phone : "---"))
                    ])
                  ],
                  1
                )
              ]
            )
          }),
          _vm.is_hide === 0
            ? _c("div", { staticClass: "jzz" }, [
                _vm.is_Yes === 1
                  ? _c("i", { staticClass: "el-icon-loading" })
                  : _vm._e(),
                _vm.is_Yes === 1
                  ? _c("span", { staticClass: "jzz_span1" }, [
                      _vm._v("玩命加载中")
                    ])
                  : _vm._e(),
                _vm.is_Yes === 0
                  ? _c("span", { staticClass: "jzz_span2" }, [
                      _vm._v("----- 我也是有底线的 -----")
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }