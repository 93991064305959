<template>
  <div>
    <el-card v-loading="incomeLoading" class="m-t-20" element-loading-text="玩命加载中" shadow="hover">
      <div slot="header">
        <div class="flex row-between">
          <div class="flex">
            <div class="font-18">耗卡金额统计</div>
            <div class="w-20 h-20 m-l-4 m-t-2 cursor">
              <img style="width: 100%; height: 100%;" src="@/assets/images/asset_statistics/doubt.png" alt="">
            </div>
            <div class="font-18 m-l-40">
              耗卡总额（元）
              <span class="font-18">¥ {{ data.consume_sum }}</span>
            </div>
          </div>
          <div class="flex">
            <div class="flex m-l-20">
              <div class="font-14">根据：</div>
              <el-select v-model="listQueryParams.unit" class="w-80" @change="handleSelectChange">
                <el-option label="年" value="year" />
                <el-option label="月" value="month" />
                <el-option label="天" value="day" />
              </el-select>
              <div class="font-14"><span class="m-x-4">数</span>进行日期：</div>
              <el-date-picker
                v-model="listQueryParams.start_time"
                :type="pickerType.type"
                placeholder="选择日期"
                :format="pickerType.format"
                :value-format="pickerType.format"
                :picker-options="pickerOptions"
              />
              <div class="m-x-10">至</div>
              <el-date-picker
                v-model="listQueryParams.end_time"
                :type="pickerType.type"
                placeholder="选择日期"
                :format="pickerType.format"
                :value-format="pickerType.format"
                :picker-options="pickerOptions"
              />
            </div>
            <el-button :loading="searchLoading" type="success" class="filter-item m-l-20" @click="handleFilter">
              筛选
            </el-button>
          </div>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <div id="cardConsumption" style="width: 100%; height: 400px;" />
        </el-col>
        <el-col :span="12">
          <el-table :data="data.list" height="400" style="width: 100%;">
            <el-table-column prop="period" label="日期" fixed="left" />
            <el-table-column label="耗卡金额">
              <template slot-scope="{ row }">
                <div class="text-warning">{{ row.total_consume }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="subscribe_consume" label="预约耗卡" />
            <el-table-column prop="time_consume" label="期限卡耗卡" />
            <el-table-column prop="other_consume" label="其他耗卡" />
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// API
import { getExpendStatisticsList } from '@/api/store/assetStatistics.js'
// 方法函数
import { timestampToTime } from '@/utils/takeleave'
export default {
  data() {
    return {
      data: {
        consume_sum: 0, // 收入总额
        list: []
      },
      listQueryParams: {
        unit: 'day',
        start_time: '',
        end_time: ''
      },
      timeData: '',
      incomeLoading: false, // 收入统计加载
      searchLoading: false,
      pickerType: {
        type: 'date',
        format: 'yyyy-MM-dd'
      },
      // 禁止选择当日之前的日期
      pickerOptions: {
        disabledDate: time => {
          // 禁用日期
          let nowData = new Date()
          nowData = new Date(nowData.setDate(nowData.getDate()))
          return time > nowData
        }
      },
      optionConsume: {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '10%', // 调整左边距
          right: '10%', // 调整右边距
          top: '10%', // 调整上边距
          bottom: '10%' // 调整下边距
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLine: {
            lineStyle: {
              type: 'dashed', // 设置X轴的线为虚线
              color: '#CCCCCC' // 设置X轴的线为灰色虚线
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed', // 设置X轴的网格线为虚线
              color: '#CCCCCC' // 设置X轴的线为灰色虚线
            }
          },
          axisLabel: {
            color: '#333333' // 设置X轴的文字颜色为黑色
          }
        },
        yAxis: {
          type: 'value',
          splitNumber: 5, // 保证最多显示五个刻度
          splitLine: {
            lineStyle: {
              type: 'dashed', // 设置背景网格线为虚线
              color: '#CCCCCC' // 设置X轴的线为灰色虚线
            }
          }
        },
        series: [
          {
            data: [],
            type: 'line',
            lineStyle: {
              color: '#01CBCA' // 设置折线颜色
            },
            showSymbol: false, // 不显示节点
            // 隐藏折点
            symbol: 'none'
          }
        ]
      } // echarts
    }
  },
  created() {},
  mounted() {
    this.getDate()
    this.getList()
  },
  methods: {
    getList() {
      this.incomeLoading = true
      const data = {
        ...this.listQueryParams
      }
      getExpendStatisticsList(data)
        .then(res => {
          this.data = res.data
          const list = res.data.list
          if (list.length > 0) {
            const xData = []
            const yData = []
            list.forEach(item => {
              xData.push(item.period)
              yData.push(parseFloat(item.total_consume.replace(/,/g, '')))
            })
            this.optionConsume.xAxis.data = xData.reverse()
            this.optionConsume.series[0].data = yData.reverse()
          }
          const myChart = this.$echarts.init(document.getElementById('cardConsumption'))
          myChart.setOption(this.optionConsume)
        })
        .catch(err => {
          if (err.status === 701) this.$message.error(err.msg)
        })
        .finally(() => {
          this.incomeLoading = false
          this.searchLoading = false
        })
    },
    // 获取当前时间
    getDate() {
      const startDate = this.listQueryParams.unit === 'day'
        ? new Date().getTime() - 3600 * 1000 * 24 * 89
        : this.listQueryParams.unit === 'month'
          ? new Date().getTime() - 3600 * 1000 * 24 * 180
          : new Date().getTime() - 3600 * 1000 * 24 * 1440
      const endDate = new Date().getTime()
      this.listQueryParams.start_time = timestampToTime(startDate)
      this.listQueryParams.end_time = timestampToTime(endDate)
    },
    handleFilter() {
      this.searchLoading = true
      this.getList()
    },
    handleSelectChange() {
      switch (this.listQueryParams.unit) {
        case 'day':
          this.pickerType = {
            type: 'date',
            format: 'yyyy-MM-dd'
          }
          break
        case 'month':
          this.pickerType = {
            type: 'month',
            format: 'yyyy-MM'
          }
          break
        case 'year':
          this.pickerType = {
            type: 'year',
            format: 'yyyy'
          }
          break
        default:
          break
      }
      this.getDate()
    }
  }
}
</script>

<style lang="scss" scoped></style>
