<template>
  <div>
    <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate">全选</el-checkbox>
    <el-checkbox-group v-model="selected">
      <el-checkbox v-for="(item,value) in list" :key="value" :label="item.id">
        <span v-if="item.role_name">{{ item.role_name }}</span>
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    // 选中列表
    parentSelected: {
      type: Array,
      default() {
        return []
      }
    },
    // 全部列表
    list: {
      type: [Array, Object],
      default() {
        return {}
      }
    },
    // 类型
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
    }
  },
  computed: {
    checkAll: {
      get() {
        return this.parentSelected.length === this.list.length
      },
      set(val) {
        var t = []
        this.list.forEach((item, v) => {
          t.push(item.id)
        })
        this.$emit('update:parentSelected', val ? t : [])
      }
    },
    selected: {
      get() {
        return this.parentSelected
      },
      set(v) {
        this.$emit('update:parentSelected', v)
      }
    },
    isIndeterminate: {
      get() {
        const checkedCount = this.parentSelected.length
        return checkedCount > 0 && checkedCount < this.list.length
      }
    }
  },
  watch: {
    parentSelected: function(v, ov) {
    }
  },
  mounted() {
  },
  methods: {
    inputChange(val) {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
	.input {
		width: 140px;
		margin: 0 10px;
	}
	.el-checkbox ::v-deep .el-checkbox__label{
		color: #606266 !important;
	}

</style>
