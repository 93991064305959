<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleAccount"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30%"
      :show-close="false"
      @open="handleOpen"
      @close="handleClose('ruleForm')"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">{{ type === 'add' ? '新增角色' : '编辑角色' }}</div>
          <div class="title_icon cursor" @click="handleClose('ruleForm')">
            <i class="el-icon-close" />
          </div>
        </div>
        <el-form
          ref="ruleForm"
          v-loading="formLoading"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm m-t-40"
        >
          <el-form-item label="角色名称" prop="name">
            <el-input v-model="ruleForm.name" clearable />
          </el-form-item>
          <el-form-item label="菜单路由">
            <el-button type="success" @click="handleAllocation">
              {{ ruleForm.menu_route_ids.length > 0 ? '更改菜单路由' : '配置菜单路由' }}
            </el-button>
            <!-- <el-select v-model="ruleForm.menu_route_ids" style="width: 100%;" multiple placeholder="请选择">
              <el-option v-for="item in menuList" :key="item.id" :label="item.title" :value="item.id" />
            </el-select> -->
          </el-form-item>
          <!-- <el-form-item label="超级管理员">
            <el-switch v-model="ruleForm.is_super" :active-value="1" :inactive-value="0" />
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="buttonLoading" @click="handleClose('ruleForm')">取 消</el-button>
        <el-button :loading="buttonLoading" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="menuRout"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30%"
      :show-close="false"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">配置菜单路由</div>
          <div class="title_icon cursor" @click="handleMenuRout">
            <i class="el-icon-close" />
          </div>
        </div>
      </div>
      <div class="tree-height">
        <el-tree
          ref="tree"
          :data="menuList"
          :show-checkbox="true"
          node-key="id"
          highlight-current
          :expand-on-click-node="false"
          :props="defaultProps"
          :check-strictly="true"
          :default-checked-keys="checked_keys"
          @check="nodeClick"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="buttonLoading" @click="handleMenuRout">取 消</el-button>
        <el-button :loading="buttonLoading" type="primary" @click="submitMenuRout">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { addRole, getRoleDetail, editRole, getMenuList } from '@/api/admin/account.js'
// 函数
import { deepClone } from '@/utils'

export default {
  props: {
    dialogVisibleAccount: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    typeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: {
        name: '',
        menu_route_ids: []
      },
      rules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }]
      },
      buttonLoading: false,
      formLoading: false,
      menuList: [],
      menuRout: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      checked_keys: []
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.getMenuList()
      if (this.type === 'edit') this.getDetails()
    },
    // 关闭的回调
    handleClose(formName) {
      this.$emit('roleVisible')
      this.$refs[formName].resetFields()
      this.ruleForm.menu_route_ids = []
    },
    // 保存表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          const data = deepClone(this.ruleForm)
          if (this.type === 'add') {
            addRole(data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('新增成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          } else {
            editRole(this.typeId, data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('编辑成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    // 菜单列表
    getMenuList() {
      getMenuList().then(res => {
        const { data } = res
        this.menuList = data
      })
    },
    // 详情
    getDetails() {
      this.formLoading = true
      getRoleDetail(this.typeId)
        .then(res => {
          const { data } = res
          const { name, menu_route_ids } = data
          const ids = menu_route_ids.map(function(str) {
            return parseInt(str, 10)
          })
          this.ruleForm = { name, menu_route_ids: ids }
        })
        .finally(() => {
          this.formLoading = false
        })
    },
    // 配置
    handleAllocation() {
      this.checked_keys = this.ruleForm.menu_route_ids
      this.menuRout = true
    },
    // 关闭
    handleMenuRout() {
      this.$refs.tree.setCheckedKeys([])
      this.checked_keys = []
      this.menuRout = false
    },
    // 保存
    submitMenuRout() {
      this.ruleForm.menu_route_ids = this.$refs.tree.getCheckedKeys()
      this.handleMenuRout()
    },
    nodeClick(currentObj, treeStatus) {
      // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
      const selected = treeStatus.checkedKeys.indexOf(currentObj.id) // -1未选中,>=0为选中

      // 选中
      if (selected !== -1) {
        // 子节点只要被选中父节点就被选中(需要选中父节点时候调用此方法)
        // this.selectedParent(currentObj)
        // 统一处理子节点为相同的勾选状态
        this.uniteChildSame(currentObj, true)
      } else {
        // 未选中 处理子节点全部未选中
        if (currentObj.children) {
          if (currentObj.children.length !== 0) {
            this.uniteChildSame(currentObj, false)
          }
        }
      }
    },
    // 统一处理子节点为相同的勾选状态
    uniteChildSame(treeList, isSelected) {
      this.$refs.tree.setChecked(treeList.id, isSelected)
      if (treeList.children) {
        for (let i = 0; i < treeList.children.length; i++) {
          this.uniteChildSame(treeList.children[i], isSelected)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tree-height {
  height: 60vh;
  overflow-y: auto;
}
</style>
