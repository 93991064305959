import request from '@/utils/request'

/**
 * 活动记录列表
 */
export function getActivityList(params) {
  return request({
    url: '/admin/v1/activity/deposit',
    method: 'get',
    params
  })
}

/**
 * 记录详情
 */
export function getActivityDetail(id) {
  return request({
    url: `/admin/v1/activity/deposit/${id}`,
    method: 'get'
  })
}

/**
 * 新增记录
 */
export function addActivity(data) {
  return request({
    url: '/admin/v1/activity/deposit',
    method: 'post',
    data
  })
}

/**
 * 更新记录
 */
export function updateActivity(id, data) {
  return request({
    url: `/admin/v1/activity/deposit/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除记录
 */
export function deleteActivity(id) {
  return request({
    url: `/admin/v1/activity/deposit/${id}`,
    method: 'delete'
  })
}
