var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "war" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c(
            "el-col",
            {
              staticClass: "m-t-10",
              attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 }
            },
            [
              _c(
                "div",
                { staticClass: "app-container fillet p-30" },
                [
                  _c("div", { staticClass: "font-16 el-message__content" }, [
                    _vm._v(" 平台趋势 ")
                  ]),
                  _c("platformTrends", {
                    attrs: { "general-trend": _vm.workbenchData.generalTrend }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "m-t-10",
              attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 }
            },
            [
              _c(
                "div",
                {
                  staticClass: "app-container fillet p-30",
                  staticStyle: { height: "360px" }
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "font-16 el-message__content" }, [
                      _vm._v(" 年度完成计划 ")
                    ]),
                    _c("div", { staticClass: "font-14 m-l-10" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.workbenchData.yearPlan.appCount))
                      ]),
                      _vm._v(" （ "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.workbenchData.yearPlan.percentage))
                      ]),
                      _vm._v(" %）/ "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.workbenchData.yearPlan.yearNumber))
                      ])
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "m-t-20" },
                    [
                      _c("el-progress", {
                        attrs: {
                          "text-inside": "",
                          "stroke-width": 15,
                          percentage: 35,
                          color: "#7887fb"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex row-between font-16 m-t-16" },
                    [
                      _c("div", [
                        _c("div", [_vm._v("品牌总数")]),
                        _c(
                          "div",
                          { staticClass: "m-t-12 font-24 text-warning" },
                          [_vm._v(_vm._s(_vm.workbenchData.appTotal.appCount))]
                        )
                      ]),
                      _c("div", [
                        _c("div", [_vm._v("有效品牌")]),
                        _c(
                          "div",
                          { staticClass: "m-t-12 font-24 text-success" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.workbenchData.appTotal.appNotExpiredCount
                              )
                            )
                          ]
                        )
                      ]),
                      _c("div", [
                        _c("div", [_vm._v("即将到期(7天)")]),
                        _c(
                          "div",
                          { staticClass: "m-t-12 font-24 text-danger" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.workbenchData.appTotal.appWillExpireCount
                              )
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "m-t-20" }, [
                    _c("div", { staticClass: "font-16" }, [
                      _vm._v("客服客户分布图")
                    ]),
                    _c(
                      "div",
                      { staticClass: "m-t-10 font-14" },
                      _vm._l(
                        _vm.workbenchData.appTotal.customerAppTotal,
                        function(item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "flex row-between m-t-8"
                            },
                            [
                              _c("div", [_vm._v(_vm._s(item.name))]),
                              _c(
                                "div",
                                {
                                  staticClass: "p-6 fillet",
                                  staticStyle: {
                                    background: "#f0f2ff",
                                    color: "#9d9bd8"
                                  }
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.user_count))]),
                                  _vm._v(" / "),
                                  _c("span", [_vm._v(_vm._s(item.pay_count))]),
                                  _vm._v(" ( "),
                                  _c("span", [_vm._v(_vm._s(item.percentage))]),
                                  _vm._v(" %) ")
                                ]
                              )
                            ]
                          )
                        }
                      ),
                      0
                    )
                  ])
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c(
            "el-col",
            {
              staticClass: "m-t-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 }
            },
            [
              _c("div", { staticClass: "app-container fillet p-30" }, [
                _c("div", { staticClass: "font-16 el-message__content" }, [
                  _vm._v("今日注册")
                ]),
                _c("div", { staticClass: "flex row-between col-top m-t-30" }, [
                  _c("div", { staticClass: "font-14" }, [
                    _vm._v(" 待处理 "),
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(
                        _vm._s(
                          _vm.workbenchData.dayData.day_register_data
                            .pending_count
                        )
                      )
                    ]),
                    _vm._v(" 个，已通过 "),
                    _c("span", { staticClass: "text-success" }, [
                      _vm._v(
                        _vm._s(
                          _vm.workbenchData.dayData.day_register_data
                            .success_count
                        )
                      )
                    ]),
                    _vm._v(" 个, 已拒绝 "),
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        _vm._s(
                          _vm.workbenchData.dayData.day_register_data
                            .refuse_count
                        )
                      )
                    ]),
                    _vm._v(" 个 ")
                  ]),
                  _c("div", { staticClass: "font-26" }, [
                    _vm._v(
                      _vm._s(_vm.workbenchData.dayData.day_register_data.count)
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "m-t-20" },
                  [
                    _c("el-progress", {
                      attrs: {
                        "text-inside": "",
                        "stroke-width": 15,
                        percentage: 100,
                        color: "#9b70fe",
                        format: _vm.format
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "m-t-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 }
            },
            [
              _c("div", { staticClass: "app-container fillet p-30" }, [
                _c("div", { staticClass: "font-16 el-message__content" }, [
                  _vm._v("今日到期")
                ]),
                _c("div", { staticClass: "flex row-between col-top m-t-30" }, [
                  _c("div", { staticClass: "font-14" }, [
                    _vm._v(" 待跟进 "),
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        _vm._s(
                          _vm.workbenchData.dayData.day_expire_data
                            .not_followed_count
                        )
                      )
                    ]),
                    _vm._v(" 个，已跟进 "),
                    _c("span", { staticClass: "text-success" }, [
                      _vm._v(
                        _vm._s(
                          _vm.workbenchData.dayData.day_expire_data
                            .followed_count
                        )
                      )
                    ]),
                    _vm._v(" 个 ")
                  ]),
                  _c("div", { staticClass: "font-26" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchData.dayData.day_expire_data.expire_count
                      )
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "m-t-20" },
                  [
                    _c("el-progress", {
                      attrs: {
                        "text-inside": "",
                        "stroke-width": 15,
                        percentage: 100,
                        color: "#f67385",
                        format: _vm.format
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "m-t-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 }
            },
            [
              _c("div", { staticClass: "app-container fillet p-30" }, [
                _c("div", { staticClass: "font-16 el-message__content" }, [
                  _vm._v("今日成交")
                ]),
                _c("div", { staticClass: "flex row-between col-top m-t-30" }, [
                  _c("div", { staticClass: "font-14" }, [
                    _vm._v(" 新购 "),
                    _c("span", { staticClass: "text-success" }, [
                      _vm._v(
                        _vm._s(
                          _vm.workbenchData.dayData.day_clinch_data
                            .new_buy_count
                        )
                      )
                    ]),
                    _vm._v(
                      " ，续费" +
                        _vm._s(
                          _vm.workbenchData.dayData.day_clinch_data
                            .renewal_buy_count
                        ) +
                        "，短信" +
                        _vm._s(
                          _vm.workbenchData.dayData.day_clinch_data
                            .sms_buy_count
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "font-26" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchData.dayData.day_clinch_data
                          .app_clinch_count
                      )
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "m-t-20" },
                  [
                    _c("el-progress", {
                      attrs: {
                        "text-inside": "",
                        "stroke-width": 15,
                        percentage: 100,
                        color: "#6bcbc2",
                        format: _vm.format
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "m-t-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 }
            },
            [
              _c("div", { staticClass: "app-container fillet p-30" }, [
                _c("div", { staticClass: "font-16 el-message__content" }, [
                  _vm._v("今日收入")
                ]),
                _c("div", { staticClass: "flex row-between col-top m-t-30" }, [
                  _c("div", { staticClass: "font-14" }, [
                    _vm._v(" 新购 "),
                    _c("span", { staticClass: "text-success" }, [
                      _vm._v(
                        _vm._s(
                          _vm.workbenchData.dayData.day_clinch_data.new_buy_sum
                        )
                      )
                    ]),
                    _vm._v(
                      " ，续费" +
                        _vm._s(
                          _vm.workbenchData.dayData.day_clinch_data
                            .renewal_buy_sum
                        ) +
                        "，短信" +
                        _vm._s(
                          _vm.workbenchData.dayData.day_clinch_data.sms_buy_sum
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "font-26" }, [
                    _vm._v(
                      _vm._s(
                        _vm.workbenchData.dayData.day_clinch_data.clinch_sum
                      )
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "m-t-20" },
                  [
                    _c("el-progress", {
                      attrs: {
                        "text-inside": "",
                        "stroke-width": 15,
                        percentage: 100,
                        color: "#7887fc",
                        format: _vm.format
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c(
            "el-col",
            {
              staticClass: "m-t-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 }
            },
            [
              _c(
                "div",
                { staticClass: "app-container fillet p-30" },
                [
                  _c("div", { staticClass: "font-16 el-message__content" }, [
                    _vm._v(" 注册趋势 ")
                  ]),
                  _c("registerTrends", {
                    attrs: { "register-trend": _vm.workbenchData.registerTrend }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "m-t-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 }
            },
            [
              _c(
                "div",
                {
                  staticClass: "app-container fillet p-30",
                  staticStyle: { height: "500px" }
                },
                [
                  _c("div", { staticClass: "font-16 el-message__content" }, [
                    _vm._v("待办提醒")
                  ]),
                  _c("div", { staticClass: "flex m-t-30" }, [
                    _c(
                      "div",
                      {
                        staticClass: "box_icon",
                        staticStyle: { background: "#f0f2ff" }
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "40%", height: "40%" },
                          attrs: {
                            src: require("@/assets/images/workbench/bird1.png"),
                            alt: "bird1"
                          }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "m-l-20" }, [
                      _c("div", { staticClass: "el-message__content" }, [
                        _vm._v("未完成定制小程序")
                      ]),
                      _c("div", { staticClass: "m-t-10" }, [
                        _vm._v(" 总数 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.mini_program.count
                            )
                          )
                        ]),
                        _vm._v(" 个，待注册 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.mini_program
                                .register
                            )
                          )
                        ]),
                        _vm._v(" 个，备案中 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.mini_program.icp
                            )
                          )
                        ]),
                        _vm._v(" 个，商户接入 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.mini_program
                                .sub_mch
                            )
                          )
                        ]),
                        _vm._v(" 个， "),
                        _c("br"),
                        _vm._v(" 未发布 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.mini_program
                                .code_status_0
                            )
                          )
                        ]),
                        _vm._v(" 个，已发布 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.mini_program
                                .code_status_1
                            )
                          )
                        ]),
                        _vm._v(" 个，审核中 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.mini_program
                                .code_status_2
                            )
                          )
                        ]),
                        _vm._v(" 个，审核失败 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.mini_program
                                .code_status_3
                            )
                          )
                        ]),
                        _vm._v(" 个 ")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "flex m-t-20" }, [
                    _c(
                      "div",
                      {
                        staticClass: "box_icon",
                        staticStyle: { background: "#f0e8ff" }
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "40%", height: "40%" },
                          attrs: {
                            src: require("@/assets/images/workbench/information.png"),
                            alt: "information"
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "m-l-20", staticStyle: { width: "90%" } },
                      [
                        _c("div", { staticClass: "el-message__content" }, [
                          _vm._v("待处理用户反馈")
                        ]),
                        _c(
                          "div",
                          { staticClass: "m-t-10" },
                          _vm._l(
                            _vm.workbenchData.toDoMessages.feedback,
                            function(item, index) {
                              return _c("span", { key: index }, [
                                _vm._v(
                                  _vm._s(item.type) +
                                    _vm._s(item.count) +
                                    "个，"
                                )
                              ])
                            }
                          ),
                          0
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "flex m-t-20" }, [
                    _c(
                      "div",
                      {
                        staticClass: "box_icon",
                        staticStyle: { background: "#fad7dd" }
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "40%", height: "40%" },
                          attrs: {
                            src: require("@/assets/images/workbench/xiyiji.png"),
                            alt: "xiyiji"
                          }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "m-l-20" }, [
                      _c("div", { staticClass: "el-message__content" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.workbenchData.toDoMessages.expire.count)
                          )
                        ]),
                        _vm._v(" 个即将到期客户 ")
                      ]),
                      _c("div", { staticClass: "m-t-10" }, [
                        _vm._v(" 未使用 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.expire.status_0
                            )
                          )
                        ]),
                        _vm._v(" 个，已使用 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.expire.status_1
                            )
                          )
                        ]),
                        _vm._v(" 个，已付费 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.expire.status_2
                            )
                          )
                        ]),
                        _vm._v(" 个 ")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "flex m-t-20" }, [
                    _c(
                      "div",
                      {
                        staticClass: "box_icon",
                        staticStyle: { background: "#fff6e1" }
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "40%", height: "40%" },
                          attrs: {
                            src: require("@/assets/images/workbench/shoppingBag.png"),
                            alt: "shoppingBag"
                          }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "m-l-20" }, [
                      _c("div", { staticClass: "el-message__content" }, [
                        _vm._v("待处理提现申请")
                      ]),
                      _c("div", { staticClass: "m-t-10" }, [
                        _vm._v(" 共 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.withdraw.count
                            )
                          )
                        ]),
                        _vm._v(" 条申请，共计余额 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.workbenchData.toDoMessages.withdraw.money_sum
                            )
                          )
                        ]),
                        _vm._v(" 元 ")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "flex m-t-20" }, [
                    _c(
                      "div",
                      {
                        staticClass: "box_icon",
                        staticStyle: { background: "#f0e8ff" }
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "40%", height: "40%" },
                          attrs: {
                            src: require("@/assets/images/workbench/bird2.png"),
                            alt: "bird2"
                          }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "m-l-20" }, [
                      _c("div", { staticClass: "el-message__content" }, [
                        _vm._v("待处理开发票")
                      ]),
                      _c("div", { staticClass: "m-t-10" }, [
                        _vm._v(" 共 "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.workbenchData.toDoMessages.invoice))
                        ]),
                        _vm._v(" 张发票申请未进行处理 ")
                      ])
                    ])
                  ])
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c(
            "el-col",
            {
              staticClass: "m-t-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 }
            },
            [
              _c(
                "div",
                { staticClass: "app-container fillet p-30" },
                [
                  _c("div", { staticClass: "font-16 el-message__content" }, [
                    _vm._v("待跟进客户")
                  ]),
                  _c(
                    "el-table",
                    {
                      staticClass: "m-t-20",
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: "420",
                        data: _vm.workbenchData.toDoMessages.todo_user
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "用户", "min-width": 160 },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                !_vm.utils.empty(row)
                                  ? _c(
                                      "div",
                                      { staticClass: "flex col-center" },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-avatar",
                                              {
                                                attrs: {
                                                  shape: "square",
                                                  size: 50,
                                                  src: row.logo
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: _vm.errorLogo()
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "p-l-10" }, [
                                          _c("span", [
                                            _vm._v(_vm._s(row.real_name))
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _vm._v(_vm._s(row.phone))
                                          ])
                                        ])
                                      ]
                                    )
                                  : _c("div", { staticClass: "text-danger" }, [
                                      _vm._v("用户信息缺失")
                                    ])
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "品牌", prop: "name", "min-width": 90 }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "套餐",
                          prop: "brand_level_text",
                          "min-width": 90
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "到期时间", "min-width": 120 },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      row.expiration_time,
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  )
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "跟进事项",
                          prop: "todo_content",
                          "min-width": 120
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "m-t-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 }
            },
            [
              _c("div", { staticClass: "app-container fillet p-30" }, [
                _c("div", { staticClass: "font-16 el-message__content" }, [
                  _vm._v("今日跟进")
                ]),
                _vm.workbenchData.dayAppTrackList.length > 0
                  ? _c(
                      "div",
                      {
                        staticStyle: { height: "440px", "overflow-y": "scroll" }
                      },
                      _vm._l(_vm.workbenchData.dayAppTrackList, function(
                        item,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index, staticClass: "flex m-t-20" },
                          [
                            _c(
                              "el-avatar",
                              {
                                attrs: {
                                  shape: "square",
                                  size: 50,
                                  src: item.avatar
                                }
                              },
                              [_c("img", { attrs: { src: _vm.errorLogo() } })]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "m-l-20",
                                staticStyle: { width: "86%" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "el-message__content" },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c("div", { staticClass: "m-t-10" }, [
                                  _vm._v(" " + _vm._s(item.content) + " - "),
                                  _c("span", { staticClass: "text-info" }, [
                                    _vm._v(_vm._s(item.create_time))
                                  ])
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "text-center",
                        staticStyle: { height: "460px" }
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.empty_default_img, alt: "" }
                        }),
                        _c("div", { staticClass: "text-info" }, [
                          _vm._v("当日跟进")
                        ])
                      ]
                    )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        [
          _c(
            "el-col",
            {
              staticClass: "m-y-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 }
            },
            [
              _c("div", { staticClass: "app-container fillet p-30" }, [
                _c("div", { staticClass: "flex row-between" }, [
                  _c("div", { staticClass: "font-16 el-message__content" }, [
                    _vm._v("今日交易排行")
                  ]),
                  _c("div", { staticClass: "font-14" }, [
                    _vm._v(
                      "今日交易总额+￥" +
                        _vm._s(_vm.workbenchData.appDayPayRanking.money_sum)
                    )
                  ])
                ]),
                _vm.workbenchData.appDayPayRanking.list.length !== 0
                  ? _c(
                      "div",
                      {
                        staticStyle: { height: "280px", "overflow-y": "scroll" }
                      },
                      _vm._l(_vm.workbenchData.appDayPayRanking.list, function(
                        item,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "flex row-between m-t-20"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex",
                                staticStyle: { width: "80%" }
                              },
                              [
                                _c(
                                  "el-avatar",
                                  {
                                    attrs: {
                                      shape: "square",
                                      size: 50,
                                      src: item.logo
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: _vm.errorLogo() }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70%",
                                      "margin-left": "20px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "el-message__content" },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c("div", { staticClass: "m-t-10" }, [
                                      item.seckill_count != "0.00"
                                        ? _c("span", [
                                            _vm._v(
                                              "售卡/秒杀" +
                                                _vm._s(item.seckill_count) +
                                                "￥，"
                                            )
                                          ])
                                        : _vm._e(),
                                      item.subscribe_count != "0.00"
                                        ? _c("span", [
                                            _vm._v(
                                              "付费约课" +
                                                _vm._s(item.subscribe_count) +
                                                "￥，"
                                            )
                                          ])
                                        : _vm._e(),
                                      item.pay_count != "0.00"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(item.mclass_count) + "￥，"
                                            )
                                          ])
                                        : _vm._e(),
                                      item.mclass_count != "0.00"
                                        ? _c("span", [
                                            _vm._v(
                                              "小班课" +
                                                _vm._s(item.mclass_count) +
                                                "￥，"
                                            )
                                          ])
                                        : _vm._e(),
                                      item.group_booking_count != "0.00"
                                        ? _c("span", [
                                            _vm._v(
                                              "拼团活动" +
                                                _vm._s(
                                                  item.group_booking_count
                                                ) +
                                                "￥，"
                                            )
                                          ])
                                        : _vm._e(),
                                      item.event_signup_count != "0.00"
                                        ? _c("span", [
                                            _vm._v(
                                              "活动报名" +
                                                _vm._s(
                                                  item.event_signup_count
                                                ) +
                                                "￥，"
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "font-14",
                                staticStyle: { color: "#01cbca" }
                              },
                              [_vm._v("+￥" + _vm._s(item.money_count))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "text-center",
                        staticStyle: { height: "280px" }
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.empty_default_img, alt: "" }
                        }),
                        _c("div", { staticClass: "text-info" }, [
                          _vm._v("当日暂无交易记录")
                        ])
                      ]
                    )
              ])
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "m-y-20",
              attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 }
            },
            [
              _c("div", { staticClass: "app-container fillet p-30" }, [
                _c("div", { staticClass: "font-16 el-message__content" }, [
                  _vm._v("今日成交排行")
                ]),
                _vm.workbenchData.customerRanking.length > 0
                  ? _c(
                      "div",
                      {
                        staticStyle: { height: "280px", "overflow-y": "scroll" }
                      },
                      _vm._l(_vm.workbenchData.customerRanking, function(
                        item,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index, staticClass: "flex m-t-20" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "box_icon",
                                staticStyle: { background: "#f0e8ff" }
                              },
                              [
                                _c("img", {
                                  staticStyle: { width: "50%", height: "50%" },
                                  attrs: {
                                    src: require("@/assets/images/workbench/avatar.png"),
                                    alt: "avatar"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "m-l-20",
                                staticStyle: { width: "90%" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "el-message__content" },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c("div", { staticClass: "m-t-10" }, [
                                  _vm._v(
                                    " 新购（" +
                                      _vm._s(item.new_buy_count) +
                                      "个）" +
                                      _vm._s(item.new_buy_sum) +
                                      "￥，续费（" +
                                      _vm._s(item.renewal_buy_count) +
                                      "个）" +
                                      _vm._s(item.renewal_buy_sum) +
                                      "￥，短信" +
                                      _vm._s(item.sms_buy_sum) +
                                      "￥ "
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "text-center",
                        staticStyle: { height: "280px" }
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.empty_default_img, alt: "" }
                        }),
                        _c("div", { staticClass: "text-info" }, [
                          _vm._v("当日暂无成交排行")
                        ])
                      ]
                    )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }