var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c("el-page-header", {
        staticClass: "page-header",
        attrs: {
          content: _vm.$route.params.mallType == 1 ? "编辑秒杀" : "编辑售卡"
        },
        on: {
          back: function($event) {
            return _vm.returnPage()
          }
        }
      }),
      _c("el-divider"),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "ruleForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "right",
            "label-width": "120px"
          }
        },
        [
          _c("p", [_c("b", [_vm._v("活动信息")])]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品封面图" } },
                    [
                      _c(
                        "el-upload",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.upLoadingCover,
                              expression: "upLoadingCover"
                            }
                          ],
                          staticClass: "images-uploader",
                          attrs: {
                            action: _vm.storeUpload,
                            "show-file-list": false,
                            "on-success": _vm.upSuccessCover,
                            headers: _vm.utils.upload_headers(),
                            "on-progress": _vm.upProgressCover,
                            accept: ".jpg,.jpeg,.png"
                          }
                        },
                        [
                          _vm.form.images
                            ? _c("img", {
                                staticClass: "images",
                                attrs: {
                                  src: _vm.form.images,
                                  title: "点击上传图片"
                                }
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus images-uploader-icon",
                                staticStyle: { border: "1px dashed #d9d9d9" }
                              })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品图片" } },
                    [
                      _c(
                        "el-upload",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.upLoading,
                              expression: "upLoading"
                            }
                          ],
                          staticClass: "upload-venues",
                          attrs: {
                            action: _vm.storeUpload,
                            "on-preview": _vm.handlePreview,
                            "on-remove": _vm.handleRemove,
                            "file-list": _vm.fileList,
                            "list-type": "picture-card",
                            "on-success": _vm.upSuccess,
                            headers: _vm.utils.upload_headers(),
                            limit: 6,
                            "on-exceed": _vm.handleExceed,
                            "on-progress": _vm.upProgress,
                            accept: ".jpg,.jpeg,.png"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "dialog_view_image",
                          attrs: { visible: _vm.dialogVisibleS },
                          on: {
                            "update:visible": function($event) {
                              _vm.dialogVisibleS = $event
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              width: "100%",
                              src: _vm.dialogImageUrl,
                              alt: ""
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入活动名称",
                          maxlength: "30",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$route.params.mallType
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "活动开始时间", prop: "start_time" }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期时间",
                              format: "yyyy-MM-dd HH:mm",
                              "value-format": "yyyy-MM-dd HH:mm",
                              "default-time": "00:00:00"
                            },
                            model: {
                              value: _vm.form.start_time,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "start_time", $$v)
                              },
                              expression: "form.start_time"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$route.params.mallType
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动结束时间", prop: "end_time" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              placeholder: "选择日期时间",
                              format: "yyyy-MM-dd HH:mm",
                              "value-format": "yyyy-MM-dd HH:mm",
                              "default-time": "23:59:59"
                            },
                            model: {
                              value: _vm.form.end_time,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "end_time", $$v)
                              },
                              expression: "form.end_time"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "购卡说明" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisibleTask = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.form.content
                                  ? "编辑购卡说明"
                                  : "添加购卡说明"
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("p", [_c("b", [_vm._v("商品信息")])]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择会员卡" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisibleCard = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.form.membership_card_ids.length > 0 ||
                                _vm.form.card_combo_id
                                ? "编辑会员卡"
                                : "选择会员卡"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.membershipCardListInfo.length > 0
                    ? _c(
                        "div",
                        { staticClass: "m-l-30" },
                        _vm._l(_vm.membershipCardListInfo, function(
                          item,
                          index
                        ) {
                          return _c(
                            "el-link",
                            {
                              key: index,
                              staticClass: "color m-x-6 m-b-20",
                              attrs: { target: "_blank" },
                              on: {
                                click: function($event) {
                                  return _vm.handleToPage(item.id)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              !_vm.$route.params.mallType
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "售价", prop: "price" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入售价" },
                              model: {
                                value: _vm.form.price,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "price", $$v)
                                },
                                expression: "form.price"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("元")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$route.params.mallType
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "秒杀价", prop: "seckill_price" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入秒杀价" },
                              model: {
                                value: _vm.form.seckill_price,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "seckill_price", $$v)
                                },
                                expression: "form.seckill_price"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("元")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "原价", prop: "line_price" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "请输入原价" },
                          model: {
                            value: _vm.form.line_price,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "line_price", $$v)
                            },
                            expression: "form.line_price"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元")])],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "relative", attrs: { span: 12 } },
                [
                  _c("i", {
                    staticClass: "el-icon-question cursor absolute",
                    on: {
                      click: function($event) {
                        _vm.cardType = true
                      }
                    }
                  }),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开卡类型", prop: "open_card_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "m-l-10",
                          attrs: { placeholder: "请选择开卡类型" },
                          model: {
                            value: _vm.form.open_card_type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "open_card_type", $$v)
                            },
                            expression: "form.open_card_type"
                          }
                        },
                        _vm._l(_vm.card_open_type, function(item, index) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: index }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.open_card_type === 2
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "购买后开卡时间",
                            prop: "open_card_time"
                          }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { type: "number" },
                              model: {
                                value: _vm.form.open_card_time,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "open_card_time", $$v)
                                },
                                expression: "form.open_card_time"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("天")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("p", [_c("b", [_vm._v("高级设置")])]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "relative", attrs: { span: 12 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "设置已售出的数量",
                        placement: "bottom"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-question absolute" })]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "已售数量" } },
                    [
                      _c("el-input", {
                        staticClass: "m-l-10",
                        attrs: {
                          type: "number",
                          placeholder: "请输入已售数量"
                        },
                        model: {
                          value: _vm.form.sales_actual,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sales_actual", $$v)
                          },
                          expression: "form.sales_actual"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "购买人" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.form.member_buy,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "member_buy", $$v)
                            },
                            expression: "form.member_buy"
                          }
                        },
                        [_vm._v("会员")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.form.guest_buy,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "guest_buy", $$v)
                            },
                            expression: "form.guest_buy"
                          }
                        },
                        [_vm._v("访客")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "relative", attrs: { span: 12 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "当商品已售数量大于等于库存时，该商品将无法购买",
                        placement: "bottom"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-question absolute" })]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "库存" } },
                    [
                      _c("el-input", {
                        staticClass: "m-l-10",
                        attrs: { type: "number", placeholder: "请输入库存" },
                        model: {
                          value: _vm.form.inventory,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "inventory", $$v)
                          },
                          expression: "form.inventory"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "relative", attrs: { span: 12 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "排序数字越大越靠前",
                        placement: "bottom"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-question absolute" })]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序" } },
                    [
                      _c("el-input", {
                        staticClass: "m-l-10",
                        attrs: { type: "number", placeholder: "请输入排序" },
                        model: {
                          value: _vm.form.sort,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "限购" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "flex",
                          model: {
                            value: _vm.form.restriction,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "restriction", $$v)
                            },
                            expression: "form.restriction"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不限制")
                              ]),
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("限制")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "el-input",
                            {
                              staticClass: "m-l-20",
                              staticStyle: { width: "160px" },
                              attrs: {
                                type: "number",
                                disabled: _vm.form.restriction == 0
                              },
                              model: {
                                value: _vm.peopleNumber,
                                callback: function($$v) {
                                  _vm.peopleNumber = $$v
                                },
                                expression: "peopleNumber"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("人")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品上架" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 0, "inactive-value": 1 },
                        model: {
                          value: _vm.form.is_hidden,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "is_hidden", $$v)
                          },
                          expression: "form.is_hidden"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "relative", attrs: { span: 12 } },
                [
                  _c("i", {
                    staticClass: "el-icon-question cursor absolute",
                    staticStyle: {
                      position: "absolute",
                      top: "10px",
                      left: "120px"
                    },
                    on: {
                      click: function($event) {
                        _vm.recommendation = true
                      }
                    }
                  }),
                  _c(
                    "el-form-item",
                    { attrs: { label: "加入推荐" } },
                    [
                      _c("el-switch", {
                        staticClass: "m-l-10",
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.form.is_recommend,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "is_recommend", $$v)
                          },
                          expression: "form.is_recommend"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.is_recommend
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "推荐轮播图" } },
                        [
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.upLoading,
                                  expression: "upLoading"
                                }
                              ],
                              staticClass: "images-uploader",
                              attrs: {
                                action: _vm.storeUpload,
                                "show-file-list": false,
                                "on-success": _vm.upSuccessS,
                                headers: _vm.utils.upload_headers(),
                                "on-progress": _vm.upProgressS,
                                accept: ".jpg,.jpeg,.png"
                              }
                            },
                            [
                              _vm.form.recommend_image
                                ? _c("img", {
                                    staticClass: "images",
                                    attrs: {
                                      src: _vm.form.recommend_image,
                                      title: "点击上传图片"
                                    }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus images-uploader-icon",
                                    staticStyle: {
                                      border: "1px dashed #d9d9d9"
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "s_seckill_list" })
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading, type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("ruleForm")
                }
              }
            },
            [_vm._v("提 交")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "购卡说明",
            visible: _vm.dialogVisibleTask,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleTask = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c("wangEditor", {
            model: {
              value: _vm.WangEditorValue,
              callback: function($$v) {
                _vm.WangEditorValue = $$v
              },
              expression: "WangEditorValue"
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleTask = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleActivityPreserve }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "会员卡",
            visible: _vm.dialogVisibleCard,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleCard = $event
            },
            open: _vm.handleOpenCard,
            close: _vm.handleClose
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cardLoading,
                  expression: "cardLoading"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "filter-item m-b-10" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: _vm.is_card
                          ? "组合卡名称"
                          : "搜索会员卡名称"
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleFilter($event)
                        }
                      },
                      model: {
                        value: _vm.listQueryParams.query,
                        callback: function($$v) {
                          _vm.$set(_vm.listQueryParams, "query", $$v)
                        },
                        expression: "listQueryParams.query"
                      }
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          loading: _vm.searchLoading
                        },
                        on: { click: _vm.handleFilter },
                        slot: "append"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex m-b-20" },
                [
                  _c("div", [_vm._v("套餐卡：")]),
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    on: { change: _vm.handleChangeIsCard },
                    model: {
                      value: _vm.is_card,
                      callback: function($$v) {
                        _vm.is_card = $$v
                      },
                      expression: "is_card"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.is_card,
                      expression: "!is_card"
                    }
                  ],
                  staticClass: "tal"
                },
                [
                  _c("checkboxInput", {
                    attrs: {
                      "parent-selected": _vm.selectedCard,
                      list: _vm.supportCard
                    },
                    on: {
                      "update:parentSelected": [
                        function($event) {
                          _vm.selectedCard = $event
                        },
                        _vm.handleParentSelected
                      ],
                      "update:parent-selected": function($event) {
                        _vm.selectedCard = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.is_card,
                      expression: "is_card"
                    }
                  ],
                  staticClass: "tal"
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "m-b-10", attrs: { span: 8 } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.card_combo_id,
                                callback: function($$v) {
                                  _vm.card_combo_id = $$v
                                },
                                expression: "card_combo_id"
                              }
                            },
                            [_vm._v("不选择组合卡")]
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.ComboList, function(item, index) {
                        return _c(
                          "el-col",
                          {
                            key: index,
                            staticClass: "m-b-10",
                            attrs: { span: 8 }
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: item.id },
                                model: {
                                  value: _vm.card_combo_id,
                                  callback: function($$v) {
                                    _vm.card_combo_id = $$v
                                  },
                                  expression: "card_combo_id"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.name.length > 10
                                        ? item.name.slice(0, 10) + "..."
                                        : item.name
                                    ) +
                                    "（包含" +
                                    _vm._s(
                                      item.card_group
                                        ? item.card_group.length
                                        : 0
                                    ) +
                                    "张会员卡） "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleCard = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlePreserveCard }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ]
      ),
      _c("Dialog", {
        attrs: {
          id: _vm.membership_card_id_info,
          venue: _vm.venuesId,
          dialog: _vm.dialogVisibleMembership
        },
        on: { Close: _vm.Close }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "加入推荐示列",
            visible: _vm.recommendation,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.recommendation = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "font-14 text-info",
              staticStyle: { "text-align": "center" }
            },
            [_vm._v("启用加入推荐，即可在特定板块中展示此活动")]
          ),
          _c("el-image", {
            staticClass: "m-t-10",
            staticStyle: { width: "100%" },
            attrs: { src: _vm.recommendationImg, fit: "fill" }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer flex row-center",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.recommendation = false
                    }
                  }
                },
                [_vm._v("知道了")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "开卡类型",
            visible: _vm.cardType,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.cardType = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c(
            "div",
            { staticClass: "font-14" },
            [
              _c("div", { staticClass: "el-message__content" }, [
                _vm._v("立即开卡")
              ]),
              _c("div", { staticClass: "text-info m-t-4" }, [
                _vm._v("发卡成功后立即激活会员卡")
              ]),
              _c("div", { staticClass: "el-message__content m-t-4" }, [
                _vm._v("首次预约开卡")
              ]),
              _c("div", { staticClass: "text-info m-t-4" }, [
                _vm._v(
                  "该卡将在首次预约课程时激活会员卡，包括会员自主预约和老师代约课"
                )
              ]),
              _c("div", { staticClass: "el-message__content m-t-4" }, [
                _vm._v("特定时间开卡")
              ]),
              _c("div", { staticClass: "text-info m-t-4" }, [
                _vm._v(
                  "该卡将在指定日期和时间点启用并激活会员卡，在这之前该卡无法使用"
                )
              ]),
              _c("div", { staticClass: "el-message__content m-t-4" }, [
                _vm._v("首次上课开卡")
              ]),
              _c("div", { staticClass: "text-info m-t-4" }, [
                _vm._v(
                  "该卡将在首次预约课程的上课时间激活会员卡，包括会员自主预约和老师代约课"
                )
              ]),
              _c("el-divider"),
              _c("div", { staticClass: "el-message__content text-warning" }, [
                _c("i", { staticClass: "el-icon-warning" }),
                _vm._v(" 注意：")
              ]),
              _c("div", { staticClass: "text-warning m-t-4" }, [
                _vm._v(
                  "除立即开卡以外的开卡方式，开卡前无到期时间，且无法修改会员卡有效期，有效期将在开卡后计算"
                )
              ])
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer flex row-center",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.cardType = false
                    }
                  }
                },
                [_vm._v("知道了")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }