<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button v-waves class="filter-item" type="primary" icon="el-icon-plus" @click="showAddFrom = true">
        添加配置
      </el-button>
    </div>

    <!-- 列表 -->
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="id" label="ID" />

      <el-table-column prop="name" label="配置名称" />
      <el-table-column prop="field" label="配置字段" />
      <el-table-column prop="type" label="字段类型" />
      <el-table-column prop="info" label="配置简介" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" :offset="2" @click="FieldDetails(scope.row)">编辑</el-button>
          <el-popconfirm
            title="确定删除吗？"
            class="m-l-10"
            @confirm="deleteField(scope.row.id)"
          >
            <el-button slot="reference" type="danger" icon="el-icon-delete" :offset="2">删除</el-button>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 新建表单 -->
    <el-dialog
      title="添加配置字段"
      :visible.sync="showAddFrom"
      width="600"
      :close-on-click-modal="false"
    >
      <el-tabs v-model="activeType">
        <el-tab-pane label="文本框" name="text">
          <form-create ref="fc" :rule="rules.text" class="formBox" handle-icon="false" @submit="onSubmit" />
        </el-tab-pane>
        <el-tab-pane label="多行文本框" name="textarea">
          <form-create ref="fc" :rule="rules.textarea" class="formBox" handle-icon="false" @submit="onSubmit" />
        </el-tab-pane>
        <el-tab-pane label="单选框" name="radio">
          <form-create ref="fc" :rule="rules.radio" class="formBox" handle-icon="false" @submit="onSubmit" />
        </el-tab-pane>
        <el-tab-pane label="文件上传" name="upload">
          <form-create ref="fc" :rule="rules.upload" class="formBox" handle-icon="false" @submit="onSubmit" />
        </el-tab-pane>
        <el-tab-pane label="多选框" name="checkbox">
          <form-create ref="fc" :rule="rules.checkbox" class="formBox" handle-icon="false" @submit="onSubmit" />
        </el-tab-pane>
        <el-tab-pane label="下拉框" name="select">
          <form-create ref="fc" :rule="rules.select" class="formBox" handle-icon="false" @submit="onSubmit" />
        </el-tab-pane>
        <el-tab-pane label="富文本" name="editor">
          <form-create ref="fc" :rule="rules.editor" class="formBox" handle-icon="false" @submit="onSubmit" />
        </el-tab-pane>
        <el-tab-pane label="数组" name="group">
          <form-create ref="fc" :rule="rules.group" class="formBox" handle-icon="false" @submit="onSubmit" />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 编辑表单-->
    <edit-from ref="edits" :from-data="FromData" @onSubmit="setConfig" />
  </div>
</template>

<script>
import {
  getConfig,
  getFieldList,
  editField,
  addField,
  deleteField
} from '@/api/admin/config.js'
import formCreate from '@form-create/element-ui'
import editFrom from '@/components/From'
import Pagination from '@/components/Pagination' // 分页组件

export default {
  components: {
    Pagination, editFrom, formCreate: formCreate.$form()
  },
  data() {
    return {
      showAddFrom: false,
      activeType: 'text',
      FromData: {},
      tableData: {
        list: [],
        count: 0
      },
      listLoading: false,
      rules: {
        text: [
          {
            'type': 'hidden',
            'field': 'type',
            'value': 'text'
          },
          {
            'type': 'select',
            'field': 'input_type',
            'value': '',
            'title': '类型',
            'props': {
              'multiple': false,
              'placeholder': '请选择类型'
            },
            'options': [
              {
                'value': 'input',
                'label': '文本框'
              },
              {
                'value': 'dateTime',
                'label': '时间'
              },
              {
                'value': 'color',
                'label': '颜色'
              },
              {
                'value': 'number',
                'label': '数字'
              },
              {
                'value': 'password',
                'label': '密码'
              }
            ]
          },
          {
            'type': 'input',
            'field': 'name',
            'value': '',
            'title': '配置名称',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置名称',
              'autofocus': true
            }
          },
          {
            'type': 'input',
            'field': 'field',
            'value': '',
            'title': '字段变量',
            'props': {
              'type': 'text',
              'placeholder': '例如：site_url'
            }
          },
          {
            'type': 'input',
            'field': 'info',
            'value': '',
            'title': '配置简介',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置简介'
            }
          },
          {
            'type': 'input',
            'field': 'default',
            'value': '',
            'title': '默认值',
            'props': {
              'type': 'text',
              'placeholder': '请输入默认值'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'width',
            'value': 100,
            'title': '文本框宽(%)',
            'props': {
              'placeholder': '请输入文本框宽(%)'
            }
          },
          {
            'type': 'input',
            'field': 'required',
            'value': '',
            'title': '验证规则',
            'props': {
              'type': 'text',
              'placeholder': '多个请用,隔开例如：required:true,url:true'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'sort',
            'value': 0,
            'title': '排序',
            'props': {
              'placeholder': '请输入排序'
            }
          },
          {
            'type': 'radio',
            'field': 'status',
            'value': 1,
            'title': '状态',
            'props': {},
            'options': [
              {
                'value': 1,
                'label': '显示'
              },
              {
                'value': 2,
                'label': '隐藏'
              }
            ]
          }
        ],
        textarea: [
          {
            'type': 'hidden',
            'field': 'type',
            'value': 'textarea'
          },
          {
            'type': 'input',
            'field': 'name',
            'value': '',
            'title': '配置名称',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置名称',
              'autofocus': true
            }
          },
          {
            'type': 'input',
            'field': 'field',
            'value': '',
            'title': '字段变量',
            'props': {
              'type': 'text',
              'placeholder': '例如：site_url'
            }
          },
          {
            'type': 'input',
            'field': 'info',
            'value': '',
            'title': '配置简介',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置简介'
            }
          },
          {
            'type': 'input',
            'field': 'default',
            'value': '',
            'title': '默认值',
            'props': {
              'type': 'textarea',
              'placeholder': '请输入默认值'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'width',
            'value': 100,
            'title': '文本框宽(%)',
            'props': {
              'placeholder': '请输入文本框宽(%)'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'high',
            'value': 5,
            'title': '多行文本框高(%)',
            'props': {
              'placeholder': '请输入多行文本框高(%)'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'sort',
            'value': 0,
            'title': '排序',
            'props': {
              'placeholder': '请输入排序'
            }
          },
          {
            'type': 'radio',
            'field': 'status',
            'value': 1,
            'title': '状态',
            'props': {},
            'options': [
              {
                'value': 1,
                'label': '显示'
              },
              {
                'value': 2,
                'label': '隐藏'
              }
            ]
          }
        ],
        radio: [
          {
            'type': 'hidden',
            'field': 'type',
            'value': 'radio'
          },
          {
            'type': 'input',
            'field': 'name',
            'value': '',
            'title': '配置名称',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置名称',
              'autofocus': true
            }
          },
          {
            'type': 'input',
            'field': 'field',
            'value': '',
            'title': '字段变量',
            'props': {
              'type': 'text',
              'placeholder': '例如：site_url'
            }
          },
          {
            'type': 'input',
            'field': 'info',
            'value': '',
            'title': '配置简介',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置简介'
            }
          },
          {
            'type': 'input',
            'field': 'parameter',
            'value': '',
            'title': '配置参数',
            'props': {
              'type': 'textarea',
              'placeholder': '参数方式例如:\n1=>男\n2=>女\n3=>保密'
            }
          },
          {
            'type': 'input',
            'field': 'default',
            'value': '',
            'title': '默认值',
            'props': {
              'type': 'text',
              'placeholder': '请输入默认值'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'sort',
            'value': 0,
            'title': '排序',
            'props': {
              'placeholder': '请输入排序'
            }
          },
          {
            'type': 'radio',
            'field': 'status',
            'value': 1,
            'title': '状态',
            'props': {},
            'options': [
              {
                'value': 1,
                'label': '显示'
              },
              {
                'value': 2,
                'label': '隐藏'
              }
            ]
          }
        ],
        upload: [
          {
            'type': 'hidden',
            'field': 'type',
            'value': 'upload'
          },
          {
            'type': 'input',
            'field': 'name',
            'value': '',
            'title': '配置名称',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置名称',
              'autofocus': true
            }
          },
          {
            'type': 'input',
            'field': 'field',
            'value': '',
            'title': '字段变量',
            'props': {
              'type': 'text',
              'placeholder': '例如：site_url'
            }
          },
          {
            'type': 'input',
            'field': 'info',
            'value': '',
            'title': '配置简介',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置简介'
            }
          },
          {
            'type': 'radio',
            'field': 'input_type',
            'value': 'image',
            'title': '上传类型',
            'props': {},
            'options': [
              {
                'value': 'image',
                'label': '单图'
              },
              {
                'value': 'images',
                'label': '多图'
              },
              {
                'value': 'file',
                'label': '文件'
              }
            ]
          },
          {
            'type': 'input',
            'field': 'default',
            'value': '',
            'title': '默认值',
            'props': {
              'type': 'text',
              'placeholder': '请输入默认值'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'sort',
            'value': 0,
            'title': '排序',
            'props': {
              'placeholder': '请输入排序'
            }
          },
          {
            'type': 'radio',
            'field': 'status',
            'value': 1,
            'title': '状态',
            'props': {},
            'options': [
              {
                'value': 1,
                'label': '显示'
              },
              {
                'value': 2,
                'label': '隐藏'
              }
            ]
          }
        ],
        checkbox: [
          {
            'type': 'hidden',
            'field': 'type',
            'value': 'checkbox'
          },
          {
            'type': 'input',
            'field': 'name',
            'value': '',
            'title': '配置名称',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置名称',
              'autofocus': true
            }
          },
          {
            'type': 'input',
            'field': 'field',
            'value': '',
            'title': '字段变量',
            'props': {
              'type': 'text',
              'placeholder': '例如：site_url'
            }
          },
          {
            'type': 'input',
            'field': 'info',
            'value': '',
            'title': '配置简介',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置简介'
            }
          },
          {
            'type': 'input',
            'field': 'parameter',
            'value': '',
            'title': '配置参数',
            'props': {
              'type': 'textarea',
              'placeholder': '参数方式例如:\n1=>白色\n2=>红色\n3=>黑色'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'sort',
            'value': 0,
            'title': '排序',
            'props': {
              'placeholder': '请输入排序'
            }
          },
          {
            'type': 'radio',
            'field': 'status',
            'value': 1,
            'title': '状态',
            'props': {},
            'options': [
              {
                'value': 1,
                'label': '显示'
              },
              {
                'value': 2,
                'label': '隐藏'
              }
            ]
          }
        ],
        select: [
          {
            'type': 'hidden',
            'field': 'type',
            'value': 'select'
          },
          {
            'type': 'input',
            'field': 'name',
            'value': '',
            'title': '配置名称',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置名称',
              'autofocus': true
            }
          },
          {
            'type': 'input',
            'field': 'field',
            'value': '',
            'title': '字段变量',
            'props': {
              'type': 'text',
              'placeholder': '例如：site_url'
            }
          },
          {
            'type': 'input',
            'field': 'info',
            'value': '',
            'title': '配置简介',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置简介'
            }
          },
          {
            'type': 'input',
            'field': 'parameter',
            'value': '',
            'title': '配置参数',
            'props': {
              'type': 'textarea',
              'placeholder': '参数方式例如:\n1=>白色\n2=>红色\n3=>黑色'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'sort',
            'value': 0,
            'title': '排序',
            'props': {
              'placeholder': '请输入排序'
            }
          },
          {
            'type': 'radio',
            'field': 'status',
            'value': 1,
            'title': '状态',
            'props': {},
            'options': [
              {
                'value': 1,
                'label': '显示'
              },
              {
                'value': 2,
                'label': '隐藏'
              }
            ]
          }
        ],
        editor: [
          {
            'type': 'hidden',
            'field': 'type',
            'value': 'editor'
          },
          {
            'type': 'input',
            'field': 'name',
            'value': '',
            'title': '配置名称',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置名称',
              'autofocus': true
            }
          },
          {
            'type': 'input',
            'field': 'field',
            'value': '',
            'title': '字段变量',
            'props': {
              'type': 'text',
              'placeholder': '例如：site_url'
            }
          },
          {
            'type': 'input',
            'field': 'info',
            'value': '',
            'title': '配置简介',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置简介'
            }
          },
          {
            'type': 'editor',
            'field': 'default',
            'value': '',
            'title': '默认值',
            'props': {
              'type': 'editor',
              'placeholder': '请输入默认值'
            }
          },
          {
            'type': 'inputNumber',
            'field': 'sort',
            'value': 0,
            'title': '排序',
            'props': {
              'placeholder': '请输入排序'
            }
          },
          {
            'type': 'radio',
            'field': 'status',
            'value': 1,
            'title': '状态',
            'props': {},
            'options': [
              {
                'value': 1,
                'label': '显示'
              },
              {
                'value': 2,
                'label': '隐藏'
              }
            ]
          }
        ],
        group: [
          {
            'type': 'hidden',
            'field': 'type',
            'value': 'group'
          },
          {
            'type': 'input',
            'field': 'name',
            'value': '',
            'title': '配置名称',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置名称',
              'autofocus': true
            }
          },
          {
            'type': 'input',
            'field': 'field',
            'value': '',
            'title': '字段变量',
            'props': {
              'type': 'text',
              'placeholder': '例如：site_url'
            }
          },
          {
            'type': 'input',
            'field': 'info',
            'value': '',
            'title': '配置简介',
            'props': {
              'type': 'text',
              'placeholder': '请输入配置简介'
            }
          },
          {
            'type': 'group',
            'field': 'parameter',
            'value': [],
            'title': '添加字段',
            'props': {
              'rules': [
                { 'type': 'input', 'field': 'label', 'title': '字段名', 'placeholder': '请输入字段名' },
                { 'type': 'input', 'field': 'value', 'title': '字段值', 'placeholder': '请输入字段值' }
              ]
            }
          },
          {
            'type': 'inputNumber',
            'field': 'sort',
            'value': 0,
            'title': '排序',
            'props': {
              'placeholder': '请输入排序'
            }
          },
          {
            'type': 'radio',
            'field': 'status',
            'value': 1,
            'title': '状态',
            'props': {},
            'options': [
              {
                'value': 1,
                'label': '显示'
              },
              {
                'value': 2,
                'label': '隐藏'
              }
            ]
          }
        ]
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 点击tab
    onhangeTab(name) {
      this.typeFrom.type = name
    },
    // 字段属性详情
    FieldDetails(data) {
      const rules = this.rules[data.type].map(val => {
        if (data.type === 'group' && val.field === 'parameter') {
          val.value = JSON.parse(data[val.field])
        } else if (data.type === 'radio' && val.field === 'parameter') {
          val.value = JSON.parse(data[val.field])
        } else if (data.type === 'checkbox' && val.field === 'parameter') {
          val.value = JSON.parse(data[val.field])
        } else {
          val.value = data[val.field]
        }
        return val
      })
      rules.push({ 'type': 'hidden', 'field': 'id', 'value': data.id })
      this.FromData = {
        rules: rules,
        title: '编辑字段'
      }
      this.$refs.edits.modals = true
    },
    // 提交
    onSubmit(formData, fapi) {
      console.log(this.$refs.fc)
      const data = {
        classify_id: this.$route.params.id,
        ...formData
      }
      data.parameter = JSON.stringify(data.parameter)
      addField(data).then(res => {
        this.getList()
        this.showAddFrom = false
        fapi.reload()
      }).catch(() => {})
    },
    // 编辑提交
    setConfig(formData, fapi) {
      const data = {
        classify_id: this.$route.params.id,
        ...formData
      }
      data.parameter = JSON.stringify(data.parameter)
      editField(formData.id, data).then(res => {
        this.getList()
        this.$refs.edits.modals = false
        fapi.reload()
      }).catch(() => {})
    },
    // 删除
    deleteField(id) {
      deleteField(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      })
    },
    // 获取配置分组列表
    getList() {
      this.listLoading = true
      getConfig(this.$route.params.id).then(data => {
        this.utils.setPageTitle(this.$route, data.data.name)
        this.utils.setTagsViewTitle(this.$route, data.data.name)
        const data_page = JSON.parse(JSON.stringify(this.listQuery))
        data_page['offset'] = (data_page.page - 1) * data_page.limit
        delete data_page['page']
        getFieldList(this.$route.params.id, data_page).then(res => {
          this.tableData = res.data
          this.listLoading = false
        }).catch(() => {})
      })
    },
    // 修改成功
    submitFail() {
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
