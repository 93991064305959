import { render, staticRenderFns } from "./Verify.vue?vue&type=template&id=872d7aea&"
import script from "./Verify.vue?vue&type=script&lang=js&"
export * from "./Verify.vue?vue&type=script&lang=js&"
import style0 from "./Verify.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\拾谷科技\\悠然瑜伽\\admin\\yoga-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('872d7aea')) {
      api.createRecord('872d7aea', component.options)
    } else {
      api.reload('872d7aea', component.options)
    }
    module.hot.accept("./Verify.vue?vue&type=template&id=872d7aea&", function () {
      api.rerender('872d7aea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/verifition/Verify.vue"
export default component.exports