<template>
  <div class="app-container">
    <el-tabs v-model="activeName" class="config_box" @tab-click="handleClick">
      <el-tab-pane v-for="(item,index) in configFormData" :key="index" :name="item.id.toString()">
        <span slot="label">
          <i v-if="item.icon" :class="item.icon" />
          {{ item.name }}
        </span>
        <div v-if="item.children">
          <el-tabs v-model="formID" type="border-card" @tab-click="childrenClick">
            <el-tab-pane v-for="(child,i) in item.children" :key="i" :name="child.id.toString()">
              <span slot="label">
                <i v-if="child.icon" :class="child.icon" />
                {{ child.name }}
              </span>
              <form-create
                ref="fc"
                v-loading="Loading"
                :option="option"
                :rule="formData"
                class="formBox"
                handle-icon="false"
                @submit="onSubmit"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-else>
          <form-create
            ref="fc"
            v-loading="Loading"
            :option="option"
            :rule="formData"
            class="formBox"
            handle-icon="false"
            @submit="onSubmit"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getConfigList, getFieldList, saveConfig } from '@/api/store/config.js'
import formCreate from '@form-create/element-ui'
import { getToken } from '@/utils/auth'

export default {
  components: {
    formCreate: formCreate.$form()
  },
  data() {
    return {
      showAddFrom: false,
      activeName: '',
      configFormData: [],
      formData: [],
      formID: 0,
      option: {
        info: {
          type: 'tooltip',
          icon: 'el-icon-info'
        },
        global: {
          upload: {
            props: {
              onSuccess(res, file) {
                console.log(res, file)
                if (res.status === 200) {
                  file.url = res.data.path
                } else {
                  this.$message.error(res.msg)
                }
              }
            }
          }
        }
      },
      Loading: false,
      idArr: []
    }
  },
  mounted() {
    this.getConfig()
  },
  methods: {
    // 获取配置分类
    getConfig() {
      const that = this
      getConfigList().then(res => {
        that.configFormData = res.data.list
        if (that.configFormData.length > 0) {
          that.activeName = that.configFormData[0].id.toString()
          that.handleClick({ name: that.activeName, index: 0 })
          if (!that.utils.empty(that.configFormData[0].children)) {
            that.formID = that.configFormData[0].children[0].id.toString()
          } else {
            that.formID = that.activeName
          }
        }
      })
    },
    // 点击配置分类
    handleClick(e) {
      const that = this
      if (!that.utils.empty(that.configFormData[e.index].children)) {
        that.childrenClick({
          name: that.configFormData[e.index].children[0].id.toString(),
          index: 0
        })
      } else {
        that.childrenClick(e)
        // that.formData = []
        // that.Loading = true
        // getFieldList(e.name)
        //   .then(res => {
        //     that.Loading = false
        //     that.formData = that.setFieldList(res.data.list);
        //     for (const item in res.data.list) {
        //       const field = res.data.list[item].field
        //       this.idArr[field] = res.data.list[item].id
        //     }
        //   })
        //   .catch(e => {})
      }
    },
    childrenClick(e) {
      const that = this
      that.formData = []
      that.Loading = true
      that.formID = e.name
      getFieldList(e.name)
        .then(res => {
          that.Loading = false
          that.formData = that.setFieldList(res.data.list)
          for (const item in res.data.list) {
            const field = res.data.list[item].field
            this.idArr[field] = res.data.list[item].id
          }
        })
        .catch(e => {})
    },
    // 获取字段
    setFieldList(resList) {
      const that = this
      const data = resList.map(val => {
        const rule = {
          type: '',
          title: val.name,
          field: val.field,
          props: {},
          validate: [
            // { required: true, message: '请输入goods_name', trigger: 'blur' },
          ]
        }
        switch (val.type) {
          case 'text':
            switch (val.input_type) {
              case 'input':
                rule.type = 'input'
                break
              case 'color':
                rule.type = 'ColorPicker'
                break
              case 'dateTime':
                rule.type = 'DatePicker'
                break
              case 'number':
                rule.type = 'InputNumber'
                break
              case 'password':
                rule.type = 'input'
                rule.props = {
                  type: 'password',
                  showPassword: true
                }
                break
            }
            if (val.required) {
              rule.validate = val.required.split(',').map(a => {
                const arr = a.split(':')
                const vali = { [arr[0]]: arr[1] }
                if (arr[0] === 'required') {
                  vali.message = val.name + '必填'
                  vali.whitespace = true
                }
                return vali
              })
            }
            break
          case 'radio':
            rule.type = 'radio'
            var options = []
            val.parameter.split('\n').map(a => {
              const a_string = JSON.parse(a)
              const a_data = a_string.split('\n')
              if (a_string && a_data) {
                a_data.forEach((item, index) => {
                  options.push({ value: item.split('=>')[0], label: item.split('=>')[1] })
                })
              }
            })
            rule.options = options
            break
          case 'textarea':
            rule.type = 'input'
            rule.props = {
              type: 'textarea'
            }
            break
          case 'upload':
            rule.type = 'upload'
            rule.props = {
              type: 'select',
              uploadType: 'image',
              action: this.storeUpload,
              headers: { Authorization: 'Bearer ' + getToken(), 'Venues-Id': this.utils.getVenues().id, 'App-Id': localStorage.getItem('Appid') ? localStorage.getItem('Appid') : '' },
              name: 'file',
              accept: 'image/*'
            }
            switch (val.input_type) {
              case 'image':
                rule.props.uploadType = 'image'
                rule.props.multiple = false
                rule.props.limit = 1
                break
              case 'images':
                rule.props.uploadType = 'image'
                rule.props.multiple = true
                rule.props.limit = 5
                that.utils.empty(val.store_config)
                  ? (val.default = [])
                  : (val.store_config.value = JSON.parse(
                    val.store_config.value
                  ))
                break
              case 'file':
                rule.props.uploadType = 'file'
                rule.props.multiple = false
                rule.props.limit = 1
                break
            }
            break
          case 'checkbox':
            rule.type = 'checkbox'
            rule.options = val.parameter.split('\n').map(a => {
              return { value: a.split('=>')[0], label: a.split('=>')[1] }
            })
            that.utils.empty(val.store_config)
              ? (val.default = [])
              : (val.store_config.value = JSON.parse(val.store_config.value))
            break
          case 'select':
            rule.type = 'select'
            rule.options = val.parameter.split('\n').map(a => {
              return { value: a.split('=>')[0], label: a.split('=>')[1] }
            })
            break
          case 'editor':
            rule.type = 'editor'
            break
          case 'group':
            rule.type = 'group'
            rule.props.rules = JSON.parse(val.parameter).map((item, index) => {
              return { type: 'input', field: item.value, title: item.label, placeholder: '请输入' + item.label }
            })
            that.utils.empty(val.store_config)
              ? (val.default = {})
              : (val.store_config.value = JSON.parse(val.store_config.value))
            break
        }
        if (!that.utils.empty(val.info)) rule.info = val.info
        rule.value = that.utils.empty(val.store_config)
          ? val.default
          : val.store_config.value
        return rule
      })
      return data
    },
    // 提交
    onSubmit(data, fapi) {
      const subdata = []
      console.log(this.idArr)
      console.log(this.formID)
      this.Loading = true
      for (var key in data) {
        subdata.push({
          id: this.idArr[key],
          field: key,
          classify_id: this.formID === 0 ? this.activeName : this.formID,
          value:
            typeof data[key] === 'string'
              ? data[key]
              : JSON.stringify(data[key])
        })
      }
      saveConfig({ data: subdata }).then(res => {
        this.$message.success(res.msg)
        this.Loading = false
        // this.handleClick({ name: this.activeName, index: 0 }, this.formID)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.config_box{
  // 修正工具栏提示遮罩
  ::v-deep{
    .el-tabs__content{
      overflow:unset;
    }
  }
}
</style>
