import { render, staticRenderFns } from "./index.vue?vue&type=template&id=30b6f868&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=30b6f868&lang=scss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&id=30b6f868&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b6f868",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\拾谷科技\\悠然瑜伽\\admin\\yoga-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30b6f868')) {
      api.createRecord('30b6f868', component.options)
    } else {
      api.reload('30b6f868', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=30b6f868&scoped=true&", function () {
      api.rerender('30b6f868', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/merchants/info/publicAccount/components/PublicNumberConfiguration/index.vue"
export default component.exports