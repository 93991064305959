import request from '@/utils/request'

// 获取公告列表
export function getArticleList(data) {
  return request({
    url: '/admin/v1/article',
    method: 'get',
    params: data
  })
}

// 获取公告详情
export function getArticleDetails(id) {
  return request({
    url: `/admin/v1/article/${id}`,
    method: 'get'
  })
}

// 更新公告
export function editArticle(id, data) {
  return request({
    url: `/admin/v1/article/${id}`,
    method: 'put',
    data
  })
}

// 添加公告
export function addArticle(data) {
  return request({
    url: '/admin/v1/article',
    method: 'post',
    data
  })
}
