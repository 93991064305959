<template>
  <div class="app-container special-center">
    <el-page-header :content="$route.params.mallType == 1 ? '编辑秒杀' : '编辑售卡'" class="page-header" @back="returnPage()" />
    <el-divider />
    <!-- 表单 -->
    <el-form ref="ruleForm" v-loading="formLoading" :rules="rules" :model="form" label-position="right" label-width="120px">
      <p><b>活动信息</b></p>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="商品封面图">
            <el-upload
              v-loading="upLoadingCover"
              class="images-uploader"
              :action="storeUpload"
              :show-file-list="false"
              :on-success="upSuccessCover"
              :headers="utils.upload_headers()"
              :on-progress="upProgressCover"
              accept=".jpg,.jpeg,.png"
            >
              <img v-if="form.images" :src="form.images" class="images" title="点击上传图片">
              <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="商品图片">
            <el-upload
              v-loading="upLoading"
              class="upload-venues"
              :action="storeUpload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture-card"
              :on-success="upSuccess"
              :headers="utils.upload_headers()"
              :limit="6"
              :on-exceed="handleExceed"
              :on-progress="upProgress"
              accept=".jpg,.jpeg,.png"
            >
              <i class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleS" class="dialog_view_image">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="商品名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入活动名称" maxlength="30" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col v-if="$route.params.mallType" :span="12">
          <el-form-item label="活动开始时间" prop="start_time">
            <el-date-picker
              v-model="form.start_time"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              default-time="00:00:00"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="$route.params.mallType" :span="12">
          <el-form-item label="活动结束时间" prop="end_time">
            <el-date-picker
              v-model="form.end_time"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              default-time="23:59:59"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="购卡说明">
            <el-button @click="dialogVisibleTask = true">
              {{ form.content ? '编辑购卡说明' : '添加购卡说明' }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <p><b>商品信息</b></p>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="选择会员卡">
            <el-button @click="dialogVisibleCard = true">{{ form.membership_card_ids.length > 0 || form.card_combo_id ? '编辑会员卡' : '选择会员卡' }}</el-button>
          </el-form-item>
          <div v-if="membershipCardListInfo.length > 0" class="m-l-30">
            <el-link v-for="(item, index) in membershipCardListInfo" :key="index" class="color m-x-6 m-b-20" target="_blank" @click="handleToPage(item.id)">
              {{ item.name }}
            </el-link>
          </div>
        </el-col>
        <el-col v-if="!$route.params.mallType" :span="12">
          <el-form-item label="售价" prop="price">
            <el-input v-model="form.price" placeholder="请输入售价">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="$route.params.mallType" :span="12">
          <el-form-item label="秒杀价" prop="seckill_price">
            <el-input v-model="form.seckill_price" placeholder="请输入秒杀价">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="原价" prop="line_price">
            <el-input v-model="form.line_price" placeholder="请输入原价">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="relative">
          <i class="el-icon-question cursor absolute" @click="cardType = true" />
          <el-form-item label="开卡类型" prop="open_card_type">
            <el-select v-model="form.open_card_type" class="m-l-10" placeholder="请选择开卡类型">
              <el-option v-for="(item, index) in card_open_type" :key="item" :label="item" :value="index" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="form.open_card_type === 2" :span="12">
          <el-form-item label="购买后开卡时间" prop="open_card_time">
            <el-input v-model="form.open_card_time" type="number">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p><b>高级设置</b></p>
      <el-row :gutter="20">
        <el-col :span="12" class="relative">
          <el-tooltip class="item" effect="dark" content="设置已售出的数量" placement="bottom">
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="已售数量">
            <el-input v-model="form.sales_actual" class="m-l-10" type="number" placeholder="请输入已售数量" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="购买人">
            <el-checkbox v-model="form.member_buy">会员</el-checkbox>
            <el-checkbox v-model="form.guest_buy">访客</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" class="relative">
          <el-tooltip class="item" effect="dark" content="当商品已售数量大于等于库存时，该商品将无法购买" placement="bottom">
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="库存">
            <el-input v-model="form.inventory" class="m-l-10" type="number" placeholder="请输入库存" />
          </el-form-item>
        </el-col>
        <el-col :span="12" class="relative">
          <el-tooltip class="item" effect="dark" content="排序数字越大越靠前" placement="bottom">
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="排序">
            <el-input v-model="form.sort" class="m-l-10" type="number" placeholder="请输入排序" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="限购">
            <el-radio-group v-model="form.restriction" class="flex">
              <div>
                <el-radio :label="0">不限制</el-radio>
                <el-radio label="1">限制</el-radio>
              </div>
              <el-input
                v-model="peopleNumber"
                type="number"
                :disabled="form.restriction == 0"
                style="width: 160px;"
                class="m-l-20"
              >
                <template slot="append">人</template>
              </el-input>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="商品上架">
            <el-switch v-model="form.is_hidden" :active-value="0" :inactive-value="1" />
          </el-form-item>
        </el-col>
        <el-col :span="12" class="relative">
          <i
            class="el-icon-question cursor absolute"
            style="position: absolute; top: 10px; left: 120px;"
            @click="recommendation = true"
          />
          <el-form-item label="加入推荐">
            <el-switch v-model="form.is_recommend" class="m-l-10" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-col>
        <el-col v-if="form.is_recommend" :span="24">
          <el-form-item label="推荐轮播图">
            <el-upload
              v-loading="upLoading"
              class="images-uploader"
              :action="storeUpload"
              :show-file-list="false"
              :on-success="upSuccessS"
              :headers="utils.upload_headers()"
              :on-progress="upProgressS"
              accept=".jpg,.jpeg,.png"
            >
              <img v-if="form.recommend_image" :src="form.recommend_image" class="images" title="点击上传图片">
              <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 按钮 -->
    <div class="footer">
      <el-button :loading="buttonLoading" @click="$router.push({ name: 's_seckill_list' })">取 消</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="submitForm('ruleForm')">提 交</el-button>
    </div>
    <!-- 购卡说明 -->
    <el-dialog
      title="购卡说明"
      :visible.sync="dialogVisibleTask"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%"
      @open="handleOpen"
    >
      <wangEditor v-model="WangEditorValue" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTask = false">取 消</el-button>
        <el-button type="primary" @click="handleActivityPreserve">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 会员卡 -->
    <el-dialog
      title="会员卡"
      :visible.sync="dialogVisibleCard"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="50%"
      @open="handleOpenCard"
      @close="handleClose"
    >
      <div v-loading="cardLoading">
        <div class="filter-item m-b-10">
          <el-input
            v-model="listQueryParams.query"
            :placeholder="is_card ? '组合卡名称' : '搜索会员卡名称'"
            @keyup.enter.native="handleFilter"
          >
            <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
          </el-input>
        </div>
        <div class="flex m-b-20">
          <div>套餐卡：</div>
          <el-switch v-model="is_card" :active-value="1" :inactive-value="0" @change="handleChangeIsCard" />
        </div>
        <div v-show="!is_card" class="tal">
          <checkboxInput
            :parent-selected.sync="selectedCard"
            :list="supportCard"
            @update:parentSelected="handleParentSelected"
          />
        </div>
        <div v-show="is_card" class="tal">
          <el-row :gutter="20">
            <el-col class="m-b-10" :span="8">
              <el-radio v-model="card_combo_id" :label="0">不选择组合卡</el-radio>
            </el-col>
            <el-col v-for="(item, index) in ComboList" :key="index" class="m-b-10" :span="8">
              <el-radio v-model="card_combo_id" :label="item.id">
                {{ item.name.length > 10 ? item.name.slice(0, 10) + '...' : item.name }}（包含{{ item.card_group ? item.card_group.length : 0 }}张会员卡）
              </el-radio>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCard = false">取 消</el-button>
        <el-button type="primary" @click="handlePreserveCard">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 会员卡详情 -->
    <Dialog :id="membership_card_id_info" :venue="venuesId" :dialog="dialogVisibleMembership" @Close="Close" />
    <!-- 推荐解释 -->
    <el-dialog
      title="加入推荐示列"
      :visible.sync="recommendation"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="20%"
      @open="handleOpen"
    >
      <div class="font-14 text-info" style="text-align: center;">启用加入推荐，即可在特定板块中展示此活动</div>
      <el-image class="m-t-10" style="width: 100%;" :src="recommendationImg" fit="fill" />
      <span slot="footer" class="dialog-footer flex row-center">
        <el-button type="primary" @click="recommendation = false">知道了</el-button>
      </span>
    </el-dialog>
    <!-- 开卡类型 -->
    <el-dialog
      title="开卡类型"
      :visible.sync="cardType"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="20%"
      @open="handleOpen"
    >
      <div class="font-14">
        <div class="el-message__content">立即开卡</div>
        <div class="text-info m-t-4">发卡成功后立即激活会员卡</div>
        <div class="el-message__content m-t-4">首次预约开卡</div>
        <div class="text-info m-t-4">该卡将在首次预约课程时激活会员卡，包括会员自主预约和老师代约课</div>
        <div class="el-message__content m-t-4">特定时间开卡</div>
        <div class="text-info m-t-4">该卡将在指定日期和时间点启用并激活会员卡，在这之前该卡无法使用</div>
        <div class="el-message__content m-t-4">首次上课开卡</div>
        <div class="text-info m-t-4">该卡将在首次预约课程的上课时间激活会员卡，包括会员自主预约和老师代约课</div>
        <el-divider />
        <div class="el-message__content text-warning"><i class="el-icon-warning" /> 注意：</div>
        <div class="text-warning m-t-4">除立即开卡以外的开卡方式，开卡前无到期时间，且无法修改会员卡有效期，有效期将在开卡后计算</div>
      </div>
      <span slot="footer" class="dialog-footer flex row-center">
        <el-button type="primary" @click="cardType = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getSeckillInfo, editSeckill } from '@/api/store/seckill.js'
import { getVipCard } from '@/api/store/venues.js'
import { getComboList } from '@/api/store/combination.js'
// 组件
import WangEditor from '@/components/wangEditor'
import checkboxInput from '@/views/store/teamwork/components/checkboxInput.vue'
import Dialog from '@/views/store/user/vipCard/components/Dialog.vue'
// 函数
import { deepClone } from '@/utils'
import Utils from '@/utils/utils.js'

export default {
  components: { WangEditor, checkboxInput, Dialog },
  data() {
    return {
      form: {
        name: '',
        images: '',
        recommend_image: '',
        goods_images: [],
        start_time: '',
        end_time: '',
        content: '',
        seckill_price: '',
        price: '',
        line_price: '',
        open_card_type: 0,
        open_card_time: 0,
        restriction: 0,
        sort: 100,
        inventory: 100,
        sales_actual: '',
        member_buy: true,
        guest_buy: true,
        is_hidden: 1,
        is_recommend: 0,
        sales_method: 1,
        membership_card_ids: [], // 自定义组合卡ID
        card_combo_id: 0 // 组合卡套餐ID
      },
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        recommend_image: [{ required: true, message: '请上传推荐图', trigger: 'blur' }],
        start_time: [{ required: true, message: '请选择活动开始时间', trigger: 'blur' }],
        end_time: [{ required: true, message: '请选择拼活动结束时间', trigger: 'blur' }],
        seckill_price: [{ required: true, message: '请输入秒杀价', trigger: 'blur' }],
        price: [{ required: true, message: '请输入售价', trigger: 'blur' }],
        line_price: [{ required: true, message: '请输入原价', trigger: 'blur' }],
        open_card_type: [{ required: true, message: '请选择开卡类型', trigger: 'blur' }],
        open_card_time: [{ required: true, message: '请输入购买后开卡时间', trigger: 'blur' }]
      },
      WangEditorValue: '',
      dialogVisibleTask: false,
      dialogVisibleCard: false,
      cardLoading: false,
      peopleNumber: '',
      activeNames: ['1', '2', '3'],
      // 支持卡列表
      supportCard: [],
      // 已选择的会员卡
      selectedCard: [],
      is_card: 0,
      ComboList: [],
      membershipCardList: [],
      buttonLoading: false,
      searchLoading: false,
      membershipCardListInfo: [],
      dialogVisibleMembership: false,
      membership_card_id_info: 0,
      venuesId: Utils.getVenues().id,
      fileList: [],
      upLoadingCover: false,
      dialogVisibleS: false,
      formLoading: false,
      dialogImageUrl: '',
      cardType: false,
      recommendation: false,
      recommendationImg: require('@/assets/images/exampleImage.png'),
      card_combo_id: 0 // 组合卡套餐ID
    }
  },
  created() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.formLoading = true
    getSeckillInfo(this.id)
      .then(res => {
        const {
          name,
          images,
          goods_images,
          recommend_image,
          start_time,
          end_time,
          seckill_price,
          content,
          price,
          line_price,
          open_card_type,
          open_card_time,
          restriction,
          sort,
          inventory,
          sales_actual,
          member_buy,
          guest_buy,
          is_hidden,
          is_recommend,
          membership_card_ids,
          card_combo_id,
          sales_method
        } = res.data
        this.form = {
          name,
          images,
          goods_images,
          recommend_image,
          start_time,
          end_time,
          seckill_price,
          content,
          price,
          line_price,
          open_card_type,
          open_card_time,
          restriction,
          sort,
          inventory,
          sales_actual,
          member_buy,
          guest_buy,
          is_hidden,
          is_recommend,
          membership_card_ids,
          card_combo_id,
          sales_method
        }
        this.form.member_buy = !!this.form.member_buy
        this.form.guest_buy = !!this.form.guest_buy
        if (this.form.restriction !== 0) {
          this.peopleNumber = this.form.restriction
          this.form.restriction = '1'
        }
        if (this.form.goods_images.length > 0) {
          this.form.goods_images.forEach(item => {
            // element ui要想显示图片 对象中必须带有以下参数
            const data = {
              name: '123123',
              uid: this.generateUniqueId(),
              response: {
                data: {
                  path: item
                }
              },
              url: item
            }
            this.fileList.push(data)
          })
        }
      })
      .finally(() => {
        this.formLoading = false
      })
  },
  methods: {
    generateUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
    },
    // 图片上传
    upSuccessCover(response, file, fileList) {
      this.upLoadingCover = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'images', response.data.path)
    },
    upSuccessS(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'recommend_image', response.data.path)
    },
    upProgressCover() {
      this.upLoadingCover = true
    },
    upProgressS() {
      this.upLoading = true
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = deepClone(this.form)
          if (!data.member_buy && !data.guest_buy) return this.$message.error('请选择购买人')
          data.goods_images = this.fileList.map(item => item.response.data.path)
          if (data.restriction === '1') data.restriction = this.peopleNumber
          data.member_buy = data.member_buy ? 1 : 0
          data.guest_buy = data.guest_buy ? 1 : 0
          // 是否是秒杀价
          data.is_seckill = this.$route.params.mallType
          // 秒杀活动时 price 同步 line_price 字段
          if (this.$route.params.mallType) data.price = data.line_price
          // 判断是否是组合卡 0 = 单卡售卡；1 = 自定义售卡；2 = 组合卡售卡
          if (data.membership_card_ids.length === 1) data.sales_method = 0
          if (data.membership_card_ids.length > 0) data.sales_method = 1
          if (data.card_combo_id !== 0) {
            data.membership_card_ids = null
            data.sales_method = 2
          }
          this.buttonLoading = true
          editSeckill(this.id, data)
            .then(() => {
              this.$router.push({ name: 's_seckill_list' })
              this.$message.success('修改成功')
              this.buttonLoading = false
            })
            .finally(() => {
              this.buttonLoading = false
            })
        } else {
          return false
        }
      })
    },
    // 活动打开的回调
    handleOpen() {
      this.WangEditorValue = this.form.content
    },
    // 保存活动
    handleActivityPreserve() {
      this.form.content = this.WangEditorValue
      this.dialogVisibleTask = false
    },
    // 会员卡打开的回调
    handleOpenCard() {
      this.cardLoading = true
      // 获取会员卡列表
      const data = {
        limit: 999
      }
      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'name', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      if (!this.is_card) {
        getVipCard(data)
          .then(res => {
            const { data } = res
            this.supportCard = data.list
            this.selectedCard = this.form.membership_card_ids
            this.membershipCardList = this.form.membership_card_ids
            this.handleOpenCardInfo()
          })
          .catch(() => {})
          .finally(() => {
            this.cardLoading = false
            this.searchLoading = false
          })
      } else {
        getComboList(data)
          .then(res => {
            const { data } = res
            this.ComboList = data.list
            this.card_combo_id = this.form.card_combo_id
          })
          .finally(() => {
            this.cardLoading = false
            this.searchLoading = false
          })
      }
    },
    // 会员卡关闭的回调
    handleClose() {
      this.listQueryParams.query = ''
      this.is_card = 0
    },
    // 保存选择的会员卡
    handlePreserveCard() {
      if (this.membershipCardList.length > 0 && this.card_combo_id) return this.$message.error('会员卡和套餐卡只能选择一种')
      if (this.membershipCardList.length > 15) {
        this.$message.error('最多只能选择15张会员卡')
        return false
      }
      this.form.membership_card_ids = this.membershipCardList
      this.form.card_combo_id = this.card_combo_id
      this.handleOpenCardInfo()
      this.dialogVisibleCard = false
    },
    handleParentSelected(val) {
      this.membershipCardList = val
    },
    handleToPage(membership_card_ids) {
      this.dialogVisibleMembership = true
      this.membership_card_id_info = membership_card_ids
    },
    Close() {
      this.dialogVisibleMembership = false
    },
    // 遍历会员卡的详情
    handleOpenCardInfo() {
      // 遍历出完整的卡信息
      const data = this.supportCard.filter(item => {
        // 使用 Array.prototype.some 来检查是否有匹配的 supportCard
        return this.membershipCardList.some(item1 => item1 === item.id)
      })
      this.membershipCardListInfo = data
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      // this.listQuery.page = 1
      this.handleOpenCard()
    },
    handleChangeIsCard() {
      this.$nextTick(() => {
        this.listQueryParams.query = ''
        this.handleOpenCard()
      })
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisibleS = true
    },
    // 上传成功事件
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.fileList = fileList
    },
    // 图片移除事件
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleExceed() {
      this.$message.warning('当前限制选择 6 张图片')
    },
    upProgress() {
      this.upLoading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409eff;
}
.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.color {
  color: rgb(24, 144, 255);
  /* padding-bottom: 3px;
  border-bottom: 1px solid rgb(24, 144, 255); */
  border: 1px solid rgb(24, 144, 255);
  padding: 4px 6px;
  border-radius: 5px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 192px;
  height: 108px;
}
::v-deep .el-upload {
  width: 192px;
  height: 108px;
  line-height: 116px;
}
</style>
