<template>
  <div v-if="$route.name=='s_visitors_list'" class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="性别">
            <el-select v-model="listQueryParams.sex" placeholder="请选择" filterable clearable>
              <el-option v-for="(item,index) in sexList" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <!-- 会籍顾问 -->
          <el-form-item label="会籍顾问">
            <Select :type="4" @changeId="handleCoachID" />
          </el-form-item>
          <el-form-item label="来访时间">
            <el-date-picker
              v-model="listQueryParams.visitor_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索 手机号 | 昵称" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('s_visitors_add')" class="filter-item m-l-10" type="primary" icon="el-icon-plus" @click="add_edit">
        添加
      </el-button>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="avatar" label="头像">
        <template slot-scope="{row}">
          <div v-if="!utils.empty(row)" class="flex col-center">
            <div>
              <el-avatar shape="square" :size="50" :src="row.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row) }}</span><br>
              <span>{{ row.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="real_name" label="姓名" :formatter="getUserNames"   /> -->
      <!-- <el-table-column prop="nickname" label="昵称" /> -->
      <!-- <el-table-column prop="phone" label="手机号"   /> -->
      <el-table-column prop="sex" label="性别" :formatter="formatTableSex" />
      <el-table-column prop="birthday" label="生日">
        <template slot-scope="{ row }">
          {{ row.birthday ? row.birthday : '---' }}
        </template>
      </el-table-column>
      <el-table-column prop="channel" label="登入渠道">
        <template slot-scope="{row}">
          <el-tag v-if="row.program_open_id" type="success" class="m-r-2">小程序</el-tag>
          <el-tag v-if="row.wechat_open_id" type="primary" class="m-r-2">公众号</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="store_user" label="会籍顾问">
        <template slot-scope="{row}">
          {{ row.store_user?row.store_user.real_name:'--' }}
        </template>
      </el-table-column>
      <el-table-column prop="visitor_time" label="来访时间">
        <template slot-scope="{row}">
          {{ row.visitor_time?row.visitor_time:'--' }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="remark" label="备注" min-width="150px" show-overflow-tooltip /> -->
      <el-table-column label="操作" min-width="240px">
        <template slot-scope="scope">
          <el-button v-show="btn_access('s_visitors_info')" class="m-2" type="primary" icon="el-icon-info" @click="toInfoPage(scope.row.id)">详情</el-button>
          <el-button v-show="btn_access('s_user_vipcard_add')" type="success" class="filter-item m-2" icon="el-icon-plus" @click="$router.push({ name: 's_user_vipcard_add', params: { user_id: scope.row.id, card_id: 3 }})">发卡</el-button>
          <el-popconfirm
            title="删除后将无法恢复，确定吗？"
            @confirm="delUser(scope.row.id)"
          >
            <el-button v-show="btn_access('s_visitors_delete')" slot="reference" icon="el-icon-delete" class="m-2" type="danger">删除</el-button>
          </el-popconfirm>
          <el-button v-show="btn_access('s_visitor_track_list')" icon="el-icon-notebook-2" class="m-2" @click="toPage(scope.row.id)">回访记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <el-dialog
      v-if="dialog.visible"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="添加访客"
      class="dialog_auto"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="头像" prop="avatar" style="width: 100px;">
          <div class="block" @click="imagecropperShow=true">
            <el-avatar shape="square" :size="100" :src="form.avatar" :title="dialog.id ? '点击修改' : '点击上传'" style="width:100px">
              <img :src="errorMemberSrc()">
            </el-avatar>
          </div>
          <image-cropper
            v-show="imagecropperShow"
            :key="imagecropperKey"
            field="file"
            :width="300"
            :height="300"
            :url="storeUpload"
            lang-type="zh"
            @close="imagecropperClose"
            @crop-upload-success="cropSuccess"
          />

        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="form.sex">
            <el-radio-button :label="2">女</el-radio-button>
            <el-radio-button :label="1">男</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="姓名" prop="real_name">
          <el-input v-model="form.real_name" placeholder="请输入姓名" class="" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号" class="">
            <div slot="prepend">
              <Code @area_code="area_code" />
            </div>
          </el-input>
        </el-form-item>
        <!-- 会籍顾问 -->
        <el-form-item label="会籍顾问" prop="store_user_id">
          <Select :type="4" @changeId="handleCoachIDAdd" />
        </el-form-item>
        <el-form-item label="来访时间">
          <el-date-picker
            v-model="form.visitor_time"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="用户来访时间"
            class="dialog_w100"
          />
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" type="textarea" :rows="4" placeholder="请输入备注" class="" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <VisitorTrack :id="dialog.trackId" :track-visible.sync="dialog.trackVisible" />
  </div>
  <div v-else><router-view /></div>
</template>

<script>
import { deepClone } from '@/utils'
import { delVisitor } from '@/api/store/course.js'
import { getVisitor, addVisitor } from '@/api/store/course.js'
// import VisitorTrack from './tracking'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import ImageCropper from '@/components/ImageCropper'// 头像上传
import VisitorTrack from '../user/tracking' // 用户回访
// 组件
import Code from '@/components/code/index.vue'
import Select from '@/components/elSelect/Select'
export default {
  components: { Pagination, VisitorTrack, ImageCropper, Select, Code },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 弹窗
      dialog: {
        visible: false,
        trackVisible: false,
        trackId: null
      },
      // 数据
      form: {
        real_name: '',
        phone: '',
        sex: 2,
        avatar: '',
        store_user_id: '',
        remark: '',
        visitor_time: this.$moment().format('YYYY-MM-DD HH:mm'),
        area_code: '+86'
      },
      storeUser: [],
      rules: ['real_name', 'phone', 'store_user_id'],
      searchLoading: false,
      imagecropperKey: 0,
      imagecropperShow: false
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.getList()
    // 获取管理员列表
    // getStoreUser().then(res => {
    //   this.storeUser = res.data.list
    // }).catch(() => {})
  },
  methods: {
    delUser(id) {
      delVisitor(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'phone|real_name|nickname', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.sex !== '' && this.listQueryParams.sex != null) querys.push({ field: 'sex', key: this.listQueryParams.sex })
      if (this.listQueryParams.store_user_id !== '' && this.listQueryParams.store_user_id != null) querys.push({ field: 'store_user_id', key: this.listQueryParams.store_user_id })
      if (this.listQueryParams.visitor_time) querys.push({ field: 'visitor_time', type: 'between-time', key: this.listQueryParams.visitor_time })

      data.query = this.utils.getQueryParams(querys)

      getVisitor(data).then(res => {
        this.tableData = res.data
        this.searchLoading = false
      }).catch(() => {})
    },
    add_edit() {
      this.dialog.visible = true
      // this.form.store_user_id = this.$store.getters.userinfo.storeInfo.id
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = deepClone(this.form)
          data.venues_id = this.utils.getVenues().id
          data.area_code = (this.form.area_code === '+86') ? '86' : this.form.area_code.replace(/[^0-9]/ig, '')
          data.scene = (this.form.area_code === '+86') ? '' : 'internation'
          addVisitor(data).then(res => {
            this.$message.success('添加成功')
            this.getList()
            this.dialog.visible = false
          }).catch(() => {})
        }
      })
    },
    toPage(id) {
      this.dialog.trackId = id
      this.dialog.trackVisible = true
    },
    // 头像上传成功
    cropSuccess(resData) {
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      this.form.avatar = resData.path
    },
    // 头像上传弹出层关闭
    imagecropperClose() {
      this.imagecropperShow = false
    },
    // 打开用户详情
    toInfoPage(id) {
      this.$router.push({ name: 's_visitors_info', params: { id: id, is_visitors: 1 }})
    },
    handleCoachID(val) {
      this.listQueryParams.store_user_id = val
    },
    handleCoachIDAdd(val) {
      this.form.store_user_id = val
    },
    area_code(val) {
      this.form.area_code = val
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.el-avatar{
  background:none;
}
</style>
