var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box p-20" }, [
    _c(
      "div",
      { staticClass: " rela" },
      [
        _c(
          "el-menu",
          {
            staticClass: "el-menu-demo",
            attrs: {
              "default-active": _vm.key,
              mode: "horizontal",
              router: true
            }
          },
          [
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_mclass_classmanagement"),
                    expression: "btn_access('s_mclass_classmanagement')"
                  }
                ],
                attrs: { index: "/store/course/small/classmanagement" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_mclass_classmanagement")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_mclass_coursemanagement"),
                    expression: "btn_access('s_mclass_coursemanagement')"
                  }
                ],
                attrs: { index: "/store/course/small/coursemanagement" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_mclass_coursemanagement")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_mclass_classschedule"),
                    expression: "btn_access('s_mclass_classschedule')"
                  }
                ],
                attrs: { index: "/store/course/small/classschedule" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_mclass_classschedule")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_mclass_vipmanagement"),
                    expression: "btn_access('s_mclass_vipmanagement')"
                  }
                ],
                attrs: { index: "/store/course/small/vipmanagement" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_mclass_vipmanagement")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_mclass_datastatistics"),
                    expression: "btn_access('s_mclass_datastatistics')"
                  }
                ],
                attrs: { index: "/store/course/small/datastatistics" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_mclass_datastatistics")))]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "p-y-10" },
          [_c("router-view", { key: _vm.key })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }