<template>
  <div v-loading="loading" class="app-container special-center">
    <!-- 请假详情 -->
    <el-page-header content="请假详情" class="page-header" @back="returnPage()" />
    <div class="image">
      <div v-if="tableData.user_membership_card">
        <el-avatar shape="square" :size="100" :src="tableData.user_membership_card.user.avatar">
          <img :src="errorAvatarSrc(tableData.user_membership_card.user, 'img')">
        </el-avatar>
      </div>
      <div>
        <el-button type="warning" icon="el-icon-edit" @click="handelCZ(tableData.user_membership_card)">操作</el-button>
      </div>
    </div>
    <el-descriptions
      v-if="tableData.user_membership_card"
      title="基本信息"
      class="margin-top"
      :column="2"
      :label-style="rowCenter"
      border
    >
      <el-descriptions-item label="请假人">
        {{ tableData.user_membership_card.user ? tableData.user_membership_card.user.nickname : '用户数据丢失' }}
      </el-descriptions-item>
      <el-descriptions-item label="卡号">{{ tableData.user_membership_card.number }}</el-descriptions-item>
      <el-descriptions-item label="卡名称">{{ tableData.user_membership_card.card.name }}</el-descriptions-item>
      <el-descriptions-item label="请假状态">
        <el-tag>{{ tableData.ask_status_text }}</el-tag>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions v-if="tableData.user_membership_card" title="请假信息" class="margin-top" :column="2" :label-style="rowCenter" border>
      <el-descriptions-item label="请假开始时间">{{ tableData.start_time }}</el-descriptions-item>
      <el-descriptions-item label="请假结束时间">{{ tableData.end_time }}</el-descriptions-item>
      <el-descriptions-item v-if="tableData.user_membership_card" label="请假天数">
        {{ tableData.ask_day }} 天
      </el-descriptions-item>
      <el-descriptions-item v-if="tableData.user_membership_card.valid_time_text" label="剩余有效期">
        {{ tableData.user_membership_card.valid_time_text }}
      </el-descriptions-item>
      <el-descriptions-item v-if="tableData.user_membership_card.card" label="最多可请假次数">
        {{ tableData.user_membership_card.card.setting.leave_number === 0 ? '不限' : tableData.user_membership_card.card.setting.leave_number }} 次
      </el-descriptions-item>
      <el-descriptions-item v-if="tableData.user_membership_card.card" label="最多可请假天数">
        {{ tableData.user_membership_card.card.setting.leave_day === 0 ? '不限' : tableData.user_membership_card.card.setting.leave_day }} 天
      </el-descriptions-item>
      <!-- <el-descriptions-item label="最多可请假次数">kooriookami</el-descriptions-item>
      <el-descriptions-item label="最多可请假天数">kooriookami</el-descriptions-item> -->
      <el-descriptions-item label="请假事由">{{ tableData.remark ? tableData.remark : '无' }}</el-descriptions-item>
    </el-descriptions>
    <div>
      <h3 style="font-size: 16px;">请假记录</h3>
    </div>
    <el-table :data="tableDataList" style="width: 100%">
      <el-table-column label="操作人" width="160">
        <template slot-scope="scope">{{ scope.row.store_user.real_name }}</template>
      </el-table-column>
      <el-table-column label="操作类型" width="160">
        <template slot-scope="scope">{{ scope.row.status.name }}</template>
      </el-table-column>
      <el-table-column label="操作状态">
        <template slot-scope="scope">
          {{ scope.row.status.name }}：{{ scope.row.ask_day }} 天
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="操作时间" />
    </el-table>
    <!-- 操作 -->
    <el-dialog
      v-if="dialog.operation"
      title="操作"
      class="dialog_auto"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.operation"
      width="30%"
    >
      <Operation v-model="dialog.operation" :card-data="dialog.row" @callback="operation_callback" />
    </el-dialog>
  </div>
</template>
<script>
// 组件
import Operation from '@/views/store/user/vipCard/components/Operation.vue'
// API
import { getDataList } from '@/api/store/takeleave'
// 方法函数
// import { timestampToTime } from '@/utils/takeleave'
export default {
  components: { Operation },
  data() {
    return {
      tableData: {},
      tableDataList: [],
      loading: false,
      dialog: {
        operation: false,
        row: {}
      },
      rowCenter: {
        'text-align': 'center'
      } // 表格居中
    }
  },
  created() {
    // 获取详情
    this.getList()
  },
  mounted() {
    // 获取详情
    // this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      getDataList(this.$route.params.id).then(res => {
        const data = res.data
        // data.start_time = timestampToTime(data.start_time)
        // data.end_time = timestampToTime(data.end_time)
        this.tableData = data
        this.tableDataList = data.ask
        this.loading = false
      })
    },
    handelCZ(row) {
      this.dialog.operation = true
      this.dialog.row = row
    },
    // 操作回调
    operation_callback(msg) {
      this.$message.success(msg)
      this.getList()
      this.dialog.operation = false
    }
  }
}
</script>
<style lang="scss" scoped>
.head {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  h3 {
    margin: 0 0 0 20px;
  }
}
.image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  /* img {
    width: 90px;
    display: block;
  } */
}
.margin-top {
  margin-top: 20px;
}
</style>
