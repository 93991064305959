var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wx-config" }, [
    _c("div", { staticClass: "wx-box" }, [
      _c("div", { staticClass: "item" }, [
        _c(
          "div",
          { staticClass: "label text-primary", attrs: { id: "relevance" } },
          [_vm._v("公众号关联小程序")]
        ),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "tip" }, [
            _vm._v(
              " 关联小程序后，用户可以通过公众号菜单直接进入小程序进行约课等操作 "
            )
          ]),
          _c(
            "div",
            { staticClass: "m-t-10", staticStyle: { height: "100%" } },
            [
              _c(
                "el-steps",
                { attrs: { direction: "vertical" } },
                [
                  _c(
                    "el-step",
                    {
                      attrs: {
                        status: "success ",
                        title: "登录公众号平台",
                        space: "200"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-info m-b-10 ",
                          attrs: { slot: "description" },
                          slot: "description"
                        },
                        [
                          _vm._v("微信公众号平台 "),
                          _c(
                            "a",
                            {
                              staticClass: "text-primary",
                              attrs: {
                                href: "https://mp.weixin.qq.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("https://mp.weixin.qq.com")]
                          ),
                          _vm._v(" -> 扫码登录")
                        ]
                      )
                    ]
                  ),
                  _c(
                    "el-step",
                    {
                      attrs: {
                        status: "success ",
                        title: "关联小程序",
                        space: "200"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-info",
                          attrs: { slot: "description" },
                          slot: "description"
                        },
                        [
                          _vm._v(
                            " 找到广告与服务 -> 小程序管理 -> 添加 按钮,选择关联小程序-管理员微信扫码并确认 -> 搜索《瑜小九》 -> 下一步，完成关联 "
                          ),
                          _c("div", { staticClass: "flex m-t-10" }, [
                            _c(
                              "div",
                              { staticClass: "m-2" },
                              [
                                _c("el-image", {
                                  staticClass: "viewImg",
                                  attrs: {
                                    src: _vm.exampleImg[2],
                                    fit: "contain",
                                    "preview-src-list": [_vm.exampleImg[2]]
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "m-2" },
                              [
                                _c("el-image", {
                                  staticClass: "viewImg",
                                  attrs: {
                                    src: _vm.exampleImg[3],
                                    fit: "contain",
                                    "preview-src-list": [_vm.exampleImg[3]]
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "m-2" },
                              [
                                _c("el-image", {
                                  staticClass: "viewImg",
                                  attrs: {
                                    src: _vm.exampleImg[1],
                                    fit: "contain",
                                    "preview-src-list": [_vm.exampleImg[1]]
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "m-2" },
                              [
                                _c("el-image", {
                                  staticClass: "viewImg",
                                  attrs: {
                                    src: _vm.exampleImg[0],
                                    fit: "contain",
                                    "preview-src-list": [_vm.exampleImg[0]]
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "el-step",
                    { attrs: { status: "success ", title: "设置自定义菜单" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "text-info",
                          attrs: { slot: "description" },
                          slot: "description"
                        },
                        [
                          _vm._v(
                            " 找到内容与互动 -> 自定义菜单 -> 添加 -> 填写子菜单名称（如：约课小程序） -> 子菜单内容选择跳转小程序 -> 修改小程序路径为 "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "点击复制",
                                placement: "bottom"
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:copy",
                                      value: _vm.appidUrl,
                                      expression: "appidUrl",
                                      arg: "copy"
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:success",
                                      value: _vm.clipboardSuccess,
                                      expression: "clipboardSuccess",
                                      arg: "success"
                                    }
                                  ],
                                  staticClass: "text-primary",
                                  attrs: { icon: "el-icon-document" }
                                },
                                [_vm._v(" " + _vm._s(_vm.appidUrl))]
                              )
                            ]
                          ),
                          _vm._v(" -> 点击保存并发布即可 "),
                          _c("div", { staticClass: "flex m-t-10" }, [
                            _c("div", { staticClass: "m-2" }, [
                              _c(
                                "div",
                                { staticClass: "m-2" },
                                [
                                  _c("el-image", {
                                    staticClass: "viewImg",
                                    attrs: {
                                      src: _vm.exampleImg[5],
                                      fit: "contain",
                                      "preview-src-list": [_vm.exampleImg[5]]
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "m-2" }, [
                              _c(
                                "div",
                                { staticClass: "m-2" },
                                [
                                  _c("el-image", {
                                    staticClass: "viewImg",
                                    attrs: {
                                      src: _vm.exampleImg[4],
                                      fit: "contain",
                                      "preview-src-list": [_vm.exampleImg[4]]
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }