<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="dialog"
      width="40%"
      @open="open"
    >
      <div slot="title" class="title">
        <div class="title_title">会员卡详情</div>
        <div class="title_icon cursor" @click="handleClose">
          <i class="el-icon-close" />
        </div>
      </div>
      <el-tabs v-model="activeName" v-loading="loading">
        <el-tab-pane label="基本信息" name="first">
          <div class="information">
            <div class="information_left">
              <div class="information_title">会员卡名称：</div>
              <div class="information_content">{{ dataList.name }}</div>
            </div>
            <div class="information_right">
              <div class="information_title">种类：</div>
              <div class="information_content">{{ dataList.kind === 0 ? '常规' : '体验' }}</div>
            </div>
          </div>
          <div class="information">
            <div class="information_left">
              <div class="information_title">会员卡类型：</div>
              <div class="information_content">
                {{ dataList.type === 0 ? '期限卡' : dataList.type === 1 ? '次数卡' : '储值卡' }}
              </div>
            </div>
            <div class="information_right">
              <div class="information_title">售价：</div>
              <div class="information_content">{{ dataList.price }} 元</div>
            </div>
          </div>
          <div class="information">
            <div class="information_left">
              <div class="information_title">有效期：</div>
              <div class="information_content">
                {{ dataList.valid_period ? JSON.parse(dataList.valid_period).digital : '' }}
                {{ dataList.valid_period ? formatTime(JSON.parse(dataList.valid_period).type) : '' }}
              </div>
            </div>
            <div v-if="dataList.type === 1 || dataList.type === 2" class="information_right">
              <div class="information_title">卡额度：</div>
              <div v-if="dataList.type === 1" class="information_content">{{ dataList.limit }} 次</div>
              <div v-if="dataList.type === 2" class="information_content">{{ dataList.limit }} 元</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="适用场馆及课程" name="second">
          <div v-if="dataList.course.length > 0" class="label">
            <div v-for="(item, index) in dataList.course" :key="index">
              <div v-if="typeof item.course_id === 'string'" :class="dataList.type === 0 ? 'box1' : 'box2'">
                <el-tag>{{ item.course_id }}</el-tag>
                <span v-if="dataList.type === 1">（{{ item.charge }}）次/人</span>
                <span v-if="dataList.type === 2">（{{ item.charge }}）元/人</span>
              </div>
            </div>
          </div>
          <div v-else class="venuesList_empty text-center p-10">
            <img :src="empty_default_img" alt="">
            <div class="text-info">暂无适用的场馆及课程</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="高级设置" name="third">
          <!-- 卡相关设置 -->
          <h3>卡相关设置</h3>
          <div class="cardSettings">
            <div class="cardSettings_left">
              <div class="cardSettings_title">请假设置：</div>
              <div class="cardSettings_content">
                <span>
                  {{
                    dataList.setting
                      ? dataList.setting.leave_option === 0
                        ? '不允许'
                        : dataList.setting.leave_option === 1
                          ? '不限制'
                          : '允许'
                      : ''
                  }}
                </span>
                请假
              </div>
            </div>
          </div>
          <div v-if="dataList.setting && dataList.setting.leave_option === 2" class="cardSettings">
            <div class="cardSettings_left">
              <div class="cardSettings_title">课请假次数(次)：</div>
              <div class="cardSettings_content">
                <span>
                  {{ dataList.setting ? dataList.setting.leave_number : '' }}
                </span>
                次
              </div>
            </div>
            <div class="cardSettings_right">
              <div class="cardSettings_title">可请假天数(天)：</div>
              <div class="cardSettings_content">
                <span>
                  {{ dataList.setting ? dataList.setting.leave_day : '' }}
                </span>
                天
              </div>
            </div>
          </div>
          <div class="cardSettings">
            <div class="cardSettings_left">
              <div class="cardSettings_title">单节课可预约人数上限：</div>
              <div class="cardSettings_content">
                <span>
                  {{ dataList.setting ? dataList.setting.single_subscribe_limit : '' }}
                </span>
                人
              </div>
            </div>
            <div class="cardSettings_right">
              <div class="cardSettings_title">每日可预约次数上限：</div>
              <div class="cardSettings_content">
                <span>
                  {{ dataList.setting ? dataList.setting.every_day_subscribe_limit : '' }}
                </span>
                人
              </div>
            </div>
          </div>
          <div class="cardSettings">
            <div class="cardSettings_left">
              <div class="cardSettings_title">每周可预约次数上限：</div>
              <div class="cardSettings_content">
                <span>
                  {{ dataList.setting ? dataList.setting.every_week_subscribe_limit : '' }}
                </span>
                人
              </div>
            </div>
            <div class="cardSettings_right">
              <div class="cardSettings_title">每月可预约次数上限：</div>
              <div class="cardSettings_content">
                <span>
                  {{ dataList.setting ? dataList.setting.every_month_subscribe_limit : '' }}
                </span>
                人
              </div>
            </div>
          </div>
          <div class="cardSettings">
            <div class="cardSettings_left">
              <div class="cardSettings_title">可预约天数 会员最多可预约：</div>
              <div class="cardSettings_content">
                <span v-if="dataList.setting">
                  {{ dataList.setting.subscribe_day ? `${dataList.setting.subscribe_day}天的课程` : '不限制天数' }}
                </span>
              </div>
            </div>
          </div>
          <div class="cardSettings">
            <div class="cardSettings_left">
              <div class="cardSettings_title">可用时间：</div>
              <div class="cardSettings_content">
                <span>
                  {{ dataList.setting ? (dataList.setting.dayparting_select === 1 ? '全时段' : '自定义时段') : '' }}
                </span>
                可用
              </div>
            </div>
          </div>
          <div v-if="dataList.setting && dataList.setting.dayparting_select === 2" class="cardSettings">
            <div class="cardSettings_center">
              <div class="cardSettings_title">只有在：</div>
              <div class="cardSettings_content">
                <span v-for="(item, index) in checkList" :key="index">周{{ date(item) }}</span>
                时间内才可预约
                <!-- <el-checkbox-group v-model="checkList">
                <el-checkbox :label="1" disabled>周一</el-checkbox>
                <el-checkbox :label="2" disabled>周二</el-checkbox>
                <el-checkbox :label="3" disabled>周三</el-checkbox>
                <el-checkbox :label="4" disabled>周四</el-checkbox>
                <el-checkbox :label="5" disabled>周五</el-checkbox>
                <el-checkbox :label="6" disabled>周六</el-checkbox>
                <el-checkbox :label="7" disabled>周日</el-checkbox>
              </el-checkbox-group> -->
              </div>
            </div>
          </div>
          <div v-if="dataList.setting && dataList.setting.dayparting_select === 2" class="cardSettings">
            <div class="cardSettings_center">
              <div class="cardSettings_title">可用时间段：</div>
              <div v-for="(item, index) in dataList.setting.dayparting" :key="index" class="cardSettings_data">
                <span>{{ item.start_time }}</span>
                <span>至</span>
                <span>{{ item.end_time }}</span>
              </div>
            </div>
          </div>
          <!-- 用卡人相关设置 -->
          <h3>用卡人相关设置</h3>
          <div class="cardSettings">
            <div class="cardSettings_center">
              <div class="cardSettings_title">每日约课间隔时间限制：</div>
              <div class="cardSettings_content">
                <span>
                  {{ dataList.setting ? dataList.setting.every_interval_limit : '' }}
                </span>
                分钟之内不可连续约课
              </div>
            </div>
          </div>
          <div class="cardSettings">
            <div class="cardSettings_center">
              <div class="cardSettings_title">预约次数限制：</div>
              <div class="cardSettings_content">
                未结束课程预约次数限制为：
                <span>
                  {{ dataList.setting ? dataList.setting.not_finished_subscribe_limit : '' }}
                </span>
                次
              </div>
            </div>
          </div>
          <div class="cardSettings">
            <div class="cardSettings_center">
              <div class="cardSettings_title">会员取消预约次数限制：</div>
              <div class="cardSettings_content">
                每
                {{
                  dataList.setting
                    ? dataList.setting.cancel_subscribe_cycle === 1
                      ? '日'
                      : dataList.setting.cancel_subscribe_cycle === 2
                        ? '周'
                        : '月'
                    : ''
                }}
              </div>
              <div class="cardSettings_content">
                <span>
                  {{ dataList.setting ? dataList.setting.cancel_subscribe_limit : '' }}
                </span>
                次
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getVipCardDetails } from '@/api/store/venues.js'
import { getVenuesCourseId } from '@/api/store/course.js'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    id: {
      type: Number
    },
    venue: {
      type: Number
    }
  },
  data() {
    return {
      dataList: {
        course: []
      },
      activeName: 'first',
      loading: false,
      checkList: []
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 打开的回调
    open() {
      this.loading = true
      this.activeName = 'first'
      getVipCardDetails(this.id).then(res1 => {
        getVenuesCourseId().then(res2 => {
          if (res2.data.length !== 0) {
            const VipCardData = res1.data
            // const VenuesData = Object.values(res2.data)[0].list
            for (let index = 0; index < Object.values(res2.data).length; index++) {
              if (Object.values(res2.data)[index].id === this.venue) {
                var VenuesData = Object.values(res2.data)[index].list
              }
            }
            VipCardData.course.forEach(item1 => {
              VenuesData.forEach(item2 => {
                if (item1.course_id === item2.id) {
                  if (item2.store_user) {
                    item1.course_id = `${item2.store_user.real_name} / ${item2.name}`
                  } else {
                    item1.course_id = `教练待更新 / ${item2.name}`
                  }
                }
              })
            })
            this.dataList = VipCardData
            if (this.dataList.setting.dayparting[0]) {
              const data = this.dataList.setting.dayparting[0].support_days.split(',')
              const week = []
              data.forEach(item => week.push(parseInt(item)))
              this.checkList = week
            }
            this.loading = false
          } else {
            const VipCardData = res1.data
            this.dataList = VipCardData
            if (this.dataList.setting.dayparting[0]) {
              const data = this.dataList.setting.dayparting[0].support_days.split(',')
              const week = []
              data.forEach(item => week.push(parseInt(item)))
              this.checkList = week
            }
            this.loading = false
          }
        })
      })
    },
    // 关闭Dialog
    handleClose() {
      this.$emit('Close')
    },
    formatTime(t) {
      switch (t) {
        case 'day':
          return '天'
        case 'month':
          return '月'
        case 'year':
          return '年'
      }
    },
    date(item) {
      switch (item) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        case 7:
          return '七'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* 基本信息 */
.information {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  .information_left,
  .information_right {
    display: flex;
    .information_title {
      width: 200px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .information_content {
      width: 150px;
      height: 35px;
      /* border: 1px solid rgb(220, 223, 230);
      border-radius: 5px; */
      display: flex;
      align-items: center;
      padding-left: 15px;
    }
  }
}
/* 使用场馆及课程 */
.label {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .box1 {
    margin: 6px;
  }
  .box2 {
    margin: 10px;
    padding: 10px;
    border: 1px solid rgb(220, 223, 230);
    border-radius: 5px;
  }
}
/* 高级设置 */
.cardSettings {
  display: flex;
  justify-content: flex-start;
  /* margin-top: 20px; */
  .cardSettings_left,
  .cardSettings_right {
    width: 50%;
    display: flex;
    .cardSettings_title {
      width: 200px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .cardSettings_content {
      width: 150px;
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      span {
        color: rgb(24, 144, 255);
        padding-right: 20px;
      }
    }
  }
  .cardSettings_center {
    width: 100%;
    display: flex;
    .cardSettings_title {
      width: 200px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .cardSettings_content {
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      span {
        color: rgb(24, 144, 255);
        padding-right: 20px;
      }
    }
    .cardSettings_data {
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      span {
        color: rgb(24, 144, 255);
      }
      span:nth-of-type(2) {
        color: black;
        padding: 0 10px;
      }
    }
  }
}
.venuesList_empty{
  min-height: 400px;
  img {
    -webkit-user-drag: none;
  }
}
</style>
