var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        "close-on-click-modal": false,
        "show-close": false,
        width: _vm.equipment ? "60%" : "100%"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        open: _vm.handleOpen,
        close: _vm.handleClose
      }
    },
    [
      _c(
        "el-dialog",
        {
          class: _vm.equipment ? "dialog_w580" : "dialog_auto",
          attrs: {
            title: _vm.handleId === 0 ? "添加收款信息" : "编辑收款信息",
            visible: _vm.innerVisible,
            "close-on-click-modal": false,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.innerVisible = $event
            },
            open: _vm.handleInnerOpen,
            close: _vm.handleInnerClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                "label-position": _vm.equipment ? "right" : "top",
                model: _vm.form,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.product_type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "product_type", $$v)
                        },
                        expression: "form.product_type"
                      }
                    },
                    _vm._l(_vm.productType, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.id, attrs: { label: item.id } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "购买时长", prop: "buy_number" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number", placeholder: "请输入购买时长" },
                      model: {
                        value: _vm.form.buy_number,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "buy_number", $$v)
                        },
                        expression: "form.buy_number"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "v_type",
                              staticStyle: { width: "60px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.buy_number,
                                callback: function($$v) {
                                  _vm.buy_number = $$v
                                },
                                expression: "buy_number"
                              }
                            },
                            [
                              _vm.form.product_type !== 2
                                ? _c("el-option", {
                                    attrs: { label: "年", value: "年" }
                                  })
                                : _vm._e(),
                              _vm.form.product_type !== 2
                                ? _c("el-option", {
                                    attrs: { label: "月", value: "月" }
                                  })
                                : _vm._e(),
                              _vm.form.product_type !== 2
                                ? _c("el-option", {
                                    attrs: { label: "天", value: "天" }
                                  })
                                : _vm._e(),
                              _vm.form.product_type === 2
                                ? _c("el-option", {
                                    attrs: { label: "条", value: "条" }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "付款金额", prop: "pay_money" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number", placeholder: "请输入付款金额" },
                      model: {
                        value: _vm.form.pay_money,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "pay_money", $$v)
                        },
                        expression: "form.pay_money"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "付款方式" } },
                [
                  _c(
                    "el-select",
                    {
                      class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                      attrs: {
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        placeholder: "请选择付款方式"
                      },
                      model: {
                        value: _vm.form.pay_type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "pay_type", $$v)
                        },
                        expression: "form.pay_type"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "现金", value: "现金" }
                      }),
                      _c("el-option", {
                        attrs: { label: "微信", value: "微信" }
                      }),
                      _c("el-option", {
                        attrs: { label: "淘宝", value: "淘宝" }
                      }),
                      _c("el-option", {
                        attrs: { label: "支付宝", value: "支付宝" }
                      }),
                      _c("el-option", {
                        attrs: { label: "银行卡", value: "银行卡" }
                      }),
                      _c("el-option", {
                        attrs: { label: "信用卡", value: "信用卡" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "购买时间", prop: "pay_time" } },
                [
                  _c("el-date-picker", {
                    class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                    attrs: {
                      type: "datetime",
                      placeholder: "选择购买时间",
                      "default-time": "00:00:00",
                      "value-format": "yyyy-MM-dd HH:mm"
                    },
                    model: {
                      value: _vm.form.pay_time,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "pay_time", $$v)
                      },
                      expression: "form.pay_time"
                    }
                  })
                ],
                1
              ),
              _vm.handleId !== 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "退款" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.is_refund,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "is_refund", $$v)
                            },
                            expression: "form.is_refund"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("否")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.handleId !== 0 && _vm.form.is_refund === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "退款时间", prop: "refund_time" } },
                    [
                      _c("el-date-picker", {
                        class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                        attrs: {
                          type: "datetime",
                          placeholder: "选择退款时间",
                          "default-time": "00:00:00",
                          "value-format": "yyyy-MM-dd HH:mm"
                        },
                        model: {
                          value: _vm.form.refund_time,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "refund_time", $$v)
                          },
                          expression: "form.refund_time"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.handleId !== 0 && _vm.form.is_refund === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "退款金额", prop: "refund_money" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "number",
                            placeholder: "请输入退款金额"
                          },
                          model: {
                            value: _vm.form.refund_money,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "refund_money", $$v)
                            },
                            expression: "form.refund_money"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元")])],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入备注信息",
                      rows: "5"
                    },
                    model: {
                      value: _vm.form.notice,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "notice", $$v)
                      },
                      expression: "form.notice"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.innerVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm.handleId === 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleAdd("form")
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleModify("form")
                        }
                      }
                    },
                    [_vm._v("修 改")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "title_title" }, [_vm._v("收款信息")]),
          _c(
            "div",
            {
              staticClass: "title_icon cursor",
              on: { click: _vm.handleCloseDialog }
            },
            [_c("i", { staticClass: "el-icon-close" })]
          )
        ])
      ]),
      _c(
        "el-button",
        {
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: {
            click: function($event) {
              _vm.innerVisible = true
            }
          }
        },
        [_vm._v("添加")]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ]
        },
        [
          _c(
            "el-table",
            {
              staticClass: "m-t-20",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.list, stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "product_type", label: "类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.productType[row.product_type].name))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "buy_number",
                  label: "购买时长",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pay_money",
                  label: "购买金额",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "span",
                          { class: row.is_refund ? "text-danger" : "" },
                          [
                            _vm._v(
                              _vm._s(
                                row.pay_money +
                                  "元" +
                                  (row.is_refund
                                    ? "（已退款：" + row.refund_money + "）"
                                    : "")
                              )
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "pay_type", label: "购买方式", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pay_time",
                  label: "购买时间",
                  align: "center",
                  "min-width": _vm.equipment ? "" : "160"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "notice", label: "备注", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.notice ? row.notice : "暂无备注信息") +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "min-width": _vm.equipment ? "" : "180"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: { type: "primary", icon: "el-icon-edit" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(row.id)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "删除后将无法找回，确定继续吗？" },
                            on: {
                              confirm: function($event) {
                                return _vm.handleDelete(row.id)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "m-2",
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  icon: "el-icon-delete"
                                },
                                slot: "reference"
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableData.count > 0 && _vm.equipment,
                expression: "tableData.count > 0 && equipment"
              }
            ],
            attrs: {
              total: _vm.tableData.count,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.handleOpen
            }
          }),
          _c("van-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableData.count > 0 && !_vm.equipment,
                expression: "tableData.count > 0 && !equipment"
              }
            ],
            staticClass: "custom-pagination m-t-20",
            attrs: {
              "total-items": _vm.tableData.count,
              "show-page-size": 4,
              "force-ellipses": ""
            },
            on: { change: _vm.handleAccountChange },
            model: {
              value: _vm.AccountPage,
              callback: function($$v) {
                _vm.AccountPage = $$v
              },
              expression: "AccountPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }