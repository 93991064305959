var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wscn-http404-container" }, [
    _c("div", { staticClass: "wscn-http404" }, [
      _vm._m(0),
      _c("div", { staticClass: "bullshit" }, [
        _c("div", { staticClass: "bullshit__oops" }, [_vm._v("页面丢失了!")]),
        _c("div", { staticClass: "bullshit__info" }, [
          _vm._v("请检查您输入的网址是否正确，或点击下面的按钮返回首页。")
        ]),
        _c("div", { staticClass: "bullshit__info" }, [
          _vm._v("如您经常遇到这个问题，请联系售后客服处理： "),
          _c("br"),
          _c(
            "a",
            {
              staticStyle: { color: "#20a0ff" },
              attrs: { href: _vm.web_link, target: "_blank" }
            },
            [_vm._v(_vm._s(_vm.callNumber))]
          )
        ]),
        _c(
          "a",
          { staticClass: "bullshit__return-home", attrs: { href: "/" } },
          [_vm._v("返回主页")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pic-404" }, [
      _c("img", {
        staticClass: "pic-404__parent",
        attrs: { src: require("@/assets/404_images/404.png"), alt: "404" }
      }),
      _c("img", {
        staticClass: "pic-404__child left",
        attrs: { src: require("@/assets/404_images/404_cloud.png"), alt: "404" }
      }),
      _c("img", {
        staticClass: "pic-404__child mid",
        attrs: { src: require("@/assets/404_images/404_cloud.png"), alt: "404" }
      }),
      _c("img", {
        staticClass: "pic-404__child right",
        attrs: { src: require("@/assets/404_images/404_cloud.png"), alt: "404" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }