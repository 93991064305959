<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <el-form-item label="时间范围">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <!-- 售卡类型 -->
          <el-form-item label="活动名称">
            <Select :type="7" @changeId="handleSellingCardsID" />
          </el-form-item>
          <!-- 下单用户 -->
          <el-form-item label="下单用户" prop="memberID">
            <Select :type="2" @changeId="handleMemberID" />
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select v-model="listQueryParams.pay_status" placeholder="请选择" filterable clearable>
              <el-option
                v-for="(item,index) in status_list"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索订单号" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <!-- <el-table-column prop="id" label="Id" /> -->
      <el-table-column prop="number" label="订单号" width="150px" />
      <el-table-column prop="user.nickname" label="会员">
        <template slot-scope="{row}">
          <div v-if="row.user">{{ getUserNames(row.user) }}</div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <el-table-column prop="seckill_activity_id" label="活动名称" :formatter="seckillActivity" />
      <el-table-column prop="pay_price" label="订单金额" />
      <el-table-column prop="pay_status" label="订单状态" :formatter="orderStatus">
        <template slot-scope="{row}">
          <el-tag v-if="row.pay_status==3" type="danger">{{ row.pay_status_text }}</el-tag>
          <el-tag v-if="row.pay_status==2" type="info">{{ row.pay_status_text }}</el-tag>
          <el-tag v-if="row.pay_status==1" type="success">{{ row.pay_status_text }}</el-tag>
          <el-tag v-if="row.pay_status==0" type="warning">{{ row.pay_status_text }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="下单时间" />
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { getSeckillOrderList } from '@/api/store/seckill.js'
import Pagination from '@/components/Pagination' // 分页组件
import Select from '@/components/elSelect/Select'

export default {
  components: { Pagination, Select },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      dialog: { visible: false, id: '' },
      loading: { listLoading: false },
      form: {
        name: '',
        price: '',
        line_price: '0',
        time: '',
        time_type: 'month'
      },
      searchLoading: false,
      status_list: [
        { label: '待支付', value: 0 },
        { label: '已支付', value: 1 },
        { label: '交易关闭', value: 2 },
        { label: '已退款', value: 3 }
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'number', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.pay_status !== '' && this.listQueryParams.pay_status != null) querys.push({ field: 'pay_status', key: this.listQueryParams.pay_status })
      if (this.listQueryParams.user_id !== '' && this.listQueryParams.user_id != null) querys.push({ field: 'user_id', key: this.listQueryParams.user_id })
      if (this.listQueryParams.seckill_activity_id !== '' && this.listQueryParams.seckill_activity_id != null) querys.push({ field: 'seckill_activity_id', key: this.listQueryParams.seckill_activity_id })
      if (this.listQueryParams.create_time) querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })

      data.query = this.utils.getQueryParams(querys)

      getSeckillOrderList(data).then(res => {
        this.tableData = res.data
        this.loading.listLoading = false
        this.searchLoading = false
      })
    },
    seckillActivity(i, v) {
      if (i.seckill_activity_backup) {
        var info = i.seckill_activity_backup
        return info.name
      }
    },
    orderStatus(i, v) {
      var str = []
      switch (i.pay_status) {
        case 0:
          str.push('待支付')
          break
        case 1:
          str.push('已支付')
          break
        default:
          break
      }
      return str.join(',')
    },
    handleSellingCardsID(val) {
      this.listQueryParams.seckill_activity_id = val
    },
    handleMemberID(val) {
      this.listQueryParams.user_id = val
    }
  }
}
</script>

<style  lang="scss" scoped>
.el-input-group__append, .el-input-group__prepend{
  width: 70px;
}
</style>
