import request from '@/utils/request'

// 获取短信套餐列表
export function getPlan(data) {
  return request({
    url: '/admin/v1/sms_plan',
    method: 'get',
    params: data
  })
}

// 删除短信套餐
export function delPlan(id, data) {
  return request({
    url: `/admin/v1/sms_plan/${id}`,
    method: 'delete',
    params: data
  })
}

// 新增短信套餐
export function addPlan(data) {
  return request({
    url: '/admin/v1/sms_plan',
    method: 'post',
    data
  })
}

// 修改短信套餐
export function editPlan(id, data) {
  return request({
    url: `/admin/v1/sms_plan/${id}`,
    method: 'put',
    data
  })
}

// 短信套餐详情
export function infoPlan(id, data) {
  return request({
    url: `/admin/v1/sms_plan/${id}`,
    method: 'get',
    params: data
  })
}

// 短信发送记录
export function record(data) {
  return request({
    url: `/admin/v1/sms_record`,
    method: 'get',
    params: data
  })
}

// 短信重发
export function resend(id) {
  return request({
    url: `/admin/v1/sms_resend/${id}`,
    method: 'put'
  })
}

// 查看验证码
export function code(params) {
  return request({
    url: `/admin/v1/sms_code`,
    method: 'get',
    params
  })
}

// IP黑名单管理
export function ip_blacklist(data) {
  return request({
    url: `/admin/v1/sms/ip_blacklist`,
    method: 'put',
    data
  })
}
