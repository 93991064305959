import { constantRoutesConcat, concat } from '@/router'
import Layout from '@/layout'

var access_list = []

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  // if (!routes.children || !Array.isArray(routes.children)) {
  //   return []
  // }
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

/**
 * 后台查询的菜单数据拼装成路由格式的数据
 * @param routes
 */
export function generaMenu(routes, data) {
  data.forEach(item => {
    const menu = {
      path: item.path === '' ? 'key_' + item.id : item.path,
      component: item.component === '#' || !item.component ? Layout : (resolve) => require([`@/views${item.component}`], resolve),
      meta: {}
    }
    if (item.name) {
      access_list.push(item.name)
      menu.name = item.name
    }
    // type 0 代表路由 1则代表按钮 接入路由的时候不要把按钮的也接入进来
    if (item.type === 1) return false
    if (item.type === 0) menu.type = item.type
    if (item.title) menu.title = item.title
    if (item.hidden) menu.hidden = item.hidden
    if (item.roles) menu.meta.roles = item.roles.split(',')
    if (item.title) menu.meta.title = item.title
    if (item.icon) menu.meta.icon = item.icon
    if (item.activeMenu) menu.meta.activeMenu = item.activeMenu
    if (item.redirect) menu.redirect = item.redirect
    if (item.is_root) menu.alwaysShow = true
    if (item.children) {
      menu.children = []
      generaMenu(menu.children, item.children)
    }
    routes.push(menu)
  })
  localStorage.setItem('access_list', access_list)
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = routes
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      const loadMenuData = []
      // 先查询后台并返回左侧菜单数据并把数据添加到路由
      const userAccess = JSON.parse(localStorage.getItem('authority') === 'admin' ? localStorage.getItem('adminAccess') : localStorage.getItem('userAccess'))
      Object.assign(loadMenuData, userAccess)
      var newRole = concat()
      let type = roles
      if (localStorage.getItem('is_super') === 'false') {
        // newRole = deepClone(asyncRoutes)
        // 合并后端权限与前端权限
        generaMenu(newRole, loadMenuData)
        // is_super 为false时就不走 路由筛选
        // 注释掉的是： 如果登录权限是admin 就显示全部的菜单栏，不会进行筛选['store'])
        if (localStorage.getItem('authority') === 'admin') type = ['store']
        else type = roles
      }
      var accessedRoutes = filterAsyncRoutes(newRole, type)
      /**
       * 0、公共路由
       * 1、工作台
       * 2、动态
       * 3、plan
       * 4、404
       */
      var constantRoutes = constantRoutesConcat(accessedRoutes)
      commit('SET_ROUTES', constantRoutes)
      resolve(constantRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
