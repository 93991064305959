<template>
  <el-dialog
    :visible.sync="dialogVisibleApiRoute"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
    :show-close="false"
    @open="handleOpen"
    @close="handleClose('ruleForm')"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">{{ type === 'add' ? '新增分类' : '编辑分类' }}</div>
        <div class="title_icon cursor" @click="handleClose('ruleForm')">
          <i class="el-icon-close" />
        </div>
      </div>
      <el-form ref="ruleForm" v-loading="formLoading" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm m-t-40">
        <el-form-item label="父级分类" prop="pid">
          <SelectTree
            :props="defaultProps"
            :options="classDetails"
            :value="ruleForm.pid"
            :clearable="isClearable"
            :accordion="isAccordion"
            :root-name="'顶级分类'"
            @getValue="getValue($event)"
          />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name" clearable />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="buttonLoading" @click="handleClose('ruleForm')">取 消</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { getApiRouteCategoryList, addApiRouteCategory, updateApiRouteCategory, getApiRouteCategoryDetail } from '@/api/admin/account.js'
// 组件
import SelectTree from '@/components/TreeSelect/treeSelect.vue'
// 函数
import { deepClone } from '@/utils'

export default {
  components: { SelectTree },
  props: {
    dialogVisibleApiRoute: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    typeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: {
        pid: 0,
        name: ''
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      roleList: [],
      classDetails: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      isClearable: false, // 可清空（可选）
      isAccordion: false, // 可收起（可选）
      buttonLoading: false,
      formLoading: false
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.$nextTick(() => {
        this.getClassDetails()
        if (this.type === 'edit') this.getDetails()
      })
    },
    // 关闭的回调
    handleClose(formName) {
      this.$emit('apiRouteVisible')
      this.$refs[formName].resetFields()
    },
    // 保存表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          const data = deepClone(this.ruleForm)
          if (this.type === 'add') {
            addApiRouteCategory(data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('新增成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          } else {
            updateApiRouteCategory(this.typeId, data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('编辑成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    // 详情
    getDetails() {
      this.formLoading = true
      getApiRouteCategoryDetail(this.typeId)
        .then(res => {
          const { data } = res
          const { pid, name } = data
          this.ruleForm = { pid, name }
        })
        .finally(() => {
          this.formLoading = false
        })
    },
    // 分类详情
    getClassDetails() {
      getApiRouteCategoryList()
        .then(res => {
          const { data } = res
          this.classDetails = data
        })
    },
    // 取值
    getValue(value) {
      this.ruleForm.pid = value
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
