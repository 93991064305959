<template>
  <div class="">
    <el-form ref="form" :rules="rules" :model="form" label-width="120px" label-position="left">
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          预约时间设置
        </div>
        <div class="item_content m-y-10">
          <el-form-item label="预约方式">
            <el-radio-group v-model="form.private_subscribe.type" @change="handleRadio">
              <el-radio :label="1">私教排课模式</el-radio>
              <el-radio :label="2">自由预约模式</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="私教预约">
            <div class="flex flex-col col-top row-left">
              <div class="flex m-y-10">
                <div>每天</div>
                <div>
                  <el-time-picker
                    v-model="form.private_subscribe.start_time"
                    is-range
                    arrow-control
                    range-separator="-"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    format="HH:mm"
                    value-format="HH:mm"
                    class="w-250"
                    :clearable="false"
                  />
                </div>
                <div>接受自主预约</div>
              </div>
              <div class="flex flex-1">
                <!-- <div v-if="form.private_subscribe.type === 1">教练空闲时间可约，</div> -->
                <div>会员需早于开课前</div>
                <div>
                  <el-form-item prop="before_course_minute">
                    <el-input
                      v-model="form.private_subscribe.before_course_minute"
                      type="number"
                      size="mini"
                      placeholder="请输入内容"
                      class="w-120 m-x-5"
                    />
                  </el-form-item>
                </div>
                <div>分钟自主预约</div>
              </div>
            </div>
            <p v-if="form.private_subscribe.type === 2">
              课前休息
              <el-input
                v-model="form.private_subscribe.before_rest"
                type="number"
                size="mini"
                placeholder="请输入内容"
                class="w-120 m-x-5"
              />
              分钟，课后休息
              <el-input
                v-model="form.private_subscribe.after_rest"
                size="mini"
                placeholder="请输入内容"
                class="w-120"
                type="number"
              />
              分钟
            </p>
            <p v-if="form.private_subscribe.type === 2">
              教练可预约时间段每
              <el-input
                v-model="form.private_subscribe.interval"
                type="number"
                size="mini"
                placeholder="请输入内容"
                class="w-120 m-x-5"
              />
              分钟为一个间隔
            </p>
          </el-form-item>
        </div>
      </div>
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          签到设置
        </div>
        <div class="item_content m-y-10">
          <el-form-item label="自主签到">
            <el-radio-group v-model="form.private_sign.autonomously_status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.private_sign.autonomously_status" label="签到时间">
            <el-radio-group v-model="form.private_sign.autonomously_time_type">
              <div class="flex flex-col col-top row-left">
                <el-radio :label="1" class="m-y-5">课程当天，会员可随时签到</el-radio>
                <el-radio :label="2" class="m-y-5">课程当天，会员可在课程结束前签到</el-radio>
                <el-radio :label="3" class="flex">
                  <div class="flex row-left flex-wrap">
                    <div>课程当天，会员可在课程结束后</div>
                    <div>
                      <el-form-item prop="after_1">
                        <el-input
                          v-model="form.private_sign.after_1"
                          size="mini"
                          placeholder=""
                          class="w-100 m-x-5"
                          type="number"
                        />
                      </el-form-item>
                    </div>
                    <div>分钟内签到</div>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="扫码签到">
            <el-radio-group v-model="form.private_sign.scan_code_status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.private_sign.scan_code_status" label="签到时间">
            <el-radio-group v-model="form.private_sign.scan_code_time_type">
              <div class="flex flex-col col-top row-left">
                <el-radio :label="1" class="m-y-5 flex">
                  <div>课程当天，会员可签到两小时内开始的课程</div>
                </el-radio>
                <el-radio :label="2" class="m-y-5 flex">
                  <div class="flex">课程当天，会员可在课程结束前扫码签到</div>
                </el-radio>
                <el-radio :label="3" class="flex">
                  <div class="flex">
                    <div>课程当天，会员可在课程开始前</div>
                    <div>
                      <el-form-item prop="before">
                        <el-input
                          v-model="form.private_sign.before"
                          size="mini"
                          placeholder="请输入内容"
                          class="w-100 m-x-5"
                          type="number"
                        />
                      </el-form-item>
                    </div>
                    <div>分钟至课程结束后</div>
                    <div>
                      <el-form-item prop="after_2">
                        <el-input
                          v-model="form.private_sign.after_2"
                          size="mini"
                          placeholder="请输入内容"
                          class="w-100 m-x-5"
                          type="number"
                        />
                      </el-form-item>
                    </div>
                    <div>分钟内扫码签到</div>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <!-- <el-alert
        title="预约取消设置仅针对会员支付的约课，无卡支付的约课暂不支持会员自主预约取消"
        type="warning"
        show-icon
        :closable="false"
      /> -->
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          预约取消设置
        </div>
        <div class="item_content m-y-10">
          <el-form-item label="自主取消">
            <el-radio-group v-model="form.private_cancel_subscribe.status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.private_cancel_subscribe.status" label="取消时间">
            <el-radio-group v-model="form.private_cancel_subscribe.time_type">
              <div class="flex flex-col col-top row-left">
                <el-radio :label="1" class="flex ">
                  <div class="flex">
                    <div>会员自主取消不晚于开课前</div>
                    <div>
                      <el-form-item prop="minute_1">
                        <el-input
                          v-model="form.private_cancel_subscribe.minute_1"
                          size="mini"
                          placeholder="请输入内容"
                          class="w-100 m-x-5 m-y-8"
                          type="number"
                        />
                      </el-form-item>
                    </div>

                    <div>分钟</div>
                  </div>
                </el-radio>
                <el-radio :label="2" class="flex ">
                  <div class="flex">
                    <div>会员自主取消不晚于开课后</div>
                    <div>
                      <el-form-item prop="minute_2">
                        <el-input
                          v-model="form.private_cancel_subscribe.minute_2"
                          size="mini"
                          placeholder="请输入内容"
                          class="w-100 m-x-5 m-y-8"
                          type="number"
                        />
                      </el-form-item>
                    </div>
                    <div>分钟</div>
                  </div>
                </el-radio>
                <el-radio :label="3" class="flex ">
                  <div class="flex">
                    <div>会员自主取消不晚于课程结束后</div>
                    <div>
                      <el-form-item prop="minute_3">
                        <el-input
                          v-model="form.private_cancel_subscribe.minute_3"
                          size="mini"
                          placeholder="请输入内容"
                          class="w-100 m-x-5 m-y-8"
                          type="number"
                        />
                      </el-form-item>
                    </div>
                    <div>分钟</div>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>

      <div class="footer">
        <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
        <el-button @click="returnPage()">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getSubscribeSetting, editSubscribeSetting } from '@/api/store/subscribe'
import { deepClone } from '@/utils'
export default {
  data() {
    var bcmV = (rule, value, callback) => {
      if (!/^\d+$/.test(this.form.private_subscribe.before_course_minute)) {
        callback(new Error('请输入整数'))
      }
      callback()
    }
    var bV = (rule, value, callback) => {
      if (this.form.private_sign.scan_code_time_type === 3) {
        if (!/^\d+$/.test(this.form.private_sign.before)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var af1V = (rule, value, callback) => {
      if (this.form.private_sign.autonomously_time_type === 3) {
        if (!/^\d+$/.test(this.form.private_sign.after_1)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var af2V = (rule, value, callback) => {
      if (this.form.private_sign.scan_code_time_type === 3) {
        if (!/^\d+$/.test(this.form.private_sign.after_2)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var m1V = (rule, value, callback) => {
      if (this.form.private_cancel_subscribe.time_type === 1) {
        if (!/^\d+$/.test(this.form.private_cancel_subscribe.minute_1)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var m2V = (rule, value, callback) => {
      if (this.form.private_cancel_subscribe.time_type === 2) {
        if (!/^\d+$/.test(this.form.private_cancel_subscribe.minute_2)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var m3V = (rule, value, callback) => {
      if (this.form.private_cancel_subscribe.time_type === 3) {
        if (!/^\d+$/.test(this.form.private_cancel_subscribe.minute_3)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    return {
      form: {
        // 预约设置
        private_subscribe: {
          type: 1,
          start_time: ['00:00', '23:59'],
          end_time: '',
          before_course_minute: 30,
          before_rest: 0,
          after_rest: 0,
          interval: 30
        },
        private_sign: {
          autonomously_status: 0,
          autonomously_time_type: 1,
          scan_code_status: 1,
          scan_code_time_type: 1,
          before: 5,
          after_1: 15,
          after_2: 15
        },
        private_cancel_subscribe: {
          status: 1,
          time_type: 1,
          minute_1: 120,
          minute_2: 120,
          minute_3: 120
        }
      },
      list: {},
      rules: {
        before_course_minute: [{ validator: bcmV, trigger: ['blur', 'change'] }],
        before: [{ validator: bV, trigger: ['blur', 'change'] }],
        after_1: [{ validator: af1V, trigger: ['blur', 'change'] }],
        after_2: [{ validator: af2V, trigger: ['blur', 'change'] }],
        minute_1: [{ validator: m1V, trigger: ['blur', 'change'] }],
        minute_2: [{ validator: m2V, trigger: ['blur', 'change'] }],
        minute_3: [{ validator: m3V, trigger: ['blur', 'change'] }]
      },
      submitLoading: false
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function(val, oldVal) {}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    submit() {
      this.submitLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          // if (this.form.private_subscribe.type === 1) {
          //   this.$message.error('暂不支持私教排课模式')
          //   return false
          // }
          const formData = deepClone(this.form)
          for (var i in this.list) {
            const item = this.list[i]
            delete this.list[i]['appid']
            delete this.list[i]['update_time']
            delete this.list[i]['create_time']
            console.log(
              '🚀 ~ file: setting.vue ~ line 231 ~ submit ~ formData[item.name]',
              item.name,
              formData[item.name]
            )
            this.list[i]['content'] = JSON.stringify(formData[item.name])
          }
          var data = this.list
          console.log('🚀 ~ file: setting.vue ~ line 232 ~ editSubscribeSetting ~ data', data)
          editSubscribeSetting(data)
            .then(res => {
              this.$message.success('保存成功')
              this.returnPage()
            })
            .finally(() => {
              this.submitLoading = false
            })
        } else {
          console.log('error submit!!')
          this.submitLoading = false
          return false
        }
      })
    },
    getList() {
      getSubscribeSetting({ limit: 999 }).then(res => {
        this.list = res.data.list
        this.form = res.data.res
        if (this.form.private_subscribe.start_time === '00:00') {
          this.form.private_subscribe.start_time = ['00:00', '23:59']
        }
      })
    },
    handleRadio(val) {
      if (val === 1) {
        this.$confirm(`切换私教自由预约模式为排课模式后，将取消所有未结束课程的私教预约记录，请谨慎操作！`, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
          .catch(() => {
            this.form.private_subscribe.type = 2
          })
      } else if (val === 2) {
        this.$confirm(`切换私教排课模式为自由预约模式后，将删除所有未开始私教课程表并取消相关预约记录，请谨慎操作！`, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
          .catch(() => {
            this.form.private_subscribe.type = 1
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item_title {
  border-left: 5px solid #3498db;
  // font-size: 16px;
}
.el-form-item--small.el-form-item {
  margin-bottom: 3px !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #606266 !important;
}
p {
  margin-bottom: 5px;
  margin-top: 5px;
}
</style>
