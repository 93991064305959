<template>
  <el-card>
    <div slot="header" class="clearfix flex">
      <span>{{ type === 1 ? '我的相册' : type === 2 ? '员工相册' : type === 3 ? '场馆相册' : type === 4 ? '课程相册' : '' }}</span>
      <div v-show="btn_access('s_venues_photo_sort') || btn_access('s_course_photo_sort')" class="m-l-10">
        <el-button v-if="!is_sort" type="primary" size="mini" plain @click="handleSort">排 序</el-button>
        <el-button v-if="is_sort" type="success" size="mini" plain :loading="buttonLoading" @click="handlePreserve">保 存</el-button>
        <el-button v-if="is_sort" type="danger" size="mini" plain :loading="buttonLoading" @click="handleCancel">取 消</el-button>
      </div>
    </div>
    <div v-if="!is_sort" v-loading="dialogLoading">
      <el-upload
        field="file"
        :action="`${url}${type === 1 ? '/store/v2/userInfo/photo' : type === 2 ? `/store/v2/store_user/photo/${id}` : type === 3 ? `/store/v2/venues/photo?venues_id=${id}` : type === 4 ? `/store/v2/course/photo/${id}` : ''}`"
        list-type="picture-card"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success="upSuccesss"
        :headers="utils.upload_headers()"
        :file-list="fileList"
        :limit="9"
        :on-exceed="handleExceed"
        :on-progress="upProgresss"
        :before-upload="checkFileType"
        accept=".jpg,.jpeg,.png"
      >
        <i class="el-icon-plus" />
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" class="dialog_view_image">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      <small class="text-info">图片尺寸建议750*1000</small>
    </div>
    <div v-if="is_sort">
      <div ref="multipleTable" class="flex flex-wrap">
        <div v-for="(item,index) in fileList" :key="index" class="m-r-8 m-b-8 cursor_move images">
          <img style="width: 100%; height: 100%" :src="item.url">
        </div>
      </div>
      <small class="text-info">拖拽图片进行排序</small>
    </div>
  </el-card>
</template>

<script>
// API
import { getImg, deleteImg, putAlbumSort } from '@/api/store/user.js'
import { getImgs, deleteImges, staffSort } from '@/api/store/config.js'
import { getVenueAlbum, deleteVenueAlbum, putVenueAlbum } from '@/api/store/venues.js'
import { getCurriculum, deleteCurriculum, putCurriculum } from '@/api/store/course.js'
//
import Sortable from 'sortablejs'
import { deepClone } from '@/utils'

export default {
  props: {
    type: {
      type: Number,
      default: 0
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialogLoading: false,
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      dataList: [],
      idArr: [],
      url: process.env.VUE_APP_BASE_API,
      is_sort: false,
      buttonLoading: false
    }
  },
  created() {},
  mounted() {
    this.handleAdd()
  },
  methods: {
    handleAdd() {
      this.dialogLoading = true
      if (this.type === 1) {
        getImg().then(res => {
          var imagesArrs = []
          res.data.list.forEach(function(i) {
            imagesArrs.push({
              name: '123123',
              url: i.image,
              id: i.id
            })
          })
          this.fileList = imagesArrs
        }).finally(() => {
          setTimeout(() => {
            this.dialogLoading = false
          }, 800)
        })
      }
      if (this.type === 2) {
        getImgs(this.id).then(res => {
          var imagesArrs = []
          res.data.list.forEach(function(i) {
            imagesArrs.push({
              name: '123123',
              url: i.image,
              id: i.id
            })
          })
          this.fileList = imagesArrs
        }).finally(() => {
          setTimeout(() => {
            this.dialogLoading = false
          }, 800)
        })
      }
      if (this.type === 3) {
        getVenueAlbum({ venues_id: this.id }).then(res => {
          var imagesArrs = []
          res.data.list.forEach(function(i) {
            imagesArrs.push({
              name: '123123',
              url: i.image,
              id: i.id
            })
          })
          this.fileList = imagesArrs
        }).finally(() => {
          setTimeout(() => {
            this.dialogLoading = false
          }, 800)
        })
      }
      if (this.type === 4) {
        getCurriculum(this.id).then(res => {
          var imagesArrs = []
          res.data.list.forEach(function(i) {
            imagesArrs.push({
              name: '123123',
              url: i.image,
              id: i.id
            })
          })
          this.fileList = imagesArrs
        }).finally(() => {
          setTimeout(() => {
            this.dialogLoading = false
          }, 800)
        })
      }
    },
    upSuccesss(response, file, fileList) {
      if (response.status !== 200) {
        // this.$message.error(response.msg)
        return
      }
      this.fileList = []
      this.dialogLoading = true
      setTimeout(() => {
        this.handleAdd()
        this.dialogLoading = false
      }, 800)
    },
    checkFileType(file) {
      console.log(file, 1)
      if (file.size / 4000 / 4000 > 0.1) {
        this.$message.warning('上传图片过大，请重新上传！')
        return false
      }
    },
    upProgresss() {},
    handleRemove(file, fileList) {
      if (!file.id) return false
      console.log(file, 2)
      this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (file.id === undefined) return false
        if (this.type === 1) deleteImg(file.id)
        if (this.type === 2) deleteImges(file.id)
        if (this.type === 3) deleteVenueAlbum(file.id)
        if (this.type === 4) deleteCurriculum(file.id)
        setTimeout(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      }).catch(() => {
        this.handleAdd()
      })
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleExceed(files, fileList) {
      this.$message.warning(`仅支持上传九张相册图片`)
    },
    handleClose(done) {
      done()
      this.fileList = []
    },
    // 开启排序
    handleSort() {
      this.is_sort = true
      setTimeout(() => {
        this.dataList = deepClone(this.fileList)
        const tableElement = this.$refs.multipleTable
        new Sortable(tableElement, {
          animation: 600, // 拖拽动画时间（以毫秒为单位）
          onChoose: this.handleChoose, // 选择元素时的回调函数
          onEnd: this.handleEnd // 拖拽结束时的回调函数
        })
      }, 0
      )
    },
    // 保存排序
    handlePreserve() {
      if (this.idArr.length === 0) {
        this.is_sort = false
        return
      }
      this.buttonLoading = true
      if (this.type === 1) {
        putAlbumSort({ ids: this.idArr }).then(res => {
          this.handleAdd()
          this.is_sort = false
        }).finally(() => {
          this.buttonLoading = false
        })
      }
      if (this.type === 2) {
        staffSort(this.id, { ids: this.idArr }).then(res => {
          this.handleAdd()
          this.is_sort = false
        }).finally(() => {
          this.buttonLoading = false
        })
      }
      if (this.type === 3) {
        putVenueAlbum({ ids: this.idArr }).then(res => {
          this.handleAdd()
          this.is_sort = false
        }).finally(() => {
          this.buttonLoading = false
        })
      }
      if (this.type === 4) {
        putCurriculum(this.id, { ids: this.idArr }).then(res => {
          this.handleAdd()
          this.is_sort = false
        }).finally(() => {
          this.buttonLoading = false
        })
      }
    },
    handleCancel() {
      this.is_sort = false
    },
    handleChoose(evt) {},
    handleEnd(evt) {
      this.idArr = []
      // 初始位置
      const oldIndex = evt.oldIndex
      // 更换后的位置
      const newIndex = evt.newIndex
      // 交换数据
      const oldData = this.dataList[oldIndex]
      this.dataList.splice(oldIndex, 1)
      this.dataList.splice(newIndex, 0, oldData)

      setTimeout(() => {
        // 获取每个元素的id
        this.dataList.forEach(item => {
          this.idArr.push(item.id)
        })
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload-list__item {
  width: 100px !important;
  height: 100px !important;
}
::v-deep .el-upload {
  width: 100px !important;
  height: 100px !important;
  line-height: 110px !important;
}
::v-deep .el-upload-list__item {
  transition: none !important;
}

.images {
  width: 100px;
  height: 100px;
  border: 1px solid #c0ccda;
  border-radius: 6px;
}
</style>
