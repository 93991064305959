<template>
  <div class="app-container">
    <el-tabs v-model="titleName">
      <el-tab-pane label="营销活动海报" name="posters_marketing" />
      <el-tab-pane label="非活动海报" name="posters" />
    </el-tabs>
    <el-row>
      <el-col :span="2">
        <el-tabs
          v-model="contentName"
          tab-position="left"
          style="height: 80vh;"
          class="m-t-20"
          @tab-click="handleGetList"
        >
          <el-tab-pane v-for="(item, index) in dataList.list" :key="index" :label="item.name" :name="item.id" />
        </el-tabs>
      </el-col>
      <el-col :span="22">
        <el-skeleton v-if="imgList.count > 0" :loading="skeletonLoading" animated>
          <template slot="template">
            <div class="scrollBar">
              <el-skeleton-item
                v-for="(item, index) in imgList.list"
                :key="index"
                class="m-10"
                variant="image"
                style="width: 180px; height: 340px;"
              />
            </div>
          </template>
          <template>
            <div class="scrollBar">
              <el-image
                v-for="(item, index) in imgList.list"
                :key="index"
                class="cursor m-10"
                style="width: 180px; height: 340px;"
                :src="item.preview_image"
                lazy
                @click="handlePreview(item.id)"
              />
            </div>
          </template>
        </el-skeleton>
        <div v-else class="venuesList_empty text-center p-10">
          <img :src="empty_default_img" alt="">
          <div><div class="text-info">当前分类暂无海报</div></div>
        </div>
      </el-col>
    </el-row>
    <elDialog :id="imgId" :dialog-visible="dialogVisible" :title-name="titleName" @cancel="handleCancel" />
  </div>
</template>

<script>
// API
import { getPosterCategory, getPosterTemplate } from '@/api/store/posterGeneration.js'
// 组件
import elDialog from './components/dialog.vue'

export default {
  components: {
    elDialog
  },
  data() {
    return {
      dataList: {
        count: 0,
        list: []
      },
      imgList: {},
      imgId: 0,
      titleName: 'posters_marketing',
      contentName: '',
      skeletonLoading: false,
      dialogVisible: false
    }
  },
  watch: {
    titleName(val) {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      const data = {
        limit: 999
      }

      var querys = []
      if (this.titleName) querys.push({ field: 'type', key: this.titleName })
      data.query = this.utils.getQueryParams(querys)

      getPosterCategory(data).then(res => {
        this.dataList = res.data
        this.dataList.list.forEach(item => {
          item.id = item.id + ''
        })
        this.contentName = this.dataList.list[0].id
        this.handleGetList()
      })
    },
    handleGetList() {
      this.skeletonLoading = true
      // 转换成数字类型
      const category_id = parseInt(this.contentName)

      const data = {
        limit: 999
      }

      var querys = []
      if (category_id) querys.push({ field: 'category_id', key: category_id })
      data.query = this.utils.getQueryParams(querys)

      getPosterTemplate(data).then(res => {
        this.imgList = res.data
        this.skeletonLoading = false
      })
    },
    handlePreview(id) {
      this.dialogVisible = true
      this.imgId = id
    },
    handleCancel() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollBar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 82vh;
  overflow-y: scroll;
}
.venuesList_empty{
  min-height: 400px;
  img {
    -webkit-user-drag: none;
  }
}
</style>
