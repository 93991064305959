<template>
  <div v-loading="weChatLoading">
    <div v-if="!is_show" class="weChat_bg m-b-15">
      <div>点此去注册小程序</div>
      <el-button class="m-x-20" type="primary" @click="toPage()">注册</el-button>
    </div>
    <el-row>
      <el-col :span="14">
        <!-- 场馆信息 -->
        <el-card class="m-r-20">
          <div slot="header" class="clearfix">
            <span>场馆信息</span>
            <el-button type="primary" class="m-l-10" size="mini" @click="refresh">刷新品牌二维码</el-button>
          </div>
          <div class="information">
            <div class="information_line flex">
              <div class="information_name">品牌ID</div>
              <div class="information_content">{{ $route.params.id }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">品牌名称</div>
              <div class="information_content">{{ appData.name }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">地区</div>
              <div class="information_content">{{ appData.location ? appData.location : '---' }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">到期时间</div>
              <div class="information_content">{{ appData.expiration_time | formatDate('YYYY-MM-DD') }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">品牌编码</div>
              <div class="information_content">{{ appData.url ? appData.url.split('=')[1] : '---' }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">品牌二维码</div>
              <div class="information_content">
                <el-image class="m-r-10" style="width: 50px; height: 50px;" :src="appData.program_open_path" :preview-src-list="[appData.program_open_path]" />
                <el-image style="width: 50px; height: 50px;" :src="appData.wechat_open_path" :preview-src-list="[appData.wechat_open_path]" />
              </div>
            </div>
          </div>
        </el-card>
        <!-- 服务器域名 -->
        <el-card class="m-t-20 m-r-20">
          <div slot="header" class="clearfix">
            <span>服务器域名</span>
          </div>
          <div v-if="is_showUrl">
            <div class="information">
              <div class="information_line flex">
                <div class="information_name">request合法域名</div>
                <div class="information_content">
                  <div v-for="(item, index) in networkList.RequestDomain" :key="index" class="url">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="information">
              <div class="information_line flex">
                <div class="information_name">socket合法域名</div>
                <div class="information_content">
                  <div v-for="(item, index) in networkList.WsRequestDomain" :key="index" class="url">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="information">
              <div class="information_line flex">
                <div class="information_name">uploadFile合法域名</div>
                <div class="information_content">
                  <div v-for="(item, index) in networkList.UploadDomain" :key="index" class="url">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="information">
              <div class="information_line flex">
                <div class="information_name">downloadFile合法域名</div>
                <div class="information_content">
                  <div v-for="(item, index) in networkList.DownloadDomain" :key="index" class="url">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="information">
              <div class="information_line flex">
                <div class="information_name">udp合法域名</div>
                <div class="information_content">
                  <div v-for="(item, index) in networkList.UploadDomain" :key="index" class="url">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="information">
              <div class="information_line flex">
                <div class="information_name">tcp合法域名</div>
                <div class="information_content">
                  <div v-for="(item, index) in networkList.TCPDomain" :key="index" class="url">{{ item }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>数据为空</div>
        </el-card>
      </el-col>
      <el-col :span="10">
        <!-- 基本信息 -->
        <el-card>
          <div slot="header" class="clearfix">
            <span>基本信息</span>
          </div>
          <div v-if="is_showId" class="information">
            <div class="information_line flex">
              <div class="information_name">AppID(小程序ID)</div>
              <div class="information_content">{{ dataSecretKey.mini_program_appid }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">原始ID</div>
              <div class="information_content">{{ dataSecretKey.mini_program_id }}</div>
            </div>
            <div v-if="is_show">
              <div class="information_line flex">
                <div class="information_name">小程序名称</div>
                <div class="information_content">{{ dataInfo.nick_name }}</div>
              </div>
              <!-- <div class="information_line flex">
              <div class="information_name">小程序简称</div>
              <div class="information_content">{{ dataInfo.signature }}</div>
            </div> -->
              <div class="information_line flex">
                <div class="information_name">小程序头像</div>
                <div class="information_content">
                  <div class="avatar">
                    <el-image :src="dataInfo.head_img" :preview-src-list="[dataInfo.head_img]" />
                  </div>
                </div>
              </div>
              <div class="information_line flex">
                <div class="information_name">小程序码</div>
                <div class="information_content">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="dataInfo.qrcode_url"
                    :preview-src-list="[dataInfo.qrcode_url]"
                  />
                </div>
              </div>
              <div class="information_line flex">
                <div class="information_name">介绍</div>
                <div class="information_content">{{ dataInfo.signature }}</div>
              </div>
              <div class="information_line flex">
                <div class="information_name">服务类目</div>
                <div v-if="Object.keys(dataInfo.miniprograminfo).length > 0" class="information_content">
                  <div v-if="dataInfo.miniprograminfo.categories.length > 0">
                    {{ dataInfo.miniprograminfo.categories[0].first }} >
                    {{ dataInfo.miniprograminfo.categories[0].second }}
                  </div>
                  <div v-else>---</div>
                </div>
                <div v-else class="information_content">---</div>
              </div>
              <div class="information_line flex">
                <div class="information_name">主体信息</div>
                <div class="information_content flex">
                  <div>
                    {{ data.principal_name }}
                    <span>（{{ dataInfo.principal_name }}）</span>
                  </div>
                </div>
              </div>
              <div class="information_line flex">
                <div class="information_name">微信认证</div>
                <div class="information_content">
                  <div>{{ dataInfo.verify_type_info === -1 ? '未认证' : '微信认证' }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>数据为空</div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// API
import { getMiniInfo, getAppDetails } from '@/api/admin/merchants.js'
import { refreshApp } from '@/api/admin/merchants.js'

export default {
  data() {
    return {
      dataInfo: {},
      data: {},
      dataSecretKey: {},
      networkList: {},
      appData: {},
      weChatLoading: false,
      is_show: false,
      is_showId: false,
      is_showUrl: false
    }
  },
  created() {
    getAppDetails(this.$route.params.id).then(res => {
      this.appData = res.data
    })
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.weChatLoading = true
      getMiniInfo(this.$route.params.id).then(res => {
        const data = res.data
        if (data.programsInfo && data.programs) {
          this.dataInfo = data.programsInfo
          this.data = data.programs
          this.is_show = true
          if (Object.keys(data.programsInfo.miniprograminfo).length > 0) {
            this.networkList = data.programsInfo.miniprograminfo.network
            this.is_showUrl = true
          }
        }
        // 判断data.programsSecretKey的数据类型是否为对象
        if (Object.prototype.toString.call(data.programsSecretKey) === '[object Object]') {
          this.dataSecretKey = data.programsSecretKey
          this.is_showId = true
        }
        this.weChatLoading = false
      })
    },
    // 立即注册
    toPage() {
      // this.$router.push({ name: 'adminSignWeChat', params: { id: this.$route.params.id }})
      // 子传父
      this.$emit('toPage')
    },
    refresh() {
      this.$confirm('刷新品牌信息及二维码，确定继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        refreshApp(this.$route.params.id).then(res => {
          this.$message.success('刷新成功')
          this.getList()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.weChat_bg {
  width: 100%;
  height: 60px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: rgb(225, 240, 255);
  padding: 10px 24px;
}
.information {
  width: 100%;
  .information_line {
    /* margin-bottom: 20px; */
    font-size: 13px;
    .information_name {
      /* width: 100px; */
      text-align: left;
      color: #9a9a9a;
      flex: 0.2;
    }
    .information_content {
      padding: 16px 0 16px 20px;
      color: #353535;
      flex: 0.8;
      /* text-align: right; */
      /* border-bottom: 1px solid #e7e7eb; */
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
      }
      .url {
        margin: 5px 0px;
      }
      div:nth-child(1) {
        span {
          color: #9a9a9a;
        }
      }
    }
  }
}
</style>
