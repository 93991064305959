<template>
  <div class="app-container special-center">
    <el-steps :active="active" finish-status="success" align-center @change="handleChange">
      <el-step title="课程信息" />
      <el-step title="支持卡类型" />
      <el-step title="课程预览" />
    </el-steps>
    <el-form v-if="active == 0" ref="ruleForm" :rules="rules" :model="form" label-position="left" label-width="100px">
      <h3>课程信息</h3>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item class="line-clear" label="课程图片" prop="image">
            <el-upload
              v-loading="upLoading"
              class="images-uploader"
              :action="storeUpload"
              :show-file-list="false"
              :on-success="upSuccess"
              :headers="utils.upload_headers()"
              :on-progress="upProgress"
              accept=".jpg,.jpeg,.png"
            >
              <img v-if="form.image" :src="form.image" class="images" title="点击上传图片">
              <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="line-clear" label="课程名称" prop="name">
            <el-input v-model="form.name" class="w-660" placeholder="请输入课程名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="line-clear" label="课程类型" prop="type">
            <el-radio-group v-model="form.type" @change="typeChange">
              <el-radio :label="0">团课</el-radio>
              <el-radio :label="2">精品课</el-radio>
              <el-radio :label="1">私教课</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="line-clear" label="课程难度" prop="type">
            <el-rate v-model="form.difficulty" class="p-t-4 curriculum_rate" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="教练" prop="store_user_id">
            <Select ref="coachRef" :exist-name="userName" :width="250" :type="4" :coach-type="coachType" @changeId="handleCoachID" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="line-clear" label="教室" prop="type">
            <el-select v-model="form.classroom_id" class="w-250" placeholder="请选择教室">
              <el-option v-for="item in classroom" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="line-clear" label="课程时长" prop="duration">
            <el-input v-model="form.duration" type="number" class="w-250" placeholder="请输入课程时长" oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')">
              <template slot="append">分钟</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="form.type != 1" :span="12">
          <el-form-item class="line-clear" label="容纳人数" prop="galleryful">
            <el-input v-model="form.galleryful" type="number" class="w-250" placeholder="请输入容纳人数" oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')">
              <template slot="append">人</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="line-clear" label="课程标签">
            <div>
              <div v-if="!utils.empty(labelList)">
                <el-tag v-for="(item, index) in labelList" :key="index" class="m-2" type="info">
                  {{ item ? item.name : '' }}
                </el-tag>
              </div>
              <div><el-button type="primary" size="mini" @click="selectLabel">选择标签</el-button></div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="允许付费约课">
            <el-switch v-model="form.is_pay" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-col>
        <el-col v-if="form.is_pay" :span="9">
          <el-form-item label="优惠价(元/次)" prop="price">
            <el-input v-model="form.price" type="number" oninput="if(value<0)value=0" placeholder="请输入优惠价">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="form.is_pay" :span="9">
          <el-form-item label="原价(元/次)" prop="original_price">
            <el-input v-model="form.original_price" type="number" oninput="if(value<0)value=0" placeholder="请输入原价">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="line-clear" label="简介">
            <el-input v-model="form.abstract" type="textarea" class="w-660" placeholder="请输入简介" rows="3" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form v-if="active != 0" :inline="true">
      <h3>课程类型</h3>
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-order" />
            课程名称
          </template>
          {{ form.name }}
        </el-descriptions-item>
        <el-descriptions-item label="">
          <template slot="label">
            <i class="el-icon-s-flag" />
            课程类型
          </template>
          {{ form.type == 0 ? '团课' : form.type == 2 ? '精品课' : '私教课' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-custom" />
            教练
          </template>
          <template v-for="(item, index) in store_user">
            <span v-if="item.id == form.store_user_id" :key="index">{{ item.real_name }}</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-home" />
            教室
          </template>
          <template v-for="(item, index) in classroom">
            <span v-if="item.id == form.classroom_id" :key="index">{{ item.name }}</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user" />
            容纳人数
          </template>
          {{ form.type === 1 ? '1' : form.galleryful }}人
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-star-on" />
            课程难度
          </template>
          <el-rate :value="form.difficulty" disabled text-color="#ff9900" :title="form.difficulty" />
        </el-descriptions-item>
        <el-descriptions-item label="">
          <template slot="label">
            <i class="el-icon-time" />
            课程时长
          </template>
          {{ form.duration }} 分钟
        </el-descriptions-item>
        <el-descriptions-item label="">
          <template slot="label">
            <i class="el-icon-paperclip" />
            允许付费约课
          </template>
          {{ form.is_pay ? '已开启' : '未开启' }}
        </el-descriptions-item>
        <el-descriptions-item v-if="form.is_pay" label="">
          <template slot="label">
            <i class="el-icon-paperclip" />
            优惠价(元/节)
          </template>
          {{ form.price }}
        </el-descriptions-item>
        <el-descriptions-item v-if="form.is_pay" label="">
          <template slot="label">
            <i class="el-icon-paperclip" />
            原价(元/节)
          </template>
          {{ form.original_price }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" :column="1" border direction="vertical">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-edit" />
            课程简介
          </template>
          {{ form.abstract ? form.abstract : '无' }}
        </el-descriptions-item>
      </el-descriptions>
    </el-form>
    <el-form v-if="active == 1" :model="form">
      <h3>支持卡类型</h3>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="期限卡" name="1">
          <el-form-item>
            <div class="tal">
              <checkboxInput
                :type="0"
                :parent-selected.sync="selectedCard.limit"
                :list="supportCard.limit"
                :is-course="true"
              />
            </div>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="次数卡" name="2">
          <el-form-item>
            <div class="tal">
              <checkboxInput
                :type="1"
                :parent-selected.sync="selectedCard.number"
                :list="supportCard.number"
                :is-course="true"
              />
            </div>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="储值卡" name="3">
          <el-form-item>
            <div class="tal">
              <checkboxInput
                :type="2"
                :parent-selected.sync="selectedCard.stored"
                :list="supportCard.stored"
                :is-course="true"
              />
            </div>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <el-form v-if="active == 2" :model="form">
      <h3>支持卡类型</h3>
      <el-descriptions class="margin-top" :column="3" direction="vertical" border>
        <el-descriptions-item label="期限卡">
          <span v-for="item in selectedCard.limit" :key="item.id">
            <i class="el-icon-bank-card" />
            {{ item.name }}
            <br>
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="次数卡">
          <span v-for="item in selectedCard.number" :key="item.id">
            <i class="el-icon-bank-card" />
            {{ item.name }}(每节课收费 {{ item.charge }} 次/人)
            <br>
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="储值卡">
          <span v-for="item in selectedCard.stored" :key="item.id">
            <i class="el-icon-bank-card" />
            {{ item.name }}(每节课收费￥ {{ item.charge }} 元/人)
            <br>
          </span>
        </el-descriptions-item>
      </el-descriptions>
    </el-form>
    <div class="footer">
      <el-button v-if="active != 0" @click="active--">上一步</el-button>
      <el-button v-if="active != 2" type="primary" @click="nextStep">下一步</el-button>
      <el-button v-if="active == 2" type="primary" @click="submit">提交</el-button>
    </div>

    <!-- 课程标签选择 -->
    <courseLabelView v-model="labelShow" :c-list="labelList" @setLabel="setLabel" />
  </div>
</template>

<script>
import courseLabelView from './label/index'
import { getImg } from '@/api/store/config.js'
import { addCourse } from '@/api/store/course.js'
import { getVipCard } from '@/api/store/venues.js'
import { getClassroom, getCoach } from '@/api/store/venues.js'
import checkboxInput from '@/components/All/checkboxInput'
import Select from '@/components/elSelect/Select'
export default {
  components: { checkboxInput, courseLabelView, Select },
  data() {
    // 上传图片验证器
    // var checkImage = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error('课程图片不能为空'))
    //   }
    //   callback()
    // }
    return {
      labelShow: false,
      labelList: [],
      active: 0,
      form: { type: 0, difficulty: 3, image: '', abstract: '', is_pay: 0, price: 0, original_price: 0 },
      store_user: [],
      // 教室列表
      classroom: [],
      // 支持卡列表
      supportCard: {
        limit: [],
        number: [],
        stored: []
      },
      // 支持卡选中
      selectedCard: {
        limit: [],
        number: [],
        stored: []
      },
      rules: {
        name: [{ required: true, message: '请输入课程名称', trigger: ['blur', 'change'] }],
        type: [{ required: true }],
        duration: [{ required: true, message: '请输入课程时长', trigger: ['blur', 'change'] }],
        galleryful: [{ required: true, message: '请输入容纳人数', trigger: ['blur', 'change'] }],
        price: [{ required: true, message: '请输入优惠价', trigger: ['blur', 'change'] }],
        original_price: [{ required: true, message: '请输入原价', trigger: ['blur', 'change'] }],
        store_user_id: [{ required: true, message: '请选择教练', trigger: ['blur', 'change'] }]
        // image: [{ required: true, validator: checkImage }]
      },
      is_load_succ: false,
      systemInfo: {
        default_calss_img: ''
      },
      quantity: 0,
      coachType: 0,
      activeNames: ['1', '2', '3'],
      userName: ''
    }
  },
  watch: {
    active(v, ov) {
      if (v === 1) {
        // 获取支持卡列表
        if (!this.is_load_succ) {
          getVipCard({ limit: 999 })
            .then(res => {
              const { data } = res
              var limit = []
              var number = []
              var stored = []
              for (var i in data.list) {
                const item = data.list[i]
                if (item.type === 0) {
                  limit.push(item)
                } else if (item.type === 1) {
                  item.charge = 1
                  number.push(item)
                } else if (item.type === 2) {
                  item.charge = 1
                  stored.push(item)
                }
              }
              this.supportCard.limit = limit
              this.supportCard.number = number
              this.supportCard.stored = stored
              this.is_load_succ = true
            })
            .catch(() => {})
        }
      }
    },
    'form.type'(v, ov) {
      if (v === 0) {
        this.getSystemInfo()
        this.quantity = 0
      } else if (v === 1) {
        this.getSystemInfo()
        this.quantity = 1
      } else if (v === 2) {
        this.getSystemInfo()
        this.quantity = 2
      }
    }
  },
  mounted() {
    this.getCoachList()
    // 获取教室列表
    getClassroom()
      .then(res => {
        this.classroom = res.data.list
        this.$set(this.form, 'classroom_id', this.classroom[0].id)
      })
      .catch(() => {})
    this.getSystemInfo()
  },
  methods: {
    setLabel(row) {
      this.labelList = row
      const data = []
      row.map((item, index) => {
        data.push(item.name)
      })
      this.form.label = data.join(',')
    },
    selectLabel() {
      this.labelShow = true
    },
    getCoachList() {
      // 获取教练列表
      getCoach({ limit: 999, type: this.form.type })
        .then(res => {
          this.store_user = res.data.list
          if (this.form.store_user_id) {
            console.log('🚀 ~ file: add.vue:412 ~ getCoachList ~ this.store_user:', this.store_user)
            const user = this.store_user.find(item => item.id === this.form.store_user_id)
            this.userName = user.real_name
          }
          // this.$set(this.form, 'store_user_id', this.store_user[0].id)
        })
        .catch(() => {})
    },
    // 下一步
    nextStep() {
      if (this.active === 0) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.active++
          } else {
            console.log('验证失败')
          }
        })
      } else {
        this.active++
      }
    },
    // 提交
    submit() {
      const venuesCard = []
      for (const i in this.selectedCard) {
        if (this.selectedCard[i].length !== 0) {
          this.selectedCard[i].forEach((val, index) => {
            var new_arr = {}
            // 严格键名排序
            new_arr.membership_card_id = val['id']
            new_arr.type = val['type']
            new_arr.charge = val['charge'] ? val['charge'] : 0
            venuesCard.push(new_arr)
          })
        }
      }

      this.form['venuesCard'] = JSON.stringify(venuesCard)
      this.form['venues_id'] = this.utils.getVenues().id
      addCourse(this.form)
        .then(() => {
          this.$message.success('添加成功')
          this.returnPage()
        })
        .catch(() => {})
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'image', response.data.path)
    },
    upProgress() {
      this.upLoading = true
    },
    typeChange(e) {
      if (e === 2) {
        this.coachType = 0
      } else {
        this.coachType = e
      }
      this.$refs.coachRef.refreshGetList()
      this.getCoachList()
    },
    handleChange(e) {
      this.getCoachList()
    },
    getSystemInfo() {
      getImg('course_group').then(res => {
        // const images = JSON.parse(res.data)
        this.form.image = res.data[this.quantity]
      })
    },
    handleCoachID(val) {
      this.form.store_user_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409eff;
}
.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.curriculum_rate {
  ::v-deep .el-rate__icon {
    font-size: 24px;
  }
}
</style>
