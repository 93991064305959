import request from '@/utils/request'

// 获取充值订单列表
export function getPackage(data) {
  return request({
    url: '/admin/v1/activity/deposit/order',
    method: 'get',
    params: data
  })
}
// 核销充值订单
export function putPackage(id) {
  return request({
    url: `/admin/v1/activity/deposit/order/${id}`,
    method: 'put'
  })
}
// 删除充值订单
export function deletePackage(id) {
  return request({
    url: `/admin/v1/activity/deposit/order/${id}`,
    method: 'delete'
  })
}
