var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c("el-page-header", {
        staticClass: "page-header",
        attrs: { content: "编辑活动报名" },
        on: {
          back: function($event) {
            return _vm.returnPage()
          }
        }
      }),
      _c("el-divider"),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "ruleForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "right",
            "label-width": "120px"
          }
        },
        [
          _c("p", [_c("b", [_vm._v("活动信息")])]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "封面图", prop: "cover" } },
                    [
                      _c(
                        "el-upload",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.upLoading,
                              expression: "upLoading"
                            }
                          ],
                          staticClass: "images-uploader",
                          attrs: {
                            action: _vm.storeUpload,
                            "show-file-list": false,
                            "on-success": _vm.upSuccess,
                            headers: _vm.utils.upload_headers(),
                            "on-progress": _vm.upProgress,
                            accept: ".jpg,.jpeg,.png"
                          }
                        },
                        [
                          _vm.form.cover
                            ? _c("img", {
                                staticClass: "images",
                                attrs: {
                                  src: _vm.form.cover,
                                  title: "点击上传图片"
                                }
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus images-uploader-icon",
                                staticStyle: { border: "1px dashed #d9d9d9" }
                              })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入活动名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动地点", prop: "location" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入活动地点" },
                        model: {
                          value: _vm.form.location,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "location", $$v)
                          },
                          expression: "form.location"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动详情", prop: "details" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisibleTask = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.form.details
                                  ? "编辑活动详情"
                                  : "添加活动详情"
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("p", [_c("b", [_vm._v("活动设置")])]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "活动开始时间", prop: "event_start_time" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期时间",
                          format: "yyyy-MM-dd HH:mm",
                          "value-format": "yyyy-MM-dd HH:mm",
                          "default-time": "00:00:00"
                        },
                        model: {
                          value: _vm.form.event_start_time,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "event_start_time", $$v)
                          },
                          expression: "form.event_start_time"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "活动结束时间", prop: "event_end_time" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期时间",
                          format: "yyyy-MM-dd HH:mm",
                          "value-format": "yyyy-MM-dd HH:mm",
                          "default-time": "23:59:59"
                        },
                        model: {
                          value: _vm.form.event_end_time,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "event_end_time", $$v)
                          },
                          expression: "form.event_end_time"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "报名收费" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "flex",
                          model: {
                            value: _vm.form.fee,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "fee", $$v)
                            },
                            expression: "form.fee"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("免费")
                              ]),
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("收费")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "el-input",
                            {
                              staticClass: "m-l-20",
                              staticStyle: { width: "160px" },
                              attrs: {
                                type: "number",
                                disabled: _vm.form.fee == 0
                              },
                              model: {
                                value: _vm.moneyNumber,
                                callback: function($$v) {
                                  _vm.moneyNumber = $$v
                                },
                                expression: "moneyNumber"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("元")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("p", [_c("b", [_vm._v("高级设置")])]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "报名人", prop: "buy_identity" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.form.buy_identity,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "buy_identity", $$v)
                            },
                            expression: "form.buy_identity"
                          }
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "member" } }, [
                            _vm._v("会员")
                          ]),
                          _c("el-checkbox", { attrs: { label: "visitor" } }, [
                            _vm._v("访客")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "relative", attrs: { span: 14 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "设定报名人数上限后，报名人数将受到限制不得超过设定的人数",
                        placement: "bottom"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-question absolute" })]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "总人数限制" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "flex m-l-10",
                          model: {
                            value: _vm.form.max_member,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "max_member", $$v)
                            },
                            expression: "form.max_member"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不限")
                              ]),
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("限制")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "el-input",
                            {
                              staticClass: "m-l-20",
                              staticStyle: { width: "160px" },
                              attrs: {
                                type: "number",
                                disabled: _vm.form.max_member == 0
                              },
                              model: {
                                value: _vm.peopleNumber,
                                callback: function($$v) {
                                  _vm.peopleNumber = $$v
                                },
                                expression: "peopleNumber"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("人")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "报名开始时间" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "flex",
                          model: {
                            value: _vm.form.sign_up_start_time,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sign_up_start_time", $$v)
                            },
                            expression: "form.sign_up_start_time"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("活动创建时间")
                              ]),
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("自定义时间")
                              ])
                            ],
                            1
                          ),
                          _c("el-date-picker", {
                            staticClass: "m-l-20",
                            attrs: {
                              disabled: _vm.form.sign_up_start_time == 0,
                              type: "datetime",
                              placeholder: "选择日期时间",
                              format: "yyyy-MM-dd HH:mm",
                              "value-format": "yyyy-MM-dd HH:mm",
                              "default-time": "00:00:00"
                            },
                            model: {
                              value: _vm.activity_start_time,
                              callback: function($$v) {
                                _vm.activity_start_time = $$v
                              },
                              expression: "activity_start_time"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "报名截至时间" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "flex",
                          model: {
                            value: _vm.form.sign_up_end_time,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sign_up_end_time", $$v)
                            },
                            expression: "form.sign_up_end_time"
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("截至活动结束")
                              ]),
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("自定义时间")
                              ])
                            ],
                            1
                          ),
                          _c("el-date-picker", {
                            staticClass: "m-l-20",
                            attrs: {
                              disabled: _vm.form.sign_up_end_time == 0,
                              type: "datetime",
                              placeholder: "选择日期时间",
                              format: "yyyy-MM-dd HH:mm",
                              "value-format": "yyyy-MM-dd HH:mm",
                              "default-time": "23:59:59"
                            },
                            model: {
                              value: _vm.activity_finish_time,
                              callback: function($$v) {
                                _vm.activity_finish_time = $$v
                              },
                              expression: "activity_finish_time"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "relative", attrs: { span: 24 } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "一旦选择了相应的表单，将在约课端显示，并由报名人填写;若未选择，将不会展示",
                        placement: "bottom"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-question absolute" })]
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "relative", attrs: { label: "活动表单" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "m-l-10",
                          attrs: { multiple: "", placeholder: "请选择" },
                          model: {
                            value: _vm.form.required_info,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "required_info", $$v)
                            },
                            expression: "form.required_info"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "姓名", value: "name" }
                          }),
                          _c("el-option", {
                            attrs: { label: "手机号", value: "phone" }
                          }),
                          _c("el-option", {
                            attrs: { label: "微信号", value: "wechat" }
                          }),
                          _c("el-option", {
                            attrs: { label: "性别", value: "sex" }
                          }),
                          _c("el-option", {
                            attrs: { label: "年龄", value: "age" }
                          }),
                          _c("el-option", {
                            attrs: { label: "身份证", value: "id_card" }
                          }),
                          _c("el-option", {
                            attrs: { label: "邮箱", value: "email" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "relative", attrs: { span: 12 } },
                [
                  _c("i", {
                    staticClass: "el-icon-question cursor absolute",
                    on: {
                      click: function($event) {
                        _vm.recommendation = true
                      }
                    }
                  }),
                  _c(
                    "el-form-item",
                    { attrs: { label: "加入推荐" } },
                    [
                      _c("el-switch", {
                        staticClass: "m-l-10",
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.form.is_recommend,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "is_recommend", $$v)
                          },
                          expression: "form.is_recommend"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.is_recommend
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "推荐轮播图" } },
                        [
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.upLoading,
                                  expression: "upLoading"
                                }
                              ],
                              staticClass: "images-uploader",
                              attrs: {
                                action: _vm.storeUpload,
                                "show-file-list": false,
                                "on-success": _vm.upSuccessS,
                                headers: _vm.utils.upload_headers(),
                                "on-progress": _vm.upProgressS,
                                accept: ".jpg,.jpeg,.png"
                              }
                            },
                            [
                              _vm.form.recommend_image
                                ? _c("img", {
                                    staticClass: "images",
                                    attrs: {
                                      src: _vm.form.recommend_image,
                                      title: "点击上传图片"
                                    }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus images-uploader-icon",
                                    staticStyle: {
                                      border: "1px dashed #d9d9d9"
                                    }
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "s_offline_event" })
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("ruleForm")
                }
              }
            },
            [_vm._v("提 交")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "活动详情",
            visible: _vm.dialogVisibleTask,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleTask = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c("wangEditor", {
            model: {
              value: _vm.WangEditorValue,
              callback: function($$v) {
                _vm.WangEditorValue = $$v
              },
              expression: "WangEditorValue"
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleTask = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleActivityPreserve }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认修改活动",
            visible: _vm.dialogVisibleActivity,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleActivity = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "font-16 text-info text-center" }, [
              _vm._v("活动修改后，已报名人员信息可能产生偏差，确定修改活动吗？")
            ]),
            _c(
              "div",
              { staticClass: "flex row-between m-t-30" },
              [
                _c("div", { staticClass: "p-l-4" }, [_vm._v("发送短信通知")]),
                _c("el-switch", {
                  staticClass: "p-r-4",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.sms_notify_start,
                    callback: function($$v) {
                      _vm.sms_notify_start = $$v
                    },
                    expression: "sms_notify_start"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "text-info m-t-10" }, [
              _vm._v(
                " *短信通知将扣减品牌短信余量，请确保短信余量充足，如不足将无法正常送达报名人手机短信 "
              )
            ])
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading },
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleActivity = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading, type: "primary" },
                  on: { click: _vm.handleModifyOK }
                },
                [_vm._v("提 交")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "加入推荐示列",
            visible: _vm.recommendation,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.recommendation = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "font-14 text-info",
              staticStyle: { "text-align": "center" }
            },
            [_vm._v("启用加入推荐，即可在特定板块中展示此活动")]
          ),
          _c("el-image", {
            staticClass: "m-t-10",
            staticStyle: { width: "100%" },
            attrs: { src: _vm.recommendationImg, fit: "fill" }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer flex row-center",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.recommendation = false
                    }
                  }
                },
                [_vm._v("知道了")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }