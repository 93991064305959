<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="新卡号" prop="number">
        <el-input v-model="form.number" placeholder="请输入新卡号" />
      </el-form-item>
      <el-form-item label="补卡费" prop="checked">
        <el-checkbox v-model="form.checked">收取补卡费用</el-checkbox>
      </el-form-item>
      <el-form-item v-if="form.checked" label="补办费" prop="poundage">
        <el-input v-model="form.poundage" placeholder="请输入费用" type="number" />
      </el-form-item>
      <el-form-item v-if="form.checked" label="收费方式" prop="payment_method_id">
        <el-select v-model="form.payment_method_id" placeholder="收费方式" class="dialog_w100">
          <el-option
            v-for="(item,index) in payment_method"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请填写操作备注"
          rows="5"
          maxlength="300"
          show-word-limit
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button @click="$emit('back', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reissueUserMembershipCard } from '@/api/store/user.js'
import { deepClone } from '@/utils'

export default {
  name: 'CardReissue',
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      form: {
        number: '',
        checked: false,
        poundage: '',
        payment_method_id: 0,
        remark: ''
      },
      rules: {
        number: { required: true, message: '请输入新卡号', trigger: ['blur', 'change'] },
        poundage: [
          { validator: (rule, value, callback) => {
            if (this.form.checked && (this.form.poundage === '' || this.form.poundage < 0)) {
              callback(new Error('请填写正数'))
            }
            callback()
          }, trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  created() {

  },
  methods: {
    // 补卡操作
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const data = deepClone(this.form)
          if (!this.form.checked) {
            data.payment_method_id = 0
            data.poundage = ''
          }
          reissueUserMembershipCard(this.cardData.id, data).then(res => {
            this.$emit('callback', res.msg)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style>

</style>
