<template>
  <div class="app-container">
    <div v-for="(item,index) in tableData" :key="index" class="m-y-10">
      <div class="p-y-8 p-x-10 title h3">{{ $t(`side_template.${index}`) }}<span v-if="index=='custom'" class="text-danger m-l-10">可以添加更多维度</span></div>
      <el-table :data="item" stripe style="width: 100%" empty-text="暂无自定义体测数据">
        <el-table-column prop="name" label="维度名称">
          <template slot-scope="{row}">
            <template v-if="row.edit||row.add">
              <el-input v-model="row.name" class="edit-input" />
            </template>
            <span v-else>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="单位">
          <template slot-scope="{row}">
            <template v-if="row.edit||row.add">
              <el-input v-model="row.unit" class="edit-input" />
            </template>
            <span v-else>{{ row.en_name === 'shape'?'':row.unit }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" :formatter="statusFormatter" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
          <template slot-scope="{row}">
            <!-- 新增 -->
            <el-button
              v-if="row.add"
              type="success"
              @click="addSaveTemplate(row)"
            >
              保存
            </el-button>
            <el-button
              v-if="row.add"
              type="danger"
              class="m-r-10"
              @click.native.prevent="deleteRow(tableData)"
            >
              取消
            </el-button>
            <!-- 编辑 -->
            <el-button
              v-if="row.edit"
              type="success"
              @click="confirmEdit(row)"
            >
              保存
            </el-button>
            <el-button
              v-if="row.edit"
              type="danger"
              class="m-r-10"
              @click="cancelEdit(row)"
            >
              取消
            </el-button>
            <!-- 显示与隐藏 -->
            <el-switch
              v-if="!row.add && !row.edit"
              v-model="row.status"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
              class="m-r-10"
              @change="statusChange(row)"
            />
            <el-button v-show="btn_access('s_side_template_edit')" v-if="row.group=='custom' && !row.add && !row.edit" class="m-r-10" type="primary" icon="el-icon-edit" :offset="2" @click="row.edit=!row.edit">编辑</el-button>
            <el-popconfirm
              title="确认删除该模版数据吗？"
              @confirm="side_template_delete(row.id)"
            >
              <el-button v-show="btn_access('s_side_template_del')" v-if="row.group=='custom' && !row.add && !row.edit" slot="reference" class="m-r-10" type="danger" icon="el-icon-delete" :offset="2">删除</el-button>
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>
      <div v-show="btn_access('s_side_template_add')" v-if="index=='custom' && !isAdd" class="m-t-5"><el-button type="primary" icon="el-icon-circle-plus" @click="addTemplate">新增</el-button></div>
    </div>
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { getSideTemplate, delSideTemplate, editSideTemplate, addSideTemplate } from '@/api/store/fianco.js'
export default {
  data() {
    return {
      // 列表
      tableData: {},
      isAdd: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      getSideTemplate().then(res => {
        if (!this.utils.ObjHasKey(res.data, 'custom')) {
          res.data.custom = []
        }
        const items = res.data
        for (var i in items) {
          items[i].map(v => {
            this.$set(v, 'edit', false) // https://vuejs.org/v2/guide/reactivity.html
            v.originalName = v.name //  将在用户单击取消按钮时使用
            v.originalUnit = v.unit //  …………
            return v
          })
        }
        this.tableData = items
      }).catch(() => {})
    },
    statusFormatter(v) {
      return v.status ? '显示' : '不显示'
    },
    unitFormatter(v) {
      if (v.en_name === 'shape') {
        return JSON.parse(v.unit).join(',')
      }
      return v.unit
    },
    side_template_delete(id) {
      delSideTemplate(id).then(res => {
        this.$message.success('删除成功')
        this.getList()
      }).catch(() => {})
    },
    statusChange(v) {
      // 更新信息
      const data = deepClone(v)
      if (data.en_name === 'bmi') {
        data.unit = 'BMI'
      }
      editSideTemplate(v.id, data).then(res => {
        this.$message.success('修改成功')
        this.getList()
      }).catch(() => {})
    },
    addTemplate() {
      this.tableData.custom.push({
        name: '',
        unit: '',
        status: 1,
        add: true,
        group: 'custom',
        edit: false,
        en_name: ''
      })
      this.isAdd = true
    },
    // 添加
    addSaveTemplate(v) {
      const data = {
        name: v.name,
        group: v.group,
        status: v.status,
        unit: v.unit,
        en_name: v.en_name
      }
      addSideTemplate(data).then(res => {
        this.$message.success('新增成功')
        this.getList()
        this.isAdd = false
      }).catch(() => {})
    },
    deleteRow(rows) {
      rows['custom'].pop()
      this.isAdd = false
    },
    confirmEdit(v) {
      const data = {
        name: v.name,
        id: v.id,
        group: v.group,
        status: v.status,
        unit: v.unit,
        en_name: v.en_name
      }
      editSideTemplate(v.id, data).then(res => {
        this.$message.success('修改成功')
        this.getList()
      }).catch(() => {})
    },
    cancelEdit(row) {
      row.name = row.originalName
      row.unit = row.originalUnit
      row.edit = false
    }
  }
}
</script>

<style lang="scss" scoped>
.title{
  border-bottom:1px solid #dfe6ec;
  color: #303133;
  font-weight: 600;
}
</style>
