<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索标题|内容" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
    </div>
    <!-- 列表 -->

    <div v-if="tableData.list.length>0" v-loading="loading.listLoading" class="tables">
      <el-collapse @change="handleChange">
        <el-collapse-item v-for="(item,index) in tableData.list" :key="index" :name="item.id" class="title" :class="{is_read:read_check(item)}">
          <template slot="title" class="flex flex-wrap">
            <div class="flex-1 line-1">{{ item.title }}</div>
            <div>{{ item.create_time }}</div>
          </template>
          <div class="desc" v-html="item.content" />
          <div class="text-primary cursor" @click="$router.push({ name: 's_sys_notice_info',params:{id:item.id} })">查看详情</div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-else>
      <div class=" text-center">
        <div>
          <el-image
            style="width: 300px;"
            :src="empty_default_img"
          />
        </div>
        <div class="text-info">暂无公告</div>
      </div>
    </div>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { getSystemNote } from '@/api/store/common.js'
import Pagination from '@/components/Pagination' // 分页组件
export default {
  components: { Pagination },
  data() {
    return {
      form: {
        category_id: 0,
        weigh: 1,
        venues_id: this.utils.getVenues().id,
        status: 0,
        is_hidden: 0
      },
      tableData: {
        list: [],
        count: 0
      },
      searchLoading: false,
      loading: { listLoading: true }
    }
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleChange(e) {
    },
    // 公告列表
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'title|content', type: 'like', key: this.listQueryParams.query })

      data.query = this.utils.getQueryParams(querys)

      getSystemNote(data).then(res => {
        // 数据嵌入
        this.tableData = res.data
        this.loading.listLoading = false
        this.searchLoading = false
      }).catch(() => {})
    },
    write_read(id) {
      if (!id) return false
    },
    read_check(item) {
      return false
    }
  }

}
</script>

<style lang="scss" scoped>
.tables{

  .title{
    color:#333;
  }
  .desc{
    color:#999;
    font-size: 13px;
    font-weight: 400;
    line-height:1.8;
  }
  .is_read{
    ::v-deep {
      .el-collapse-item__header{
        color:#999;
      }
    }
  }
}

</style>
