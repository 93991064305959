import request from '@/utils/request'

// 客询接口列表

// 获取 客询列表
export function getInquireList(data) {
  return request({
    url: `/store/v1/user_inquire`,
    method: 'get',
    params: data
  })
}

// 获取 新增客询
export function addInquire(data) {
  return request({
    url: `/store/v1/user_inquire`,
    method: 'post',
    data
  })
}

// 编辑客询
export function editInquire(id, data) {
  return request({
    url: `/store/v1/user_inquire/${id}`,
    method: 'put',
    data
  })
}

// 获取 删除客询
export function delInquire(id, data) {
  return request({
    url: `/store/v1/user_inquire/${id}`,
    method: 'delete',
    params: data
  })
}

// 获取客询详情
export function getInquireInfo(id, data) {
  return request({
    url: `/store/v1/user_inquire/${id}`,
    method: 'get',
    params: data
  })
}

// 分配会籍顾问
export function InquireAdviser(id, data) {
  return request({
    url: `/store/v1/user_inquire/set_adviser/${id}`,
    method: 'post',
    data
  })
}

// 跟踪访客
export function InquireCard(id, data) {
  return request({
    url: `/store/v1/user_inquire/set_card/${id}`,
    method: 'post',
    data
  })
}

// 客询数据导出
export function InquireExport(data) {
  return request({
    url: `/store/v1/user_inquire/export`,
    method: 'get',
    params: data
  })
}

// 获取 客询二维码生成
export function InquireQrcode(params) {
  return request({
    url: `/store/v1/user_inquire/qrcode`,
    method: 'get',
    params
  })
}

// 回访列表
export function InquireTrack(data) {
  return request({
    url: `/store/v1/user_inquire_track`,
    method: 'get',
    params: data
  })
}

// 回访详情
export function getInquireTrack(id, data) {
  return request({
    url: `/store/v1/user_inquire_track/${id}`,
    method: 'get',
    params: data
  })
}

// 回访删除
export function delInquireTrack(id, data) {
  return request({
    url: `/store/v1/user_inquire_track/${id}`,
    method: 'delete',
    data
  })
}

// 新增回访
export function addInquireTrack(data) {
  return request({
    url: `/store/v1/user_inquire_track`,
    method: 'POST',
    data
  })
}

// 编辑回访
export function editInquireTrack(id, data) {
  return request({
    url: `/store/v1/user_inquire_track/${id}`,
    method: 'put',
    data
  })
}

// --------------------配置
// 获取配置
export function InquireConfig(id, data) {
  return request({
    url: `/store/v1/user_inquire_config`,
    method: 'get',
    params: data
  })
}

// 更新配置
export function editInquireConfig(data) {
  return request({
    url: `/store/v1/user_inquire_config`,
    method: 'post',
    data
  })
}
