<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleAccount"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="45%"
      :show-close="false"
      @open="handleOpen"
      @close="handleClose"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">活动报名活动详情</div>
          <div class="title_icon cursor" @click="handleClose">
            <i class="el-icon-close" />
          </div>
        </div>
      </div>
      <div>
        <div class="flex row-right m-b-20">
          <el-button type="success" @click="dialogSignIn = true">签到码</el-button>
          <el-button
            v-show="btn_access('s_offline_event_export')"
            type="danger"
            class="filter-item m-l-10"
            icon="el-icon-download"
            :loading="exportLoading"
            @click="Download"
          >
            导出人员名单
          </el-button>
        </div>
        <el-descriptions
          v-if="teamworkData"
          v-loading="descriptionsLoading"
          class="margin-top"
          :column="2"
          border
          :label-style="{ width: '100px' }"
        >
          <el-descriptions-item :span="2">
            <template slot="label">
              封面图
            </template>
            <el-image
              v-if="teamworkData.cover"
              style="min-width: 120px; height: 120px"
              :src="teamworkData.cover"
              fit="fill"
            />
            <div v-else>暂无封面图</div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              活动名称
            </template>
            {{ teamworkData.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              状 态
            </template>
            <el-tag v-if="teamworkData.cancelled === 1" type="danger">活动已取消</el-tag>
            <el-tag
              v-else-if="teamworkData.cancelled === 0 && currentTime < new Date(teamworkData.sign_up_start_time)"
              type="warning"
            >
              未开始
            </el-tag>
            <el-tag
              v-else-if="
                teamworkData.cancelled === 0 &&
                  new Date(teamworkData.sign_up_start_time) <= currentTime &&
                  currentTime <= new Date(teamworkData.sign_up_end_time)
              "
              type="success"
            >
              报名中
            </el-tag>
            <el-tag v-else type="danger">已截至报名时间</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              活动时间
            </template>
            {{ formatTime(teamworkData.event_start_time) }} - {{ formatTime(teamworkData.event_end_time) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              活动报名时间
            </template>
            {{ formatTime(teamworkData.sign_up_start_time) }} - {{ formatTime(teamworkData.sign_up_end_time) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              活动报名费
            </template>
            <el-tag :type="teamworkData.fee === '0.00' ? 'success' : ''">
              {{ teamworkData.fee === '0.00' ? '免费' : teamworkData.fee + ' 元' }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              剩余名额
            </template>
            {{
              `${teamworkData.max_member == '0' ? '不限制' : teamworkData.max_member - teamworkData.sign_up_count + ' 人'} `
            }}
          </el-descriptions-item>
          <el-descriptions-item :span="2">
            <template slot="label">
              活动地点
            </template>
            {{ teamworkData.location }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              报名人
            </template>
            <div v-if="teamworkData.buy_identity">
              <el-tag v-if="teamworkData.buy_identity.indexOf('member') !== -1" class="m-x-2">会员</el-tag>
              <el-tag v-if="teamworkData.buy_identity.indexOf('visitor') !== -1" class="m-x-2">团课</el-tag>
            </div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              是否推荐
            </template>
            <div>
              <el-tag v-if="teamworkData.is_recommend == 1" class="m-x-2">已推荐</el-tag>
              <el-tag v-if="teamworkData.is_recommend == 0" type="info" class="m-x-2">未推荐</el-tag>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="2">
            <template slot="label">
              活动内容
            </template>
            <el-button @click="dialogVisibleTask = true">查看详情</el-button>
          </el-descriptions-item>
        </el-descriptions>
        <p>
          <b class="m-r-20 font-16">报名人员</b>
          <b class="font-16">
            已报名
            <span class="text-primary">{{ teamworkData.sign_up_count }}</span>
            / 已签到
            <span class="text-success">{{ teamworkData.sign_in_count }}</span>
          </b>
        </p>
        <p class="m-r-20 font-16">
          <b>报名费用：{{ tableData.total_price }} 元</b>
          <b>报名人数：{{ tableData.count }}</b>
        </p>
        <div class="searchBox">
          <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
            <el-form-item label="状态">
              <el-select v-model="listQueryParams.status" placeholder="请选择" filterable clearable>
                <el-option label="未签到" value="no" />
                <el-option label="已签到" value="yes" />
              </el-select>
            </el-form-item>
            <el-form-item label="报名时间">
              <el-date-picker
                v-model="listQueryParams.sign_up_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="dateShortcutChooser"
              />
            </el-form-item>
          </el-form>
          <div>
            <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
              搜索
            </el-button>
          </div>
          <div class="filter-item m-b-10">
            <el-input
              v-model="listQueryParams.query"
              style="width: 300px;"
              placeholder="搜索 报名人姓名 | 报名手机号"
              @keyup.enter.native="handleFilter"
            >
              <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
            </el-input>
          </div>
        </div>
        <el-table ref="multipleTable" v-loading="TableLoading" :data="tableData.list" row-key="id" header-align="center">
          <el-table-column label="用户信息" width="155">
            <template slot-scope="{ row }">
              <div v-if="row.user" class="flex col-center">
                <div>
                  <el-avatar shape="square" :size="50" :src="row.user.avatar">
                    <img :src="errorAvatarSrc(row.user, 'img')">
                  </el-avatar>
                </div>
                <div class="p-l-10">
                  <span>{{ getUserNames(row.user) }}</span>
                  <br>
                  <span>{{ row.user.phone ? row.user.phone : '--' }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报名信息" width="180">
            <template slot-scope="{ row }">
              <div v-if="row.user" class="flex col-center">
                <div class="p-l-10">
                  <div>姓名：{{ row.name }}</div>
                  <div v-if="row.sex > 0">性别：{{ row.sex === 1 ? '男' : '女' }}</div>
                  <div v-if="row.phone">手机号：{{ row.phone }}</div>
                  <div v-if="row.wechat">微信号：{{ row.wechat }}</div>
                  <div v-if="row.sign_in_time">签到时间：{{ formatTime(row.sign_in_time) }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报名费用" width="70">
            <template slot-scope="{ row }">
              {{ row.pay_price === 0 ? '免费' : row.pay_price + '元' }}
            </template>
          </el-table-column>
          <el-table-column label="报名时间" width="140">
            <template slot-scope="{ row }">
              {{ row.sign_up_time }}
            </template>
          </el-table-column>
          <el-table-column label="签到状态" width="140">
            <template slot-scope="{ row }">
              <el-tag v-if="row.status === 0" type="info">未签到</el-tag>
              <el-tag v-if="row.status === 1">已签到（扫码签到）</el-tag>
              <el-tag v-if="row.status === 3">已签到（代签）</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button v-if="row.status === 0 && btn_access('s_offline_event_sign')" type="success" @click="handleSignIn(row.id, 0)">代 签</el-button>
              <el-button v-if="row.status > 1" @click="handleSignIn(row.id, 1)">取消签到</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="tableData.count > 0"
          :total="tableData.count"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 活动详情 -->
    <el-dialog
      title="活动内容"
      :visible.sync="dialogVisibleTask"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%"
    >
      <div v-html="teamworkData.details" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTask = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 签到码 -->
    <el-dialog
      title="签到码"
      :visible.sync="dialogSignIn"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%"
      @close="handelClose"
    >
      <div>
        <div style="font-weight: 600;" class="font-20 text-center">{{ teamworkData.name }}</div>
        <div class="font-16 text-center m-t-10">
          {{ teamworkData.event_start_time }} - {{ teamworkData.event_end_time }}
        </div>
        <div class="flex flex-col row-center m-t-30">
          <div class="m-b-10 text-info">请参与人员打开微信，扫码进行签到</div>
          <el-image style="width: 300px; height: 300px;" :src="teamworkData.sign_in_code" fit="fill" />
        </div>
        <div class="font-16 text-center m-t-20">
          已签到：
          <span class="text-success">{{ teamworkData.sign_in_count }}</span>
          / 未签到：
          <span class="text-danger">{{ teamworkData.sign_up_count - teamworkData.sign_in_count }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSignIn = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 导出 -->
    <Export :success-prompt="successPrompt" :is-judge="is_judge" :animation-display="animation_display" />
  </div>
</template>

<script>
// API
import { activityDetail, activitySignUpList, signUp } from '@/api/store/activitySignUp.js'
// 组件
import Pagination from '@/components/Pagination'
import Export from '@/components/export/index.vue'

export default {
  components: { Pagination, Export },
  props: {
    dialogVisibleAccount: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      teamworkData: {},
      dialogVisibleTask: false,
      tableData: {
        list: [],
        count: 0,
        total_price: 0
      },
      TableLoading: false,
      descriptionsLoading: false,
      searchLoading: false,
      dialogSignIn: false,
      downloadLoading: false,
      successPrompt: false,
      is_judge: 0,
      animation_display: true,
      exportLoading: false,
      currentTime: new Date() // 当前时间
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.id = this.typeId
      this.getList()
      this.getDetails()
    },
    // 关闭的回调
    handleClose() {
      this.$emit('roleVisible')
    },
    getDetails() {
      this.descriptionsLoading = true
      // 拼团活动详情
      activityDetail(this.id)
        .then(res => {
          const { data } = res
          this.teamworkData = data
        })
        .finally(() => {
          this.descriptionsLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getList() {
      this.TableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) {
        querys.push({ field: 'name|phone', type: 'like', key: this.listQueryParams.query })
      }
      if (this.listQueryParams.status && this.listQueryParams.status === 'yes') {
        querys.push({ field: 'status', type: 'gt', key: 0 })
      }
      if (this.listQueryParams.status && this.listQueryParams.status === 'no') querys.push({ field: 'status', key: 0 })
      if (this.listQueryParams.sign_up_time) {
        querys.push({ field: 'sign_up_time', type: 'between-time', key: this.listQueryParams.sign_up_time })
      }
      data.query = this.utils.getQueryParams(querys)

      activitySignUpList(this.id, data)
        .then(res => {
          this.tableData = res.data
        })
        .finally(() => {
          this.TableLoading = false
          this.searchLoading = false
        })
    },
    handleSignIn(id, type) {
      console.log(type)
      this.$confirm(`${type === 0 ? '确定签到本条预约记录吗？' : '确定取消签到本条预约记录吗？'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          is_undo: type
        }
        signUp(id, data).then(() => {
          this.$message.success('操作成功!')
          this.getList()
          this.getDetails()
        })
      })
    },
    // 关闭的回调
    handelClose() {
      this.getList()
      this.getDetails()
    },
    // 导出
    async Download() {
      const _this = this
      this.animation_display = true
      this.is_judge = 0
      this.successPrompt = true
      this.exportLoading = true
      const progressBar = await this.utils.handleDownload({
        url: `/store/v1/offline_event/${this.id}/export`,
        fileName: '用户会员列表.xlsx',
        baseURL: this.baseUrl
      })

      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.exportLoading = false
          _this.successPrompt = false
        }, 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tree-height {
  height: 60vh;
  overflow-y: auto;

}
</style>
