var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      attrs: { "tab-position": "top" },
      model: {
        value: _vm.activeName,
        callback: function($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName"
      }
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "小程序信息", name: "weChatInformation" } },
        [
          _vm.activeName == "weChatInformation"
            ? _c("weChatInformation", { on: { toPage: _vm.toPage } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "小程序配置", name: "weChatAllocation" } },
        [
          _vm.activeName == "weChatAllocation"
            ? _c("weChatAllocation")
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "小程序服务商", name: "WeChatServiceProvider" } },
        [
          _vm.activeName == "WeChatServiceProvider"
            ? _c("WeChatServiceProvider")
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "草稿箱", name: "WeChatDrafts" } },
        [_vm.activeName == "WeChatDrafts" ? _c("WeChatDrafts") : _vm._e()],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }