import router from './router'
import { resetRouter } from '@/router'
import store from './store'
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' // 进度条样式
import { getToken } from '@/utils/auth' // 从cookie中获取token
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress配置

const whiteList = ['/login', '/auth-redirect', '/resetpwd', '/verificationCode', '/401', '/404', '/410', '/601', '/602', '/603'] // 没有重定向白名单

router.beforeEach(async(to, from, next) => {
  // 开始进度条
  NProgress.start()

  // 设置页面标题
  document.title = getPageTitle(to.meta.title)

  // 还是会走到登录页，暂时注释
  // if (to.query.token) {
  //   setToken(to.query.token)
  // }

  // 确认用户是否已登录
  const hasToken = getToken() // token
  const hasVenues = localStorage.getItem('Venues') // 登录的场馆

  // 存权限 store：商户   admin：总后台
  const authority = localStorage.getItem('authority') ? localStorage.getItem('authority') : 'store'
  if (to.path === '/login') localStorage.setItem('authority', to.query.authority ? to.query.authority : (window.location.host === 'system.yuxiaojiu.cn' ? 'admin' : 'store'))
  if ((hasToken && hasVenues) || (hasToken && authority === 'admin')) {
    if (to.path === '/login') {
      next({ path: to.query.referer_path || '/' })
      NProgress.done()
      // 如果已登录，则重定向到主页
      // next({ path: '/' })
      // NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      // 通过getInfo判断用户是否获得了权限角色
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      // 防止切换场馆时鉴权不对称
      const roles_refresh = localStorage.getItem('roles_refresh') !== 'false'
      if (hasRoles && roles_refresh) {
        next()
      } else {
        try {
          // 获取用户信息
          // 注意:角色必须是一个对象数组!例如:['admin']或['developer'，'editor']
          const { roles } = await store.dispatch('user/getInfo')

          // 根据角色生成可访问路由图
          const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          // 重载路由
          resetRouter()

          // 动态添加可访问路由
          router.addRoutes(accessRoutes)

          localStorage.setItem('roles_refresh', true)

          // 黑客方法，以确保地址路由是完整的
          // 设置replace: true，这样导航就不会留下历史记录
          next({ ...to, replace: true })
        } catch (error) {
          // 删除token，然后转到登录页面重新登录
          await store.dispatch('user/resetToken')
          NProgress.done()
        }
      }
    }
  } else {
    /* 没有 token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免费登录白名单中，直接进入
      next()
    } else {
      // 其他没有访问权限的页面被重定向到登录页面。
      const referer_path = (to.path && to.path !== '/dashboard' && to.path === '/store/withdraw_apply/index') ? to.path : '' || ''
      let jump_url = `/login`
      if (referer_path) jump_url += `?referer_path=${referer_path}`
      next(jump_url)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
