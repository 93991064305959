export function timestampToTime(timestamp) {
  let date = ''
  if (timestamp.length === 10) {
    date = new Date(timestamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  } else {
    date = new Date(timestamp)
  }
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = date.getDate() + ' '
  return Y + M + D
}
export function timestampToTimes(timestampS) {
  if (timestampS === 0) return
  let timestamp = timestampS + ''
  if (timestamp.length === 10) {
    timestamp = parseInt(timestamp) * 1000
  } else {
    timestamp = parseInt(timestamp)
  }
  var date = new Date(timestamp)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  var D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
  var hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + hh + ':' + mm + ':' + ss
}
