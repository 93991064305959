var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box p-20" }, [
    _c(
      "div",
      { staticClass: "rela" },
      [
        _c(
          "el-menu",
          {
            staticClass: "el-menu-remind",
            attrs: {
              "default-active": _vm.key,
              mode: "horizontal",
              router: true
            }
          },
          [
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_user_card_validity"),
                    expression: "btn_access('s_user_card_validity')"
                  }
                ],
                attrs: { index: "/store/remind/userCardValidity" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_user_card_validity")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_user_birthday"),
                    expression: "btn_access('s_user_birthday')"
                  }
                ],
                attrs: { index: "/store/remind/userBirthday" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_user_birthday")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_user_card_balance"),
                    expression: "btn_access('s_user_card_balance')"
                  }
                ],
                attrs: { index: "/store/remind/userCardBalance" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_user_card_balance")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_user_notclass"),
                    expression: "btn_access('s_user_notclass')"
                  }
                ],
                attrs: { index: "/store/remind/userNotClass" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_user_notclass")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_user_souvenir"),
                    expression: "btn_access('s_user_souvenir')"
                  }
                ],
                attrs: { index: "/store/remind/userSouvenir" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_user_souvenir")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_user_card_leave_expire"),
                    expression: "btn_access('s_user_card_leave_expire')"
                  }
                ],
                attrs: { index: "/store/remind/userCardOpen" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_user_card_open")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_user_card_open"),
                    expression: "btn_access('s_user_card_open')"
                  }
                ],
                attrs: { index: "/store/remind/userCardLeaveExpire" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_user_card_leave_expire")))]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "p-y-10" },
          [_c("router-view", { key: _vm.key })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }