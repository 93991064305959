<template>
  <div class="app-container">
    <!-- 筛选 -->
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="80px" label-position="left" label-suffix="：">
          <el-form-item label="状态">
            <el-select v-model="listQueryParams.status" placeholder="请选择" clearable>
              <el-option label="启用" :value="1" />
              <el-option label="禁用" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="支持">
            <el-select v-model="listQueryParams.is_supported" placeholder="请选择" clearable>
              <el-option label="支持" :value="1" />
              <el-option label="不支持" :value="0" />
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索区号|国家名称|英文名称|栏目" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('code_management_add')" type="primary" class="filter-item m-l-10" icon="el-icon-plus" @click="handleAdd">添加区号</el-button>
    </div>
    <!-- 数据 -->
    <el-table v-loading="loading" :data="tableData.list" style="width: 100%">
      <!-- 序号 -->
      <el-table-column type="index" label="序号" min-width="60" align="center">
        <template slot-scope="scope">
          <span>{{ (listQuery.page - 1) * listQuery.limit + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="国家名称" align="center" />
      <el-table-column prop="en_name" label="英文名称" align="center" />
      <el-table-column prop="code" label="区号" align="center">
        <template slot-scope="{ row }">
          <span>+{{ row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="label" label="栏目" align="center" />
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status === 1" type="success">启用</el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_supported" label="支持" align="center">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_supported === 1" type="success">支持</el-tag>
          <el-tag v-else type="danger">不支持</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="weigh" label="排序" align="center" />
      <el-table-column prop="balance" label="余量" align="center" />
      <el-table-column label="操作" min-width="100">
        <template slot-scope="{ row }">
          <el-button v-show="btn_access('code_management_edit')" class="m-2" type="primary" icon="el-icon-edit" @click="handleEdit(row.id)">编辑</el-button>
          <el-popconfirm v-show="btn_access('code_management_del')" title="是否删除该区号，确定继续吗？" @confirm="handleDelete(row.id)">
            <el-button slot="reference" class="m-2" type="danger" icon="el-icon-delete">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count > 0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <el-dialog :title="isAddEdit === 'add' ? '添加区号' : '编辑区号'" :visible.sync="dialogVisible" :close-on-click-modal="false" width="30%" :before-close="handleClose" @close="handleCloses">
      <!-- 表单 -->
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px" label-position="left" label-suffix="：">
        <el-form-item label="国家名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入国家名称" />
        </el-form-item>
        <el-form-item label="英文名称">
          <el-input v-model="form.en_name" placeholder="请输入英文名称" />
        </el-form-item>
        <el-form-item label="区号" prop="code">
          <el-input v-model="form.code" placeholder="请输入区号" />
        </el-form-item>
        <el-form-item label="栏目" prop="label">
          <el-input v-model="form.label" placeholder="请输入栏目" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择" clearable>
            <el-option label="启用" :value="1" />
            <el-option label="禁用" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="支持">
          <el-select v-model="form.is_supported" placeholder="请选择" clearable>
            <el-option label="支持" :value="1" />
            <el-option label="不支持" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.weigh" type="number" placeholder="请输入排序" />
        </el-form-item>
        <el-form-item label="短信余量">
          <el-input v-model="form.balance" type="number" placeholder="请输入短信余量" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="isAddEdit === 'add'" type="primary" @click="handleSubmit('ruleForm')">确 定</el-button>
        <el-button v-if="isAddEdit === 'edit'" type="primary" @click="handleModify('ruleForm')">修 改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getAreaCodeList, getAreaCodeDetail, delAreaCode, addAreaCode, editAreaCode } from '@/api/admin/codeManagement'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      }, // 列表
      loading: false, // 加载
      searchLoading: false, // 搜索
      form: {
        name: '',
        en_name: '',
        code: '',
        label: '',
        status: 1,
        is_supported: 1,
        weigh: 0,
        balance: 0
      }, // 表单
      dialogVisible: false, // 弹窗
      rules: {
        name: [{ required: true, message: '请输入国家名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入区号', trigger: 'blur' }],
        label: [{ required: true, message: '请输入栏目', trigger: 'blur' }]
      }, // 表单验证
      isAddEdit: '', // 是否添加
      rowId: 0 // 行ID
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.status !== null && typeof this.listQueryParams.status === 'number') querys.push({ field: 'status', key: this.listQueryParams.status })
      if (this.listQueryParams.is_supported !== null && typeof this.listQueryParams.is_supported === 'number') querys.push({ field: 'is_supported', key: this.listQueryParams.is_supported })
      if (this.listQueryParams.query) querys.push({ field: 'code|en_name|name|label', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      this.loading = true
      getAreaCodeList(data)
        .then(res => {
          this.tableData = res.data
          this.loading = false
          this.searchLoading = false
        })
        .catch(() => {
          this.loading = false
          this.searchLoading = false
        })
    },
    // 筛选
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 添加
    handleAdd() {
      this.isAddEdit = 'add'
      this.dialogVisible = true
    },
    // 编辑
    handleEdit(id) {
      this.isAddEdit = 'edit'
      this.rowId = id
      this.dialogVisible = true
      getAreaCodeDetail(id).then(res => {
        console.log(res.data)
        this.form.name = res.data.name
        this.form.en_name = res.data.en_name
        this.form.code = res.data.code
        this.form.label = res.data.label
        this.form.status = res.data.status
        this.form.weigh = res.data.weigh
        this.form.is_supported = res.data.is_supported
        this.form.balance = res.data.balance
      })
    },
    // 删除
    handleDelete(id) {
      delAreaCode(id).then(res => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    // 关闭弹窗
    handleClose(done) {
      done()
      // 清空表单
      this.form = this.$options.data.call(this).form
    },
    handleCloses() {
      // 清空表单
      this.form = this.$options.data.call(this).form
    },
    // 提交表单
    handleSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          addAreaCode(this.form).then(res => {
            this.dialogVisible = false
            this.$message.success('提交成功')
            this.getList()
          })
        } else {
          return false
        }
      })
    },
    // 修改
    handleModify(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          editAreaCode(this.rowId, this.form).then(res => {
            this.dialogVisible = false
            this.$message.success('修改成功')
            this.getList()
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
