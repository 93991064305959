<template>
  <div class="wx-config">
    <div class="wx-box">
      <div class="item">
        <div id="relevance" class="label text-primary">公众号关联小程序</div>
        <div class="content">
          <div class="tip">
            关联小程序后，用户可以通过公众号菜单直接进入小程序进行约课等操作
          </div>
          <div style="height: 100%" class="m-t-10">
            <el-steps direction="vertical">
              <el-step status="success " title="登录公众号平台" space="200">
                <div slot="description" class="text-info m-b-10 ">微信公众号平台 <a href="https://mp.weixin.qq.com" target="_blank" class="text-primary">https://mp.weixin.qq.com</a> -> 扫码登录</div>
              </el-step>
              <el-step status="success " title="关联小程序" space="200">
                <div slot="description" class="text-info">
                  找到广告与服务 -> 小程序管理 -> 添加 按钮,选择关联小程序-管理员微信扫码并确认 -> 搜索《瑜小九》 -> 下一步，完成关联
                  <div class="flex m-t-10">
                    <div class="m-2">
                      <el-image
                        class="viewImg"
                        :src="exampleImg[2]"
                        fit="contain"
                        :preview-src-list="[exampleImg[2]]"
                      />
                    </div>
                    <div class="m-2">
                      <el-image
                        class="viewImg"
                        :src="exampleImg[3]"
                        fit="contain"
                        :preview-src-list="[exampleImg[3]]"
                      />
                    </div>
                    <div class="m-2">
                      <el-image
                        class="viewImg"
                        :src="exampleImg[1]"
                        fit="contain"
                        :preview-src-list="[exampleImg[1]]"
                      />
                    </div>
                    <div class="m-2">
                      <el-image
                        class="viewImg"
                        :src="exampleImg[0]"
                        fit="contain"
                        :preview-src-list="[exampleImg[0]]"
                      />
                    </div>
                  </div>
                </div>
              </el-step>
              <el-step status="success " title="设置自定义菜单">
                <div slot="description" class="text-info">
                  找到内容与互动 -> 自定义菜单 -> 添加 -> 填写子菜单名称（如：约课小程序） -> 子菜单内容选择跳转小程序 -> 修改小程序路径为
                  <el-tooltip class="item" effect="dark" content="点击复制" placement="bottom">
                    <a v-clipboard:copy="appidUrl" v-clipboard:success="clipboardSuccess" class="text-primary" icon="el-icon-document"> {{ appidUrl }}</a>
                  </el-tooltip>
                  -> 点击保存并发布即可
                  <div class="flex m-t-10">
                    <div class="m-2">
                      <div class="m-2">
                        <el-image
                          class="viewImg"
                          :src="exampleImg[5]"
                          fit="contain"
                          :preview-src-list="[exampleImg[5]]"
                        />
                      </div>
                    </div>
                    <div class="m-2">
                      <div class="m-2">
                        <el-image
                          class="viewImg"
                          :src="exampleImg[4]"
                          fit="contain"
                          :preview-src-list="[exampleImg[4]]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </el-step>
            </el-steps>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import clipboard from '@/directive/clipboard/index.js'
export default {
  name: 'Relevance',
  directives: { clipboard },
  data() {
    return {
      appInfo: this.utils.getApp(),
      appidUrl: 'pages/index/index?appid=' + (localStorage.getItem('Appid') ? localStorage.getItem('Appid') : '').toString(),
      exampleImg: [
        require('@/assets/images/wxconfig/绑定.png'),
        require('@/assets/images/wxconfig/关联.png'),
        require('@/assets/images/wxconfig/广告与服务.png'),
        require('@/assets/images/wxconfig/小程序管理.png'),
        require('@/assets/images/wxconfig/编辑菜单.png'),
        require('@/assets/images/wxconfig/自定义菜单.png')
      ]
    }
  },
  mounted() {
  },
  methods: {
    clipboardSuccess() {
      this.$message({
        message: '复制成功',
        type: 'success',
        duration: 1500
      })
    },

    kssstart(name, file_name) {
      // mymap你要截取的元素 也可以是body  整个页面  **
      const canvasID = this.$refs[name]
      const that = this
      const a = document.createElement('a')
      html2canvas(canvasID, { useCORS: true, scrollY: 0, removeContainer: false }).then(canvas => {
        const dom = document.body.appendChild(canvas)
        dom.style.display = 'none'
        a.style.display = 'none'
        document.body.removeChild(dom)
        const blob = that.dataURLToBlob(dom.toDataURL('image/jpg'))
        a.setAttribute('href', URL.createObjectURL(blob))
        a.setAttribute('download', file_name)
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(blob)
        document.body.removeChild(a)
      })
    },
    dataURLToBlob(dataurl) { // ie 图片转格式
      var arr = dataurl.split(','); var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1]); var n = bstr.length; var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    viewImg(index) {
      this.$refs[`exampleImg${index}`].clickHandler()
    }
  }
}
</script>

<style lang="scss" scoped>
.wx-config{
  padding-top:15px;

  .wx-box{
    border:1px solid #d9d9d9;
    border-radius: 4px;
    padding-bottom: 20px;
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .item{
      .image_down{
        padding-top: 5px;
      }
      .yueke_image{
        display: block;
        width: 180px;
      }
    }
    // margin: 0 40px;
    .label{
      width: 160px;
      height: 40px;
      background-color: #efeff2;
      padding: 10px;
      text-align: center;
    }
    .content{
      margin: 0 40px;
      padding: 15px 0;
    }
    .tips{
    color: #787878;

    font-size: 12px;
    line-height: 1.5;
    padding-top: 10px;
    margin-bottom: 10px;
    }
  }

}
.viewImg{
  // width: 100px;
  height: 100px;
  // border:1px solid #dcdfe6;
}
</style>
