<template>
  <el-dialog
    :title="id === 0 ? '新增菜单' : '编辑菜单'"
    :visible.sync="dialogVisible"
    :fullscreen="true"
    :close-on-click-modal="false"
    :show-close="false"
    width="30%"
    @open="handleOpen"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">{{ id === 0 ? '新增菜单' : '编辑菜单' }}</div>
        <div class="title_icon cursor" @click="handleCloseS">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <div class="container_bg">
      <div class="flex m-t-20 p-x-80">
        <el-form ref="menuForm" :model="menuForm" :rules="menuRules" label-width="100px" style="width: 100%;" class="demo-ruleForm">
          <el-form-item label="菜单名称" prop="name">
            <el-input v-model="menuForm.name" style="width: 90%;" />
          </el-form-item>
        </el-form>
      </div>
      <el-row>
        <el-col :span="7" class="flex row-center m-t-20">
          <div class="phone_weChat">
            <div v-loading="weChatLoading" class="weChat">
              <div style="height: 130vh;" @click="handleClose" />
              <div class="weChat_bottom flex">
                <div class="weChat_bottomCode flex row-center"><img src="@/assets/images/code.png" alt=""></div>
                <div class="weChat_bottomList flex row-around">
                  <div v-show="navigationBarList.length > 0" ref="multipleTable" class="flex">
                    <div
                      v-for="(item, index) in navigationBarList"
                      :key="index"
                      :style="
                        navigationBarStatus === index ? 'border: 1px solid #00c9c9;' : 'border: 1px solid #e7e7eb;'
                      "
                      class="cursor weChat_button"
                      @click="handleClick(item, index)"
                    >
                      {{ item.name }}
                      <div v-if="navigationBarStatus === index" class="weChat_option">
                        <div
                          v-if="item.sub_button ? item.sub_button.length < 5 : true"
                          class="weChat_bg"
                          @click.stop="handleAddSon(item, index)"
                        >
                          <div>+</div>
                        </div>
                        <div v-if="item.sub_button" ref="multipleTableSon" class="weChat_all">
                          <div
                            v-for="(item2, index2) in item.sub_button"
                            :key="index2"
                            :style="
                              navigationBarStatusSon === index2
                                ? 'border: 1px solid #00c9c9;'
                                : 'border: 1px solid #e7e7eb;'
                            "
                            class="weChat_asd"
                            @click.stop="handleClickSon(item2, index2)"
                          >
                            {{ item2.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="navigationBarList.length < 3"
                    style="border: 1px solid #e7e7eb;"
                    class="cursor"
                    :style="navigationBarList.length === 2 ? 'width: 50%;' : ''"
                    @click="handleAdd"
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="17" class="flex m-t-20">
          <div class="control_panel">
            <div class="control_center">
              <div class="top_label flex row-between">
                <div>添加菜单</div>
                <div class="flex">
                  <div v-if="navigationBarStatus > -1" class="cursor" style="color: red;" @click="handleDelete">
                    删除菜单
                  </div>
                </div>
              </div>
              <div v-if="navigationBarStatus > -1" class="form_list m-t-30">
                <el-form
                  ref="ruleForm"
                  label-suffix="："
                  label-position="left"
                  :model="ruleForm"
                  label-width="120px"
                  class="demo-ruleForm"
                >
                  <el-form-item label="菜单名称" prop="name">
                    <!-- 限制el-input输入4个汉字8个字母数字 -->
                    <el-input v-model="ruleForm.name" @input="limitInput" />
                    <span class="font_color">菜单名称字数不多于4个汉字或8个字母</span>
                  </el-form-item>
                  <div v-if="navigationBarDisplay">
                    <div>
                      <el-form-item label="页面内容" prop="type">
                        <el-radio-group v-model="ruleForm.type">
                          <el-radio label="view">跳转页面</el-radio>
                          <el-radio label="miniprogram">跳转小程序</el-radio>
                          <!-- <el-radio label="click">回复素材</el-radio> -->
                          <el-radio label="media_id">回复素材</el-radio>
                        </el-radio-group>
                        <br>
                        <span v-if="ruleForm.type === 'view'" class="font_color">点击该菜单会跳到页面链接</span>
                      </el-form-item>
                      <el-form-item v-if="ruleForm.type === 'miniprogram'" label="AppId" prop="appid">
                        <el-input v-model="ruleForm.appid" />
                      </el-form-item>
                      <el-form-item v-if="ruleForm.type === 'miniprogram'" label="小程序路径" prop="pagepath">
                        <el-input v-model="ruleForm.pagepath" />
                        <span class="font_color">小程序路径不可用时跳转页面链接</span>
                      </el-form-item>
                      <el-form-item
                        v-if="ruleForm.type === 'view' || ruleForm.type === 'miniprogram'"
                        label="网页链接"
                        prop="url"
                      >
                        <el-input v-model="ruleForm.url" />
                      </el-form-item>
                      <el-form-item v-if="ruleForm.type === 'media_id'" label="消息类型" prop="messageType">
                        <el-radio-group v-model="ruleForm.messageType" @change="handelChange()">
                          <!-- <el-radio :label="0">图文消息</el-radio>
                        <el-radio :label="1">图片</el-radio>
                        <el-radio :label="2">视频</el-radio>
                        <el-radio :label="3">音频</el-radio>
                        <el-radio :label="4">文本</el-radio>
                        <el-radio :label="5">链接</el-radio> -->
                          <el-radio label="image">图片</el-radio>
                          <el-radio label="video">视频</el-radio>
                          <el-radio label="voice">语音</el-radio>
                          <el-radio label="news">图文</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item
                        v-if="ruleForm.type === 'media_id'"
                        v-loading="formItemLoading"
                        label="消息内容"
                        prop="media_id"
                      >
                        <el-select v-model="ruleForm.media_id" style="width:100%" placeholder="请选择活动区域">
                          <el-option
                            v-for="(item, index) in messageContentList.item"
                            :key="index"
                            :label="ruleForm.messageType == 'news' ? item.content.news_item[0].title : item.name"
                            :value="item.media_id"
                          >
                            <div v-if="ruleForm.messageType == 'news'" class="flex">
                              <div>{{ item.content.news_item[0].title }}</div>
                              <div style="width: 10px;" />
                              <div style="color: #b9b9b9;">{{ item.content.news_item[0].digest }}</div>
                            </div>
                            <div v-else class="flex">
                              <el-image
                                style="width: 40px; height: 40px; padding: 5px 0px;"
                                :src="item.url"
                                :preview-src-list="[item.url]"
                                :referrerpolicy="'no-referrer'"
                              />
                              <div style="width: 10px;" />
                              <div>{{ item.name }}</div>
                              <div style="width: 10px;" />
                              <div style="color: #b9b9b9;">{{ item.media_id }}</div>
                            </div>
                            <!-- <div class="flex">
                              <el-image
                                style="width: 40px; height: 40px; padding: 5px 0px;"
                                :src="item.url"
                                :preview-src-list="[item.url]"
                                :referrerpolicy="'no-referrer'"
                              />
                              <div style="width: 10px;" />
                              <div>{{ item.name }}</div>
                              <div style="width: 10px;" />
                              <div style="color: #b9b9b9;">{{ item.media_id }}</div>
                            </div> -->
                          </el-option>
                        </el-select>
                        <span class="font_color">如果没有数据，请去公众号添加</span>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- <el-form-item style="text-align: right">
                    <el-button type="primary" :loading="buttonLoading" @click="handlePreserve">保 存</el-button>
                  </el-form-item> -->
                </el-form>
              </div>
              <div v-else class="hintLanguage">点击左侧菜单进行编辑操作</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="dialog_button">
        <el-button type="primary" :loading="buttonLoading" @click="handlePreserve('menuForm')">保 存</el-button>
        <el-button @click="handleCloseS">关 闭</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
// API
import { setMenu, updateMenu, getMenuDetail, getMaterialList } from '@/api/admin/merchants.js'
// 组件
// import Sortable from 'sortablejs'
// 方法
// import { deepClone } from '@/utils'

export default {
  components: {},
  props: {
    id: {
      type: Number,
      default: 0
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navigationBarList: [],
      navigationBarStatus: -1,
      navigationBarStatusSon: 0,
      navigationBarDisplay: true,
      weChatLoading: false,
      buttonLoading: false,
      formItemLoading: false,
      ruleForm: {
        name: '',
        type: '',
        appid: '',
        pagepath: '',
        url: '',
        messageType: 0,
        media_id: ''
      },
      menuForm: {
        name: ''
      },
      menuRules: {
        name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }]
      },
      // rules: {
      //   name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
      //   type: [{ required: true, message: '请选择页面内容', trigger: 'blur' }],
      //   appid: [{ required: true, message: '请输入appid', trigger: 'blur' }],
      //   pagepath: [{ required: true, message: '请输入小程序路径', trigger: 'blur' }],
      //   url: [{ required: true, message: '请输入网页链接', trigger: 'blur' }],
      //   messageType: [{ required: true, message: '请选择消息类型', trigger: 'blur' }],
      //   media_id: [{ required: true, message: '请选择消息内容', trigger: 'blur' }]
      // },
      messageContentList: {
        item: [],
        item_count: 0,
        total_count: 0
      }
    }
  },
  watch: {
    // 监听表单数据
    // ruleForm: {
    //   handler(val) {
    //     this.navigationBarList = this.navigationBarList.map(item => {
    //       if (item.id === val.id) {
    //         return val
    //       } else {
    //         return item
    //       }
    //     })
    //   },
    //   deep: true
    // },
    // 监听ruleForm.messageType的变化
    'ruleForm.messageType': {
      handler(val) {
        if (val) {
          this.formItemLoading = true
          const data = {
            type: val
          }
          getMaterialList(this.$route.params.id, data)
            .then(res => {
              this.messageContentList = res.data
            })
            .finally(() => {
              this.formItemLoading = false
            })
        }
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    /* const tableElement = this.$refs.multipleTable
    new Sortable(tableElement, {
      animation: 600, // 拖拽动画时间（以毫秒为单位）
      onChoose: this.handleChoose, // 选择元素时的回调函数
      onEnd: this.handleEnd, // 拖拽结束时的回调函数
      // draggable: '.weChat_button', // 指定哪些元素可以拖拽
      filter: '.weChat_option' // 指定哪些元素不可以拖拽
    })*/
  },
  methods: {
    /* // 选择元素时的回调函数
    handleChoose(evt) {},
    // 拖拽结束时的回调函数
    handleEnd(evt) {
      // 初始位置
      const oldIndex = evt.oldIndex
      // 更换后的位置
      const newIndex = evt.newIndex
      // 交换位置
      const data = deepClone(this.navigationBarList)
      data.splice(newIndex, 0, data.splice(oldIndex, 1)[0])
      console.log(data)
      this.navigationBarList = data
    },*/
    handleOpen() {
      this.getList()
    },
    getList() {
      this.weChatLoading = true
      getMenuDetail(this.id).then(res => {
        const data = res.data
        const list = []
        for (const i in data.config) {
          list.push(data.config[i])
        }
        this.menuForm.name = data.name
        this.navigationBarList = list
        // if (data.selfmenu_info) {
        // this.navigationBarList = this.navigationBarList.map(item => {
        //   if (item.sub_button) {
        //     item.sub_button = item.sub_button.list
        //   }
        //   return item
        // })
        // }
        this.weChatLoading = false
      })
    },
    // 添加菜单
    handleAdd() {
      const data = {
        name: '',
        type: ''
      }
      this.navigationBarList.push(data)
      this.handleClick(data, this.navigationBarList.length - 1)
    },
    // 切换菜单
    handleClick(item, index) {
      this.navigationBarStatus = index
      this.navigationBarStatusSon = -1
      this.ruleForm = item
      if (item.sub_button) {
        this.navigationBarDisplay = false
      } else {
        this.navigationBarDisplay = true
      }

      /* setTimeout(() => {
        if (!this.$refs.multipleTableSon) return false
        const tableElementSon = this.$refs.multipleTableSon[0]
        new Sortable(tableElementSon, {
          animation: 600, // 拖拽动画时间（以毫秒为单位）
          onChoose: this.handleChooseSon, // 选择元素时的回调函数
          onEnd: this.handleEndSon // 拖拽结束时的回调函数
        })
      })*/
    },
    /* // 选择元素时的回调函数
    handleChooseSon(evt) {},
    // 拖拽结束时的回调函数
    handleEndSon(evt) {
      // 初始位置
      const oldIndex = evt.oldIndex
      // 更换后的位置
      const newIndex = evt.newIndex
      console.log(oldIndex, newIndex)
    },*/
    // 判断输入的内容是否超过限制
    limitInput() {
      const name = this.ruleForm.name
      const hanziRegex = /[\u4e00-\u9fa5]/g
      const letterAndNumberRegex = /[a-zA-Z0-9]/g

      const hanziCount = (name.match(hanziRegex) || []).length
      const letterAndNumberCount = (name.match(letterAndNumberRegex) || []).length

      if (hanziCount > 4 || letterAndNumberCount > 8) {
        // 如果超过限制，截断输入的内容
        this.ruleForm.name = name.slice(0, name.length - 1)
      }
    },
    // 添加子菜单
    handleAddSon(item, index) {
      this.navigationBarDisplay = true
      this.navigationBarList = this.navigationBarList.map((item2, index2) => {
        if (index2 === index) {
          if (item2.sub_button) {
            item2.sub_button.push({
              name: '',
              type: ''
            })
          } else {
            item2 = {
              name: item2.name,
              sub_button: [
                {
                  name: '',
                  type: ''
                }
              ]
            }
          }
          this.handleClickSon(item2.sub_button[item2.sub_button.length - 1], item2.sub_button.length - 1)
        }
        return item2
      })
    },
    // 切换子菜单
    handleClickSon(item, index) {
      this.navigationBarStatusSon = index
      this.ruleForm = item
      this.navigationBarDisplay = true
    },
    // 取消选中菜单
    handleClose() {
      this.navigationBarStatus = -1
      this.navigationBarStatusSon = 0
      this.ruleForm = {}
    },
    // 删除菜单
    handleDelete() {
      // 删除父菜单
      if (this.navigationBarStatus > -1 && this.navigationBarStatusSon === -1) {
        this.navigationBarList.splice(this.navigationBarStatus, 1)
        this.navigationBarStatus = -1
      } else if (this.navigationBarStatus > -1 && this.navigationBarStatusSon > -1) {
        // 删除子菜单
        this.navigationBarList[this.navigationBarStatus].sub_button.splice(this.navigationBarStatusSon, 1)
      }
      this.ruleForm = {}
    },
    // 保存菜单
    handlePreserve(menuForm) {
      // if (!this.menuForm.name) {
      //   this.$message.error('菜单名称不能为空！')
      //   return false
      // }
      this.$refs[menuForm].validate((valid) => {
        if (valid) {
          this.buttonLoading = true
          const data = {
            name: this.menuForm.name,
            config: this.navigationBarList
          }
          if (this.id === 0) {
            setMenu(this.$route.params.id, data)
              .then(res => {
                this.handleCloseS()
                this.$message({
                  message: '新增成功',
                  type: 'success'
                })
                this.buttonLoading = false
              })
              .catch(() => {
                this.buttonLoading = false
              })
          } else {
            updateMenu(this.$route.params.id, this.id, data)
              .then(res => {
                this.handleCloseS()
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
                this.buttonLoading = false
              })
              .catch(() => {
                this.buttonLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    handleCloseS() {
      this.$emit('handleCloseS')
      this.navigationBarList = []
      this.navigationBarStatus = -1
      this.navigationBarStatusSon = 0
      this.ruleForm = {}
    },
    handelChange() {
      this.ruleForm.media_id = ''
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.font_color {
  color: #999;
}
.container_bg {
  width: 100%;
  /* height: 88vh; */
  /* background-color: #f2f2f6; */
  .phone_weChat {
    width: 415px;
    height: 72vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f6;
    border-radius: 40px;
    .weChat {
      width: 750px;
      height: 130vh;
      zoom: 0.5;
      background-color: #fff;
      font-size: 26px;
      position: relative;
      .weChat_bottom {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 100px;
        border-top: 1px solid #e7e7eb;
        .weChat_bottomCode {
          width: 130px;
          img {
            display: inline-block;
            width: 40%;
          }
        }
        .weChat_bottomList {
          width: 100%;
          div {
            width: 100%;
            height: 100px;
            line-height: 95px;
            text-align: center;
            /* border: 1px solid #e7e7eb; */
            font-size: 30px;
            color: #39495a;
            user-select: none;
          }
          .weChat_button {
            position: relative;
            .weChat_option {
              position: absolute;
              top: -120px;
              left: 0px;
              display: flex;
              flex-direction: column-reverse;
              align-items: center;
              .weChat_bg {
                width: 160px;
                background-image: url('~@/assets/images/add-btn.png');
                background-size: 100% 100%;
              }
              .weChat_all {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                height: 420px;
                .weChat_asd {
                  width: 160px;
                  height: 80px;
                  line-height: 80px;
                  text-align: center;
                  background-color: #fff;
                  /* border: 1px solid #e7e7eb; */
                  /* border-bottom: none; */
                }
              }
            }
          }
        }
      }
    }
  }
  .control_panel {
    width: 1140px;
    height: 72vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f6;
    /* border-radius: 40px; */
    .control_center {
      width: 1120px;
      height: 70vh;
      background-color: #fff;
      .top_label {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #e7e7eb;
        div {
          font-size: 14px;
          margin: 0px 20px;
        }
      }
      .form_list {
        width: 1000px;
        margin: 0 auto;
      }
      .hintLanguage {
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }
    }
  }
}
.dialog_button {
  text-align: center;
  margin-top: 20px;
  /* border-top: 1px solid #dcdfe6; */
  padding-top: 10px;
}
</style>
