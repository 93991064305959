<template>
  <div class="app-container special-center">
    <h3 v-if="utils.getVenues().name">{{ utils.getVenues().name }}</h3>
    <div class="guide">
      <wx-guide />
    </div>
    <div v-if="utils.getVenues().id!==-1" class="sign_in">
      <SignIn />
    </div>
    <div>
      <Relevance />
    </div>
    <div>
      <accessSettings />
    </div>

    <div class="refresh" @click="setRefresh">刷新品牌二维码</div>

  </div>
</template>

<script>
import { refreshAppInfo } from '@/api/store/management.js'
import WxGuide from './WxGuide.vue'
import SignIn from './SignIn.vue'
import Relevance from './Relevance.vue'
import accessSettings from './accessSettings.vue'
export default {
  components: { WxGuide, SignIn, Relevance, accessSettings },
  data() {
    return {

    }
  },
  methods: {
    setRefresh() {
      refreshAppInfo().then(res => {
        this.$message(res.msg)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.refresh{
  position: absolute;
  bottom: 100px;
  right: 100px;
  width: 50px;
  height: 20px;
  // background:#fff;
  color:#ffffff00;
  cursor: none;
}
</style>
