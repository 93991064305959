var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container m-t-20" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "搜索活动名称" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleFilter($event)
                    }
                  },
                  model: {
                    value: _vm.listQueryParams.query,
                    callback: function($$v) {
                      _vm.$set(_vm.listQueryParams, "query", $$v)
                    },
                    expression: "listQueryParams.query"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      loading: _vm.searchLoading
                    },
                    on: { click: _vm.handleFilter },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("s_offline_event_add"),
                  expression: "btn_access('s_offline_event_add')"
                }
              ],
              staticClass: "filter-item m-l-10",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "s_offline_event_add" })
                }
              }
            },
            [_vm._v(" 新增活动 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.TableLoading,
              expression: "TableLoading"
            }
          ],
          ref: "multipleTable",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "活动名称", prop: "name", "min-width": "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "cover", label: "活动封面", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.cover
                      ? _c("el-image", {
                          staticStyle: { width: "160px" },
                          attrs: { src: row.cover }
                        })
                      : _c("div", { staticClass: "text-danger" }, [
                          _vm._v("暂无封面图")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "活动报名费" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: row.fee === "0.00" ? "success" : "" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.fee === "0.00" ? "免费" : row.fee + " 元"
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "剩余名额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (row.max_member == "0"
                            ? "不限制"
                            : row.max_member -
                              row.valid_registrant_count +
                              " 人") + " "
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "报名人数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.valid_registrant_count + " 人" + " ") +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.cancelled === 1
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("活动已取消")
                        ])
                      : row.cancelled === 0 &&
                        _vm.currentTime < new Date(row.sign_up_start_time)
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("未开始")
                        ])
                      : row.cancelled === 0 &&
                        new Date(row.sign_up_start_time) <= _vm.currentTime &&
                        _vm.currentTime <= new Date(row.sign_up_end_time)
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("报名中")
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("已截至报名时间")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "活动时间", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.event_start_time.slice(0, 16) +
                            " - " +
                            row.event_end_time.slice(0, 16)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "140px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "m-2",
                        on: {
                          click: function($event) {
                            return _vm.handleDetails(row.id)
                          }
                        }
                      },
                      [_vm._v(" 详 情 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("s_offline_event_edit"),
                            expression: "btn_access('s_offline_event_edit')"
                          }
                        ],
                        staticClass: "m-2",
                        attrs: {
                          type: "primary",
                          disabled: row.cancelled === 1
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "s_offline_event_edit",
                              params: { id: row.id }
                            })
                          }
                        }
                      },
                      [_vm._v(" 编 辑 ")]
                    ),
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "course",
                        attrs: { "hide-on-click": false }
                      },
                      [
                        _c("el-button", { attrs: { type: "info" } }, [
                          _vm._v(" 更多 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right"
                          })
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            row.cancelled !== 1 &&
                            _vm.btn_access("s_offline_event_cancel")
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.handleCancel(row.id)
                                      }
                                    }
                                  },
                                  [[_vm._v(" 取消活动 ")]],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access(
                                      "s_offline_event_delete"
                                    ),
                                    expression:
                                      "btn_access('s_offline_event_delete')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handleDelete(row.id)
                                  }
                                }
                              },
                              [[_vm._v(" 删除活动 ")]],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认取消活动",
            visible: _vm.dialogVisibleTask,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "20%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleTask = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c("div", { staticClass: "font-16" }, [
            _vm._v(
              "活动取消后，报名涉及的费用系统不予自动退款，需自行退费处理，确认取消活动吗？"
            )
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "m-t-20",
              attrs: {
                model: _vm.form,
                "label-position": "left",
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "请选择取消原因" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "m-t-6",
                      model: {
                        value: _vm.form.reason,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "reason", $$v)
                        },
                        expression: "form.reason"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "m-y-4", attrs: { label: 1 } },
                        [_vm._v("报名人数不足")]
                      ),
                      _c("br"),
                      _c(
                        "el-radio",
                        { staticClass: "m-y-4", attrs: { label: 2 } },
                        [_vm._v("极端天气")]
                      ),
                      _c("br"),
                      _c(
                        "el-radio",
                        { staticClass: "m-y-4", attrs: { label: 3 } },
                        [_vm._v("其他（特殊原因）")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发送短信通知" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.send_sms,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "send_sms", $$v)
                      },
                      expression: "form.send_sms"
                    }
                  })
                ],
                1
              ),
              _c("span", { staticClass: "font-12 text-info" }, [
                _vm._v("*您可选择将活动取消的通知通过短信发送给已报名的用户")
              ])
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleTask = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c("detailsDialog", {
        attrs: {
          "dialog-visible-account": _vm.dialogVisibleAccount,
          "type-id": _vm.typeId
        },
        on: { roleVisible: _vm.handleRoleVisible }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }