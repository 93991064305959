<template>
  <div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="groupVisible"
      title="约私教"
      class="dialog_w1200"
      @close="cancelDialog"
    >
      <div v-loading="pageloading" class="content" element-loading-text="拼命加载中">
        <el-form ref="ruleForm" :rules="rules" :model="form" label-width="100px" label-position="left" label-suffix=":">
          <!-- 预约日期 -->
          <el-row :gutter="20">
            <el-col :span="9">
              <div class="rili item">
                <div class="title flex row-between col-top">
                  <div>
                    预约日期 （<span class="text-warning">{{ interval_time===0?'今天':interval_time+'天后' }}</span>）
                  </div>
                  <div>
                    <el-button type="primary" size="mini" class="m-r-20" @click="setToday">返回今天</el-button>
                  </div>
                </div>
                <div class="rili_list">
                  <Calendar ref="Calendar" :ago-day-hide="agoDayHide" @choseDay="clickDay" />
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="course item">
                <div class="title">
                  课程信息
                </div>
                <div class="m-y-10">
                  <!-- 教练列表 -->
                  <el-select
                    v-model="form.store_user_id"
                    placeholder="请选择教练"
                    @change="storeUserChange"
                  >
                    <el-option
                      v-for="item in storeUserOptions"
                      :key="item.id"
                      :label="item.real_name"
                      :value="item.id"
                    />
                  </el-select>
                </div>
                <div class="course_box">
                  <ul v-if="!utils.empty(courseOptions)" class="course_list">
                    <!-- <li v-for="(item,index) in courseOptions" :key="index" class="course_item" :class="[form.course_id===item.id?'active':'']" @click="checkCourse(item)">{{ item.start_time | formatDateLT }} ~ {{ item.end_time | formatDateLT }} / {{ item.name }} [{{ item.galleryful }}]</li> -->
                    <li v-for="(item,index) in courseOptions" :key="index" class="course_item" :class="[form.course_id===item.id?'active':'']" @click="checkCourse(item)">{{ item.name }} [{{ item.galleryful }}]</li>
                  </ul>
                  <span v-else class="font-16 m-t-10 text-danger">当前老师暂无课程</span>
                </div>
                <div class="course_time m-y-10">
                  <p><span class="title">预约时间（课程共{{ course_duration }}分钟）</span></p>
                  <div class="m-b-10 flex ">
                    <div>
                      上课时间
                    </div>
                    <div class="m-l-10">
                      <el-select v-model="form.start_time_d" placeholder="请选择上课时间" filterable style="width:100%" size="mini" @change="upStartTime">
                        <el-option
                          v-for="(item,index) in timeQuantum"
                          :key="index"
                          :label="item.start"
                          :value="item.start"
                          :disabled="!item.status"
                        />
                      </el-select>
                    </div>
                    <!-- 开始时间<el-input-number v-model="stime_h" :min="0" :max="24" placeholder="请输入内容" type="number" style="width:100px" class="m-x-10" />时<el-input-number v-model="stime_m" :min="0" :max="59" placeholder="请输入内容" type="number" style="width:100px" class="m-x-10" />分 -->
                  </div>
                  <div class="m-b-5">
                    <div class="flex">
                      <div>课程时间</div>
                      <div class="m-l-10 text-info">{{ start_time_day }} {{ form.start_time_d }} <span v-if="end_time">至 {{ end_time }}</span></div>
                    </div>
                    <!-- 结束时间<el-input-number v-model="etime_h" :min="0" :max="24" placeholder="请输入内容" type="number" style="width:100px" class="m-x-10" :disabled="true" />时<el-input-number v-model="etime_m" :min="0" :max="59" placeholder="请输入内容" type="number" style="width:100px" class="m-x-10" :disabled="true" />分 -->
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="user item">
                <div class="title">会员信息</div>
                <div class="user_info">
                  <el-form-item label="会员信息" prop="user_id">
                    <Select ref="userRef" :type="2" :width="195" @changeId="handleMemberID" />
                    <!-- <el-select
                      v-model="form.user_id"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请选择会员,可搜索昵称|姓名|手机号"
                      :remote-method="remoteUserMethod"
                      :loading="loading.searchLoading"
                      style="width:90%"
                      :clearable="true"
                      no-data-text="暂无数据"
                      no-match-text="无搜索项,重新搜索"
                      @change="userChange"
                    >
                      <el-option
                        v-for="item in userOptions"
                        :key="item.id"
                        :label="getUserNames(item)"
                        :value="item.id"
                      >
                        <div class="flex">
                          <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="item.avatar">
                            <img :src="errorMemberSrc()">
                          </el-avatar>
                          <span>{{ getUserNames(item) }}</span>-
                          <span>{{ item.phone }}</span>
                        </div>
                      </el-option>
                    </el-select> -->
                  </el-form-item>
                  <el-form-item label="会员卡" prop="user_membership_card_id">
                    <el-select
                      v-model="form.user_membership_card_id"
                      remote
                      reserve-keyword
                      placeholder="请选择会员卡"
                      :loading="loading.searchLoading"
                      style="width:90%"
                      :clearable="true"
                      no-data-text="暂无数据"
                      :disabled="is_display"
                      @change="userCardChange"
                    >
                      <el-option-group
                        v-for="group in userCardOptions"
                        :key="group.label"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.options"
                          :key="item.id"
                          :label="item.card.name+' / '+item.number"
                          :value="item.id"
                          :disabled="!item.course_limit"
                        >
                          <div class="flex">
                            <!-- <el-tag v-if="item.course_limit && item.status_id == 2">正常</el-tag> -->
                            <el-tag v-if="item.course_limit && item.status_id != 2" type="warning">{{ item.status.name }}</el-tag>
                            <el-tag v-if="!item.course_limit" type="danger">不支持</el-tag>
                            <div class="m-l-6">{{ item.card.name+' / '+item.number }}</div>
                          </div>
                        </el-option>
                      </el-option-group>
                    </el-select>
                    <br>
                    <small v-if="text_display && form.user_id" class="text-danger">当前会员暂无会员卡</small>
                  </el-form-item>
                  <el-form-item label="卡号">
                    <el-tag v-if="userCardInfo&&userCardInfo.number">
                      {{ userCardInfo?userCardInfo.number:'' }}
                    </el-tag>
                  </el-form-item>
                  <el-form-item label="手机号">
                    {{ userInfo.phone }}
                  </el-form-item>
                  <el-form-item label="余额">
                    {{ userCardInfo?userCardInfo.limit:'' }}{{ userCardInfo?formatterCardType(userCardInfo.type):'' }}
                  </el-form-item>
                  <el-form-item v-if="userCardInfo.valid_time" label="有效期至">
                    {{ userCardInfo.valid_time?userCardInfo.valid_time:'--' }}
                  </el-form-item>
                  <el-form-item label="预约人次">
                    <el-input-number v-model="form.subscribe_number" :min="1" :max="10" label="描述文字" />
                  </el-form-item>
                  <el-form-item label="备注">
                    <el-input v-model="form.dremarksc" type="textarea" />
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>

        </el-form>
      </div>

      <div class="footer text-center m-t-20">
        <el-button type="primary" @click="submit">提交</el-button>
        <el-button type="text" @click="cancelDialog">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// https://github.com/zwhGithub/vue-calendar
import Calendar from 'vue-calendar-component'
import { addBook } from '@/api/store/worktable.js'
import { getCourse } from '@/api/store/course.js'
import { getUser, getUserDetails, getUserSupportCard, getUserMembershipCardDetails } from '@/api/store/user.js'
import { getCoach, getStoreUserTimeQuantum } from '@/api/store/venues.js'
import { deepClone } from '@/utils'
import Select from '@/components/elSelect/Select'
export default {
  name: 'PrivateBook',
  components: { Calendar, Select },
  props: {
    venuesId: {
      type: Number,
      default() {
        return -1
      }
    },
    groupVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: [
        'user_id',
        'store_user_id',
        'subscribe_number',
        'user_membership_card_id',
        'course_id'
      ],
      courseInfo: {},
      form: {
        user_membership_card_id: '', // 会员卡卡号
        subscribe_number: 1, // 预约人数
        class_schedule_id: '', // 课程表ID
        store_user_id: '', // 教练id
        user_id: '', // 用户ID
        course_id: '', // 课程ID
        start_time: this.$moment().format('YYYY-MM-DD'), // 上课时间
        start_time_d: '', // 开始时间点
        venues_id: this.utils.getVenues().id
      },
      start_time_day: this.$moment().format('YYYY-MM-DD'),
      end_time: '',
      userInfo: {},
      userOptions: {},
      userCardOptions: {},
      courseOptions: {},
      loading: { searchLoading: false },
      agoDayHide: this.$moment().startOf('day').unix().toString(),
      interval_time: 0,
      pageloading: false,
      userCardInfo: {},
      storeUserOptions: {},
      course_duration: '--',
      stime_h: 0,
      stime_m: 0,
      etime_h: 1,
      etime_m: 0,
      timeQuantum: [],
      is_display: true,
      text_display: false
    }
  },
  watch: {
    stime_h(v, ov) {
      this.stime_h = v
      this.updateTime()
    },
    stime_m(v, ov) {
      this.stime_m = v
      this.updateTime()
    },
    course_duration(v, ov) {
      this.updateTime()
    }
  },
  created() {
    this.getStoreUserList()
    this.getUserList()
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
  },
  methods: {
    // 私教预约时间获取
    getStoreUserTimeQuantumInfo() {
      if (this.form.store_user_id && this.start_time_day) {
        getStoreUserTimeQuantum(this.form.store_user_id, this.form.venues_id, this.start_time_day).then(res => {
          if (!this.utils.empty(res.data)) {
            this.timeQuantum = res.data
          } else {
            this.form.start_time_d = ''
            this.end_time = ''
          }
        })
      } else {
        this.form.start_time_d = ''
        this.end_time = ''
      }
    },
    // 选择上课时间事件
    upStartTime() {
      this.updateTime()
    },
    updateTime() {
      if (this.start_time_day && this.form.start_time_d) {
        const s = this.$moment(this.start_time_day + ' ' + this.form.start_time_d).add(this.course_duration, 'm').format('YYYY-MM-DD HH:mm')
        this.end_time = s
      } else {
        this.end_time = ''
      }

      // console.log(this.form.start_time)
      // var s = this.$moment(this.form.start_time).hour(this.stime_h).minute(this.stime_m)
      // s.add(this.course_duration, 'm')
      // this.etime_h = s.format('HH')
      // this.etime_m = s.format('mm')
      // this.$set(this.form, 'start_time', s.format('YYYY-MM-DD HH:mm'))
    },
    // 选中教练
    storeUserChange(val) {
      this.pageloading = true
      this.course_duration = '--'
      this.getPrivateTeacherList(val)
      this.getStoreUserTimeQuantumInfo()
      this.form.user_membership_card_id = ''
      this.getUserCardList()
    },
    getStoreUserList() {
      const data = {}
      // if (this.listQuery.query) data.query = this.utils.getLikeQueryParams('nickname', this.listQuery.query)
      data.limit = 999
      data.type = 1
      getCoach(data).then(res => {
        if (res.data.list.length > 0) {
          this.$set(this.form, 'store_user_id', res.data.list[0]['id'])
        }
        // 数据嵌入
        this.loading.searchLoading = false
        this.storeUserOptions = res.data.list
        this.getGroupVenuesList()
        this.setToday()
      })
    },
    /**
     * 用户列表
     */
    getUserList() {
      const data = {}
      if (this.listQuery.query) data.query = this.utils.getLikeQueryParams('nickname|phone|real_name', this.listQuery.query)
      // data.limit = 999
      getUser(data).then(res => {
        // 数据嵌入
        this.loading.searchLoading = false
        this.userOptions = res.data.list
      })
    },
    /**
     * 搜索会员卡
     */
    // remoteUserCardMethod(query) {
    //   if (query !== '') {
    //     this.loading.searchLoading = true
    //     // 获取指定会员下的会员卡列表
    //     this.getUserCardList()
    //   } else {
    //     this.userCardOptions = {}
    //   }
    // },
    /**
     * 搜索用户
     */
    remoteUserMethod(query) {
      this.loading.searchLoading = true
      this.listQuery.query = query
      this.getUserList()
    },
    /**
     * 获取选中会员
     */
    userChange(id) {
      this.form.user_membership_card_id = ''
      this.userCardOptions = {}
      if (!id) {
        // 重置选中项
        this.listQuery.query = ''
        this.userInfo = {}
        this.user_info_show = false
        this.getUserList()
        this.getUserCardList()
        return
      }
      this.user_info_show = true
      // 请求API获取用户信息
      getUserDetails(id).then(res => {
        this.userInfo = res.data
        this.getUserCardList()
        this.userCardInfo = {}
      })
    },
    /**
     * 获取选中会员会员卡
     */
    userCardChange(id) {
      if (!id) {
        this.userCardInfo = {}
        return
      }
      this.form.user_membership_card_id = id
      this.user_info_show = true
      // 获取用户会员卡详情
      getUserMembershipCardDetails(id).then(res => {
        this.userCardInfo = res.data
      })
    },
    // 获取用户会员卡列表
    getUserCardList() {
      if (!this.userInfo.id) {
        this.is_display = true
        return
      }
      if (!this.form.course_id) {
        this.is_display = true
        return
      }
      this.is_display = false
      // getUserMembershipCard({ query: JSON.stringify({ user_id: this.userInfo.id }) }).then(res => {
      //   if (this.utils.empty(res.data.list)) this.$message.error('当前会员无可用会员卡')
      //   // 数据嵌入
      //   this.loading.searchLoading = false
      //   this.userCardOptions = res.data.list
      // })
      const data = {
        offset: 0,
        limit: 999
      }
      getUserSupportCard(this.form.course_id, this.userInfo.id, data).then(res => {
        const data = res.data.list
        if (data.length <= 0) this.text_display = true
        else this.text_display = false
        const optionsY = data.filter(item => item.course_limit)
        const optionsN = data.filter(item => !item.course_limit)
        this.userCardOptions = [
          { label: '可用', options: optionsY },
          { label: '不支持', options: optionsN }
        ]
      }).finally(() => {
        this.loading.searchLoading = false
      })
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let postData = {}
          postData = deepClone(this.form)
          if (!postData.course_id) {
            this.$message.error('请选择课程')
            return false
          }
          if (!postData.store_user_id) {
            this.$message.error('请选择教练')
            return false
          }
          if (!postData.start_time_d) {
            this.$message.error('请选择上课时间')
            return false
          }
          postData.start_time = postData.start_time + ' ' + postData.start_time_d
          delete postData.start_time_d
          this.submitBook(postData.user_id, postData)
        }
      })
    },
    // 提交预约
    submitBook(user_id, postData, force = 0) {
      postData.force = force
      addBook(user_id, postData).then(res => {
        this.$message.success('添加成功')
        this.cancelDialog()
      }).catch(error => {
        if (error.status === 701) {
          this.$confirm(error.msg, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.submitBook(user_id, postData, 1)
          })
        }
      })
    },
    // 关闭页面
    cancelDialog() {
      this.$emit('update:groupVisible', false)
    },
    // 获取指定日期课程列表
    async getGroupVenuesList() {
      this.$set(this.form, 'course_id', '')
      this.getPrivateTeacherList(this.form.store_user_id)
    },
    /**
     * 获取指定老师私教课程列表
     */
    getPrivateTeacherList(id) {
      this.pageloading = true
      this.$set(this.form, 'course_id', '')
      const search = { query: JSON.stringify({ 'store_user_id': id, 'type': 1 }), limit: 99 }
      getCourse(search).then(res => {
        this.courseOptions = res.data.list
        this.pageloading = false
      }).catch(() => {})
    },
    // 选择日期
    clickDay(data) {
      this.start_time_day = this.$moment(data).format('yyyy-MM-DD')
      this.form.start_time = this.$moment(data).format('yyyy-MM-DD')
      this.getStoreUserTimeQuantumInfo()
      this.updateTime()

      // 计算相隔天数
      const s = this.$moment().startOf('day').format('yyyy/MM/DD')// 上课时间
      const e = data // 结束时间
      const diff_day = this.$moment(e).diff(s, 'days')
      this.interval_time = diff_day

      // 获取当前日期课程列表
      this.getGroupVenuesList()
    },
    setToday() {
      // 返回今天
      this.$refs.Calendar.ChoseMonth(this.$moment().startOf('day').format('yyyy/MM/DD'))
    },
    // 选中课程
    checkCourse(v) {
      this.$set(this.form, 'course_id', v.id)
      this.course_duration = v.duration
      this.etime_h = Number(this.stime_h) + Number(Math.round(this.course_duration / 60))
      this.etime_m = this.course_duration % 60
      this.form.user_membership_card_id = ''
      this.getUserCardList()
    },
    formatterCardType(v) {
      switch (v) {
        case 0:
          return ''
        case 1:
          return '次'
        case 2:
          return '元'
        default:
          break
      }
    },
    handleMemberID(val) {
      this.$set(this.form, 'user_id', val)
      this.userChange(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.item{
  .title{
    height: 30px;
    margin-bottom: 10px;
    color:#82848a;
  }
  padding: 5px;
}
.rili_list{
  $rili_bg:#515a6e;
  ::v-deep .wh_container{
    width: 100% !important;
  }
  ::v-deep .wh_content_all{
    .wh_isToday.wh_chose_day{
      background:#3498db;
      color:#eee !important
    }
    .wh_chose_day{
      background:#3498db;
      color:#eee !important
    }
    // 当天
    .wh_isToday:not(.wh_chose_day){
      background: none ;
    }
    .wh_item_date{
      color:$rili_bg
    }
    // 星期
    .wh_top_tag{
      color:$rili_bg
    }
    // 日历对齐
    .wh_content .wh_content_item{
      width:14.2%
    }
    // 左翻页
    .wh_jiantou1{
      border-top: 2px solid $rili_bg;
      border-left: 2px solid $rili_bg;
    }
    // 右翻页
    .wh_jiantou2{
      border-top: 2px solid $rili_bg;
      border-right: 2px solid $rili_bg;
    }
    // 当前年
    .wh_content_li{
      color:$rili_bg
    }
    .wh_item_date.wh_want_dayhide{
      color:#bfbfbf;
    }
    .wh_item_date:hover{
      border-radius:4px;
    }
    background-color: #fff !important;
  }
}
.course_box{
  height: 170px;
  overflow:auto;
  border:1px solid rgb(238, 238, 238);
  span{
    display: block;
    text-align: center;
  }
}

.course_list{
  list-style: none;
  padding: 0;
  margin:0;
  width: 500px;
  .course_item{
    border:1px solid #eee;
    white-space:nowrap;
    padding: 6px 5px;
    border:1px solid #eee;
    background: #fff;
    overflow: auto;
    cursor:pointer;
    // margin: 1px 0px;
  }
  .course_item.active{
    color:#fff;
    background: #3498db;
  }
}
.el-form-item--small.el-form-item{
  margin-bottom:7px;
}
.course{
  /*滚动条样式代码*/
  ::-webkit-scrollbar {
    width: 8px;
    height:5px;
  }
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #606266;
    // background-image: -webkit-linear-gradient(45deg,  rgba(255, 255, 255, .4) 25%,  transparent 25%,  transparent 50%,  rgba(255, 255, 255, .4) 50%,  rgba(255, 255, 255, .4) 75%,  transparent 75%,  transparent);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }
}

</style>
