<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <!-- Info 自定义检索方法，不与通用检索同步 -->
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <van-form label-width="80px" class="window_phone">
            <!-- 创建时间 -->
            <van-field
              readonly
              clickable
              name="calendar"
              :value="vanCreationTime"
              label="创建时间："
              placeholder="点击选择创建时间"
              @click="vanCreationTimeShow = true"
            />
            <van-calendar
              v-model="vanCreationTimeShow"
              type="range"
              :min-date="minVanCreationTime"
              :max-date="maxVanCreationTime"
              color="#1989fa"
              confirm-text="确 定"
              confirm-disabled-text="请选择结束时间"
              @confirm="onCreation"
            />
          </van-form>
          <el-form-item label="创建时间" class="window_pc">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-form-item label="审核状态">
            <el-select v-model="listQueryParams.status" placeholder="请选择" filterable clearable>
              <el-option v-for="(item, index) in status_list" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="渠道">
            <el-select v-model="listQueryParams.source" placeholder="请选择" filterable clearable>
              <el-option label="app" value="app" />
              <el-option label="web-PC" value="web-PC" />
              <el-option label="web-wap" value="web-wap" />
              <el-option label="wechat" value="wechat" />
              <el-option label="wetch" value="wetch" />
              <el-option label="wxMini" value="wxMini" />
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
          <el-button class="m-b-10 window_phone" @click="handleReset">重置</el-button>
          <el-button type="primary" class="m-10">申请数：{{ tableData.count }}</el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索名称|手机号|IP" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <!-- <el-button type="primary" icon="el-icon-plus" class="filter-item m-l-10" @click="dialogVisible()">添加</el-button>
      <el-button type="warning" icon="el-icon-download" class="filter-item m-l-10" @click="appExport()">导出</el-button> -->
    </div>
    <el-table v-loading="tableLoading" element-loading-text="拼命加载中" :data="tableData.list" style="width: 100%">
      <el-table-column prop="brand_name" label="品牌名称" :min-width="equipment ? '120px' : '160px'" />
      <el-table-column prop="industry_text" label="行业" :min-width="equipment ? '120px' : '160px'" />
      <el-table-column prop="phone" label="手机号" min-width="120px">
        <template slot-scope="{ row }">
          <span v-if="row.area_code !== '86'">(+{{ row.area_code }})</span><span class="styleText cursor" @click="handleCopy(row.phone, '手机号复制成功')">{{ row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="password" label="密码" :min-width="equipment ? '60px' : '80px'" />
      <el-table-column prop="create_time" label="提交时间" :min-width="equipment ? '120px' : '160px'" />
      <el-table-column prop="update_time" label="更新时间" :min-width="equipment ? '120px' : '160px'" />
      <el-table-column prop="source" label="渠道" :min-width="equipment ? '100px' : '120px'">
        <template slot-scope="{ row }">
          <div class="flex">
            <div class="m-r-10">{{ row.source }}</div>
            <el-tooltip v-if="row.invite_store_user" class="item" effect="light" content="Right Center 提示文字" placement="right">
              <template slot="content">
                <div class="flex col-center">
                  <div>
                    <el-avatar shape="square" :size="50" :src="row.invite_store_user.avatar">
                      <img :src="errorMemberSrc()">
                    </el-avatar>
                  </div>
                  <div class="p-l-10">
                    <span>{{ getUserNames(row.invite_store_user) }}</span>
                  </div>
                </div>
              </template>
              <el-tag type="success">邀请</el-tag>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="principal" label="联系人" :min-width="equipment ? '80px' : '100px'">
        <template slot-scope="{ row }">
          <span>{{ row.principal ? row.principal : '---' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="venues_address" label="地址" :min-width="equipment ? '100px' : '160px'">
        <template slot-scope="{ row }">
          <span>{{ row.venues_address ? row.venues_address : '---' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ip" label="IP" :min-width="equipment ? '120px' : '160px'" />
      <el-table-column prop="status" label="审核状态" :min-width="equipment ? '180px' : '220px'">
        <template slot-scope="{ row }">
          <div>
            <el-tag v-if="row.status === 0" type="info">未审核</el-tag>
            <el-tag v-if="row.status === 1" type="success">已通过</el-tag>
            <div v-if="row.status === 2" class="flex">
              <el-tag type="warning">已拒绝</el-tag>
              <div class="m-l-20" style="color: #ffba00;">
                <div>拒绝理由：{{ row.rejection_type_text ? row.rejection_type_text : '无' }}</div>
                <div>拒绝原因：{{ row.rejection_grounds ? row.rejection_grounds : '无' }}</div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" :min-width="equipment ? '180px' : '260px'">
        <template slot-scope="scope">
          <el-popconfirm v-if="scope.row.status === 0 && btn_access('apply_audit')" title="通过品牌申请，确定继续吗？" @confirm="apply_status(scope.row.id, 1)">
            <el-button slot="reference" type="primary" class="m-2">同意</el-button>
          </el-popconfirm>
          <!-- <el-popconfirm v-if="scope.row.status === 0" title="拒绝品牌申请，确定继续吗？" @confirm="apply_status(scope.row.id, 2)">
            <el-button slot="reference" type="warning" class="m-2">拒绝</el-button>
          </el-popconfirm> -->
          <el-button v-if="scope.row.status === 0 && btn_access('apply_audit')" type="warning" class="m-2" @click="handleRefuse(scope.row.id, 2)">拒绝</el-button>
          <el-popconfirm v-show="btn_access('apply_del')" title="删除申请记录，确定继续吗？" @confirm="apply_del(scope.row.id)">
            <el-button slot="reference" type="danger" class="m-2">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0 && equipment"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <van-pagination
      v-show="tableData.count > 0 && !equipment"
      v-model="currentPage"
      :total-items="tableData.count"
      :show-page-size="4"
      force-ellipses
      class="custom-pagination m-t-20"
      @change="handleCurrentChange"
    />
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :width="equipment ? '20%' : '100%'"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="拒绝类型">
          <i class="icon-required" />
          <el-select v-model="form.rejection_type" clearable placeholder="请选择拒绝类型">
            <el-option :label="rejection_type[1]" :value="1" />
            <el-option :label="rejection_type[2]" :value="2" />
            <el-option :label="rejection_type[3]" :value="3" />
            <el-option :label="rejection_type[4]" :value="4" />
          </el-select>
        </el-form-item>
        <el-form-item label="驳回理由" prop="reason">
          <el-input v-model="form.rejection_grounds" type="textarea" :rows="4" placeholder="【选填】请输入驳回理由" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="apply_status(refuseId, 2)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination' // 分页组件
import { getApplyList, editApplyStatus, delApply, getReason } from '@/api/admin/merchants.js'
export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      loading: false,
      force: false,
      force_number: 0,
      status_list: [
        {
          label: '未审核',
          value: 0
        },
        {
          label: '已通过',
          value: 1
        },
        {
          label: '已拒绝',
          value: 2
        }
      ],
      searchLoading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      tableLoading: false,
      // 日期格式
      minVanCreationTime: new Date(2000, 0, 1),
      maxVanCreationTime: new Date(2099, 12, 31),
      // 创建时间
      vanCreationTime: '',
      vanCreationTimeShow: false,
      equipment: false,
      currentPage: 1,
      // 拒绝的弹窗
      dialogVisible: false,
      refuseId: 0,
      rejection_type: {},
      form: {
        rejection_type: '',
        rejection_grounds: ''
      }
    }
  },
  created() {
    // 判断页面是否大于768
    if (document.body.clientWidth > 768) {
      this.equipment = true
    } else {
      this.equipment = false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 获取列表
    getList() {
      this.searchLoading = true
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      // delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'brand_name|phone|ip', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.status !== '' && this.listQueryParams.status != null) querys.push({ field: 'status', key: this.listQueryParams.status })
      if (this.equipment) {
        if (this.listQueryParams.create_time) querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      } else {
        const vanCreationTime = this.vanCreationTime.split('至')
        if (this.vanCreationTime) querys.push({ field: 'create_time', type: 'between-time', key: vanCreationTime })
      }
      if (this.listQueryParams.source === 'wxMini') {
        querys.push({ field: 'source', type: 'like', key: this.listQueryParams.source })
      } else if (this.listQueryParams.source) {
        querys.push({ field: 'source', key: this.listQueryParams.source })
      }

      data.query = this.utils.getQueryParams(querys)

      getApplyList(data)
        .then(res => {
          this.tableData = res.data
        })
        .catch(() => {})
        .finally(() => {
          this.searchLoading = false
          this.tableLoading = false
        })
    },
    // 审核
    submit() {
      var id = this.data.id
      var status = this.data.status
      const data = {
        force: this.force,
        rejection_type: this.form.rejection_type,
        rejection_grounds: this.form.rejection_grounds
      }
      if (this.force_number > 3) return false
      editApplyStatus(id, status, data)
        .then(res => {
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.getList()
        })
        .catch(res => {
          if (res.status === 701) {
            this.$confirm(res.msg + ', 是否继续?', '提示', {
              confirmButtonText: '继续',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.force_number += 1
                this.force = true
                this.submit()
              })
              .catch(() => {})
          }
        })
    },
    apply_status(id, status) {
      console.log(123)
      if (status === 2 && this.form.rejection_type) this.dialogVisible = false
      this.force = false
      this.force_number = 0
      this.data = {
        id: id,
        status: status
      }
      this.utils.throttle(this.submit)
    },
    // 申请删除
    apply_del(id) {
      delApply(id).then(res => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
    },
    // 日期格式
    formatDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    // 创建时间
    onCreation(date) {
      const [start, end] = date
      this.vanCreationTimeShow = false
      this.vanCreationTime = `${this.formatDate(start)} 至 ${this.formatDate(end)}`
    },
    handleReset() {
      this.listQueryParams = {
        page: 1,
        limit: 10,
        query: '',
        status: '',
        source: '',
        create_time: ''
      }
      this.vanCreationTime = ''
      this.getList()
    },
    // 拒绝的弹窗
    handleRefuse(id) {
      this.dialogVisible = true
      this.refuseId = id
    },
    // 打开的回调
    handleOpen() {
      getReason().then(res => {
        this.rejection_type = res.data.rejection_type
      })
    },
    // 关闭的回调
    handleClose() {
      this.form = {
        rejection_type: '',
        rejection_grounds: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.styleText:hover {
  color: #1890ff;
}

.van-cell {
  padding: 10px 0;
  ::v-deep {
    .van-cell__title {
      margin-right: 0px;
    }
  }
}

.icon-required {
  &:after {
    content: '*';
    color: red;
    line-height: 46px;
  }
  position: absolute;
  left: -80px;
  top: -8px;
  bottom: 0;
  vertical-align: middle;
  z-index: 99;
}

@media screen and (max-width: 768px) {
  .window_pc {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .window_phone {
    display: none;
  }
}
</style>
