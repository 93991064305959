import request from '@/utils/request'

/**
 * 提交订单
 */
export function buyPlan(data) {
  return request({
    url: `/store/v1/system_plan`,
    method: 'post',
    data
  })
}

// 套餐列表
export function getPlan(data) {
  return request({
    url: `/store/v1/system_plan`,
    method: 'get',
    params: data
  })
}

// 新套餐列表
export function getPackage(brand_level) {
  return request({
    url: `/store/v2/system_plan/${brand_level}`,
    method: 'get'
  })
}
