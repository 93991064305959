var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slide_footer" }, [
    _c(
      "div",
      [
        _vm.windows && !_vm.browser
          ? _c(
              "el-link",
              {
                attrs: { type: "info", underline: false },
                on: { click: _vm.windowsDownload }
              },
              [_vm._v("电脑版下载")]
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      [
        _vm.windows && _vm.browser
          ? _c(
              "el-link",
              {
                attrs: { type: "info", underline: false },
                on: { click: _vm.websiteDownload }
              },
              [_vm._v("添加瑜小九到桌面")]
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "m-l-10" },
      [
        _c("el-link", { attrs: { type: "info", underline: false } }, [
          _vm._v("版本号：" + _vm._s(_vm.Version))
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }