import request from '@/utils/request'

// 登录获取token
export function postLogin(data) {
  return request({
    url: `/admin/v1/keep_import/login`,
    method: 'post',
    data
  })
}
// 提交导入
export function postSubmit(data) {
  return request({
    url: `/admin/v1/keep_import/submit`,
    method: 'post',
    data
  })
}
