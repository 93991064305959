import request from '@/utils/request'

// 获取模板列表
export function getList() {
  return request({
    url: `/admin/v1/matter/template`,
    method: 'get'
  })
}

// 获取物料列表
export function getDataList(params) {
  return request({
    url: `/admin/v1/matter`,
    method: 'get',
    params
  })
}

// 物料详情
export function getDetails(id) {
  return request({
    url: `/admin/v1/matter/${id}`,
    method: 'get'
  })
}

// 新增物料
export function postAdd(data) {
  return request({
    url: `/admin/v1/matter`,
    method: 'post',
    data
  })
}

// 更新物料
export function putToUpdate(id, data) {
  return request({
    url: `/admin/v1/matter/${id}`,
    method: 'put',
    data
  })
}

// 删除物料
export function deleteMaterial(id) {
  return request({
    url: `/admin/v1/matter/${id}`,
    method: 'delete'
  })
}
