<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <!-- 徽章 -->
    <Badge v-if="utils.getVenues() && utils.getVenues().id != -1" :badge="isCollapse" />
    <!-- 功能按钮 -->
    <SideHeader />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item v-for="(route, index) in permission_routes" :key="index" :item="route" :base-path="route.path" />
      </el-menu>
      <!-- 尾部 -->
      <SideFooter />
      <!-- 下载按钮 -->
      <Download v-if="!isCollapse" />
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import Badge from './Badge'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import SideHeader from './SideHeader'
import SideFooter from './SideFooter'
import Download from './Download'

export default {
  components: { SidebarItem, Logo, SideHeader, SideFooter, Badge, Download },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
