<template>
  <div v-loading="isLoading" class="content">
    <div class="head-box" :style="{background:'url('+head_bg+') no-repeat right'}">
      <div class="money-info text">可用余额（元）</div>
      <div class="money-text text">
        {{ appinfo.balance }}
      </div>
    </div>
    <div class="body">
      <div class="form box">
        <div class="input-box">
          <div class="label">
            提现金额
          </div>
          <div class="flex">
            <div class="m-x-2">￥</div>
            <div><input v-model="money" type="number" placeholder="请输入提现金额" class="input"></div>
          </div>
        </div>
        <div class="flex col-center row-between m-b-6">
          <div class="label">
            提现至
          </div>
          <div class="bank-list flex m-b-10" @tap="showWithdrawList = true">
            <div v-if="!withdrawType" class="empty-text">请选择提现方式</div>
            <div v-else class="sel-box flex col-center">
              <img class="item-img" :src="withdrawList[withdrawType].icon">
              <div class="item-title m-l-10">{{ withdrawList[withdrawType].title }}</div>
            </div>
            <i class="el-icon-arrow-right" color="#C4C4C4" />
          </div>
        </div>
        <div class="bank-info input-box">
          <div v-if="withdrawType">
            <div v-if="withdrawType === 'wechat'" class="flex row-between col-center">
              <div class="bank-info-title">
                {{ utils.empty(userinfo.storeInfo.wechat_open_id) ? '暂无微信授权信息' : '已绑定微信' }}
              </div>
              <div>
                <button class="u-reset-button bind-btn m-l-20" @click="wxOfficialAccountBaseLogin()">{{ utils.empty(userinfo.storeInfo.wechat_open_id) ? '绑定' : '重新绑定' }}</button>
              </div>
              <!-- <button v-if="!withdrawInfo" class="u-reset-button bind-btn u-m-l-20" @tap="bindThirdOauth()">绑定</button> -->
            </div>
          </div>
        </div>
        <div class="flex row-between col-center input-box">
          <div class="name">
            验证码
          </div>
          <div class="m-l-10">
            <input v-model="code" type="text" placeholder="请输入验证码" class="input code">
          </div>
          <div class="u-reset-button" @click="getCode">{{ codeText }}</div>
        </div>
        <div class="btn-submit m-b-10" @click="withdraw()">
          确认提现
        </div>
      </div>
      <!-- <div class="info box m-t-10">
        <div class="label">
          提现说明：
        </div>
        <div>
          <div class="draw-list">1.单次最低提现 {{ ruleInfo.min }} 元，最高提现 {{ ruleInfo.max }} 元；</div>
          <div class="draw-list">2.每笔收取提现手续费 {{ ruleInfo.service_fee }}%；</div>
          <div class="draw-list">3.提现申请递交后，我们将第一时间进行处理（2个工作日内），请您耐心等待，提现结果请查收对应渠道服务通知；</div>
          <div class="draw-list">4.如遇申请被驳回请点击“已驳回”查看具体驳回原因；</div>
          <div class="draw-list">5.如有其他疑问请及时联系客服{{ callNumber }}；</div>
        </div>
      </div> -->
      <div class="box m-y-10">
        <div class="label">
          提现记录<small class="text-info">(共{{ logList.count }}条)</small>：
        </div>
        <div>
          <el-table
            :data="logList.list"
            style="width: 100%"
          >
            <el-table-column prop="order_no" label="订单号" width="160px" />
            <el-table-column prop="money" label="金额">
              <template slot-scope="{row}">
                ￥{{ row.money }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="{row}">
                <span v-if="row.status===0" class="text-info">处理中</span>
                <span v-if="row.status===1" class="text-success">已处理</span>
                <span v-if="row.status===2" class="text-danger" @click="showMsg(row)">已驳回</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAppInfo, setWithdraw } from '@/api/store/management'
import { getSendCode, getUserInfo } from '@/api/store/user'
import { getWithdraw } from '@/api/store/management.js'
export default {
  data() {
    return {
      showModal: false,
      money: '', // 提现金额
      ruleInfo: {
        min: 1,
        max: 5000,
        service_fee: '1'
      }, // 提现规则
      codeText: '获取验证码',
      code: '',	// 验证码
      formTitle: '',
      withdrawType: 'wechat',
      showBindForm: false,
      selectedWithdrawType: '',
      withdrawInfo: {}, // 提现信息
      showWithdrawList: false,
      head_bg: require('@/assets/images/draw_money_head_bg.png'),
      withdrawList: {
        // bank: {
        // 	icon: this.IMG_URL + '/imgs/wallet/bank_type.png',
        // 	title: '银行卡转账',
        // 	value: 'bank'
        // },
        wechat: {
          icon: require('@/assets/images/wx_type.png'),
          title: '微信零钱',
          value: 'wechat'
        }
        // alipay: {
        // 	icon: this.IMG_URL + '/imgs/wallet/ali_type.png',
        // 	title: '支付宝账户',
        // 	value: 'alipay'
        // }
      },
      appinfo: {},	// 品牌信息
      // 表单样式
      errorType: ['message'],
      labelStyle: {
        'font-size': '30rpx',
        'font-weight': '500',
        color: '#333'
      },
      placeholderStyle: 'font-size: 30rpx; font-weight: 500;color:#C2C7CF;',
      userinfo: {},
      smsTimes: 60,
      sendCoding: false,
      isLoading: false,
      logList: {
        list: [],
        count: 0
      }
    }
  },
  mounted() {
    this.appInfo()
    this.getUserInfo()
    this.getLog()
  },
  methods: {
    // 获取品牌信息查看可提现金额
    appInfo() {
      getAppInfo().then(res => {
        this.appinfo = res.data
      })
    },
    // 获取提现记录
    getLog() {
      getWithdraw({ limit: 999 }).then(res => {
        this.logList = res.data
      })
    },
    // 获取用户详情
    getUserInfo() {
      getUserInfo().then(res => {
        this.userinfo = res.data
      })
    },
    showMsg(val) {
      this.$alert('驳回理由：' + val.content, '', {
        confirmButtonText: '知道了',
        roundButton: true,
        showClose: false,
        center: true
      })
    },
    // 微信公众号网页静默登录:获取OpenI绑定用户
    wxOfficialAccountBaseLogin() {
      const payloadObject = {
        host: window.location.href,
        token: localStorage.getItem('Admin-Token') ? localStorage.getItem('Admin-Token') : ''
      }
      const payload = encodeURIComponent(JSON.stringify(payloadObject))
      let redirect_uri = ''
      redirect_uri = encodeURIComponent(`${process.env.VUE_APP_BASE_API}/store/v1/oauth_callback?payload=${payload}`)
      const oauthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcd6d86f3edbb3e87&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=1`
      window.location = oauthUrl
      return false
    },

    // 验证码
    codeChange(text) {
      this.codeText = text
    },
    // 获取验证码
    getCode() {
      // 模拟向后端请求验证码
      if (!this.sendCoding) {
        this.$message('正在获取验证码')
        this.isLoading = true
        getSendCode().then(res => {
          this.isLoading = false
          this.$message({
            type: 'success',
            message: '验证码已发送，请注意查收短信'
          })
          this.sendCoding = true
          // 开始计时
          var stime = this.smsTimes
          var dtime = setInterval(() => {
            stime--
            if (stime === 0) {
              this.sendCoding = false
              clearInterval(dtime)
              this.codeText = '获取验证码'
            } else {
              this.codeText = stime + ' 秒后重试'
            }
          }, 1000)
        }).catch(e => {
          this.isLoading = false
        })
      } else {
        // this.$message('稍候再试')
        return false
      }
    },
    // 提现
    withdraw() {
      if (!this.withdrawType) {
        this.$message('请选择提现方式')
        return
      }
      if (this.userinfo.storeInfo.wechat_open_id === '') {
        this.$message('请输入先绑定微信')
        return
      }
      if (this.money <= 0) {
        this.$message('请输入提现金额')
        return
      }
      if (this.money < this.ruleInfo.min) {
        this.$message('单次最小提现金额：' + this.ruleInfo.min + '元')
        return
      }
      if (this.money > this.ruleInfo.max) {
        this.$message('单次最大提现金额：' + this.ruleInfo.max + '元')
        return
      }
      if (!this.code) {
        this.$message('请输入验证码')
        return
      }
      // loading
      const data = {
        openid: this.userinfo.storeInfo.wechat_open_id,
        verify_code: this.code,
        money: this.money
      }
      this.isLoading = true
      setWithdraw(data).then(res => {
        this.isLoading = false
        this.$message({
          type: 'success',
          message: res.msg
        })
        this.appInfo()
        this.getLog()
      }).catch(e => {
        this.isLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
body{
  padding:0;
  margin:0;
}

.content{
  background: #eee;
  min-height: 100vh;
  width: calc(100% + 20px);
  margin:-10px;
}
.head-box {
	// background: url('https://file.shopro.top/imgs/user/draw_money_head_bg.png') no-repeat bottom;
	background-size: 100% auto;
	min-height: 130px;
  color:#ffffff;
  .text{
    padding: 10px 10px 0 10px;
    font-size: 13px;
  }
  .money-text{
    font-size: 24px;
  }
}
.body{
  margin:-30px 20px 20px 20px;
  .label{
    color:#2f3542;
    margin-bottom: 10px;

  }
  .name{
    white-space:nowrap;
    color:#2f3542;
  }
  .code{
    width: 120px;
  }
  .draw-list {
		font-size: 12px;
		font-weight: 400;
		color: #999999;
		margin-bottom: 5px;
	}
  .btn-submit{
    text-align: center;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, #68B1C0, #348393);
    box-shadow: 0 3px 5px 1px rgba(134,182,196, 0.34);
    border-radius: 20px;
    font-weight: 500;
    color: #ffffff;
  }
  .input{
    border:0;
  }
  input::-webkit-input-placeholder {
      padding-left:2px;
      opacity:0.5;
      font-size: 12px;
  }
  input:focus {
    outline:none;
    border: none;
  }
  .input-box {
		border-bottom: 1px solid #eee;
		height: 50px;
		margin-bottom: 10px;
		.unit {
			font-size: 24px;
			color: #333;
		}
	}
  .bank-list{
    .item-img{
      width:12px;
      height:12px;
    }
  }
  .u-reset-button{
    padding: 0 10px;
    min-width: 100px;
    line-height: 22px;
    background: #f5f6f8;
    border-radius: 10px;
    color: #999;
    border:none;
    text-align: center;
    font-size:12px;
  }
  .bank-info{
    color: #999999;
  }
}
.box{
  background: #ffffff;
  border-radius: 8px;
  padding:20px;
  box-shadow: 0 3px 5px 1px rgba(189,195,199, 0.34);
}
</style>
