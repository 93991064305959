<template>
  <div class="app-container">
    <el-page-header content="资金日志" class="page-header" @back="returnPage()" />
    <!-- 搜索 -->
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="80px" label-position="left">
        <!-- 日期 -->
        <el-form-item label="日期">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            align="right"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <!-- 账单类型 -->
        <el-form-item label="账单类型">
          <el-select v-model="listQueryParams.operation_type" placeholder="请选账单类型" clearable>
            <el-option label="商城售卡" :value="10" />
            <el-option label="会员报班" :value="20" />
            <!-- <el-option label="充值订单" :value="30" /> -->
            <el-option label="付费约课" :value="40" />
            <el-option label="提现扣款" :value="99" />
            <el-option label="提现驳回" :value="98" />
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </div>
    </div>
    <div class="total m-y-20 font-16">
      余额总数￥
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="parseFloat(walletInfo.balance)" :duration="2000" :decimals="2" />
      </span>
      昨日收入￥
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="parseFloat(walletInfo.yesterday_money)" :duration="2000" :decimals="2" />
      </span>
    </div>
    <div class="total m-y-20 font-16">
      {{ dataTime.length === 0 ? '全部的总' : `${dataTime[0]} 至 ${dataTime[1]}` }}
      收入￥
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="parseFloat(tableData.income)" :duration="2000" :decimals="2" />
      </span>
      支出￥
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="parseFloat(tableData.expend)" :duration="2000" :decimals="2" />
      </span>
    </div>
    <!-- <div class="m-y-20 flex row-between">
      <div class="s_card p-20">
        <div class="font-xxs">余额总数￥</div>
        <div style="height: 15px;" class="m-y-4 text-info" />
        <div class="text-success p-x-2 font-xl flex row-center" style="line-height: 90px;">
          <count-to :start-val="0" :end-val="walletInfo.balance" :duration="2000" :decimals="2" />
        </div>
      </div>
      <div class="s_card p-20">
        <div class="font-xxs">昨日收入￥</div>
        <div style="height: 15px;" class="m-y-4 text-info" />
        <div class="text-success p-x-2 font-xl flex row-center" style="line-height: 90px;">
          <count-to :start-val="0" :end-val="walletInfo.yesterday_money" :duration="2000" :decimals="2" />
        </div>
      </div>
      <div class="s_card p-20">
        <div class="font-xxs">收入￥</div>
        <div style="height: 15px;" class="m-y-4 text-info">{{ dataTime.length === 0 ? '' : `日期：${dataTime[0]} 至 ${dataTime[1]}` }}</div>
        <div class="text-success p-x-2 font-xl flex row-center" style="line-height: 90px;">
          <count-to :start-val="0" :end-val="tableData.income" :duration="2000" :decimals="2" />
        </div>
      </div>
      <div class="s_card p-20">
        <div class="font-xxs">收入￥</div>
        <div style="height: 15px;" class="m-y-4 text-info">{{ dataTime.length === 0 ? '' : `日期：${dataTime[0]} 至 ${dataTime[1]}` }}</div>
        <div class="text-success p-x-2 font-xl flex row-center" style="line-height: 90px;">
          <count-to :start-val="0" :end-val="tableData.expend" :duration="2000" :decimals="2" />
        </div>
      </div>
    </div> -->
    <!-- 表格 -->
    <el-table v-loading="tableLoading" :data="tableData.list" style="width: 100%" :stripe="true">
      <el-table-column prop="operation_type_text" label="名称">
        <template slot-scope="{ row }">
          <div class="flex">
            <img
              v-if="row.operation_type === 10"
              style="width: 22px;"
              src="@/assets/images/walletIcon/ico_nov_mall.png"
              alt=""
            >
            <img
              v-else-if="row.operation_type === 40"
              style="width: 22px;"
              src="@/assets/images/walletIcon/ico_book.png"
              alt=""
            >
            <img
              v-else-if="row.operation_type === 20"
              style="width: 22px;"
              src="@/assets/images/walletIcon/Frame 4839.png"
              alt=""
            >
            <img
              v-else-if="row.operation_type === 99"
              style="width: 22px;"
              src="@/assets/images/walletIcon/Frame2.png"
              alt=""
            >
            <img
              v-else-if="row.operation_type === 98"
              style="width: 22px;"
              src="@/assets/images/walletIcon/Frame.png"
              alt=""
            >
            <span class="m-l-10">{{ row.operation_type_text }}</span>
            <div v-if="row.operation_type === 99" class="m-l-20">
              <el-tag v-if="row.order && row.order.length != 0" :type="row.order.status == 0 ? 'warning' : row.order.status == 2 ? 'danger' : 'success'">{{ row.order.status == 0 ? '处理中' : row.order.status == 2 ? '已驳回' : '已通过' }}</el-tag>
              <el-tag v-else type="success">已通过</el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="收入/支出（元）">
        <template slot-scope="{ row }">
          <el-tag :type="row.operation_type === 99 ? 'danger' : 'success'">
            {{ row.operation_type === 99 ? '-' : '+' }} {{ row.money }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="money_before" label="变动前（元）" />
      <el-table-column prop="money_after" label="变动后（元）" />
      <el-table-column prop="create_time" label="时间" />
      <el-table-column label="操作">
        <template slot-scope="{row}">
          <el-button type="primary" size="small" icon="el-icon-edit" @click="handelDetails(row.id)">详 情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <Dialog :id="id" :visible="dialogVisible" @Close="handleClose" />
  </div>
</template>

<script>
// API
import { getFundLog, getWallet } from '@/api/store/takeleave'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import CountTo from 'vue-count-to'
import Dialog from './components/Dialog'

export default {
  components: { Pagination, CountTo, Dialog },
  data() {
    return {
      tableData: {
        count: 0,
        expend: 0,
        income: 0,
        list: []
      }, // 品牌资金日志
      walletInfo: {}, // 钱包信息
      tableLoading: false, // 表格加载
      searchLoading: false, // 搜索按钮加载
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }, // 时间快捷选择
      dataTime: [],
      dialogVisible: false,
      id: 0
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    setQuery() {
      var querys = []
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.operation_type) {
        querys.push({ field: 'operation_type', key: this.listQueryParams.operation_type })
      }
      return this.utils.getQueryParams(querys)
    },
    async getList() {
      this.tableLoading = true
      // 钱包信息
      const getWalletData = await getWallet()
      this.walletInfo = getWalletData.data
      // 品牌资金日志
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()

      const getFundLogData = await getFundLog(data)
      this.tableData = getFundLogData.data
      this.tableLoading = false
      this.searchLoading = false
    },
    handleFilter() {
      // this.dataTime = this.listQueryParams.create_time
      if (this.listQueryParams.create_time) this.dataTime = this.listQueryParams.create_time
      else this.dataTime = []
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handelDetails(id) {
      this.id = id
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.s_card {
  width: 370px;
  height: 160px;
  background-color: #FFF;
  border: 1px solid #FFF;
  border-radius: 10px;
  /* 周围阴影 */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
</style>
