<template>
  <div class="box p-20">
    <div class="rela">
      <el-menu :default-active="key" class="el-menu-remind" mode="horizontal" :router="true">
        <!-- xxx 课程取消 -->

        <!-- 会员卡有效期 -->
        <el-menu-item v-show="btn_access('s_user_card_validity')" index="/store/remind/userCardValidity">{{ $t('route.s_user_card_validity') }}</el-menu-item>
        <!-- 会员生日 -->
        <el-menu-item v-show="btn_access('s_user_birthday')" index="/store/remind/userBirthday">{{ $t('route.s_user_birthday') }}</el-menu-item>
        <!-- 会员卡余额 -->
        <el-menu-item v-show="btn_access('s_user_card_balance')" index="/store/remind/userCardBalance">{{ $t('route.s_user_card_balance') }}</el-menu-item>
        <!-- 多天未上课 -->
        <el-menu-item v-show="btn_access('s_user_notclass')" index="/store/remind/userNotClass">{{ $t('route.s_user_notclass') }}</el-menu-item>
        <!-- 入会纪念日 -->
        <el-menu-item v-show="btn_access('s_user_souvenir')" index="/store/remind/userSouvenir">{{ $t('route.s_user_souvenir') }}</el-menu-item>
        <!-- 开卡 -->
        <el-menu-item v-show="btn_access('s_user_card_leave_expire')" index="/store/remind/userCardOpen">{{ $t('route.s_user_card_open') }}</el-menu-item>
        <!-- 请假到期 -->
        <el-menu-item v-show="btn_access('s_user_card_open')" index="/store/remind/userCardLeaveExpire">{{ $t('route.s_user_card_leave_expire') }}</el-menu-item>
        <!-- <el-menu-item index="/store/remind/orderRule/orderRuleForLeague">{{ $t('route.s_orderRuleForLeague') }}</el-menu-item>
        <el-menu-item index="/store/remind/orderRule/orderRuleForPrivate">{{ $t('route.s_orderRuleForPrivate') }}</el-menu-item> -->
      </el-menu>
      <div class="p-y-10">
        <router-view :key="key" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    key() {
      console.log('🚀 ~ file: index.vue ~ line 26 ~ key ~ this.$route.path', this.$route.path)
      return this.$route.path
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.box{
    background-color: #ffffff;
    ::v-deep .title{
    border-bottom:1px solid #dfe6ec;
    color: #303133;
    font-weight: 600;
    }
}
</style>
