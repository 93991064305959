<template>
  <div class="">
    <div class="text-right">
      <el-button
        v-show="btn_access('s_orderRuleForCommonSetting')"
        type="primary"
        icon="el-icon-edit"
        @click="$router.push({ name: 's_orderRuleForCommonSetting', params: { type: 'orderRuleForCommon' } })"
      >
        编辑设置
      </el-button>
    </div>
    <div v-for="(item, index) in infoNode" :key="index" class="m-y-10">
      <div v-if="item.type == 'alert'">
        <el-alert :title="item.text" :closable="false" show-icon type="warning" />
      </div>
      <div v-if="item.type == 'table'">
        <div class="p-y-8 p-x-10 title">
          {{ item.title }}
        </div>
        <el-table :show-header="false" :data="item.data" stripe style="width: 100%">
          <el-table-column prop="title" label="标题" width="180" />
          <el-table-column prop="value" label="值" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getSubscribeSetting } from '@/api/store/subscribe.js'
export default {
  data() {
    return {
      a: 1,
      infoNode: '',
      form: {},
      reslist: {
        general_break_promise: {
          status: 0,
          type: 1,
          number_1: 3,
          number_2: 3,
          day_1: 3,
          day_2: 3
        },
        general_other: {
          day: 1
        }
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      getSubscribeSetting().then(res => {
        this.reslist = res.data.res

        this.infoNode = [
          {
            type: 'alert',
            text: '通用设置适用于所有课程'
          },
          {
            type: 'table',
            title: '失约惩罚设置',
            data: [
              {
                title: '期限卡爽约惩罚',
                value: this.getValue(function(that) {
                  return that.reslist.general_break_promise.status ? '是' : '否'
                })
              },
              {
                title: '惩罚方式',
                value: this.getValue(function(that) {
                  return that.reslist.general_break_promise.type === 1 ? '限制自主预约' : '扣减使用卡期限'
                })
              },
              {
                title: '惩罚细则',
                value: this.getValue(function(that) {
                  switch (that.reslist.general_break_promise.type) {
                    case 1:
                      return `一个自然月内，每存在 ${
                        that.reslist.general_break_promise['number_' + that.reslist.general_break_promise.type]
                      } 次预约未签到，${that.reslist.general_break_promise['day_' + that.reslist.general_break_promise.type]} 天内不能自主预约`
                    case 2:
                      return `一个自然月内，每存在 ${
                        that.reslist.general_break_promise['number_' + that.reslist.general_break_promise.type]
                      } 次预约未签到，扣减使用卡期限 ${that.reslist.general_break_promise['day_' + that.reslist.general_break_promise.type]} 天`
                  }
                  return ''
                })
              }
            ]
          },
          {
            type: 'table',
            title: '其他设置',
            data: [
              {
                title: '可预约天数',
                value: this.getValue(function(that) {
                  if (that.reslist.general_other.day === 0) return `会员最多可预约当天的课程`
                  return `会员最多可预约 ${that.reslist.general_other.day} 天的课程，0 表示当天`
                })
              },
              {
                title: '上课短信发送时间',
                value: this.getValue(function(that) {
                  return `上课短信提前 ${that.reslist.general_other.notice_time} 分钟发送`
                })
              }
            ]
          }
        ]
      })
    },
    getValue(a) {
      return a(this)
    }
  }
}
</script>
