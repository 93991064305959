<template>
  <!-- 班课课程表 -->
  <div v-loading="downLoading" class="app-container">
    <!-- 日期头部功能 -->
    <div class="filter-container time">
      <img src="@/assets/images/back.png" alt="back" title="上一页" @click="backNext(true)">
      <span v-if="weekList[1]">{{ weekList[1].date }}&nbsp;&nbsp;至&nbsp;&nbsp;{{ weekList[7].date }}</span>
      <img src="@/assets/images/next.png" alt="next" title="下一页" @click="backNext(false)">
      <el-button slot="reference" type="success" icon="el-icon-document-copy" class="m-l-5" @click="add_edit(1)">
        新增排课
      </el-button>
      <el-popconfirm title="确定清空本周课程表吗？" @confirm="clear_curriculum">
        <el-button
          v-show="btn_access('s_course_curriculum_clear')"
          slot="reference"
          type="danger"
          icon="el-icon-delete"
          class="m-l-5"
        >
          清空本周课程表
        </el-button>
      </el-popconfirm>
    </div>
    <!-- 日期表 -->
    <div id="courseView">
      <!-- <div class="bg-primary text-center m-x-2">
        <div style="color:#ffffff;" class="font-lg p-y-10">{{ viewInput.title }}</div>
      </div> -->
      <table v-loading="loading" class="table">
        <thead>
          <tr>
            <th>时间</th>
            <th
              v-for="(item, index) in weekList"
              :key="index"
              class="week_info"
              :class="{ disable: checkTime(item.date) }"
            >
              <div>{{ item.week }}</div>
              <div>{{ item.date }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableList" :key="index">
            <td>{{ index }}</td>
            <td v-for="(item1, index1) in item" :key="index1">
              <div
                v-for="(item2, index2) in item1"
                :key="index2"
                class="course"
                :class="[index2 > 0 ? 'special' : '', chooseStyle ? 'color' : '']"
                :style="getBg(item2)"
                @click="add_edit(item2, index)"
              >
                <div v-if="item2.start_time">{{ item2.start_time.substr(-8) }}~{{ item2.end_time.substr(-8) }}</div>
                <div :class="{ 'text-danger': !item2.course }">
                  {{ item2.course ? item2.course.name : '关联课程已被删除' }}
                </div>
                <div v-if="item2.store_user">{{ item2.store_user.real_name }}</div>
                <div>{{ item2.classroom ? item2.classroom.name : '未知教室' }}</div>
                <div v-if="item2.difficulty">
                  <el-rate v-model="item2.difficulty" disabled-void-color="#e9e9e9" disabled />
                </div>
                <div v-if="item2.course && item2.course.type === 2" class="fine_class">精</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 弹出的对话框 -->
    <el-dialog
      append-to-body
      :visible.sync="dialog.visible"
      :show-close="is_edit"
      :close-on-click-modal="is_edit"
      :close-on-press-escape="is_edit"
      :title="chooseDate.title"
      @close="handleClose"
    >
      <el-form
        ref="ruleForm"
        v-loading="chooseDate.loading"
        :rules="rules"
        :model="form"
        label-position="right"
        label-width="120px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item class="line-clear" label="课程类型">
              <el-input placeholder="课程" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班级名称" prop="mclass_grade_id">
              <el-select v-model="form.mclass_grade_id" placeholder="请选择班级名称" prop="mclass_grade_id">
                <el-option v-for="item in classTitle" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="课程名称" prop="course_id">
              <el-select v-model="form.course_id" placeholder="请选择课程名称">
                <el-option
                  v-for="item in courseTitle"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="courseChoose(item)"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item class="line-clear" label="教练" prop="store_user_id">
              <el-select v-model="form.store_user_id" placeholder="请选择教练">
                <el-option v-for="item in store_user" :key="item.id" :label="item.real_name" :value="item.id" />
              </el-select>
            </el-form-item> -->
            <coach :id="form.store_user_id" :display="true" :timer="timer" :type="coachType" @storeUserId="storeUserId" />
          </el-col>
          <el-col :span="12">
            <el-form-item class="line-clear" label="课程时长" prop="duration">
              <el-input v-model="form.duration" type="number" placeholder="输入课程时长">
                <template slot="append">分钟</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="line-clear" label="上课教室" prop="classroom_id">
              <el-select v-model="form.classroom_id" placeholder="请选择教室">
                <el-option v-for="item in classroom" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="dialog.id === 1" :span="12">
            <el-form-item label="排课模式">
              <el-radio-group v-model="is_iok">
                <el-radio :label="1">单节</el-radio>
                <el-radio :label="2">批量</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始时间">
              <div class="flex">
                <div>
                  <el-select v-model="stime_h" filterable placeholder="请选择" style="width:100px;">
                    <el-option
                      v-for="(item, index) in 24"
                      :key="index"
                      :label="(index < 10 ? '0' + index : index).toString()"
                      :value="(index < 10 ? '0' + index : index).toString()"
                    />
                  </el-select>
                </div>
                <div class="m-x-5">:</div>
                <div>
                  <el-select v-model="stime_m" filterable placeholder="请选择" style="width:100px;">
                    <el-option
                      v-for="(item, index) in 60"
                      :key="index"
                      :label="(index < 10 ? '0' + index : index).toString()"
                      :value="(index < 10 ? '0' + index : index).toString()"
                    />
                  </el-select>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col v-if="dialog.id === 1" :span="12">
            <el-form-item v-if="is_iok === 1" label="课程时间">
              <el-date-picker
                v-model="time"
                :picker-options="pickerOptions"
                type="date"
                align="right"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="选择日期时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item v-if="is_iok === 2" label="课程时间">
              <el-date-picker
                v-model="time"
                :picker-options="dataProhibitSelection"
                type="daterange"
                align="right"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="is_iok === 2" label="课程日期" prop="time_week">
              <el-switch
                v-model="is_coclk"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="全选"
                inactive-text="取消全选"
                @change="is_switch"
              />
              <el-checkbox-group v-model="form.time_week" @change="is_checkbox">
                <el-checkbox :label="1" class="m-b-10">周一</el-checkbox>
                <el-checkbox :label="2" class="m-b-10">周二</el-checkbox>
                <el-checkbox :label="3" class="m-b-10">周三</el-checkbox>
                <el-checkbox :label="4" class="m-b-10">周四</el-checkbox>
                <el-checkbox :label="5" class="m-b-10">周五</el-checkbox>
                <el-checkbox :label="6" class="m-b-10">周六</el-checkbox>
                <el-checkbox :label="7" class="m-b-10">周日</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="课程颜色" style="height: 44px;" prop="color">
              <el-color-picker v-model="form.color" :predefine="courseDefaultColorList" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 按钮 -->
      <div class="footer text-center">
        <el-button type="primary" :disabled="!!form.auto_cancel || form.disable_edit" :loading="submitLoading" @click="handleYes('ruleForm')">
          确 认
        </el-button>
        <el-button @click="handleNo('ruleForm')">关 闭</el-button>
        <el-button v-if="dialog.id !== 1" :disabled="form.disable_edit" type="danger" @click="handleDelete">
          删除
        </el-button>
        <!-- <el-button
          :disabled="form.disable_edit"
          type="warning"
        >
          课程取消
        </el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
// API
import {
  getSchedule,
  getDataList,
  getCourseList,
  postCalssAdd,
  postClearCourse,
  deleteClearCourse,
  getClearCourse,
  putClearCourse
} from '@/api/store/course.js'
import { getClassroom, getCoach } from '@/api/store/venues.js'
import coach from '@/components/elSelect/coach.vue'
import { deepClone } from '@/utils'

export default {
  components: { coach },
  data() {
    return {
      submitLoading: false,
      is_coclk: false,
      // 班级名称
      classTitle: [],
      // 课程名称
      courseTitle: [],
      // 教练名称
      store_user: [],
      // 教室名称
      chooseStyle: true,
      classroom: [],
      is_edit: false,
      currentWeek: 0,
      tableList: {},
      weekList: {},
      downLoading: false,
      loading: false,
      dialog: {
        visible: false,
        id: 0
      },
      chooseDate: {
        loading: false,
        title: '提示'
      },
      form: {
        mclass_grade_id: '',
        course_id: '',
        store_user_id: '',
        duration: '',
        classroom_id: '',
        time_week: '',
        time_hm: '',
        bat_start_time: '',
        bat_end_time: '',
        color: '#00C9C9'
      },
      time: '',
      is_iok: 1,
      stime_h: this.$moment()
        .format('HH')
        .toString(),
      stime_m: this.$moment()
        .format('mm')
        .toString(),
      rules: {
        mclass_grade_id: [{ required: true, message: '请输入班级名称', trigger: ['blur', 'change'] }],
        course_id: [{ required: true, message: '请输入课程名称', trigger: ['blur', 'change'] }],
        store_user_id: [{ required: true, message: '请选择教练', trigger: ['blur', 'change'] }],
        duration: [{ required: true, message: '请输入课程时长', trigger: ['blur', 'change'] }],
        classroom_id: [{ required: true, message: '请选择上课教室', trigger: ['blur', 'change'] }],
        time_week: [{ required: true, message: '请选择课程日期', trigger: ['blur', 'change'] }],
        color: [{ required: true, message: '请选择课程颜色', trigger: ['blur', 'change'] }]
      },
      coachType: 3,
      timer: ''
    }
  },
  watch: {
    is_iok(val) {
      if (val === 1) {
        this.time = ''
        this.form.bat_start_time = ''
        this.form.bat_end_time = ''
        this.form.time_week = ''
        this.is_coclk = false
      } else {
        this.time = []
        this.form.bat_start_time = ''
        this.form.bat_end_time = ''
        this.form.time_week = []
      }
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList() {
      this.downLoading = true
      getSchedule({ currentWeek: this.currentWeek })
        .then(res => {
          this.weekList = res.data.week
          this.tableList = res.data.list
          this.loading = false
          this.downLoading = false
        })
        .catch(() => {})
      // 获取班级名称
      getDataList().then(res => {
        this.classTitle = res.data.list
      })
      // 获取课程名称
      getCourseList().then(res => {
        this.courseTitle = res.data.list
      })
      // 获取教练列表
      getCoach({ limit: 999, type: this.form.type }).then(res => {
        this.store_user = res.data.list
      })
      getClassroom().then(res => {
        this.classroom = res.data.list
      })
    },
    getBg(item2) {
      if (this.chooseStyle) {
        let color = !item2.course ? '#c0c4cc' : this.chooseStyle ? item2.color : ''
        if (item2.auto_cancel) color = '#82848a'
        return { background: color }
      }
    },
    // 上一页下一页
    backNext(type) {
      this.loading = true
      if (type) this.currentWeek--
      else this.currentWeek++
      this.getList()
    },
    checkTime(time) {
      return this.getExcessTime(time) < 0
    },
    // 添加\编辑课程
    add_edit(item, index) {
      this.dialog.visible = true
      console.log(index)
      if (item === 1) {
        this.chooseDate.title = '新增排课'
        this.dialog.id = 1
      } else {
        this.chooseDate.title = '课程信息'
        this.dialog.id = item.id
        this.chooseDate.loading = true
        getClearCourse(item.id).then(res => {
          console.log(res.data)
          this.form.mclass_grade_id = res.data.mclass_grade_id
          this.form.course_id = res.data.course_id
          this.form.store_user_id = res.data.store_user_id
          this.form.duration = res.data.duration
          this.form.classroom_id = res.data.classroom_id
          this.stime_h = index.slice(0, 2)
          this.stime_m = index.slice(3, 5)
          this.form.color = res.data.color
          this.form.bat_start_time = res.data.create_time
          this.form.bat_end_time = res.data.end_time
          this.form.start_time = res.data.start_time.slice(0, 10)
          this.chooseDate.loading = false
          this.timer = new Date().getTime()
        })
      }
    },
    handleYes(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.time) {
            if (!this.stime_h) {
              this.$message.error('开始时间不能为空')
              return false
            }
            if (this.is_iok === 1) {
              this.form.bat_start_time = this.time
              this.form.bat_end_time = this.time
            } else {
              this.form.bat_start_time = this.time[0]
              this.form.bat_end_time = this.time[1]
              if (!this.form.bat_start_time && !this.form.bat_end_time) {
                this.$message.error('课程时间不能为空')
                return false
              }
            }
          }
          this.form.time_hm = this.stime_h + ':' + this.stime_m
          const data = deepClone(this.form)
          if (data.time_week.length > 0) {
            data.time_week = data.time_week.join(',')
          }
          this.submitLoading = true
          if (this.dialog.id === 1) {
            postCalssAdd(data)
              .then(res => {
                this.dialog.visible = false
                this.getList()
                this.$refs[formName].resetFields()
                this.time = ''
                this.is_iok = 1
                this.$message({
                  type: 'success',
                  message: '新增成功!'
                })
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            putClearCourse(this.dialog.id, this.form)
              .then(() => {
                this.dialog.visible = false
                this.getList()
                this.$refs[formName].resetFields()
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                })
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    handleNo(formName) {
      this.dialog.visible = false
      this.$refs[formName].resetFields()
      if (this.time) this.time = ''
      if (this.is_iok) this.is_iok = 1
      this.is_coclk = false
    },
    // 删除班课课程表
    handleDelete() {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteClearCourse(this.dialog.id).then(res => {
            this.dialog.visible = false
            this.getList()
            this.$message.success('删除成功')
          })
        })
        .catch(() => {})
    },
    // 清空课程表
    clear_curriculum() {
      this.loading = true
      postClearCourse(this.weekList[1].date)
        .then(res => {
          this.getList()
          this.$message.success('清空成功')
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 课程选择
    courseChoose(item) {
      console.log(item)
      // 教练
      this.$set(this.form, 'store_user_id', item.store_user_id)
      // 课程时长
      this.$set(this.form, 'duration', item.duration)
      // 教室
      this.$set(this.form, 'classroom_id', item.classroom_id)
    },
    is_switch() {
      if (this.is_coclk) this.form.time_week = [1, 2, 3, 4, 5, 6, 7]
      if (!this.is_coclk) this.form.time_week = []
    },
    is_checkbox() {
      console.log(this.form.time_week)
      if (this.form.time_week.length === 7) this.is_coclk = true
      else this.is_coclk = false
    },
    storeUserId(val) {
      this.form.store_user_id = val
    },
    handleClose() {
      console.log(1111111111)
      this.form.store_user_id = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.time {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  justify-content: center;

  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
.table {
  table-layout: fixed;
  width: 100%;

  thead {
    background-color: #67a1ff;

    th {
      color: #fff;
      line-height: 17px;
      font-weight: normal;
    }

    .week_info {
      position: relative;
      &.disable {
        filter: grayscale(100%);
        img {
          cursor: unset;
        }
      }
      img {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
    }
  }

  tbody {
    background-color: #eaf2ff;

    .course {
      position: relative;
      cursor: pointer;
      padding: 7px 0;

      > div {
        padding-top: 7px;
      }
      ::v-deep .el-rate__icon {
        margin-right: 0;
      }
      .fine_class {
        font-size: 12px;
        padding: 0;
        text-align: center;
        width: 20px;
        height: 20px;
        background: rgb(0 0 0 / 10%);
        right: 0;
        bottom: 0;
        line-height: 20px;
        position: absolute;
        border-radius: 4px 0px 0px 0px;
      }
    }
    .course.color {
      color: #fff !important;
    }
    .special {
      position: relative;
      padding-top: 6px;

      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
        top: 0;
      }
    }

    td {
      color: #677998;
      line-height: 12px;
    }
  }

  th {
    width: 60px;
    padding: 12px 2px;
    font-size: 12px;
    text-align: center;
  }

  td {
    text-align: center;
    vertical-align: sub;
  }

  tr td:first-child {
    color: #333;
    vertical-align: middle;

    .period {
      font-size: 8px;
    }
  }
}
</style>
