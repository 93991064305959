<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <!-- XXX 暂无搜索字段，废弃 -->
          <!-- <el-form-item label="操作类型">
            <el-select v-model="listQueryParams.method" placeholder="请选择" filterable clearable>
              <el-option key="1" label="添加" value="POST" />
              <el-option key="2" label="修改" value="PUT" />
              <el-option key="3" label="删除" value="DELETE" />
              <el-option key="4" label="查看" value="GET" />
            </el-select>
          </el-form-item> -->
          <el-form-item label="操作员工">
            <Select :type="4" @changeId="handleCoachID" />
          </el-form-item>
          <el-form-item v-if="utils.getVenues().id == -1" label="操作场馆">
            <el-select v-model="listQueryParams.venues_id" placeholder="请选择" filterable clearable>
              <el-option key="ppgl" label="品牌管理" :value="-1" />
              <el-option v-for="(item, index) in venues_list" :key="index" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="操作时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索日志详情 | 标题" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button
        v-show="btn_access('s_user_export')"
        :disabled="tableData.list.length <= 0"
        :loading="downloadLoading"
        class="m-l-10 filter-item"
        type="danger"
        icon="el-icon-download"
        @click="handleDownload"
      >
        导出
      </el-button>
    </div>
    <el-table ref="privateMultipleTable" v-loading="isLoading" :data="tableData.list" row-key="id">
      <el-table-column prop="title" label="标题" width="200px" />
      <el-table-column prop="access.title" label="模块" width="200px">
        <template slot-scope="{ row }">
          <div>
            {{ row.access ? row.access.title : '--' }}(
            <span v-if="row.access && row.access.method == 'GET'" class="text-primary">查看</span>
            <span v-if="row.access && row.access.method == 'POST'" class="text-success">添加</span>
            <span v-if="row.access && row.access.method == 'DELETE'" class="text-danger">删除</span>
            <span v-if="row.access && row.access.method == 'PUT'" class="text-warning">更新</span>
            )
          </div>
          <div class="text-info">{{ row.create_time }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="store_user.real_name" label="操作员工" width="100px" />
      <el-table-column prop="venues.name" label="操作场馆" width="150px">
        <template slot-scope="scope">
          <span v-if="scope.row.venues">{{ scope.row.venues.name }}</span>
          <span v-else>品牌管理</span>
        </template>
      </el-table-column>

      <el-table-column prop="detail" label="详情">
        <template slot-scope="{ row: { detail } }">
          <div class="scrollBar" v-html="detail" />
        </template>
      </el-table-column>
      <el-table-column v-if="is_operate" label="操作" fixed="right" width="100px">
        <template slot-scope="scope">
          <el-button class="m-2" type="primary" icon="el-icon-view" @click="view_data(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      small
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <!-- 详情弹出层 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="详情"
      class="dialog_auto dialog_autoh"
      destroy-on-close
    >
      <div class="data_info">
        <div v-for="(item, index) in dialog.data" :key="index" class="data_item">
          <div class="flex">
            <div class="field">{{ index }}</div>
            <div
              v-if="index == 'data'"
              v-clipboard:copy="typeof item === 'object' ? JSON.stringify(item) : item"
              v-clipboard:success="clipboardSuccess"
              class="cursor"
            >
              <pre>{{ item }}</pre>
            </div>
            <div
              v-else
              v-clipboard:copy="typeof item === 'object' ? JSON.stringify(item) : item"
              v-clipboard:success="clipboardSuccess"
              class="value cursor"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import clipboard from '@/directive/clipboard/index.js'
import { get_logs } from '@/api/store/record.js'
import { getStoreUser } from '@/api/store/management.js'
import { getVenues } from '@/api/store/venues.js'
import { parseTime } from '@/utils'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import { deepClone } from '@/utils'
import Select from '@/components/elSelect/Select'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'

export default {
  directives: { clipboard },
  components: { Pagination, Select },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      isLoading: false,
      searchShow: false,
      downloadLoading: false,
      store_user_list: {},
      venues_list: {},
      dialog: {
        visible: false,
        data: []
      },
      is_operate: false,
      listQueryParams: {
        create_time: []
      }
    }
  },
  computed: {
    pickerOptions() {
      const currentDate = new Date()
      const threeMonthsAgo = new Date(currentDate)
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3)

      return {
        disabledDate(time) {
          return time < threeMonthsAgo
        }
      }
    }
  },
  created() {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    this.listQueryParams.create_time = [
      timestampToTimes(start.getTime()).slice(0, 10),
      timestampToTimes(end.getTime()).slice(0, 10)
    ]
  },
  mounted() {
    this.getList()

    // 获取员工列表
    if (this.utils.empty(this.store_user_list)) {
      getStoreUser({ limit: 999 })
        .then(res => {
          this.store_user_list = res.data.list
        })
        .catch(() => {})
    }
    // 获取场馆列表
    if (this.utils.empty(this.venues_list)) {
      getVenues({ limit: 999 })
        .then(res => {
          this.venues_list = res.data.list
          if (this.listQuery.venues_id !== -1) {
            this.listQuery.venues_id = this.utils.getVenues().id
          }
          // this.$set('listQuery', 'venues_id', this.utils.getVenues().id)
        })
        .catch(() => {})
    }
  },
  methods: {
    clipboardSuccess() {
      this.$message({
        message: '复制成功',
        type: 'success',
        duration: 1500
      })
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      this.isLoading = true

      var querys = []
      if (this.listQueryParams.query) { querys.push({ field: 'detail|url|title', type: 'like', key: this.listQueryParams.query }) }
      if (this.listQueryParams.method !== '' && this.listQueryParams.method != null) { querys.push({ field: 'method', key: this.listQueryParams.method }) }
      if (this.listQueryParams.store_user_id !== '' && this.listQueryParams.store_user_id != null) { querys.push({ field: 'store_user_id', key: this.listQueryParams.store_user_id }) }
      if (this.listQueryParams.venues_id) querys.push({ field: 'venues_id', key: this.listQueryParams.venues_id })
      if (this.listQueryParams.create_time) { querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time }) }

      data.query = this.utils.getQueryParams(querys)

      get_logs(data)
        .then(res => {
          this.tableData = res.data
          this.isLoading = false
          this.searchLoading = false
        })
        .catch(() => {})
    },
    isEmpty(v) {
      return !this.utils.empty(v)
    },
    // 搜索
    handleFilter() {
      if (this.listQueryParams.query === 'yuxiaojiu') {
        this.is_operate = true
        this.listQueryParams.query = ''
        return false
      }
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 导出
    handleDownload() {
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['模块', '操作类型', '操作员工', '操作场馆', '详情', '请求url', '操作时间']
        const filterVal = [
          'access.title',
          'access.method',
          'store_user.account',
          'venues.name',
          'detail',
          'url',
          'create_time'
        ]
        console.log(filterVal)
        const data = this.formatJson(filterVal)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: `操作日志_${this.utils.getVenues().name}_${this.$moment().format('YYYY-MM-DD')}`
        })
        this.downloadLoading = false
      })
    },
    formatJson(filterVal) {
      return this.tableData.list.map(v =>
        filterVal.map(j => {
          var c = ''
          if (j.indexOf('.') !== -1) {
            const a = j.split('.')
            c = !v[a[0]] ? '' : v[a[0]][a[1]]
          } else {
            c = v[j]
          }
          if (j === 'access.method') {
            var method = ''
            switch (c) {
              case 'GET':
                method = '查看'
                break
              case 'POST':
                method = '创建'
                break
              case 'DELETE':
                method = '删除'
                break
              case 'PUT':
                method = '修改'
                break
              default:
                break
            }
            return method
          }
          if (j === 'detail') {
            return c.replace(/<br>/g, '\n').replace(/\s+/g, '\n')
          }
          if (j === 'timestamp') {
            return parseTime(v[j])
          }
          return c
        })
      )
    },
    // 查看详情
    view_data(item) {
      const arr = deepClone(item)
      arr.data = JSON.parse(arr.data)
      this.dialog.visible = true
      this.dialog.data = arr
    },
    handleCoachID(val) {
      this.listQueryParams.store_user_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.data_info {
  .data_item {
    padding: 10px 10px;
    border-bottom: 1px solid #ebeef5;
  }
  .field {
    font-weight: 600;
    min-width: 120px;
  }
}
.scrollBar {
  max-height: 80px;
  overflow: scroll; /* 添加滚动条 */
}
</style>
