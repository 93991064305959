var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c("el-page-header", {
        staticClass: "page-header",
        attrs: { content: "会员批量导入" },
        on: {
          back: function($event) {
            return _vm.returnPage()
          }
        }
      }),
      [
        _c(
          "el-tabs",
          {
            staticStyle: { "margin-top": "20px" },
            model: {
              value: _vm.typeL,
              callback: function($$v) {
                _vm.typeL = $$v
              },
              expression: "typeL"
            }
          },
          [
            _c("el-tab-pane", { attrs: { label: "瑜小九", name: 1 } }, [
              _c("div", { staticClass: "import_box" }, [
                _c("div", { staticClass: "file_demo" }, [
                  _c(
                    "div",
                    { staticClass: "demo_list flex row-around" },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "text-center",
                          attrs: { label: 1 },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        [
                          _c("div", { staticClass: "demo_item flex" }, [
                            _c(
                              "div",
                              { staticClass: "file_icon" },
                              [
                                _c("svg-icon", {
                                  staticClass: "icon",
                                  attrs: { "icon-class": "excel" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "file_name cursor" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "下载模版文件",
                                      placement: "bottom"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.down_file(1)
                                          }
                                        }
                                      },
                                      [_vm._v("批量导入会员（有卡）模版.xls")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "el-radio",
                        {
                          staticClass: "text-center",
                          attrs: { label: 2 },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        [
                          _c("div", { staticClass: "demo_item flex" }, [
                            _c(
                              "div",
                              { staticClass: "file_icon" },
                              [
                                _c("svg-icon", {
                                  staticClass: "icon",
                                  attrs: { "icon-class": "excel" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "file_name cursor" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "下载模版文件",
                                      placement: "bottom"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.down_file(0)
                                          }
                                        }
                                      },
                                      [_vm._v("批量导入会员（无卡）模版.xls")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "file_upload text-center" },
                [
                  _c(
                    "el-upload",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.upLoading,
                          expression: "upLoading"
                        }
                      ],
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.upSuccess,
                        "on-change": _vm.upChange,
                        headers: _vm.utils.upload_headers(),
                        "file-list": _vm.fileList,
                        limit: 1,
                        name: "excel",
                        "on-exceed": _vm.handleExceed,
                        "on-progress": _vm.upProgress,
                        "auto-upload": false,
                        drag: "",
                        action: _vm.importUrl,
                        accept: ".xlsx, .xls"
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v(" 将文件拖到此处，或 "),
                        _c("em", [_vm._v("点击上传")])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v("只能上传xls/xlsx文件")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-block",
                      attrs: {
                        type: "primary",
                        round: "",
                        loading: _vm.btnLoading
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "info" }, [
                _c("div", [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" 批量导入会员（有卡）需知 ")
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("li", [
                      _vm._v("1、支持Excel（.xls/.xlsx）格式的文件；")
                    ]),
                    _c("li", [
                      _vm._v("2、多馆卡请单独录入，批量导入会导致数据错误；")
                    ]),
                    _c("li", [
                      _vm._v(
                        "3、模板中 * 为必填项，其余项可以不填；如果该会员持有卡， * 也为必填项；"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "4、支持一人多卡，详细格式参照模板；不支持导入请假状态的卡，请假状态的卡手动录入，或者恢复正常后再导入；"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "5、请确保你已经在系统里添加了正确的会籍顾问信息，系统不支持重名的员工，如果重名的员工请调整表格里名字与录入员工的姓名一致；"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "6、请确保手机号格式的正确性：13800138000；出生日期格式：1986/10/10。不正确的格式将不能成功导入；"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "7、每次最多导入500条数据，如您的会员超出500人，请分批次导入；"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "8、开卡日期可填写具体的开卡时间或 “立即开卡”、“首次预约开卡”、“首次上课开卡” ；"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "9、卡类型可选“期限卡”、“次数卡”、“储值卡”，期限卡“余额”项不填，次数卡与储值卡必填；"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "10、卡名称会匹配已有会员卡名称，如不存在将自动创建对应卡类型的会员卡名称，默认为1年有效期；"
                      )
                    ]),
                    _c("li", [
                      _vm._v("11、如数据导入有疑问，可联系专属客服进行处理；")
                    ])
                  ])
                ]),
                _c("div", [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" 批量导入会员（无卡）需知 ")
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("li", [
                      _vm._v("1、支持Excel（.xls/.xlsx）格式的文件；")
                    ]),
                    _c("li", [
                      _vm._v("2、模板中 * 为必填项，其余项可以不填；")
                    ]),
                    _c("li", [
                      _vm._v(
                        "3、请确保你已经在系统里添加了正确的会籍顾问信息，系统不支持重名的员工，如果重名的员工请调整表格里名字与录入员工的姓名一致；"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "4、请确保手机号格式的正确性：13800138000；出生日期格式：1986/10/10。不正确的格式将不能成功导入；"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "5、每次最多导入500条数据，如您的会员超出500人，请分批次导入；"
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("el-tab-pane", { attrs: { label: "其他模板", name: 2 } }, [
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.typeI,
                        callback: function($$v) {
                          _vm.typeI = $$v
                        },
                        expression: "typeI"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("随心瑜")
                      ]),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("菲特云")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "import_box",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c("div", { staticClass: "file_demo" }, [
                    _c(
                      "div",
                      { staticClass: "demo_list flex row-around" },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "text-center",
                            attrs: { label: 1 },
                            model: {
                              value: _vm.form.type,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type"
                            }
                          },
                          [
                            _c("div", { staticClass: "demo_item flex" }, [
                              _c("div", { staticClass: "file_name" }, [
                                _c("span", { attrs: { type: "primary" } }, [
                                  _vm._v("批量导入有卡会员")
                                ])
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "el-radio",
                          {
                            staticClass: "text-center",
                            attrs: { label: 2 },
                            model: {
                              value: _vm.form.type,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type"
                            }
                          },
                          [
                            _c("div", { staticClass: "demo_item flex" }, [
                              _c("div", { staticClass: "file_name" }, [
                                _c("span", { attrs: { type: "primary" } }, [
                                  _vm._v("批量导入无卡会员")
                                ])
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "file_upload text-center" },
                [
                  _c(
                    "el-upload",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.upLoading,
                          expression: "upLoading"
                        }
                      ],
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.upSuccess,
                        "on-change": _vm.upChange,
                        headers: _vm.utils.upload_headers(),
                        "file-list": _vm.fileList,
                        limit: 1,
                        name: "excel",
                        "on-exceed": _vm.handleExceed,
                        "on-progress": _vm.upProgress,
                        "auto-upload": false,
                        drag: "",
                        action: _vm.importUrl,
                        accept: ".xlsx, .xls"
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v(" 将文件拖到此处，或 "),
                        _c("em", [_vm._v("点击上传")])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v("只能上传xls/xlsx文件")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-block",
                      attrs: {
                        type: "primary",
                        round: "",
                        loading: _vm.btnLoading
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }