var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "multipleTable",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
          _c("el-table-column", {
            attrs: { prop: "user", label: "用户" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.user
                      ? _c(
                          "div",
                          { staticClass: "flex col-center" },
                          [
                            _c(
                              "el-avatar",
                              {
                                attrs: {
                                  shape: "square",
                                  size: 50,
                                  src: row.user.avatar
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.errorAvatarSrc(row.user, "img")
                                  }
                                })
                              ]
                            ),
                            _c("div", { staticClass: "m-l-10" }, [
                              _vm._v(
                                _vm._s(
                                  row.user ? _vm.getUserNames(row.user) : "--"
                                )
                              ),
                              _c("br"),
                              _vm._v(_vm._s(row.user.phone))
                            ])
                          ],
                          1
                        )
                      : _c("span", [_vm._v("--")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "type", label: "类型" } }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "反馈内容" }
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "反馈时间" }
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count>0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }