var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "toolbar" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("category_add"),
                  expression: "btn_access('category_add')"
                }
              ],
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.dialogVisible()
                }
              }
            },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticClass: "el-table--small",
          attrs: {
            data: _vm.tableData,
            "row-key": "id",
            "tree-props": { children: "children", hasChildren: "hasChildren" }
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            select: _vm.selectChange,
            "select-all": _vm.selectAllChange
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "80" }
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "分类名", "min-width": "180px" }
          }),
          _c("el-table-column", {
            attrs: { prop: "en_name", label: "标识", "min-width": "180px" }
          }),
          _c("el-table-column", {
            attrs: { prop: "image", label: "图标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.image
                      ? _c("el-image", {
                          staticStyle: { width: "30px", height: "30px" },
                          attrs: {
                            src: row.image,
                            fit: "fill",
                            "preview-src-list": [row.image]
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "weigh", label: "排序" } }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("显示")
                        ])
                      : _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("隐藏")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("category_edit"),
                            expression: "btn_access('category_edit')"
                          }
                        ],
                        attrs: { type: "primary", icon: "el-icon-edit" },
                        on: {
                          click: function($event) {
                            return _vm.dialogVisible(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("category_del"),
                            expression: "btn_access('category_del')"
                          }
                        ],
                        attrs: { title: "删除该分类，确定继续吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.category_del(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog_w750",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.cancelDialog,
            visible: _vm.dialog.visible,
            title: _vm.dialog.id === null ? "添加分类" : "编辑分类"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "父级分类", prop: "parent_id" } },
                [
                  _c("SelectTree", {
                    attrs: {
                      props: _vm.defaultProps,
                      options: _vm.tableData,
                      value: _vm.form.parent_id,
                      clearable: _vm.isClearable,
                      accordion: _vm.isAccordion,
                      "root-name": "顶级分类"
                    },
                    on: {
                      getValue: function($event) {
                        return _vm.getValue($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": ""
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类名", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "分类名" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签名", prop: "en_name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "标签名" },
                    model: {
                      value: _vm.form.en_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "en_name", $$v)
                      },
                      expression: "form.en_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类图标", prop: "image" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.adminUpload,
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "before-remove": _vm.beforeRemove,
                        "on-exceed": _vm.handleExceed,
                        "on-success": _vm.upSuccess,
                        headers: _vm.utils.upload_headers(),
                        "show-file-list": false,
                        accept: ".jpg,.jpeg,.png"
                      }
                    },
                    [
                      _c(
                        "el-avatar",
                        {
                          attrs: {
                            shape: "square",
                            size: 50,
                            src: _vm.form.image,
                            title: "点击上传"
                          }
                        },
                        [_c("img", { attrs: { src: _vm.errorImageSrc } })]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示隐藏", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("显示")
                      ]),
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("隐藏")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类排序", prop: "weigh" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 0,
                      max: 999,
                      "controls-position": "right",
                      placeholder: "分类排序"
                    },
                    model: {
                      value: _vm.form.weigh,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "weigh", _vm._n($$v))
                      },
                      expression: "form.weigh"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.cancelDialog } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }