<template>
  <div v-if="$route.name == 's_user_list'" class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="性别">
            <el-select v-model="listQueryParams.sex" placeholder="请选择" filterable clearable>
              <el-option v-for="(item, index) in sexList" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <!-- 会籍顾问 -->
          <el-form-item label="会籍顾问">
            <Select :type="4" @changeId="handleCoachID" />
          </el-form-item>
          <el-form-item label="入会时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              unlink-panels
            />
          </el-form-item>
          <!-- 生日暂时无法检索 -->
          <el-form-item label="生日">
            <!-- <el-date-picker
              v-model="listQueryParams.birthday"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="MM-dd"
              value-format="MM-dd"
            /> -->
            <DateMonthDay @changeDay="changeDay" />
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索 手机号 | 昵称" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button
        v-show="btn_access('s_user_add')"
        class="filter-item m-l-10"
        type="primary"
        icon="el-icon-plus"
        @click="add_edit"
      >
        添加
      </el-button>
      <el-button
        v-show="btn_access('s_user_export')"
        :disabled="tableData.list.length <= 0"
        type="danger"
        class="filter-item m-l-10"
        icon="el-icon-download"
        :loading="exportLoading"
        @click="Download"
      >
        导出
      </el-button>
      <!-- <el-button
        v-show="currentVenues.id !== -1 && btn_access('s_user_vipcard_import')"
        class="filter-item"
        type="warning"
        @click="$router.push({ name: 's_user_vipcard_import', params: { type: 2 } })"
      >
        批量导入
      </el-button> -->
      <!-- <el-button class="filter-item" type="success" @click="handleModifyPointsAll">批量修改</el-button> -->
      <el-dropdown class="course filter-item m-l-10" :hide-on-click="false">
        <el-button type="success">
          批量操作
          <i class="el-icon-arrow-down el-icon--right" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-show="currentVenues.id !== -1 && btn_access('s_user_vipcard_import')"
            @click.native="$router.push({ name: 's_user_vipcard_import', params: { type: 2 } })"
          >
            <template>
              <i class="el-icon-upload" />
              批量导入
            </template>
          </el-dropdown-item>
          <el-dropdown-item v-show="btn_access('s_batch_integral')" @click.native="handleModifyPointsAll">
            <template>
              <i class="el-icon-edit" />
              批量修改
            </template>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 列表 -->
    <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData.list" row-key="id" :fit="true">
      <el-table-column prop="avatar" label="头像">
        <template slot-scope="{ row }">
          <div v-if="!utils.empty(row)" class="flex col-center">
            <div>
              <el-avatar shape="square" :size="50" :src="row.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row) }}</span>
              <br>
              <span>{{ row.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="real_name" label="姓名" :formatter="getUserNames" align="center" /> -->
      <!-- <el-table-column prop="nickname" label="昵称" /> -->
      <!-- <el-table-column prop="phone" label="手机号" align="center" /> -->
      <el-table-column prop="sex" label="性别" :formatter="formatTableSex" />
      <el-table-column prop="integral" label="积分" />
      <el-table-column prop="birthday" label="生日">
        <template slot-scope="{ row }">
          {{ row.birthday ? row.birthday : '---' }}
        </template>
      </el-table-column>
      <el-table-column prop="channel" label="登入渠道">
        <template slot-scope="{ row }">
          <el-tag v-if="row.program_open_id" type="success" class="m-r-2">小程序</el-tag>
          <el-tag v-if="row.wechat_open_id" type="primary" class="m-r-2">公众号</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="store_user" label="会籍顾问">
        <template slot-scope="{ row }">
          {{ row.store_user ? row.store_user.real_name : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="入会时间" min-width="120" />
      <el-table-column label="操作" min-width="160px">
        <template slot-scope="scope">
          <el-button
            v-show="btn_access('s_user_info')"
            class="m-2"
            type="primary"
            icon="el-icon-info"
            @click="toInfoPage(scope.row.id)"
          >
            详情
          </el-button>
          <el-button
            v-show="btn_access('s_user_vipcard_add')"
            type="success"
            class="filter-item m-2"
            icon="el-icon-plus"
            @click="$router.push({ name: 's_user_vipcard_add', params: { user_id: scope.row.id, card_id: 2 } })"
          >
            发卡
          </el-button>
          <!-- <el-button class="m-2" type="info" icon="el-icon-close" @click="handleClearPoints(scope.row.id)">
            清空积分
          </el-button> -->
          <el-button
            v-show="btn_access('s_user_track_list')"
            class="m-2"
            icon="el-icon-notebook-2"
            @click="toVisitorTrackPage(scope.row.id)"
          >
            回访记录
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <!-- 添加编辑用户弹窗 -->
    <el-dialog
      v-if="dialog.visible"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.id ? '编辑用户' : '添加用户'"
      class="dialog_auto"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="头像" prop="avatar" style="width: 100px;">
          <div class="block" @click="imagecropperShow = true">
            <el-avatar
              shape="square"
              :size="100"
              :src="form.avatar"
              :title="dialog.id ? '点击修改' : '点击上传'"
              style="width:100px"
            >
              <img :src="errorMemberSrc()">
            </el-avatar>
          </div>
          <image-cropper
            v-show="imagecropperShow"
            :key="imagecropperKey"
            field="file"
            :width="300"
            :height="300"
            :url="storeUpload"
            lang-type="zh"
            @close="imagecropperClose"
            @crop-upload-success="cropSuccess"
          />
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="form.sex">
            <el-radio-button :label="2">女</el-radio-button>
            <el-radio-button :label="1">男</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="真实姓名" prop="real_name">
          <el-input v-model="form.real_name" placeholder="请输入真实姓名" class="" />
        </el-form-item>
        <!-- <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入昵称" class="" />
        </el-form-item> -->
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号" class="">
            <div slot="prepend">
              <Code @area_code="area_code" />
            </div>
          </el-input>
        </el-form-item>
        <!-- 会籍顾问 -->
        <el-form-item label="会籍顾问" prop="store_user_id">
          <Select :type="4" @changeId="handleCoachIDAdd" />
        </el-form-item>
        <el-form-item v-if="utils.getVenues().id == -1" label="所属场馆" prop="venues_id">
          <el-select v-model="form.venues_id" filterable placeholder="请选择所属场馆" class="dialog_w100">
            <el-option v-for="item in venues_list" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" type="textarea" :rows="4" placeholder="请输入备注" class="" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">
          {{ dialog.id ? '保存' : '新增' }}
        </el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 用户信息弹窗 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.userVisible"
      title="用户信息"
      class=""
      @close="onClose"
    >
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user" />
            头像
          </template>
          <div class="demo-basic--circle">
            <div class="block">
              <el-avatar shape="square" :size="50" :src="form.avatar">
                <img :src="errorAvatarSrc(form.sex, 'img')">
              </el-avatar>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user-solid" />
            姓名
          </template>
          {{ form.real_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone" />
            手机号
          </template>
          {{ form.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets" />
            昵称
          </template>
          <el-tag>{{ form.nickname }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building" />
            地区
          </template>
          {{ form.area ? form.area : '--' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building" />
            详细地址
          </template>
          {{ form.address ? form.address : '无' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-time" />
            入会时间
          </template>
          {{ form.create_time ? form.create_time : '无' }}
        </el-descriptions-item>
        <template slot="extra" />
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button type="text" @click="onClose">关闭</el-button>
        <el-button type="primary" icon="el-icon-edit" @click="add_edit(form.id)">编辑</el-button>
      </span>
    </el-dialog>

    <!-- 用户回访 -->
    <VisitorTrack :id="dialog.trackId" :track-visible.sync="dialog.trackVisible" />
    <!-- 导出 -->
    <Export :success-prompt="successPrompt" :is-judge="is_judge" :animation-display="animation_display" />
    <!-- 批量修改积分 -->
    <ModifyPoints :visible="modifyPointsVisible" @closeDialog="closeDialog" />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { getVenues } from '@/api/store/venues.js'
import { getUser, addUser, getUserDetails } from '@/api/store/user.js'
import { setUserIntegral } from '@/api/store/user.js'
import { parseTime } from '@/utils'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import ImageCropper from '@/components/ImageCropper' // 头像上传
import VisitorTrack from './tracking' // 用户回访
import DateMonthDay from './components/index.vue'
// 组件
import Code from '@/components/code/index.vue'
import ModifyPoints from './components/ModifyPoints.vue'
import Export from '@/components/export/index.vue'
import Select from '@/components/elSelect/Select'

export default {
  components: { Pagination, ImageCropper, VisitorTrack, DateMonthDay, Code, ModifyPoints, Export, Select },
  data() {
    // 上传图片验证器
    // var checkImage = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error('请上传头像'))
    //   }
    // }
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      tableLoading: false,
      dialog: { visible: false, id: '', userVisible: false, trackVisible: false, trackId: null },
      add_form: { sex: 2, avatar: '', area_code: '+86' },
      edit_form: {},
      rules: {
        // nickname: [{ required: true, message: '请填写昵称', trigger: 'blur' }],
        real_name: [{ required: true, message: '请填写真实姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请填写手机号', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        venues_id: [{ required: true, message: '请选择场馆', trigger: 'change' }],
        store_user_id: [{ required: true, message: '请选择会籍顾问', trigger: 'change' }]
        // avatar: [{ required: true, validator: checkImage }]
      },
      form: {
        venues_id: '',
        remark: '',
        area_code: '+86'
      },
      exportLoading: false,
      loading: false,
      is_edit: 0,
      imagecropperShow: false,
      imagecropperKey: 0,
      downloadLoading: false,
      searchLoading: false,
      venues_list: [],
      successPrompt: false,
      is_judge: 0,
      modifyPointsVisible: false,
      animation_display: true
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  },
  created() {},
  mounted() {
    this.getList()

    // 获取场馆列表
    this.getVenuesList()
  },
  methods: {
    async Download() {
      const _this = this
      this.animation_display = true
      this.is_judge = 0
      this.successPrompt = true
      this.exportLoading = true
      const query = encodeURIComponent(this.setQuery())
      const progressBar = await this.utils.handleDownload({
        url: '/store/v1/userExport?query=' + query,
        fileName: '用户会员列表.xlsx',
        baseURL: this.baseUrl
      })

      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.exportLoading = false
          _this.successPrompt = false
        }, 2000)
      }
    },
    getVenuesList(query) {
      const data = query ? this.utils.getQueryParam({ name: '%' + query + '%' }) : {}
      data.limit = 999
      getVenues(data)
        .then(res => {
          this.venues_list = res.data.list
        })
        .catch(() => {})
    },
    setQuery() {
      var querys = []
      if (this.listQueryParams.query) {
        querys.push({ field: 'phone|real_name|nickname', type: 'like', key: this.listQueryParams.query })
      }
      if (this.listQueryParams.sex !== '' && this.listQueryParams.sex != null) {
        querys.push({ field: 'sex', key: this.listQueryParams.sex })
      }
      if (this.listQueryParams.store_user_id !== '' && this.listQueryParams.store_user_id != null) {
        querys.push({ field: 'store_user_id', key: this.listQueryParams.store_user_id })
      }
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.birthday) {
        querys.push({ field: 'birthday', type: 'like', key: this.listQueryParams.birthday })
      }
      return this.utils.getQueryParams(querys)
    },
    // 获取列表
    getList() {
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()

      getUser(data)
        .then(res => {
          this.tableData = res.data
          this.searchLoading = false
          this.tableLoading = false
        })
        .catch(() => {})
    },
    add_edit(id) {
      this.is_edit = 0
      this.dialog.visible = true
      this.dialog.id = id
      this.dialog.userVisible = false
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
      this.form = this.add_form
      // this.form.store_user_id = this.$store.getters.userinfo.storeInfo.id
      if (id > 0) {
        this.loading = true
        this.is_edit = 1
        getUserDetails(id)
          .then(res => {
            this.form = res.data
            this.loading = false
          })
          .catch(() => {})
      } else {
        this.dialog.id = 0
      }
    },
    details(id) {
      this.loading = true
      this.dialog.userVisible = true
      getUserDetails(id)
        .then(res => {
          this.form = res.data
          this.loading = false
        })
        .catch(() => {})
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.is_edit) {
            console.log(this.form)
            this.$message.error('暂无接口')
            // editUser(this.form).then(res => {
            //   this.$message.success('编辑成功')
            //   this.getList()
            //   this.dialog.visible = false
            // }).catch(() => {})
          } else {
            const data = deepClone(this.form)
            data.area_code = this.form.area_code === '+86' ? '86' : this.form.area_code.replace(/[^0-9]/gi, '')
            data.scene = this.form.area_code === '+86' ? '' : 'internation'
            if (this.utils.getVenues().id !== -1) {
              data.venues_id = this.utils.getVenues().id
            }
            addUser(data)
              .then(res => {
                this.$message.success('添加成功')
                this.getList()
                this.dialog.visible = false
              })
              .catch(() => {})
          }
        }
      })
    },
    onClose() {
      console.log(this.dialog.userVisible)
      this.dialog.userVisible = false
    },
    // 头像上传成功
    cropSuccess(resData) {
      console.log(resData)
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      this.form.avatar = resData.path
    },
    // 头像上传弹出层关闭
    imagecropperClose() {
      this.imagecropperShow = false
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 导出
    handleDownload() {
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['会员', '手机号', '性别', '生日', '渠道', '入会时间']
        const filterVal = ['real_name', 'phone', 'sex', 'birthday', 'channel', 'create_time']
        const data = this.formatJson(filterVal)
        const filename = ['用户列表']
        if (this.utils.getVenues().name) filename.push(this.utils.getVenues().name)
        filename.push(this.$moment().format('YYYY-MM-DD'))
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: filename.join('_')
        })
        this.downloadLoading = false
      })
    },
    formatJson(filterVal) {
      return this.tableData.list.map(v =>
        filterVal.map(j => {
          if (j === 'sex') {
            return this.formatTableSex(v)
          }
          if (j === 'real_name') {
            return this.getUserNames(v)
          }
          if (j === 'channel') {
            const a = []
            if (v.program_open_id) a.push('小程序')
            if (v.wechat_open_id) a.push('公众号')
            return a.join('-')
          }
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        })
      )
    },
    // 打开用户详情
    toInfoPage(id) {
      this.$router.push({ name: 's_user_info', params: { id: id }})
    },
    toVisitorTrackPage(id) {
      this.dialog.trackId = id
      this.dialog.trackVisible = true
    },
    realNameFormat(row, colum) {
      return row.real_name ? row.real_name : '--'
    },
    changeDay(val) {
      this.listQueryParams.birthday = val.date
    },
    handleCoachID(val) {
      this.listQueryParams.store_user_id = val
    },
    handleCoachIDAdd(val) {
      this.form.store_user_id = val
    },
    area_code(val) {
      this.form.area_code = val
    },
    handleModifyPointsAll() {
      this.modifyPointsVisible = true
    },
    closeDialog() {
      this.modifyPointsVisible = false
      this.getList()
    },
    // 清空积分
    handleClearPoints(id) {
      this.$confirm('此操作将清空积分, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const data = {
            change_type: 3
          }
          setUserIntegral(id, data)
            .then(() => {
              this.$message.success('操作成功')
              this.getList()
            })
            .finally(() => {})
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.el-avatar {
  background: none;
}
</style>
