/**
 * 全局数据修改、方法引入
 */
module.exports = {
  data() {
    return {
      defaultLogo: require('@/assets/images/logo_round.svg'),
      currentVenues: this.utils.getVenues(),
      // 翻页
      listQuery: {
        page: 1,
        limit: 10
      },
      listQueryParams: {
      },
      sexList: [{ label: '保密', value: 0 }, { label: '女', value: 2 }, { label: '男', value: 1 }],
      storeUpload: (process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_BASE_API) + '/store/v1/upload',
      adminUpload: (process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_BASE_API) + '/admin/v1/upload',
      baseUrl: process.env.VUE_APP_BASE_API,
      view_size: 'small',
      errorImageSrc: require('@/assets/images/default_img.png'),
      errorImageAvatar: require('@/assets/images/avatar/default.png'),
      searchForm: {},
      searchParams: {},
      searchShow: false,
      publicPath: process.env.VUE_APP_PUBLIC_PATH,
      Version: process.env.VUE_APP_Version,
      searchLoading: false,
      courseDefaultColorList: [
        '#6D72EA', '#393E4F', '#3B98DA', '#84A2D4', '#EC6D71', '#40DBD9', '#69C156', '#4C6EF5', '#E26345', '#1098AD', '#F59F00', '#40C057', '#BF783A', '#FA5252', '#203744', '#0CA678', '#D6336C'
      ],
      upLoading: false,
      // 开卡类型 0 立即开卡 1 首次预约开卡 2 特定时间开卡 3 首次上课开卡
      card_open_type: [
        '立即开卡', '首次预约开卡', '特定时间开卡', '首次上课开卡'
      ],
      // '期限卡', '次数卡', '储值卡'
      card_unit_type: [
        '天', '次', '元'
      ],
      card_type: [
        '期限卡', '次数卡', '储值卡'
      ],
      course_type: [
        '团课', '私教课', '精品课'
      ],
      callNumber: '400-678-0554',
      icpNumber: '浙ICP备18053539号-3',
      empty_default_img: require('@/assets/images/empty_data.png'),
      web_link: 'https://www.yuxiaojiu.com',
      payment_method: ['无', '储值卡支付', '微信支付', '支付宝支付', '现金支付', '储蓄卡支付', '信用卡支付', '其他'],
      Data: new Date(),
      // 日期快捷选择器
      dateShortcutChooser: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      // 禁止选择当日之前的日期
      dataProhibitSelection: {
        // 对象
        // 禁用时间
        selectableRange: '10:00:00- 23:59:59', // 动态写法在初始化时 对象.的方式更改
        disabledDate: time => {
          // 禁用日期
          let nowData = new Date()
          nowData = new Date(nowData.setDate(nowData.getDate() - 1))
          return time < nowData
        }
      }
    }
  },
  computed: {
    errorAvatarSrc() {
      return function(data, type = 'string') {
        let sex = 0
        if (typeof (data) === 'object') {
          sex = data.sex
        } else {
          sex = data
        }
        switch (sex) {
          case 1:
            return type === 'string' ? '男' : require('@/assets/images/avatar/man.png')
          case 2:
            return type === 'string' ? '女' : require('@/assets/images/avatar/woman.png')
          default:
            return type === 'string' ? '保密' : require('@/assets/images/avatar/default.png')
        }
      }
    },
    errorMemberSrc() {
      return function() {
        return require('@/assets/images/avatar/Frame.svg')
      }
    },
    errorLogo() {
      return function() {
        return require('@/assets/images/logo_square.png')
      }
    }
  },
  methods: {
    /**
     * 跳转到指定页面
     */
    toPages(data) {
      if (data) this.$router.push(data)
    },
    toUserInfoPage(id) {
      if (id) this.toPages({ name: 's_user_info', params: { id: id }})
    },
    toCardInfoPage(id) {
      if (id) this.toPages({ name: 's_user_vipcard_info', params: { id: id }})
    },
    toCourseInfoPage(id) {
      if (id) this.toPages({ name: 's_course_edit', params: { id: id }})
    },
    toDetailsInfoPage(id) {
      if (id) this.$router.push({ name: 's_user_info', params: { id: id }})
    },
    // 按钮鉴权
    btn_access(name) {
      if (!name) return true
      // 超管
      if (localStorage.getItem('is_super') !== 'false') return true
      // 其他
      const access_list = localStorage.getItem('access_list') ? localStorage.getItem('access_list') : ''
      const access_list_array = access_list.split(',')
      // console.log('🚀 ~ file: mixin.js ~ line 40 ~ btn_access ~ access_list_array', access_list_array)
      return access_list_array.includes(name)
    },
    // 判断是否超管
    is_super() {
      return JSON.parse(localStorage.getItem('is_super'))
    },
    formatTableSex(row, colum, data, index) {
      switch (row.sex) {
        case 1:
          return '男'
        case 2:
          return '女'
        default:
          return '保密'
      }
    },

    /**
		 * 获取用户名
		 * @param {Object} userInfo
		 * omit 长度
		 */
    getUserNames(userInfo, omit = 0) {
      if (userInfo) {
        let str = ''
        if (userInfo.nickname && userInfo.real_name && userInfo.nickname === userInfo.real_name) {
          str = userInfo.nickname
        } else {
          // 昵称为主（考虑到新用户一般都没有真实姓名）
          // const nickname = userInfo.nickname ? userInfo.nickname : (userInfo.real_name ? '--' : '')
          // const real_name = userInfo.real_name ? `(${userInfo.real_name})` : (!userInfo.nickname ? '---' : '')
          // str = nickname + real_name
          // 真实姓名为主
          const real_name = userInfo.real_name ? userInfo.real_name : (userInfo.nickname ? '' : '')
          const nickname = userInfo.nickname ? (!userInfo.real_name ? userInfo.nickname : `(${userInfo.nickname})`) : (!userInfo.real_name ? '---' : '')
          str = real_name + nickname
        }
        if (omit && str && str !== '--' && str.length > omit) str = str.substring(0, omit) + '...'
        return str
      } else {
        return '--'
      }
    },
    // 计算剩余天数
    getExcessTime(valid_time) {
      if (!valid_time) return ''
      const e = this.$moment(valid_time).format('yyyy-MM-DD')// 开始时间
      const s = this.$moment().format('yyyy-MM-DD')// 结束时间
      return this.$moment(e).diff(s, 'days')
    },
    // 计算分钟
    getExcessTimeMinute(valid_time) {
      if (!valid_time) return ''
      const e = this.$moment(valid_time).format('yyyy-MM-DD HH:mm:ss')// 开始时间
      const s = this.$moment().format('yyyy-MM-DD HH:mm:ss')// 结束时间
      return this.$moment(e).diff(s, 'minutes')
    },
    // 卡开通类型
    formatCardOpenType(n) {
      return this.card_open_type[n]
    },
    // 卡余额单位
    formatCardUnitType(n) {
      return this.card_unit_type[n]
    },
    // 卡类型
    formatCardType(n) {
      return this.card_type[n]
    },
    // 跳转到指定链接
    toUrl(v) {
      if (!v) return
      window.open(v)
    },
    // 返回上一页
    returnPage(page) {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/dashboard' })
        return false
      } else {
        if (page) {
          this.$router.push(page)
        } else {
          this.$router.go(-1)
        }
      }
    },
    // 16进制颜色转rgb
    hexToRgb(hex) {
      return 'rgb(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) +
              ',' + parseInt('0x' + hex.slice(5, 7)) + ')'
    },
    // 对象转换成GET请求拼接
    params(obj) {
      let result = ''
      let item
      for (item in obj) {
        if (obj[item] && String(obj[item])) {
          result += `&${item}=${obj[item]}`
        }
      }
      if (result) {
        result = '?' + result.slice(1)
      }
      return result
    },
    // 点击复制内容
    handleCopy(content, text) {
      console.log('🚀 ~ file: index.vue:273 ~ handleClick ~ spanContent:', content)
      // 判断是否为空 不为空就复制到剪切板
      if (content) {
        // 获取用户粘贴板
        var clipboard = navigator.clipboard
        // 将文本复制到粘贴板
        clipboard.writeText(content)
        this.$message({
          message: content + text,
          type: 'success',
          duration: 800
        })
      }
    },
    // 把后端数据转换成el-cascader所能用的数据
    convertToCascaderData(data) {
      if (!data || !Array.isArray(data)) {
        return undefined
      }

      return data.map(item => ({
        value: item.id,
        label: item.title,
        children: this.convertToCascaderData(item.children)
      }))
    },
    // 把 2024-01-01 18:00:00 切割成 2024-01-01 18:00
    formatTime(time) {
      if (!time) return time
      return time.slice(0, 16)
    }
  }
}

