<template>
  <div v-if="$route.name=='s_user_vipcard_list'" v-loading="loading" element-loading-text="下载中" class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="开卡类型">
            <el-select v-model="listQueryParams.type" placeholder="请选择" clearable>
              <el-option :key="0" label="立即开卡" value="0" />
              <el-option :key="1" label="首次预约开卡" value="1" />
              <el-option :key="2" label="特定时间开卡" value="2" />
              <el-option :key="3" label="首次上课开卡" value="3" />
            </el-select>
          </el-form-item>
          <!-- 持卡会员 -->
          <el-form-item label="持卡会员">
            <Select :type="2" :width="216" @changeId="handleMemberID" />
          </el-form-item>
          <el-form-item label="发卡场馆">
            <el-select v-model="listQueryParams.venues_id" placeholder="请选择" filterable clearable>
              <el-option
                v-for="item in venues_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="发卡时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="开卡时间">
            <el-date-picker
              v-model="listQueryParams.open_card_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="到期时间">
            <el-date-picker
              v-model="listQueryParams.valid_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="付款方式">
            <el-select v-model="listQueryParams.payment_method_id" placeholder="请选择" clearable>
              <el-option
                v-for="(item,index) in payment_method"
                :key="index"
                :label="item"
                :value="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="卡类型">
            <el-select v-model="listQueryParams.card_type" placeholder="请选择" clearable>
              <el-option :key="0" label="期限卡" value="0" />
              <el-option :key="1" label="次数卡" value="1" />
              <el-option :key="2" label="储值卡" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="卡名称">
            <el-select v-model="listQueryParams.membership_card_id" placeholder="请选择" filterable clearable>
              <el-option v-for="item in membershipCard" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="卡状态">
            <el-select v-model="listQueryParams.status_id" placeholder="请选择" clearable>
              <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索卡号|备注" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('s_user_vipcard_add')" type="primary" class="filter-item m-l-10" icon="el-icon-plus" @click="$router.push({ name: 's_user_vipcard_add', params: { user_id: 0, card_id: 2 }})">发卡</el-button>
      <!-- <el-button v-show="btn_access('s_user_vipcard_export')" :disabled="tableData.list.length<=0" type="danger" class="filter-item m-l-10" icon="el-icon-download" :loading="exportLoading" @click="Download">导出</el-button> -->
      <!-- <el-button v-show="btn_access('s_user_vipcard_export')" :disabled="tableData.list.length<=0" type="danger" class="filter-item m-l-10" icon="el-icon-download" :loading="exportLoading" @click="Download_view">出勤报表</el-button> -->
      <!-- <el-button v-show="currentVenues.id !== -1 && btn_access('s_user_vipcard_import')" class="filter-item" type="warning" @click="$router.push({ name: 's_user_vipcard_import', params: { type: 1}})">
        批量导入
      </el-button> -->
      <el-button :disabled="multipleSelection.length == 0" type="danger" class="filter-item m-l-10" :loading="buttonLoading" @click="handleDeleteAll">批量删除</el-button>
      <!-- <el-button type="success" class="filter-item m-l-10" @click="handleOperationAll">批量操作</el-button> -->
      <!-- <el-button v-show="btn_access('s_leaveManagement')" :disabled="tableData.list.length<=0" class="filter-item" type="warning" @click="$router.push({ name: 's_leaveManagement'})">
        请假管理
      </el-button> -->
      <el-dropdown v-show="btn_access('s_user_vipcard_export')" class="course filter-item m-l-10" :hide-on-click="false">
        <el-button type="danger">
          导出操作
          <i class="el-icon-arrow-down el-icon--right" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :disabled="tableData.list.length<=0" :loading="exportLoading" @click.native="Download">
            <template>
              <i class="el-icon-download" />
              导出
            </template>
          </el-dropdown-item>
          <el-dropdown-item :disabled="tableData.list.length<=0" :loading="exportLoading" @click.native="Download_view">
            <template>
              <i class="el-icon-download" />
              出勤报表
            </template>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown class="course filter-item m-l-10" :hide-on-click="false">
        <el-button type="success">
          批量操作
          <i class="el-icon-arrow-down el-icon--right" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-show="currentVenues.id !== -1 && btn_access('s_user_vipcard_import')" @click.native="$router.push({ name: 's_user_vipcard_import', params: { type: 1 } })">
            <template>
              <i class="el-icon-upload" />
              批量导入
            </template>
          </el-dropdown-item>
          <el-dropdown-item @click.native="handleOperationAll">
            <template>
              <i class="el-icon-edit" />
              批量修改
            </template>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="tableLoading"
      :data="tableData.list"
      row-key="id"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column type="selection" width="55" />
      <el-table-column prop="number" label="卡号" min-width="100">
        <template slot-scope="{row}">
          <el-tag>{{ row.number }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="user.real_name" label="持卡用户" min-width="160px">
        <template slot-scope="{row}">
          <div v-if="!utils.empty(row.user)" class="flex col-center cursor" @click="toUserInfoPage(row.user.id)">
            <div>
              <el-avatar shape="square" :size="50" :src="row.user.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row.user) }}</span><br>
              <span>{{ row.user.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>

      <el-table-column prop="type" label="开卡类型" min-width="90px">
        <template slot-scope="{row: {type}}">
          <span>{{ card_open_type[type] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="limit" label="余额" min-width="90px">
        <template slot-scope="{row}">
          <div v-if="row.status_id!==14 || row.card.type !==0">
            <span v-if="row.card.type===0">
              <span v-if="getExcessTime(row.valid_time)>0" class="text-success">{{ getExcessTime(row.valid_time) }} <b v-if="row.card">{{ formatCardUnitType(row.card.type) }}</b></span>
              <!-- <span v-else class="text-danger">{{ getExcessTime(row.valid_time) }} <b v-if="row.card">{{ formatCardUnitType(row.card.type) }}</b></span> -->
              <span v-else class="text-danger">{{ 0 }} <b v-if="row.card">{{ formatCardUnitType(row.card.type) }}</b></span>
            </span>
            <span v-else>
              {{ row.limit }} <b v-if="row.card">{{ formatCardUnitType(row.card.type) }}</b>
            </span>
          </div>
          <div v-if="row.status_id ===14">未开卡</div>
        </template>
      </el-table-column>
      <el-table-column prop="card.name" label="卡名称" min-width="160px" />
      <el-table-column prop="venues.name" label="发卡场馆" min-width="160px" />
      <!-- <el-table-column prop="type" label="属性">
        <template slot-scope="scope">
          <p>时间范围:{{ scope.row.specific_time }}</p>
          <p>到期时间:{{ scope.row.valid_time }}</p>
        </template>
      </el-table-column> -->
      <el-table-column prop="create_time" label="发卡时间" min-width="120px" />
      <el-table-column prop="valid_time" label="到期时间" min-width="80px">
        <template slot-scope="{row}">
          {{ row.status_id==14?'':(row.valid_time?row.valid_time:'') }}
        </template>
      </el-table-column>
      <el-table-column prop="valid_time" label="剩余天数" min-width="60px">
        <template slot-scope="{row}">
          <div v-if="row.status_id!==14">
            <span v-if="getExcessTime(row.valid_time)>0" class="text-success">{{ getExcessTime(row.valid_time) }}</span>
            <!-- <span v-else class="text-danger">{{ getExcessTime(row.valid_time) }}</span> -->
            <span v-else class="text-danger">{{ 0 }}</span>
          </div>
          <div v-if="row.status_id ===14">未开卡</div>
        </template>
      </el-table-column>
      <el-table-column prop="status_id" label="状态" min-width="100px">
        <template slot-scope="{row: {status_id}}">
          <el-tag :type="card_status[status_id]">{{ formatStatus(status_id) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="140px">
        <template slot-scope="scope">
          <el-button v-show="btn_access('s_user_vipcard_info')" class="m-2" type="primary" icon="el-icon-info" @click="toInfoPage(scope.row.id)">详情</el-button>
          <el-button type="warning" class="m-2" @click="operation(scope.row)">操作</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 编辑会员卡、添加会员卡 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.id ? '编辑会员卡' : '添加会员卡'"
      class="dialog_w580"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px" label-position="right">
        <p><b>卡信息</b></p>
        <el-row :gutter="2">
          <el-col :span="12">
            <el-form-item label="会员卡号" prop="number">
              <el-input v-model="form.number" placeholder="请输入会员卡号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="会员卡" prop="membership_card_id">
              <el-select v-model="form.membership_card_id" filterable placeholder="可搜索">
                <el-option v-for="item in membershipCard" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="持卡用户" prop="user_id">
              <el-select v-model="form.user_id" filterable placeholder="可搜索">
                <el-option v-for="item in user" :key="item.id" :label="item.nickname" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开卡类型" prop="type">
              <el-select v-model="form.type" placeholder="请选择开卡类型">
                <el-option v-for="(item,index) in card_open_type" :key="item" :label="item" :value="index" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.type == 2" label="开卡时间" prop="specific_time">
              <el-date-picker v-model="form.specific_time" type="datetime" placeholder="请选择开卡时间" class="dialog_w100" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到期时间" prop="valid_time">
              <el-date-picker
                v-model="form.valid_time"
                type="date"
                placeholder="到期时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <p><b>收费信息</b></p>
          </el-col>

          <el-col :span="12">
            <el-form-item label="加赠">
              <el-input v-model="form.gift" placeholder="优惠活动(天)" type="number" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="付款方式">
              <el-select v-model="form.payment_method_id" placeholder="付款方式">
                <el-option v-for="(item,index) in payment_method" :key="index" :label="item" :value="index" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实收金额">
              <el-input v-model="form.actual_amount" placeholder="实收金额(元)" type="number" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="form.remark" type="textarea" placeholder="请填写备注" rows="3" />
            </el-form-item>
          </el-col>

        </el-row>

      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">{{ dialog.id ? '更新' : '添加' }}</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <!-- 会员卡操作 -->
    <el-dialog
      v-if="dialog.operation"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.operation"
      title="操作"
      class="dialog_auto"
    >
      <Operation v-model="dialog.operation" :card-data="dialog.row" @callback="operation_callback" />
    </el-dialog>

    <!-- 请假配置弹出层 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.ask_off_setting"
      title="请假配置"
      class="dialog_w580"
    >
      <el-form ref="ruleAskSettingForm" :model="ask_setting_form" label-width="100px" :rules="ask_setting_rules">
        <el-form-item label="会员卡号" prop="number">
          <el-input v-model="ask_setting_form.number" type="number" disabled />
        </el-form-item>
        <el-form-item label="可请假次数" prop="leave_number">
          <el-input v-model="ask_setting_form.leave_number" type="number" placeholder="请填写可请假次数" />
        </el-form-item>
        <el-form-item label="可请假天数" prop="leave_day">
          <el-input v-model="ask_setting_form.leave_day" type="number" placeholder="请填写可请假天数" />
        </el-form-item>
        <el-form-item label="自行请假" prop="leave">
          <el-radio-group v-model="ask_setting_form.leave">
            <el-radio :label="1">允许</el-radio>
            <el-radio :label="0">不允许</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="ask_setting_submit">确认</el-button>
          <el-button @click="dialog.ask_off_setting = false">取消</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
    <el-dialog
      title="请选择出勤统计时间范围"
      :visible.sync="dialogVisibleExport"
      width="30%"
      center
    >
      <div class="m-y-10 text-center">
        <el-date-picker
          v-model="export_time"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        />
      </div>
      <span slot="footer" class="dialog-footer ">
        <el-button @click="dialogVisibleExport = false">取 消</el-button>
        <el-button type="primary" @click="Download2">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导出 -->
    <Export :success-prompt="successPrompt" :is-judge="is_judge" :animation-display="animation_display" />

    <!-- 批量操作 -->
    <BatchOperations :visible="modifyPointsVisible" @closeDialog="closeDialog" />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import Operation from './components/Operation'
import { getUserMembershipCard, addUserMembershipCard, getUserMembershipCardDetails, editUserMembershipCard, getUser, deleteMembershipCardAll } from '@/api/store/user.js'
import { getUserMembershipCardStatus } from '@/api/store/user.js'
import { getVipCard, getVenues } from '@/api/store/venues.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import moment from 'moment'
import 'moment/locale/zh-cn' // moment 转中文 http://momentjs.cn/
import utils from '@/utils/utils'
import Select from '@/components/elSelect/Select'
import BatchOperations from './components/BatchOperations.vue'
import Export from '@/components/export/index.vue'
export default {
  components: { Pagination, Operation, Select, BatchOperations, Export },
  data() {
    return {
      card_status: ['', '', 'success', 'info', 'warning', 'danger', 'info', 'warning', '', '', '', '', 'danger', '', '', '', '', '', '', '', '', '', '', '', 'warning'],
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      loading: false,
      tableLoading: false,
      rules: ['number', 'membership_card_id', 'user_id', 'type', 'specific_time'],
      form: {
        payment_method_id: 0
      },
      dialog: { visible: false, id: null, operation: false, row: {}, ask_off: false, ask_off_setting: false, ask_cancel_off: false },
      membershipCard: [],
      user: [],
      statusData: {},
      statusList: {},

      ask_setting_rules: ['leave_number', 'leave_day', 'leave'],
      ask_setting_form: {},
      searchLoading: false,
      exportLoading: false,
      venues_list: [],
      o_status: {},
      getCodeText: '获取验证码',
      sendCoding: false,
      isLoading: false,
      smsTimes: 60,
      dtime: '',
      dialogVisibleExport: false,
      export_time: [moment().format('YYYY-MM-01'), moment().format('YYYY-MM-DD')],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      successPrompt: false,
      is_judge: 0,
      multipleSelection: [],
      buttonLoading: false,
      modifyPointsVisible: false,
      animation_display: true
    }
  },
  watch: {
    searchForm: {
      handler(val, oldVal) {
        this.listQuery = val
      },
      deep: true // true 深度监听
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
    this.ask_setting_rules = this.utils.validateFor(this.ask_setting_rules)
  },
  mounted() {
    this.getCardStatus()
    this.getList()
    // 获取会员卡列表
    getVipCard({ limit: 999 }).then(res => {
      this.membershipCard = res.data.list
    }).catch(() => {})
    // 获取用户列表
    getUser().then(res => {
      this.user = res.data.list
    }).catch(() => {})

    this.getVenuesList()
  },
  methods: {
    // 下载文件
    // download(data, fileName) {
    //   if (!data) {
    //     return
    //   }
    //   const url = window.URL.createObjectURL(new Blob([data]))
    //   const link = document.createElement('a')
    //   link.style.display = 'none'
    //   link.href = url
    //   link.setAttribute('download', fileName)
    //   document.body.appendChild(link)
    //   link.click()
    // },
    // 获取场馆列表
    getVenuesList() {
      getVenues({ limit: 999 }).then(res => {
        this.venues_list = res.data.list
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    Download_view() {
      this.dialogVisibleExport = true
    },
    async Download2() {
      const _this = this
      this.animation_display = true
      this.is_judge = 0
      this.successPrompt = true
      this.exportLoading = true
      let url = '/store/v1/course_subscribe/attendanceExport'
      if (this.export_time.length > 0) {
        url += '?s_time=' + this.export_time[0] + '&e_time=' + this.export_time[1]
      }
      const progressBar = await this.utils.handleDownload({ url: url, fileName: '出勤报表记录.xlsx', baseURL: this.baseUrl })

      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.exportLoading = false
          _this.dialogVisibleExport = false
          _this.successPrompt = false
        }, 2000)
      }
    },
    async Download() {
      const _this = this
      this.animation_display = true
      this.is_judge = 0
      this.successPrompt = true
      this.exportLoading = true
      const query = encodeURIComponent(this.setQuery())
      const progressBar = await this.utils.handleDownload({
        url: '/store/v1/user_membership_card/export/venues_id/' + utils.getVenues().id + '?query=' + query,
        fileName: '用户会员卡.xlsx',
        baseURL: this.baseUrl
      })

      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.exportLoading = false
          _this.successPrompt = false
        }, 2000)
      }
    },
    setQuery() {
      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'number|remark', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.type !== '' && this.listQueryParams.type != null) querys.push({ field: 'UserMembershipCardModel.type', key: this.listQueryParams.type })
      if (this.listQueryParams.payment_method_id !== '' && this.listQueryParams.payment_method_id != null) querys.push({ field: 'payment_method_id', key: this.listQueryParams.payment_method_id })
      if (this.listQueryParams.venues_id !== '' && this.listQueryParams.venues_id != null) querys.push({ field: 'UserMembershipCardModel.venues_id', key: this.listQueryParams.venues_id })
      if (this.listQueryParams.membership_card_id !== '' && this.listQueryParams.membership_card_id != null) querys.push({ field: 'membership_card_id', key: this.listQueryParams.membership_card_id })
      if (this.listQueryParams.store_user_id !== '' && this.listQueryParams.store_user_id != null) querys.push({ field: 'store_user_id', key: this.listQueryParams.store_user_id })
      if (this.listQueryParams.user_id !== '' && this.listQueryParams.user_id != null) querys.push({ field: 'user_id', key: this.listQueryParams.user_id })
      if (this.listQueryParams.status_id !== '' && this.listQueryParams.status_id != null) querys.push({ field: 'status_id', key: this.listQueryParams.status_id })
      if (this.listQueryParams.create_time) querys.push({ field: 'UserMembershipCardModel.create_time', type: 'between-time', key: this.listQueryParams.create_time })
      if (this.listQueryParams.open_card_time) querys.push({ field: 'open_card_time', type: 'between-time', key: this.listQueryParams.open_card_time })
      if (this.listQueryParams.valid_time) querys.push({ field: 'valid_time', type: 'between-time', key: this.listQueryParams.valid_time })
      if (this.listQueryParams.birthday) querys.push({ field: 'birthday', type: 'between-time', key: this.listQueryParams.birthday })
      return this.utils.getQueryParams(querys)
    },
    // 获取列表
    getList() {
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()

      // 筛选卡类型
      if (this.listQueryParams.card_type) data.card_type = this.listQueryParams.card_type

      getUserMembershipCard(data).then(res => {
        this.tableData = res.data
        this.tableLoading = false
        this.searchLoading = false
      }).catch(() => {})
    },
    getCardStatus() {
      getUserMembershipCardStatus({ limit: 999 }).then(res => {
        const new_arr = []
        for (var n = 0; n < res.data.list.length; n++) {
          new_arr[res.data.list[n]['id']] = res.data.list[n]['name']
        }
        this.statusList = res.data.list
        this.statusData = new_arr
      }).catch(() => {})
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          data['venues_id'] = this.utils.getVenues().id
          data['specific_time'] = moment(data['specific_time']).unix()
          if (this.dialog.id) {
            editUserMembershipCard(this.dialog.id, data).then(res => {
              this.callback('修改成功')
            }).catch(() => {})
          } else {
            addUserMembershipCard(data).then(res => {
              this.callback('添加成功')
            }).catch(() => {})
          }
        }
      })
    },
    callback(msg) {
      this.$message.success(msg)
      this.getList()
      this.dialog.visible = false
    },
    dialogVisible(id) {
      this.dialog.visible = true
      this.dialog.id = id
      if (id) {
        getUserMembershipCardDetails(id).then(res => {
          this.form = res.data
        }).catch(() => {})
      } else {
        this.form = {
          valid_time: utils.getDayTime()
        }
      }
    },
    // 状态码格式化
    formatStatus(s) {
      if (!this.statusData) {
        this.getCardStatus()
      }
      return this.statusData[s] ? this.statusData[s] : '----'
    },
    // 打开用户详情
    toInfoPage(id) {
      this.$router.push({ name: 's_user_vipcard_info', params: { id: id }})
    },
    // 操作
    operation(row) {
      console.log('🚀 ~ file: index.vue ~ line 515 ~ operation ~ row', row)
      this.dialog.operation = true
      this.dialog.row = row
    },
    // 自行请假配置弹窗
    operation_ask_setting() {
      getUserMembershipCardDetails(this.dialog.row.id).then(res => {
        this.ask_setting_form = res.data
        this.dialog.ask_off_setting = true
      }).catch(() => {})
    },
    // 自行请假配置提交
    ask_setting_submit() {
      this.$refs.ruleAskSettingForm.validate(valid => {
        if (valid) {
          const data = {
            leave_number: this.ask_setting_form.leave_number,
            leave_day: this.ask_setting_form.leave_day,
            leave: this.ask_setting_form.leave
          }
          editUserMembershipCard(this.ask_setting_form.id, data).then(res => {
            this.$message.success(res.msg)
            this.dialog.ask_off = false
          }).catch(() => {})
        }
      })
    },
    // 操作回调
    operation_callback(msg) {
      this.$message.success(msg)
      this.getList()
      this.dialog.operation = false
    },
    handleMemberID(val) {
      this.listQueryParams.user_id = val
    },
    handleSelectionChange(val) {
      const arr = []
      val.forEach(item => {
        arr.push(item.id)
      })
      this.multipleSelection = arr
    },
    handleDeleteAll() {
      this.$confirm('此操作将永久删除该会员卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.buttonLoading = true
        deleteMembershipCardAll({ id: this.multipleSelection })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
          .finally(() => {
            this.buttonLoading = false
          })
      })
    },
    handleOperationAll() {
      this.modifyPointsVisible = true
    },
    closeDialog() {
      this.modifyPointsVisible = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.tips{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 15px;
    border: 1px dashed #f49352;
    color: #f49352;
    min-height: 25px;
    line-height: 25px;
}
.el-avatar{
  background: none;
}
</style>
