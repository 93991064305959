var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          !_vm.is_view
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-edit" },
                  on: { click: _vm.viewChange }
                },
                [_vm._v("编辑")]
              )
            : _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-back" },
                  on: { click: _vm.viewChange }
                },
                [_vm._v("返回")]
              )
        ],
        1
      ),
      _vm._l(_vm.tableData.list, function(item, index) {
        return _c(
          "div",
          { key: index },
          [
            _vm._l(_vm.tableData.field_list, function(items, indexs) {
              return _c("div", { key: indexs }, [
                index === indexs
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "p-y-8 p-x-10 title h3 m-b-10 m-t-20 content"
                      },
                      [_vm._v(_vm._s(items))]
                    )
                  : _vm._e()
              ])
            }),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: item, "show-header": false }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "name", label: "通知业务提醒", width: "240" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [_vm._v(" " + _vm._s(row.zh_name) + " ")]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "template",
                    label: "模版内容",
                    "min-width": "500"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(row.template) }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: { prop: "status", label: "状态" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm.is_view
                              ? [
                                  _c("el-switch", {
                                    staticClass: "m-r-10",
                                    attrs: {
                                      "active-color": "#13ce66",
                                      "active-value": 1,
                                      "inactive-value": 0
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.rowChange(row)
                                      }
                                    },
                                    model: {
                                      value: row.status,
                                      callback: function($$v) {
                                        _vm.$set(row, "status", $$v)
                                      },
                                      expression: "row.status"
                                    }
                                  })
                                ]
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(row.status ? "已开启" : "已关闭")
                                  )
                                ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }