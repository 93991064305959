<template>
  <el-form
    ref="ruleForm"
    v-loading="formLoading"
    :rules="rules"
    label-position="left"
    :model="form"
    label-width="100px"
  >
    <el-form-item label="草稿箱列表" prop="draft_id">
      <el-radio-group v-model="form.draft_id" class="flex flex-col col-top">
        <el-radio v-for="(item, index) in formData.draft_list" :key="index" class="m-b-10" :label="item.draft_id">
          <span>{{ item.source_miniprogram }}</span>
          -
          <span>{{ item.user_desc }}</span>
          -
          <span class="text-info">{{ item.user_version }}</span>
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button class="m-t-10" type="primary" :loading="buttonLoading" @click="submitForm('ruleForm')">立即创建</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// API
import { getDrafts, postDrafts } from '@/api/admin/merchants.js'

export default {
  data() {
    return {
      form: {
        draft_id: ''
      },
      rules: {
        draft_id: [{ required: true, message: '请选择草稿', trigger: 'blur' }]
      },
      formData: {
        draft_list: [],
        errcode: 0,
        errmsg: ''
      },
      formLoading: false,
      buttonLoading: false
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.formLoading = true
      getDrafts()
        .then(res => {
          this.formData = res.data
        })
        .finally(() => {
          this.formLoading = false
        })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          postDrafts({ draft_id: this.form.draft_id })
            .then(res => {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.$refs[formName].resetFields()
              this.getList()
            })
            .finally(() => {
              this.buttonLoading = false
            })
          console.log(this.form)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
