<template>
  <div class="slide_footer">
    <div class="flex row-center slide_footer_box text-center">
      <div class="slide_item"><el-image style="width: 80px; height: 80px" :src="img1" alt="" :preview-src-list="[img1]" /><div>官方微信</div></div>
      <div class="slide_item"><el-image style="width: 80px; height: 80px" :src="img2" alt="" :preview-src-list="[img2]" /><div>手机端APP</div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideFooter',
  data() {
    return {
      img1: require('@/assets/images/qrcode-wx.png'),
      img2: require('@/assets/images/qrcode-app.png')
    }
  },
  mounted() {

  },
  methods: {

  }

}
</script>

<style lang="scss" scoped>
.slide_footer{
  margin-bottom: 0px;
  ::v-deep{
    .el-image__preview{
      border-radius: 4px;
    }
  }

  img{
      width: 80px;
      height: 80px;
      border-radius: 4px;
    }
  .slide_item{
    img{
      margin-bottom: 3px;
    }
    div{
      text-align: center;
      color:#999;
      font-size: 13px;
      padding-top:3px;
    }
    padding: 10px;
  }
  /* height: 200px; */
  // background: red;
  color: #fff;
  padding-top: 10px;

}
.hideSidebar .slide_footer{
  display: none;
}
.openSidebar .slide_footer{
  display: block;
}
</style>
