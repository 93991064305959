<template>
  <!-- 时间控制器 -->
  <div class="text-center time_selector">
    <el-button type="primary" icon="el-icon-arrow-left" class="time_switch" title="上一天" circle @click="btnNext(false)" />
    <el-date-picker
      v-model="currentTime"
      align="right"
      type="date"
      placeholder="选择日期"
      :picker-options="pickerOptions"
      :default-value="default_time"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      :clearable="false"
      @change="changeTime"
    />
    <el-button type="primary" class="time_switch" title="下一天" circle icon="el-icon-arrow-right" @click="btnNext(true)" />
    <el-button :class="{disable:!isshowday}" type="success" class="time_switch time_today" title="返回今天" @click="btnNext(true,1)">返回今天</el-button>
  </div>
</template>

<script>
import Utils from '@/utils/utils.js'
export default {
  name: 'Timeselect',
  props: {
    dateTime: {
      type: String,
      default: Utils.getDayTime()
    }
  },
  data() {
    return {
      // 默认年月日
      default_time: Utils.getDayTime(),
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      currentTime: this.dateTime,
      isshowday: this.dateTime !== Utils.getDayTime()
    }
  },
  methods: {
    /**
     * 切换时间
     */
    btnNext(a, day) {
      // 按钮禁用
      if (!this.isshowday && day) {
        return false
      }
      if (day) {
        this.currentTime = Utils.getDayTime()
      } else {
        const t = a ? '1' : '-1'
        this.currentTime = Utils.addDate(this.currentTime, t)
      }
      this.isshowday = this.currentTime !== Utils.getDayTime()
      this.$emit('updateTime', this.currentTime)
    },
    changeTime(v) {
      this.currentTime = v
      this.isshowday = this.currentTime !== Utils.getDayTime()
      this.$emit('updateTime', this.currentTime)
    }
  }
}
</script>

<style lang="scss" scoped>
.time_selector{
  margin-bottom: 50px;
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 140px !important;
  }

  .time_switch{
    margin: 0 10px;
  }
  .time_today{
    margin:10px 0;
  }

  .disable{
    opacity: 0;
    cursor: auto;
  }
}
</style>
