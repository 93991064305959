var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
        [
          _c(
            "div",
            { staticClass: "tx_ing" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户头像" } },
                [
                  _c(
                    "el-upload",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.upLoading,
                          expression: "upLoading"
                        }
                      ],
                      staticClass: "images-uploader",
                      attrs: {
                        action: _vm.storeUpload,
                        "show-file-list": false,
                        "on-success": _vm.upSuccess,
                        headers: _vm.utils.upload_headers(),
                        "on-progress": _vm.upProgress,
                        accept: ".jpg,.jpeg,.png"
                      }
                    },
                    [
                      _vm.form.virtual_info.avatar
                        ? _c("img", {
                            staticClass: "images",
                            attrs: {
                              src: _vm.form.virtual_info.avatar,
                              title: "点击上传图片"
                            }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus images-uploader-icon",
                            staticStyle: { border: "1px dashed #d9d9d9" }
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "tx_imgButton",
                  attrs: { icon: "el-icon-refresh" },
                  on: { click: _vm.handleGetUsername }
                },
                [_vm._v("随机")]
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户名" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200" },
                        attrs: { placeholder: "用户名" },
                        model: {
                          value: _vm.form.virtual_info.username,
                          callback: function($$v) {
                            _vm.$set(_vm.form.virtual_info, "username", $$v)
                          },
                          expression: "form.virtual_info.username"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择评论类型" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.form.type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: 10 } }, [
                    _vm._v("课程")
                  ]),
                  _c("el-radio-button", { attrs: { label: 20 } }, [
                    _vm._v("售卡")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.form.type === 10
            ? _c("course", {
                attrs: {
                  id: _vm.form.course_id,
                  type: _vm.type,
                  timer: _vm.timer
                },
                on: { courseId: _vm.courseId }
              })
            : _vm._e(),
          _vm.form.type === 20
            ? _c("card", {
                attrs: { id: _vm.form.goods_id, timer: _vm.timer },
                on: { cardId: _vm.cardId }
              })
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "点赞数量" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form.favour_number,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "favour_number", $$v)
                          },
                          expression: "form.favour_number"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "评分" } },
            [
              _c("el-rate", {
                staticStyle: { "padding-top": "6px" },
                attrs: {
                  "show-text": "",
                  colors: _vm.colors,
                  texts: _vm.texts
                },
                model: {
                  value: _vm.star_level,
                  callback: function($$v) {
                    _vm.star_level = $$v
                  },
                  expression: "star_level"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "评论内容" } }, [
            _c(
              "div",
              _vm._l(_vm.tagList, function(item, index) {
                return _c(
                  "el-tag",
                  {
                    key: index,
                    staticClass: "elTag cursor",
                    attrs: { type: item.isCheck === true ? "primary" : "info" },
                    on: {
                      click: function($event) {
                        return _vm.checkItem(item)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              1
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "评论" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: "请输入评论内容"
                },
                model: {
                  value: _vm.form.content,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "评论图" } },
            [
              _c(
                "el-upload",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.upLoading,
                      expression: "upLoading"
                    }
                  ],
                  attrs: {
                    action: _vm.storeUpload,
                    "list-type": "picture-card",
                    "on-preview": _vm.handlePreviews,
                    "on-remove": _vm.handleRemoves,
                    "on-success": _vm.upSuccesss,
                    headers: _vm.utils.upload_headers(),
                    "file-list": _vm.fileLists,
                    limit: 9,
                    "on-exceed": _vm.handleExceeds,
                    "on-progress": _vm.upProgresss,
                    accept: ".jpg,.jpeg,.png"
                  }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _c(
                "el-dialog",
                {
                  staticClass: "dialog_view_image",
                  attrs: { visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticClass: "onSubmit",
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("立即创建")]
              ),
              _c(
                "router-link",
                { attrs: { to: { name: "s_commentsVirtual_list" } } },
                [_c("el-button", [_vm._v("取消")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }