<template>
  <div class="box p-20">
    <div class=" rela">
      <el-menu :default-active="key" class="el-menu-demo" mode="horizontal" :router="true">
        <el-menu-item v-show="btn_access('s_orderRuleForCommon')" index="/store/setting/orderRule/orderRuleForCommon">{{ $t('route.s_orderRuleForCommon') }}</el-menu-item>
        <el-menu-item v-show="btn_access('s_orderRuleForLeague')" index="/store/setting/orderRule/orderRuleForLeague">{{ $t('route.s_orderRuleForLeague') }}</el-menu-item>
        <el-menu-item v-show="btn_access('s_orderRuleForFine')" index="/store/setting/orderRule/orderRuleForFine">{{ $t('route.s_orderRuleForFine') }}</el-menu-item>
        <el-menu-item v-show="btn_access('s_orderRuleForPrivate')" index="/store/setting/orderRule/orderRuleForPrivate">{{ $t('route.s_orderRuleForPrivate') }}</el-menu-item>
      </el-menu>
      <div class="p-y-10">
        <router-view :key="key" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.box{
    background-color: #ffffff;
    ::v-deep .title{
    border-bottom:1px solid #dfe6ec;
    color: #303133;
    font-weight: 600;
    }
}

</style>

<style lang="scss" scoped>
.table-close {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #dfe6ec;
  .table-title,
  .table-value {
    display: inline-block;
    line-height: 20px;
    padding: 10px 10px;
  }
  .table-title {
    width: 180px;
  }
}
</style>
