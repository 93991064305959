<template>
  <div class="app-container">
    <div class="toolbar">
      <el-button v-show="btn_access('system_notice_add')" type="primary" icon="el-icon-plus" @click="toPage()">添加</el-button>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData.list"
      row-key="id"
      class="el-table--small"
    >
      <el-table-column prop="id" label="ID" width="180" />
      <el-table-column prop="title" label="标题" min-width="150px" />
      <el-table-column prop="is_hidden" label="是否可见" width="150px">
        <template slot-scope="{row: {is_hidden}}">
          <el-tag v-if="is_hidden === 1" type="info" effect="dark" class="br0_tag">否</el-tag>
          <el-tag v-else type="success" effect="dark" class="br0_tag">是</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="weigh" label="排序" width="150px" />
      <el-table-column prop="create_time" label="创建时间" width="200" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('system_notice_edit')" class="m-2" type="primary" icon="el-icon-edit" @click="toPage(scope.row.id)" />
          <delPopover v-show="btn_access('system_notice_del')" class="m-2" :list-length="tableData.list.length" :api-data="apiData" :page.sync="listQuery.page" :row="scope.row" btn-msg="" @delete="getList" />
          <!-- <el-button :type="scope.row.is_disable ? 'success':'info'" @click="appDR(scope.row)">{{scope.row.is_disable ? '启用':'禁用'}}</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>

</template>

<script>
import { getNoticeList } from '@/api/admin/system.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import delPopover from '@/components/All/delPopover'
export default {
  components: { Pagination, delPopover },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      apiData: { url: '/v1/notice' }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /**
			 * 打开弹窗
			 */
    dialogVisible(id) {
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getNoticeList(data).then(res => {
        this.tableData = res.data
      }).catch(() => {})
    },
    toPage(id) {
      if (id) { this.$router.push({ name: 'EditAnnouncement', params: { id: id }}) } else { this.$router.push({ name: 'AddAnnouncement' }) }
      // this.$router.push({path:`edit/${id}`});
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
