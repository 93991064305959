<template>
  <div>
    <el-button v-show="btn_access('s_user_fianco_add')" type="primary" icon="el-icon-plus" class="fr m-b-10" @click="$router.push({ name: 's_user_fianco_add', params: { id: userId }})">新增</el-button>
    <el-table
      :data="tableData.list"
      stripe
    >
      <el-table-column prop="create_time" label="体测时间" min-width="120" />
      <el-table-column prop="content" label="身高">
        <template slot-scope="{row}">
          {{ row.content.height.value?row.content.height.value:'--' }}{{ row.content.height.value?row.content.height.unit:'' }}
        </template>
      </el-table-column>
      <el-table-column prop="content" label="体重">
        <template slot-scope="{row}">
          {{ row.content.weight.value?row.content.weight.value:'--' }}{{ row.content.weight.value?row.content.weight.unit:'' }}
        </template>
      </el-table-column>
      <el-table-column prop="content" label="体脂率">
        <template slot-scope="{row}">
          {{ row.content.bodyfat.value?row.content.bodyfat.value:'--' }}{{ row.content.bodyfat.value?row.content.bodyfat.unit:'' }}
        </template>
      </el-table-column>
      <el-table-column prop="content" label="BMI">
        <template slot-scope="{row}">
          {{ row.content.bmi.value?row.content.bmi.value:'--' }}{{ row.content.bmi.value?row.content.bmi.unit:'' }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="体型">
        <template slot-scope="{row}">
          {{ row.content.shape.value?row.content.shape.value:'--' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="180">
        <template slot-scope="scope">
          <el-button v-show="btn_access('s_user_side_info')" type="primary" icon="el-icon-info" :offset="2" class="m-r-10" @click="fianco_info(scope.row.id)">详情</el-button>
          <el-popconfirm
            title="确定要删除该条体测记录吗？"
            @confirm="fianco_del(scope.row.id)"
          >
            <el-button v-show="btn_access('s_user_side_del')" slot="reference" type="danger" icon="el-icon-delete" :offset="2">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { getUserSide, deleteUserSide } from '@/api/store/fianco.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  name: 'UserFianco',
  components: { Pagination },
  props: {
    userId: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    }
  },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getUserSide(this.userId).then(res => {
        const { data } = res
        this.tableData = data
      }).catch(() => {})
    },
    fianco_info(id) {
      this.$router.push({ name: 's_user_fianco_edit', params: { id: id }})
    },
    fianco_del(id) {
      deleteUserSide(id).then(res => {
        this.$message.success('删除成功')
        this.getList()
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
