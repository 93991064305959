<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleAccount"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
      :show-close="false"
      @open="handleOpen"
      @close="handleClose"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">拼团活动详情</div>
          <div class="title_icon cursor" @click="handleClose">
            <i class="el-icon-close" />
          </div>
        </div>
      </div>
      <div>
        <p><b>活动信息</b></p>
        <el-descriptions v-if="teamworkData" class="margin-top" :column="2" border :label-style="{ width: '100px' }">
          <el-descriptions-item :span="2">
            <template slot="label">
              封面图
            </template>
            <el-image
              v-if="teamworkData.cover"
              style="min-width: 120px; height: 120px"
              :src="teamworkData.cover"
              fit="fill"
            />
            <div v-else>暂无封面图</div>
          </el-descriptions-item>
          <el-descriptions-item :span="2">
            <template slot="label">
              商品图
            </template>
            <div v-if="teamworkData.goods_images && teamworkData.goods_images.length > 0">
              <el-image
                v-for="(item, index) in teamworkData.goods_images"
                :key="index"
                class="m-6"
                style="min-width: 100px; height: 100px"
                :src="item"
                :preview-src-list="[item]"
                fit="fill"
              />
            </div>
            <div v-else>暂无商品图</div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              活动名称
            </template>
            {{ teamworkData.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              活动价
            </template>
            {{ teamworkData.price }} 元
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              拼团时间
            </template>
            {{ `${formatTime(teamworkData.start_time)} - ${formatTime(teamworkData.end_time)}` }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              自动成团人数
            </template>
            {{ `${teamworkData.people_number}` }} 人
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              可发起开团数
            </template>
            {{ `${teamworkData.inventory === 0 ? '无限制' : teamworkData.inventory + ' 个'}` }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              自动成团天数
            </template>
            {{ `${teamworkData.validity_day === 0 ? '无' : teamworkData.validity_day + '天'}` }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              购买人
            </template>
            <div v-if="teamworkData.buy_identity">
              <el-tag v-if="teamworkData.buy_identity.indexOf('member') !== -1" class="m-x-2">会员</el-tag>
              <el-tag v-if="teamworkData.buy_identity.indexOf('visitor') !== -1" class="m-x-2">团课</el-tag>
            </div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              包含卡
            </template>
            <!-- <span v-if="teamworkData.membership_card_id">
          {{
            teamworkData.membership_card_id.length === 0
              ? '暂无会员卡'
              : `${teamworkData.membership_card_id.length} 张会员卡`
          }}
        </span>
        <span>+</span> -->
            <span v-if="teamworkData.card_combo_id">{{ teamworkData.card_combo ? teamworkData.card_combo.name + ' 组合卡' : '组合卡已被删除' }}</span>
            <span v-else-if="teamworkData.membership_card_id">
              {{ teamworkData.membership_card_id.length + ' 张会员卡' }}
            </span>
          </el-descriptions-item>
          <el-descriptions-item
            v-if="teamworkData.membership_card_id && teamworkData.membership_card_id.length !== 0"
            :span="2"
          >
            <template slot="label">
              会员卡
            </template>
            <el-link
              v-for="(item, index) in teamworkData.card"
              :key="index"
              class="color m-x-6"
              target="_blank"
              @click="handleToPage(item.id)"
            >
              {{ item.name }}
            </el-link>
          </el-descriptions-item>
          <el-descriptions-item :span="2">
            <template slot="label">
              拼团详情
            </template>
            <div>{{ `共 ${teamworkData.user_record_count} 人参团 / 销售额 ${teamworkData.user_record_price_sum} 元` }}</div>
          </el-descriptions-item>
        </el-descriptions>
        <p class="font-16">
          <b>
            已成团
            <b class="text-success">{{ teamworkData.group_completed_count }}</b>
            个 / 拼团中
            <b class="text-warning">{{ teamworkData.group_undone_count }}</b>
            个 /
            <!-- <b class="text-success">{{ teamworkData.remain_inventory_num === 0 ? '无限制开团个数' : teamworkData.remain_inventory_num + ' 个' }}</b> -->
            <b class="text-success">{{ `${teamworkData.inventory === 0 ? '不限制开团个数' : '可开团 ' + teamworkData.inventory + ' 个'}` }}</b>
          </b>
        </p>
        <el-tabs v-model="activeName">
          <el-tab-pane label="全部" name="whole" />
          <el-tab-pane label="拼团中" name="inProgress" />
          <el-tab-pane label="已成团" name="end" />
        </el-tabs>
        <!-- 表格 -->
        <el-table ref="multipleTable" v-loading="TableLoading" :data="teamList.list" row-key="id" header-align="center">
          <el-table-column label="团队名称" align="center">
            <template slot-scope="{ row }">
              <div>{{ getUserNames(row.user) }} 的拼团</div>
            </template>
          </el-table-column>
          <el-table-column label="拼团人数" align="center">
            <template slot-scope="{ row }">
              <el-tag type="warning">
                <span>{{ row.member.length }}</span>
                /
                <span>{{ row.people_num }}</span>
                人团
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="拼团状态" align="center">
            <template slot-scope="{ row }">
              <el-tag v-if="row.status === 0" type="warning">拼团中</el-tag>
              <el-tag v-if="row.status === 1" type="success">已成团</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="建团时间" align="center" />
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button class="m-2" @click="handleEventDetails(row.id)">
                详 情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 会员卡详情 -->
    <Dialog :id="membership_card_id" :venue="venuesId" :dialog="dialogVisibleMembership" @Close="Close" />
    <el-dialog title="拼团活动详情" :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" width="40%" @open="handleOpenSon">
      <div v-loading="teamLoading">
        <p>
          <b>
            <el-tag class="m-r-6" :type="teamDetails.status === 0 ? 'warning' : 'success'">
              {{ teamDetails.status === 0 ? '拼团中' : '已成团' }}
            </el-tag>
            ( 共{{ teamDetails.people_num }} 人 / 金额 {{ teamDetails.team_money_sum }} 元)
          </b>
        </p>
        <!-- 表格 -->
        <el-table ref="multipleTable" :data="teamDetails.member" row-key="id" header-align="center">
          <el-table-column label="用户">
            <template slot-scope="{ row }">
              <div v-if="row" class="flex col-center cursor" @click="toUserInfoPage(row.user_id)">
                <div>
                  <el-avatar shape="square" :size="50" :src="row.avatar">
                    <img :src="errorAvatarSrc(row, 'img')">
                  </el-avatar>
                </div>
                <div class="p-l-10">
                  <span>{{ getUserNames(row) }}</span>
                  <el-tag v-if="row.standing === 1" type="warning" class="m-l-6">团长</el-tag>
                  <el-tag v-if="row.standing === 2" type="success" class="m-l-6">团员</el-tag>
                  <br>
                  <span>{{ row.phone ? row.phone : '--' }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="支付金额">
            <template slot-scope="{ row }">{{ row.price }} 元</template>
          </el-table-column>
          <el-table-column prop="create_time" label="建团时间" />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getTeamworkDetails, getTeamList, getTeamDetails } from '@/api/store/teamwork.js'
// 组件
import Dialog from '@/views/store/user/vipCard/components/Dialog.vue'

export default {
  components: { Dialog },
  props: {
    dialogVisibleAccount: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeName: 'whole',
      teamworkData: {},
      teamList: {
        count: 0,
        list: []
      },
      teamDetails: {},
      TableLoading: false,
      teamLoading: false,
      teamId: 0,
      dialogVisible: false,
      dialogVisibleMembership: false,
      membership_card_id: 0,
      venuesId: 0
    }
  },
  watch: {
    activeName(val) {
      this.getList()
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.id = this.typeId
      // 拼团活动详情
      getTeamworkDetails(this.id).then(res => {
        const { data } = res
        this.teamworkData = data
        this.venuesId = data.venues_id
      })
      this.getList()
    },
    // 关闭的回调
    handleClose() {
      this.$emit('roleVisible')
    },
    getList() {
      // 团队列表
      this.TableLoading = true
      getTeamList(this.id)
        .then(res => {
          const { data } = res
          if (this.activeName === 'inProgress') data.list = data.list.filter(item => item.status === 0)
          else if (this.activeName === 'end') data.list = data.list.filter(item => item.status === 1)
          this.teamList = data
        })
        .finally(() => {
          this.TableLoading = false
        })
    },
    handleEventDetails(id) {
      this.teamId = id
      this.dialogVisible = true
    },
    // 窗口打开的回调
    handleOpenSon() {
      this.teamLoading = true
      getTeamDetails(this.id, this.teamId).then(res => {
        const { data } = res
        this.teamLoading = false
        this.teamDetails = data
      })
    },
    handleToPage(membership_card_id) {
      this.dialogVisibleMembership = true
      this.membership_card_id = membership_card_id
    },
    Close() {
      this.dialogVisibleMembership = false
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tree-height {
  height: 60vh;
  overflow-y: auto;

}
.color {
  color: rgb(24, 144, 255);
  /* padding-bottom: 3px;
  border-bottom: 1px solid rgb(24, 144, 255); */
}
</style>
