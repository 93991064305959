<template>
  <div class="box p-20">
    <div class=" rela">
      <el-menu :default-active="key" class="el-menu-demo" mode="horizontal" :router="true">
        <el-menu-item v-show="btn_access('s_record_group_list')" index="/store/work/console/group">团课预约记录</el-menu-item>
        <el-menu-item v-show="btn_access('s_record_group_list')" index="/store/work/console/boutique">精品课程预约记录</el-menu-item>
        <el-menu-item v-show="btn_access('s_record_private_list')" index="/store/work/console/private">私教预约记录</el-menu-item>
        <el-menu-item index="/store/work/console/class">班课签到</el-menu-item>
        <el-menu-item index="/store/work/console/inquire">客询管理</el-menu-item>
        <!-- <el-menu-item index="/store/work/console/returnVisit">回访管理</el-menu-item> -->
        <el-menu-item index="/store/work/console/leaveManagement">请假管理</el-menu-item>
        <el-menu-item v-show="btn_access('s_workbench_promise_penalty')" index="/store/work/console/penalty">爽约惩罚
        </el-menu-item>
        <el-menu-item index="/store/work/console/locker">储物柜</el-menu-item>
      </el-menu>
      <div class="p-y-10">
        <router-view :key="key" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  beforeDestroy() {
    const route = this.$route.name
    if (route === 's_work_group' || route === 's_work_boutique' || route === 's_work_private' || route === 's_work_class') return true
    else localStorage.removeItem('dateTime')
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.box {
  background-color: #ffffff;

  ::v-deep .title {
    border-bottom: 1px solid #dfe6ec;
    color: #303133;
    font-weight: 600;
  }
}
</style>
