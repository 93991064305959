<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="团课课时费设置" name="group">
        <!-- 列表 -->
        <el-table
          ref="multipleTable"
          v-loading="listLoading"
          :data="tableData.list"
          row-key="id"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div v-for="item in props.row.class_fee" :key="item.id" class="class_fee_bg">
                <el-row :gutter="20">
                  <el-col :span="5" :offset="0">教练姓名:{{ item.store_user?item.store_user.real_name:'--' }}</el-col>
                  <el-col :span="7" :offset="0">课时费:{{ item.fee }}元/节</el-col>
                  <el-col :span="6" :offset="0">
                    <el-button v-show="btn_access('s_classfee_edit')" type="text" icon="el-icon-edit" :offset="2" @click="add_edit(props.row,item)">编辑</el-button>
                    <el-button v-show="btn_access('s_classfee_delete')" type="text" icon="el-icon-delete" :offset="2" @click="classfee_del(item.id)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="id" label="ID" /> -->
          <el-table-column prop="name" label="课程名" />
          <!-- <el-table-column prop="galleryful" label="容纳人数" />
      <el-table-column prop="duration" label="课时费用" /> -->
          <!-- <el-table-column prop="create_time" label="创建时间" /> -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button v-show="btn_access('s_classfee_add')" type="primary" icon="el-icon-plus" :offset="2" @click="add_edit(scope.row)">新增</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
      </el-tab-pane>
      <el-tab-pane label="精品课课时费设置" name="fine">
        <!-- 列表 -->
        <el-table
          ref="multipleTable"
          v-loading="listLoading"
          :data="tableData.list"
          row-key="id"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div v-for="item in props.row.class_fee" :key="item.id" class="class_fee_bg">
                <el-row :gutter="20">
                  <el-col :span="5" :offset="0">教练姓名:{{ item.store_user?item.store_user.real_name:'--' }}</el-col>
                  <el-col :span="7" :offset="0">课时费:{{ item.fee }}元/节</el-col>
                  <el-col :span="6" :offset="0">
                    <el-button v-show="btn_access('s_classfee_edit')" type="text" icon="el-icon-edit" :offset="2" @click="add_edit(props.row,item)">编辑</el-button>
                    <el-button v-show="btn_access('s_classfee_delete')" type="text" icon="el-icon-delete" :offset="2" @click="classfee_del(item.id)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="id" label="ID" /> -->
          <el-table-column prop="name" label="课程名" />
          <!-- <el-table-column prop="galleryful" label="容纳人数" />
      <el-table-column prop="duration" label="课时费用" /> -->
          <!-- <el-table-column prop="create_time" label="创建时间" /> -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button v-show="btn_access('s_classfee_add')" type="primary" icon="el-icon-plus" :offset="2" @click="add_edit(scope.row)">新增</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
      </el-tab-pane>
      <el-tab-pane label="私教课时费设置" name="private">
        <!-- 列表 -->
        <el-table
          ref="multipleTable"
          v-loading="listLoading"
          :data="tableData.list"
          row-key="id"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div v-for="item in props.row.class_fee" :key="item.id" class="class_fee_bg">
                <el-row :gutter="20">
                  <el-col :span="5" :offset="0">教练姓名:{{ item.store_user?item.store_user.real_name:'--' }}</el-col>
                  <el-col :span="7" :offset="0">课时费:{{ item.fee }}元/节</el-col>
                  <el-col :span="6" :offset="0">
                    <el-button v-show="btn_access('s_classfee_edit')" type="text" icon="el-icon-edit" :offset="2" @click="add_edit(props.row,item)">编辑</el-button>
                    <el-button v-show="btn_access('s_classfee_delete')" type="text" icon="el-icon-delete" :offset="2" @click="classfee_del(item.id)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="id" label="ID" /> -->
          <el-table-column prop="name" label="课程名" />
          <!-- <el-table-column prop="galleryful" label="容纳人数" />
      <el-table-column prop="duration" label="课时费用" /> -->
          <!-- <el-table-column prop="create_time" label="创建时间" /> -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button v-show="btn_access('s_classfee_add')" type="primary" icon="el-icon-plus" :offset="2" @click="add_edit(scope.row)">新增</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
      </el-tab-pane>
    </el-tabs>

    <!-- 添加、编辑弹窗 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.id ? '编辑课时费' : '添加课时费'"
      width="24%"
      class="dialog_w580"
      @close="handleClose"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="场馆" prop="venues_id">
          <el-select
            v-model="form.venues_id"
            placeholder="请选择场馆"
            filterable
            remote
            reserve-keyword
            :remote-method="queryVenuesSearch"
            :disabled="true"
            style="width: 300px;"
          >
            <el-option
              v-for="item in venuesOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="课程" prop="course_id">
          <el-input v-model="course_name" :disabled="true" style="width: 300px;" />
        </el-form-item>
        <el-form-item label="员工" prop="store_user_id">
          <Select ref="staffRef" :width="300" :type="4" @changeId="handleCoachID" />
        </el-form-item>
        <el-form-item label="费用" prop="fee">
          <el-input v-model="form.fee" placeholder="请填写课时费用" style="width: 300px;" type="number">
            <template slot="append">元/节</template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">
          {{ dialog.id ? '保存' : '新增' }}
        </el-button>
        <el-button @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClassFee, addClassFee, editClassFee, delClassFee } from '@/api/store/course.js'
import { getVenues } from '@/api/store/venues.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
// 组件
import Select from '@/components/elSelect/Select'
export default {
  components: { Pagination, Select },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      listLoading: false,
      is_edit: false,
      dialog: { visible: false, id: '' },
      form: {
        venues_id: this.utils.getVenues().id
      },
      rules: {
        venues_id: [
          { required: true, message: '请选择场馆', trigger: 'change' }
        ],
        course_id: [
          { required: true, message: '请选择课程', trigger: 'change' }
        ],
        store_user_id: [
          { required: true, message: '请选择员工', trigger: 'change' }
        ],
        fee: [
          { required: true, message: '填写课时费', trigger: 'blur' }
        ]
      },
      venuesOptions: [],
      course_name: '',
      expandRowKeys: [0, 1, 2],
      activeName: 'group'
    }
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取列表
    getList() {
      this.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      data.type = this.activeName === 'group' ? 0 : this.activeName === 'private' ? 1 : 2
      getClassFee(data).then(res => {
        this.tableData = res.data
        this.listLoading = false
      }).catch(() => {})
    },
    add_edit(row, class_fee) {
      console.log('🚀 ~ file: index.vue:264 ~ add_edit ~ row:', row, class_fee)
      // 加载列表
      this.getVenuesList()
      // 赋值
      this.course_name = row.name
      this.is_edit = false
      this.dialog.visible = true
      this.dialog.id = class_fee ? class_fee.id : ''
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
      if (class_fee) {
        this.is_edit = true
        this.form = {
          venues_id: this.utils.getVenues().id,
          store_user_id: class_fee.store_user_id,
          course_id: row.id,
          fee: class_fee.fee
        }
        this.$refs.staffRef.refreshGetList(class_fee.store_user.real_name)
      } else {
        this.dialog.id = 0
        this.form = {
          venues_id: this.utils.getVenues().id,
          course_id: row.id
        }
      }
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.is_edit) {
            const data = { fee: this.form.fee, store_user_id: this.form.store_user_id }
            editClassFee(this.dialog.id, data).then(res => {
              this.dialog.visible = false
              this.$message.success(res.msg)
              // 重载列表
              this.getList()
            }).catch(() => {})
          } else {
            const data = this.form
            addClassFee(data).then(res => {
              this.dialog.visible = false
              this.$message.success(res.msg)
              // 重载列表
              this.getList()
            }).catch(() => {})
          }
        }
      })
    },
    // 获取场馆列表
    getVenuesList(query) {
      const data = query ? this.utils.getQueryParam({ name: '%' + query + '%' }) : {}
      data.limit = 9999
      getVenues(data).then(res => {
        this.venuesOptions = res.data.list
      }).catch(() => {})
    },
    queryVenuesSearch(query) {
      this.getVenuesList(query)
    },
    // 删除
    classfee_del(id) {
      this.$confirm('确定删除该条记录吗？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delClassFee(id).then(res => {
          this.$message.success(res.msg)
          this.getList()
        }).catch(() => {})
      }).catch(() => {

      })
    },
    handleClick(tab, event) {
      this.listQuery = {
        page: 1,
        limit: 10
      }
      this.tableData = {
        list: [],
        count: 0
      }
      this.getList()
    },
    handleCoachID(val) {
      this.form.store_user_id = val
    },
    handleClose() {
      this.$refs.staffRef.refreshGetList()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table__expanded-cell{
  background-color:#F2F6FC !important
}
</style>
