<template>
  <div class="filter-container">
    <!-- <div><span class="font-16 p-r-10">当前选中 <b class="text-success">{{ checkedUserCount }}</b> 个会员</span><el-button size="mini" type="warning" plain @click="send_sms">批量发送短信</el-button></div> -->
  </div>
</template>

<script>
export default {
  name: 'SelectSendSms',
  props: {
    checkedUserCount: {
      type: Number,
      default: 0
    },
    multipleSelection: {
      type: Array,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    send_sms() {
      if (this.multipleSelection.length < 1) {
        this.$message.error('请选择需要发送短信的用户')
        return false
      }
      // 获取当前选中
      console.info(this.multipleSelection)
      // TODO 批量发送短信
      this.$message.error('开发中')
      // console.info(this.$refs.multipleTable.tableData)
      // console.info(this.$refs.multipleTable)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
