import request from '@/utils/request'

// 查询订单
export function getUserOrderInfo(order_id, data) {
  return request({
    url: `/store/v1/user_recharge_order/${order_id}`,
    method: 'get',
    params: data
  })
}

export function getSmsOrderInfo(order_id, data) {
  return request({
    url: `/store/v1/sms_recharge_order/${order_id}`,
    method: 'get',
    params: data
  })
}

