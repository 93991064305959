var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.utils.getVenues()
            ? _c(
                "div",
                { staticClass: "right-menu-item m-r-10 notice " },
                [_c("HeaderNotice")],
                1
              )
            : _vm._e(),
          _vm.device !== "mobile"
            ? [
                _vm.utils.getVenues()
                  ? _c(
                      "div",
                      {
                        staticClass: "right-menu-item venues_name",
                        on: { click: _vm.switchVenues }
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "切换场馆",
                              placement: "bottom"
                            }
                          },
                          [
                            _vm.utils.getVenues().id != -1
                              ? _c(
                                  "span",
                                  [
                                    _vm._v(" 当前馆： "),
                                    _c(
                                      "el-link",
                                      { attrs: { type: "primary", href: "#" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.utils.getVenues().name)
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c("el-link", [_vm._v("品牌管理")])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect"
                })
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "flex avatar-wrapper m-r-10" }, [
                _vm.avatar
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" }
                    })
                  : _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.defaultLogo }
                    }),
                _vm.getAuthority() === "admin"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "user-name",
                          attrs: { title: "管理员" }
                        },
                        [_vm._v("管理员")]
                      )
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "user-name",
                          attrs: {
                            title: _vm.userinfo.storeInfo.real_name
                              ? _vm.userinfo.storeInfo.real_name
                              : _vm.userinfo.account
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.userinfo.storeInfo.real_name
                                  ? _vm.userinfo.storeInfo.real_name
                                  : _vm.userinfo.account
                              ) +
                              " "
                          )
                        ]
                      )
                    ])
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          _vm.getAuthority() !== "admin"
                            ? "/profile/index"
                            : "/profileAdmin/index"
                      }
                    },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(" " + _vm._s(_vm.$t("navbar.profile")) + " ")
                      ])
                    ],
                    1
                  ),
                  _vm.getAuthority() !== "admin"
                    ? _c(
                        "router-link",
                        { attrs: { to: "/profile/index?resetpass=2" } },
                        [
                          _c("el-dropdown-item", [
                            _vm._v(" " + _vm._s(_vm.$t("navbar.phone")) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          _vm.getAuthority() !== "admin"
                            ? "/profile/index?resetpass=1"
                            : "/profileAdmin/index"
                      }
                    },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(" " + _vm._s(_vm.$t("navbar.resetPass")) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(" " + _vm._s(_vm.$t("navbar.dashboard")) + " ")
                      ])
                    ],
                    1
                  ),
                  _vm.utils.getVenues()
                    ? _c(
                        "div",
                        { on: { click: _vm.switchVenues } },
                        [
                          _c("el-dropdown-item", [
                            _c("div", [_vm._v("切换场馆")])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(_vm._s(_vm.$t("navbar.logOut")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }