var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stop_box" }, [
    _c("div", { staticClass: "text-center" }, [
      _c("div", { staticClass: "image" }, [
        _c("img", {
          staticStyle: { width: "410px", height: "260px" },
          attrs: { src: _vm.empty_pause_img }
        })
      ]),
      _c("div", { staticClass: "text1" }, [
        _vm._v(" " + _vm._s(_vm.msg) + " ")
      ]),
      _c("div", { staticClass: "text2" }, [
        _vm._v("您可以通过切换其他品牌或联系客服解决问题")
      ]),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "240px" },
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/login")
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "m-t-10" }, [
        _c("div", { staticClass: "wx_qrcode" }, [
          _c("img", {
            staticStyle: { width: "113px", height: "113px" },
            attrs: { src: _vm.wx_qrcode_img }
          })
        ]),
        _c(
          "div",
          { staticClass: "call_number" },
          [
            _c("svg-icon", { attrs: { "icon-class": "phone" } }),
            _vm._v(" " + _vm._s(_vm.callNumber) + " ")
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }