<template>
  <div>
    <section v-if="key == '/store/plan/index' || level != 1" class="app-main">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="cachedViews">
          <router-view :key="key" />
        </keep-alive>
      </transition>
    </section>
    <flagship v-else />
  </div>
</template>

<script>
// import { getAppInfo } from '@/api/store/management.js'
import { getUser } from '@/api/store/user.js'
import flagship from '@/views/error-page/603'
export default {
  name: 'AppMain',
  components: { flagship },
  data() {
    return {
      level: ''
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  },
  mounted() {
    if (this.utils.getVenues()) {
      this.level = this.utils.getApp().brand_level.level
      getUser()
        .then(res => {
          if (res) this.level = 100
          else this.level = 1
          // this.level = res.data.brand_level.level
        })
        .catch(() => {})
    } else {
      this.level = 100
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 10px;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
