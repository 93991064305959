var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-box" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-width": "120px",
            "label-position": "left"
          }
        },
        [
          _c("div", { staticClass: "setting_item" }, [
            _c("div", { staticClass: "m-y-8  p-y-5 p-x-15 title item_title" }, [
              _vm._v(" 预约时间设置 ")
            ]),
            _c(
              "div",
              { staticClass: "item_content m-y-10" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "预约方式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.group_subscribe.type,
                          callback: function($$v) {
                            _vm.$set(_vm.form.group_subscribe, "type", $$v)
                          },
                          expression: "form.group_subscribe.type"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("不支持会员自主预约")
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("自主预约，不限制预约时间")
                        ]),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("自主预约，限制预约时间")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.group_subscribe.type > 2
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "预约时间" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.group_subscribe.time_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.group_subscribe,
                                  "time_type",
                                  $$v
                                )
                              },
                              expression: "form.group_subscribe.time_type"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex flex-col col-top row-left" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass: "m-b-5",
                                        attrs: { label: 1 }
                                      },
                                      [
                                        _vm._v(" 提前 "),
                                        _c("el-input", {
                                          staticClass: "w-100 m-x-5",
                                          attrs: {
                                            size: "mini",
                                            placeholder: "不限制",
                                            type: "number"
                                          },
                                          model: {
                                            value:
                                              _vm.form.group_subscribe
                                                .before_day_1,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form.group_subscribe,
                                                "before_day_1",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.group_subscribe.before_day_1"
                                          }
                                        }),
                                        _vm._v(" 天， "),
                                        _c("el-time-picker", {
                                          staticClass: "w-250",
                                          attrs: {
                                            "is-range": "",
                                            "arrow-control": "",
                                            "range-separator": "-",
                                            "start-placeholder": "开始时间",
                                            "end-placeholder": "结束时间",
                                            placeholder: "选择时间范围",
                                            format: "HH:mm",
                                            "value-format": "HH:mm",
                                            clearable: false
                                          },
                                          model: {
                                            value:
                                              _vm.form.group_subscribe
                                                .before_start_time_1,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form.group_subscribe,
                                                "before_start_time_1",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.group_subscribe.before_start_time_1"
                                          }
                                        }),
                                        _vm._v(" 接受自主预约 ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        staticClass: "m-b-5",
                                        attrs: { label: 2 }
                                      },
                                      [
                                        _vm._v(" 提前 "),
                                        _c("el-input", {
                                          staticClass: "w-100 m-x-5",
                                          attrs: {
                                            size: "mini",
                                            placeholder: "不限制",
                                            type: "number"
                                          },
                                          model: {
                                            value:
                                              _vm.form.group_subscribe
                                                .before_day_2,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form.group_subscribe,
                                                "before_day_2",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.group_subscribe.before_day_2"
                                          }
                                        }),
                                        _vm._v(" 天， "),
                                        _c("el-time-picker", {
                                          staticClass: "w-100",
                                          attrs: {
                                            "arrow-control": "",
                                            format: "HH:mm",
                                            "value-format": "HH:mm",
                                            clearable: false
                                          },
                                          model: {
                                            value:
                                              _vm.form.group_subscribe
                                                .before_start_time_2,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form.group_subscribe,
                                                "before_start_time_2",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.group_subscribe.before_start_time_2"
                                          }
                                        }),
                                        _vm._v(" 后接受自主预约 ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "m-b-5 flex",
                                    attrs: { label: 3 }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c("div", [_vm._v("开课前")]),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "w-100 m-x-5",
                                            attrs: {
                                              prop: "before_course_minute_1"
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "w-100",
                                              attrs: {
                                                size: "mini",
                                                type: "number"
                                              },
                                              model: {
                                                value:
                                                  _vm.form.group_subscribe
                                                    .before_course_minute_1,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form.group_subscribe,
                                                    "before_course_minute_1",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.group_subscribe.before_course_minute_1"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", [
                                          _vm._v("分钟不接受自主预约")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.group_subscribe.type > 1
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "预约截止时间",
                          prop: "before_course_minute_2"
                        }
                      },
                      [
                        _vm._v(" 课程预约人数未满，会员需早于开课前 "),
                        _c("el-input", {
                          staticClass: "w-120",
                          attrs: { size: "mini", placeholder: "0" },
                          model: {
                            value:
                              _vm.form.group_subscribe.before_course_minute_2,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form.group_subscribe,
                                "before_course_minute_2",
                                $$v
                              )
                            },
                            expression:
                              "form.group_subscribe.before_course_minute_2"
                          }
                        }),
                        _vm._v(" 分钟自主预约 ")
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "setting_item" }, [
            _c("div", { staticClass: "m-y-8  p-y-5 p-x-15 title item_title" }, [
              _vm._v(" 排队候补设置 ")
            ]),
            _c(
              "div",
              { staticClass: "item_content m-y-10" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "排队候补" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.group_queue.status,
                          callback: function($$v) {
                            _vm.$set(_vm.form.group_queue, "status", $$v)
                          },
                          expression: "form.group_queue.status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启")
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("关闭")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.group_queue.status
                  ? _c("el-form-item", { attrs: { label: "候补设置" } }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-col col-top row-left" },
                        [
                          _c("div", { staticClass: "flex m-y-5" }, [
                            _c("div", [_vm._v("最大排队人数")]),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "max" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "w-100 m-x-5",
                                      attrs: {
                                        size: "mini",
                                        placeholder: "0",
                                        type: "number"
                                      },
                                      model: {
                                        value: _vm.form.group_queue.max,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.group_queue,
                                            "max",
                                            $$v
                                          )
                                        },
                                        expression: "form.group_queue.max"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "flex m-y-5" }, [
                            _c("div", [_vm._v("开课前")]),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "stop_queue_minute" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "w-100 m-x-5",
                                      attrs: {
                                        size: "mini",
                                        placeholder: "0",
                                        type: "number"
                                      },
                                      model: {
                                        value:
                                          _vm.form.group_queue
                                            .stop_queue_minute,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.group_queue,
                                            "stop_queue_minute",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.group_queue.stop_queue_minute"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("div", [_vm._v("分钟停止排队")])
                          ]),
                          _c("div", { staticClass: "flex m-y-5" }, [
                            _c("div", [_vm._v("开课前")]),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "stop_alternate_minute" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "w-100 m-x-5",
                                      attrs: {
                                        size: "mini",
                                        placeholder: "0",
                                        type: "number"
                                      },
                                      model: {
                                        value:
                                          _vm.form.group_queue
                                            .stop_alternate_minute,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.group_queue,
                                            "stop_alternate_minute",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.group_queue.stop_alternate_minute"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("div", [_vm._v("分钟停止候补")])
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "setting_item" }, [
            _c("div", { staticClass: "m-y-8  p-y-5 p-x-15 title item_title" }, [
              _vm._v(" 签到设置 ")
            ]),
            _c(
              "div",
              { staticClass: "item_content m-y-10" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "自主签到" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.group_sign.autonomously_status,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.group_sign,
                              "autonomously_status",
                              $$v
                            )
                          },
                          expression: "form.group_sign.autonomously_status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启")
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("关闭")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.group_sign.autonomously_status
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "签到时间" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.group_sign.autonomously_time_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.group_sign,
                                  "autonomously_time_type",
                                  $$v
                                )
                              },
                              expression:
                                "form.group_sign.autonomously_time_type"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex flex-col col-top row-left" },
                              [
                                _c(
                                  "el-radio",
                                  { staticClass: "m-y-5", attrs: { label: 1 } },
                                  [_vm._v("课程当天，会员可随时签到")]
                                ),
                                _c(
                                  "el-radio",
                                  { staticClass: "m-y-5", attrs: { label: 2 } },
                                  [_vm._v("课程当天，会员可在课程结束前签到")]
                                ),
                                _c(
                                  "el-radio",
                                  { staticClass: "flex", attrs: { label: 3 } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex row-left flex-wrap"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("课程当天，会员可在课程结束后")
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "after_1" } },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w-100 m-x-5",
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "",
                                                    type: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.group_sign
                                                        .after_1,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form.group_sign,
                                                        "after_1",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.group_sign.after_1"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", [_vm._v("分钟内签到")])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "扫码签到" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.group_sign.scan_code_status,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.group_sign,
                              "scan_code_status",
                              $$v
                            )
                          },
                          expression: "form.group_sign.scan_code_status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启")
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("关闭")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.group_sign.scan_code_status
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "签到时间" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.group_sign.scan_code_time_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.group_sign,
                                  "scan_code_time_type",
                                  $$v
                                )
                              },
                              expression: "form.group_sign.scan_code_time_type"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex flex-col col-top row-left" },
                              [
                                _c(
                                  "el-radio",
                                  { staticClass: "m-y-5", attrs: { label: 1 } },
                                  [
                                    _vm._v(
                                      "课程当天，会员可签到两小时内开始的课程"
                                    )
                                  ]
                                ),
                                _c(
                                  "el-radio",
                                  { staticClass: "m-y-5", attrs: { label: 2 } },
                                  [
                                    _vm._v(
                                      "课程当天，会员可在课程结束前扫码签到"
                                    )
                                  ]
                                ),
                                _c(
                                  "el-radio",
                                  { staticClass: "flex", attrs: { label: 3 } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex row-left flex-wrap"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("课程当天，会员可在课程开始前")
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "before" } },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w-100 m-x-5",
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "",
                                                    type: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.group_sign
                                                        .before,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form.group_sign,
                                                        "before",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.group_sign.before"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", [_vm._v("分钟至课程结束后")]),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "after_2" } },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w-100 m-x-5",
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "",
                                                    type: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.group_sign
                                                        .after_2,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form.group_sign,
                                                        "after_2",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.group_sign.after_2"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", [_vm._v("分钟内扫码签到")])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "setting_item" }, [
            _c("div", { staticClass: "m-y-8  p-y-5 p-x-15 title item_title" }, [
              _vm._v(" 预约取消设置 ")
            ]),
            _c(
              "div",
              { staticClass: "item_content m-y-10" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "自主取消" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.group_cancel_subscribe.status,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.group_cancel_subscribe,
                              "status",
                              $$v
                            )
                          },
                          expression: "form.group_cancel_subscribe.status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启")
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("关闭")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.group_cancel_subscribe.status
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "取消时间" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.group_cancel_subscribe.time_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.group_cancel_subscribe,
                                  "time_type",
                                  $$v
                                )
                              },
                              expression:
                                "form.group_cancel_subscribe.time_type"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex flex-col col-top row-left" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "m-y-5 flex",
                                    attrs: { label: 1 }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex row-left flex-wrap"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("会员自主取消不晚于开课前")
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "minute_1" } },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w-100 m-x-5",
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "",
                                                    type: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .group_cancel_subscribe
                                                        .minute_1,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form
                                                          .group_cancel_subscribe,
                                                        "minute_1",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.group_cancel_subscribe.minute_1"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", [_vm._v("分钟")])
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "m-y-5 flex",
                                    attrs: { label: 2 }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex row-left flex-wrap"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("会员自主取消不晚于开课后")
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "minute_2" } },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w-100 m-x-5",
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "",
                                                    type: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .group_cancel_subscribe
                                                        .minute_2,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form
                                                          .group_cancel_subscribe,
                                                        "minute_2",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.group_cancel_subscribe.minute_2"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", [_vm._v("分钟")])
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "m-y-5 flex",
                                    attrs: { label: 3 }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex row-left flex-wrap"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("会员自主取消不晚于课程结束后")
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "minute_3" } },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w-100 m-x-5",
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "",
                                                    type: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .group_cancel_subscribe
                                                        .minute_3,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form
                                                          .group_cancel_subscribe,
                                                        "minute_3",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.group_cancel_subscribe.minute_3"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", [_vm._v("分钟")])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "setting_item" }, [
            _c("div", { staticClass: "m-y-8  p-y-5 p-x-15 title item_title" }, [
              _vm._v(" 开课人数限制 ")
            ]),
            _c(
              "div",
              { staticClass: "item_content m-y-10" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "开课人数限制" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.group_minimum_member.is_check,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.group_minimum_member,
                              "is_check",
                              $$v
                            )
                          },
                          expression: "form.group_minimum_member.is_check"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启")
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("关闭")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.group_minimum_member.is_check
                  ? _c("el-form-item", { attrs: { label: "开课设置" } }, [
                      _c("div", { staticClass: "flex" }, [
                        _c("div", [_vm._v("满")]),
                        _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "lower_limit" } },
                              [
                                _c("el-input", {
                                  staticClass: "w-100 m-x-5",
                                  attrs: {
                                    size: "mini",
                                    placeholder: "",
                                    type: "number"
                                  },
                                  model: {
                                    value:
                                      _vm.form.group_minimum_member.lower_limit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.group_minimum_member,
                                        "lower_limit",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.group_minimum_member.lower_limit"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v("人开课，人数不足提前")]),
                        _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "check_time" } },
                              [
                                _c("el-input", {
                                  staticClass: "w-100 m-x-5",
                                  attrs: {
                                    size: "mini",
                                    placeholder: "",
                                    type: "number"
                                  },
                                  model: {
                                    value:
                                      _vm.form.group_minimum_member.check_time,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.group_minimum_member,
                                        "check_time",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.group_minimum_member.check_time"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v("分钟自动取消课程")])
                      ])
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "setting_item" }, [
            _c("div", { staticClass: "m-y-8  p-y-5 p-x-15 title item_title" }, [
              _vm._v(" 其他设置 ")
            ]),
            _c(
              "div",
              { staticClass: "item_content m-y-10" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "查看约课同学" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.group_other.schoolmate_status,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.group_other,
                              "schoolmate_status",
                              $$v
                            )
                          },
                          expression: "form.group_other.schoolmate_status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启")
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("关闭")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "约课人数显示" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.group_other.show_type,
                          callback: function($$v) {
                            _vm.$set(_vm.form.group_other, "show_type", $$v)
                          },
                          expression: "form.group_other.show_type"
                        }
                      },
                      [
                        _c(
                          "p",
                          [
                            _c(
                              "el-radio",
                              { staticClass: "m-y-5", attrs: { label: 1 } },
                              [
                                _vm._v(
                                  "微信端“约课”页面约课人数显示“还可预约几人”"
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _c(
                              "el-radio",
                              { staticClass: "m-y-5", attrs: { label: 2 } },
                              [
                                _vm._v(
                                  "微信端“约课”页面约课人数显示“已约人数/容纳人数”"
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _c(
                              "el-radio",
                              { staticClass: "m-y-5", attrs: { label: 3 } },
                              [
                                _vm._v(
                                  "微信端“约课”页面约课人数显示“预约满几人开课”"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submit }
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.returnPage()
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }