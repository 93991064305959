<template>
  <el-card style="margin-bottom:20px;">
    <div slot="header" class="clearfix">
      <div class="header_flex">
        <div class="header_title">
          <span>热门课程</span>
        </div>
        <div class="header_dropdown">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              {{ dropdownName }}
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleTime(1)">正序</el-dropdown-item>
              <el-dropdown-item @click.native="handleTime(2)">倒序</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="time">
          <el-date-picker
            v-model="time"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            @change="changeTime"
          />
        </div>
        <div class="details" @click="is_display = !is_display">
          {{ is_display ? '切换详情' : '切换图表' }}
          <i class="el-icon-sort" />
        </div>
        <div v-if="!is_display" class="details screen" @click="show = !show">
          {{ show ? '收起' : '筛选' }}
        </div>
      </div>
      <div>
        <el-collapse-transition>
          <div v-show="show && !is_display">
            <el-form ref="form" style="padding-top: 30px;" :inline="true" :model="listQuery" label-width="80px">
              <el-form-item label="课程" prop="curriculumID">
                <Select :type="6" @changeId="handleCurriculumID" />
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-transition>
      </div>
    </div>
    <!-- 内容 -->
    <div v-show="is_display" id="topRanking" :style="{ height: '500px', width: '100%' }" />
    <el-table
      v-show="!is_display"
      v-loading="loadingTable"
      :data="dataTable.list"
      :height="428"
      style="width: 100%; height: 428px;"
      align="center"
    >
      <el-table-column type="index" label="排名" width="100px" align="center">
        <template slot-scope="scope">
          <div v-if="(listQuery.page - 1) * listQuery.limit + scope.$index + 1 === 1">
            <img src="@/assets/images/ranking/rankingNo1.png" alt="">
          </div>
          <div v-else-if="(listQuery.page - 1) * listQuery.limit + scope.$index + 1 === 2">
            <img src="@/assets/images/ranking/rankingNo2.png" alt="">
          </div>
          <div v-else-if="(listQuery.page - 1) * listQuery.limit + scope.$index + 1 === 3">
            <img src="@/assets/images/ranking/rankingNo3.png" alt="">
          </div>
          <div v-else>{{ (listQuery.page - 1) * listQuery.limit + scope.$index + 1 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="课程名称" align="left">
        <template slot-scope="{ row }">
          <span :style="{ color: row.course ? '#629eff' : 'red' }">
            {{ row.course ? row.course.name : '课程已删除' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="数量" width="100px" align="center">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" :content="'同期对比' + row.relative_value + '%'" placement="left">
            <div class="flex row-center">
              {{ row.totalCount }}
              <!-- {{ row.relative > 0 ? '上升' : row.relative === 0 ? '持平' : '下降' }} -->
              <img v-if="row.relative > 0" class="p-l-10" src="@/assets/images/icon_top.png" alt="">
              <img v-if="row.relative === 0" class="p-l-10" src="@/assets/images/icon_dash.png" alt="">
              <img v-if="row.relative < 0" class="p-l-10" src="@/assets/images/icon_bottom.png" alt="">
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="dataTable.count > 0 && !is_display"
      :total="dataTable.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getTable"
    />
  </el-card>
</template>

<script>
import { getHotCourse } from '@/api/store/ranking'
import { timestampToTimes } from '@/utils/takeleave.js'
import Select from '@/components/elSelect/Select'
// 基于el-pagination的二级包
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination, Select },
  data() {
    return {
      dataTable: {
        count: 0,
        list: [],
        subscribeCount: 0
      }, // 数据
      option: {}, // echarts
      data: [], // 课程数据
      dropdownName: '倒序', // 排序
      dropdownId: 'desc', // 排序id
      end: '', // 日期
      start: '', // 日期
      time: '', // 时间日期
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }, // 快捷选择日期
      is_display: true,
      show: false,
      loadingTable: false,
      count: 0,
      subscribeCount: 0
    }
  },
  mounted() {
    // 一开始的默认时间
    this.start = timestampToTimes(new Date().getTime() - 3600 * 1000 * 24 * 7)
    this.end = timestampToTimes(Date.parse(new Date()))
    this.getTable()
    this.getList()
  },
  methods: {
    getList() {
      const data = {
        limit: 5,
        offsset: 0,
        order: `totalCount ${this.dropdownId}`,
        search_time: [this.start.slice(0, 10) + ' 00:00:00', this.end.slice(0, 10) + ' 23:59:59']
      }
      data.search_time = JSON.stringify(data.search_time)

      this.data = []
      getHotCourse(data)
        .then(res => {
          this.count = res.data.count
          this.subscribeCount = res.data.subscribeCount
          const data = res.data
          for (let i = 0; i < data.list.length; i++) {
            if (data.list.length === 0) return
            this.data.push({
              value: data.list[i].totalCount,
              name: data.list[i].course ? data.list[i].course.name : '该课程已被删除'
            })
          }
        })
        .finally(() => {
          this.getOption()
        })
    },
    getTable() {
      this.loadingTable = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.order = `totalCount ${this.dropdownId}`
      data.search_time = [this.start.slice(0, 10) + ' 00:00:00', this.end.slice(0, 10) + ' 23:59:59']
      data.search_time = JSON.stringify(data.search_time)

      var querys = []
      if (this.listQuery.course_id) querys.push({ field: 'course_id', key: this.listQuery.course_id })
      data.query = this.utils.getQueryParams(querys)

      getHotCourse(data).then(res => {
        this.dataTable = res.data
        this.loadingTable = false
      })
    },
    // echarts
    getOption() {
      this.option = {
        title: {
          text: '热门课程',
          subtext: `${this.start.slice(0, 10)} - ${this.end.slice(0, 10)}`,
          // '本月数据'
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          confine: true
        },
        legend: {
          show: true, // 是否显示
          bottom: '0%',
          left: 'left'
        },
        color: ['#00D8D7', '#61ec22', '#ff6317', '#3fa1ff', '#fb902d'],
        graphic: {
          elements: [
            {
              type: 'text',
              left: 20,
              top: 20,
              style: {
                text: `预约课程：${this.count}${' '.repeat(6)}预约总次数：${this.subscribeCount}`,
                fontSize: 14
              }
            },
            {
              type: 'text',
              left: 'center',
              top: 'middle',
              style: {
                fill: '#999',
                text: '暂无数据',
                font: '14px Arial',
                opacity: this.data.length === 0 ? 1 : 0
              }
            }
          ]
        },
        series: [
          {
            name: '热门课程',
            type: 'pie',
            radius: ['45%', '70%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.data
            // [
            //   { value: 250, name: '1', itemStyle: { color: '#6aff25' }},
            //   { value: 250, name: '2', itemStyle: { color: '#9c34ff' }},
            //   { value: 250, name: '3', itemStyle: { color: '#ff6317' }},
            //   { value: 250, name: '4', itemStyle: { color: '#3fa1ff' }},
            //   { value: 250, name: '5', itemStyle: { color: '#3fa1ff' }}
            // ]
          }
        ]
      }

      const myChart = this.$echarts.init(document.getElementById('topRanking'))
      myChart.setOption(this.option)
    },
    // 点击正序或者倒序
    handleTime(val) {
      if (val === 1) {
        this.dropdownName = '正序'
        this.dropdownId = 'asc'
      }
      if (val === 2) {
        this.dropdownName = '倒序'
        this.dropdownId = 'desc'
      }
      this.getList()
      this.getTable()
    },
    // 监听日期变化
    changeTime() {
      this.start = this.time[0]
      this.end = this.time[1]
      this.getList()
      this.getTable()
    },
    handleCurriculumID(val) {
      this.listQuery.course_id = val
      this.getTable()
    }
  }
}
</script>

<style lang="scss" scoped>
.header_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_title {
    font-size: 14px;
  }
  .header_dropdown {
    /* margin-left: 10px; */
    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }
    .el-icon-arrow-down {
      font-size: 14px;
    }
  }
  .time {
    /* margin-left: 15px; */
  }
  .details {
    font-size: 14px;
    /* margin-left: 10px; */
    display: flex;
    align-items: center;
    color: #00d8d7;
    cursor: pointer;
    i {
      font-size: 18px;
    }
  }
  .screen {
    color: #000;
  }
}

.pagination-container {
  margin-top: 10px !important;
}

.app-container .el-table {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.venuesList_empty {
  min-height: 500px;
  img {
    -webkit-user-drag: none;
  }
}
</style>
