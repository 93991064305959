<template>
  <div class="stop_box">
    <div class="text-center">
      <div class="image">
        <img
          style="width: 410px; height: 260px;"
          :src="empty_pause_img"
        >
      </div>
      <div class="text1">
        {{ msg }}
      </div>
      <div class="text2">您可以通过切换其他品牌或联系客服解决问题</div>
      <div>
        <el-button type="primary" round style="width:240px" @click="$router.push(`/login`)">返回</el-button>
      </div>
      <div class="m-t-10">
        <div class="wx_qrcode">
          <img style="width: 113px; height: 113px" :src="wx_qrcode_img">
        </div>
        <div class="call_number">
          <svg-icon icon-class="phone" /> {{ callNumber }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// API
import { getVenuesService } from '@/api/store/venues.js'

export default {
  data() {
    return {
      msg: localStorage.getItem('602Msg') ? localStorage.getItem('602Msg') : '您当前品牌已过期',
      wx_qrcode_img: require('@/assets/images/defaultManager.png'),
      empty_pause_img: require('@/assets/images/empty_pause.png'),
      callNumber: '400-678-0554'
    }
  },
  mounted() {
    getVenuesService().then(res => {
      this.wx_qrcode_img = res.data.qrcode_src
      this.callNumber = res.data.phone
    })
  }
}
</script>

<style lang="scss" scoped>
.stop_box{
  margin: 0 auto; /*水平居中*/
  position: relative;
  top: 50%; /*偏移*/
  transform: translateY(-50%);
  .text1{
    color:#09AEB7;
    font-size: 22px;
    letter-spacing: 5px;
    margin: 12px;
  }
  .text2{
    color:#999999;
    font-size: 15px;
    margin-bottom: 25px;
    letter-spacing: 2px;
  }
  .call_number{
    color:#999999;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 10px 0px 10px 0px;
  }
}
</style>
