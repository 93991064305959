<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="8" :xs="24">
        <el-card style="margin-bottom:20px;">
          <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div>
          <div class="user-profile">
            <div class="box-center">
              <el-avatar :size="100" :src="avatar" />
            </div>
            <div class="box-center">
              <div class="user-name text-center">{{ userinfo.real_name }}</div>
            </div>
            <el-form style="margin-top: 10px;">
              <el-form-item label="手机号">
                {{ userinfo.phone }}
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16" :xs="24">
        <el-card style="margin-bottom:20px;">
          <div slot="header" class="clearfix">
            <span>修改密码</span>
          </div>
          <div class="title_style">
            <el-form ref="form" :model="form" :rules="formRules" size="small" label-width="80px">
              <el-form-item label="旧密码" prop="old_password">
                <el-input v-model="form.old_password" type="password" />
              </el-form-item>
              <el-form-item label="新密码" prop="new_password">
                <el-input v-model="form.new_password" type="password" />
              </el-form-item>
              <el-form-item label="确认密码" prop="confirmPass">
                <el-input v-model="form.confirmPass" type="password" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { updatePassword } from '@/api/user.js'
import { mapGetters } from 'vuex'

export default {
  data() {
    const confirmPass = (rule, value, callback) => {
      if (value) {
        if (this.form.new_password !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请再次输入密码'))
      }
    }
    return {
      avatar: require('@/assets/images/avatar/default.png'),
      form: {},
      formRules: {
        old_password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        confirmPass: [{ required: true, validator: confirmPass, trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters(['userinfo'])
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          delete this.form['confirmPass']
          updatePassword(this.form)
            .then(res => {
              this.$message.success('修改成功！')
              this.form = {}
            })
            .catch(() => {})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box-center {
  margin: 0 auto;
  display: table;
}

.text-muted {
  color: #777;
}

.user-profile {
  .user-name {
    font-weight: bold;
  }

  .box-center {
    padding-top: 10px;
  }

  .user-role {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }

  .box-social {
    padding-top: 30px;

    .el-table {
      border-top: 1px solid #dfe6ec;
    }
  }

  .user-follow {
    padding-top: 20px;
  }
}

.user-bio {
  margin-top: 20px;
  color: #606266;

  span {
    padding-left: 4px;
  }

  .user-bio-section {
    font-size: 14px;
    padding: 15px 0;

    .user-bio-section-header {
      border-bottom: 1px solid #dfe6ec;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
}
.class_color {
  width: 80px;
  height: 20px;
  margin: 5px 0 0 70px;
}
.title_style {
  width: 45%;
}
@media screen and (max-width: 768px) {
  .title_style {
    width: 100%;
  }
}
</style>
