<template>
  <div v-loading="loading">
    <el-row :gutter="20" class="text-center">
      <div class="m-b-1 qrcode_images">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="qr-box">
            <div class="m-b-10 font-18 qr-text">公众号</div>
            <el-image
              :src="data.wechat_path"
              class="image_item wechat_path "
              :preview-src-list="[data.wechat_path]"
            />
            <br>
            <el-link icon="el-icon-download" class="image_down" @click="downloadCodeImg('wechat_path')">点击下载二维码
            </el-link>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="qr-box">
            <div class="m-b-10 font-18 qr-text">小程序</div>
            <el-image :src="data.program_path" class="image_item program_path " :preview-src-list="[data.program_path]" />
            <br>
            <el-link icon="el-icon-download" class="image_down" @click="downloadCodeImg('program_path')">点击下载二维码</el-link>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="p-14 m-t-10">
            <span>访客手机扫一扫录入信息</span>
            <span> - {{ Venues.name }}</span>
          </div>
        </el-col>
      </div>
    </el-row>

  </div>
</template>

<script>
import { InquireQrcode } from '@/api/store/inquire.js'
import utils from '@/utils/utils.js'
export default {
  name: 'InquireQrcodeSign',
  data() {
    return {
      loading: true,
      data: {},
      Venues: utils.getVenues()
    }
  },
  created() {
  },
  mounted() {
    console.log(this.Venues)
    this.getQrcodeInfo()
  },
  methods: {
    getQrcodeInfo() {
      // InquireQrcode({ venues_id: this.Venues.id }).then(res => {
      InquireQrcode().then(res => {
        this.data = res.data
        this.loading = false
      }).catch(() => { })
    },

    downloadCodeImg(name) {
      // const link = document.createElement('a')
      const url = this.data[name] // codeIMG  要下载的路径
      // 这里是将url转成blob地址，
      var file_name = `${this.utils.getVenues().name}_${name === 'wechat_path' ? '公众号' : '小程序'}客询二维码_${this.$moment().format('YYYY-MM-DD') + '.jpg'}`
      // this.utils.handleDownload({ url: '/store/v1/download?filePath=' + url, fileName: file_name, baseURL: this.baseUrl })
      this.utils.downloadIamge(url, file_name)
      // fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
      //   link.href = URL.createObjectURL(blob)
      //   console.log(link.href)
      //   link.download = file_name
      //   document.body.appendChild(link)
      //   link.click()
      // })
    }

  }

}
</script>

<style lang="scss" scoped>
.qrcode_images {
  img {
    width: 280px;
    height: 280px;
  }

  .image_down {
    // display: block;
    // padding-top: 20px;
  }

  .qr-box {
    border: 1px solid #eee;
    padding: 10px 0px;
    box-shadow: 0px 2px 5px #e9e9e9;
    display: inline-block;

    .image_item {
      width: 280px;
      height: 280px;
    }
  }

  .qr-text {
    font-weight: 800;
    color: #4e95ff;
    border-bottom: 1px solid #ededed;
    border-bottom-width: 1px;
    padding-bottom: 5px;
    box-shadow: 0px 2px 5px #e9e9e9;
  }
}
</style>
