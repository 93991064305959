<template>
  <div class="app-container">
    <!-- 通用搜索 -->
    <div class="filter-container m-t-20">
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索角色名称" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('system_role_add')" type="primary" class="filter-item m-l-10" @click="handleAdd">
        新增角色
      </el-button>
    </div>
    <!-- 表格 -->
    <el-table ref="multipleTable" v-loading="TableLoading" :data="tableData.list" row-key="id" header-align="center">
      <el-table-column label="角色" prop="name">
        <template slot-scope="{ row }">
          <div class="flex">
            <div>{{ row.name }}</div>
            <el-tag v-if="row.is_super === 1" class="m-l-10" type="success">超级管理员</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_time" />
      <!-- <el-table-column label="菜单权限" prop="phone">
        <template slot-scope="{ row }">
          <div v-if="row.menu_route_ids.length === 0">暂无菜单权限</div>
          <div v-else>
            <el-tag v-for="(item, index) in row.menu_route_ids" :key="item.id" class="m-x-4">{{ index }}</el-tag>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button v-show="btn_access('system_role_edit')" class="m-2" type="primary" @click="handleEdit(row.id)">
            编辑角色
          </el-button>
          <el-button v-show="btn_access('system_role_del')" class="m-2" type="danger" @click="handleDelete(row.id)">
            删除角色
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <roleDialog
      :dialog-visible-account="dialogVisibleAccount"
      :type="type"
      :type-id="typeId"
      @roleVisible="roleVisible"
    />
  </div>
</template>

<script>
// API
import { getRoleList, deleteRole } from '@/api/admin/account.js'
// 组件
import Pagination from '@/components/Pagination'
import roleDialog from '../components/roleDialog'

export default {
  components: { Pagination, roleDialog },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      TableLoading: false,
      dialogVisibleAccount: false,
      type: '',
      typeId: 0
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.TableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'name', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      getRoleList(data)
        .then(res => {
          const { data } = res
          this.tableData = data
        })
        .finally(() => {
          this.TableLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 新增角色
    handleAdd() {
      this.type = 'add'
      this.dialogVisibleAccount = true
    },
    // 编辑角色
    handleEdit(id) {
      this.type = 'edit'
      this.typeId = id
      this.dialogVisibleAccount = true
    },
    // 删除角色
    handleDelete(id) {
      this.$confirm('此操作将永久删除该角色权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteRole(id).then(() => {
          this.getList()
          this.$message.success('删除成功!')
        })
      })
    },
    roleVisible() {
      this.dialogVisibleAccount = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
