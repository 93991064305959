<template>
  <div class="app-container">
    <el-alert
      v-if="is_view"
      title="修改后可使用回车键进行保存"
      type="info"
      show-icon
      :closable="false"
      class="m-b-10"
    />
    <el-table
      :data="tableData.list"
      style="width: 100%"
    >
      <el-table-column prop="name" label="提醒设置" width="220" fixed="left" />
      <el-table-column prop="value" :align="rowAlign" min-width="500">
        <template slot-scope="{row}">
          <template v-if="is_view">
            <el-input v-model="row.value" class="edit-input" onkeyup="value=value.replace(/^0+(\d)|[^\d]+/g,'')" style="width:300px" @change="rowChange(row)">
              <template slot="prepend">{{ stringArr[row.en_name][0] }}</template>
              <template slot="append">{{ stringArr[row.en_name][1] }}</template>
            </el-input>
          </template>
          <span v-else>{{ valueFormatter(row) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="{row}">
          <template v-if="is_view">
            <el-switch
              v-model="row.status"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
              class="m-r-10"
              @change="rowChange(row)"
            />
          </template>
          <span v-else>{{ row.status?'已开启':'已关闭' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="right"
      >
        <template #header>
          <el-button v-if="!is_view" type="primary" icon="el-icon-edit" @click="viewChange">编辑</el-button>
          <el-button v-else icon="el-icon-back" @click="viewChange">返回</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getRemind, editRemindInfo } from '@/api/store/remind.js'
export default {
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      is_view: false,
      form: {},
      stringArr: {
        birthday: ['会员生日前', '天提醒'],
        disappear: ['会员连续', '天未上课提醒'],
        anniversary: ['会员入会纪念日前', '天提醒'],
        expire: ['有效期前后', '天提醒'],
        number_card_limit: ['次数卡余额不足', '次提醒'],
        value_card_limit: ['储值卡余额不足', '元提醒'],
        open_card: ['开卡日期前', '天提醒'],
        leave_expire: ['请假截止日期前', '天提醒']
      },
      rowAlign: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getRemind().then(res => {
        this.tableData = res.data
      }).catch(() => {})
    },
    valueFormatter(v) {
      var s = ''
      s = this.stringArr[v.en_name]
      return s[0] + v.value + s[1]
    },
    rowChange(v) {
      // 更新信息
      const data = v
      editRemindInfo(data.id, { value: data.value, status: data.status }).then(res => {
        this.$message.success('修改成功')
      }).catch(() => {})
    },
    viewChange() {
      this.rowAlign = (!this.rowAlign ? 'center' : '')
      this.$set(this, 'is_view', !this.is_view)
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
