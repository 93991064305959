<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleContract"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="25%"
      :show-close="false"
      @open="handleOpen"
      @close="handleClose"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">{{ dialogData.name }}</div>
          <div class="title_icon cursor" @click="handleClose">
            <i class="el-icon-close" />
          </div>
        </div>
      </div>
      <div v-loading="dialogLoading">
        <div class="flex row-between">
          <div class="flex">
            <el-image style="width: 60px;" :src="iconUrl" fit="fill" />
            <div class="m-l-10">
              <div class="flex m-b-10">
                <div v-if="dialogData.name" style="font-weight: bold;" class="font-18">
                  {{ dialogData.name.length > 15 ? dialogData.name.slice(0, 10) + '...' : dialogData.name }}
                </div>
                <!-- <el-tag v-if="dialogData.status >= 0" class="m-l-10" :type="status[dialogData.status].type">
                  {{ status[dialogData.status].label }}
                </el-tag> -->
              </div>
              <el-tag v-if="dialogData.status >= 0" :type="status[dialogData.status].type">
                {{ status[dialogData.status].label }}
              </el-tag>
            </div>
          </div>
          <!-- <div class="cursor" style="color: #01cbca;" @click="goFile(dialogData.file_path)">
            <i class="el-icon-view m-r-6" />
            查看
          </div> -->
          <div class="flex">
            <div class="cursor" style="color: #01cbca;" @click="goFile(dialogData.file_path)">
              <i class="el-icon-view m-r-2" />
              查 看
            </div>
            <div
              class="cursor m-l-16"
              style="color: #01cbca;"
              @click="goDownload(dialogData.file_path, dialogData.name)"
            >
              <i class="el-icon-download m-r-2" />
              下载合同
            </div>
          </div>
        </div>
        <p class="m-t-30"><b>详细信息</b></p>
        <el-descriptions class="margin-top" :column="1" border>
          <el-descriptions-item v-if="dialogData.tags" label="标签">
            <div v-if="dialogData.tags.length === 0" class="text-info flex">
              <div>暂无标签</div>
              <div v-if="contractStatus == 5" class="cursor text-success m-l-20" @click="handleAddTags">
                <i class="el-icon-plus font-16" />
              </div>
            </div>
            <div v-else class="text-info flex">
              <div>
                <el-tag v-for="item in dialogData.tags" :key="item.id" class="m-2">{{ item.name }}</el-tag>
              </div>
              <div v-if="contractStatus == 5" class="cursor text-success m-l-20" @click="handleAddTags">
                <i class="el-icon-plus font-16" />
              </div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item v-if="contractStatus !== 5" label="签署信息">
            <div v-if="Array.isArray(dialogData.users)" class="flex">
              <el-avatar
                shape="square"
                :size="30"
                :src="
                  dialogData.users.length > 0
                    ? dialogData.users[0].avatar
                      ? dialogData.users[0].avatar
                      : errorMemberSrc()
                    : errorMemberSrc()
                "
              />
              <div class="m-l-4">{{ dialogData.users.length > 0 ? dialogData.users[0].nickname : '---' }}</div>
              <div class="m-l-4">（{{ status[dialogData.status].label }}）</div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item v-if="contractStatus !== 5" label="合同编号">
            {{ dialogData.contract_no }}
          </el-descriptions-item>
          <el-descriptions-item v-if="dialogData.store_user" label="创建人">
            <div class="flex">
              <el-avatar
                shape="square"
                :size="30"
                :src="dialogData.store_user.avatar ? dialogData.store_user.avatar : errorMemberSrc()"
              />
              <div class="m-l-4">{{ dialogData.store_user.real_name }}</div>
            </div>
          </el-descriptions-item>
          <el-descriptions-item v-if="dialogData.create_time" label="创建时间">
            {{ dialogData.create_time.slice(0, 16) }}
          </el-descriptions-item>
          <el-descriptions-item
            v-if="contractStatus == 0 || contractStatus == 1 || contractStatus == 2"
            label="签署截至时间"
          >
            {{ dialogData.sign_expire_time ? dialogData.sign_expire_time.slice(0, 16) : '不限制' }}
          </el-descriptions-item>
          <el-descriptions-item
            v-if="contractStatus == 1 || contractStatus == 3 || contractStatus == 4"
            label="合同到期时间"
          >
            {{ dialogData.contract_expire_time ? dialogData.contract_expire_time.slice(0, 16) : '不限制' }}
          </el-descriptions-item>
          <el-descriptions-item v-if="contractStatus == 4" label="备注">
            <div v-if="dialogData.dispose_record">{{ dialogData.dispose_record.remark ? dialogData.dispose_record.remark : '无' }}</div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 标签 -->
    <tagDialog
      :dialog-visible-template="dialogVisibleTemplate"
      :template-tags="templateTags"
      @closeVisibleTemplate="closeVisibleTemplate"
    />
  </div>
</template>

<script>
// API
import { getContractDetail, editTemplate } from '@/api/store/contract'
// 组件
import tagDialog from './tagDialog'

export default {
  components: { tagDialog },
  props: {
    dialogVisibleContract: {
      type: Boolean,
      default: false
    },
    contractId: {
      type: Number,
      default: 0
    },
    contractStatus: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialogData: {},
      dialogLoading: false,
      iconUrl: require('@/assets/images/contract/Group.png'),
      status: [
        { label: '待签署', type: '' },
        { label: '已签署', type: 'success' },
        { label: '逾期未签', type: 'warning' },
        { label: '合同到期', type: 'danger' },
        { label: '作废', type: 'info' },
        { label: '待添加签署人', type: 'danger' }
      ],
      dialogVisibleTemplate: false,
      templateTags: []
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.dialogLoading = true

      let data = {}
      if (this.contractStatus === 5) {
        data = {
          is_customized: 1
        }
      }
      getContractDetail(this.contractId, data)
        .then(res => {
          const data = res.data
          this.dialogData = data
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    // 关闭的回调
    handleClose() {
      this.$emit('closeVisible')
    },
    // 预览PDF文件
    goFile(url) {
      window.open(url)
    },
    // 下载合同
    goDownload(file_path, name) {
      this.utils.downloadPaf(file_path, `${name}合同模板`)
    },
    // 添加标签
    handleAddTags() {
      if (this.dialogData.tags.length > 0) this.templateTags = this.dialogData.tags
      else this.templateTags = []
      this.dialogVisibleTemplate = true
    },
    closeVisibleTemplate(val) {
      this.dialogVisibleTemplate = false
      console.log(val)
      let value = ''
      if (val.length > 0) value = val.toString()
      const data = {
        tag_id: value
      }
      editTemplate(this.contractId, data).then(() => {
        this.$message.success('修改成功')
        this.handleOpen()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tree-height {
  height: 60vh;
  overflow-y: auto;
}
</style>
