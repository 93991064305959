var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      directives: [
        {
          name: "loadmore",
          rawName: "v-loadmore",
          value: _vm.loadMore,
          expression: "loadMore"
        }
      ],
      attrs: { label: _vm.title }
    },
    [
      _c(
        "el-select",
        {
          attrs: {
            filterable: "",
            clearable: "",
            remote: "",
            "reserve-keyword": "",
            placeholder: "请选择" + _vm.title,
            "remote-method": _vm.selectChanged
          },
          on: { "visible-change": _vm.visibleChanged },
          model: {
            value: _vm.goods_id,
            callback: function($$v) {
              _vm.goods_id = $$v
            },
            expression: "goods_id"
          }
        },
        [
          _vm._l(_vm.cardList, function(item, index) {
            return _c("el-option", {
              key: index,
              attrs: { label: item.name, value: item.id }
            })
          }),
          _vm.is_hide === 0
            ? _c("div", { staticClass: "jzz" }, [
                _vm.is_Yes === 1
                  ? _c("i", { staticClass: "el-icon-loading" })
                  : _vm._e(),
                _vm.is_Yes === 1
                  ? _c("span", { staticClass: "jzz_span1" }, [
                      _vm._v("玩命加载中")
                    ])
                  : _vm._e(),
                _vm.is_Yes === 0
                  ? _c("span", { staticClass: "jzz_span2" }, [
                      _vm._v("----- 我也是有底线的 -----")
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }