<template>
  <div>
    <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate">全选</el-checkbox>
    <el-checkbox-group v-model="selected">
      <el-checkbox v-for="item in list" :key="item.id" :label="item">
        <span v-if="item.store_user">{{ item.store_user.real_name }} / </span><span v-else>{{ isCourse?'':'教练待更新  / ' }}</span>{{ item.name }}
        <template v-if="type!=0 && type"><el-input v-model="item.charge" type="number" class="input m-b-10" placeholder="单节课收费,默认0" @input="inputChange" />{{ type == 1 ? '次/人':'元/人' }}</template>
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    // 选中列表
    parentSelected: {
      type: Array,
      default() {
        return []
      }
    },
    // 全部列表
    list: {
      type: [Array, Object],
      default() {
        return {}
      }
    },
    // 类型
    type: {
      type: Number,
      default: 0
    },
    isCourse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    checkAll: {
      get() {
        return this.parentSelected.length === this.list.length
      },
      set(val) {
        this.list.map(item => {
          // 次数卡默认1
          if ((typeof item.charge === 'undefined' || item.charge === '') && this.type === 1) {
            item.charge = 1
          }
        })
        this.$emit('update:parentSelected', val ? this.list : [])
      }
    },
    selected: {
      get() {
        return this.parentSelected
      },
      set(v) {
        this.list.map(item => {
          // 次数卡默认1
          if ((typeof item.charge === 'undefined' || item.charge === '') && this.type === 1) {
            item.charge = 1
          }
        })
        this.$emit('update:parentSelected', v)
      }
    },
    isIndeterminate: {
      get() {
        const checkedCount = this.parentSelected.length
        return checkedCount > 0 && checkedCount < this.list.length
      }
    }
  },
  watch: {
    parentSelected: function(v, ov) {
    }
  },
  mounted() {
  },
  methods: {
    inputChange(val) {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
	.input {
		width: 140px;
		margin: 0 10px;
	}
	.el-checkbox ::v-deep .el-checkbox__label{
		color: #606266 !important;
	}

</style>
