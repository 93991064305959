<template>
  <div class="p-t-10">
    <SelectSendSms :checked-user-count="checkedUserCount" :multiple-selection="multipleSelection" />
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="卡种">
            <el-select v-model="listQueryParams.membership_card_type" placeholder="请选择" filterable clearable>
              <el-option v-for="(item, index) in card_type" :key="index" :label="item" :value="index" />
            </el-select>
            <el-button
              icon="el-icon-search"
              :loading="searchLoading"
              type="success"
              class="m-l-10"
              @click="handleFilter"
            >
              搜索
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 列表 -->
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      :data="tableData.list"
      row-key="id"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column
        type="selection"
        width="55"
      /> -->
      <el-table-column prop="number" label="卡号" width="160">
        <template slot-scope="{ row }">
          <el-tag class="cursor" @click="toCardInfoPage(row.id)">{{ row.number }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="user" label="会员" width="260">
        <template slot-scope="{ row }">
          <div v-if="!utils.empty(row.user)" class="flex col-center cursor" @click="toUserInfoPage(row.user.id)">
            <el-avatar shape="square" :size="50" :src="row.user.avatar">
              <img :src="errorMemberSrc()">
            </el-avatar>
            <div class="m-l-10">
              {{ getUserNames(row.user) }}
              <br>
              {{ row.user ? row.user.phone : '--' }}
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="开卡类型">
        <template slot-scope="{ row: { type } }">
          <span>{{ card_open_type[type] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="card.name" label="卡名称" />
      <el-table-column prop="valid_time" label="有效期">
        <template slot-scope="{ row }">
          {{ row.open_card_time | formatDate('YYYY-MM-DD') }} 至 {{ row.valid_time }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column prop="status" label="回访状态">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status" type="success">已回访</el-tag>
          <el-tag v-else type="info">未回访</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240px">
        <template slot-scope="scope">
          <el-button
            v-show="btn_access('s_user_track_list')"
            icon="el-icon-notebook-2"
            @click="toVisitorTrackPage(scope.row.user_id)"
          >
            回访记录
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <!-- 用户回访 -->
    <VisitorTrack
      :id="dialog.trackId"
      :is-show="isShow"
      :track-visible.sync="dialog.trackVisible"
      @trackVisible="trackVisible"
    />
  </div>
</template>

<script>
import SelectSendSms from '../selectSendSms.vue'
import { getUserMembershipCardExpire } from '@/api/store/user.js'
import VisitorTrack from '@/views/store/user/tracking' // 用户回访
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  components: { Pagination, SelectSendSms, VisitorTrack },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      listLoading: false,
      checkedUserCount: 0,
      multipleSelection: [],
      searchLoading: false,
      dialog: { trackVisible: false, trackId: null },
      isShow: 10
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 保存选中结果
    handleSelectionChange(val) {
      // 先清空id数组
      this.multipleSelection = []
      // 去重获取被选中的id值存入到数组中
      for (const selectedItem of val) {
        this.multipleSelection.push(selectedItem.id)
      }
      this.checkedUserCount = this.multipleSelection.length
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 获取列表
    getList() {
      console.log(
        '🚀 ~ file: index.vue ~ line 90 ~ getList ~ this.listQueryParams.membership_card_type',
        this.listQueryParams.membership_card_type
      )
      this.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      if (this.listQueryParams.membership_card_type !== undefined && this.listQueryParams.membership_card_type !== '') {
        data.membership_card_type = this.listQueryParams.membership_card_type
      }
      getUserMembershipCardExpire(data)
        .then(res => {
          this.tableData = res.data
          this.listLoading = false
        })
        .finally(() => {
          this.searchLoading = false
        })
    },
    toVisitorTrackPage(id) {
      this.dialog.trackId = id
      this.dialog.trackVisible = true
    },
    trackVisible() {
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
