<template>
  <div class="app-container">
    <!-- 搜索 -->
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="top" :inline="true">
        <!-- 会员搜索 -->
        <el-form-item label="会员名/手机号：">
          <el-input v-model="listQueryParams.q" placeholder="请输入会员名/手机号" style="width: 220px;" clearable />
        </el-form-item>
        <el-form-item label="请假时间：">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            align="right"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <!-- 操作人 -->
        <el-form-item label="操作人">
          <Select :type="4" @changeId="handleCoachID" />
        </el-form-item>
      </el-form>
      <div>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </div>
    </div>
    <div class="total m-y-20 font-16">
      累计请假
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="tableData.count" :duration="2000" :decimals="0" />
      </span>
      次，请假天数
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="tableData.day_count" :duration="2000" :decimals="0" />
      </span>
      天
      <br>
      其中期限卡
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="tableData.qx_card_count" :duration="2000" :decimals="0" />
      </span>
      张，储值卡
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="tableData.cz_card_count" :duration="2000" :decimals="0" />
      </span>
      张，次数卡
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="tableData.cs_card_count" :duration="2000" :decimals="0" />
      </span>
      张
    </div>
    <!-- 请假列表 -->
    <div class="tableColumn">
      <el-table v-loading="loading" :data="tableData.list" style="width: 100%" :stripe="true">
        <el-table-column label="请假人" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.user_membership_card.user" class="photo">

              <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="scope.row.user_membership_card.user.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
              <div>{{ getUserNames(scope.row.user_membership_card.user) }}</div>
              <div class="m-x-4 text-info">-</div>
              <div :class="scope.row.user_membership_card.user.phone ? '' : 'text-info'">{{ scope.row.user_membership_card.user.phone ? scope.row.user_membership_card.user.phone : '---' }}</div>
            </div>
            <div v-if="!scope.row.user_membership_card.user" style="color: red;">用户数据丢失</div>
          </template>
        </el-table-column>
        <el-table-column label="请假时间">
          <template slot-scope="scope">{{ scope.row.start_time }} 至 {{ scope.row.end_time }}</template>
        </el-table-column>
        <el-table-column label="请假事由">
          <template slot-scope="scope">{{ scope.row.remark ? scope.row.remark : '无' }}</template>
        </el-table-column>
        <el-table-column label="会员卡名称">
          <template slot-scope="scope">{{ scope.row.user_membership_card.card.name }}</template>
        </el-table-column>
        <el-table-column label="卡号">
          <template slot-scope="scope">{{ scope.row.user_membership_card.number }}</template>
        </el-table-column>
        <el-table-column label="请假状态">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.ask_status_text }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作人">
          <template slot-scope="scope">
            <div v-if="scope.row.store_user">
              {{ scope.row.store_user.real_name }}
            </div>
            <div v-if="!scope.row.store_user">无</div>
          </template>
        </el-table-column>
        <el-table-column label="申请时间" prop="create_time" />
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button type="primary" @click="toPage(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="tableData.count > 0"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>
<script>
// API
import { getStatisticList } from '@/api/store/takeleave'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import CountTo from 'vue-count-to'
import Select from '@/components/elSelect/Select'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'

export default {
  components: { Pagination, CountTo, Select },
  data() {
    return {
      tableData: {
        list: [],
        count: 0,
        qx_card_count: 0,
        cz_card_count: 0,
        cs_card_count: 0,
        day_count: 0
      }, // 数据
      search: '', // 搜索框
      loading: false, // 表格加载
      searchLoading: false // 搜索按钮加载
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    // 获取数据
    getList() {
      this.loading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      if (this.listQueryParams.q) {
        data.q = this.listQueryParams.q
      }

      if (this.listQueryParams.create_time) {
        data.start_time = this.listQueryParams.create_time[0]
        data.end_time = this.listQueryParams.create_time[1]
      }
      if (this.listQueryParams.store_user_id) {
        data.store_user_id = this.listQueryParams.store_user_id
      }

      getStatisticList(data).then(res => {
        const list = res.data
        list.list.forEach(item => {
          item.start_time = timestampToTimes(item.start_time)
          item.end_time = timestampToTimes(item.end_time)
        })
        this.tableData = list
        console.log(this.tableData)
        this.loading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    toPage(id) {
      this.$router.push({ name: 's_takeLeave_edits', params: { id: id }})
    },
    handleCoachID(val) {
      this.listQueryParams.store_user_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.tableColumn {
  margin-top: 20px;
}

.ssl_input {
  width: 300px;
  margin-right: 20px;
}
.photo {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    margin-left: 10px;
  }
}
</style>
