<template>
  <el-tag v-if="value" :type="tag_type">{{ value }}</el-tag>
</template>

<script>
export default {
  name: 'Inquiretag',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  computed: {
    tag_type() {
      let type = 'default'
      switch (this.value) {
        case '一般':
        case '潜在':
          break
        case '重要':
        case '意向':
        case '洽谈':
          type = 'warning'
          break
        case '核心':
        case '流失':
          type = 'danger'
          break
        case '完结':
          type = 'success'
          break
        default:
          break
      }
      return type
    }
  },
  watch: {
    value: {
      handler(newVal) {

      },
      deep: true
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
</style>
