var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.bgLoading,
          expression: "bgLoading"
        },
        { name: "prevent-ctrl-scroll", rawName: "v-prevent-ctrl-scroll" }
      ],
      staticClass: "activity_bg"
    },
    [
      _c("div", { staticClass: "activity_center" }, [
        _vm.activityStatusTime === 0
          ? _c("div", { staticClass: "activity_button3 cursor" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/activePage/but_main_notstarted.png"),
                  draggable: "false",
                  alt: ""
                }
              })
            ])
          : _vm.activityStatusTime === 2
          ? _c("div", { staticClass: "activity_button3 cursor" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/activePage/but_main_ended.png"),
                  draggable: "false",
                  alt: ""
                }
              })
            ])
          : _vm.activityStatusTime === 1 && _vm.activityStatus === 0
          ? _c(
              "div",
              {
                staticClass: "activity_button cursor",
                on: { click: _vm.handleActivity }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/activePage/but_main_start.png"),
                    draggable: "false",
                    alt: ""
                  }
                }),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.activityQuota > 100
                        ? "限时名额"
                        : "仅剩" + _vm.activityQuota + "名"
                    )
                  )
                ])
              ]
            )
          : _c(
              "div",
              {
                staticClass: "activity_button2 cursor",
                on: { click: _vm.handleMask }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/activePage/but_main_end.png"),
                    draggable: "false",
                    alt: ""
                  }
                }),
                _c("div", [_vm._v("联系客服")])
              ]
            ),
        _c("div", { staticClass: "activity_rotation" }, [
          _c(
            "div",
            _vm._l(_vm.activityOrders, function(item, index) {
              return _c("div", { key: index, staticClass: "rotation" }, [
                _c("div", [_vm._v(_vm._s(item.name))]),
                _c("div", { staticClass: "rotationGG" }, [
                  _vm._v(_vm._s(item.desc))
                ])
              ])
            }),
            0
          )
        ]),
        _vm._m(0),
        _c("div", { staticClass: "activity_package" }, [
          _vm._m(1),
          _c(
            "div",
            _vm._l(_vm.imgData, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "cursor",
                  on: {
                    mouseover: function($event) {
                      return _vm.handleMouseover(item.id)
                    }
                  }
                },
                [
                  _c("img", {
                    class: item.Select ? "img_select" : "",
                    attrs: {
                      src: item.Select ? item.imgSelect : item.img,
                      alt: "",
                      draggable: "false"
                    }
                  }),
                  item.id === 2
                    ? _c("img", {
                        class: item.Select
                          ? "img_subscript1"
                          : "img_subscript2",
                        attrs: {
                          src: require("@/assets/images/activePage/img_tc_subscript.png"),
                          alt: "",
                          draggable: "false"
                        }
                      })
                    : _vm._e()
                ]
              )
            }),
            0
          ),
          _c("div", [
            _vm.imgId === 1
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/images/activePage/img_1year.png"),
                    alt: "",
                    draggable: "false"
                  }
                })
              : _vm.imgId === 2
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/images/activePage/img_3year.png"),
                    alt: "",
                    draggable: "false"
                  }
                })
              : _vm.imgId === 3
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/images/activePage/img_5year.png"),
                    alt: "",
                    draggable: "false"
                  }
                })
              : _vm._e()
          ])
        ]),
        _vm._m(2),
        _vm._m(3),
        _c("div", { staticClass: "YuXIaoJiu" }, [
          _vm._v("*本次活动最终解释权归瑜小九所有")
        ])
      ]),
      _vm.activity_dialog
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.divLoading,
                  expression: "divLoading"
                }
              ],
              staticClass: "activity_dialog"
            },
            [
              _c("div", [
                _c("div", [_vm._v("订单支付")]),
                _c("i", {
                  staticClass: "el-icon-close cursor",
                  on: {
                    click: function($event) {
                      return _vm.handleClose(0)
                    }
                  }
                })
              ]),
              _c("div", [
                _c("div", [_vm._v("订单号：")]),
                _c("div", [_vm._v(_vm._s(_vm.orderInfo.order.number))])
              ]),
              _c("div", [
                _c("div", [_vm._v("流水号：")]),
                _c("div", [_vm._v(_vm._s(_vm.orderInfo.prepay_id))])
              ]),
              _c("div", [
                _c("div", [_vm._v("支付金额：")]),
                _c("div", [
                  _vm._v(_vm._s(_vm.orderInfo.order.pay_price) + "元")
                ])
              ]),
              _c("div", [
                _c("div", [_vm._v("支付状态：")]),
                _c(
                  "div",
                  {
                    style: { color: _vm.orderInfo.paid ? "#42d3b5" : "#fbb677" }
                  },
                  [_vm._v(_vm._s(_vm.orderInfo.paid ? "已支付" : "未支付"))]
                )
              ]),
              _c("div", [_c("canvas", { ref: "qrcode_ban" }), _vm._m(4)])
            ]
          )
        : _vm._e(),
      _vm.customerService
        ? _c("div", { staticClass: "customerService" }, [
            _c("img", {
              attrs: { src: _vm.qrcode_src, alt: "", draggable: "false" }
            }),
            _c(
              "div",
              {
                staticClass: "cursor",
                on: {
                  click: function($event) {
                    return _vm.handleClose(1)
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-close" })]
            )
          ])
        : _vm._e(),
      _vm.activity_mask ? _c("div", { staticClass: "activity_mask" }) : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "activity_content" }, [
      _c("div", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/activePage/img_ac_title.png"),
            alt: "",
            draggable: "false"
          }
        })
      ]),
      _c("div", [_vm._v("2023年6月8日-2023年6月18日")]),
      _c("div", [
        _vm._v("在系统中支付"),
        _c("span", [_vm._v("6.18")]),
        _vm._v("元即可抢占限时特惠名额，抢占成功后还可任选超值618活动福利。")
      ]),
      _c("div", [_vm._v("活动名额仅限前618名，先到先得。")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/assets/images/activePage/img_tc_title.png"),
          alt: "",
          draggable: "false"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "activity_rule" }, [
      _c("div", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/activePage/img_xz_title.png"),
            alt: "",
            draggable: "false"
          }
        })
      ]),
      _c("div", [
        _vm._v(" 1. 活动时间：2023年6月8日—2023年6月18日。"),
        _c("br"),
        _vm._v(" 2. 每个品牌仅可选择购买一个套餐，不可重复参与。"),
        _c("br"),
        _vm._v(" 3. 抢占名额成功后该订单将被锁定，不支持退款。"),
        _c("br"),
        _vm._v(
          " 4. 选择了盲盒福利的馆主请提供正确的收货地址，因地址错误产生的额外物流费用需自行承担。"
        ),
        _c("br"),
        _vm._v(" 5. 瑜小九定制盲盒不允许兑换现金。"),
        _c("br"),
        _vm._v(
          " 6. 现金抵扣福利在购买指定活动套餐时立即抵扣使用，使用期限为活动时间内，且不能与其他优惠同时使用。"
        ),
        _c("br"),
        _vm._v(
          " 7. 赠送短信用于提醒会员、老师上课相关信息，建立更好的服务体系，提高瑜伽馆的服务质量。短信使用完后需自行在APP内的【个人中心】选择套餐购买。"
        ),
        _c("br"),
        _vm._v(
          " 8. 在购买活动套餐后，系统将开放所有功能，且无需支付任何附加费用（除购买短信之外）。"
        ),
        _c("br")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "activity_exchange" }, [
      _c("div", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/activePage/img_way_title.png"),
            alt: "",
            draggable: "false"
          }
        })
      ]),
      _c("div", [
        _vm._v(
          " 1. 成功购买套餐后系统使用时长和赠送短信立即生效，登录绑定账号即可使用。"
        ),
        _c("br"),
        _vm._v(
          " 2. 成功购买任意套餐后，请联系您的专属客服领取瑜伽馆金牌销售话术礼包。"
        ),
        _c("br"),
        _vm._v(
          " 3. 活动结束后按照订单顺序依次进行盲盒礼包的发货，礼包内容随机发货，均不支持自选和退换。"
        ),
        _c("br"),
        _vm._v(" 偏远地区不包邮：内蒙古、甘肃、青海、宁夏、新疆、西藏"),
        _c("br"),
        _vm._v(" 以下地区不发货：港澳台地区、海外地区"),
        _c("br"),
        _vm._v(" 本次活动指定快递：顺丰快递"),
        _c("br")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "pay-text flex flex-nowrap row-center m-t-10" },
      [
        _c("div", [
          _c("img", {
            staticClass: "pay-icon",
            attrs: {
              src: require("@/assets/images/wxpay_icon.png"),
              alt: "",
              draggable: "false"
            }
          })
        ]),
        _c("div", { staticClass: "m-l-10" }, [_vm._v("微信支付")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }