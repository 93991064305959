var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleSettings,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "35%",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleSettings = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "title_title" }, [_vm._v("合同设置")]),
              _c(
                "div",
                {
                  staticClass: "title_icon cursor",
                  on: { click: _vm.handleClose }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            ])
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ],
              staticClass: "tags_layout"
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    "label-position": "left",
                    "label-width": "160px"
                  }
                },
                [
                  _c("p", [_c("b", [_vm._v("通知设置")])]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "会员签署后通知我" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: _vm.form.notify_store_user,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "notify_store_user", $$v)
                                  },
                                  expression: "form.notify_store_user"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "短信通知会员签署合同" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: _vm.form.enable_sms,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "enable_sms", $$v)
                                  },
                                  expression: "form.enable_sms"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("p", [_c("b", [_vm._v("签署设置")])]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { span: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "会员合同签署截至期限" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: _vm.form.sign_valid_period,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "sign_valid_period", $$v)
                                  },
                                  expression: "form.sign_valid_period"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text-info",
                              staticStyle: {
                                position: "absolute",
                                top: "30px",
                                left: "10px"
                              }
                            },
                            [
                              _vm._v(
                                " (开启后会员需在规定时间签署，否则将逾期) "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.form.sign_valid_period
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "未签署逾期时间" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      model: {
                                        value: _vm.day,
                                        callback: function($$v) {
                                          _vm.day = $$v
                                        },
                                        expression: "day"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("天内")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { span: 12 }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "会员签署时强制阅读" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: _vm.form.verify_read_seconds,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "verify_read_seconds",
                                      $$v
                                    )
                                  },
                                  expression: "form.verify_read_seconds"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text-info",
                              staticStyle: {
                                position: "absolute",
                                top: "30px",
                                left: "10px"
                              }
                            },
                            [
                              _vm._v(
                                " (开启后会员签署时需要阅读规定时间才能签署) "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.form.verify_read_seconds
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "阅读时间" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      model: {
                                        value: _vm.second,
                                        callback: function($$v) {
                                          _vm.second = $$v
                                        },
                                        expression: "second"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("秒")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex row-between m-t-20" },
                [
                  _c("div", { staticClass: "font-title" }, [
                    _vm._v("合同标签")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.is_edit ? "success" : "primary",
                        size: "mini",
                        icon: "el-icon-edit",
                        plain: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.is_edit = !_vm.is_edit
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.is_edit ? "完 成" : "编 辑") + " "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._l(_vm.tagData.list, function(item) {
                return _c(
                  "el-tag",
                  {
                    key: item.id,
                    staticClass: "m-t-10 m-r-10 m-b-10 cursor",
                    attrs: { closable: _vm.is_edit },
                    on: {
                      close: function($event) {
                        return _vm.handleEditTags(item.id)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              !_vm.is_edit
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "mini",
                        icon: "el-icon-plus",
                        plain: ""
                      },
                      on: { click: _vm.handleAddTags }
                    },
                    [_vm._v(" 添 加 ")]
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlePreserve }
                },
                [_vm._v("保 存")]
              ),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }