var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wx-config" }, [
    _c("div", { staticClass: "wx-box" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label text-success" }, [
          _vm._v("品牌有微信公众号")
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm._m(0),
            _c(
              "el-form",
              { staticClass: "demo-form-inline", attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: { disabled: true },
                      model: {
                        value: _vm.appidUrl,
                        callback: function($$v) {
                          _vm.appidUrl = $$v
                        },
                        expression: "appidUrl"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.appidUrl,
                            expression: "appidUrl",
                            arg: "copy"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.clipboardSuccess,
                            expression: "clipboardSuccess",
                            arg: "success"
                          }
                        ],
                        attrs: { type: "primary", icon: "el-icon-document" }
                      },
                      [_vm._v("复制URL地址")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "tips" }, [
              _vm._v(
                "方式二：通过微信网页约课。 复制下方URL，去微信公众号里设置菜单，会员关注公众号，验证身份即可自主约课"
              )
            ]),
            _c(
              "el-form",
              { staticClass: "demo-form-inline", attrs: { inline: true } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: { disabled: true },
                      model: {
                        value: _vm.appInfo.url,
                        callback: function($$v) {
                          _vm.$set(_vm.appInfo, "url", $$v)
                        },
                        expression: "appInfo.url"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.appInfo.url,
                            expression: "appInfo.url",
                            arg: "copy"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.clipboardSuccess,
                            expression: "clipboardSuccess",
                            arg: "success"
                          }
                        ],
                        attrs: { type: "primary", icon: "el-icon-document" }
                      },
                      [_vm._v("复制URL地址")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._m(1)
          ],
          1
        )
      ]),
      _vm._m(2),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "label text-info" }, [_vm._v("约课二维码")]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              [
                _c(
                  "el-skeleton",
                  {
                    attrs: {
                      loading: !_vm.appInfo.wechat_open_path,
                      animated: ""
                    }
                  },
                  [
                    _c("template", { slot: "template" }, [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          { staticClass: "m-r-10" },
                          [
                            _c("el-skeleton-item", {
                              staticStyle: { width: "180px", height: "180px" },
                              attrs: { variant: "image" }
                            }),
                            _c("div", { staticStyle: { padding: "14px" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-items": "space-between"
                                  }
                                },
                                [
                                  _c("el-skeleton-item", {
                                    attrs: { variant: "text" }
                                  })
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "m-r-10" },
                          [
                            _c("el-skeleton-item", {
                              staticStyle: { width: "180px", height: "180px" },
                              attrs: { variant: "image" }
                            }),
                            _c("div", { staticStyle: { padding: "14px" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-items": "space-between"
                                  }
                                },
                                [
                                  _c("el-skeleton-item", {
                                    attrs: { variant: "text" }
                                  })
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "flex row-around flex-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "m-r-10" },
                        [
                          _c(
                            "div",
                            {
                              ref: "wechat_open_path",
                              staticClass: "image_box"
                            },
                            [
                              _c("el-image", {
                                staticClass: "yueke_image",
                                attrs: {
                                  "preview-src-list": [
                                    _vm.appInfo.wechat_open_path +
                                      "?" +
                                      new Date().getTime()
                                  ],
                                  src:
                                    _vm.appInfo.wechat_open_path +
                                    "?" +
                                    new Date().getTime(),
                                  alt: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-link",
                            {
                              staticClass: "image_down",
                              attrs: {
                                icon: "el-icon-download",
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadCodeImg("wechat_open_path")
                                }
                              }
                            },
                            [_vm._v("点击下载二维码")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "m-r-10" },
                        [
                          _c(
                            "div",
                            {
                              ref: "program_open_path",
                              staticClass: "image_box"
                            },
                            [
                              _c("el-image", {
                                staticClass: "yueke_image",
                                attrs: {
                                  "preview-src-list": [
                                    _vm.appInfo.program_open_path +
                                      "?" +
                                      new Date().getTime()
                                  ],
                                  src:
                                    _vm.appInfo.program_open_path +
                                    "?" +
                                    new Date().getTime(),
                                  alt: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-link",
                            {
                              staticClass: "image_down",
                              attrs: {
                                icon: "el-icon-download",
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadCodeImg(
                                    "program_open_path"
                                  )
                                }
                              }
                            },
                            [_vm._v("点击下载二维码")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                )
              ]
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _vm._v("方式一：通过小程序约课。登录公众号平台 -> "),
      _c("a", { staticClass: "text-primary", attrs: { href: "#relevance" } }, [
        _vm._v("公众号关联小程序")
      ]),
      _vm._v(
        " -> 设置自定义菜单 -> 选择跳转小程序跳转路径为下方URL -> 备用网页可用方式二的网址 -> 保存发布"
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips p-t-0" }, [
      _vm._v("方式三： 保存约课二维码图片，在"),
      _c("span", { staticClass: "text-primary" }, [_vm._v("公众号")]),
      _vm._v(" -> "),
      _c("span", { staticClass: "text-primary" }, [_vm._v("自定义菜单")]),
      _vm._v("中配置该图片，会员点击该菜单即可扫描约课二维码预约")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item flex" }, [
      _c("div", { staticClass: "flex-1" }, [
        _c("div", { staticClass: "label text-warning" }, [
          _vm._v("品牌没有微信公众号")
        ]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "tips" }, [
            _c("div", { staticClass: "m-b-6" }, [
              _vm._v("打开"),
              _c("span", { staticClass: "text-primary" }, [_vm._v("微信")]),
              _vm._v(" -> "),
              _c("span", { staticClass: "text-primary" }, [
                _vm._v("扫描二维码")
              ]),
              _vm._v(
                "进入约课，点击右上角“…”分享给会员，会员点击即可查看课表并约课"
              )
            ]),
            _c("div", { staticClass: "text-danger m-b-6 tip" }, [
              _vm._v("您也可以新建自己的微信公众号，注册地址: "),
              _c(
                "a",
                {
                  staticClass: "text-primary",
                  attrs: { href: "https://mp.weixin.qq.com", target: "_blank" }
                },
                [_vm._v("https://mp.weixin.qq.com")]
              ),
              _vm._v(
                "，个人公众号（免费）、企业公众号（300元/年，由微信平台收取）"
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }