<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索流水号" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
        <!-- <div @click="$router.push({ name: 's_withdraw_apply'})">去提现</div> -->
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData.list"
      stripe
      row-key="id"
    >
      <el-table-column prop="order_no" label="提现单号" />
      <el-table-column prop="money" label="提现金额" />
      <el-table-column prop="create_time" label="提现时间" />
      <el-table-column prop="payment_time" label="审核时间" :formatter="paymentTimeFormatter" />
      <el-table-column prop="payment" label="提现方式">
        <template slot-scope="">
          微信零钱
        </template>
      </el-table-column>
      <el-table-column prop="store_user.real_name" label="提现人员" />
      <el-table-column prop="status" label="状态">
        <template slot-scope="{row}">
          <el-tag v-if="row.status===0">未处理</el-tag>
          <el-tag v-if="row.status===1" type="success">已处理</el-tag>
          <div class="cursor" @click="showMsg(row)"> <el-tag v-if="row.status===2" type="danger">已驳回</el-tag></div>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { getWithdraw } from '@/api/store/management.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  components: { Pagination },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      searchLoading: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'order_no', type: 'like', key: this.listQueryParams.query })

      data.query = this.utils.getQueryParams(querys)
      getWithdraw(data).then(res => {
        this.searchLoading = false
        const { data } = res
        this.tableData = data
      }).catch(() => {})
    },
    paymentTimeFormatter(row) {
      return row.payment_time !== '0000-00-00 00:00:00' ? row.payment_time : ''
    },
    // 查看驳回理由
    showMsg(row) {
      this.$alert('驳回理由：' + (row.content ? row.content : '无'), `订单号:${row.order_no}`, {
        confirmButtonText: '确定'
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
