<template>
  <div>
    <!-- 租赁/归还储物柜 -->
    <el-dialog
      :title="title"
      :visible.sync="value"
      width="440px"
      :close-on-click-modal="false"
      :before-close="close"
    >
      <div>
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item v-if="info" label="柜编号">
            <el-input v-model="info.serial_number" disabled />
          </el-form-item>
          <el-form-item label-width="80px" label="租赁用户" prop="user_id">
            <el-select
              v-model="form.user_id"
              filterable
              placeholder="请选择租赁用户"
              style="width:100%"
            >
              <el-option
                v-for="(item,index) in userList"
                :key="index"
                :label="getUserNames(item)"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="3"
              placeholder="请输入备注"
              maxlength="100"
              show-word-limit
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :loading="btn_loading" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUser } from '@/api/store/user.js'
import { lockerLeaseRecord } from '@/api/store/locker.js'
export default {
  name: 'Lease',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1
    },
    id: {
      type: Number,
      default: 1
    },
    userId: {
      type: Number,
      default: 1
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      btn_loading: false,
      userList: {},
      search_loading: false,
      form: {
        locker_id: 1,
        user_id: '',
        remark: '',
        type: 1,
        venues_id: this.utils.getVenues().id
      },
      rules: {
        user_id: [
          { required: true, message: '请选择用户', trigger: ['blur', 'change'] }
        ]
      },
      listQueryParams: {
        user_id: '',
        query: ''
      }
    }
  },
  computed: {
    title: function() {
      return this.type === 1 ? '储物柜租赁' : '储物柜归还'
    }
  },
  watch: {
    userId(v, o) {
      this.listQueryParams.user_id = v
      this.form.user_id = v || ''
      this.getUserList()
    }
  },
  created() {
  },
  methods: {
    getUserList() {
      this.search_loading = true
      const data = { limit: 999 }
      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'phone|real_name|nickname', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.user_id) querys.push({ field: 'id', key: this.listQueryParams.user_id })
      data.query = this.utils.getQueryParams(querys)

      getUser(data).then(res => {
        this.userList = res.data.list
      }).finally(() => {
        this.search_loading = false
      })
    },
    // 租赁/归还
    add() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btn_loading = true
          const data = this.form
          data.locker_id = this.id
          data.type = this.type
          lockerLeaseRecord(data).then(res => {
            this.$message.success(res.msg)
            this.$emit('input', false)
            this.$emit('getList')
            this.$emit('getLogList')
          }).finally(() => {
            this.btn_loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style>

</style>
