var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "errPage-container text-center" }, [
    _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: _vm.errGif,
          width: "250",
          height: "250",
          alt: "Girl has dropped her ice cream."
        }
      })
    ]),
    _c("div", { staticClass: "title font-md m-20" }, [
      _vm._v("系统升级维护中……")
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "before" }, [_vm._v("尊敬的小九用户：")]),
      _c("div", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.msg))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }