<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <van-form label-width="70px" class="window_phone">
            <!-- 创建时间 -->
            <van-field readonly clickable name="calendar" :value="vanCreationTime" label="创建时间：" placeholder="点击选择创建时间" @click="vanCreationTimeShow = true" />
            <van-calendar
              v-model="vanCreationTimeShow"
              type="range"
              :min-date="minVanCreationTime"
              :max-date="maxVanCreationTime"
              color="#1989fa"
              confirm-text="确 定"
              confirm-disabled-text="请选择结束时间"
              @confirm="onCreation"
            />
          </van-form>
          <el-form-item label="发送时间" class="window_pc">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-form-item label="发送类型">
            <el-select v-model="listQueryParams.sms_id" placeholder="请选择" filterable clearable>
              <el-option v-for="(item, index) in tableData.type_list" :key="index" :label="item.zh_name" :value="item.sms_id" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否有效">
            <el-select v-model="listQueryParams.fail" placeholder="请选择" filterable clearable>
              <el-option label="发送成功" :value="0" />
              <el-option label="发送失败" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="发送渠道">
            <el-select v-model="listQueryParams.provider" placeholder="请选择" filterable clearable>
              <el-option v-for="(item, index) in provider_list" :key="index" :label="item.zh_name" :value="item.provider" />
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="手机号 | 发送内容" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button type="primary" class="filter-item m-l-10" @click="dialogVisible = true">查看验证码</el-button>
    </div>
    <el-table ref="multipleTable" v-loading="loading.listLoading" :data="tableData.list" row-key="id">
      <el-table-column prop="phone" label="手机号" min-width="100" />
      <el-table-column prop="content" label="发送内容" min-width="320">
        <template slot-scope="{ row }">
          <div class="line-1" :title="row.content_text" @click="handleCopy(row.content_text, '发送内容复制成功')">
            <!-- {{ formatContent(row) }} -->
            {{ row.content_text }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="ip" label="IP" /> -->
      <!-- <el-table-column prop="type" label="类型">
        <template slot-scope="{row}">
          {{ typeList[row.type] }}
        </template>
      </el-table-column> -->
      <el-table-column prop="template_name" label="模版名称" min-width="120">
        <template slot-scope="{ row }">
          <div v-if="row.template">
            {{ row.template.zh_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="是否有效" min-width="90">
        <template slot-scope="{ row }">
          <el-tag v-if="!row.fail">发送成功</el-tag>
          <el-tag v-else type="danger">发送失败：{{ row.fail_reason }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="发送时间" min-width="100" />
      <el-table-column prop="provider" label="渠道商" min-width="80" />
      <el-table-column prop="" label="操作" min-width="90">
        <template slot-scope="{ row }">
          <!-- 核销 -->
          <el-popconfirm v-if="row.can_be_resent && btn_access('sms_resend')" title="确定重发短信吗？" @confirm="handleResendSMS(row.id)">
            <el-button slot="reference" class="m-2" type="success">
              重发短信
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count > 0 && equipment" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <van-pagination
      v-show="tableData.count > 10 && !equipment"
      v-model="AccountPage"
      :total-items="tableData.count"
      :show-page-size="4"
      force-ellipses
      class="custom-pagination m-t-20"
      @change="handleTableDataChange"
    />

    <el-dialog title="验证码" :visible.sync="dialogVisible" :close-on-click-modal="false" :width="equipment ? '40%' : '100%'" @open="handleOpen">
      <div class="searchBox p-b-10">
        <div class="fix">
          <div>手机：</div>
          <i class="icon-required" />
          <Code code="''" @area_code="area_code" />
          <el-input ref="phone" v-model="resetPwdForm.phone" placeholder="手机号" name="phone" type="text" tabindex="1" autocomplete="on" style="width: 250px;" />
        </div>
        <div class="flex m-t-20">
          <div class="flex">
            <div>类型：</div>
            <el-select v-model="resetPwdForm.type" placeholder="请选择" filterable clearable>
              <el-option label="系统级别" :value="1" />
              <el-option label="模板级别" :value="2" />
              <el-option label="下载文档" :value="3" />
              <el-option label="申请品牌验证" :value="4" />
              <el-option label="验证码登录注册" :value="5" />
            </el-select>
          </div>
          <div class="flex m-l-10">
            <div style="min-width:35px">IP：</div>
            <el-input ref="ip" v-model="resetPwdForm.ip" placeholder="IP地址" name="ip" type="text" tabindex="1" autocomplete="on" />
          </div>
        </div>
        <div class="flex m-y-20">
          <div>是否使用：</div>
          <el-select v-model="resetPwdForm.is_valid" placeholder="请选择" filterable clearable>
            <el-option label="是" :value="1" />
            <el-option label="否" :value="0" />
          </el-select>
        </div>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" @click="handleFilter1">搜索</el-button>
        <el-button v-show="btn_access('ip_blacklist')" type="primary" class="filter-item m-l-10" @click="dialogBlacklistVisible = true">黑名单管理</el-button>
      </div>
      <el-table ref="multipleTable" v-loading="smsLoading.listLoading" class="m-t-20" :data="smsData.list" row-key="id">
        <el-table-column prop="phone" label="手机号" min-width="120">
          <template slot-scope="{ row }">
            <div><span v-if="row.area_code!==86">+{{ row.area_code }}</span> <span class="cursor" @click="handleCopy(row.phone, '手机号复制成功')">{{ row.phone }}</span></div>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="验证码" min-width="90">
          <template slot-scope="{ row }">
            <div class="cursor" @click="handleCopy(row.code, '验证码复制成功')">{{ row.code }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" min-width="90">
          <template slot-scope="{ row }">
            <div v-if="row.type === 1">系统类型</div>
            <div v-if="row.type === 2">模板级别</div>
            <div v-if="row.type === 3">下载文档</div>
            <div v-if="row.type === 4">申请品牌验证</div>
            <div v-if="row.type === 5">验证码登录注册</div>
          </template>
        </el-table-column>

        <el-table-column prop="valid_time" label="有效时间" min-width="140">
          <template slot-scope="{ row }">
            <div v-if="getExcessTimeMinute(row.valid_time) > 0">{{ getExcessTimeMinute(row.valid_time) }} 分钟</div>
            <div v-else>{{ row.valid_time }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="is_used" label="是否使用" min-width="90">
          <template slot-scope="{ row }">
            <el-tag v-if="row.is_used == 1">是</el-tag>
            <el-tag v-if="row.is_used == 0" type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="provider" label="渠道" min-width="60">
          <template slot-scope="{ row }">
            {{ row.provider }}
          </template>
        </el-table-column>
        <el-table-column prop="ip" label="IP" min-width="120">
          <template slot-scope="{ row }">
            {{ row.ip }}
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="smsData.count > 0 && equipment" :total="smsData.count" :page.sync="listQuerySms.page" :limit.sync="listQuerySms.limit" @pagination="handleOpen" />
      <van-pagination
        v-show="smsData.count > 10 && !equipment"
        v-model="AccountPage"
        :total-items="smsData.count"
        :show-page-size="4"
        force-ellipses
        class="custom-pagination m-t-20"
        @change="handleSmsDataChange"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog title="黑名单管理" :visible.sync="dialogBlacklistVisible" :close-on-click-modal="false" :width="equipment ? '40%' : '100%'">

      <el-form ref="ruleForm" :model="ipBlacklistForm" :rules="ipBlacklistFormRules" class="m-t-20">
        <el-form-item label="IP：" label-width="60px" prop="ip">
          <el-input v-model="ipBlacklistForm.ip" placeholder="192.168.x.x" class="w100" />
        </el-form-item>
        <el-form-item label="类型：" label-width="60px">
          <el-select v-model="ipBlacklistForm.unblock" placeholder="请选择" filterable clearable>
            <el-option label="拉黑" :value="0" />
            <el-option label="解封" :value="1" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="blacklistLoading" @click="blacklistSubmit">提 交</el-button>
        <el-button @click="dialogBlacklistVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { record, resend, code, ip_blacklist } from '@/api/admin/sms.js'
import Pagination from '@/components/Pagination' // 分页组件
import { timestampToTimes } from '@/utils/takeleave'
// 组件
import Code from '@/components/code/index.vue'
export default {
  components: { Pagination, Code },
  data() {
    return {
      tableData: {
        list: [],
        type_list: [],
        count: 0
      },
      ipBlacklistForm: {
        ip: '',
        unblock: 0
      },
      ipBlacklistFormRules: {
        ip: [{ required: true, message: '请输入IP', trigger: ['blur', 'change'] }]
      },
      provider_list: [
        {
          zh_name: '歆阳',
          provider: 'xy'
        }, {
          zh_name: '阿里云',
          provider: 'aliyun'
        }, {
          zh_name: 'ucloud',
          provider: 'ucloud'
        }
      ],
      smsData: {
        list: [],
        count: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      dialog: { visible: false, id: '' },
      loading: { listLoading: false },
      smsLoading: { listLoading: false },
      blacklistLoading: false,
      typeList: ['', '系统级别', '模版级别', '下载文档'],
      searchLoading: false,
      dialogVisible: false,
      dialogBlacklistVisible: false,
      listQueryParams: {
        create_time: []
      },
      listQuerySms: {
        page: 1,
        limit: 10,
        offset: 0
      },
      resetPwdForm: {
        ip: '',
        area_code: '',
        phone: '',
        type: '',
        is_valid: ''
      },
      account: {
        dialogVisible: false,
        loading: false,
        count: 0,
        list: [],
        store_user_id: '',
        appid: ''
      },
      AccountPage: 1,
      equipment: false,
      // 日期格式
      minVanCreationTime: new Date(2000, 0, 1),
      maxVanCreationTime: new Date(2099, 12, 31),
      // 时间
      vanCreationTime: '',
      vanCreationTimeShow: false
    }
  },
  created() {
    // 判断页面是否大于768
    if (document.body.clientWidth > 768) {
      this.equipment = true
    } else {
      this.equipment = false
    }

    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    this.listQueryParams.create_time = [timestampToTimes(start.getTime()).slice(0, 10), timestampToTimes(end.getTime()).slice(0, 10)]
    this.vanCreationTime = `${timestampToTimes(start.getTime()).slice(0, 10)} 至 ${timestampToTimes(end.getTime()).slice(0, 10)}`
    this.listQueryParams.fail = 0
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 弹窗打开的回调
    handleOpen() {
      this.smsLoading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuerySms))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      const t = deepClone(this.resetPwdForm)
      var querys = []

      if (t.ip) querys.push({ field: 'ip', type: 'like', key: t.ip })
      if (t.phone) querys.push({ field: 'phone', type: 'like', key: t.phone })
      if (t.area_code) querys.push({ field: 'area_code', key: t.area_code })
      if (t.type) querys.push({ field: 'type', key: t.type })
      if (t.is_valid !== '' && t.is_valid !== null) querys.push({ field: 'usage_count', type: (t.is_valid ? 'egt' : 'elt'), key: t.is_valid })

      data.query = this.utils.getQueryParams(querys)

      code(data)
        .then(res => {
          this.smsData = res.data
        })
        .finally(() => {
          this.smsLoading.listLoading = false
        })
    },
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      const t = deepClone(this.listQueryParams)

      console.log(this.vanCreationTime)

      var querys = []
      if (document.body.clientWidth > 768) {
        if (t.create_time) querys.push({ field: 'create_time', type: 'between-time', key: t.create_time })
      } else {
        if (this.vanCreationTime) {
          const vanCreationTime = this.vanCreationTime.split('至')
          querys.push({ field: 'create_time', type: 'between-time', key: vanCreationTime })
        }
      }

      if (t.query) querys.push({ field: 'phone|content_text', type: 'like', key: t.query })
      if (t.sms_id) querys.push({ field: 'sms_id', key: t.sms_id })
      if (t.provider) querys.push({ field: 'provider', key: t.provider })
      if (t.fail !== '' && t.fail !== null) querys.push({ field: 'fail', key: t.fail })

      data.query = this.utils.getQueryParams(querys)

      record(data)
        .then(res => {
          this.tableData = res.data
          this.loading.listLoading = false
        })
        .finally(() => {
          this.searchLoading = false
        })
    },
    formatContent(row) {
      if (row.content) {
        var arr
        try {
          arr = JSON.parse(row.content)
          if (typeof arr === 'object') {
            return JSON.stringify(arr)
          }
        } catch (e) {
          arr = row.content
        }
        return arr
      }
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleTableDataChange(val) {
      this.listQuery.page = val
      this.getList()
    },
    handleSmsDataChange(val) {
      this.listQuery.page = val
      this.handleOpen()
    },
    handleResendSMS(id) {
      resend(id).then(() => {
        this.$message.success('重发成功')
        this.getList()
      })
    },
    area_code(val) {
      this.resetPwdForm.area_code = val
    },
    handleFilter1() {
      this.listQuerySms.page = 1
      this.handleOpen()
    },
    // 日期格式
    formatDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    // 时间
    onCreation(date) {
      const [start, end] = date
      this.vanCreationTimeShow = false
      this.vanCreationTime = `${this.formatDate(start)} 至 ${this.formatDate(end)}`
    },
    // 黑名单管理提交
    blacklistSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.blacklistLoading = true
          ip_blacklist(this.ipBlacklistForm).then(res => {
            this.$message.success(res.msg)
            this.dialogBlacklistVisible = false
            this.blacklistLoading = false
          })
            .finally(() => {
              this.blacklistLoading = false
            })
        }
      })
    }
  }
}
</script>

<style>
.el-table .info-row {
  background: #95a5a6;
}
.el-table .warning-row {
  background: #fdf5e6;
}
.el-table .danger-row {
  background: #e7d9d9;
}
.el-table .success-row {
  background: #f0f9eb;
}
.custom-pagination .van-pagination__prev,
.custom-pagination .van-pagination__next {
  font-size: 12px; /* 自定义按钮文字大小 */
}
</style>

<style lang="scss" scoped>
.el-input-group__append,
.el-input-group__prepend {
  width: 70px;
}

.fix {
  display: flex;
  align-items: center;
  /* border: 1px solid #dae0e6; */
  border-radius: 4px;
  .CodeList {
    width: 100px;
    background: transparent;
    border-right: none;
    .el-input {
      .el-input__inner {
        border: none !important;
      }
    }
  }
}

.van-cell {
  padding: 10px 0;
  ::v-deep {
    .van-cell__title {
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .window_pc {
    display: none;
  }
  .borderX {
    width: 100%;
    border-radius: 6px;
    margin: 5px 1%;
  }
}
@media screen and (min-width: 768px) {
  .window_phone {
    display: none;
  }

  .borderX {
    width: 48%;
    border-radius: 6px;
    margin: 5px 1%;
  }
}
</style>
