<template>
  <el-tabs v-model="activeNameS" tab-position="top">
    <el-tab-pane label="公众号信息" name="publicAccountInformation">
      <publicAccountInformation v-if="activeNameS == 'publicAccountInformation'" @toPage="toPage" />
    </el-tab-pane>
    <el-tab-pane label="公众号配置" name="PublicNumberConfiguration">
      <PublicNumberConfiguration v-if="activeNameS == 'PublicNumberConfiguration'" />
    </el-tab-pane>
    <el-tab-pane label="公众号菜单" name="publicAccountAllocation">
      <publicAccountAllocation v-if="activeNameS == 'publicAccountAllocation'" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import publicAccountInformation from './components/publicAccountInformation'
import publicAccountAllocation from './components/publicAccountAllocation'
import PublicNumberConfiguration from './components/PublicNumberConfiguration'

export default {
  components: {
    publicAccountInformation,
    publicAccountAllocation,
    PublicNumberConfiguration
  },
  data() {
    return {
      activeNameS: 'publicAccountInformation'
    }
  },
  watch: {
    activeNameS(val) {
      this.$cookies.set('publicAccount', val)
    }
  },
  created() {
    this.activeNameS = this.$cookies.get('publicAccount') || 'publicAccountInformation'
  },
  mounted() {},
  methods: {
    toPage() {
      this.activeNameS = 'PublicNumberConfiguration'
    }
  }
}
</script>

<style lang="scss" scoped></style>
