import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/* Router Modules */
import brand from './modules/brand'
import admin from './modules/admin'
import store from './modules/store'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * 首页
 * @returns title
 */
const dashboard = () => {
  let title = 'dashboard'
  const Venues = JSON.parse(localStorage.getItem('Venues'))
  if (Venues) {
    if (Venues.id === -1) title = '品牌管理'
  }
  return title
}
/**
 * constantRoutes
 * 没有权限要求的基本页
 * 所有角色可以访问
 */
export const constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/index')
  }]
},
{
  path: '/login',
  component: () => import('@/views/login/index'),
  hidden: true
},
{
  path: '/resetpwd',
  component: () => import('@/views/resetpwd/index'),
  hidden: true
},
{
  path: '/verificationCode',
  component: () => import('@/views/verificationCode/index'),
  hidden: true
},
{
  path: '/auth-redirect',
  component: () => import('@/views/login/auth-redirect'),
  hidden: true
},
{
  path: '/401',
  component: () => import('@/views/error-page/401'),
  hidden: true
},
// 系统维护
{
  path: '/410',
  component: () => import('@/views/error-page/410'),
  hidden: true
},
// 品牌禁止
{
  path: '/601',
  component: () => import('@/views/error-page/601'),
  hidden: true
},
// 品牌过期
{
  path: '/602',
  component: () => import('@/views/error-page/602'),
  hidden: true
},
// 升级品牌
{
  path: '/603',
  component: () => import('@/views/error-page/603'),
  hidden: true
},
{
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: () => import('@/views/profile/index'),
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
},
{
  path: '/profileAdmin',
  component: Layout,
  redirect: '/profileAdmin/index',
  hidden: true,
  children: [{
    path: 'index',
    component: () => import('@/views/profileAdmin/index'),
    name: 'profileAdmin',
    meta: {
      title: 'profileAdmin',
      icon: 'user',
      noCache: true
    }
  }]
},
{
  name: 's_work',
  path: '/store/work',
  component: Layout,
  redirect: '/store/work/console/group',
  meta: {
    icon: 'el-icon-bell',
    roles: ['store'],
    noCache: true
  },
  hidden: true,
  children: [
    {
      path: 'console',
      name: 's_work_index',
      component: () => import('@/views/store/worktable/index'),
      meta: {
        activeMenu: '/dashboard'
      },
      hidden: true,
      children: [{
        path: 'group',
        component: () => import('@/views/store/worktable/group/index'),
        name: 's_work_group',
        meta: {
          title: 's_work_group',
          activeMenu: '/dashboard'
        },
        hidden: true
      }, {
        path: 'boutique',
        component: () => import('@/views/store/worktable/boutique/index'),
        name: 's_work_boutique',
        meta: {
          title: 's_work_boutique',
          activeMenu: '/dashboard'
        },
        hidden: true
      }, {
        path: 'private',
        component: () => import('@/views/store/worktable/private/index'),
        name: 's_work_private',
        meta: {
          title: 's_work_private',
          activeMenu: '/dashboard'
        },
        hidden: true
      }, {
        path: 'class',
        component: () => import('@/views/store/worktable/class/index'),
        name: 's_work_class',
        meta: {
          title: 's_work_class',
          activeMenu: '/dashboard'
        },
        hidden: true
      },
      // ---------------------------------------------------------
      {
        path: 'inquire',
        component: () => import('@/views/store/worktable/inquire'),
        name: 's_work_inquire',
        meta: {
          title: 's_work_inquire',
          activeMenu: '/dashboard'
        },
        hidden: true
      }, {
        path: 'inquireTrackList',
        component: () => import('@/views/store/worktable/inquire/trackList'),
        name: 's_inquire_track',
        meta: {
          title: 's_inquire_track',
          activeMenu: '/dashboard'
        },
        hidden: true
      },
      // ---------------------------------------------------------
      {
        path: 'leaveManagement',
        component: () => import('@/views/store/takeleave/leaveManagement'),
        name: 's_leaveManagement',
        meta: {
          title: 's_leaveManagement',
          activeMenu: '/dashboard'
        },
        hidden: true
      }, {
        path: 'leaveManagement/edit/:id(\\d+)',
        component: () => import('@/views/store/takeleave/edit'),
        name: 's_takeLeave_edit',
        meta: {
          title: 's_takeLeave_edit',
          activeMenu: '/dashboard'
        },
        hidden: true
      }, {
        path: 'penalty',
        name: 's_work_penalty',
        component: () => import('@/views/store/worktable/penalty'),
        meta: {
          title: 's_work_penalty',
          activeMenu: '/dashboard'
        },
        hidden: true
      }, {
        path: 'locker',
        name: 's_work_locker',
        component: () => import('@/views/store/worktable/locker'),
        meta: {
          title: 's_work_locker',
          activeMenu: '/dashboard'
        },
        hidden: true
      }]
    }

  ]
},
{
  path: '/store/sys_notice',
  name: 's_sys_notice',
  component: Layout,
  meta: {
    // title: 's_sys_notice',
    roles: ['store', 'brand']
  },
  hidden: true,
  children: [{
    path: 'index',
    component: () => import('@/views/store/notice/system/index'),
    name: 's_sys_notice',
    meta: {
      title: 's_sys_notice'
    },
    hidden: true
  }, {
    path: 'info/:id(\\d+)',
    component: () => import('@/views/store/notice/system/info'),
    name: 's_sys_notice_info',
    meta: {
      title: 's_sys_notice_info'
    },
    hidden: true
  }]
},
{
  path: '/404',
  component: () => import('@/views/error-page/404'),
  hidden: true
},
{
  path: '/store/activity',
  component: () => import('@/views/store/activity/index'),
  hidden: true
}
]
// 短信管理
export function constantRoutesSms() {
  return {
    name: 's_sms',
    path: '/store/sms',
    component: Layout,
    alwaysShow: true,
    redirect: '/store/sms/config',
    meta: {
      title: 's_sms',
      icon: 'el-icon-chat-line-round',
      roles: ['brand', 'store']
    },
    hidden: localStorage.getItem('authority') === 'admin',
    children: [
      {
        path: 'config',
        component: () => import('@/views/store/sms/config'),
        name: 's_sms_config',
        meta: {
          title: 's_sms_config',
          roles: ['store', 'brand']
        }
      },
      {
        path: 'log',
        component: () => import('@/views/store/sms/log'),
        name: 's_sms_log',
        meta: {
          title: 's_sms_log',
          roles: ['store', 'brand']
        }
      }, {
        path: 'buy',
        component: () => import('@/views/store/sms/buy'),
        name: 's_sms_buy',
        meta: {
          title: 's_sms_buy',
          roles: ['store', 'brand']
        }
      }, {
        path: 'buy_log',
        component: () => import('@/views/store/sms/buy_log'),
        name: 's_sms_buy_log',
        meta: {
          title: 's_sms_buy_log',
          roles: ['store', 'brand']
        }
      }]
  }
}
// 海报生成
export function posterRoutes() {
  return {
    name: 's_poster_generation',
    path: '/store/posterGeneration',
    component: Layout,
    redirect: '/store/posterGeneration',
    children: [
      {
        path: 'index',
        component: () => import('@/views/store/posterGeneration/index.vue'),
        name: 's_poster_generation',
        meta: {
          title: 's_poster_generation',
          icon: 'el-icon-picture-outline',
          roles: ['store']
        },
        hidden: localStorage.getItem('authority') === 'admin'
      },
      {
        path: 'edit/:id(\\d+)/:activityId(\\d+)/:uId(\\d+)',
        component: () => import('@/views/store/posterGeneration/components/edit.vue'),
        name: 's_poster_generation_edit',
        meta: {
          title: 's_poster_generation_edit',
          roles: ['store'],
          activeMenu: '/store/posterGeneration/index'
        },
        hidden: true
      }
    ]
  }
}
// 套餐购买
export function constantRoutesPlan() {
  return {
    name: 's_plan',
    path: '/store/plan',
    component: Layout,
    redirect: '/store/plan',
    children: [{
      path: 'index',
      component: () => import('@/views/store/plan/index'),
      name: 's_plan',
      meta: {
        title: 's_plan',
        icon: 'el-icon-goods',
        roles: ['store', 'brand']
      },
      hidden: localStorage.getItem('authority') === 'admin'
    }]
  }
}
// 工作台
export function constantRoutesDashboard() {
  return {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      component: () => import('@/views/dashboard/index'),
      name: 'Dashboard',
      meta: {
        title: `${dashboard()}`,
        icon: 'el-icon-odometer',
        affix: true
      },
      hidden: localStorage.getItem('authority') === 'admin'
    }, {
      path: 'bill_details',
      component: () => import('@/views/store/data/bill_details/index.vue'),
      name: 's_bill_details',
      meta: {
        title: 's_bill_details',
        noCache: true,
        activeMenu: '/dashboard'
      },
      hidden: true
    }]
  }
}
// 404
export const constantRoutes404 = [
  { path: '*', redirect: '/404', hidden: true }
]

/**
 * 动态路由合并
 * @param {*} router 动态路由
 * @returns
 */
export function constantRoutesConcat(router) {
  return constantRoutes.concat(constantRoutesDashboard()).concat(router).concat(constantRoutesSms()).concat(posterRoutes()).concat(constantRoutesPlan()).concat(constantRoutes404)
}

/**
 * asyncRoutes
 * 需要根据用户角色动态加载的路由
 */
export const asyncRoutes = concat()
// 所有路由
export function concat() {
  console.log("🚀 ~ file: index.js ~ line 121 ~ concat ~ localStorage.getItem('is_super')", localStorage.getItem('is_super'), typeof localStorage.getItem('is_super'))
  if (localStorage.getItem('is_super') !== 'false') {
    return brand.concat(admin).concat(store)
  } else {
    return brand.concat(admin)
  }
}
const createRouter = () => new Router({
  // 打包成exe文件时，需要使用hash模式
  mode: 'history', // require service support
  // mode: 'hash',
  base: process.env.VUE_APP_PUBLIC_PATH,
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
