import request from '@/utils/request'
// import Utils from '@/utils/utils.js'

// 获取体测模板
export function getSideTemplate(data) {
  return request({
    url: '/store/v1/side_template',
    method: 'get',
    params: data
  })
}

// 添加体测模板
export function addSideTemplate(data) {
  return request({
    url: '/store/v1/side_template',
    method: 'post',
    data
  })
}

// 修改体测模板
export function editSideTemplate(id, data) {
  return request({
    url: `/store/v1/side_template/${id}`,
    method: 'put',
    data
  })
}

// 删除体测模版
export function delSideTemplate(id, data) {
  return request({
    url: `/store/v1/side_template/${id}`,
    method: 'delete',
    data
  })
}

// 获取用户体测数据列表
export function getUserSide(id, data) {
  return request({
    url: `/store/v1/user_side/user_id/${id}`,
    method: 'get',
    params: data
  })
}

// 获取用户体侧详情
export function getSideInfo(id, data) {
  return request({
    url: `/store/v1/user_side/${id}`,
    method: 'get',
    params: data
  })
}

// 添加用户体测数据
export function addUserSide(data) {
  return request({
    url: `/store/v1/user_side`,
    method: 'post',
    data
  })
}

// 修改用户体测数据
export function editUserSide(id, data) {
  return request({
    url: `/store/v1/user_side/${id}`,
    method: 'put',
    data
  })
}

// 删除用户体测数据
export function deleteUserSide(id, data) {
  return request({
    url: `/store/v1/user_side/${id}`,
    method: 'delete',
    data
  })
}
