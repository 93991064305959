<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button v-show="btn_access('banner_add')" v-waves class="filter-item m-r-10" type="primary" icon="el-icon-plus" @click="add_edit">
        添加
      </el-button>
    </div>
    <!-- 列表 -->
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column prop="src" label="图片">
        <template slot-scope="scope">
          <el-image
            :style="{width:'120px','border-radius':'4px'}"
            :src="scope.row.src"
            fit="fill"
          />
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="跳转链接">
        <template slot-scope="{row}">
          <span>{{ row.path?row.path:'无' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column prop="weigh" label="排序" />
      <el-table-column prop="channel" label="平台" />

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('banner_edit')" type="primary" icon="el-icon-edit" class="m-2" @click="add_edit(scope.row.id)">编辑</el-button>
          <el-popconfirm
            v-show="btn_access('banner_del')"
            title="这是一段内容确定删除吗？"
            @confirm="banner_delete(scope.row.id)"
          >
            <el-button slot="reference" type="danger" icon="el-icon-delete" class="m-2">删除</el-button>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <!-- 添加、编辑弹窗 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.id ? '编辑轮播图' : '添加轮播图'"
      class="dialog_w750"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="图片" prop="src">
          <el-upload
            class="avatar-uploader"
            :action="adminUpload"
            :show-file-list="false"
            :on-success="upSuccess"
            :headers="utils.upload_headers()"
            accept=".jpg,.jpeg,.png"
          >
            <img v-if="form.src" :src="form.src" class="avatar" title="点击上传图片">
            <i v-else class="el-icon-plus avatar-uploader-icon" style="border: 1px dashed #d9d9d9;" />
          </el-upload>
        </el-form-item>
        <el-form-item label="排序" prop="weigh">
          <el-input v-model="form.weigh" type="number" placeholder="从大到小" />
        </el-form-item>
        <el-form-item label="发布平台" prop="channel">
          <el-select v-model="form.channel" multiple placeholder="请选择">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="跳转路径" prop="path">
          <el-input v-model="form.path" placeholder="请填写跳转路径" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button v-loading="loading.btnLoading" type="primary" @click="submit">
          {{ dialog.id ? '保存' : '新增' }}
        </el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getBanner, getBannerInfo, addBanner, editBanner, delBanner } from '@/api/admin/banner.js'
import Pagination from '@/components/Pagination' // 分页组件
import { deepClone } from '@/utils'
export default {
  components: { Pagination },
  data() {
    return {
      form: {
        src: '',
        weigh: 1,
        channel: ''
      },
      tableData: {
        list: [],
        count: 0
      },
      is_edit: 0,
      dialog: { visible: false, id: '', addVisible: false, editVisible: false, infoVisible: false },
      rules: {
        src: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ]
      },
      loading: { listLoading: false, searchLoading: false, btnLoading: false },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      typeOptions: [{
        value: 'app',
        label: 'APP'
      }, {
        value: 'web',
        label: 'WEB'
      }]
    }
  },
  computed: {
    mainUrlSrc() {
      if (this.mainUrl && this.src.indexOf('http') === -1) {
        return this.mainUrl + this.form.src
      }
      return this.src
    }
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    upSuccess(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.form.src = response.data.path
    },
    // 搜索
    handleFilter() {

    },
    getList() {
      this.loading.listLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      getBanner(data).then(res => {
        // 数据嵌入
        this.tableData = res.data
        this.loading.listLoading = false
      }).catch(() => {})
    },
    banner_delete(id) {
      delBanner(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      }).catch(() => {})
    },
    add_edit(id) {
      this.form.src = ''
      this.is_edit = 0
      this.dialog = { visible: true, id: id, addVisible: false, editVisible: false, infoVisible: false }
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })

      if (id > 0) {
        this.loading.listLoading = true
        this.is_edit = 1
        getBannerInfo(id).then(res => {
          this.form = res.data
          this.form.channel = res.data.channel.split(',')
          this.loading.listLoading = false
        }).catch(() => {})
      } else {
        this.dialog.id = 0
      }
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = deepClone(this.form)
          this.loading.btnLoading = true
          if (data.channel) data.channel = data.channel.join(',')
          if (this.is_edit) {
            editBanner(this.dialog.id, data).then(res => {
              this.$message.success(res.msg)
              // 重载列表
              this.getList()
              this.loading.btnLoading = false
            }).catch(() => {})
          } else {
            addBanner(data).then(res => {
              this.$message.success(res.msg)
              // 重载列表
              this.getList()
              this.loading.btnLoading = false
            }).catch(() => {})
          }
          this.dialog.visible = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 200px;
  // height: 178px;
  display: block;
  border-radius:0%
}
</style>
