<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>租赁记录{{ info.serial_number ? `(${info.serial_number}号)` : '' }}</span>
      </div>
      <el-table :data="tableData.list" row-key="id">
        <el-table-column prop="locker.serial_number" label="柜编号" />
        <el-table-column prop="user" label="会员信息" min-width="180px">
          <template slot-scope="{ row }">
            <div v-if="row.user" class="flex col-center cursor" @click="toUserInfoPage(row.user.id)">
              <div class="photo">
                <!-- <el-avatar shape="square" size="large" :src="row.user.avatar ? row.user.avatar : defaultLogo" /> -->
                <el-avatar shape="square" :size="50" :src="row.user.avatar">
                  <img :src="errorAvatarSrc(row.user, 'img')">
                </el-avatar>
                <div class="user">
                  <div>{{ getUserNames(row.user) }}</div>
                  <!-- <div>{{ row.user.real_name }}{{ row.user.nickname ? `（${row.user.nickname}）` : '' }}</div> -->
                  <div>{{ row.user.phone }}</div>
                </div>
              </div>
            </div>
            <div v-else class="text-danger">会员信息丢失</div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="{ row }">
            <el-tag v-if="row.type == 1">租赁</el-tag>
            <el-tag v-if="row.type == 2" type="success">归还</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.remark ? scope.row.remark : '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="操作时间">
          <template slot-scope="scope">
            {{ scope.row.create_time | formatDate }}
          </template>
        </el-table-column>
      </el-table>
      <pagination
        small
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination' // 分页组件
import { getLeaseRecord } from '@/api/store/locker.js'
export default {
  name: 'Log',
  components: {
    Pagination
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      // 列表数据
      tableData: {
        list: [],
        count: 0
      },
      listQueryParams: {
        locker_id: ''
      }
    }
  },
  watch: {
    id(v, o) {
      if (v) {
        this.listQueryParams.locker_id = v
        this.getList()
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      var querys = []
      if (this.listQueryParams.locker_id) querys.push({ field: 'locker_id', key: this.listQueryParams.locker_id })
      data.query = this.utils.getQueryParams(querys)
      getLeaseRecord(data)
        .then(res => {
          this.tableData = res.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.photo {
  display: flex;
  align-items: center;
  span {
    display: block;
    margin-left: 10px;
  }
  .user {
    margin-left: 10px;
  }
}
</style>
