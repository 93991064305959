var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container special-center" }, [
    _vm.utils.getVenues().name
      ? _c("h3", [_vm._v(_vm._s(_vm.utils.getVenues().name))])
      : _vm._e(),
    _c("div", { staticClass: "guide" }, [_c("wx-guide")], 1),
    _vm.utils.getVenues().id !== -1
      ? _c("div", { staticClass: "sign_in" }, [_c("SignIn")], 1)
      : _vm._e(),
    _c("div", [_c("Relevance")], 1),
    _c("div", [_c("accessSettings")], 1),
    _c("div", { staticClass: "refresh", on: { click: _vm.setRefresh } }, [
      _vm._v("刷新品牌二维码")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }