import request from '@/utils/request'
// import Utils from '@/utils/utils.js'

// 秒杀活动列表
export function getSeckillList(data) {
  return request({
    url: `/store/v2/goods`,
    method: 'get',
    params: data
  })
}

// 秒杀活动详情
export function getSeckillInfo(id, data) {
  return request({
    url: `/store/v2/goods/${id}`,
    method: 'get',
    params: data
  })
}

// 添加秒杀活动
export function addSeckill(data) {
  return request({
    url: `/store/v2/goods`,
    method: 'post',
    data
  })
}

// 修改秒杀活动
export function editSeckill(id, data) {
  return request({
    url: `/store/v2/goods/${id}`,
    method: 'put',
    data
  })
}

// 删除秒杀活动
export function delSeckill(id, data) {
  return request({
    url: `/store/v2/goods/${id}`,
    method: 'delete',
    data
  })
}

// 秒杀订单列表
export function getSeckillOrderList(data) {
  return request({
    url: `/store/v2/goods/order`,
    method: 'get',
    params: data
  })
}

// 秒杀订单详情
export function getSeckillOrderInfo(id, data) {
  return request({
    url: `/store/v2/goods/order/${id}`,
    method: 'get',
    params: data
  })
}

// 秒杀卡项评论
export function getSeckillComment(id, data) {
  return request({
    url: `/store/v2/comments/goods/${id}`,
    method: 'get',
    params: data
  })
}
