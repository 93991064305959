import request from '@/utils/request'

// 活动列表
export function activityList(params) {
  return request({
    url: '/store/v1/offline_event',
    method: 'get',
    params
  })
}

// 新增活动
export function addActivity(data) {
  return request({
    url: '/store/v1/offline_event',
    method: 'post',
    data
  })
}

// 活动详情
export function activityDetail(id) {
  return request({
    url: `/store/v1/offline_event/${id}`,
    method: 'get'
  })
}

// 活动编辑
export function editActivity(id, data) {
  return request({
    url: `/store/v1/offline_event/${id}`,
    method: 'put',
    data
  })
}

// 活动报名人列表
export function activitySignUpList(id, params) {
  return request({
    url: `/store/v1/offline_event/${id}/registrant`,
    method: 'get',
    params
  })
}

// 取消活动
export function cancelActivity(id, data) {
  return request({
    url: `/store/v1/offline_event/${id}/cancel`,
    method: 'put',
    data
  })
}

// 删除活动
export function deleteActivity(id) {
  return request({
    url: `/store/v1/offline_event/${id}`,
    method: 'delete'
  })
}

// 代签到
export function signUp(id, data) {
  return request({
    url: `/store/v1/offline_event/registrant/${id}/sign_in`,
    method: 'put',
    data
  })
}
