<template>
  <div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.title"
      class="dialog_auto"
      @close="handleCloseS"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px" label-position="top">
        <!-- 课程信息 -->
        <h3>课程信息</h3>
        <el-descriptions class="margin-top" :column="3" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-collection" />
              课程
            </template>
            {{ form.courseInfo.course ? form.courseInfo.course.name : '--' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user" />
              教练
            </template>
            {{ form.courseInfo.store_user ? form.courseInfo.store_user.real_name : '--' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building" />
              教室
            </template>
            {{ form.courseInfo.classroom ? form.courseInfo.classroom.name : '未知教室' }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-time" />
              时间
            </template>
            <el-tag>{{ form.courseInfo.start_time | formatDateLT }}</el-tag>
            -
            <el-tag>{{ form.courseInfo.end_time | formatDateLT }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-star-off" />
              难度
            </template>
            <el-rate :value="form.courseInfo.difficulty" disabled />
          </el-descriptions-item>
        </el-descriptions>
        <!-- 会员信息 -->
        <h3>预约会员</h3>
        <el-alert v-show="validate_msg && form.user_id" :title="validate_msg" type="info" :closable="false" show-icon />
        <el-row :gutter="20">
          <el-col :span="16" :offset="0">
            <el-form-item label="会员信息" prop="user_id">
              <Select ref="userRef" :type="2" :width="465" @changeId="handleMemberID" />
              <!-- <el-select
                v-model="form.user_id"
                filterable
                remote
                reserve-keyword
                placeholder="请选择会员，搜索用户名|手机号"
                :remote-method="remoteUserMethod"
                :loading="searchLoading"
                style="width:100%"
                :clearable="true"
                no-data-text="暂无数据"
                no-match-text="无搜索项,重新搜索"
                @change="userChange"
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="getUserNames(item)"
                  :value="item.id"
                >
                  <div class="flex">
                    <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="item.avatar">
                      <img :src="errorMemberSrc()">
                    </el-avatar>
                    <span>{{ getUserNames(item) }}</span>-
                    <span>{{ item.phone }}</span>
                  </div>
                </el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col v-if="is_display" :span="8" :offset="0">
            <!-- 预约人数 -->
            <el-form-item label="预约人数" prop="subscribe_number">
              <el-input v-model="form.subscribe_number" type="number" :value="form.subscribe_number" />
            </el-form-item>
          </el-col>
          <el-col :span="16" :offset="0">
            <el-form-item label="会员卡" prop="user_membership_card_id">
              <el-select
                v-model="form.user_membership_card_id"
                remote
                reserve-keyword
                placeholder="请选择会员卡"
                :loading="searchLoading"
                style="width:100%"
                :clearable="false"
                no-data-text="暂无数据"
                :disabled="!form.userInfo.id"
                @change="userCardChange"
              >
                <el-option-group v-for="group in userCardOptions" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.id"
                    :label="item.card.name + ' / ' + item.number"
                    :value="item.id"
                    :disabled="!item.course_limit"
                  >
                    <div class="flex">
                      <!-- <el-tag v-if="item.course_limit && item.status_id == 2">正常</el-tag> -->
                      <el-tag v-if="item.course_limit && item.status_id != 2" type="warning">
                        {{ item.status.name }}
                      </el-tag>
                      <el-tag v-if="!item.course_limit" type="danger">不支持</el-tag>
                      <div class="m-l-6">{{ item.card.name + ' / ' + item.number }}</div>
                    </div>
                  </el-option>
                </el-option-group>
              </el-select>
              <small v-if="text_display && form.user_id" class="text-danger">当前会员暂无会员卡</small>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-show="form.user_membership_card_id && form.user_id" class="userInfo">
          <el-descriptions class="margin-top m-b-20" :column="3" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user" />
                会员
              </template>
              <!-- <el-avatar shape="square"  :src="form.userInfo.avatar" /> -->
              <el-tag>{{ getUserNames(form.userInfo) }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-mobile-phone" />
                手机号
              </template>
              {{ form.userInfo.phone }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-bank-card" />
                类型
              </template>
              {{ formatCardType(form.userCardInfo.card ? form.userCardInfo.card.type : '') }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-bank-card" />
                卡号
              </template>
              {{ form.userCardInfo.number }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-connection" />
                卡状态
              </template>
              <el-tag :type="card_status">{{ formatStatus(form.userCardInfo.status_id) }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-collection-tag" />
                开卡类型
              </template>
              {{ formatCardOpenType(form.userCardInfo.type) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-time" />
                开卡时间
              </template>
              <div v-if="form.userCardInfo.type == 2">
                {{ form.userCardInfo.specific_time | formatDate('YYYY-MM-DD') }}
              </div>
              <div v-else>
                {{
                  form.userCardInfo.type === 1 && !form.userCardInfo.open_card_time
                    ? '未开卡'
                    : form.userCardInfo.open_card_time === 0
                      ? '未开卡'
                      : form.userCardInfo.open_card_time
                }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-money" />
                余额
              </template>
              <div v-if="form.userCardInfo.status_id !== 14">
                <span v-if="form.userCardInfo.card.type === 0">
                  <span v-if="getExcessTime(form.userCardInfo.valid_time) > 0" class="text-success">
                    {{ getExcessTime(form.userCardInfo.valid_time) }}
                    <b v-if="form.userCardInfo.card">{{ formatCardUnitType(form.userCardInfo.card.type) }}</b>
                  </span>
                  <span v-else class="text-danger">
                    {{ getExcessTime(form.userCardInfo.valid_time) }}
                    <b v-if="form.userCardInfo.card">{{ formatCardUnitType(form.userCardInfo.card.type) }}</b>
                  </span>
                </span>
                <span v-else>
                  {{ form.userCardInfo.limit }}
                  <b v-if="form.userCardInfo.card">{{ formatCardUnitType(form.userCardInfo.card.type) }}</b>
                </span>
              </div>
              <div v-else>未开卡</div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-time" />
                有效期至
              </template>
              {{ form.userCardInfo.status_id == 14 ? '未开卡' : form.userCardInfo.valid_time }}
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入约课备注信息" rows="3" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      title="提示"
      :visible.sync="dialogVisible"
      class="dialog_w580"
      :before-close="handleClose"
    >
      <span>{{ forceData.msg }}</span>
      <div class="m-y-10"><el-checkbox v-model="is_queue">排队预约</el-checkbox></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitBook(forceData.user_id, forceData.data, 1)">
          {{ is_queue ? '排队预约' : '继续预约' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClassInfo } from '@/api/store/course.js'

import {
  getUser,
  getUserDetails,
  getUserSupportCard,
  getUserMembershipCardDetails,
  getUserMembershipCardStatus
} from '@/api/store/user.js'
import { addBook, addBookValidate } from '@/api/store/worktable.js'
import Utils from '@/utils/utils.js'
import { deepClone } from '@/utils'
import { getSubscribeSettingInfo } from '@/api/store/subscribe'
import Select from '@/components/elSelect/Select'

export default {
  name: 'GroupBook',
  components: { Select },
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      }
    },
    courseid: {
      type: [String, Number],
      default: '0'
    }
  },
  data() {
    return {
      form: {
        store_user_id: 1, // 教练ID
        venues_id: Utils.getVenues().id, // 场馆
        subscribe_number: 1, // 预约人数
        userInfo: {}, // 用户信息
        userCardInfo: { card: {}}, // 用户信息
        courseInfo: {}, // 课程信息
        privateTeacherInfo: {}, // 私教老师信息
        user_membership_card_id: ''
      },
      rules: ['user_id', 'subscribe_number', 'user_membership_card_id'],
      searchLoading: false,
      dialog: this.value,
      userOptions: [],
      userCardOptions: [],
      user_info_show: false,
      statusData: [],
      card_status: 'success',
      forceData: {},
      dialogVisible: false,
      is_queue: false,
      validate_msg: '',
      is_display: true,
      title: '预约会员',
      text_display: false
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.dialog = newVal
      },
      deep: true
    },
    courseid: {
      handler(newVal) {
        this.getCourseInfo()
      },
      deep: true
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  async mounted() {
    const SubscribeSettingInfo = await getSubscribeSettingInfo('private_subscribe')
    if (SubscribeSettingInfo.data.config) {
      if (SubscribeSettingInfo.data.config.type === 1) this.is_display = false
      else this.is_display = true
    } else {
      this.is_display = true
    }
    this.getUserList()
    this.getCardStatus()
  },
  methods: {
    /**
     * 获取课程表详情
     */
    getCourseInfo() {
      if (!this.courseid) return false
      getClassInfo(this.courseid).then(res => {
        this.form.courseInfo = res.data
      })
    },
    /**
     * 用户列表
     */
    getUserList() {
      this.searchLoading = true
      const data = {}
      if (this.listQuery.query) {
        data.query = this.utils.getLikeQueryParams('real_name|nickname|phone', this.listQuery.query)
      }
      data.limit = 999
      getUser(data)
        .then(res => {
          // 数据嵌入
          this.searchLoading = false
          this.userOptions = res.data.list
        })
        .catch(() => {})
    },
    /**
     * 添加预约提交
     */
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (!this.form.courseInfo.course) {
            this.$message.error('关联课程已丢失，请检查')
            return false
          }
          const postData = {
            class_schedule_id: this.form.courseInfo.id,
            course_id: this.form.courseInfo.course.id,
            user_membership_card_id: this.form.user_membership_card_id,
            subscribe_number: this.form.subscribe_number,
            venues_id: this.form.venues_id,
            remark: this.form.remark,
            store_user_id: this.form.courseInfo.store_user.id
          }
          this.submitBook(this.form.user_id, postData)
        }
      })
    },
    submitBook(user_id, data, force = 0) {
      data.force = force
      data.is_queue = this.is_queue ? 1 : 0
      addBook(user_id, data)
        .then(res => {
          this.$message.success(res.msg)
          // 添加成功后联动获取预约记录
          this.$emit('updateListMethods', true)
          this.$parent.getVenuesList()
          this.dialog.visible = false
          this.is_queue = false
          if (force) {
            this.dialogVisible = false
            this.forceData = {
              msg: '',
              data: {},
              user_id: ''
            }
          }
        })
        .catch(error => {
          if (error.status === 701) {
            this.$confirm(error.msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.submitBook(user_id, data, 1)
              })
              .catch(() => {})
          }
          if (error.status === 702) {
            this.dialogVisible = true
            this.forceData.msg = error.msg
            this.forceData.data = deepClone(data)
            this.is_queue = true
            this.forceData.user_id = user_id
          }
        })
    },
    handleClose() {
      this.dialogVisible = false
      this.is_queue = false
      this.forceData = {}
    },
    /**
     * 获取选中会员
     */
    userChange(id) {
      this.form.user_membership_card_id = ''
      this.userCardOptions = []
      if (!id) {
        // 重载会员列表
        this.listQuery.query = ''
        this.form.userInfo = {}
        this.user_info_show = false
        this.getUserList()
        return
      }
      this.user_info_show = true
      // 请求API获取用户信息
      getUserDetails(id)
        .then(res => {
          this.form.userInfo = res.data
          this.getUserCardList()
        })
        .catch(() => {})
      addBookValidate(id, {
        class_schedule_id: this.form.courseInfo.id
      }).then(res => {
        if (res.data.msg) {
          this.validate_msg = res.data.msg
        } else {
          this.validate_msg = ''
        }
      })
    },
    /**
     * 获取选中会员会员卡
     */
    userCardChange(id) {
      if (!id) {
        this.form.userCardInfo = {}
        return
      }
      this.form.user_membership_card_id = id
      this.user_info_show = true
      // 请求API获取用户信息
      getUserMembershipCardDetails(id)
        .then(res => {
          this.form.userCardInfo = res.data
        })
        .catch(() => {})
    },
    /**
     * 搜索会员卡
     */
    // remoteUserCardMethod(query) {
    //   if (query !== '') {
    //     this.searchLoading = true
    //     // 获取指定会员下的会员卡列表
    //     this.getUserCardList()
    //   } else {
    //     this.userCardOptions = []
    //   }
    // },
    /**
     * 获取会员卡列表
     */
    getUserCardList() {
      if (!this.form.userInfo.id) {
        // this.$message.error('请先选择会员')
        return
      }
      // getUserMembershipCard({ query: JSON.stringify({ user_id: this.form.userInfo.id }) }).then(res => {
      //   // 数据嵌入
      //   this.searchLoading = false
      //   this.userCardOptions = res.data.list
      // }).catch(() => {})
      const data = {
        offset: 0,
        limit: 999
      }
      getUserSupportCard(this.form.courseInfo.course.id, this.form.userInfo.id, data)
        .then(res => {
          const data = res.data.list
          if (data.length <= 0) this.text_display = true
          else this.text_display = false
          const optionsY = data.filter(item => item.course_limit)
          const optionsN = data.filter(item => !item.course_limit)
          this.userCardOptions = [
            { label: '可用', options: optionsY },
            { label: '不支持', options: optionsN }
          ]
        })
        .finally(() => {
          this.searchLoading = false
        })
    },
    /**
     * 搜索用户
     */
    remoteUserMethod(query) {
      this.listQuery.query = query
      this.getUserList()
    },
    // 状态码格式化
    formatStatus(s) {
      if (!this.statusData.length <= 0) {
        this.getCardStatus()
      }
      switch (s) {
        case 1:
        case 2:
          this.card_status = 'success'
          break
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          this.card_status = 'warning'
          break

        default:
          this.card_status = 'danger'
          break
      }
      return this.statusData[s] ? this.statusData[s] : '----'
    },
    getCardStatus() {
      if (this.statusData.length <= 0) {
        getUserMembershipCardStatus({ limit: 999 })
          .then(res => {
            const new_arr = []
            for (var n = 0; n < res.data.list.length; n++) {
              new_arr[res.data.list[n]['id']] = res.data.list[n]['name']
            }
            this.statusData = new_arr
          })
          .catch(() => {})
      }
    },
    handleCloseS() {
      this.form = {
        store_user_id: 1, // 教练ID
        venues_id: Utils.getVenues().id, // 场馆
        subscribe_number: 1, // 预约人数
        userInfo: {}, // 用户信息
        userCardInfo: { card: {}}, // 用户信息
        courseInfo: {}, // 课程信息
        privateTeacherInfo: {}, // 私教老师信息
        user_membership_card_id: '',
        user_id: ''
      }
      this.$refs.userRef.refreshGetList()
      this.$emit('updateListMethods', true)
    },
    handleMemberID(val) {
      this.$set(this.form, 'user_id', val)
      this.userChange(val)
    }
  }
}
</script>

<style lang="scss" scoped></style>
