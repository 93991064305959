<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="客询姓名">
            <el-select v-model="listQueryParams.inquire_user_id" placeholder="请选择" filterable clearable>
              <el-option v-for="item in inquire_user_list" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="回访人员">
            <el-select v-model="listQueryParams.store_user_id" placeholder="请选择" filterable clearable>
              <el-option v-for="item in store_user_list" :key="item.id" :label="item.real_name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="回访时间">
            <el-date-picker
              v-model="listQueryParams.update_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <div class="m-b-10">
            <el-button icon="el-icon-search" :loading="searchLoading" type="success" @click="handleFilter">
              搜索
            </el-button>
            <el-button
              v-show="btn_access('s_inquire_track_export')"
              :disabled="tableData.list.length <= 0"
              type="danger"
              class=" m-l-10"
              icon="el-icon-download"
              :loading="exportLoading"
              @click="Download"
            >导出
            </el-button>
          </div>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索回访内容" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>

      <el-button
        v-show="btn_access('s_inquire_track_add') && currentVenues.id != -1"
        type="primary"
        icon="el-icon-plus"
        class="filter-item m-l-10"
        @click="dialogVisible()"
      >添加</el-button>
    </div>
    <el-table ref="multipleTable" v-loading="loading" :data="tableData.list" row-key="id">
      <!-- <el-table-column prop="id" label="ID" width="50" /> -->
      <el-table-column prop="name" label="客户姓名" width="130">
        <template slot-scope="scope">{{ scope.row.inquire_user?scope.row.inquire_user.name:'--' }}</template>
      </el-table-column>
      <el-table-column prop="phone" label="电话" width="150">
        <template slot-scope="scope">{{ scope.row.inquire_user?scope.row.inquire_user.phone:'--' }}</template>
      </el-table-column>
      <el-table-column prop="content" label="回访内容" min-width="150px" show-overflow-tooltip />
      <el-table-column prop="create_time" label="创建时间" width="150" />
      <el-table-column prop="store_user" label="会籍顾问">
        <template slot-scope="{row: {inquire_user}}">
          {{ inquire_user&&inquire_user.store_user ? inquire_user.store_user.real_name : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="store_user" label="回访人员">
        <template slot-scope="{row: {store_user}}">
          {{ store_user.real_name }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            v-show="btn_access('s_inquire_track_edit')"
            class="m-2"
            type="primary"
            @click="dialogVisible(scope.row)"
          >
            编辑</el-button>
          <el-popconfirm title="删除后将无法恢复，确定吗？" @confirm="delInquireTrack(scope.row.id)">
            <el-button v-show="btn_access('s_inquire_track_del')" slot="reference" class="m-2" type="danger">删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <el-dialog
      v-loading="dialog.loading"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="添加用户回访记录"
      class="dialog_w580"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" :inline="true" label-width="80px">
        <el-form-item label="用户" prop="inquire_user_id">
          <el-select v-model="form.inquire_user_id" placeholder="请选择" filterable>
            <el-option
              v-for="item in inquire_user_list"
              :key="item.id"
              :label="item.name + '/' + item.phone"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="会籍顾问" prop="inquire_user">
          <el-input :value="form.store_user" disabled />
        </el-form-item> -->
        <el-form-item label="回访内容" prop="content">
          <el-input v-model="form.content" type="textarea" :rows="4" placeholder="请输入回访内容" class="dialog_w100" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" :loading="isLoading" @click="submit">确认</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  getInquireList,
  InquireTrack,
  delInquireTrack,
  editInquireTrack,
  addInquireTrack
} from '@/api/store/inquire.js'
import { getStoreUser } from '@/api/store/management.js'

import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  components: { Pagination },
  data() {
    return {
      exportLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 弹窗
      dialog: {
        visible: false,
        loading: false
      },
      // 数据
      form: {
        inquire_user_id: this.id,
        content: ''
      },
      user: {},
      rules: ['content', 'inquire_user_id'],
      loading: false,
      store_user_list: [],
      inquire_user_list: [],
      isLoading: false
    }
  },
  mounted() {
    this.getList()
    // 获取员工列表
    if (this.utils.empty(this.store_user_list)) {
      getStoreUser({ limit: 999 })
        .then(res => {
          this.store_user_list = res.data.list
        })
        .catch(() => { })
    }
    // 获取询课列表
    if (this.utils.empty(this.inquire_user_list)) {
      getInquireList({ limit: 999 })
        .then(res => {
          this.inquire_user_list = res.data.list
        })
        .catch(() => { })
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  methods: {

    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true
          if (this.form.id) {
            editInquireTrack(this.form.id, this.form)
              .then(res => {
                this.$message.success('修改成功')
                this.getList()
                this.dialog.visible = false
                this.dialog.loading = false
                this.isLoading = false
              })
              .catch(() => {
                this.isLoading = false
              })
          } else {
            addInquireTrack(this.form)
              .then(res => {
                this.$message.success('添加成功')
                this.getList()
                this.dialog.visible = false
                this.dialog.loading = false
                this.isLoading = false
              })
              .catch(() => {
                this.isLoading = false
              })
          }
        }
      })
    },
    // 删除
    delInquireTrack(id) {
      delInquireTrack(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      })
    },
    dialogVisible(data) {
      this.form = data ? {
        id: data.id,
        store_user: data.inquire_user.store_user ? data.inquire_user.store_user.real_name : '--',
        inquire_user_id: data.inquire_user_id,
        content: data.content
      } : {
        inquire_user_id: this.id,
        content: ''
      }
      this.dialog = { visible: true, loading: true }
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
        this.dialog.loading = false
      })
    },
    isEmpty(v) {
      return !this.utils.empty(v)
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },

    Download() {
      const _this = this
      this.exportLoading = true
      const query = encodeURIComponent(this.setQuery())
      this.utils.handleDownload({
        url: '/store/v1/user_inquire_track/export?query=' + query,
        fileName: '客询回访列表.xlsx',
        baseURL: this.baseUrl
      })
      setTimeout(function() {
        _this.exportLoading = false
      }, 4000)
    },
    setQuery() {
      var querys = []
      if (this.listQueryParams.query) {
        querys.push({
          field: 'phone|name|content',
          type: 'like',
          key: this.listQueryParams.query
        })
      }
      if (this.listQueryParams.sex !== '' && this.listQueryParams.sex != null) { querys.push({ field: 'sex', key: this.listQueryParams.sex }) }
      if (
        this.listQueryParams.inquire_user_id !== '' &&
        this.listQueryParams.inquire_user_id != null
      ) {
        querys.push({
          field: 'uit.inquire_user_id',
          key: this.listQueryParams.inquire_user_id
        })
      }
      if (
        this.listQueryParams.store_user_id !== '' &&
        this.listQueryParams.store_user_id != null
      ) {
        querys.push({
          field: 'uit.store_user_id',
          key: this.listQueryParams.store_user_id
        })
      }
      if (this.listQueryParams.update_time) {
        querys.push({
          field: 'uit.update_time',
          type: 'between-time',
          key: this.listQueryParams.update_time
        })
      }
      return this.utils.getQueryParams(querys)
    },
    // 获取列表
    getList() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()

      InquireTrack(data)
        .then(res => {
          this.tableData = res.data
          this.searchLoading = false
          this.loading = false
        })
        .catch(() => { })
    }

  }
}
</script>

<style lang="scss" scoped>
.el-avatar {
  background: none;
}
</style>
