var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container special-center" }, [
    _c("div", { staticClass: "p-x-30 p-y-90 notice_box" }, [
      _c("div", { staticClass: "title text-center m-y-30 font-xs" }, [
        _vm._v(_vm._s(_vm.info.title))
      ]),
      _c("div", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.info.content) }
      }),
      _c("div", { staticClass: "time text-right text-info m-y-20" }, [
        _vm._v(_vm._s(_vm.info.create_time))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }