var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "flex row-between" },
        [
          _c("el-page-header", {
            staticClass: "page-header",
            attrs: { content: "快速注册小程序" },
            on: {
              back: function($event) {
                return _vm.returnPage()
              }
            }
          }),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleSubmitRecord }
            },
            [_vm._v("提交记录")]
          )
        ],
        1
      ),
      _c(
        "el-card",
        {
          staticClass: "m-t-20",
          staticStyle: { background: "#FFFFFF" },
          attrs: { shadow: "never", "body-style": "background-color: #ffffff;" }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                margin: "24px 0",
                background: "#ECF5FE",
                padding: "20px",
                "font-size": "14px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    color: "#999999",
                    "font-size": "14px",
                    "margin-bottom": "4px"
                  }
                },
                [_vm._v("使用说明")]
              ),
              _c("div", { staticStyle: { margin: "2px 0" } }, [
                _vm._v(
                  " 通过该接口创建的小程序创建成功后即为“已认证”状态，创建成功后，可直接绑定平台进行小程序的开发使用。 "
                )
              ]),
              _c("div", { staticStyle: { margin: "2px 0" } }, [
                _vm._v("1.填写以下信息，提交微信审核。")
              ]),
              _c("div", { staticStyle: { margin: "2px 0" } }, [
                _vm._v(
                  "2.法人微信收到消息模板，法人于24h内进行法人身份信息和人脸信息认证。"
                )
              ]),
              _c("div", { staticStyle: { margin: "2px 0" } }, [
                _vm._v("3.身份认证通过后，小程序创建成功。")
              ]),
              _c("div", { staticStyle: { margin: "2px 0" } }, [
                _vm._v("4.更多问题详见 "),
                _c(
                  "a",
                  {
                    staticClass: "text-primary",
                    attrs: {
                      target: "_blank",
                      href:
                        "https://developers.weixin.qq.com/doc/oplatform/Third-party_Platforms/2.0/product/Register_Mini_Programs/Fast_Registration_Interface_document.html"
                    }
                  },
                  [_vm._v("微信官方文档")]
                )
              ])
            ]
          ),
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                model: _vm.editForm,
                rules: _vm.editFormRules,
                "label-width": "150px",
                "position-label": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "name", label: "企业名称" } },
                [
                  _c("el-input", {
                    staticClass: "fast-input",
                    attrs: { placeholder: "请输入公司名称", size: "small" },
                    model: {
                      value: _vm.editForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "name", $$v)
                      },
                      expression: "editForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "code_type", label: "代码类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.editForm.code_type,
                        callback: function($$v) {
                          _vm.$set(_vm.editForm, "code_type", $$v)
                        },
                        expression: "editForm.code_type"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "统一社会信用代码(18 位)", value: 1 }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "组织机构代码(9 位 xxxxxxxx-x)",
                          value: 2
                        }
                      }),
                      _c("el-option", {
                        attrs: { label: "营业执照注册号(15 位)", value: 3 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "code", label: "企业代码" } },
                [
                  _c("el-input", {
                    staticClass: "fast-input",
                    attrs: {
                      placeholder: "请输入相应的企业代码",
                      size: "small"
                    },
                    model: {
                      value: _vm.editForm.code,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "code", $$v)
                      },
                      expression: "editForm.code"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "legal_persona_wechat", label: "法人微信号" }
                },
                [
                  _c("el-input", {
                    staticClass: "fast-input",
                    attrs: {
                      placeholder: "请输入法人的微信号（需已绑定银行卡）",
                      size: "small"
                    },
                    model: {
                      value: _vm.editForm.legal_persona_wechat,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "legal_persona_wechat", $$v)
                      },
                      expression: "editForm.legal_persona_wechat"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "legal_persona_name", label: "法人姓名" } },
                [
                  _c("el-input", {
                    staticClass: "fast-input",
                    attrs: { placeholder: "请输入法人姓名", size: "small" },
                    model: {
                      value: _vm.editForm.legal_persona_name,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "legal_persona_name", $$v)
                      },
                      expression: "editForm.legal_persona_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "component_phone", label: "联系电话" } },
                [
                  _c("el-input", {
                    staticClass: "fast-input",
                    attrs: { placeholder: "请输入联系方式", size: "small" },
                    model: {
                      value: _vm.editForm.component_phone,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "component_phone", $$v)
                      },
                      expression: "editForm.component_phone"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { size: "small", loading: _vm.btnLoading, type: "primary" },
          on: { click: _vm.submit }
        },
        [_vm._v(" 提交 ")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "注册提交记录",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.btnLoading,
                  expression: "btnLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.list, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "企业名称", width: "" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "code_type",
                  formatter: _vm.codeTypeFormat,
                  label: "代码类型",
                  "min-width": "",
                  width: ""
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "企业代码" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "legal_persona_wechat",
                  label: "法人微信号",
                  width: ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "legal_persona_name",
                  label: "法人姓名",
                  width: ""
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "component_phone", label: "联系电话", width: "" }
              }),
              _c("el-table-column", {
                attrs: { prop: "status_text", label: "状态", width: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.status === 0
                          ? _c("span", [_vm._v(_vm._s(row.status_text))])
                          : _vm._e(),
                        row.status === -2 || row.status !== 0
                          ? _c(
                              "span",
                              [
                                _vm._v(
                                  _vm._s(
                                    row.status === -2
                                      ? "验证中"
                                      : row.status_text
                                  ) + " "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "m-4",
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleRegister(row)
                                      }
                                    }
                                  },
                                  [_vm._v("重新提交")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("pagination", {
            attrs: {
              small: "",
              total: _vm.tableData.count,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }