<template>
  <div>
    <div class="app-container">
      <div class="filter-container">
        <div class="searchBox">
          <!-- Info 自定义检索方法，不与通用检索同步 -->
          <el-form :model="listQuery" label-width="100px" label-position="top" label-suffix="：" :inline="true">
            <el-form-item label="时间范围">
              <el-date-picker
                v-model="listQuery.create_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="dateShortcutChooser"
              />
            </el-form-item>
            <!-- 教练 -->
            <el-form-item label="教练">
              <Select :type="4" @changeId="handleCoachID" />
            </el-form-item>
            <el-form-item label="课程类型">
              <el-select v-model="listQuery.type" placeholder="请选择" filterable clearable>
                <el-option v-for="(item, index) in course_list" :key="index" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-form>
          <div>
            <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
              搜索
            </el-button>
          </div>
        </div>
      </div>
      <div class="total m-y-20 font-16">
        总课时
        <span class="text-success p-x-2 font-xs">
          <count-to :start-val="0" :end-val="tableData.all_count" :duration="2000" :decimals="2" />
        </span>
        ，课时费总金额
        <span class="text-success p-x-2 font-xs">
          <count-to :start-val="0" :end-val="tableData.sum" :duration="2000" :decimals="2" separator="," prefix="¥ " />
        </span>
      </div>
      <div class="total m-y-20 font-16">
        团课
        <span class="text-success p-x-2 font-xs">
          <count-to :start-val="0" :end-val="tableData.group_count" :duration="2000" :decimals="2" />
        </span>
        课时，团课课时费金额
        <span class="text-success p-x-2 font-xs">
          <count-to :start-val="0" :end-val="tableData.group_sum" :duration="2000" :decimals="2" separator="," prefix="¥ " />
        </span>
      </div>
      <div class="total m-y-20 font-16">
        私教课
        <span class="text-success p-x-2 font-xs">
          <count-to :start-val="0" :end-val="tableData.private_count" :duration="2000" :decimals="2" />
        </span>
        课时，私教课课时费金额
        <span class="text-success p-x-2 font-xs">
          <count-to :start-val="0" :end-val="tableData.private_sum" :duration="2000" :decimals="2" separator="," prefix="¥ " />
        </span>
      </div>
      <div class="total m-y-20 font-16">
        精品课
        <span class="text-success p-x-2 font-xs">
          <count-to :start-val="0" :end-val="tableData.fine_count" :duration="2000" :decimals="2" />
        </span>
        课时，精品课课时费金额
        <span class="text-success p-x-2 font-xs">
          <count-to :start-val="0" :end-val="tableData.fine_sum" :duration="2000" :decimals="2" separator="," prefix="¥ " />
        </span>
      </div>
      <el-button v-show="btn_access('s_class_statistics_export')" :loading="downloadLoading" class="filter-item m-y-10" type="danger" icon="el-icon-download" @click="handleDownload">
        课时费导出
      </el-button>
      <el-button class="filter-item m-y-10" type="primary" icon="el-icon-question" @click="handleDisplay">
        课时费统计说明
      </el-button>
      <el-table ref="multipleTable" v-loading="searchLoading" :data="tableData.list" row-key="id">
        <!-- <el-table-column prop="id" label="ID" /> -->
        <el-table-column prop="real_name" label="教练" />
        <el-table-column prop="course_name" label="课程名称" />
        <el-table-column prop="type" label="课程类型" :formatter="formatCourseName" />
        <el-table-column prop="total" label="上课次数" />
        <el-table-column prop="fee" label="课时费" />
        <el-table-column prop="fee_sum" label="课时费统计" />
      </el-table>
      <pagination
        small
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
      <!-- 导出 -->
      <Export :success-prompt="successPrompt" :is-judge="is_judge" :animation-display="animation_display" />
    </div>
    <el-dialog
      title="课时费统计说明"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div slot="header" class="clearfix">
        <span>课时费解释</span>
      </div>
      <div class="text_all">
        <b>团课和精品课统计规则：</b>
        <div style="text-indent: 2em;">当前时间过了课表中“课程开始时间”，有预约且未取消的课程，则统计该课程对应教练的课时费。</div>
        <div style="text-indent: 2em;">多人预约同一节课，课时费只统计一次，不重复统计。</div>

        <b>私教（排课模式）统计规则：</b>
        <div style="text-indent: 2em;">当前时间过了课表中“课程开始时间”，有预约且未取消的课程，则统计该课程对应教练的课时费。</div>
        <div style="text-indent: 2em;">如果没有预约，则不统计该课程对应教练的课时费。</div>
        <div style="text-indent: 2em;">多人预约同一节课程，课时费只统计一次，不重复统计。</div>

        <b> 私教（非排课模式）统计规则：</b>
        <div style="text-indent: 2em;">按照预约作为统计标准，当前时间过了课表中“课程开始时间”，课程被预约且未取消，则按照正常流程统计课时费。</div>
        <div style="text-indent: 2em;">多人预约同一上课时间，课时费只统计一次，不重复统计。</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
import { get_statistic } from '@/api/store/record.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Select from '@/components/elSelect/Select'
import Utils from '@/utils/utils.js'
import Export from '@/components/export/index.vue'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'

export default {
  components: { CountTo, Pagination, Select, Export },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      listQuery: {
        create_time: []
      },
      searchLoading: false,
      course_list: [
        {
          label: '未选择',
          value: ''
        },
        {
          label: '团课',
          value: '0'
        },
        {
          label: '私教课',
          value: '1'
        },
        {
          label: '精品课',
          value: '2'
        }
      ],
      downloadLoading: false,
      successPrompt: false,
      is_judge: 0,
      animation_display: true,
      dialogVisible: false
    }
  },
  created() {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    this.listQuery.create_time = [timestampToTimes(start.getTime()).slice(0, 10), timestampToTimes(end.getTime()).slice(0, 10)]
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      console.log(this.listQuery)
      this.getList()
    },
    setQuery() {
      // var querys = []
      // if (this.listQuery.create_time) querys.push({ field: 'create_time', type: 'between-time', key: this.listQuery.create_time })
      // if (this.listQuery.store_user_id) querys.push({ field: 'store_user_id', key: this.listQuery.store_user_id })
      // if (this.listQuery.type) querys.push({ field: 'type', key: this.listQuery.type })

      // return this.utils.getQueryParams(querys)
      const data = JSON.parse(JSON.stringify(this.listQuery))
      delete data['limit']
      delete data['page']
      if (data.create_time) {
        data.start_time = data.create_time[0] + ' 00:00:00'
        data.end_time = data.create_time[1] + ' 23:59:59'
        delete data['create_time']
      }
      const dataGet = this.params(data)
      return dataGet
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      this.searchLoading = true
      if (data.create_time) {
        data.start_time = data.create_time[0] + ' 00:00:00'
        data.end_time = data.create_time[1] + ' 23:59:59'
        delete data['create_time']
      }
      get_statistic(data)
        .then(res => {
          this.tableData = res.data
          this.searchLoading = false
        })
        .catch(() => {})
    },
    formatCourseName(row, column) {
      switch (row.type) {
        case 0:
          return '团课'
        case 1:
          return '私教课'
        case 2:
          return '精品课'
        default:
          return '--'
      }
    },
    handleCoachID(val) {
      this.listQuery.store_user_id = val
    },
    // 导出
    async handleDownload() {
      const _this = this
      this.animation_display = true
      this.is_judge = 0
      this.downloadLoading = true
      this.successPrompt = true
      const query = this.setQuery()
      const progressBar = await this.utils.handleDownload({
        url: `/store/v1/course_subscribe/statistic_export/venues_id/${Utils.getVenues().id}${query}`,
        fileName: '课时费.xlsx',
        baseURL: this.baseUrl
      })
      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.downloadLoading = false
          _this.successPrompt = false
        }, 2000)
      }
    },
    handleDisplay() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__label {
  padding: 0 !important;
}
.text_all {
  font-size: 14px;
  line-height: 30px;
}
</style>
