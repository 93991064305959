<template>
  <div class="app-container moneyFont">
    <!-- 卡片 -->
    <el-row :gutter="20">
      <!-- 收入总额 -->
      <el-col :span="6">
        <el-card v-loading="cardLoading" element-loading-text="玩命加载中" shadow="hover">
          <div slot="header">
            <span class="font-18">资产统计</span>
          </div>
          <div>
            <div class="flex row-between">
              <div>
                <div class="font-14">收入总额(元)</div>
                <div class="font-30">¥ {{ data.total_income_sum }}</div>
              </div>
              <div class="w-40 h-40 flex row-center" style="background: #E2FAFA; border-radius: 10px;">
                <img style="width: 60%; height: 60%;" src="@/assets/images/asset_statistics/totalRevenue.png" alt="">
              </div>
            </div>
            <div class="m-y-10" style="border-bottom: 1px solid #f0f0f0;" />
            <div class="flex row-around">
              <div class="flex-col col-center">
                <div class="flex font-14">
                  <div class="w-16 h-16 m-r-4">
                    <img
                      style="width: 100%; height: 100%;"
                      src="@/assets/images/asset_statistics/usedCard.png"
                      alt=""
                    >
                  </div>
                  <div>已耗卡总额</div>
                </div>
                <div class="font-20">¥ {{ data.total_consume_sum }}</div>
              </div>
              <div class="h-30" style="border-left: 1px solid #f0f0f0;" />
              <div class="flex-col col-center">
                <div class="flex font-14">
                  <div class="w-16 h-16 m-r-4">
                    <img
                      style="width: 100%; height: 100%;"
                      src="@/assets/images/asset_statistics/unusedCardCost.png"
                      alt=""
                    >
                  </div>
                  <div>剩余耗卡</div>
                </div>
                <div class="font-20">¥ {{ data.total_balance_sum }}</div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <!-- 收入统计 -->
      <el-col :span="9">
        <el-card v-loading="cardLoading" element-loading-text="玩命加载中" shadow="hover">
          <div slot="header">
            <div class="flex">
              <div class="font-18">收入统计</div>
              <div class="w-20 h-20 m-l-4 m-t-2 cursor" @click="handleDialogVisible(1)">
                <img style="width: 100%; height: 100%;" src="@/assets/images/asset_statistics/doubt.png" alt="">
              </div>
            </div>
          </div>
          <div class="h-126">
            <el-row :gutter="20">
              <el-col v-for="(item, index) in data.cards" :key="index" :span="8" class="m-t-10">
                <div class="flex">
                  <div class="w-10 h-10 m-r-10" style="border-radius: 50%;" :style="{ background: item.color }" />
                  <div class="font-14">{{ item.text }}</div>
                </div>
                <div class="font-20">¥ {{ item.value }}</div>
              </el-col>
            </el-row>
            <!-- <div class="m-y-20" style="border-bottom: 1px solid #f0f0f0;" /> -->
          </div>
        </el-card>
      </el-col>
      <!-- 耗卡金额统计 -->
      <el-col :span="9">
        <el-card v-loading="cardLoading" element-loading-text="玩命加载中" shadow="hover">
          <div slot="header">
            <div class="flex">
              <div class="font-18">耗卡金额统计</div>
              <div class="w-20 h-20 m-l-4 m-t-2 cursor" @click="handleDialogVisible(2)">
                <img style="width: 100%; height: 100%;" src="@/assets/images/asset_statistics/doubt.png" alt="">
              </div>
            </div>
          </div>
          <div class="h-126">
            <div class="flex bg-info" style="border-radius: 6px; overflow: hidden;">
              <div
                v-for="(item, index) in data.bars"
                :key="index"
                :style="{ width: `${item.percent}%`, height: '25px', background: item.color }"
              />
            </div>
            <div class="m-t-20">
              <div v-for="(item, index) in data.bars" :key="index" class="flex row-between m-t-10 font-14">
                <div class="flex">
                  <div class="w-10 h-10 m-r-10" style="border-radius: 50%;" :style="{ background: item.color }" />
                  <div class="font-14">{{ item.text }}</div>
                </div>
                <div class="flex font-16">
                  <div>¥ {{ item.value }}</div>
                  <div class="w-65 m-l-20 text-right" style="color: #999999;">{{ item.percent }} %</div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 收入统计 -->
    <income />
    <!-- 耗卡金额统计 -->
    <cardConsumption />
    <!-- 资产统计 -->
    <assets />
    <!-- 弹窗提示 -->
    <Dialog :dialog-visible="dialogVisible" :type="type" @dialogVisibleClose="dialogVisibleClose" />
  </div>
</template>

<script>
// API
import { getAssetStatistics } from '@/api/store/assetStatistics.js'
// 组件
import assets from './components/assets'
import income from './components/income'
import cardConsumption from './components/cardConsumption'
import Dialog from './components/dialog'

export default {
  components: { assets, income, cardConsumption, Dialog },
  data() {
    return {
      // 卡片数据
      data: {
        total_income_sum: '0', // 收入总额
        total_consume_sum: '0', // 已耗卡总额
        total_balance_sum: '0', // 剩余耗卡
        cards: [], // 收入统计
        bars: [], // 耗卡金额
        list: [] // 资产统计
      },
      cardLoading: false, // 卡片加载
      type: 0,
      dialogVisible: false // 弹窗提示
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.cardLoading = true
      getAssetStatistics()
        .then(res => {
          this.data = res.data
        })
        .finally(() => {
          this.cardLoading = false
        })
    },
    handleDialogVisible(type) {
      console.log('🚀 ~ handleDialogVisible ~ type:', type)
      this.type = type
      this.dialogVisible = true
    },
    dialogVisibleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.moneyFont {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
</style>
