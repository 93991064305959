import request from '@/utils/request'

// 回访列表
export function getReturnVisitList(params) {
  return request({
    url: '/store/v1/user_track',
    method: 'get',
    params
  })
}

// 获取用户回访记录
export function getReturnVisitRecord(userId) {
  return request({
    url: `/store/v1/user_track/user_id/${userId}`,
    method: 'get'
  })
}

// 添加回访记录
export function addReturnVisit(data) {
  return request({
    url: '/store/v1/user_track',
    method: 'post',
    data
  })
}

// 获取回访记录详情
export function getReturnVisitDetail(userId) {
  return request({
    url: `/store/v1/user_track/${userId}`,
    method: 'get'
  })
}

// 更新回访记录
export function updateReturnVisit(userId, data) {
  return request({
    url: `/store/v1/user_track/${userId}`,
    method: 'put',
    data
  })
}

// 删除回访
export function deleteReturnVisit(userId) {
  return request({
    url: `/store/v1/user_track/${userId}`,
    method: 'delete'
  })
}
