var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "penaltyTable",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "会员信息", "min-width": "120px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var user = ref.row.user
                  return [
                    !_vm.utils.empty(user)
                      ? _c(
                          "div",
                          {
                            staticClass: "flex col-center cursor",
                            on: {
                              click: function($event) {
                                return _vm.toUserInfoPage(user.id)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-avatar",
                                  {
                                    attrs: {
                                      shape: "square",
                                      size: 50,
                                      src: user.avatar
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.errorAvatarSrc(user, "img")
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "p-l-10" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.getUserNames(user)))
                              ]),
                              _c("br"),
                              _c("span", [_vm._v(_vm._s(user.phone))])
                            ])
                          ]
                        )
                      : _c("div", { staticClass: "text-danger" }, [
                          _vm._v("用户信息缺失")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "id", label: "会员卡信息", "min-width": "120px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.user_membership_card
                      ? _c("div", [
                          row.user_membership_card.card
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.user_membership_card.card.name +
                                        "（" +
                                        (row.user_membership_card.card.type ===
                                        0
                                          ? "期限卡"
                                          : row.user_membership_card.card
                                              .type === 1
                                          ? "次数卡"
                                          : row.user_membership_card.card
                                              .type === 2
                                          ? "储值卡"
                                          : "") +
                                        "）"
                                    ) +
                                    " "
                                )
                              ])
                            : _c("div", { staticClass: "text-danger" }, [
                                _vm._v("会员卡信息缺失")
                              ])
                        ])
                      : _c("div", { staticClass: "text-danger" }, [
                          _vm._v("会员卡信息缺失")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "venues.name", label: "场馆名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "notice", label: "惩罚备注" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.notice
                      ? _c("div", [_vm._v(_vm._s(scope.row.notice))])
                      : _c("div", [_vm._v("无")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "id", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.type == 1
                      ? _c("div", [_c("el-tag", [_vm._v("已惩罚")])], 1)
                      : _vm._e(),
                    scope.row.type == 2
                      ? _c(
                          "div",
                          [
                            scope.row.status == 0
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("惩罚生效")
                                ])
                              : _vm._e(),
                            scope.row.status == 1
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("不生效")
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "爽约课程" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.course_subscribe_list.length > 0
                      ? _c(
                          "div",
                          _vm._l(row.course_subscribe_list, function(
                            item,
                            index
                          ) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticClass: "cursor",
                                on: {
                                  click: function($event) {
                                    return _vm.handleInfo(item.id)
                                  }
                                }
                              },
                              [
                                index !== 0
                                  ? _c("span", [_vm._v("、")])
                                  : _vm._e(),
                                _c("span", { staticClass: "clickText" }, [
                                  _vm._v(_vm._s(item.name))
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      : _c("div", [_vm._v("无")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "限制预约时间",
              "min-width": "120px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      _vm._s(row.create_time.slice(0, 16)) +
                        " - " +
                        _vm._s(row.end_time.slice(0, 16))
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "id", label: "操作", "min-width": "120px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 0 && scope.row.type == 2
                      ? [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: { title: "确定取消本条爽约记录吗？" },
                              on: {
                                confirm: function($event) {
                                  return _vm.penalty_cancel(scope.row.id)
                                }
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.btn_access(
                                        "s_break_promise_penalty"
                                      ),
                                      expression:
                                        "btn_access('s_break_promise_penalty')"
                                    }
                                  ],
                                  staticClass: "m-r-5",
                                  attrs: { slot: "reference", type: "danger" },
                                  slot: "reference"
                                },
                                [_vm._v("取消爽约")]
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          small: "",
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "div",
        [
          _c("bookInfo", {
            attrs: { logid: _vm.logid },
            on: {
              "update:logid": function($event) {
                _vm.logid = $event
              }
            },
            model: {
              value: _vm.dialog,
              callback: function($$v) {
                _vm.dialog = $$v
              },
              expression: "dialog"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }