var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog_auto",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.dialog.visible,
            title: _vm.dialog.title,
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            },
            close: _vm.handleCloseS
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "top"
              }
            },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c(
                      "el-avatar",
                      {
                        attrs: {
                          shape: "square",
                          size: 50,
                          src: _vm.form.privateTeacherInfo.avatar
                        }
                      },
                      [_c("img", { attrs: { src: _vm.errorImageSrc } })]
                    )
                  ],
                  1
                ),
                _c("span", { staticClass: "p-10" }, [
                  _vm._v(_vm._s(_vm.form.privateTeacherInfo.real_name))
                ])
              ]),
              _c("h3", [_vm._v("预约会员")]),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "会员信息", prop: "user_id" } },
                        [
                          _c("Select", {
                            ref: "userRef",
                            attrs: { type: 2, width: 345 },
                            on: { changeId: _vm.handleMemberID }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "预约课程", prop: "course_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择课程" },
                              on: { change: _vm.course_change },
                              model: {
                                value: _vm.form.course_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "course_id", $$v)
                                },
                                expression: "form.course_id"
                              }
                            },
                            _vm._l(_vm.private_options, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          ),
                          _vm.private_options.length <= 0
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v("当前老师暂无课程")
                              ])
                            : _vm._e(),
                          _vm.handleCurriculum
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v("请选择预约课程")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "会员卡",
                            prop: "user_membership_card_id"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请选择会员卡",
                                clearable: true,
                                empty: "暂无内容",
                                "no-data-text": "暂无数据",
                                disabled: _vm.is_display
                              },
                              on: { change: _vm.userCardChange },
                              model: {
                                value: _vm.form.user_membership_card_id,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "user_membership_card_id",
                                    $$v
                                  )
                                },
                                expression: "form.user_membership_card_id"
                              }
                            },
                            _vm._l(_vm.userCardOptions, function(group) {
                              return _c(
                                "el-option-group",
                                {
                                  key: group.label,
                                  attrs: { label: group.label }
                                },
                                _vm._l(group.options, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        label:
                                          item.card.name + " / " + item.number,
                                        value: item.id,
                                        disabled: !item.course_limit
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          item.course_limit &&
                                          item.status_id != 2
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "warning" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.status.name) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          !item.course_limit
                                            ? _c(
                                                "el-tag",
                                                { attrs: { type: "danger" } },
                                                [_vm._v("不支持")]
                                              )
                                            : _vm._e(),
                                          _c("div", { staticClass: "m-l-6" }, [
                                            _vm._v(
                                              _vm._s(
                                                item.card.name +
                                                  " / " +
                                                  item.number
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            }),
                            1
                          ),
                          _vm.text_display && _vm.form.user_id
                            ? _c("small", { staticClass: "text-danger" }, [
                                _vm._v("当前会员暂无会员卡")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上课时间", prop: "start_time_d" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择", filterable: "" },
                              model: {
                                value: _vm.form.start_time_d,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "start_time_d", $$v)
                                },
                                expression: "form.start_time_d"
                              }
                            },
                            _vm._l(_vm.timeQuantum, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.start,
                                  value: item.start,
                                  disabled: !item.status
                                }
                              })
                            }),
                            1
                          ),
                          _c("div", [
                            _vm.form.start_time_d
                              ? _c("small", { staticClass: "text-info" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dateTime + " " + _vm.form.start_time_d
                                    )
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 24, offset: 0 } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.form.user_membership_card_id &&
                              _vm.form.user_id,
                            expression:
                              "form.user_membership_card_id && form.user_id"
                          },
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.userinfoLoading,
                            expression: "userinfoLoading"
                          }
                        ],
                        staticClass: "userInfo m-b-20"
                      },
                      [
                        _c(
                          "el-descriptions",
                          {
                            staticClass: "margin-top",
                            attrs: { column: 3, border: "" }
                          },
                          [
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _c("i", { staticClass: "el-icon-user" }),
                                  _vm._v(" 会员 ")
                                ]),
                                _c("el-tag", [
                                  _vm._v(
                                    _vm._s(_vm.getUserNames(_vm.form.userInfo))
                                  )
                                ])
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _c("i", {
                                    staticClass: "el-icon-mobile-phone"
                                  }),
                                  _vm._v(" 手机号 ")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.form.userInfo.phone) + " "
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _c("i", { staticClass: "el-icon-bank-card" }),
                                  _vm._v(" 类型 ")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCardType(
                                        _vm.form.userCardInfo.card
                                          ? _vm.form.userCardInfo.card.type
                                          : ""
                                      )
                                    ) +
                                    " "
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _c("i", { staticClass: "el-icon-bank-card" }),
                                  _vm._v(" 卡号 ")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.form.userCardInfo.number) +
                                    " "
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _c("i", {
                                    staticClass: "el-icon-connection"
                                  }),
                                  _vm._v(" 卡状态 ")
                                ]),
                                _c(
                                  "el-tag",
                                  { attrs: { type: _vm.card_status } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatStatus(
                                          _vm.form.userCardInfo.status_id
                                        )
                                      )
                                    )
                                  ]
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _c("i", {
                                    staticClass: "el-icon-collection-tag"
                                  }),
                                  _vm._v(" 开卡类型 ")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCardOpenType(
                                        _vm.form.userCardInfo.type
                                      )
                                    ) +
                                    " "
                                )
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _c("i", { staticClass: "el-icon-time" }),
                                  _vm._v(" 开卡时间 ")
                                ]),
                                _vm.form.userCardInfo.type == 2
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              _vm.form.userCardInfo
                                                .specific_time,
                                              "YYYY-MM-DD"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.userCardInfo.type === 1 &&
                                              !_vm.form.userCardInfo
                                                .open_card_time
                                              ? "未开卡"
                                              : _vm.form.userCardInfo
                                                  .open_card_time === 0
                                              ? "未开卡"
                                              : _vm.form.userCardInfo
                                                  .open_card_time
                                          ) +
                                          " "
                                      )
                                    ])
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _c("i", { staticClass: "el-icon-money" }),
                                  _vm._v(" 余额 ")
                                ]),
                                _vm.form.userCardInfo.status_id !== 14
                                  ? _c("div", [
                                      _vm.form.userCardInfo.card.type === 0
                                        ? _c("span", [
                                            _vm.getExcessTime(
                                              _vm.form.userCardInfo.valid_time
                                            ) > 0
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-success"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getExcessTime(
                                                            _vm.form
                                                              .userCardInfo
                                                              .valid_time
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _vm.form.userCardInfo.card
                                                      ? _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatCardUnitType(
                                                                _vm.form
                                                                  .userCardInfo
                                                                  .card.type
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getExcessTime(
                                                            _vm.form
                                                              .userCardInfo
                                                              .valid_time
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _vm.form.userCardInfo.card
                                                      ? _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatCardUnitType(
                                                                _vm.form
                                                                  .userCardInfo
                                                                  .card.type
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.form.userCardInfo.limit
                                                ) +
                                                " "
                                            ),
                                            _vm.form.userCardInfo.card
                                              ? _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatCardUnitType(
                                                        _vm.form.userCardInfo
                                                          .card.type
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                    ])
                                  : _c("div", [_vm._v("未开卡")])
                              ],
                              2
                            ),
                            _c(
                              "el-descriptions-item",
                              [
                                _c("template", { slot: "label" }, [
                                  _c("i", { staticClass: "el-icon-time" }),
                                  _vm._v(" 有效期至 ")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.form.userCardInfo.status_id == 14
                                        ? "未开卡"
                                        : _vm.form.userCardInfo.valid_time
                                    ) +
                                    " "
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "预约人数", prop: "subscribe_number" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              value: _vm.form.subscribe_number
                            },
                            model: {
                              value: _vm.form.subscribe_number,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "subscribe_number", $$v)
                              },
                              expression: "form.subscribe_number"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入约课备注信息",
                      rows: "3"
                    },
                    model: {
                      value: _vm.form.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      _vm.dialog.visible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }