<template>
  <el-dialog :visible.sync="successPrompt" :close-on-click-modal="false" :show-close="false" width="30%">
    <template #title>
      <div class="dialog_title" @click="successPrompt = false">
        <i class="el-icon-close" />
      </div>
    </template>
    <div class="p-10 flex-col" style="align-items: center;">
      <div class="loader" :class="animationDisplay ? 'animation_loading' : 'loader_color'">
        <div class="tick" :class="animationDisplay ? '' : 'animation_tick'" />
      </div>
      <div>
        <div class="font-xxs text-center m-t-50">{{ isJudge == 0 ? '导出中' : isJudge == 1 ? '导出成功' : '导出失败' }}</div>
        <div class="font-xxxs m-t-10">{{ isJudge == 0 ? '我们正在努力为您导出数据，请您耐心等待片刻' : isJudge == 1 ? '导出结果已生成，请查看下载文件' : '请检查网络连接并重试' }}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    successPrompt: {
      type: Boolean,
      default: false
    },
    animationDisplay: {
      type: Boolean,
      default: false
    },
    isJudge: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>

<style scoped>
/* 圆圈 */
.loader {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid rgba(186, 237, 235, 0.4);
  border-left-color: #1bc5bd;
}

.animation_loading {
  animation: animation_collect 0.8s infinite linear both;
}

/* 圆圈动画代码 */
@keyframes animation_collect {
  0% {
    transform: rotate(360deg);
    border-left-color: #1bc5bd;
  }
  25% {
    border-left-color: #1bc5bd;
  }
  50% {
    border-left-color: #1bc5bd;
  }
  75% {
    border-left-color: #1bc5bd;
  }
  100% {
    transform: rotate(0deg);
  }
}
.loader_color {
  border: 4px solid rgba(27, 197, 189, 1);
}

/* 对号 */
.loader .tick {
  position: absolute;
  content: '';
  top: 50%;
  left: 17px;
  border: 4px solid #1bc5bd;
  border-left-width: 0;
  border-bottom-width: 0;
  transform: scaleX(-1) rotate(135deg);
  transform-origin: left top;
  /* 外层执行动画执行时，里面不显示，但是不能使用display:none,所以用opacity代替 */
  opacity: 0;
}

.animation_tick {
  /* 设置动画延迟1s执行，先执行外层圆圈动画 结束后执行该动画 */
  animation: animation_true 0.3s linear 1 both;
}

@keyframes animation_true {
  0% {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
  33% {
    opacity: 1;
    width: 20px;
    height: 0px;
  }
  100% {
    opacity: 1;
    width: 20px;
    height: 40px;
  }
}
</style>
