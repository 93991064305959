var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.discountActivities
      ? _c(
          "div",
          {
            staticClass: "discountActivities cursor",
            on: { click: _vm.handleActivePopup }
          },
          [
            _c("div", { staticClass: "discount_bg" }, [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "discount_close",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleClose(0)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            ])
          ]
        )
      : _vm._e(),
    _vm.discountPopover
      ? _c("div", { staticClass: "discountPopover" }, [
          _c("div", { staticClass: "popover_bg" }, [
            _c(
              "div",
              {
                staticClass: "popover_button cursor",
                on: { click: _vm.handlePopover }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/activity/popoverButton.png"),
                    alt: ""
                  }
                })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "popover_close cursor",
                on: {
                  click: function($event) {
                    return _vm.handleClose(1)
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-close" })]
            )
          ])
        ])
      : _vm._e(),
    _vm.discountPopover ? _c("div", { staticClass: "discountBg" }) : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "discount_font" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/activity/suspensionFont.png"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }