<template>
  <el-dialog
    title="添加邀请好友记录"
    :visible.sync="visible"
    width="30%"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">新增邀请记录</div>
        <div class="title_icon cursor" @click="handleCloseDialog">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <!-- 表单 -->
    <el-form ref="ruleForm" :rules="rules" label-position="left" :model="form" label-width="120px">
      <!-- 邀请品牌 -->
      <el-form-item label="邀请者品牌">
        <Select :type="1" @changeId="cardIdI" />
      </el-form-item>
      <!-- 邀请人 -->
      <el-form-item label="邀请人" prop="invite_store_user_id">
        <el-select v-model="form.invite_store_user_id" placeholder="请选参与员工" :disabled="!form.inviteAppid">
          <el-option v-for="(item, index) in inviterData.list" :key="index" :label="item.real_name" :value="item.id">
            <template v-slot:default>
              <div class="flex">
                <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="item.avatar">
                  <img :src="errorAvatarSrc(item, 'img')">
                </el-avatar>
                <span>{{ item.real_name.length > 6 ? item.real_name.substring(0,5) + '...' : item.real_name }}</span>
                -
                <span>{{ item.phone }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 被邀请的品牌 -->
      <el-form-item label="被邀请者品牌">
        <Select :type="1" @changeId="cardIdC" />
      </el-form-item>
      <!-- 被邀请人 -->
      <el-form-item label="被邀请人" prop="invited_store_user_id">
        <el-select v-model="form.invited_store_user_id" placeholder="请选参与员工" :disabled="!form.inviteeAppid">
          <el-option v-for="(item, index) in inviteeData.list" :key="index" :label="item.real_name" :value="item.id">
            <template v-slot:default>
              <div class="flex">
                <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="item.avatar">
                  <img :src="errorAvatarSrc(item, 'img')">
                </el-avatar>
                <span>{{ item.real_name.length > 6 ? item.real_name.substring(0,5) + '...' : item.real_name }}</span>
                -
                <span>{{ item.phone }}</span>
              </div>
            </template>
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 获取的佣金金额 -->
      <el-form-item label="佣金金额" prop="cashback">
        <el-input v-model="form.cashback" placeholder="请输入佣金金额" type="number">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <!-- 邀请凭证 -->
      <el-form-item label="邀请凭证" prop="proof">
        <el-upload
          v-loading="upLoading"
          class="upload-venues"
          :action="adminUpload"
          :on-success="upSuccess"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileList"
          list-type="picture-card"
          :headers="utils.upload_headers()"
          :limit="6"
          :on-exceed="handleExceed"
          :on-progress="upProgress"
          :before-upload="checkFileType"
          accept=".jpg,.jpeg,.png"
        >
          <i class="el-icon-plus" />
        </el-upload>
        <el-dialog :visible.sync="dialogVisibleS" class="dialog_view_image">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="buttonLoading" @click="handleCloseDialog">取 消</el-button>
      <el-button type="primary" :loading="buttonLoading" @click="handleConfirm('ruleForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { accountList } from '@/api/admin/merchants.js'
import { addInvite } from '@/api/admin/invite.js'
// 组件
import Select from '@/components/elSelect/Select'

export default {
  components: { Select },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        inviteAppid: '',
        invite_store_user_id: '',
        inviteeAppid: '',
        invited_store_user_id: '',
        cashback: '',
        proof: []
      },
      rules: {
        invite_store_user_id: [{ required: true, message: '请选择邀请人', trigger: 'change' }],
        invited_store_user_id: [{ required: true, message: '请选择被邀请人', trigger: 'change' }],
        cashback: [{ required: true, message: '请输入佣金金额', trigger: 'change' }],
        proof: [{ required: true, message: '请上传邀请凭证', trigger: 'change' }]
      },
      inviterData: {
        list: [],
        count: 0
      },
      inviteeData: {
        list: [],
        count: 0
      },
      fileList: [],
      dialogImageUrl: '',
      dialogVisibleS: false,
      title: '被邀请的品牌',
      buttonLoading: false
    }
  },
  mounted() {},
  methods: {
    // 关闭弹窗
    handleCloseDialog() {
      this.$emit('close')
    },
    handleConfirm(formName) {
      // 上传链接至接口处
      if (this.fileList.length !== 0) {
        this.fileList.forEach(item => {
          this.form.proof.push(item.response.data.path)
        })
      } else {
        this.form.proof = this.fileList
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.buttonLoading = true
          addInvite(this.form)
            .then(res => {
              this.$message.success('添加成功')
              this.$emit('close')
            })
            .catch(() => {
              this.form.proof = []
            })
            .finally(() => {
              this.buttonLoading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cardIdI(val) {
      this.form.inviteAppid = val
      this.form.invite_store_user_id = ''
      this.staffList(1)
    },
    cardIdC(val) {
      this.form.inviteeAppid = val
      this.form.invited_store_user_id = ''
      this.staffList(2)
    },
    staffList(type) {
      const id = type === 1 ? this.form.inviteAppid : this.form.inviteeAppid
      accountList(id, { limit: 999 }).then(res => {
        if (type === 1) this.inviterData = res.data
        else this.inviteeData = res.data
      })
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisibleS = true
    },
    // 图片移除事件
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    // 上传成功事件
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.fileList = fileList
    },
    // 上传图片大小的限制
    checkFileType(file) {
      if (file.size / 4000 / 4000 > 0.1) {
        this.$message.warning('上传图片过大，请重新上传！')
        return false
      }
    },
    // 上传图片数量的限制
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 6 张图片')
    },
    upProgress() {
      this.upLoading = true
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
