<!-- eslint-disable vue/no-parsing-error -->
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :show-close="false"
    :width="titleName === 'posters_marketing' ? '40%' : '25%'"
    @open="handleOpen"
    @close="handleClose"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">{{ titleName === 'posters_marketing' ? '选择营销活动' : '选择非活动海报' }}</div>
        <div class="title_icon cursor" @click="handleCancel">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <el-row>
      <el-col :span="titleName === 'posters_marketing' ? 10 : 24">
        <el-skeleton :loading="skeletonLoading" animated>
          <template slot="template">
            <el-skeleton-item variant="image" :style="{ margin: titleName === 'posters_marketing' ? '10px' : '0 0 0 90px' }" style="width: 250px; height: 440px;" />
          </template>
          <template>
            <div class="flex flex-col">
              <el-image
                v-if="is_img"
                class="cursor"
                style="width: 250px; margin: '10px'"
                :src="imgDataList.preview_image"
                :preview-src-list="[imgDataList.preview_image]"
              />
              <el-image
                v-else
                class="cursor"
                style="width: 250px; margin: '10px'"
                :src="imgDataList.background_image"
                :preview-src-list="[imgDataList.background_image]"
              />
              <div class="m-t-10">
                <el-button type="info" icon="el-icon-download" @click="handleDownload">
                  {{ is_img ? '下载模板' : '下载原图' }}
                </el-button>
                <el-button type="success" icon="el-icon-picture-outline" @click="is_img = !is_img">
                  {{ is_img ? '查看原图' : '查看模板' }}
                </el-button>
              </div>
            </div>
          </template>
        </el-skeleton>
      </el-col>
      <el-col v-if="titleName === 'posters_marketing'" :span="14">
        <el-skeleton :loading="skeletonLoading" animated>
          <template slot="template">
            <div class="m-b-10">
              <el-skeleton-item variant="p" class="m-b-10" style="width: 50%" />
              <el-skeleton-item variant="p" class="m-b-10" style="width: 100%" />
              <el-skeleton-item variant="p" class="m-b-10" style="width: 100%" />
              <el-skeleton-item variant="p" class="m-b-10" style="width: 100%" />
              <el-skeleton-item variant="p" class="m-b-10" style="width: 40%" />
            </div>
            <div class="m-b-10">
              <el-skeleton-item variant="p" class="m-b-10" style="width: 50%" />
              <el-skeleton-item variant="p" class="m-b-10" style="width: 100%" />
              <el-skeleton-item variant="p" class="m-b-10" style="width: 100%" />
              <el-skeleton-item variant="p" class="m-b-10" style="width: 100%" />
              <el-skeleton-item variant="p" class="m-b-10" style="width: 40%" />
            </div>
          </template>
          <template>
            <div v-if="marketList.list.length !== 0" class="p-r-20" style="height: 55vh; overflow-y: scroll;">
              <div
                v-for="(item, index) in marketList.list"
                :key="index"
                :style="{ border: item.id === activityId ? '2px solid #46a6ff' : '2px solid #ebeef5' }"
                class="m-b-10 cursor div_box"
                @click="handleId(item.id)"
              >
                <div v-if="item.is_seckill === 1" style="color: #82848a;" class="m-b-8">
                  活动时间：{{ `${item.start_time} - ${item.end_time}` }}
                </div>
                <div class="m-b-8">{{ item.name }}</div>
                <div class="flex row-between" style="color: #e67e22;">
                  <div v-if="item.is_seckill === 1">秒杀价：{{ item.seckill_price }}</div>
                  <div v-else>活动价：{{ item.price }}</div>
                  <div>进度：{{ `${item.sales_actual}/${item.inventory}` }}</div>
                </div>
                <div v-if="item.is_seckill === 1" class="div_label">
                  <span class="div_labelZ" :style="{'background-color': new Date().getTime() < new Date(item.start_time).getTime() ? '#FF6B6B' : new Date().getTime() > new Date(item.end_time).getTime() ? '#52C41A' : '#FFD700'}">
                    {{ new Date().getTime() < new Date(item.start_time).getTime() ? '未开始' : new Date().getTime() > new Date(item.end_time).getTime() ? '已结束' : '进行中' }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else class="venuesList_empty text-center p-10">
              <img :src="empty_default_img" alt="">
              <div>
                <div class="text-info">暂无营销活动</div>
                <!-- <el-link type="primary" @click="$router.push({ name: 's_seckill_add' })">去创建</el-link> -->
              </div>
            </div>
          </template>
        </el-skeleton>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" icon="el-icon-right" @click="handleNextStep">下一步</el-button>
    </div>
  </el-dialog>
</template>

<script>
// API
import { getPosterTemplateById, getMarketingActivity } from '@/api/store/posterGeneration.js'

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    },
    titleName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgDataList: {},
      marketList: {
        count: 0,
        list: []
      },
      is_img: true,
      skeletonLoading: false,
      activityId: 0
    }
  },
  watch: {},
  mounted() {},
  methods: {
    // 打开的回调
    handleOpen() {
      this.skeletonLoading = true
      getPosterTemplateById(this.id).then(res => {
        this.imgDataList = res.data
      })
      getMarketingActivity().then(res => {
        this.marketList = res.data
        this.skeletonLoading = false
        if (this.marketList.const === 0) return false
        this.activityId = this.marketList.list[0].id
      })
    },
    // 关闭的回调
    handleClose() {
      this.is_img = true
      this.activityId = 0
    },
    // 取消回调
    handleCancel() {
      this.handleCallback()
    },
    // 回调关闭窗口
    handleCallback() {
      this.$emit('cancel')
    },
    // 下载图片
    handleDownload() {
      const img = this.is_img ? this.imgDataList.preview_image : this.imgDataList.background_image
      const name = this.is_img ? '模板' : '原图'
      // 调用 utils 里面的方法函数 下载图片
      this.utils.downloadIamge(img, name)
    },
    handleId(id) {
      this.activityId = id
    },
    handleNextStep() {
      const uId = this.titleName === 'posters_marketing' ? 1 : 0
      this.$router.push({ name: 's_poster_generation_edit', params: { id: this.id, activityId: this.activityId, uId: uId }})
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.venuesList_empty {
  min-height: 400px;
  img {
    -webkit-user-drag: none;
  }
}
.div_box {
  padding: 10px;
  border: 1px solid #ebeef5;
  border-radius: 8px;
  font-size: 13px;
  position: relative;
  overflow: hidden;

  .div_label {
    width: 95px;
    height: 95px;
    position: absolute;
    /*background: green;*/
    top: -50px;
    right: -50px;
    transform: rotate(45deg);

    .div_labelZ {
      position: absolute;
      bottom: 0;
      display: block;
      font-size: 14px;
      background-color: #FFA500;
      color: #fff;
      width: 95px;
      text-align: center;
    }
  }
}
</style>
