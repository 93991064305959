<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <!-- 面包屑 -->
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <!-- 系统公告 -->
      <div v-if="utils.getVenues()" class="right-menu-item m-r-10 notice ">
        <HeaderNotice />
      </div>

      <template v-if="device !== 'mobile'">
        <div v-if="utils.getVenues()" class="right-menu-item venues_name" @click="switchVenues">
          <el-tooltip class="item" effect="dark" content="切换场馆" placement="bottom">
            <span v-if="utils.getVenues().id != -1">
              当前馆：
              <el-link type="primary" href="#">{{ utils.getVenues().name }}</el-link>
            </span>
            <el-link v-else>品牌管理</el-link>
          </el-tooltip>
        </div>
        <!-- <search id="header-search" class="right-menu-item" /> -->

        <error-log class="errLog-container right-menu-item hover-effect" />

        <!-- 切换全屏 -->
        <!-- <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->

        <!-- 切换布局大小 -->
        <!-- <el-tooltip :content="$t('navbar.size')" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip> -->

        <!-- <lang-select class="right-menu-item hover-effect" /> -->
      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="flex avatar-wrapper m-r-10">
          <img v-if="avatar" :src="avatar + '?imageView2/1/w/80/h/80'" class="user-avatar">
          <img v-else :src="defaultLogo" class="user-avatar">
          <div v-if="getAuthority() === 'admin'">
            <div class="user-name" title="管理员">管理员</div>
          </div>
          <div v-else>
            <div
              class="user-name"
              :title="userinfo.storeInfo.real_name ? userinfo.storeInfo.real_name : userinfo.account"
            >
              {{ userinfo.storeInfo.real_name ? userinfo.storeInfo.real_name : userinfo.account }}
            </div>
          </div>
        </div>

        <el-dropdown-menu slot="dropdown">
          <router-link :to="getAuthority() !== 'admin' ? '/profile/index' : '/profileAdmin/index'">
            <el-dropdown-item>
              {{ $t('navbar.profile') }}
            </el-dropdown-item>
          </router-link>
          <router-link v-if="getAuthority() !== 'admin'" to="/profile/index?resetpass=2">
            <el-dropdown-item>
              {{ $t('navbar.phone') }}
            </el-dropdown-item>
          </router-link>
          <router-link :to="getAuthority() !== 'admin' ? '/profile/index?resetpass=1' : '/profileAdmin/index'">
            <el-dropdown-item>
              {{ $t('navbar.resetPass') }}
            </el-dropdown-item>
          </router-link>
          <router-link to="/">
            <el-dropdown-item>
              {{ $t('navbar.dashboard') }}
            </el-dropdown-item>
          </router-link>
          <div v-if="utils.getVenues()" @click="switchVenues">
            <el-dropdown-item>
              <div>切换场馆</div>
            </el-dropdown-item>
          </div>
          <!-- <div @click="handleRefresh">
            <el-dropdown-item>
              <el-tooltip class="item" effect="dark" :content="'v_' + version" placement="bottom">
                <div class="flex">
                  <i class="el-icon-refresh" />刷新
                </div>
              </el-tooltip>
            </el-dropdown-item>
          </div> -->
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">{{ $t('navbar.logOut') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import ErrorLog from '@/components/ErrorLog'
import HeaderNotice from '@/components/HeaderNotice'
// import Screenfull from '@/components/Screenfull'
// import SizeSelect from '@/components/SizeSelect'
// import LangSelect from '@/components/LangSelect'
// import Search from '@/components/HeaderSearch'

export default {
  components: {
    Breadcrumb,
    Hamburger,
    ErrorLog,
    HeaderNotice
    // Screenfull,
    // SizeSelect,
    // LangSelect,
    // Search
  },
  data() {
    return {
      version: process.env.VUE_APP_Version
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'device', 'avatar', 'userinfo'])
  },
  methods: {
    getAuthority() {
      return localStorage.getItem('authority')
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?authority=${localStorage.getItem('authority')}&redirect=${this.$route.fullPath}`)
      this.$router.push(`/login`)
    },
    switchVenues() {
      localStorage.removeItem('Venues')
      // this.$router.push(`/login?authority=store&redirect=${this.$route.fullPath}`)
      this.$router.push(`/login`)
    },
    handleRefresh() {
      // 执行浏览器缓存刷新
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }
    .venues_name {
      font-size: 14px !important;
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .notice {
      ::v-deep {
        .el-badge__content.is-fixed {
          top: 10px;
        }
      }
    }

    .avatar-container {
      // margin-right: 70px;

      .avatar-wrapper {
        width: 130px;
        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
          margin-right: 10px;
        }

        .user-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 13px;
          // cursor: pointer;
          // position: absolute;
          // right: -50px;
          // top: -6px;
          // font-size: 13px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
