<template>
  <el-dialog
    :visible.sync="dialogVisibleApiRouteList"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
    :show-close="false"
    @open="handleOpen"
    @close="handleClose('ruleForm')"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">{{ type === 'add' ? '新增路由' : '编辑路由' }}</div>
        <div class="title_icon cursor" @click="handleClose('ruleForm')">
          <i class="el-icon-close" />
        </div>
      </div>
      <el-form ref="ruleForm" v-loading="formLoading" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm m-t-40">
        <el-form-item label="父级分类" prop="classify_id">
          <SelectTree
            :props="defaultProps"
            :options="classDetails"
            :value="ruleForm.classify_id"
            :clearable="isClearable"
            :accordion="isAccordion"
            :root-name="'顶级分类'"
            @getValue="getValue($event)"
          />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name" clearable />
        </el-form-item>
        <el-form-item label="请求方式" prop="method">
          <el-select v-model="ruleForm.method" clearable>
            <el-option v-for="item in method" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="api路径" prop="url">
          <el-input v-model="ruleForm.url" clearable />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="ruleForm.status">
            <el-radio-button :label="0">停用</el-radio-button>
            <el-radio-button :label="1">启用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否鉴权" prop="not_authentication">
          <el-radio-group v-model="ruleForm.not_authentication">
            <el-radio-button :label="1">否</el-radio-button>
            <el-radio-button :label="0">是</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="ruleForm.mark" type="textarea" :rows="3" clearable placeholder="请输入备注" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="buttonLoading" @click="handleClose('ruleForm')">取 消</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { getApiRouteCategoryList, addApiRoute, updateApiRoute, getApiRouteDetail } from '@/api/admin/account.js'
// 组件
import SelectTree from '@/components/TreeSelect/treeSelect.vue'
// 函数
import { deepClone } from '@/utils'

export default {
  components: { SelectTree },
  props: {
    dialogVisibleApiRouteList: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    typeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: {
        classify_id: 0,
        name: '',
        method: '',
        url: '',
        status: 0,
        not_authentication: 1,
        mark: ''
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        method: [{ required: true, message: '请选择请求方式', trigger: 'change' }],
        url: [{ required: true, message: '请输入api路径', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        not_authentication: [{ required: true, message: '请选择是否鉴权', trigger: 'change' }]
      },
      roleList: [],
      buttonLoading: false,
      formLoading: false,
      method: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
      classDetails: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      isClearable: false, // 可清空（可选）
      isAccordion: false // 可收起（可选）
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.$nextTick(() => {
        this.getClassDetails()
        if (this.type === 'edit') this.getDetails()
      })
    },
    // 关闭的回调
    handleClose(formName) {
      this.$emit('apiRouteVisibleList')
      this.$refs[formName].resetFields()
    },
    // 保存表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          const data = deepClone(this.ruleForm)
          if (this.type === 'add') {
            addApiRoute(data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('新增成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          } else {
            updateApiRoute(this.typeId, data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('编辑成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    // 详情
    getDetails() {
      this.formLoading = true
      getApiRouteDetail(this.typeId)
        .then(res => {
          const { data } = res
          const {
            classify_id,
            name,
            method,
            url,
            status,
            not_authentication,
            mark
          } = data
          this.ruleForm = {
            classify_id,
            name,
            method,
            url,
            status,
            not_authentication,
            mark
          }
        })
        .finally(() => {
          this.formLoading = false
        })
    },
    // 分类详情
    getClassDetails() {
      getApiRouteCategoryList()
        .then(res => {
          const { data } = res
          this.classDetails = data
        })
    },
    // 取值
    getValue(value) {
      this.ruleForm.classify_id = value
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
