<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleEditContract"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="45%"
      :show-close="false"
      @open="handleOpen"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">编辑合同</div>
          <div class="title_icon cursor" @click="handleClose">
            <i class="el-icon-close" />
          </div>
        </div>
      </div>
      <div v-loading="editLoading" class="tags_layout">
        <!-- 表单 -->
        <el-form ref="ruleForm" :model="form" label-position="left" label-width="160px">
          <p><b>基本设置</b></p>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="合同名称">
                <el-input v-model="form.name" placeholder="请输入名称" />
              </el-form-item>
            </el-col>
            <el-col v-if="!!editType" :span="12">
              <el-form-item label="合同有效期">
                <el-date-picker
                  v-model="form.contract_expire_time"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="合同标签">
                <el-tag v-for="(item, index) in form.tag_id" :key="index" class="m-6">{{ item.name }}</el-tag>
                <el-tag class="cursor m-l-6" type="success" @click="handleTag">
                  {{ form.tag_id.length > 0 ? '+ 编辑标签' : '+ 添加标签' }}
                </el-tag>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同签署区域">
                <el-tag class="cursor" type="success" @click="dialogVisiblePdf = true">去编辑</el-tag>
              </el-form-item>
            </el-col>
          </el-row>
          <p><b>签署设置</b></p>
          <el-row :gutter="20">
            <el-col style="position: relative;" :span="12">
              <el-form-item label="会员合同签署截至期限">
                <el-switch v-model="form.sign_valid_period" :active-value="1" :inactive-value="0" />
              </el-form-item>
              <div class="text-info" style="position: absolute; top: 30px; left: 10px;">
                (开启后会员需在规定时间签署，否则将逾期)
              </div>
            </el-col>
            <el-col v-if="form.sign_valid_period" :span="12">
              <el-form-item label="未签署逾期时间">
                <el-input v-model="day">
                  <template slot="append">天内</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col style="position: relative;" :span="12">
              <el-form-item label="会员签署时强制阅读">
                <el-switch v-model="form.verify_read_seconds" :active-value="1" :inactive-value="0" />
              </el-form-item>
              <div class="text-info" style="position: absolute; top: 30px; left: 10px;">
                (开启后会员签署时需要阅读规定时间才能签署)
              </div>
            </el-col>
            <el-col v-if="form.verify_read_seconds" :span="12">
              <el-form-item label="阅读时间">
                <el-input v-model="second">
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p><b>通知设置</b></p>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="会员签署后通知我">
                <el-switch v-model="form.notify_store_user" :active-value="1" :inactive-value="0" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="短信通知会员签署合同">
                <el-switch v-model="form.enable_sms" :active-value="1" :inactive-value="0" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlePreserve">保 存</el-button>
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 标签 -->
    <tagDialog
      :dialog-visible-template="dialogVisibleTemplate"
      :template-tags="templateTags"
      @closeVisibleTemplate="closeVisibleTemplate"
    />
    <!-- pdf组件 -->
    <pdfDialog
      :dialog-visible-pdf="dialogVisiblePdf"
      :src="srcPdf"
      :signature-data="signatureData"
      @closeVisiblePdf="closeVisiblePdf"
    />
  </div>
</template>

<script>
// API
import { getTagList, getTemplateDetail, editTemplate } from '@/api/store/contract'
// 组件
import tagDialog from '../components/tagDialog'
import pdfDialog from '../components/pdfDialog'
// 函数
import { deepClone } from '@/utils'

export default {
  components: { tagDialog, pdfDialog },
  props: {
    dialogVisibleEditContract: {
      type: Boolean,
      default: false
    },
    editContractId: {
      type: Number,
      default: 0
    },
    editType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      form: {
        name: '',
        contract_expire_time: '',
        tag_id: [],
        notify_store_user: 0,
        enable_sms: 0,
        sign_valid_period: 0,
        verify_read_seconds: 0
      },
      day: 1,
      second: 3,
      editLoading: false,
      dialogVisibleTemplate: false,
      templateTags: [],
      tagList: [],
      dialogVisiblePdf: false,
      srcPdf: '',
      signatureData: {},
      pdfData: {}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getTagList().then(res => {
        const { data } = res
        this.tagList = data.list
      })
    },
    // 打开的回调
    handleOpen() {
      this.editLoading = true
      getTemplateDetail(this.editContractId)
        .then(res => {
          const { data } = res
          const {
            name,
            config: { contract_expire_time, signature },
            tags,
            setting: { notify_store_user, enable_sms, sign_valid_period, verify_read_seconds },
            file_path
          } = data
          this.form = {
            name,
            contract_expire_time,
            tag_id: tags,
            notify_store_user,
            enable_sms,
            sign_valid_period,
            verify_read_seconds
          }
          if (!sign_valid_period) this.form.sign_valid_period = 0
          else {
            this.day = this.form.sign_valid_period
            this.form.sign_valid_period = 1
          }
          if (verify_read_seconds > 0) {
            this.second = this.form.verify_read_seconds
            this.form.verify_read_seconds = 1
          }
          this.signatureData = signature
          this.pdfData = signature
          this.srcPdf = file_path
        })
        .finally(() => {
          this.editLoading = false
        })
    },
    // 关闭的回调
    handleClose() {
      this.$emit('editContractVisible')
    },
    // 保存
    handlePreserve() {
      const data = deepClone({
        ...this.form,
        ...this.pdfData
      })
      if (data.sign_valid_period === 0) data.sign_valid_period = -1
      else data.sign_valid_period = this.day
      if (data.verify_read_seconds > 0) data.verify_read_seconds = this.second
      if (data.tag_id.length > 0) {
        const idList = []
        data.tag_id.forEach(item => {
          idList.push(item.id)
        })
        data.tag_id = idList.toString()
      } else {
        data.tag_id = ''
      }
      editTemplate(this.editContractId, data).then(() => {
        this.$message.success('编辑成功')
        this.handleClose()
        this.form = {
          name: '',
          contract_expire_time: '',
          tag_id: [],
          notify_store_user: 0,
          enable_sms: 0,
          sign_valid_period: 0,
          verify_read_seconds: 0
        }
      })
    },
    handleTag() {
      if (this.form.tag_id.length > 0) this.templateTags = this.form.tag_id
      else this.templateTags = []
      this.dialogVisibleTemplate = true
    },
    closeVisibleTemplate(val) {
      this.getList()
      this.dialogVisibleTemplate = false
      const tag = val.map(value => {
        const tagObject = this.tagList.find(item => item.id === value)
        return tagObject || null
      })
      this.form.tag_id = tag
    },
    closeVisiblePdf(val) {
      this.dialogVisiblePdf = false
      this.pdfData = val
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
