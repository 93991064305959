<template>
  <div class="app-container">
    <!-- 搜索 -->
    <!-- <div class="searchBox">
      <el-form :model="listQueryParams" label-width="80px" label-position="left" />
      <div>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </div>
    </div> -->
    <!-- 类型 -->
    <el-tabs v-model="type" class="m-t-10" @tab-click="handleChange">
      <el-tab-pane label="进行中" name="1" />
      <el-tab-pane label="未开始" name="0" />
      <el-tab-pane label="已结束" name="2" />
    </el-tabs>
    <!-- 通用搜索 -->
    <div class="filter-container m-t-20">
      <div class="filter-item">
        <el-input
          v-model="listQueryParams.query"
          placeholder="搜索活动名称"
          @keyup.enter.native="handleFilter"
        >
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('s_group_booking_add')" type="primary" class="filter-item m-l-10" @click="handleAdd">新增活动</el-button>
    </div>
    <!-- 表格 -->
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="name" label="活动名称" min-width="120" />
      <el-table-column prop="cover" label="活动封面" min-width="100">
        <template slot-scope="{ row }">
          <el-image v-if="row.cover" style="width: 160px;" :src="row.cover" />
          <div v-else class="text-danger">暂无封面图</div>
        </template>
      </el-table-column>
      <el-table-column label="价格">
        <template slot-scope="{ row }">
          {{ `${row.price} 元` }}
        </template>
      </el-table-column>
      <el-table-column label="成团人数">
        <template slot-scope="{ row }">
          {{ `${row.people_number} 人` }}
        </template>
      </el-table-column>
      <el-table-column label="自动成团天数">
        <template slot-scope="{ row }">
          {{ `${row.validity_day} 天` }}
        </template>
      </el-table-column>
      <el-table-column label="售卡方式">
        <template slot-scope="{ row }">
          {{ `${cardMethod[row.sales_method]}` }}
        </template>
      </el-table-column>
      <el-table-column label="是否推荐" min-width="60">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_recommend === 0" type="danger">否</el-tag>
          <el-tag v-if="row.is_recommend === 1" type="success">是</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="活动状态" min-width="60">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status === 0" type="danger">已停止</el-tag>
          <el-tag v-if="row.status === 1" :type="type === '1' ? 'success' : 'info'">{{ type === '1' ? '进行中' : type === '0' ? '未开始' : '已结束' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="活动时间" min-width="150">
        <template slot-scope="{ row }">
          {{ `${row.start_time.slice(0, 16)} - ${row.end_time.slice(0, 16)}` }}
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="140px">
        <template slot-scope="{ row }">
          <el-button class="m-2" @click="handleDetails(row.id)">
            详 情
          </el-button>
          <el-button v-show="btn_access('s_group_booking_edit')" class="m-2" type="primary" @click="handleEdit(row.id)">
            编 辑
          </el-button>
          <el-button
            v-show="btn_access('s_group_booking_operate')"
            class="m-2"
            :type="row.status === 1 ? 'danger' : 'success'"
            :disabled="row.status === 1 && type === '2'"
            @click="handleActivity(row.id, row.status)"
          >
            {{ row.status === 1 ? '停止活动' : '开启活动' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <detailsDialog :dialog-visible-account="dialogVisibleAccount" :type-id="typeId" @roleVisible="handleRoleVisible" />
  </div>
</template>

<script>
// API
import { getTeamworkList, stopTeamwork } from '@/api/store/teamwork.js'
// 组件
import Pagination from '@/components/Pagination'
import detailsDialog from './components/detailsDialog'

export default {
  components: { Pagination, detailsDialog },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      TableLoading: false,
      searchLoading: false,
      type: '1',
      cardMethod: ['单卡售卡', '自定义组合售卡', '组合卡套餐售卡'],
      is_id: false,
      typeId: 0,
      dialogVisibleAccount: false
    }
  },
  destroyed() {
    // 只有离开当前页面时，才进行销毁
    if (!this.is_id) localStorage.removeItem('teamworkType')
  },
  mounted() {
    if (localStorage.getItem('teamworkType')) this.type = localStorage.getItem('teamworkType')
    this.getList()
  },
  methods: {
    getList() {
      this.TableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'name', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      getTeamworkList(this.type, data)
        .then(res => {
          this.tableData = res.data
        })
        .finally(() => {
          this.TableLoading = false
          this.searchLoading = false
        })
    },
    handleDetails(id) {
      this.typeId = id
      this.dialogVisibleAccount = true
      // this.is_id = true
      // localStorage.setItem('teamworkType', this.type)
      // this.$router.push({ name: 's_teamwork_activity_details', params: { id }})
    },
    handleAdd() {
      this.is_id = true
      localStorage.setItem('teamworkType', this.type)
      this.$router.push({ name: 's_group_booking_add' })
    },
    handleEdit(id) {
      this.is_id = true
      localStorage.setItem('teamworkType', this.type)
      this.$router.push({ name: 's_group_booking_edit', params: { id }})
    },
    // 监听类型变化
    handleChange(val) {
      this.listQueryParams.query = ''
      this.getList()
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 操作活动
    handleActivity(id, type) {
      // 穿过来的type是0则表示该活动已停止 1则是活动已开启
      this.$confirm(
        type === 1 ? '活动停止后将无法创建新团，未完成的拼团将自动成团，确定停止该活动吗？' : '活动开启后将直接进行拼团，确定重新开启该活动吗？',
        '温馨提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        var status = null
        if (type === 0) status = 1
        if (type === 1) status = 0
        stopTeamwork(id, status)
          .then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
          })
      })
    },
    handleRoleVisible() {
      this.dialogVisibleAccount = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
