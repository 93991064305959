var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleTags,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "40%",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleTags = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "title_title" }, [_vm._v("选择标签")]),
              _c(
                "div",
                {
                  staticClass: "title_icon cursor",
                  on: {
                    click: function($event) {
                      return _vm.handleClose(false)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            ])
          ]),
          _c(
            "div",
            [
              _c("div", { staticClass: "font-title" }, [_vm._v("默认标签")]),
              _vm._l(_vm.tagsData.default, function(item, index) {
                return _c(
                  "el-tag",
                  {
                    key: index,
                    staticClass: "m-t-10 m-r-10 m-b-10 cursor",
                    attrs: {
                      type: _vm.tags.indexOf(item) !== -1 ? "primary" : "info"
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleChoice(item)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              }),
              _c(
                "div",
                { staticClass: "flex row-between" },
                [
                  _c("div", { staticClass: "font-title" }, [
                    _vm._v("自定义标签")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.is_edit ? "success" : "primary",
                        size: "mini",
                        icon: "el-icon-edit",
                        plain: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.is_edit = !_vm.is_edit
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.is_edit ? "完 成" : "编 辑") + " "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._l(_vm.tagsData.customize, function(item) {
                return _c(
                  "el-tag",
                  {
                    key: item.id,
                    staticClass: "m-t-10 m-r-10 m-b-10 cursor",
                    attrs: {
                      type:
                        _vm.tags.indexOf(item.name) !== -1 ? "primary" : "info",
                      closable: _vm.is_edit
                    },
                    on: {
                      close: function($event) {
                        return _vm.handleEditTags(item.id, item.name)
                      },
                      click: function($event) {
                        return _vm.handleChoice(item.name)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              !_vm.is_edit
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "mini",
                        icon: "el-icon-plus",
                        plain: ""
                      },
                      on: { click: _vm.handleAddTags }
                    },
                    [_vm._v(" 添 加 ")]
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading },
                  on: {
                    click: function($event) {
                      return _vm.handleClose(false)
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }