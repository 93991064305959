<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div v-loading="pageloading" class="plan-buy-box">
    <div class="header flex flex-wrap row-between" :style="{ backgroundImage: 'url(' + defaultHeader + ')' }">
      <div class="header-title">服务套餐购买</div>
      <div class="app-info flex flex-wrap row-center">
        <div class="app-logo">
          <el-avatar :size="40" :src="appInfo.logo ? appInfo.logo : defaultLogo">
            <img :src="errorImageSrc">
          </el-avatar>
        </div>
        <div class="app-s">
          <div class="app-name">{{ appInfo.name }}</div>
          <div class="app-expire">
            <div>到期时间：</div>
            <div
              v-if="appInfo.expiration_time != 0 && appInfo.expiration_time < $moment().unix()"
              class="text-danger"
              style="display:inline"
            >
              已到期
            </div>
            <div v-else class="expiration-time">
              {{ appInfo.expiration_time ? formatTime(appInfo.expiration_time) : '永久' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-alert
        v-if="appInfo.expiration_time != 0 && appInfo.expiration_time < $moment().unix()"
        :closable="false"
        title="套餐过期，请及时续费！"
        type="warning"
        show-icon
      />
    </div>
    <div>
      <el-alert :closable="false" title="限时活动，先到先得" type="success" show-icon />
    </div>
    <div v-show="is_display && appInfo.expiration_time" class="flex row-evenly m-t-20 p-b-20">
      <div
        v-for="(item, indexSon) in dataList"
        v-show="brandLevelType <= indexSon + 1"
        :key="indexSon"
        :style="{ boxShadow: indexSon === typeId ? '0px 0px 10px #878e9a40' : '0px 0px 10px #878e9a20' }"
        style="width: 30%; height: 666px; border: 1px solid #f4f4f4; border-radius: 10px; "
        @mouseover="handleOver(indexSon)"
        @mouseout="handleOut(indexSon)"
      >
        <div style="height: 8px; border-radius: 10px 10px 0px 0px;" :style="{backgroundColor: indexSon === typeId ? '#45d4b7' : ''}" />
        <div class="font-18 m-t-26" style="text-align: center;">{{ item.name }}</div>
        <div class="m-t-40" style="width: 46px; margin: 0 auto;">
          <img style="width: 100%;" :src="item.img" alt="" draggable="false">
        </div>
        <div
          class="flex row-evenly m-t-30 p-b-30 font-14"
          style="border-bottom: 1px solid #f4f4f4;"
        >
          <div v-if="item.data.list">
            ￥
            <b class="font-18">{{ item.data.list[0].price }}</b>
            / {{ plan_validity(item.data.list[0]) }} {{ item.data.list.length > 1 ? '起' : '' }}
          </div>
        </div>
        <!-- <div
            class="flex row-center cursor"
            style="width: 130px; height: 40px; margin: 20px auto 0px; border-radius: 20px; color: #FFFFFF;"
            :style="{ backgroundColor: item.color }"
            @click="handleBuy(indexSon)"
          >

          </div> -->
        <div class="m-t-20 flex row-center">
          <el-button class="p-y-12 p-x-44 font-14" type="primary" round @click="handleBuy(indexSon)">{{ item.data.is_renewal == 0 && item.data.is_upgrade == 0 ? '购 买' : item.data.is_renewal == 1 ? '续 费' : item.data.is_upgrade == 1 ? '升 级' : '' }}</el-button>
        </div>
        <div class="m-t-40 p-x-30">
          <div
            v-for="(itemText, indexText) in item.text"
            :key="indexText"
            class="font-14 m-t-10"
          >
            <i class="el-icon-check m-r-6" />{{ itemText }}
          </div>
        </div>
      </div>
    </div>
    <div v-show="!is_display" class="p-30">
      <div class="flex">
        <div class="font-16">选择套餐服务：</div>
        <el-row class="flex-1">
          <el-col v-for="item in dataList[index].data.list" :key="item.id" class="m-10" :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <div
              class="cursor p-x-20 p-y-10 package m-x-10"
              :class="item.id == check_id ? 'active' : ''"
              @click="check_plan(item.id)"
            >
              <div class="font-18" style="font-weight: 600;">{{ item.name }}</div>
              <div class="flex flex-wrap m-t-6">
                <div class="font-16" style="color: red;">￥{{ item.price }}元</div>
                <div class="m-l-10" style="text-decoration: line-through; color: #bbbbbb;">{{ item.line_price }}元</div>
              </div>
              <div class="m-t-6">有效期：{{ plan_validity(item) }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div v-if="discount > 0" class="flex row-between p-10 font-14" style="width: 500px; margin: 10px 0px 0px 130px; border: 1px solid #f2f2f2; border-radius: 10px;">
        <div class="flex">
          <div class="flex row-center" style="width: 25px; height: 25px; background-color: #f04545; border-radius: 6px; color: #ffffff;">抵</div>
          <div class="m-l-4">个人版有效期抵扣</div>
        </div>
        <div class="flex">
          <div style="color: #f04545;">已抵扣<span class="font-16" style="font-weight: 600;">￥{{ discount }}</span></div>
          <el-tooltip class="item" effect="light" content="个人版套餐的剩余天数按一块钱一天的单价进行抵扣" placement="right">
            <div class="font-12 m-l-8 cursor" style="width: 16px; height: 16px; border: 1px solid #cccccc; border-radius: 50%; color: #cccccc; text-align: center;">?</div>
          </el-tooltip>
        </div>
      </div>
      <div class="m-t-20 flex">
        <div class="font-16">选择购买方式：</div>
        <div class="m-l-40 p-t-5">
          <el-radio v-model="paymentMethod" :label="1" class="flex">
            <div class="flex">
              <div class="m-r-6"><img src="@/assets/images/wxpay_icon.png" alt="" class="" style="width: 20px;"></div>
              <div>微信</div>
            </div>
          </el-radio>
          <!-- <el-radio v-model="paymentMethod" :label="2">支付宝</el-radio> -->
        </div>
      </div>
      <div class="flex row-around p-y-20" style="width: 100%; position: absolute; bottom: 0px; left: 0px; border-top: 1px solid #f2f2f2;">
        <div class="font-16" style="font-weight: 600;">瑜小九预约管理系统——{{ index == 0 ? '个人版' : index == 1 ? '旗舰版' : '定制版' }}（{{ packageName }}）</div>
        <div class="flex">
          <div class="m-r-20 flex">
            <div class="font-14 m-r-4">支付金额：￥<span class="font-24" style="color: #41c3a8; font-weight: 600;">{{ discount > 0 ? packagePrice - discount : packagePrice }}</span></div>
            <div v-if="discount > 0" class="p-t-4" style="text-decoration: line-through; color: #bbbbbb;">{{ packagePrice }}</div>
          </div>
          <el-button class="p-y-12 p-x-30" type="success" round @click="handleConfirmOrder">确认订单</el-button>
          <el-button class="p-y-12 p-x-30" type="info" round @click="handleReturn">返 回</el-button>
        </div>
      </div>
    </div>
    <div v-show="!appInfo.expiration_time">
      <el-result icon="info" title="当前为永久日期" sub-title="永久日期无需购买套餐">
      <!-- <template slot="extra">
        <el-button type="primary" size="medium">返回</el-button>
      </template> -->
      </el-result>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      width="25%"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">订单信息</div>
          <div class="title_icon cursor" @click="handleCloseDialog">
            <i class="el-icon-close" />
          </div>
        </div>
        <div v-loading="dialogVisibleLoading" class="m-t-40">
          <div class="flex flex-wrap row-center">
            <div class="" :class="pc ? 'PcPhone' : ''">
              <div class=""><canvas ref="qrcode_ban" /></div>
              <div class="flex row-center">
                <div><img src="@/assets/images/wxpay_icon.png" alt="" class="" style="width: 32px;"></div>
                <div class="m-l-10">微信支付</div>
              </div>
            </div>
            <div class="wxPayment" :class="phone ? 'PcPhone' : ''">
              <div class="wxPayment_button cursor" @click="pay">
                <div class="wxPayment_icon">
                  <img src="@/assets/images/wechatPay_icon.png" alt="" class="wxPayment_img">
                </div>
                <div class="wxPayment_title">微信支付</div>
              </div>
            </div>
          </div>
          <div class="m-t-20 p-x-20 font-16">
            <div class="m-t-10">产品名称: {{ index == 0 ? '个人版' : index == 1 ? '旗舰版' : '定制版' }}（{{ packageName }}）</div>
            <div class="m-t-10">订单号：{{ orderInfo.order.number }}</div>
            <div class="m-t-10">流水号：{{ orderInfo.prepay_id ? orderInfo.prepay_id : orderInfo.package ? orderInfo.package.slice(10) : '------' }}</div>
            <div class="m-t-10">支付金额：<span style="color: #ff5252;">{{ orderInfo.order.pay_price }}元</span></div>
            <div class="m-t-10">支付状态：<span :class="orderInfo.paid ? 'text-success' : 'text-warning'">{{ orderInfo.paid ? '已支付' : '未支付' }}</span></div>
            <div class="m-t-10">支付方式：{{ paymentMethod == 1 ? '微信' : '支付宝' }}</div>
          </div>
        </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import { buyPlan, getPackage } from '@/api/store/plan.js'
import { getUserOrderInfo } from '@/api/store/order.js'
import { getUserInfo } from '@/api/store/user'
import { getAppInfo } from '@/api/store/management.js'

export default {
  data() {
    return {
      dataList: [
        {
          name: '个人版',
          color: 'rgba(189, 159, 111, 1)',
          img: require('@/assets/images/badge/flagship.png'),
          data: {},
          text: [
            '优质活动文案',
            '专属客服',
            '数据统筹',
            '智能排课',
            '精准拓客',
            '单场馆',
            '仅限APP使用',
            '场馆数量仅限1个',
            '员工数量仅限5个',
            '会员数量仅限50个',
            '会员卡数量仅限20张'
          ]
        },
        {
          name: '旗舰版',
          color: 'rgba(173, 221, 232, 1)',
          img: require('@/assets/images/badge/that person.png'),
          data: {},
          text: [
            '优质活动文案',
            '专属客服',
            '数据统筹',
            '智能排课',
            '精准拓客',
            '多场馆使用',
            'APP + PC 端均可使用',
            '场馆数量无限制',
            '员工数量无限制',
            '会员数量无限制',
            '会员卡数量无限制'
          ]
        },
        {
          name: '定制版',
          color: 'rgba(142, 142, 142, 1)',
          img: require('@/assets/images/badge/black.png'),
          data: {},
          text: [
            '包含旗舰版所有功能',
            '场馆LOGO自定义',
            '场馆名称自定义',
            '独立商户号',
            '数据分析与运营',
            '提高用户粘性',
            '聚合周边流量',
            '私域流量'
          ]
        }
      ],
      personalList: [],
      flagshipList: [],
      customizationList: [],
      paymentMethod: 1,
      appInfo: {
        name: '品牌名称',
        logo: '',
        expiration_time: true
      },
      pageloading: false,
      check_id: '',
      is_display: true,
      packageName: '',
      packagePrice: '',
      orderInfo: {
        order: {}
      },
      dialogVisibleLoading: false,
      dialogVisible: false,
      defaultLogo: require('@/assets/images/logo_round.svg'),
      defaultHeader: require('@/assets/images/plan_header_bg.png'),
      code_url: '',
      clientWidth: 0,
      trade_type: '',
      code: '',
      userinfo: {},
      pc: true,
      phone: true,
      index: 0,
      check: '',
      brandLevelType: 0,
      discount: 0,
      typeId: ''
    }
  },
  created() {
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('micromessenger') !== -1) {
      if (this.$cookies.get('yuxiaojiu') === null) {
        console.log('111')
        this.$cookies.set('yuxiaojiu', 'yuxiaojiu', '3h')
        this.wxOfficialAccountBaseLogin()
      }
    }
  },
  mounted() {
    this.getAppInfo()
    this.getList()
    this.getUserInfo()
  },
  methods: {
    isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    getAppInfo() {
      this.pageloading = true
      getAppInfo()
        .then(res => {
          this.appInfo = res.data
          this.brandLevelType = res.data.brand_level.level
        })
        .catch(() => {})
        .finally(() => {
          this.pageloading = false
        })
    },
    getList() {
      this.clientWidth = document.documentElement.clientWidth
      for (let index = 1; index <= 3; index++) {
        getPackage(index).then(res => {
          this.dataList[index - 1].data = res.data
        })
      }
    },
    getUserInfo() {
      getUserInfo().then(res => {
        this.userinfo = res.data
      })
    },
    // 购买 升级 续费
    handleBuy(index) {
      this.index = index
      this.is_display = !this.is_display
      this.check_plan(this.dataList[index].data.list[0].id)
    },
    wxOfficialAccountBaseLogin() {
      const payloadObject = {
        host: window.location.href,
        token: localStorage.getItem('Admin-Token') ? localStorage.getItem('Admin-Token') : ''
      }
      console.log(payloadObject, '123')
      const payload = encodeURIComponent(JSON.stringify(payloadObject))
      let redirect_uri = ''
      redirect_uri = encodeURIComponent(`${process.env.VUE_APP_BASE_API}/store/v1/oauth_callback?payload=${payload}`)
      const oauthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcd6d86f3edbb3e87&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=1`
      window.location = oauthUrl
      return false
    },
    handleConfirmOrder() {
      this.dialogVisible = true
      this.dialogVisibleLoading = true
      const ua = navigator.userAgent.toLowerCase()
      if (this.isMobile()) {
        this.phone = false
        this.pc = true
        this.trade_type = 'MWEB'
        if (ua.indexOf('micromessenger') !== -1) {
          this.trade_type = 'JSAPI'
        }
      } else {
        this.pc = false
        this.phone = true
        this.trade_type = 'NATIVE'
      }
      // 生成订单
      buyPlan({ plan_id: this.check_id, trade_type: this.trade_type })
        .then(res => {
          const { data } = res
          this.orderInfo = data
          if (!this.isMobile()) {
            if (!data.is_free) {
              this.useqrcode('qrcode_ban', data.code_url)
            }
            this.check_order()
          } else if (this.isMobile()) {
            this.code_url = ''
            this.code_url = data.code_url
          }
        })
        .finally(() => {
          this.dialogVisibleLoading = false
        })
    },
    // 选择套餐
    check_plan(id) {
      if (this.check_id === id) return false
      this.check_id = id
      console.log(this.index)
      this.dataList[this.index].data.list.forEach(item => {
        if (item.id === id) {
          this.packageName = item.name
          this.packagePrice = item.price
          if (item.renewal_deduction && this.index > 0) this.discount = item.renewal_deduction
          else this.discount = 0
        }
      })
    },
    // 生成二维码
    useqrcode(name, url) {
      const that = this
      const canvas = that.$refs[name]
      // 去掉qrcode的边框（建议留1px；否则浏览器识别有些困难）
      QRCode.toCanvas(canvas, url, { width: 180, height: 180, margin: 2 }, function(error) {
        if (error) console.error(error)
        that.$forceUpdate()
      })
    },
    check_order() {
      if (!localStorage.getItem('Appid')) return false
      getUserOrderInfo(this.orderInfo.order.number).then(res => {
        // 切换页面后销毁轮询
        if (this.$route.name === 's_plan') {
          if (res.data.pay_status) {
            this.$set(this.orderInfo, 'paid', true)
            this.$message.success('支付成功')
            setTimeout(() => {
              location.reload()
            }, 1000)
            clearTimeout(this.check)
          } else {
            this.check = setTimeout(() => {
              this.check_order()
            }, 5000)
          }
        }
      })
    },
    pay() {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('micromessenger') !== -1) {
        // const data = this.$moment().unix()
        const this_ = this
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: this.orderInfo.appId, // 公众号ID，由商户传入
            timeStamp: this.orderInfo.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: this.orderInfo.nonceStr, // 随机串
            package: this.orderInfo.package,
            signType: this.orderInfo.signType, // 微信签名方式：
            paySign: this.orderInfo.paySign // 微信签名
          },
          function(res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              this_.$set(this_.orderInfo, 'paid', true)
              this_.$message.success('支付成功')
              setTimeout(() => {
                location.reload()
              }, 2000)
            } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
              this_.$message.warning('取消支付')
            } else {
              this_.$message.error('支付失败')
            }
          }
        )
      } else {
        location.href = this.code_url
        this.check_order()
      }
    },
    plan_validity(item) {
      var valid_period = JSON.parse(item.valid_period)
      const digital = valid_period['digital']
      const type = valid_period['type']
      var time = ''
      switch (type) {
        case 'month':
          time = '个月'
          break
        case 'day':
          time = '天'
          break
        case 'year':
          time = '年'
          break
        default:
          break
      }
      return digital + time
    },
    formatTime(val) {
      return this.$moment.unix(val).format('YYYY-MM-DD')
    },
    handleCloseDialog() {
      this.dialogVisible = false
      clearTimeout(this.check)
    },
    handleReturn() {
      this.is_display = true
    },
    // 鼠标移入
    handleOver(id) {
      this.typeId = id
    },
    // 鼠标移出
    handleOut(id) {
      this.typeId = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-buy-box {
  margin: 0 auto;
  width: 1200px;
  min-height: 807px;
  background: #ffffff;
  position: relative;

  .label {
    font-size: 16px;
    color: #666666;
    width: 150px;
  }
  .header {
    padding: 15px 40px;
    background-size: cover;
    .header-title {
      font-size: 25px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #ffffff;
    }
    .app-info {
      .app-name {
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
      }
      .app-expire {
        color: #a2ffec;
        font-size: 12px;
        display: flex;
      }
      .app-logo {
        margin-right: 5px;
      }
    }
    .app-s {
      width: 140px;
    }
  }
  .package {
    border: 2px solid #f1f4fb;
    box-shadow: 0 4px 10px #878e9a12;
  }
  .package:hover {
    box-shadow: 0 4px 10px #878e9a24;
  }
  .active {
    box-sizing: border-box;
    position: relative;
    border: 2px solid #41c3a8;
  }
  .active::after {
    content: '';
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAhCAYAAAC803lsAAAAAXNSR0IArs4c6QAAAyJJREFUWEfN11lIVGEUwPH/NzOOjeZU2IJiZpvZHpZZZHFnjPYNaaOH6KWEoF4ionqsl6QXe6geokAt70wrWZGtOGOSliGUEUK7RYWWrdaY94srZe5znWa0eT7nzO+e+53D/QT/wc/pVfeK3nY4itUsJNt7DyKlcNxyZSPZojejdyA6oth1CMj880Z6HLLK7TbXxmpHpGRDy2PRoxAdUROj5QDr2p7NHoOsqnRba9/L4xK5sqMB6RGIjqiplScRcllnUxpyyMwSt61PozwDckFXqyKkkHmFOZENkeHnkdLpb1+FDLLwdp79e4P5Iog0f4iQ7RHl5tn+wvLjMpBqBBESSHrpmWjN57sCJBtFBB0y71bO4AbNehWY1B1EUCGzPbkxYSLsuoSx3UUEDTKnKH+o2SSuAYmBIIICUW7mJwiLuAEMN4qIt9l5Uf+pVfg/ja9S4h4lGrXrQLxRxHj7QPZPUFhUchqJbE4LGKKUnEgSjSYdEWsUEW214UpZysGnFZx9XdWCEeD3yJwi90SzSdOnY4hRhB53OnU5VV8+sLPS0y6t2x1xetRkibyCENFGEQLBnnGzSB0QS0bpOT7/9P0bxOHNTwWhb8z+RhF6XGbCZNbEJbG+/BLV9Z87TDXcEcXrThNoFwF7dxBzBw1j15gZ5L18yNHn9ztNNQRJL3Y5NCkLgMiWleJsUQwOj+Be3dsO/yCmT1+OT1vMk68fyawopFH+nZK2CX4hDo9rPkL/niCibfKKmNFsHjGFOx/eND3t4691zSFRFis5UxfRLyyc1WXnqfHVd9nILiGKV10i4BQQ3lmVifZB7EiczkCrjcJ3z3BXP+LV9y8cS15IfISdHQ+KuFv3xu/b7BTiKFYzkOQDVr9VgE0Jk1kbl9Q0ETazBYswNcH2VZUaSe/4XqN41LVCkAtYDFX5fUFKi45j68hk9MVV66tndVlBq+3ZVa12HXF41fXAUcBsFNH2AG8blcKBx+U8/fbRcIlWEKfHtVEKeRgwGa4QpMBmiNOjbpGC7N66hjZBHB51O4KsID1cQGWE4lV3C9gbUHZwkjSkuPALaFL1ZUHLIu8AAAAASUVORK5CYII=)
      no-repeat right bottom;
    position: absolute;
    left: 180px;
    top: 20px;
    bottom: -1px;
    right: -1px;
    background-size: 50%;
  }
}
.PcPhone {
  display: none;
}

/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
