<template>
  <div>
    <!-- 预约列表 -->
    <div>
      <el-card class="box-card">
        <div slot="header" class="flex row-between">
          <span>{{ title }}  （共计 <b>{{ tableData.count }}</b> 条）</span>
          <!-- {{ totalSignInS }}{{ totalValidS }} {{ !display }} -->
          <el-button v-show="btn_access('s_workbench_sign_in') && !display" type="success" :loading="buttonLoading" :disabled="totalSignInS == totalValidS" @click.stop="handleSignIn(activeId)">全部签到</el-button>
        </div>
        <div class="text item">
          <el-table
            v-loading="listLoading"
            :data="tableData.list"
            row-key="id"
          >
            <el-table-column prop="user" label="会员信息" min-width="180px">
              <template slot-scope="{row: {user, subscribe_channel, remark}}">
                <div class="flex">
                  <div v-if="user" class="flex col-center cursor" @click="toUserInfoPage(user.id)">
                    <el-avatar shape="square" :size="50" :src="user.avatar">
                      <img :src="errorMemberSrc()">
                    </el-avatar>
                    <div class="p-l-10">
                      <span>{{ getUserNames(user) }}</span><br>
                      <span>{{ user ? user.phone : '' }}</span>
                    </div>
                  </div>
                  <div v-else class="text-danger">会员信息丢失</div>
                  <el-tag v-if="subscribe_channel == 'STORE'" class="m-l-10">代约</el-tag>
                  <el-tooltip v-if="remark" class="item" effect="dark" :content="remark" placement="top">
                    <el-tag class="m-l-10">
                      <i class="el-icon-document" />
                    </el-tag>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="user_membership_card.card.name" label="会员卡名称" /> -->
            <el-table-column v-if="type === 3" label="课程名称" min-width="120px">
              <template slot-scope="{row}">
                <div>{{ row.course.name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="user_membership_card.number" label="卡号" min-width="120px">
              <template slot-scope="{row}">
                <el-tag>{{ row.user_membership_card?row.user_membership_card.number:'--' }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="limit" label="扣减">
              <template slot-scope="{row}">
                {{ row.limit + ''+getType(row) }}
              </template>
            </el-table-column>
            <el-table-column prop="limit" label="当前余额">
              <template slot-scope="{row}">
                {{ row.user_membership_card?(row.user_membership_card.limit + '' +getType(row)):'--' }}
              </template>
            </el-table-column>
            <el-table-column prop="limit" label="剩余天数">
              <template slot-scope="{row}">
                <span v-if="row.user_membership_card&&getExcessTime(row.user_membership_card.valid_time)>0" class="text-success">{{ getExcessTime(row.user_membership_card.valid_time) }}天</span>
                <!-- <span v-else class="text-danger">{{ row.user_membership_card?getExcessTime(row.user_membership_card.valid_time):'--' }}天</span> -->
                <span v-else>未开卡</span>
              </template>
            </el-table-column>
            <el-table-column v-if="type !== 3" prop="queue" label="排队">
              <template slot-scope="{row}">
                <div class="block">
                  <el-tag v-if="row.queue_on" :type="getColorType(row.queue_status)">{{ row.queue_status_text }}</el-tag>
                  <el-tag v-else type="info">非排队</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="{row: {status}}">
                <div class="block">
                  <el-tag v-if="status==0">已预约</el-tag>
                  <el-tag v-if="status==1" type="success">已签到</el-tag>
                  <el-tag v-if="status==2" type="info">已取消</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" :label="type === 1 || type === 3 ? '上课时间' : '预约时间'" min-width="100px">
              <template slot-scope="scope">
                <span v-if="type === 1 || type === 3">{{ scope.row.subscribe_time | formatDate }}</span>
                <span v-else>{{ scope.row.create_time | formatDate }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="操作" min-width="160px">
              <template slot-scope="scope">
                <el-button v-show="btn_access('s_course_subscribe_info')" size="mini" type="primary" class="m-2" @click="course_details(scope.row)">详情</el-button>

                <template v-if="scope.row.status===0&&scope.row.queue===0">
                  <el-popconfirm
                    title="确定签到本条预约记录吗？"
                    @confirm="course_sign(scope.row.id,scope.row.user_id)"
                  >
                    <el-button v-show="btn_access('s_workbench_sign_in')" slot="reference" size="mini" type="success" class="m-2">签到</el-button>
                  </el-popconfirm>
                </template>
                <template v-if="scope.row.status===0">
                  <el-popconfirm
                    title="确定取消本条预约记录吗？"
                    @confirm="course_cancel(scope.row.id)"
                  >
                    <el-button v-show="btn_access('s_workbench_cancel_subscribe')" slot="reference" size="mini" type="danger" class="m-2">取消预约</el-button>
                  </el-popconfirm>
                </template>
                <template v-if="scope.row.status===1">
                  <el-popconfirm
                    title="确定取消签到本条预约记录吗？"
                    @confirm="course_cancelCheck(scope.row.id, scope.row.user_id)"
                  >
                    <el-button v-show="btn_access('s_workbench_cancel_sign')" slot="reference" size="mini" type="danger" class="m-2">取消签到</el-button>
                  </el-popconfirm>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <pagination small :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
        </div>
      </el-card>

    </div>
    <!-- 预约详情 -->
    <div>
      <bookInfo v-model="dialog" :logid.sync="logid" />
    </div>
  </div>
</template>

<script>
import { getVenuesRecord, cancelBook, signBook, getPrivateLog, cancelCheck } from '@/api/store/worktable.js'
import Pagination from '@/components/Pagination' // 分页组件
import bookInfo from '@/views/store/worktable/components/bookInfo'
import { classScheduleSign } from '@/api/store/course.js'
export default {
  name: 'BookList',
  components: { Pagination, bookInfo },
  props: {
    value: {
      type: [Number, String],
      default: 0,
      required: true
    },
    dateTime: {
      type: [String],
      default: ''
    },
    type: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    totalSignIn: {
      type: Number,
      default: 0
    },
    totalValid: {
      type: Number,
      default: 0
    },
    display: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bookInfo: {}, // 预约详情
      // 列表数据
      tableData: {
        list: [],
        count: 0
      },
      listLoading: false,
      activeId: this.value,
      // 记录ID
      logid: '',
      dialog: { title: '预约记录', visible: false, type: 0 },
      totalSignInS: this.totalSignIn,
      totalValidS: this.totalValid,
      buttonLoading: false
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (!newVal) {
          this.TableData = {
            list: [],
            count: 0
          }
          return
        }
        this.activeId = newVal
        // 重置页码
        this.listQuery.page = 1
        this.getList()
      },
      deep: true
    },
    totalSignIn(val) {
      this.totalSignInS = val
    },
    totalValid(val) {
      this.totalValidS = val
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取会员卡额度单位
    getType(item) {
      var type = (typeof (item.user_membership_card) !== 'undefined') && item.user_membership_card !== null ? item.user_membership_card.card.type : 0
      var unit = ''
      switch (type) {
        case 0:
          break
        case 1:
          unit = '次'
          break
        case 2:
          unit = '元'
          break
      }
      return unit
    },
    /**
     * 取消预约
     */
    course_cancel(id) {
      cancelBook(id).then(res => {
        this.$message.success('取消成功')
        // 成功之后的联动
        this.getList()
        this.$emit('refresh')
      })
    },
    /**
     * 签到
     */
    course_sign(id, user_id) {
      signBook({
        sign_in: id,
        user_id: user_id
      }).then(res => {
        this.$message.success('签到成功')
        // 签到成功之后的联动
        this.getList()
        this.$emit('refresh')
      })
    },
    // 取消签到
    course_cancelCheck(id, user_id) {
      cancelCheck(id, user_id).then(res => {
        this.$message.success('取消签到成功')
        // 签到成功之后的联动
        this.getList()
        this.$emit('refresh')
      })
    },
    // 预约详情
    course_details(row) {
      if (!row.user_membership_card) {
        this.$message.error('该卡已删除')
        return false
      }
      this.logid = row.id
      const title = this.type === 0 ? '预约团课记录' : this.type === 1 ? '预约私教课记录' : this.type === 2 ? '预约精品课记录' : '预约私教课记录'
      this.dialog = { title: title, visible: true, type: this.type }
    },
    getList() {
      if (!this.activeId) return false
      // 翻页联动获取列表
      this.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      if (this.type === 0 || this.type === 1 || this.type === 2) {
      // 获取团课预约记录
        getVenuesRecord(this.activeId, data).then(res => {
          this.tableData = res.data
          this.listLoading = false
        }).catch(() => {})
      } else {
        // 获取团课预约记录
        getPrivateLog(this.activeId, this.dateTime, data).then(res => {
          this.tableData = res.data
          this.listLoading = false
        })
      }
    },
    getColorType(value) {
      var text = ''
      switch (value) {
        case 0:
          text = 'primary'
          break
        case 1:
          text = 'success'
          break
        case 2:
          text = 'info'
          break
        case 3:
          text = 'danger'
          break

        default:
          text = 'info'
          break
      }
      return text
    },
    // 签到
    handleSignIn(id) {
      this.buttonLoading = true
      this.$confirm('确认给该课程里全部的学员签到吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        classScheduleSign(id).then(() => {
          this.$message.success('签到成功')
          this.getList()
          this.$emit('refresh')
          this.buttonLoading = false
        }).catch(() => {
          this.buttonLoading = false
        })
      }).catch(() => {
        this.buttonLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
