var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox",
        {
          staticClass: "m-b-10",
          attrs: { indeterminate: _vm.isIndeterminate },
          model: {
            value: _vm.checkAll,
            callback: function($$v) {
              _vm.checkAll = $$v
            },
            expression: "checkAll"
          }
        },
        [_vm._v("全选")]
      ),
      _c(
        "el-checkbox-group",
        {
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.list, function(item) {
              return _c(
                "el-col",
                { key: item.id, staticClass: "m-b-10", attrs: { span: 8 } },
                [
                  _c("el-checkbox", { attrs: { label: item.id } }, [
                    _vm._v(
                      _vm._s(
                        item.name.length > 14
                          ? item.name.slice(0, 16) + "..."
                          : item.name
                      )
                    )
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }