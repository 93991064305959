<template>
  <div>
    <el-button type="primary" @click="handleAdd">新增菜单</el-button>
    <el-table v-loading="tableLoading" class="m-t-20" :data="tableData.list" border stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="80px" align="center" />
      <el-table-column prop="name" label="菜单名称" width="1000px">
        <template slot-scope="{ row }">
          <div class="flex">
            <div style="flex: 0.2;">{{ row.name }}</div>
            <div v-if="row.config" style="flex: 0.8;">
              <el-tag v-for="(item, index) in row.config" :key="index" class="m-4">{{ item.name }}</el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="发布" width="120px" align="center">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status === 0" type="danger">未发布</el-tag>
          <el-tag v-else type="success">已发布</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="操作">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" icon="el-icon-edit-outline" @click="handleEdit(row)">编辑</el-button>
          <el-popconfirm title="此操作将永久删除该菜单, 是否继续?" class="m-4" @confirm="handleDelete(row)">
            <el-button slot="reference" size="mini" :loading="buttonLoading" type="danger" icon="el-icon-delete-solid">
              删除
            </el-button>
          </el-popconfirm>
          <el-button
            slot="reference"
            size="mini"
            :loading="buttonLoading"
            type="success"
            icon="el-icon-plus"
            @click="handleRelease(row)"
          >
            {{ row.status === 0 ? '发布' : '更新' }}
          </el-button>
          <el-button
            v-if="row.status !== 0"
            slot="reference"
            size="mini"
            :loading="buttonLoading"
            type="warning"
            icon="el-icon-minus"
            @click="handleWithdraw(row)"
          >
            撤回
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 弹出层 -->
    <Dialog :id="id" :dialog-visible="dialogVisible" @handleCloseS="handleCloseS" />
  </div>
</template>

<script>
// API
import { getMenu, clearMenu, postRelease, postRecall } from '@/api/admin/merchants.js'
// 组件
import Pagination from '@/components/Pagination'
import Dialog from './dialog.vue'

export default {
  components: { Pagination, Dialog },
  data() {
    return {
      tableData: {
        count: 0,
        list: []
      },
      tableLoading: false,
      dialogVisible: false,
      id: 0,
      buttonLoading: false
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      getMenu(this.$route.params.id, data).then(res => {
        this.tableData = res.data
        this.tableLoading = false
      })
    },
    handleEdit(row) {
      this.id = row.id
      this.dialogVisible = true
    },
    handleCloseS() {
      this.dialogVisible = false
      this.getList()
    },
    handleAdd() {
      this.id = 0
      this.dialogVisible = true
    },
    handleDelete(row) {
      this.buttonLoading = true
      clearMenu(row.id)
        .then(res => {
          this.handleCloseS()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.buttonLoading = false
        })
        .catch(() => {
          this.buttonLoading = false
        })
    },
    handleRelease(row) {
      this.$confirm('确定发布吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.buttonLoading = true
        postRelease(row.id)
          .then(res => {
            this.handleCloseS()
            this.$message({
              type: 'success',
              message: '发布成功!'
            })
            this.buttonLoading = false
          })
          .catch(() => {
            this.buttonLoading = false
          })
      })
    },
    handleWithdraw(row) {
      this.$confirm('撤回发布将清空已发布菜单，公众号上将无菜单按钮，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.buttonLoading = true
        postRecall(row.id)
          .then(res => {
            this.handleCloseS()
            this.$message({
              type: 'success',
              message: '撤回成功!'
            })
            this.buttonLoading = false
          })
          .catch(() => {
            this.buttonLoading = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
