<template>
  <div>
    <div class="box_checkbox">
      <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
      <div class="flex m-t-10">
        <div class="m-r-20">课程类型：</div>
        <el-radio v-model="checkboxName" :label="0">团课</el-radio>
        <el-radio v-model="checkboxName" :label="2">精品课</el-radio>
        <el-radio v-model="checkboxName" :label="1">私教课</el-radio>
      </div>
      <el-checkbox-group v-model="checkedCities" class="m-t-20" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="item in list" v-show="item.type == checkboxName" :key="item.id" class="m-y-4" :label="item">
          <div>
            {{ item.name }}
          </div>
          <div v-if="!isCourse" class="text-info">
            教练：
            <span v-if="item.store_user">{{ item.store_user.real_name }}</span>
            <span v-else>{{ isCourse ? '' : '教练待更新' }}</span>
          </div>
          <template v-if="type != 0 && type">
            <el-input
              v-model="item.charge"
              type="number"
              class="input m-b-10"
              placeholder="单节课收费,默认0"
              @input="inputChange"
            />
            {{ type == 1 ? '次/人' : '元/人' }}
          </template>
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 选中列表
    parentSelected: {
      type: Array,
      default() {
        return []
      }
    },
    // 全部列表
    list: {
      type: [Array, Object],
      default() {
        return {}
      }
    },
    // 类型
    type: {
      type: Number,
      default: 0
    },
    isCourse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checkboxName: 0,
      checkAll: false,
      groupClassCheckAll: false,
      premiumCoursesCheckAll: false,
      privateClassesCheckAll: false,
      isIndeterminate: false,
      checkedCities: [],
      checkedCities0: [],
      checkedCities2: [],
      checkedCities1: []
    }
  },
  computed: {},
  watch: {
    checkboxName(val) {
      const dataList = this.list.filter(item => item.type === val)
      if (val === 0) {
        this.isIndeterminate = this.checkedCities0.length > 0 && this.checkedCities0.length < dataList.length
        this.checkAll = dataList.length === this.checkedCities0.length
      } else if (val === 2) {
        this.isIndeterminate = this.checkedCities2.length > 0 && this.checkedCities2.length < dataList.length
        this.checkAll = dataList.length === this.checkedCities2.length
      } else if (val === 1) {
        this.isIndeterminate = this.checkedCities1.length > 0 && this.checkedCities1.length < dataList.length
        this.checkAll = dataList.length === this.checkedCities1.length
      }
    }
  },
  created() {
    // 监听 parentSelected 只监听一次
    const unwatch = this.$watch('parentSelected', (val) => {
      // 判断选中的ID中优先显示 团课 => 精品课 => 私教课
      const groupClass = val.some(obj => obj.type === 0)
      const premiumCourses = val.some(obj => obj.type === 2)
      const privateClasses = val.some(obj => obj.type === 1)
      this.checkedCities0 = this.parentSelected.filter(obj => obj.type === 0)
      this.checkedCities2 = this.parentSelected.filter(obj => obj.type === 2)
      this.checkedCities1 = this.parentSelected.filter(obj => obj.type === 1)
      if (groupClass) {
        this.checkboxName = 0
        const dataList = this.list.filter(item => item.type === this.checkboxName)
        this.isIndeterminate = this.checkedCities0.length > 0 && this.checkedCities0.length < dataList.length
        this.checkAll = dataList.length === this.checkedCities0.length
      } else if (premiumCourses) {
        this.checkboxName = 2
        const dataList = this.list.filter(item => item.type === this.checkboxName)
        this.isIndeterminate = this.checkedCities2.length > 0 && this.checkedCities2.length < dataList.length
        this.checkAll = dataList.length === this.checkedCities2.length
      } else if (privateClasses) {
        this.checkboxName = 1
        const dataList = this.list.filter(item => item.type === this.checkboxName)
        this.isIndeterminate = this.checkedCities1.length > 0 && this.checkedCities1.length < dataList.length
        this.checkAll = dataList.length === this.checkedCities1.length
      }
      this.result()
      // 执行完所需的操作后停止监听
      unwatch()
    })
  },
  mounted() {},
  methods: {
    inputChange(val) {
      this.$forceUpdate()
    },
    handleCheckAllChange(val) {
      this.list.map(item => {
        if (item.type === this.checkboxName) {
          console.log(item)
          if (this.checkboxName === 0) {
            if (val) this.checkedCities0.push(item)
            else this.checkedCities0 = []
          } else if (this.checkboxName === 2) {
            if (val) this.checkedCities2.push(item)
            else this.checkedCities2 = []
          } else if (this.checkboxName === 1) {
            if (val) this.checkedCities1.push(item)
            else this.checkedCities1 = []
          }
        }
      })
      this.isIndeterminate = false
      this.result()
    },
    handleCheckedCitiesChange(value) {
      const dataValue = value.filter(obj => obj.type === this.checkboxName)
      if (this.checkboxName === 0) {
        this.checkedCities0 = dataValue
        const dataList = this.list.filter(item => item.type === this.checkboxName)
        this.isIndeterminate = this.checkedCities0.length > 0 && this.checkedCities0.length < dataList.length
        this.checkAll = dataList.length === this.checkedCities0.length
      } else if (this.checkboxName === 2) {
        this.checkedCities2 = dataValue
        const dataList = this.list.filter(item => item.type === this.checkboxName)
        this.isIndeterminate = this.checkedCities2.length > 0 && this.checkedCities2.length < dataList.length
        this.checkAll = dataList.length === this.checkedCities2.length
      } else if (this.checkboxName === 1) {
        this.checkedCities1 = dataValue
        const dataList = this.list.filter(item => item.type === this.checkboxName)
        this.isIndeterminate = this.checkedCities1.length > 0 && this.checkedCities1.length < dataList.length
        this.checkAll = dataList.length === this.checkedCities1.length
      }
      this.result()
    },
    result() {
      this.checkedCities = [...this.checkedCities0, ...this.checkedCities2, ...this.checkedCities1]
      this.$emit('update:parentSelected', this.checkedCities)
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  width: 100px;
  /* margin: 0 10px; */
}
.el-checkbox ::v-deep .el-checkbox__label {
  color: #606266 !important;
}
.box_checkbox {
  padding: 10px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
</style>
