<template>
  <div class="app-wrapper">
    <!-- 品牌管理 -->
    <div class="app-container">
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="20">
          <!-- 品牌管理 -->
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="box app-left">
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="text-center manage-box m-10">
                  <div :style="{display:'initial'}" @click="imagecropperShow=true">
                    <el-avatar shape="square" :size="150" :src="appInfo.logo?appInfo.logo:defaultLogo" title="点击修改" />
                    <div class="editLogoText">点击修改品牌LOGO</div>
                  </div>
                  <image-cropper
                    v-show="imagecropperShow"
                    :key="imagecropperKey"
                    field="file"
                    :width="300"
                    :height="300"
                    :url="storeUpload"
                    lang-type="zh"
                    @close="imagecropperClose"
                    @crop-upload-success="cropSuccess"
                  />
                  <div v-if="nameType" class="name p-t-10 font-14" @click="editLogo">品牌名： {{ appInfo.name }} <i class="el-icon-edit" /></div>
                  <el-input v-show="!nameType" ref="inputRef" v-model="appInfo.name" class="name" :autofocus="true" @blur="venues_name_change" />
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <div class="m-l-20 m-t-10">
                  <div class="font-16 m-b-10">品牌有效期：<span class="text-success">{{ appInfo.expiration_time?formatTime(appInfo.expiration_time):'永久' }}</span></div>
                  <div class="font-16 m-b-10">可提现金额：<span class="text-warning font-20">{{ appInfo.balance }}</span>元</div>
                  <div class="flex">
                    <div>
                      <el-popover
                        placement="right"
                        trigger="click"
                      >
                        <canvas ref="qrcode_ban" />
                        <el-button slot="reference" type="warning" icon="el-icon-circle-plus-outline">立即提现</el-button>
                        <el-button slot="reference" type="success" icon="el-icon-money" @click="handleCapital">资金日志</el-button>
                        <el-button v-if="!appInfo.lock_industry" slot="reference" type="primary" icon="el-icon-sort" @click="industry">行业切换</el-button>
                      </el-popover>
                    </div>
                  <!-- <div class="m-l-10">
                    <router-link :to="{name: 's_venues_add'}">
                      <el-button type="primary" icon="el-icon-circle-plus-outline" :to="{name: 's_venues_add'}">添加场馆</el-button>
                    </router-link>
                  </div> -->
                  </div>
                </div>
              </el-col>
            </div>
          </el-col>
          <!-- 场馆列表 -->
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="box app-left venues-list">
              <div class="header">
                <span>场馆列表</span>
                <el-button style="float: right; padding: 3px 0" icon="el-icon-circle-plus-outline" type="text" @click="$router.push({ name: 's_venues_add'})">添加场馆</el-button>
              </div>
              <div v-if="!utils.empty(venues_list)" v-loading="list_loading" class="venues_list">
                <el-col v-for="item in venues_list" :key="item.id" :xs="24" :md="12" :lg="8" :xl="8">
                  <div class="venues">
                    <div class="flex flex-wrap row-between" :style="{}">
                      <div class="flex-1"><span class="font-13 m-r-10">{{ item.name }}</span><el-tag v-if="item.type==20" class="m-r-5" effect="dark">分店</el-tag><el-tag v-if="item.type==10" type="success" class="m-r-5" effect="dark">总店</el-tag><el-tag v-if="item.default" type="info" class="m-r-5" effect="dark">默认</el-tag></div>
                      <div class="m-l-2">
                        <el-tooltip class="item" effect="dark" content="编辑场馆" placement="bottom">
                          <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="venues_edit(item.id)" />
                        </el-tooltip>
                      </div>
                      <div class="m-l-4">
                        <el-tooltip class="item" effect="dark" content="进入场馆" placement="bottom">
                          <el-button type="success" icon="el-icon-arrow-right" size="mini" circle @click="enterVenues(item)" />
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="p-25 line-5 m-t-5" style="background:darkgray;color:white;min-height:100px" :style="{background:'cadetblue',borderRadius:'4px',color:'white'}">
                      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <div>负责人：{{ item.principal }}</div>
                        <div>电话：{{ item.phone }}</div>
                        <div>营业时间：{{ item.start_time }}~{{ item.end_time }}</div>
                        <div :title="item.address" class="line-1">地址：{{ item.address }}</div>
                      </el-col>
                    <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="6">
                      <div @click="enterVenues(item)">
                        <el-button icon="el-icon-download" type="text" size="small" class="m-t-5">进入场馆</el-button>
                      </div>
                    </el-col> -->
                    </div>
                  </div>
                </el-col>
              </div>
              <div v-else>
                <el-empty description="您当前没有场馆！">
                  <div class="venues_list_empty"><el-link type="success" @click="$router.push({ name: 's_venues_add' })">快速创建场馆</el-link></div>
                </el-empty>
              </div>
            </div>
          </el-col>
        </el-col>

        <!-- 右侧栏 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
          <div class="box app-right">
            <!-- 轮播图 -->
            <div v-if="!utils.empty(banner)" class="banner hidden-md-and-down m-b-20">
              <el-carousel height="150px" arrow="never">
                <el-carousel-item v-for="item in banner" :key="item.id">
                  <el-image :src="item.src" style="width: 100%;height: 100%" fit="fill" @click="toUrl(item.path)" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <!-- 专属顾问 -->
            <div class=" m-t-10">
              <div class="flex">
                <div>
                  <el-avatar shape="square" :size="50" :src="consultant_avatar" />
                </div>
                <div class="m-l-10">
                  <div>老师您好，我是您的</div>
                  <div class="text-primary font-13">专属顾问 {{ consultant_name }}</div>
                </div>
              </div>
              <div class="">
                <div class="consultant_box flex row-between">
                  <div>
                    <li><i class="el-icon-menu m-r-2" />咨询答疑</li>
                    <li><i class="el-icon-menu m-r-2" />案例推荐</li>
                    <li><i class="el-icon-menu m-r-2" />订购政策</li>
                    <li><i class="el-icon-menu m-r-2" />服务支持</li>
                  </div>
                  <div class="consultant_img">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="consultant_wx"
                      fit="fill"
                    />
                  </div>
                </div>
              </div>
              <div class="m-y-20 flex row-between">
                <div>咨询电话：<br>{{ consultant_phone }}</div>
                <div class="m-l-10"><el-button size="mini" type="primary">客服经理</el-button></div>
              </div>
            </div>
            <!-- 提需求 -->
            <!-- <div class="hidden-md-and-down demand-box">
              <el-image
                style="width: 100%; height: 100px"
                :src="consultant_card"
              />
            </div> -->
            <!-- 系统公告 -->
            <div v-if="!utils.empty(notelist)" class="note_box">
              <div class="m-10">
                <div class="title">系统公告</div>
                <div v-if="!utils.empty(notelist)">
                  <div v-for="item,index in notelist" :key="index" class="note_item flex cursor" @click="$router.push({ name: 's_sys_notice_info',params:{id:item.id} })">
                    <div class="time">{{ item.create_time | formatDate("MM/DD") }}</div>
                    <div class="content line-1">{{ item.title }}</div>
                  </div>
                </div>
                <div v-else>
                  暂无公告~
                </div>
              </div>

            </div>
          </div>
        </el-col>

        <!-- 资料下载 -->
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
          <div class="box app-right">
            <div class="title">附件下载</div>
            <div class="material">
              <div v-for="item,index in fileList" :key="index" class="item">
                <div class="flex ">
                  <div class="name" @click="goFile(item.url)">
                    <i class="el-icon-document m-r-5" />{{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>

      </el-row>
    </div>

    <!-- 行业切换 -->
    <el-dialog
      title="行业切换"
      :visible.sync="dialogVisible"
      width="50%"
      @close="handleClose"
    >
      <h3>请选择您要切换的行业</h3>
      <span>切换行业后场馆默认图片会发生变化，如课程，会员卡封面等</span>
      <br>
      <span>请谨慎操作，不影响已发布内容</span>
      <div class="industryList">
        <div v-for="(item, index) in industryList" :key="index" class="industry" :style="item.code === industry_code ? 'border: 4px solid rgb(1, 197, 1);' : 'border: 4px solid #FFF;'" @click="handelIndustry(item.code)">
          <img :src="item.img" alt="">
          <div class="industry_text">
            <div class="industry_title">
              <div>{{ item.name }}</div>
              <div>{{ item.en_name }}</div>
            </div>
            <div class="industry_icon" :style="item.code === industry_code ? 'color: rgb(1, 197, 1); border: 1px solid rgb(1, 197, 1);' : 'color: #FFF; border: 1px solid #FFF'">
              <i class="el-icon-check" />
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handelConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import { getAppInfo, editAppInfo, industryList, industrySwitch } from '@/api/store/management.js'
import { getVenues, getVenuesService } from '@/api/store/venues.js'
import { getSystemBanner, getSystemNote } from '@/api/store/common.js'
import ImageCropper from '@/components/ImageCropper'// 头像上传
// import { getToken } from '@/utils/auth'

import 'element-ui/lib/theme-chalk/display.css'
export default {
  name: 'DashboardEditor',
  components: { ImageCropper },
  data() {
    return {
      dialogVisible: false,
      industryList: [],
      industry_code: 0,
      appInfo: {
        name: '',
        logo: ''
      },
      nameType: true,
      venues_list: [], // 场馆列表
      list_loading: true,
      imagecropperShow: false,
      imagecropperKey: 0,
      originalName: '',
      banner: {},
      notelist: {},
      consultant_avatar: require('@/assets/images/consultant_avatar.png'),
      consultant_name: '瑜小九',
      consultant_card: require('@/assets/images/consultant_card.png'),
      consultant_wx: require('@/assets/images/defaultManager.png'),
      consultant_phone: '400-678-0554',
      fileList: [{
        name: '瑜小九APP使用流程.pdf',
        url: 'https://oss.yuxiaojiu.cn/doc/瑜小九APP使用流程.pdf?v_202202100002'
      }, {
        name: '瑜小九PC端使用手册.pdf',
        url: 'https://oss.yuxiaojiu.cn/doc/瑜小九PC端使用手册.pdf?v_202202100001'
      }, {
        name: '瑜小九约课端使用手册.pdf',
        url: 'https://oss.yuxiaojiu.cn/doc/瑜小九约课端使用手册.pdf?v_202202090001'
      }, {
        name: '瑜小九预约系统使用手册(店长版).pdf',
        url: 'https://oss.yuxiaojiu.cn/doc/瑜小九预约系统使用手册(店长版).pdf?v_202202090001'
      }, {
        name: '瑜小九预约系统使用手册(教练版).pdf',
        url: 'https://oss.yuxiaojiu.cn/doc/瑜小九预约系统使用手册(教练版).pdf?v_202202090001'
      }]
    }
  },
  computed: {

  },
  mounted() {
    this.getInfo()
    getVenues({ limit: 999 }).then((res) => {
      this.venues_list = res.data.list
      this.list_loading = false
    }).catch(() => { })
    getVenuesService().then(res => {
      this.consultant_avatar = res.data.avatar
      this.consultant_name = res.data.name
      this.consultant_wx = res.data.qrcode_src
      this.consultant_phone = res.data.phone
    })
    this.getBanner()
    this.getNoteList()
  },
  methods: {
    goFile(url) {
      window.open(url)
    },
    editLogo() {
      this.nameType = false
      this.originalName = this.appInfo.name
      this.$nextTick(() => {
        this.$refs.inputRef.focus()
      })
    },
    save() {
      editAppInfo({ name: this.appInfo.name, logo: this.appInfo.logo }).then(res => {
        this.$message.success('修改成功！')
        // this.nameType = true;
        this.getInfo()
        this.nameType = true
        location.reload()
      }).catch(() => {})
    },
    getInfo() {
      getAppInfo().then(res => {
        this.industry_code = res.data.industry
        this.appInfo = res.data
        localStorage.setItem('App', JSON.stringify(res.data))
        // ?token=${getToken()}
        this.useqrcode('qrcode_ban', `${window.location.origin}${this.publicPath}store/withdraw_apply/index`)
        // this.$set(this.list, 'logo', 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png')
      }).catch(() => {})
    },
    // 进入场馆
    enterVenues(item) {
      const data = { id: item.id, name: item.name }
      localStorage.setItem('Venues', JSON.stringify(data))
      location.reload()
      // this.$router.push({
      // 	path: this.redirect || '/',
      // 	query: this.otherQuery
      // })
    },
    // 编辑场馆
    venues_edit(id) {
      this.$router.push({ name: 's_venues_edit', params: { id: id }})
    },
    // 添加场馆
    venues_add() {

    },
    // 头像上传成功
    cropSuccess(resData) {
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      this.appInfo.logo = resData.path
      this.save()
    },
    // 头像上传弹出层关闭
    imagecropperClose() {
      this.imagecropperShow = false
    },
    // 品牌名变更
    venues_name_change(v, i) {
      if (this.appInfo.name !== this.originalName) {
        this.save()
      }
    },
    useqrcode(name, url) {
      const that = this
      const canvas = that.$refs[name]
      // 去掉qrcode的边框（建议留1px；否则浏览器识别有些困难）
      QRCode.toCanvas(canvas, url, { width: 180, height: 180, margin: 2 }, function(error) {
        if (error) console.error(error)
        that.$forceUpdate()
      })
    },
    formatTime(val) {
      return this.$moment.unix(val).format('YYYY-MM-DD')
    },
    // 获取系统轮播图
    getBanner() {
      const data = {}
      var querys = []
      querys.push({ field: 'channel', key: 'web', type: 'like' })
      data.query = this.utils.getQueryParams(querys)
      getSystemBanner(data).then(res => {
        this.banner = res.data.list
      })
    },
    getNoteList() {
      getSystemNote().then(res => {
        this.notelist = res.data.list
      })
    },
    // 行业列表
    industry() {
      this.dialogVisible = true
      industryList().then(res => {
        this.industryList = res.data
      })
    },
    handelIndustry(code) {
      this.industry_code = code
    },
    handleClose() {
      getAppInfo().then(res => {
        this.industry_code = res.data.industry
      }).catch(() => {})
    },
    // 切换行业
    handelConfirm() {
      this.dialogVisible = false
      industrySwitch({ industry_code: this.industry_code }).then(() => {
        this.$message({
          message: '行业切换成功',
          type: 'success',
          duration: 800
        })
      })
    },
    // 跳转路由
    handleCapital() {
      this.$router.push({ name: 's_bill_details' })
    }
  }
}
</script>

<style lang="scss" scoped>
.venues_list{
  margin:0 -10px;
}
.venues{
    // width: 370px;
    margin: 10px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
    padding: 10px;
}
.el-avatar{
  background: none !important;
}
.manage-box{
  .name{
    color:#999;
    cursor: pointer;
  }
}

.editLogoText{
  color:#999999;
  cursor: pointer;
}
.box{
  // margin-bottom: 16px;
  margin: 5px;
  // padding:0px 5px;
  padding:15px;
}
@media screen and (min-width:768px){
 .manage-box{
    border-right:1px solid #e6ebf5;
  }
}
@media screen and (max-width:767px){
 .manage-box{
   padding-bottom: 10px;
    border-bottom:1px solid #e6ebf5;
  }
}

.header{
  // border-bottom: 1px solid #e6ebf5;
  padding:10px 0;
  font-size: 14px;
  font-weight: 600;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.banner{
  margin: -10px;
}
.consultant_box{
  background: rgb(0 0 0 / 6%);
  padding: 5px 10px;
  li{
    list-style: none;
    margin: 10px;
  }
}
.hr{
  border-bottom: 1px solid #dfe4ed;
  margin:30px 0;
}
.demand-box{
  padding:10px 0px;
  border-top: 1px solid #dfe4ed;
}
.note_box{
  border-top: 1px solid #dfe4ed;
  .title{
    font-weight: 600;
    margin:10px 0;
    font-size: 14px;
  }
  .time{
    color:#9999;
    margin-right: 10px;
  }
  .note_item{
    margin: 6px 0;
  }
}
.app-right{
  .title{
    padding: 6px 0;
    font-size: 14px;
    font-weight: 600;
  }
  .material{
    .item{
      margin: 10px 0px;
      .name{
        cursor: pointer;
      }
    }
  }
}
.venues-list{
  min-height: 572px;
}
// 禁止banner图片拖动
.banner{
  ::v-deep {
    .el-image__inner{
      -webkit-user-drag: none;
      cursor: pointer;
    }
  }
}
h3 {
  margin: 0px 0px 16px 0px;
}
.industryList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .industry {
    width: 48%;
    margin-top: 15px;
    margin-left: 1.5%;
    position: relative;
    img {
      width: 100%;
      display: block;
    }
    .industry_text {
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      color: #FFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      .industry_title {
        margin: 10px 0 0 20px;
        div {
          margin-top: 4px;
        }
        div:nth-child(2) {
          font-size: 14px;
        }
      }
      .industry_icon {
        margin-right: 20px;
        width: 25px;
        height: 25px;
        border: 1px solid #FFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
