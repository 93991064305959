var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-width": "120px",
            "label-position": "left"
          }
        },
        [
          _c("div", { staticClass: "setting_item" }, [
            _c("div", { staticClass: "m-y-8  p-y-5 p-x-15 title item_title" }, [
              _vm._v(" 预约时间设置 ")
            ]),
            _c(
              "div",
              { staticClass: "item_content m-y-10" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "预约方式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.handleRadio },
                        model: {
                          value: _vm.form.private_subscribe.type,
                          callback: function($$v) {
                            _vm.$set(_vm.form.private_subscribe, "type", $$v)
                          },
                          expression: "form.private_subscribe.type"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("私教排课模式")
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("自由预约模式")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: "私教预约" } }, [
                  _c("div", { staticClass: "flex flex-col col-top row-left" }, [
                    _c("div", { staticClass: "flex m-y-10" }, [
                      _c("div", [_vm._v("每天")]),
                      _c(
                        "div",
                        [
                          _c("el-time-picker", {
                            staticClass: "w-250",
                            attrs: {
                              "is-range": "",
                              "arrow-control": "",
                              "range-separator": "-",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              placeholder: "选择时间范围",
                              format: "HH:mm",
                              "value-format": "HH:mm",
                              clearable: false
                            },
                            model: {
                              value: _vm.form.private_subscribe.start_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.private_subscribe,
                                  "start_time",
                                  $$v
                                )
                              },
                              expression: "form.private_subscribe.start_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", [_vm._v("接受自主预约")])
                    ]),
                    _c("div", { staticClass: "flex flex-1" }, [
                      _c("div", [_vm._v("会员需早于开课前")]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "before_course_minute" } },
                            [
                              _c("el-input", {
                                staticClass: "w-120 m-x-5",
                                attrs: {
                                  type: "number",
                                  size: "mini",
                                  placeholder: "请输入内容"
                                },
                                model: {
                                  value:
                                    _vm.form.private_subscribe
                                      .before_course_minute,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.private_subscribe,
                                      "before_course_minute",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.private_subscribe.before_course_minute"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", [_vm._v("分钟自主预约")])
                    ])
                  ]),
                  _vm.form.private_subscribe.type === 2
                    ? _c(
                        "p",
                        [
                          _vm._v(" 课前休息 "),
                          _c("el-input", {
                            staticClass: "w-120 m-x-5",
                            attrs: {
                              type: "number",
                              size: "mini",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.form.private_subscribe.before_rest,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.private_subscribe,
                                  "before_rest",
                                  $$v
                                )
                              },
                              expression: "form.private_subscribe.before_rest"
                            }
                          }),
                          _vm._v(" 分钟，课后休息 "),
                          _c("el-input", {
                            staticClass: "w-120",
                            attrs: {
                              size: "mini",
                              placeholder: "请输入内容",
                              type: "number"
                            },
                            model: {
                              value: _vm.form.private_subscribe.after_rest,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.private_subscribe,
                                  "after_rest",
                                  $$v
                                )
                              },
                              expression: "form.private_subscribe.after_rest"
                            }
                          }),
                          _vm._v(" 分钟 ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.private_subscribe.type === 2
                    ? _c(
                        "p",
                        [
                          _vm._v(" 教练可预约时间段每 "),
                          _c("el-input", {
                            staticClass: "w-120 m-x-5",
                            attrs: {
                              type: "number",
                              size: "mini",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.form.private_subscribe.interval,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.private_subscribe,
                                  "interval",
                                  $$v
                                )
                              },
                              expression: "form.private_subscribe.interval"
                            }
                          }),
                          _vm._v(" 分钟为一个间隔 ")
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "setting_item" }, [
            _c("div", { staticClass: "m-y-8  p-y-5 p-x-15 title item_title" }, [
              _vm._v(" 签到设置 ")
            ]),
            _c(
              "div",
              { staticClass: "item_content m-y-10" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "自主签到" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.private_sign.autonomously_status,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.private_sign,
                              "autonomously_status",
                              $$v
                            )
                          },
                          expression: "form.private_sign.autonomously_status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启")
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("关闭")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.private_sign.autonomously_status
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "签到时间" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value:
                                _vm.form.private_sign.autonomously_time_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.private_sign,
                                  "autonomously_time_type",
                                  $$v
                                )
                              },
                              expression:
                                "form.private_sign.autonomously_time_type"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex flex-col col-top row-left" },
                              [
                                _c(
                                  "el-radio",
                                  { staticClass: "m-y-5", attrs: { label: 1 } },
                                  [_vm._v("课程当天，会员可随时签到")]
                                ),
                                _c(
                                  "el-radio",
                                  { staticClass: "m-y-5", attrs: { label: 2 } },
                                  [_vm._v("课程当天，会员可在课程结束前签到")]
                                ),
                                _c(
                                  "el-radio",
                                  { staticClass: "flex", attrs: { label: 3 } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex row-left flex-wrap"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("课程当天，会员可在课程结束后")
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { prop: "after_1" } },
                                              [
                                                _c("el-input", {
                                                  staticClass: "w-100 m-x-5",
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "",
                                                    type: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.private_sign
                                                        .after_1,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form.private_sign,
                                                        "after_1",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.private_sign.after_1"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", [_vm._v("分钟内签到")])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "扫码签到" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.private_sign.scan_code_status,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.private_sign,
                              "scan_code_status",
                              $$v
                            )
                          },
                          expression: "form.private_sign.scan_code_status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启")
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("关闭")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.private_sign.scan_code_status
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "签到时间" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.private_sign.scan_code_time_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.private_sign,
                                  "scan_code_time_type",
                                  $$v
                                )
                              },
                              expression:
                                "form.private_sign.scan_code_time_type"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex flex-col col-top row-left" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "m-y-5 flex",
                                    attrs: { label: 1 }
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "课程当天，会员可签到两小时内开始的课程"
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "m-y-5 flex",
                                    attrs: { label: 2 }
                                  },
                                  [
                                    _c("div", { staticClass: "flex" }, [
                                      _vm._v(
                                        "课程当天，会员可在课程结束前扫码签到"
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-radio",
                                  { staticClass: "flex", attrs: { label: 3 } },
                                  [
                                    _c("div", { staticClass: "flex" }, [
                                      _c("div", [
                                        _vm._v("课程当天，会员可在课程开始前")
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "before" } },
                                            [
                                              _c("el-input", {
                                                staticClass: "w-100 m-x-5",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入内容",
                                                  type: "number"
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.private_sign
                                                      .before,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form.private_sign,
                                                      "before",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.private_sign.before"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("分钟至课程结束后")]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "after_2" } },
                                            [
                                              _c("el-input", {
                                                staticClass: "w-100 m-x-5",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入内容",
                                                  type: "number"
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.private_sign
                                                      .after_2,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form.private_sign,
                                                      "after_2",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.private_sign.after_2"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("分钟内扫码签到")])
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "setting_item" }, [
            _c("div", { staticClass: "m-y-8  p-y-5 p-x-15 title item_title" }, [
              _vm._v(" 预约取消设置 ")
            ]),
            _c(
              "div",
              { staticClass: "item_content m-y-10" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "自主取消" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.private_cancel_subscribe.status,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form.private_cancel_subscribe,
                              "status",
                              $$v
                            )
                          },
                          expression: "form.private_cancel_subscribe.status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启")
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("关闭")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.private_cancel_subscribe.status
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "取消时间" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value:
                                _vm.form.private_cancel_subscribe.time_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.private_cancel_subscribe,
                                  "time_type",
                                  $$v
                                )
                              },
                              expression:
                                "form.private_cancel_subscribe.time_type"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex flex-col col-top row-left" },
                              [
                                _c(
                                  "el-radio",
                                  { staticClass: "flex ", attrs: { label: 1 } },
                                  [
                                    _c("div", { staticClass: "flex" }, [
                                      _c("div", [
                                        _vm._v("会员自主取消不晚于开课前")
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "minute_1" } },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "w-100 m-x-5 m-y-8",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入内容",
                                                  type: "number"
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .private_cancel_subscribe
                                                      .minute_1,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form
                                                        .private_cancel_subscribe,
                                                      "minute_1",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.private_cancel_subscribe.minute_1"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("分钟")])
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-radio",
                                  { staticClass: "flex ", attrs: { label: 2 } },
                                  [
                                    _c("div", { staticClass: "flex" }, [
                                      _c("div", [
                                        _vm._v("会员自主取消不晚于开课后")
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "minute_2" } },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "w-100 m-x-5 m-y-8",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入内容",
                                                  type: "number"
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .private_cancel_subscribe
                                                      .minute_2,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form
                                                        .private_cancel_subscribe,
                                                      "minute_2",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.private_cancel_subscribe.minute_2"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("分钟")])
                                    ])
                                  ]
                                ),
                                _c(
                                  "el-radio",
                                  { staticClass: "flex ", attrs: { label: 3 } },
                                  [
                                    _c("div", { staticClass: "flex" }, [
                                      _c("div", [
                                        _vm._v("会员自主取消不晚于课程结束后")
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "minute_3" } },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "w-100 m-x-5 m-y-8",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入内容",
                                                  type: "number"
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .private_cancel_subscribe
                                                      .minute_3,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form
                                                        .private_cancel_subscribe,
                                                      "minute_3",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.private_cancel_subscribe.minute_3"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("分钟")])
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submit }
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.returnPage()
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }