var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisiblePdf,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "35%",
            fullscreen: "",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisiblePdf = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "title_title" }, [
              _vm._v(
                "添加签署区 - " +
                  _vm._s(_vm.src.split("/")[_vm.src.split("/").length - 1])
              )
            ]),
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                {
                  staticClass: "button_shadow",
                  class: _vm.currentPage === 1 ? "cursor_disabled" : "cursor",
                  on: {
                    click: function($event) {
                      _vm.currentPage === 1 ? "" : _vm.currentPage--
                    }
                  }
                },
                [_vm._v(" 上一页 ")]
              ),
              _c("div", { staticClass: "m-x-4" }, [
                _vm._v(" " + _vm._s(_vm.currentPage) + " "),
                _c("span", { staticClass: "m-x-4" }, [_vm._v("/")]),
                _vm._v(" " + _vm._s(_vm.pageCount) + " ")
              ]),
              _c(
                "div",
                {
                  staticClass: "button_shadow",
                  class:
                    _vm.currentPage === _vm.pageCount
                      ? "cursor_disabled"
                      : "cursor",
                  on: {
                    click: function($event) {
                      _vm.currentPage === _vm.pageCount ? "" : _vm.currentPage++
                    }
                  }
                },
                [_vm._v(" 下一页 ")]
              ),
              _c(
                "div",
                { staticClass: "m-x-10", staticStyle: { color: "#707375" } },
                [_vm._v("|")]
              ),
              _c(
                "div",
                {
                  staticClass: "icon_shadow",
                  class: _vm.scaleRatio === 55 ? "cursor_disabled" : "cursor",
                  on: {
                    click: function($event) {
                      _vm.scaleRatio === 55 ? "" : (_vm.scaleRatio -= 15)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-minus font-16 m-t-2" })]
              ),
              _c("div", { staticClass: "m-x-10" }, [
                _vm._v(_vm._s(_vm.scaleRatio) + "%")
              ]),
              _c(
                "div",
                {
                  staticClass: "cursor icon_shadow",
                  class: _vm.scaleRatio === 220 ? "cursor_disabled" : "cursor",
                  on: {
                    click: function($event) {
                      _vm.scaleRatio === 220 ? "" : (_vm.scaleRatio += 15)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-plus font-16 m-t-2" })]
              )
            ]),
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                {
                  staticClass: "cursor m-r-20",
                  on: {
                    click: function($event) {
                      return _vm.handleClose(0)
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "div",
                {
                  staticClass: "cursor",
                  on: {
                    click: function($event) {
                      return _vm.handleClose(1)
                    }
                  }
                },
                [_vm._v("下一步")]
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "war_bg",
              style: {
                width: 100 + "%",
                height: _vm.scaleValue < 680 ? 100 + "vh" : 100 + "%"
              },
              on: { click: _vm.handleDocumentClick }
            },
            [
              _c(
                "div",
                {
                  ref: "myDiv",
                  staticClass: "war",
                  style: { width: _vm.scaleValue + "px" }
                },
                [
                  _c("pdf", {
                    attrs: { src: _vm.src, page: _vm.currentPage },
                    on: {
                      "num-pages": function($event) {
                        _vm.pageCount = $event
                      },
                      progress: function($event) {
                        _vm.loadedRatio = $event
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      ref: "signatureArea",
                      staticClass: "signature_area",
                      class: _vm.isResizing ? "border_area" : "",
                      style: {
                        left: _vm.currentPosition.x + "px",
                        top: _vm.currentPosition.y + "px",
                        cursor: _vm.isDragging ? "grabbing" : "grab",
                        width: _vm.divWidth + "px",
                        height: _vm.divHeight + "px",
                        fontSize: _vm.divFontSize + "px"
                      },
                      on: {
                        dblclick: _vm.handleClick,
                        mousedown: _vm.startDrag
                      }
                    },
                    [_c("div", [_vm._v("签名区")])]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }