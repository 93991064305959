<template>
  <div v-if="fromData">
    <el-dialog
      :title="fromData.title"
      :visible.sync="modals"
      width="700"
      :before-close="cancel"
      :close-on-click-modal="false"
    >
      <form-create ref="fc" :option="config" :rule="fromData.rules" class="formBox" handle-icon="false" @submit="onSubmit" />
    </el-dialog>
  </div>
</template>

<script>
import formCreate from '@form-create/element-ui'
import request from '@/utils/request'

// import { mapState } from 'vuex';
export default {
  name: 'Edit',
  components: {
    formCreate: formCreate.$form()
  },
  props: {
    fromData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      modals: false,
      config: {
        global: {
          upload: {
            props: {
              onSuccess(res, file) {
                if (res.status === 200) {
                  file.url = res.data.path
                } else {
                  this.Message.error(res.msg)
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    // 提交表单 group
    onSubmit(formData) {
      let datas = {}
      datas = formData
      if (this.$listeners['onSubmit']) {
        this.$emit('onSubmit', formData)
      } else {
        request({
          url: this.fromData.action,
          method: this.fromData.method,
          data: datas
        }).then(res => {
          this.$parent.getList()
          this.$message.success(res.msg)
          this.modals = false
          setTimeout(() => {
            this.$emit('submitFail')
          }, 1000)
        }).catch(res => {
          this.$message.error(res.msg)
        })
      }
    },
    // 关闭按钮
    cancel() {
      this.modals = false
      // this.$emit('onCancel')
    }
  }
}
</script>

<style scoped lang="scss">
//     .v-transfer-dom >>> .ivu-modal-content-drag{
//         z-index: 2!important;
//     }
//     .radio{
//         margin-bottom:14px;
//     }
//     .radio >>> .name{
//         width: 125px;
//         text-align: right;
//         padding-right: 12px;
//     }
 </style>
