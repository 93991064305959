<template>
  <div class="app-container">
    <el-page-header content="卡项评论详情" class="page-header" @back="returnPage()" />
    <div class="searchBox" style="margin-top: 20px;">
      <el-form :model="listQueryParams" label-width="100px" label-position="left">
        <!-- 日期筛选 -->
        <el-form-item label="日期筛选">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <!-- 评论状态 -->
        <!-- <el-form-item label="评论状态">
          <el-select v-model="listQueryParams.is_read" placeholder="评论状态" clearable>
            <el-option label="全部" value="" />
            <el-option label="未读" :value="0" />
            <el-option label="已读" :value="1" />
          </el-select>
        </el-form-item> -->
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="tableData.list" border style="width: 100%; margin-top: 20px;" :stripe="true">
      <el-table-column label="买家">
        <template slot-scope="scope">
          <div class="photo">
            <!-- <el-avatar size="large" :src="scope.row.avatar : https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" /> -->
            <el-avatar size="large" :src="scope.row.user ? scope.row.user.avatar : scope.row.virtual_info.avatar" />
            <span>{{ scope.row.user ? scope.row.user.nickname : scope.row.virtual_info.username }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="评论内容" min-width="180px">
        <template slot-scope="scope">
          <div class="content">
            <div>
              <el-tag v-for="(item, index) in scope.row.tags" :key="index" class="pl_elTag" type="info">
                {{ item }}
              </el-tag>
            </div>
            <div>{{ scope.row.content }}</div>
            <div style="display: flex;">
              <!-- 品论的图 -->
              <div v-for="(item, index) in scope.row.images" :key="index" class="imgUsername">
                <el-avatar v-if="index <= 5" shape="square" size="small" :src="item" />
                <div v-if="index === scope.row.images.length - 1">共{{ index + 1 }}张</div>
              </div>
            </div>
            <div v-if="scope.row.reply.length != 0" class="cghf">[场馆回复]: {{ scope.row.reply[0].content }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="星级" min-width="120px">
        <template slot-scope="scope">
          <el-rate
            v-model="scope.row.star_level"
            disabled
            show-text
            score-template="{value}"
            :texts="texts"
            :colors="colors"
          />
        </template>
      </el-table-column>
      <el-table-column prop="favour_number" label="点赞" />
      <el-table-column label="交易商品">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 10">[课程]：{{ scope.row.course.name }}</span>
          <span v-if="scope.row.type === 20">[售卡]：{{ scope.row.goods.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="is_read" label="评论类型">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_anonymous">匿名评论</el-tag>
          <el-tag v-if="!row.user">自定义评论</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_read" label="隐藏评论">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_hidden === 1">已隐藏</el-tag>
          <el-tag v-else type="info">未隐藏</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="is_read" label="评论状态">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_read === 1">已读</el-tag>
          <el-tag v-else type="info">未读</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column prop="create_time" label="评论时间" />
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { getSeckillComment } from '@/api/store/seckill.js'
import Pagination from '@/components/Pagination' // 分页组件

export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      loading: false,
      searchLoading: false,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      texts: ['差', '一般', '还不错', '很满意', '强烈推荐']
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.is_read) querys.push({ field: 'is_read', key: this.listQueryParams.is_read })

      data.query = this.utils.getQueryParams(querys)

      getSeckillComment(this.$route.params.id, data).then(res => {
        const data = res.data
        this.tableData = data
        this.loading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.photo {
  display: flex;
  align-items: center;
  span {
    display: block;
    margin-left: 10px;
  }
}
.pl_elTag {
  margin-right: 6px;
  margin-top: 6px;
}
.cghf {
  color: #e6a23c;
  span {
    display: block;
    color: red;
  }
}
.content {
  div {
    margin-top: 6px;
  }
}
.imgUsername {
  display: flex;
  margin-right: 6px;
  div {
    margin-left: 6px;
  }
}
</style>
