import request from '@/utils/request'

// 佣金提现记录列表
export function getInvitationRecordList(params) {
  return request({
    url: '/admin/v1/activity/invite/withdraw_records',
    method: 'get',
    params
  })
}
// 佣金记录审核
export function auditInvitationRecord(id, data) {
  return request({
    url: `/admin/v1/activity/invite/withdraw_records/${id}/audit`,
    method: 'put',
    data
  })
}
