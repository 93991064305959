<template>
  <!-- 班课会员管理 -->
  <el-dialog
    title="班课会员详情"
    :visible.sync="dialog"
    width="40%"
    :show-close="is_edit"
    :close-on-click-modal="is_edit"
    :close-on-press-escape="is_edit"
    @open="dialogOpen"
    @close="dialogClose"
  >
    <div class="table">
      <div class="user">
        <el-avatar v-if="data.user" class="m-r-10" shape="square" :size="50" :src="data.user.avatar">
          <img :src="errorMemberSrc()">
        </el-avatar>
        <div class="user_is">
          <h3 v-if="data.user && data.user.phone">{{ getUserNames(data.user) }} - {{ data.user.phone }}</h3>
          <div v-if="data.grade">班级：{{ data.grade.name }}</div>
          <div>剩余课时：{{ data.balance }}</div>
        </div>
      </div>
      <el-descriptions v-loading="searchLoading" class="margin-top" :column="2" border>
        <el-descriptions-item v-if="data.join_time" label="报班时间">
          {{ data.join_time.slice(0, 10) }}
        </el-descriptions-item>
        <el-descriptions-item label="有效期">
          <div>
            <span v-if="data.validity === 0">长期有效</span>
            <span v-else>{{ data.validity }}</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="消耗课时">
          {{ data.count_balance - data.balance }}
        </el-descriptions-item>
        <el-descriptions-item label="剩余课时">
          {{ data.balance }}
        </el-descriptions-item>
        <el-descriptions-item label="总课时">
          {{ data.count_balance }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 选项卡表格 -->
      <el-tabs v-model="is" class="el_tabs" @tab-click="handleClick">
        <el-tab-pane label="未结课" name="1" />
        <el-tab-pane label="已结课" name="3" />
        <el-tab-pane label="已请假" name="2" />
        <el-tab-pane label="旷课" name="4" />
        <el-tab-pane label="班课课时" name="5" />
      </el-tabs>
      <el-table v-if="is != 5" v-loading="elTabsLoading" :data="dataList.list" stripe style="width: 100%">
        <el-table-column prop="create_time" label="课程时间" min-width="150">
          <template slot-scope="{row}">
            {{ row.class_subscribe ? `${row.class_subscribe.start_time.slice(0, 16)} - ${row.class_subscribe.end_time.slice(0, 16)}` : '课程暂定中' }}
          </template>
        </el-table-column>
        <el-table-column prop="store_user_id" label="授课老师">
          <template slot-scope="{row}">
            {{ row.store_user ? row.store_user.real_name : '老师暂定中' }}
          </template>
        </el-table-column>
        <el-table-column prop="course_id" label="课程名称">
          <template slot-scope="{row}">
            {{ row.class_subscribe ? row.class_subscribe.course.name : '课程暂定中' }}
          </template>
        </el-table-column>
        <el-table-column v-if="is == 4 || is == 3 || is == 2" prop="class_expend" label="扣减课时" />
        <el-table-column v-if="is == 3" prop="create_time" label="签到时间" min-width="80">
          <template slot-scope="{row}">
            {{ row.create_time.slice(0, 16) }}
          </template>
        </el-table-column>
        <el-table-column v-if="is == 2" prop="ask_time" label="请假时间" min-width="80">
          <template slot-scope="{row}">
            {{ row.ask_time.slice(0, 16) }}
          </template>
        </el-table-column>
        <el-table-column v-if="is == 4" prop="truant_time" label="旷课时间" min-width="80">
          <template slot-scope="{row}">
            {{ row.truant_time.slice(0, 16) }}
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else v-loading="elTabsLoading" :data="dataClassHoursList.list" stripe style="width: 100%">
        <el-table-column prop="operator_type_text" label="变动类型">
          <template slot-scope="{row}">{{ row.operator_type_text }}</template>
        </el-table-column>
        <el-table-column label="变动课时">
          <template slot-scope="{row}">{{ row.class_after - row.class_before }}</template>
        </el-table-column>
        <el-table-column prop="class_before" label="变动课时前">
          <template slot-scope="{row}">{{ row.class_before }}</template>
        </el-table-column>
        <el-table-column prop="class_after" label="变动课时后">
          <template slot-scope="{row}">{{ row.class_after }}</template>
        </el-table-column>
        <el-table-column prop="notice" label="备注">
          <template slot-scope="{row}">{{ row.notice ? row.notice : '---' }}</template>
        </el-table-column>
        <el-table-column prop="create_time" label="时间" min-width="80">
          <template slot-scope="{row}">{{ row.create_time.slice(0, 16) }}</template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      width="25%"
      :title="num == 1 ? '续班' : num == 2 ? '出班' : num == 3 ? '转班' : num == 4 ? '分课' : '调整课时'"
      :visible.sync="innerVisible"
      :close-on-click-modal="is_edit"
      append-to-body
    >
      <div class="user">
        <el-avatar v-if="data.user" class="m-r-10" shape="square" :size="50" :src="data.user.avatar">
          <img :src="errorAvatarSrc(data.user, 'img')">
        </el-avatar>
        <div class="user_is">
          <h3 v-if="data.user && data.user.phone">{{ data.user.nickname }} - {{ data.user.phone }}</h3>
          <div v-if="data.grade">班级：{{ data.grade.name }}</div>
          <div>剩余课时：{{ data.balance }}</div>
        </div>
      </div>
      <el-form ref="ruleForm" label-position="left" :rules="rules" :model="form" label-width="100px">
        <!-- 续班 -->
        <el-form-item v-if="num === 1" class="line-clear" label="续班课时">
          <el-input v-model="form.class_time" type="number" placeholder="请输入续班课时" />
        </el-form-item>
        <el-form-item v-if="num === 1" label="有效期">
          <el-radio-group v-model="period">
            <el-radio :label="0">不限制</el-radio>
            <el-radio :label="1">限制时间</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="period === 1 && num === 1" label="设置有效期">
          <el-date-picker v-model="time" type="datetime" align="right" placeholder="选择选择有效期" />
        </el-form-item>
        <el-form-item v-if="num === 1" class="line-clear" label="续班金额">
          <el-input v-model="form.charge_money" type="number" placeholder="请输入续班金额">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="num === 1" label="付款方式">
          <el-select v-model="form.charge_type" placeholder="请选付款方式" clearable>
            <el-option v-for="(item, index) in payment_method" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>
        <!-- 续班 -->
        <!-- 出班 -->
        <el-form-item v-if="num === 2" label="是否退费">
          <el-switch v-model="form.is_refund" />
        </el-form-item>
        <el-form-item
          v-if="form.is_refund === true && num === 2"
          class="line-clear"
          label="退费金额"
          prop="charge_money"
        >
          <el-input v-model="form.charge_money" type="number" placeholder="请输入退费金额">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="form.is_refund === true && num === 2" label="付款方式">
          <el-select v-model="form.charge_type" placeholder="请选付款方式" clearable>
            <el-option v-for="(item, index) in payment_method" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>
        <!-- 出班 -->
        <!-- 转班 -->
        <el-form-item v-if="num === 3" label="转向班级">
          <el-select v-model="form.class_id" placeholder="请选择转向班级">
            <el-option v-for="item in classTitle" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="num === 3" class="line-clear" label="转班后课时">
          <el-input v-model="form.class_time" type="number" placeholder="请输入转班后课时" />
        </el-form-item>
        <el-form-item v-if="num === 3" label="有效期">
          <el-radio-group v-model="period">
            <el-radio :label="0">不限制</el-radio>
            <el-radio :label="1">限制时间</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="period === 1 && num === 3" label="设置有效期">
          <el-date-picker v-model="time" type="datetime" align="right" placeholder="选择选择有效期" />
        </el-form-item>
        <el-form-item v-if="num === 3" label="是否收费">
          <el-switch v-model="form.is_refund" />
        </el-form-item>
        <el-form-item
          v-if="form.is_refund === true && num === 3"
          class="line-clear"
          label="收费金额"
          prop="charge_money"
        >
          <el-input v-model="form.charge_money" type="number" placeholder="请输入收费金额">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="form.is_refund === true && num === 3" label="付款方式">
          <el-select v-model="form.charge_type" placeholder="请选付款方式" clearable>
            <el-option v-for="(item, index) in payment_method" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>
        <!-- 转班 -->
        <!-- 分课 -->
        <el-form-item v-if="num === 4" label="分课班级">
          <el-select v-model="form.class_id" placeholder="请选择分课班级">
            <el-option v-for="item in classTitle" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="num === 4" class="line-clear" label="分课课时">
          <el-input v-model="form.balance" type="number" placeholder="请输入分课课时" />
        </el-form-item>
        <!-- 分课 -->
        <!-- 调整课时 -->
        <el-form-item v-if="num === 5" class="line-clear" label="调整后课时">
          <el-input-number v-model="form.class_time" :min="0" />
        </el-form-item>
        <!-- 调整课时 -->
        <!-- 分课 -->
        <el-form-item label="备注">
          <el-input v-model="form.notice" type="textarea" :rows="5" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleYes(num)">确 认</el-button>
        <el-button @click="innerVisible = false">返 回</el-button>
      </span>
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <el-button type="success" @click="handleCheck(1)">续 班</el-button>
      <el-button type="danger" @click="handleCheck(2)">出 班</el-button>
      <el-button type="info" @click="handleCheck(3)">转 班</el-button>
      <el-button type="warning" @click="handleCheck(4)">分 课</el-button>
      <el-button type="primary" @click="handleCheck(5)">调整课时</el-button>
      <el-button @click="handleToPage">返 回</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import {
  getVipCheck,
  getVipRecord,
  getClassRecordList,
  getDataList,
  postRenewal,
  postExpend,
  postShift,
  postSplit,
  postRegulate
} from '@/api/store/course'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean
    },
    id: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      // 班级名称
      classTitle: [],
      is_edit: false,
      innerVisible: false,
      searchLoading: false,
      is: '1',
      data: {},
      dataList: {
        list: [],
        total: 0
      },
      dataClassHoursList: {
        list: [],
        total: 0
      },
      num: 0,
      form: {},
      period: 0,
      time: '',
      rules: {},
      elTabsLoading: false
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 打开的回调
    dialogOpen() {
      this.searchLoading = true
      getVipCheck(this.id).then(res => {
        this.data = res.data
        this.searchLoading = false
      })
      this.handleClick()
    },
    // 关闭的回调
    dialogClose() {
      this.is = '1'
    },
    // 查看
    handleCheck(num) {
      switch (num) {
        case 1:
          this.form = {
            class_time: '',
            validity: '',
            charge_money: '',
            charge_type: '',
            notice: ''
          }
          break
        case 2:
          this.form = {
            is_refund: false,
            charge_type: '',
            charge_money: '',
            notice: ''
          }
          break
        case 3:
          // 获取班级名称
          getDataList().then(res => {
            this.classTitle = res.data.list
          })
          this.form = {
            class_id: '',
            class_time: '',
            validity: '',
            is_refund: false,
            charge_type: '',
            charge_money: '',
            notice: ''
          }
          break
        case 4:
          // 获取班级名称
          getDataList().then(res => {
            this.classTitle = res.data.list
          })
          this.form = {
            class_id: '',
            balance: '',
            notice: ''
          }
          break
        case 5:
          this.form = {
            class_time: this.data.balance,
            notice: ''
          }
          break
      }
      this.innerVisible = true
      this.num = num
    },
    // 返回
    handleToPage() {
      this.$emit('callback')
    },
    handleClick() {
      this.elTabsLoading = true
      if (this.is === '5') {
        getClassRecordList(this.id).then(res => {
          this.dataClassHoursList = res.data
        }).finally(() => {
          this.elTabsLoading = false
        })
      } else {
        getVipRecord(this.is, this.id).then(res => {
          this.dataList = res.data
        }).finally(() => {
          this.elTabsLoading = false
        })
      }
    },
    handleYes(num) {
      switch (num) {
        case 1:
          if (this.period === 0) {
            this.form.validity = 0
          } else {
            this.form.validity = this.form.validity = timestampToTimes(this.time.getTime()).slice(0, 10)
          }
          postRenewal(this.id, this.form).then(() => {
            this.$message({
              type: 'success',
              message: '续班成功!'
            })
            this.innerVisible = false
            this.dialogOpen()
          })
          break
        case 2:
          if (this.form.is_refund === true) {
            this.form.is_refund = 1
          } else {
            this.form.is_refund = 0
          }
          postExpend(this.id, this.form).then(() => {
            this.$message({
              type: 'success',
              message: '出班成功!'
            })
            this.innerVisible = false
            this.dialogOpen()
          })
          break
        case 3:
          if (this.period === 0) {
            this.form.validity = 0
          } else {
            this.form.validity = this.form.validity = timestampToTimes(this.time.getTime()).slice(0, 10)
          }
          if (this.form.is_refund === true) {
            this.form.is_refund = 1
          } else {
            this.form.is_refund = 0
          }
          postShift(this.id, this.form).then(() => {
            this.$message({
              type: 'success',
              message: '转班成功!'
            })
            this.innerVisible = false
            this.dialogOpen()
          })
          break
        case 4:
          postSplit(this.id, this.form).then(() => {
            this.$message({
              type: 'success',
              message: '分课成功!'
            })
            this.innerVisible = false
            this.dialogOpen()
          })
          break
        case 5:
          postRegulate(this.id, this.form).then(() => {
            this.$message({
              type: 'success',
              message: '调整成功!'
            })
            this.innerVisible = false
            this.dialogOpen()
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  .user_is {
    h3 {
      margin: 5px 0;
    }
    div {
      margin-top: 4px;
      font-size: 12px;
    }
  }
  .user_img {
    margin-right: 10px;
  }
}
.el_tabs {
  margin-top: 10px;
}
</style>
