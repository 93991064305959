<template>
  <!-- 班级管理 -->
  <div class="app-container">
    <!-- 搜索 -->
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="left">
        <el-form-item label="开课时间：">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            align="right"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <!-- 售卡状态 -->
        <el-form-item label="售卡状态：">
          <el-select v-model="listQueryParams.shelves" placeholder="请选售卡状态" clearable>
            <el-option label="全部" value="" />
            <el-option label="上架" value="1" />
            <el-option label="下架" value="0" />
          </el-select>
        </el-form-item>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </el-form>
    </div>
    <!-- 会员搜索 -->
    <div class="filter-item">
      <el-input
        v-model="listQueryParams.name"
        placeholder="班级名称"
        style="width: 300px;"
        @keyup.enter.native="handleFilter"
      >
        <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
      </el-input>
      <el-button style="margin-left: 20px;" type="primary" icon="el-icon-plus" @click="toPage('add', '0')">
        新增班级
      </el-button>
    </div>
    <!-- 列表 -->
    <div class="tableColumn">
      <el-table v-loading="loading" :data="tableData.list" style="width: 100%" :stripe="true">
        <el-table-column label="班级名称" prop="name" align="center" width="160" />
        <el-table-column label="封面图片" align="center" width="180">
          <template slot-scope="scope">
            <div class="cover">
              <img :src="scope.row.cover" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="课程时间" align="center" width="220">
          <template slot-scope="scope">{{ scope.row.open_time }} 至 {{ scope.row.end_time }}</template>
        </el-table-column>
        <el-table-column label="人数" align="center" width="160">
          <template slot-scope="scope">
            当前人数
            <el-tag>{{ scope.row.user_count }}</el-tag>
            人
          </template>
        </el-table-column>
        <el-table-column label="价格/优惠" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.discount_on == 0">售价：{{ scope.row.money }}</div>
            <div v-if="scope.row.discount_on == 1" class="discount">
              <div>优惠价：{{ scope.row.discount }}</div>
              <div>限时优惠：{{ scope.row.discount_start_time }} 至 {{ scope.row.discount_end_time }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="人数上限" align="center" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.people_number != 0">
              上限
              <el-tag>{{ scope.row.people_number }}</el-tag>
              人
            </div>
            <div v-if="scope.row.people_number == 0">
              <el-tag>人数不限制</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="售卡状态" align="center" width="100">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.shelves === 1">上架</el-tag>
            <el-tag v-show="scope.row.shelves === 0" type="danger">下架</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="300">
          <template slot-scope="scope">
            <el-button type="primary" @click="toCheck(scope.row.id)">查看详情</el-button>
            <el-dropdown class="course" :hide-on-click="false">
              <el-button type="success">
                操作
                <i class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-show="scope.row.shelves === 0" @click.native="handleShelves(scope.row.id, 1)">
                  上架
                </el-dropdown-item>
                <el-dropdown-item v-show="scope.row.shelves === 1" @click.native="handleShelves(scope.row.id, 0)">
                  下架
                </el-dropdown-item>
                <el-dropdown-item @click.native="toPage('edit', scope.row.id)">编辑</el-dropdown-item>
                <el-dropdown-item @click.native="toPages(scope.row.id, scope.row.shelves)">报班</el-dropdown-item>
                <el-dropdown-item @click.native="handleDelete(scope.row)">删除班级</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown class="course" :hide-on-click="false">
              <el-button type="info">
                更多
                <i class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="toPagess(scope.row.id)">课程记录</el-dropdown-item>
                <el-dropdown-item @click.native="toPagesss(scope.row.id)">销售记录</el-dropdown-item>
                <!-- <el-dropdown-item @click.native="handleShare(scope.row.id)">分享报名</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="分享报名" :visible.sync="dialogVisible" width="30%">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">取 消 分 享</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
// API
import { getDataList, putShelves, deleteMclass } from '@/api/store/course'
// import { getShare } from '@/api/store/course'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      }, // 数据
      searchLoading: false, // 搜索按钮加载
      loading: false, // 表格加载
      dialogVisible: false
    }
  },
  watch: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    // 获取数据
    getList() {
      this.loading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.shelves) querys.push({ field: 'shelves', key: this.listQueryParams.shelves })
      if (this.listQueryParams.name) querys.push({ field: 'name', type: 'like', key: this.listQueryParams.name })

      data.query = this.utils.getQueryParams(querys)

      getDataList(data).then(res => {
        const data = res.data
        this.tableData = data
        this.loading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 上架下架
    handleShelves(id, num) {
      if (num === 0) {
        this.$confirm('班级下架后会员无法继续购买，确定下架？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            putShelves(id, num).then(() => {
              this.getList()
            })
          })
          .catch(() => {
            this.getList()
          })
      } else {
        putShelves(id, num).then(() => {
          this.getList()
        })
      }
    },
    // 删除班级
    handleDelete(id) {
      this.$confirm('班级删除后信息将不可回复，班内会员及课程也将删除，是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteMclass(id.id).then(() => {
            this.getList()
          })
        })
        .catch(() => {
          this.getList()
        })
    },
    // 跳转页面
    toPage(operate, id) {
      this.$router.push({ name: 's_mclass_classmanagement_operate', params: { operate: operate, id: id }})
    },
    toCheck(id) {
      this.$router.push({ name: 's_mclass_classmanagement_check', params: { id: id }})
    },
    toPages(id, shelves) {
      if (shelves === 1) {
        this.$router.push({ name: 's_mclass_classmanagement_signup', params: { id: id }})
      } else {
        this.$message({
          message: '下架的班级不允许报班哦',
          type: 'warning'
        })
      }
    },
    toPagess(id) {
      this.$router.push({ name: 's_mclass_classmanagement_record', params: { id: id }})
    },
    toPagesss(id) {
      this.$router.push({ name: 's_mclass_classmanagement_sales', params: { id: id }})
    }
    // 分享
    // handleShare(id) {
    //   console.log(id)
    //   this.dialogVisible = true
    //   getShare(id, 2)
    // }
  }
}
</script>

<style lang="scss" scoped>
.cover {
  width: 150px;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
}
.discount {
  color: #ff9945;
}
.tableColumn {
  margin-top: 20px;
}
.course {
  margin-left: 10px;
}
</style>
