<template>
  <div>
    <!-- 时间选择器 -->
    <div class="m-t-20"><timeSelect :date-time="dateTime" @updateTime="updateTime" /></div>
    <div v-loading="loading">
      <!-- 课程列表 -->
      <el-table v-if="dataList.count > 0" :data="dataList.list" style="width: 100%">
        <el-table-column prop="date" label="教练">
          <template slot-scope="{ row }">
            <div v-if="row.store_user" class="flex col-center">
              <el-avatar shape="square" :size="50" :src="row.store_user.avatar">
                <img :src="errorAvatarSrc(row.store_user, 'img')">
              </el-avatar>
              <div class="p-l-10">
                <span>{{ getUserNames(row.store_user) }}</span><br>
              </div>

            </div>
            <div v-else class="text-danger">会员信息丢失</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="班级名称">
          <template slot-scope="{ row }">
            {{ row.mclass_grade.name }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="课程名称">
          <template slot-scope="{ row }">
            {{ row.course.name }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="班课时间">
          <template slot-scope="{ row }">{{ row.start_time.slice(11, 16) }} ~ {{ row.end_time.slice(11, 16) }}</template>
        </el-table-column>
        <el-table-column prop="total_due" label="应到" />
        <el-table-column prop="total_sign_in" label="签到" />
        <el-table-column prop="total_ask" label="请假" />
        <el-table-column prop="address" label="操作" width="300px">
          <template slot-scope="{ row }">
            <el-button size="small" @click="handleTask(row.id)">作业</el-button>
            <el-button size="small" type="success" @click="handleAdd(row.id)">精彩瞬间</el-button>
            <el-button size="small" type="primary" @click="handleSign(row.id)">学员签到</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-else class="venuesList_empty text-center p-10">
        <img :src="empty_default_img" alt="">
        <div><div class="text-info">当前暂无班课</div><el-link type="primary" @click="$router.push({ name: 's_mclass_classschedule' })">去排课</el-link></div>
      </div>
      <pagination
        v-show="dataList.count > 0"
        :total="dataList.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getPrivateVenuesList"
      />
    </div>
    <!-- 班课作业 -->
    <el-dialog
      title="班课作业"
      :visible.sync="dialogVisibleTask"
      width="30%"
      :before-close="handleCloseTask"
      :close-on-click-modal="false"
    >
      <wangEditor v-model="WangEditorValue" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTask = false">取 消</el-button>
        <el-button type="primary" :loading="buttonLoading" @click="handleWangEditor">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 精彩瞬间 -->
    <el-dialog
      title="精彩瞬间"
      :visible.sync="dialogVisibles"
      width="30%"
      :before-close="handleCloseImg"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <el-upload
        v-loading="upLoading"
        field="file"
        :action="url + '/store/v1/upload'"
        list-type="picture-card"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success="upSuccess"
        :headers="utils.upload_headers()"
        :file-list="fileList"
        :limit="6"
        :on-exceed="handleExceed"
        :on-progress="upProgress"
        :before-upload="checkFileType"
        accept=".jpg,.jpeg,.png"
      >
        <i class="el-icon-plus" />
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" class="dialog_view_image">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      <small class="text-info">图片尺寸建议750*1000</small>
    </el-dialog>
    <!-- 签到弹出框 -->
    <el-dialog
      title="签到详情"
      :visible.sync="dialogVisibleSignIn"
      width="35%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-button v-if="MclassList.length !== 0" style="margin-bottom: 10px;" type="success" @click="handleSignIn">
        全部签到
      </el-button>
      <el-table v-loading="loaDialog" :data="MclassList" border style="width: 100%" :stripe="true">
        <el-table-column label="姓名" align="center" width="230px">
          <template slot-scope="{row}">
            <div v-if="!utils.empty(row.user)" class="flex col-center">
              <div>
                <el-avatar shape="square" :size="50" :src="row.user.avatar">
                  <img :src="errorMemberSrc()">
                </el-avatar>
              </div>
              <div class="p-l-10">
                <span>{{ getUserNames(row.user) }}</span>
                <br>
                <span>{{ row.user.phone }}</span>
              </div>
            </div>
            <div v-else class="text-danger">用户信息缺失</div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1">正常</el-tag>
            <el-tag v-if="scope.row.status === 2" type="success">请假</el-tag>
            <el-tag v-if="scope.row.status === 3">签到</el-tag>
            <el-tag v-if="scope.row.status === 4" type="danger">旷课</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="220px">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.status === 2 || scope.row.status === 4 || scope.row.status === 1 || scope.row.status === 0
              "
              type="primary"
              @click="handleOperation(3, scope.row.id)"
            >
              签到
            </el-button>
            <el-button
              v-if="
                scope.row.status === 3 || scope.row.status === 4 || scope.row.status === 1 || scope.row.status === 0
              "
              type="success"
              @click="handleOperation(2, scope.row.id, scope.row)"
            >
              请假
            </el-button>
            <el-button v-if="scope.row.status === 2" type="info" @click="handleOperation(0, scope.row.id)">
              取消请假
            </el-button>
            <el-button
              v-if="scope.row.status === 3 || scope.row.status === 1 || scope.row.status === 0"
              type="danger"
              @click="handleOperation(4, scope.row.id)"
            >
              旷课
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleSignIn = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import timeSelect from '@/views/store/worktable/components/timeSelect'
import Utils from '@/utils/utils.js'
import WangEditor from '@/components/wangEditor'
// API
import {
  getMclassBench,
  getMclassList,
  postMclassList,
  oneClickCheckIn,
  getMclassBenchDate,
  getImgs,
  postImgs,
  deleteImgs,
  getWangEditor,
  postWangEditor
} from '@/api/store/course'
import Pagination from '@/components/Pagination' // 分页组件

export default {
  components: { timeSelect, WangEditor, Pagination },
  data() {
    return {
      loading: false,
      dateTime: '',
      dataList: {
        count: 0,
        list: []
      },
      dialogVisibleSignIn: false,
      loaDialog: false,
      MclassBench: {},
      MclassList: [],
      id: 0,
      idImg: 0,
      dialogVisibles: false,
      fileList: [],
      dialogImageUrl: '',
      url: process.env.VUE_APP_BASE_API,
      dialogVisibleTask: false,
      idTask: 0,
      WangEditorValue: '',
      dialogVisible: false,
      buttonLoading: false
    }
  },
  watch: {
    dateTime: {
      handler(newVal) {
        this.getPrivateVenuesList()
      },
      deep: true
    }
  },
  created() {
    if (localStorage.getItem('dateTime')) this.dateTime = localStorage.getItem('dateTime')
    else this.dateTime = Utils.getDayTime()
  },
  mounted() {
    this.getPrivateVenuesList()
  },
  methods: {
    updateTime(e) {
      localStorage.setItem('dateTime', e)
      this.dateTime = e
    },
    getPrivateVenuesList() {
      this.loading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      getMclassBenchDate(this.dateTime, data).then(res => {
        this.dataList = res.data
        this.loading = false
      })
    },
    // 操作
    handleSign(id) {
      this.id = id
      this.loaDialog = true
      this.dialogVisibleSignIn = true
      this.abc()
    },
    abc() {
      getMclassBench(this.id, { is_log: 0 }).then(res => {
        this.MclassBench = res.data
      })
      getMclassList(this.id, { is_log: 0 }).then(res => {
        this.MclassList = res.data.list
        this.loaDialog = false
      })
    },
    handleClose(done) {
      done()
      this.getPrivateVenuesList()
    },
    handleOperation(type, id, ask) {
      if (type === 2) {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          message: h('div', null, [
            h('div', null, `所在班级：${this.MclassBench.course.name}`),
            h('div', null, `请假课程：${this.MclassBench.mclass_grade.name}`),
            h(
              'div',
              null,
              `课程时间：${this.MclassBench.update_time.slice(0, 16)} 至 ${this.MclassBench.start_time.slice(11, 16)}`
            ),
            h('div', null, `剩余请假：${ask.remain_ask_number}`)
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              postMclassList(type, this.id, id).then(() => {
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                })
                done()
                this.abc()
                this.getPrivateVenuesList()
              })
            } else {
              done()
            }
          }
        })
      } else {
        postMclassList(type, this.id, id).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.abc()
          this.getPrivateVenuesList()
        })
      }
    },
    handleSignIn() {
      console.log(this.id)
      this.$confirm('是否确定为全部学员签到? （课时不足、成员状态异常、请假学员无法签到）', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        oneClickCheckIn(this.id).then(() => {
          this.$message({
            type: 'success',
            message: '签到成功!'
          })
          this.abc()
          this.getPrivateVenuesList()
        })
      })
    },
    // 精彩瞬间
    handleAdd(id) {
      this.idImg = id
      this.dialogVisibles = true
      getImgs(id).then(res => {
        var imagesArrs = []
        res.data.list.forEach(function(i) {
          imagesArrs.push({
            name: '123123',
            url: i.images,
            id: i.id
          })
        })
        this.fileList = imagesArrs
      })
    },
    handleCloseImg(done) {
      done()
      this.fileList = []
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        // this.$message.error(response.msg)
        return
      }
      // console.log(response)
      postImgs(this.idImg, { images: response.data.path }).then(() => {
        this.$message({
          message: '上传成功',
          type: 'success',
          duration: 800
        })
      })
      // var imageList = []
      // for (var i in fileList) {
      //   imageList.push(fileList[i].response.data.path)
      // }
      // console.log(imageList)
    },
    checkFileType(file) {
      if (file.size / 4000 / 4000 > 0.1) {
        this.$message.warning('上传图片过大，请重新上传！')
        return false
      }
    },
    upProgress() {
      this.upLoading = true
    },
    handleRemove(file, fileList) {
      if (file.id === undefined) return false
      deleteImgs(file.id).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success',
          duration: 800
        })
      })
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleExceed(files, fileList) {
      this.$message.warning(`仅支持上传六张相册图片`)
    },
    // 作业
    handleTask(id) {
      this.idTask = id
      this.dialogVisibleTask = true
      getWangEditor(id).then(res => {
        this.WangEditorValue = res.data.content
      })
    },
    handleCloseTask(done) {
      done()
      this.WangEditorValue = ''
    },
    handleWangEditor() {
      this.buttonLoading = true
      this.dialogVisibleTask = false
      postWangEditor(this.idTask, { content: this.WangEditorValue }).then(() => {
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 800
        })
        this.buttonLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .user_img {
    margin-right: 10px;
  }
}
</style>
