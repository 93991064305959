<template>
  <div class="app-container special-center">
    <div class="p-x-30 p-y-90 notice_box">
      <div class="title text-center m-y-30 font-xs">{{ info.title }}</div>
      <div class="content" v-html="info.content" />
      <div class="time text-right text-info m-y-20">{{ info.create_time }}</div>
    </div>

  </div>
</template>

<script>
import { getSystemNoteInfo } from '@/api/store/common.js'
export default {
  data() {
    return {
      id: '',
      info: {}
    }
  },
  created() {
    if (!this.$route.params.id) return false
    this.id = this.$route.params.id
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      getSystemNoteInfo(this.id).then(res => {
        this.info = res.data
        if (this.info.title) {
          this.utils.setTagsViewTitle(this.$route, this.info.title)
          this.utils.setPageTitle(this.$route, this.info.title)
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.app-container{
  min-height: 100vh;
}
.notice_box{
  .content{
    overflow: hidden;
  }
  img{
    width: 100%;
  }
}
</style>
