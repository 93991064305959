var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.name == "s_user_list"
    ? _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "div",
                { staticClass: "searchBox" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.listQueryParams,
                        "label-width": "100px",
                        "label-position": "left",
                        "label-suffix": "："
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                filterable: "",
                                clearable: ""
                              },
                              model: {
                                value: _vm.listQueryParams.sex,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryParams, "sex", $$v)
                                },
                                expression: "listQueryParams.sex"
                              }
                            },
                            _vm._l(_vm.sexList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "会籍顾问" } },
                        [
                          _c("Select", {
                            attrs: { type: 4 },
                            on: { changeId: _vm.handleCoachID }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "入会时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              "unlink-panels": ""
                            },
                            model: {
                              value: _vm.listQueryParams.create_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.listQueryParams,
                                  "create_time",
                                  $$v
                                )
                              },
                              expression: "listQueryParams.create_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生日" } },
                        [
                          _c("DateMonthDay", {
                            on: { changeDay: _vm.changeDay }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "m-b-10",
                          attrs: {
                            icon: "el-icon-search",
                            loading: _vm.searchLoading,
                            type: "success"
                          },
                          on: { click: _vm.handleFilter }
                        },
                        [_vm._v(" 搜索 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "搜索 手机号 | 昵称" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleFilter($event)
                        }
                      },
                      model: {
                        value: _vm.listQueryParams.query,
                        callback: function($$v) {
                          _vm.$set(_vm.listQueryParams, "query", $$v)
                        },
                        expression: "listQueryParams.query"
                      }
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          loading: _vm.searchLoading
                        },
                        on: { click: _vm.handleFilter },
                        slot: "append"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.btn_access("s_user_add"),
                      expression: "btn_access('s_user_add')"
                    }
                  ],
                  staticClass: "filter-item m-l-10",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.add_edit }
                },
                [_vm._v(" 添加 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.btn_access("s_user_export"),
                      expression: "btn_access('s_user_export')"
                    }
                  ],
                  staticClass: "filter-item m-l-10",
                  attrs: {
                    disabled: _vm.tableData.list.length <= 0,
                    type: "danger",
                    icon: "el-icon-download",
                    loading: _vm.exportLoading
                  },
                  on: { click: _vm.Download }
                },
                [_vm._v(" 导出 ")]
              ),
              _c(
                "el-dropdown",
                {
                  staticClass: "course filter-item m-l-10",
                  attrs: { "hide-on-click": false }
                },
                [
                  _c("el-button", { attrs: { type: "success" } }, [
                    _vm._v(" 批量操作 "),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right"
                    })
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.currentVenues.id !== -1 &&
                                _vm.btn_access("s_user_vipcard_import"),
                              expression:
                                "currentVenues.id !== -1 && btn_access('s_user_vipcard_import')"
                            }
                          ],
                          nativeOn: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "s_user_vipcard_import",
                                params: { type: 2 }
                              })
                            }
                          }
                        },
                        [
                          [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _vm._v(" 批量导入 ")
                          ]
                        ],
                        2
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.btn_access("s_batch_integral"),
                              expression: "btn_access('s_batch_integral')"
                            }
                          ],
                          nativeOn: {
                            click: function($event) {
                              return _vm.handleModifyPointsAll($event)
                            }
                          }
                        },
                        [
                          [
                            _c("i", { staticClass: "el-icon-edit" }),
                            _vm._v(" 批量修改 ")
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              ref: "multipleTable",
              attrs: { data: _vm.tableData.list, "row-key": "id", fit: true }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "avatar", label: "头像" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          !_vm.utils.empty(row)
                            ? _c("div", { staticClass: "flex col-center" }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-avatar",
                                      {
                                        attrs: {
                                          shape: "square",
                                          size: 50,
                                          src: row.avatar
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: _vm.errorMemberSrc() }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "p-l-10" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.getUserNames(row)))
                                  ]),
                                  _c("br"),
                                  _c("span", [_vm._v(_vm._s(row.phone))])
                                ])
                              ])
                            : _c("div", { staticClass: "text-danger" }, [
                                _vm._v("用户信息缺失")
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1236177567
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sex",
                  label: "性别",
                  formatter: _vm.formatTableSex
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "integral", label: "积分" }
              }),
              _c("el-table-column", {
                attrs: { prop: "birthday", label: "生日" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(row.birthday ? row.birthday : "---") +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2490919955
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "channel", label: "登入渠道" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.program_open_id
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "m-r-2",
                                  attrs: { type: "success" }
                                },
                                [_vm._v("小程序")]
                              )
                            : _vm._e(),
                          row.wechat_open_id
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "m-r-2",
                                  attrs: { type: "primary" }
                                },
                                [_vm._v("公众号")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4219302402
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "store_user", label: "会籍顾问" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.store_user ? row.store_user.real_name : "--"
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2060479346
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "入会时间",
                  "min-width": "120"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "160px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.btn_access("s_user_info"),
                                  expression: "btn_access('s_user_info')"
                                }
                              ],
                              staticClass: "m-2",
                              attrs: { type: "primary", icon: "el-icon-info" },
                              on: {
                                click: function($event) {
                                  return _vm.toInfoPage(scope.row.id)
                                }
                              }
                            },
                            [_vm._v(" 详情 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.btn_access("s_user_vipcard_add"),
                                  expression: "btn_access('s_user_vipcard_add')"
                                }
                              ],
                              staticClass: "filter-item m-2",
                              attrs: { type: "success", icon: "el-icon-plus" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "s_user_vipcard_add",
                                    params: {
                                      user_id: scope.row.id,
                                      card_id: 2
                                    }
                                  })
                                }
                              }
                            },
                            [_vm._v(" 发卡 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.btn_access("s_user_track_list"),
                                  expression: "btn_access('s_user_track_list')"
                                }
                              ],
                              staticClass: "m-2",
                              attrs: { icon: "el-icon-notebook-2" },
                              on: {
                                click: function($event) {
                                  return _vm.toVisitorTrackPage(scope.row.id)
                                }
                              }
                            },
                            [_vm._v(" 回访记录 ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3049100926
                )
              })
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableData.count > 0,
                expression: "tableData.count > 0"
              }
            ],
            attrs: {
              total: _vm.tableData.count,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList
            }
          }),
          _vm.dialog.visible
            ? _c(
                "el-dialog",
                {
                  staticClass: "dialog_auto",
                  attrs: {
                    "append-to-body": "",
                    "close-on-click-modal": false,
                    visible: _vm.dialog.visible,
                    title: _vm.dialog.id ? "编辑用户" : "添加用户"
                  },
                  on: {
                    "update:visible": function($event) {
                      return _vm.$set(_vm.dialog, "visible", $event)
                    }
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "100px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100px" },
                          attrs: { label: "头像", prop: "avatar" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "block",
                              on: {
                                click: function($event) {
                                  _vm.imagecropperShow = true
                                }
                              }
                            },
                            [
                              _c(
                                "el-avatar",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    shape: "square",
                                    size: 100,
                                    src: _vm.form.avatar,
                                    title: _vm.dialog.id
                                      ? "点击修改"
                                      : "点击上传"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.errorMemberSrc() }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("image-cropper", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imagecropperShow,
                                expression: "imagecropperShow"
                              }
                            ],
                            key: _vm.imagecropperKey,
                            attrs: {
                              field: "file",
                              width: 300,
                              height: 300,
                              url: _vm.storeUpload,
                              "lang-type": "zh"
                            },
                            on: {
                              close: _vm.imagecropperClose,
                              "crop-upload-success": _vm.cropSuccess
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别", prop: "sex" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.sex,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sex", $$v)
                                },
                                expression: "form.sex"
                              }
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("女")
                              ]),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("男")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "真实姓名", prop: "real_name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入真实姓名" },
                            model: {
                              value: _vm.form.real_name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "real_name", $$v)
                              },
                              expression: "form.real_name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "phone" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入手机号" },
                              model: {
                                value: _vm.form.phone,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "phone", $$v)
                                },
                                expression: "form.phone"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "prepend" }, slot: "prepend" },
                                [
                                  _c("Code", {
                                    on: { area_code: _vm.area_code }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "会籍顾问", prop: "store_user_id" } },
                        [
                          _c("Select", {
                            attrs: { type: 4 },
                            on: { changeId: _vm.handleCoachIDAdd }
                          })
                        ],
                        1
                      ),
                      _vm.utils.getVenues().id == -1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "所属场馆", prop: "venues_id" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "dialog_w100",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择所属场馆"
                                  },
                                  model: {
                                    value: _vm.form.venues_id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "venues_id", $$v)
                                    },
                                    expression: "form.venues_id"
                                  }
                                },
                                _vm._l(_vm.venues_list, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: "请输入备注"
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submit }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.dialog.id ? "保存" : "新增") + " "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              _vm.dialog.visible = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": "",
                "close-on-click-modal": false,
                visible: _vm.dialog.userVisible,
                title: "用户信息"
              },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.dialog, "userVisible", $event)
                },
                close: _vm.onClose
              }
            },
            [
              _c(
                "el-descriptions",
                { staticClass: "margin-top", attrs: { column: 3, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-user" }),
                        _vm._v(" 头像 ")
                      ]),
                      _c("div", { staticClass: "demo-basic--circle" }, [
                        _c(
                          "div",
                          { staticClass: "block" },
                          [
                            _c(
                              "el-avatar",
                              {
                                attrs: {
                                  shape: "square",
                                  size: 50,
                                  src: _vm.form.avatar
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.errorAvatarSrc(_vm.form.sex, "img")
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-user-solid" }),
                        _vm._v(" 姓名 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.form.real_name) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-mobile-phone" }),
                        _vm._v(" 手机号 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.form.phone) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-tickets" }),
                        _vm._v(" 昵称 ")
                      ]),
                      _c("el-tag", [_vm._v(_vm._s(_vm.form.nickname))])
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-office-building" }),
                        _vm._v(" 地区 ")
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.form.area ? _vm.form.area : "--") + " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-office-building" }),
                        _vm._v(" 详细地址 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.form.address ? _vm.form.address : "无") +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-time" }),
                        _vm._v(" 入会时间 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.form.create_time ? _vm.form.create_time : "无"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c("template", { slot: "extra" })
                ],
                2
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.onClose } },
                    [_vm._v("关闭")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-edit" },
                      on: {
                        click: function($event) {
                          return _vm.add_edit(_vm.form.id)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("VisitorTrack", {
            attrs: {
              id: _vm.dialog.trackId,
              "track-visible": _vm.dialog.trackVisible
            },
            on: {
              "update:trackVisible": function($event) {
                return _vm.$set(_vm.dialog, "trackVisible", $event)
              },
              "update:track-visible": function($event) {
                return _vm.$set(_vm.dialog, "trackVisible", $event)
              }
            }
          }),
          _c("Export", {
            attrs: {
              "success-prompt": _vm.successPrompt,
              "is-judge": _vm.is_judge,
              "animation-display": _vm.animation_display
            }
          }),
          _c("ModifyPoints", {
            attrs: { visible: _vm.modifyPointsVisible },
            on: { closeDialog: _vm.closeDialog }
          })
        ],
        1
      )
    : _c("div", [_c("router-view")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }