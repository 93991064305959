<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- 筛选 -->
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <!-- <el-form-item label="品牌" prop="brandID">
            <Select :type="1" @changeId="handleBrandID" />
          </el-form-item> -->
          <el-form-item label="操作时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索 url标题|详情|ip" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-table v-loading="tableLoading" :data="tableData.list" stripe style="width: 100%">
        <el-table-column prop="date" label="操作用户" align="center">
          <template slot-scope="{ row }">{{ row.admin.real_name }}</template>
        </el-table-column>
        <el-table-column prop="title" label="url标题" align="center" />
        <el-table-column prop="url" label="访问链接" align="center" />
        <el-table-column prop="type" label="请求类型" align="center" />
        <!-- <el-table-column prop="operation" label="操作" align="center" /> -->
        <el-table-column prop="detail" label="详情" align="center">
          <template slot-scope="{ row }"><div class="scrollBar" v-html="row.detail" /></template>
        </el-table-column>
        <el-table-column prop="ip" label="操作IP" align="center" />
        <el-table-column prop="update_time" label="修改时间" align="center" />
        <el-table-column label="操作" fixed="right" width="100px">
          <template slot-scope="scope">
            <el-button class="m-2" type="primary" icon="el-icon-view" @click="view_data(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="tableData.count > 0"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
    <!-- 详情弹出层 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="详情"
      class="dialog_auto dialog_autoh"
      destroy-on-close
    >
      <div class="data_info">
        <div v-for="(item,index) in dialog.data" :key="index" class="data_item">
          <div class="flex">
            <div class="field">{{ index }}</div>
            <div v-if="index=='data'" v-clipboard:copy="typeof item==='object'? JSON.stringify(item):item" v-clipboard:success="clipboardSuccess" class="cursor">
              <pre>{{ item }}</pre>
            </div>
            <div v-else v-clipboard:copy="typeof item==='object'? JSON.stringify(item):item" v-clipboard:success="clipboardSuccess" class="value cursor">{{ item }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getAdminOperateLogList } from '@/api/admin/capital.js'
// 分页组件
import Pagination from '@/components/Pagination'
// 组件
// import Select from '@/components/elSelect/Select'
import clipboard from '@/directive/clipboard/index.js'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'
import { deepClone } from '@/utils'

export default {
  directives: { clipboard },
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      searchLoading: false,
      tableLoading: false,
      appid: 0,
      timer: '',
      listQueryParams: {
        create_time: []
      },
      dialog: {
        visible: false,
        data: []
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    this.listQueryParams.create_time = [timestampToTimes(start.getTime()).slice(0, 10), timestampToTimes(end.getTime()).slice(0, 10)]
  },
  mounted() {
    this.getList()
    this.timer = new Date().getTime()
  },
  methods: {
    clipboardSuccess() {
      this.$message({
        message: '复制成功',
        type: 'success',
        duration: 1500
      })
    },
    getList() {
      this.searchLoading = true
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      var querys = []
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.id) {
        querys.push({ field: 'appid', key: this.listQueryParams.id })
      }
      if (this.listQueryParams.query) {
        querys.push({ field: 'title|detail|ip', type: 'like', key: this.listQueryParams.query })
      }
      data.query = this.utils.getQueryParams(querys)

      getAdminOperateLogList(data)
        .then(res => {
          const { data } = res
          this.tableData = data
          this.searchLoading = false
          this.tableLoading = false
        })
        .catch(() => {
          this.searchLoading = false
          this.tableLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleBrandID(val) {
      this.listQueryParams.id = val
    },
    // 查看详情
    view_data(item) {
      const arr = deepClone(item)
      // arr.data = JSON.parse(arr.data)
      this.dialog.visible = true
      this.dialog.data = arr
    }
  }
}
</script>

<style lang="scss" scoped>
.venues_word {
  width: 200px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
.data_info{
  .data_item{
    padding: 10px 10px;
    border-bottom: 1px solid #ebeef5;
  }
  .field{
    font-weight: 600;
    min-width: 120px;
  }

}
.scrollBar {
  max-height: 80px;
  overflow: scroll; /* 添加滚动条 */
}
</style>
