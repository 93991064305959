var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "toolbar" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("course_label_add"),
                  expression: "btn_access('course_label_add')"
                }
              ],
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.dialogVisible()
                }
              }
            },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticClass: "el-table--small",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", label: "名称", "min-width": "150px" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "category.name",
              label: "分类",
              "min-width": "150px"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "weigh", label: "排序", "min-width": "150px" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "创建时间",
              "min-width": "150px"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("course_label_edit"),
                            expression: "btn_access('course_label_edit')"
                          }
                        ],
                        staticClass: "m-2",
                        attrs: { type: "primary", icon: "el-icon-edit" },
                        on: {
                          click: function($event) {
                            return _vm.dialogVisible(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("course_label_del"),
                            expression: "btn_access('course_label_del')"
                          }
                        ],
                        attrs: { title: "删除该标签，确定继续吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.del(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count>0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog_w580",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.cancelDialog,
            visible: _vm.dialog.visible,
            title: _vm.dialog.id === null ? "添加标签" : "编辑标签"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类", prop: "category_id" } },
                [
                  _c("SelectTree", {
                    attrs: {
                      props: _vm.defaultProps,
                      options: _vm.sorts,
                      value: _vm.form.category_id,
                      clearable: _vm.isClearable,
                      accordion: _vm.isAccordion,
                      "root-name": "顶级分类"
                    },
                    on: {
                      getValue: function($event) {
                        return _vm.getValue($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "weigh" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.weigh,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "weigh", $$v)
                      },
                      expression: "form.weigh"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.cancelDialog } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }