<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisiblePdf"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="35%"
      fullscreen
      :show-close="false"
      @open="handleOpen"
    >
      <!-- <div slot="title"> -->
      <div class="title">
        <div class="title_title">添加签署区 - {{ src.split('/')[src.split('/').length - 1] }}</div>
        <div class="flex">
          <div
            class="button_shadow"
            :class="currentPage === 1 ? 'cursor_disabled' : 'cursor'"
            @click="currentPage === 1 ? '' : currentPage--"
          >
            上一页
          </div>
          <div class="m-x-4">
            {{ currentPage }}
            <span class="m-x-4">/</span>
            {{ pageCount }}
          </div>
          <div
            class="button_shadow"
            :class="currentPage === pageCount ? 'cursor_disabled' : 'cursor'"
            @click="currentPage === pageCount ? '' : currentPage++"
          >
            下一页
          </div>
          <div class="m-x-10" style="color: #707375;">|</div>
          <div
            class="icon_shadow"
            :class="scaleRatio === 55 ? 'cursor_disabled' : 'cursor'"
            @click="scaleRatio === 55 ? '' : (scaleRatio -= 15)"
          >
            <i class="el-icon-minus font-16 m-t-2" />
          </div>
          <div class="m-x-10">{{ scaleRatio }}%</div>
          <div
            class="cursor icon_shadow"
            :class="scaleRatio === 220 ? 'cursor_disabled' : 'cursor'"
            @click="scaleRatio === 220 ? '' : (scaleRatio += 15)"
          >
            <i class="el-icon-plus font-16 m-t-2" />
          </div>
        </div>
        <div class="flex">
          <div class="cursor m-r-20" @click="handleClose(0)">取 消</div>
          <div class="cursor" @click="handleClose(1)">下一步</div>
        </div>
      </div>
      <!-- </div> -->
      <div
        class="war_bg"
        :style="{ width: 100 + '%', height: scaleValue < 680 ? 100 + 'vh' : 100 + '%' }"
        @click="handleDocumentClick"
      >
        <div ref="myDiv" class="war" :style="{ width: scaleValue + 'px' }">
          <pdf :src="src" :page="currentPage" @num-pages="pageCount = $event" @progress="loadedRatio = $event" />
          <div
            ref="signatureArea"
            class="signature_area"
            :style="{
              left: currentPosition.x + 'px',
              top: currentPosition.y + 'px',
              cursor: isDragging ? 'grabbing' : 'grab',
              width: divWidth + 'px',
              height: divHeight + 'px',
              fontSize: divFontSize + 'px'
            }"
            :class="isResizing ? 'border_area' : ''"
            @dblclick="handleClick"
            @mousedown="startDrag"
          >
            <div>签名区</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 组件
import pdf from 'vue-pdf'

export default {
  components: { pdf },
  props: {
    dialogVisiblePdf: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: ''
    },
    signatureData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currentPage: 1, // 当前页数
      pageCount: 0, // 总页数
      loadedRatio: 0, // 加载进度 0 = 加载开始, 1 = 加载完成
      scaleRatio: 100, // 缩放比例
      scaleValue: 750, // CSS缩小比例
      isDragging: false,
      isResizing: false,
      startPosition: { x: 0, y: 30 }, // 起始位置
      currentPosition: { x: 0, y: 30 }, // 当前位置
      position: { x: 0, y: 30 },
      divWidth: 120, // 签名区的宽度
      divHeight: 40, // 签名区的高度
      divFontSize: 14, // 字体大小
      startX: 0,
      startY: 0,
      startWidth: 0,
      startHeight: 0,
      resizing: false
    }
  },
  watch: {
    scaleRatio(val) {
      // const width = 100 * (val / 100)
      // const height = 40 * (val / 100)
      // this.divWidth = width.toFixed(0)
      // this.divHeight = height.toFixed(0)
      this.scaleValue = (750 * (val / 100)).toFixed(0)
      // this.currentPosition.x = (this.position.x * (val / 100)).toFixed(0)
      // this.currentPosition.y = (this.position.y * (val / 100)).toFixed(0)
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      // 判断是否是空对象
      if (JSON.stringify(this.signatureData) !== '{}') {
        const {
          page_width,
          // page_height,
          signature_page,
          signature_pos_x,
          signature_pos_y,
          signature_width
        } = this.signatureData
        this.scaleValue = page_width * 2
        this.currentPage = signature_page
        this.currentPosition.x = signature_pos_x * 2
        this.currentPosition.y = signature_pos_y * 2
        this.divWidth = signature_width * 2
        this.divHeight = this.divWidth * 0.33
      }
      this.open()
    },
    // 关闭的回调
    handleClose(type) {
      const data = {
        page_width: this.$refs.myDiv.offsetWidth / 2,
        page_height: this.$refs.myDiv.offsetHeight / 2,
        signature_page: this.currentPage,
        signature_pos_x: this.currentPosition.x / 2,
        signature_pos_y: this.currentPosition.y / 2,
        signature_width: this.$refs.signatureArea.offsetWidth / 2
      }
      if (type) this.$emit('closeVisiblePdf', data)
      else this.$emit('closeVisiblePdf', this.signatureData)
      this.currentPage = 1
    },
    // 下载合同
    handleDownload() {
      this.utils.downloadPaf(this.src, `${this.src.split('/')[this.src.split('/').length - 1]}`)
    },
    // 打印合同
    handlePrinter() {
      window.print(this.src)
    },
    // 拖拽
    startDrag(e) {
      this.isDragging = true
      this.startPosition.x = event.clientX - this.currentPosition.x
      this.startPosition.y = event.clientY - this.currentPosition.y

      // 在mousedown时绑定mousemove和mouseup事件
      document.addEventListener('mousemove', this.handleDrag)
      document.addEventListener('mouseup', this.stopDrag)
      if (this.isResizing) {
        e.preventDefault()
        this.resizing = true
        this.startX = e.clientX
        this.startY = e.clientY
        this.startWidth = this.divWidth
        this.startHeight = this.divHeight
        document.documentElement.addEventListener('mousemove', this.resize)
        document.documentElement.addEventListener('mouseup', this.stopResize)
      }
    },
    resize(e) {
      if (!this.resizing && !this.isResizing) return
      const deltaX = e.clientX - this.startX
      const deltaY = e.clientY - this.startY
      const delta = Math.max(deltaX, deltaY)
      if (this.$refs.signatureArea.offsetWidth > 240 && delta > 0) return
      if (this.$refs.signatureArea.offsetWidth < 120 && delta < 0) return
      this.divWidth = this.startWidth + delta
      this.divHeight = this.$refs.signatureArea.offsetWidth * 0.33
    },
    stopResize() {
      this.resizing = false
      document.documentElement.removeEventListener('mousemove', this.resize)
      document.documentElement.removeEventListener('mouseup', this.stopResize)
    },
    handleDrag(event) {
      if (this.isDragging && !this.isResizing) {
        // 使用 $refs 来引用元素
        // const myDiv = this.$refs.myDiv
        this.currentPosition.x = event.clientX - this.startPosition.x
        this.currentPosition.y = event.clientY - this.startPosition.y
        this.position.x = this.currentPosition.x
        this.position.y = this.currentPosition.y
      }
    },
    stopDrag() {
      this.isDragging = false

      // 在mouseup时移除mousemove和mouseup事件监听
      document.removeEventListener('mousemove', this.handleDrag)
      document.removeEventListener('mouseup', this.stopDrag)
    },
    handleClick() {
      this.isResizing = true
    },
    handleDocumentClick(event) {
      // 点击文档其他地方时取消选中状态
      if (!this.$refs.signatureArea.contains(event.target)) {
        this.isResizing = false
      }
    },
    open() {
      this.$alert('双击选中签名框可缩放,移动拖拽请取消选中状态', '提示', {
        confirmButtonText: '我知道了',
        callback: action => {}
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-weight: 600; */
  position: fixed;
  padding: 10px 60px;
  background-color: #323639;
  color: #fff;
  z-index: 999;
  /* opacity: 0.6; */
  top: 0;
  left: 0;
  .title_title {
    font-size: 16px;
  }
  .button_shadow {
    padding: 10px;
    user-select: none;
  }
  .button_shadow:hover {
    background-color: #424649;
    border-radius: 4px;
  }
  .icon_shadow {
    padding: 10px;
    user-select: none;
  }
  .icon_shadow:hover {
    background-color: #424649;
    border-radius: 50%;
  }
  .title_icon {
    width: 30px;
    height: 30px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.war_bg {
  background-color: #525659;
  .war {
    /* width: 45%; */
    /* height: 1024px; */
    /* margin: 0 auto; */
    /* transform: scale(0.43); */
    /* transform-origin: 50% 0; */
    margin: 0 auto;
    position: relative;
    .signature_area {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      color: #000;
      background-color: rgba(218, 247, 247, 0.6);
      z-index: 99;
      user-select: none;
      resize: both;
      overflow: hidden;
      div {
        width: 45px;
        border-bottom: 1px solid #000;
      }
    }
    .border_area {
      border: 1px dashed rgba(65, 195, 168, 1);
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0px !important;
}
</style>
