<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="请假日期">
        <div class="flex">
          <div>
            {{ cardStatusLog.start_time|formatDate('YYYY-MM-DD') }} 至 {{ cardStatusLog.end_time|formatDate('YYYY-MM-DD') }}
          </div>
          <div><div class="m-l-10">共<span class="text-primary p-x-4">{{ leave_day }}</span>天</div></div>
        </div>
      </el-form-item>
      <el-form-item label="延长请假日期" prop="end_time">
        <el-date-picker
          v-model="form.end_time"
          type="date"
          range-separator="至"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          :clearable="false"
          placeholder="请选择延期时间"
          @change="extended_leave_time_change"
        />
        <small class="p-l-5">本次实际请假<span class="text-primary p-x-4">{{ diff_day }}</span>天</small>
      </el-form-item>
      <el-form-item label="收取费用" prop="checked">
        <el-checkbox v-model="form.checked">收取延长请假费用</el-checkbox>
      </el-form-item>
      <el-form-item v-if="form.checked" label="请假手续费" prop="poundage">
        <el-input v-model="form.poundage" placeholder="请输入费用" type="number" />
      </el-form-item>
      <el-form-item v-if="form.checked" label="收费方式" prop="payment_method_id">
        <el-select v-model="form.payment_method_id" placeholder="收费方式" class="dialog_w100">
          <el-option
            v-for="(item,index) in payment_method"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请填写操作备注"
          rows="5"
          maxlength="300"
          show-word-limit
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button @click="$emit('back', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { extendedLeaveUserMembershipCard, getUserMembershipCardChangeLogs } from '@/api/store/user.js'
import { deepClone } from '@/utils'
export default {
  name: 'ExtendedLeave',
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      form: {
        end_time: '',
        checked: false,
        poundage: '',
        payment_method_id: 0,
        remark: ''
      },
      rules: {
        end_time: { required: true, message: '请选择延长时间', trigger: ['blur', 'change'] },
        poundage: [
          { validator: (rule, value, callback) => {
            if (this.form.checked && (this.form.poundage === '' || this.form.poundage < 0)) {
              callback(new Error('请填写正数'))
            }
            callback()
          }, trigger: ['blur', 'change']
          }
        ]
      },
      cardStatusLog: [],
      diff_day: 0,
      leave_day: 0
    }
  },
  created() {
    // 获取卡变更记录
    this.getCardLog(this.cardData.id)
  },
  methods: {
    // 延长请假操作
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const data = deepClone(this.form)
          if (!this.form.checked) {
            data.payment_method_id = 0
            data.poundage = ''
          }
          extendedLeaveUserMembershipCard(this.cardData.id, data).then(res => {
            this.$emit('callback', res.msg)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 延长请假时间计算
    extended_leave_time_change(res) {
      const data = []
      data[0] = this.$moment.unix(this.cardStatusLog.start_time).format('yyyy-MM-DD')
      data[1] = res
      this.diff_day = this.time_reckon(data)
    },
    // 获取卡状态列表
    getCardLog(card_id) {
      getUserMembershipCardChangeLogs(this.utils.getQueryParam({ user_membership_card_id: card_id, user_membership_card_status_id: 3 }), { limit: 999 }).then(res => {
        if (res.data.list[0]) {
          this.cardStatusLog = res.data.list[0]
          const data = []
          data[0] = this.$moment.unix(this.cardStatusLog.start_time).format('yyyy-MM-DD')
          data[1] = this.$moment.unix(this.cardStatusLog.end_time).format('yyyy-MM-DD')
          this.leave_day = this.time_reckon(data)
        } else {
          this.$message.error('当前会员卡请假状态异常')
        }
      }).catch(() => {})
    },
    // 时间计算（+1天）
    time_reckon(res) {
      if (!res) return 0
      const s = this.$moment(res[0]).format('yyyy-MM-DD')// 开始时间
      const e = this.$moment(res[1]).format('yyyy-MM-DD')// 结束时间
      let diff_day = this.$moment(e).diff(s, 'days')
      diff_day += 1
      return diff_day
    }
  }
}
</script>

<style>

</style>
