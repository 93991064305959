var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container clearfix" },
        [
          _c(
            "div",
            { staticClass: "searchBox" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.listQueryParams,
                    "label-width": "100px",
                    "label-position": "left",
                    "label-suffix": "："
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentVenues.id == -1,
                          expression: "currentVenues.id == -1"
                        }
                      ],
                      attrs: { label: "登记场馆" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.venues_id,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "venues_id", $$v)
                            },
                            expression: "listQueryParams.venues_id"
                          }
                        },
                        _vm._l(_vm.venues_list, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.status,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "status", $$v)
                            },
                            expression: "listQueryParams.status"
                          }
                        },
                        _vm._l(_vm.InquireConfig.status_list, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户级别" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.level,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "level", $$v)
                            },
                            expression: "listQueryParams.level"
                          }
                        },
                        _vm._l(_vm.InquireConfig.user_level, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "会籍顾问" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.store_user_id,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.listQueryParams,
                                "store_user_id",
                                $$v
                              )
                            },
                            expression: "listQueryParams.store_user_id"
                          }
                        },
                        _vm._l(_vm.store_user_list, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.real_name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "来访时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.listQueryParams.create_time,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "create_time", $$v)
                          },
                          expression: "listQueryParams.create_time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "m-b-10" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            loading: _vm.searchLoading,
                            type: "success"
                          },
                          on: { click: _vm.handleFilter }
                        },
                        [_vm._v(" 搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.btn_access("s_inquire_export"),
                              expression: "btn_access('s_inquire_export')"
                            }
                          ],
                          staticClass: " m-l-10",
                          attrs: {
                            disabled: _vm.tableData.list.length <= 0,
                            type: "danger",
                            icon: "el-icon-download",
                            loading: _vm.exportLoading
                          },
                          on: { click: _vm.Download }
                        },
                        [_vm._v("导出 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "搜索客询姓名|手机号" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleFilter($event)
                    }
                  },
                  model: {
                    value: _vm.listQueryParams.query,
                    callback: function($$v) {
                      _vm.$set(_vm.listQueryParams, "query", $$v)
                    },
                    expression: "listQueryParams.query"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      loading: _vm.searchLoading
                    },
                    on: { click: _vm.handleFilter },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.btn_access("s_inquire_add") &&
                    _vm.currentVenues.id != -1,
                  expression:
                    "btn_access('s_inquire_add') && currentVenues.id != -1"
                }
              ],
              staticClass: "filter-item m-l-10",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.add_edit()
                }
              }
            },
            [_vm._v("添加")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("s_inquire_track"),
                  expression: "btn_access('s_inquire_track')"
                }
              ],
              staticClass: "filter-item m-l-10",
              attrs: { type: "warning", icon: "el-icon-chat-line-round" },
              on: { click: _vm.toTrackListPage }
            },
            [_vm._v("回访记录")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentVenues.id != -1,
                  expression: "currentVenues.id != -1"
                }
              ],
              staticClass: "filter-item m-l-10 fr",
              attrs: { type: "primary", plain: "", icon: "el-icon-s-tools" },
              on: {
                click: function($event) {
                  _vm.qrcode_visible = true
                }
              }
            },
            [_vm._v(" 客询配置 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "multipleTable",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", { attrs: { prop: "name", label: "姓名" } }),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "电话", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "store_user.real_name", label: "会籍顾问" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var store_user = ref.row.store_user
                  return [
                    store_user
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(_vm._s(store_user.real_name))
                        ])
                      : _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("暂无")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "source", label: "来源" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.source ? scope.row.source : "暂无")
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "register_type_text", label: "登记方式" }
          }),
          _vm.currentVenues.id == -1
            ? _c("el-table-column", {
                attrs: { prop: "source", label: "登记场馆" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var venues = ref.row.venues
                        return [
                          _c("span", [
                            _vm._v(_vm._s(venues.name ? venues.name : "暂无"))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1963108773
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { prop: "level", label: "用户级别", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("inquiretag", {
                          model: {
                            value: scope.row.level,
                            callback: function($$v) {
                              _vm.$set(scope.row, "level", $$v)
                            },
                            expression: "scope.row.level"
                          }
                        }),
                        _c(
                          "my-popconfirm",
                          {
                            attrs: { placement: "top", width: "200" },
                            on: {
                              confirm: function($event) {
                                return _vm.saveLevel(scope.row)
                              }
                            }
                          },
                          [
                            _c("p", [_vm._v("选择客户级别")]),
                            _c(
                              "div",
                              { staticClass: "m-b-10" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      filterable: ""
                                    },
                                    model: {
                                      value: _vm.level_value,
                                      callback: function($$v) {
                                        _vm.level_value = $$v
                                      },
                                      expression: "level_value"
                                    }
                                  },
                                  _vm._l(_vm.InquireConfig.user_level, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item,
                                      attrs: { label: item, value: item }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.btn_access("s_inquire_edit")
                              ? _c("el-link", {
                                  staticClass: "m-l-5",
                                  attrs: {
                                    slot: "reference",
                                    icon: "el-icon-edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.level_value = scope.row.level
                                    }
                                  },
                                  slot: "reference"
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "用户状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("inquiretag", {
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    }),
                    _c(
                      "my-popconfirm",
                      {
                        attrs: { placement: "top", width: "200" },
                        on: {
                          confirm: function($event) {
                            return _vm.saveStatus(scope.row)
                          }
                        }
                      },
                      [
                        _c("p", [_vm._v("选择用户状态")]),
                        _c(
                          "div",
                          { staticClass: "m-b-10" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  filterable: ""
                                },
                                model: {
                                  value: _vm.status_value,
                                  callback: function($$v) {
                                    _vm.status_value = $$v
                                  },
                                  expression: "status_value"
                                }
                              },
                              _vm._l(_vm.InquireConfig.status_list, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm.btn_access("s_inquire_edit")
                          ? _c("el-link", {
                              staticClass: "m-l-5",
                              attrs: {
                                slot: "reference",
                                icon: "el-icon-edit"
                              },
                              on: {
                                click: function($event) {
                                  _vm.status_value = scope.row.status
                                }
                              },
                              slot: "reference"
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "来访时间" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "my-popconfirm",
                      {
                        on: {
                          confirm: function($event) {
                            return _vm.saveAdviser(scope.row.id)
                          }
                        }
                      },
                      [
                        _c("p", [_vm._v("选择会籍顾问")]),
                        _c(
                          "div",
                          { staticClass: "m-b-20" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  filterable: "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.adviser.store_user_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.adviser, "store_user_id", $$v)
                                  },
                                  expression: "adviser.store_user_id"
                                }
                              },
                              _vm._l(_vm.store_user_list, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.real_name,
                                    value: item.id
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.currentVenues.id != -1 &&
                                  _vm.btn_access("s_inquire_setadviser"),
                                expression:
                                  "currentVenues.id != -1 && btn_access('s_inquire_setadviser')"
                              }
                            ],
                            staticClass: "m-2",
                            attrs: {
                              slot: "reference",
                              icon: "el-icon-s-promotion",
                              type: "warning"
                            },
                            on: {
                              click: function($event) {
                                _vm.adviser.store_user_id =
                                  scope.row.store_user_id
                              }
                            },
                            slot: "reference"
                          },
                          [_vm._v("分配")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "m-2",
                        attrs: { type: "primary", icon: "el-icon-info" },
                        on: {
                          click: function($event) {
                            return _vm.add_edit(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "删除后将无法恢复，确定吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.delInquire(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.currentVenues.id != -1 &&
                                  _vm.btn_access("s_inquire_del"),
                                expression:
                                  "currentVenues.id != -1 &&btn_access('s_inquire_del')"
                              }
                            ],
                            staticClass: "m-2",
                            attrs: {
                              slot: "reference",
                              icon: "el-icon-delete",
                              type: "danger"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "m-2",
                        attrs: { icon: "el-icon-notebook-2" },
                        on: {
                          click: function($event) {
                            return _vm.dialogTrack(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("回访")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.currentVenues.id != -1 &&
                              _vm.btn_access("s_inquire_setcard"),
                            expression:
                              "currentVenues.id != -1 && btn_access('s_inquire_setcard')"
                          }
                        ],
                        staticClass: "m-2",
                        attrs: { icon: "el-icon-location" },
                        on: {
                          click: function($event) {
                            return _vm.dialogTrail(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("跟踪")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm.isEmpty(_vm.userInfo)
        ? _c(
            "div",
            { staticClass: "userInfo" },
            [
              _c(
                "el-dialog",
                {
                  staticClass: "dialog_w750",
                  attrs: {
                    "append-to-body": "",
                    "close-on-click-modal": false,
                    visible: _vm.add_dialog.visible,
                    title: _vm.add_dialog.id
                      ? _vm.add_dialog.is_edit
                        ? "编辑客询"
                        : "客询详情"
                      : "添加客询"
                  },
                  on: {
                    "update:visible": function($event) {
                      return _vm.$set(_vm.add_dialog, "visible", $event)
                    }
                  }
                },
                [
                  _vm.add_dialog.id && !_vm.add_dialog.is_edit
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "m-b-10 text-right" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.btn_access("s_inquire_edit"),
                                      expression: "btn_access('s_inquire_edit')"
                                    }
                                  ],
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-info"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.add_dialog.is_edit = true
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions",
                            {
                              staticClass: "margin-top",
                              attrs: { column: 2, border: "" }
                            },
                            [
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "姓名" } },
                                [_vm._v(" " + _vm._s(_vm.userInfo.name) + " ")]
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "电话" } },
                                [_vm._v(_vm._s(_vm.userInfo.phone))]
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "来源" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.userInfo.source
                                          ? _vm.userInfo.source
                                          : "--"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _vm.userInfo.venues
                                ? _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "场馆" } },
                                    [_vm._v(_vm._s(_vm.userInfo.venues.name))]
                                  )
                                : _vm._e(),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "会籍顾问" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.userInfo.store_user
                                          ? _vm.userInfo.store_user.real_name
                                          : "暂无"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "是否办卡" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.userInfo.has_card == 0 ? "否" : "是"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _vm.userInfo.has_card == 1
                                ? _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "办卡金额" } },
                                    [_vm._v(_vm._s(_vm.userInfo.card_money))]
                                  )
                                : _vm._e(),
                              _vm.userInfo.has_card == 1
                                ? _c(
                                    "el-descriptions-item",
                                    { attrs: { label: "办卡时间" } },
                                    [
                                      _vm.userInfo.card_time == 0
                                        ? _c("span", [_vm._v("未办卡")])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.userInfo.card_time)
                                            )
                                          ])
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "会员级别" } },
                                [
                                  _c("inquiretag", {
                                    model: {
                                      value: _vm.userInfo.level,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userInfo, "level", $$v)
                                      },
                                      expression: "userInfo.level"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "会员状态" } },
                                [
                                  _c("inquiretag", {
                                    model: {
                                      value: _vm.userInfo.status,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userInfo, "status", $$v)
                                      },
                                      expression: "userInfo.status"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "登记方式" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.userInfo.register_type_text) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "创建时间" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.userInfo.create_time) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "ruleForm",
                              attrs: {
                                model: _vm.userInfo,
                                rules: _vm.rules,
                                "label-width": "100px"
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "姓名", prop: "name" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入姓名" },
                                    model: {
                                      value: _vm.userInfo.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userInfo, "name", $$v)
                                      },
                                      expression: "userInfo.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "手机号", prop: "phone" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入手机号" },
                                    model: {
                                      value: _vm.userInfo.phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userInfo, "phone", $$v)
                                      },
                                      expression: "userInfo.phone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "来源", prop: "source" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择来源",
                                        "allow-create": "",
                                        "default-first-option": "",
                                        filterable: "",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.userInfo.source,
                                        callback: function($$v) {
                                          _vm.$set(_vm.userInfo, "source", $$v)
                                        },
                                        expression: "userInfo.source"
                                      }
                                    },
                                    _vm._l(_vm.InquireConfig.label, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item,
                                        attrs: { label: item, value: item }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "会籍顾问",
                                    prop: "store_user_id"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        filterable: "",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.userInfo.store_user_id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userInfo,
                                            "store_user_id",
                                            $$v
                                          )
                                        },
                                        expression: "userInfo.store_user_id"
                                      }
                                    },
                                    _vm._l(_vm.store_user_list, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.real_name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "是否办卡", prop: "has_card" }
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0
                                    },
                                    model: {
                                      value: _vm.userInfo.has_card,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userInfo, "has_card", $$v)
                                      },
                                      expression: "userInfo.has_card"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.userInfo.has_card == 1,
                                      expression: "userInfo.has_card == 1"
                                    }
                                  ],
                                  attrs: { label: "办卡金额", prop: "has_card" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "办卡金额" },
                                    model: {
                                      value: _vm.userInfo.card_money,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.userInfo,
                                          "card_money",
                                          $$v
                                        )
                                      },
                                      expression: "userInfo.card_money"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.userInfo.has_card == 1,
                                      expression: "userInfo.has_card == 1"
                                    }
                                  ],
                                  attrs: { label: "办卡时间", prop: "has_card" }
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd",
                                      type: "date",
                                      placeholder: "办卡时间"
                                    },
                                    model: {
                                      value: _vm.userInfo.card_time,
                                      callback: function($$v) {
                                        _vm.$set(_vm.userInfo, "card_time", $$v)
                                      },
                                      expression: "userInfo.card_time"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "用户级别", prop: "status" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择用户级别",
                                        filterable: ""
                                      },
                                      model: {
                                        value: _vm.userInfo.level,
                                        callback: function($$v) {
                                          _vm.$set(_vm.userInfo, "level", $$v)
                                        },
                                        expression: "userInfo.level"
                                      }
                                    },
                                    _vm._l(
                                      _vm.InquireConfig.user_level,
                                      function(item) {
                                        return _c("el-option", {
                                          key: item,
                                          attrs: { label: item, value: item }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "用户状态", prop: "status" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择用户状态",
                                        filterable: ""
                                      },
                                      model: {
                                        value: _vm.userInfo.status,
                                        callback: function($$v) {
                                          _vm.$set(_vm.userInfo, "status", $$v)
                                        },
                                        expression: "userInfo.status"
                                      }
                                    },
                                    _vm._l(
                                      _vm.InquireConfig.status_list,
                                      function(item) {
                                        return _c("el-option", {
                                          key: item,
                                          attrs: { label: item, value: item }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "m-2",
                              attrs: {
                                type: "primary",
                                loading: _vm.submit_loading
                              },
                              on: { click: _vm.save }
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "m-2",
                              on: { click: _vm.cancelAddDialog }
                            },
                            [_vm._v("取消")]
                          )
                        ],
                        1
                      )
                ]
              ),
              _c(
                "el-dialog",
                {
                  staticClass: "dialog_auto config",
                  attrs: {
                    title: "客询配置",
                    "append-to-body": "",
                    "modal-append-to-body": "",
                    "close-on-click-modal": false,
                    visible: _vm.qrcode_visible,
                    center: true
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.qrcode_visible = $event
                    },
                    opened: _vm.handleOpened
                  }
                },
                [
                  _c("div", { staticClass: "m-b-20 title" }, [
                    _vm._v("客询二维码")
                  ]),
                  _c("InquireQrcodeSign"),
                  _vm.btn_access("s_inquire_config")
                    ? _c(
                        "div",
                        [
                          _c("el-divider"),
                          _c(
                            "div",
                            { staticClass: "dashed m-y-10" },
                            [
                              _c("div", { staticClass: "m-b-10 title" }, [
                                _vm._v("通知状态")
                              ]),
                              _c("div", { staticClass: "el-small m-b-20" }, [
                                _vm._v(
                                  "客户登记成功将发送通知给店长，客户分配后将发送通知给指定会籍顾问"
                                )
                              ]),
                              _c(
                                "el-row",
                                {
                                  attrs: {
                                    gutter: 20,
                                    type: "flex",
                                    justify: "space-between"
                                  }
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          attrs: {
                                            type: "flex",
                                            justify: "space-between"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("客户登记短信提醒")
                                          ]),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-value": 1,
                                              "inactive-value": 0
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.saveInquireConfig()
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.InquireConfig.sms_inquire,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.InquireConfig,
                                                  "sms_inquire",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "InquireConfig.sms_inquire"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          attrs: {
                                            type: "flex",
                                            justify: "space-between"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("客户分配短信提醒")
                                          ]),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-value": 1,
                                              "inactive-value": 0
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.saveInquireConfig()
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.InquireConfig
                                                  .sms_inquire_assign,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.InquireConfig,
                                                  "sms_inquire_assign",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "InquireConfig.sms_inquire_assign"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          attrs: {
                                            type: "flex",
                                            justify: "space-between"
                                          }
                                        },
                                        [
                                          _c("span", [_vm._v("APP通知提醒")]),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-value": 1,
                                              "inactive-value": 0
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.saveInquireConfig()
                                              }
                                            },
                                            model: {
                                              value: _vm.InquireConfig.app,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.InquireConfig,
                                                  "app",
                                                  $$v
                                                )
                                              },
                                              expression: "InquireConfig.app"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("el-divider"),
                          _c("div", { staticClass: "m-t-10 m-b-20 source" }, [
                            _c("div", { staticClass: "m-b-10 title" }, [
                              _vm._v("客户来源自定义")
                            ]),
                            _c("div", { staticClass: "el-small" }, [
                              _vm._v(
                                "点击添加或者编辑修改来源，最多可添加5个（点击拖拽即可排序）"
                              )
                            ]),
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                {
                                  ref: "multipleTableSon",
                                  staticClass: "m-y-10 cursor_move"
                                },
                                _vm._l(_vm.InquireConfig.label, function(tag) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: tag,
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false,
                                        size: "medium"
                                      },
                                      on: {
                                        close: function($event) {
                                          return _vm.handleClose(tag)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(tag) + " ")]
                                  )
                                }),
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "m-l-10" },
                                [
                                  _vm.inputVisible
                                    ? _c("el-input", {
                                        ref: "saveTagInput",
                                        staticClass: "input-new-tag",
                                        on: { blur: _vm.handleInputConfirm },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleInputConfirm(
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.inputValue,
                                          callback: function($$v) {
                                            _vm.inputValue = $$v
                                          },
                                          expression: "inputValue"
                                        }
                                      })
                                    : _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.InquireConfig.label.length <
                                                5,
                                              expression:
                                                "InquireConfig.label.length < 5"
                                            }
                                          ],
                                          staticClass: "button-new-tag",
                                          on: { click: _vm.showInput }
                                        },
                                        [_vm._v("+ 添加来源 ")]
                                      )
                                ],
                                1
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("inquireTrack", {
        attrs: { id: _vm.track.id, "track-visible": _vm.track.visible },
        on: {
          "update:trackVisible": function($event) {
            return _vm.$set(_vm.track, "visible", $event)
          },
          "update:track-visible": function($event) {
            return _vm.$set(_vm.track, "visible", $event)
          }
        }
      }),
      _c(
        "el-dialog",
        {
          ref: "trail",
          staticClass: "dialog_w750",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.trail.visible,
            title: "跟踪客询"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.trail, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.userInfo,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否办卡", prop: "has_card" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.userInfo.has_card,
                      callback: function($$v) {
                        _vm.$set(_vm.userInfo, "has_card", $$v)
                      },
                      expression: "userInfo.has_card"
                    }
                  })
                ],
                1
              ),
              _vm.userInfo.has_card == "1"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "办卡金额", prop: "card_money" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "办卡金额" },
                            model: {
                              value: _vm.userInfo.card_money,
                              callback: function($$v) {
                                _vm.$set(_vm.userInfo, "card_money", $$v)
                              },
                              expression: "userInfo.card_money"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "办卡时间", prop: "card_time" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              format: "yyyy 年 MM 月 dd 日",
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "办卡时间"
                            },
                            model: {
                              value: _vm.userInfo.card_time,
                              callback: function($$v) {
                                _vm.$set(_vm.userInfo, "card_time", $$v)
                              },
                              expression: "userInfo.card_time"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "用户级别", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择用户级别", filterable: "" },
                      model: {
                        value: _vm.userInfo.level,
                        callback: function($$v) {
                          _vm.$set(_vm.userInfo, "level", $$v)
                        },
                        expression: "userInfo.level"
                      }
                    },
                    _vm._l(_vm.InquireConfig.user_level, function(item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择用户状态", filterable: "" },
                      model: {
                        value: _vm.userInfo.status,
                        callback: function($$v) {
                          _vm.$set(_vm.userInfo, "status", $$v)
                        },
                        expression: "userInfo.status"
                      }
                    },
                    _vm._l(_vm.InquireConfig.status_list, function(item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitTrail } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      _vm.trail.visible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }