<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-item">
        <el-input v-model="listQuery.query" placeholder="搜索" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="loading.searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('system_config_add')" v-waves class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-plus" @click="classAdd">
        添加分类
      </el-button>
    </div>

    <!-- 列表 -->
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
      default-expand-all
    >
      <el-table-column prop="id" label="ID" width="150" />
      <el-table-column prop="name" label="分类名称" />
      <el-table-column prop="eng_name" label="分类字段" />
      <el-table-column prop="update_time" label="更新时间" />
      <el-table-column prop="icon" label="图标" width="100">
        <template slot-scope="{row: {icon}}">
          <i :class="icon" />
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('ConfigList')" type="primary" class="m-2" icon="el-icon-s-operation" @click="$router.push({path:`list/${scope.row.id}`})">配置列表</el-button>
          <el-button v-show="btn_access('system_config_edit')" type="primary" class="m-2" icon="el-icon-edit" @click="editConfig(scope.row)">编辑</el-button>
          <el-popconfirm
            v-show="btn_access('system_config_del')"
            title="确定删除吗？"
            @confirm="deleteConfig(scope.row.id)"
          >
            <el-button slot="reference" class="m-2" type="danger" icon="el-icon-delete">删除</el-button>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 新建  编辑表单-->
    <edit-from ref="edits" :from-data="FromData" @onSubmit="setConfig" />
  </div>
</template>

<script>
import {
  getConfigList,
  editConfig,
  addConfig,
  deleteConfig
} from '@/api/admin/config.js'
import editFrom from '@/components/From'
import Pagination from '@/components/Pagination' // 分页组件

export default {
  components: {
    Pagination, editFrom
  },
  data() {
    return {
      FromData: {},
      tableData: {
        list: [],
        count: 0
      },
      loading: { listLoading: false, searchLoading: false }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 添加配置分类
    classAdd() {
      this.FromData = {
        rules: [
          { 'type': 'cascader', 'field': 'parent_string', 'value': '', 'title': '父级分类', 'props': { 'multiple': false, 'placeholder': '请选择父级分类', 'filterable': true, 'props': { 'checkStrictly': true, 'value': 'id', 'label': 'name' }, 'options': this.tableData.list }},
          { 'type': 'input', 'field': 'name', 'value': '', 'title': '分类名称', 'props': { 'type': 'text', 'placeholder': '请输入分类名称' }},
          { 'type': 'input', 'field': 'eng_name', 'value': '', 'title': '分类字段英文', 'props': { 'type': 'text', 'placeholder': '请输入分类字段英文' }},
          { 'type': 'frame', 'field': 'icon', 'value': '', 'title': '图标', 'props': { 'type': 'input', 'maxLength': 1, 'title': '请选择图标', 'src': '\/admin\/widget\/IconFrom?fodder=icon', 'icon': 'el-icon-s-open', 'height': '435px' }},
          { 'type': 'inputNumber', 'field': 'sort', 'value': 0, 'title': '排序', 'props': { 'placeholder': '请输入排序' }},
          { 'type': 'radio', 'field': 'system', 'value': '', 'title': '配置类型', 'options': [{ 'label': '系统', 'value': 1 }, { 'label': '商户', 'value': 0 }] }
        ],
        title: '添加配置分类'
      }
      this.$refs.edits.modals = true
    },
    // 提交
    setConfig(formData) {
      let datas = {}
      datas = formData
      datas.parent_id = formData.parent_string[formData.parent_string.length - 1]
      datas.parent_string = formData.parent_string.toString()
      if (this.FromData.id) {
        editConfig(this.FromData.id, datas).then(res => {
          this.$message.success(res.msg)
          this.$refs.edits.modals = false
          this.getList()
        })
      } else {
        addConfig(datas).then(res => {
          this.$message.success(res.msg)
          this.$refs.edits.modals = false
          this.getList()
        })
      }
    },
    editConfig(data) {
      this.FromData = {
        id: data.id,
        rules: [
          { 'type': 'cascader', 'field': 'parent_string', 'value': data.parent_string.split(','), 'title': '父级分类', 'props': { 'multiple': false, 'placeholder': '请选择父级分类', 'filterable': true, 'props': { 'checkStrictly': true, 'value': 'id', 'label': 'name' }, 'options': this.tableData.list }},
          { 'type': 'input', 'field': 'name', 'value': data.name, 'title': '分类名称', 'props': { 'type': 'text', 'placeholder': '请输入分类名称' }},
          { 'type': 'input', 'field': 'eng_name', 'value': data.eng_name, 'title': '分类字段英文', 'props': { 'type': 'text', 'placeholder': '请输入分类字段英文' }},
          { 'type': 'frame', 'field': 'icon', 'value': data.icon, 'title': '图标', 'props': { 'type': 'input', 'maxLength': 1, 'title': '请选择图标', 'src': '\/#\/admin\/widget\/IconFrom?fodder=icon', 'icon': 'el-icon-s-open', 'height': '435px' }},
          { 'type': 'inputNumber', 'field': 'sort', 'value': data.sort, 'title': '排序', 'props': { 'placeholder': '请输入排序' }},
          { 'type': 'radio', 'field': 'system', 'value': data.system, 'title': '配置类型', 'options': [{ 'label': '系统', 'value': 1 }, { 'label': '商户', 'value': 0 }] }
        ],
        title: '编辑配置分类'
      }
      this.$refs.edits.modals = true
    },
    // 删除
    deleteConfig(id) {
      console.log('delete', id)
      deleteConfig(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      })
    },
    // 获取配置分组列表
    getList() {
      getConfigList()
        .then(res => {
          this.tableData = res.data
        })
        .catch(() => {})
        .finally(() => {
          this.loading.searchLoading = false
        })
    },
    operation() {
      console.log(this.fApi.getValue('name'))
      this.operationVisible = true
    },
    // 搜索
    handleFilter() {
      this.loading.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
