<template>
  <div class="app-container special-center">
    <el-page-header :content="type ? '新增电子合同' : '上传模板'" class="page-header" @back="returnPage()" />
    <el-divider />
    <!-- 步骤条 -->
    <el-steps :active="active" finish-status="success" align-center>
      <el-step title="上传合同" />
      <el-step title="添加签署区" />
      <el-step title="确定文件" />
    </el-steps>
    <!-- 上传合同 -->
    <div v-show="active === 0" class="m-t-40">
      <div class="text-center">
        <el-upload
          ref="upload"
          v-loading="upLoading"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="upSuccess"
          :on-change="upChange"
          :headers="utils.upload_headers()"
          :file-list="fileList"
          :limit="1"
          name="template_file"
          :on-exceed="handleExceed"
          :on-progress="upProgress"
          :auto-upload="false"
          class="upload-demo"
          drag
          :action="importUrl"
          accept=".doc, .docx, .pdf, .jpg, .jpeg, .png"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div slot="tip" class="el-upload__tip">只能上传doc/docx/pdf/jpg/jpeg/png文件</div>
        </el-upload>
      </div>
      <div class="text-center m-t-20">
        <el-button class="btn-block" type="primary" round :loading="btnLoading" @click="submit">{{ state ? '下一步' : '确认并上传' }}</el-button>
      </div>
    </div>
    <div v-show="active === 1" />
    <div v-show="active === 2" style="width: 60%; margin: 60px auto 0;">
      <!-- 表单 -->
      <el-form v-show="!pageType" ref="ruleForm" :model="form" label-position="left" label-width="80px">
        <div style="position: relative;">
          <el-tooltip
            style="position: absolute; top: 10px; left: 30px;"
            effect="dark"
            placement="bottom"
          >
            <div slot="content">
              <div>会员合同：上传此合同后一旦签署将不可更改，只能发送给单个会员签署</div>
              <div>合同模板：上传此合同可创建为模板，此模板可为多个会员批量发送</div>
            </div>
            <i class="el-icon-question cursor" />
          </el-tooltip>
          <el-form-item label="类型">
            <el-radio-group v-model="form.is_customized">
              <el-radio :disabled="!!!type" :label="1">会员合同</el-radio>
              <el-radio :disabled="!!!type" :label="0">合同模板</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="合同名称">
          <el-input v-model="form.name" clearable />
        </el-form-item>
        <el-form-item label="标签">
          <el-tag v-for="item in form.tag_id" :key="item.id" class="m-6">{{ item.name }}</el-tag>
          <el-tag class="cursor m-l-6" type="success" @click="handleTag">{{ form.tag_id.length > 0 ? '+ 编辑标签' : '+ 添加标签' }}</el-tag>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="handlePreserve(1)">保存并添加会员签署</el-button>
          <el-button @click="handlePreserve(0)">仅保存</el-button>
        </el-form-item>
      </el-form>
      <el-form v-show="pageType" ref="defaultForm" :model="defaultForm" label-position="left" label-width="100px">
        <el-form-item label="合同名称">
          <el-input v-model="defaultForm.name" clearable />
        </el-form-item>
        <el-form-item label="合同有效期">
          <el-date-picker
            v-model="defaultForm.contract_expire_time"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="是否短信通知">
          <el-switch v-model="defaultForm.enable_sms" :active-value="1" :inactive-value="0" />
        </el-form-item>
        <el-form-item label="发送给">
          <el-radio-group v-model="memberType">
            <el-radio :label="2">会 员</el-radio>
            <el-radio :label="3">访 客</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择会员">
          <Select ref="userRef" :type="memberType" @changeId="handleMemberID" />
        </el-form-item>
        <el-form-item label="签署截至时间">
          <el-date-picker
            v-model="defaultForm.sign_expire_time"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSend">发送</el-button>
        </el-form-item>
      </el-form>
    </div>
    <pdfDialog :dialog-visible-pdf="dialogVisiblePdf" :src="srcPdf" @closeVisiblePdf="closeVisiblePdf" />
    <!-- 标签 -->
    <tagDialog
      :dialog-visible-template="dialogVisibleTemplate"
      :template-tags="templateTags"
      @closeVisibleTemplate="closeVisibleTemplate"
    />
  </div>
</template>

<script>
// API
import { getTagList, saveContractTemplate, getDefaultForm, startContractSign } from '@/api/store/contract'
// 组件
import pdfDialog from './components/pdfDialog'
import tagDialog from './components/tagDialog'
import Select from '@/components/elSelect/Select'
// 函数
import { deepClone } from '@/utils'

export default {
  components: { pdfDialog, tagDialog, Select },
  data() {
    return {
      active: 0,
      upLoading: false,
      fileList: [],
      importUrl: process.env.VUE_APP_BASE_API + '/store/v1//contract/template/upload',
      btnLoading: false,
      state: false,
      templateFile: '',
      dialogVisiblePdf: false,
      srcPdf: '',
      pdfData: {},
      form: {
        is_customized: 1,
        name: '',
        tag_id: []
      },
      defaultForm: {
        contract_template_id: '',
        name: '',
        contract_expire_time: '',
        enable_sms: 0,
        user_id: '',
        sign_expire_time: ''
      },
      dialogVisibleTemplate: false,
      templateTags: [],
      tagList: [],
      type: 1,
      pageType: 0,
      memberType: 2
    }
  },
  watch: {
    active(val) {
      if (val === 1) this.dialogVisiblePdf = true
    }
  },
  created() {
    this.type = this.$route.params.type
    this.form.is_customized = this.$route.params.type
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getTagList().then(res => {
        const { data } = res
        this.tagList = data.list
      })
    },
    // 上传文件参数
    handlePreview(file) {},
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    upProgress(event, file, fileList) {
      this.upLoading = true
    },
    handleExceed(files, fileList) {
      this.$message.warning(`单次上传限制一个文件`)
    },
    upChange(file, fileList) {
      // 获取上传文件的名称
      this.form.name = file.name.split('.')[0]
      this.fileList = fileList
    },
    upSuccess(response, file, fileList) {
      if (response.status === 200) {
        this.templateFile = response.data.file
        this.$message.success('上传成功！')
        this.state = true
      } else {
        this.$message.error(response.msg)
      }
      this.upLoading = false
      this.btnLoading = false
    },
    // 上传/下一步
    submit() {
      if (this.state) {
        this.active++
        this.srcPdf = this.templateFile
      } else {
        if (this.fileList === undefined || this.fileList.length <= 0) {
          this.$message.error('请先导入文件')
          return false
        }
        this.btnLoading = true

        this.$refs.upload.submit()
      }
    },
    closeVisiblePdf(val) {
      this.dialogVisiblePdf = false
      this.active++
      this.pdfData = val
    },
    handleTag() {
      if (this.form.tag_id.length > 0) this.templateTags = this.form.tag_id
      else this.templateTags = []
      this.dialogVisibleTemplate = true
    },
    closeVisibleTemplate(val) {
      this.getList()
      this.dialogVisibleTemplate = false
      const tag = val.map(value => {
        const tagObject = this.tagList.find(item => item.id === value)
        return tagObject || null
      })
      this.form.tag_id = tag
    },
    // 仅保存
    handlePreserve(type) {
      this.pageType = type
      const data = deepClone({
        file_path: this.srcPdf,
        ...this.form,
        ...this.pdfData
      })
      if (data.tag_id.length > 0) {
        const idList = []
        data.tag_id.forEach(item => {
          idList.push(item.id)
        })
        data.tag_id = idList.toString()
      } else {
        data.tag_id = ''
      }
      saveContractTemplate(data).then(res => {
        if (type === 1) {
          const { data: { template_id }} = res
          this.goJump(template_id)
        } else {
          this.$message.success('保存成功')
          // 跳转页面
          this.$router.push({ name: 's_contract' })
        }
      })
    },
    goJump(id) {
      getDefaultForm(id).then(res => {
        const { data: { name, enable_sms }} = res
        this.defaultForm.contract_template_id = id
        this.defaultForm.name = name
        this.defaultForm.enable_sms = enable_sms
      })
    },
    handleMemberID(val) {
      this.defaultForm.user_id = val
    },
    // 发送
    handleSend() {
      const data = deepClone(this.defaultForm)
      data.user_id = [data.user_id]
      startContractSign(data).then(() => {
        this.$message.success('发送成功')
        // 跳转页面
        this.$router.push({ name: 's_contract' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-block {
  width: 300px;
}
</style>
