<template>
  <div class="app-container">
    <el-table
      ref="multipleTable"
      :data="tableData.list"
      row-key="id"
      class="el-table--small"
    >
      <el-table-column prop="id" label="ID" />
      <el-table-column prop="app.name" label="品牌" min-width="150px" />
      <el-table-column prop="store_user" label="反馈人" min-width="150px">
        <template slot-scope="{row}">
          <div v-if="row.store_user">
            <div>{{ row.store_user.real_name }}</div>
            <div>{{ row.store_user.phone }}</div>
          </div>
          <div v-else>用户丢失</div>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="反馈内容" min-width="150px" />
      <el-table-column prop="type" label="反馈图片" min-width="160px">
        <template slot-scope="{row}">
          <div v-if="row.images">
            <el-image
              v-for="(item,index) in (row.images.split(','))"
              :key="index"
              style="width: 50px; height: 50px"
              :src="item"
              fit="fill"
              :preview-src-list="(row.images.split(','))"
              class="m-2"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="反馈类型" min-width="150px" />
      <el-table-column prop="create_time" label="反馈时间" min-width="150px" />
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>

</template>

<script>
import { getFeedback } from '@/api/admin/feedback.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  components: { Pagination },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      apiData: { url: '/v1/notice' },
      screen: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getFeedback(data).then(res => {
        this.tableData = res.data
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
