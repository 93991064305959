var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c("el-page-header", {
        staticClass: "page-header",
        attrs: { content: "报班" },
        on: {
          back: function($event) {
            return _vm.returnPage()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "formColumn" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.upForm,
                  expression: "upForm"
                }
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.user,
                        callback: function($$v) {
                          _vm.user = $$v
                        },
                        expression: "user"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "会员" } }, [
                        _vm._v("会员")
                      ]),
                      _c("el-radio", { attrs: { label: "访客" } }, [
                        _vm._v("访客")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.user === "会员"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "搜索用户" } },
                    [
                      _c("Select", {
                        ref: "memberRef",
                        attrs: { type: 2 },
                        on: { changeId: _vm.memberId }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user === "访客"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "搜索用户" } },
                    [
                      _c("Select", {
                        ref: "visitorRef",
                        attrs: { type: 3 },
                        on: { changeId: _vm.memberId }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { staticStyle: { width: "400px" }, attrs: { label: "班级" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true, placeholder: "请输入班级名称" },
                    model: {
                      value: _vm.form.class_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "class_id", $$v)
                      },
                      expression: "form.class_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "班级课时" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: true,
                        placeholder: "请输入班级课时",
                        type: "number"
                      },
                      model: {
                        value: _vm.balance,
                        callback: function($$v) {
                          _vm.balance = $$v
                        },
                        expression: "balance"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("节")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "实际课程", prop: "balance" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请输入班级课时", type: "number" },
                      model: {
                        value: _vm.form.balance,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "balance", $$v)
                        },
                        expression: "form.balance"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("节")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "入班日期", prop: "join_time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.form.join_time,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "join_time", $$v)
                      },
                      expression: "form.join_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "有效期" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.validity,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "validity", $$v)
                        },
                        expression: "form.validity"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不限制")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("限制时间")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.validity === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "有效期至" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          format: "yyyy-MM-dd HH:mm",
                          align: "right",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.time,
                          callback: function($$v) {
                            _vm.time = $$v
                          },
                          expression: "time"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "参考售价" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: true,
                        placeholder: "费用",
                        type: "number"
                      },
                      model: {
                        value: _vm.money,
                        callback: function($$v) {
                          _vm.money = $$v
                        },
                        expression: "money"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收费金额", prop: "charge_money" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "费用", type: "number" },
                      model: {
                        value: _vm.form.charge_money,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "charge_money", $$v)
                        },
                        expression: "form.charge_money"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "付款方式" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选付款方式", clearable: "" },
                      model: {
                        value: _vm.form.charge_type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "charge_type", $$v)
                        },
                        expression: "form.charge_type"
                      }
                    },
                    _vm._l(_vm.payment_method, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: index }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { margin: "none", "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: {
                        click: function($event) {
                          return _vm.returnPage()
                        }
                      }
                    },
                    [_vm._v("返 回")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }