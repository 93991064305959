import request from '@/utils/request'

// 版本迭代列表
export function getVersion(data) {
  return request({
    url: '/admin/v1/version_iteration_record',
    method: 'get',
    params: data
  })
}

// 创建版本迭代记录
export function addVersion(data) {
  return request({
    url: '/admin/v1/version_iteration_record',
    method: 'post',
    data
  })
}

// 版本迭代详情
export function versionInfo(id, data) {
  return request({
    url: `/admin/v1/version_iteration_record/${id}`,
    method: 'get',
    params: data
  })
}

// 修改版本迭代信息
export function editVersion(id, data) {
  return request({
    url: `/admin/v1/version_iteration_record/${id}`,
    method: 'put',
    data
  })
}

// 删除版本
export function deleteVersion(id) {
  return request({
    url: `/admin/v1/version_iteration_record/${id}`,
    method: 'delete'
  })
}
