<template>
  <div>
    <el-dialog
      title="请选择场馆"
      :visible.sync="dialogVisibleDisplay"
      width="30%"
      :show-close="is_edit"
      :close-on-click-modal="is_edit"
      :close-on-press-escape="is_edit"
      @open="dialogOpen"
      @close="dialogClose"
    >
      <el-dialog width="40%" title="休息时间" :visible.sync="innerVisible" append-to-body>
        <div v-if="innerVisible">
          <TimeQuantum :store-user-id="userId" :venues-id="venuesId" />
        </div>
      </el-dialog>
      <div v-loading="dialogLoading">
        <el-tag
          v-for="(item, index) in venues"
          :key="index"
          class="m-10 cursor"
          @click="handleClick(item.id)"
        >
          {{ item.name }}
        </el-tag>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleExit">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getUserVenues } from '@/api/store/venues'
// 组件
import TimeQuantum from '@/views/store/venues/employee/components/TimeQuantum.vue'

export default {
  components: { TimeQuantum },
  props: {
    dialogVisibleDisplay: {
      required: true,
      type: Boolean
    },
    userId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      is_edit: false,
      venues: [],
      venuesId: 0,
      dialogLoading: false,
      innerVisible: false
    }
  },
  methods: {
    // 打开的回调
    dialogOpen() {
      this.dialogLoading = true
      getUserVenues().then(res => {
        this.venues = res.data[0].venues
        this.dialogLoading = false
      })
    },
    // 关闭的回调
    dialogClose() {
      console.log('关闭')
    },
    handleExit() {
      this.$emit('close')
    },
    handleClick(id) {
      this.venuesId = id
      this.innerVisible = true
    }
  }
}
</script>

<style lang="scss" scoped></style>
