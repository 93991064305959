import request from '@/utils/request'

// 获取品论列表
export function getComments(data) {
  return request({
    url: '/store/v2/comments',
    method: 'get',
    params: data
  })
}
// 获取自定义评论列表
export function getCommentsVirtual(data) {
  return request({
    url: '/store/v2/comments/virtual',
    method: 'get',
    params: data
  })
}

// 设为精选
export function putSift(id) {
  return request({
    url: `/store/v2/comments/sift/${id}`,
    method: 'put'
  })
}
// 取消设为精选
export function putUnSift(id) {
  return request({
    url: `/store/v2/comments/un_sift/${id}`,
    method: 'put'
  })
}
// 隐藏品论
export function putHidden(id) {
  return request({
    url: `/store/v2/comments/hidden/${id}`,
    method: 'put'
  })
}
// 取消隐藏评论
export function putUnHidden(id) {
  return request({
    url: `/store/v2/comments/un_hidden/${id}`,
    method: 'put'
  })
}
// 删除评论
export function deleteComments(id) {
  return request({
    url: `/store/v2/comments/${id}`,
    method: 'delete'
  })
}
// 回复评论
export function postReply(data, id) {
  return request({
    url: `/store/v2//comments/reply/${id}`,
    method: 'post',
    data
  })
}
// 自定义评论增加
export function postAdd(data) {
  return request({
    url: `/store/v2/comments`,
    method: 'post',
    data
  })
}

// 随机头像
export function getUsername() {
  return request({
    url: '/store/v2/comments/template',
    method: 'get'
  })
}

// 评论数据
export function getIcon() {
  return request({
    url: '/store/v2/comments/data',
    method: 'get'
  })
}
