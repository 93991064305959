var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer-container" }, [
    _c("div", [
      _c("h3", { staticClass: "drawer-title" }, [
        _vm._v(_vm._s(_vm.$t("settings.title")))
      ]),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.theme")))]),
          _c("theme-picker", {
            staticStyle: {
              float: "right",
              height: "26px",
              margin: "-3px 8px 0 0"
            },
            on: { change: _vm.themeChange }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.tagsView")))]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.tagsView,
              callback: function($$v) {
                _vm.tagsView = $$v
              },
              expression: "tagsView"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.fixedHeader")))]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.fixedHeader,
              callback: function($$v) {
                _vm.fixedHeader = $$v
              },
              expression: "fixedHeader"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.sidebarLogo")))]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.sidebarLogo,
              callback: function($$v) {
                _vm.sidebarLogo = $$v
              },
              expression: "sidebarLogo"
            }
          })
        ],
        1
      ),
      _vm.isShowJob
        ? _c(
            "a",
            {
              staticClass: "job-link",
              attrs: { href: "http://www.shigukj.com/", target: "_blank" }
            },
            [
              _c("el-alert", {
                attrs: { title: "拾谷科技", type: "success", closable: false }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.lang === "zh"
        ? _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v("菜单支持拼音搜索")]),
              _c("el-switch", {
                staticClass: "drawer-switch",
                model: {
                  value: _vm.supportPinyinSearch,
                  callback: function($$v) {
                    _vm.supportPinyinSearch = $$v
                  },
                  expression: "supportPinyinSearch"
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }