var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "createPost-container" },
      [
        _c(
          "el-form",
          {
            ref: "postForm",
            staticClass: "form-container",
            attrs: {
              model: _vm.postForm,
              rules: _vm.rules,
              "label-width": "80px",
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "标题", prop: "title" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "标题" },
                  model: {
                    value: _vm.postForm.title,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "title", $$v)
                    },
                    expression: "postForm.title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "内容", prop: "content" } },
              [
                _c("Tinymce", {
                  ref: "editor",
                  attrs: { init: _vm.defaultSetting },
                  model: {
                    value: _vm.postForm.content,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "content", $$v)
                    },
                    expression: "postForm.content"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "排序", prop: "weigh" } },
              [
                _c("el-input", {
                  attrs: { type: "number", placeholder: "排序" },
                  model: {
                    value: _vm.postForm.weigh,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "weigh", $$v)
                    },
                    expression: "postForm.weigh"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "是否可见" } },
              [
                _c("el-switch", {
                  attrs: { "active-value": 0, "inactive-value": 1 },
                  model: {
                    value: _vm.postForm.is_hidden,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "is_hidden", $$v)
                    },
                    expression: "postForm.is_hidden"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.submitForm }
              },
              [_vm._v("确认")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }