var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.weChatLoading,
          expression: "weChatLoading"
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "weChat_bg" },
        [
          _c("div", [_vm._v("尚未注册小程序？点此快速注册小程序")]),
          _c(
            "el-button",
            {
              staticClass: "m-x-20",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.toPage()
                }
              }
            },
            [_vm._v("立即注册")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "m-t-20",
          attrs: {
            "label-position": "right",
            rules: _vm.rules,
            model: _vm.ruleForm,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "是否使用第三方授权" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.third },
                  model: {
                    value: _vm.accredit,
                    callback: function($$v) {
                      _vm.accredit = $$v
                    },
                    expression: "accredit"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _vm.accredit === 0
            ? _c(
                "el-form-item",
                { attrs: { label: "原始ID", prop: "mini_program_id" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.mini_program_id,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "mini_program_id", $$v)
                      },
                      expression: "ruleForm.mini_program_id"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.accredit === 0
            ? _c(
                "el-form-item",
                { attrs: { label: "小程序ID", prop: "mini_program_appid" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.mini_program_appid,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "mini_program_appid", $$v)
                      },
                      expression: "ruleForm.mini_program_appid"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.accredit === 0
            ? _c(
                "el-form-item",
                { attrs: { label: "小程序密钥", prop: "mini_program_secret" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.mini_program_secret,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "mini_program_secret", $$v)
                      },
                      expression: "ruleForm.mini_program_secret"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.accredit === 0
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.accredit === 1 && !_vm.third
            ? _c(
                "div",
                { staticClass: "choose flex" },
                [
                  _c("div", { staticClass: "left flex row-center" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/mini-program.png"),
                        alt: ""
                      }
                    })
                  ]),
                  _c("div", [_vm._v("选择已有小程序")]),
                  _c(
                    "el-button",
                    {
                      staticClass: "button-item",
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.submitLoading
                      },
                      on: { click: _vm.handleAuth }
                    },
                    [_vm._v(" 立即授权 ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.accredit === 1 && _vm.third
            ? _c("div", {}, [
                _c("div", { staticClass: "version flex row-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "light",
                            content: _vm.data.programs
                              ? _vm.data.programs.nickname
                                ? _vm.data.programs.nickname
                                : "---"
                              : "---",
                            placement: "top"
                          }
                        },
                        [
                          _c("div", { staticClass: "left flex row-center" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/mini-program.png"),
                                alt: ""
                              }
                            })
                          ])
                        ]
                      ),
                      _c("div", [
                        _c("div", { staticClass: "version-big" }, [
                          _vm._v("线上版本")
                        ]),
                        _c("div", [
                          _vm._v(
                            "版本号：" +
                              _vm._s(
                                _vm.data.version.release_info
                                  ? _vm.data.version.release_info
                                      .release_version
                                  : "---"
                              )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("div", {}, [
                    _c(
                      "div",
                      {
                        staticClass: "setting-url",
                        on: { click: _vm.handleAllocation }
                      },
                      [_vm._v("配置业务域名")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "setting-url",
                        on: { click: _vm.handleBindExperience }
                      },
                      [_vm._v("绑定体验者")]
                    )
                  ]),
                  _c("div", { staticClass: "m-r-10" }, [
                    _c(
                      "div",
                      {
                        staticClass: "setting-url",
                        on: { click: _vm.handleSetCategory }
                      },
                      [_vm._v("设置类目")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "setting-url",
                        on: { click: _vm.handleSetPrivacy }
                      },
                      [_vm._v("设置隐私协议")]
                    )
                  ])
                ]),
                _vm.check_status !== 0
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.getMiniInfoLoading,
                            expression: "getMiniInfoLoading"
                          }
                        ],
                        staticClass: "program-info"
                      },
                      [
                        _c("div", { staticClass: "program-tip" }, [
                          _c("div", { staticClass: "flex" }, [
                            _c("div", { staticClass: "number" }, [_vm._v("1")]),
                            _c("div", { staticClass: "flex" }, [
                              _vm._v("提交审核版本一般需要1-7天，请耐心等待")
                            ])
                          ]),
                          _c("div", { staticClass: "flex" }, [
                            _c("div", { staticClass: "number" }, [_vm._v("2")]),
                            _c("div", { staticClass: "flex" }, [
                              _vm._v("审核通过后自动发布小程序")
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "choose-version flex" }, [
                          _c("div", [_vm._v("选择版本")]),
                          _c(
                            "div",
                            _vm._l(_vm.getTemplateDataList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "15px" }
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: item.template_id },
                                      on: {
                                        change: function($event) {
                                          return _vm.handlePlugin(item)
                                        }
                                      },
                                      model: {
                                        value: _vm.is_plugin,
                                        callback: function($$v) {
                                          _vm.is_plugin = $$v
                                        },
                                        expression: "is_plugin"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.user_desc.split("\n")[0]
                                          ) +
                                          "(" +
                                          _vm._s(item.user_version) +
                                          ") "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "to-test button",
                            staticStyle: { "line-height": "38px" },
                            on: { click: _vm.toUpVersion }
                          },
                          [_vm._v("生成体验版本")]
                        ),
                        _vm.check_status == -1 || _vm.check_status == 3
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button",
                                attrs: { loading: _vm.submitLoading },
                                on: { click: _vm.handleSubmitForReview }
                              },
                              [_vm._v(" 提交审核 ")]
                            )
                          : _vm._e(),
                        _vm.check_status == 2
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button",
                                attrs: { loading: _vm.submitLoading },
                                on: { click: _vm.handleWithdrawalReview }
                              },
                              [_vm._v(" 审核中，撤回审核 ")]
                            )
                          : _vm._e(),
                        _vm.check_status == 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button",
                                attrs: { loading: _vm.submitLoading },
                                on: { click: _vm.handleSubmitForReview }
                              },
                              [_vm._v(" 审核失败，再次提交审核 ")]
                            )
                          : _vm._e(),
                        _vm.check_status == 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button",
                                attrs: { loading: _vm.submitLoading },
                                on: { click: _vm.handleRelease }
                              },
                              [_vm._v(" 审核通过，立即发布 ")]
                            )
                          : _vm._e(),
                        _vm.check_status == 1 && _vm.getMiniInfoData.reason
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#ff4544",
                                  margin: "8px 20px"
                                }
                              },
                              [
                                _vm._v(" 失败原因： "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getMiniInfoData.reason
                                    )
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.getMiniInfoLoading,
                            expression: "getMiniInfoLoading"
                          }
                        ],
                        staticClass: "program-info"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.data.programsInfo.qrcode_url,
                            referrerPolicy: "no-referrer"
                          }
                        }),
                        _c(
                          "div",
                          [
                            _c("div", { staticClass: "program-text" }, [
                              _vm._v(
                                "小程序名称：" +
                                  _vm._s(_vm.data.programsInfo.nick_name)
                              )
                            ]),
                            _c("div", { staticClass: "program-text" }, [
                              _vm._v(
                                "版本生成时间：" +
                                  _vm._s(
                                    _vm.getMiniInfoData.last
                                      ? _vm.getMiniInfoData.last.create_time
                                      : "---"
                                  )
                              )
                            ]),
                            _c("div", { staticClass: "program-text" }, [
                              _vm._v(
                                "发布时间 ：" +
                                  _vm._s(
                                    _vm.getMiniInfoData.last
                                      ? _vm.getMiniInfoData.last.release_time
                                      : "---"
                                  )
                              )
                            ]),
                            _vm.lastBug
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "button_green",
                                    on: { click: _vm.handleVersionFallback }
                                  },
                                  [_vm._v(" 版本回退 ")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticClass: "button",
                                    attrs: { loading: _vm.submitLoading },
                                    on: { click: _vm.handleRelease }
                                  },
                                  [_vm._v(" 手动发布 ")]
                                ),
                            _vm.is_new
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "button",
                                    attrs: { loading: _vm.submitLoading },
                                    on: {
                                      click: function($event) {
                                        _vm.check_status = -1
                                      }
                                    }
                                  },
                                  [_vm._v(" 发现新版本，去更新 ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "配置业务域名",
            visible: _vm.urlVisible,
            "close-on-click-modal": false,
            width: "785px"
          },
          on: {
            "update:visible": function($event) {
              _vm.urlVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ],
              staticStyle: { "padding-left": "30px" }
            },
            [
              _c(
                "div",
                { staticStyle: { "max-height": "400px", overflow: "auto" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        "label-position": "left"
                      }
                    },
                    _vm._l(_vm.domain, function(item, index) {
                      return _c("el-form-item", { key: index }, [
                        _c(
                          "label",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v(" 配置业务域名 "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "以https://开头，例:https://www.yuxiaojiu.com",
                                  placement: "top"
                                }
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "520px",
                                "margin-right": "20px"
                              },
                              model: {
                                value: _vm.domain[index],
                                callback: function($$v) {
                                  _vm.$set(_vm.domain, index, $$v)
                                },
                                expression: "domain[index]"
                              }
                            }),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top"
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    src: require("@/assets/images/del.png"),
                                    alt: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.delDomain(index)
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { color: "#999999", margin: "20px 0 10px" } },
                [_vm._v("最多添加20条域名")]
              ),
              _vm.domain.length < 20
                ? _c(
                    "el-button",
                    {
                      staticClass: "add-btn",
                      attrs: { size: "small", plain: "" },
                      on: { click: _vm.addUrl }
                    },
                    [_vm._v("+添加域名")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.urlLoading
                  },
                  on: { click: _vm.submitUrl }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "体验版本小程序码",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "360px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.previewLoading,
                  expression: "previewLoading"
                }
              ]
            },
            [
              _c("img", {
                staticStyle: { display: "block", margin: "15px auto 50px" },
                attrs: {
                  id: "code",
                  src: _vm.basQrcode ? _vm.basQrcode : "",
                  width: "180",
                  height: "180",
                  alt: ""
                }
              })
            ]
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "绑定体验者",
            visible: _vm.bindExperienceVisible,
            "close-on-click-modal": false,
            width: "785px",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.bindExperienceVisible = $event
            },
            close: _vm.cancelBindExperience
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-width": "120px",
                    "label-position": "right"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "体验者微信号" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "520px" },
                        model: {
                          value: _vm.bindExperienceValue,
                          callback: function($$v) {
                            _vm.bindExperienceValue = $$v
                          },
                          expression: "bindExperienceValue"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.urlLoading
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleWeChat(1)
                    }
                  }
                },
                [_vm._v("绑 定")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "danger",
                    loading: _vm.urlLoading
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleWeChat(0)
                    }
                  }
                },
                [_vm._v("解 绑")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.bindExperienceVisible = false
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "版本回退",
            visible: _vm.versionFallbackVisible,
            "close-on-click-modal": false,
            width: "380px"
          },
          on: {
            "update:visible": function($event) {
              _vm.versionFallbackVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-width": "90px",
                    "label-position": "right"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "版本回退" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择版本回退" },
                          model: {
                            value: _vm.versionFallback,
                            callback: function($$v) {
                              _vm.versionFallback = $$v
                            },
                            expression: "versionFallback"
                          }
                        },
                        _vm._l(_vm.versionFallbackData, function(item, index) {
                          return _c(
                            "el-option",
                            {
                              key: index,
                              attrs: {
                                label: item.user_desc.split("\n")[0],
                                value: item.app_version
                              }
                            },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(item.user_desc.split("\n")[0]) +
                                      " / " +
                                      _vm._s(item.user_version)
                                  )
                                ])
                              ])
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.versionFallbackVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.urlLoading
                  },
                  on: { click: _vm.handelOK }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }