var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.weChatLoading,
          expression: "weChatLoading"
        }
      ]
    },
    [
      !_vm.is_show
        ? _c(
            "div",
            { staticClass: "weChat_bg m-b-15" },
            [
              _c("div", [_vm._v("点此去注册小程序")]),
              _c(
                "el-button",
                {
                  staticClass: "m-x-20",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.toPage()
                    }
                  }
                },
                [_vm._v("注册")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c("el-card", { staticClass: "m-r-20" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [
                    _c("span", [_vm._v("场馆信息")]),
                    _c(
                      "el-button",
                      {
                        staticClass: "m-l-10",
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.refresh }
                      },
                      [_vm._v("刷新品牌二维码")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "information" }, [
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("品牌ID")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(_vm._s(_vm.$route.params.id))
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("品牌名称")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(_vm._s(_vm.appData.name))
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("地区")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(
                        _vm._s(
                          _vm.appData.location ? _vm.appData.location : "---"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("到期时间")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(
                            _vm.appData.expiration_time,
                            "YYYY-MM-DD"
                          )
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("品牌编码")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(
                        _vm._s(
                          _vm.appData.url
                            ? _vm.appData.url.split("=")[1]
                            : "---"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("品牌二维码")
                    ]),
                    _c(
                      "div",
                      { staticClass: "information_content" },
                      [
                        _c("el-image", {
                          staticClass: "m-r-10",
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src: _vm.appData.program_open_path,
                            "preview-src-list": [_vm.appData.program_open_path]
                          }
                        }),
                        _c("el-image", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src: _vm.appData.wechat_open_path,
                            "preview-src-list": [_vm.appData.wechat_open_path]
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("el-card", { staticClass: "m-t-20 m-r-20" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("服务器域名")])]
                ),
                _vm.is_showUrl
                  ? _c("div", [
                      _c("div", { staticClass: "information" }, [
                        _c("div", { staticClass: "information_line flex" }, [
                          _c("div", { staticClass: "information_name" }, [
                            _vm._v("request合法域名")
                          ]),
                          _c(
                            "div",
                            { staticClass: "information_content" },
                            _vm._l(_vm.networkList.RequestDomain, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "url" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "information" }, [
                        _c("div", { staticClass: "information_line flex" }, [
                          _c("div", { staticClass: "information_name" }, [
                            _vm._v("socket合法域名")
                          ]),
                          _c(
                            "div",
                            { staticClass: "information_content" },
                            _vm._l(_vm.networkList.WsRequestDomain, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "url" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "information" }, [
                        _c("div", { staticClass: "information_line flex" }, [
                          _c("div", { staticClass: "information_name" }, [
                            _vm._v("uploadFile合法域名")
                          ]),
                          _c(
                            "div",
                            { staticClass: "information_content" },
                            _vm._l(_vm.networkList.UploadDomain, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "url" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "information" }, [
                        _c("div", { staticClass: "information_line flex" }, [
                          _c("div", { staticClass: "information_name" }, [
                            _vm._v("downloadFile合法域名")
                          ]),
                          _c(
                            "div",
                            { staticClass: "information_content" },
                            _vm._l(_vm.networkList.DownloadDomain, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "url" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "information" }, [
                        _c("div", { staticClass: "information_line flex" }, [
                          _c("div", { staticClass: "information_name" }, [
                            _vm._v("udp合法域名")
                          ]),
                          _c(
                            "div",
                            { staticClass: "information_content" },
                            _vm._l(_vm.networkList.UploadDomain, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "url" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "information" }, [
                        _c("div", { staticClass: "information_line flex" }, [
                          _c("div", { staticClass: "information_name" }, [
                            _vm._v("tcp合法域名")
                          ]),
                          _c(
                            "div",
                            { staticClass: "information_content" },
                            _vm._l(_vm.networkList.TCPDomain, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "url" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  : _c("div", [_vm._v("数据为空")])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("基本信息")])]
                ),
                _vm.is_showId
                  ? _c("div", { staticClass: "information" }, [
                      _c("div", { staticClass: "information_line flex" }, [
                        _c("div", { staticClass: "information_name" }, [
                          _vm._v("AppID(小程序ID)")
                        ]),
                        _c("div", { staticClass: "information_content" }, [
                          _vm._v(_vm._s(_vm.dataSecretKey.mini_program_appid))
                        ])
                      ]),
                      _c("div", { staticClass: "information_line flex" }, [
                        _c("div", { staticClass: "information_name" }, [
                          _vm._v("原始ID")
                        ]),
                        _c("div", { staticClass: "information_content" }, [
                          _vm._v(_vm._s(_vm.dataSecretKey.mini_program_id))
                        ])
                      ]),
                      _vm.is_show
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("小程序名称")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [_vm._v(_vm._s(_vm.dataInfo.nick_name))]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("小程序头像")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "avatar" },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: _vm.dataInfo.head_img,
                                            "preview-src-list": [
                                              _vm.dataInfo.head_img
                                            ]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("小程序码")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px"
                                      },
                                      attrs: {
                                        src: _vm.dataInfo.qrcode_url,
                                        "preview-src-list": [
                                          _vm.dataInfo.qrcode_url
                                        ]
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("介绍")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [_vm._v(_vm._s(_vm.dataInfo.signature))]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("服务类目")
                                ]),
                                Object.keys(_vm.dataInfo.miniprograminfo)
                                  .length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "information_content" },
                                      [
                                        _vm.dataInfo.miniprograminfo.categories
                                          .length > 0
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.dataInfo.miniprograminfo
                                                      .categories[0].first
                                                  ) +
                                                  " > " +
                                                  _vm._s(
                                                    _vm.dataInfo.miniprograminfo
                                                      .categories[0].second
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _c("div", [_vm._v("---")])
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "information_content" },
                                      [_vm._v("---")]
                                    )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("主体信息")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content flex" },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.data.principal_name) +
                                          " "
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          "（" +
                                            _vm._s(
                                              _vm.dataInfo.principal_name
                                            ) +
                                            "）"
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("微信认证")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dataInfo.verify_type_info === -1
                                            ? "未认证"
                                            : "微信认证"
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  : _c("div", [_vm._v("数据为空")])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }