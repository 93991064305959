<template>
  <div class="app-container special-center">
    <el-form ref="ruleForm" :rules="rules" :model="form" label-position="top">
      <el-form-item label="角色名称" prop="role_name">
        <el-input v-model="form.role_name" placeholder="请输入角色名称" />
      </el-form-item>
      <el-form-item label="角色属性" prop="attribute_id">
        <el-select v-model="form.attribute_id" placeholder="请选择角色属性">
          <el-option v-for="(item,index) in attributeList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="form.sort" placeholder="排序(数字越小越靠前)" type="number" />
      </el-form-item>
      <el-form-item label="角色权限" prop="access_id">
        <el-checkbox @change="checkAll">全选</el-checkbox>
        <el-tree
          ref="tree"
          :data="accessList"
          :show-checkbox="true"
          node-key="id"
          highlight-current
          :expand-on-click-node="false"
          :props="accessProps"
          :default-expand-all="true"
          :check-strictly="true"
          :default-checked-keys="checked_keys"
          :indent="10"
          @check="nodeClick"
        />
      </el-form-item>

    </el-form>
    <div class="footer">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button @click="cancelCloseTag">取消</el-button>
    </div>
  </div>
</template>

<script>
import { getRoleAttribute, getAccess, getRoleInfo, setRoleInfo } from '@/api/store/management.js'
export default {
  data() {
    const accessValidator = (rule, value, callback) => {
      const value1 = this.$refs.tree.getCheckedKeys()
      if (value1.length === 0) {
        callback(new Error('不能为空'))
      } else {
        callback()
      }
    }
    return {
      form: {
        sort: 100
      },
      attributeList: [],
      accessProps: {
        label: 'title',
        children: 'children'
      },
      accessList: [],
      rules: {
        role_name: this.utils.validate(),
        attribute_id: this.utils.validate(),
        access_id: [{ required: true, validator: accessValidator }]
      },
      checked_keys: [],
      node_list: []// 节点ID列表
    }
  },
  mounted() {
    this.utils.setPageTitle(this.$route, '编辑角色', 1)
    this.utils.setTagsViewTitle(this.$route, '编辑角色')
    // 获取角色属性列表
    getRoleAttribute().then(res => {
      this.attributeList = res.data.list
    }).catch(() => {})
    // 获取权限列表
    getAccess().then(res => {
      this.accessList = res.data
      this.getNodeID(this.accessList)
    }).catch(() => {})
    getRoleInfo(this.$route.params.id, this.form).then(res => {
      this.form = res.data
      this.checked_keys = res.data.access_id.split(',')
    }).catch(() => {})

    // 给多选树设置默认值
    this.$refs.tree.setCheckedKeys(this.checked_keys)
  },
  methods: {
    // 获取所有节点ID（因为tree加了:check-strictly="true"导致不能选中父级从而全选，只能拿到所有id进行单点控制）
    getNodeID(items) {
      for (var i in items) {
        const item = items[i]
        this.node_list.push(item.id)
        if (item.children) {
          if (item.children.length !== 0) this.getNodeID(item.children)
        }
      }
    },
    // 全选
    checkAll(val) {
      this.$refs.tree.setCheckedKeys(val ? this.node_list : [])
    },
    nodeClick(currentObj, treeStatus) {
      // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
      const selected = treeStatus.checkedKeys.indexOf(currentObj.id) // -1未选中,>=0为选中

      // 选中
      if (selected !== -1) {
        // 子节点只要被选中父节点就被选中(需要选中父节点时候调用此方法)
        // this.selectedParent(currentObj)
        // 统一处理子节点为相同的勾选状态
        this.uniteChildSame(currentObj, true)
      } else {
        // 未选中 处理子节点全部未选中
        if (currentObj.children) {
          if (currentObj.children.length !== 0) {
            this.uniteChildSame(currentObj, false)
          }
        }
      }
    },

    // 统一处理子节点为相同的勾选状态
    uniteChildSame(treeList, isSelected) {
      this.$refs.tree.setChecked(treeList.id, isSelected)
      if (treeList.children) {
        for (let i = 0; i < treeList.children.length; i++) {
          this.uniteChildSame(treeList.children[i], isSelected)
        }
      }
    },
    // 统一处理父节点为选中
    selectedParent(currentObj) {
      const currentNode = this.$refs.tree.getNode(currentObj)
      if (currentNode.parent.key !== undefined) {
        this.$refs.tree.setChecked(currentNode.parent, true)
        this.selectedParent(currentNode.parent)
      }
    },
    cancelCloseTag() {
      this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ name: 's_management_role' })
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form['access_id'] = this.$refs.tree.getCheckedKeys().join()
          setRoleInfo(this.form.id, this.form).then(res => {
            this.$message.success('修改成功')
            this.$router.push({ name: 's_management_role' })
          }).catch(() => {})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
