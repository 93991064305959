var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "searchBox" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.listQueryParams,
                "label-width": "100px",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "日期筛选" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.listQueryParams.create_time,
                      callback: function($$v) {
                        _vm.$set(_vm.listQueryParams, "create_time", $$v)
                      },
                      expression: "listQueryParams.create_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "课程" } },
                [
                  _c("Select", {
                    attrs: { type: 6 },
                    on: { changeId: _vm.handleCurriculumID }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卡", prop: "sellingCardsID" } },
                [
                  _c("Select", {
                    attrs: { type: 7 },
                    on: { changeId: _vm.handleSellingCardsID }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "评分" } },
                [
                  _c("el-rate", {
                    staticStyle: { "padding-top": "6px" },
                    attrs: {
                      "show-text": "",
                      texts: _vm.texts,
                      colors: _vm.colors
                    },
                    model: {
                      value: _vm.listQueryParams.star_level,
                      callback: function($$v) {
                        _vm.$set(_vm.listQueryParams, "star_level", $$v)
                      },
                      expression: "listQueryParams.star_level"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "m-b-10",
                  attrs: {
                    icon: "el-icon-search",
                    loading: _vm.searchLoading,
                    type: "success"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v(" 搜索 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ssk_top tableColumn" },
        [
          _c(
            "el-input",
            {
              staticClass: "ssl_input",
              attrs: { placeholder: "搜索" },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleFilter($event)
                }
              },
              model: {
                value: _vm.listQueryParams.query,
                callback: function($$v) {
                  _vm.$set(_vm.listQueryParams, "query", $$v)
                },
                expression: "listQueryParams.query"
              }
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  loading: _vm.searchLoading
                },
                on: { click: _vm.handleFilter },
                slot: "append"
              })
            ],
            1
          ),
          _c(
            "router-link",
            { attrs: { to: { name: "s_commentsVirtual_add" } } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary", icon: "el-icon-plus" } },
                [_vm._v("添加评论")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableColumn" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.list, border: "", stripe: true }
            },
            [
              _c("el-table-column", {
                attrs: { label: "买家", width: "240" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "photo" },
                          [
                            _c("el-avatar", {
                              attrs: {
                                shape: "square",
                                size: 50,
                                src: scope.row.user
                                  ? scope.row.user.avatar
                                  : scope.row.virtual_info.avatar
                              }
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.user
                                    ? scope.row.user.nickname
                                    : scope.row.virtual_info.username
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "评论内容", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "div",
                            _vm._l(scope.row.tags, function(item, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: index,
                                  staticClass: "pl_elTag",
                                  attrs: { type: "info" }
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            1
                          ),
                          _c("div", [_vm._v(_vm._s(scope.row.content))]),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            _vm._l(scope.row.images, function(item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "imgUsername" },
                                [
                                  index <= 5
                                    ? _c("el-avatar", {
                                        attrs: {
                                          shape: "square",
                                          size: "small",
                                          src: item
                                        }
                                      })
                                    : _vm._e(),
                                  index === scope.row.images.length - 1
                                    ? _c("div", [
                                        _vm._v("共" + _vm._s(index + 1) + "张")
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          scope.row.reply.length != 0
                            ? _c("div", { staticClass: "cghf" }, [
                                _vm._v(
                                  "[场馆回复]: " +
                                    _vm._s(scope.row.reply[0].content)
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "星级", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-rate", {
                          attrs: {
                            disabled: "",
                            "show-text": "",
                            "score-template": "{value}",
                            texts: _vm.texts,
                            colors: _vm.colors
                          },
                          model: {
                            value: scope.row.star_level,
                            callback: function($$v) {
                              _vm.$set(scope.row, "star_level", $$v)
                            },
                            expression: "scope.row.star_level"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "favour_number", label: "点赞", width: "80" }
              }),
              _c("el-table-column", {
                attrs: { label: "设为精选" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949"
                          },
                          on: {
                            change: function($event) {
                              return _vm.is_siftOff(
                                scope.row.id,
                                scope.row.is_sift
                              )
                            }
                          },
                          model: {
                            value: scope.row.is_sift,
                            callback: function($$v) {
                              _vm.$set(scope.row, "is_sift", $$v)
                            },
                            expression: "scope.row.is_sift"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "隐藏评论" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949"
                          },
                          on: {
                            change: function($event) {
                              return _vm.is_hiddenOff(
                                scope.row.id,
                                scope.row.is_hidden
                              )
                            }
                          },
                          model: {
                            value: scope.row.is_hidden,
                            callback: function($$v) {
                              _vm.$set(scope.row, "is_hidden", $$v)
                            },
                            expression: "scope.row.is_hidden"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "交易商品" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.type === 10
                          ? _c("span", [
                              _vm._v("[课程]：" + _vm._s(scope.row.course.name))
                            ])
                          : _vm._e(),
                        scope.row.type === 20
                          ? _c("span", [
                              _vm._v(
                                "[" +
                                  _vm._s(
                                    scope.row.goods
                                      ? scope.row.goods.is_seckill
                                        ? "秒杀"
                                        : "售卡"
                                      : "---"
                                  ) +
                                  "]：" +
                                  _vm._s(scope.row.goods.name)
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "create_time", label: "评论时间" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleDeletePin(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除评论")]
                        ),
                        scope.row.reply.length != 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDeletePin(
                                      scope.row.reply[0].id
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 删除评论回复 ")]
                            )
                          : _vm._e(),
                        scope.row.reply.length == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "warning" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleHF(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v(" 回复评论 ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableData.count > 0,
                expression: "tableData.count > 0"
              }
            ],
            attrs: {
              total: _vm.tableData.count,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "请输入评论内容",
              rows: "5"
            },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleYes } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }