<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleTags"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
      :show-close="false"
      @open="handleOpen"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">选择标签</div>
          <div class="title_icon cursor" @click="handleClose(false)">
            <i class="el-icon-close" />
          </div>
        </div>
      </div>
      <div>
        <div class="font-title">默认标签</div>
        <el-tag v-for="(item, index) in tagsData.default" :key="index" :type="tags.indexOf(item) !== -1 ? 'primary' : 'info'" class="m-t-10 m-r-10 m-b-10 cursor" @click="handleChoice(item)">
          {{ item }}
        </el-tag>
        <div class="flex row-between">
          <div class="font-title">自定义标签</div>
          <el-button
            :type="is_edit ? 'success' : 'primary'"
            size="mini"
            icon="el-icon-edit"
            plain
            @click="is_edit = !is_edit"
          >
            {{ is_edit ? '完 成' : '编 辑' }}
          </el-button>
        </div>
        <el-tag
          v-for="item in tagsData.customize"
          :key="item.id"
          :type="tags.indexOf(item.name) !== -1 ? 'primary' : 'info'"
          :closable="is_edit"
          class="m-t-10 m-r-10 m-b-10 cursor"
          @close="handleEditTags(item.id, item.name)"
          @click="handleChoice(item.name)"
        >
          {{ item.name }}
        </el-tag>
        <el-button v-if="!is_edit" type="success" size="mini" icon="el-icon-plus" plain @click="handleAddTags">
          添 加
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="buttonLoading" @click="handleClose(false)">关 闭</el-button>
        <!-- <el-button :loading="buttonLoading" type="primary" @click="handleClose(true)">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getVenueLabelList, postVenueLabel, deleteVenueLabel } from '@/api/store/venues.js'

export default {
  props: {
    dialogVisibleTags: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      default: 0
    },
    selectTagsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tagsData: {
        customize: [], // 自定义标签
        default: [] // 默认标签
      },
      buttonLoading: false,
      is_edit: false,
      tags: []
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.tags = this.selectTagsList
      console.log('🚀 ~ handleOpen ~ this.tags:', this.tags)
      getVenueLabelList().then(res => {
        const { data } = res
        this.tagsData = data
      })
    },
    // 关闭的回调
    handleClose(is_show) {
      this.$nextTick(() => {
        this.$emit('tagsVisible', is_show ? this.tags : this.selectTagsList)
      })
    },
    // 新增标签
    handleAddTags() {
      this.$prompt('请输入标签名称', '自定义标签名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          const data = {
            name: value
          }
          postVenueLabel(data).then(res => {
            this.$message.success(res.msg)
            this.handleOpen()
          })
        })
        .catch(() => {})
    },
    // 删除标签
    handleEditTags(id, name) {
      this.$confirm('此操作将永久删除标签 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteVenueLabel(id).then(res => {
            const index = this.tags.indexOf(name)
            if (index !== -1) this.tags.splice(index, 1)
            this.$message.success(res.msg)
            this.handleOpen()
          })
        })
        .catch(() => {})
    },
    // 选择标签
    handleChoice(name) {
      const index = this.tags.indexOf(name)
      if (index !== -1) this.tags.splice(index, 1)
      else {
        if (this.tags.length > 5) return this.$message.error('最多选择六个标签')
        this.tags.push(name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.font-title {
  font-size: 16px;
  font-weight: bold;
}
</style>
