<template>
  <div class="app-container">
    <div class="filter-container clearfix">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item v-show="currentVenues.id == -1" label="登记场馆">
            <el-select v-model="listQueryParams.venues_id" placeholder="请选择" filterable clearable>
              <el-option v-for="item in venues_list" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="客户状态">
            <el-select v-model="listQueryParams.status" placeholder="请选择" filterable clearable>
              <el-option v-for="item in InquireConfig.status_list" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="客户级别">
            <el-select v-model="listQueryParams.level" placeholder="请选择" filterable clearable>
              <el-option v-for="item in InquireConfig.user_level" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="会籍顾问">
            <el-select v-model="listQueryParams.store_user_id" placeholder="请选择" filterable clearable>
              <el-option v-for="item in store_user_list" :key="item.id" :label="item.real_name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="来访时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <div class="m-b-10">
            <el-button icon="el-icon-search" :loading="searchLoading" type="success" @click="handleFilter">
              搜索</el-button>
            <el-button
              v-show="btn_access('s_inquire_export')"
              :disabled="tableData.list.length <= 0"
              type="danger"
              class=" m-l-10"
              icon="el-icon-download"
              :loading="exportLoading"
              @click="Download"
            >导出
            </el-button>
          </div>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索客询姓名|手机号" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>

      <el-button
        v-show="btn_access('s_inquire_add') && currentVenues.id != -1"
        type="primary"
        icon="el-icon-plus"
        class="filter-item m-l-10"
        @click="add_edit()"
      >添加</el-button>

      <el-button
        v-show="btn_access('s_inquire_track')"
        type="warning"
        icon="el-icon-chat-line-round"
        class="filter-item m-l-10"
        @click="toTrackListPage"
      >回访记录</el-button>
      <el-button
        v-show="currentVenues.id != -1"
        type="primary"
        plain
        icon="el-icon-s-tools"
        class="filter-item m-l-10 fr"
        @click="qrcode_visible = true"
      >
        客询配置
      </el-button>
    </div>
    <el-table ref="multipleTable" v-loading="loading" :data="tableData.list" row-key="id">
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="phone" label="电话" width="120" />
      <el-table-column prop="store_user.real_name" label="会籍顾问">
        <template slot-scope="{ row: { store_user } }" class="">
          <el-tag v-if="store_user" type="success">{{
            store_user.real_name
          }}</el-tag>
          <el-tag v-else type="info">暂无</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="source" label="来源">
        <template slot-scope="scope" class="">
          <span>{{ scope.row.source ? scope.row.source : "暂无" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="register_type_text" label="登记方式" />
      <el-table-column v-if="currentVenues.id == -1" prop="source" label="登记场馆">
        <template slot-scope="{ row: { venues } }" class="">
          <span>{{ venues.name ? venues.name : "暂无" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="用户级别" width="100">
        <template slot-scope="scope">
          <div class="flex">
            <inquiretag v-model="scope.row.level" />
            <my-popconfirm placement="top" width="200" @confirm="saveLevel(scope.row)">
              <p>选择客户级别</p>
              <div class="m-b-10">
                <el-select v-model="level_value" placeholder="请选择" filterable>
                  <el-option v-for="item in InquireConfig.user_level" :key="item" :label="item" :value="item" />
                </el-select>
              </div>
              <el-link v-if="btn_access('s_inquire_edit')" slot="reference" class="m-l-5" icon="el-icon-edit" @click="level_value = scope.row.level" />
            </my-popconfirm>
          </div>

        </template>
      </el-table-column>
      <el-table-column prop="status" label="用户状态" width="100">
        <template slot-scope="scope">
          <inquiretag v-model="scope.row.status" />
          <my-popconfirm placement="top" width="200" @confirm="saveStatus(scope.row)">
            <p>选择用户状态</p>
            <div class="m-b-10">
              <el-select v-model="status_value" placeholder="请选择" filterable>
                <el-option v-for="item in InquireConfig.status_list" :key="item" :label="item" :value="item" />
              </el-select>
            </div>
            <el-link v-if="btn_access('s_inquire_edit')" slot="reference" class="m-l-5" icon="el-icon-edit" @click="status_value = scope.row.status" />
          </my-popconfirm>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="来访时间" />
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <my-popconfirm @confirm="saveAdviser(scope.row.id)">
            <p>选择会籍顾问</p>
            <div class="m-b-20">
              <el-select v-model="adviser.store_user_id" placeholder="请选择" filterable clearable>
                <el-option v-for="item in store_user_list" :key="item.id" :label="item.real_name" :value="item.id" />
              </el-select>
            </div>
            <el-button
              v-show="currentVenues.id != -1 && btn_access('s_inquire_setadviser')"
              slot="reference"
              icon="el-icon-s-promotion"
              class="m-2"
              type="warning"
              @click="adviser.store_user_id = scope.row.store_user_id"
            >分配</el-button>

          </my-popconfirm>
          <el-button
            class="m-2"
            type="primary"
            icon="el-icon-info"
            @click="add_edit(scope.row.id)"
          >详情</el-button>
          <el-popconfirm title="删除后将无法恢复，确定吗？" @confirm="delInquire(scope.row.id)">
            <el-button
              v-show="currentVenues.id != -1 &&btn_access('s_inquire_del')"
              slot="reference"
              icon="el-icon-delete"
              class="m-2"
              type="danger"
            >删除</el-button>
          </el-popconfirm>
          <el-button icon="el-icon-notebook-2" class="m-2" @click="dialogTrack(scope.row.id)">回访</el-button>
          <el-button
            v-show="currentVenues.id != -1 && btn_access('s_inquire_setcard')"
            icon="el-icon-location"
            class="m-2"
            @click="dialogTrail(scope.row.id)"
          >跟踪</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 详情弹窗 -->
    <div v-if="isEmpty(userInfo)" class="userInfo">
      <el-dialog
        append-to-body
        :close-on-click-modal="false"
        :visible.sync="add_dialog.visible"
        :title="
          add_dialog.id
            ? add_dialog.is_edit
              ? '编辑客询'
              : '客询详情'
            : '添加客询'
        "
        class="dialog_w750"
      >
        <div v-if="add_dialog.id && !add_dialog.is_edit">
          <div class="m-b-10 text-right">
            <el-button
              v-show="btn_access('s_inquire_edit')"
              type="primary"
              icon="el-icon-info"
              @click="add_dialog.is_edit = true"
            >编辑</el-button>
          </div>
          <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item label="姓名">
              {{ userInfo.name }}
            </el-descriptions-item>
            <el-descriptions-item label="电话">{{
              userInfo.phone
            }}</el-descriptions-item>
            <el-descriptions-item label="来源">
              {{ userInfo.source ? userInfo.source : '--' }}
            </el-descriptions-item>
            <el-descriptions-item v-if="userInfo.venues" label="场馆">{{
              userInfo.venues.name
            }}</el-descriptions-item>
            <el-descriptions-item label="会籍顾问">
              {{ userInfo.store_user ? userInfo.store_user.real_name : "暂无" }}
            </el-descriptions-item>
            <el-descriptions-item label="是否办卡">
              {{ userInfo.has_card == 0 ? "否" : "是" }}
            </el-descriptions-item>
            <el-descriptions-item v-if="userInfo.has_card == 1" label="办卡金额">{{
              userInfo.card_money
            }}</el-descriptions-item>
            <el-descriptions-item v-if="userInfo.has_card == 1" label="办卡时间">
              <span v-if="userInfo.card_time == 0">未办卡</span>
              <span v-else>{{ userInfo.card_time }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="会员级别">
              <inquiretag v-model="userInfo.level" />
            </el-descriptions-item>
            <el-descriptions-item label="会员状态">
              <inquiretag v-model="userInfo.status" />
            </el-descriptions-item>
            <el-descriptions-item label="登记方式">
              {{ userInfo.register_type_text }}
            </el-descriptions-item>
            <el-descriptions-item label="创建时间">
              {{ userInfo.create_time }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div v-else>
          <el-form ref="ruleForm" :model="userInfo" :rules="rules" label-width="100px">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="userInfo.name" placeholder="请输入姓名" class="" />
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="userInfo.phone" placeholder="请输入手机号" class="" />
            </el-form-item>

            <el-form-item label="来源" prop="source">
              <el-select
                v-model="userInfo.source"
                placeholder="请选择来源"
                allow-create
                default-first-option
                filterable
                clearable
              >
                <el-option v-for="item in InquireConfig.label" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
            <el-form-item label="会籍顾问" prop="store_user_id">
              <el-select v-model="userInfo.store_user_id" placeholder="请选择" filterable clearable>
                <el-option v-for="item in store_user_list" :key="item.id" :label="item.real_name" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="是否办卡" prop="has_card">
              <el-switch v-model="userInfo.has_card" :active-value="1" :inactive-value="0" />
            </el-form-item>
            <el-form-item v-show="userInfo.has_card == 1" label="办卡金额" prop="has_card">
              <el-input v-model="userInfo.card_money" placeholder="办卡金额" class="" />
            </el-form-item>
            <el-form-item v-show="userInfo.has_card == 1" label="办卡时间" prop="has_card">
              <el-date-picker v-model="userInfo.card_time" value-format="yyyy-MM-dd" type="date" placeholder="办卡时间" />
            </el-form-item>
            <el-form-item label="用户级别" prop="status">
              <el-select v-model="userInfo.level" placeholder="请选择用户级别" filterable>
                <el-option v-for="item in InquireConfig.user_level" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
            <el-form-item label="用户状态" prop="status">
              <el-select v-model="userInfo.status" placeholder="请选择用户状态" filterable>
                <el-option v-for="item in InquireConfig.status_list" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
          </el-form>
          <el-button class="m-2" type="primary" :loading="submit_loading" @click="save">保存</el-button>
          <el-button class="m-2" @click="cancelAddDialog">取消</el-button>
        </div>
      </el-dialog>

      <!-- 客询配置二维码 -->
      <el-dialog
        title="客询配置"
        class="dialog_auto config"
        append-to-body
        modal-append-to-body
        :close-on-click-modal="false"
        :visible.sync="qrcode_visible"
        :center="true"
        @opened="handleOpened"
      >
        <div class="m-b-20 title">客询二维码</div>
        <InquireQrcodeSign />

        <div v-if="btn_access('s_inquire_config')">
          <el-divider />
          <div class="dashed m-y-10">
            <div class="m-b-10 title">通知状态</div>
            <div class="el-small m-b-20">客户登记成功将发送通知给店长，客户分配后将发送通知给指定会籍顾问</div>
            <el-row :gutter="20" type="flex" justify="space-between">
              <el-col :span="12">
                <el-row type="flex" justify="space-between">
                  <span>客户登记短信提醒</span>
                  <el-switch
                    v-model="InquireConfig.sms_inquire"
                    :active-value="1"
                    :inactive-value="0"
                    @change="saveInquireConfig()"
                  />
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row type="flex" justify="space-between">
                  <span>客户分配短信提醒</span>
                  <el-switch
                    v-model="InquireConfig.sms_inquire_assign"
                    :active-value="1"
                    :inactive-value="0"
                    @change="saveInquireConfig()"
                  />
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-row type="flex" justify="space-between">
                  <span>APP通知提醒</span>
                  <el-switch
                    v-model="InquireConfig.app"
                    :active-value="1"
                    :inactive-value="0"
                    @change="saveInquireConfig()"
                  />
                </el-row>
              </el-col>
            </el-row>
          </div>
          <el-divider />

          <div class="m-t-10 m-b-20 source">
            <div class="m-b-10 title">客户来源自定义</div>
            <div class="el-small">点击添加或者编辑修改来源，最多可添加5个（点击拖拽即可排序）</div>
            <div class="flex">
              <div ref="multipleTableSon" class="m-y-10 cursor_move">
                <el-tag
                  v-for="tag in InquireConfig.label"
                  :key="tag"
                  closable
                  :disable-transitions="false"
                  size="medium"
                  @close="handleClose(tag)"
                >
                  {{ tag }}
                </el-tag>
              </div>
              <div class="m-l-10">
                <el-input
                  v-if="inputVisible"
                  ref="saveTagInput"
                  v-model="inputValue"
                  class="input-new-tag"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
                />
                <el-button v-else v-show="InquireConfig.label.length < 5" class="button-new-tag" @click="showInput">+ 添加来源
                </el-button>
              </div>
            </div>
          </div>
        </div>

      </el-dialog>
    </div>
    <!-- 用户回访 -->
    <inquireTrack :id="track.id" :track-visible.sync="track.visible" />

    <!-- 跟踪 -->
    <el-dialog
      ref="trail"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="trail.visible"
      title="跟踪客询"
      class="dialog_w750"
    >
      <el-form ref="ruleForm" :model="userInfo" :rules="rules" label-width="100px">
        <el-form-item label="是否办卡" prop="has_card">
          <el-switch v-model="userInfo.has_card" :active-value="1" :inactive-value="0" />
        </el-form-item>
        <div v-if="userInfo.has_card == '1'">
          <el-form-item label="办卡金额" prop="card_money">
            <el-input v-model="userInfo.card_money" placeholder="办卡金额" class="" />
          </el-form-item>
          <el-form-item label="办卡时间" prop="card_time">
            <el-date-picker
              v-model="userInfo.card_time"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="办卡时间"
            />
          </el-form-item>
        </div>
        <el-form-item label="用户级别" prop="status">
          <el-select v-model="userInfo.level" placeholder="请选择用户级别" filterable>
            <el-option v-for="item in InquireConfig.user_level" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="用户状态" prop="status">
          <el-select v-model="userInfo.status" placeholder="请选择用户状态" filterable>
            <el-option v-for="item in InquireConfig.status_list" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitTrail">保存</el-button>
        <el-button type="text" @click="trail.visible = false">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { deepClone } from '@/utils'
import InquireQrcodeSign from './qrcode' // 二维码签到

import {
  getInquireList,
  getInquireInfo,
  addInquire,
  InquireCard,
  editInquire,
  InquireAdviser,
  delInquire,
  InquireConfig,
  editInquireConfig
} from '@/api/store/inquire.js'
import { getVenues } from '@/api/store/venues.js'
import { getStoreUser } from '@/api/store/management.js'

import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import myPopconfirm from '@/components/All/myPopconfirm' // 基于el-pagination的二级包
import inquireTrack from './tracking' // 用户回访
import inquiretag from '../components/inquiretag' // 用户回访

// 组件
import Sortable from 'sortablejs'

export default {
  components: { Pagination, InquireQrcodeSign, inquireTrack, myPopconfirm, inquiretag },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      apiData: { url: '/v1/course' },
      loading: false,
      searchLoading: false,
      store_user_list: {},
      userInfo: {
        store_user_id: '',
        name: '',
        phone: '',
        source: '',
        has_card: 0,
        card_time: '',
        card_money: '',
        level: '一般',
        status: '潜在',
        register_type: ''
      },
      infoVisible: false,
      add_dialog: {
        id: '',
        visible: false,
        is_edit: false
      },
      submit_loading: false,
      form: {
        venues_id: '',
        remark: '',
        area_code: '+86'
      },
      rules: {
        name: [{ required: true, message: '请填写真实姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请填写手机号', trigger: 'blur' }]
      },
      exportLoading: false,
      qrcode_visible: false,
      track: {
        id: 0,
        visible: false
      },
      adviser: {
        id: 0,
        store_user_id: '',
        visible: false
      },
      trail: {
        id: '',
        visible: false
      },
      InquireConfig: {
        label: [],
        user_level: [],
        status_list: [],
        channel: []
      },
      venues_list: [],
      level_value: '',
      status_value: '',
      inputVisible: false,
      inputValue: ''
    }
  },
  mounted() {
    this.getList()

    // 获取员工列表
    if (this.utils.empty(this.store_user_list)) {
      getStoreUser({ limit: 999 })
        .then(res => {
          this.store_user_list = res.data.list
        })
        .catch(() => { })
    }

    if (this.utils.empty(this.venues_list)) {
      this.getVenuesList()
    }
    // 获取配置
    if (this.utils.empty(this.InquireConfig.label)) {
      InquireConfig({ limit: 999 })
        .then(res => {
          this.InquireConfig = {
            sms_inquire: res.data.config.sms_inquire,
            sms_inquire_assign: res.data.config.sms_inquire_assign,
            app: res.data.config.app,
            label: res.data.config.label ? res.data.config.label : [],
            user_level: res.data.user_level,
            status_list: res.data.status_list,
            channel: res.data.channel
          }
          this.InquireConfig.label = this.InquireConfig.label.split(',')
        })
        .catch(() => { })
    }
  },
  methods: {
    // 获取场馆列表
    getVenuesList() {
      getVenues({ limit: 999 }).then(res => {
        this.venues_list = res.data.list
      })
    },
    // 保存配置
    saveInquireConfig(data) {
      return new Promise((resolve, reject) => {
        if (!data) {
          data = {
            config: {
              label: this.InquireConfig.label.join(','),
              sms_inquire: this.InquireConfig.sms_inquire,
              sms_inquire_assign: this.InquireConfig.sms_inquire_assign,
              app: this.InquireConfig.app
            }
          }
        }
        editInquireConfig(data).then(res => {
          resolve(res)
          this.$message.success(res.msg)
        }).catch(() => {
          reject()
        })
      })
    },

    // 标签配置
    handleClose(tag) {
      const label = deepClone(this.InquireConfig.label)
      label.splice(label.indexOf(tag), 1)
      const data = {
        config: {
          label: label.join(','),
          sms_inquire: this.InquireConfig.sms_inquire,
          sms_inquire_assign: this.InquireConfig.sms_inquire_assign,
          app: this.InquireConfig.app
        }
      }
      this.saveInquireConfig(data).then(res => {
        this.InquireConfig.label.splice(this.InquireConfig.label.indexOf(tag), 1)
      })
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        const label = deepClone(this.InquireConfig.label)
        label.push(inputValue)

        const data = {
          config: {
            label: label.join(','),
            sms_inquire: this.InquireConfig.sms_inquire,
            sms_inquire_assign: this.InquireConfig.sms_inquire_assign,
            app: this.InquireConfig.app
          }
        }
        this.saveInquireConfig(data).then(res => {
          this.InquireConfig.label.push(inputValue)
        })
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    // 显示跟踪弹窗
    dialogTrail(id) {
      // 打开单页
      getInquireInfo(id)
        .then(res => {
          this.userInfo = res.data
          // this.userInfo.card_time = this.userInfo.card_time ? this.$moment.unix(this.userInfo.card_time).format('yyyy-MM-DD') : ''
          this.$nextTick(() => {
            this.$refs.trail.$forceUpdate()
            this.trail.id = id
            this.trail.visible = true
          })
        })
        .catch(() => { })
    },
    submitTrail() {
      const data = {
        status: this.userInfo.status,
        level: this.userInfo.level,
        has_card: this.userInfo.has_card,
        card_money: this.userInfo.card_money,
        is_end: this.userInfo.is_end,
        card_time: this.userInfo.card_time,
        scene: 'card'
      }
      InquireCard(this.trail.id, data).then(res => {
        this.$message.success(res.msg)
        this.userInfo = this.$options.data.call(this).userInfo
        this.getList()
        this.trail.visible = false
      })
    },
    saveAdviser(id) {
      const data = {
        store_user_id: this.adviser.store_user_id
      }
      InquireAdviser(id, data).then(res => {
        this.$message.success(res.msg)
        this.getList()
      })
    },
    saveStatus(item) {
      // item.status = this.status_value;
      const data = {
        id: item.id,
        status: this.status_value,
        name: item.name,
        phone: item.phone,
        level: item.level,
        source: item.source,
        store_user_id: item.store_user_id,
        has_card: item.has_card,
        card_money: item.card_money,
        card_time: item.card_time
      }
      editInquire(item.id, data)
        .then(res => {
          this.$message.success(res.msg)
          this.getList()
        })
        .catch(e => {
        })
    },
    saveLevel(item) {
      // item.level = this.level_value;
      const data = {
        id: item.id,
        level: this.level_value,
        name: item.name,
        phone: item.phone,
        status: item.status,
        source: item.source,
        store_user_id: item.store_user_id,
        has_card: item.has_card,
        card_money: item.card_money,
        card_time: item.card_time
      }
      editInquire(item.id, data)
        .then(res => {
          this.$message.success(res.msg)
          this.getList()
        })
        .catch(e => {
        })
    },
    // 回访弹窗
    dialogTrack(id) {
      this.track.id = id
      this.track.visible = true
    },
    // 删除
    delInquire(id) {
      delInquire(id).then(res => {
        this.$message.success(res.msg)
        this.listQuery.page = 1
        this.getList()
      })
    },
    // 取消编辑客询弹窗
    cancelAddDialog() {
      if (this.add_dialog.id === '') {
        this.add_dialog.visible = false
      } else {
        this.add_dialog.is_edit = false
      }
    },
    Download() {
      const _this = this
      this.exportLoading = true
      const query = encodeURIComponent(this.setQuery())
      this.utils.handleDownload({
        url: '/store/v1/user_inquire/export?query=' + query,
        fileName: '客询列表.xlsx',
        baseURL: this.baseUrl
      })
      setTimeout(function() {
        _this.exportLoading = false
      }, 4000)
    },
    // 新增编辑保存
    save() {
      this.submit_loading = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = deepClone(this.userInfo)
          data.venues_id = this.utils.getVenues().id
          if (this.userInfo.id) {
            editInquire(this.userInfo.id, data)
              .then(res => {
                this.add_dialog.visible = false
                this.$message.success(res.msg)
                this.getList()
                this.submit_loading = false
              })
              .catch(e => {
                this.submit_loading = false
              })
          } else {
            addInquire(data)
              .then(res => {
                this.add_dialog.visible = false
                this.$message.success(res.msg)
                this.getList()
                this.submit_loading = false
              })
              .catch(e => {
                this.submit_loading = false
              })
          }
        } else {
          this.submit_loading = false
        }
      })
    },
    isEmpty(v) {
      return !this.utils.empty(v)
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },

    // 客询详情
    add_edit(id) {
      console.log(id)
      if (id) {
        // 打开单页
        getInquireInfo(id)
          .then(res => {
            this.add_dialog.is_edit = false
            this.userInfo = res.data
            // this.userInfo.card_time = this.userInfo.card_time ? this.$moment.unix(this.userInfo.card_time).format('yyyy-MM-DD') : ''
            this.add_dialog.id = id
            this.add_dialog.visible = true
          })
          .catch(() => { })
      } else {
        this.userInfo = this.$options.data.call(this).userInfo
        this.add_dialog.is_edit = true
        this.add_dialog.visible = true
        this.add_dialog.is_edit = true
      }
    },

    setQuery() {
      var querys = []
      if (this.listQueryParams.query) {
        querys.push({
          field: 'phone|name',
          type: 'like',
          key: this.listQueryParams.query
        })
      }
      if (
        this.listQueryParams.status !== '' &&
        this.listQueryParams.status != null
      ) {
        querys.push({
          field: 'status',
          key: this.listQueryParams.status
        })
      }
      if (this.listQueryParams.venues_id !== '' && this.listQueryParams.venues_id != null) querys.push({ field: 'venues_id', key: this.listQueryParams.venues_id })

      if (
        this.listQueryParams.level !== '' &&
        this.listQueryParams.level != null
      ) {
        querys.push({
          field: 'level',
          key: this.listQueryParams.level
        })
      }
      if (
        this.listQueryParams.store_user_id !== '' &&
        this.listQueryParams.store_user_id != null
      ) {
        querys.push({
          field: 'store_user_id',
          key: this.listQueryParams.store_user_id
        })
      }
      if (this.listQueryParams.create_time) {
        querys.push({
          field: 'update_time',
          type: 'between-time',
          key: this.listQueryParams.create_time
        })
      }

      return this.utils.getQueryParams(querys)
    },
    // 获取列表
    getList() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()
      console.log('🚀 ~ file: index.vue ~ line 885 ~ getList ~ data.query', data.query)

      getInquireList(data)
        .then(res => {
          // res.data.list = res.data.list.filter(item => {
          //   return item.venues
          // })
          // console.log(res.data)
          this.tableData = res.data
          this.searchLoading = false
          this.loading = false
        })
        .catch(() => { })
    },
    toTrackListPage(id) {
      this.$router.push({ name: 's_inquire_track', params: {}})
    },
    handleOpened() {
      const tableElement = this.$refs.multipleTableSon
      new Sortable(tableElement, {
        animation: 600, // 拖拽动画时间（以毫秒为单位）
        onChoose: this.handleChoose, // 选择元素时的回调函数
        onEnd: this.handleEnd, // 拖拽结束时的回调函数
        // draggable: '.weChat_button', // 指定哪些元素可以拖拽
        filter: '.button-new-tag' // 指定哪些元素不可以拖拽
      })
    },
    handleChoose() {},
    handleEnd(evt) {
      // 初始位置
      const oldIndex = evt.oldIndex
      // 更换后的位置
      const newIndex = evt.newIndex
      // 交换位置
      const data = this.InquireConfig.label
      data.splice(newIndex, 0, data.splice(oldIndex, 1)[0])
      this.saveInquireConfig()
      // this.InquireConfig.label = data
      // console.log(this.InquireConfig.label)
      // console.log(this.InquireConfig.label)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-avatar {
  background: none;
}

.config .title {
  font-weight: 600;
}

.el-small {
  color: #909399
}

.source {
  .el-tag {

    &+.el-tag {
      margin-left: 10px;
    }

    &+.button-new-tag {
      margin-left: 10px;
    }

    &+.input-new-tag {
      margin-left: 10px;

    }
  }

}

.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
.msg_box{
  width: 300px;
}
</style>
