var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.weChatLoading,
          expression: "weChatLoading"
        }
      ]
    },
    [
      !_vm.is_showId
        ? _c(
            "div",
            { staticClass: "weChat_bg m-b-15" },
            [
              _c("div", [_vm._v("尚未注册公众号？点此快速注册公众号")]),
              _c(
                "el-button",
                {
                  staticClass: "m-x-20",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.toPage()
                    }
                  }
                },
                [_vm._v("立即注册")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c("el-card", { staticClass: "m-r-20" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [
                    _c("span", [_vm._v("场馆信息")]),
                    _c(
                      "el-button",
                      {
                        staticClass: "m-l-10",
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.refresh }
                      },
                      [_vm._v("刷新品牌二维码")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "information" }, [
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("品牌ID")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(_vm._s(_vm.$route.params.id))
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("品牌名称")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(_vm._s(_vm.appData.name))
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("地区")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(
                        _vm._s(
                          _vm.appData.location ? _vm.appData.location : "---"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("到期时间")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(
                            _vm.appData.expiration_time,
                            "YYYY-MM-DD"
                          )
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("品牌编码")
                    ]),
                    _c("div", { staticClass: "information_content" }, [
                      _vm._v(
                        _vm._s(
                          _vm.appData.url
                            ? _vm.appData.url.split("=")[1]
                            : "---"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "information_line flex" }, [
                    _c("div", { staticClass: "information_name" }, [
                      _vm._v("品牌二维码")
                    ]),
                    _c(
                      "div",
                      { staticClass: "information_content" },
                      [
                        _c("el-image", {
                          staticClass: "m-r-10",
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src: _vm.appData.program_open_path,
                            "preview-src-list": [_vm.appData.program_open_path]
                          }
                        }),
                        _c("el-image", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src: _vm.appData.wechat_open_path,
                            "preview-src-list": [_vm.appData.wechat_open_path]
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("公开信息")])]
                ),
                _vm.is_showId
                  ? _c("div", { staticClass: "information" }, [
                      _c("div", { staticClass: "information_line flex" }, [
                        _c("div", { staticClass: "information_name" }, [
                          _vm._v("AppID(公众号ID)")
                        ]),
                        _c("div", { staticClass: "information_content" }, [
                          _vm._v(_vm._s(_vm.getPublicData.appid))
                        ])
                      ]),
                      _vm.is_showUrl
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("头像")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "avatar" },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: _vm.getPublicInfoData.head_img,
                                            "preview-src-list": [
                                              _vm.getPublicInfoData.head_img
                                            ]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("名称")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getPublicInfoData.nick_name)
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("二维码")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px"
                                      },
                                      attrs: {
                                        src: _vm.getPublicInfoData.qrcode_url,
                                        "preview-src-list": [
                                          _vm.getPublicInfoData.qrcode_url
                                        ],
                                        referrerpolicy: "no-referrer"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("介绍")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getPublicInfoData.signature)
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("认证情况")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getPublicInfoData
                                            .verify_type_info === -1
                                            ? "未认证"
                                            : "微信认证"
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "information_line flex" },
                              [
                                _c("div", { staticClass: "information_name" }, [
                                  _vm._v("主体信息")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "information_content" },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getPublicData.principal_name
                                          ) +
                                          " "
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.getPublicInfoData
                                                .principal_name
                                            ) +
                                            ")"
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  : _c("div", [_vm._v("无法获取公众号信息")])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }