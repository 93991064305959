<template>
  <!-- 班课会员管理 -->
  <div class="app-container">
    <!-- 搜索 -->
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="left">
        <!-- 选择班级 -->
        <el-form-item label="选择班级：">
          <el-select v-model="listQueryParams.class_id" placeholder="请选选择班级" clearable>
            <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <!-- 报班时间 -->
        <el-form-item label="报班时间：">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            align="right"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <!-- 操作类型 -->
        <el-form-item label="操作类型：">
          <el-select v-model="listQueryParams.status" placeholder="请选售卡状态" clearable>
            <el-option label="全部" value="" />
            <el-option label="正常" :value="1" />
            <el-option label="转班" :value="2" />
            <el-option label="过期" :value="3" />
            <el-option label="结课" :value="4" />
            <el-option label="结班" :value="5" />
            <el-option label="出班" :value="-1" />
          </el-select>
        </el-form-item>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </el-form>
    </div>
    <!-- 会员搜索 -->
    <div class="filter-item">
      <el-input
        v-model="listQueryParams.q"
        placeholder="昵称/姓名/手机号"
        style="width: 300px;"
        @keyup.enter.native="handleFilter"
      >
        <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
      </el-input>
    </div>
    <!-- 列表 -->
    <div class="tableColumn">
      <el-table v-loading="loading" :data="tableData.list" style="width: 100%" :stripe="true">
        <el-table-column label="姓名" align="center" min-width="120px">
          <template slot-scope="{ row }">
            <div v-if="!utils.empty(row.user)" class="flex col-center">
              <div>
                <el-avatar shape="square" :size="50" :src="row.user.avatar">
                  <img :src="errorMemberSrc()">
                </el-avatar>
              </div>
              <div class="p-l-10">
                <span>{{ getUserNames(row.user) }}</span><br>
                <span>{{ row.user.phone }}</span>
              </div>
            </div>
            <div v-else class="text-danger">用户信息缺失</div>
          </template>
        </el-table-column>
        <el-table-column label="封面图片" align="center">
          <template slot-scope="scope">
            <div class="cover">
              <img :src="scope.row.grade.cover" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="班级名称" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.grade.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="剩余课时" align="center">
          <template slot-scope="scope">
            <span>
              <el-tag>{{ scope.row.balance }}</el-tag>
              课时
            </span>
          </template>
        </el-table-column>
        <el-table-column label="已消耗课时" align="center">
          <template slot-scope="scope">
            <span>
              <el-tag>{{ scope.row.user_class_count }}</el-tag>
              课时
            </span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === -1">出班</el-tag>
            <el-tag v-if="scope.row.status === 1">正常</el-tag>
            <el-tag v-if="scope.row.status === 2">转班</el-tag>
            <el-tag v-if="scope.row.status === 3">过期</el-tag>
            <el-tag v-if="scope.row.status === 4">结课</el-tag>
            <el-tag v-if="scope.row.status === 5">结班</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="有效期" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.validity === 0">长期有效</span>
            <span v-else>{{ scope.row.validity }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleCheck(scope.row.id)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 对话框 -->
    <Dialog :id="id" :dialog="dialogVisible" @callback="callback" />
  </div>
</template>

<script>
// API
import { getDataList, getVipList } from '@/api/store/course'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Dialog from './dialog.vue'

export default {
  components: { Pagination, Dialog },
  data() {
    return {
      classList: [],
      dialogVisible: false,
      id: 0,
      searchLoading: false, // 搜索按钮加载
      loading: false, // 表格加载
      tableData: {
        list: [],
        count: 0
      } // 数据
    }
  },
  watch: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList() {
      this.loading = true

      getDataList().then(res => {
        this.classList = res.data.list
      })

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.q) {
        data.q = this.listQueryParams.q
      }
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.class_id) querys.push({ field: 'class_id', key: this.listQueryParams.class_id })
      if (this.listQueryParams.status) querys.push({ field: 'status', key: this.listQueryParams.status })

      data.query = this.utils.getQueryParams(querys)

      getVipList(data).then(res => {
        const data = res.data
        this.tableData = data
        this.loading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 查看详情
    handleCheck(id) {
      this.dialogVisible = true
      this.id = id
    },
    // 回调函数
    callback() {
      this.dialogVisible = false
      this.id = 0
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.tableColumn {
  margin-top: 20px;
}
.user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .user_img {
    margin-right: 10px;
  }
}
.cover {
  width: 150px;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
}
</style>
