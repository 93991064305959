var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, xs: 24 } },
            [_c("classAppointment")],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, xs: 24 } },
            [_c("cardConsumption")],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12, xs: 24 } }, [_c("topRanking")], 1),
          _c(
            "el-col",
            { attrs: { span: 12, xs: 24 } },
            [_c("pointsRanking")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }