<template>
  <div class="app-container">
    <div class="filter-container">
      <el-tabs v-model="activeName" @tab-click="handleFilter">
        <el-tab-pane label="全部" name="-1" />
        <el-tab-pane name="0">
          <template v-slot:label>
            <div>待签署（{{ tableData.statistic[0] ? tableData.statistic[0].status_count : '0' }}）</div>
          </template>
        </el-tab-pane>
        <el-tab-pane name="1">
          <template v-slot:label>
            <div>已签署（{{ tableData.statistic[1] ? tableData.statistic[1].status_count : '0' }}）</div>
          </template>
        </el-tab-pane>
        <el-tab-pane name="2">
          <template v-slot:label>
            <div>逾期未签（{{ tableData.statistic[2] ? tableData.statistic[2].status_count : '0' }}）</div>
          </template>
        </el-tab-pane>
        <el-tab-pane name="3">
          <template v-slot:label>
            <div>合同到期（{{ tableData.statistic[3] ? tableData.statistic[3].status_count : '0' }}）</div>
          </template>
        </el-tab-pane>
        <el-tab-pane name="4">
          <template v-slot:label>
            <div>作废（{{ tableData.statistic[4] ? tableData.statistic[4].status_count : '0' }}）</div>
          </template>
        </el-tab-pane>
        <el-tab-pane name="5">
          <template v-slot:label>
            <div>待添加签署人（{{ tableData.statistic[5] ? tableData.statistic[5].status_count : '0' }}）</div>
          </template>
        </el-tab-pane>
      </el-tabs>
      <!-- 筛选 -->
      <div class="searchBox">
        <el-form :inline="true" :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="筛选条件">
            <el-select v-model="listQueryParams.filter" placeholder="请选择" filterable clearable>
              <el-option label="标签" value="tag" />
              <el-option label="状态" value="status" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="排序"> -->
          <el-select v-model="listQueryParams.order" placeholder="请选择" filterable clearable>
            <el-option
              :label="listQueryParams.filter === 'tag' ? '按标签（升序）排序' : '按状态（升序）排序'"
              value="asc"
            />
            <el-option
              :label="listQueryParams.filter === 'tag' ? '按标签（降序）排序' : '按状态（降序）排序'"
              value="desc"
            />
          </el-select>
          <!-- </el-form-item> -->
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-l-20" @click="handleFilter">
            搜索
          </el-button>
        </el-form>
      </div>
      <!-- 搜索 -->
      <div class="filter-item">
        <el-input
          v-model="listQueryParams.name"
          placeholder="搜索 合同名称 | 会员名 | 手机号"
          style="width: 260px;"
          @keyup.enter.native="handleFilter"
        >
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <router-link :to="{ name: 's_contract_add', params: { type: 1 } }">
        <el-button class="filter-item m-l-10" type="primary" icon="el-icon-document-add">
          添加合同
        </el-button>
      </router-link>
      <!-- <router-link :to="{ name: 's_contract_template' }">
        <el-button class="filter-item m-l-10" type="success" icon="el-icon-document">
          合同模板（{{ tableData.template_count }}）
        </el-button>
      </router-link> -->
      <el-button class="filter-item m-l-10" type="info" icon="el-icon-setting" @click="handleSettings">
        合同设置
      </el-button>
    </div>
    <!-- 列表 -->
    <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData.list" row-key="id" :fit="true">
      <el-table-column label="合同名称" prop="name" />
      <el-table-column label="签署人">
        <template slot-scope="{ row }">
          <div v-if="row.user" class="flex col-center">
            <div>
              <el-avatar shape="square" :size="50" :src="row.user.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row.user) }}</span>
              <br>
              <span>{{ row.user.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">待添加签署人</div>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="{ row }">
          <el-tag :type="status[row.status].type">{{ status[row.status].label }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="日期">
        <template slot-scope="{ row }">
          {{ row.create_time.slice(0, 16) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template slot-scope="{ row }">
          <el-button class="m-2" @click="handleDetails(row.id, row.status)">详细信息</el-button>
          <!-- <el-button class="m-2" type="success" @click="handleDownload(row.file_path, row.name)">下载合同</el-button> -->
          <el-button v-if="row.status == 0 || row.status == 1" class="m-2" type="danger" @click="handleVoid(row.id)">
            合同作废
          </el-button>
          <el-button v-if="row.status == 4" class="m-2" type="warning" @click="handleRevokeVoid(row.id)">
            撤销作废
          </el-button>
          <el-dropdown v-if="row.status == 5">
            <el-button type="info">
              更多
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleEditContract(row.id)">编辑合同</el-dropdown-item>
              <el-dropdown-item @click.native="handleChangeName(row.id, row.name)">重命名</el-dropdown-item>
              <el-dropdown-item @click.native="handleDelete(row.id)">删除合同</el-dropdown-item>
              <el-dropdown-item @click.native="handleInitiateSign(row.id)">发起签署</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 详细信息 -->
    <detailsDialog
      :dialog-visible-contract="dialogVisibleContract"
      :contract-id="contractId"
      :contract-status="contractStatus"
      @closeVisible="dialogVisibleContract = false"
    />
    <!-- 合同设置 -->
    <settingsDialog :dialog-visible-settings="dialogVisibleSettings" @closeVisibleSettings="closeVisibleSettings" />
    <!-- 编辑合同 -->
    <editDialog
      :dialog-visible-edit-contract="dialogVisibleEditContract"
      :edit-contract-id="editContractId"
      :edit-type="editType"
      @editContractVisible="editContractVisible"
    />
    <!-- 签署合同 -->
    <initiateSignDialog
      :dialog-visible-initiate-sign="dialogVisibleInitiateSign"
      :edit-initiate-sign-id="editInitiateSignId"
      @editInitiateSignVisible="editInitiateSignVisible"
    />
  </div>
</template>

<script>
// API
import {
  getInquireList,
  cancelContract,
  revokeCancelContract,
  deleteContract,
  renameContract
} from '@/api/store/contract'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import detailsDialog from './components/detailsDialog'
import settingsDialog from './components/settingsDialog'
import editDialog from './components/editDialog'
import initiateSignDialog from './components/initiateSignDialog'

export default {
  components: { Pagination, detailsDialog, settingsDialog, editDialog, initiateSignDialog },
  data() {
    return {
      listQueryParams: {
        name: '',
        filter: 'status',
        order: 'desc'
      },
      // 列表
      tableData: {
        count: 0,
        legal_info: '',
        list: [],
        statistic: {},
        template_count: 0
      },
      tableLoading: false,
      searchLoading: false,
      activeName: '-1',
      status: [
        { label: '待签署', type: '' },
        { label: '已签署', type: 'success' },
        { label: '逾期未签', type: 'warning' },
        { label: '合同到期', type: 'danger' },
        { label: '作废', type: 'info' },
        { label: '待添加签署人', type: 'danger' }
      ],
      dialogVisibleContract: false,
      contractId: 0,
      contractStatus: 0,
      dialogVisibleSettings: false,
      dialogVisibleEditContract: false,
      editContractId: 0,
      editType: 1,
      dialogVisibleInitiateSign: false,
      editInitiateSignId: 0
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      if (this.listQueryParams.name) data.name = this.listQueryParams.name
      const search = Number(this.activeName)
      if (search > -1) data.search = search
      if (this.listQueryParams.filter) data.filter = this.listQueryParams.filter
      if (this.listQueryParams.order) data.order = this.listQueryParams.order

      getInquireList(data)
        .then(res => {
          const data = res.data
          this.tableData = data
        })
        .finally(() => {
          this.searchLoading = false
          this.tableLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 列表详情
    handleDetails(id, status) {
      this.dialogVisibleContract = true
      this.contractId = id
      this.contractStatus = status
    },
    // 合同作废
    handleVoid(id) {
      this.$prompt('请输入内容', '确定作废此合同', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          const data = {
            remark: value
          }
          cancelContract(id, data).then(() => {
            this.$message.success('作废成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    // 撤销合同作废
    handleRevokeVoid(id) {
      this.$confirm('是否确认撤销作废?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          revokeCancelContract(id).then(() => {
            this.$message.success('撤销作废成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    // 删除合同
    handleDelete(id) {
      this.$confirm('是否确认删除合同?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteContract(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    // 重属名
    handleChangeName(id, name) {
      this.$prompt('请输入内容', '重属名', {
        inputValue: name,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          const data = {
            name: value,
            is_customized: 1
          }
          renameContract(id, data).then(() => {
            this.$message.success('重属名成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    // 下载合同
    handleDownload(file_path, name) {
      this.utils.downloadPaf(file_path, `${name}合同模板`)
    },
    // 合同设置
    handleSettings() {
      this.dialogVisibleSettings = true
    },
    closeVisibleSettings() {
      this.dialogVisibleSettings = false
    },
    // 编辑合同
    handleEditContract(id) {
      this.dialogVisibleEditContract = true
      this.editContractId = id
      this.editType = 1
    },
    editContractVisible() {
      this.dialogVisibleEditContract = false
      this.getList()
    },
    // 发起签署
    handleInitiateSign(id) {
      this.dialogVisibleInitiateSign = true
      this.editInitiateSignId = id
    },
    editInitiateSignVisible() {
      this.dialogVisibleInitiateSign = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
