<template>
  <div class="setting-box">
    <el-form ref="form" :rules="rules" :model="form" label-width="120px" label-position="left">
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          预约时间设置
        </div>
        <div class="item_content m-y-10">
          <el-form-item label="预约方式">
            <el-radio-group v-model="form.group_subscribe.type">
              <el-radio :label="1">不支持会员自主预约</el-radio>
              <el-radio :label="2">自主预约，不限制预约时间</el-radio>
              <el-radio :label="3">自主预约，限制预约时间</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.group_subscribe.type > 2" label="预约时间">
            <el-radio-group v-model="form.group_subscribe.time_type">
              <div class="flex flex-col col-top row-left">
                <div>
                  <el-radio :label="1" class="m-b-5">
                    提前
                    <el-input v-model="form.group_subscribe.before_day_1" size="mini" placeholder="不限制" class="w-100 m-x-5" type="number" />
                    天，
                    <el-time-picker
                      v-model="form.group_subscribe.before_start_time_1"
                      is-range
                      arrow-control
                      range-separator="-"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                      format="HH:mm"
                      value-format="HH:mm"
                      class="w-250"
                      :clearable="false"
                    />
                    接受自主预约
                  </el-radio>
                </div>
                <div>
                  <el-radio :label="2" class="m-b-5">
                    提前
                    <el-input v-model="form.group_subscribe.before_day_2" size="mini" placeholder="不限制" class="w-100 m-x-5" type="number" />
                    天，
                    <el-time-picker v-model="form.group_subscribe.before_start_time_2" arrow-control format="HH:mm" value-format="HH:mm" class="w-100" :clearable="false" />
                    后接受自主预约
                  </el-radio>
                </div>
                <el-radio :label="3" class="m-b-5 flex">
                  <div class="flex">
                    <div>开课前</div>
                    <el-form-item prop="before_course_minute_1" class="w-100 m-x-5">
                      <el-input v-model="form.group_subscribe.before_course_minute_1" size="mini" class="w-100" type="number" />
                    </el-form-item>
                    <div>分钟不接受自主预约</div>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.group_subscribe.type > 1" label="预约截止时间" prop="before_course_minute_2">
            课程预约人数未满，会员需早于开课前
            <el-input v-model="form.group_subscribe.before_course_minute_2" size="mini" placeholder="0" class="w-120" />
            分钟自主预约
          </el-form-item>
        </div>
      </div>
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          排队候补设置
        </div>
        <div class="item_content m-y-10">
          <el-form-item label="排队候补">
            <el-radio-group v-model="form.group_queue.status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.group_queue.status" label="候补设置">
            <div class="flex flex-col col-top row-left">
              <div class="flex m-y-5">
                <div>最大排队人数</div>
                <div>
                  <el-form-item prop="max">
                    <el-input v-model="form.group_queue.max" size="mini" placeholder="0" class="w-100 m-x-5" type="number" />
                  </el-form-item>
                </div>
              </div>
              <div class="flex m-y-5">
                <div>开课前</div>
                <div>
                  <el-form-item prop="stop_queue_minute">
                    <el-input v-model="form.group_queue.stop_queue_minute" size="mini" placeholder="0" class="w-100 m-x-5" type="number" />
                  </el-form-item>
                </div>
                <div>分钟停止排队</div>
              </div>
              <div class="flex m-y-5">
                <div>开课前</div>
                <div>
                  <el-form-item prop="stop_alternate_minute">
                    <el-input v-model="form.group_queue.stop_alternate_minute" size="mini" placeholder="0" class="w-100 m-x-5" type="number" />
                  </el-form-item>
                </div>
                <div>分钟停止候补</div>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          签到设置
        </div>
        <div class="item_content m-y-10">
          <el-form-item label="自主签到">
            <el-radio-group v-model="form.group_sign.autonomously_status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.group_sign.autonomously_status" label="签到时间">
            <el-radio-group v-model="form.group_sign.autonomously_time_type">
              <div class="flex flex-col col-top row-left">
                <el-radio :label="1" class="m-y-5">课程当天，会员可随时签到</el-radio>
                <el-radio :label="2" class="m-y-5">课程当天，会员可在课程结束前签到</el-radio>
                <el-radio :label="3" class="flex">
                  <div class="flex row-left flex-wrap">
                    <div>课程当天，会员可在课程结束后</div>
                    <div>
                      <el-form-item prop="after_1">
                        <el-input v-model="form.group_sign.after_1" size="mini" placeholder="" class="w-100 m-x-5" type="number" />
                      </el-form-item>
                    </div>
                    <div>分钟内签到</div>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="扫码签到">
            <el-radio-group v-model="form.group_sign.scan_code_status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.group_sign.scan_code_status" label="签到时间">
            <el-radio-group v-model="form.group_sign.scan_code_time_type">
              <div class="flex flex-col col-top row-left">
                <el-radio :label="1" class="m-y-5">课程当天，会员可签到两小时内开始的课程</el-radio>
                <el-radio :label="2" class="m-y-5">课程当天，会员可在课程结束前扫码签到</el-radio>
                <el-radio :label="3" class="flex">
                  <div class="flex row-left flex-wrap">
                    <div>课程当天，会员可在课程开始前</div>
                    <div>
                      <el-form-item prop="before">
                        <el-input v-model="form.group_sign.before" size="mini" placeholder="" class="w-100 m-x-5" type="number" />
                      </el-form-item>
                    </div>
                    <div>分钟至课程结束后</div>
                    <div>
                      <el-form-item prop="after_2">
                        <el-input v-model="form.group_sign.after_2" size="mini" placeholder="" class="w-100 m-x-5" type="number" />
                      </el-form-item>
                    </div>
                    <div>分钟内扫码签到</div>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          预约取消设置
        </div>
        <div class="item_content m-y-10">
          <el-form-item label="自主取消">
            <el-radio-group v-model="form.group_cancel_subscribe.status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.group_cancel_subscribe.status" label="取消时间">
            <el-radio-group v-model="form.group_cancel_subscribe.time_type">
              <div class="flex flex-col col-top row-left">
                <el-radio :label="1" class="m-y-5 flex">
                  <div class="flex row-left flex-wrap">
                    <div>会员自主取消不晚于开课前</div>
                    <div>
                      <el-form-item prop="minute_1">
                        <el-input v-model="form.group_cancel_subscribe.minute_1" size="mini" placeholder="" class="w-100 m-x-5" type="number" />
                      </el-form-item>
                    </div>
                    <div>分钟</div>
                  </div>
                </el-radio>
                <el-radio :label="2" class="m-y-5 flex">
                  <div class="flex row-left flex-wrap">
                    <div>会员自主取消不晚于开课后</div>
                    <div>
                      <el-form-item prop="minute_2">
                        <el-input v-model="form.group_cancel_subscribe.minute_2" size="mini" placeholder="" class="w-100 m-x-5" type="number" />
                      </el-form-item>
                    </div>
                    <div>分钟</div>
                  </div>
                </el-radio>
                <el-radio :label="3" class="m-y-5 flex">
                  <div class="flex row-left flex-wrap">
                    <div>会员自主取消不晚于课程结束后</div>
                    <div>
                      <el-form-item prop="minute_3">
                        <el-input v-model="form.group_cancel_subscribe.minute_3" size="mini" placeholder="" class="w-100 m-x-5" type="number" />
                      </el-form-item>
                    </div>
                    <div>分钟</div>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          开课人数限制
        </div>
        <div class="item_content m-y-10">
          <el-form-item label="开课人数限制">
            <el-radio-group v-model="form.group_minimum_member.is_check">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="form.group_minimum_member.is_check" label="开课设置">
            <div class="flex">
              <div>满</div>
              <div>
                <el-form-item prop="lower_limit">
                  <el-input v-model="form.group_minimum_member.lower_limit" size="mini" placeholder="" class="w-100 m-x-5" type="number" />
                </el-form-item>
              </div>
              <div>人开课，人数不足提前</div>
              <div>
                <el-form-item prop="check_time">
                  <el-input v-model="form.group_minimum_member.check_time" size="mini" placeholder="" class="w-100 m-x-5" type="number" />
                </el-form-item>
              </div>
              <div>分钟自动取消课程</div>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          其他设置
        </div>
        <div class="item_content m-y-10">
          <el-form-item label="查看约课同学">
            <el-radio-group v-model="form.group_other.schoolmate_status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="约课人数显示">
            <el-radio-group v-model="form.group_other.show_type">
              <p><el-radio :label="1" class="m-y-5">微信端“约课”页面约课人数显示“还可预约几人”</el-radio></p>
              <p><el-radio :label="2" class="m-y-5">微信端“约课”页面约课人数显示“已约人数/容纳人数”</el-radio></p>
              <p><el-radio :label="3" class="m-y-5">微信端“约课”页面约课人数显示“预约满几人开课”</el-radio></p>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>

      <div class="footer">
        <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
        <el-button @click="returnPage()">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getSubscribeSetting, editSubscribeSetting } from '@/api/store/subscribe'
import { deepClone } from '@/utils'
export default {
  data() {
    var bcm1V = (rule, value, callback) => {
      if (this.form.group_subscribe.time_type === 3) {
        if (!/^\d+$/.test(this.form.group_subscribe.before_course_minute_1)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var bcm2V = (rule, value, callback) => {
      if (this.form.group_subscribe.type > 1) {
        if (!/^\d+$/.test(this.form.group_subscribe.before_course_minute_2)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var maxV = (rule, value, callback) => {
      if (this.form.group_queue.status) {
        if (!/^\d+$/.test(this.form.group_queue.max)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var sqmV = (rule, value, callback) => {
      if (this.form.group_queue.status) {
        if (!/^\d+$/.test(this.form.group_queue.stop_queue_minute)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var samV = (rule, value, callback) => {
      if (this.form.group_queue.status) {
        if (!/^\d+$/.test(this.form.group_queue.stop_alternate_minute)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var bfV = (rule, value, callback) => {
      if (this.form.group_sign.scan_code_status && this.form.group_sign.scan_code_time_type === 3) {
        if (!/^\d+$/.test(this.form.group_sign.before)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var af1V = (rule, value, callback) => {
      if (this.form.group_sign.autonomously_status && this.form.group_sign.autonomously_time_type === 3) {
        if (!/^\d+$/.test(this.form.group_sign.after_1)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var af2V = (rule, value, callback) => {
      if (this.form.group_sign.scan_code_status && this.form.group_sign.scan_code_time_type === 3) {
        if (!/^\d+$/.test(this.form.group_sign.after_2)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var m1V = (rule, value, callback) => {
      if (this.form.group_cancel_subscribe.status && this.form.group_cancel_subscribe.time_type === 1) {
        if (!/^\d+$/.test(this.form.group_cancel_subscribe.minute_1)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var m2V = (rule, value, callback) => {
      if (this.form.group_cancel_subscribe.status && this.form.group_cancel_subscribe.time_type === 2) {
        if (!/^\d+$/.test(this.form.group_cancel_subscribe.minute_2)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var m3V = (rule, value, callback) => {
      if (this.form.group_cancel_subscribe.status && this.form.group_cancel_subscribe.time_type === 3) {
        if (!/^\d+$/.test(this.form.group_cancel_subscribe.minute_3)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    return {
      form: {
        group_subscribe: {
          type: 1,
          time_type: 1,
          before_day_1: 1,
          before_day_2: 1,
          before_course_minute_1: 1,
          before_course_minute_2: 1,
          before_start_time_1: ['00:00', '23:59'],
          before_start_time_2: '',
          before_end_time: ''
        },
        group_queue: {
          status: 1,
          max: 5,
          stop_queue_minute: 60,
          stop_alternate_minute: ''
        },
        group_sign: {
          autonomously_status: 0,
          autonomously_time_type: 1,
          scan_code_status: 1,
          scan_code_time_type: 1,
          before: 5,
          after_1: 15,
          after_2: 15
        },
        group_cancel_subscribe: {
          status: 1,
          time_type: 1,
          minute_1: 120,
          minute_2: 120,
          minute_3: 120
        },
        group_other: {
          show_type: 1,
          schoolmate_status: 0
        },
        group_minimum_member: {
          is_check: 1,
          lower_limit: 1,
          check_time: 45
        }
      },
      list: {},
      rules: {
        before_course_minute_1: [{ validator: bcm1V, trigger: ['blur', 'change'] }],
        before_course_minute_2: [{ validator: bcm2V, trigger: ['blur', 'change'] }],
        max: [{ validator: maxV, trigger: ['blur', 'change'] }],
        stop_queue_minute: [{ validator: sqmV, trigger: ['blur', 'change'] }],
        stop_alternate_minute: [{ validator: samV, trigger: ['blur', 'change'] }],
        before: [{ validator: bfV, trigger: ['blur', 'change'] }],
        after_1: [{ validator: af1V, trigger: ['blur', 'change'] }],
        after_2: [{ validator: af2V, trigger: ['blur', 'change'] }],
        minute_1: [{ validator: m1V, trigger: ['blur', 'change'] }],
        minute_2: [{ validator: m2V, trigger: ['blur', 'change'] }],
        minute_3: [{ validator: m3V, trigger: ['blur', 'change'] }]
      },
      submitLoading: false
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function(val, oldVal) {}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    submit() {
      this.submitLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          const formData = deepClone(this.form)
          for (var i in this.list) {
            const item = this.list[i]
            delete this.list[i]['appid']
            delete this.list[i]['update_time']
            delete this.list[i]['create_time']
            console.log('🚀 ~ file: setting.vue ~ line 231 ~ submit ~ formData[item.name]', item.name, formData[item.name])
            this.list[i]['content'] = JSON.stringify(formData[item.name])
          }
          var data = this.list
          console.log('🚀 ~ file: setting.vue ~ line 232 ~ editSubscribeSetting ~ data', data)
          editSubscribeSetting(data)
            .then(res => {
              this.$message.success('保存成功')
              this.returnPage()
            })
            .finally(() => {
              this.submitLoading = false
            })
        } else {
          console.log('error submit!!')
          this.submitLoading = false
          return false
        }
      })
    },
    getList() {
      getSubscribeSetting({ limit: 999 }).then(res => {
        this.list = res.data.list
        this.form = res.data.res
        if (typeof this.form.group_subscribe.before_start_time_1 === 'string') {
          this.form.group_subscribe.before_start_time_1 = ['00:00', '23:59']
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item_title {
  border-left: 5px solid #3498db;
  // font-size: 16px;
}
.el-form-item--small.el-form-item {
  margin-bottom: 3px !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #606266 !important;
}
p {
  margin-bottom: 5px;
  margin-top: 5px;
}
</style>
