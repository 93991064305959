import request from '@/utils/request'

// 获取短信剩余
export function getSmsNum() {
  return request({
    url: `/store/v1/sms/index`,
    method: 'get'
  })
}

// 获取短信模板
export function getSmsTemplate(data) {
  return request({
    url: `/store/v1/sms_template`,
    method: 'get',
    params: data
  })
}

// 获取短信模板
export function getSms(data) {
  return request({
    url: `/store/v1/sms_templates`,
    method: 'get',
    params: data
  })
}

// 修改模板状态
export function editSmsTemplate(id, data) {
  return request({
    url: `/store/v1/sms_template/${id}`,
    method: 'put',
    data
  })
}

// 获取短信模板发送记录
export function getSmsLog(data) {
  return request({
    url: `/store/v1/sms_record`,
    method: 'get',
    params: data
  })
}

// 获取短信套餐
export function getSmsPlan(data) {
  return request({
    url: `/store/v1/sms_plan`,
    method: 'get',
    params: data
  })
}

// 购买短信套餐
export function buySmsPlan(data) {
  return request({
    url: `/store/v1/sms_plan`,
    method: 'post',
    data
  })
}

// 短信充值订单列表
export function getSmsRechargeOrder(data) {
  return request({
    url: `/store/v1/sms_recharge_order`,
    method: 'get',
    params: data
  })
}

// 短信充值订单详情
export function getSmsRechargeOrderInfo(order_id, data) {
  return request({
    url: `/store/v1/sms_recharge_order/${order_id}`,
    method: 'get',
    params: data
  })
}

