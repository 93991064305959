<template>
  <div>
    <el-submenu v-if="menuData.api || menuData.children" :index="characterStr(menuData.id)">
      <template slot="title">
        <div class="flex row-between">
          <div>
            <i class="el-icon-files" />
            <span class="characters">{{ menuData.name }}</span>
          </div>
          <div class="m-r-20">
            <el-link v-show="btn_access('system_api_route_edit')" type="primary" @click="handleEditClass(menuData.id)">编辑</el-link>
            &nbsp;
            <el-link v-show="btn_access('system_api_route_del')" type="danger" @click="handleDeleteClass(menuData.id)">删除</el-link>
          </div>
        </div>
      </template>
      <RecursiveMenu
        v-for="childMenu in menuData.children"
        :key="childMenu.id"
        :menu-data="childMenu"
        @componentsData="handleComponentsData"
        @refresh="handleRefresh"
      />
      <RecursiveMenu
        v-for="childMenu in menuData.api"
        :key="childMenu.id"
        :menu-data="childMenu"
        @componentsData="handleComponentsData"
        @refresh="handleRefresh"
      />
    </el-submenu>
    <el-menu-item v-else :index="characterStr(menuData.id)" class="father" @click="handleClick(menuData)">
      <span slot="title" class="characters">
        <i v-if="!!!menuData.hasOwnProperty('classify_id')" class="el-icon-files" />
        <el-tag v-for="(item, index) in method" v-show="menuData.hasOwnProperty('classify_id') && menuData.method === item.method" :key="index" :type="item.type" class="m-r-4 disable-transitions" size="mini">{{ item.method }}</el-tag>
        <span>{{ menuData.name }}</span>
      </span>
      <div v-if="!!!menuData.hasOwnProperty('classify_id')" class="son">
        <!-- <el-link type="success" @click="handleAddApi">新增接口</el-link> -->
        &nbsp;
        <el-link type="primary" @click="handleEditClass(menuData.id)">编辑</el-link>
        &nbsp;
        <el-link type="danger" @click="handleDeleteClass(menuData.id)">删除</el-link>
      </div>
    </el-menu-item>
    <!-- 组件 -->
    <apiRouteDialog
      :dialog-visible-api-route="dialogVisibleApiRoute"
      :type="type"
      :type-id="typeId"
      @apiRouteVisible="apiRouteVisible"
    />
    <apiRouteDialogList
      :dialog-visible-api-route-list="dialogVisibleApiRouteList"
      :type="type"
      :type-id="typeId"
      @apiRouteVisibleList="apiRouteVisibleList"
    />
  </div>
</template>

<script>
// API
import { deleteApiRouteCategory } from '@/api/admin/account.js'
// 组件
import apiRouteDialog from './apiRouteDialog'
import apiRouteDialogList from './apiRouteDialogList'

export default {
  name: 'RecursiveMenu',
  components: { apiRouteDialog, apiRouteDialogList },
  props: {
    menuData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataList: {},
      type: '',
      typeId: 0,
      dialogVisibleApiRoute: false,
      dialogVisibleApiRouteList: false,
      method: [
        { method: 'GET', type: '' },
        { method: 'POST', type: 'success' },
        { method: 'DELETE', type: 'danger' },
        { method: 'PUT', type: 'warning' },
        { method: 'PATCH', type: 'info' }
      ]
    }
  },
  methods: {
    handleClick(menuData) {
      this.$emit('componentsData', menuData)
    },
    handleComponentsData(val) {
      this.$emit('componentsData', val)
    },
    characterStr(str) {
      return str + ''
    },
    // 新增接口
    handleAddApi() {
      this.type = 'add'
      this.dialogVisibleApiRouteList = true
    },
    // 编辑分类
    handleEditClass(id) {
      this.type = 'edit'
      this.typeId = id
      this.dialogVisibleApiRoute = true
    },
    // 删除分类
    handleDeleteClass(id) {
      this.$confirm('此操作将永久删除该接口分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteApiRouteCategory(id).then(() => {
          this.apiRouteVisible()
          this.$message.success('删除成功!')
        })
      })
    },
    apiRouteVisible() {
      this.dialogVisibleApiRoute = false
      this.$emit('refresh')
    },
    apiRouteVisibleList() {
      this.dialogVisibleApiRouteList = false
      this.$emit('refresh')
    },
    handleRefresh() {
      this.$emit('refresh')
    },
    childMethod() {
      console.log('子组件的方法被调用了！')
    }
  }
}
</script>

<style lang="scss" scoped>
.father {
  position: relative;
  .son {
    position: absolute;
    top: 0px;
    right: 10px;
  }
}
.characters {
  font-size: 14px;
  font-weight: 600;
}

.el-menu-item {
  list-style-type: none !important; /* 去除默认的列表项小圆点 */
  margin-left: 0 !important; /* 可能需要去除默认的左侧间距 */
}
.disable-transitions {
  transition: none !important; // 去除tag标签的过度效果
}
</style>
