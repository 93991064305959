import request from '@/utils/request'

// 请假管理列表
export function getList(data) {
  return request({
    url: '/store/v2/user_membership_card/ask_log',
    method: 'get',
    params: data
  })
}
// 请假统计列表
export function getStatisticList(data) {
  return request({
    url: '/store/v2/user_membership_card/ask_log/statistic',
    method: 'get',
    params: data
  })
}
// 详情数据
export function getDataList(id) {
  return request({
    url: `/store/v2//user_membership_card/ask_log/${id}`,
    method: 'get'
  })
}

// 品牌资金日志
export function getFundLog(params) {
  return request({
    url: '/store/v1/app/money_record',
    method: 'get',
    params
  })
}

//  钱包信息
export function getWallet() {
  return request({
    url: '/store/v1/app/wallet',
    method: 'get'
  })
}

// 资金日志详情
export function getWalletDetails(id) {
  return request({
    url: `/store/v1/app/money_record/${id}`,
    method: 'get'
  })
}
