var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleAccount,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "40%",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleAccount = $event
            },
            open: _vm.handleOpen,
            close: _vm.handleClose
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "title_title" }, [
                _vm._v("拼团活动详情")
              ]),
              _c(
                "div",
                {
                  staticClass: "title_icon cursor",
                  on: { click: _vm.handleClose }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            ])
          ]),
          _c(
            "div",
            [
              _c("p", [_c("b", [_vm._v("活动信息")])]),
              _vm.teamworkData
                ? _c(
                    "el-descriptions",
                    {
                      staticClass: "margin-top",
                      attrs: {
                        column: 2,
                        border: "",
                        "label-style": { width: "100px" }
                      }
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { span: 2 } },
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 封面图 ")
                          ]),
                          _vm.teamworkData.cover
                            ? _c("el-image", {
                                staticStyle: {
                                  "min-width": "120px",
                                  height: "120px"
                                },
                                attrs: {
                                  src: _vm.teamworkData.cover,
                                  fit: "fill"
                                }
                              })
                            : _c("div", [_vm._v("暂无封面图")])
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { span: 2 } },
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 商品图 ")
                          ]),
                          _vm.teamworkData.goods_images &&
                          _vm.teamworkData.goods_images.length > 0
                            ? _c(
                                "div",
                                _vm._l(_vm.teamworkData.goods_images, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-image", {
                                    key: index,
                                    staticClass: "m-6",
                                    staticStyle: {
                                      "min-width": "100px",
                                      height: "100px"
                                    },
                                    attrs: {
                                      src: item,
                                      "preview-src-list": [item],
                                      fit: "fill"
                                    }
                                  })
                                }),
                                1
                              )
                            : _c("div", [_vm._v("暂无商品图")])
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 活动名称 ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.teamworkData.name) + " ")
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 活动价 ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.teamworkData.price) + " 元 ")
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 拼团时间 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatTime(_vm.teamworkData.start_time) +
                                  " - " +
                                  _vm.formatTime(_vm.teamworkData.end_time)
                              ) +
                              " "
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 自动成团人数 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s("" + _vm.teamworkData.people_number) +
                              " 人 "
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 可发起开团数 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                "" +
                                  (_vm.teamworkData.inventory === 0
                                    ? "无限制"
                                    : _vm.teamworkData.inventory + " 个")
                              ) +
                              " "
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 自动成团天数 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                "" +
                                  (_vm.teamworkData.validity_day === 0
                                    ? "无"
                                    : _vm.teamworkData.validity_day + "天")
                              ) +
                              " "
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 购买人 ")
                          ]),
                          _vm.teamworkData.buy_identity
                            ? _c(
                                "div",
                                [
                                  _vm.teamworkData.buy_identity.indexOf(
                                    "member"
                                  ) !== -1
                                    ? _c("el-tag", { staticClass: "m-x-2" }, [
                                        _vm._v("会员")
                                      ])
                                    : _vm._e(),
                                  _vm.teamworkData.buy_identity.indexOf(
                                    "visitor"
                                  ) !== -1
                                    ? _c("el-tag", { staticClass: "m-x-2" }, [
                                        _vm._v("团课")
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 包含卡 ")
                          ]),
                          _vm.teamworkData.card_combo_id
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.teamworkData.card_combo
                                      ? _vm.teamworkData.card_combo.name +
                                          " 组合卡"
                                      : "组合卡已被删除"
                                  )
                                )
                              ])
                            : _vm.teamworkData.membership_card_id
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.teamworkData.membership_card_id
                                        .length + " 张会员卡"
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm.teamworkData.membership_card_id &&
                      _vm.teamworkData.membership_card_id.length !== 0
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { span: 2 } },
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" 会员卡 ")
                              ]),
                              _vm._l(_vm.teamworkData.card, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "el-link",
                                  {
                                    key: index,
                                    staticClass: "color m-x-6",
                                    attrs: { target: "_blank" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleToPage(item.id)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "el-descriptions-item",
                        { attrs: { span: 2 } },
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 拼团详情 ")
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                "共 " +
                                  _vm.teamworkData.user_record_count +
                                  " 人参团 / 销售额 " +
                                  _vm.teamworkData.user_record_price_sum +
                                  " 元"
                              )
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("p", { staticClass: "font-16" }, [
                _c("b", [
                  _vm._v(" 已成团 "),
                  _c("b", { staticClass: "text-success" }, [
                    _vm._v(_vm._s(_vm.teamworkData.group_completed_count))
                  ]),
                  _vm._v(" 个 / 拼团中 "),
                  _c("b", { staticClass: "text-warning" }, [
                    _vm._v(_vm._s(_vm.teamworkData.group_undone_count))
                  ]),
                  _vm._v(" 个 / "),
                  _c("b", { staticClass: "text-success" }, [
                    _vm._v(
                      _vm._s(
                        "" +
                          (_vm.teamworkData.inventory === 0
                            ? "不限制开团个数"
                            : "可开团 " + _vm.teamworkData.inventory + " 个")
                      )
                    )
                  ])
                ])
              ]),
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "全部", name: "whole" }
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "拼团中", name: "inProgress" }
                  }),
                  _c("el-tab-pane", { attrs: { label: "已成团", name: "end" } })
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.TableLoading,
                      expression: "TableLoading"
                    }
                  ],
                  ref: "multipleTable",
                  attrs: {
                    data: _vm.teamList.list,
                    "row-key": "id",
                    "header-align": "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "团队名称", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.getUserNames(row.user)) + " 的拼团"
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "拼团人数", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("el-tag", { attrs: { type: "warning" } }, [
                              _c("span", [_vm._v(_vm._s(row.member.length))]),
                              _vm._v(" / "),
                              _c("span", [_vm._v(_vm._s(row.people_num))]),
                              _vm._v(" 人团 ")
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "拼团状态", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            row.status === 0
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("拼团中")
                                ])
                              : _vm._e(),
                            row.status === 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("已成团")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "建团时间",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                staticClass: "m-2",
                                on: {
                                  click: function($event) {
                                    return _vm.handleEventDetails(row.id)
                                  }
                                }
                              },
                              [_vm._v(" 详 情 ")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭")
              ])
            ],
            1
          )
        ]
      ),
      _c("Dialog", {
        attrs: {
          id: _vm.membership_card_id,
          venue: _vm.venuesId,
          dialog: _vm.dialogVisibleMembership
        },
        on: { Close: _vm.Close }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拼团活动详情",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.handleOpenSon
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.teamLoading,
                  expression: "teamLoading"
                }
              ]
            },
            [
              _c("p", [
                _c(
                  "b",
                  [
                    _c(
                      "el-tag",
                      {
                        staticClass: "m-r-6",
                        attrs: {
                          type:
                            _vm.teamDetails.status === 0 ? "warning" : "success"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.teamDetails.status === 0 ? "拼团中" : "已成团"
                            ) +
                            " "
                        )
                      ]
                    ),
                    _vm._v(
                      " ( 共" +
                        _vm._s(_vm.teamDetails.people_num) +
                        " 人 / 金额 " +
                        _vm._s(_vm.teamDetails.team_money_sum) +
                        " 元) "
                    )
                  ],
                  1
                )
              ]),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  attrs: {
                    data: _vm.teamDetails.member,
                    "row-key": "id",
                    "header-align": "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "用户" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            row
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "flex col-center cursor",
                                    on: {
                                      click: function($event) {
                                        return _vm.toUserInfoPage(row.user_id)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-avatar",
                                          {
                                            attrs: {
                                              shape: "square",
                                              size: 50,
                                              src: row.avatar
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: _vm.errorAvatarSrc(
                                                  row,
                                                  "img"
                                                )
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "p-l-10" },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.getUserNames(row)))
                                        ]),
                                        row.standing === 1
                                          ? _c(
                                              "el-tag",
                                              {
                                                staticClass: "m-l-6",
                                                attrs: { type: "warning" }
                                              },
                                              [_vm._v("团长")]
                                            )
                                          : _vm._e(),
                                        row.standing === 2
                                          ? _c(
                                              "el-tag",
                                              {
                                                staticClass: "m-l-6",
                                                attrs: { type: "success" }
                                              },
                                              [_vm._v("团员")]
                                            )
                                          : _vm._e(),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(row.phone ? row.phone : "--")
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "支付金额" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [_vm._v(_vm._s(row.price) + " 元")]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "create_time", label: "建团时间" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }