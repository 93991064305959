var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("s_orderRuleForCommonSetting"),
                  expression: "btn_access('s_orderRuleForCommonSetting')"
                }
              ],
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: "s_orderRuleForCommonSetting",
                    params: { type: "orderRuleForCommon" }
                  })
                }
              }
            },
            [_vm._v(" 编辑设置 ")]
          )
        ],
        1
      ),
      _vm._l(_vm.infoNode, function(item, index) {
        return _c("div", { key: index, staticClass: "m-y-10" }, [
          item.type == "alert"
            ? _c(
                "div",
                [
                  _c("el-alert", {
                    attrs: {
                      title: item.text,
                      closable: false,
                      "show-icon": "",
                      type: "warning"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          item.type == "table"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "p-y-8 p-x-10 title" }, [
                    _vm._v(" " + _vm._s(item.title) + " ")
                  ]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "show-header": false,
                        data: item.data,
                        stripe: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "title", label: "标题", width: "180" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "value", label: "值" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }