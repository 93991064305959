<template>
  <div>
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
        <el-form-item label="时间范围">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="订单类型">
          <el-select v-model="listQueryParams.order_type" placeholder="请选择" filterable clearable>
            <el-option v-for="(item, index) in order_list" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="listQueryParams.pay_status" placeholder="请选择" filterable clearable>
            <el-option v-for="(item, index) in status_list" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </div>
    </div>
    <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData.list" row-key="id">
      <el-table-column prop="number" label="订单号" />
      <el-table-column prop="order_type" label="订单名称">
        <template slot-scope="{row}">
          <span v-if="row.order_info">{{ row.order_info.name }}</span>
          <span v-else style="color: red;">订单信息丢失</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_type" label="订单类型">
        <template slot-scope="{row}">
          <span v-if="row.order_type === 20 && row.is_seckill === 0">售卡</span>
          <span v-else-if="row.order_type === 20 && row.is_seckill === 1">秒杀</span>
          <span v-else-if="row.order_type === 40">班课</span>
          <span v-else-if="row.order_type === 50">付费约课</span>
        </template>
      </el-table-column>
      <el-table-column prop="pay_price" label="金额" />
      <el-table-column prop="pay_status" label="订单状态">
        <template slot-scope="{row}">
          <el-tag v-if="row.pay_status === 0">待支付</el-tag>
          <el-tag v-else-if="row.pay_status === 1" type="success">交易成功</el-tag>
          <el-tag v-else-if="row.pay_status === 2" type="info">交易关闭</el-tag>
          <el-tag v-else-if="row.pay_status === 3" type="danger">已退款</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="下单时间">
        <template slot-scope="{row}">
          <span>{{ row.create_time | formatDate('YYYY-MM-DD HH:mm') }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
// API
import { getUserOrder } from '@/api/store/user.js'
// 组件
import Pagination from '@/components/Pagination' // 分页组件

export default {
  components: { Pagination },
  props: {
    userId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      status_list: [
        { label: '待支付', value: 0 },
        { label: '已支付', value: 1 },
        { label: '交易关闭', value: 2 },
        { label: '已退款', value: 3 }
      ],
      order_list: [
        { label: '售卡', value: 20 },
        { label: '秒杀', value: 21 },
        { label: '班课', value: 40 },
        { label: '付费约课', value: 50 }
      ],
      tableLoading: false,
      searchLoading: false
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      // var querys = []
      // if (this.listQueryParams.create_time) { querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time }) }
      // if (this.listQueryParams.pay_status !== '' && this.listQueryParams.pay_status != null) { querys.push({ field: 'pay_status', key: this.listQueryParams.pay_status }) }
      // if (this.listQueryParams.order_type !== '' && this.listQueryParams.order_type != null) { querys.push({ field: 'order_type', key: this.listQueryParams.order_type }) }
      // data.query = this.utils.getQueryParams(querys)
      if (this.listQueryParams.create_time) data.create_time = `${this.listQueryParams.create_time[0]}, ${this.listQueryParams.create_time[1]}`
      if (this.listQueryParams.order_type !== '' && this.listQueryParams.order_type != null) data.order_type = this.listQueryParams.order_type
      if (this.listQueryParams.pay_status !== '' && this.listQueryParams.pay_status != null) data.pay_status = this.listQueryParams.pay_status

      getUserOrder(this.userId, data).then(res => {
        this.tableData = res.data
        this.tableLoading = false
        this.searchLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
