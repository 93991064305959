var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.is_view
        ? _c("el-alert", {
            staticClass: "m-b-10",
            attrs: {
              title: "修改后可使用回车键进行保存",
              type: "info",
              "show-icon": "",
              closable: false
            }
          })
        : _vm._e(),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData.list } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "提醒设置",
              width: "220",
              fixed: "left"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "value", align: _vm.rowAlign, "min-width": "500" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm.is_view
                      ? [
                          _c(
                            "el-input",
                            {
                              staticClass: "edit-input",
                              staticStyle: { width: "300px" },
                              attrs: {
                                onkeyup:
                                  "value=value.replace(/^0+(\\d)|[^\\d]+/g,'')"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.rowChange(row)
                                }
                              },
                              model: {
                                value: row.value,
                                callback: function($$v) {
                                  _vm.$set(row, "value", $$v)
                                },
                                expression: "row.value"
                              }
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v(_vm._s(_vm.stringArr[row.en_name][0]))
                              ]),
                              _c("template", { slot: "append" }, [
                                _vm._v(_vm._s(_vm.stringArr[row.en_name][1]))
                              ])
                            ],
                            2
                          )
                        ]
                      : _c("span", [_vm._v(_vm._s(_vm.valueFormatter(row)))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm.is_view
                      ? [
                          _c("el-switch", {
                            staticClass: "m-r-10",
                            attrs: {
                              "active-color": "#13ce66",
                              "active-value": 1,
                              "inactive-value": 0
                            },
                            on: {
                              change: function($event) {
                                return _vm.rowChange(row)
                              }
                            },
                            model: {
                              value: row.status,
                              callback: function($$v) {
                                _vm.$set(row, "status", $$v)
                              },
                              expression: "row.status"
                            }
                          })
                        ]
                      : _c("span", [
                          _vm._v(_vm._s(row.status ? "已开启" : "已关闭"))
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "right" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    !_vm.is_view
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", icon: "el-icon-edit" },
                            on: { click: _vm.viewChange }
                          },
                          [_vm._v("编辑")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-back" },
                            on: { click: _vm.viewChange }
                          },
                          [_vm._v("返回")]
                        )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }