<template>
  <div v-loading="weChatLoading">
    <!-- <div class="weChat_bg">
      <div>尚未注册公众号？点此快速注册公众号</div>
      <el-button class="m-x-20" type="primary">立即注册</el-button>
    </div> -->
    <el-form ref="ruleForm" label-position="right" :rules="rules" :model="ruleForm" label-width="140px">
      <el-form-item label="是否使用第三方授权">
        <el-radio-group v-model="accredit" :disabled="third">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="accredit === 0" label="公众号ID" prop="wechat_open_appid">
        <el-input v-model="ruleForm.wechat_open_appid" />
      </el-form-item>
      <el-form-item v-if="accredit === 0" label="公众号密钥" prop="wechat_open_secret">
        <el-input v-model="ruleForm.wechat_open_secret" />
      </el-form-item>
      <el-form-item v-if="accredit === 0">
        <el-button type="primary" :loading="btnLoading" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
      <div v-if="accredit === 1 && !third" class="choose flex">
        <div class="left flex row-center">
          <img src="@/assets/images/mini-Publicaccount.png" alt="">
        </div>
        <div>选择已有公众号</div>
        <el-button class="button-item" type="primary" size="small" :loading="btnLoading" @click="handleAuth">
          立即授权
        </el-button>
      </div>
      <div v-if="accredit === 1 && third" class="">
        <div class="version flex row-between">
          <div class="flex">
            <el-tooltip
              class="item"
              effect="light"
              :content="
                data.officialAccounts
                  ? data.officialAccounts.nickname
                    ? data.officialAccounts.nickname
                    : '---'
                  : '---'
              "
              placement="top"
            >
              <div class="left flex row-center">
                <img src="@/assets/images/mini-Publicaccount.png" alt="">
              </div>
            </el-tooltip>
            <div>
              <!-- <div class="version-big">线上版本</div>
              <div>版本号：{{ data.version.release_info ? data.version.release_info.release_version : '---' }}</div> -->
              <div class="version-big">
                {{
                  data.officialAccounts
                    ? data.officialAccounts.nickname
                      ? data.officialAccounts.nickname
                      : '---'
                    : '---'
                }}
              </div>
            </div>
          </div>
          <div class="m-r-10">
            <!-- <div class="setting-url" @click="handleAllocation">配置业务域名</div> -->
            <div class="setting-url" @click="handleBindExperience">关联小程序</div>
          </div>
        </div>
        <!-- 公众号信息 -->
        <div class="program-info">
          <img :src="data.officialAccountsInfo.qrcode_url" referrerPolicy="no-referrer">
          <div>
            <div class="program-text">名称：{{ data.officialAccountsInfo.nick_name }}</div>
            <div class="program-text">介绍：{{ data.officialAccountsInfo.signature }}</div>
            <div class="program-text">
              认证情况：{{ data.officialAccountsInfo.verify_type_info === -1 ? '未认证' : '微信认证' }}
            </div>
            <div class="program-text">
              主体信息：{{ data.officialAccounts.principal_name }}
              <span>（{{ data.officialAccountsInfo.principal_name }}）</span>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 关联小程序 -->
    <el-dialog
      title="关联小程序"
      :visible.sync="bindExperienceVisible"
      :close-on-click-modal="false"
      width="785px"
      :before-close="handleClose"
    >
      <div class="flex">
        <el-form size="small" label-width="120px" label-position="right">
          <el-form-item label="小程序ID">
            <el-input v-model="bindExperienceValue" style="width: 520px;" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" :loading="urlLoading" @click="handleWeChat(1)">绑 定</el-button>
        <el-button size="small" type="danger" :loading="urlLoading" @click="handleWeChat(0)">解 绑</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getAuthCode, getAuthInfo, setPublicInfo, getPublicInfo, setMiniRelation, cancelMiniRelation } from '@/api/admin/merchants.js'

export default {
  components: {},
  data() {
    return {
      weChatLoading: false,
      btnLoading: false,
      third: false,
      accredit: 0,
      data: {},
      ruleForm: {
        wechat_open_appid: '',
        wechat_open_secret: ''
      },
      rules: {
        wechat_open_appid: [{ required: true, message: '请输入公众号ID', trigger: 'blur' }],
        wechat_open_secret: [{ required: true, message: '请输入公众号密钥', trigger: 'blur' }]
      },
      bindExperienceVisible: false,
      bindExperienceValue: '',
      urlLoading: false
    }
  },
  created() {
    // 获取当前路由参数
    if (this.$route.query.auth_code) {
      const data = {
        auth_code: this.$route.query.auth_code,
        auth_type: 1
      }
      getAuthCode(this.$route.params.id, data)
        .then(res => {
          this.$message.success(res.msg)
          // 完成之后去除路由参数
          this.$router.replace({ path: this.$route.path, query: {}})
        })
        .catch(() => {
          this.$router.replace({ path: this.$route.path, query: {}})
        })
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.weChatLoading = true
      getPublicInfo(this.$route.params.id).then(res => {
        const data = res.data
        this.data = data
        if (Object.keys(data.officialAccountsSecretKey).length > 0) {
          // data.officialAccountsSecretKey
          this.ruleForm.wechat_open_appid = data.officialAccountsSecretKey.wechat_open_appid
          this.ruleForm.wechat_open_secret = data.officialAccountsSecretKey.wechat_open_secret
        }
        if (Object.keys(data.officialAccounts).length > 0 && Object.keys(data.officialAccountsInfo).length > 0) {
          this.third = true
          this.accredit = 1
        }
        this.weChatLoading = false
      })
    },
    // 保存公众号配置
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.btnLoading = true
          setPublicInfo(this.$route.params.id, this.ruleForm).then(res => {
            this.$message.success('添加配置成功！')
            this.btnLoading = false
          })
        } else {
          return false
        }
      })
    },
    handleAuth() {
      this.btnLoading = true
      console.log(window.document.location.href)
      const data = {
        redirect_uri: window.document.location.href,
        auth_type: 1
      }
      getAuthInfo(data)
        .then(res => {
          this.$confirm('请在新窗口中完成微信小程序授权', '提示', {
            confirmButtonText: '已成功授权',
            cancelButtonText: '授权失败，重试',
            type: 'warning'
          })
            .then(() => {
              this.$message({
                type: 'success',
                message: '已完成授权!'
              })
              this.btnLoading = false
              this.getList()
            })
            .catch(() => {
              this.btnLoading = false
            })
          this.weChatUrl = res.data.url
          window.open(this.weChatUrl)
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
    // 关联小程序
    handleBindExperience() {
      this.bindExperienceVisible = true
    },
    handleClose(done) {
      done()
      this.bindExperienceValue = ''
    },
    handleWeChat(type) {
      if (!this.bindExperienceValue) {
        this.$message.error('请输入小程序ID')
        return false
      }
      this.$confirm(`确定要${type === 1 ? '绑定' : '解绑'}（${this.bindExperienceValue}）此小程序ID吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.urlLoading = true
          if (type === 1) {
            setMiniRelation(this.$route.params.id, { authorizer_appid: this.bindExperienceValue })
              .then(() => {
                this.$message.success('绑定成功')
                this.urlLoading = false
                this.bindExperienceVisible = false
                this.bindExperienceValue = ''
              })
              .finally(() => {
                this.urlLoading = false
              })
          } else {
            cancelMiniRelation(this.$route.params.id, { authorizer_appid: this.bindExperienceValue })
              .then(() => {
                this.$message.success('取消绑定成功')
                this.urlLoading = false
                this.bindExperienceVisible = false
                this.bindExperienceValue = ''
              })
              .finally(() => {
                this.urlLoading = false
              })
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.weChat_bg {
  width: 100%;
  height: 60px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: rgb(225, 240, 255);
  padding: 10px 24px;
}
.el-input--small {
  width: 40%;
}
</style>

<style scoped>
.form_box {
  margin-top: 10px;
  background-color: #fff;
}

.form_box_box {
  padding: 30px 20px;
}

.form_box_qx {
  padding: 24px 20px 30px;
}

.button-item {
  margin-top: 12px;
  padding: 9px 25px;
}

.el-tabs__header {
  padding: 0 20px;
  height: 56px;
  line-height: 56px;
  background-color: #fff;
  margin-bottom: 0;
}

.choose {
  /* margin-left: 10px; */
  width: 364px;
  height: 93px;
  border-radius: 6px;
  background-color: #e1f0ff;
  color: #3a3a3a;
}

.choose .left {
  width: 57px;
  height: 57px;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 18px;
}

.choose .left img {
  height: 38px;
  width: 38px;
}

.choose .button-item {
  margin-top: 0;
  margin-left: 50px;
}

.version {
  width: 414px;
  height: 110px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  /* background-image: linear-gradient(to right, #409eff, #40b9ff); */
  background-image: linear-gradient(to right, #00d3d3, #00c4c9);
  color: #fff;
  /* margin-left: 10px; */
  position: relative;
}

.version .left {
  width: 57px;
  height: 57px;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 18px;
}

.version .setting-url {
  /* position: absolute; */
  /* right: 24px; */
  /* top: 30px; */
  height: 28px;
  width: 104px;
  margin: 16px 0;
  line-height: 28px;
  text-align: center;
  border-radius: 12px;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  overflow: hidden;
}

.version-big {
  font-size: 16px;
  margin-bottom: 5px;
}

.program-info {
  /* margin-left: 10px; */
  width: 414px;
  border: 1px solid #e2e2e2;
  border-top: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 25px 0;
}

.program-info img {
  height: 148px;
  width: 148px;
  margin: 0 auto 25px;
  display: block;
}

.program-info div .program-text {
  padding: 0 20px;
  color: #606266;
  margin-top: 10px;
  font-size: 14px;
}

.program-info .program-tip div {
  color: #606266;
  height: 50px;
  line-height: 30px;
}

.program-info .program-tip .number {
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  margin-right: 22px;
  color: #409eff;
  background-color: #e1f0ff;
  margin-left: 22px;
}

.program-info .choose-version {
  margin: 10px 20px;
}

.program-info .choose-version > div:first-of-type {
  margin-right: 20px;
}

.program-info .button {
  width: 372px;
  text-align: center;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #409eff;
  margin-left: 20px;
  margin-top: 15px;
  cursor: pointer;
  background-color: #409eff;
  color: #fff;
}

.program-info .button_green {
  width: 372px;
  text-align: center;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #67c23a;
  margin-left: 20px;
  margin-top: 15px;
  cursor: pointer;
  background-color: #67c23a;
  color: #fff;
}

.program-info .to-test {
  margin-top: 30px;
  color: #409eff;
  background-color: #fff;
}

.el-dialog {
  min-width: 360px;
}

.add-btn.el-button.is-plain {
  border-color: #409eff;
  color: #409eff;
}
</style>
