import request from '@/utils/request'

// 品牌列表和品牌申请的接口

// 获取商户列表
export function getList(data) {
  return request({
    url: '/admin/v1/app',
    method: 'get',
    params: data
  })
}

export function getListTwo(data) {
  return request({
    url: '/admin/v1/app',
    method: 'get',
    params: data
  })
}
// 添加商户
export function addApp(data) {
  return request({
    url: '/admin/v1/app',
    method: 'post',
    data
  })
}

// 删除商户
export function appDel(id, data) {
  return request({
    url: `/admin/v1/app/${id}`,
    method: 'delete',
    data
  })
}

// 启/禁用商户
export function appDR(id, status_id) {
  return request({
    url: `/admin/v1/appStatus/${id}/status/${status_id}`,
    method: 'patch'
  })
}

// 获取商户详情
export function getAppDetails(id) {
  return request({
    url: `/admin/v1/app/${id}`,
    method: 'get'
  })
}

// 更改商户详情
export function editApp(id, data) {
  return request({
    url: `/admin/v1/app/${id}`,
    method: 'put',
    data
  })
}

// 刷新商户信息
export function refreshApp(id, data) {
  return request({
    url: `/admin/v1/app/refresh/${id}`,
    method: 'put',
    data
  })
}

// 商户导出
export function exportApp(data) {
  return request({
    url: `/admin/v1/appExport`,
    method: 'get',
    data
  })
}

// 商户运营情况
export function operateApp(id, data) {
  return request({
    url: `/admin/v1/app/operate/${id}`,
    method: 'get',
    params: data
  })
}
/**
 * 品牌列表end
 */

/**
 * 行业列表
 * @param {*} data
 * @returns
 */
export function industry(data) {
  return request({
    url: `/admin/v1/app/industry_list`,
    method: 'get',
    params: data
  })
}

/**
 * 品牌标签
 * @param {*} data
 * @returns
 */
export function tags(data) {
  return request({
    url: `/admin/v1/app/tags`,
    method: 'get',
    params: data
  })
}

/**
 * 申请start
 */

// 品牌申请列表
export function getApplyList(data) {
  return request({
    url: '/admin/v1/apply',
    method: 'get',
    params: data
  })
}

// 修改状态(通过、拒绝)
export function editApplyStatus(id, status, data) {
  return request({
    url: `/admin/v1/apply/${id}/status/${status}`,
    method: 'patch',
    data
  })
}

// 删除商户申请记录
export function delApply(id, data) {
  return request({
    url: `/admin/v1/apply/${id}`,
    method: 'delete',
    data
  })
}

// 添加商户跟踪记录
export function addAppTrack(data) {
  return request({
    url: `/admin/v1/app_track`,
    method: 'post',
    data
  })
}

// 获取品牌跟踪记录
export function appTrackList(appid, data) {
  return request({
    url: `/admin/v1/app_track/appid/${appid}`,
    method: 'get',
    data
  })
}

// 获取品牌场馆列表
export function appVenuesList(appid, data) {
  return request({
    url: `/admin/v1/app/${appid}/venues`,
    method: 'get',
    data
  })
}

// 账号列表
export function accountList(appid, params) {
  return request({
    url: `/admin/v1/store/employee/${appid}`,
    method: 'get',
    params
  })
}

// 切换超管
export function changeSuper(data) {
  return request({
    url: `/admin/v1/store/employee/set_super`,
    method: 'put',
    data
  })
}

// 客服列表
export function serviceList(params) {
  return request({
    url: `/admin/v1/customer`,
    method: 'get',
    params
  })
}

// 切换客服
export function changeService(appid, data) {
  return request({
    url: `/admin/v1/customer/cut/${appid}`,
    method: 'put',
    data
  })
}

// 获取拒绝原因
export function getReason() {
  return request({
    url: `/admin/v1/apply/data`,
    method: 'get'
  })
}
/**
 *
 *
 ** 微信小程序服务商
 *
 *
 */
// 写入小程序配置
export function setMiniConfig(data, appid) {
  return request({
    url: `/admin/v1/wxapp/set_wxapp_secret_key?aid=${appid}`,
    method: 'post',
    data
  })
}
// 授权信息接口
export function getAuthInfo(params) {
  return request({
    url: `/admin/v1/wxapp/authorizer`,
    method: 'get',
    params
  })
}
// 获取品牌小程序信息
export function getMiniInfo(appid) {
  return request({
    url: `/admin/v1/wxapp/info?aid=${appid}`,
    method: 'get'
  })
}
// 小程序注册列表
export function getAppletRegistrationList(params) {
  return request({
    url: `/admin/v1/fast_create`,
    method: 'get',
    params
  })
}
// 定制小程序列表
export function getListMiniPrograms(params) {
  return request({
    url: `/admin/v1/app/customize`,
    method: 'get',
    params
  })
}
// 授权成功返回回调事件
export function getAuthCode(appid, data) {
  return request({
    url: `/admin/v1/wxapp/action_authorizer?aid=${appid}`,
    method: 'post',
    data
  })
}
// 获取代码模板列表
export function getTemplateList(params) {
  return request({
    url: `/admin/v1/wxapp/template_list`,
    method: 'get',
    params
  })
}
// 上传代码
export function uploadCode(appid, data) {
  return request({
    url: `/admin/v1/wxapp/upload?aid=${appid}`,
    method: 'post',
    data
  })
}
// 获取体验版二维码
export function getMiniCode(appid) {
  return request({
    url: `/admin/v1/wxapp/preview?aid=${appid}`,
    method: 'get'
  })
}
// 提交审核
export function submitAudit(appid, data) {
  return request({
    url: `/admin/v1/wxapp/submit_review?aid=${appid}`,
    method: 'post',
    data
  })
}
// 撤回审核
export function recallAudit(appid) {
  return request({
    url: `/admin/v1/wxapp/un_do_code_audit?aid=${appid}`,
    method: 'put'
  })
}
// 获取最后一次审核记录
export function getLastRecord(appid) {
  return request({
    url: `/admin/v1/wxapp/get_last_audit?aid=${appid}`,
    method: 'get'
  })
}
// 发布
export function release(appid) {
  return request({
    url: `/admin/v1/wxapp/release?aid=${appid}`,
    method: 'post'
  })
}
// 快速注册小程序
export function quickRegister(data) {
  return request({
    url: `/admin/v1/fast_create`,
    method: 'post',
    data
  })
}
// 申请注册记录
export function registerRecord(appid, params) {
  return request({
    url: `/admin/v1/fast_create/log?aid=${appid}`,
    method: 'get',
    params
  })
}
// 获取业务域名
export function getDomain(appid) {
  return request({
    url: `/admin/v1/wxapp/business_domain?aid=${appid}`,
    method: 'get'
  })
}
// 设置业务域名
export function setDomain(appid, data) {
  return request({
    url: `/admin/v1/wxapp/business_domain?aid=${appid}`,
    method: 'post',
    data
  })
}
// 绑定体验者
export function bindTester(appid, data) {
  return request({
    url: `/admin/v1/wxapp/bind_tester?aid=${appid}`,
    method: 'post',
    data
  })
}
// 解绑体验者
export function unbindTester(appid, data) {
  return request({
    url: `/admin/v1/wxapp/bind_tester?aid=${appid}`,
    method: 'delete',
    data
  })
}
// 版本回退查询
export function getBackRecord(appid) {
  return request({
    url: `/admin/v1/wxapp/un_release?aid=${appid}`,
    method: 'get'
  })
}
// 版本回退提交
export function backRecord(appid, data) {
  return request({
    url: `/admin/v1/wxapp/un_release?aid=${appid}`,
    method: 'post',
    data
  })
}
/**
 *
 *
 ** 微信支付
 *
 *
 */
// 支付配置信息
export function getPayConfig(appid) {
  return request({
    url: `/admin/v1/pay?aid=${appid}`,
    method: 'get'
  })
}
// 服务商列表
export function getProviderList() {
  return request({
    url: `/admin/v1/pay/service_list`,
    method: 'get'
  })
}
// 支付配置写入
export function setPayConfig(data, appid) {
  return request({
    url: `/admin/v1/pay/set_pay_config?aid=${appid}`,
    method: 'post',
    data
  })
}
/**
 *
 *
 ** 微信公众号
 *
 *
 */
// 公众号信息
export function getPublicInfo(appid) {
  return request({
    url: `/admin/v1/wechat?aid=${appid}`,
    method: 'get'
  })
}
// 公众号配置
export function setPublicInfo(appid, data) {
  return request({
    url: `/admin/v1/wechat/set_secret_key?aid=${appid}`,
    method: 'post',
    data
  })
}
// 公众号关联小程序
export function setMiniRelation(appid, data) {
  return request({
    url: `/admin/v1/wechat/link_miniprogram?aid=${appid}`,
    method: 'post',
    data
  })
}
// 公众号取消关联小程序
export function cancelMiniRelation(appid, data) {
  return request({
    url: `/admin/v1/wechat/unlink_miniprogram?aid=${appid}`,
    method: 'post',
    data
  })
}
// 新增菜单
export function setMenu(appid, data) {
  return request({
    url: `/admin/v1/wechat/menu?aid=${appid}`,
    method: 'post',
    data
  })
}
// 更新菜单
export function updateMenu(appid, id, data) {
  return request({
    url: `/admin/v1/wechat/menu/${id}?aid=${appid}`,
    method: 'put',
    data
  })
}
// 更新小程序信息
export function updateMiniInfo(appid) {
  return request({
    url: `/admin/v1/wxapp/info?aid=${appid}`,
    method: 'put'
  })
}
// 菜单详情
export function getMenuDetail(id) {
  return request({
    url: `/admin/v1/wechat/menu/${id}`,
    method: 'get'
  })
}
// 菜单列表
export function getMenu(appid, params) {
  return request({
    url: `/admin/v1/wechat/menu?aid=${appid}`,
    method: 'get',
    params
  })
}
// 删除菜单
export function clearMenu(id) {
  return request({
    url: `/admin/v1/wechat/menu/${id}`,
    method: 'delete'
  })
}

// 获取素材列表
export function getMaterialList(appid, params) {
  return request({
    url: `/admin/v1/wechat/material?aid=${appid}`,
    method: 'get',
    params
  })
}
// 获取素材详情
export function getMaterialDetail(appid, params) {
  return request({
    url: `/admin/v1/wechat/material/info?aid=${appid}`,
    method: 'get',
    params
  })
}
// 发布菜单
export function postRelease(id) {
  return request({
    url: `/admin/v1/wechat/menu/${id}/release`,
    method: 'post'
  })
}
// 撤回菜单
export function postRecall(id) {
  return request({
    url: `/admin/v1/wechat/menu/cancel/${id}`,
    method: 'delete'
  })
}
// 获取草稿箱
export function getDrafts() {
  return request({
    url: `/admin/v1/wxapp/drafts`,
    method: 'get'
  })
}
// 添加草稿箱到模板库
export function postDrafts(data) {
  return request({
    url: `/admin/v1/wxapp/to_template`,
    method: 'post',
    data
  })
}
/**
 *
 *
 ** 微信服务商平台
 *
 *
 */
// 查询可分配的sku表
export function getSkus() {
  return request({
    url: `/admin/v1/wechat/fuwu`,
    method: 'get'
  })
}
// 订单列表
export function getOrderList(params) {
  return request({
    url: `/admin/v1/wechat/fuwu/order`,
    method: 'get',
    params
  })
}
// 订单详情
export function getOrderDetail() {
  return request({
    url: `/admin/v1/wechat/fuwu/order/DD3046963932567207942`,
    method: 'get'
  })
}
// 分配志愿包
export function setSku(appid, data) {
  return request({
    url: `/admin/v1/wechat/fuwu/assign?aid=${appid}`,
    method: 'post',
    data
  })
}
// 取消分配
export function cancelSku(id) {
  return request({
    url: `/admin/v1/wechat/fuwu/cancel/${id}`,
    method: 'delete'
  })
}
// 分配记录列表
export function getSkuList(appid, params) {
  return request({
    url: `/admin/v1//wechat/fuwu/record?aid=${appid}`,
    method: 'get',
    params
  })
}
// 设置类目
export function setCategory(appid, data) {
  return request({
    url: `/admin/v1/wxapp/add_category?aid=${appid}`,
    method: 'post',
    data
  })
}
// 设置隐私协议
export function setPrivacy(appid, data) {
  return request({
    url: `/admin/v1/wxapp/set_privacy_setting?aid=${appid}`,
    method: 'post',
    data
  })
}

// 增加有效期
export function addValidity(appid, data) {
  return request({
    url: `/admin/v1/app/validity/${appid}`,
    method: 'put',
    data
  })
}

// 限制导出
export function limitExport(appid) {
  return request({
    url: `/admin/v1/app/limit_export/${appid}`,
    method: 'post'
  })
}

// 解除限制
export function removeLimit(appid) {
  return request({
    url: `/admin/v1/app/limit_export/${appid}`,
    method: 'delete'
  })
}
