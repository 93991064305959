import utils from '@/utils/utils.js'

export function encapsulation(new_obj, activityIdData) {
  const { name, is_seckill, price, start_time, end_time, line_price, phone, address } = activityIdData
  const venuesName = utils.getVenues().name

  new_obj.name.text = venuesName
  new_obj.activity_name.text = name

  if (is_seckill === 1) {
    new_obj.activity_price.text = `秒杀价：${activityIdData.seckill_price}`
  } else {
    new_obj.activity_price.text = `活动价：${price}`
  }

  if (start_time) {
    new_obj.activity_time.text = `${start_time.substring(5, 7)}月${start_time.substring(8, 10)}日至${end_time.substring(5, 7)}月${end_time.substring(8, 10)}日`
  }

  if (!new_obj.original_price) {
    new_obj.original_price = {
      align: '',
      color: '#FFFFFF',
      file_type: 'text',
      font: 12,
      is_show: 0,
      left: 0,
      text: `原价：${line_price}`,
      top: 0,
      width: 100
    }
  } else {
    new_obj.original_price.text = `原价：${line_price}`
  }

  // 手机号和地址
  const isCallTextShown = new_obj.call_text.is_show === 1
  new_obj.call_text.text = isCallTextShown ? phone : ''
  new_obj.address_text.text = address

  return new_obj
}

