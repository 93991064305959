var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "新卡号", prop: "number" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入新卡号" },
                model: {
                  value: _vm.form.number,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "number", $$v)
                  },
                  expression: "form.number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "补卡费", prop: "checked" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.form.checked,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "checked", $$v)
                    },
                    expression: "form.checked"
                  }
                },
                [_vm._v("收取补卡费用")]
              )
            ],
            1
          ),
          _vm.form.checked
            ? _c(
                "el-form-item",
                { attrs: { label: "补办费", prop: "poundage" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入费用", type: "number" },
                    model: {
                      value: _vm.form.poundage,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "poundage", $$v)
                      },
                      expression: "form.poundage"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.form.checked
            ? _c(
                "el-form-item",
                { attrs: { label: "收费方式", prop: "payment_method_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "dialog_w100",
                      attrs: { placeholder: "收费方式" },
                      model: {
                        value: _vm.form.payment_method_id,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "payment_method_id", $$v)
                        },
                        expression: "form.payment_method_id"
                      }
                    },
                    _vm._l(_vm.payment_method, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: index }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请填写操作备注",
                  rows: "5",
                  maxlength: "300",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("back", false)
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }