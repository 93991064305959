<template>
  <div>
    <!-- 反馈表单 -->
    <div v-if="!is_success" class="feedback_box">
      <!-- 专属顾问 -->
      <div>
        <div class="flex">
          <div>
            <el-avatar shape="square" :size="50" :src="consultant_avatar" />
          </div>
          <div class="m-l-10">
            <div>老师您好，我是您的</div>
            <div class="text-primary font-13">专属顾问 {{ consultant_name }}</div>
          </div>
        </div>
        <div class="flex col-top m-t-10" style="background-color: #f0f0f0;">
          <div class="m-10">
            <div class="m-b-12"><i class="el-icon-menu m-r-2" />咨询答疑</div>
            <div class="m-b-12"><i class="el-icon-menu m-r-2" />案例推荐</div>
            <div class="m-b-12"><i class="el-icon-menu m-r-2" />订购政策</div>
            <div><i class="el-icon-menu m-r-2" />服务支持</div>
          </div>
          <div class="consultant_img m-10">
            <el-image
              style="width: 100px; height: 100px"
              :src="consultant_wx"
              fit="fill"
            />
          </div>
        </div>
        <div class="m-l-10 m-t-10 font-14">咨询电话：{{ consultant_phone }}</div>
      </div>
      <el-divider />
      <el-form ref="form" :model="form" label-width="80px" label-position="top" :rules="rules" label-suffix=":">
        <el-form-item label="反馈类型" prop="type">
          <el-radio-group v-model="form.type" size="mini">
            <el-radio v-for="(item,index) in typeList" :key="index" border :label="item.name" class="m-4" />
          </el-radio-group>

        </el-form-item>
        <el-form-item label="反馈内容" prop="content">
          <el-input v-model="form.content" maxlength="230" show-word-limit type="textarea" rows="5" placeholder="写点什么……" />
        </el-form-item>
        <el-form-item label="问题截图">
          <el-upload
            v-loading="upLoading"
            class="upload-demo"
            :action="storeUpload"
            :on-success="upSuccess"
            :headers="utils.upload_headers()"
            :on-progress="upProgress"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-error="handleError"
            multiple
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="fileList"
            list-type="picture"
            accept=".jpg,.jpeg,.png"
          >
            <!-- <i class="el-icon-plus" /> -->
            <el-button size="small" type="warning">点击上传</el-button>
            <!-- <el-button size="small" type="danger" @click.stop="onScreen">在线截图</el-button> -->
            <div slot="tip" class="el-upload__tip text-info">附上问题截图，我们能更好的处理您遇到的问题，感谢理解</div>
          </el-upload>

        </el-form-item>
        <el-form-item class="m-t-20 text-center">
          <el-button :loading="loading" type="primary" round style="width:100%;" size="medium" @click="onSubmit">立即提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <el-result icon="success" title="提交成功" sub-title="您反馈的内容已提交成功，感谢您的支持~">
        <template slot="extra" />
      </el-result>
    </div>
  </div>
</template>

<script>
import Kscreenshot from 'kscreenshot' // 截图插件
import { getVenuesService } from '@/api/store/venues.js'
// import { addClass, removeClass } from '@/utils'
// import ScreenShort from 'js-web-screen-shot'

import { deepClone } from '@/utils'
import { setFeedback, upload } from '@/api/store/common.js'
export default {
  data() {
    return {
      form: {
        type: '问题反馈',
        content: '',
        images: []

      },
      loading: false,
      upLoading: false,
      typeList: [
        {
          name: '功能建议'
        }, {
          name: '内容建议'
        }, {
          name: '问题反馈'
        }, {
          name: '界面建议'
        }, {
          name: '交互建议'
        }, {
          name: '其他建议'
        }
      ],
      fileList: [],
      rules: {
        content: [
          { required: true, message: '请输入反馈内容', trigger: ['change', 'blur'] },
          { min: 10, max: 230, message: '长度在 10 到 230 个字符', trigger: ['change', 'blur'] }
        ]
      },
      is_success: false,
      consultant_name: '瑜小九',
      consultant_phone: '400-678-0554',
      consultant_avatar: require('@/assets/images/consultant_avatar.png'),
      consultant_wx: require('@/assets/images/defaultManager.png')
    }
  },
  mounted() {
    getVenuesService().then(res => {
      this.consultant_avatar = res.data.avatar
      this.consultant_name = res.data.name
      this.consultant_wx = res.data.qrcode_src
      this.consultant_phone = res.data.phone
    })
  },
  methods: {
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          var data = deepClone(this.form)
          var imgs = []
          if (this.fileList) {
            this.fileList.forEach((item, index) => {
              imgs.push(item.response.data.path)
            })
          }
          data.images = imgs.join(',')
          setFeedback(data).then(res => {
            if (res.status === 200) {
              this.is_success = true
              this.form.content = ''
              setTimeout(() => {
                this.$emit('hiddenView')
              }, 800)
            }
          }).finally(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    upProgress() {
      this.upLoading = true
    },
    upSuccess(response, file, fileList) {
      console.log('🚀 ~ file: index.vue ~ line 129 ~ upSuccess ~ response, file', response, file)
      this.upLoading = false
      if (response.status === 200) {
        this.fileList = fileList
      } else {
        this.$message.error('图片上传失败，请稍后重试')
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log('handlePreview', file)
    },
    handleError(err, file, fileList) {
      console.log('🚀 ~ file: index.vue ~ line 145 ~ handleError ~ err, file, fileList', err, file, fileList)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 网页截图
    onScreen() {
      this.$emit('hiddenView')
      var a = new Kscreenshot({
        key: 65,
        endCB: this.callbackScreen,
        cancelCB: this.closeFnScreen
      })
      a.startScreenShot()
    },
    // 截图完成
    callbackScreen(base64) {
      const file = this.dataURLtoBlob(base64)
      upload(file).then(res => {
        this.fileList.push({
          name: '网页截图',
          percentage: 100,
          response: res,
          url: res.data.path
        })
        this.$emit('showView')
      })
    },
    // 关闭截图
    closeFnScreen() {
      setTimeout(() => {
        this.$emit('showView')
      }, 300)
    },
    // base64转二进制
    dataURLtoBlob(dataurl) {
      var bytes = window.atob(dataurl.split(',')[1])

      // 处理异常，将ASCII码小于0的转换为大于0，这里有两种写法
      var ab = new ArrayBuffer(bytes.length)
      var ia = new Uint8Array(ab)
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i) // 这里有点疑惑，ia是怎么改变ab的？注：①
      }
      // Blob对象
      var blob = new Blob([ab], { type: 'image/jpeg' }) // type为图片的格式

      // FormData对象
      var fd = new FormData()
      // TDOD Ajax或者其他方式上传FormData对象

      // FormData对象接受三个参数，第三个参数为文件名，通常我们只传前两个参数，第三个参数不传则使用默认文件名，这里使用的Blob对象，所以需要一个文件名，用时间戳代替。
      fd.append('file', blob, Date.now() + '.jpg')
      return fd
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback_box{
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}

.images-uploader .el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409EFF;
}
.images-uploader-icon ,.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.feedback_box{
  ::v-deep .el-select-dropdown{
    z-index:400001 !important;
  }
}
.upload-demo{
  ::v-deep .el-upload--picture-card{
    width: 80px !important;
    height: 80px !important;
    line-height:80px !important;
  }
  ::v-deep .el-upload-list--picture-card .el-upload-list__item{
    width: 80px !important;
    height: 80px !important;
  }
}
</style>
<style lang="scss" >
// #screenShotContainer{
//   position: fixed !important;
// }
// #toolPanel,#optionPanel{
//   height:auto !important;
// }
// .screenShotContainer{
//   // overflow: hidden;
// }
</style>
