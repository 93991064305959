<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="预约时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="上课时间">
            <el-date-picker
              v-model="listQueryParams.subscribe_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <!-- 会员 -->
          <el-form-item label="会员">
            <Select :type="2" @changeId="handleMemberID" />
          </el-form-item>
          <!-- 会员卡 -->
          <el-form-item label="会员卡">
            <Select :type="5" @changeId="handleMembershipCardID" />
          </el-form-item>
          <!-- 教练 -->
          <el-form-item label="教练">
            <Select :type="4" @changeId="handleCoachID" />
          </el-form-item>
          <el-form-item label="预约状态">
            <el-select v-model="listQueryParams.status" placeholder="请选择" filterable clearable>
              <el-option label="已预约" :value="0">已预约</el-option>
              <el-option label="已签到" :value="1">已签到</el-option>
              <el-option label="已取消" :value="2">已取消</el-option>
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <!-- 暂无可用模糊搜索字段，取消
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="课程名称|手机号|姓名" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div> -->
      <el-button v-show="btn_access('s_user_export')" :disabled="tableData.list.length<=0" :loading="loading.download" class="filter-item" type="danger" icon="el-icon-download" @click="handleDownload">
        导出
      </el-button>
    </div>
    <!-- 预约列表 -->
    <div class="course_book_list">
      <!-- <h3><span>团课预约信息 （共计 <b>{{ tableData.count }}</b> 人）</span></h3> -->
      <div class="text item">
        <el-table
          ref="multipleTable"
          v-loading="loading.listLoading"
          :data="tableData.list"
          row-key="id"
        >
          <!-- <el-table-column prop="id" label="ID" width="100px" /> -->
          <el-table-column prop="user" label="会员信息" min-width="180px">
            <template slot-scope="{row: {user}}">
              <div v-if="!utils.empty(user)" class="flex col-center cursor" @click="toUserInfoPage(user.id)">
                <div>
                  <el-avatar shape="square" :size="50" :src="user.avatar">
                    <img :src="errorMemberSrc()">
                  </el-avatar>
                </div>
                <div class="p-l-10">
                  <span>{{ getUserNames(user) }}</span><br>
                  <span>{{ user?user.phone:'--' }}</span>
                </div>
              </div>
              <div v-else class="text-danger">用户信息缺失</div>
            </template>
          </el-table-column>
          <el-table-column prop="course.name" label="课程名">
            <template slot-scope="scope">
              <span class="cursor" @click="scope.row.course?toCourseInfoPage(scope.row.course.id):''">{{ scope.row.course?scope.row.course.name:'---' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="store_user.real_name" label="教练" />
          <!-- <el-table-column prop="subscribe_time" label="上课时间">
            <template slot-scope="{row: {subscribe_time}}">
              {{ subscribe_time|formatDate("YYYY-MM-DD HH:mm") }}
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="user_membership_card.card.name" label="会员卡名称" /> -->
          <!-- <el-table-column prop="user_membership_card.number" label="卡号" /> -->
          <el-table-column prop="limit" label="扣减" />
          <el-table-column prop="queue_on" label="排队">
            <template slot-scope="{row: {queue_on}}">
              <div class="block">
                <el-tag v-if="queue_on==0" type="success">正常</el-tag>
                <el-tag v-else type="danger">排队</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="order_type" label="预约方式">
            <template slot-scope="{row: {order_type}}">
              <div class="block">
                <span v-if="order_type == 0">会员卡预约</span>
                <span v-if="order_type == 1">付费约课</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="pay_price" label="付费金额">
            <template slot-scope="{row: {pay_price}}">
              <div class="block">
                <span>{{ pay_price === '0.00' ? '会员卡抵扣' : `${pay_price} 元` }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="{row: {status}}">
              <div class="block">
                <el-tag v-if="status==0" type="info">已预约</el-tag>
                <el-tag v-if="status==1" type="success">已签到</el-tag>
                <el-tag v-if="status==2" type="warning">已取消</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="预约时间">
            <template slot-scope="{row: {create_time}}">
              {{ create_time |formatDate("YYYY-MM-DD HH:mm") }}
            </template>
          </el-table-column>
          <el-table-column prop="subscribe_time" label="上课时间">
            <template slot-scope="{row: {subscribe_time}}">
              {{ subscribe_time |formatDate("YYYY-MM-DD HH:mm") }}
            </template>
          </el-table-column>
          <el-table-column prop="id" label="操作" min-width="160">
            <template slot-scope="scope">
              <el-button v-show="btn_access('s_course_subscribe_info')" type="primary" icon="el-icon-info" class="m-2" @click="course_details(scope.row.id)">详情</el-button>
              <template v-if="scope.row.status==0">
                <el-popconfirm
                  title="确定签到本条预约记录吗？"
                  @confirm="course_sign(scope.row.id,scope.row.user_id)"
                >
                  <el-button v-show="btn_access('s_workbench_sign_in')" slot="reference" type="success" class="m-2">签到</el-button>
                </el-popconfirm>
              </template>
              <template v-if="scope.row.status==0">
                <el-popconfirm
                  title="确定取消本条预约记录吗？"
                  @confirm="course_cancel(scope.row.id)"
                >
                  <el-button v-show="btn_access('s_workbench_cancel_subscribe')" slot="reference" type="danger" class="m-2">取消预约</el-button>
                </el-popconfirm>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <pagination small :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    <!-- 导出 -->
    <Export :success-prompt="successPrompt" :is-judge="is_judge" :animation-display="animation_display" />
    <!-- 预约详情 -->
    <bookInfo v-model="dialog" :logid.sync="logid" />
  </div>
</template>

<script>
// API
import { cancelBook, signBook } from '@/api/store/worktable.js'
import { parseTime } from '@/utils'
import bookInfo from '@/views/store/worktable/components/bookInfo'
import { get_fine_list } from '@/api/store/record.js'
// 方法函数
import Utils from '@/utils/utils.js'
// 组件
import Select from '@/components/elSelect/Select'
import Export from '@/components/export/index.vue'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  components: { Pagination, Export, Select, bookInfo },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      dialog: {},
      logid: 0,
      bookInfo: {},
      loading: {
        queryLoading: false,
        listLoading: false,
        download: false,
        searchLoading: false
      },
      coachType: 3,
      successPrompt: false,
      is_judge: 0,
      animation_display: true
    }
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    setQuery() {
      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'phone|real_name|nickname', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.create_time) querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      if (this.listQueryParams.subscribe_time) querys.push({ field: 'subscribe_time', type: 'between-time', key: this.listQueryParams.subscribe_time })
      if (this.listQueryParams.user_id) querys.push({ field: 'user_id', key: this.listQueryParams.user_id })
      if (this.listQueryParams.store_user_id) querys.push({ field: 'store_user_id', key: this.listQueryParams.store_user_id })
      if (this.listQueryParams.user_membership_card_id) querys.push({ field: 'user_membership_card_id', key: this.listQueryParams.user_membership_card_id })
      if (this.listQueryParams.status !== '' && this.listQueryParams.status != null) querys.push({ field: 'status', key: this.listQueryParams.status })
      return this.utils.getQueryParams(querys)
    },
    // 获取列表
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()

      get_fine_list(data).then(res => {
        this.tableData = res.data
        this.searchLoading = false
        this.loading.listLoading = false
      }).catch(() => {})
    },
    /**
     * 取消预约
     */
    course_cancel(id) {
      cancelBook(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      }).catch(() => {})
    },
    /**
     * 签到
     */
    course_sign(id, user_id) {
      signBook({
        sign_in: id,
        user_id: user_id
      }).then(res => {
        this.$message.success(res.msg)
        this.getList()
      }).catch(() => {})
    },
    // 预约详情
    course_details(id) {
      this.logid = id
      this.dialog = { title: '预约精品课记录', visible: true, type: this.type }
    },
    // 导出
    async handleDownload() {
      const _this = this
      this.animation_display = true
      this.is_judge = 0
      this.successPrompt = true
      this.loading.download = true
      const query = encodeURIComponent(this.setQuery())
      const progressBar = await this.utils.handleDownload({
        url: `/store/v1/course_subscribe/venues_id/${Utils.getVenues().id}/export?course_type=${2}&query=${query}`,
        fileName: '精品课预约记录.xlsx',
        baseURL: this.baseUrl
      })

      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.loading.download = false
          _this.successPrompt = false
        }, 2000)
      }
    },
    formatJson(filterVal) {
      return this.tableData.list.map(v => filterVal.map(j => {
        var c = ''
        if (j.indexOf('.') !== -1) {
          const a = j.split('.')
          c = !v[a[0]] ? '' : v[a[0]][a[1]]
        } else {
          c = v[j]
        }
        if (j === 'queue') return c ? '排队' : '正常'

        if (j === 'status') {
          switch (c) {
            case 0:
              return '已预约'
            case 1:
              return '已签到'
            case 2:
              return '已取消'
            default:
              break
          }
        }
        if (j === 'is_valid') return c ? '有效' : '无效'

        if (j === 'type') {
          switch (c) {
            case 0:
              return '团课'
            case 1:
              return '私教课'
            case 2:
              return '精品课'
            default:
              break
          }
        }
        if (j === 'subscribe_time' || j === 'cancel_time' || j === 'sign_time') {
          if (c) return parseTime(c)
        }
        return c
      }))
    },
    /**
     * 搜索用户
     */
    remoteUserMethod(query) {
      this.loading.searchLoading = true
      this.listQuery.query = query
      this.getUserList()
    },
    handleMemberID(val) {
      this.listQueryParams.user_id = val
    },
    handleCoachID(val) {
      this.listQueryParams.store_user_id = val
    },
    handleMembershipCardID(val) {
      this.listQueryParams.user_membership_card_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.el-avatar{
  background: none;
}
</style>
