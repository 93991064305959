<template>
  <div>
    <h1>TikTok</h1>
  </div>
</template>

<script>
export default {
  name: 'TikTok',
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
