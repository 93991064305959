var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "special-center"
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24, xs: 24 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "flex font-xxs",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "cursor",
                        on: {
                          click: function($event) {
                            return _vm.returnPage()
                          }
                        }
                      },
                      [_c("i", { staticClass: "el-icon-back" })]
                    ),
                    _c("div", { staticClass: "m-l-10" }, [_vm._v("员工详情")])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "userInfo" },
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "userHeader flex row-between",
                        attrs: { gutter: 20 }
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "flex",
                            attrs: { span: 6, offset: 0 }
                          },
                          [
                            _c(
                              "el-avatar",
                              {
                                attrs: {
                                  shape: "square",
                                  size: 80,
                                  src: _vm.userInfo.avatar
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.errorAvatarSrc(
                                      _vm.userInfo.sex,
                                      "img"
                                    )
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-right user_operation",
                            attrs: { span: 18, offset: 0 }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.utils.getVenues().id === -1,
                                    expression: "utils.getVenues().id === -1"
                                  }
                                ],
                                staticClass: "m-x-2",
                                attrs: {
                                  disabled:
                                    !!_vm.userInfo.is_super &&
                                    !!_vm.$store.state.user.isSuper,
                                  type: "warning"
                                },
                                on: { click: _vm.store_user_quit }
                              },
                              [_vm._v(" 一键离职 ")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                staticClass: "m-x-2",
                                attrs: {
                                  title: "删除后将无法找回，确定继续吗？"
                                },
                                on: { confirm: _vm.delUserRole }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.btn_access(
                                          "s_management_delete"
                                        ),
                                        expression:
                                          "btn_access('s_management_delete')"
                                      }
                                    ],
                                    attrs: {
                                      slot: "reference",
                                      disabled:
                                        !!_vm.userInfo.is_super &&
                                        !!_vm.$store.state.user.isSuper,
                                      type: "danger"
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v(" 删除 ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("s_management_edit"),
                                    expression:
                                      "btn_access('s_management_edit')"
                                  }
                                ],
                                staticClass: "m-x-2",
                                attrs: {
                                  disabled:
                                    !!_vm.userInfo.is_super &&
                                    !!_vm.$store.state.user.isSuper,
                                  type: "primary"
                                },
                                on: { click: _vm.goPage }
                              },
                              [_vm._v(" 编辑员工 ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("h5", [_vm._v("基本信息")]),
                    _c(
                      "el-descriptions",
                      {
                        staticClass: "margin-top",
                        attrs: {
                          column: 2,
                          border: "",
                          "label-style": { width: "100px" }
                        }
                      },
                      [
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", { staticClass: "el-icon-school" }),
                              _vm._v(" 所属场馆 ")
                            ]),
                            _vm._v(" " + _vm._s(_vm.venues_info.name) + " ")
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", { staticClass: "el-icon-user" }),
                              _vm._v(" 员工名 ")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.getUserNames(_vm.userInfo)) + " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", { staticClass: "el-icon-s-custom" }),
                              _vm._v(" 性别 ")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatTableSex(_vm.userInfo)) +
                                " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", { staticClass: "el-icon-mobile-phone" }),
                              _vm._v(" 手机号 ")
                            ]),
                            _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  "+" +
                                    _vm.userInfo.area_code +
                                    "-" +
                                    _vm.userInfo.phone
                                )
                              )
                            ])
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", { staticClass: "el-icon-potato-strips" }),
                              _vm._v(" 生日 ")
                            ]),
                            _vm._v(" " + _vm._s(_vm.userInfo.birthday) + " ")
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", { staticClass: "el-icon-star-on" }),
                              _vm._v(" 员工星级 ")
                            ]),
                            _c("el-rate", {
                              attrs: {
                                value:
                                  _vm.userInfo.star_level >= 5
                                    ? 5
                                    : parseInt(_vm.userInfo.star_level),
                                colors: _vm.colors,
                                disabled: ""
                              }
                            })
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", {
                                staticClass: "el-icon-office-building"
                              }),
                              _vm._v(" 教练类型 ")
                            ]),
                            _c(
                              "div",
                              [
                                _vm.userInfo.is_group
                                  ? _c(
                                      "el-tag",
                                      { staticClass: "m-r-5 m-b-5" },
                                      [_vm._v("团课教练")]
                                    )
                                  : _vm._e(),
                                _vm.userInfo.is_private
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("私教课教练")]
                                    )
                                  : _vm._e(),
                                !_vm.userInfo.is_group &&
                                !_vm.userInfo.is_private
                                  ? _c("span", [_vm._v("无")])
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", {
                                staticClass: "el-icon-office-building"
                              }),
                              _vm._v(" 课程颜色 ")
                            ]),
                            _vm.userInfo.color
                              ? _c("div", [
                                  _c("div", {
                                    staticClass: "class_color",
                                    style: { background: _vm.userInfo.color }
                                  })
                                ])
                              : _c("div", [_vm._v("未设置")])
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", {
                                staticClass: "el-icon-office-building"
                              }),
                              _vm._v(" 状态 ")
                            ]),
                            _vm.userInfo.role_info
                              ? _c(
                                  "el-tag",
                                  {
                                    staticClass: "m-r-5 m-b-5",
                                    attrs: {
                                      type:
                                        _vm.userInfo.role_info.state === 1
                                          ? ""
                                          : "danger"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.userInfo.role_info.state === 1
                                            ? "在职"
                                            : "已离职"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", {
                                staticClass: "el-icon-office-building"
                              }),
                              _vm._v(" 是否兼职 ")
                            ]),
                            _vm.userInfo.role_info
                              ? _c(
                                  "el-tag",
                                  {
                                    staticClass: "m-r-5 m-b-5",
                                    attrs: {
                                      type:
                                        _vm.userInfo.role_info.part_time === 1
                                          ? "danger"
                                          : ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.userInfo.role_info.part_time === 1
                                            ? "是"
                                            : "否"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", {
                                staticClass: "el-icon-office-building"
                              }),
                              _vm._v(" 员工角色 ")
                            ]),
                            !_vm.utils.empty(_vm.userInfo.employee)
                              ? _c(
                                  "div",
                                  _vm._l(_vm.userInfo.employee, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "el-tag",
                                      { key: index, staticClass: "m-x-2" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.role
                                                ? item.role.role_name
                                                : "--"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              : _c("div", [_vm._v(" 暂无 ")])
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", { staticClass: "el-icon-time" }),
                              _vm._v(" 加入时间 ")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.userInfo.create_time
                                    ? _vm.userInfo.create_time
                                    : "无"
                                ) +
                                " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { span: "2" } },
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", { staticClass: "el-icon-s-management" }),
                              _vm._v(" 简介 ")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.userInfo.introduce === ""
                                    ? "无"
                                    : _vm.userInfo.introduce
                                ) +
                                " "
                            )
                          ],
                          2
                        ),
                        _c(
                          "el-descriptions-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("i", { staticClass: "el-icon-postcard" }),
                              _vm._v(" 员工封面图 ")
                            ]),
                            _vm.userInfo.cover
                              ? _c(
                                  "div",
                                  [
                                    _c("el-image", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        src: _vm.userInfo.cover,
                                        "preview-src-list": [_vm.userInfo.cover]
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24, xs: 24 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "m-t-20", attrs: { span: 24, xs: 24 } },
                    [
                      _c(
                        "el-card",
                        [
                          _c("TimeQuantum", {
                            attrs: {
                              "store-user-id": _vm.form.store_user_id,
                              "venues-id": _vm.form.venues_id
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "m-t-20", attrs: { span: 24, xs: 24 } },
                    [
                      _c("album", {
                        attrs: { id: _vm.form.store_user_id, type: _vm.type }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }