import request from '@/utils/request'
// import Utils from '@/utils/utils.js'

// 获取海报分类
export function getPosterCategory(params) {
  return request({
    url: `/store/v1/category`,
    method: 'get',
    params
  })
}

// 根据分类获取海报模板
export function getPosterTemplate(params) {
  return request({
    url: `/store/v1/poster_template`,
    method: 'get',
    params
  })
}

// 根据ID获取海报模板
export function getPosterTemplateById(id) {
  return request({
    url: `/store/v1/poster_template/${id}`,
    method: 'get'
  })
}

// 获取营销活动
export function getMarketingActivity(params) {
  return request({
    url: `/store/v2/goods`,
    method: 'get',
    params
  })
}

// 获取营销活动详情
export function getMarketingActivityDetail(id) {
  return request({
    url: `/store/v2/goods/${id}`,
    method: 'get'
  })
}
