<template>
  <div v-if="!utils.empty(timeQuantum)">
    <!-- <el-card> -->
    <div class="flex row-between">
      <div v-if="!is_edit && isDisplay != 0"><h5>作息时间</h5></div>
      <div v-if="!is_edit && isDisplay != 0">
        <el-button type="primary" icon="el-icon-edit" @click="is_edit = true">编辑</el-button>
      </div>
    </div>
    <div v-if="is_edit" class="flex m-y-10 timeauto_tips">
      <el-checkbox v-model="time_auto" />
      <div class="m-l-5">每个工作日作息时间都一样 （私教作息时间涉及到会员预约私教可选的时间，建议认真设置。）</div>
    </div>
    <div class="time_quantum">
      <!-- 周 -->
      <div class="week flex">
        <div class="week-item" />
        <div v-for="(v, i) in 7" :key="i" class="week-item">
          <div class="flex row-center flex-col col-center week-item-h">
            <div class="m-b-5">
              {{ weekList[i] }}
            </div>
            <div v-if="timeQuantum[0]" class="flex">
              <div>
                {{
                  timeQuantum[0]['list'][i].is_work && timeQuantum[0]['list'][i].is_work != false ? '上班' : '休息'
                }}
              </div>
              <div v-if="is_edit" class="m-l-5">
                <el-switch
                  v-model="timeQuantum[0]['list'][i].is_work"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="work">
        <div v-for="(item, index) in timeQuantum" :key="index" class="flex">
          <div class="work-item">
            <div v-if="item.list[0] && item.list[0].type == 1">
              <div class="start_time">上班时间</div>
              <div class="end_time">下班时间</div>
            </div>
            <div v-else>
              <!-- {{ item ? item.title : '--' }} -->
              <div v-if="is_edit">
                <input v-model="item.title" type="text" class="inputEdit">
              </div>
              <div v-else class="inputSee">
                {{ item ? item.title : '--' }}
              </div>
            </div>
          </div>
          <div v-for="(item1, index1) in item.list" :key="index1" class="work-item">
            <div class="start_time" :class="{ cursor: is_edit }">
              <div v-if="is_edit">
                <el-time-select
                  v-show="is_edit"
                  v-model="item1.start_time"
                  format="HH:mm"
                  value-format="HH:mm"
                  :picker-options="{
                    start: '00:00',
                    step: '00:05',
                    end: '23:30'
                  }"
                  class="timePicker"
                  :clearable="false"
                  prefix-icon="a"
                  placeholder="--:--"
                  @change="timeChange($event, 'start_time', index, index1)"
                />
              </div>
              <div v-else>
                {{ item1 ? item1.start_time : '--:--' }}
              </div>
            </div>
            <div class="end_time" :class="{ cursor: is_edit }">
              <div v-if="is_edit">
                <el-time-select
                  v-show="is_edit"
                  v-model="item1.end_time"
                  format="HH:mm"
                  value-format="HH:mm"
                  :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: '23:30'
                  }"
                  class="timePicker"
                  :clearable="false"
                  prefix-icon="a"
                  placeholder="--:--"
                  @change="timeChange($event, 'end_time', index, index1)"
                />
              </div>
              <div v-else>
                {{ item1 ? item1.end_time : '--:--' }}
              </div>
            </div>
          </div>

          <div v-if="index != 0 && is_edit" class="delete m-l-10" @click="del_list(index)">
            <el-button type="danger" icon="el-icon-delete" circle size="mini" />
          </div>
        </div>
      </div>

      <div v-if="is_edit" class="m-y-10">
        <el-link icon="el-icon-edit" type="success" @click="add_list">新增其他休息时间</el-link>
      </div>
    </div>
    <div v-if="is_edit" class="p-t-20">
      <el-button type="primary" @click="updateTimeQuantum">保存</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { getTimeQuantum, updateTimeQuantum } from '@/api/store/venues.js'

export default {
  name: 'TimeQuantum',
  props: {
    storeUserId: {
      type: [Number, String],
      default: 0
    },
    venuesId: {
      type: [Number, String],
      default: 0
    },
    isDisplay: {
      type: [Number],
      default: 1
    }
  },
  data() {
    return {
      weekList: ['周一', '周二', '周三', '周四', '周五', '周六', '周天'],
      timeQuantum: {},
      time_auto: true,
      isLoading: false,
      is_edit: false
    }
  },
  created() {
    this.getTimeQuantumInfo()
  },
  methods: {
    // 保存作息时间
    updateTimeQuantum() {
      const time_quantum = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }
      const timeQuantumData = deepClone(this.timeQuantum)
      timeQuantumData.map((val, l) => {
        val.list.forEach((item, i) => {
          time_quantum[i + 1][l] = item
          time_quantum[i + 1][l].title = val.title
          time_quantum[i + 1][l].is_work = this.timeQuantum[0]['list'][i].is_work ? 1 : 0
        })
      })
      this.isLoading = true
      const data = {
        time_quantum: JSON.stringify(time_quantum)
      }
      updateTimeQuantum(this.storeUserId, this.venuesId, data)
        .then(res => {
          this.$message.success(res.msg)
          this.cancel()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // 返回重载
    cancel() {
      this.timeQuantum = {}
      this.is_edit = false
      this.getTimeQuantumInfo()
    },
    // 删除
    del_list(i) {
      this.timeQuantum.splice(i, 1)
    },
    // 添加其他作息
    add_list() {
      const title = '其他休息' + this.timeQuantum.length
      this.timeQuantum.push({
        title: title,
        type: '0',
        list: Array.from(Array(7), (v, k) => {
          return { title: title, start_time: '00:00', end_time: '23:59' }
        })
      })
    },
    timeChange(v, type, index, index1) {
      // 批量
      if (this.time_auto) {
        this.timeQuantum[index].list.map((val, index) => {
          val[type] = v
        })
      }
    },
    // 获取作息时间
    getTimeQuantumInfo() {
      if (this.venuesId) {
        getTimeQuantum(this.storeUserId, this.venuesId).then(res => {
          if (res.data && !this.utils.empty(res.data)) {
            const time_quantum = res.data.time_quantum
            const quantum = []
            time_quantum[1].forEach((item, index) => {
              quantum[index] = {
                title: item.title,
                type: item.type,
                list: []
              }
            })
            for (const i in time_quantum) {
              time_quantum[i].forEach((item, index) => {
                quantum[index].list.push(item)
              })
            }
            this.timeQuantum = quantum
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.timeauto_tips {
  font-size: 14px;
}
.time_quantum {
  width: 100%;
  .week {
    .week-item {
      width: 85px;
      height: 60px;
      text-align: center;
      border-bottom: 1px solid rgb(0 0 0 / 10%);
      border-left: 1px solid rgb(0 0 0 / 10%);
      border-top: 1px solid rgb(0 0 0 / 10%);
    }
    .week-item:nth-last-child(1) {
      border-right: 1px solid rgb(0 0 0 / 10%);
    }
    .week-item-h {
      height: 100%;
    }
  }
  .work {
    .work-item {
      border-bottom: 1px solid rgb(0 0 0 / 10%);
      border-left: 1px solid rgb(0 0 0 / 10%);
      width: 85px;
      height: 80px;
      text-align: center;
      line-height: 80px;
    }
    .work-item:nth-last-child(2),
    .work-item:nth-last-child(1) {
      border-right: 1px solid rgb(0 0 0 / 10%);
    }
    .start_time {
      border-bottom: 1px solid rgb(0 0 0 / 10%);
    }
    .start_time,
    .end_time {
      line-height: 38px;
    }
  }
  .timePicker {
    width: 100%;
    ::v-deep {
      input {
        width: 100%;
        border: unset;
        padding: 0;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.inputEdit {
  width: 100%;
  border: none;
  outline: none;
}
.inputSee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
</style>
