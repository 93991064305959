<template>
  <div class="app-container">
    <!-- 筛选 -->
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <!-- 品牌 -->
          <card :id="listQueryParams.id" @cardId="cardIds" />
          <el-form-item label="状态">
            <el-select v-model="listQueryParams.status" placeholder="请选择状态" clearable>
              <el-option label="未使用" value="0" />
              <el-option label="已使用" value="1" />
              <el-option label="已退款" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="dateShortcutChooser"
            />
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
    </div>
    <!-- 添加按钮 -->
    <div class="filter-container">
      <el-button v-show="btn_access('activity_deposit_add')" v-waves class="filter-item m-r-10" type="primary" icon="el-icon-plus" @click="handleAdd">
        添加
      </el-button>
    </div>
    <!-- 表格 -->
    <el-table v-loading="tableLoading" stripe :data="tableData.list" style="width: 100%">
      <!-- <el-table-column prop="activity_id" label="活动ID" width="120" /> -->
      <el-table-column prop="app" label="品牌信息">
        <template slot-scope="{ row }">
          <div v-if="row.app" class="flex">
            <el-avatar v-if="row.app.logo" shape="square" style="width: 40px; height: 40px;" class="m-r-6" :src="row.app.logo">
              <img :src="errorMemberSrc()">
            </el-avatar>
            <span>{{ row.app.name }}</span>
          </div>
          <div v-else style="color: red;">品牌信息丢失</div>
        </template>
      </el-table-column>
      <el-table-column prop="store_user" label="参与人员信息">
        <template slot-scope="{ row }">
          <div class="flex">
            <el-avatar style="width: 40px; height: 40px;" class="m-r-6" :src="row.store_user.avatar">
              <img :src="errorMemberSrc()">
            </el-avatar>
            <div>
              <div>{{ row.store_user.real_name }}</div>
              <div>{{ row.store_user.phone }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="advance_payment" label="预付款" />
      <el-table-column prop="deduction_amount" label="抵扣款" />
      <el-table-column prop="coupon_expires" label="过期时间">
        <template slot-scope="{ row }">
          <div v-if="row.coupon_expires === 0">永久有效</div>
          <div v-else>{{ row.coupon_expires | formatDate('YYYY-MM-DD HH:mm:ss') }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="{ row }">
          <div v-if="row.status === 0"><el-tag type="info">未使用</el-tag></div>
          <div v-if="row.status === 1"><el-tag>已使用</el-tag></div>
          <div v-if="row.status === 2"><el-tag type="danger">已退款</el-tag></div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="下单时间">
        <template slot-scope="{ row }">
          {{ row.create_time | formatDate('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button v-show="btn_access('activity_deposit_edit')" type="primary" icon="el-icon-edit" class="m-2" @click="handleEdit(row.id)">编辑</el-button>
          <el-popconfirm v-show="btn_access('activity_deposit_del')" title="确定删除该记录吗？" @confirm="handleDelete(row.id)">
            <el-button slot="reference" type="danger" icon="el-icon-delete" class="m-2">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 弹出层 -->
    <el-dialog :title="addEdit ? '添加活动记录' : '编辑活动记录'" :visible.sync="dialogVisible" width="25%" @open="handleOpen" @close="handleClose">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <!-- 活动ID -->
        <el-form-item label="活动ID" style="display: none;">
          <el-input v-model="form.activity_id" type="number" />
        </el-form-item>
        <!-- 品牌 -->
        <i class="icon-required" />
        <card v-if="dialogVisible" :id="form.aid" :name="name" @cardId="cardId" />
        <!-- 参与人员工ID -->
        <el-form-item label="参与员工" prop="store_user_id">
          <el-select v-model="form.store_user_id" placeholder="请选参与员工" :disabled="!form.aid">
            <el-option v-for="(item, index) in staffData.list" :key="index" :label="item.real_name" :value="item.id">
              <template v-slot:default>
                <div class="flex">
                  <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="item.avatar">
                    <img :src="errorAvatarSrc(item, 'img')">
                  </el-avatar>
                  <span>{{ item.real_name.length > 6 ? item.real_name.substring(0,5) + '...' : item.real_name }}</span>
                  -
                  <span>{{ item.phone }}</span>
                </div>
              </template>
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 预付款 -->
        <el-form-item label="预付款">
          <el-input v-model="form.advance_payment" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <!-- 抵扣款 -->
        <el-form-item label="抵扣款">
          <el-input v-model="form.deduction_amount" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <!-- 过期时间 -->
        <el-form-item label="过期时间">
          <el-date-picker
            v-model="form.coupon_expires"
            type="datetime"
            placeholder="选择日期时间"
            default-time="23:59:59"
            value-format="yyyy-MM-dd HH:mm"
          />
        </el-form-item>
        <!-- 状态 -->
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="0">未使用</el-radio>
            <el-radio :label="1">已使用</el-radio>
            <el-radio :label="2">已退款</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit('form')">{{ addEdit ? '确 定' : '保 存' }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getActivityList, addActivity, deleteActivity, getActivityDetail, updateActivity } from '@/api/admin/activityManagement.js'
import { accountList } from '@/api/admin/merchants.js'
// 组件
import Pagination from '@/components/Pagination' // 分页组件
import card from '@/components/elSelect/capital.vue'
// 时间错过滤器
import { formatDate } from '@/filters/index.js'

export default {
  components: { Pagination, card },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      tableLoading: false,
      dialogVisible: false,
      form: {
        activity_id: 1,
        aid: '',
        store_user_id: '',
        advance_payment: '',
        deduction_amount: 0.00,
        coupon_expires: '',
        status: 0
      },
      rules: {
        store_user_id: [{ required: true, message: '请选择参与员工', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      staffData: {
        list: [],
        count: 0
      },
      addEdit: true,
      id: '',
      name: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.id) querys.push({ field: 'appid', key: this.listQueryParams.id })
      if (this.listQueryParams.status) querys.push({ field: 'status', type: '=', key: this.listQueryParams.status })
      if (this.listQueryParams.create_time) querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      data.query = this.utils.getQueryParams(querys)

      getActivityList(data).then(res => {
        this.tableData = res.data
        this.tableLoading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 添加活动记录
    handleAdd() {
      this.addEdit = true
      this.dialogVisible = true
    },
    // 弹出层打开的回调
    handleOpen() {
      console.log('handleOpen')
    },
    // 弹出层关闭的回调
    handleClose() {
      // 清除表单
      this.form = {
        activity_id: 1,
        aid: '',
        store_user_id: '',
        advance_payment: '',
        deduction_amount: 0.00,
        coupon_expires: '',
        status: 0
      }
      this.name = ''
    },
    // 提交
    handleSubmit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          if (this.addEdit) {
            addActivity(this.form).then(res => {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.dialogVisible = false
              this.getList()
            })
          } else {
            updateActivity(this.id, this.form).then(res => {
              this.$message({
                message: '编辑成功',
                type: 'success'
              })
              this.dialogVisible = false
              this.getList()
            })
          }
        } else {
          return false
        }
      })
    },
    cardId(val) {
      this.form.aid = val
      if (this.addEdit) this.form.store_user_id = ''
      this.staffList()
    },
    staffList() {
      if (!this.form.aid) return
      accountList(this.form.aid, { limit: 999 }).then(res => {
        this.staffData = res.data
      })
    },
    // 编辑记录
    handleEdit(id) {
      this.addEdit = false
      this.id = id
      getActivityDetail(id).then(res => {
        this.form.activity_id = res.data.activity_id
        this.form.aid = res.data.app.id
        this.name = res.data.app.name
        this.form.store_user_id = res.data.store_user_id
        this.form.advance_payment = res.data.advance_payment
        this.form.deduction_amount = res.data.deduction_amount
        if (res.data.coupon_expires === 0) {
          this.form.coupon_expires = 0
        } else {
          this.form.coupon_expires = formatDate(res.data.coupon_expires, 'YYYY-MM-DD HH:mm:ss')
        }
        this.form.status = res.data.status
        this.dialogVisible = true
      })
    },
    // 删除记录
    handleDelete(id) {
      deleteActivity(id).then(res => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.getList()
      })
    },
    cardIds(val) {
      this.listQueryParams.id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-required {
  &:after {
    content: '*';
    color: red;
    line-height: 46px;
  }
  position: absolute;
  left: 50px;
  top: 65px;
  bottom: 0;
  vertical-align: middle;
  z-index: 99;
}
</style>
