var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "50%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.handleOpen,
        close: _vm.handleClose
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "title_title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.type === "add"
                    ? "新增组合卡套餐"
                    : _vm.type === "edit"
                    ? "编辑组合卡套餐"
                    : "查看组合卡套餐详情"
                ) +
                " "
            )
          ]),
          _c(
            "div",
            {
              staticClass: "title_icon cursor",
              on: { click: _vm.handleClose }
            },
            [_c("i", { staticClass: "el-icon-close" })]
          )
        ])
      ]),
      _vm.type === "add" || _vm.type === "edit"
        ? _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cardLoading,
                  expression: "cardLoading"
                }
              ],
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "套餐名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入套餐名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "套餐名称", prop: "card_group" } },
                [
                  _c(
                    "el-collapse",
                    {
                      model: {
                        value: _vm.activeNames,
                        callback: function($$v) {
                          _vm.activeNames = $$v
                        },
                        expression: "activeNames"
                      }
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "期限卡", name: "0" } },
                        [
                          _c("checkboxInput", {
                            attrs: {
                              "parent-selected": _vm.selectedCard.termCard,
                              list: _vm.supportCard.termCard
                            },
                            on: {
                              "update:parentSelected": [
                                function($event) {
                                  return _vm.$set(
                                    _vm.selectedCard,
                                    "termCard",
                                    $event
                                  )
                                },
                                _vm.handleParentTermCard
                              ],
                              "update:parent-selected": function($event) {
                                return _vm.$set(
                                  _vm.selectedCard,
                                  "termCard",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "次数卡", name: "1" } },
                        [
                          _c("checkboxInput", {
                            attrs: {
                              "parent-selected": _vm.selectedCard.frequencyCard,
                              list: _vm.supportCard.frequencyCard
                            },
                            on: {
                              "update:parentSelected": [
                                function($event) {
                                  return _vm.$set(
                                    _vm.selectedCard,
                                    "frequencyCard",
                                    $event
                                  )
                                },
                                _vm.handleParentFrequencyCard
                              ],
                              "update:parent-selected": function($event) {
                                return _vm.$set(
                                  _vm.selectedCard,
                                  "frequencyCard",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "储值卡", name: "3" } },
                        [
                          _c("checkboxInput", {
                            attrs: {
                              "parent-selected":
                                _vm.selectedCard.storedValueCard,
                              list: _vm.supportCard.storedValueCard
                            },
                            on: {
                              "update:parentSelected": [
                                function($event) {
                                  return _vm.$set(
                                    _vm.selectedCard,
                                    "storedValueCard",
                                    $event
                                  )
                                },
                                _vm.handleParentStoredValueCard
                              ],
                              "update:parent-selected": function($event) {
                                return _vm.$set(
                                  _vm.selectedCard,
                                  "storedValueCard",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.type === "details"
        ? _c(
            "div",
            [
              _c("p", [_c("b", [_vm._v("活动信息")])]),
              _vm.dataList
                ? _c(
                    "el-descriptions",
                    {
                      staticClass: "margin-top",
                      attrs: {
                        column: 2,
                        border: "",
                        "label-style": { width: "100px" }
                      }
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 套餐卡名称 ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.dataList.name) + " ")
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 包含卡项目 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dataList.card_group
                                  ? _vm.dataList.card_group.length
                                  : 0
                              ) +
                              " 张会员卡 "
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 最后修改人 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dataList.store_user
                                  ? _vm.dataList.store_user.real_name
                                  : ""
                              ) +
                              " "
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 最后修改时间 ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.dataList.update_time) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("p", [_c("b", [_vm._v("套餐卡组")])]),
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: { column: 3, direction: "vertical", border: "" }
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "期限卡" } },
                    _vm._l(_vm.dataList.card_data, function(item) {
                      return _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.type === 0,
                              expression: "item.type === 0"
                            }
                          ],
                          key: item.id
                        },
                        [
                          _c("i", { staticClass: "el-icon-bank-card" }),
                          _vm._v(" " + _vm._s(item.name) + " "),
                          _c("br")
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "次数卡" } },
                    _vm._l(_vm.dataList.card_data, function(item) {
                      return _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.type === 1,
                              expression: "item.type === 1"
                            }
                          ],
                          key: item.id
                        },
                        [
                          _c("i", { staticClass: "el-icon-bank-card" }),
                          _vm._v(" " + _vm._s(item.name) + " "),
                          _c("br")
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "储值卡" } },
                    _vm._l(_vm.dataList.card_data, function(item) {
                      return _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.type === 2,
                              expression: "item.type === 2"
                            }
                          ],
                          key: item.id
                        },
                        [
                          _c("i", { staticClass: "el-icon-bank-card" }),
                          _vm._v(" " + _vm._s(item.name) + " "),
                          _c("br")
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading },
              on: { click: _vm.handleClose }
            },
            [_vm._v(_vm._s(_vm.type === "details" ? "关 闭" : "取 消"))]
          ),
          _vm.type === "add" || _vm.type === "edit"
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading, type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v(" 保 存 ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }