var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "show-close": false,
        width: "30%"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.handleOpen
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "title_title" }, [_vm._v("提示")]),
          _c(
            "div",
            {
              staticClass: "title_icon cursor",
              on: { click: _vm.handleClose }
            },
            [_c("i", { staticClass: "el-icon-close" })]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "dialog_box" },
        [
          _c("el-alert", {
            attrs: {
              title: _vm.data.title,
              type: "info",
              "show-icon": "",
              closable: false
            }
          }),
          _vm._l(_vm.data.list, function(item, index) {
            return _c("div", { key: index, staticClass: "m-t-20" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", {
                  staticClass: "w-6 h-24",
                  staticStyle: { "border-radius": "9px", background: "#01CBCA" }
                }),
                _c("div", { staticClass: "font-16 m-l-10" }, [
                  _vm._v(_vm._s(item.title))
                ])
              ]),
              _c(
                "div",
                { staticClass: "m-t-10" },
                [
                  _c("el-alert", {
                    attrs: {
                      title: item.content,
                      type: "info",
                      closable: false
                    }
                  })
                ],
                1
              )
            ])
          })
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }