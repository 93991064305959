<template>
  <div class="app-container flex row-center">
    <div class="filter-container">
      <div style="width: 500px; margin: 0 auto;"><img style="width: 100%;" :src="empty_pause_img" alt=""></div>
      <div style="text-align: center; color: #09AEB7;" class="font-22 m-t-10">{{ msg }}</div>
      <div style="text-align: center; color: color: #999999;" class="font-16 m-t-10">您可以通过切换其他品牌或联系客服解决问题</div>
      <div style="width: 120px; margin: 10px auto 0px;"><img style="width: 100%;" :src="wx_qrcode_img" alt=""></div>
      <div style="text-align: center; color: #999999;" class="font-16 m-t-10"><svg-icon icon-class="phone" /> {{ callNumber }}</div>
      <div class="flex row-center m-t-20"><el-button type="primary" @click="handleToPath">去升级套餐</el-button></div>
    </div>
  </div>
</template>

<script>
// API
import { getVenuesService } from '@/api/store/venues.js'

export default {
  data() {
    return {
      // msg: localStorage.getItem('603Msg') ? localStorage.getItem('602Msg') : '您当前品牌已过期',
      msg: localStorage.getItem('603Msg') ? localStorage.getItem('603Msg') : '您当前的品牌未升级至旗舰版',
      wx_qrcode_img: require('@/assets/images/defaultManager.png'),
      empty_pause_img: require('@/assets/images/empty_pause.png'),
      callNumber: '400-678-0554'
    }
  },
  mounted() {
    getVenuesService().then(res => {
      this.wx_qrcode_img = res.data.qrcode_src
      this.callNumber = res.data.phone
    })
  },
  methods: {
    handleToPath() {
      this.$router.push({ name: 's_plan' })
    }
  }
}
</script>

<style lang="scss" scoped></style>
