<template>
  <!-- 班级课程管理 -->
  <div class="app-container special-center">
    <!-- 头部导航 -->
    <el-page-header
      :content="
        this.$route.params.operate == 'add'
          ? '新增班级'
          : this.$route.params.operate == 'edit'
            ? '编辑班级'
            : '班级详情'
      "
      class="page-header"
      @back="returnPage()"
    />
    <!-- 表单 -->
    <div class="formColumn">
      <el-form ref="ruleForm" v-loading="upForm" :model="form" :rules="rules" label-width="80px" class="demo-ruleForm">
        <el-form-item label="班级名称" prop="name" style="width: 400px;">
          <el-input v-model="form.name" placeholder="请输入班级名称" />
        </el-form-item>
        <el-form-item class="line-clear" label="课程图片">
          <el-upload
            v-loading="upLoading"
            class="images-uploader"
            :action="storeUpload"
            :show-file-list="false"
            :on-success="upSuccess"
            :headers="utils.upload_headers()"
            :on-progress="upProgress"
            accept=".jpg,.jpeg,.png"
          >
            <img v-if="form.cover" :src="form.cover" class="images" title="点击上传图片">
            <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
          </el-upload>
        </el-form-item>
        <el-row>
          <el-col :span="10">
            <el-form-item label="开课时间" prop="open_time">
              <el-date-picker
                v-model="form.open_time"
                :picker-options="pickerOptions"
                type="date"
                align="right"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="选择日期时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="结课时间" prop="end_time">
              <el-date-picker
                v-model="form.end_time"
                :picker-options="pickerOptions"
                type="date"
                align="right"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                placeholder="选择日期时间"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="班级课时" prop="class_time">
          <el-input v-model="form.class_time" placeholder="请输入班级课时" type="number" oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')" style="width: 200px;">
            <template slot="append">节</template>
          </el-input>
        </el-form-item>
        <el-form-item label="班级简介">
          <el-input v-model="form.desc" placeholder="请输入班级简介" />
        </el-form-item>
        <el-form-item label="会员购买" prop="guest_buy">
          <el-checkbox v-model="form.member_buy" label="会员" />
          <el-checkbox v-model="form.guest_buy" label="访客" />
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="售价">
              <el-radio-group v-model="money">
                <el-radio :label="1">免费</el-radio>
                <el-radio :label="2">收费</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item v-if="money === 2" label="售价">
              <!-- <el-input v-model="form.money" placeholder="费用" type="number" oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')" style="width: 120px;"> -->
              <el-input v-model="form.money" placeholder="费用" type="number" style="width: 120px;">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item v-if="money === 2" label="优惠价">
              <el-radio-group v-model="form.discount_on">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item v-if="form.discount_on === 1 && money === 2" label="收取" prop="discount">
              <el-input v-model="form.discount" placeholder="费用" type="number" oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')" style="width: 120px;">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form.discount_on === 1 && money === 2" :span="8">
            <el-form-item label="优惠时间">
              <el-date-picker
                v-model="discounts"
                type="datetimerange"
                align="right"
                unlink-panels
                :picker-options="dataProhibitSelection"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :default-time="['00:00:00', '23:59:59']"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="人数上限">
              <el-radio-group v-model="form.people_number">
                <el-radio :label="0">不限</el-radio>
                <el-radio :label="1">限制</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item v-if="form.people_number != '0'" label="人数">
              <el-input v-model="people_number" type="number" oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')" style="width: 150px;">
                <template slot="append">人</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="免费请假">
              <el-radio-group v-model="form.ask_number">
                <el-radio :label="0">不允许</el-radio>
                <el-radio :label="1">允许</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item v-if="form.ask_number != '0'" label="次数">
              <el-input v-model="ask_number" type="number" oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')" style="width: 150px;">
                <template slot="append">次</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="班级描述">
          <el-input v-model="form.introduction" type="textarea" :rows="5" />
        </el-form-item>
        <el-form-item label="是否上架">
          <el-radio-group v-model="form.shelves">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="0">下架</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="this.$route.params.operate == 'add'" style="margin: none; text-align: center;">
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          <el-button type="info" @click="returnPage()">返 回</el-button>
        </el-form-item>
        <el-form-item v-if="this.$route.params.operate == 'edit'" style="margin: none; text-align: center;">
          <el-button type="primary" @click="editForm('ruleForm')">确 定</el-button>
          <el-button type="info" @click="returnPage()">返 回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// API
import { getImg } from '@/api/store/config.js'
import { postAdd, getMclass, putMclass } from '@/api/store/course'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'

export default {
  data() {
    return {
      form: {
        id: '',
        name: '',
        cover: '',
        open_time: '',
        end_time: '',
        class_time: '',
        desc: '',
        money: 0,
        discount_on: 0,
        discount: 0,
        discount_start_time: '',
        discount_end_time: '',
        people_number: 0,
        ask_number: 0,
        introduction: '',
        shelves: 0,
        guest_buy: false,
        member_buy: false
      },
      money: 1,
      discounts: [],
      people_number: 0,
      ask_number: 0,
      rules: {
        name: [{ required: true, message: '请输入班级名称', trigger: 'blur' }],
        class_time: [{ required: true, message: '请输入班级课时', trigger: 'blur' }],
        discount: [{ required: true, message: '请输填写优惠价格', trigger: 'blur' }],
        guest_buy: [{ required: true, message: '至少选择一项', trigger: 'blur' }],
        member_buy: [{ required: true, message: '至少选择一项', trigger: 'blur' }],
        open_time: [{ required: true, message: '请选择开课时间', trigger: 'blur' }],
        end_time: [{ required: true, message: '请选择结课时间', trigger: 'blur' }]
      },
      upForm: false
    }
  },
  watch: {},
  created() {
    // 获取默认封面
    getImg('course_mclass').then(res => {
      if (!this.utils.empty(res.data)) {
        this.form.cover = res.data[0]
      }
    })
    // 根据id获取详情
    if (this.$route.params.operate !== 'add') {
      this.upForm = true
      getMclass(this.$route.params.id).then(res => {
        if (res.data.money !== 0) this.money = 2
        if (res.data.people_number !== 0 && res.data.people_number !== 1) {
          this.people_number = res.data.people_number
          res.data.people_number = 1
        }
        if (res.data.ask_number !== 0 && res.data.ask_number !== 1) {
          this.ask_number = res.data.ask_number
          res.data.ask_number = 1
        }
        if (res.data.guest_buy === 1) res.data.guest_buy = true
        else res.data.guest_buy = false
        if (res.data.member_buy === 1) res.data.member_buy = true
        else res.data.member_buy = false
        if (res.data.discount_on === 1) {
          this.discounts.push(res.data.discount_start_time)
          this.discounts.push(res.data.discount_end_time)
        }
        this.form = res.data
        this.upForm = false
      })
    }
  },
  mounted() {},
  methods: {
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.form.guest_buy === false && this.form.member_buy === false) {
            this.$message.error('会员购买必须选择一项')
            return false
          }
          if (this.discounts.length > 0) {
            this.form.discount_start_time = timestampToTimes(this.discounts[0].getTime()).slice(0, 20)
            this.form.discount_end_time = timestampToTimes(this.discounts[1].getTime()).slice(0, 20)
          }
          if (this.form.people_number === 1) this.form.people_number = this.people_number
          if (this.form.ask_number === 1) this.form.ask_number = this.ask_number

          if (this.form.guest_buy === true) this.form.guest_buy = 1
          else this.form.guest_buy = 0
          if (this.form.member_buy === true) this.form.member_buy = 1
          else this.form.member_buy = 0
          postAdd(this.form).then(() => {
            this.$message({
              type: 'success',
              message: '添加成功!'
            })
            this.returnPage()
          })
        } else {
          return false
        }
      })
    },
    // 编辑
    editForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.money === 2 && this.form.money === 0) {
            this.$message.error('售价不能为0！')
            return false
          }
          if (this.form.discount_on === 1 && this.discounts === null) {
            this.$message.error('优惠时间不能为空！')
            return false
          }
          if (this.form.guest_buy === false && this.form.member_buy === false) {
            this.$message.error('会员购买必须选择一项')
            return false
          }
          if (this.money === 1) {
            this.form.money = 0
            this.form.discount_on = 0
            this.form.discount = 0
            this.discounts = []
          }
          if (this.form.discount_on === 1 && typeof this.discounts[0] === 'string') {
            this.form.discount_start_time = this.discounts[0]
            this.form.discount_end_time = this.discounts[1]
          } else if (this.form.discount_on === 1) {
            this.form.discount_start_time = timestampToTimes(this.discounts[0].getTime()).slice(0, 20)
            this.form.discount_end_time = timestampToTimes(this.discounts[1].getTime()).slice(0, 20)
          }

          if (this.form.people_number === 1) this.form.people_number = this.people_number
          if (this.form.ask_number === 1) this.form.ask_number = this.ask_number

          if (this.form.guest_buy === true) this.form.guest_buy = 1
          else this.form.guest_buy = 0
          if (this.form.member_buy === true) this.form.member_buy = 1
          else this.form.member_buy = 0
          putMclass(this.form.id, this.form).then(() => {
            this.$message({
              type: 'success',
              message: '编辑成功!'
            })
            this.returnPage()
          })
        } else {
          return false
        }
      })
    },
    // 上传封面图
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.form.cover = response.data.path
    },
    upProgress() {
      this.upLoading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.formColumn {
  margin-top: 20px;
}
.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409eff;
}
.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
</style>
