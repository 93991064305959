var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box p-20" }, [
    _c(
      "div",
      { staticClass: " rela" },
      [
        _c(
          "el-menu",
          {
            staticClass: "el-menu-demo",
            attrs: {
              "default-active": _vm.key,
              mode: "horizontal",
              router: true
            }
          },
          [
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_orderRuleForCommon"),
                    expression: "btn_access('s_orderRuleForCommon')"
                  }
                ],
                attrs: { index: "/store/setting/orderRule/orderRuleForCommon" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_orderRuleForCommon")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_orderRuleForLeague"),
                    expression: "btn_access('s_orderRuleForLeague')"
                  }
                ],
                attrs: { index: "/store/setting/orderRule/orderRuleForLeague" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_orderRuleForLeague")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_orderRuleForFine"),
                    expression: "btn_access('s_orderRuleForFine')"
                  }
                ],
                attrs: { index: "/store/setting/orderRule/orderRuleForFine" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_orderRuleForFine")))]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_orderRuleForPrivate"),
                    expression: "btn_access('s_orderRuleForPrivate')"
                  }
                ],
                attrs: { index: "/store/setting/orderRule/orderRuleForPrivate" }
              },
              [_vm._v(_vm._s(_vm.$t("route.s_orderRuleForPrivate")))]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "p-y-10" },
          [_c("router-view", { key: _vm.key })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }