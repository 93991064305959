// 格式化js

// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils'
import Moment from 'moment' // 时间格式化

/**
 * 时间格式化 HH mm
 * @param {string} value
 * @returns
 */
export function formatDateLT(value) {
  return Moment(value).format('HH:mm')
}

/**
 * 格式化为YYYY-MM-DD HH:mm:ss
 * @param {string|number} value
 * @returns
 */
export function formatDate(value, format = 'YYYY-MM-DD HH:mm') {
  if (typeof value === 'number') {
    return Moment.unix(value).format(format)
  } else if (typeof value === 'string') {
    return Moment(value).format(format)
  }
}

/**
 * 解析换行符
 * @param {string} value
 * @returns
 */
export function formatBr(value) {
  return value.replace(/[\n\r\t]/g, '\r\n')
}

/**
 * 转整数
 * @param {string} value
 * @returns
 */
export function formatInt(value) {
  return parseInt(value)
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
