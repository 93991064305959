<template>
  <div class="title_style">
    <el-form ref="form" :model="form" :rules="formRules" size="small" label-width="80px">
      <!--  头像  -->
      <el-form-item label="头像" prop="avatar">
        <div class="avatar-box" @click="imagecropperShow = true">
          <el-avatar shape="square" :size="100" :src="form.avatar ? form.avatar : defaultLogo" title="点击修改" />
          <div class="avatar-hover">{{ form.avatar ? '更换头像' : '上传头像' }}</div>
        </div>
        <image-cropper
          v-show="imagecropperShow"
          :key="imagecropperKey"
          field="file"
          :width="300"
          :height="300"
          :url="storeUpload"
          lang-type="zh"
          @close="imagecropperClose"
          @crop-upload-success="cropSuccess"
        />
      </el-form-item>
      <!--  姓名  -->
      <el-form-item label="真实姓名" prop="real_name">
        <el-input v-model="form.real_name" placeholder="请填写真实姓名" />
      </el-form-item>
      <!--  性别  -->
      <el-form-item label="性别" prop="sex">
        <el-radio v-for="(item, index) in sexList" :key="index" v-model="form.sex" :label="item.value">
          {{ item.label }}
        </el-radio>
      </el-form-item>
      <!--  简介  -->
      <el-form-item label="简介">
        <el-input v-model="form.introduce" type="textarea" :rows="3" placeholder="请填简介" />
      </el-form-item>
      <el-form-item class="line-clear" label="个人封面" prop="cover">
        <el-upload
          v-loading="upLoading"
          class="images-uploader"
          :action="storeUpload"
          :show-file-list="false"
          :on-success="upSuccess"
          :headers="utils.upload_headers()"
          :on-progress="upProgress"
          accept=".jpg,.jpeg,.png"
        >
          <img v-if="form.cover" :src="form.cover" class="images" title="点击上传图片">
          <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
        </el-upload>
      </el-form-item>
      <!-- 按钮 -->
      <el-form-item>
        <el-button type="primary" @click="submit">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getUserInfo, editUserInfo } from '@/api/store/user.js'
import ImageCropper from '@/components/ImageCropper'

export default {
  components: { ImageCropper },
  props: {
    user: {
      type: Object,
      default: function() {
        return { name: '' }
      }
    }
  },
  data() {
    return {
      form: {
        id: '',
        avatar: '',
        sex: 0,
        birthday: '',
        cover: '',
        star_level: '',
        color: '#000000'
      },
      formRules: {
        real_name: [{ required: true, message: '请输入真实姓名', trigger: 'change' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        birthday: [{ required: true, message: '请选择生日', trigger: 'change' }]
      },
      fileList: [],
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      imagecropperShow: false,
      imagecropperKey: 0,
      store_user_id: ''
    }
  },
  mounted() {
    this.getUserDetails()
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          editUserInfo(this.form)
            .then(res => {
              this.$message.success('更新成功')
              location.reload()
            })
            .catch(() => {})
        }
      })
    },
    getUserDetails() {
      getUserInfo()
        .then(res => {
          this.form = res.data.storeInfo
          if (this.form.birthday === '0000-00-00') this.form.birthday = ''
        })
        .catch(() => {})
    },
    // 头像上传成功
    cropSuccess(resData) {
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      this.form.avatar = resData.path
    },
    // 头像上传弹出层关闭
    imagecropperClose() {
      this.imagecropperShow = false
    },
    // 修改用户信息
    editUser() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = this.form
          editUserInfo(data)
            .then(res => {})
            .catch(() => {})
        }
      })
    },
    //   上传风脉你图
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'cover', response.data.path)
    },
    upProgress() {
      this.upLoading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-box {
  display: unset;
}

.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.images-uploader .el-upload:hover {
  border-color: #409eff;
}

.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  line-height: 120px;
  text-align: center;
}
.class_color {
  width: 80px;
  height: 20px;
}
.title_style {
  width: 45%;
}
@media screen and (max-width: 768px) {
  .title_style {
    width: 100%;
  }
}
</style>
