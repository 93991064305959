<template>
  <el-card style="margin-bottom:20px;">
    <div slot="header" class="clearfix">
      <span>个人信息</span>
    </div>

    <div class="user-profile">
      <div class="box-center">
        <el-avatar :size="100" :src="storeInfo.avatar ? storeInfo.avatar : defaultLogo" />
      </div>
      <div class="box-center">
        <div class="user-name text-center">{{ storeInfo.real_name }}</div>
        <!-- <div class="user-role text-center text-muted">{{ user.role | uppercaseFirst }}</div> -->
      </div>
      <el-form style="margin-top: 10px;">
        <!--  星级  -->
        <el-form-item label="手机号">
          {{ storeInfo.phone }}
        </el-form-item>
        <!--  星级  -->
        <el-form-item label="星级">
          <el-rate v-model="storeInfo.star_level" style="margin: 5px 0 0 40px;" disabled :colors="colors" />
        </el-form-item>
        <!--  课程颜色  -->
        <el-form-item label="课程颜色">
          <div v-if="storeInfo.color">
            <div class="class_color" :style="{ background: storeInfo.color }" />
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div class="user-bio">
      <div class="user-education user-bio-section">
        <div class="user-bio-section-header">
          <svg-icon icon-class="education" />
          <span>个人简介</span>
        </div>
        <div class="user-bio-section-body">
          <div class="text-muted">{{ storeInfo.introduce ? storeInfo.introduce : '无' }}</div>
        </div>
      </div>
      <ul class="user-info">
        <!-- <li><div style="height: 100%"><svg-icon icon-class="login" /> 登录账号<div class="user-right">{{ user.username }}</div></div></li>
        <li><svg-icon icon-class="user1" /> 用户昵称 <div class="user-right">{{ user.nickName }}</div></li>
        <li><svg-icon icon-class="dept" /> 所属部门 <div class="user-right"> {{ user.dept.name }}</div></li>
        <li><svg-icon icon-class="phone" /> 手机号码 <div class="user-right">{{ user.phone }}</div></li>
        <li><svg-icon icon-class="email" /> 用户邮箱 <div class="user-right">{{ user.email }}</div></li>
        <li>
          <svg-icon icon-class="anq" /> 安全设置
          <div class="user-right">
            <a @click="$refs.pass.dialog = true">修改密码</a>
            <a @click="$refs.email.dialog = true">修改邮箱</a>
          </div>
        </li> -->
      </ul>
    </div>
  </el-card>
</template>

<script>
// import PanThumb from '@/components/PanThumb'
import { getUserInfo } from '@/api/store/user.js'

export default {
  // components: { PanThumb },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: '',
          email: '',
          avatar: '',
          role: ''
        }
      }
    }
  },
  data() {
    return {
      storeInfo: {},
      colors: ['#99A9BF', '#F7BA2A', '#FF9900']
    }
  },
  mounted() {
    getUserInfo()
      .then(res => {
        this.storeInfo = res.data.storeInfo
        if (this.form.birthday === '0000-00-00') this.form.birthday = ''
      })
      .catch(() => {})
  }
}
</script>

<style lang="scss" scoped>
.box-center {
  margin: 0 auto;
  display: table;
}

.text-muted {
  color: #777;
}

.user-profile {
  .user-name {
    font-weight: bold;
  }

  .box-center {
    padding-top: 10px;
  }

  .user-role {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }

  .box-social {
    padding-top: 30px;

    .el-table {
      border-top: 1px solid #dfe6ec;
    }
  }

  .user-follow {
    padding-top: 20px;
  }
}

.user-bio {
  margin-top: 20px;
  color: #606266;

  span {
    padding-left: 4px;
  }

  .user-bio-section {
    font-size: 14px;
    padding: 15px 0;

    .user-bio-section-header {
      border-bottom: 1px solid #dfe6ec;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
}
.class_color {
  width: 80px;
  height: 20px;
  margin: 5px 0 0 70px;
}
</style>
