<template>
  <div v-if="$route.name == 's_venues_employee'" class="app-container">
    <div class="filter-container">
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索姓名|手机号" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button
        v-show="btn_access('s_venues_employee_add')"
        type="primary"
        icon="el-icon-plus"
        class="filter-item m-l-10"
        @click="$router.push({ name: 's_venues_employee_add' })"
      >
        添加
      </el-button>
      <el-button
        v-show="utils.getVenues().id != -1"
        type="success"
        icon="el-icon-sort"
        class="filter-item m-l-10"
        @click="handleSort()"
      >
        排序
      </el-button>
    </div>
    <div class="originF">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="id === -1 ? '全部员工' : '馆内员工'" name="first" />
        <el-tab-pane v-if="id !== -1" label="全部员工" name="second" />
        <el-tab-pane v-show="btn_access('s_store_user_audit')" label="待审核" name="third" />
      </el-tabs>
      <div v-if="badgeValue > 0" class="origin" :class="id !== -1 ? 'originN' : 'originY'">
        {{ badgeValue > 99 ? '99+' : badgeValue }}
      </div>
    </div>
    <el-table ref="multipleTable" v-loading="handleLoading" :data="tableData.list" row-key="key">
      <el-table-column prop="avatar" label="员工信息">
        <template slot-scope="{ row }">
          <div v-if="isEmpty(row)" class="flex col-center cursor">
            <div>
              <el-avatar shape="square" :size="50" :src="row.avatar">
                <img :src="errorAvatarSrc(row, 'img')">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row) }}</span>
              <span v-if="row.state === 0 && id !== -1 && activeName === 'first'" class="user_part_time1">离 职</span>
              <span
                v-if="row.state === 1 && row.part_time === 1 && id !== -1 && activeName === 'first'"
                class="user_part_time"
              >
                兼 职
              </span>
              <br>
              <span>{{ row.phone ? row.phone : '--' }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName === 'second'" prop="sex" label="性别">
        <template slot-scope="{ row }">
          {{ formatTableSex(row) }}
        </template>
      </el-table-column>
      <el-table-column v-if="activeName === 'second'" prop="is_private" label="教练类型" min-width="140">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_gold === 1" class="m-r-5 m-b-5" type="danger">金牌教练</el-tag>
          <el-tag v-if="row.is_group" class="m-r-5 m-b-5">团课教练</el-tag>
          <el-tag v-if="row.is_private" type="warning">私教课教练</el-tag>
          <span v-if="!row.is_group && !row.is_private">无</span>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName === 'second'" prop="star_level" label="星级">
        <template slot-scope="{ row }">
          <el-rate :value="row.star_level >= 5 ? 5 : parseInt(row.star_level)" disabled text-color="#ff9900" />
        </template>
      </el-table-column>
      <el-table-column v-if="activeName === 'first'" label="教练类型">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_private === 1" type="warning" class="m-2">私教</el-tag>
          <el-tag v-if="row.is_group === 1" class="m-2">团课</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName === 'first'" prop="role_names" label="角色">
        <template slot-scope="{ row }">
          <div>{{ row.role_names }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="activeName === 'first'" prop="venues_name" label="场馆">
        <template slot-scope="{ row }">
          {{ row.venues_name }}
        </template>
      </el-table-column>
      <el-table-column v-if="activeName === 'third'" prop="audit_status" label="审批">
        <template slot-scope="{ row }">
          <el-tag v-if="row.audit_status === 1" type="success">通过审批</el-tag>
          <el-tag v-if="row.audit_status === 0" type="warning">待审批</el-tag>
          <el-tag v-if="row.audit_status === 2" type="danger">已拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="60">
        <template slot-scope="scope">
          <el-button v-show="activeName === 'first'" type="primary" icon="el-icon-info" @click="toPage(scope.row)">
            详 情
          </el-button>

          <el-button
            v-show="btn_access('s_management_delete') && activeName === 'second'"
            slot="reference"
            class="m-2"
            type="primary"
            icon="el-icon-edit"
            :offset="2"
            @click="$router.push({ name: 's_venues_employee_edit', params: { id: scope.row.id } })"
          >
            编辑
          </el-button>
          <el-popconfirm
            v-if="scope.row.is_super !== 1 && activeName === 'second'"
            title="删除后将无法找回，确定继续吗？"
            @confirm="store_user_delete(scope.row.id)"
          >
            <el-button
              v-show="btn_access('s_management_delete')"
              slot="reference"
              class="m-2"
              type="danger"
              icon="el-icon-delete"
              :offset="2"
            >
              删除
            </el-button>
          </el-popconfirm>

          <el-button
            v-show="activeName === 'third'"
            type="primary"
            icon="el-icon-info"
            @click="handleCaoZuo(scope.row)"
          >
            操 作
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0 && activeName === 'first'"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList('first')"
    />
    <pagination
      v-show="tableData.count > 0 && activeName === 'second'"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList('second')"
    />
    <el-dialog
      v-loading="toExamineLoading"
      title="员工审批"
      :visible.sync="editVisible.display"
      width="40%"
      custom-class="import-dialog"
      @close="handleClose"
    >
      <el-dialog
        width="20%"
        title="确定拒绝"
        :visible.sync="editVisible.displayMin"
        append-to-body
        @close="handleCloseMin"
      >
        <el-input
          v-model="textarea"
          type="textarea"
          show-word-limit
          :rows="6"
          maxlength="100"
          placeholder="拒绝原因[选填]"
        />
        <span slot="footer" class="dialog-footer">
          <el-button @click="editVisible.displayMin = false">取 消</el-button>
          <el-button type="primary" @click="handleSubmitMin">确 认</el-button>
        </span>
      </el-dialog>
      <div class="user_avatar">
        <el-avatar :size="80" :src="editVisible.list.avatar">
          <img :src="errorAvatarSrc(editVisible.list.sex, 'img')">
        </el-avatar>
        <div>当前：{{ editVisible.list.real_name }}</div>
        <div>申请时间：{{ editVisible.list.create_time }}</div>
      </div>
      <!-- 基本信息 -->
      <h5>基本信息</h5>
      <el-descriptions class="margin-top" :column="2" border :label-style="{ width: '100px' }">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-school" />
            姓名
          </template>
          {{ editVisible.list.real_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-school" />
            手机号
          </template>
          {{ editVisible.list.phone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-school" />
            性别
          </template>
          {{ editVisible.list.sex === 1 ? '男' : editVisible.list.sex === 2 ? '女' : '保密' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-school" />
            生日
          </template>
          {{ editVisible.list.birthday }}
        </el-descriptions-item>
        <el-descriptions-item label="员工封面图">
          <template>
            <el-image
              style="width: 150px"
              :src="
                editVisible.list.cover
                  ? editVisible.list.cover
                  : 'https://static.yuxiaojiu.cn/resources/yoga/coach_cover/1.png'
              "
              fit="cover"
              :preview-src-list="[editVisible.list.cover]"
            />
          </template>
        </el-descriptions-item>
      </el-descriptions>
      <el-form ref="form" class="m-t-20" label-position="left" :model="formData" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="角色权限" prop="role" label-width="100px">
              <div v-if="utils.empty(roleLists)" class="tal">
                <checkRole :type.sync="formData.type" :parent-selected.sync="rs" :list.sync="roleList" />
              </div>
              <div v-else>
                <el-collapse v-for="item in roleLists" :key="item.id" v-model="activeNames">
                  <el-collapse-item :title="item.name" :name="item.id">
                    <checkRole :type.sync="form.type" :parent-selected.sync="rs[item.id]" :list.sync="item.role" />
                  </el-collapse-item>
                </el-collapse>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="兼职老师">
              <el-switch v-model="formData.part_time" :active-value="1" :inactive-value="0" />
              <div v-show="formData.part_time === 1" class="word_text">兼职老师无法登录场馆只提供场馆作为临时教练</div>
            </el-form-item>
          </el-col>
          <el-col :spam="24">
            <el-form-item label="教练类型" prop="guest_buy">
              <el-checkbox v-model="formData.is_private" :true-label="1" :false-label="0" label="私教教练" />
              <el-checkbox v-model="formData.is_group" :true-label="1" :false-label="0" label="团课教练" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="私教教练">
              <el-switch v-model="formData.is_private" :active-value="1" :inactive-value="0" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="团课教练">
              <el-switch v-model="formData.is_group" :active-value="1" :inactive-value="0" />
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" style="display: block; text-align: center;">
        <el-button type="primary" @click="handleSubmit">通 过</el-button>
        <el-button type="danger" @click="handleRefuse">拒 绝</el-button>
        <el-button @click="handleCancel">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 排序弹窗 -->
    <sortDialog :dialog-sort="dialogSort" @sortDialog="sortDialog" />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { getStoreUser, getRole, delStoreUser } from '@/api/store/management.js'
import { getEmployee, getReviewedList, getEmployeeId, getVenues, postApproval } from '@/api/store/venues.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Utils from '@/utils/utils.js'
import checkRole from '@/components/All/checkRole'
import sortDialog from './components/sortDialog.vue'
import { getVenuesRole } from '@/api/store/venues.js'

export default {
  components: { Pagination, checkRole, sortDialog },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 数据
      form: {
        venues_id: this.utils.getVenues().id === -1 ? '' : this.utils.getVenues().id,
        role_id: '',
        store_user_id: '',
        state: 1,
        part_time: 0
      },
      // 弹窗
      dialog: {
        visible: false,
        id: null
      },
      storeUser: [], // 管理员列表
      roleList: [], // 角色列表
      rules: ['role_id', 'store_user_id', 'venues_id'],
      venues_list: {},
      searchLoading: false,
      activeName: 'first',
      handleLoading: false,
      id: Utils.getVenues().id,
      editVisible: {
        display: false,
        displayMin: false,
        id: null,
        list: {}
      },
      roleLists: {},
      activeNames: ['1'],
      rs: [],
      formData: {
        is_group: 0,
        is_private: 0,
        part_time: 0
      },
      textarea: '',
      badgeValue: 0,
      toExamineLoading: false,
      dialogSort: false,
      listQueryParams: {
        query: ''
      }
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.getList('first')
    getVenuesRole().then(res => {
      this.roleLists = res.data
    })
    getStoreUser({ limit: 999 })
      .then(res => {
        this.storeUser = res.data.list
      })
      .catch(() => {})
    getRole({ limit: 999 })
      .then(res => {
        this.roleList = res.data.list
      })
      .catch(() => {})
    getReviewedList()
      .then(res => {
        this.badgeValue = res.data.count
      })
      .catch(() => {})
  },
  methods: {
    // 获取列表
    getList(name) {
      this.handleLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) {
        if (name === 'first') { querys.push({ field: 'b.real_name|b.phone', type: 'like', key: this.listQueryParams.query }) }
        if (name === 'second') { querys.push({ field: 'account|phone|real_name', type: 'like', key: this.listQueryParams.query }) }
        if (name === 'third') querys.push({ field: 'real_name|phone', type: 'like', key: this.listQueryParams.query })
      }

      data.query = this.utils.getQueryParams(querys)

      if (name === 'first') {
        getEmployee(data)
          .then(res => {
            this.tableData = res.data
            this.handleLoading = false
          })
          .catch(() => {})
          .finally(() => {
            this.searchLoading = false
          })
      } else if (name === 'second') {
        getStoreUser(data)
          .then(res => {
            this.tableData = res.data
            this.handleLoading = false
          })
          .catch(() => {})
          .finally(() => {
            this.searchLoading = false
          })
      } else if (name === 'third') {
        getReviewedList(data)
          .then(res => {
            this.tableData = res.data
            this.handleLoading = false
          })
          .catch(() => {})
          .finally(() => {
            this.searchLoading = false
          })
      }
    },
    isEmpty(v) {
      return !this.utils.empty(v)
    },
    callback(msg) {
      this.$message.success(msg)
      this.getList()
    },
    // 获取场馆列表
    getVenuesList() {
      if (this.utils.empty(this.venues_list)) {
        getVenues({ limit: 999 })
          .then(res => {
            this.venues_list = res.data.list
          })
          .catch(() => {})
      }
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList(this.activeName)
    },
    // 格式化管理员显示label
    formatStoreName(item) {
      const n = []
      if (item.is_private) n.push('私教老师')
      if (item.is_group) n.push('团课老师')
      let s = item.real_name
      if (n.length > 0) s = `${s}(${n.join('/')})`
      return s
    },
    toPage(row) {
      if (row) {
        this.$router.push({
          name: 's_venues_employee_info',
          params: { id: row.id, store_user_id: row.store_user_id, venues_id: row.venues_id }
        })
      }
    },
    handleClick() {
      this.listQueryParams.query = ''
      this.tableData = {
        list: [],
        count: 0
      }
      this.listQuery = {
        page: 1,
        limit: 10
      }
      this.getList(this.activeName)
    },
    handleCaoZuo(row) {
      this.editVisible.display = true
      this.toExamineLoading = true
      this.editVisible.id = row.id
      getEmployeeId(row.id).then(res => {
        this.editVisible.list = res.data
        this.toExamineLoading = false
      })
    },
    // 同意操作
    handleSubmit() {
      var roles = []
      if (this.rs) {
        this.rs.forEach((v, i) => {
          v.forEach((v2, i2) => {
            roles.push({
              venues_id: i,
              role_id: v2,
              part_time: this.formData.part_time
              // store_user_id: 1
            })
          })
        })
        if (roles.length !== 0) {
          const data = {
            audit_status: 1,
            employee: roles,
            is_group: this.formData.is_group,
            is_private: this.formData.is_private
          }
          postApproval(this.editVisible.id, data).then(res => {
            this.callback('操作成功')
            this.editVisible.display = false
            window.location.reload()
          })
        } else {
          this.$message.error('角色权限不能为空')
        }
      }
    },
    // 拒绝操作
    handleRefuse() {
      this.editVisible.displayMin = true
    },
    // 关闭时触发
    handleClose() {
      console.log('close')
      this.formData = {
        is_group: 0,
        is_private: 0,
        part_time: 0
      }
    },
    // 内层关闭时
    handleCloseMin() {
      this.textarea = ''
    },
    // 内层确认
    handleSubmitMin() {
      const data = {
        audit_status: 2,
        audit_notice: this.textarea
      }
      postApproval(this.editVisible.id, data).then(res => {
        this.callback('操作成功')
        this.editVisible.display = false
        this.editVisible.displayMin = false
        // this.getList('third')
        window.location.reload()
      })
    },
    handleCancel() {
      this.editVisible.display = false
    },
    handleSort() {
      this.dialogSort = true
    },
    sortDialog() {
      this.dialogSort = false
      this.getList('first')
      this.activeName = 'first'
    },
    // 删除管理员
    store_user_delete(id) {
      delStoreUser(id).then(res => {
        this.getList()
        this.$message.success(res.msg)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user_avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    margin-top: 10px;
  }
}
.originF {
  position: relative;
  .origin {
    width: 22px;
    line-height: 22px;
    background-color: #f56c6c;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    position: absolute;
    top: 0px;
    color: #ffffff;
  }
  .originY {
    left: 140px;
  }
  .originN {
    left: 235px;
  }
}
//弹出层的高度
::v-deep .el-dialog.import-dialog {
  height: auto;
  max-height: 80vh;
}
//弹出层里内容的高度
::v-deep .el-dialog__body {
  max-height: 60vh !important;
  overflow-y: auto;
}
.user_part_time1 {
  display: inline-block;
  width: 50px;
  height: 25px;
  line-height: 25px;
  background-color: #f56c6c;
  text-align: center;
  border-radius: 4px;
  margin-left: 4px;
  color: #ffffff;
}
.user_part_time {
  display: inline-block;
  width: 50px;
  height: 25px;
  line-height: 25px;
  background-color: #ffd099;
  text-align: center;
  border-radius: 4px;
  margin-left: 4px;
  color: #ffffff;
}
.word_text {
  font-size: 12px;
  color: #9c9c9c;
  margin-left: -80px;
}
</style>
