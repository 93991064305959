var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    _vm._b(
      {
        attrs: { trigger: "click" },
        model: {
          value: _vm.visible,
          callback: function($$v) {
            _vm.visible = $$v
          },
          expression: "visible"
        }
      },
      "el-popover",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        { staticClass: "el-popconfirm" },
        [
          _vm.title
            ? _c("p", { staticClass: "el-popconfirm__main" }, [
                !_vm.hideIcon
                  ? _c("i", {
                      staticClass: "el-popconfirm__icon",
                      class: _vm.icon,
                      style: { color: _vm.iconColor }
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ])
            : _vm._t("default"),
          _c(
            "div",
            { staticClass: "el-popconfirm__action" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: _vm.cancelButtonType },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" " + _vm._s(_vm.displayCancelButtonText) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: _vm.confirmButtonType },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" " + _vm._s(_vm.displayConfirmButtonText) + " ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._t("reference", null, { slot: "reference" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }