<template>
  <div v-if="utils.getVenues()&&utils.getVenues().id!=-1" class="slide_header">
    <div class="flex col-center row-center slide_header_box">
      <div v-show="btn_access('s_workbench_sign_qrcode')" class="qiandao cursor slide_header_item" @click="qiandao_action()"><div>
        <span class="svg-container">
          <svg-icon icon-class="signin" />
        </span>
      </div><span>签到</span></div>
      <div class="yuetuanke cursor slide_header_item" @click="yuetuanke_action()"><div>
        <span class="svg-container">
          <svg-icon icon-class="groupclass" />
        </span>
      </div><span>约团课</span></div>
      <div class="yuesijiao cursor slide_header_item" @click="yuesijiao_action()"><div>
        <span class="svg-container">
          <svg-icon icon-class="privateclass" />
        </span>
      </div><span>约私教</span></div>
    </div>
    <!-- 弹出层管理 -->
    <div class="dialog_box">
      <!-- 二维码签到 -->
      <el-dialog
        title="签到"
        class="dialog_auto"
        append-to-body
        modal-append-to-body
        :close-on-click-modal="false"
        :visible.sync="dialog.qrcode_visible"
        :center="true"
      >
        <QrcodeSign />
      </el-dialog>
      <!-- 约团课 -->
      <GroupBook v-if="dialog.yuetuanke_visible" :group-visible.sync="dialog.yuetuanke_visible" />
      <!-- 约私教 -->
      <PrivateBook v-if="dialog.yuesijiao_visible" :group-visible.sync="dialog.yuesijiao_visible" />
      <PrivateBookS v-if="dialog.yuesijiaoS_visible" :group-visible.sync="dialog.yuesijiaoS_visible" />
    </div>
  </div>

</template>

<script>
import QrcodeSign from '@/views/store/qrcode' // 二维码签到
import GroupBook from '@/views/store/worktable/group.vue' // 团课预约
import PrivateBook from '@/views/store/worktable/private.vue' // 私教预约
import PrivateBookS from '@/views/store/worktable/PrivateBookS.vue' // 私教预约
// API
import { getSubscribeSettingInfo } from '@/api/store/subscribe'
export default {
  name: 'SideHeader',
  components: { QrcodeSign, GroupBook, PrivateBook, PrivateBookS },
  data() {
    return {
      dialog: { qrcode_visible: false, yuetuanke_visible: false, yuesijiao_visible: false, yuesijiaoS_visible: false },
      is_display: false
    }
  },
  methods: {
    qiandao_action() {
      this.dialog.qrcode_visible = true
    },
    // 约课触发
    yuetuanke_action() {
      this.dialog.yuetuanke_visible = true
    },
    // 私教触发
    async yuesijiao_action() {
      console.log(123)
      console.log(localStorage.getItem('authority'))
      if (localStorage.getItem('authority') === 'store') {
        const SubscribeSettingInfo = await getSubscribeSettingInfo('private_subscribe')
        if (SubscribeSettingInfo.data.config) {
          if (SubscribeSettingInfo.data.config.type === 1) {
            this.dialog.yuesijiaoS_visible = true
          } else {
            this.dialog.yuesijiao_visible = true
          }
        } else {
          this.dialog.yuesijiao_visible = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hideSidebar .slide_header{
  display: none;
}
.openSidebar .slide_header{
  display: block;
}
.slide_header{
  // padding-top: 10px;
  margin-bottom: 10px;
  height: 80px;
  color:#fff;
  text-align: center;
  // background: red;
  img{
    // display: block;
    width:40px;
  }
  .slide_header_box{
    padding:0px 5px;
    span{
      font-size: 10px;
      font-weight: lighter;
    }
    svg{
      font-size: 35px;
      padding: 3px;
      margin: 3px;
      margin-right:3px !important;
      margin-bottom: 5px;
    }
  }
  .slide_header_box .qiandao{
    background-image: linear-gradient(185deg,#ffc494,#ff7c0f);
    box-shadow: 0px 15px 10px -15px #ff9e4e;
    // background: #ff9e4e;
    // background-image: linear-gradient(#ffd8b9, #ff7a0d);
    // box-shadow: inset 0 0 100px hsl(0deg 0% 0% / 30%);
  }
  .slide_header_box .yuesijiao{
    background-image: linear-gradient(185deg,#6ce1ff,#0066e8);
    box-shadow: 0px 15px 10px -15px #2082ff;
    // background: #4094ff;
  }
  .slide_header_box .yuetuanke{
    background-image: linear-gradient(185deg,#7dee7d,#15a615);
    box-shadow: 0px 15px 10px -15px #1ccf1c;
    // background: #1ccf1c;
  }
  .slide_header_box .slide_header_item{
    text-align: center;
    width: 100px;
    height: 70px;

    border-radius: 13px;
    padding:5px 0px;
    margin: 3px;
  }

  .slide_header_box .slide_header_item:hover{
    margin-top: 1px;
  }

  span{
    font-size: 15px;
    color:#fff;
  }
}
</style>
