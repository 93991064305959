import request from '@/utils/request'

// 获取日志列表
export function getLogList(data) {
  return request({
    url: '/admin/v1/store/money_record',
    method: 'get',
    params: data
  })
}

// 获取日志详情
export function getLogDetails(id) {
  return request({
    url: `/admin/v1/store/money_record/${id}`,
    method: 'get'
  })
}

// 获取指定品牌日志
export function getBrandLogList(data, id) {
  return request({
    url: `/admin/v1/store/money_record/app_log/${id}`,
    method: 'get',
    params: data
  })
}

// 获取操作日志列表
export function getOperateLogList(data) {
  return request({
    url: '/admin/v1/store/operation_log',
    method: 'get',
    params: data
  })
}

// 获取后台操作日志
export function getAdminOperateLogList(params) {
  return request({
    url: '/admin/v1/system/operation_log',
    method: 'get',
    params
  })
}

// 获取品牌变更记录
export function getBrandChangeLogList(params) {
  return request({
    url: `/admin/v1/app/change_log`,
    method: 'get',
    params
  })
}

// 获取操作日志详情
export function getOperateLogDetails(data, id) {
  return request({
    url: `/admin/v1/store/operation_log/${id}`,
    method: 'get',
    params: data
  })
}

// 获取指定品牌操作日志
export function getBrandOperateLogList(data, id) {
  return request({
    url: `/admin/v1/store/operation_log/app_log/${id}`,
    method: 'get',
    params: data
  })
}
