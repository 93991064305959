import request from '@/utils/request'

// 角色、品牌

// 获取管理员列表
export function getStoreUser(data) {
  return request({
    url: `/store/v1/store_user`,
    method: 'get',
    params: data
  })
}
// 添加管理员
export function addStoreUser(data) {
  return request({
    url: '/store/v1/store_user',
    method: 'post',
    data
  })
}
// 编辑管理员
export function editStoreUser(id, data) {
  return request({
    url: '/store/v1/store_user/' + id,
    method: 'put',
    data
  })
}
// 获取管理员详情
export function getStoreUserDetails(id) {
  return request({
    url: `/store/v1/store_user/${id}`,
    method: 'get'
  })
}
// 删除管理员
export function delStoreUser(id) {
  return request({
    url: `/store/v1/store_user/${id}`,
    method: 'delete'
  })
}

// 获取角色列表
export function getRole(data) {
  return request({
    url: `/store/v1/role`,
    method: 'get',
    params: data
  })
}
// 添加角色
export function addRole(data) {
  return request({
    url: '/store/v1/role',
    method: 'post',
    data
  })
}
// 获取角色信息
export function getRoleInfo(id, data) {
  return request({
    url: `/store/v1/role/${id}`,
    method: 'get',
    data
  })
}
// 编辑角色信息
export function setRoleInfo(id, data) {
  return request({
    url: `/store/v1/role/${id}`,
    method: 'put',
    data
  })
}

// 获取角色属性列表
export function getRoleAttribute() {
  return request({
    url: `/store/v1/role_attribute`,
    method: 'get'
  })
}
// 获取权限列表
export function getAccess() {
  return request({
    url: '/store/v1/system_access',
    method: 'get'
  })
}

// 获取品牌信息
export function getAppInfo() {
  return request({
    url: `/store/v1/app`,
    method: 'get'
  })
}
// 更改品牌信息
export function editAppInfo(data) {
  return request({
    url: `/store/v1/app`,
    method: 'put',
    data
  })
}

// 刷新品牌信息
export function refreshAppInfo() {
  return request({
    url: `/store/v1/app/refresh`,
    method: 'put'
  })
}

// 获取反馈列表
export function getFeedback(data) {
  return request({
    url: `/store/v1/feedback`,
    method: 'get',
    params: data
  })
}

// 提现记录
export function getWithdraw(data) {
  return request({
    url: `/store/v1/withdraw`,
    method: 'get',
    params: data
  })
}

// 发起提现
export function setWithdraw(data) {
  return request({
    url: `/store/v1/withdraw`,
    method: 'post',
    data
  })
}

// 删除员工相册
export function deleteImges(id) {
  return request({
    url: `/store/v2/store_user/photo/${id}`,
    method: 'delete'
  })
}

// 行业列表
export function industryList() {
  return request({
    url: `/store/v2/industry`,
    method: 'get'
  })
}
// 切换行业
export function industrySwitch(data) {
  return request({
    url: '/store/v2/industry/cut',
    method: 'put',
    data
  })
}
// 一键离职
export function postQuit(id) {
  return request({
    url: `/store/v1/employee/un_job/${id}`,
    method: 'post'
  })
}

// 获取公众号二维码
export function getQrcode(data) {
  return request({
    url: '/store/v1/poster_code',
    method: 'post',
    data
  })
}

// 获取特殊配置
export function getSpecialConfig(name) {
  return request({
    url: `/store/v1/special_config/${name}`,
    method: 'get'
  })
}

// 编辑特殊配置
export function setSpecialConfig(name, data) {
  return request({
    url: `/store/v1/special_config/${name}`,
    method: 'put',
    data
  })
}
