<template>
  <div>
    <el-dialog id="mapDialog" title="定位设置" :visible.sync="mapVisible" :before-close="close" width="65%" top="8vh" :close-on-click-modal="false">
      <div>
        <el-select
          v-model="search"
          class="select"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="请输入地址"
          :remote-method="mapNameChange"
          :loading="loading"
          style="width: 100%;"
          @clear="clearSearch"
          @change="mapNameChange($event,'change')"
        >
          <el-option
            v-for="item in searchOption"
            :key="item.title"
            :label="item.title"
            :value="item.title"
          />
        </el-select>
      </div>
      <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        :ak="ak"
        @ready="handler"
        @click="clickEvent"
      >
        <!-- 地图控件位置 -->
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
        <!-- 城市列表 -->
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT" />
        <!-- 定位当前位置 -->
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :show-address-bar="true" :auto-location="true" @locationSuccess="getLoctionSuccess" />
        <!-- 地图容器 -->
        <bm-view :style="{width:'100%',height: clientHeight+'px',flex: 1,marginBottom:'-30px'}" />
      </baidu-map>
      <div class="demo-input-suffix">
        <el-link type="info">lng：</el-link><el-input v-model.number="locData.longitude" class="lineinput" style="width:100px" size="mini" />
        <el-link type="info">lat：</el-link><el-input v-model.number="locData.latitude" class="lineinput" style="width:100px" size="mini" />
        <el-link type="info">地址：</el-link><el-input v-model="locData.address" class="lineinput" style="width:250px" size="mini" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="warning" size="small" icon="el-icon-close" @click.native="close">取消</el-button>
        <el-button type="primary" size="small" icon="el-icon-check" @click.native="findlocation">保存</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
/*global BMap map*/
let geoc = null
import { BaiduMap, BmNavigation, BmView, BmGeolocation, BmCityList } from 'vue-baidu-map'
export default {
  name: 'MapDialog',
  components: {
    BaiduMap,
    BmNavigation,
    BmView,
    BmGeolocation,
    BmCityList
  },
  props: {
    mapVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ak: 'iKEvxjhWVIC47yKkA84MXQtfhV4jqDHa',
      center: { lng: 116.41854, lat: 39.887576 },
      zoom: 12,
      locData: {
        longitude: '',
        latitude: '',
        address: '',
        provinces: '',
        citys: '',
        areas: ''
      },
      clientHeight: document.documentElement.clientHeight - 450, // 屏幕高度
      iconUrl: require('@/assets/images/location.png'),
      search: '',
      searchOption: [],
      loading: false
    }
  },
  mounted() {
  },
  methods: {
    handler({ BMap, map }) {
      const _this = this	// 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
      var geolocation = new BMap.Geolocation()
      geolocation.getCurrentPosition(function(r) {
        // console.log(r);
        _this.center = { lng: r.longitude, lat: r.latitude }		// 设置center属性值
        _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude }		// 自定义覆盖物
        _this.initLocation = true
      }, { enableHighAccuracy: true })

      window.map = map
    },
    // 点击地图监听
    clickEvent(e) {
      map.clearOverlays()
      const Icon_0 = new BMap.Icon(this.iconUrl, new BMap.Size(64, 64), { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 36) })
      var myMarker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat), { icon: Icon_0 })
      map.addOverlay(myMarker)
      // 用所定位的经纬度查找所在地省市街道等信息
      var point = new BMap.Point(e.point.lng, e.point.lat)
      var gc = new BMap.Geocoder()
      const _this = this
      gc.getLocation(point, function(rs) {
        // console.log('🚀 ~ file: index.vue ~ line 124 ~ gc.getLocation ~ rs', rs)
        _this.locData.provinces = rs.addressComponents.province
        _this.locData.citys = rs.addressComponents.city
        _this.locData.areas = rs.addressComponents.district
        // var addComp = rs.addressComponents
        // console.log(rs.address)// 地址信息
        _this.locData.address = rs.address
      })
      this.locData.longitude = e.point.lng
      this.locData.latitude = e.point.lat
    },
    // 定位成功回调
    getLoctionSuccess(point, AddressComponent, marker) {
      map.clearOverlays()
      const Icon_0 = new BMap.Icon(this.iconUrl, new BMap.Size(64, 64), { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 36) })
      var myMarker = new BMap.Marker(new BMap.Point(point.point.lng, point.point.lat), { icon: Icon_0 })
      map.addOverlay(myMarker)
      this.locData.longitude = point.point.lng
      this.locData.latitude = point.point.lat
    },
    mapNameChange(e, data) {
      this.loading = true
      if (!e && !this.search) {
        return false
      }
      const that = this
      let point
      let marker = null
      geoc = new BMap.Geocoder()
      const local = new BMap.LocalSearch(map, {
        renderOptions: { map: map },
        onSearchComplete: res => {
          this.loading = false
          if (res) {
            if (data !== 'change') {
              that.searchOption = res.Ir
            }
            if (res.Hr) {
              if (res.Hr.length > 0) {
                that.$set(that.locData, 'longitude', res.Hr[0].point.lng)
                that.$set(that.locData, 'latitude', res.Hr[0].point.lat)
              }
            }
            if (res.Ir) {
              if (res.Ir.length > 0) {
                that.$set(that.locData, 'longitude', res.Ir[0].point.lng)
                that.$set(that.locData, 'latitude', res.Ir[0].point.lat)
              }
            }
          }
          if (local.getResults() !== undefined) {
            map.clearOverlays() // 清除地图上所有覆盖物
            if (local.getResults().getPoi(0)) {
              point = local.getResults().getPoi(0).point // 获取第一个智能搜索的结果
              map.centerAndZoom(point, 10)
              marker = new BMap.Marker(point) // 创建标注
              map.addOverlay(marker) // 将标注添加到地图中
              marker.enableDragging() // 可拖拽
              geoc.getLocation(point, function(rs) {
                var addComp = rs.addressComponents
                that.$set(that.locData, 'address', addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber)
              })
            } else {
              this.$message('未匹配到地点!可拖动地图上的红色图标到精确位置')
            }
          } else {
            this.$message('未找到搜索结果')
          }
        }
      })
      local.search(e || this.search)
    },
    findlocation() {
      this.$emit('mapLocationSave', this.locData)
    },
    clearSearch() {
      this.loading = false
    },
    close() {
      this.$emit('mapLocationClose')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.map{
		margin-top: 5px;
	}
.demo-input-suffix{
	width: 100%;
	margin-top: 40px;
}
.BMap_bubble_title a{  /* 隐藏搜索结果的详情按钮 */
	display: none;
}
.anchorBL{display:none} /* 去除百度地图左下角的介绍文字 */
</style>

