<template>
  <div class="app-container">
    <div class="createPost-container">
      <el-form ref="postForm" :model="postForm" :rules="rules" class="form-container" label-width="80px" label-position="left">
        <!-- <el-form-item prop="name">
			<MDinput v-model="postForm.name" :maxlength="100" name="name" required>
				标题
			</MDinput>
			</el-form-item> -->
        <el-form-item label="标题" prop="title">
          <el-input v-model="postForm.title" placeholder="标题" />
        </el-form-item>

        <el-form-item label="内容" prop="content">
          <Tinymce ref="editor" v-model="postForm.content" :init="defaultSetting" />
        </el-form-item>
        <el-form-item label="排序" prop="weigh">
          <el-input v-model="postForm.weigh" type="number" placeholder="排序" />
        </el-form-item>
        <el-form-item label="是否可见">
          <el-switch
            v-model="postForm.is_hidden"
            :active-value="0"
            :inactive-value="1"
          />
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button type="primary" size="small" @click="submitForm">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import MDinput from '@/components/MDinput'
import Tinymce from '@/components/Tinymce'
import { getNoticeDetails, editNotice } from '@/api/admin/system.js'
export default {
  components: { Tinymce },
  data() {
    return {
      postForm: { is_hidden: 0, weigh: 100, content: '', title: '' },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        weigh: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }]
      },
      // 自定义样式
      defaultSetting: {
        content_style: 'img {max-width:100%;}'
      }
    }
  },
  created() {

  },
  mounted() {
    this.utils.setPageTitle(this.$route, '编辑公告')
    this.utils.setTagsViewTitle(this.$route, '编辑公告')
    getNoticeDetails(this.$route.params.id).then(res => {
      this.postForm = res.data
    }).catch(() => {})
  },
  methods: {
    submitForm() {
      this.$refs.postForm.validate(valid => {
        if (valid) {
          const data = {
            title: this.postForm.title,
            content: this.postForm.content,
            weigh: this.postForm.weigh,
            is_hidden: this.postForm.is_hidden
          }
          editNotice(this.$route.params.id, data).then(res => {
            this.$message.success('更新成功')
            this.$router.push({ name: 'announcement' })
          }).catch(() => {})
        } else {
          this.$message.error('请填写完整')
        }
      })
    }
  }
}
</script>

<style>
</style>
