var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box p-20" }, [
    _c(
      "div",
      { staticClass: " rela" },
      [
        _c(
          "el-menu",
          {
            staticClass: "el-menu-demo",
            attrs: {
              "default-active": _vm.key,
              mode: "horizontal",
              router: true
            }
          },
          [
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_record_group_list"),
                    expression: "btn_access('s_record_group_list')"
                  }
                ],
                attrs: { index: "/store/work/console/group" }
              },
              [_vm._v("团课预约记录")]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_record_group_list"),
                    expression: "btn_access('s_record_group_list')"
                  }
                ],
                attrs: { index: "/store/work/console/boutique" }
              },
              [_vm._v("精品课程预约记录")]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_record_private_list"),
                    expression: "btn_access('s_record_private_list')"
                  }
                ],
                attrs: { index: "/store/work/console/private" }
              },
              [_vm._v("私教预约记录")]
            ),
            _c(
              "el-menu-item",
              { attrs: { index: "/store/work/console/class" } },
              [_vm._v("班课签到")]
            ),
            _c(
              "el-menu-item",
              { attrs: { index: "/store/work/console/inquire" } },
              [_vm._v("客询管理")]
            ),
            _c(
              "el-menu-item",
              { attrs: { index: "/store/work/console/leaveManagement" } },
              [_vm._v("请假管理")]
            ),
            _c(
              "el-menu-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_workbench_promise_penalty"),
                    expression: "btn_access('s_workbench_promise_penalty')"
                  }
                ],
                attrs: { index: "/store/work/console/penalty" }
              },
              [_vm._v("爽约惩罚 ")]
            ),
            _c(
              "el-menu-item",
              { attrs: { index: "/store/work/console/locker" } },
              [_vm._v("储物柜")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "p-y-10" },
          [_c("router-view", { key: _vm.key })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }