<template>
  <div class="app-container special-center">
    <el-page-header content="添加会员卡" class="page-header" @back="returnPage()" />
    <el-divider />
    <el-form ref="ruleForm" :rules="rules" :model="form" label-position="left" label-width="140px">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="会员卡名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入会员卡名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="种类" prop="kind">
            <el-radio v-model="form.kind" :label="0">常规</el-radio>
            <el-radio v-model="form.kind" :label="1">体验</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="会员卡类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择" @change="typeChange">
              <el-option v-for="(item, index) in card_type" :key="item.id" :label="item" :value="index" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="售价" prop="price">
            <el-input v-model="form.price" type="number" placeholder="请输入售价" min="0">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-show="!type_hidden" :span="12">
          <el-form-item label="卡额度" prop="limit">
            <el-input v-model="form.limit" type="number" placeholder="请输入卡额度">
              <template slot="append">{{ limit_type }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="可请假天数" prop="leave_day">
            <el-input v-model="form.leave_day" type="number" placeholder="请输入可请假天数">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="可请假次数" prop="leave_number">
            <el-input v-model="form.leave_number" type="number" placeholder="请输入可请假次数">
              <template slot="append">次</template>
            </el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="有效期" prop="digital">
            <el-input
              ref="v_digital"
              v-model="form.valid_period.digital"
              type="number"
              placeholder="有效期"
              class="input-with-select"
            >
              <el-select
                ref="v_type"
                slot="append"
                v-model="form.valid_period.type"
                placeholder="请选择"
                style="width:60px"
              >
                <el-option label="天" value="day" checked />
                <el-option label="月" value="month" />
                <el-option label="年" value="year" />
              </el-select>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="适用场馆">
            <el-input :value="venues_name === '' ? '所有场馆' : venues_name" placeholder="请输入会员卡名称" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="适用课程" prop="venuesCourse">
            <div v-if="utils.empty(courseLists)" class="tal">
              <el-collapse v-model="activeNames">
                <el-collapse-item title="点击折叠/展开" name="1">
                  <checkboxInputSon
                    :type.sync="form.type"
                    :parent-selected.sync="form.venuesCourse"
                    :list.sync="courseList"
                    :is-add.sync="is_add"
                  />
                </el-collapse-item>
              </el-collapse>
            </div>
            <div v-else>
              <el-collapse v-for="item in courseLists" :key="item.id" v-model="activeNames">
                <el-collapse-item :title="item.name" :name="item.id">
                  <checkboxInputSon
                    :type.sync="form.type"
                    :parent-selected.sync="form.venuesCourse[item.id]"
                    :list.sync="item.list"
                    :is-add.sync="is_add"
                  />
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 高级设置 -->
      <el-row>
        <el-col :span="24">
          <div class="flex cursor font-14 m-y-10" @click="settingShow = !settingShow">
            <div>
              高级设置
              <i class="el-icon-arrow-down" />
            </div>
            <div class="m-l-55">（提示：高级设置可设置该卡的预约限额、可用时间等，注：该限制仅针对客户端预约）</div>
          </div>
          <div v-show="settingShow" class="m-y-10 settings">
            <!-- 卡相关设置 -->
            <div>
              <div class="title">
                卡相关设置
              </div>
              <div class="config">
                <!-- 请假设置 -->
                <el-form-item label="请假设置" prop="dayparting_select">
                  <el-radio v-model="form.setting.leave_option" :label="0">不允许</el-radio>
                  <el-radio v-model="form.setting.leave_option" :label="1">不限制</el-radio>
                  <el-radio v-model="form.setting.leave_option" :label="2">允许</el-radio>
                </el-form-item>
                <el-row v-if="form.setting.leave_option == 2">
                  <!-- 可请假次数 -->
                  <el-col :span="12">
                    <el-form-item label="可请假次数(次)" label-width="110px">
                      <el-input v-model="form.setting.leave_number" type="number" placeholder="请输入请假次数" style="width:180px">
                        <template slot="append">次</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <!-- 可请假天数 -->
                  <el-col :span="12">
                    <el-form-item label="可请假天数(天)" label-width="110px">
                      <el-input v-model="form.setting.leave_day" type="number" placeholder="请输入请假天数" style="width:180px">
                        <template slot="append">天</template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item
                  label="单节课可约人数上限"
                  prop="single_subscribe_limit"
                  :inline-message="true"
                  label-width="150px"
                >
                  <el-input
                    v-model="form.setting.single_subscribe_limit"
                    type="number"
                    placeholder="默认单课可约1人"
                    min="1"
                    max="10"
                    style="width:140px"
                  />
                </el-form-item>
                <el-form-item label="每日可约次数上限" prop="every_day_subscribe_limit" label-width="150px">
                  <el-input
                    v-model="form.setting.every_day_subscribe_limit"
                    type="number"
                    placeholder="不限制预约次数"
                    style="width:140px"
                  />
                </el-form-item>
                <el-form-item label="每周可约次数上限" prop="every_week_subscribe_limit" label-width="150px">
                  <el-input
                    v-model="form.setting.every_week_subscribe_limit"
                    type="number"
                    placeholder="不限制预约次数"
                    style="width:140px"
                  />
                </el-form-item>
                <el-form-item label="每月可约次数上限" prop="every_month_subscribe_limit" label-width="150px">
                  <el-input
                    v-model="form.setting.every_month_subscribe_limit"
                    type="number"
                    placeholder="不限制预约次数"
                    style="width:140px"
                  />
                </el-form-item>
                <el-form-item label="可预约天数" prop="subscribe_day">
                  <div class="flex">
                    <div>会员最多可预约</div>
                    <div class="m-x-5">
                      <el-input
                        v-model="form.setting.subscribe_day"
                        type="number"
                        placeholder="不限制"
                        style="width:120px"
                      />
                    </div>
                    <div>天的课程，0表示当天</div>
                  </div>
                </el-form-item>
                <el-form-item label="可用时间" prop="dayparting_select">
                  <el-radio v-model="form.setting.dayparting_select" :label="1">全时段</el-radio>
                  <el-radio v-model="form.setting.dayparting_select" :label="2">自定义时段</el-radio>
                </el-form-item>
                <!-- 可用时间配置 -->
                <div v-show="form.setting.dayparting_select == 2" class="dayparting_config">
                  <div class="title">可用时间内才可预约</div>
                  <div>
                    <el-checkbox-group v-model="dayparting_days">
                      <el-checkbox :label="1" class="m-b-10">周一</el-checkbox>
                      <el-checkbox :label="2" class="m-b-10">周二</el-checkbox>
                      <el-checkbox :label="3" class="m-b-10">周三</el-checkbox>
                      <el-checkbox :label="4" class="m-b-10">周四</el-checkbox>
                      <el-checkbox :label="5" class="m-b-10">周五</el-checkbox>
                      <el-checkbox :label="6" class="m-b-10">周六</el-checkbox>
                      <el-checkbox :label="7" class="m-b-10">周日</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="title">可用时段(最多3个)</div>
                  <div class="">
                    <div v-for="(item, index) in dayparting_time_config" :key="index" class="flex m-y-5">
                      <div>
                        <el-time-picker
                          v-model="item.stime"
                          :clearable="false"
                          style="width:120px"
                          format="HH:mm"
                          value-format="HH:mm"
                          arrow-control
                        />
                      </div>
                      <div class="m-x-5">至</div>
                      <div>
                        <el-time-picker
                          v-model="item.etime"
                          :clearable="false"
                          style="width:120px"
                          format="HH:mm"
                          value-format="HH:mm"
                          arrow-control
                        />
                      </div>
                      <div v-if="index != 0" class="m-l-5">
                        <el-button icon="el-icon-delete" type="danger" @click="delTime(item)">删除</el-button>
                      </div>
                    </div>
                    <div class="m-y-10">
                      <el-button
                        v-show="dayparting_time_config.length < 3"
                        icon="el-icon-plus"
                        plain
                        type="success"
                        @click="addTime"
                      >
                        新增可用时段
                      </el-button>
                    </div>
                  </div>
                </div>
                <div v-if="form.setting.leave_option == 2" class="boxs">
                  提示：请至少设置课请假次数/天数中的一项
                </div>
              </div>
            </div>
            <!-- 用卡人相关设置 -->
            <div>
              <div class="title">用卡人相关设置</div>
              <div class="config">
                <el-form-item label="每日约课间隔时间限制" prop="every_interval_limit" label-width="160px">
                  <div class="flex">
                    <div class="m-x-5">
                      <el-input
                        v-model="form.setting.every_interval_limit"
                        type="number"
                        placeholder="不限制"
                        style="width:120px"
                      />
                    </div>
                    <div>分钟内不可连续约课</div>
                  </div>
                </el-form-item>
                <el-form-item label="预约次数限制" prop="not_finished_subscribe_limit" label-width="160px">
                  <div class="flex">
                    <div>未结束课程预约次数限制为</div>
                    <div class="m-x-5">
                      <el-input
                        v-model="form.setting.not_finished_subscribe_limit"
                        type="number"
                        placeholder="不限制"
                        style="width:120px"
                      />
                    </div>
                    <div>次，0表示不限制</div>
                  </div>
                </el-form-item>
                <el-form-item label="会员取消预约次数限制" prop="cancel_subscribe_limit" label-width="160px">
                  <div class="flex">
                    <div>
                      <el-input
                        v-model="form.setting.cancel_subscribe_limit"
                        type="number"
                        placeholder="不限取消预约次数"
                        style="width:120px"
                      />
                    </div>
                    <div class="m-x-5">次，每</div>
                    <div>
                      <el-select
                        v-model="form.setting.cancel_subscribe_cycle"
                        placeholder="请选择"
                        style="width:100px;"
                      >
                        <el-option label="日" :value="1">日</el-option>
                        <el-option label="周" :value="2">周</el-option>
                        <el-option label="月" :value="3">月</el-option>
                      </el-select>
                    </div>
                  </div>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button @click="returnPage()">取消</el-button>
    </div>
  </div>
</template>

<script>
import { getVenuesCourse } from '@/api/store/course.js'
import { addVipCard } from '@/api/store/venues.js'
import checkboxInputSon from '@/components/All/checkboxInputSon'
import { getVenuesInfo } from '@/api/store/venues.js'

export default {
  components: { checkboxInputSon },
  data() {
    const digital = (rule, value, callback) => {
      const v_digital = this.$refs.v_digital.value
      if (v_digital < 1) callback(new Error('不能小于1'))
      if (v_digital % 1 !== 0) callback(new Error('请输入整数'))
      const v_type = this.$refs.v_type.value
      if (!v_digital || !v_type) {
        callback(new Error('不能为空'))
      } else {
        callback()
      }
    }
    return {
      form: {
        price: 0,
        // leave_day: 0,
        // leave_number: 0,
        name: '',
        type: 0,
        valid_period: { type: 'month', digital: 1 },
        kind: 0,
        venuesCourse: [],
        limit: 0,
        // 高级设置
        setting: {
          single_subscribe_limit: 1, // 单节课可约人数上限
          every_day_subscribe_limit: '', // 每日可约次数上限
          every_week_subscribe_limit: '', // 每周可约次数上限
          every_month_subscribe_limit: '', // 每月可约次数上限
          subscribe_day: '', // 可预约天数
          dayparting: {}, // 可用时间
          every_interval_limit: '', // 每日约课间隔时间限制
          not_finished_subscribe_limit: 0, // 预约次数限制
          cancel_subscribe_cycle: 2, // 会员取消预约次数限制(日周月)
          cancel_subscribe_limit: '', // 会员取消预约次数限制
          dayparting_select: 1, // 可用时间选择
          leave_option: 0, // 请假设置
          leave_number: '', // 请假次数
          leave_day: '' // 请假天数
        }
      },
      rules: {
        name: this.utils.validate(),
        kind: this.utils.validate(),
        type: this.utils.validate(),
        price: this.utils.validate(),
        limit: this.utils.validate(),
        // leave_day: this.utils.validate(),
        // leave_number: this.utils.validate(),
        digital: [{ required: true, validator: digital }]
        // venuesCourse: this.utils.validate()
      },
      // 课程列表
      courseList: {},
      courseLists: {},
      is_add: true,
      activeNames: [],
      // activeNames: ['1'],
      limit_type: '元',
      type_hidden: true,
      settingShow: false,
      dayparting_time_config: [
        {
          stime: '00:00',
          etime: '23:59'
        }
      ],
      dayparting_days: [1, 2, 3, 4, 5, 6, 7],
      venues_id: 0,
      venues_name: ''
    }
  },
  mounted() {
    getVenuesCourse()
      .then(res => {
        if (this.utils.getVenues().id === -1) {
          this.courseLists = res.data
        } else {
          this.courseList = res.data[this.utils.getVenues().id].list
        }
      })
      .catch(() => { })
    if (this.utils.getVenues().id === -1) {
      this.venues_id = 0
    } else {
      this.venues_id = this.utils.getVenues().id
    }
    this.dataList()
  },
  methods: {
    dataList() {
      if (this.venues_id !== 0) {
        getVenuesInfo(this.venues_id).then(res => {
          this.venues_name = res.data.name
        })
      }
    },
    typeChange(val) {
      if (val === 0) {
        this.type_hidden = true
        this.limit_type = ''
      }
      if (val === 1) {
        this.type_hidden = false
        this.limit_type = '次'
        this.upVal(1)
      }
      if (val === 2) {
        this.type_hidden = false
        this.limit_type = '元'
        this.upVal(2)
      }
    },
    upVal(v) {
      var data
      if (this.utils.getVenues().id === -1) {
        data = this.courseLists
        for (const i in data) {
          const item = data[i]
          if (item.list) {
            item.list.map(val => {
              if (val.charge === undefined || val.charge === '') val.charge = 1
            })
          }
        }
      } else {
        data = this.courseList
        data.map(val => {
          if (val.charge === undefined || val.charge === '') val.charge = 1
        })
      }
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          console.log('🚀 ~ file: add.vue ~ line 145 ~ submit ~ data.venuesCourse', data.venuesCourse)

          if (this.utils.getVenues().id === -1) {
            const venuesCourseArr = []
            if (data.venuesCourse && data.venuesCourse.length > 0) {
              this.form.venuesCourse.forEach((val, index) => {
                Array.prototype.push.apply(venuesCourseArr, val)
              })
            }
            data.venuesCourse = venuesCourseArr
          }
          // 不强制勾选课程
          // if (data.venuesCourse.length === 0) {
          //   this.$message.error('请选择课程')
          //   return false
          // }
          if (data.venuesCourse && data.venuesCourse.length > 0) {
            data.venuesCourse.forEach((val, index) => {
              data.venuesCourse[index] = {
                course_id: data.venuesCourse[index].id,
                charge: data.venuesCourse[index].charge ? data.venuesCourse[index].charge : 0
              }
              // if (!data.venuesCourse[index]['charge']) delete data.venuesCourse[index]['charge']
            })
          }

          data.venues_id = this.utils.getVenues().id
          data.venuesCourse = JSON.stringify(data.venuesCourse)
          data.valid_period = JSON.stringify(data.valid_period)

          // 构建高级设置数据
          const dayparting_data = []
          if (this.dayparting_days.length > 0 && this.dayparting_time_config.length > 0) {
            this.dayparting_time_config.forEach(element => {
              dayparting_data.push({
                support_days: this.dayparting_days.join(','),
                start_time: element.stime,
                end_time: element.etime
              })
            })
          }
          data.setting.dayparting = dayparting_data

          // return false
          addVipCard(data)
            .then(() => {
              this.$message.success('添加成功')
              this.$router.push({ name: 's_membership_card' })
            })
            .catch(() => {})
        }
      })
    },
    // 添加时间
    addTime() {
      this.dayparting_time_config.push({
        stime: '00:00',
        etime: '23:59'
      })
    },
    // 删除时间
    delTime(item) {
      if (this.dayparting_time_config.length === 1) return false
      var index = this.dayparting_time_config.indexOf(item)
      if (index !== -1) {
        this.dayparting_time_config.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  padding: 10px 20px 20px 140px;
  .title {
    margin: 10px 0px;
    font-size: 14px;
    font-weight: 600;
  }
  .config {
    padding: 10px 12px;
    border: 1px solid rgb(0 0 0 / 10%);
    border-radius: 4px;
  }
  .dayparting_config {
    padding-left: 140px;
    .title {
      font-weight: 500;
    }
  }
}
.boxs {
  margin-left: 140px;
  color: #ccc;
}
</style>
