<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-item">
        <el-input v-model="listQuery.query" placeholder="搜索" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <router-link :to="{name: 's_venues_add'}" class="filter-item m-l-10">
        <el-button type="primary" icon="el-icon-plus">添加</el-button>
      </router-link>
      <!-- <el-button type="warning" class="filter-item m-l-10" icon="el-icon-files" :disabled="is_check.length<=0" @click="batch_holiday">场馆放假</el-button> -->
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData.list"
      row-key="id"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column
        type="selection"
        width="55"
      /> -->
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column prop="name" label="名称">
        <template slot-scope="{row}">
          {{ row.name }}
        </template>
      </el-table-column>
      <el-table-column prop="telephone" label="门店电话" />
      <el-table-column prop="address" label="地址" min-width="120px" />
      <el-table-column prop="type" label="类型" min-width="60px">
        <template slot-scope="{row}">
          <el-tag v-if="row.default===1" type="primary" class="m-2">默认门店</el-tag>
          <el-tag v-if="row.type===10" type="success" class="m-2">总店</el-tag>
          <el-tag v-if="row.type===20" type="primary" class="m-2">分店</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="default" label="场馆放假">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_holiday!==0" type="warning">放假中</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column label="操作" min-width="140px">
        <template slot-scope="scope">
          <el-button class="m-2" type="primary" icon="el-icon-edit" @click="toPage(scope.row.id)">编辑</el-button>
          <delPopover class="m-2" :list-length="tableData.list.length" :api-data="apiData" :page.sync="listQuery.page" :row="scope.row" btn-msg="删除" @delete="getList" />
          <el-button class="m-2" type="warning" icon="el-icon-notebook-2" @click="getLogs(scope.row.id)">放假</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 请假弹出层 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="场馆放假"
      class="dialog_w580"
    >
      <el-row :gutter="20">
        <el-col :span="24" :offset="0">
          <el-form ref="ruleForm" label-width="80px" :rules="rules" :model="form">
            <el-form-item label="放假场馆">
              <div class="flex flex-wrap">
                <el-tag v-for="(item,index) in is_check" :key="index" effect="dark" class="m-2">{{ item.name }}</el-tag>
              </div>
            </el-form-item>
            <el-form-item label="放假时间" prop="holiday_time">
              <el-date-picker
                v-model="form.holiday_time"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button type="primary" @click="holiday_submit">提交</el-button>
        <el-button @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <VenuesLogs v-if="dialog.id" :id="dialog.id" :visible-log.sync="dialog.visibleLog" />
  </div>

</template>

<script>
import { getVenues, holidayVenues } from '@/api/store/venues.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import delPopover from '@/components/All/delPopover'
import VenuesLogs from './logs'
export default {
  components: { Pagination, delPopover, VenuesLogs },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      LogstableData: {
        list: [],
        count: 0
      },
      form: {
        venues_id: [],
        start_time: '',
        end_time: ''
      },
      apiData: { url: '/v1/venues' },
      dialog: { id: '', visible: false, row: [], visibleLog: false },
      holiday_time: [],
      rules: ['holiday_time'],
      loading: false,
      searchLoading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      },
      is_check: []
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      if (this.listQuery.query) data.query = this.utils.getLikeQueryParams('name|principal|phone|telephone|address|abstract', this.listQuery.query)
      getVenues(data).then(res => {
        this.tableData = res.data
        this.searchLoading = false
      }).catch(() => {})
    },
    toPage(id) {
      this.$router.push({ name: 's_venues_edit', params: { id: id }})
    },
    // 放假提交
    holiday_submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const s = this.form.holiday_time[0]
          const e = this.form.holiday_time[1]
          const p_data = {
            venues_id: this.form.venues_id,
            start_time: s,
            end_time: e
          }
          holidayVenues(p_data).then(res => {
            this.$message.success(res.msg)
            this.dialog.visible = false
          }).catch(() => {})
        }
      })
    },
    // 获取放假记录
    getLogs(id) {
      this.dialog.id = id
      this.dialog.visibleLog = true
    },
    // 批量放假
    batch_holiday() {
      this.form.venues_id = []
      this.is_check.forEach((item, index) => {
        this.form.venues_id.push(item.id)
      })
      this.dialog.visible = true
    },
    handleSelectionChange(val) {
      this.is_check = val
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
