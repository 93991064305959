<template>
  <div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="groupVisible"
      title="约团课"
      class="dialog_w1200"
      @close="cancelDialog"
    >
      <div v-loading="pageloading" class="content" element-loading-text="拼命加载中">
        <el-form ref="ruleForm" :rules="rules" :model="form" label-width="100px" label-position="left" label-suffix=":">
          <!-- 预约日期 -->
          <el-row :gutter="20">
            <el-col :span="9">
              <div class="rili item">
                <div class="title flex row-between col-top">
                  <div>
                    预约日期 （<span class="text-warning">{{ interval_time===0?'今天':interval_time+'天后' }}</span>）
                  </div>
                  <div>
                    <el-button type="primary" size="mini" class="m-r-20" @click="setToday">返回今天</el-button>
                  </div>
                </div>
                <div class="rili_list">
                  <Calendar ref="Calendar" :ago-day-hide="agoDayHide" @choseDay="clickDay" />
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="course item">
                <div class="title">
                  课程信息
                </div>
                <div class="course_box">
                  <ul v-if="!utils.empty(courseOptions)" class="course_list">
                    <li v-for="(item,index) in courseOptions.list" :key="index" class="course_item" :class="{active:form.class_schedule_id===item.id,disabled:item.auto_cancel}" @click="checkCourse(item)">{{ item.start_time | formatDateLT }} ~ {{ item.end_time | formatDateLT }} / {{ item.course.name }} [{{ item.total_subscribe }}/{{ item.galleryful }}]</li>
                  </ul>
                  <span v-else class="font-16 m-t-10 text-danger">当日暂无课程</span>
                </div>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="user item">
                <div class="title">会员信息</div>
                <el-alert
                  v-show="validate_msg && form.user_id"
                  :title="validate_msg"
                  type="info"
                  :closable="false"
                  show-icon
                  class="m-b-10"
                />
                <div class="user_info">
                  <el-form-item label="会员信息" prop="user_id">
                    <Select ref="userRef" :type="2" :width="214" @changeId="handleMemberID" />
                    <!-- <el-select
                      v-model="form.user_id"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请选择会员,可搜索昵称|姓名|手机号"
                      :remote-method="remoteUserMethod"
                      :loading="loading.searchLoading"
                      style="width:100%"
                      :clearable="true"
                      no-data-text="暂无数据"
                      no-match-text="无搜索项,重新搜索"
                      class="m-b-5"
                      @change="userChange"
                    >
                      <el-option
                        v-for="item in userOptions"
                        :key="item.id"
                        :label="getUserNames(item)"
                        :value="item.id"
                      >
                        <div class="flex">
                          <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="item.avatar">
                            <img :src="errorMemberSrc()">
                          </el-avatar>
                          <span>{{ getUserNames(item) }}</span>-
                          <span>{{ item.phone }}</span>
                        </div>
                      </el-option>
                    </el-select> -->
                  </el-form-item>
                  <el-form-item label="会员卡" prop="user_membership_card_id">
                    <el-select
                      v-model="form.user_membership_card_id"
                      remote
                      reserve-keyword
                      placeholder="请选择会员卡"
                      :loading="loading.searchLoading"
                      style="width:100%"
                      :clearable="true"
                      no-data-text="暂无数据"
                      :disabled="is_display"
                      @change="userCardChange"
                    >
                      <el-option-group
                        v-for="group in userCardOptions"
                        :key="group.label"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.options"
                          :key="item.id"
                          :label="item.card.name+' / '+item.number"
                          :value="item.id"
                          :disabled="!item.course_limit"
                        >
                          <div class="flex">
                            <!-- <el-tag v-if="item.course_limit && item.status_id == 2">正常</el-tag> -->
                            <el-tag v-if="item.course_limit && item.status_id != 2" type="warning">{{ item.status.name }}</el-tag>
                            <el-tag v-if="!item.course_limit" type="danger">不支持</el-tag>
                            <div class="m-l-6">{{ item.card.name+' / '+item.number }}</div>
                          </div>
                        </el-option>
                      </el-option-group>
                    </el-select>
                    <small v-if="text_display && form.user_id" class="text-danger">当前会员暂无会员卡</small>
                  </el-form-item>
                  <el-form-item label="卡号">
                    <el-tag v-if="userCardInfo&&userCardInfo.number">
                      {{ userCardInfo?userCardInfo.number:'' }}
                    </el-tag>
                  </el-form-item>
                  <el-form-item label="手机号">
                    {{ userInfo.phone }}
                  </el-form-item>
                  <el-form-item label="余额">
                    {{ userCardInfo?userCardInfo.limit:'' }}{{ userCardInfo.card?formatterCardType(userCardInfo.card.type):'' }}
                  </el-form-item>
                  <el-form-item v-if="userCardInfo.valid_time" label="有效期至">
                    {{ userCardInfo.valid_time?userCardInfo.valid_time:'--' }}
                  </el-form-item>
                  <el-form-item label="预约人次">
                    <el-input-number v-model="form.subscribe_number" :min="1" :max="10" label="描述文字" />
                  </el-form-item>
                  <el-form-item label="备注">
                    <el-input v-model="form.dremarksc" type="textarea" />
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>

        </el-form>
      </div>

      <div class="footer text-center m-t-20">
        <el-button type="primary" @click="submit">提交</el-button>
        <el-button type="text" @click="cancelDialog">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      append-to-body
      title="提示"
      :visible.sync="dialogVisible"
      class="dialog_w580"
      :before-close="handleClose"
    >
      <span>{{ forceData.msg }}</span>
      <div class="m-y-10"><el-checkbox v-model="is_queue">排队预约</el-checkbox></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitBook(forceData.user_id,forceData.data,1)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// https://github.com/zwhGithub/vue-calendar
import Calendar from 'vue-calendar-component'
import { getGroupVenues, addBook, addBookValidate } from '@/api/store/worktable.js'
import { getUser, getUserDetails, getUserSupportCard, getUserMembershipCardDetails } from '@/api/store/user.js'
import { getVenues } from '@/api/store/venues.js'
import { deepClone } from '@/utils'
import Select from '@/components/elSelect/Select'
export default {
  name: 'GroupBook',
  components: { Calendar, Select },
  props: {
    venuesId: {
      type: Number,
      default() {
        return -1
      }
    },
    groupVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: [
        'user_id',
        'subscribe_number',
        'user_membership_card_id',
        'course_id'
      ],
      courseInfo: {},
      form: {
        user_membership_card_id: '', // 会员卡卡号
        subscribe_number: 1, // 预约人数
        class_schedule_id: '', // 课程表ID
        store_user_id: '', // 后台员工id
        user_id: '', // 用户ID
        course_id: '', // 课程ID
        start_time: this.$moment().format('YYYY-MM-DD'), // 开始时间
        venues_id: this.utils.getVenues().id
      },
      userInfo: {},
      userOptions: {},
      userCardOptions: {},
      venuesOptions: {},
      courseOptions: [],
      loading: { searchLoading: false },
      agoDayHide: this.$moment().startOf('day').unix().toString(),
      interval_time: 0,
      pageloading: false,
      userCardInfo: {},
      forceData: {},
      dialogVisible: false,
      is_queue: false,
      validate_msg: '',
      is_display: true,
      text_display: false
    }
  },
  watch: {

  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.getVenuesList()
    this.getUserList()
    // this.getGroupVenuesList()

    // 初始化回到今天
    var that = this
    setTimeout(function() {
      that.setToday()
    }, 500)
  },
  methods: {
    /**
     * 用户列表
     */
    getUserList() {
      const data = {}
      if (this.listQuery.query) data.query = this.utils.getLikeQueryParams('nickname|phone|real_name', this.listQuery.query)
      // data.limit = 999
      getUser(data).then(res => {
        // 数据嵌入
        this.loading.searchLoading = false
        this.userOptions = res.data.list
      })
    },
    /**
     * 搜索会员卡
     */
    // remoteUserCardMethod(query) {
    //   if (query !== '') {
    //     this.loading.searchLoading = true
    //     // 获取指定会员下的会员卡列表
    //     this.getUserCardList()
    //   } else {
    //     this.userCardOptions = {}
    //   }
    // },
    /**
     * 搜索用户
     */
    remoteUserMethod(query) {
      this.loading.searchLoading = true
      this.listQuery.query = query
      this.getUserList()
    },
    /**
     * 获取选中会员
     */
    userChange(id) {
      this.form.user_membership_card_id = ''
      this.userCardOptions = {}
      if (!id) {
        // 重置选中项
        this.listQuery.query = ''
        this.userInfo = {}
        this.user_info_show = false
        this.getUserList()
        this.getUserCardList()
        return
      }
      this.user_info_show = true
      // 请求API获取用户信息
      getUserDetails(id).then(res => {
        this.userInfo = res.data
        this.getUserCardList()
        this.userCardInfo = {}
      })
      this.va(id)
    },
    va(id) {
      if (!id || !this.form.class_schedule_id) return false
      addBookValidate(id, {
        class_schedule_id: this.form.class_schedule_id
      }).then(res => {
        if (res.data.msg) {
          this.validate_msg = res.data.msg
        } else {
          this.validate_msg = ''
        }
      })
    },
    /**
     * 获取选中会员会员卡
     */
    userCardChange(id) {
      if (!id) {
        this.userCardInfo = {}
        return
      }
      this.form.user_membership_card_id = id
      this.user_info_show = true
      // 获取用户会员卡详情
      getUserMembershipCardDetails(id).then(res => {
        this.userCardInfo = res.data
      })
    },
    // 获取用户会员卡列表
    getUserCardList() {
      if (!this.userInfo.id) {
        this.is_display = true
        return
      }
      if (!this.form.course_id) {
        this.is_display = true
        return
      }
      this.is_display = false
      // getUserMembershipCard({ query: JSON.stringify({ user_id: this.userInfo.id }) }).then(res => {
      //   if (this.utils.empty(res.data.list)) this.$message.error('当前会员无可用会员卡')
      //   // 数据嵌入
      //   this.loading.searchLoading = false
      //   this.userCardOptions = res.data.list
      // })
      const data = {
        offset: 0,
        limit: 999
      }
      getUserSupportCard(this.form.course_id, this.userInfo.id, data).then(res => {
        const data = res.data.list
        if (data.length <= 0) this.text_display = true
        else this.text_display = false
        const optionsY = data.filter(item => item.course_limit)
        const optionsN = data.filter(item => !item.course_limit)
        this.userCardOptions = [
          { label: '可用', options: optionsY },
          { label: '不支持', options: optionsN }
        ]
      }).finally(() => {
        this.loading.searchLoading = false
      })
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let postData = {}
          postData = this.form
          if (!postData.course_id) {
            this.$message.error('请选择课程')
            return false
          }
          console.log('🚀 ~ file: group.vue ~ line 286 ~ submit ~ postData', postData)
          this.submitBook(postData.user_id, postData)
        }
      })
    },
    submitBook(user_id, data, force = 0) {
      data.force = force
      data.is_queue = this.is_queue ? 1 : 0
      addBook(user_id, data).then(res => {
        this.is_queue = false
        this.$message.success(res.msg)
        this.cancelDialog()
        if (force) {
          this.dialogVisible = false
          this.forceData = {
            msg: '',
            data: {},
            user_id: ''
          }
        }
      }).catch(error => {
        if (error.status === 701) {
          this.$confirm(error.msg, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.submitBook(user_id, data, 1)
          })
        }
        if (error.status === 702) {
          this.dialogVisible = true
          this.forceData.msg = error.msg
          this.forceData.data = deepClone(data)
          this.is_queue = true
          this.forceData.user_id = user_id
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.is_queue = false
      this.forceData = {}
    },
    // 关闭页面
    cancelDialog() {
      this.$emit('update:groupVisible', false)
    },
    // 获取场馆列表
    getVenuesList() {
      getVenues({ limit: 999 }).then(res => {
        this.venuesOptions = res.data.list
      })
    },
    // 获取指定日期课程列表
    async getGroupVenuesList() {
      this.$set(this.form, 'course_id', '')
      const time = this.form.start_time
      getGroupVenues(time).then(res => {
        this.courseOptions = res.data
        this.pageloading = false
      })
    },
    // 选择日期
    clickDay(data) {
      this.pageloading = true
      this.form.start_time = this.$moment(data).format('yyyy-MM-DD')
      // 计算相隔天数
      const s = this.$moment().startOf('day').format('yyyy/MM/DD')// 开始时间
      const e = data // 结束时间
      const diff_day = this.$moment(e).diff(s, 'days')
      this.interval_time = diff_day

      // 获取当前日期课程列表
      this.getGroupVenuesList()
    },
    setToday() {
      // 返回今天
      this.$refs.Calendar.ChoseMonth(this.$moment().startOf('day').format('yyyy/MM/DD'))
    },
    // 选中课程
    checkCourse(v) {
      if (v.auto_cancel) {
        this.$message.error('该课程已取消，无法预约')
        return false
      }
      this.$set(this.form, 'course_id', v.course.id)
      this.$set(this.form, 'class_schedule_id', v.id)
      this.$set(this.form, 'store_user_id', v.store_user_id)
      this.va(this.form.user_id)
      this.form.user_membership_card_id = ''
      this.getUserCardList()
    },
    formatterCardType(v) {
      switch (v) {
        case 0:
          return ''
        case 1:
          return '次'
        case 2:
          return '元'
        default:
          break
      }
    },
    handleMemberID(val) {
      this.$set(this.form, 'user_id', val)
      this.userChange(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.item{
  .title{
    height: 30px;
    margin-bottom: 10px;
  }
  padding: 5px;
}
.rili_list{
  $rili_bg:#515a6e;
  ::v-deep .wh_container{
    width: 100% !important;
  }
  ::v-deep .wh_content_all{
    .wh_isToday.wh_chose_day{
      background:#41c3a8;
      color:#eee !important
    }
    .wh_chose_day{
      background:#41c3a8;
      color:#eee !important
    }
    // 日历对齐
    .wh_content .wh_content_item{
      width:14.2%
    }
    // 当天
    .wh_isToday:not(.wh_chose_day){
      background: none ;
    }
    .wh_item_date{
      color:$rili_bg
    }
    // 星期
    .wh_top_tag{
      color:$rili_bg
    }
    // 左翻页
    .wh_jiantou1{
      border-top: 2px solid $rili_bg;
      border-left: 2px solid $rili_bg;
    }
    // 右翻页
    .wh_jiantou2{
      border-top: 2px solid $rili_bg;
      border-right: 2px solid $rili_bg;
    }
    // 当前年
    .wh_content_li{
      color:$rili_bg
    }
    .wh_item_date.wh_want_dayhide{
      color:#bfbfbf;
    }
    .wh_item_date:hover{
      background: #3498db;
      border-radius:4px;
      color:#eee
    }
    background-color: #fff !important;
  }
}
.course_box{
  span{
    display: block;
    // margin: 20px;
    // padding: 20px;
    text-align: center;
  }
  overflow:auto;
  height: 335px;
  border:1px solid rgb(238, 238, 238);
}

.course_list{
  list-style: none;
  padding: 0;
  margin:0;
  width: 500px;
  .course_item{
    border:1px solid #eee;
    white-space:nowrap;
    padding: 6px 5px;
    border:1px solid #eee;
    background: #fff;
    overflow: auto;
    cursor: pointer;
    // margin: 1px 0px;
  }
  .course_item.active{
    color:#fff;
    background: #41c3a8;
  }
  .course_item.disabled{
    color:#fff;
    background: rgb(0 0 0 / 10%);
  }
}
.el-form-item--small.el-form-item{
  margin-bottom:7px;
}
.course{
  /*滚动条样式代码*/
  ::-webkit-scrollbar {
    width: 8px;
    height:5px;
  }
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #606266;
    // background-image: -webkit-linear-gradient(45deg,  rgba(255, 255, 255, .4) 25%,  transparent 25%,  transparent 50%,  rgba(255, 255, 255, .4) 50%,  rgba(255, 255, 255, .4) 75%,  transparent 75%,  transparent);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }
}

</style>
