<template>
  <el-select
    v-model="dataId"
    filterable
    clearable
    remote
    reserve-keyword
    :placeholder="methodFunction[type].placeholder"
    :remote-method="selectChanged"
    :disabled="disabled"
    :style="{ width : width + 'px'}"
    @visible-change="visibleChanged"
    @change="handleChange"
  >
    <div v-loading="selectLoading">
      <el-option
        v-for="(item, index) in dataList.list"
        :key="index"
        :label="type === 2 || type === 3 || type === 4 || type === 9 ? getUserNames(item) : type === 5 ? getMembershipCard(item) : type === 1 || type === 6 || type === 7 || type === 8 ? item.name : '请求错误'"
        :value="item.id"
        @click.native="courseChoose(item, index)"
      >
        <div v-if="type === 1" class="flex">
          <div class="text-info">{{ item.id }}</div>
          <div v-if="item.logo" class="m-x-4 text-info">-</div>
          <el-avatar v-if="item.logo" size="small" :src="item.logo" />
          <div class="m-x-4 text-info">-</div>
          <div>{{ item.name }}</div>
          <div class="m-x-4 text-info">-</div>
          <div>{{ item.phone }}</div>
        </div>
        <div v-if="type === 2 || type === 3 || type === 4 || type === 9" class="flex">
          <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="item.avatar">
            <img :src="type === 4 ? errorAvatarSrc(item, 'img') : errorMemberSrc()">
          </el-avatar>
          <div>{{ getUserNames(item) }}</div>
          <div class="m-x-4 text-info">-</div>
          <div :class="item.phone ? '' : 'text-info'">{{ item.phone ? item.phone : '---' }}</div>
        </div>
        <div v-if="type === 5" class="flex">
          <div>{{ item.number }}</div>
          <div class="m-x-4 text-info">-</div>
          <div class="text-info">{{ cardType(item.card.type) }}</div>
          <div class="text-info">（{{ JSON.parse(item.card.valid_period).digital }}{{ formatTime(JSON.parse(item.card.valid_period).type) }}）
          </div>
        </div>
        <div v-if="type === 7" class="flex">
          <div>{{ item.name }}</div>
          <div class="m-x-4 text-info">-</div>
          <div class="text-info">{{ item.name }}</div>
        </div>
        <div v-if="type === 8" class="flex">
          <div>{{ item.name }}</div>
          <div class="m-x-4 text-info">-</div>
          <div class="text-info">{{ item.galleryful }}人</div>
        </div>
      </el-option>
      <el-pagination
        v-show="dataList.count > 6"
        small
        class="text-center m-t-10"
        layout="prev, pager, next"
        :page-size="6"
        :total="dataList.count"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-select>
</template>

<script>
/**
 * API
 */
// 商户列表
import { getList } from '@/api/admin/merchants.js'
// 会员列表
import { getUser } from '@/api/store/user.js'
// 访客列表
// import { getVisitor } from '@/api/store/course'
// 教练列表
import { getCoach } from '@/api/store/venues.js'
// 管理员列表
import { getStoreUser } from '@/api/store/management.js'
// 会员卡列表
import { getUserMembershipCard } from '@/api/store/user.js'
// 课程列表
import { getCourse } from '@/api/store/course.js'
// 售卡列表
import { getSeckillList } from '@/api/store/seckill.js'
// 教室列表
import { getClassroom } from '@/api/store/venues.js'

export default {
  name: 'Select',
  props: {
    /**
     * 品牌：1
     * 会员：2
     * 访客：3
     * 教练：4
     * 会员卡：5
     * 课程：6
     * 售卡：7
     * 教室：8
     * 会员/访客：9
     */
    type: {
      type: Number,
      default: 0
    },
    // 如果是编辑，则直接传入要显示的名称
    existName: {
      type: String,
      default: ''
    },
    // 如果有些下拉需要和其他表单中的宽度保持一致即可传入宽
    width: {
      type: Number,
      default: 220
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 教练列表传值 0：团课，精品课；1：私教；不传=所有教练
    coachType: {
      type: Number,
      default: 2
    },
    // 课程列表传值 0：团课；2：精品课；1：私教课；不传=所有课程
    curriculumType: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      // 数据
      dataList: {
        count: 0,
        list: []
      },
      dataId: '', // 选中的ID
      selectPage: 0, // 当前页数
      currentPage: 1, // 当前页码
      query: '', // 模糊搜索的关键字
      selectLoading: false, // 加载
      userName: this.existName, // 传过来的用户名
      methodFunction: [
        {},
        {
          placeholder: '搜索品牌|手机号|标签|来源|IP|地区',
          field: 'a.id|a.name|s.phone|s.login_ip|a.location|a.source|a.content|a.tags'
        },
        { placeholder: '可搜索昵称|姓名|手机号', field: 'nickname|phone|real_name' },
        { placeholder: '可搜索昵称|姓名|手机号', field: 'nickname|phone|real_name' },
        { placeholder: '可搜索姓名|手机号', field: 'real_name|phone' },
        { placeholder: '可搜索会员卡号', field: 'number' },
        { placeholder: '可搜索课程名称', field: 'name' },
        { placeholder: '可搜索名称', field: 'name' },
        { placeholder: '可搜索教室名称', field: 'name' },
        { placeholder: '可搜索昵称|姓名|手机号', field: 'nickname|phone|real_name' }
      ]
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      // 加载
      this.selectLoading = true
      // 翻页
      const data = {
        offset: this.selectPage,
        limit: 6
      }
      // 模糊搜索
      var querys = []
      if (this.query) querys.push({ field: this.methodFunction[this.type].field, type: 'like', key: this.query })
      data.query = this.utils.getQueryParams(querys)
      // 如果是编辑，则直接传入要显示的名称
      if (this.userName) this.dataId = this.userName

      /**
       * 获取数据
       */
      // type是1时则是品牌列表
      if (this.type === 1) {
        getList(data)
          .then(res => {
            this.dataList = res.data
            this.selectLoading = false
          })
          .catch(() => {})
          .finally(() => {})
      }
      // type是2时则是会员列表
      if (this.type === 2) {
        data.type = 'user'
        getUser(data)
          .then(res => {
            this.dataList = res.data
            this.selectLoading = false
          })
          .catch(() => {})
          .finally(() => {})
      }
      // type是3时是访客列表
      if (this.type === 3) {
        data.type = 'visitor'
        getUser(data)
          .then(res => {
            this.dataList = res.data
            this.selectLoading = false
          })
          .catch(() => {})
          .finally(() => {})
      }
      // type是4时是教练列表
      if (this.type === 4) {
        // 如果是教练列表则新增一个参数
        if (this.coachType !== 2) data.type = this.coachType === 0 ? 0 : 1
        // 根据 this.utils.getVenues().id 判断是否在品牌管理里面
        if (this.utils.getVenues().id !== -1) {
          getCoach(data)
            .then(res => {
              this.dataList = res.data
              this.selectLoading = false
            })
            .catch(() => {})
            .finally(() => {})
        } else {
          getStoreUser(data)
            .then(res => {
              this.dataList = res.data
              this.selectLoading = false
            })
            .catch(() => {})
            .finally(() => {})
        }
      }
      // type是5时是会员卡列表
      if (this.type === 5) {
        getUserMembershipCard(data)
          .then(res => {
            this.dataList = res.data
            this.selectLoading = false
          })
          .catch(() => {})
          .finally(() => {})
      }
      // type是6时是课程列表
      if (this.type === 6) {
        // 如果是课程列表则新增一个参数
        if (this.curriculumType !== 3) data.type = this.curriculumType === 0 ? 0 : this.curriculumType === 1 ? 1 : 2
        getCourse(data)
          .then(res => {
            this.dataList = res.data
            this.selectLoading = false
          })
          .catch(() => {})
          .finally(() => {})
      }
      // type是7时是售卡列表
      if (this.type === 7) {
        getSeckillList(data)
          .then(res => {
            this.dataList = res.data
            this.selectLoading = false
          })
          .catch(() => {})
          .finally(() => {})
      }
      // type是8时是教室列表
      if (this.type === 8) {
        getClassroom(data)
          .then(res => {
            this.dataList = res.data
            this.selectLoading = false
          })
          .catch(() => {})
          .finally(() => {})
      }
      // type是9时则是会员/访客列表
      if (this.type === 9) {
        data.type = 'all'
        getUser(data)
          .then(res => {
            this.dataList = res.data
            this.selectLoading = false
          })
          .catch(() => {})
          .finally(() => {})
      }
    },
    // 选中之后传回父级
    handleChange() {
      this.$emit('changeId', this.dataId)
      this.userName = ''
    },
    // 课程列表传回去的index
    courseChoose(item, index) {
      if (this.type === 2 || this.type === 3 || this.type === 4 || this.type === 9) {
        this.dataId = this.getUserNames(item)
      }
      if (this.type === 5) {
        this.dataId = `${item.number} - ${this.cardType(item.card.type)}`
      }
      if (this.type === 1 || this.type === 6 || this.type === 7 || this.type === 8) {
        this.dataId = item.name
      }
      if (this.type === 6) this.$emit('courseChoose', index)
    },
    // 切换分页
    handleCurrentChange(val) {
      // 解决切换分页把筛选条件清空掉
      if (this.query) this.dataId = this.query
      this.selectPage = (val - 1) * 6
      this.getList()
    },
    // 远程搜索的方法
    selectChanged(val) {
      this.query = val
      // 给后端的页码返回到第一页
      this.selectPage = 0
      this.currentPage = 1
      this.getList()
    },
    // 下拉框出项/隐藏是触发
    visibleChanged(val) {
      if (val === false) {
        // 给后端的页码返回到第一页
        this.selectPage = 0
        this.currentPage = 1
        this.query = ''
        this.getList()
      }
    },
    // 会员卡的函数
    cardType(type) {
      switch (type) {
        case 0:
          return '期限卡'
        case 1:
          return '次数卡'
        case 2:
          return '储蓄卡'
        default:
          break
      }
    },
    formatTime(t) {
      switch (t) {
        case 'day':
          return '天'
        case 'month':
          return '月'
        case 'year':
          return '年'
      }
    },
    getMembershipCard(item) {
      return `${item.number} - ${this.cardType(item.card.type)}`
    },
    // 父级调用子级的方法刷新表单
    refreshGetList(userName) {
      /**
       * this.$refs.组件自定义的ref.refreshGetList()
       *
       * 如果遇见 existName 传值不显示的问题 即可调用刷新表单传值的办法传过来
       */
      this.getList()
      if (userName) this.dataId = userName
      else this.dataId = ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
