<template>
  <div class="">
    <el-form ref="form" :model="form" :rules="rules" label-width="140px" label-position="left">
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          失约惩罚设置
        </div>
        <el-form-item label="期限卡爽约惩罚">
          <el-radio-group v-model="form.general_break_promise.status">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.general_break_promise.status" label="惩罚方式">
          <el-radio-group v-model="form.general_break_promise.type">
            <div class="flex flex-col col-top row-left">
              <el-radio :label="1" class="m-b-5 flex">
                <div class="flex">
                  <div>限制自主预约，一个自然月内，每存在</div>
                  <div>
                    <el-form-item prop="number_1">
                      <el-input v-model="form.general_break_promise.number_1" size="mini" placeholder="填数字" class="w-60 m-x-5" type="number" />
                    </el-form-item>
                  </div>
                  <div>次预约未签到，</div>
                  <div>
                    <el-form-item prop="day_1">
                      <el-input v-model="form.general_break_promise.day_1" size="mini" placeholder="填数字" class="w-60 m-x-5" type="number" />
                    </el-form-item>
                  </div>
                  <div>天内不能自主预约</div>
                </div>
              </el-radio>
              <el-radio :label="2" class="m-b-5 flex">
                <div class="flex">
                  <div>扣减使用卡期限，一个自然月内，每存在</div>
                  <div>
                    <el-form-item prop="number_2">
                      <el-input v-model="form.general_break_promise.number_2" size="mini" placeholder="填数字" class="w-60 m-x-5" type="number" />
                    </el-form-item>
                  </div>
                  <div>次预约未签到，扣减使用卡期限</div>
                  <div>
                    <el-form-item prop="day_2">
                      <el-input v-model="form.general_break_promise.day_2" size="mini" placeholder="填数字" class="w-60 m-x-5" type="number" />
                    </el-form-item>
                  </div>
                  <div>天</div>
                </div>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="setting_item">
        <div class="m-y-8  p-y-5 p-x-15 title item_title">
          其他设置
        </div>

        <el-form-item label="可预约天数">
          <div class="flex">
            <div>会员最多可预约</div>
            <div>
              <el-form-item prop="day">
                <el-input v-model="form.general_other.day" size="mini" placeholder="不限制" class="w-80 m-x-5" type="number" />
              </el-form-item>
            </div>
            <div>天的课程，0表示当天</div>
          </div>
        </el-form-item>
        <el-form-item label="上课短信发送时间">
          <div class="flex">
            <div>上课短信提前</div>
            <div>
              <el-form-item prop="day">
                <el-input v-model="form.general_other.notice_time" size="mini" placeholder="不限制" class="w-80 m-x-5" type="number" />
              </el-form-item>
            </div>
            <div>分钟发送</div>
          </div>
        </el-form-item>
      </div>

      <div class="footer">
        <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
        <el-button @click="returnPage()">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getSubscribeSetting, editSubscribeSetting } from '@/api/store/subscribe'
import { deepClone } from '@/utils'
export default {
  data() {
    var m1V = (rule, value, callback) => {
      if (this.form.general_break_promise.type === 1 && this.form.general_break_promise.status) {
        if (!/^\d+$/.test(this.form.general_break_promise.number_1)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var d1V = (rule, value, callback) => {
      if (this.form.general_break_promise.type === 1 && this.form.general_break_promise.status) {
        if (!/^\d+$/.test(this.form.general_break_promise.day_1)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var n2V = (rule, value, callback) => {
      if (this.form.general_break_promise.type === 2 && this.form.general_break_promise.status) {
        if (!/^\d+$/.test(this.form.general_break_promise.number_2)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    var d2V = (rule, value, callback) => {
      if (this.form.general_break_promise.type === 2 && this.form.general_break_promise.status) {
        if (!/^\d+$/.test(this.form.general_break_promise.day_2)) {
          callback(new Error('请输入整数'))
        }
      }
      callback()
    }
    // var dV = (rule, value, callback) => {
    //   if (!/^\d+$/.test(this.form.general_other.day)) {
    //     callback(new Error('请输入整数'))
    //   }
    //   callback()
    // }
    return {
      form: {
        general_break_promise: {
          status: 0,
          type: 1,
          number_1: 3,
          number_2: 3,
          day_1: 3,
          day_2: 3
        },
        general_other: {
          day: 1,
          notice_time: 1
        }
      },
      list: {},
      general_break_promise_number: '',
      general_break_promise_day: '',
      rules: {
        number_1: [{ validator: m1V, trigger: ['blur', 'change'] }],
        day_1: [{ validator: d1V, trigger: ['blur', 'change'] }],
        number_2: [{ validator: n2V, trigger: ['blur', 'change'] }],
        day_2: [{ validator: d2V, trigger: ['blur', 'change'] }]
        // day: [{ validator: dV, trigger: ['blur', 'change'] }]
      },
      submitLoading: false
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function(val, oldVal) {}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    submit() {
      this.submitLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          const formData = deepClone(this.form)
          for (var i in this.list) {
            const item = this.list[i]
            delete this.list[i]['appid']
            delete this.list[i]['update_time']
            delete this.list[i]['create_time']
            this.list[i]['content'] = JSON.stringify(formData[item.name])
          }
          var data = this.list
          editSubscribeSetting(data)
            .then(res => {
              this.$message.success('保存成功')
              this.returnPage()
            })
            .finally(() => {
              this.submitLoading = false
            })
        } else {
          console.log('error submit!!')
          this.submitLoading = false
          return false
        }
      })
    },
    getList() {
      getSubscribeSetting({ limit: 999 }).then(res => {
        this.list = res.data.list
        this.form = res.data.res
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item_title {
  border-left: 5px solid #3498db;
  // font-size: 16px;
}
.el-form-item--small.el-form-item {
  margin-bottom: 3px !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #606266 !important;
}
</style>
