<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="申请时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="放款时间">
            <el-date-picker
              v-model="listQueryParams.payment_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="listQueryParams.status" placeholder="请选择" filterable clearable>
              <el-option v-for="(item,index) in statusList" :key="index" :label="item" :value="index">{{ item }}</el-option>
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="订单号|驳回理由" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="app.name" label="提现品牌">
        <template slot-scope="{row}">
          <el-tooltip class="item" effect="dark" :content="`品牌余额：￥${row.app.balance}`" placement="bottom">
            <span class="cursor">{{ row.app.name }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="提现金额" />
      <el-table-column prop="order_no" label="订单号" />
      <el-table-column prop="create_time" label="申请时间" />
      <el-table-column prop="payment_time" label="放款时间" :formatter="paymentTimeFormatter" />

      <el-table-column prop="payment" label="提现方式">
        <template slot-scope="">
          微信零钱
        </template>
      </el-table-column>
      <el-table-column prop="store_user.real_name" label="操作人" />
      <el-table-column prop="status" label="状态">
        <template slot-scope="{row}">
          <el-tag v-if="row.status===0">未处理</el-tag>
          <el-tag v-if="row.status===1" type="success">已处理</el-tag>
          <el-tag v-if="row.status===2" type="danger" class="cursor">已驳回</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm
            v-if="scope.row.status===0"
            title="确定放款吗？"
            @confirm="confirm(scope.row.id)"
          >
            <el-button slot="reference" class="m-2" type="primary" icon="el-icon-circle-check">放款</el-button>
          </el-popconfirm>
          <el-button v-if="scope.row.status===0" class="m-2" type="danger" icon="el-icon-circle-check" @click="rejected(scope.row.id)">驳回</el-button>
          <el-button v-if="scope.row.status===2" class="m-2" type="info" icon="el-icon-view" @click="show_rejected(scope.row)">驳回理由</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { getWithdraw, confirmWithdraw } from '@/api/admin/withdraw.js'
import Pagination from '@/components/Pagination' // 分页组件
export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      dialog: { visible: false, id: '' },
      loading: { listLoading: false },
      statusList: ['未处理', '已处理', '已驳回'],
      searchLoading: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      const t = deepClone(this.listQueryParams)

      var querys = []
      if (t.query) querys.push({ field: 'order_no|content', type: 'like', key: t.query })
      if (t.create_time) querys.push({ field: 'create_time', type: 'between-time', key: t.create_time })
      if (t.payment_time) querys.push({ field: 'payment_time', type: 'between-time', key: t.payment_time })
      if (t.status !== '' && t.status != null) querys.push({ field: 'status', key: t.status })

      data.query = this.utils.getQueryParams(querys)

      getWithdraw(data).then(res => {
        this.tableData = res.data
        this.loading.listLoading = false
      }).finally(() => {
        this.searchLoading = false
      })
    },
    // 放款
    confirm(id) {
      this.loading.listLoading = true
      confirmWithdraw(id, 1).then(res => {
        this.$message.success(res.msg)
        this.getList()
      }).finally(() => {
        this.loading.listLoading = false
      })
    },
    // 驳回
    rejected(id) {
      this.$prompt('请输入驳回理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false
      }).then(({ value }) => {
        confirmWithdraw(id, 2, { content: value }).then(res => {
          this.$message.success(res.msg)
          this.getList()
        }).finally(() => {
          this.loading.listLoading = false
        })
      }).catch(() => {
      })
    },
    // 查看驳回理由
    show_rejected(val) {
      this.$alert('驳回理由：' + (val.content ? val.content : '无'), `订单号:${val.order_no}`, {
        confirmButtonText: '确定'
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    paymentTimeFormatter(row) {
      return row.payment_time !== '0000-00-00 00:00:00' ? row.payment_time : ''
    }

  }
}
</script>

<style  lang="scss" scoped>
.el-input-group__append, .el-input-group__prepend{
  width: 70px;
}
</style>
