<template>
  <div>
    <!-- 租赁/归还储物柜 -->
    <el-dialog
      :title="title"
      :visible.sync="value"
      width="440px"
      :close-on-click-modal="false"
      :before-close="close"
    >
      <div>
        <el-form ref="form" :model="form" label-width="60px" :rules="rules">
          <el-form-item label="编号" prop="serial_number">
            <el-input v-model="form.serial_number" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :loading="btn_loading" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addLocker, updataLocker } from '@/api/store/locker.js'
export default {
  name: 'Box',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: [Number, Boolean],
      default: 1
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      btn_loading: false,
      form: {
        serial_number: '',
        venues_id: this.utils.getVenues().id
      },
      rules: {
        serial_number: [
          { required: true, message: '请输入储物柜编号', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    title: function() {
      return this.type ? '编辑储物柜' : '新增储物柜'
    }
  },
  watch: {
    info(v, o) {
      this.form.serial_number = this.type ? v.serial_number : ''
    },
    type(v, o) {
      if (!v) this.form.serial_number = ''
    }
  },
  methods: {
    // 添加
    add() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btn_loading = true
          const data = this.form
          if (this.type) {
            updataLocker(this.info.id, data).then(res => {
              this.$message.success(res.msg)
              this.$emit('input', false)
              this.$emit('getList')
            }).finally(() => {
              this.btn_loading = false
            })
          } else {
            addLocker(data).then(res => {
              this.$message.success(res.msg)
              this.$emit('input', false)
              this.$emit('getList')
            }).finally(() => {
              this.btn_loading = false
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style>

</style>
