var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "searchBox" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.listQueryParams,
                  "label-width": "100px",
                  "label-position": "top",
                  "label-suffix": "：",
                  inline: true
                }
              },
              [
                _c("card", {
                  attrs: { id: _vm.listQueryParams.id },
                  on: { cardId: _vm.cardIds }
                }),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择状态", clearable: "" },
                        model: {
                          value: _vm.listQueryParams.status,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "status", $$v)
                          },
                          expression: "listQueryParams.status"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "未使用", value: "0" }
                        }),
                        _c("el-option", {
                          attrs: { label: "已使用", value: "1" }
                        }),
                        _c("el-option", {
                          attrs: { label: "已退款", value: "2" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "下单时间" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "picker-options": _vm.dateShortcutChooser
                      },
                      model: {
                        value: _vm.listQueryParams.create_time,
                        callback: function($$v) {
                          _vm.$set(_vm.listQueryParams, "create_time", $$v)
                        },
                        expression: "listQueryParams.create_time"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "m-b-10",
                    attrs: {
                      icon: "el-icon-search",
                      loading: _vm.searchLoading,
                      type: "success"
                    },
                    on: { click: _vm.handleFilter }
                  },
                  [_vm._v(" 搜索 ")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("activity_deposit_add"),
                  expression: "btn_access('activity_deposit_add')"
                },
                { name: "waves", rawName: "v-waves" }
              ],
              staticClass: "filter-item m-r-10",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v(" 添加 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { stripe: "", data: _vm.tableData.list }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "app", label: "品牌信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.app
                      ? _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            row.app.logo
                              ? _c(
                                  "el-avatar",
                                  {
                                    staticClass: "m-r-6",
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px"
                                    },
                                    attrs: {
                                      shape: "square",
                                      src: row.app.logo
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: _vm.errorMemberSrc() }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _c("span", [_vm._v(_vm._s(row.app.name))])
                          ],
                          1
                        )
                      : _c("div", { staticStyle: { color: "red" } }, [
                          _vm._v("品牌信息丢失")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "store_user", label: "参与人员信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-avatar",
                          {
                            staticClass: "m-r-6",
                            staticStyle: { width: "40px", height: "40px" },
                            attrs: { src: row.store_user.avatar }
                          },
                          [_c("img", { attrs: { src: _vm.errorMemberSrc() } })]
                        ),
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(row.store_user.real_name))]),
                          _c("div", [_vm._v(_vm._s(row.store_user.phone))])
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "advance_payment", label: "预付款" }
          }),
          _c("el-table-column", {
            attrs: { prop: "deduction_amount", label: "抵扣款" }
          }),
          _c("el-table-column", {
            attrs: { prop: "coupon_expires", label: "过期时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.coupon_expires === 0
                      ? _c("div", [_vm._v("永久有效")])
                      : _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(
                                row.coupon_expires,
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            )
                          )
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status === 0
                      ? _c(
                          "div",
                          [
                            _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("未使用")
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    row.status === 1
                      ? _c("div", [_c("el-tag", [_vm._v("已使用")])], 1)
                      : _vm._e(),
                    row.status === 2
                      ? _c(
                          "div",
                          [
                            _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("已退款")
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "下单时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatDate")(
                            row.create_time,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("activity_deposit_edit"),
                            expression: "btn_access('activity_deposit_edit')"
                          }
                        ],
                        staticClass: "m-2",
                        attrs: { type: "primary", icon: "el-icon-edit" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row.id)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("activity_deposit_del"),
                            expression: "btn_access('activity_deposit_del')"
                          }
                        ],
                        attrs: { title: "确定删除该记录吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDelete(row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addEdit ? "添加活动记录" : "编辑活动记录",
            visible: _vm.dialogVisible,
            width: "25%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.handleOpen,
            close: _vm.handleClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "none" },
                  attrs: { label: "活动ID" }
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.activity_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "activity_id", $$v)
                      },
                      expression: "form.activity_id"
                    }
                  })
                ],
                1
              ),
              _c("i", { staticClass: "icon-required" }),
              _vm.dialogVisible
                ? _c("card", {
                    attrs: { id: _vm.form.aid, name: _vm.name },
                    on: { cardId: _vm.cardId }
                  })
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "参与员工", prop: "store_user_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选参与员工",
                        disabled: !_vm.form.aid
                      },
                      model: {
                        value: _vm.form.store_user_id,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "store_user_id", $$v)
                        },
                        expression: "form.store_user_id"
                      }
                    },
                    _vm._l(_vm.staffData.list, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.real_name, value: item.id },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c(
                                        "el-avatar",
                                        {
                                          staticClass: "m-r-6",
                                          staticStyle: {
                                            width: "25px",
                                            height: "25px"
                                          },
                                          attrs: { src: item.avatar }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.errorAvatarSrc(
                                                item,
                                                "img"
                                              )
                                            }
                                          })
                                        ]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.real_name.length > 6
                                              ? item.real_name.substring(0, 5) +
                                                  "..."
                                              : item.real_name
                                          )
                                        )
                                      ]),
                                      _vm._v(" - "),
                                      _c("span", [_vm._v(_vm._s(item.phone))])
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预付款" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.form.advance_payment,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "advance_payment", $$v)
                        },
                        expression: "form.advance_payment"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "抵扣款" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.form.deduction_amount,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "deduction_amount", $$v)
                        },
                        expression: "form.deduction_amount"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "过期时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择日期时间",
                      "default-time": "23:59:59",
                      "value-format": "yyyy-MM-dd HH:mm"
                    },
                    model: {
                      value: _vm.form.coupon_expires,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "coupon_expires", $$v)
                      },
                      expression: "form.coupon_expires"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("未使用")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("已使用")
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("已退款")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit("form")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.addEdit ? "确 定" : "保 存"))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }