<template>
  <div class="app-container special-center">
    <div class="userInfo">
      <el-form ref="form" :model="form" label-width="140px">
        <el-descriptions direction="vertical" :column="3" border class="p-t-10 m-b-20" :label-style="{textAlign:'center'}" :content-style="{textAlign:'center'}">
          <el-descriptions-item label="姓名">{{ userInfo.real_name?userInfo.real_name:'--' }}</el-descriptions-item>
          <el-descriptions-item label="昵称">{{ userInfo.nickname }}</el-descriptions-item>
          <el-descriptions-item label="手机号" :span="2">{{ userInfo.phone }}</el-descriptions-item>
        </el-descriptions>

        <!-- 体测表单 -->
        <div v-for="(item,index) in templateList" :key="index">
          <div v-if="isShow[index]">
            <div class="p-y-5 p-x-10 title template_group">{{ $t(`side_template.${index}`) }}<span v-if="index=='custom'" class="text-danger m-l-10">（在系统设置-体测表模版设置中可以添加）</span></div>
            <div class="flex flex-wrap">
              <div v-for="(v,i) in item" :key="i">
                <el-form-item v-if="v.en_name=='shape' && v.status" :label="(v.name)" class="m-b-10">
                  <el-select v-model="form[v.id]" placeholder="请选择" class="w-250">
                    <el-option
                      v-for="it in (JSON.parse(v.unit))"
                      :key="it"
                      :label="it"
                      :value="it"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="v.en_name!='shape' && v.status" :label="(v.name + (v.unit?'('+v.unit+')':''))" class="m-b-10">
                  <el-input v-model="form[v.id]" class="w-250" />
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <!-- 自定义体测数据 -->
        <!-- 体测照片 -->
        <el-form-item label="体测照片">
          <el-upload
            v-loading="upLoading"
            :action="storeUpload"
            list-type="picture-card"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="upSuccess"
            :headers="utils.upload_headers()"
            :on-progress="upProgress"
            accept=".jpg,.jpeg,.png"
          >
            <i class="el-icon-plus" />
          </el-upload>
          <el-dialog :visible.sync="dialogVisible" class="dialog_view_image">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <!-- 体测时间 -->
        <el-form-item label="体测时间">
          <el-date-picker
            v-model="form.create_time"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            class="w-250"
          />
        </el-form-item>
        <!-- 指导建议 -->
        <el-form-item label="指导建议">
          <el-input v-model="form.proposal" type="textarea" rows="5" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button @click="returnPage()">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getUserDetails } from '@/api/store/user.js'
import { getSideTemplate, addUserSide } from '@/api/store/fianco.js'

export default {
  data() {
    return {
      // 列表
      userInfo: {},
      form: {
        value: '',
        content: [],
        images: [],
        create_time: this.$moment().format('YYYY-MM-DD')
      },
      templateList: [],
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      isShow: {
        basics: false,
        girth: false,
        modify: false,
        body_composition: false,
        healthy: false,
        custom: false
      }
    }
  },
  created() {
    this.getTempalate()
  },
  mounted() {
    this.getUserInfo()
    // this.getTempalate()
  },
  methods: {
    getUserInfo() {
      getUserDetails(this.$route.params.id).then(res => {
        this.userInfo = res.data
      }).catch(() => {})
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.form.images = []
      for (var i in fileList) {
        this.form.images.push(fileList[i].response.data.path)
      }
    },
    upProgress() {
      this.upLoading = true
    },
    handleRemove(file, fileList) {
      this.form.images = []
      for (var i in fileList) {
        this.form.images.push(fileList[i].response.data.path)
      }
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getTempalate() {
      getSideTemplate().then(res => {
        if (!this.utils.ObjHasKey(res.data, 'custom')) {
          res.data.custom = []
        }
        const data_arr = res.data
        this.templateList = data_arr

        const t = data_arr
        for (var item in t) {
          for (var item2 in t[item]) {
            const i = t[item][item2]
            if (i.status === 1) {
              this.isShow[item] = true
              break
            }
          }
        }
      }).catch((e) => {
        console.log('🚀 ~ file: add.vue ~ line 156 ~ getSideTemplate ~ e', e)
      })
    },
    onSubmit() {
      console.log('🚀 ~ file: add.vue ~ line 139 ~ onSubmit ~ this.form', this.form)

      const templateData = []

      const t = this.templateList
      for (var item in t) {
        for (var item2 in t[item]) {
          const i = t[item][item2]
          // if (['height', 'weight', 'shape'].indexOf(i.en_name) > -1 && this.utils.empty(this.form[i.id])) {
          //   this.$message.error('请将基本体测数据填写完整')
          //   return false
          // }
          templateData.push({
            id: i.id,
            name: i.name,
            group: i.group,
            unit: i.unit,
            en_name: i.en_name,
            value: this.form[i.id] ? this.form[i.id] : ''
          })
        }
      }
      const data = {
        user_id: this.$route.params.id,
        content: JSON.stringify(templateData),
        create_time: this.form.create_time,
        images: this.form.images.join(','),
        proposal: this.form.proposal
      }
      addUserSide(data).then(res => {
        this.$message.success('添加成功')
        // 返回上一页
        this.returnPage()
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.template_group{
  border-left: 5px solid #3498db;
  margin-bottom: 10px;
}
.el-form-item--small.el-form-item{
  margin-bottom:10px;
}
.title{
  color: #303133;
  font-weight: 600;
}
</style>
