var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    _vm._l(_vm.tableData, function(item, index) {
      return _c(
        "div",
        { key: index, staticClass: "m-y-10" },
        [
          _c("div", { staticClass: "p-y-8 p-x-10 title h3" }, [
            _vm._v(_vm._s(_vm.$t("side_template." + index))),
            index == "custom"
              ? _c("span", { staticClass: "text-danger m-l-10" }, [
                  _vm._v("可以添加更多维度")
                ])
              : _vm._e()
          ]),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: item,
                stripe: "",
                "empty-text": "暂无自定义体测数据"
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "维度名称" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.edit || row.add
                            ? [
                                _c("el-input", {
                                  staticClass: "edit-input",
                                  model: {
                                    value: row.name,
                                    callback: function($$v) {
                                      _vm.$set(row, "name", $$v)
                                    },
                                    expression: "row.name"
                                  }
                                })
                              ]
                            : _c("span", [_vm._v(_vm._s(row.name))])
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "unit", label: "单位" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.edit || row.add
                            ? [
                                _c("el-input", {
                                  staticClass: "edit-input",
                                  model: {
                                    value: row.unit,
                                    callback: function($$v) {
                                      _vm.$set(row, "unit", $$v)
                                    },
                                    expression: "row.unit"
                                  }
                                })
                              ]
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.en_name === "shape" ? "" : row.unit
                                  )
                                )
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  formatter: _vm.statusFormatter
                }
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.add
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addSaveTemplate(row)
                                    }
                                  }
                                },
                                [_vm._v(" 保存 ")]
                              )
                            : _vm._e(),
                          row.add
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "m-r-10",
                                  attrs: { type: "danger" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.deleteRow(_vm.tableData)
                                    }
                                  }
                                },
                                [_vm._v(" 取消 ")]
                              )
                            : _vm._e(),
                          row.edit
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.confirmEdit(row)
                                    }
                                  }
                                },
                                [_vm._v(" 保存 ")]
                              )
                            : _vm._e(),
                          row.edit
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "m-r-10",
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelEdit(row)
                                    }
                                  }
                                },
                                [_vm._v(" 取消 ")]
                              )
                            : _vm._e(),
                          !row.add && !row.edit
                            ? _c("el-switch", {
                                staticClass: "m-r-10",
                                attrs: {
                                  "active-color": "#13ce66",
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.statusChange(row)
                                  }
                                },
                                model: {
                                  value: row.status,
                                  callback: function($$v) {
                                    _vm.$set(row, "status", $$v)
                                  },
                                  expression: "row.status"
                                }
                              })
                            : _vm._e(),
                          row.group == "custom" && !row.add && !row.edit
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.btn_access(
                                        "s_side_template_edit"
                                      ),
                                      expression:
                                        "btn_access('s_side_template_edit')"
                                    }
                                  ],
                                  staticClass: "m-r-10",
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-edit",
                                    offset: 2
                                  },
                                  on: {
                                    click: function($event) {
                                      row.edit = !row.edit
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              )
                            : _vm._e(),
                          _c(
                            "el-popconfirm",
                            {
                              attrs: { title: "确认删除该模版数据吗？" },
                              on: {
                                confirm: function($event) {
                                  return _vm.side_template_delete(row.id)
                                }
                              }
                            },
                            [
                              row.group == "custom" && !row.add && !row.edit
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.btn_access(
                                            "s_side_template_del"
                                          ),
                                          expression:
                                            "btn_access('s_side_template_del')"
                                        }
                                      ],
                                      staticClass: "m-r-10",
                                      attrs: {
                                        slot: "reference",
                                        type: "danger",
                                        icon: "el-icon-delete",
                                        offset: 2
                                      },
                                      slot: "reference"
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          index == "custom" && !_vm.isAdd
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.btn_access("s_side_template_add"),
                      expression: "btn_access('s_side_template_add')"
                    }
                  ],
                  staticClass: "m-t-5"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-circle-plus" },
                      on: { click: _vm.addTemplate }
                    },
                    [_vm._v("新增")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }