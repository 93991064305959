<template>
  <div class="app-container">
    <!-- 搜索 -->
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <el-form-item label="品牌" prop="brandID">
            <Select :type="1" @changeId="handleBrandID" />
          </el-form-item>
          <!-- 付款状态 -->
          <el-form-item label="付款状态">
            <el-select v-model="listQueryParams.pay_status" placeholder="请选择">
              <el-option label="全部" :value="-1" />
              <el-option label="待支付" :value="0" />
              <el-option label="已支付" :value="1" />
              <el-option label="已取消" :value="2" />
              <el-option label="已退款" :value="3" />
            </el-select>
          </el-form-item>
          <!-- 秒杀订单 -->
          <el-form-item label="秒杀订单">
            <el-select v-model="listQueryParams.is_seckill" placeholder="请选择">
              <el-option label="全部" :value="-1" />
              <el-option label="是" :value="1" />
              <el-option label="否" :value="0" />
            </el-select>
          </el-form-item>
          <!-- 付款时间 -->
          <el-form-item label="付款时间">
            <el-date-picker
              v-model="listQueryParams.pay_time"
              type="daterange"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" style="width: 300px;" placeholder="搜索卡号 | 支付订单号 | 品牌名称" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="exportLoading" @click="handleFilter" />
        </el-input>
      </div>
    </div>
    <el-table ref="multipleTable" v-loading="searchLoading" :data="tableData.list" row-key="id">
      <el-table-column label="卡号" align="center">
        <template slot-scope="{ row }">
          <el-tag>{{ row.number }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="appid" label="品牌">
        <template slot-scope="stop">
          <span>{{ stop.row.app_name ? stop.row.app_name : '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡名称" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.seckill_activity_backup" :style="{color : row.seckill_activity_backup.cards.length > 0 ? '' : 'red'}">
            <el-tooltip v-if="row.seckill_activity_backup.cards.length > 0" class="item" effect="dark" content="Bottom Center 提示文字" placement="right">
              <div>{{ row.seckill_activity_backup.cards[0].name + ' 等 ' + row.seckill_activity_backup.cards.length + ' 张会员卡' }}</div>
              <template slot="content">
                <div v-for="item in row.seckill_activity_backup.cards" :key="item.id">{{ item.name }}</div>
              </template>
            </el-tooltip>
            <div v-else>用户信息丢失</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="金额" align="center">
        <template slot-scope="{ row }">
          {{ row.pay_price }} 元
        </template>
      </el-table-column>
      <el-table-column label="付款状态" align="center">
        <template slot-scope="{ row }">
          <el-tag v-if="row.pay_status === 0" type="warning">待支付</el-tag>
          <el-tag v-if="row.pay_status === 1" type="success">已支付</el-tag>
          <el-tag v-if="row.pay_status === 2" type="info">已取消</el-tag>
          <el-tag v-if="row.pay_status === 3" type="danger">已退款</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="transaction_id" label="支付订单号" align="center" min-width="100" />
      <el-table-column prop="is_seckill" label="秒杀订单" align="center">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_seckill === 0" type="info">否</el-tag>
          <el-tag v-if="row.is_seckill === 1" type="success">是</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="pay_time" label="付款时间" align="center">
        <!-- 时间戳转换标准时间 -->
        <template slot-scope="{ row }">
          <div v-if="row.pay_time">
            {{ row.pay_time | formatDate('YYYY-MM-DD HH:mm') }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      small
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
// API
import { getOrderList } from '@/api/admin/orderList.js'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Select from '@/components/elSelect/Select'

export default {
  components: { Pagination, Select },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      searchLoading: false,
      exportLoading: false,
      timer: ''
    }
  },
  created() {},
  mounted() {
    this.getList()
    this.timer = new Date().getTime()
  },
  methods: {
    getList() {
      this.searchLoading = true
      this.exportLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var query = []
      if (this.listQueryParams.query) query.push({ field: 'a.number|a.transaction_id|app.name', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.appid) {
        query.push({ field: 'a.appid', key: this.listQueryParams.appid })
      }
      if (this.listQueryParams.pay_status >= 0) {
        query.push({ field: 'a.pay_status', key: this.listQueryParams.pay_status })
      }
      if (this.listQueryParams.is_seckill >= 0) {
        query.push({ field: 'a.is_seckill', key: this.listQueryParams.is_seckill })
      }
      if (this.listQueryParams.pay_time) {
        query.push({ field: 'a.pay_time', type: 'between-time', key: this.listQueryParams.pay_time })
      }
      data.query = this.utils.getQueryParams(query)

      getOrderList(data).then(res => {
        this.tableData = res.data
        this.searchLoading = false
        this.exportLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleBrandID(val) {
      this.listQueryParams.appid = val
    }
  }
}
</script>

<style lang="scss" scoped></style>
