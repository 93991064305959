<template>
  <div class="app-container">
    <div class="createPost-container">
      <el-form ref="postForm" :model="postForm" :rules="rules" class="form-container" label-width="80px" label-position="left">
        <!-- <el-form-item prop="name">
			<MDinput v-model="postForm.name" :maxlength="100" name="name" required>
				标题
			</MDinput>
			</el-form-item> -->
        <el-form-item label="分类" prop="category_id">
          <el-select v-model="postForm.category_id" placeholder="请选择">
            <el-option
              v-for="item in categoryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="postForm.title" placeholder="标题" />
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <Tinymce ref="editor" v-model="postForm.content" />
        </el-form-item>
        <el-form-item label="文章封面" prop="cover">
          <el-upload
            v-loading="upLoading"
            class="images-uploader"
            :action="adminUpload"
            :show-file-list="false"
            :on-success="upSuccess"
            :headers="utils.upload_headers()"
            :on-progress="upProgress"
            accept=".jpg,.jpeg,.png"
          >
            <img v-if="postForm.cover" :src="postForm.cover" class="images" title="点击上传图片">
            <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
          </el-upload>
        </el-form-item>
        <el-form-item label="视频地址" prop="video_file">
          <el-input v-model="postForm.video_file" placeholder="视频地址" />
        </el-form-item>
        <el-form-item label="视频封面" prop="video_cover">
          <el-upload
            v-loading="upVideoCoverLoading"
            class="images-uploader"
            :action="adminUpload"
            :show-file-list="false"
            :on-success="upSuccess1"
            :headers="utils.upload_headers()"
            :on-progress="upProgress1"
            accept=".jpg,.jpeg,.png"
          >
            <img v-if="postForm.video_cover" :src="postForm.video_cover" class="images" title="点击上传图片">
            <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
          </el-upload>
        </el-form-item>
        <el-form-item label="排序" prop="weigh">
          <el-input v-model="postForm.weigh" placeholder="排序" type="number" />
        </el-form-item>
        <el-form-item label="热门">
          <el-switch
            v-model="postForm.is_hot"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="入门引导">
          <el-switch
            v-model="postForm.is_guide"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="显示">
          <el-switch
            v-model="postForm.status"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="submitForm">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import MDinput from '@/components/MDinput'
import Tinymce from '@/components/Tinymce'
import { getSortType } from '@/api/admin/category.js'
import { addArticle } from '@/api/admin/article.js'
export default {
  components: { Tinymce },
  data() {
    return {
      postForm: {
        category_id: '',
        cover: '',
        video_cover: '',
        video_file: '',
        title: '',
        content: '',
        is_hot: 0,
        status: 0,
        weigh: 100,
        is_guide: 0
      },
      rules: {
        category_id: [{ required: true, message: '请选择分类', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }],
        weigh: [{ required: true, message: '请输入排序', trigger: 'blur' }]
      },
      categoryOptions: [],
      upLoading: false,
      upVideoCoverLoading: false
    }
  },
  created() {
    this.getCategroyList()
  },
  methods: {
    getCategroyList() {
      getSortType('article').then(res => {
        this.categoryOptions = res.data[0].children
      })
    },
    submitForm() {
      this.$refs.postForm.validate(valid => {
        if (valid) {
          const data = this.postForm
          addArticle(data).then(res => {
            this.$message.success(res.msg)
            this.$router.push({ name: 'article' })
          }).catch(() => {})
        } else {
          this.$message.error('请填写完整')
        }
      })
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.postForm.cover = response.data.path
    },
    upProgress() {
      this.upLoading = true
    },
    upSuccess1(response, file, fileList) {
      this.upVideoCoverLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.postForm.video_cover = response.data.path
    },
    upProgress1() {
      this.upVideoCoverLoading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409eff;
}
.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
</style>
