<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- 筛选 -->
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="60px" label-position="left" label-suffix="：" :inline="true">
          <el-form-item label="项目">
            <el-select v-model="listQueryParams.id" placeholder="请选择项目" clearable>
              <el-option label="APP" :value="0" />
              <el-option label="PC" :value="1" />
              <el-option label="约课端" :value="2" />
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input
          v-model="listQueryParams.query"
          placeholder="搜索 错误内容|路径|设备型号|设备机型|设备UA|设备ID|版本|IP地址|系统信息"
          @keyup.enter.native="handleFilter"
        >
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <div class="title">可搜索 错误内容 | 路径 | 设备型号 | 设备机型 | 设备UA | 设备ID | 版本 | IP地址 | 系统信息</div>
      <!-- 表格 -->
      <el-table v-loading="tableLoading" :data="tableData.list" stripe style="width: 100%">
        <el-table-column prop="uuid" label="UUID" align="center" min-width="180" />
        <!-- <el-table-column prop="user_id" label="用户ID" align="center" /> -->
        <el-table-column prop="error" label="错误内容" align="center">
          <template slot-scope="{ row }">
            <el-popover v-if="row.error !== 'Error:'" placement="top" width="400" trigger="click">
              <div style="max-height: 300px; overflow: auto;">
                {{ row.error }}
              </div>
              <el-button slot="reference" type="text">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="project" label="项目" align="center">
          <template slot-scope="{ row }">
            <el-tag v-if="row.project === 0">APP</el-tag>
            <el-tag v-if="row.project === 1">PC</el-tag>
            <el-tag v-if="row.project === 2">约课端</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="path" label="路径" align="center" min-width="120" />
        <el-table-column prop="params" label="请求参数" align="center">
          <template slot-scope="{ row }">
            <el-popover v-if="row.params !== '[]' && row.params" placement="top" width="400" trigger="click">
              <div style="max-height: 300px; overflow: auto;">
                <!-- 便利对象 -->
                {
                <div v-for="(value, key) in JSON.parse(row.params)" :key="key">
                  <div style="margin: 5px 0 5px 20px">{{ key }}: {{ value }}</div>
                </div>
                }
              </div>
              <el-button slot="reference" type="text">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="os" label="设备型号" align="center" />
        <el-table-column prop="device" label="设备机型" align="center" />
        <el-table-column prop="ua" label="设备UA" align="center">
          <template slot-scope="{ row }">
            <el-popover v-if="row.ua" placement="top" width="400" trigger="click">
              <div style="max-height: 300px; overflow: auto;">
                {{ row.ua }}
                <div v-for="(item, index) in row.ua.split(' ')" :key="index">{{ item }}</div>
              </div>
              <el-button slot="reference" type="text">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="appid" label="商户ID" align="center" /> -->
        <el-table-column prop="version" label="版本" align="center" />
        <el-table-column prop="ip" label="IP地址" align="center" />
        <el-table-column prop="system_info" label="系统信息" align="center">
          <template slot-scope="{ row }">
            <el-popover v-if="row.system_info !== '[]'" placement="top" width="400" trigger="click">
              <div style="max-height: 300px; overflow: auto;">
                <!-- 便利对象 -->
                {
                <div v-for="(value, key) in JSON.parse(row.system_info)" :key="key">
                  <div style="margin: 5px 0 5px 20px">{{ key }}: {{ value }}</div>
                </div>
                }
              </div>
              <el-button slot="reference" type="text">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="日期" align="center" min-width="100" />
      </el-table>
      <pagination
        v-show="tableData.count > 0"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
// 分页组件
import Pagination from '@/components/Pagination'
// API
import { getAbnormalLogList } from '@/api/admin/abnormalLog'

export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      searchLoading: false,
      tableLoading: false
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.searchLoading = true
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      var querys = []
      if (this.listQueryParams.id !== undefined) {
        querys.push({ field: 'project', key: this.listQueryParams.id })
      }
      if (this.listQueryParams.query !== undefined) {
        querys.push({ field: 'error|path|params|os|device|ua|client_id|version|ip|system_info', type: 'like', key: this.listQueryParams.query })
      }
      data.query = this.utils.getQueryParams(querys)

      getAbnormalLogList(data)
        .then(res => {
          this.tableData = res.data
        })
        .finally(() => {
          this.searchLoading = false
          this.tableLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 10px;
  font-size: 12px;
  color: #999;
}
</style>
