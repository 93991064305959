<template>
  <div v-if="$route.name=='s_course_list'" class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="课程类型">
            <el-select v-model="listQueryParams.type" placeholder="请选择" filterable clearable>
              <el-option key="0" label="团课" :value="0" />
              <el-option key="2" label="精品课" :value="2" />
              <el-option key="1" label="私教课" :value="1" />
            </el-select>
          </el-form-item>
          <!-- 教练 -->
          <el-form-item label="教练" prop="coachID">
            <Select :type="4" :width="216" @changeId="handleCoachID" />
          </el-form-item>
          <!-- 教室 -->
          <el-form-item label="教室" prop="classroomID">
            <Select :type="8" @changeId="handleClassroomID" />
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索课程名称|课程简介" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <router-link :to="{name: 's_course_add'}">
        <el-button v-show="btn_access('s_course_add')" type="primary" icon="el-icon-plus" class="filter-item m-l-10">添加</el-button>
      </router-link>
      <!-- <el-button class="m-l-10 filter-item" type="info" icon="el-icon-search" @click="searchShowBtn">
        高级检索
      </el-button> -->
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData.list"
      row-key="id"
    >
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column prop="image" label="图片">
        <template slot-scope="{row}">
          <div class="block">
            <el-avatar shape="square" :size="50" :src="row.image">
              <img :src="errorImageSrc">
            </el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="课程名称" />
      <el-table-column prop="store_user.real_name" label="教练" min-width="120">
        <template slot-scope="{row: {store_user}}">
          <div class="flex">
            <div v-if="store_user">{{ store_user.real_name }}</div>
            <div v-else style="color: red;">教练待更新</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="duration" label="课程时长(分钟)" />
      <el-table-column prop="galleryful" label="可容纳人数" />
      <el-table-column prop="type" label="课程类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type==0">团课</span>
          <span v-if="scope.row.type==2">精品课</span>
          <span v-if="scope.row.type==1">私教课</span>
        </template>
      </el-table-column>
      <el-table-column prop="difficulty" label="难度">
        <template slot-scope="{row:{difficulty}}">
          <el-rate
            :value="difficulty"
            disabled
            text-color="#ff9900"
            :title="difficulty"
          />
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button v-show="btn_access('s_course_edit')" class="m-2" type="primary" icon="el-icon-info" @click="toPage(scope.row.id)">详情</el-button>
          <!-- <delPopover v-show="btn_access('s_course_delete')" class="m-2" :list-length="tableData.list.length" :api-data="apiData" :page.sync="listQuery.page" :row="scope.row" btn-msg="删除" @delete="getList" /> -->
          <el-button v-show="btn_access('s_course_delete')" icon="el-icon-delete" class="m-2" type="danger" @click="delUser(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { getCourse, delCourse } from '@/api/store/course.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Select from '@/components/elSelect/Select'
export default {
  components: { Pagination, Select },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      apiData: { url: '/v1/course' },
      loading: false,
      searchLoading: false,
      coachType: 3,
      downloadLoading: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    setQuery() {
      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'name|abstract', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.type !== '' && this.listQueryParams.type != null) querys.push({ field: 'type', key: this.listQueryParams.type })
      if (this.listQueryParams.store_user_id !== '' && this.listQueryParams.store_user_id != null) querys.push({ field: 'store_user_id', key: this.listQueryParams.store_user_id })
      if (this.listQueryParams.classroom_id) querys.push({ field: 'classroom_id', key: this.listQueryParams.classroom_id })
      return this.utils.getQueryParams(querys)
    },
    // 获取列表
    getList() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()

      getCourse(data).then(res => {
        this.tableData = res.data
        this.searchLoading = false
        this.loading = false
      }).catch(() => {})
    },
    toPage(id) {
      this.$router.push({ name: 's_course_edit', params: { id: id }})
    },
    // 删除
    delUser(id) {
      this.$confirm('课程删除后将清除相关数据，如未开课课表、预约、相册、评论等数据，删除后将无法恢复，请确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCourse(id, { force: 0 })
          .then(res => {
            if (res.status === 200) {
              this.$message.success('删除成功')
              this.getList()
            }
          })
          .catch(res => {
            if (res.status === 701) {
              this.$confirm(res.msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                delCourse(id, { force: 1 }).then(res => {
                  if (res.status === 200) {
                    this.$message.success('删除成功')
                    this.getList()
                  }
                })
              })
            }
          })
      }).catch(() => {})
    },
    handleCoachID(val) {
      this.listQueryParams.store_user_id = val
    },
    handleClassroomID(val) {
      this.listQueryParams.classroom_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.el-avatar{
  background: none;
}
</style>
