var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.utils.empty(_vm.bookInfo)
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog_w750",
              attrs: {
                "append-to-body": "",
                "close-on-click-modal": false,
                visible: _vm.dialog.visible,
                title: _vm.dialog.title
              },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.dialog, "visible", $event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "about_info"
                },
                [
                  _c("div", { staticClass: "m-b-10 font-18" }, [
                    _vm._v("用户信息")
                  ]),
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "持卡人" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bookInfo.user
                                ? _vm.getUserNames(_vm.bookInfo.user)
                                : "--"
                            )
                          )
                        ]
                      ),
                      _c("el-descriptions-item", { attrs: { label: "手机" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.bookInfo.user ? _vm.bookInfo.user.phone : "--"
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "m-t-20 m-b-10 font-18" }, [
                    _vm._v("课程信息")
                  ]),
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "课程名称" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bookInfo.course.name +
                                "（" +
                                (_vm.bookInfo.course.type === 0
                                  ? "团课"
                                  : _vm.bookInfo.course.type === 2
                                  ? "精品课"
                                  : "私教课") +
                                "）"
                            )
                          )
                        ]
                      ),
                      _c("el-descriptions-item", { attrs: { label: "教练" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.bookInfo.store_user
                              ? _vm.bookInfo.store_user.real_name
                              : "--"
                          )
                        )
                      ]),
                      _vm.bookInfo.type !== 1
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { label: "教室" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.bookInfo.class_schedule.classroom
                                      ? _vm.bookInfo.class_schedule.classroom
                                          .name
                                      : _vm.bookInfo.course.classroom
                                      ? _vm.bookInfo.course.classroom.name
                                      : "--"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("el-descriptions-item", { attrs: { label: "时长" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.bookInfo.class_schedule
                              ? _vm.bookInfo.class_schedule.duration
                              : _vm.bookInfo.course.duration
                          ) + " 分钟"
                        )
                      ]),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "上课场馆" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bookInfo.user_membership_card.venues
                                ? _vm.bookInfo.user_membership_card.venues.name
                                : "--"
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "上课时间" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(_vm.bookInfo.subscribe_time)
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "m-t-20 m-b-10 font-18" }, [
                    _vm._v("预约信息")
                  ]),
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "预约状态" } },
                        [
                          _c(
                            "div",
                            { staticClass: "block" },
                            [
                              _vm.bookInfo.status == 0
                                ? _c("el-tag", { staticClass: "m-x-2" }, [
                                    _vm._v("已预约")
                                  ])
                                : _vm._e(),
                              _vm.bookInfo.status == 1
                                ? _c(
                                    "el-tag",
                                    {
                                      staticClass: "m-x-2",
                                      attrs: { type: "success" }
                                    },
                                    [_vm._v("已签到")]
                                  )
                                : _vm._e(),
                              _vm.bookInfo.status == 2
                                ? _c(
                                    "el-tag",
                                    {
                                      staticClass: "m-x-2",
                                      attrs: { type: "info" }
                                    },
                                    [_vm._v("已取消")]
                                  )
                                : _vm._e(),
                              _vm.bookInfo.queue > 0
                                ? _c(
                                    "el-tag",
                                    {
                                      staticClass: "m-x-2",
                                      attrs: { type: "danger" }
                                    },
                                    [_vm._v("排队中")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "预约方式" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.bookInfo.subscribe_channel == "STORE" ||
                                  _vm.bookInfo.subscribe_channel == "USER"
                                  ? _vm.bookInfo.subscribe_channel_text +
                                      " " +
                                      (_vm.bookInfo.ext
                                        ? _vm.bookInfo.ext.subscribe_real_name
                                          ? "(" +
                                            _vm.bookInfo.ext
                                              .subscribe_real_name +
                                            ")"
                                          : ""
                                        : "")
                                  : "自主预约"
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "预约人数" } },
                        [_vm._v(_vm._s(_vm.bookInfo.subscribe_number) + " 人")]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "预约时间" } },
                        [_vm._v(_vm._s(_vm.bookInfo.create_time.slice(0, 16)))]
                      ),
                      _vm.bookInfo.status == 1
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { label: "签到方式" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.bookInfo.sign_mode_text +
                                      " " +
                                      (_vm.bookInfo.ext
                                        ? _vm.bookInfo.ext.sign_real_name
                                          ? "(" +
                                            _vm.bookInfo.ext.sign_real_name +
                                            ")"
                                          : ""
                                        : "")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.bookInfo.status == 1
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { label: "签到时间" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate")(_vm.bookInfo.sign_time)
                                )
                              )
                            ]
                          )
                        : _vm._e(),
                      (_vm.bookInfo.status == 1 && _vm.bookInfo.integral > 0
                      ? true
                      : false)
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { label: "赠送积分" } },
                            [_vm._v("+ " + _vm._s(_vm.bookInfo.integral))]
                          )
                        : _vm._e(),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "预约备注", span: 2 } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bookInfo.remark ? _vm.bookInfo.remark : "无"
                            )
                          )
                        ]
                      ),
                      _vm.bookInfo.status == 2
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { label: "取消原因" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.bookInfo.cancel_notice) +
                                  _vm._s(
                                    _vm.bookInfo.cancel_notice != "自主取消"
                                      ? "" +
                                          (_vm.bookInfo.cancel_store_user
                                            ? "（" +
                                              _vm.bookInfo.cancel_store_user
                                                .real_name +
                                              "）"
                                            : "")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.bookInfo.status == 2
                        ? _c(
                            "el-descriptions-item",
                            { attrs: { label: "取消时间" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate")(_vm.bookInfo.cancel_time)
                                )
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.bookInfo.queue_on == 1
                    ? _c("div", { staticClass: "m-t-20 m-b-10 font-18" }, [
                        _vm._v("排队信息")
                      ])
                    : _vm._e(),
                  _vm.bookInfo.queue_on == 1
                    ? _c(
                        "el-descriptions",
                        { attrs: { column: 2, border: "" } },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "排队状态" } },
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type:
                                      _vm.bookInfo.queue_status == 0
                                        ? "warning"
                                        : _vm.bookInfo.queue_status == 1
                                        ? "primary"
                                        : _vm.bookInfo.queue_status == 2
                                        ? "info"
                                        : "danger"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.bookInfo.queue_status == 0
                                          ? "排队中"
                                          : _vm.bookInfo.queue_status == 1
                                          ? "候补成功"
                                          : _vm.bookInfo.queue_status == 2
                                          ? "已取消"
                                          : "候补失败"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.bookInfo.queue_status == 0
                            ? _c(
                                "el-descriptions-item",
                                { attrs: { label: "排队序号" } },
                                [
                                  _vm._v(
                                    _vm._s("第" + _vm.bookInfo.queue + "个")
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "排队时间" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate")(_vm.bookInfo.queue_time)
                                )
                              )
                            ]
                          ),
                          _vm.bookInfo.queue_status == 1
                            ? _c(
                                "el-descriptions-item",
                                { attrs: { label: "候补时间" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.bookInfo.queue_success_time
                                      )
                                    )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.bookInfo.queue_status == 3
                            ? _c(
                                "el-descriptions-item",
                                { attrs: { label: "排队失败原因", span: 2 } },
                                [_vm._v(_vm._s(_vm.bookInfo.queue_msg))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "m-t-20 m-b-10 font-18" }, [
                    _vm._v("支付信息")
                  ]),
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "支付方式" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bookInfo.order_type == 0
                                ? "会员卡"
                                : "在线支付" +
                                    "(" +
                                    _vm.bookInfo.order_data.type +
                                    ")"
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "卡名称" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bookInfo.user_membership_card.card
                                ? _vm.bookInfo.user_membership_card.card.name
                                : "--"
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "卡号" } },
                        [
                          _c("el-tag", [
                            _vm._v(
                              _vm._s(_vm.bookInfo.user_membership_card.number)
                            )
                          ])
                        ],
                        1
                      ),
                      _c("el-descriptions-item", { attrs: { label: "余额" } }, [
                        _vm.bookInfo.user_membership_card.status_id !== 14
                          ? _c("div", [
                              _vm.bookInfo.user_membership_card.card.type === 0
                                ? _c("span", [
                                    _vm.getExcessTime(
                                      _vm.bookInfo.user_membership_card
                                        .valid_time
                                    ) > 0
                                      ? _c(
                                          "span",
                                          { staticClass: "text-success" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getExcessTime(
                                                    _vm.bookInfo
                                                      .user_membership_card
                                                      .valid_time
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.bookInfo.user_membership_card
                                              .card
                                              ? _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatCardUnitType(
                                                        _vm.bookInfo
                                                          .user_membership_card
                                                          .card.type
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getExcessTime(
                                                    _vm.bookInfo
                                                      .user_membership_card
                                                      .valid_time
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.bookInfo.user_membership_card
                                              .card
                                              ? _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatCardUnitType(
                                                        _vm.bookInfo
                                                          .user_membership_card
                                                          .card.type
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.bookInfo.user_membership_card
                                            .limit
                                        ) +
                                        " "
                                    ),
                                    _vm.bookInfo.user_membership_card.card
                                      ? _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatCardUnitType(
                                                _vm.bookInfo
                                                  .user_membership_card.card
                                                  .type
                                              )
                                            )
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                            ])
                          : _c("div", [_vm._v("--")])
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "扣减" } }, [
                        _vm._v(_vm._s(_vm.bookInfo.limit))
                      ]),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "有效期至" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.bookInfo.user_membership_card.valid_time == 0
                                ? "未开卡"
                                : _vm.bookInfo.user_membership_card.valid_time
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }