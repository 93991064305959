import request from '@/utils/request'

// 海报模版

// 获取海报模版列表
export function getList(data) {
  return request({
    url: '/admin/v1/poster_template',
    method: 'get',
    params: data
  })
}

// 创建海报模板
export function Add(data) {
  return request({
    url: '/admin/v1/poster_template',
    method: 'post',
    data
  })
}

// 删除海报模板
export function Delete(id, data) {
  return request({
    url: `/admin/v1/poster_template/${id}`,
    method: 'delete',
    params: data
  })
}

// 获取海报模板详情
export function getInfo(id, data) {
  return request({
    url: `/admin/v1/poster_template/${id}`,
    method: 'get',
    params: data
  })
}

// 更新海报模板信息
export function Edit(id, data) {
  return request({
    url: `/admin/v1/poster_template/${id}`,
    method: 'put',
    data
  })
}
