var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading"
        }
      ],
      ref: "ruleForm",
      attrs: {
        rules: _vm.rules,
        "label-position": "left",
        model: _vm.form,
        "label-width": "100px"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "草稿箱列表", prop: "draft_id" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "flex flex-col col-top",
              model: {
                value: _vm.form.draft_id,
                callback: function($$v) {
                  _vm.$set(_vm.form, "draft_id", $$v)
                },
                expression: "form.draft_id"
              }
            },
            _vm._l(_vm.formData.draft_list, function(item, index) {
              return _c(
                "el-radio",
                {
                  key: index,
                  staticClass: "m-b-10",
                  attrs: { label: item.draft_id }
                },
                [
                  _c("span", [_vm._v(_vm._s(item.source_miniprogram))]),
                  _vm._v(" - "),
                  _c("span", [_vm._v(_vm._s(item.user_desc))]),
                  _vm._v(" - "),
                  _c("span", { staticClass: "text-info" }, [
                    _vm._v(_vm._s(item.user_version))
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              staticClass: "m-t-10",
              attrs: { type: "primary", loading: _vm.buttonLoading },
              on: {
                click: function($event) {
                  return _vm.submitForm("ruleForm")
                }
              }
            },
            [_vm._v("立即创建")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }