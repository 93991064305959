<template>
  <div class="errPage-container text-center">
    <div class="img">
      <img :src="errGif" width="250" height="250" alt="Girl has dropped her ice cream.">
    </div>
    <div class="title font-md m-20">系统升级维护中……</div>
    <div class="content">
      <div class="before">尊敬的小九用户：</div>
      <div class="desc">{{ msg }}</div>
    </div>
  </div>
</template>

<script>
import errGif from '@/assets/410_images/web.png'
import { getSystemNote } from '@/api/store/common.js'

export default {
  name: 'Page410',
  data() {
    return {
      errGif: errGif + '?' + +new Date(),
      ewizardClap: 'https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646',
      dialogVisible: false,
      msg: localStorage.getItem('410Msg')
    }
  },
  mounted() {
    // this.getMsg()
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({ path: '/dashboard' })
      } else {
        this.$router.go(-1)
      }
    },
    getMsg() {
      getSystemNote().then(res => {
        console.log('🚀 ~ file: 410.vue ~ line 38 ~ getSystemNote ~ res', res)
      }).catch((res) => {
        console.log('🚀 ~ file: 410.vue ~ line 40 ~ getSystemNote ~ res', res)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .errPage-container {
    width: 800px;
    max-width: 100%;
    margin: 100px auto;
    .title{
      color:#1892AA;
    }
    .content{
      font-size: 14px;
      text-align: left;
      padding: 20px 30px;
      line-height: 30px;
      .before{
        font-weight: 600;
      }
      .desc{
        color:#82848a;
        margin: 0 30px;
      }
    }
  }
  @media screen and (max-width: 620px) {
    .desc{
      margin:unset !important;
    }
  }
</style>
