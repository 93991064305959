var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c(
        "div",
        { staticClass: "fr" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.returnPage()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      ),
      _c("div", { staticStyle: { clear: "both" } }),
      _c("router-view", { key: _vm.key })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }