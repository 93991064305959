var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.name == "s_visitors_list"
    ? _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "div",
                { staticClass: "searchBox" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.listQueryParams,
                        "label-width": "100px",
                        "label-position": "left",
                        "label-suffix": "："
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                filterable: "",
                                clearable: ""
                              },
                              model: {
                                value: _vm.listQueryParams.sex,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryParams, "sex", $$v)
                                },
                                expression: "listQueryParams.sex"
                              }
                            },
                            _vm._l(_vm.sexList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "会籍顾问" } },
                        [
                          _c("Select", {
                            attrs: { type: 4 },
                            on: { changeId: _vm.handleCoachID }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "来访时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd"
                            },
                            model: {
                              value: _vm.listQueryParams.visitor_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.listQueryParams,
                                  "visitor_time",
                                  $$v
                                )
                              },
                              expression: "listQueryParams.visitor_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "m-b-10",
                          attrs: {
                            icon: "el-icon-search",
                            loading: _vm.searchLoading,
                            type: "success"
                          },
                          on: { click: _vm.handleFilter }
                        },
                        [_vm._v("搜索")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "搜索 手机号 | 昵称" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleFilter($event)
                        }
                      },
                      model: {
                        value: _vm.listQueryParams.query,
                        callback: function($$v) {
                          _vm.$set(_vm.listQueryParams, "query", $$v)
                        },
                        expression: "listQueryParams.query"
                      }
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          loading: _vm.searchLoading
                        },
                        on: { click: _vm.handleFilter },
                        slot: "append"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.btn_access("s_visitors_add"),
                      expression: "btn_access('s_visitors_add')"
                    }
                  ],
                  staticClass: "filter-item m-l-10",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.add_edit }
                },
                [_vm._v(" 添加 ")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: { data: _vm.tableData.list, "row-key": "id" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "avatar", label: "头像" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          !_vm.utils.empty(row)
                            ? _c("div", { staticClass: "flex col-center" }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-avatar",
                                      {
                                        attrs: {
                                          shape: "square",
                                          size: 50,
                                          src: row.avatar
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: _vm.errorMemberSrc() }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "p-l-10" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.getUserNames(row)))
                                  ]),
                                  _c("br"),
                                  _c("span", [_vm._v(_vm._s(row.phone))])
                                ])
                              ])
                            : _c("div", { staticClass: "text-danger" }, [
                                _vm._v("用户信息缺失")
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3013639199
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sex",
                  label: "性别",
                  formatter: _vm.formatTableSex
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "birthday", label: "生日" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(row.birthday ? row.birthday : "---") +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2490919955
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "channel", label: "登入渠道" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.program_open_id
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "m-r-2",
                                  attrs: { type: "success" }
                                },
                                [_vm._v("小程序")]
                              )
                            : _vm._e(),
                          row.wechat_open_id
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "m-r-2",
                                  attrs: { type: "primary" }
                                },
                                [_vm._v("公众号")]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  982228098
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "store_user", label: "会籍顾问" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.store_user ? row.store_user.real_name : "--"
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3051005586
                )
              }),
              _c("el-table-column", {
                attrs: { prop: "visitor_time", label: "来访时间" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.visitor_time ? row.visitor_time : "--"
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2880706334
                )
              }),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "240px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.btn_access("s_visitors_info"),
                                  expression: "btn_access('s_visitors_info')"
                                }
                              ],
                              staticClass: "m-2",
                              attrs: { type: "primary", icon: "el-icon-info" },
                              on: {
                                click: function($event) {
                                  return _vm.toInfoPage(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("详情")]
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.btn_access("s_user_vipcard_add"),
                                  expression: "btn_access('s_user_vipcard_add')"
                                }
                              ],
                              staticClass: "filter-item m-2",
                              attrs: { type: "success", icon: "el-icon-plus" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "s_user_vipcard_add",
                                    params: {
                                      user_id: scope.row.id,
                                      card_id: 3
                                    }
                                  })
                                }
                              }
                            },
                            [_vm._v("发卡")]
                          ),
                          _c(
                            "el-popconfirm",
                            {
                              attrs: { title: "删除后将无法恢复，确定吗？" },
                              on: {
                                confirm: function($event) {
                                  return _vm.delUser(scope.row.id)
                                }
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.btn_access(
                                        "s_visitors_delete"
                                      ),
                                      expression:
                                        "btn_access('s_visitors_delete')"
                                    }
                                  ],
                                  staticClass: "m-2",
                                  attrs: {
                                    slot: "reference",
                                    icon: "el-icon-delete",
                                    type: "danger"
                                  },
                                  slot: "reference"
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.btn_access("s_visitor_track_list"),
                                  expression:
                                    "btn_access('s_visitor_track_list')"
                                }
                              ],
                              staticClass: "m-2",
                              attrs: { icon: "el-icon-notebook-2" },
                              on: {
                                click: function($event) {
                                  return _vm.toPage(scope.row.id)
                                }
                              }
                            },
                            [_vm._v("回访记录")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  124913164
                )
              })
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableData.count > 0,
                expression: "tableData.count>0"
              }
            ],
            attrs: {
              total: _vm.tableData.count,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList
            }
          }),
          _vm.dialog.visible
            ? _c(
                "el-dialog",
                {
                  staticClass: "dialog_auto",
                  attrs: {
                    "append-to-body": "",
                    "close-on-click-modal": false,
                    visible: _vm.dialog.visible,
                    title: "添加访客"
                  },
                  on: {
                    "update:visible": function($event) {
                      return _vm.$set(_vm.dialog, "visible", $event)
                    }
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "100px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100px" },
                          attrs: { label: "头像", prop: "avatar" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "block",
                              on: {
                                click: function($event) {
                                  _vm.imagecropperShow = true
                                }
                              }
                            },
                            [
                              _c(
                                "el-avatar",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    shape: "square",
                                    size: 100,
                                    src: _vm.form.avatar,
                                    title: _vm.dialog.id
                                      ? "点击修改"
                                      : "点击上传"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.errorMemberSrc() }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("image-cropper", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imagecropperShow,
                                expression: "imagecropperShow"
                              }
                            ],
                            key: _vm.imagecropperKey,
                            attrs: {
                              field: "file",
                              width: 300,
                              height: 300,
                              url: _vm.storeUpload,
                              "lang-type": "zh"
                            },
                            on: {
                              close: _vm.imagecropperClose,
                              "crop-upload-success": _vm.cropSuccess
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别", prop: "sex" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.sex,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sex", $$v)
                                },
                                expression: "form.sex"
                              }
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("女")
                              ]),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("男")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "real_name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入姓名" },
                            model: {
                              value: _vm.form.real_name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "real_name", $$v)
                              },
                              expression: "form.real_name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "phone" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入手机号" },
                              model: {
                                value: _vm.form.phone,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "phone", $$v)
                                },
                                expression: "form.phone"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "prepend" }, slot: "prepend" },
                                [
                                  _c("Code", {
                                    on: { area_code: _vm.area_code }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "会籍顾问", prop: "store_user_id" } },
                        [
                          _c("Select", {
                            attrs: { type: 4 },
                            on: { changeId: _vm.handleCoachIDAdd }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "来访时间" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "dialog_w100",
                            attrs: {
                              type: "datetime",
                              format: "yyyy-MM-dd HH:mm",
                              "value-format": "yyyy-MM-dd HH:mm",
                              placeholder: "用户来访时间"
                            },
                            model: {
                              value: _vm.form.visitor_time,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "visitor_time", $$v)
                              },
                              expression: "form.visitor_time"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: "请输入备注"
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("确认")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              _vm.dialog.visible = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("VisitorTrack", {
            attrs: {
              id: _vm.dialog.trackId,
              "track-visible": _vm.dialog.trackVisible
            },
            on: {
              "update:trackVisible": function($event) {
                return _vm.$set(_vm.dialog, "trackVisible", $event)
              },
              "update:track-visible": function($event) {
                return _vm.$set(_vm.dialog, "trackVisible", $event)
              }
            }
          })
        ],
        1
      )
    : _c("div", [_c("router-view")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }