var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "searchBox" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.listQueryParams,
                    "label-width": "100px",
                    "label-position": "left",
                    "label-suffix": "："
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.listQueryParams.create_time,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "create_time", $$v)
                          },
                          expression: "listQueryParams.create_time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类" } },
                    [
                      _c("SelectTree", {
                        attrs: {
                          props: _vm.defaultProps,
                          options: _vm.categoryList,
                          value: _vm.listQueryParams.category_id,
                          clearable: false,
                          accordion: false,
                          "root-name": "全部分类"
                        },
                        on: {
                          getValue: function($event) {
                            return _vm.getCategoryValue($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.type,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "type", $$v)
                            },
                            expression: "listQueryParams.type"
                          }
                        },
                        _vm._l(_vm.typeList, function(item, index) {
                          return _c(
                            "el-option",
                            {
                              key: index,
                              attrs: { label: item, value: index }
                            },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m-b-10",
                      attrs: {
                        icon: "el-icon-search",
                        loading: _vm.searchLoading,
                        type: "success"
                      },
                      on: { click: _vm.handleFilter }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "搜索海报名称" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleFilter($event)
                    }
                  },
                  model: {
                    value: _vm.listQueryParams.query,
                    callback: function($$v) {
                      _vm.$set(_vm.listQueryParams, "query", $$v)
                    },
                    expression: "listQueryParams.query"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      loading: _vm.searchLoading
                    },
                    on: { click: _vm.handleFilter },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("poster_add"),
                  expression: "btn_access('poster_add')"
                }
              ],
              staticClass: "filter-item m-l-10",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.toPage("")
                }
              }
            },
            [_vm._v(" 添加 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.listLoading,
              expression: "loading.listLoading"
            }
          ],
          ref: "multipleTable",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "name", label: "名称" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "background_image",
              label: "背景图"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: row.background_image,
                            fit: "contain",
                            "preview-src-list": [row.background_image]
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "preview_image", label: "效果图" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: row.preview_image,
                            fit: "contain",
                            "preview-src-list": [row.preview_image]
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "category.name", label: "分类" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "type", label: "类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(_vm.typeList[row.type]) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "is_hidden", label: "显示/隐藏" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.is_hidden
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("隐藏")
                        ])
                      : _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("显示")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "visits",
              label: "访问次数",
              "render-header": _vm.renderId
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "weigh", label: "排序" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "create_time", label: "创建时间" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", "min-width": "140", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("poster_edit"),
                            expression: "btn_access('poster_edit')"
                          }
                        ],
                        staticClass: "m-2",
                        attrs: { type: "primary", icon: "el-icon-edit" },
                        on: {
                          click: function($event) {
                            return _vm.toPage(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("poster_copy"),
                            expression: "btn_access('poster_copy')"
                          }
                        ],
                        attrs: { title: "复制这个模版？" },
                        on: {
                          confirm: function($event) {
                            return _vm.copyTemplate(scope.row)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: {
                              slot: "reference",
                              type: "warning",
                              icon: "el-icon-copy-document"
                            },
                            slot: "reference"
                          },
                          [_vm._v("拷贝")]
                        )
                      ],
                      1
                    ),
                    _c("delPopover", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btn_access("poster_del"),
                          expression: "btn_access('poster_del')"
                        }
                      ],
                      staticClass: "m-2",
                      attrs: {
                        "list-length": _vm.tableData.list.length,
                        "api-data": _vm.apiData,
                        page: _vm.listQuery.page,
                        row: scope.row,
                        "btn-msg": "删除"
                      },
                      on: {
                        "update:page": function($event) {
                          return _vm.$set(_vm.listQuery, "page", $event)
                        },
                        delete: _vm.getList
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count>0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }