<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- 筛选 -->
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <!-- 品牌 -->
          <el-form-item label="品牌" prop="brandID">
            <Select :type="1" @changeId="handleBrandID" />
          </el-form-item>
          <!-- 状态 -->
          <el-form-item label="状态">
            <el-select v-model="listQueryParams.status" placeholder="请选择状态" clearable>
              <el-option label="已提交" :value="1" />
              <el-option label="申请成功" :value="2" />
              <el-option label="申请失败" :value="3" />
            </el-select>
          </el-form-item>
          <!-- 代码类型 -->
          <el-form-item prop="code_type" label="代码类型">
            <el-select v-model="listQueryParams.code_type" placeholder="请选择" clearable>
              <el-option label="统一社会信用代码(18 位)" :value="1" />
              <el-option label="组织机构代码(9 位 xxxxxxxx-x)" :value="2" />
              <el-option label="营业执照注册号(15 位)" :value="3" />
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="模糊搜索" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('app_fast_create')" type="primary" class="filter-item m-l-10" @click="handleRegister">
        快速注册
      </el-button>
      <div style="color: #c0c4cc;">搜索品牌 | 企业名称 | 企业代码 | 法人微信 | 法人姓名 | 法人手机号 | 小程序appid</div>
      <!-- 表格 -->
      <el-table v-loading="tableLoading" class="m-t-10" :data="dataList.list" stripe style="width: 100%">
        <el-table-column label="品牌信息">
          <template slot-scope="{ row }">
            <div class="flex">
              <el-avatar v-if="row.app_logo" shape="square" class="m-r-6" :src="row.app_logo" />
              <span>{{ row.app_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="企业名称" />
        <el-table-column prop="code" label="企业代码" />
        <el-table-column prop="legal_persona_wechat" label="法人微信" />
        <el-table-column prop="legal_persona_name" label="法人姓名" />
        <el-table-column prop="component_phone" label="法人手机号" />
        <el-table-column prop="wx_appid" label="小程序appid" />
        <el-table-column prop="status" label="状态">
          <template slot-scope="{ row }">
            <el-tag v-if="row.status === -2">已提交</el-tag>
            <el-tag v-if="row.status === 0" type="success">申请成功</el-tag>
            <el-tag v-if="row.status > 0" type="danger">申请失败</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!-- 翻页组件 -->
      <pagination
        v-show="dataList.count > 0"
        :total="dataList.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
      <el-dialog
        v-if="registerDialogVisible"
        title="快速注册小程序"
        :visible.sync="registerDialogVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="20%"
      >
        <!-- 品牌 -->
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="品牌" prop="brandID">
            <Select :type="1" @changeId="handleBrandIDS" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" :disabled="appid == 0" @click="handleToPage">确 定</el-button>
          <el-button @click="registerDialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// API
import { getAppletRegistrationList } from '@/api/admin/merchants.js'
// 分页组件
import Pagination from '@/components/Pagination'
import Select from '@/components/elSelect/Select'

export default {
  components: { Pagination, Select },
  data() {
    return {
      dataList: {
        list: [],
        count: 0
      },
      searchLoading: false,
      tableLoading: false,
      registerDialogVisible: false,
      appid: ''
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      var querys = []
      if (this.listQueryParams.appid) querys.push({ field: 'appid', key: this.listQueryParams.appid })
      if (this.listQueryParams.status) {
        if (this.listQueryParams.status === 1) querys.push({ field: 'status', type: '=', key: -2 })
        if (this.listQueryParams.status === 2) querys.push({ field: 'status', type: '=', key: 0 })
        if (this.listQueryParams.status === 3) querys.push({ field: 'status', type: 'gt', key: 0 })
      }
      if (this.listQueryParams.code_type) querys.push({ field: 'code_type', key: this.listQueryParams.code_type })
      if (this.listQueryParams.query) {
        querys.push({
          field: 'app.name|a.name|a.code|a.legal_persona_wechat|a.legal_persona_name|a.component_phone|a.wx_appid',
          type: 'like',
          key: this.listQueryParams.query
        })
      }
      data.query = this.utils.getQueryParams(querys)

      getAppletRegistrationList(data)
        .then(res => {
          this.dataList = res.data
        })
        .finally(() => {
          this.tableLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleBrandID(val) {
      this.listQueryParams.appid = val
    },
    handleBrandIDS(val) {
      this.appid = val
    },
    handleRegister() {
      this.registerDialogVisible = true
    },
    handleToPage() {
      this.registerDialogVisible = false
      this.$router.push({ name: 'adminSignWeChat', params: { id: this.appid }})
    }
  }
}
</script>

<style lang="scss" scoped></style>
