<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <el-skeleton style="width: 100%" :loading="loading" animated>
          <template slot="template">
            <el-skeleton-item variant="image" style="width: 80px; height: 80px; margin:10px auto;border-radius:10px" />
            <div style="padding: 10px;">
              <!-- <el-skeleton-item variant="p" style="width: 50%" /> -->
            </div>
          </template>
          <template>
            <img :src="list.logo ? list.logo : defaultLogo" class="sidebar-logo">
            <!-- <span class="sidebar-title">{{ list.name }}</span> -->
          </template>
        </el-skeleton>
      </router-link>
      <!-- <router-link v-else key="expand" class="sidebar-logo-link" to="/"> -->
      <el-skeleton v-else style="width: 100%" :loading="loading" class="sidebar-logo-link" animated>
        <template slot="template">
          <el-skeleton-item variant="image" style="width: 80px; height: 80px; margin:10px auto;border-radius:10px" />
          <div style="padding: 10px;">
            <!-- <el-skeleton-item variant="p" style="width: 50%" /> -->
          </div>
        </template>
        <template>
          <router-link key="expand" to="/">
            <img :src="list.logo ? list.logo : defaultLogo" class="sidebar-logo">
          </router-link>
          <el-tooltip class="item" effect="dark" :content="list.name" placement="right">
            <span class="sidebar-title">{{ list.name }}</span>
          </el-tooltip>
        </template>
      </el-skeleton>
      <!-- </router-link> -->
    </transition>
  </div>
</template>

<script>
import { getAppInfo } from '@/api/store/management.js'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      defaultLogo: require('@/assets/images/logo_square.svg'),
      list: {
        logo: this.defaultLogo,
        name: process.env.VUE_APP_TITLE
        // logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
        // name: '总后台'
      }
    }
  },
  mounted() {
    this.loading = true
    if (this.$store.getters.roles.indexOf('admin') !== 0) {
      getAppInfo()
        .then(res => {
          localStorage.setItem('App', JSON.stringify(res.data))
          this.list = res.data
          this.loading = false
        })
        .catch(() => {})
    } else {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.collapse {
  & .sidebar-logo {
    width: 25px !important;
    height: 25px !important;
  }
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  // height: 50px;
  // line-height: 50px;
  // background: #2b2f3a;
  text-align: center;
  overflow: hidden;
  padding: 10px;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      -webkit-user-drag: none;
      width: 80px;
      height: 80px;
      vertical-align: middle;
      margin: 10px 0px;
      // margin-right: 12px;
      border-radius: 10px;
    }

    & .sidebar-title {
      display: block;
      margin: 0;
      color: #98a0a7;
      font-weight: 400;
      line-height: 30px;
      font-size: 16px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
