import request from '@/utils/request'

/**
 * 邀请记录列表
 */
export function getInvite(params) {
  return request({
    url: '/admin/v1/activity/invite/invite_record',
    method: 'get',
    params
  })
}

/**
 * 删除记录列表
 */
export function deleteInvite(id) {
  return request({
    url: `/admin/v1/activity/invite/invite_record/${id}`,
    method: 'delete'
  })
}

/**
 * 手动添加记录
 */
export function addInvite(data) {
  return request({
    url: '/admin/v1/activity/invite/manual_cashback',
    method: 'post',
    data
  })
}

/**
 * 手动返现审核
 */
export function auditInvite(id, data) {
  return request({
    url: `/admin/v1/activity/invite/invite_record/${id}/audit`,
    method: 'put',
    data
  })
}
// 手动改为状态已完成
export function completeInvite(id, data) {
  return request({
    url: `/admin/v1/activity/invite/invite_record/${id}/complete`,
    method: 'put',
    data
  })
}
