var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c("el-page-header", {
        staticClass: "page-header",
        attrs: { content: "查看详情" },
        on: {
          back: function($event) {
            return _vm.returnPage()
          }
        }
      }),
      _c(
        "el-descriptions",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.upForm,
              expression: "upForm"
            }
          ],
          staticClass: "margin-top",
          staticStyle: { "margin-top": "20px" },
          attrs: { column: 2, border: "" }
        },
        [
          _c("el-descriptions-item", { attrs: { label: "班级名称" } }, [
            _vm._v(_vm._s(_vm.form.name))
          ]),
          _c("el-descriptions-item", { attrs: { label: "开课时间" } }, [
            _vm._v(_vm._s(_vm.classTime[0]) + " - " + _vm._s(_vm.classTime[1]))
          ]),
          _c("el-descriptions-item", { attrs: { label: "班级课时" } }, [
            _vm._v(_vm._s(_vm.form.class_time))
          ]),
          _c("el-descriptions-item", { attrs: { label: "班级简介" } }, [
            _vm._v(_vm._s(_vm.form.desc))
          ]),
          _c("el-descriptions-item", { attrs: { label: "会员购买" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.form.member_buy === true ? "会员" : "") +
                " " +
                _vm._s(_vm.form.guest_buy === true ? "访客" : "") +
                " "
            )
          ]),
          _c("el-descriptions-item", { attrs: { label: "售价" } }, [
            _vm._v(_vm._s(_vm.money === 1 ? "免费" : "收费"))
          ]),
          _vm.money === 2
            ? _c("el-descriptions-item", { attrs: { label: "售价" } }, [
                _vm._v(_vm._s(_vm.form.money))
              ])
            : _vm._e(),
          _vm.money === 2
            ? _c("el-descriptions-item", { attrs: { label: "优惠价" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.form.discount_on === 1 ? "开启" : "关闭") +
                    " "
                )
              ])
            : _vm._e(),
          _vm.form.discount_on === 1 && _vm.money === 2
            ? _c("el-descriptions-item", { attrs: { label: "优惠价费用" } }, [
                _vm._v(" " + _vm._s(_vm.form.discount) + " ")
              ])
            : _vm._e(),
          _vm.form.discount_on === 1 && _vm.money === 2
            ? _c("el-descriptions-item", { attrs: { label: "优惠价时间" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.discounts[0]) +
                    " - " +
                    _vm._s(_vm.discounts[1]) +
                    " "
                )
              ])
            : _vm._e(),
          _c("el-descriptions-item", { attrs: { label: "人数上限" } }, [
            _vm._v(_vm._s(_vm.form.people_number === 1 ? "限制" : "不限制"))
          ]),
          _vm.form.people_number === 1
            ? _c("el-descriptions-item", { attrs: { label: "限制人数" } }, [
                _vm._v(_vm._s(_vm.people_number) + " 人")
              ])
            : _vm._e(),
          _c("el-descriptions-item", { attrs: { label: "请假上限" } }, [
            _vm._v(_vm._s(_vm.form.ask_number === 1 ? "限制" : "不限制"))
          ]),
          _vm.form.ask_number === 1
            ? _c("el-descriptions-item", { attrs: { label: "请假次数" } }, [
                _vm._v(_vm._s(_vm.ask_number) + " 次")
              ])
            : _vm._e(),
          _c("el-descriptions-item", { attrs: { label: "是否上架" } }, [
            _vm._v(_vm._s(_vm.form.shelves === 1 ? "上架" : "下架"))
          ]),
          _c("el-descriptions-item", { attrs: { label: "班级描述" } }, [
            _vm._v(_vm._s(_vm.form.introduction))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }