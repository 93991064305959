<template>
  <div>
    <!-- 时间选择器 -->
    <div class="m-t-20"><timeSelect :date-time="dateTime" @updateTime="updateTime" /></div>
    <!-- 课程列表 -->
    <div>
      <div v-loading="loading" class="course_list">
        <el-row v-if="venuesList.count>0" :gutter="10">
          <el-col v-for="item in venuesList.list" :key="item.id" :xs="12" :sm="12" :md="8" :lg="4" :xl="4">
            <div class="course_item">
              <div @click="course_active(item)">

                <el-card :body-style="{ padding: '0px'}" shadow="hover" class="course_card" :class="(activeCourse==item.id?'active':'') + (item.overdue?' disabled':'')">
                  <el-image
                    style="width: 100%; height: 130px"
                    :src="(item.course&&item.course.image?item.course.image:default_bg)"
                    fit="cover"
                  />
                  <div class=" course_card_text" style="position:relative">
                    <div class="course_color" :style="{background:item.color}" />
                    <p><span>教练</span>：{{ item.store_user?item.store_user.real_name:'' }}</p>
                    <p class="line-1"><span>课程</span>：<span v-if="item.course&&item.course.type==2" class="text-danger" title="精品课">{{ '[精] ' }}</span>{{ item.course?item.course.name:'--' }}</p>
                    <p><span>教室</span>：{{ item.classroom?item.classroom.name:'未知教室' }} </p>
                    <p><span>人数</span>：{{ item.total_sign_in }}/{{ item.total_valid }}/{{ item.galleryful }}
                      <el-tooltip class="item" effect="dark" content="已签到人数/有效预约人数/可容纳人数" placement="bottom">
                        <i class="el-icon-question text-info m-l-2" />
                      </el-tooltip>
                    </p>
                    <p><span>难度</span>：
                      <el-rate
                        :value="item.difficulty"
                        disabled
                        :style="{display:'inline-flex'}"
                      />
                    </p>
                    <div class="bottom flex m-y-12">
                      <time class="time flex-1">{{ item.start_time | formatDateLT }} ~ {{ item.end_time | formatDateLT }}</time>
                      <el-button v-if="!item.auto_cancel" v-show="btn_access('s_course_curriculum_cancel')" :disabled="!!item.overdue" type="text" class="button text-warning" @click="courseCancel(item)">取消课程</el-button>
                      <el-button v-if="!item.auto_cancel" v-show="btn_access('s_workbench_subscribe')" :disabled="!!item.auto_cancel" type="text" class="button" @click="courseSubscribe(item)">预约</el-button>
                      <el-button v-if="item.auto_cancel" :disabled="!!item.auto_cancel" type="text" class="button ">课程已取消</el-button>
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
          </el-col>
        </el-row>
        <div v-else class="venuesList_empty text-center p-10">
          <img :src="empty_default_img" alt="">
          <div><div class="text-info">当日暂无团课预约记录</div><el-link type="primary" @click="$router.push({ name: 's_course_curriculum' })">去排课</el-link></div>
        </div>
        <div class="flex row-center">
          <pagination
            v-show="venuesList.count > 0"
            :total="venuesList.count"
            :page.sync="flippingPages.page"
            :limit.sync="flippingPages.limit"
            @pagination="getVenuesList"
          />
        </div>
      </div>
    </div>
    <!-- 预约列表 -->
    <div v-if="activeCourse" class="m-t-30">
      <bookList ref="booklist" v-model="activeCourse" :type="0" :title="booklist_title" :total-sign-in="total_sign_in" :total-valid="total_valid" @refresh="refresh" />
    </div>
    <!-- 预约弹窗 -->
    <div>
      <groupBook v-model="dialog" :courseid="courseid" @updateListMethods="updateListMethods" />
    </div>
  </div>
</template>

<script>
import timeSelect from '@/views/store/worktable/components/timeSelect'
import bookList from '@/views/store/worktable/components/bookList'
import groupBook from '@/views/store/worktable/group/book'

import { getGroupVenues } from '@/api/store/worktable.js'
import { classScheduleCancel } from '@/api/store/course.js'
import Utils from '@/utils/utils.js'
import Pagination from '@/components/Pagination' // 分页组件

export default {
  components: { timeSelect, bookList, groupBook, Pagination },
  data() {
    return {
      // 课程列表
      venuesList: {
        list: [],
        count: 0
      },
      // 当前选中
      activeCourse: 0,
      dateTime: '',
      default_bg: require('@/assets/images/default_bg.jpg'),
      loading: false,
      dialog: { title: '约团课', visible: false, id: '' },
      // 课程表id
      courseid: '',
      booklist_title: '预约信息',
      flippingPages: {
        page: 1,
        limit: 6
      },
      total_sign_in: 0,
      total_valid: 0
    }
  },
  watch: {
    dateTime: {
      handler(newVal) {
        this.flippingPages = {
          page: 1,
          limit: 6
        }
        this.getVenuesList()
      },
      deep: true
    }
  },
  created() {
    if (localStorage.getItem('dateTime')) this.dateTime = localStorage.getItem('dateTime')
    else this.dateTime = Utils.getDayTime()
  },
  mounted() {
    this.getVenuesList()
  },
  methods: {
    /**
     * 获取团课课程表
     */
    getVenuesList() {
      this.loading = true
      this.activeCourse = 0

      const data = JSON.parse(JSON.stringify(this.flippingPages))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      // const data = {
      //   offset: 0,
      //   limit: 6
      // }

      data.has_fine = 2

      getGroupVenues(this.dateTime, data).then(res => {
        const data = res.data
        // console.log(res.data)
        this.venuesList = data
        if (!this.utils.empty(data.list)) this.course_active(data.list[0])
      }).finally(() => {
        this.loading = false
      })
    },
    updateTime(e) {
      localStorage.setItem('dateTime', e)
      this.dateTime = e
    },
    /**
     * 课程点击事件
     */
    course_active(item) {
      this.booklist_title = `课程预约信息（${item.course ? item.course.name : '--'}）`
      this.activeCourse = item.id
      this.total_sign_in = item.total_sign_in
      this.total_valid = item.total_valid
    },
    /**
     * 预约点击事件
     */
    courseSubscribe(item) {
      this.courseid = item.id
      this.dialog = { title: '约团课', visible: true, id: item.id }
    },
    // 更新预约记录列表
    updateListMethods() {
      // 父组件通过$ref调用子组件1中的事件方法
      this.$refs.booklist.getList()
      this.courseid = ''
    },
    courseCancel(item) {
      this.$confirm('取消课程后，已预约会员会被强制取消预约，并且这节课将不能再被预约, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        classScheduleCancel(item.id, 1).then(res => {
          this.getVenuesList()
          this.updateListMethods()
        })
      }).catch(() => {
      })
    },
    refresh() {
      this.getVenuesList()
    }
  }
}
</script>

<style lang="scss" scoped>

.course_list{
  .venuesList_empty{
    min-height: 400px;
    img {
      -webkit-user-drag: none;
    }
  }
  .button{
    padding:0;
  }
  .course_item{
    cursor: pointer;
    .disabled{
      background-color: #eee;
      color:#82848a;
    }
    .course_card_text{
      padding:8px;
      min-height: 170px;
    }
    .time {
      font-size: 13px;
      color: #999;
    }

    .course_color{
      width: 30px;height: 30px;position: absolute;right: 20px;top: 20px;
    }
  }
  .course_card{
    margin-bottom:10px;
    background-origin: no-repeat;
    background-image: url("~@/assets/images/active_bg4.png");
    background-position:0% 200%;
  }
  .active{
    z-index:999;
    border: 1px solid #c1c1c1;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background-position:80% 140%;
  }
}
</style>
