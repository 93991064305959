import request from '@/utils/request'

// 预约设置列表
export function getSubscribeSetting(data) {
  return request({
    url: `/store/v1/course_subscribe_setting`,
    method: 'get',
    params: data
  })
}

// 修改预约设置
export function editSubscribeSetting(data) {
  return request({
    url: `/store/v1/course_subscribe_setting`,
    method: 'put',
    data
  })
}

// 预约设置查询
export function getSubscribeSettingInfo(name) {
  return request({
    url: `/store/v1/course_subscribe_setting/${name}`,
    method: 'get'
  })
}

