<template>
  <el-dialog
    :visible.sync="dialogVisibleAccount"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
    :show-close="false"
    @open="handleOpen"
    @close="handleClose('ruleForm')"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">{{ type === 'add' ? '新增账号' : '编辑账号' }}</div>
        <div class="title_icon cursor" @click="handleClose('ruleForm')">
          <i class="el-icon-close" />
        </div>
      </div>
      <el-form ref="ruleForm" v-loading="formLoading" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm m-t-40">
        <el-form-item label="姓名" prop="real_name">
          <el-input v-model="ruleForm.real_name" clearable />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="ruleForm.phone" clearable />
        </el-form-item>
        <!--  -->
        <el-form-item v-if="type === 'add'" label="密码" prop="password">
          <el-input v-model="ruleForm.password" show-password clearable />
        </el-form-item>
        <el-form-item v-else-if="type === 'edit'" label="密码">
          <el-input v-model="ruleForm.password" show-password clearable />
        </el-form-item>
        <el-form-item v-if="type === 'add'" label="确认密码" prop="confirmPass">
          <el-input v-model="ruleForm.confirmPass" show-password clearable />
        </el-form-item>
        <el-form-item v-else-if="type === 'edit'" label="确认密码">
          <el-input v-model="ruleForm.confirmPass" show-password clearable />
        </el-form-item>
        <!--  -->
        <el-form-item label="角色">
          <el-select v-model="ruleForm.role_id" style="width: 100%;" multiple placeholder="请选择">
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="buttonLoading" @click="handleClose('ruleForm')">取 消</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { addAccount, editAccount, getAccountDetail, getRoleList } from '@/api/admin/account.js'
// 函数
import { deepClone } from '@/utils'

export default {
  props: {
    dialogVisibleAccount: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    typeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === '') callback(new Error('请输入手机号'))
      else if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/.test(
          value
        )
      ) {
        callback(new Error('请输入正确的手机号'))
      } else callback()
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') callback(new Error('请输入密码'))
      else if (value.length < 6) callback(new Error('密码长度不能小于6位!'))
      else {
        if (this.ruleForm.confirmPass !== '') {
          this.$refs.ruleForm.validateField('confirmPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') callback(new Error('请再次输入密码'))
      else if (value !== this.ruleForm.password) callback(new Error('两次输入密码不一致!'))
      else callback()
    }
    return {
      ruleForm: {
        real_name: '',
        phone: '',
        password: '',
        confirmPass: '',
        role_id: []
      },
      rules: {
        real_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        password: [{ required: true, validator: validatePass, trigger: 'blur' }],
        confirmPass: [{ required: true, validator: validatePass2, trigger: 'blur' }]
      },
      roleList: [],
      buttonLoading: false,
      formLoading: false
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.getRoleList()
      if (this.type === 'edit') this.getDetails()
    },
    // 关闭的回调
    handleClose(formName) {
      this.$emit('accountVisible')
      this.$refs[formName].resetFields()
      this.ruleForm.role_id = []
    },
    // 保存表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          const data = deepClone(this.ruleForm)
          // 去除对象中的 confirmPass
          if (this.type === 'add') {
            delete data.confirmPass
            addAccount(data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('新增成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          } else {
            editAccount(this.typeId, data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('编辑成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    // 角色列表
    getRoleList() {
      getRoleList().then(res => {
        const { list } = res.data
        this.roleList = list
      })
    },
    // 详情
    getDetails() {
      this.formLoading = true
      getAccountDetail(this.typeId)
        .then(res => {
          const { data } = res
          const { real_name, phone, role } = data
          // 把id过滤出来
          const roleId = role.map(item => item.id)
          this.ruleForm = { real_name, phone, role_id: roleId }
        })
        .finally(() => {
          this.formLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
