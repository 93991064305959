<template>
  <!-- 报班 -->
  <div class="app-container special-center">
    <!-- 头部导航 -->
    <el-page-header content="报班" class="page-header" @back="returnPage()" />
    <!-- 表单 -->
    <div class="formColumn">
      <el-form ref="ruleForm" v-loading="upForm" :model="form" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="用户类型">
          <el-radio-group v-model="user">
            <el-radio label="会员">会员</el-radio>
            <el-radio label="访客">访客</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="搜索用户">
          <el-autocomplete
            v-model="form.user_id"
            style="width: 300px;"
            :fetch-suggestions="querySearchAsync"
            placeholder="昵称/姓名/手机号"
            @select="handleSelect"
          />
        </el-form-item> -->
        <!-- 搜索用户 -->
        <el-form-item v-if="user === '会员'" label="搜索用户">
          <Select ref="memberRef" :type="2" @changeId="memberId" />
        </el-form-item>
        <el-form-item v-if="user === '访客'" label="搜索用户">
          <Select ref="visitorRef" :type="3" @changeId="memberId" />
        </el-form-item>
        <el-form-item label="班级" style="width: 400px;">
          <el-input v-model="form.class_id" :disabled="true" placeholder="请输入班级名称" />
        </el-form-item>
        <el-form-item label="班级课时">
          <el-input v-model="balance" :disabled="true" placeholder="请输入班级课时" type="number" style="width: 200px;">
            <template slot="append">节</template>
          </el-input>
        </el-form-item>
        <el-form-item label="实际课程" prop="balance">
          <el-input v-model="form.balance" placeholder="请输入班级课时" type="number" style="width: 200px;">
            <template slot="append">节</template>
          </el-input>
        </el-form-item>
        <el-form-item label="入班日期" prop="join_time">
          <el-date-picker
            v-model="form.join_time"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <el-form-item label="有效期">
          <el-radio-group v-model="form.validity">
            <el-radio :label="0">不限制</el-radio>
            <el-radio :label="1">限制时间</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.validity === 1" label="有效期至">
          <el-date-picker
            v-model="time"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            align="right"
            placeholder="选择日期时间"
          />
        </el-form-item>
        <el-form-item label="参考售价">
          <el-input v-model="money" :disabled="true" placeholder="费用" type="number" style="width: 200px;">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="收费金额" prop="charge_money">
          <el-input v-model="form.charge_money" placeholder="费用" type="number" style="width: 200px;">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="付款方式">
          <el-select v-model="form.charge_type" placeholder="请选付款方式" clearable>
            <el-option v-for="(item, index) in payment_method" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>

        <el-form-item style="margin: none; text-align: center;">
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          <el-button type="info" @click="returnPage()">返 回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// API
import { getMclass, postMclassAdd, getVisitor } from '@/api/store/course'
import { getUser } from '@/api/store/user.js'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'
import { deepClone } from '@/utils'
// 组件
import Select from '@/components/elSelect/Select'

export default {
  components: { Select },
  data() {
    return {
      form: {
        user_id: '',
        class_id: '',
        balance: '',
        join_time: '',
        validity: 0,
        charge_money: '',
        charge_type: 0
      },
      name: '',
      balance: '',
      time: '',
      money: '',
      user: '会员',
      rules: {
        user_id: [{ required: true, message: '请输入用户类型', trigger: 'blur' }],
        join_time: [{ required: true, message: '请输入日期', trigger: 'blur' }],
        balance: [{ required: true, message: '请输入课程', trigger: 'blur' }],
        charge_money: [{ required: true, message: '请输入金额', trigger: 'blur' }]
      },
      upForm: false,
      restaurants: [],
      state: '',
      timeout: null,
      visitors: 0
    }
  },
  watch: {
    user(val) {
      console.log(val)
      if (val === '会员') {
        this.vip()
        this.visitors = 0
        this.$refs.memberRef.refreshGetList()
      }
      if (val === '访客') {
        this.visitor()
        this.visitors = 1
        this.$refs.visitorRef.refreshGetList()
      }
    }
  },
  created() {
    this.upForm = true
    this.vip()
    getMclass(this.$route.params.id).then(res => {
      this.form.class_id = res.data.name
      this.balance = res.data.class_time
      if (res.datadiscount_on === 0) {
        this.money = res.data.money
      } else {
        this.money = res.data.discount
      }
      this.upForm = false
    })
  },
  mounted() {},
  methods: {
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = deepClone(this.form)
          if (data.validity === 1) {
            data.validity = timestampToTimes(this.time.getTime()).slice(0, 10)
          }
          // this.form.join_time = timestampToTimes(this.form.join_time.getTime()).slice(0, 10)
          data.class_id = this.$route.params.id
          this.restaurants.forEach(item => {
            console.log(item)
            if (data.user_id === item.value) data.user_id = item.label
          })
          console.log(data)
          postMclassAdd(data)
            .then(res => {
              this.$message({
                type: 'success',
                message: '报名成功!'
              })
              this.returnPage()
            })
            .catch(() => {
              // if (this.time !== '') {
              //   this.form.validity = 1
              //   this.time = ''
              // }
              // this.form.join_time = ''
            })
        } else {
          return false
        }
      })
    },
    // 会员
    vip() {
      this.restaurants = []
      this.state = ''
      this.timeout = null
      this.form.user_id = ''
      getUser().then(res => {
        res.data.list.forEach(item => {
          if (item.sex === 0) item.sex = '保密'
          if (item.sex === 1) item.sex = '男'
          if (item.sex === 2) item.sex = '女'
          const obj = {
            label: `${item.id}`,
            value: `${item.nickname}/${item.sex}/${item.phone}`
          }
          this.restaurants.push(obj)
        })
      })
    },
    // 访客
    visitor() {
      this.restaurants = []
      this.state = ''
      this.timeout = null
      this.form.user_id = ''
      getVisitor().then(res => {
        res.data.list.forEach(item => {
          if (item.sex === 0) item.sex = '保密'
          if (item.sex === 1) item.sex = '男'
          if (item.sex === 2) item.sex = '女'
          const obj = {
            label: `${item.id}`,
            value: `${item.nickname}/${item.sex}/${item.phone}`
          }
          this.restaurants.push(obj)
        })
        console.log(this.restaurants)
      })
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(results)
      }, 800 * Math.random())
    },
    createStateFilter(queryString) {
      console.log(queryString)
      return state => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    handleSelect(item) {
      console.log(item)
    },
    memberId(val) {
      this.form.user_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.formColumn {
  margin-top: 40px;
}
</style>
