<template>
  <el-dialog :visible.sync="visible" :close-on-click-modal="false" :show-close="false" width="26%" @open="handleOpen">
    <div v-loading="dialogLoading">
      <div slot="title" class="title">
        <div class="title_title">订单详情</div>
        <div class="title_icon cursor" @click="handleClose">
          <i class="el-icon-close" />
        </div>
      </div>
      <div>
        <div>
          <div
            v-for="(item, index) in details"
            v-show="data.operation_type == item.id"
            :key="index"
            class="flex flex-col"
          >
            <img style="width: 50px;" :src="item.img" alt="">
            <div class="m-t-6" style="font-weight: 600;">{{ item.name }}</div>
            <div class="m-t-6" style="font-weight: 600;">
              {{ data.operation_type == 99 ? '-' : '+' }}{{ data.money }}
            </div>
          </div>
          <el-descriptions v-if="data" class="margin-top m-t-20" :column="2" border>
            <el-descriptions-item label="资金变动前">{{ data.money_before }} 元</el-descriptions-item>
            <el-descriptions-item label="资金变动后">{{ data.money_after }} 元</el-descriptions-item>
            <template v-if="data.order_info && Object.prototype.toString.call(data.order_info) === '[object Object]'">
              <el-descriptions-item
                v-if="data.operation_type == 10 || data.operation_type == 20 || data.operation_type == 40"
                label="订单号"
              >
                {{ data.order_info.number }}
              </el-descriptions-item>
              <el-descriptions-item
                :label="
                  data.operation_type == 10
                    ? '付款时间'
                    : data.operation_type == 20
                      ? '报班时间'
                      : data.operation_type == 40
                        ? '预约时间'
                        : data.operation_type == 99
                          ? '提现时间'
                          : data.operation_type == 98
                            ? '驳回时间'
                            : ''
                "
              >
                {{ data.create_time }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 10" label="商品">
                {{ data.order_info.seckill_activity ? data.order_info.seckill_activity.name : '商品已被删除' }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 10" label="会员卡">
                {{
                  data.order_info.seckill_activity
                    ? data.order_info.seckill_activity.card
                      ? data.order_info.seckill_activity.card.name
                      : data.order_info.seckill_activity.cards[0].name +
                        ' 等 ' +
                        data.order_info.seckill_activity.cards.length +
                        ' 张会员卡'
                    : '商品已被删除'
                }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 40" label="课程名称">
                {{ data.order_info.course.name }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 40" label="课程类型">
                {{ data.order_info.type == 0 ? '团课' : data.order_info.type == 1 ? '私教课' : '精品课' }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 40" label="预约人数">
                {{ data.order_info.subscribe_number }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 40" label="开课时间">
                {{ data.order_info.course_subscribe.subscribe_time | formatDate('YYYY-MM-DD HH:mm:ss') }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 20" label="班级名称">
                {{ data.order_info.grade.name }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 20" label="开班时间">
                {{ data.order_info.grade.open_time }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 20" label="结班时间">
                {{ data.order_info.grade.end_time }}
              </el-descriptions-item>
              <el-descriptions-item
                v-if="data.operation_type == 10 || data.operation_type == 20 || data.operation_type == 40"
                label="会员"
              >
                <div class="flex col-center">
                  <div>
                    <el-avatar shape="square" :size="40" :src="data.order_info.user.avatar">
                      <img :src="errorMemberSrc()">
                    </el-avatar>
                  </div>
                  <div class="p-l-10">
                    <span>{{ getUserNames(data.order_info.user) }}</span>
                    <br>
                  </div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item
                v-if="data.operation_type == 10 || data.operation_type == 20 || data.operation_type == 40"
                label="手机号"
              >
                {{ data.order_info.user.phone }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 99 || data.operation_type == 98" label="提现单号">
                {{ data.order_info.order_no }}
              </el-descriptions-item>
              <el-descriptions-item v-if="data.operation_type == 99" label="处理状态">
                <el-tag
                  :type="data.order_info.status == 0 ? 'warning' : data.order_info.status == 2 ? 'danger' : 'success'"
                >
                  {{ data.order_info.status == 0 ? '处理中' : data.order_info.status == 2 ? '已驳回' : '已通过' }}
                </el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="备注说明">
                {{ data.order_info.content ? data.order_info.content : '无' }}
              </el-descriptions-item>
            </template>
          </el-descriptions>
          <div v-if="data.order_info.length === 0" class="venuesList_empty text-center">
            <img style="margin-left: 25px;" :src="empty_default_img" alt="">
            <div><div class="text-info">暂无数据</div></div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { getWalletDetails } from '@/api/store/takeleave'

export default {
  props: ['visible', 'id'],
  data() {
    return {
      data: {
        order_info: []
      },
      details: [
        { id: 10, name: '商城售卡', img: require('@/assets/images/walletIcon/ico_nov_mall.png') },
        { id: 20, name: '会员报班', img: require('@/assets/images/walletIcon/ico_book.png') },
        { id: 40, name: '付费约课', img: require('@/assets/images/walletIcon/Frame 4839.png') },
        { id: 99, name: '提现扣款', img: require('@/assets/images/walletIcon/Frame2.png') },
        { id: 98, name: '提现驳回', img: require('@/assets/images/walletIcon/Frame.png') }
      ],
      dialogLoading: false
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleOpen() {
      this.dialogLoading = true
      getWalletDetails(this.id)
        .then(res => {
          this.data = res.data
          console.log(this.data)
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    // 关闭Dialog
    handleClose() {
      this.$emit('Close')
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .el-dialog__header {
  padding: 0px;
}
</style>
