import request from '@/utils/request'

// 组合卡套餐列表
export function getComboList(params) {
  return request({
    url: '/store/v1/card_combo',
    method: 'get',
    params
  })
}

// 新增组合卡套餐
export function addCombo(data) {
  return request({
    url: '/store/v1/card_combo',
    method: 'post',
    data
  })
}

// 组合卡套餐详情
export function getComboDetail(id) {
  return request({
    url: `/store/v1/card_combo/${id}`,
    method: 'get'
  })
}

// 删除组合卡套餐
export function delCombo(id) {
  return request({
    url: `/store/v1/card_combo/${id}`,
    method: 'delete'
  })
}

// 更新组合卡套餐
export function updateCombo(id, data) {
  return request({
    url: `/store/v1/card_combo/${id}`,
    method: 'put',
    data
  })
}
