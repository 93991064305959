<template>
  <div class="app-container">
    <div class="total m-y-20 font-18">
      国内容量
      <span class="text-success p-x-2">
        <count-to :start-val="0" :end-val="parseInt(data.sms_balance)" :duration="2000" :decimals="0" />
      </span>
      国际容量
      <span class="text-success p-x-2">
        <count-to :start-val="0" :end-val="parseInt(data.sms_internation_balance)" :duration="2000" :decimals="0" />
      </span>
    </div>
    <div style="text-align: right;">
      <el-button v-if="!is_view" type="primary" :disabled="!!!btn_access('s_sms_config')" icon="el-icon-edit" @click="viewChange">编辑</el-button>
      <el-button v-else icon="el-icon-back" @click="viewChange">返回</el-button>
    </div>
    <div v-for="(item, index) in tableData.list" :key="index">
      <div v-for="(items, indexs) in tableData.field_list" :key="indexs">
        <div v-if="index === indexs" class="p-y-8 p-x-10 title h3 m-b-10 m-t-20 content">{{ items }}</div>
      </div>
      <el-table :data="item" style="width: 100%" :show-header="false">
        <el-table-column prop="name" label="短信业务提醒" width="220">
          <template slot-scope="{ row }">
            <span>{{ row.zh_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="template" label="短信内容" min-width="500">
          <template slot-scope="{ row }">
            {{ row.template }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="{ row }">
            <template v-if="is_view">
              <el-switch
                v-model="row.status"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                class="m-r-10"
                @change="rowChange(row)"
              />
            </template>
            <span v-else>{{ row.status ? '已开启' : '已关闭' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
import { getSmsNum, editSmsTemplate, getSms } from '@/api/store/sms.js'
export default {
  components: { CountTo },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        field_list: {}
      },
      data: {
        ms_balance: '',
        sms_count: '',
        sms_internation_balance: ''
      },
      is_view: false,
      form: {},
      stringArr: {
        birthday: ['会员生日前', '天提醒'],
        disappear: ['会员连续', '天未上课提醒'],
        anniversary: ['会员入会纪念日前', '天提醒'],
        expire: ['有效期剩余', '天提醒'],
        number_card_limit: ['次数卡余额不足', '次提醒'],
        value_card_limit: ['储值卡余额不足', '元提醒'],
        open_card: ['开卡日期前', '天提醒'],
        leave_expire: ['请假截止日期前', '天提醒']
      }
    }
  },
  mounted() {
    this.getList()
    getSmsNum().then(res => {
      this.data = res.data
    })
  },
  methods: {
    getList() {
      getSms({ limit: 999 })
        .then(res => {
          this.tableData = res.data
        })
        .catch(() => {})
    },
    valueFormatter(v) {
      var s = ''
      s = this.stringArr[v.en_name]
      return s[0] + v.value + s[1]
    },
    rowChange(v) {
      // 更新信息
      const data = v
      editSmsTemplate(data.id, { value: data.value, status: data.status })
        .then(res => {
          this.$message.success('修改成功')
        })
        .catch(() => {})
    },
    viewChange() {
      this.$set(this, 'is_view', !this.is_view)
    },
    handleClick(num) {
      if (num === 0) this.$router.push({ name: 's_sms_log' })
      if (num === 1) this.$router.push({ name: 's_sms_buy_log' })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 16px;
  font-weight: bold;
}
</style>
