<template>
  <div v-loading="loading" class="special-center">
    <el-row :gutter="20">
      <el-col :span="24" :xs="24">
        <el-card>
          <div slot="header" class="flex font-xxs">
            <div class="cursor" @click="returnPage()">
              <i class="el-icon-back" />
            </div>
            <div class="m-l-10">员工详情</div>
          </div>
          <!-- <el-page-header content="员工详情" class="page-header" @back="returnPage()" /> -->
          <!-- <el-divider /> -->
          <!-- 用户信息 -->
          <div class="userInfo">
            <el-row :gutter="20" class="userHeader flex row-between">
              <el-col :span="6" :offset="0" class="flex">
                <el-avatar shape="square" :size="80" :src="userInfo.avatar">
                  <img :src="errorAvatarSrc(userInfo.sex, 'img')">
                </el-avatar>
                <!-- <span class="userName p-l-15">{{ getUserNames(userInfo) }}</span> -->
              </el-col>
              <el-col :span="18" :offset="0" class="text-right user_operation">
                <el-button
                  v-show="utils.getVenues().id === -1"
                  :disabled="!!userInfo.is_super && !!$store.state.user.isSuper"
                  class="m-x-2"
                  type="warning"
                  @click="store_user_quit"
                >
                  一键离职
                </el-button>
                <el-popconfirm title="删除后将无法找回，确定继续吗？" class="m-x-2" @confirm="delUserRole">
                  <el-button
                    v-show="btn_access('s_management_delete')"
                    slot="reference"
                    :disabled="!!userInfo.is_super && !!$store.state.user.isSuper"
                    type="danger"
                  >
                    删除
                  </el-button>
                </el-popconfirm>
                <el-button
                  v-show="btn_access('s_management_edit')"
                  :disabled="!!userInfo.is_super && !!$store.state.user.isSuper"
                  class="m-x-2"
                  type="primary"
                  @click="goPage"
                >
                  编辑员工
                </el-button>
              </el-col>
            </el-row>

            <!-- 基本信息 -->
            <h5>基本信息</h5>
            <el-descriptions class="margin-top" :column="2" border :label-style="{ width: '100px' }">
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-school" />
                  所属场馆
                </template>
                {{ venues_info.name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user" />
                  员工名
                </template>
                {{ getUserNames(userInfo) }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-s-custom" />
                  性别
                </template>
                {{ formatTableSex(userInfo) }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone" />
                  手机号
                </template>
                <el-tag>{{ `+${userInfo.area_code}-${userInfo.phone}` }}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-potato-strips" />
                  生日
                </template>
                {{ userInfo.birthday }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-star-on" />
                  员工星级
                </template>
                <el-rate :value="userInfo.star_level >= 5 ? 5 : parseInt(userInfo.star_level)" :colors="colors" disabled />
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building" />
                  教练类型
                </template>
                <div>
                  <el-tag v-if="userInfo.is_group" class="m-r-5 m-b-5">团课教练</el-tag>
                  <el-tag v-if="userInfo.is_private" type="warning">私教课教练</el-tag>
                  <span v-if="!userInfo.is_group && !userInfo.is_private">无</span>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building" />
                  课程颜色
                </template>
                <div v-if="userInfo.color">
                  <div class="class_color" :style="{ background: userInfo.color }" />
                </div>
                <div v-else>未设置</div>
              </el-descriptions-item>
              <!-- 状态 -->
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building" />
                  状态
                </template>
                <el-tag v-if="userInfo.role_info" :type="userInfo.role_info.state === 1 ? '' : 'danger'" class="m-r-5 m-b-5">
                  {{ userInfo.role_info.state === 1 ? '在职' : '已离职' }}
                </el-tag>
              </el-descriptions-item>
              <!-- 兼职 -->
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building" />
                  是否兼职
                </template>
                <el-tag
                  v-if="userInfo.role_info"
                  :type="userInfo.role_info.part_time === 1 ? 'danger' : ''"
                  class="m-r-5 m-b-5"
                >
                  {{ userInfo.role_info.part_time === 1 ? '是' : '否' }}
                </el-tag>
              </el-descriptions-item>
              <!-- 员工角色 -->
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building" />
                  员工角色
                </template>
                <div v-if="!utils.empty(userInfo.employee)">
                  <el-tag v-for="(item, index) in userInfo.employee" :key="index" class="m-x-2">
                    {{ item.role ? item.role.role_name : '--' }}
                  </el-tag>
                </div>
                <div v-else>
                  暂无
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-time" />
                  加入时间
                </template>
                {{ userInfo.create_time ? userInfo.create_time : '无' }}
              </el-descriptions-item>
              <el-descriptions-item span="2">
                <template slot="label">
                  <i class="el-icon-s-management" />
                  简介
                </template>
                {{ userInfo.introduce === '' ? '无' : userInfo.introduce }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-postcard" />
                  员工封面图
                </template>
                <div v-if="userInfo.cover">
                  <el-image style="width: 200px" :src="userInfo.cover" :preview-src-list="[userInfo.cover]" />
                </div>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-card>
      </el-col>
      <el-col :span="24" :xs="24">
        <el-row :gutter="20">
          <el-col class="m-t-20" :span="24" :xs="24">
            <!-- 作息时间 -->
            <el-card>
              <TimeQuantum :store-user-id="form.store_user_id" :venues-id="form.venues_id" />
            </el-card>
          </el-col>
          <el-col class="m-t-20" :span="24" :xs="24">
            <album :id="form.store_user_id" :type="type" />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TimeQuantum from './components/TimeQuantum'
import { getEmployeeDetails, editEmployee, getVenuesInfo } from '@/api/store/venues.js'
import { delStoreUser, postQuit } from '@/api/store/management.js'
import album from '@/views/profile/components/album.vue'

export default {
  components: { TimeQuantum, album },
  data() {
    return {
      form: {
        venues_id: this.$route.params.venues_id
          ? this.$route.params.venues_id
          : this.utils.getVenues().id === -1
            ? ''
            : this.utils.getVenues().id,
        role_id: [],
        store_user_id: '',
        part_time: '',
        state: ''
      },
      rules: ['role_id', 'store_user_id', 'venues_id'],
      loading: false,
      userInfo: {},
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      venues_info: {},
      type: 2
    }
  },
  created() {
    this.id = this.$route.params.id
    this.form.store_user_id = this.$route.params.store_user_id
    if (this.$route.params.venues_id) this.form.venues_id = this.$route.params.venues_id
    this.rules = this.utils.validateFor(this.rules)
    this.getStoreUserInfo()
    this.getVenuesInfo()
  },
  methods: {
    getVenuesInfo() {
      getVenuesInfo(this.form.venues_id).then(res => {
        this.venues_info = res.data
        console.log('🚀 ~ file: edit.vue ~ line 174 ~ getVenuesInfo ~ res', res)
      })
    },
    getStoreUserInfo() {
      this.loading = true
      getEmployeeDetails(this.form.store_user_id, this.form.venues_id)
        .then(res => {
          this.userInfo = res.data
          const venues_id = this.form.venues_id + ''
          const getVenues_id = this.utils.getVenues().id + ''
          if (venues_id === getVenues_id) {
            if (this.userInfo.employee) {
              this.form.role_id = []
              this.userInfo.employee.forEach(item => {
                this.form.role_id.push(item.role_id)
              })
            }
          }

          if (this.userInfo.role_info) {
            this.form.part_time = this.userInfo.role_info.part_time
            this.form.state = this.userInfo.role_info.state
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 更新角色
    upUserRole() {
      const data = JSON.parse(JSON.stringify(this.form))
      if (this.utils.getVenues().id !== -1) {
        data['venues_id'] = this.utils.getVenues().id
      }
      console.log('🚀 ~ file: edit.vue ~ line 179 ~ upUserRole ~ data', data)
      if (this.form.role_id.length > 0) {
        editEmployee(this.id, data).then(res => {
          this.$message.success('修改成功')
          this.getStoreUserInfo()
        })
      } else {
        this.$message.error('请选择角色')
        return
      }
    },
    // 移除当前员工关系
    delUserRole() {
      const data = JSON.parse(JSON.stringify(this.form))
      if (this.utils.getVenues().id !== -1) {
        data['venues_id'] = this.utils.getVenues().id
      }
      data.role_id = []
      delStoreUser(this.$route.params.store_user_id).then(res => {
        this.$message.success('删除成功')
        this.$router.push({ name: 's_venues_employee' })
      })
    },
    store_user_quit() {
      this.$confirm('一键离职针对所有场馆,离职后该员工将不能登录该品牌,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        postQuit(this.$route.params.store_user_id).then(res => {
          this.getStoreUserInfo()
          this.$message({
            message: '操作成功',
            type: 'success'
          })
        })
      })
    },
    goPage() {
      this.$router.push({ name: 's_venues_employee_edit', params: { id: this.$route.params.store_user_id }})
    }
  }
}
</script>

<style lang="scss" scoped>
.class_color {
  width: 80px;
  height: 20px;
}
.word_text {
  font-size: 12px;
  color: #9c9c9c;
  margin-left: -40px;
}
</style>
