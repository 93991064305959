<template>
  <div class="p-t-10">
    <SelectSendSms :checked-user-count="checkedUserCount" :multiple-selection="multipleSelection" />

    <el-alert
      title="期限卡余额请到会员卡有效期查看"
      type="warning"
      show-icon
    />
    <!-- 列表 -->
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      :data="tableData.list"
      row-key="id"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column
        type="selection"
        width="55"
      /> -->
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column prop="user.real_name" label="会员">
        <template slot-scope="{row}">
          <div v-if="!utils.empty(row.user)" class="flex col-center cursor" @click="toUserInfoPage(row.user.id)">
            <el-avatar shape="square" :size="50" :src="row.user.avatar">
              <img :src="errorMemberSrc()">
            </el-avatar>
            <div class="m-l-10">
              {{ getUserNames(row.user) }}
              <br>
              {{ row.user ? row.user.phone : '--' }}
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="user.nickname" label="昵称" /> -->
      <!-- <el-table-column prop="user.phone" label="联系方式" /> -->
      <el-table-column prop="number" label="卡号" />
      <el-table-column prop="card.name" label="卡名称" />
      <el-table-column prop="card" label="卡类型">
        <template slot-scope="{row}">
          {{ formatCardType(row.card.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="valid_time" label="有效期至">
        <template slot-scope="{row}">
          <div v-if="timeRemain(row.valid_time)<=7">
            <el-tag type="warning">{{ row.valid_time | formatDate("YYYY-MM-DD") }}</el-tag>
          </div>
          <div v-else-if="timeRemain(row.valid_time)<=0">
            <el-tag type="danger">{{ row.valid_time | formatDate("YYYY-MM-DD") }}</el-tag>
          </div>
          <div v-else>
            <el-tag type="success">{{ row.valid_time | formatDate("YYYY-MM-DD") }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="limit" label="余额">
        <template slot-scope="{row}">
          {{ row.limit }}
          <span v-if="row.card.type==1">次</span>
          <span v-if="row.card.type==2">元</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="回访状态">
        <template slot-scope="{row}">
          <el-tag v-if="row.status" type="success">已回访</el-tag>
          <el-tag v-else type="info">未回访</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240px">
        <template slot-scope="scope">
          <el-button v-show="btn_access('s_user_track_list')" icon="el-icon-notebook-2" @click="toVisitorTrackPage(scope.row.user_id)">回访记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 用户回访 -->
    <VisitorTrack :id="dialog.trackId" :is-show="isShow" :track-visible.sync="dialog.trackVisible" @trackVisible="trackVisible" />
  </div>
</template>

<script>
import SelectSendSms from '../selectSendSms.vue'
import { getUserCardBalance } from '@/api/store/user.js'
import VisitorTrack from '@/views/store/user/tracking' // 用户回访
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  components: { SelectSendSms, VisitorTrack, Pagination },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      listLoading: false,
      checkedUserCount: 0,
      multipleSelection: [],
      dialog: { trackVisible: false, trackId: null },
      isShow: 12
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 保存选中结果
    handleSelectionChange(val) {
      // 先清空id数组
      this.multipleSelection = []
      // 去重获取被选中的id值存入到数组中
      for (const selectedItem of val) {
        this.multipleSelection.push(selectedItem.id)
      }
      this.checkedUserCount = this.multipleSelection.length
    },
    // 获取列表
    getList() {
      this.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getUserCardBalance(data).then(res => {
        this.tableData = res.data
        this.listLoading = false
      }).catch(() => {})
    },

    toVisitorTrackPage(id) {
      this.dialog.trackId = id
      this.dialog.trackVisible = true
    },
    // 时间对比
    timeRemain(v) {
      const e = this.$moment(v).format('yyyy-MM-DD')// 开始时间
      const s = this.$moment().format('yyyy-MM-DD')// 结束时间
      return this.$moment(e).diff(s, 'days')
    },
    trackVisible() {
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
