<template>
  <!-- 班课会员管理 -->
  <el-dialog
    :title="name === 'add' ? '新增班课' : name === 'check' ? '查看详情' : '编辑班课'"
    :visible.sync="dialog"
    width="30%"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="dialogOpen"
    @close="dialogClose"
  >
    <!-- 添加表单 -->
    <el-form ref="ruleForm" v-loading="loading" :rules="rules" :model="form" label-width="80px">
      <el-form-item class="line-clear" label="课程名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入课程名称" :disabled="name === 'check' ? true : false" />
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item class="line-clear" label="课程类型">
            <el-input placeholder="小班课" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="line-clear" label="课程时长" prop="duration">
            <el-input
              v-model="form.duration"
              :disabled="name === 'check' ? true : false"
              type="number"
              placeholder="输入课程时长"
            >
              <template slot="append">分钟</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="教练" prop="store_user_id">
            <Select ref="coachRef" :type="4" :width="180" :disabled="name === 'check' ? true : false" @changeId="handleCoachID" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="教室" prop="classroom_id">
            <Select ref="classroomRef" :type="8" :width="180" :disabled="name === 'check' ? true : false" @changeId="handleClassroomID" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="name !== 'check'" type="primary" @click="handleYes('ruleForm')">确 定</el-button>
      <el-button @click="handleToPage('ruleForm')">返 回</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { postCourseAdd, getCourseCheck, putCourseEdit } from '@/api/store/course'
import Select from '@/components/elSelect/Select'

export default {
  components: { Select },
  props: {
    dialog: {
      required: true,
      type: Boolean
    },
    name: {
      required: true,
      type: String
    },
    id: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      form: {
        name: '',
        duration: '',
        store_user_id: '',
        classroom_id: ''
      },
      rules: {
        name: [{ required: true, message: '请输入课程名称', trigger: ['blur', 'change'] }],
        duration: [{ required: true, message: '请输入课程名称', trigger: ['blur', 'change'] }],
        store_user_id: [{ required: true, message: '请选择教练', trigger: ['blur', 'change'] }],
        classroom_id: [{ required: true, message: '请选择教师', trigger: ['blur', 'change'] }]
      },
      loading: false
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 打开的回调
    dialogOpen() {
      if (this.id !== 0) {
        this.loading = true
        getCourseCheck(this.id).then(res => {
          this.form.name = res.data.name
          this.form.duration = res.data.duration
          this.form.store_user_id = res.data.store_user_id
          this.form.classroom_id = res.data.classroom_id
          this.$refs.coachRef.refreshGetList(res.data.store_user.real_name)
          this.$refs.classroomRef.refreshGetList(res.data.classroom.name)
          this.loading = false
        })
      }
    },
    // 关闭的回调
    dialogClose() {
      this.$refs.coachRef.refreshGetList()
      this.$refs.classroomRef.refreshGetList()
    },
    handleYes(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.id === 0) {
            postCourseAdd(this.form).then(() => {
              this.$refs[formName].resetFields()
              this.$emit('callback')
              this.$message({
                type: 'success',
                message: '新增成功'
              })
            })
          } else {
            putCourseEdit(this.id, this.form).then(() => {
              this.$refs[formName].resetFields()
              this.$emit('callback')
              this.$message({
                type: 'success',
                message: '修改成功'
              })
            })
          }
        } else {
          return false
        }
      })
    },
    handleToPage(formName) {
      this.$refs[formName].resetFields()
      this.$emit('callback')
    },
    handleCoachID(val) {
      this.form.store_user_id = val
    },
    handleClassroomID(val) {
      this.form.classroom_id = val
    }
  }
}
</script>

<style lang="scss" scoped></style>
