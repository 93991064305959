<template>
  <div class="">
    <div class="text-right">
      <el-button
        v-show="btn_access('s_orderRuleForPrivateSetting')"
        type="primary"
        icon="el-icon-edit"
        @click="$router.push({ name: 's_orderRuleForPrivateSetting', params: { type: 'orderRuleForPrivate' } })"
      >
        编辑设置
      </el-button>
    </div>
    <div v-for="(item, index) in infoNode" :key="index" class="m-y-10">
      <div v-if="item.type == 'alert'">
        <el-alert :title="item.text" :closable="false" show-icon type="warning" />
      </div>
      <div v-if="item.type == 'table'">
        <div class="p-y-8 p-x-10 title">
          {{ item.title }}
        </div>
        <div v-if="item.data[0].value === '关闭' || item.data[0].value === '开启'">
          <el-table
            v-if="item.data[0].value === '开启'"
            :show-header="false"
            :data="item.data"
            stripe
            style="width: 100%"
          >
            <el-table-column prop="title" label="标题" width="180" />
            <el-table-column prop="value" label="值" />
          </el-table>
          <div v-if="item.data[0].value === '关闭'" class="table-close">
            <span class="table-title">{{ item.data[0].title }}</span>
            <span class="table-value">{{ item.data[0].value }}</span>
          </div>
        </div>
        <div v-else>
          <el-table :show-header="false" :data="item.data" stripe style="width: 100%">
            <el-table-column prop="title" label="标题" width="180" />
            <el-table-column prop="value" label="值" />
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSubscribeSetting } from '@/api/store/subscribe.js'
export default {
  data() {
    return {
      infoNode: '',
      reslist: {
        // 预约设置
        private_subscribe: {
          type: 1,
          start_time: '',
          end_time: '',
          before_course_minute: 30,
          before_rest: 0,
          after_rest: 0,
          interval: 30
        },
        private_sign: {
          autonomously_status: 0,
          autonomously_time_type: 1,
          scan_code_status: 1,
          scan_code_time_type: 1,
          before: 5,
          after_1: 15,
          after_2: 15
        },
        private_cancel_subscribe: {
          status: 1,
          time_type: 1,
          minute_1: 10,
          minute_2: 10,
          minute_3: 10
        }
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      getSubscribeSetting().then(res => {
        this.reslist = res.data.res

        this.infoNode = [
          {
            type: 'table',
            title: '预约时间设置',
            data: [
              {
                title: '预约方式',
                value: this.getValue(function(that) {
                  switch (that.reslist.private_subscribe.type) {
                    case 1:
                      return '私教排课模式'
                    case 2:
                      return '自由预约模式'
                    default:
                      break
                  }
                })
              },
              {
                title: '私教预约',
                value: this.getValue(function(that) {
                  return `每天 ${that.reslist.private_subscribe.start_time[0]}至${that.reslist.private_subscribe.start_time[1]} 接受自主预约`
                })
              },
              {
                title: '预约细则',
                value: this.getValue(function(that) {
                  return `教练空闲时间可约，会员需早于开课前 ${that.reslist.private_subscribe.before_course_minute} 分钟自主预约`
                })
              }
              // {
              //   title: '休息时间',
              //   value: this.getValue(function(that) {
              //     return `课前休息${that.reslist.private_subscribe.before_rest}分钟,课后休息${that.reslist.private_subscribe.after_rest}分钟。每${that.reslist.private_subscribe.interval}分钟一个时间段`
              //   })
              // }
            ]
          },
          {
            type: 'table',
            title: '自主签到',
            data: [
              {
                title: '自主签到',
                value: this.getValue(function(that) {
                  return that.reslist.private_sign.autonomously_status ? '开启' : '关闭'
                })
              },
              {
                title: '签到时间',
                value: this.getValue(function(that) {
                  switch (that.reslist.private_sign.autonomously_time_type) {
                    case 1:
                      return '课程当天，会员可随时签到'
                    case 2:
                      return '课程当天，会员可在课程结束前签到'
                    case 3:
                      return `课程当天，会员可在课程结束后 ${that.reslist.private_sign.after_1} 分钟内签到`
                    default:
                      break
                  }
                })
              }
            ]
          },
          {
            type: 'table',
            title: '扫码签到',
            data: [
              {
                title: '扫码签到',
                value: this.getValue(function(that) {
                  return that.reslist.private_sign.scan_code_status ? '开启' : '关闭'
                })
              },
              {
                title: '签到时间',
                value: this.getValue(function(that) {
                  switch (that.reslist.private_sign.scan_code_time_type) {
                    case 1:
                      return '课程当天，会员可签到两小时内开始的课程'
                    case 2:
                      return '课程当天，会员可在课程结束前扫码签到'
                    case 3:
                      return `课程当天，会员可在课程开始前 ${that.reslist.private_sign.before} 分钟至课程结束后 ${that.reslist.private_sign.after_2} 分钟内扫码签到`
                    default:
                      break
                  }
                })
              }
            ]
          },
          {
            type: 'table',
            title: '预约取消设置',
            data: [
              {
                title: '自主取消',
                value: this.getValue(function(that) {
                  return that.reslist.private_cancel_subscribe.status ? '开启' : '关闭'
                })
              },
              {
                title: '取消时间',
                value: this.getValue(function(that) {
                  var v = that.reslist.private_cancel_subscribe
                  switch (v.time_type) {
                    case 1:
                      return `会员自主取消不晚于开课前 ${v['minute_' + v.time_type]} 分钟`
                    case 2:
                      return `会员自主取消不晚于开课后 ${v['minute_' + v.time_type]} 分钟`
                    case 3:
                      return `会员自主取消不晚于课程结束后 ${v['minute_' + v.time_type]} 分钟`
                    default:
                      break
                  }
                })
              }
            ]
          }
        ]
      })
    },
    getValue(a) {
      return a(this)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-close {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #dfe6ec;
  .table-title,
  .table-value {
    display: inline-block;
    line-height: 20px;
    padding: 10px 10px;
  }
  .table-title {
    width: 180px;
  }
}
</style>
