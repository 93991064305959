<template>
  <div class="app-container">
    <!-- 头部导航 -->
    <div class="flex row-between">
      <el-page-header content="快速注册小程序" class="page-header" @back="returnPage()" />
      <el-button size="small" type="primary" @click="handleSubmitRecord">提交记录</el-button>
    </div>
    <el-card class="m-t-20" shadow="never" style="background: #FFFFFF" body-style="background-color: #ffffff;">
      <div style="margin: 24px 0;background: #ECF5FE;padding: 20px;font-size: 14px;">
        <div style="color: #999999;font-size: 14px;margin-bottom: 4px">使用说明</div>
        <div style="margin: 2px 0">
          通过该接口创建的小程序创建成功后即为“已认证”状态，创建成功后，可直接绑定平台进行小程序的开发使用。
        </div>
        <div style="margin: 2px 0">1.填写以下信息，提交微信审核。</div>
        <div style="margin: 2px 0">2.法人微信收到消息模板，法人于24h内进行法人身份信息和人脸信息认证。</div>
        <div style="margin: 2px 0">3.身份认证通过后，小程序创建成功。</div>
        <div style="margin: 2px 0">4.更多问题详见 <a class="text-primary" target="_blank" href="https://developers.weixin.qq.com/doc/oplatform/Third-party_Platforms/2.0/product/Register_Mini_Programs/Fast_Registration_Interface_document.html">微信官方文档</a></div>
      </div>
      <el-form ref="editForm" :model="editForm" :rules="editFormRules" label-width="150px" position-label="right">
        <el-form-item prop="name" label="企业名称">
          <el-input v-model="editForm.name" placeholder="请输入公司名称" size="small" class="fast-input" />
        </el-form-item>
        <el-form-item prop="code_type" label="代码类型">
          <el-select v-model="editForm.code_type" placeholder="请选择">
            <el-option label="统一社会信用代码(18 位)" :value="1" />
            <el-option label="组织机构代码(9 位 xxxxxxxx-x)" :value="2" />
            <el-option label="营业执照注册号(15 位)" :value="3" />
          </el-select>
        </el-form-item>
        <el-form-item prop="code" label="企业代码">
          <el-input v-model="editForm.code" placeholder="请输入相应的企业代码" size="small" class="fast-input" />
        </el-form-item>
        <el-form-item prop="legal_persona_wechat" label="法人微信号">
          <el-input
            v-model="editForm.legal_persona_wechat"
            placeholder="请输入法人的微信号（需已绑定银行卡）"
            size="small"
            class="fast-input"
          />
        </el-form-item>
        <el-form-item prop="legal_persona_name" label="法人姓名">
          <el-input
            v-model="editForm.legal_persona_name"
            placeholder="请输入法人姓名"
            size="small"
            class="fast-input"
          />
        </el-form-item>
        <el-form-item prop="component_phone" label="联系电话">
          <el-input v-model="editForm.component_phone" placeholder="请输入联系方式" size="small" class="fast-input" />
        </el-form-item>
      </el-form>
    </el-card>
    <el-button size="small" style="margin-top: 20px" :loading="btnLoading" type="primary" @click="submit">
      提交
    </el-button>
    <!-- 注册提交记录 -->
    <el-dialog title="注册提交记录" :visible.sync="dialogVisible" :close-on-click-modal="false" width="80%">
      <el-table v-loading="btnLoading" :data="tableData.list" style="width: 100%" border>
        <el-table-column prop="name" label="企业名称" width="" />
        <el-table-column prop="code_type" :formatter="codeTypeFormat" label="代码类型" min-width="" width="" />
        <el-table-column prop="code" label="企业代码" />
        <el-table-column prop="legal_persona_wechat" label="法人微信号" width="" />
        <el-table-column prop="legal_persona_name" label="法人姓名" width="" />
        <el-table-column prop="component_phone" label="联系电话" width="" />
        <el-table-column prop="status_text" label="状态" width="">
          <template slot-scope="{ row }">
            <span v-if="row.status === 0">{{ row.status_text }}</span>
            <span v-if="row.status === -2 || row.status !== 0">{{ row.status === -2 ? '验证中' : row.status_text }}
              <el-button size="mini" class="m-4" type="danger" @click="handleRegister(row)">重新提交</el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <pagination small :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </el-dialog>
  </div>
</template>

<script>
// API
import { quickRegister, registerRecord } from '@/api/admin/merchants.js'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  name: 'SignWeChat',
  components: { Pagination },
  data() {
    return {
      tableData: {
        count: 0,
        list: []
      },
      btnLoading: false,
      dialogVisible: false,
      editForm: {
        name: '',
        code_type: 1,
        code: '',
        legal_persona_wechat: '',
        legal_persona_name: '',
        component_phone: ''
      },
      editFormRules: {
        name: [{ required: true, message: '企业名称不能为空', trigger: 'blur' }],
        code_type: [{ required: true, message: '企业类型不能为空', trigger: 'blur' }],
        code: [{ required: true, message: '企业代码不能为空', trigger: 'blur' }],
        legal_persona_wechat: [{ required: true, message: '法人微信号不能为空', trigger: 'blur' }],
        legal_persona_name: [{ required: true, message: '法人姓名不能为空', trigger: 'blur' }],
        component_phone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
          {
            pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/,
            message: '请输入正确的手机号格式',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    submit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.btnLoading = true
          this.editForm.aid = this.$route.params.id
          this.editForm.code = this.editForm.code.replace(/\s/g, '')
          this.editForm.legal_persona_wechat = this.editForm.legal_persona_wechat.replace(/\s/g, '')
          this.editForm.component_phone = this.editForm.component_phone.replace(/\s/g, '')
          quickRegister(this.editForm)
            .then(res => {
              this.$message.success('提交成功')
              // 清空表单
              this.$refs.editForm.resetFields()
            })
            .finally(() => {
              this.btnLoading = false
            })
        }
      })
    },
    handleRegister(row) {
      this.$confirm('此操作将把数据重新填到表单中，并不会自动重新提交。是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.editForm = {
          name: row.name,
          code_type: row.code_type,
          code: row.code,
          legal_persona_wechat: row.legal_persona_wechat,
          legal_persona_name: row.legal_persona_name,
          component_phone: row.component_phone
        }
        this.editForm.aid = this.$route.params.id
        this.dialogVisible = false
        // quickRegister(this.editForm)
        //   .then(res => {
        //     this.$message.success('提交成功')
        //     // 清空表单
        //     this.$refs.editForm.resetFields()
        //     // 刷新列表
        //     this.handleSubmitRecord()
        //   })
        //   .finally(() => {
        //     this.btnLoading = false
        //   })
      })
    },
    // 提交记录
    handleSubmitRecord() {
      this.dialogVisible = true
      this.btnLoading = true
      this.getList()
    },
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      registerRecord(this.$route.params.id, data).then(res => {
        this.tableData = res.data
        this.btnLoading = false
      })
    },
    codeTypeFormat(e) {
      const data = {
        '': '未知',
        1: '统一社会信用代码(18 位)',
        2: '组织机构代码(9 位 xxxxxxxx-x)',
        3: '营业执照注册号(15 位)'
      }
      return data[e.code_type]
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style scoped>
.header-box {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.fast-input {
  width: 40vw;
}
</style>
