<template>
  <div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="visibleLog"
      title="添加放假记录"
      class="dialog_w580"
      :before-close="cancelDialog"
      @open="handelOpen"
      @close="handelClose"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="放假场馆">
          <div class="flex flex-wrap">
            <el-tag effect="dark" class="m-2">{{ info }}</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="放假时间" prop="holiday_time">
          <el-date-picker
            v-model="form.holiday_time"
            type="datetimerange"
            range-separator="至"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          />
        </el-form-item>
        <el-form-item label-width="90px" label="会员卡延期">
          <el-switch v-model="form.is_delay" />
        </el-form-item>
        <div class="boxs" @click="open">
          <i class="el-icon-warning-outline" />
          <el-button type="text">放假说明</el-button>
        </div>
        <el-form-item label="放假事由">
          <el-input v-model="form.notice" type="textarea" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button type="text" @click="handleNo">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { holidayVenues, getVenuesInfo } from '@/api/store/venues.js'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'

export default {
  props: {
    visibleLog: {
      type: Boolean,
      required: true,
      default: false
    },
    id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      info: '',
      form: {
        holiday_time: [],
        is_delay: 0,
        notice: ''
      },
      rules: {
        holiday_time: [{ required: true, message: '日期不能为空', trigger: 'blur' }]
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // const s = this.form.holiday_time[0]
          // const e = this.form.holiday_time[1] + ' ' + '23:59'
          const s = timestampToTimes(this.form.holiday_time[0].getTime()).slice(0, 16)
          const e = timestampToTimes(this.form.holiday_time[1].getTime()).slice(0, 16)
          let is = 0
          if (this.form.is_delay === true) {
            is = 1
          }
          if (!s || !e) {
            this.$message.error('请选择时间')
            return false
          }
          const venues_id = this.id
          const p_data = {
            venues_id: venues_id,
            start_time: s,
            end_time: e,
            is_delay: is,
            notice: this.form.notice
          }
          holidayVenues(p_data)
            .then(res => {
              this.$message.success(res.msg)
              this.cancelDialog()
            })
            .catch(() => {})
        }
      })
    },
    // 获取场馆信息
    handelOpen() {
      getVenuesInfo(this.id).then(res => {
        this.info = res.data.name
      })
    },
    handelClose() {
      this.info = ' '
    },
    open() {
      this.$alert(
        `<div>
            <div>
              <span>场馆放假：放假期间场馆中所有课程将停止预约，非放假时间的课程不受影响</span>
            </div>
            <div>
              <span>取消放假：如有会员卡延期，将根据取消时间计算实际放假时间并扣减会员卡有效期</span>
            </div>
            <div>
              <span>
                会员卡延期：如勾选会员卡延期，当放假开始至放假结束时长大于一天时，该馆有效会员卡将根据放假天数自动延期
                <br />
                (注：多馆卡的延期只有在发卡的场馆放假的时候才能延期)
              </span>
            </div>
          </div>`,
        '放假说明',
        {
          dangerouslyUseHTMLString: true
        }
      )
    },
    cancelDialog() {
      this.$emit('visibleCZ')
      // 重置form表单
      this.$refs['ruleForm'].resetFields()
      this.form.is_delay = 0
      this.form.notice = ''
    },
    handleNo() {
      this.cancelDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.boxs {
  margin-top: -10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  i {
    padding-right: 4px;
  }
}
</style>
