import request from '@/utils/request'

/**
 * 获取微信消息模板
 */
export function getWechatTemplate(data) {
  return request({
    url: `/store/v1/wechat_template`,
    method: 'get',
    params: data
  })
}

export function getWechat(data) {
  return request({
    url: `/store/v1/wechat_templates`,
    method: 'get',
    params: data
  })
}

/**
 * 修改模板状态
 * @param {int} id 模版ID
 * @param {*} data
 * @returns
 */
export function editWechatTemplate(id, data) {
  return request({
    url: `/store/v1/wechat_template/${id}`,
    method: 'put',
    data
  })
}

/**
 *  系统轮播图列表
 */
export function getSystemBanner(data) {
  return request({
    url: `/store/v1/system_slide`,
    method: 'get',
    params: data
  })
}

/**
 * 系统公告列表
 */
export function getSystemNote(data) {
  return request({
    url: `/store/v1/system_notice`,
    method: 'get',
    params: data
  })
}

/**
 * 系统公告详情
 */
export function getSystemNoteInfo(id, data) {
  return request({
    url: `/store/v1/system_notice/${id}`,
    method: 'get',
    params: data
  })
}

// 提交反馈
export function setFeedback(data) {
  return request({
    url: `/store/v1/feedback`,
    method: 'post',
    data
  })
}

// 文件上传
export function upload(data) {
  return request({
    url: `/store/v1/upload`,
    method: 'post',
    data
  })
}
