var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: { rules: _vm.rules, model: _vm.form, "label-position": "top" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称", prop: "role_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入角色名称" },
                model: {
                  value: _vm.form.role_name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "role_name", $$v)
                  },
                  expression: "form.role_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色属性", prop: "attribute_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择角色属性" },
                  model: {
                    value: _vm.form.attribute_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "attribute_id", $$v)
                    },
                    expression: "form.attribute_id"
                  }
                },
                _vm._l(_vm.attributeList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                attrs: { placeholder: "排序(数字越小越靠前)", type: "number" },
                model: {
                  value: _vm.form.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色权限", prop: "access_id" } },
            [
              _c("el-checkbox", { on: { change: _vm.checkAll } }, [
                _vm._v("全选")
              ]),
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.accessList,
                  "show-checkbox": true,
                  "node-key": "id",
                  "highlight-current": "",
                  "expand-on-click-node": false,
                  props: _vm.accessProps,
                  "default-expand-all": true,
                  "check-strictly": true,
                  "default-checked-keys": _vm.checked_keys,
                  indent: 10
                },
                on: { check: _vm.nodeClick }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.cancelCloseTag } }, [
            _vm._v("取消")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }