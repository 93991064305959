<template>
  <div class="wx-config">
    <div class="wx-box">
      <div class="item">
        <div id="relevance" class="label text-primary">约课端访问设置</div>
        <div class="content">
          <div class="tip">
            访客：访客禁止查看课表及课程
            <br>
            未登录：未登录用户将强制跳转登录页面
            <br>
            未绑定手机号：未绑定手机号的用户禁止查看课表及课程
          </div>
          <div style="height: 100%" class="m-t-20">
            <div class="flex">
              <div>禁止访问课表：</div>
              <div class="m-l-40">
                <el-checkbox v-model="form.no_visitor" :disabled="!is_super()" @change="handleNoVisitor">访客</el-checkbox>
                <el-checkbox v-model="form.must_log_in" :disabled="!is_super()" @change="handleMustLogIn">未登录</el-checkbox>
                <el-checkbox v-model="form.must_have_phone" :disabled="!is_super()" @change="handleMustHavePhone">未绑定手机号</el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// API
import { getSpecialConfig, setSpecialConfig } from '@/api/store/management.js'
// 函数
import { deepClone } from '@/utils'

export default {
  data() {
    return {
      form: {
        no_visitor: true,
        must_log_in: true,
        must_have_phone: true
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getSpecialConfig('client_access').then(res => {
        this.form = {
          no_visitor: !!res.data.no_visitor,
          must_log_in: !!res.data.must_log_in,
          must_have_phone: !!res.data.must_have_phone
        }
      })
    },
    submitSettings() {
      const content = deepClone(this.form)
      const data = {
        content: `{ "must_log_in": ${content.must_log_in ? 1 : 0}, "no_visitor": ${content.no_visitor ? 1 : 0}, "must_have_phone": ${content.must_have_phone ? 1 : 0}}`
      }

      setSpecialConfig('client_access', data).then(res => {
        this.$message.success(res.msg)
      })
    },
    handleNoVisitor(val) {
      this.form.no_visitor = val
      this.submitSettings()
    },
    handleMustLogIn(val) {
      this.form.must_log_in = val
      this.submitSettings()
    },
    handleMustHavePhone(val) {
      this.form.must_have_phone = val
      this.submitSettings()
    }
  }
}
</script>

<style lang="scss" scoped>
.wx-config {
  padding-top: 15px;

  .wx-box {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding-bottom: 20px;
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .item {
      .image_down {
        padding-top: 5px;
      }
      .yueke_image {
        display: block;
        width: 180px;
      }
    }
    // margin: 0 40px;
    .label {
      width: 160px;
      height: 40px;
      background-color: #efeff2;
      padding: 10px;
      text-align: center;
    }
    .content {
      margin: 0 40px;
      padding: 15px 0;
    }
    .tips {
      color: #787878;

      font-size: 14px;
      line-height: 1.5;
      padding-top: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
