import variables from '@/styles/element-variables.scss'
import defaultSettings from '@/settings'

const {
  showSettings,
  showFeedback,
  tagsView,
  fixedHeader,
  sidebarLogo,
  supportPinyinSearch,
  discountActivities,
  discountPopover
} = defaultSettings

const state = {
  theme: variables.theme,
  showSettings,
  showFeedback,
  tagsView,
  fixedHeader,
  sidebarLogo,
  supportPinyinSearch,
  discountActivities,
  discountPopover
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  DISCOUNT_ACTIVITIES: (state, value) => {
    state.discountActivities = value
  },
  DISCOUNT_POPOVER: (state, value) => {
    state.discountPopover = value
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  discountActivities({ commit }, value) {
    commit('DISCOUNT_ACTIVITIES', value)
  },
  discountPopover({ commit }, value) {
    commit('DISCOUNT_POPOVER', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
