var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center project" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "left",
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号头像", prop: "avatar" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "avatar-block avatar-box",
                          on: {
                            click: function($event) {
                              _vm.imagecropperShow = true
                            }
                          }
                        },
                        [
                          _c(
                            "el-avatar",
                            {
                              attrs: {
                                shape: "square",
                                size: 100,
                                src: _vm.form.avatar,
                                title: _vm.form.avatar ? "点击修改" : "点击上传"
                              }
                            },
                            [
                              _c("img", {
                                attrs: { src: _vm.errorImageAvatar }
                              })
                            ]
                          ),
                          _c("div", { staticClass: "avatar-hover" }, [
                            _vm._v(
                              _vm._s(_vm.form.avatar ? "更换头像" : "上传头像")
                            )
                          ])
                        ],
                        1
                      ),
                      _c("image-cropper", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.imagecropperShow,
                            expression: "imagecropperShow"
                          }
                        ],
                        key: _vm.imagecropperKey,
                        attrs: {
                          field: "file",
                          width: 300,
                          height: 300,
                          url: _vm.storeUpload,
                          "lang-type": "zh"
                        },
                        on: {
                          close: _vm.imagecropperClose,
                          "crop-upload-success": _vm.cropSuccess
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "real_name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.form.real_name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "real_name", $$v)
                          },
                          expression: "form.real_name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "phone" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "number",
                            placeholder: "请输入手机号"
                          },
                          model: {
                            value: _vm.form.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "prepend" }, slot: "prepend" },
                            [
                              _c("Code", {
                                attrs: { code: _vm.areaCodeData },
                                on: { area_code: _vm.area_code }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "生日", prop: "birthday" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "请选择日期"
                        },
                        model: {
                          value: _vm.form.birthday,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "birthday", $$v)
                          },
                          expression: "form.birthday"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.sex,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sex", $$v)
                            },
                            expression: "form.sex"
                          }
                        },
                        _vm._l(_vm.sexList, function(item, index) {
                          return _c(
                            "el-radio",
                            { key: index, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "星级", prop: "star_level" } },
                    [
                      _c("el-rate", {
                        attrs: { colors: _vm.colors },
                        model: {
                          value: _vm.form.star_level,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "star_level", $$v)
                          },
                          expression: "form.star_level"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "团课教练", prop: "is_group" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.is_group,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "is_group", $$v)
                            },
                            expression: "form.is_group"
                          }
                        },
                        _vm._l(_vm.typeOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "私课教练", prop: "is_private" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.is_private,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "is_private", $$v)
                            },
                            expression: "form.is_private"
                          }
                        },
                        _vm._l(_vm.typeOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "课程颜色", prop: "color" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap col-center row-left" },
                        [
                          _c(
                            "div",
                            [
                              _c("el-color-picker", {
                                style: { paddingTop: "10px" },
                                on: { change: _vm.colorChange },
                                model: {
                                  value: _vm.form.color,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "color", $$v)
                                  },
                                  expression: "form.color"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "m-l-10" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    placeholder: "快速选色",
                                    clearable: ""
                                  },
                                  on: { change: _vm.defaultColor },
                                  model: {
                                    value: _vm.defaultColorValue,
                                    callback: function($$v) {
                                      _vm.defaultColorValue = $$v
                                    },
                                    expression: "defaultColorValue"
                                  }
                                },
                                _vm._l(_vm.courseDefaultColorList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: index,
                                      attrs: { value: item, label: item }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "defaultColorItem",
                                          style: { background: item }
                                        },
                                        [_vm._v(_vm._s(item))]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "金牌教练", prop: "color" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.form.is_gold,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "is_gold", $$v)
                          },
                          expression: "form.is_gold"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "员工封面图片", prop: "cover" } },
                    [
                      _c(
                        "el-upload",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.upLoading,
                              expression: "upLoading"
                            }
                          ],
                          attrs: {
                            action: _vm.storeUpload,
                            "list-type": "picture-card",
                            "on-preview": _vm.handlePreview,
                            "on-remove": _vm.handleRemove,
                            "on-success": _vm.upSuccess,
                            headers: _vm.utils.upload_headers(),
                            "file-list": _vm.fileList,
                            limit: 1,
                            "on-exceed": _vm.handleExceed,
                            "on-progress": _vm.upProgress,
                            "before-upload": _vm.checkFileType,
                            accept: ".jpg,.jpeg,.png"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "dialog_view_image",
                          attrs: { visible: _vm.dialogVisible },
                          on: {
                            "update:visible": function($event) {
                              _vm.dialogVisible = $event
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              width: "100%",
                              src: _vm.dialogImageUrl,
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c("small", { staticClass: "text-info" }, [
                        _vm._v("图片尺寸建议16:9")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "员工简介" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: 200,
                          autosize: { minRows: 5, maxRows: 5 },
                          placeholder: "请输入员工简介"
                        },
                        model: {
                          value: _vm.form.introduce,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "introduce", $$v)
                          },
                          expression: "form.introduce"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所在场馆角色配置",
                        prop: "role",
                        "label-width": "140px"
                      }
                    },
                    [
                      _vm.utils.empty(_vm.roleLists)
                        ? _c(
                            "div",
                            { staticClass: "tal" },
                            [
                              _c("checkRole", {
                                attrs: {
                                  "parent-selected": _vm.rs,
                                  list: _vm.roleList
                                },
                                on: {
                                  "update:parentSelected": function($event) {
                                    _vm.rs = $event
                                  },
                                  "update:parent-selected": function($event) {
                                    _vm.rs = $event
                                  },
                                  "update:list": function($event) {
                                    _vm.roleList = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            _vm._l(_vm.roleLists, function(item) {
                              return _c(
                                "el-collapse",
                                {
                                  key: item.id,
                                  model: {
                                    value: _vm.activeNames,
                                    callback: function($$v) {
                                      _vm.activeNames = $$v
                                    },
                                    expression: "activeNames"
                                  }
                                },
                                [
                                  _c(
                                    "el-collapse-item",
                                    { attrs: { name: item.id } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("div", [_vm._v(_vm._s(item.name))]),
                                        item.state === 0
                                          ? _c(
                                              "div",
                                              { staticClass: "m-l-10" },
                                              [
                                                _c(
                                                  "el-tag",
                                                  { attrs: { type: "danger" } },
                                                  [_vm._v("离职")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.part_time === 1 && item.state === 1
                                          ? _c(
                                              "div",
                                              { staticClass: "m-l-10" },
                                              [
                                                _c(
                                                  "el-tag",
                                                  {
                                                    attrs: { type: "warning" }
                                                  },
                                                  [_vm._v("兼职")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c("checkRole", {
                                        attrs: {
                                          "parent-selected":
                                            _vm.form.rs[item.id],
                                          list: item.role
                                        },
                                        on: {
                                          "update:parentSelected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.form.rs,
                                              item.id,
                                              $event
                                            )
                                          },
                                          "update:parent-selected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.form.rs,
                                              item.id,
                                              $event
                                            )
                                          },
                                          "update:list": function($event) {
                                            return _vm.$set(
                                              item,
                                              "role",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "s_management_list" })
                }
              }
            },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }