<template>
  <div class="app-container">
    <div class="toolbar">
      <el-button v-show="btn_access('course_label_add')" type="primary" icon="el-icon-plus" @click="dialogVisible()">添加</el-button>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData.list"
      row-key="id"
      class="el-table--small"
    >
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column prop="name" label="名称" min-width="150px" />
      <el-table-column prop="category.name" label="分类" min-width="150px" />
      <el-table-column prop="weigh" label="排序" min-width="150px" />
      <el-table-column prop="create_time" label="创建时间" min-width="150px" />
      <el-table-column label="操作" min-width="160">
        <template slot-scope="scope">
          <el-button v-show="btn_access('course_label_edit')" type="primary" icon="el-icon-edit" class="m-2" @click="dialogVisible(scope.row)">编辑</el-button>
          <el-popconfirm
            v-show="btn_access('course_label_del')"
            title="删除该标签，确定继续吗？"
            @confirm="del(scope.row.id)"
          >
            <el-button slot="reference" type="danger" class="m-2" icon="el-icon-delete">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 添加/编辑 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :before-close="cancelDialog"
      :visible.sync="dialog.visible"
      :title="dialog.id === null ? '添加标签':'编辑标签'"
      class="dialog_w580"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="分类" prop="category_id">
          <SelectTree
            :props="defaultProps"
            :options="sorts"
            :value="form.category_id"
            :clearable="isClearable"
            :accordion="isAccordion"
            :root-name="'顶级分类'"
            @getValue="getValue($event)"
          />
        </el-form-item>
        <el-form-item label="标签名称" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="排序" prop="weigh">
          <el-input v-model="form.weigh" type="number" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="text" @click="cancelDialog">取消</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import SelectTree from '@/components/TreeSelect/treeSelect.vue'
import { getSorts } from '@/api/admin/category.js'
import { getCourseLabel, addCourseLabel, delCourseLabel, editCourseLabel } from '@/api/admin/course_label.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  components: { Pagination, SelectTree },
  data() {
    return {
      isClearable: false, // 可清空（可选）
      isAccordion: false, // 可收起（可选）
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      sorts: {},
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 弹窗
      dialog: {
        visible: false,
        id: null
      },
      form: {
        name: '',
        category_id: 0,
        weigh: '100'
      },
      rules: {
        name: [{ required: true, message: '请输入标签名称', trigger: ['blur', 'change'] }],
        category_id: [{ required: true, message: '请选择分类', trigger: ['blur', 'change'] }]
      }
    }
  },
  mounted() {
    this.getList()
    this.getSorts()
  },
  methods: {
    // 获取分类列表
    getSorts() {
      getSorts().then(res => {
        this.sorts = res.data
      })
    },
    // 取值
    getValue(value) {
      this.form.category_id = value
    },
    dialogVisible(row) {
      this.dialog.visible = true
      if (row && row.id) {
        this.dialog.id = row.id
        this.form.category_id = row.category_id
        this.form.name = row.name
        this.form.weigh = row.weigh
      } else {
        this.dialog.id = null
        // 重载表单
        this.form = this.$options.data.call(this).form
      }
    },
    submit() {
      const data = this.form
      if (this.dialog.id) {
        editCourseLabel(this.dialog.id, data).then(res => {
          this.callback(res.msg)
        })
      } else {
        addCourseLabel(data).then(res => {
          this.callback(res.msg)
        })
      }
    },
    callback(msg) {
      this.$message.success(msg)
      this.dialog.visible = false
      this.getList()
    },
    cancelDialog() {
      this.dialog.visible = false
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getCourseLabel(data).then(res => {
        this.tableData = res.data
      }).catch(() => {})
    },
    // 删除
    del(id) {
      delCourseLabel(id).then(res => {
        this.callback(res.msg)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
