var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "searchBox" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.listQueryParams,
                    "label-width": "100px",
                    "label-position": "top",
                    "label-suffix": "：",
                    inline: true
                  }
                },
                [
                  _c(
                    "van-form",
                    {
                      staticClass: "window_phone",
                      attrs: { "label-width": "80px" }
                    },
                    [
                      _c("van-field", {
                        attrs: {
                          readonly: "",
                          clickable: "",
                          name: "calendar",
                          value: _vm.vanCreationTime,
                          label: "创建时间：",
                          placeholder: "点击选择创建时间"
                        },
                        on: {
                          click: function($event) {
                            _vm.vanCreationTimeShow = true
                          }
                        }
                      }),
                      _c("van-calendar", {
                        attrs: {
                          type: "range",
                          "min-date": _vm.minVanCreationTime,
                          "max-date": _vm.maxVanCreationTime,
                          color: "#1989fa",
                          "confirm-text": "确 定",
                          "confirm-disabled-text": "请选择结束时间"
                        },
                        on: { confirm: _vm.onCreation },
                        model: {
                          value: _vm.vanCreationTimeShow,
                          callback: function($$v) {
                            _vm.vanCreationTimeShow = $$v
                          },
                          expression: "vanCreationTimeShow"
                        }
                      }),
                      _c("van-field", {
                        attrs: {
                          readonly: "",
                          clickable: "",
                          name: "calendar",
                          value: _vm.vanExpireTime,
                          label: "到期时间：",
                          placeholder: "点击选择到期时间"
                        },
                        on: {
                          click: function($event) {
                            _vm.vanExpireTimeShow = true
                          }
                        }
                      }),
                      _c("van-calendar", {
                        attrs: {
                          type: "range",
                          "min-date": _vm.minVanCreationTime,
                          "max-date": _vm.maxVanCreationTime,
                          color: "#1989fa",
                          "confirm-text": "确 定",
                          "confirm-disabled-text": "请选择结束时间"
                        },
                        on: { confirm: _vm.onExpire },
                        model: {
                          value: _vm.vanExpireTimeShow,
                          callback: function($$v) {
                            _vm.vanExpireTimeShow = $$v
                          },
                          expression: "vanExpireTimeShow"
                        }
                      }),
                      _c("van-field", {
                        attrs: {
                          readonly: "",
                          clickable: "",
                          name: "calendar",
                          value: _vm.vanLoginTime,
                          label: "登录时间：",
                          placeholder: "点击选择登录时间"
                        },
                        on: {
                          click: function($event) {
                            _vm.vanLoginTimeShow = true
                          }
                        }
                      }),
                      _c("van-calendar", {
                        attrs: {
                          type: "range",
                          "min-date": _vm.minVanCreationTime,
                          "max-date": _vm.maxVanCreationTime,
                          color: "#1989fa",
                          "confirm-text": "确 定",
                          "confirm-disabled-text": "请选择结束时间"
                        },
                        on: { confirm: _vm.onLogin },
                        model: {
                          value: _vm.vanLoginTimeShow,
                          callback: function($$v) {
                            _vm.vanLoginTimeShow = $$v
                          },
                          expression: "vanLoginTimeShow"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "window_pc", attrs: { label: "创建时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.listQueryParams.create_time,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "create_time", $$v)
                          },
                          expression: "listQueryParams.create_time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "window_pc", attrs: { label: "到期时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.listQueryParams.expiration_time,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.listQueryParams,
                              "expiration_time",
                              $$v
                            )
                          },
                          expression: "listQueryParams.expiration_time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "window_pc", attrs: { label: "登录时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.listQueryParams.login_time,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "login_time", $$v)
                          },
                          expression: "listQueryParams.login_time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.is_disable,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "is_disable", $$v)
                            },
                            expression: "listQueryParams.is_disable"
                          }
                        },
                        _vm._l(_vm.status_list, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "国内短信" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "请输入条数", type: "number" },
                          model: {
                            value: _vm.listQueryParams.sms_balance,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "sms_balance", $$v)
                            },
                            expression: "listQueryParams.sms_balance"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { slot: "prepend", placeholder: "请选择" },
                              slot: "prepend",
                              model: {
                                value: _vm.listQueryParams.sms_balance_where,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.listQueryParams,
                                    "sms_balance_where",
                                    $$v
                                  )
                                },
                                expression: "listQueryParams.sms_balance_where"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "小于", value: "lt" }
                              }),
                              _c("el-option", {
                                attrs: { label: "大于", value: "gt" }
                              }),
                              _c("el-option", {
                                attrs: { label: "等于", value: "=" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "国际短信" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "请输入条数", type: "number" },
                          model: {
                            value: _vm.listQueryParams.sms_internation_balance,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.listQueryParams,
                                "sms_internation_balance",
                                $$v
                              )
                            },
                            expression:
                              "listQueryParams.sms_internation_balance"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { slot: "prepend", placeholder: "请选择" },
                              slot: "prepend",
                              model: {
                                value:
                                  _vm.listQueryParams
                                    .sms_internation_balance_where,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.listQueryParams,
                                    "sms_internation_balance_where",
                                    $$v
                                  )
                                },
                                expression:
                                  "listQueryParams.sms_internation_balance_where"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "小于", value: "lt" }
                              }),
                              _c("el-option", {
                                attrs: { label: "大于", value: "gt" }
                              }),
                              _c("el-option", {
                                attrs: { label: "等于", value: "=" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.status,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "status", $$v)
                            },
                            expression: "listQueryParams.status"
                          }
                        },
                        _vm._l(_vm.s_status, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: index }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌过期" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.is_expire,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "is_expire", $$v)
                            },
                            expression: "listQueryParams.is_expire"
                          }
                        },
                        _vm._l(_vm.expire_status, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客服" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.customer_id,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "customer_id", $$v)
                            },
                            expression: "listQueryParams.customer_id"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "小九", value: 1 }
                          }),
                          _c("el-option", {
                            attrs: { label: "兔子", value: 2 }
                          }),
                          _c("el-option", {
                            attrs: { label: "嬛嬛", value: 3 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "套餐版本" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.brand_level,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "brand_level", $$v)
                            },
                            expression: "listQueryParams.brand_level"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "试用版", value: 0 }
                          }),
                          _c("el-option", {
                            attrs: { label: "个人版", value: 1 }
                          }),
                          _c("el-option", {
                            attrs: { label: "旗舰版", value: 2 }
                          }),
                          _c("el-option", {
                            attrs: { label: "定制版", value: 3 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "m-b-10",
                      attrs: {
                        icon: "el-icon-search",
                        loading: _vm.searchLoading,
                        type: "success"
                      },
                      on: { click: _vm.handleFilter }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m-b-10 window_phone",
                      on: { click: _vm.handleReset }
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    { staticClass: "m-10", attrs: { type: "primary" } },
                    [_vm._v("品牌数：" + _vm._s(_vm.tableData.count))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    placeholder: "搜索品牌|手机号|标签|来源|IP|地区|编码"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleFilter($event)
                    }
                  },
                  model: {
                    value: _vm.listQueryParams.query,
                    callback: function($$v) {
                      _vm.$set(_vm.listQueryParams, "query", $$v)
                    },
                    expression: "listQueryParams.query"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      loading: _vm.searchLoading
                    },
                    on: { click: _vm.handleFilter },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("app_add"),
                  expression: "btn_access('app_add')"
                }
              ],
              staticClass: "filter-item m-l-10",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.dialogVisible()
                }
              }
            },
            [_vm._v("添加")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("app_export"),
                  expression: "btn_access('app_export')"
                }
              ],
              staticClass: "filter-item m-l-10",
              attrs: { type: "warning", icon: "el-icon-download" },
              on: {
                click: function($event) {
                  return _vm.appExport()
                }
              }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          ref: "multipleTable",
          staticClass: "el-table--small",
          attrs: {
            data: _vm.tableData.list,
            "row-key": "id",
            "row-class-name": _vm.tableRowClassName
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "demo-table-expand",
                        attrs: { "label-position": "left", inline: "" }
                      },
                      [
                        _c("el-form-item", { attrs: { label: "品牌编码：" } }, [
                          _c("span", [_vm._v(_vm._s(row.encrypt_appid))])
                        ]),
                        _c("el-form-item", { attrs: { label: "地区：" } }, [
                          _c("span", [
                            _vm._v(_vm._s(row.location ? row.location : "无"))
                          ])
                        ]),
                        _c("el-form-item", { attrs: { label: "会员数量：" } }, [
                          _c("span", [_vm._v(_vm._s(row.user_count) + " 人")])
                        ]),
                        _c("el-form-item", { attrs: { label: "员工数量：" } }, [
                          _c("span", [
                            _vm._v(_vm._s(row.store_user_count) + " 人")
                          ])
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "短信余额：" } },
                          [
                            _c("el-tag", { staticClass: "m-2" }, [
                              _vm._v("国内:" + _vm._s(row.sms_balance))
                            ]),
                            _c(
                              "el-tag",
                              {
                                staticClass: "m-2",
                                attrs: { type: "success" }
                              },
                              [
                                _vm._v(
                                  "国际:" + _vm._s(row.sms_internation_balance)
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "行业" } },
                          [
                            _c("el-tag", { staticClass: "m-2" }, [
                              _vm._v(_vm._s(row.industry_text))
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "累计发送短信：" } },
                          [
                            _c("el-tag", { staticClass: "m-2" }, [
                              _vm._v(
                                "国内:" +
                                  _vm._s(row.sms_send_count.domestic_count)
                              )
                            ]),
                            _c(
                              "el-tag",
                              {
                                staticClass: "m-2",
                                attrs: { type: "success" }
                              },
                              [
                                _vm._v(
                                  "国际:" +
                                    _vm._s(
                                      row.sms_send_count.international_count
                                    )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("el-form-item", { attrs: { label: "来源：" } }, [
                          row.source
                            ? _c(
                                "div",
                                [
                                  _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(_vm._s(row.source))
                                  ])
                                ],
                                1
                              )
                            : _c("div", [_vm._v("无")])
                        ]),
                        _c("el-form-item", { attrs: { label: "客户标签：" } }, [
                          row.tags
                            ? _c(
                                "div",
                                _vm._l(row.tags.split(","), function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticClass: "m-2",
                                      attrs: { effect: "dark" }
                                    },
                                    [_vm._v(" " + _vm._s(item) + " ")]
                                  )
                                }),
                                1
                              )
                            : _c("div", [_vm._v("无")])
                        ]),
                        _c("el-form-item", { attrs: { label: "上次回访：" } }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(row.track_time ? row.track_time : "未回访")
                            )
                          ])
                        ]),
                        _c("el-form-item", { attrs: { label: "客户详情：" } }, [
                          row.content
                            ? _c("div", { staticClass: "pre-line" }, [
                                _vm._v(_vm._s(row.content))
                              ])
                            : _c("div", [_vm._v("无")])
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "进15日活跃次数：" } },
                          [_c("div", [_vm._v(_vm._s(row.slog_count))])]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "最近操作时间：" } },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  row.last_operation_time
                                    ? row.last_operation_time.slice(0, 16)
                                    : "无"
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "id",
              label: "ID",
              "min-width": "60px",
              "render-header": _vm.renderId
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "logo",
              label: "品牌logo",
              "min-width": _vm.equipment ? "120px" : "180px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      row.logo
                        ? _c(
                            "div",
                            [
                              _c("el-avatar", {
                                attrs: {
                                  shape: "square",
                                  size: 50,
                                  src: row.logo
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          {
                            class: {
                              "text-danger": row.status == 1,
                              "text-success": row.status == 2
                            }
                          },
                          [_vm._v(" " + _vm._s(row.name) + " ")]
                        ),
                        row.customized_version.code !== 0
                          ? _c(
                              "div",
                              { staticClass: "m-l-4" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      content: row.customized_version.text,
                                      placement: "right"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-tag",
                                      {
                                        staticClass: "cursor",
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function($event) {
                                            return _vm.toPage(row)
                                          }
                                        }
                                      },
                                      [_vm._v("定制版")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "store_user",
              label: "关联账号",
              "min-width": _vm.equipment ? "180px" : "260px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "block line-1",
                        attrs: { title: row.real_name }
                      },
                      [_vm._v("用户名：" + _vm._s(row.real_name))]
                    ),
                    _c("div", { staticClass: "block line-1" }, [
                      _vm._v("手机号：" + _vm._s(row.phone))
                    ]),
                    row.login_time
                      ? _c(
                          "div",
                          {
                            staticClass: "block line-1",
                            attrs: {
                              title: _vm._f("formatDate")(
                                row.login_time,
                                "YYYY-MM-DD"
                              )
                            }
                          },
                          [
                            _vm._v(
                              "登录时间：" +
                                _vm._s(
                                  _vm._f("formatDate")(
                                    row.login_time,
                                    "YYYY-MM-DD"
                                  )
                                )
                            )
                          ]
                        )
                      : _vm._e(),
                    row.location_text
                      ? _c(
                          "div",
                          {
                            staticClass: "block",
                            attrs: { title: row.location_text + row.login_ip }
                          },
                          [
                            _vm._v(
                              "登录位置：" +
                                _vm._s(row.location_text) +
                                "(" +
                                _vm._s(row.login_ip) +
                                ")"
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "program_open_path",
              label: "品牌二维码",
              "min-width": _vm.equipment ? "100px" : "120px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex " }, [
                      row.program_open_path
                        ? _c(
                            "div",
                            [
                              _c("el-image", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: row.program_open_path,
                                  "preview-src-list": [row.program_open_path]
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      row.wechat_open_path
                        ? _c(
                            "div",
                            { staticClass: "m-l-10" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: row.wechat_open_path,
                                  "preview-src-list": [row.wechat_open_path]
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pay_money_sum",
              label: "付款金额",
              "min-width": _vm.equipment ? "60px" : "80px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "cursor",
                        on: {
                          click: function($event) {
                            _vm.btn_access("app_pay_log")
                              ? _vm.handelPayment(row)
                              : ""
                          }
                        }
                      },
                      [_vm._v("￥" + _vm._s(row.pay_money_sum))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "balance",
              label: "可提现余额",
              "render-header": _vm.renderBalanceTime,
              "min-width": _vm.equipment ? "80px" : "100px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "cursor",
                        on: {
                          click: function($event) {
                            _vm.btn_access("app_money_log")
                              ? _vm.handelCapital(row)
                              : ""
                          }
                        }
                      },
                      [_vm._v("￥" + _vm._s(row.balance))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "expiration_time",
              label: "有效期",
              width: "120",
              "render-header": _vm.renderExpirationTime
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "info",
                              title: scope.row.create_time
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatDate")(
                                    scope.row.create_time,
                                    "YYYY-MM-DD"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text-center" }, [_vm._v("至")]),
                    scope.row.expiration_time <= 0
                      ? _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("永久")
                            ])
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "cursor",
                            on: {
                              click: function($event) {
                                _vm.btn_access("app_set_validity")
                                  ? _vm.handelValidityPeriod(scope.row)
                                  : ""
                              }
                            }
                          },
                          [
                            _vm.timeRemain(scope.row.expiration_time) <= 90 &&
                            _vm.timeRemain(scope.row.expiration_time) > 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              scope.row.expiration_time,
                                              "YYYY-MM-DD"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm.timeRemain(scope.row.expiration_time) <= 7
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              scope.row.expiration_time,
                                              "YYYY-MM-DD"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              scope.row.expiration_time,
                                              "YYYY-MM-DD"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                          ]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "is_disable",
              label: "状态",
              "render-header": _vm.renderIsDisable,
              "min-width": _vm.equipment ? "60px" : "80px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    +row.is_disable === 0
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "br0_tag cursor",
                            attrs: {
                              type: "success",
                              effect: "dark",
                              title: "点击禁用"
                            },
                            on: {
                              click: function($event) {
                                return _vm.appDR(row)
                              }
                            }
                          },
                          [_vm._v(" 启用 ")]
                        )
                      : _c(
                          "el-tag",
                          {
                            staticClass: "br0_tag cursor",
                            attrs: {
                              type: "info",
                              effect: "dark",
                              title: "点击启用"
                            },
                            on: {
                              click: function($event) {
                                return _vm.appDR(row)
                              }
                            }
                          },
                          [_vm._v(" 禁用 ")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "is_disable", label: "客服", "min-width": "60px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "cursor",
                        on: {
                          click: function($event) {
                            _vm.btn_access("app_set_customer")
                              ? _vm.handelCustomerService(row)
                              : ""
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            row.customer_id === 1
                              ? "小九"
                              : row.customer_id === 2
                              ? "兔子"
                              : "嬛嬛"
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "brand_level",
              label: "版本套餐",
              "min-width": "60px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(
                          row.brand_level == 0
                            ? "试用版"
                            : row.brand_level == 1
                            ? "个人版"
                            : row.brand_level == 2
                            ? "旗舰版"
                            : "定制版"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": _vm.equipment ? "180" : "260px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("app_edit"),
                            expression: "btn_access('app_edit')"
                          }
                        ],
                        staticClass: "m-2",
                        attrs: { type: "primary", icon: "el-icon-edit" },
                        on: {
                          click: function($event) {
                            return _vm.dialogVisible(scope.row.id)
                          }
                        }
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("app_del"),
                            expression: "btn_access('app_del')"
                          }
                        ],
                        attrs: { title: "删除后将无法找回，确定继续吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.app_delete(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "course",
                        attrs: { "hide-on-click": false }
                      },
                      [
                        _c("el-button", { attrs: { type: "info" } }, [
                          _vm._v(" 更多 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right"
                          })
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_refresh"),
                                    expression: "btn_access('app_refresh')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.refresh(scope.row.id)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", { staticClass: "el-icon-refresh" }),
                                  _vm._v(" 刷新 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_visit"),
                                    expression: "btn_access('app_visit')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.dialogVisibleV(scope.row.id)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", {
                                    staticClass: "el-icon-notebook-2"
                                  }),
                                  _vm._v(" 回访 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_login"),
                                    expression: "btn_access('app_login')"
                                  }
                                ],
                                attrs: { disabled: _vm.is_bottom },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.loginStore(scope.row.id)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", { staticClass: "el-icon-set-up" }),
                                  _vm._v(" 登录 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_data"),
                                    expression: "btn_access('app_data')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.countStore(scope.row)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", { staticClass: "el-icon-set-up" }),
                                  _vm._v(" 数据 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_money_log"),
                                    expression: "btn_access('app_money_log')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handelCapital(scope.row)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", { staticClass: "el-icon-money" }),
                                  _vm._v(" 资金 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_pay_log"),
                                    expression: "btn_access('app_pay_log')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handelPayment(scope.row)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", { staticClass: "el-icon-money" }),
                                  _vm._v(" 收款信息 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_operation_log"),
                                    expression:
                                      "btn_access('app_operation_log')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handelLog(scope.row)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", {
                                    staticClass: "el-icon-s-management"
                                  }),
                                  _vm._v(" 操作日志 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_set_super"),
                                    expression: "btn_access('app_set_super')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handelSwitch(scope.row)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", { staticClass: "el-icon-sort" }),
                                  _vm._v(" 切换超管 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_set_customer"),
                                    expression: "btn_access('app_set_customer')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handelCustomerService(scope.row)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", { staticClass: "el-icon-sort" }),
                                  _vm._v(" 切换客服 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_wechat_config"),
                                    expression:
                                      "btn_access('app_wechat_config')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.toPage(scope.row)
                                  }
                                }
                              },
                              [
                                [
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "icon",
                                        staticStyle: {
                                          width: "16px",
                                          height: "16px"
                                        },
                                        attrs: {
                                          t: "1693187126971",
                                          viewBox: "0 0 1024 1024",
                                          version: "1.1",
                                          xmlns: "http://www.w3.org/2000/svg",
                                          "p-id": "4023",
                                          width: "16",
                                          height: "16"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M664.250054 368.541681c10.015098 0 19.892049 0.732687 29.67281 1.795902-26.647917-122.810047-159.358451-214.077703-310.826188-214.077703-169.353083 0-308.085774 114.232694-308.085774 259.274068 0 83.708494 46.165436 152.460344 123.281791 205.78483l-30.80868 91.730191 107.688651-53.455469c38.558178 7.53665 69.459978 15.308661 107.924012 15.308661 9.66308 0 19.230993-0.470721 28.752858-1.225921-6.025227-20.36584-9.521864-41.723264-9.521864-63.862493C402.328693 476.632491 517.908058 368.541681 664.250054 368.541681zM498.62897 285.87389c23.200398 0 38.557154 15.120372 38.557154 38.061874 0 22.846334-15.356756 38.156018-38.557154 38.156018-23.107277 0-46.260603-15.309684-46.260603-38.156018C452.368366 300.994262 475.522716 285.87389 498.62897 285.87389zM283.016307 362.090758c-23.107277 0-46.402843-15.309684-46.402843-38.156018 0-22.941502 23.295566-38.061874 46.402843-38.061874 23.081695 0 38.46301 15.120372 38.46301 38.061874C321.479317 346.782098 306.098002 362.090758 283.016307 362.090758zM945.448458 606.151333c0-121.888048-123.258255-221.236753-261.683954-221.236753-146.57838 0-262.015505 99.348706-262.015505 221.236753 0 122.06508 115.437126 221.200938 262.015505 221.200938 30.66644 0 61.617359-7.609305 92.423993-15.262612l84.513836 45.786813-23.178909-76.17082C899.379213 735.776599 945.448458 674.90216 945.448458 606.151333zM598.803483 567.994292c-15.332197 0-30.807656-15.096836-30.807656-30.501688 0-15.190981 15.47546-30.477129 30.807656-30.477129 23.295566 0 38.558178 15.286148 38.558178 30.477129C637.361661 552.897456 622.099049 567.994292 598.803483 567.994292zM768.25071 567.994292c-15.213493 0-30.594809-15.096836-30.594809-30.501688 0-15.190981 15.381315-30.477129 30.594809-30.477129 23.107277 0 38.558178 15.286148 38.558178 30.477129C806.808888 552.897456 791.357987 567.994292 768.25071 567.994292z",
                                            fill: "#5D5D5D",
                                            "p-id": "4024"
                                          }
                                        })
                                      ]
                                    ),
                                    _c("div", { staticClass: "m-l-4" }, [
                                      _vm._v("微信配置")
                                    ])
                                  ])
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("app_set_validity"),
                                    expression: "btn_access('app_set_validity')"
                                  }
                                ],
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handelValidityPeriod(scope.row)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", { staticClass: "el-icon-time" }),
                                  _vm._v(" 增加有效期 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handelLimitExport(
                                      scope.row.id,
                                      scope.row.disable_export
                                    )
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", { staticClass: "el-icon-time" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.disable_export
                                          ? "解除导出限制"
                                          : "限制导出"
                                      ) +
                                      " "
                                  )
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0 && _vm.equipment,
            expression: "tableData.count > 0 && equipment"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c("van-pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0 && !_vm.equipment,
            expression: "tableData.count > 0 && !equipment"
          }
        ],
        staticClass: "custom-pagination m-t-20",
        attrs: {
          "total-items": _vm.tableData.count,
          "show-page-size": 4,
          "force-ellipses": ""
        },
        on: { change: _vm.handleCurrentChange },
        model: {
          value: _vm.currentPage,
          callback: function($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage"
        }
      }),
      _c(
        "el-dialog",
        {
          class: _vm.equipment ? "dialog_w580" : "dialog_auto",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.cancelDialog,
            visible: _vm.dialog.visible,
            title: _vm.dialog.id === null ? "添加品牌" : "编辑品牌"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.brandLoading,
                  expression: "brandLoading"
                }
              ]
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    inline: true,
                    "label-position": _vm.equipment ? "right" : "top",
                    model: _vm.form,
                    rules: _vm.rules,
                    size: "small",
                    "label-width": "80px"
                  }
                },
                [
                  _vm.dialog.id === null
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "手机号", prop: "phone" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "请输入手机号" },
                                model: {
                                  value: _vm.form.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "phone", $$v)
                                  },
                                  expression: "form.phone"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "prepend" },
                                    slot: "prepend"
                                  },
                                  [
                                    _c("Code", {
                                      on: { area_code: _vm.area_code }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌名称", prop: "name" } },
                    [
                      _c("el-input", {
                        class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                        attrs: { placeholder: "品牌名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "行业", prop: "industry" } },
                    [
                      _c(
                        "el-select",
                        {
                          class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                          attrs: {
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            placeholder: "请选择文章标签"
                          },
                          model: {
                            value: _vm.form.industry,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "industry", $$v)
                            },
                            expression: "form.industry"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "prefix" }, slot: "prefix" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "切换行业后场馆默认图片会发生变化，如课程、会员卡封面等请谨慎操作，不影响已发布内容。",
                                    placement: "bottom"
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-info" })]
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.industry_options, function(item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.label, value: item.value }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地区", prop: "location" } },
                    [
                      _c("el-input", {
                        class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                        attrs: {
                          placeholder: "所在地区",
                          maxlength: "32",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.location,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "location", $$v)
                          },
                          expression: "form.location"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "来源", prop: "source" } },
                    [
                      _c("el-input", {
                        class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                        attrs: {
                          placeholder: "来源",
                          maxlength: "32",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.source,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "source", $$v)
                          },
                          expression: "form.source"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "国内短信", prop: "sms_balance" } },
                    [
                      _c("el-input", {
                        class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                        attrs: { placeholder: "国内短信", type: "number" },
                        model: {
                          value: _vm.form.sms_balance,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sms_balance", $$v)
                          },
                          expression: "form.sms_balance"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "国际短信",
                        prop: "sms_internation_balance"
                      }
                    },
                    [
                      _c("el-input", {
                        class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                        attrs: { placeholder: "国际短信", type: "number" },
                        model: {
                          value: _vm.form.sms_internation_balance,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sms_internation_balance", $$v)
                          },
                          expression: "form.sms_internation_balance"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "到期时间" } },
                    [
                      _vm.form.expiration_time !== 0
                        ? _c("el-date-picker", {
                            class: _vm.equipment ? "dialog_w80" : "dialog_w280",
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.equipment
                                ? _vm.pickerOptions2
                                : "",
                              "popper-class": "dataTimeWithout"
                            },
                            model: {
                              value: _vm.form.expiration_time,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "expiration_time", $$v)
                              },
                              expression: "form.expiration_time"
                            }
                          })
                        : _vm._e(),
                      _vm.form.expiration_time === 0
                        ? _c(
                            "el-button",
                            { staticClass: "m-4", attrs: { type: "success" } },
                            [_vm._v("永久")]
                          )
                        : _vm._e(),
                      _vm.form.expiration_time === 0
                        ? _c(
                            "el-button",
                            {
                              staticClass: "m-4",
                              attrs: { size: "mini" },
                              on: { click: _vm.handleChoice }
                            },
                            [_vm._v("选择日期")]
                          )
                        : _vm._e(),
                      _vm.form.expiration_time !== 0
                        ? _c(
                            "el-button",
                            {
                              staticClass: "m-l-10",
                              attrs: { type: "success" },
                              on: { click: _vm.handlePermanent }
                            },
                            [_vm._v("永久日期")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签", prop: "tags" } },
                    [
                      _c(
                        "el-select",
                        {
                          class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                          attrs: {
                            multiple: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            placeholder: "请选择客户标签"
                          },
                          model: {
                            value: _vm.form.tags,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "tags", $$v)
                            },
                            expression: "form.tags"
                          }
                        },
                        _vm._l(_vm.tags_options, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户信息", prop: "content" } },
                    [
                      _c("el-input", {
                        class: _vm.equipment ? "dialog_w100" : "dialog_w280",
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入内容",
                          maxlength: "230",
                          "show-word-limit": "",
                          rows: "5"
                        },
                        model: {
                          value: _vm.form.content,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用状态", prop: "status" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.form.status,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status"
                          }
                        },
                        [_vm._v("未使用")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.form.status,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status"
                          }
                        },
                        [_vm._v("已使用")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.form.status,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status"
                          }
                        },
                        [_vm._v("已付款")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "套餐版本", prop: "brand_level" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.form.brand_level,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "brand_level", $$v)
                            },
                            expression: "form.brand_level"
                          }
                        },
                        [_vm._v("试用版")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.form.brand_level,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "brand_level", $$v)
                            },
                            expression: "form.brand_level"
                          }
                        },
                        [_vm._v("个人版")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.form.brand_level,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "brand_level", $$v)
                            },
                            expression: "form.brand_level"
                          }
                        },
                        [_vm._v("旗舰版")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.form.brand_level,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "brand_level", $$v)
                            },
                            expression: "form.brand_level"
                          }
                        },
                        [_vm._v("定制版")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.handleSubmit },
                  on: { click: _vm.submit }
                },
                [_vm._v("确认")]
              ),
              _c("el-button", { on: { click: _vm.cancelDialog } }, [
                _vm._v("取消")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: _vm.equipment ? "40%" : "100%",
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.countDialog.visible,
            title: "数据统计(" + _vm.countDialog.title + ")"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.countDialog, "visible", $event)
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.countDialog.loading,
                  expression: "countDialog.loading"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "m-y-20" },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "会员统计", column: "5" } },
                    [
                      _c("el-descriptions-item", { attrs: { label: "今天" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.membership.day))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本月" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.membership.month))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本周" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.membership.week))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "年度" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.membership.year))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "总计" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.membership.total))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "m-y-20" },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "访客统计", column: "5" } },
                    [
                      _c("el-descriptions-item", { attrs: { label: "今天" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.visitor.day))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本月" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.visitor.month))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本周" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.visitor.week))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "年度" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.visitor.year))
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "总计" } }, [
                        _vm._v(_vm._s(_vm.countDialog.data.visitor.total))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "m-y-20" },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "团课预约记录", column: "5" } },
                    [
                      _c("el-descriptions-item", { attrs: { label: "今天" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.countDialog.data.group_course_subscribe.day
                          )
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本月" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countDialog.data.group_course_subscribe.month
                            ) +
                            " "
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本周" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.countDialog.data.group_course_subscribe.week
                          )
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "年度" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.countDialog.data.group_course_subscribe.year
                          )
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "总计" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countDialog.data.group_course_subscribe.total
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "m-y-20" },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "精品课预约记录", column: "5" } },
                    [
                      _c("el-descriptions-item", { attrs: { label: "今天" } }, [
                        _vm._v(
                          _vm._s(_vm.countDialog.data.fine_course_subscribe.day)
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本月" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.countDialog.data.fine_course_subscribe.month
                          )
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本周" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.countDialog.data.fine_course_subscribe.week
                          )
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "年度" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.countDialog.data.fine_course_subscribe.year
                          )
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "总计" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.countDialog.data.fine_course_subscribe.total
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "m-y-20" },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "私教课预约记录", column: "5" } },
                    [
                      _c("el-descriptions-item", { attrs: { label: "今天" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countDialog.data.private_course_subscribe.day
                            ) +
                            " "
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本月" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countDialog.data.private_course_subscribe
                                .month
                            ) +
                            " "
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本周" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countDialog.data.private_course_subscribe.week
                            ) +
                            " "
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "年度" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countDialog.data.private_course_subscribe.year
                            ) +
                            " "
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "总计" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.countDialog.data.private_course_subscribe
                                .total
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "m-y-20" },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { title: "用户会员卡统计", column: "5" } },
                    [
                      _c("el-descriptions-item", { attrs: { label: "今天" } }, [
                        _vm._v(
                          _vm._s(_vm.countDialog.data.user_membership_card.day)
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本月" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.countDialog.data.user_membership_card.month
                          )
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "本周" } }, [
                        _vm._v(
                          _vm._s(_vm.countDialog.data.user_membership_card.week)
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "年度" } }, [
                        _vm._v(
                          _vm._s(_vm.countDialog.data.user_membership_card.year)
                        )
                      ]),
                      _c("el-descriptions-item", { attrs: { label: "总计" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.countDialog.data.user_membership_card.total
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c("VisitorTrack", {
        attrs: {
          id: _vm.vdialog.trackId,
          "track-visible": _vm.vdialog.trackVisible
        },
        on: {
          "update:trackVisible": function($event) {
            return _vm.$set(_vm.vdialog, "trackVisible", $event)
          },
          "update:track-visible": function($event) {
            return _vm.$set(_vm.vdialog, "trackVisible", $event)
          },
          back: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          staticClass: "storeViewBox",
          attrs: {
            visible: _vm.storeView,
            width: "100%",
            fullscreen: "",
            "destroy-on-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.storeView = $event
            },
            close: _vm.closestoreViewBox
          }
        },
        [
          _vm.url
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.iframeLoading,
                      expression: "iframeLoading"
                    }
                  ]
                },
                [
                  _c("iframe", {
                    ref: "storeViews",
                    attrs: {
                      frameborder: "0",
                      width: "100%",
                      scrolling: "auto",
                      src: _vm.url
                    },
                    on: {
                      load: function($event) {
                        return _vm.sendToken()
                      }
                    }
                  })
                ]
              )
            : _vm._e()
        ]
      ),
      _c("span", { attrs: { id: "message" } }),
      _c("capital", {
        attrs: { id: _vm.capitalId, visible: _vm.capitalVisible },
        on: {
          "update:visible": function($event) {
            _vm.capitalVisible = $event
          },
          closeDialog: _vm.handleCloseDialog
        }
      }),
      _c("PayRecord", {
        attrs: { id: _vm.paymentId, visible: _vm.paymentVisible },
        on: {
          "update:visible": function($event) {
            _vm.paymentVisible = $event
          },
          handleClosePayment: _vm.handleClosePayment
        }
      }),
      _c("operation", {
        attrs: { id: _vm.logId, visible: _vm.logVisible },
        on: {
          "update:visible": function($event) {
            _vm.logVisible = $event
          },
          closeDialog: _vm.handleLog
        }
      }),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.account.loading,
              expression: "account.loading"
            }
          ],
          attrs: {
            title: "切换超管",
            visible: _vm.account.dialogVisible,
            width: _vm.equipment ? "40%" : "100%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.account, "dialogVisible", $event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap" },
            _vm._l(_vm.account.list, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "borderX flex row-between p-10 cursor",
                  style: {
                    border:
                      item.id === _vm.account.store_user_id
                        ? "2px solid #46a6ff"
                        : "2px solid #ebeef5"
                  },
                  attrs: { span: 12 },
                  on: {
                    click: function($event) {
                      return _vm.handleAccountId(item.id)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-avatar",
                        { attrs: { shape: "square", src: item.avatar } },
                        [
                          _c("img", {
                            attrs: { src: _vm.errorAvatarSrc(item, "img") }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "m-l-10" }, [
                        _vm._v(_vm._s(item.real_name + "/" + item.phone))
                      ])
                    ],
                    1
                  ),
                  item.id === _vm.account.store_user_id
                    ? _c(
                        "div",
                        {
                          staticStyle: { "font-size": "20px" },
                          style: {
                            color:
                              item.id === _vm.account.store_user_id
                                ? "#46a6ff"
                                : "#ebeef5"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-check" })]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.account.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.account.count > 10 && _vm.equipment,
                expression: "account.count > 10 && equipment"
              }
            ],
            attrs: {
              total: _vm.account.count,
              page: _vm.listQuerySwitch.page,
              limit: _vm.listQuerySwitch.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuerySwitch, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuerySwitch, "limit", $event)
              },
              pagination: _vm.handelSwitchI
            }
          }),
          _c("van-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.account.count > 10 && !_vm.equipment,
                expression: "account.count > 10 && !equipment"
              }
            ],
            staticClass: "custom-pagination m-t-20",
            attrs: {
              "total-items": _vm.account.count,
              "show-page-size": 4,
              "force-ellipses": ""
            },
            on: { change: _vm.handleAccountChange },
            model: {
              value: _vm.AccountPage,
              callback: function($$v) {
                _vm.AccountPage = $$v
              },
              expression: "AccountPage"
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.CustomerService.loading,
              expression: "CustomerService.loading"
            }
          ],
          attrs: {
            title: "切换客服",
            visible: _vm.CustomerService.dialogVisible,
            width: _vm.equipment ? "40%" : "100%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.CustomerService, "dialogVisible", $event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap" },
            _vm._l(_vm.CustomerService.list, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "borderX flex row-between p-10 cursor",
                  style: {
                    border:
                      item.id === _vm.CustomerService.customer_id
                        ? "2px solid #46a6ff"
                        : "2px solid #ebeef5"
                  },
                  attrs: { span: 12 },
                  on: {
                    click: function($event) {
                      return _vm.handleCustomerService(item.id)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: {
                          src: item.qrcode_src,
                          "preview-src-list": [item.qrcode_src]
                        }
                      }),
                      _c("div", { staticClass: "m-l-10" }, [
                        _vm._v(_vm._s(item.name + "/" + item.phone))
                      ])
                    ],
                    1
                  ),
                  item.id === _vm.CustomerService.customer_id
                    ? _c(
                        "div",
                        {
                          staticStyle: { "font-size": "20px" },
                          style: {
                            color:
                              item.id === _vm.CustomerService.customer_id
                                ? "#46a6ff"
                                : "#ebeef5"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-check" })]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.CustomerService.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCustomerConfirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.CustomerService.count > 0 && _vm.equipment,
                expression: "CustomerService.count > 0 && equipment"
              }
            ],
            attrs: {
              total: _vm.CustomerService.count,
              page: _vm.listQueryService.page,
              limit: _vm.listQueryService.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQueryService, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQueryService, "limit", $event)
              },
              pagination: _vm.handelCustomerServiceI
            }
          }),
          _c("van-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.CustomerService.count > 10 && !_vm.equipment,
                expression: "CustomerService.count > 10 && !equipment"
              }
            ],
            staticClass: "custom-pagination m-t-20",
            attrs: {
              "total-items": _vm.CustomerService.count,
              "show-page-size": 4,
              "force-ellipses": ""
            },
            on: { change: _vm.handleCustomerChange },
            model: {
              value: _vm.CustomerPage,
              callback: function($$v) {
                _vm.CustomerPage = $$v
              },
              expression: "CustomerPage"
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "增加有效期",
            visible: _vm.validityPeriod.dialogVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: _vm.equipment ? "20%" : "100%"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.validityPeriod, "dialogVisible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.validityPeriodForm, "label-width": "80px" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select",
                  attrs: { placeholder: "请输入天数", type: "number" },
                  model: {
                    value: _vm.validityPeriodForm.time,
                    callback: function($$v) {
                      _vm.$set(_vm.validityPeriodForm, "time", $$v)
                    },
                    expression: "validityPeriodForm.time"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80px" },
                      attrs: { slot: "append", placeholder: "请选择" },
                      slot: "append",
                      model: {
                        value: _vm.validityPeriodForm.unit,
                        callback: function($$v) {
                          _vm.$set(_vm.validityPeriodForm, "unit", $$v)
                        },
                        expression: "validityPeriodForm.unit"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "年", value: "year" }
                      }),
                      _c("el-option", {
                        attrs: { label: "月", value: "month" }
                      }),
                      _c("el-option", { attrs: { label: "日", value: "day" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.validityPeriod.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleValidityPeriod }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Export", {
        attrs: {
          "success-prompt": _vm.successPrompt,
          "is-judge": _vm.is_judge,
          "animation-display": _vm.animation_display
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }