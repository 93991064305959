<template>
  <div class="app-container">
    <div class="filter-container">
      <router-link :to="{name: 's_management_role_add'}">
        <el-button type="primary" icon="el-icon-plus">添加</el-button>
      </router-link>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="id" label="ID" />
      <el-table-column prop="role_name" label="名称" />
      <!-- <el-table-column prop="access_id" label="权限节点" /> -->
      <el-table-column prop="sort" label="排序">
        <template slot-scope="{row}">
          <el-tag type="primary">
            {{ row.sort }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" @click="toPage(scope.row.id)" />
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>

</template>

<script>
import { getRole } from '@/api/store/management.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  components: { Pagination },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getRole(data).then(res => {
        this.tableData = res.data
      }).catch(() => {})
    },
    toPage(id) {
      this.$router.push({ name: 's_management_role_edit', params: { id: id }})
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
