var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox",
        {
          attrs: { indeterminate: _vm.isIndeterminate },
          model: {
            value: _vm.checkAll,
            callback: function($$v) {
              _vm.checkAll = $$v
            },
            expression: "checkAll"
          }
        },
        [_vm._v("全选")]
      ),
      _c(
        "el-checkbox-group",
        {
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        _vm._l(_vm.list, function(item) {
          return _c(
            "el-checkbox",
            { key: item.id, attrs: { label: item } },
            [
              item.store_user
                ? _c("span", [
                    _vm._v(_vm._s(item.store_user.real_name) + " / ")
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.isCourse ? "" : "教练待更新 / "))
                  ]),
              _vm._v(_vm._s(item.name) + " "),
              _vm.type != 0 && _vm.type
                ? [
                    _c("el-input", {
                      staticClass: "input m-b-10",
                      attrs: {
                        type: "number",
                        placeholder: "单节课收费,默认0"
                      },
                      on: { input: _vm.inputChange },
                      model: {
                        value: item.charge,
                        callback: function($$v) {
                          _vm.$set(item, "charge", $$v)
                        },
                        expression: "item.charge"
                      }
                    }),
                    _vm._v(_vm._s(_vm.type == 1 ? "次/人" : "元/人"))
                  ]
                : _vm._e()
            ],
            2
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }