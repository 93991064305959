<template>
  <el-tabs v-model="activeName" tab-position="top">
    <el-tab-pane label="支付配置" name="WeChatPay">
      <WeChatPay />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import WeChatPay from './components/WeChatPay'

export default {
  components: {
    WeChatPay
  },
  data() {
    return {
      activeName: 'WeChatPay'
    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
