var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleContract,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "25%",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleContract = $event
            },
            open: _vm.handleOpen,
            close: _vm.handleClose
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "title_title" }, [
                _vm._v(_vm._s(_vm.dialogData.name))
              ]),
              _c(
                "div",
                {
                  staticClass: "title_icon cursor",
                  on: { click: _vm.handleClose }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            ])
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ]
            },
            [
              _c("div", { staticClass: "flex row-between" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "60px" },
                      attrs: { src: _vm.iconUrl, fit: "fill" }
                    }),
                    _c(
                      "div",
                      { staticClass: "m-l-10" },
                      [
                        _c("div", { staticClass: "flex m-b-10" }, [
                          _vm.dialogData.name
                            ? _c(
                                "div",
                                {
                                  staticClass: "font-18",
                                  staticStyle: { "font-weight": "bold" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dialogData.name.length > 15
                                          ? _vm.dialogData.name.slice(0, 10) +
                                              "..."
                                          : _vm.dialogData.name
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm.dialogData.status >= 0
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm.status[_vm.dialogData.status].type
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.status[_vm.dialogData.status].label
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "cursor",
                      staticStyle: { color: "#01cbca" },
                      on: {
                        click: function($event) {
                          return _vm.goFile(_vm.dialogData.file_path)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-view m-r-2" }),
                      _vm._v(" 查 看 ")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "cursor m-l-16",
                      staticStyle: { color: "#01cbca" },
                      on: {
                        click: function($event) {
                          return _vm.goDownload(
                            _vm.dialogData.file_path,
                            _vm.dialogData.name
                          )
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-download m-r-2" }),
                      _vm._v(" 下载合同 ")
                    ]
                  )
                ])
              ]),
              _c("p", { staticClass: "m-t-30" }, [
                _c("b", [_vm._v("详细信息")])
              ]),
              _c(
                "el-descriptions",
                { staticClass: "margin-top", attrs: { column: 1, border: "" } },
                [
                  _vm.dialogData.tags
                    ? _c("el-descriptions-item", { attrs: { label: "标签" } }, [
                        _vm.dialogData.tags.length === 0
                          ? _c("div", { staticClass: "text-info flex" }, [
                              _c("div", [_vm._v("暂无标签")]),
                              _vm.contractStatus == 5
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cursor text-success m-l-20",
                                      on: { click: _vm.handleAddTags }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-plus font-16"
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _c("div", { staticClass: "text-info flex" }, [
                              _c(
                                "div",
                                _vm._l(_vm.dialogData.tags, function(item) {
                                  return _c(
                                    "el-tag",
                                    { key: item.id, staticClass: "m-2" },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              ),
                              _vm.contractStatus == 5
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cursor text-success m-l-20",
                                      on: { click: _vm.handleAddTags }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-plus font-16"
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ])
                      ])
                    : _vm._e(),
                  _vm.contractStatus !== 5
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "签署信息" } },
                        [
                          Array.isArray(_vm.dialogData.users)
                            ? _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c("el-avatar", {
                                    attrs: {
                                      shape: "square",
                                      size: 30,
                                      src:
                                        _vm.dialogData.users.length > 0
                                          ? _vm.dialogData.users[0].avatar
                                            ? _vm.dialogData.users[0].avatar
                                            : _vm.errorMemberSrc()
                                          : _vm.errorMemberSrc()
                                    }
                                  }),
                                  _c("div", { staticClass: "m-l-4" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialogData.users.length > 0
                                          ? _vm.dialogData.users[0].nickname
                                          : "---"
                                      )
                                    )
                                  ]),
                                  _c("div", { staticClass: "m-l-4" }, [
                                    _vm._v(
                                      "（" +
                                        _vm._s(
                                          _vm.status[_vm.dialogData.status]
                                            .label
                                        ) +
                                        "）"
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm.contractStatus !== 5
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "合同编号" } },
                        [_vm._v(" " + _vm._s(_vm.dialogData.contract_no) + " ")]
                      )
                    : _vm._e(),
                  _vm.dialogData.store_user
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "创建人" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("el-avatar", {
                                attrs: {
                                  shape: "square",
                                  size: 30,
                                  src: _vm.dialogData.store_user.avatar
                                    ? _vm.dialogData.store_user.avatar
                                    : _vm.errorMemberSrc()
                                }
                              }),
                              _c("div", { staticClass: "m-l-4" }, [
                                _vm._v(
                                  _vm._s(_vm.dialogData.store_user.real_name)
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.dialogData.create_time
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "创建时间" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.dialogData.create_time.slice(0, 16)) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.contractStatus == 0 ||
                  _vm.contractStatus == 1 ||
                  _vm.contractStatus == 2
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "签署截至时间" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dialogData.sign_expire_time
                                  ? _vm.dialogData.sign_expire_time.slice(0, 16)
                                  : "不限制"
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.contractStatus == 1 ||
                  _vm.contractStatus == 3 ||
                  _vm.contractStatus == 4
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "合同到期时间" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dialogData.contract_expire_time
                                  ? _vm.dialogData.contract_expire_time.slice(
                                      0,
                                      16
                                    )
                                  : "不限制"
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.contractStatus == 4
                    ? _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                        _vm.dialogData.dispose_record
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.dialogData.dispose_record.remark
                                    ? _vm.dialogData.dispose_record.remark
                                    : "无"
                                )
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭")
              ])
            ],
            1
          )
        ]
      ),
      _c("tagDialog", {
        attrs: {
          "dialog-visible-template": _vm.dialogVisibleTemplate,
          "template-tags": _vm.templateTags
        },
        on: { closeVisibleTemplate: _vm.closeVisibleTemplate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }