import request from '@/utils/request'

// 获取课程标签列表
export function getCourseLabel(data) {
  return request({
    url: '/admin/v1/course_label',
    method: 'get',
    params: data
  })
}

// 修改课程标签
export function editCourseLabel(id, data) {
  return request({
    url: `/admin/v1/course_label/${id}`,
    method: 'put',
    params: data
  })
}

// 添加课程标签
export function addCourseLabel(data) {
  return request({
    url: `/admin/v1/course_label`,
    method: 'post',
    data
  })
}

// 删除课程标签
export function delCourseLabel(id, data) {
  return request({
    url: `/admin/v1/course_label/${id}`,
    method: 'delete',
    params: data
  })
}
