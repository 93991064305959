<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="品牌" prop="brandID">
            <Select :type="1" @changeId="handleBrandID" />
          </el-form-item>
          <el-form-item label="提现时间">
            <el-date-picker
              v-model="listQueryParams.payment_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="listQueryParams.status" placeholder="请选择" filterable clearable>
              <el-option label="未审核" :value="0" />
              <el-option label="已审核" :value="1" />
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData.list" row-key="id">
      <el-table-column label="品牌信息">
        <template slot-scope="{ row }">
          <div v-if="row.store_user.app" class="flex">
            <el-avatar v-if="row.store_user.app.logo" shape="square" class="m-r-6" :src="row.store_user.app.logo" />
            <span>{{ row.store_user.app.name }}</span>
          </div>
          <div v-else>品牌信息丢失</div>
        </template>
      </el-table-column>
      <el-table-column label="邀请人员信息">
        <template slot-scope="{ row }">
          <div v-if="!utils.empty(row.store_user)" class="flex col-center">
            <div>
              <el-avatar shape="square" :size="50" :src="row.store_user.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row.store_user) }}</span>
              <br>
              <span>{{ row.store_user.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="提现金额" min-width="60" />
      <el-table-column prop="order_no" label="订单号" min-width="100" />
      <el-table-column prop="payment_no" label="微信交易号" min-width="120" />
      <el-table-column prop="payment_time" label="提现时间" />
      <el-table-column label="提现方式">
        <template>微信零钱</template>
      </el-table-column>
      <el-table-column prop="status" label="状态" min-width="60">
        <template slot-scope="{ row }">
          <el-tag :type="row.status === 0 ? 'warning' : row.status === 1 ? '' : 'danger'">{{ row.status === 0 ? '待审核' : row.status === 1 ? '已通过' : '已驳回' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="120">
        <template slot-scope="{ row }">
          <el-popconfirm v-if="row.status === 0" title="确定审核之后即立即打款，确定继续吗？" @confirm="handleAudit(row.id, 1)">
            <el-button slot="reference" class="m-2" type="primary" icon="el-icon-circle-check">通过审核</el-button>
          </el-popconfirm>
          <el-popconfirm v-if="row.status === 0" title="是否确定驳回该提现审核？" @confirm="handleAudit(row.id, 2)">
            <el-button slot="reference" class="m-2" type="danger" icon="el-icon-circle-close">驳回审核</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
// API
import { getInvitationRecordList, auditInvitationRecord } from '@/api/admin/invitation_record.js'
// 组件
import Pagination from '@/components/Pagination'
import Select from '@/components/elSelect/Select'

export default {
  components: { Pagination, Select },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      tableLoading: false,
      searchLoading: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      if (this.listQueryParams.appid) data.appid = this.listQueryParams.appid

      const querys = []
      if (this.listQueryParams.payment_time) { querys.push({ field: 'payment_time', type: 'between-time', key: this.listQueryParams.payment_time }) }
      if (this.listQueryParams.status !== '' && this.listQueryParams.status != null) { querys.push({ field: 'status', key: this.listQueryParams.status }) }
      data.query = this.utils.getQueryParams(querys)

      getInvitationRecordList(data)
        .then(res => {
          this.tableData = res.data
        })
        .finally(() => {
          this.tableLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleBrandID(val) {
      this.listQueryParams.appid = val
    },
    handleAudit(id, type) {
      const data = {
        status: type === 1 ? 1 : 2
      }
      auditInvitationRecord(id, data)
        .then(() => {
          this.getList()
          this.$message.success('操作成功！')
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
