var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog_w580",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.value,
            title: "课程标签",
            "before-close": _vm.close
          },
          on: {
            "update:visible": function($event) {
              _vm.value = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "label-box" },
            _vm._l(_vm.list, function(item, index) {
              return _c("div", { key: index }, [
                item.category_id !== 0
                  ? _c("div", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _c(
                        "div",
                        { staticClass: "tags" },
                        _vm._l(item.list, function(item1, index1) {
                          return _c(
                            "el-tag",
                            {
                              key: index1,
                              staticClass: "m-x-4 m-y-4 cursor",
                              attrs: {
                                size: "medium",
                                type: _vm.isCheck(item1.id) ? "primary" : "info"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.checkItem(item1)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item1.name))]
                          )
                        }),
                        1
                      )
                    ])
                  : _c("div", [
                      _c("div", { staticClass: "title flex row-between" }, [
                        _c("div", [_vm._v(_vm._s(item.title))]),
                        !_vm.is_edit
                          ? _c(
                              "div",
                              {
                                staticClass: "text-primary cursor font-13",
                                on: {
                                  click: function($event) {
                                    _vm.is_edit = true
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm.is_edit
                          ? _c(
                              "div",
                              {
                                staticClass: "text-success cursor font-13",
                                on: {
                                  click: function($event) {
                                    _vm.is_edit = false
                                  }
                                }
                              },
                              [_vm._v("完成")]
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        { staticClass: "tags" },
                        [
                          _vm._l(item.list, function(item1, index1) {
                            return _c(
                              "el-tag",
                              {
                                key: index1,
                                staticClass: "m-x-4 m-y-4 cursor",
                                attrs: {
                                  "disable-transitions": false,
                                  closable: _vm.is_edit,
                                  size: "medium",
                                  type: _vm.isCheck(item1.id)
                                    ? "primary"
                                    : "info"
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.delLabel(
                                      item1,
                                      item.list,
                                      index1
                                    )
                                  },
                                  click: function($event) {
                                    return _vm.checkItem(item1)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item1.name))]
                            )
                          }),
                          !_vm.is_edit
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "m-x-4 m-y-4 cursor",
                                  attrs: {
                                    type: "success",
                                    effect: "dark",
                                    size: "medium"
                                  },
                                  on: { click: _vm.addLabel }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _vm._v(" 添加")
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ])
              ])
            }),
            0
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.close } },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }