<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    width="50%"
    @open="handleOpen"
    @close="handleClose"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">批量操作</div>
        <div class="title_icon cursor" @click="handleCloseDialog">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <div v-loading="dialogLoading">
      <el-steps align-center :active="stepsActive" finish-status="success">
        <el-step title="选择操作" />
        <el-step title="选择会员卡" />
        <el-step title="执行操作" />
      </el-steps>
      <div class="operation_center">
        <div v-if="stepsActive === 1" class="m-t-20">
          <el-tag v-for="(item, index) in tagDataList" v-show="btn_access(btnTagDataList[index])" :key="index" class="m-10 cursor" @click="handleOperation(index)">{{ item }}</el-tag>
        </div>
        <div v-if="stepsActive === 2" class="m-t-20">
          <div v-if="operationId == 4" class="flex m-b-20">
            <div>卡类型：</div>
            <el-select v-model="cardType" placeholder="请选择" @change="handleChange">
              <el-option label="期限卡" :value="0" />
              <el-option label="次数卡" :value="1" />
              <el-option label="储值卡" :value="2" />
            </el-select>
          </div>
          <el-transfer v-model="valueModifyPoints" filter-placeholder="搜索卡号 | 会员卡名称" filterable :data="dataModifyPoints" :titles="['会员卡列表', '修改列表']" />
        </div>
        <div v-if="stepsActive == 3" class="m-t-20">
          <!-- 批量请假 -->
          <div v-if="operationId == 1">
            <el-form ref="form" :model="form" label-width="120px" :rules="rules">
              <el-form-item label="请假时间" prop="ask_time">
                <el-date-picker
                  v-model="form.ask_time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
              <el-form-item label="手续费" prop="checked">
                <el-checkbox v-model="form.checked">收取请假费用</el-checkbox>
              </el-form-item>
              <el-form-item v-if="form.checked" label="请假手续费" prop="poundage">
                <el-input v-model="form.poundage" placeholder="请输入费用" type="number" :min="0" oninput="value=value.replace(/[^\d.]/g,'')" />
              </el-form-item>
              <el-form-item v-if="form.checked" label="收费方式" prop="payment_method_id">
                <el-select v-model="form.payment_method_id" placeholder="收费方式" class="dialog_w100">
                  <el-option
                    v-for="(item,index) in payment_method"
                    :key="index"
                    :label="item"
                    :value="index"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  placeholder="请填写操作备注"
                  rows="5"
                  maxlength="300"
                  show-word-limit
                />
              </el-form-item>
            </el-form>
          </div>
          <!-- 批量销假 -->
          <div v-if="operationId == 3">
            <el-form ref="formEliminate" :model="formEliminate" label-width="120px" :rules="rulesEliminate">
              <el-form-item label="销假日期" prop="end_time">
                <el-date-picker
                  v-model="formEliminate.end_time"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  :picker-options="dataProhibitSelection"
                />
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="formEliminate.remark"
                  type="textarea"
                  placeholder="请填写操作备注"
                  rows="5"
                  maxlength="300"
                  show-word-limit
                />
              </el-form-item>
            </el-form>
          </div>
          <!-- 批量修改卡余额 -->
          <div v-if="operationId == 4">
            <el-form ref="formBalance" :model="formBalance" label-width="120px" :rules="rulesBalance">
              <el-form-item label="操作类型">
                <el-radio-group v-model="change_type">
                  <el-radio :label="1">增加余额</el-radio>
                  <el-radio :label="2">扣减余额</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="变动的余额" prop="limit_change">
                <el-input v-model="formBalance.limit_change" oninput="value=value.replace(/[^\d.]/g,'')" placeholder="请输入余额" type="number" :min="0">
                  <template slot="append">{{ cardType === 0 ? '天' : cardType === 1 ? '次' : cardType === 2 ? '元' : '?' }}</template>
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <!-- 批量停卡 -->
          <div v-if="operationId == 5">
            <el-form ref="formStopCard" :model="formStopCard" label-width="120px" :rules="rulesStopCard">
              <el-form-item label="停卡开始时间" prop="start_time">
                <el-date-picker
                  v-model="formStopCard.start_time"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  :picker-options="dataProhibitSelection"
                />
              </el-form-item>
              <el-form-item label="停卡费用" prop="checked">
                <el-checkbox v-model="formStopCard.checked">收取停卡费用</el-checkbox>
              </el-form-item>
              <el-form-item v-if="formStopCard.checked" label="停卡手续费" prop="poundage">
                <el-input v-model="formStopCard.poundage" placeholder="请输入费用" type="number" :min="0" oninput="value=value.replace(/[^\d.]/g,'')" />
              </el-form-item>
              <el-form-item v-if="formStopCard.checked" label="收费方式" prop="payment_method_id">
                <el-select v-model="form.payment_method_id" placeholder="收费方式" class="dialog_w100">
                  <el-option
                    v-for="(item,index) in payment_method"
                    :key="index"
                    :label="item"
                    :value="index"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="formStopCard.remark"
                  type="textarea"
                  placeholder="请填写操作备注"
                  rows="5"
                  maxlength="300"
                  show-word-limit
                />
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer m-t-10">
      <el-button v-if="stepsActive != 1" :loading="buttonLoading" @click="handlePreviousStep">上一步</el-button>
      <el-button v-if="stepsActive != 3 && stepsActive != 1 && operationId != 2 && operationId != 6 && operationId != 7" type="primary" :disabled="valueModifyPoints.length === 0" :loading="buttonLoading" @click="handleNextStep">下一步</el-button>
      <el-button v-if="stepsActive != 3 && stepsActive != 1 && operationId == 2" type="primary" :disabled="valueModifyPoints.length === 0" :loading="buttonLoading" @click="handleCancelLeave">取消请假</el-button>
      <el-button v-if="stepsActive != 3 && stepsActive != 1 && operationId == 6" type="primary" :disabled="valueModifyPoints.length === 0" :loading="buttonLoading" @click="handleReleaseStopCard">解除停卡</el-button>
      <el-button v-if="stepsActive != 3 && stepsActive != 1 && operationId == 7" type="primary" :disabled="valueModifyPoints.length === 0" :loading="buttonLoading" @click="handleActivateBankCard">开卡</el-button>
      <el-button v-if="stepsActive == 3" :loading="buttonLoading" @click="handleCloseDialog">取 消</el-button>
      <el-button v-if="stepsActive == 3" type="primary" :loading="buttonLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { getUserMembershipCard, postLeaveAll, putCancelLeaveAll, postCancellationLeaveAll, postModifyCardBalanceAll, postStopCardAll, putActivateBankCardAll, putReleaseStopCardAll } from '@/api/store/user.js'

export default {
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 批量请假
      form: {
        ask_time: '',
        checked: false,
        poundage: '',
        payment_method_id: 0,
        remark: ''
      },
      rules: {
        ask_time: { required: true, message: '请选择请假时间', trigger: ['blur', 'change'] },
        poundage: { required: true, message: '请输入手续费', trigger: ['blur', 'change'] }
      },
      // 批量销假
      formEliminate: {
        end_time: '',
        remark: ''
      },
      rulesEliminate: {
        end_time: { required: true, message: '请输入销假日期', trigger: ['blur', 'change'] }
      },
      // 批量修改卡余额
      formBalance: {
        limit_change: ''
      },
      rulesBalance: {
        limit_change: { required: true, message: '请输入整数', trigger: ['blur', 'change'] }
      },
      // 批量停卡
      formStopCard: {
        start_time: '',
        checked: false,
        poundage: '',
        payment_method_id: 0,
        remark: ''
      },
      rulesStopCard: {
        start_time: { required: true, message: '请选择停卡时间', trigger: ['blur', 'change'] },
        poundage: { required: true, message: '请输入手续费', trigger: ['blur', 'change'] }
      },
      dialogLoading: false,
      buttonLoading: false,
      stepsActive: 1,
      tagDataList: ['批量请假', '批量取消请假', '批量销假', '批量修改卡余额', '批量停卡', '批量解除停卡', '批量开卡'],
      btnTagDataList: ['s_user_vipcard_ask', 's_user_vipcard_ask_cancels', 's_user_vipcard_wash', 's_user_vipcard_edit', 's_user_vipcard_stop', 's_user_vipcard_relieve_stop', 's_user_vipcard_open'],
      operationId: 0,
      dataModifyPoints: [],
      valueModifyPoints: [],
      change_type: 1,
      cardType: 0,
      c_diff_day: 0
    }
  },
  methods: {
    getList() {
      this.dataModifyPoints = []
      this.dialogLoading = true
      getUserMembershipCard({ limit: 999 })
        .then(res => {
          const data = res.data
          if (this.operationId === 4) {
            data.list = data.list.filter(item => {
              if (item.card) {
                return item.card.type === this.cardType
              }
            })
          }
          const dataArr = []
          data.list.forEach(item => {
            if (this.operationId === 2) {
              dataArr.push({
                key: item.id,
                label: `${item.number} - ${item.card.name.length > 8 ? item.card.name.slice(0, 8) + '...' : item.card.name}`,
                disabled: item.status_id !== 2 || item.is_leave !== 1 && this.operationId === 2
              })
            } else {
              dataArr.push({
                key: item.id,
                label: `${item.number} - ${item.card.name.length > 8 ? item.card.name.slice(0, 8) + '...' : item.card.name}`,
                disabled: (item.is_leave !== 0 && this.operationId === 1) || (item.status_id !== 3 && this.operationId === 3) || (item.status_id !== 2 && this.operationId === 5) || (item.status_id !== 8 && this.operationId === 6) || (item.status_id !== 14 && this.operationId === 7)
              })
            }
          })
          this.dataModifyPoints = dataArr
          this.dialogLoading = false
        })
    },
    // 打开的回调
    handleOpen() {},
    // 关闭的回调
    handleClose() {
      this.valueModifyPoints = []
      this.formBalance.limit_change = ''
      this.form.ask_time = ''
      this.stepsActive = 1
      this.cardType = 0
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.$emit('closeDialog')
      if (this.stepsActive === 3 && this.operationId === 1) this.resetForm('form')
      if (this.stepsActive === 3 && this.operationId === 3) this.resetForm('formEliminate')
      if (this.stepsActive === 3 && this.operationId === 4) this.resetForm('formBalance')
      if (this.stepsActive === 3 && this.operationId === 5) this.resetForm('formStopCard')
    },
    // 确定按钮
    handleConfirm() {
      if (this.operationId === 1) {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.buttonLoading = true
            const s = this.$moment(this.form.ask_time[0]).format('yyyy-MM-DD')// 开始时间
            const e = this.$moment(this.form.ask_time[1]).format('yyyy-MM-DD')// 结束时间
            const data = {
              id: this.valueModifyPoints.toString(),
              start_time: s,
              end_time: e,
              remark: this.form.remark,
              payment_method_id: this.form.payment_method_id,
              poundage: this.form.poundage
            }
            if (!this.form.checked) {
              data.payment_method_id = 0
              data.poundage = ''
            }

            postLeaveAll(data)
              .then(res => {
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                })
                this.handleCloseDialog()
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        })
      }
      if (this.operationId === 3) {
        this.$refs.formEliminate.validate(valid => {
          if (valid) {
            this.buttonLoading = true

            const data = {
              id: this.valueModifyPoints.toString(),
              ...this.formEliminate
            }

            postCancellationLeaveAll(data)
              .then(res => {
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                })
                this.handleCloseDialog()
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        })
      }
      if (this.operationId === 4) {
        this.$refs.formBalance.validate(valid => {
          if (valid) {
            this.buttonLoading = true
            const data = {
              id: this.valueModifyPoints.toString(),
              limit_change: `${this.change_type === 1 ? '' : '-'}${this.formBalance.limit_change}`,
              card_type: this.cardType
            }
            postModifyCardBalanceAll(data)
              .then(() => {
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                })
                this.handleCloseDialog()
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        })
      }
      if (this.operationId === 5) {
        this.$refs.formStopCard.validate(valid => {
          if (valid) {
            this.buttonLoading = true

            const data = {
              id: this.valueModifyPoints.toString(),
              start_time: this.formStopCard.start_time,
              payment_method_id: this.formStopCard.payment_method_id,
              poundage: this.formStopCard.poundage,
              remark: this.formStopCard.remark
            }

            if (!this.formStopCard.checked) {
              data.payment_method_id = 0
              data.poundage = ''
            }

            postStopCardAll(data)
              .then(res => {
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                })
                this.handleCloseDialog()
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        })
      }
    },
    // 上一步
    handlePreviousStep() {
      this.stepsActive--
      this.valueModifyPoints = []
    },
    // 下一步
    handleNextStep() {
      this.stepsActive++
    },
    handleOperation(val) {
      this.stepsActive += 1
      val += 1
      this.operationId = val
      this.getList()
    },
    // 清空表单
    resetForm(form) {
      this.$refs[form].resetFields()
    },
    // 取消请假
    handleCancelLeave() {
      this.$confirm('此操作将取消会员卡请假, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.buttonLoading = true
          putCancelLeaveAll({ id: this.valueModifyPoints.toString() })
            .then(res => {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.handleCloseDialog()
            })
            .finally(() => {
              this.buttonLoading = false
            })
        })
    },
    // 解除停卡
    handleReleaseStopCard() {
      this.$confirm('此操作将解除停卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.buttonLoading = true
          putReleaseStopCardAll({ id: this.valueModifyPoints })
            .then(res => {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.handleCloseDialog()
            })
            .finally(() => {
              this.buttonLoading = false
            })
        })
    },
    // 开卡
    handleActivateBankCard() {
      this.$confirm('此操作将开卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.buttonLoading = true
          putActivateBankCardAll({ id: this.valueModifyPoints.toString() })
            .then(res => {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.handleCloseDialog()
            })
            .finally(() => {
              this.buttonLoading = false
            })
        })
    },
    handleChange() {
      this.valueModifyPoints = []
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.operation_center {
  width: 762px;
  margin: 0 auto;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-tag {
  padding: 20px 20px;
  line-height: 2px;
}
::v-deep .el-transfer-panel {
  width: 300px;
}
</style>
