var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "m-t-10",
          on: { "tab-click": _vm.handleChange },
          model: {
            value: _vm.type,
            callback: function($$v) {
              _vm.type = $$v
            },
            expression: "type"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "进行中", name: "1" } }),
          _c("el-tab-pane", { attrs: { label: "未开始", name: "0" } }),
          _c("el-tab-pane", { attrs: { label: "已结束", name: "2" } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container m-t-20" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "搜索活动名称" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleFilter($event)
                    }
                  },
                  model: {
                    value: _vm.listQueryParams.query,
                    callback: function($$v) {
                      _vm.$set(_vm.listQueryParams, "query", $$v)
                    },
                    expression: "listQueryParams.query"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      loading: _vm.searchLoading
                    },
                    on: { click: _vm.handleFilter },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("s_group_booking_add"),
                  expression: "btn_access('s_group_booking_add')"
                }
              ],
              staticClass: "filter-item m-l-10",
              attrs: { type: "primary" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("新增活动")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.TableLoading,
              expression: "TableLoading"
            }
          ],
          ref: "multipleTable",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", label: "活动名称", "min-width": "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "cover", label: "活动封面", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.cover
                      ? _c("el-image", {
                          staticStyle: { width: "160px" },
                          attrs: { src: row.cover }
                        })
                      : _c("div", { staticClass: "text-danger" }, [
                          _vm._v("暂无封面图")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "价格" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.price + " 元") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "成团人数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.people_number + " 人") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "自动成团天数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.validity_day + " 天") + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "售卡方式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s("" + _vm.cardMethod[row.sales_method]) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "是否推荐", "min-width": "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.is_recommend === 0
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("否")
                        ])
                      : _vm._e(),
                    row.is_recommend === 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("是")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "活动状态", "min-width": "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status === 0
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("已停止")
                        ])
                      : _vm._e(),
                    row.status === 1
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.type === "1" ? "success" : "info"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.type === "1"
                                  ? "进行中"
                                  : _vm.type === "0"
                                  ? "未开始"
                                  : "已结束"
                              )
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "活动时间", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.start_time.slice(0, 16) +
                            " - " +
                            row.end_time.slice(0, 16)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "140px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "m-2",
                        on: {
                          click: function($event) {
                            return _vm.handleDetails(row.id)
                          }
                        }
                      },
                      [_vm._v(" 详 情 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("s_group_booking_edit"),
                            expression: "btn_access('s_group_booking_edit')"
                          }
                        ],
                        staticClass: "m-2",
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row.id)
                          }
                        }
                      },
                      [_vm._v(" 编 辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("s_group_booking_operate"),
                            expression: "btn_access('s_group_booking_operate')"
                          }
                        ],
                        staticClass: "m-2",
                        attrs: {
                          type: row.status === 1 ? "danger" : "success",
                          disabled: row.status === 1 && _vm.type === "2"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleActivity(row.id, row.status)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.status === 1 ? "停止活动" : "开启活动") +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c("detailsDialog", {
        attrs: {
          "dialog-visible-account": _vm.dialogVisibleAccount,
          "type-id": _vm.typeId
        },
        on: { roleVisible: _vm.handleRoleVisible }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }