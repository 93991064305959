import request from '@/utils/request'

// 异常上报
export function error(data) {
  return request({
    url: `/store/v1/error_report`,
    method: 'post',
    data
  })
}
