var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "toolbar" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("store_route_add"),
                  expression: "btn_access('store_route_add')"
                }
              ],
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.dialogVisible()
                }
              }
            },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.TableLoading,
              expression: "TableLoading"
            }
          ],
          ref: "multipleTable",
          staticClass: "el-table--small",
          attrs: {
            data: _vm.tableData,
            "row-key": "id",
            "tree-props": { children: "children", hasChildren: "hasChildren" }
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            select: _vm.selectChange,
            "select-all": _vm.selectAllChange
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { prop: "title", label: "菜单标题", "min-width": "180px" }
          }),
          _c("el-table-column", {
            attrs: { prop: "icon", label: "图标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("i", { class: scope.row.icon })]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "路由节点", "min-width": "180px" }
          }),
          _c("el-table-column", {
            attrs: { prop: "roles", label: "权限组", "min-width": "180px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.roles
                      ? _c(
                          "div",
                          _vm._l(scope.row.roles.split(","), function(
                            item,
                            index
                          ) {
                            return _c(
                              "el-tag",
                              { key: index, staticClass: "m-r-5" },
                              [_vm._v(_vm._s(item))]
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "path", label: "路径", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "url", label: "接口地址", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "method", label: "请求方式" }
          }),
          _c("el-table-column", { attrs: { prop: "sort", label: "排序" } }),
          _c("el-table-column", {
            attrs: { prop: "hidden", label: "菜单" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var hidden = ref.row.hidden
                  return [
                    hidden
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("否")
                        ])
                      : _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("是")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "is_show", label: "显示隐藏" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var is_show = ref.row.is_show
                  return [
                    is_show
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("显示")
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("隐藏")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-button", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btn_access("store_route_edit"),
                          expression: "btn_access('store_route_edit')"
                        }
                      ],
                      attrs: { type: "primary", icon: "el-icon-edit" },
                      on: {
                        click: function($event) {
                          return _vm.dialogVisible(scope.row.id)
                        }
                      }
                    }),
                    _c("delPopover", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btn_access("store_route_del"),
                          expression: "btn_access('store_route_del')"
                        }
                      ],
                      attrs: {
                        row: scope.row,
                        "btn-msg": "",
                        "api-data": _vm.apiData
                      },
                      on: { delete: _vm.getAccess }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog_w750",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.cancelDialog,
            visible: _vm.dialog.visible,
            title: _vm.dialog.id === null ? "添加菜单" : "编辑菜单"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级类目", prop: "parent_id" } },
                [
                  _c("SelectTree", {
                    attrs: {
                      props: _vm.defaultProps,
                      options: _vm.tableData,
                      value: _vm.form.parent_id,
                      clearable: _vm.isClearable,
                      accordion: _vm.isAccordion
                    },
                    on: {
                      getValue: function($event) {
                        return _vm.getValue($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "主菜单", prop: "hidden" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.hidden,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "hidden", $$v)
                        },
                        expression: "form.hidden"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("否")
                      ]),
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("是")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "title", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "title" },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "name", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "name" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "icon", prop: "icon" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "icon" },
                    model: {
                      value: _vm.form.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "icon", $$v)
                      },
                      expression: "form.icon"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "path", prop: "path" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "path" },
                    model: {
                      value: _vm.form.path,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "模版地址", prop: "component" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "模版地址" },
                    model: {
                      value: _vm.form.component,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "component", $$v)
                      },
                      expression: "form.component"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联路径", prop: "activeMenu" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "关联路径" },
                    model: {
                      value: _vm.form.activeMenu,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "activeMenu", $$v)
                      },
                      expression: "form.activeMenu"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "重定向地址", prop: "redirect" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "重定向地址" },
                    model: {
                      value: _vm.form.redirect,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "redirect", $$v)
                      },
                      expression: "form.redirect"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权限组", prop: "roles" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "", placeholder: "请选择" },
                      model: {
                        value: _vm.form.roles,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "roles", $$v)
                        },
                        expression: "form.roles"
                      }
                    },
                    _vm._l(_vm.rolesOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "请求方式", prop: "method" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.method,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "method", $$v)
                        },
                        expression: "form.method"
                      }
                    },
                    _vm._l(_vm.method, function(item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "后端路由地址", prop: "url" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "后端路由地址" },
                    model: {
                      value: _vm.form.url,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示隐藏", prop: "is_show" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.is_show,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "is_show", $$v)
                        },
                        expression: "form.is_show"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("显示")
                      ]),
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("隐藏")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "根菜单", prop: "is_root" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.is_root,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "is_root", $$v)
                        },
                        expression: "form.is_root"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("是")
                      ]),
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("否")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "菜单排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 0,
                      max: 999,
                      "controls-position": "right",
                      placeholder: "菜单排序"
                    },
                    model: {
                      value: _vm.form.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sort", _vm._n($$v))
                      },
                      expression: "form.sort"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.cancelDialog } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }