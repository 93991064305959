<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="持卡会员手机号" prop="phone">
        <el-input v-model="form.phone" disabled>
          <div slot="prepend">
            <el-select v-model="form.area_code" filterable placeholder="请选择" style="min-width:84px;" disabled>
              <el-option-group
                v-for="(group,index) in areaCodeList"
                :key="index"
                :label="group.label"
                class="min-width:200px"
              >
                <el-option
                  v-for="(item,indexs) in group.options"
                  :key="indexs"
                  :label="item.value"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;padding-left:100px">{{ item.value }}</span>
                </el-option>
              </el-option-group>
            </el-select>
          </div>
          <template slot="append">
            <el-button :loading="isLoading" type="primary" :class="{disabled:sendCoding}" @click="sendCode('shift')">{{ getCodeText }}</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="verify_code">
        <el-input v-model="form.verify_code" type="number" />
      </el-form-item>
      <div>
        <div class="tips"><span>转让人信息：</span>{{ form.name }}    |    {{ form.phone }}    |    {{ form.sex }}</div>
        <div>
          <!-- <el-form-item label="收卡人" prop="user_id">
            <el-select
              v-model="form.user_id"
              placeholder="请选择收卡人"
              filterable
              clearable
              class="dialog_w100"
              @change="shiftUserChange"
            >
              <el-option
                v-for="item in user_list"
                :key="item.id"
                :label="getUserNames(item)"
                :value="item.id"
              />
            </el-select>
          </el-form-item> -->
          <!-- 持卡会员 -->
          <member :title-s="title" @memberId="memberId" />
        </div>
        <div v-if="form.user_id" class="tips"><span>收卡人信息：</span>{{ getUserNames(form.shift_user_info) }}    |    {{ form.shift_user_info.phone }}    |    {{ formatTableSex(form.shift_user_info) }}</div>
        <el-form-item label="手续费" prop="checked">
          <el-checkbox v-model="form.checked">收取转让手续费</el-checkbox>
        </el-form-item>
        <el-form-item v-if="form.checked" label="转让手续费" prop="poundage">
          <el-input v-model="form.poundage" placeholder="请输入手续费" type="number" />
        </el-form-item>
        <el-form-item v-if="form.checked" label="收费方式" prop="payment_method_id">
          <el-select v-model="form.payment_method_id" placeholder="收费方式" class="dialog_w100">
            <el-option
              v-for="(item,index) in payment_method"
              :key="index"
              :label="item"
              :value="index"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="请填写操作备注"
            rows="5"
            maxlength="300"
            show-word-limit
          />
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button @click="$emit('back', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { shiftUserMembershipCard, SendCode, getUserDetails, getUser } from '@/api/store/user.js'
import areaCode from '@/components/commonData/area_code.json'
import { deepClone } from '@/utils'
import member from '@/components/elSelect/member.vue'
export default {
  name: 'CardShift',
  components: { member },
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      form: {
        phone: '',
        area_code: '86',
        verify_code: '',
        user_id: '',
        poundage: '',
        payment_method_id: 0,
        remark: '',
        checked: false,
        shift_user_info: {}
      },
      rules: {
        verify_code: { required: true, message: '请输入验证码', trigger: ['blur', 'change'] },
        poundage: [
          { validator: (rule, value, callback) => {
            if (this.form.checked && (this.form.poundage === '' || this.form.poundage < 0)) {
              callback(new Error('请填写正数'))
            }
            callback()
          }, trigger: ['blur', 'change']
          }
        ],
        user_id: { required: true, message: '请选择转让人', trigger: ['blur', 'change'] }
      },
      areaCodeList: areaCode,
      getCodeText: '获取验证码',
      sendCoding: false,
      isLoading: false,
      smsTimes: 60,
      dtime: '',
      user_list: [],
      title: '收卡人'
    }
  },
  created() {
    if (this.cardData.user) {
      this.form.name = this.getUserNames(this.cardData.user)
      this.form.sex = this.formatTableSex(this.cardData.user)
      this.form.phone = this.cardData.user.phone
      this.form.area_code = this.cardData.user.area_code
    } else {
      this.$message.error('当前此卡会员信息异常')
      return false
    }
    if (this.utils.empty(this.user_list)) {
      // 获取持卡会员列表
      getUser({ limit: 999 }).then(res => {
        this.user_list = res.data.list
      }).catch(() => {})
    }
  },
  methods: {
    // 转卡操作
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const data = deepClone(this.form)
          if (!this.form.checked) {
            data.payment_method_id = 0
            data.poundage = ''
          }
          shiftUserMembershipCard(this.cardData.id, data).then(res => {
            this.sendCodeCancel()
            this.$emit('callback', res.msg)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 收卡人切换
    shiftUserChange(user_id) {
      if (!user_id) {
        return false
      }
      getUserDetails(user_id).then(res => {
        this.form.shift_user_info = res.data
      })
    },
    // 验证码完毕
    sendCodeCancel() {
      this.sendCoding = false
      this.isLoading = false
      clearInterval(this.dtime)
      this.getCodeText = '获取验证码'
    },
    sendCode() {
      if (!this.sendCoding) {
        let data = {}
        data = {
          phone: this.form.phone,
          area_code: this.form.area_code.replace(/[^0-9]/ig, ''),
          scene: (this.form.area_code === '86') ? '' : 'internation'
        }

        SendCode(data).then(res => {
          this.sendCoding = true
          this.isLoading = true
          this.$message({
            type: 'success',
            message: '验证码已发送'
          })
          // 开始计时
          var stime = this.smsTimes
          this.dtime = setInterval(() => {
            stime--
            if (stime === 0) {
              this.sendCodeCancel()
            } else {
              this.getCodeText = stime + ' 秒后重试'
            }
          }, 1000)
        })
      }
    },
    memberId(val) {
      this.form.user_id = val
    }
  }

}
</script>

<style lang="scss" scoped>
.tips{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 15px;
    border: 1px dashed #f49352;
    color: #f49352;
    min-height: 25px;
    line-height: 25px;
}
</style>
