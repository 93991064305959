<template>
  <div>
    <el-card
      v-loading="cardLoading"
      shadow="never"
      style="border:0"
      body-style="background-color: #f3f3f3;padding: 0 0;"
    >
      <el-form v-if="ruleForm" ref="ruleForm" :model="ruleForm" :rules="rules" size="small" label-width="20%">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- 头部导航栏 -->
          <el-tab-pane :label="form.type === 1 ? '非活动海报' : '营销活动海报'" name="first">
            <!-- 内容 -->
            <div style="display: flex;">
              <div class="mobile-box">
                <div id="posterView" class="bg-box">
                  <!-- <div class="bg-pic" :style="{ 'background-image': 'url(' + ruleForm.share.bg_pic.url + ')' }" />-->
                  <img class="img-box" :src="ruleForm.share.bg_pic.url">
                  <!-- 头像 -->
                  <el-image
                    v-if="ruleForm.share.head.is_show == 1"
                    radius="50%"
                    :style="{
                      position: 'absolute',
                      top: ruleForm.share.head.top + unit,
                      left: ruleForm.share.head.left + unit,
                      width: ruleForm.share.head.size + unit,
                      height: ruleForm.share.head.size + unit
                    }"
                    :class="
                      ruleForm.share.head.align === 'left'
                        ? 'display-left'
                        : ruleForm.share.head.align === 'right'
                          ? 'display-right'
                          : ruleForm.share.head.align === 'center'
                            ? 'display-center'
                            : ''
                    "
                    :src="poster.default_head"
                  />
                  <!-- 水印图片 -->
                  <el-image
                    v-if="ruleForm.share.xj_logo.is_show == 1"
                    :style="{
                      position: 'absolute',
                      top: ruleForm.share.xj_logo.top + unit,
                      left: ruleForm.share.xj_logo.left + unit,
                      width: ruleForm.share.xj_logo.size + unit
                    }"
                    :src="ruleForm.share.xj_logo.src"
                  />
                  <!-- 二维码 -->
                  <canvas
                    v-if="ruleForm.share.qr_code.is_show == 1"
                    ref="qrcode_box"
                    :style="{
                      position: 'absolute',
                      top: ruleForm.share.qr_code.top + unit,
                      left: ruleForm.share.qr_code.left + unit,
                      width: ruleForm.share.qr_code.size + unit,
                      height: ruleForm.share.qr_code.size + unit,
                      borderRadius: ruleForm.share.qr_code.type == 1 ? '50%' : '0%'
                    }"
                    :class="
                      ruleForm.share.qr_code.align === 'left'
                        ? 'display-left'
                        : ruleForm.share.qr_code.align === 'right'
                          ? 'display-right'
                          : ruleForm.share.qr_code.align === 'center'
                            ? 'display-center'
                            : ''
                    "
                  />
                  <!-- 描述词 -->
                  <span
                    v-if="ruleForm.share.desc.is_show == 1"
                    :style="{
                      width: ruleForm.share.desc.width + unit,
                      wordWrap: 'break-word',
                      wordBreak: 'normal',
                      position: 'absolute',
                      top: ruleForm.share.desc.top + unit,
                      left: ruleForm.share.desc.left + unit,
                      fontSize: ruleForm.share.desc.font * 2 + unit,
                      color: ruleForm.share.desc.color
                    }"
                    :class="
                      ruleForm.share.desc.align === 'left'
                        ? 'display-left text-left'
                        : ruleForm.share.desc.align === 'right'
                          ? 'display-right text-right'
                          : ruleForm.share.desc.align === 'center'
                            ? 'display-center text-centers'
                            : ''
                    "
                  >
                    {{ ruleForm.share.desc.text }}
                  </span>
                  <!-- 活动名称 -->
                  <span
                    v-if="ruleForm.share.activity_name.is_show == 1"
                    :style="{
                      width: ruleForm.share.activity_name.width + unit,
                      wordWrap: 'break-word',
                      wordBreak: 'normal',
                      position: 'absolute',
                      top: ruleForm.share.activity_name.top + unit,
                      left: ruleForm.share.activity_name.left + unit,
                      fontSize: ruleForm.share.activity_name.font * 2 + unit,
                      color: ruleForm.share.activity_name.color
                    }"
                    :class="
                      ruleForm.share.activity_name.align === 'left'
                        ? 'display-left text-left'
                        : ruleForm.share.activity_name.align === 'right'
                          ? 'display-right text-right'
                          : ruleForm.share.activity_name.align === 'center'
                            ? 'display-center text-centers'
                            : ''
                    "
                  >
                    {{ ruleForm.share.activity_name.text }}
                  </span>
                  <!-- 原价 -->
                  <span
                    v-if="ruleForm.share.original_price.is_show == 1"
                    :style="{
                      width: ruleForm.share.original_price.width + unit,
                      wordWrap: 'break-word',
                      wordBreak: 'normal',
                      position: 'absolute',
                      top: ruleForm.share.original_price.top + unit,
                      left: ruleForm.share.original_price.left + unit,
                      fontSize: ruleForm.share.original_price.font * 2 + unit,
                      color: ruleForm.share.original_price.color,
                      textDecoration: ruleForm.share.activity_price.is_show == 1 ? 'line-through' : 'none'
                    }"
                    :class="
                      ruleForm.share.original_price.align === 'left'
                        ? 'display-left text-left'
                        : ruleForm.share.original_price.align === 'right'
                          ? 'display-right text-right'
                          : ruleForm.share.original_price.align === 'center'
                            ? 'display-center text-centers'
                            : ''
                    "
                  >
                    {{ ruleForm.share.original_price.text }}
                  </span>
                  <!-- 活动价格 -->
                  <span
                    v-if="ruleForm.share.activity_price.is_show == 1"
                    :style="{
                      width: ruleForm.share.activity_price.width + unit,
                      wordWrap: 'break-word',
                      wordBreak: 'normal',
                      position: 'absolute',
                      top: ruleForm.share.activity_price.top + unit,
                      left: ruleForm.share.activity_price.left + unit,
                      fontSize: ruleForm.share.activity_price.font * 2 + unit,
                      color: ruleForm.share.activity_price.color
                    }"
                    :class="
                      ruleForm.share.activity_price.align === 'left'
                        ? 'display-left text-left'
                        : ruleForm.share.activity_price.align === 'right'
                          ? 'display-right text-right'
                          : ruleForm.share.activity_price.align === 'center'
                            ? 'display-center text-centers'
                            : ''
                    "
                  >
                    {{ ruleForm.share.activity_price.text }}
                  </span>
                  <!-- 活动价格 -->
                  <span
                    v-if="ruleForm.share.activity_price.is_show == 1"
                    :style="{
                      width: ruleForm.share.activity_price.width + unit,
                      wordWrap: 'break-word',
                      wordBreak: 'normal',
                      position: 'absolute',
                      top: ruleForm.share.activity_price.top + unit,
                      left: ruleForm.share.activity_price.left + unit,
                      fontSize: ruleForm.share.activity_price.font * 2 + unit,
                      color: ruleForm.share.activity_price.color
                    }"
                    :class="
                      ruleForm.share.activity_price.align === 'left'
                        ? 'display-left text-left'
                        : ruleForm.share.activity_price.align === 'right'
                          ? 'display-right text-right'
                          : ruleForm.share.activity_price.align === 'center'
                            ? 'display-center text-centers'
                            : ''
                    "
                  >
                    {{ ruleForm.share.activity_price.text }}
                  </span>
                  <!-- 活动时间 -->
                  <span
                    v-if="ruleForm.share.activity_time.is_show == 1"
                    :style="{
                      width: ruleForm.share.activity_time.width + unit,
                      wordWrap: 'break-word',
                      wordBreak: 'normal',
                      position: 'absolute',
                      top: ruleForm.share.activity_time.top + unit,
                      left: ruleForm.share.activity_time.left + unit,
                      fontSize: ruleForm.share.activity_time.font * 2 + unit,
                      color: ruleForm.share.activity_time.color
                    }"
                    :class="
                      ruleForm.share.activity_time.align === 'left'
                        ? 'display-left text-left'
                        : ruleForm.share.activity_time.align === 'right'
                          ? 'display-right text-right'
                          : ruleForm.share.activity_time.align === 'center'
                            ? 'display-center text-centers'
                            : ''
                    "
                  >
                    {{ ruleForm.share.activity_time.text }}
                  </span>
                  <!-- 扫码开抢 -->
                  <span
                    v-if="ruleForm.share.activity_text1.is_show == 1"
                    :style="{
                      width: ruleForm.share.activity_text1.width + unit,
                      wordWrap: 'break-word',
                      wordBreak: 'normal',
                      position: 'absolute',
                      top: ruleForm.share.activity_text1.top + unit,
                      left: ruleForm.share.activity_text1.left + unit,
                      fontSize: ruleForm.share.activity_text1.font * 2 + unit,
                      color: ruleForm.share.activity_text1.color
                    }"
                    :class="
                      ruleForm.share.activity_text1.align === 'left'
                        ? 'display-left text-left'
                        : ruleForm.share.activity_text1.align === 'right'
                          ? 'display-right text-right'
                          : ruleForm.share.activity_text1.align === 'center'
                            ? 'display-center text-centers'
                            : ''
                    "
                  >
                    {{ ruleForm.share.activity_text1.text }}
                  </span>
                  <!-- 联系电话 -->
                  <span
                    v-if="ruleForm.share.call_text.is_show == 1"
                    :style="{
                      width: ruleForm.share.call_text.width + unit,
                      wordWrap: 'break-word',
                      wordBreak: 'normal',
                      position: 'absolute',
                      top: ruleForm.share.call_text.top + unit,
                      left: ruleForm.share.call_text.left + unit,
                      fontSize: ruleForm.share.call_text.font * 2 + unit,
                      color: ruleForm.share.call_text.color
                    }"
                    :class="
                      ruleForm.share.call_text.align === 'left'
                        ? 'display-left text-left'
                        : ruleForm.share.call_text.align === 'right'
                          ? 'display-right text-right'
                          : ruleForm.share.call_text.align === 'center'
                            ? 'display-center text-centers'
                            : ''
                    "
                  >
                    {{ ruleForm.share.call_text.text }}
                  </span>
                  <!-- 联系地址 -->
                  <span
                    v-if="ruleForm.share.address_text.is_show == 1"
                    :style="{
                      width: ruleForm.share.address_text.width + unit,
                      wordWrap: 'break-word',
                      wordBreak: 'normal',
                      position: 'absolute',
                      top: ruleForm.share.address_text.top + unit,
                      left: ruleForm.share.address_text.left + unit,
                      fontSize: ruleForm.share.address_text.font * 2 + unit,
                      color: ruleForm.share.address_text.color
                    }"
                    :class="
                      ruleForm.share.address_text.align === 'left'
                        ? 'display-left text-left'
                        : ruleForm.share.address_text.align === 'right'
                          ? 'display-right text-right'
                          : ruleForm.share.address_text.align === 'center'
                            ? 'display-center text-centers'
                            : ''
                    "
                  >
                    {{ ruleForm.share.address_text.text }}
                  </span>
                  <!-- 昵称设置 -->
                  <span
                    v-if="ruleForm.share.name.is_show == 1"
                    :style="{
                      position: 'absolute',
                      top: ruleForm.share.name.top + unit,
                      left: ruleForm.share.name.left + unit,
                      fontSize: ruleForm.share.name.font * 2 + unit,
                      color: ruleForm.share.name.color,
                      textAlign: 'center'
                    }"
                    :class="
                      ruleForm.share.name.align === 'left'
                        ? 'display-left'
                        : ruleForm.share.name.align === 'right'
                          ? 'display-right'
                          : ruleForm.share.name.align === 'center'
                            ? 'display-center'
                            : ''
                    "
                  >
                    瑜小九体验馆
                  </span>
                  <!-- 分割线 -->
                  <div
                    v-if="ruleForm.share.line.is_show == 1"
                    :style="{
                      position: 'absolute',
                      backgroundColor: ruleForm.share.line.color,
                      width: ruleForm.share.line.width + unit,
                      height: ruleForm.share.line.height + unit,
                      top: ruleForm.share.line.top + unit,
                      left: ruleForm.share.line.left + unit
                    }"
                    :class="
                      ruleForm.share.line.align === 'left'
                        ? 'display-left'
                        : ruleForm.share.line.align === 'right'
                          ? 'display-right'
                          : ruleForm.share.line.align === 'center'
                            ? 'display-center'
                            : ''
                    "
                  />
                </div>
              </div>
              <div v-if="ruleForm.share.bg_pic.url" class="form-body">
                <div class="p-y-20">
                  <el-form ref="form" :rules="rules" :inline="true" :model="form" label-width="82px" label-suffix=":">
                    <el-form-item label="海报名称" prop="name">
                      <el-input v-model="form.name" />
                    </el-form-item>
                    <el-form-item label="海报分类">
                      <SelectTree
                        :props="defaultProps"
                        :options="categoryList"
                        :value="form.category_id"
                        :clearable="false"
                        :accordion="false"
                        :root-name="'顶级分类'"
                        @getValue="getCategoryValue($event)"
                      />
                    </el-form-item>
                    <el-form-item label="海报类型">
                      <el-select v-model="form.type" placeholder="请选择活动区域" @change="handleOption">
                        <el-option v-for="(item, index) in typeList" :key="index" :label="item" :value="index" />
                      </el-select>
                    </el-form-item>
                    <el-form-item label="排序">
                      <el-input v-model="form.weigh" type="number" />
                    </el-form-item>
                    <el-form-item label="隐藏">
                      <el-switch v-model="form.is_hidden" />
                    </el-form-item>
                  </el-form>
                </div>
                <div>
                  <el-upload
                    class="avatar-uploader"
                    :action="adminUpload"
                    :show-file-list="false"
                    :on-success="uploadBg"
                    :on-change="handleChangeFile"
                    :headers="utils.upload_headers()"
                    accept=".jpg,.jpeg,.png"
                    :on-progress="upProgress"
                  >
                    <el-tooltip class="item" effect="dark" content="建议尺寸:750 * 1334" placement="top">
                      <el-button size="mini">{{ ruleForm.share.bg_pic.url ? '更换背景图' : '添加背景图' }}</el-button>
                    </el-tooltip>
                  </el-upload>
                </div>
                <div class="flex flex-wrap">
                  <div
                    v-for="(item, index) in shareComponent"
                    :key="index"
                    class="component-item flex row-center col-center flex-wrap"
                    :class="shareComponentKey == item.key ? 'active' : ''"
                    style="flex-flow: column;"
                    @click="componentItemClick(index)"
                  >
                    <img class="component-item-img" :src="item.icon_url">
                    <div class="m-t-5">{{ item.title }}</div>
                    <img
                      v-if="test(index)"
                      class="component-item-remove"
                      :src="poster.icon_delete"
                      @click.stop="componentItemRemove(index)"
                    >
                  </div>
                </div>
                <el-card shadow="never" class="box-card" style="width: 100%">
                  <div slot="header">
                    <span v-if="shareComponentKey == 'head'">头像设置</span>
                    <span v-if="shareComponentKey == 'name'">昵称设置</span>
                    <span v-if="shareComponentKey == 'qr_code'">二维码设置</span>
                    <span v-if="shareComponentKey == 'desc'">描述词设置</span>
                    <span v-if="shareComponentKey == 'activity_name'">活动名称</span>
                    <span v-if="shareComponentKey == 'original_price'">原价</span>
                    <span v-if="shareComponentKey == 'activity_price'">活动价格</span>
                    <span v-if="shareComponentKey == 'activity_time'">活动时间</span>
                    <span v-if="shareComponentKey == 'activity_text1'">扫码开抢</span>
                    <span v-if="shareComponentKey == 'call_text'">联系电话</span>
                    <span v-if="shareComponentKey == 'address_text'">联系地址</span>
                    <span v-if="shareComponentKey == 'line'">分割线设置</span>
                    <span v-if="shareComponentKey == 'xj_logo'">水印设置</span>
                  </div>
                  <div>
                    <!-- 头像设置 -->
                    <template v-if="shareComponentKey == 'head'">
                      <!-- <el-form-item label="样式">
                        <el-radio v-model="ruleForm.share.head.type" :label="1">圆形</el-radio>
                        <el-radio v-model="ruleForm.share.head.type" :label="2">方形</el-radio>
                      </el-form-item> -->
                      <el-form-item label="大小">
                        <el-slider v-model="ruleForm.share.head.size" :min="40" :max="300" show-input />
                      </el-form-item>
                      <el-form-item label="上间距">
                        <el-slider
                          v-model="ruleForm.share.head.top"
                          :min="0"
                          :max="ruleForm.share.divHeight - ruleForm.share.head.size"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="左间距">
                        <el-slider
                          v-model="ruleForm.share.head.left"
                          :disabled="
                            ruleForm.share.head.align === 'left' ||
                              ruleForm.share.head.align === 'center' ||
                              ruleForm.share.head.align === 'right'
                              ? true
                              : ruleForm.share.head.align === 'none'
                                ? false
                                : ''
                          "
                          :min="0"
                          :max="divWidth - ruleForm.share.head.size"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="对齐方式">
                        <el-radio v-model="ruleForm.share.head.align" label="left">左对齐</el-radio>
                        <el-radio v-model="ruleForm.share.head.align" label="center">居中对齐</el-radio>
                        <el-radio v-model="ruleForm.share.head.align" label="right">右对齐</el-radio>
                        <el-radio v-model="ruleForm.share.head.align" label="none">自定义</el-radio>
                      </el-form-item>
                    </template>
                    <!-- 昵称设置 -->
                    <template v-else-if="shareComponentKey == 'name'">
                      <el-form-item label="大小">
                        <el-slider v-model="ruleForm.share.name.font" :min="12" :max="40" show-input />
                      </el-form-item>
                      <el-form-item label="上间距">
                        <el-slider
                          v-model="ruleForm.share.name.top"
                          :min="0"
                          :max="ruleForm.share.divHeight - ruleForm.share.name.font"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="左间距">
                        <el-slider
                          v-model="ruleForm.share.name.left"
                          :disabled="
                            ruleForm.share.name.align === 'left' ||
                              ruleForm.share.name.align === 'center' ||
                              ruleForm.share.name.align === 'right'
                              ? true
                              : ruleForm.share.name.align === 'none'
                                ? false
                                : ''
                          "
                          :min="0"
                          :max="divWidth - ruleForm.share.name.font"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="颜色">
                        <el-color-picker
                          v-model="ruleForm.share.name.color"
                          style="margin-left: 20px;"
                          color-format="rgb"
                          :predefine="predefineColors"
                        />
                      </el-form-item>
                      <el-form-item label="对齐方式">
                        <el-radio v-model="ruleForm.share.name.align" label="left">左对齐</el-radio>
                        <el-radio v-model="ruleForm.share.name.align" label="center">居中对齐</el-radio>
                        <el-radio v-model="ruleForm.share.name.align" label="right">右对齐</el-radio>
                        <el-radio v-model="ruleForm.share.name.align" label="none">自定义</el-radio>
                      </el-form-item>
                    </template>
                    <!-- 二维码设置 -->
                    <template v-else-if="shareComponentKey == 'qr_code'">
                      <el-form-item label="样式">
                        <el-radio v-model="ruleForm.share.qr_code.type" :label="1">圆形</el-radio>
                        <el-radio v-model="ruleForm.share.qr_code.type" :label="2">方形</el-radio>
                      </el-form-item>
                      <el-form-item label="二维码内容">
                        <el-input v-model="ruleForm.share.qr_code.text" @input="qrcodeChange" />
                      </el-form-item>
                      <el-form-item label="大小">
                        <el-slider v-model="ruleForm.share.qr_code.size" :min="80" :max="divWidth" show-input />
                      </el-form-item>
                      <el-form-item label="上间距">
                        <el-slider
                          v-model="ruleForm.share.qr_code.top"
                          :min="0"
                          :max="ruleForm.share.divHeight - ruleForm.share.qr_code.size"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="左间距">
                        <el-slider
                          v-model="ruleForm.share.qr_code.left"
                          :disabled="
                            ruleForm.share.qr_code.align === 'left' ||
                              ruleForm.share.qr_code.align === 'center' ||
                              ruleForm.share.qr_code.align === 'right'
                              ? true
                              : ruleForm.share.qr_code.align === 'none'
                                ? false
                                : ''
                          "
                          :min="0"
                          :max="divWidth - ruleForm.share.qr_code.size"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="颜色">
                        <el-color-picker
                          v-model="ruleForm.share.qr_code.color"
                          style="margin-left: 20px;"
                          color-format="hex"
                          :predefine="predefineColors"
                          @change="qrcodeChange"
                        />
                      </el-form-item>
                      <el-form-item label="背景颜色">
                        <el-color-picker
                          v-model="ruleForm.share.qr_code.light_color"
                          style="margin-left: 20px;"
                          color-format="hex"
                          :predefine="predefineColors"
                          @change="qrcodeChange"
                        />
                      </el-form-item>
                      <el-form-item label="开启透明背景">
                        <el-switch
                          v-model="ruleForm.share.qr_code.transparency"
                          active-value="1"
                          inactive-value="0"
                          @change="qrcodeChange('带你开启美丽生活')"
                        />
                      </el-form-item>
                      <el-form-item label="对齐方式">
                        <el-radio v-model="ruleForm.share.qr_code.align" label="left">左对齐</el-radio>
                        <el-radio v-model="ruleForm.share.qr_code.align" label="center">居中对齐</el-radio>
                        <el-radio v-model="ruleForm.share.qr_code.align" label="right">右对齐</el-radio>
                        <el-radio v-model="ruleForm.share.qr_code.align" label="none">自定义</el-radio>
                      </el-form-item>
                    </template>
                    <!-- 活动描述 -->
                    <template v-else-if="shareComponentKey == 'desc'">
                      <el-form-item label="文本内容">
                        <el-input v-model="ruleForm.share.desc.text" />
                      </el-form-item>
                      <el-form-item label="大小">
                        <el-slider v-model="ruleForm.share.desc.font" :min="12" :max="200" show-input />
                      </el-form-item>
                      <el-form-item label="文本宽度">
                        <el-slider v-model="ruleForm.share.desc.width" :min="30" :max="divWidth" show-input />
                      </el-form-item>
                      <el-form-item label="上间距">
                        <el-slider
                          v-model="ruleForm.share.desc.top"
                          :min="0"
                          :max="ruleForm.share.divHeight - ruleForm.share.desc.font"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="左间距">
                        <el-slider
                          v-model="ruleForm.share.desc.left"
                          :disabled="
                            ruleForm.share.desc.align === 'left' ||
                              ruleForm.share.desc.align === 'center' ||
                              ruleForm.share.desc.align === 'right'
                              ? true
                              : ruleForm.share.desc.align === 'none'
                                ? false
                                : ''
                          "
                          :min="0"
                          :max="divWidth - ruleForm.share.desc.font"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="颜色">
                        <el-color-picker
                          v-model="ruleForm.share.desc.color"
                          style="margin-left: 20px;"
                          color-format="rgb"
                          :predefine="predefineColors"
                        />
                      </el-form-item>
                      <el-form-item label="对齐方式">
                        <el-radio v-model="ruleForm.share.desc.align" label="left">左对齐</el-radio>
                        <el-radio v-model="ruleForm.share.desc.align" label="center">居中对齐</el-radio>
                        <el-radio v-model="ruleForm.share.desc.align" label="right">右对齐</el-radio>
                        <el-radio v-model="ruleForm.share.desc.align" label="none">自定义</el-radio>
                      </el-form-item>
                    </template>
                    <!-- 文本框设置 -->
                    <template
                      v-else-if="
                        ruleForm['share'][shareComponentKey] &&
                          ruleForm['share'][shareComponentKey]['file_type'] == 'text'
                      "
                    >
                      <el-form-item label="文本内容">
                        <el-input v-model="ruleForm.share[shareComponentKey].text" />
                      </el-form-item>
                      <el-form-item label="大小">
                        <el-slider v-model="ruleForm.share[shareComponentKey].font" :min="12" :max="200" show-input />
                      </el-form-item>
                      <el-form-item label="文本宽度">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].width"
                          :min="30"
                          :max="divWidth"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="上间距">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].top"
                          :min="0"
                          :max="ruleForm.share.divHeight - ruleForm.share[shareComponentKey].font"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="左间距">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].left"
                          :disabled="
                            ruleForm.share[shareComponentKey].align === 'left' ||
                              ruleForm.share[shareComponentKey].align === 'center' ||
                              ruleForm.share[shareComponentKey].align === 'right'
                              ? true
                              : ruleForm.share[shareComponentKey].align === 'none'
                                ? false
                                : ''
                          "
                          :min="0"
                          :max="divWidth - ruleForm.share[shareComponentKey].font"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="颜色">
                        <el-color-picker
                          v-model="ruleForm.share[shareComponentKey].color"
                          style="margin-left: 20px;"
                          color-format="rgb"
                          :predefine="predefineColors"
                        />
                      </el-form-item>
                      <el-form-item label="对齐方式">
                        <el-radio v-model="ruleForm.share[shareComponentKey].align" label="left">左对齐</el-radio>
                        <el-radio v-model="ruleForm.share[shareComponentKey].align" label="center">居中对齐</el-radio>
                        <el-radio v-model="ruleForm.share[shareComponentKey].align" label="right">右对齐</el-radio>
                        <el-radio v-model="ruleForm.share[shareComponentKey].align" label="none">自定义</el-radio>
                      </el-form-item>
                    </template>
                    <!-- 分割线 -->
                    <template
                      v-else-if="
                        ruleForm['share'][shareComponentKey] &&
                          ruleForm['share'][shareComponentKey]['file_type'] == 'line'
                      "
                    >
                      <el-form-item label="宽度">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].width"
                          :min="1"
                          :max="divWidth"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="高度">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].height"
                          :min="1"
                          :max="ruleForm.share.divHeight"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="上间距">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].top"
                          :min="0"
                          :max="ruleForm.share.divHeight - ruleForm.share[shareComponentKey].height"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="左间距">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].left"
                          :disabled="
                            ruleForm.share[shareComponentKey].align === 'left' ||
                              ruleForm.share[shareComponentKey].align === 'center' ||
                              ruleForm.share[shareComponentKey].align === 'right'
                              ? true
                              : ruleForm.share[shareComponentKey].align === 'none'
                                ? false
                                : ''
                          "
                          :min="0"
                          :max="divWidth - ruleForm.share[shareComponentKey].width"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="颜色">
                        <el-color-picker
                          v-model="ruleForm.share[shareComponentKey].color"
                          style="margin-left: 20px;"
                          color-format="rgb"
                          :predefine="predefineColors"
                        />
                      </el-form-item>
                      <el-form-item label="对齐方式">
                        <el-radio v-model="ruleForm.share[shareComponentKey].align" label="left">左对齐</el-radio>
                        <el-radio v-model="ruleForm.share[shareComponentKey].align" label="center">居中对齐</el-radio>
                        <el-radio v-model="ruleForm.share[shareComponentKey].align" label="right">右对齐</el-radio>
                        <el-radio v-model="ruleForm.share[shareComponentKey].align" label="none">自定义</el-radio>
                      </el-form-item>
                    </template>
                    <!-- 图片 -->
                    <template
                      v-else-if="
                        ruleForm['share'][shareComponentKey] &&
                          ruleForm['share'][shareComponentKey]['file_type'] == 'image'
                      "
                    >
                      <el-form-item label="图片地址">
                        <el-input v-model="ruleForm.share[shareComponentKey].src">
                          <template slot="append">
                            <el-upload
                              class="upload-demo"
                              :action="adminUpload"
                              :before-remove="beforeRemove"
                              :on-exceed="handleExceed"
                              :on-success="upSuccess"
                              :on-progress="upProgress"
                              :headers="utils.upload_headers()"
                              :show-file-list="false"
                            >
                              <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                          </template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="大小">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].size"
                          :min="80"
                          :max="divWidth"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="上间距">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].top"
                          :min="0"
                          :max="ruleForm.share.divHeight"
                          show-input
                        />
                      </el-form-item>
                      <el-form-item label="左间距">
                        <el-slider
                          v-model="ruleForm.share[shareComponentKey].left"
                          :min="0"
                          :max="divWidth - ruleForm.share[shareComponentKey].size"
                          show-input
                        />
                      </el-form-item>
                    </template>
                  </div>
                </el-card>
                <div class="p-y-20">
                  <el-button
                    class="button-item"
                    :loading="btnLoading"
                    type="success"
                    size="small"
                    @click="save('form')"
                  >
                    保存
                  </el-button>
                  <el-button
                    class="button-item"
                    :loading="btnLoading"
                    type="warning"
                    size="small"
                    @click="buildPreview(1)"
                  >
                    预览
                  </el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>
    <el-dialog title="预览海报" :visible.sync="downImg" width="30%" center>
      <div class="text-center">
        <el-image ref="preview" style="width: 260px" :src="viewUrl" fit="scale-down" :preview-src-list="[viewUrl]" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$refs.preview.clickHandler()">预 览</el-button>
        <el-button type="primary" @click="imgDown">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import SelectTree from '@/components/TreeSelect/treeSelect.vue'
import { getInfo, Edit } from '@/api/admin/p_template.js'
import { getSortType } from '@/api/admin/category.js'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
export default {
  components: {
    SelectTree
  },
  data() {
    return {
      unit: 'px',
      form: {
        name: '',
        is_hidden: 0,
        type: 0,
        weigh: 0,
        category_id: 0
      },
      ruleForm: null,
      shareComponent: [
        {
          key: 'head',
          icon_url: require('@/assets/images/poster/icon_head.png'),
          title: '头像',
          is_active: true
        },
        {
          key: 'name',
          icon_url: require('@/assets/images/poster/icon_name.png'),
          title: '场馆名称',
          is_active: true
        },
        {
          key: 'desc',
          icon_url: require('@/assets/images/poster/icon_desc.png'),
          title: '描述词',
          is_active: true
        },
        {
          key: 'activity_name',
          icon_url: require('@/assets/images/poster/icon_activity.png'),
          title: '活动名称',
          is_active: true
        },
        {
          key: 'original_price',
          icon_url: require('@/assets/images/poster/icon_money.png'),
          title: '原价',
          is_active: true
        },
        {
          key: 'activity_price',
          icon_url: require('@/assets/images/poster/icon_money.png'),
          title: '活动价格',
          is_active: true
        },
        {
          key: 'activity_time',
          icon_url: require('@/assets/images/poster/icon_time.png'),
          title: '活动时间',
          is_active: true
        },
        {
          key: 'activity_text1',
          icon_url: require('@/assets/images/poster/icon_scan.png'),
          title: '扫码开抢',
          is_active: true
        },
        {
          key: 'call_text',
          icon_url: require('@/assets/images/poster/icon_phone.png'),
          title: '联系电话',
          is_active: true
        },
        {
          key: 'address_text',
          icon_url: require('@/assets/images/poster/icon_address.png'),
          title: '地址',
          is_active: true
        },
        {
          key: 'line',
          icon_url: require('@/assets/images/poster/icon_line.png'),
          title: '分割线',
          is_active: true
        },
        {
          key: 'qr_code',
          icon_url: require('@/assets/images/poster/icon_qr_code.png'),
          title: '二维码',
          is_active: true
        },
        {
          key: 'xj_logo',
          icon_url: require('@/assets/images/poster/logo.png'),
          title: '小九水印',
          is_active: true
        }
      ],
      shareComponentKey: 'name',
      rules: {
        name: [{ required: true, message: '请输入海报名称', trigger: 'blur' }]
      },
      predefineColors: ['#000', '#fff', '#888', '#ff4544'],
      btnLoading: false,
      cardLoading: true,
      activeName: 'first',
      poster: {
        default_head: require('@/assets/images/logo_round.png'),
        default_qr_code: require('@/assets/images/poster/default_qr_code.png'),
        icon_delete: require('@/assets/images/poster/icon_delete.png'),
        default_mobile_qr_code: require('@/assets/images/poster/default_mobile_qr_code.png')
      },
      viewUrl: '',
      downImg: false,
      info: {},
      typeList: ['营销活动海报', '非活动海报'],
      typeL: ['posters_marketing', 'posters'],
      categoryList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      divWidth: 750,
      divHeight: 1330
    }
  },
  computed: {
    // 控制显示的内容
    test() {
      return function(index) {
        if (!this.ruleForm.share[this.shareComponent[index].key]) {
          this.ruleForm.share[this.shareComponent[index].key] = {}
          var data = deepClone(this.getDetail())
          this.ruleForm.share[this.shareComponent[index].key] = deepClone(data.share[this.shareComponent[index].key])
        }
        var isShow = this.ruleForm.share[this.shareComponent[index].key].is_show
        return isShow === 1 || isShow === '1'
      }
    }
  },
  mounted: function() {
    this.getPosterInfo()
      .then(res => {
        this.$nextTick(function() {
          this.buildQr(res.qr_code.text)
        })
      })
      .then(res => {
        this.getCategory()
      })
  },
  methods: {
    // 营销型模版数据
    getDetail_p() {
      // self.cardLoading = true
      return {
        share: {
          bg_pic: {
            url: require('@/assets/images/poster/default_bg_p.png'),
            is_show: 1
          },
          head: {
            is_show: 0,
            size: 105,
            top: 111,
            left: 215,
            type: 2,
            file_type: 'image'
          },
          name: {
            is_show: 1,
            font: 18,
            top: 20,
            left: 266,
            color: '#FF5B6A',
            file_type: 'text'
          },
          desc: {
            is_show: 1,
            width: 563,
            font: 15,
            top: 75,
            left: 254,
            color: '#FF5B6A',
            text: '带你开启美丽生活',
            file_type: 'text'
          },
          qr_code: {
            text: '带你开启美丽生活',
            is_show: 1,
            size: 150,
            top: 1134,
            left: 368,
            type: 2,
            color: '#000000',
            light_color: '#ffffff',
            file_type: 'image'
          },
          activity_name: {
            is_show: 1,
            width: 563,
            font: 13,
            top: 1150,
            left: 40,
            color: '#000000',
            text: '原价3000元周年会员卡',
            file_type: 'text'
          },
          activity_price: {
            is_show: 1,
            width: 563,
            font: 17,
            top: 1198,
            left: 90,
            color: '#000000',
            text: '秒杀价1500元',
            file_type: 'text'
          },
          activity_time: {
            is_show: 1,
            width: 563,
            font: 12,
            top: 1150,
            left: 519,
            color: '#000000',
            text: '1月10日至2月10日',
            file_type: 'text'
          },
          activity_text1: {
            is_show: 1,
            width: 563,
            font: 23,
            top: 1198,
            left: 535,
            color: '#000000',
            text: '扫码开抢',
            file_type: 'text'
          },
          call_text: {
            is_show: 1,
            width: 563,
            font: 12,
            top: 1279,
            left: 40,
            color: '#000000',
            text: '联系电话：0581-87229825',
            file_type: 'text'
          },
          address_text: {
            is_show: 1,
            width: 563,
            font: 12,
            top: 1279,
            left: 380,
            color: '#000000',
            text: '地址：杭州市上城区杭海路251号',
            file_type: 'text'
          },
          line: {
            is_show: 1,
            width: 4,
            height: 134,
            top: 1140,
            left: 353,
            color: '#000000',
            file_type: 'line'
          },
          xj_logo: {
            src: 'https://yuxiaojiu.cn/storage/uploads/20220223/9e1e03341d19475e85ba2b3aefef6a9d.png',
            is_show: 0,
            size: 200,
            top: 1190,
            left: 50,
            type: 2,
            file_type: 'image'
          }
        }
      }
    },
    // 非活动型模版数据
    getDetail() {
      // self.cardLoading = true
      return {
        share: {
          bg_pic: {
            url: require('@/assets/images/poster/default_bg.png'),
            is_show: 1
          },
          head: {
            is_show: 1,
            size: 70,
            top: 47,
            left: 50,
            type: 2,
            file_type: 'image'
          },
          name: {
            is_show: 1,
            font: 16,
            top: 60,
            left: 140,
            color: '#000000',
            file_type: 'text'
          },
          desc: {
            is_show: 1,
            width: 563,
            font: 15,
            top: 930,
            left: 50,
            color: '#ffffff',
            text: '种一棵树最好的时间是10年， 其次就是现在',
            file_type: 'text'
          },
          activity_name: {
            is_show: 1,
            width: 563,
            font: 20,
            top: 820,
            left: 50,
            color: '#ffffff',
            text: '种一棵树最好的时间',
            file_type: 'text'
          },
          activity_price: {
            is_show: 0,
            width: 563,
            font: 17,
            top: 1198,
            left: 90,
            color: '#000000',
            text: '秒杀价1500元',
            file_type: 'text'
          },
          activity_time: {
            is_show: 0,
            width: 563,
            font: 12,
            top: 1150,
            left: 510,
            color: '#000000',
            text: '10月10日至12月10日',
            file_type: 'text'
          },
          activity_text1: {
            is_show: 0,
            width: 563,
            font: 23,
            top: 1198,
            left: 535,
            color: '#000000',
            text: '扫码开抢',
            file_type: 'text'
          },
          call_text: {
            is_show: 0,
            width: 563,
            font: 12,
            top: 1279,
            left: 40,
            color: '#000000',
            text: '联系电话：0581-87229825',
            file_type: 'text'
          },
          address_text: {
            is_show: 1,
            width: 563,
            font: 12,
            top: 1250,
            left: 50,
            color: '#ffffff',
            text: '地址：杭州市上城区杭海路251号',
            file_type: 'text'
          },
          line: {
            is_show: 0,
            width: 4,
            height: 134,
            top: 1140,
            left: 353,
            color: '#000000',
            file_type: 'line'
          },
          qr_code: {
            text: '带你开启美丽生活',
            is_show: 1,
            size: 150,
            top: 1050,
            left: 530,
            type: 2,
            color: '#000000',
            light_color: '#ffffff',
            file_type: 'image'
          },
          xj_logo: {
            src: 'https://yuxiaojiu.cn/storage/uploads/20220223/9e1e03341d19475e85ba2b3aefef6a9d.png',
            is_show: 0,
            size: 200,
            top: 1190,
            left: 50,
            type: 2,
            file_type: 'image'
          }
        }
      }
    },
    // 取值
    getCategoryValue(value) {
      this.form.category_id = value
      console.log('🚀 ~ file: edit.vue:1263 ~ getCategoryValue ~ value:', value)
    },
    getCategory() {
      getSortType(this.typeL[this.info.type]).then(res => {
        this.categoryList = res.data
      })
    },
    // 获取海报详情
    getPosterInfo() {
      return new Promise((resolve, reject) => {
        getInfo(this.$route.params.id)
          .then(res => {
            this.info = res.data
            this.form = res.data
            this.form.is_hidden = !!res.data.is_hidden
            var new_obj = {}
            Object.assign(new_obj, this.getDetail().share, res.data.config)
            new_obj.divHeight = new_obj.divHeight ? new_obj.divHeight : '1330'
            if (!new_obj.original_price) {
              new_obj.original_price = {
                align: '',
                color: '#FFFFFF',
                file_type: 'text',
                font: 12,
                is_show: 0,
                left: 0,
                text: '原价',
                top: 0,
                width: 100
              }
            }
            this.ruleForm = {
              share: new_obj
            }

            resolve(this.ruleForm.share)
          })
          .finally(() => {
            this.cardLoading = false
          })
      })
    },
    // 保存
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buildPreview()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 保存配置
    async save_p() {
      return await new Promise((resolve, reject) => {
        resolve(/* 这里是需要返回的数据 */)
      })
    },
    // 生成预览图
    async buildPreview(down) {
      this.btnLoading = true
      return await new Promise((resolve, reject) => {
        // DOM节点主体
        const main = document.getElementById('posterView')
        window.pageYOffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        html2canvas(main, {
          dpi: window.devicePixelRatio * 1,
          width: parseInt(main.offsetWidth),
          height: parseInt(main.offsetHeight),
          scale: down ? 3 : 0.5,
          x: 0,
          y: 0,
          scrollY: 0,
          scrollX: 0,
          useCORS: true,
          allowTaint: false
        })
          .then(canvas => {
            // 预览窗口
            var dataUrl = canvas.toDataURL('image/png', 1.0)
            this.viewUrl = dataUrl
            this.downImg = !!down
            if (!this.downImg) {
              this.utils
                .uploadFile({
                  url: this.adminUpload,
                  img: this.viewUrl
                })
                .then(res => {
                  if (res.status === 200) {
                    var preview_image = res.data.path
                    Edit(this.info.id, {
                      type: this.form.type,
                      category_id: this.form.category_id,
                      config: JSON.stringify(this.ruleForm.share),
                      preview_image: preview_image,
                      background_image: this.ruleForm.share.bg_pic.url,
                      weigh: this.form.weigh,
                      is_hidden: this.form.is_hidden ? 1 : 0,
                      name: this.form.name
                    })
                      .then(res => {
                        this.$message.success('修改成功')
                      })
                      .finally(() => {
                        this.btnLoading = false
                      })
                  } else {
                    this.$message.error('略缩图失败，请重新保存重试')
                  }
                })
            }
          })
          .finally(() => {
            this.btnLoading = false
          })
        resolve(/* 这里是需要返回的数据 */)
      })
    },
    transformData() {},
    handleClick(tab, event) {
      console.log(tab, event)
    },
    // 添加组件
    componentItemClick(index) {
      if (index === 11) {
        setTimeout(() => {
          this.buildQr('带你开启美丽生活')
        }, 100)
      }
      if (this.activeName === 'first') {
        this.shareComponent[index].is_active = true
        this.ruleForm.share[this.shareComponent[index].key].is_show = 1
        this.shareComponentKey = this.shareComponent[index].key
      }
    },
    // 移除组件
    componentItemRemove(index) {
      if (this.activeName === 'first') {
        this.shareComponent[index].is_active = false
        this.ruleForm.share[this.shareComponent[index].key].is_show = 0
        this.shareComponentKey = ''
      }
    },
    // 上传背景图
    uploadBg(response, file, fileList) {
      this.cardLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.ruleForm.share.bg_pic.url = response.data.path
    },
    handleChangeFile(file, fileList) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file.raw) // 必须用file.raw
        reader.onload = () => {
          // 让页面中的img标签的src指向读取的路径
          const img = new Image()
          img.src = reader.result
          if (img.width === 0 || img.height === 0) {
            return false
          } else {
            // const height = Math.round(img.height / 1.44)
            // this.divHeight = height
            console.log(img.width, img.height)
            const multiple = img.width / 750
            this.ruleForm.share.divHeight = img.height / multiple
            this.ruleForm.share.divWidth = img.width / multiple
            this.ruleForm.share.imgHeight = img.height
            this.ruleForm.share.imgWidth = img.height
            console.log(this.ruleForm.share.divHeight)
            // if (img.height === 2688) {
            //   console.log('大')
            //   this.divHeight = 1627
            // } else {
            //   console.log('小')
            //   this.divHeight = 1336
            // }
          }
        }
      })
    },
    // 图片下载
    imgDown() {
      const file_name = '海报预览.png'
      const link = document.createElement('a')
      link.href = this.viewUrl // 下载链接
      link.setAttribute('download', file_name)
      link.style.display = 'none' // a标签隐藏
      document.body.appendChild(link)
      link.click()
    },
    // 生成二维码
    buildQr(content) {
      const that = this
      const canvas = that.$refs.qrcode_box
      console.log(this.ruleForm.share.qr_code.light_color)
      if (this.ruleForm.share.qr_code.transparency === '1') {
        if (this.ruleForm.share.qr_code.light_color.length === 7) {
          this.ruleForm.share.qr_code.light_color = this.ruleForm.share.qr_code.light_color + '00'
        }
      } else if (this.ruleForm.share.qr_code.transparency === '0') {
        this.ruleForm.share.qr_code.light_color = this.ruleForm.share.qr_code.light_color.substring(0, 7)
      }
      QRCode.toCanvas(
        canvas,
        content,
        {
          width: this.ruleForm.share.qr_code.size,
          height: this.ruleForm.share.qr_code.size,
          color: {
            dark: this.ruleForm.share.qr_code.color,
            light: this.ruleForm.share.qr_code.light_color
          },
          margin: 1
        },
        function(error) {
          if (error) console.error(error)
          that.$forceUpdate()
        }
      )
    },
    // 二维码刷新
    qrcodeChange(e) {
      this.buildQr(e)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    upSuccess(response, file, fileList) {
      this.cardLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.ruleForm.share[this.shareComponentKey].src = response.data.path
      // this.$set(this.form, 'download_url', )
    },
    upProgress(event, file, fileList) {
      this.cardLoading = true
    },
    handleOption() {
      this.getCategory()
      this.form.category_id = 0
    }
  }
}
</script>

<style scoped>
.mobile-title {
  display: flex;
  background-color: #ffffff;
}

.mobile-name {
  font-size: 14px;
  padding: 20px 35px;
  margin: 0 0;
  position: relative;
}

.mobile-name:hover .mobile-icon {
  display: block;
}

.mobile-icon {
  display: none;
  position: absolute;
  font-size: 20px;
  top: 5px;
  right: 0px;
}

.color {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}

.mobile-box {
  width: 475px;
  /*height: 740px;*/
  padding: 35px 10px;
  background-color: #fff;
  border-radius: 30px;
  margin-right: 20px;
}

.bg-box {
  position: relative;
  /*border: 1px solid #e2e3e3;*/
  width: 750px;
  /*height: 1334px;*/
  zoom: 0.6;
}

.img-box {
  display: block;
  width: 100%;
}

.bg-pic {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.title {
  padding: 15px 0;
  background-color: #f7f7f7;
  margin-bottom: 10px;
}

.component-item {
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
  border: 1px solid #e2e2e2;
  margin-right: 15px;
  margin-top: 15px;
  border-radius: 5px;
}

.component-item-img {
  width: 48px;
}

.component-item.active {
  border: 1px solid #7bbdfc;
}

.component-item-remove {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 28px;
  height: 28px;
}

.component-attributes-box {
  color: #ff4544;
}

.box-card {
  margin-top: 35px;
}

.el-tabs__header {
  padding: 0 20px;
  height: 56px;
  line-height: 56px;
  background-color: #fff;
}

.form-body {
  padding: 20px 20px 20px 20px;
  background-color: #fff;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 640px;
}

.button-item {
  padding: 9px 25px;
  /* position: absolute !important; */
  bottom: -52px;
  left: 0;
}

.el-card,
.el-tabs__content {
  overflow: visible;
}

.display-center {
  /* left: 50% !important;
  transform: translate(-50%, 0%) !important; */
  left: 0px !important;
  right: 0px !important;
  margin: 0 auto !important;
}

.display-left {
  left: 0% !important;
}

.display-right {
  left: auto !important;
  right: 0% !important;
}

.text-left {
  text-align: left !important;
}

.text-centers {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}
</style>
