<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="续卡名称" prop="membership_card_id">
        <div class="flex">
          <div>
            <el-select v-model="form.card_type" placeholder="请选择" @change="card_type_change">
              <el-option
                v-for="(item,index) in card_type"
                :key="item"
                :label="item"
                :value="index"
              />
            </el-select>
          </div>
          <div class="m-x-10">
            <el-select v-model="form.membership_card_id" placeholder="请选择" @change="membership_change">
              <el-option
                v-for="item in membershipCard_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div>售价:{{ form.new_card_info.price }}元</div>
        </div>
      </el-form-item>
      <el-form-item label="有效期至" prop="valid_time">
        <el-date-picker
          v-model="form.valid_time"
          class="dialog_w100"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        />
      </el-form-item>
      <el-form-item v-if="form.card_type!==0" label="余额折算到新卡" prop="remaining">
        <el-input v-model="form.remaining" placeholder="0" @input="remaining_change">
          <template slot="append">{{ form.card?formatCardUnitType(form.card_type):'天' }}</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="form.card_type!==0" label="加赠" prop="gift">
        <el-input v-model="form.gift" placeholder="0" type="number" @input="gift_change">
          <template slot="append">{{ form.card?formatCardUnitType(form.card_type):'天' }}</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="form.card_type!==0" label="续卡后总余额" prop="limit">
        <el-input v-model="form.limit" placeholder="请填写续卡后总余额">
          <template slot="append">{{ form.card?formatCardUnitType(form.card_type):'天' }}</template>
        </el-input>
      </el-form-item>
      <el-form-item label="付款方式" prop="payment_method_id">
        <el-select v-model="form.payment_method_id" placeholder="付款方式" class="dialog_w100">
          <el-option
            v-for="(item,index) in payment_method"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="实收金额（元）" prop="actual_amount">
        <el-input v-model="form.actual_amount" placeholder="请输入费用" type="number" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请填写操作备注"
          rows="5"
          maxlength="300"
          show-word-limit
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button @click="$emit('back', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { getVipCard, getVipCardDetails } from '@/api/store/venues.js'
import { continueUserMembershipCard } from '@/api/store/user.js'
export default {
  name: 'Continue',
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      form: {
        number: '',
        gift: 0,
        card_type: 0,
        membership_card_id: 0,
        user_id: '',
        venues_id: '',
        type: '',
        valid_time: '',
        actual_amount: '',
        payment_method_id: 0,
        remark: '',
        remaining: 0,
        limit: 0,
        card: {},
        new_card_info: {}
      },
      rules: {
        gift: [
          { validator: (rule, value, callback) => {
            if (this.form.gift < 0) {
              callback(new Error('请填写正数'))
            }
            callback()
          }, trigger: ['blur', 'change']
          }
        ],
        limit: [
          { validator: (rule, value, callback) => {
            if (this.form.card_type !== 0 && (this.form.limit === '' || this.form.limit < 0)) {
              callback(new Error('请填写正数'))
            }
            callback()
          }, trigger: ['blur', 'change']
          }
        ],
        membership_card_id: { required: true, message: '请选择续卡会员卡', trigger: ['blur', 'change'] },
        valid_time: { required: true, message: '请选择有效期时间', trigger: ['blur', 'change'] },
        actual_amount: { validator: (rule, value, callback) => {
          if (this.form.actual_amount && this.form.actual_amount < 0) {
            callback(new Error('请填写正数'))
          }
          callback()
        }, trigger: ['blur', 'change']
        }
      },
      membershipCard_type: []
    }
  },
  created() {
    Object.assign(this.form, this.cardData)
    this.form.gift = 0// 清空加赠
    this.getVipCard(this.form.card_type)
    if (this.cardData.card) {
      this.form.card = this.cardData.card
    } else {
      this.$message.error('当前会员卡信息异常')
      return false
    }
  },
  methods: {
    // 续卡类型切换
    card_type_change(v) {
      this.form.membership_card_id = ''
      this.getVipCard(v)
    },
    // 续卡 卡切换
    membership_change(v) {
      // 截止时间累加
      this.autoTime(v)
    },
    // 获取指定类型卡
    getVipCard(type) {
      const data = {}
      data.limit = 999
      if (type !== null) {
        var querys = []
        querys.push({ field: 'type', key: type })
        data.query = this.utils.getQueryParams(querys)
      }
      getVipCard(data).then(res => {
        this.membershipCard_type = res.data.list
        if (this.membershipCard_type[0]) {
          this.form.membership_card_id = this.membershipCard_type[0].id
          // 计算有效期时间
          this.autoTime(this.form.membership_card_id)
        }
      }).catch(() => {})
    },
    // 计算有效期时间
    autoTime(id) {
      if (id !== '') {
        getVipCardDetails(id).then(res => {
          this.form.new_card_info = res.data
          const { data } = res
          const valid_period = JSON.parse(data.valid_period)
          this.$set(this.form, 'valid_time', this.$moment(this.cardData.valid_time).add(valid_period.digital, valid_period.type).format('YYYY-MM-DD'))
          // parseInt(data.limit) + parseInt(this.form.remaining)
          this.$set(this.form, 'limit', parseInt(data.limit))
          this.$set(this.form, 'actual_amount', data.price)
        })
      }
    },
    // 加赠变更
    gift_change(v) {
      if (!isNaN(v) && v > 0) {
        this.$set(this.form, 'limit', parseInt(this.form.limit ? this.form.limit : 0) + (!isNaN(v) && v > 0 ? parseInt(v) : 0))
      } else {
        this.$set(this.form, 'limit', parseInt(this.form.remaining ? this.form.remaining : 0) + parseInt(this.form.new_card_info.limit ? this.form.new_card_info.limit : 0))
      }
    },
    // 余额折算到新卡变更
    remaining_change(v) {
      if (!isNaN(v) && v > 0) {
        this.$set(this.form, 'limit', parseInt(this.form.new_card_info.limit ? this.form.new_card_info.limit : 0) + parseInt(this.form.gift ? this.form.gift : 0) + (/^\d+$/.test(v) ? parseInt(v) : 0))
      } else {
        this.$set(this.form, 'limit', parseInt(this.form.gift ? this.form.gift : 0) + parseInt(this.form.new_card_info.limit ? this.form.new_card_info.limit : 0))
      }
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          var data = deepClone(this.form)
          delete data.new_card_info
          delete data.card
          continueUserMembershipCard(this.cardData.id, data).then(res => {
            this.$emit('callback', res.msg)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }

}
</script>

<style>

</style>
