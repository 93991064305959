<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    :show-close="false"
    width="40%"
    @open="handleOpen"
    @close="handleClose"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">批量操作</div>
        <div class="title_icon cursor" @click="handleCloseDialog">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <div v-loading="dialogLoading">
      <el-steps align-center :active="stepsActive" finish-status="success">
        <el-step title="选择操作" />
        <el-step title="选择会员" />
        <el-step title="执行操作" />
      </el-steps>
      <!-- 操作 -->
      <div class="operation_center">
        <div v-if="stepsActive === 1" class="m-t-20">
          <el-tag v-for="(item, index) in tagDataList" v-show="btn_access(btnTagDataList[index])" :key="index" class="m-r-10 cursor" @click="handleOperation(index)">{{ item }}</el-tag>
        </div>
        <div v-if="stepsActive === 2" class="m-t-20">
          <el-transfer v-model="valueModifyPoints" filter-placeholder="搜索会员昵称" filterable :data="dataModifyPoints" :titles="['会员列表', '修改列表']" />
        </div>
        <div v-if="stepsActive === 3" class="m-t-20">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
            <el-form-item label="操作类型" prop="change_type">
              <el-radio-group v-model="ruleForm.change_type">
                <el-radio :label="1">增加积分</el-radio>
                <el-radio :label="2">扣减积分</el-radio>
                <el-radio :label="3">清空积分</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="ruleForm.change_type !== 3" label="积分" prop="change_integral">
              <el-input v-model="ruleForm.change_integral" type="number" oninput="value=value.replace(/[^\d.]/g,'')" :min="0" />
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="ruleForm.remark" />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer m-t-10">
      <el-button v-if="stepsActive != 1" :loading="buttonLoading" @click="handlePreviousStep">上一步</el-button>
      <el-button v-if="stepsActive != 3 && stepsActive != 1" type="primary" :disabled="valueModifyPoints.length === 0" :loading="buttonLoading" @click="handleNextStep">下一步</el-button>
      <el-button v-if="stepsActive == 3" :loading="buttonLoading" @click="handleCloseDialog">取 消</el-button>
      <el-button v-if="stepsActive == 3" type="primary" :loading="buttonLoading" @click="handleConfirm('ruleForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { getUser, postModifyPointsAll } from '@/api/store/user.js'

export default {
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      ruleForm: {
        change_type: 1,
        change_integral: '',
        remark: ''
      },
      rules: {
        change_type: [{ required: true, message: '请选择操作类型', trigger: 'blur' }],
        change_integral: [{ required: true, message: '请选输入积分', trigger: 'blur' }]
      },
      dialogLoading: false,
      dataModifyPoints: [],
      valueModifyPoints: [],
      stepsActive: 1,
      tagDataList: ['批量修改积分'],
      btnTagDataList: ['s_user_change_integral'],
      operationId: 0,
      buttonLoading: false
    }
  },
  methods: {
    getList() {
      this.dataModifyPoints = []
      this.dialogLoading = true
      getUser({ limit: 999 })
        .then(res => {
          const data = res.data
          const dataArr = []
          data.list.forEach(item => {
            dataArr.push({
              key: item.id,
              label: `${item.real_name === item.nickname ? item.nickname : `${item.real_name}(${item.nickname})`}`
            })
          })
          this.dataModifyPoints = dataArr
          this.dialogLoading = false
        })
        .catch(() => {})
    },
    // 打开的回调
    handleOpen() {},
    // 关闭的回调
    handleClose() {
      this.valueModifyPoints = []
      this.stepsActive = 1
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.$emit('closeDialog')
      if (this.stepsActive === 3) this.resetForm('ruleForm')
    },
    // 确定按钮
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.buttonLoading = true
          const data = {
            id: this.valueModifyPoints.toString(),
            ...this.ruleForm
          }
          // this.operationId 为 1 时执行批量修改积分操作 后续有其他操作请做判断或者||
          postModifyPointsAll(data)
            .then(() => {
              this.$message({
                message: '修改成功！',
                type: 'success'
              })
              this.handleCloseDialog()
              this.resetForm('ruleForm')
            })
            .finally(() => {
              this.buttonLoading = false
            })
        } else {
          return false
        }
      })
    },
    // 上一步
    handlePreviousStep() {
      this.stepsActive--
    },
    // 下一步
    handleNextStep() {
      this.stepsActive++
    },
    handleOperation(val) {
      this.stepsActive += 1
      val += 1
      this.operationId = val
      this.getList()
    },
    // 清空表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.operation_center {
  width: 600px;
  margin: 0 auto;
}

::v-deep .el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .el-tag {
  padding: 20px 20px;
  line-height: 2px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
