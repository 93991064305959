<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :show-close="false"
    @open="handleOpen"
    @close="handleClose"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">
          {{ type === 'add' ? '新增组合卡套餐' : type === 'edit' ? '编辑组合卡套餐' : '查看组合卡套餐详情' }}
        </div>
        <div class="title_icon cursor" @click="handleClose">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <!-- 表单 -->
    <el-form
      v-if="type === 'add' || type === 'edit'"
      ref="ruleForm"
      v-loading="cardLoading"
      :rules="rules"
      :model="form"
      label-position="left"
      label-width="100px"
    >
      <el-form-item label="套餐名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入套餐名称" />
      </el-form-item>
      <el-form-item label="套餐名称" prop="card_group">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="期限卡" name="0">
            <checkboxInput
              :parent-selected.sync="selectedCard.termCard"
              :list="supportCard.termCard"
              @update:parentSelected="handleParentTermCard"
            />
          </el-collapse-item>
          <el-collapse-item title="次数卡" name="1">
            <checkboxInput
              :parent-selected.sync="selectedCard.frequencyCard"
              :list="supportCard.frequencyCard"
              @update:parentSelected="handleParentFrequencyCard"
            />
          </el-collapse-item>
          <el-collapse-item title="储值卡" name="3">
            <checkboxInput
              :parent-selected.sync="selectedCard.storedValueCard"
              :list="supportCard.storedValueCard"
              @update:parentSelected="handleParentStoredValueCard"
            />
          </el-collapse-item>
        </el-collapse>
      </el-form-item>
    </el-form>
    <div v-if="type === 'details'">
      <p><b>活动信息</b></p>
      <el-descriptions v-if="dataList" class="margin-top" :column="2" border :label-style="{ width: '100px' }">
        <el-descriptions-item>
          <template slot="label">
            套餐卡名称
          </template>
          {{ dataList.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            包含卡项目
          </template>
          {{ dataList.card_group ? dataList.card_group.length : 0 }} 张会员卡
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            最后修改人
          </template>
          {{ dataList.store_user ? dataList.store_user.real_name : '' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            最后修改时间
          </template>
          {{ dataList.update_time }}
        </el-descriptions-item>
      </el-descriptions>
      <p><b>套餐卡组</b></p>
      <!-- dataList.card_data -->
      <el-descriptions class="margin-top" :column="3" direction="vertical" border>
        <el-descriptions-item label="期限卡">
          <span v-for="item in dataList.card_data" v-show="item.type === 0" :key="item.id">
            <i class="el-icon-bank-card" />
            {{ item.name }}
            <br>
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="次数卡">
          <span v-for="item in dataList.card_data" v-show="item.type === 1" :key="item.id">
            <i class="el-icon-bank-card" />
            {{ item.name }}
            <br>
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="储值卡">
          <span v-for="item in dataList.card_data" v-show="item.type === 2" :key="item.id">
            <i class="el-icon-bank-card" />
            {{ item.name }}
            <br>
          </span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="buttonLoading" @click="handleClose">{{ type === 'details' ? '关 闭' : '取 消' }}</el-button>
      <el-button v-if="type === 'add' || type === 'edit'" :loading="buttonLoading" type="primary" @click="submitForm('ruleForm')">
        保 存
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { addCombo, getComboDetail, updateCombo } from '@/api/store/combination.js'
import { getVipCard } from '@/api/store/venues.js'
// 组件
import checkboxInput from '@/views/store/teamwork/components/checkboxInput'
// 函数
import { deepClone } from '@/utils'

export default {
  components: { checkboxInput },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        name: '',
        card_group: []
      },
      rules: {
        name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' }]
        // card_group: [{ required: true, message: '请添加会员卡', trigger: 'blur' }]
      },
      cardLoading: false,
      // 支持卡列表
      supportCard: {
        termCard: [],
        frequencyCard: [],
        storedValueCard: []
      },
      // 已选择的会员卡
      selectedCard: {
        termCard: [],
        frequencyCard: [],
        storedValueCard: []
      },
      dataList: {},
      buttonLoading: false,
      activeNames: []
    }
  },
  mounted() {},
  methods: {
    // 打开的回调
    handleOpen() {
      this.cardLoading = true
      // 获取会员卡列表
      getVipCard({ limit: 9999 })
        .then(res => {
          const { data } = res
          // this.supportCard = data.list
          this.supportCard.termCard = data.list.filter(item => item.type === 0)
          this.supportCard.frequencyCard = data.list.filter(item => item.type === 1)
          this.supportCard.storedValueCard = data.list.filter(item => item.type === 2)
        })
        .catch(() => {})
        .finally(() => {
          this.cardLoading = false
        })
      // this.typeId大于0时 则获取详情
      if (this.type !== 'add') {
        getComboDetail(this.typeId).then(res => {
          const { data } = res
          this.form.name = data.name
          this.form.card_group = []
          this.selectedCard.termCard = data.card_data.filter(item => item.type === 0).map(item1 => item1.id)
          this.selectedCard.frequencyCard = data.card_data.filter(item => item.type === 1).map(item1 => item1.id)
          this.selectedCard.storedValueCard = data.card_data.filter(item => item.type === 2).map(item1 => item1.id)
          // 防止后端传过来的数组是字符串 这边需要转换成数字
          // const dataCardGroup = data.card_group.map(item => {
          //   return parseInt(item, 10)
          // })
          this.dataList = data
        })
      }
    },
    // 关闭的回调
    handleClose() {
      this.form.name = ''
      this.form.card_group = []
      this.selectedCard = {
        termCard: [],
        frequencyCard: [],
        storedValueCard: []
      }
      this.$emit('CloseDialog')
    },
    // 提交
    submitForm(formName) {
      console.log('formName:', formName)
      console.log('this.$refs[formName]:', this.$refs[formName])
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          const data = deepClone(this.form)
          data.card_group.push(...this.selectedCard.termCard)
          data.card_group.push(...this.selectedCard.frequencyCard)
          data.card_group.push(...this.selectedCard.storedValueCard)
          if (this.type === 'add') {
            addCombo(data)
              .then(() => {
                this.$message.success('添加成功')
                this.handleClose()
              })
              .finally(() => {
                this.buttonLoading = false
              })
          } else if (this.type === 'edit') {
            updateCombo(this.typeId, data)
              .then(() => {
                this.$message.success('编辑成功')
                this.handleClose()
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    handleParentTermCard(val) {
      this.selectedCard.termCard = val
    },
    handleParentFrequencyCard(val) {
      this.selectedCard.frequencyCard = val
    },
    handleParentStoredValueCard(val) {
      this.selectedCard.storedValueCard = val
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
