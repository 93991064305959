<template>
  <div class="app-container">
    <!-- 通用搜索 -->
    <div class="filter-container m-t-20">
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索活动名称" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('s_offline_event_add')" type="primary" class="filter-item m-l-10" @click="$router.push({ name: 's_offline_event_add' })">
        新增活动
      </el-button>
    </div>
    <!-- 表格 -->
    <el-table ref="multipleTable" v-loading="TableLoading" :data="tableData.list" row-key="id">
      <el-table-column label="活动名称" prop="name" min-width="120" />
      <el-table-column prop="cover" label="活动封面" min-width="100">
        <template slot-scope="{ row }">
          <el-image v-if="row.cover" style="width: 160px;" :src="row.cover" />
          <div v-else class="text-danger">暂无封面图</div>
        </template>
      </el-table-column>
      <el-table-column label="活动报名费">
        <template slot-scope="{ row }">
          <el-tag :type="row.fee === '0.00' ? 'success' : ''">
            {{ row.fee === '0.00' ? '免费' : row.fee + ' 元' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="剩余名额">
        <template slot-scope="{ row }">
          {{ `${row.max_member == '0' ? '不限制' : row.max_member - row.valid_registrant_count + ' 人'} ` }}
        </template>
      </el-table-column>
      <el-table-column label="报名人数">
        <template slot-scope="{ row }">
          {{ `${row.valid_registrant_count + ' 人'} ` }}
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="{row}">
          <el-tag v-if="row.cancelled === 1" type="danger">活动已取消</el-tag>
          <el-tag v-else-if="row.cancelled === 0 && currentTime < new Date(row.sign_up_start_time)" type="warning">未开始</el-tag>
          <el-tag v-else-if="row.cancelled === 0 && new Date(row.sign_up_start_time) <= currentTime && currentTime <= new Date(row.sign_up_end_time)" type="success">报名中</el-tag>
          <el-tag v-else type="danger">已截至报名时间</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="活动时间" min-width="150">
        <template slot-scope="{ row }">
          {{ `${row.event_start_time.slice(0, 16)} - ${row.event_end_time.slice(0, 16)}` }}
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="140px">
        <template slot-scope="{ row }">
          <el-button class="m-2" @click="handleDetails(row.id)">
            详 情
          </el-button>
          <el-button
            v-show="btn_access('s_offline_event_edit')"
            class="m-2"
            type="primary"
            :disabled="row.cancelled === 1"
            @click="$router.push({ name: 's_offline_event_edit', params: { id: row.id } })"
          >
            编 辑
          </el-button>
          <el-dropdown class="course" :hide-on-click="false">
            <el-button type="info">
              更多
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="row.cancelled !== 1 && btn_access('s_offline_event_cancel')" @click.native="handleCancel(row.id)">
                <template>
                  取消活动
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('s_offline_event_delete')" @click.native="handleDelete(row.id)">
                <template>
                  删除活动
                </template>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <el-button v-if="row.cancelled !== 1 && btn_access('s_offline_event_cancel')" class="m-2" type="danger" @click="handleCancel(row.id)">
            取消活动
          </el-button>
          <el-button v-show="btn_access('s_offline_event_delete')" class="m-2" type="danger" @click="handleCancel(row.id)">
            删除活动
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 取消活动弹窗 -->
    <el-dialog
      title="确认取消活动"
      :visible.sync="dialogVisibleTask"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="20%"
      @close="handleClose"
    >
      <div class="font-16">活动取消后，报名涉及的费用系统不予自动退款，需自行退费处理，确认取消活动吗？</div>
      <el-form ref="ruleForm" class="m-t-20" :model="form" label-position="left" label-width="120px">
        <el-form-item label="请选择取消原因">
          <el-radio-group v-model="form.reason" class="m-t-6">
            <el-radio class="m-y-4" :label="1">报名人数不足</el-radio>
            <br>
            <el-radio class="m-y-4" :label="2">极端天气</el-radio>
            <br>
            <el-radio class="m-y-4" :label="3">其他（特殊原因）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发送短信通知">
          <el-switch v-model="form.send_sms" :active-value="1" :inactive-value="0" />
        </el-form-item>
        <span class="font-12 text-info">*您可选择将活动取消的通知通过短信发送给已报名的用户</span>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTask = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <detailsDialog :dialog-visible-account="dialogVisibleAccount" :type-id="typeId" @roleVisible="handleRoleVisible" />
  </div>
</template>

<script>
// API
import { activityList, cancelActivity, deleteActivity } from '@/api/store//activitySignUp.js'
// 组件
import Pagination from '@/components/Pagination'
import detailsDialog from './components/detailsDialog'

export default {
  components: { Pagination, detailsDialog },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      searchLoading: false,
      TableLoading: false,
      dialogVisibleTask: false,
      form: {
        reason: 3,
        send_sms: 0
      },
      id: 0,
      typeId: 0,
      dialogVisibleAccount: false,
      currentTime: new Date() // 当前时间
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.TableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'name', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      activityList(data)
        .then(res => {
          const { data } = res
          this.tableData = data
        })
        .finally(() => {
          this.TableLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleCancel(id) {
      this.id = id
      this.dialogVisibleTask = true
    },
    handleDelete(id) {
      this.$confirm('活动删除后，如果已有报名人，则系统不会自动退款，且您将无法查看名单，报名人无法查看活动，确认删除活动吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteActivity(id).then(res => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    handleConfirm() {
      cancelActivity(this.id, this.form)
        .then(res => {
          this.$message.success('添加成功')
          this.getList()
        })
      this.dialogVisibleTask = false
    },
    // 关闭的回调
    handleClose() {
      this.form = {
        reason: 3,
        send_sms: 0
      }
    },
    handleDetails(id) {
      // this.$router.push({ name: 's_activity_signUp_details', params: { id }})
      this.typeId = id
      this.dialogVisibleAccount = true
    },
    handleRoleVisible() {
      this.dialogVisibleAccount = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
