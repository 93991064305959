<template>
  <div>
    <el-dialog v-if="!utils.empty(bookInfo)" append-to-body :close-on-click-modal="false" :visible.sync="dialog.visible" :title="dialog.title" class="dialog_w750">
      <div v-loading="loading" class="about_info">
        <div class="m-b-10 font-18">用户信息</div>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="持卡人">{{ bookInfo.user ? getUserNames(bookInfo.user) : '--' }}</el-descriptions-item>
          <el-descriptions-item label="手机">{{ bookInfo.user ? bookInfo.user.phone : '--' }}</el-descriptions-item>
        </el-descriptions>
        <div class="m-t-20 m-b-10 font-18">课程信息</div>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="课程名称">{{ `${bookInfo.course.name}（${bookInfo.course.type === 0 ? '团课' : bookInfo.course.type === 2 ? '精品课' : '私教课'}）` }}</el-descriptions-item>
          <el-descriptions-item label="教练">{{ bookInfo.store_user ? bookInfo.store_user.real_name : '--' }}</el-descriptions-item>
          <el-descriptions-item v-if="bookInfo.type !== 1" label="教室">
            {{ bookInfo.class_schedule.classroom ? bookInfo.class_schedule.classroom.name : bookInfo.course.classroom ? bookInfo.course.classroom.name : '--' }}
          </el-descriptions-item>
          <el-descriptions-item label="时长">{{ bookInfo.class_schedule ? bookInfo.class_schedule.duration : bookInfo.course.duration }} 分钟</el-descriptions-item>
          <el-descriptions-item label="上课场馆">{{ bookInfo.user_membership_card.venues ? bookInfo.user_membership_card.venues.name : '--' }}</el-descriptions-item>
          <el-descriptions-item label="上课时间">{{ bookInfo.subscribe_time | formatDate }}</el-descriptions-item>
        </el-descriptions>
        <div class="m-t-20 m-b-10 font-18">预约信息</div>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="预约状态">
            <div class="block">
              <el-tag v-if="bookInfo.status == 0" class="m-x-2">已预约</el-tag>
              <el-tag v-if="bookInfo.status == 1" class="m-x-2" type="success">已签到</el-tag>
              <el-tag v-if="bookInfo.status == 2" class="m-x-2" type="info">已取消</el-tag>
              <el-tag v-if="bookInfo.queue > 0" class="m-x-2" type="danger">排队中</el-tag>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="预约方式">
            {{
              bookInfo.subscribe_channel == 'STORE' || bookInfo.subscribe_channel == 'USER'
                ? `${bookInfo.subscribe_channel_text} ${bookInfo.ext ? (bookInfo.ext.subscribe_real_name ? '(' + bookInfo.ext.subscribe_real_name + ')' : '') : ''}`
                : '自主预约'
            }}
          </el-descriptions-item>
          <el-descriptions-item label="预约人数">{{ bookInfo.subscribe_number }} 人</el-descriptions-item>
          <el-descriptions-item label="预约时间">{{ bookInfo.create_time.slice(0, 16) }}</el-descriptions-item>
          <el-descriptions-item v-if="bookInfo.status == 1" label="签到方式">
            {{ `${bookInfo.sign_mode_text} ${bookInfo.ext ? (bookInfo.ext.sign_real_name ? '(' + bookInfo.ext.sign_real_name + ')' : '') : ''}` }}
          </el-descriptions-item>
          <el-descriptions-item v-if="bookInfo.status == 1" label="签到时间">{{ bookInfo.sign_time | formatDate }}</el-descriptions-item>
          <el-descriptions-item v-if="bookInfo.status == 1 && bookInfo.integral > 0 ? true : false" label="赠送积分">+ {{ bookInfo.integral }}</el-descriptions-item>
          <el-descriptions-item label="预约备注" :span="2">{{ bookInfo.remark ? bookInfo.remark : '无' }}</el-descriptions-item>
          <el-descriptions-item v-if="bookInfo.status == 2" label="取消原因">
            {{ bookInfo.cancel_notice }}{{ bookInfo.cancel_notice != '自主取消' ? `${bookInfo.cancel_store_user ? '（' + bookInfo.cancel_store_user.real_name + '）' : ''}` : '' }}
          </el-descriptions-item>
          <el-descriptions-item v-if="bookInfo.status == 2" label="取消时间">{{ bookInfo.cancel_time | formatDate }}</el-descriptions-item>
        </el-descriptions>
        <div v-if="bookInfo.queue_on == 1" class="m-t-20 m-b-10 font-18">排队信息</div>
        <el-descriptions v-if="bookInfo.queue_on == 1" :column="2" border>
          <el-descriptions-item label="排队状态">
            <el-tag :type="bookInfo.queue_status == 0 ? 'warning' : bookInfo.queue_status == 1 ? 'primary' : bookInfo.queue_status == 2 ? 'info' : 'danger'">
              {{ bookInfo.queue_status == 0 ? '排队中' : bookInfo.queue_status == 1 ? '候补成功' : bookInfo.queue_status == 2 ? '已取消' : '候补失败' }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item v-if="bookInfo.queue_status == 0" label="排队序号">{{ `第${bookInfo.queue}个` }}</el-descriptions-item>
          <el-descriptions-item label="排队时间">{{ bookInfo.queue_time | formatDate }}</el-descriptions-item>
          <el-descriptions-item v-if="bookInfo.queue_status == 1" label="候补时间">{{ bookInfo.queue_success_time | formatDate }}</el-descriptions-item>
          <el-descriptions-item v-if="bookInfo.queue_status == 3" label="排队失败原因" :span="2">{{ bookInfo.queue_msg }}</el-descriptions-item>
        </el-descriptions>
        <div class="m-t-20 m-b-10 font-18">支付信息</div>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="支付方式">{{ bookInfo.order_type == 0 ? '会员卡' : '在线支付' + `(${bookInfo.order_data.type})` }}</el-descriptions-item>
          <el-descriptions-item label="卡名称">{{ bookInfo.user_membership_card.card ? bookInfo.user_membership_card.card.name : '--' }}</el-descriptions-item>
          <el-descriptions-item label="卡号">
            <el-tag>{{ bookInfo.user_membership_card.number }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="余额">
            <div v-if="bookInfo.user_membership_card.status_id !== 14">
              <span v-if="bookInfo.user_membership_card.card.type === 0">
                <span v-if="getExcessTime(bookInfo.user_membership_card.valid_time) > 0" class="text-success">
                  {{ getExcessTime(bookInfo.user_membership_card.valid_time) }}
                  <b v-if="bookInfo.user_membership_card.card">{{ formatCardUnitType(bookInfo.user_membership_card.card.type) }}</b>
                </span>
                <span v-else class="text-danger">
                  {{ getExcessTime(bookInfo.user_membership_card.valid_time) }}
                  <b v-if="bookInfo.user_membership_card.card">{{ formatCardUnitType(bookInfo.user_membership_card.card.type) }}</b>
                </span>
              </span>
              <span v-else>
                {{ bookInfo.user_membership_card.limit }}
                <b v-if="bookInfo.user_membership_card.card">{{ formatCardUnitType(bookInfo.user_membership_card.card.type) }}</b>
              </span>
            </div>
            <div v-else>--</div>
          </el-descriptions-item>
          <el-descriptions-item label="扣减">{{ bookInfo.limit }}</el-descriptions-item>
          <el-descriptions-item label="有效期至">{{ bookInfo.user_membership_card.valid_time == 0 ? '未开卡' : bookInfo.user_membership_card.valid_time }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getBookInfo } from '@/api/store/worktable.js'
export default {
  name: 'BookInfo',
  props: {
    value: {
      type: Object,
      default: function() {
        return { visible: false, title: '预约私教课记录' }
      }
    },
    logid: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      dialog: this.value,
      bookInfo: {}, // 预约详情
      loading: false
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.dialog = newVal
        if (this.logid && newVal.visible) {
          this.course_details(this.logid)
        }
      },
      deep: true
    },
    logid: {
      handler(newVal) {
        this.course_details(newVal)
      },
      deep: true
    }
  },
  methods: {
    // 预约详情
    course_details(id) {
      this.loading = true
      // 打开单页
      getBookInfo(id)
        .then(res => {
          this.bookInfo = res.data
          this.dialog.visible = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.about_info {
  ::v-deep .el-descriptions-item__label {
    width: 120px;
  }
}
</style>
