var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btn_access("version_add"),
                  expression: "btn_access('version_add')"
                },
                { name: "waves", rawName: "v-waves" }
              ],
              staticClass: "filter-item m-r-10",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.add_edit("")
                }
              }
            },
            [_vm._v(" 添加 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.listLoading,
              expression: "loading.listLoading"
            }
          ],
          ref: "multipleTable",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", { attrs: { prop: "code", label: "版本号" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "版本名称" } }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "版本说明" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: "迭代类型",
              formatter: _vm.typeFilter
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "private_ip", label: "内测IP" }
          }),
          _c("el-table-column", {
            attrs: { prop: "channel", label: "发布平台" }
          }),
          _c("el-table-column", {
            attrs: { prop: "download_url", label: "下载链接" }
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "创建时间" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("version_edit"),
                            expression: "btn_access('version_edit')"
                          }
                        ],
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          offset: 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.add_edit(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("version_del"),
                            expression: "btn_access('version_del')"
                          }
                        ],
                        attrs: { title: "确定要删除此版本吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.plan_delete(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "m-l-10",
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete",
                              offset: 2
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog_w750",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.dialog.visible,
            title: _vm.dialog.id ? "编辑版本" : "添加版本"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "版本号", prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "20211230" },
                            model: {
                              value: _vm.form.code,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "code", $$v)
                              },
                              expression: "form.code"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "版本名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "1.0.0" },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内测IP", prop: "private_ip" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "0.0.0.0" },
                    model: {
                      value: _vm.form.private_ip,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "private_ip", $$v)
                      },
                      expression: "form.private_ip"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内测CID", prop: "private_cid" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "6efe4442ca1070a73dc3129df1f5191d" },
                    model: {
                      value: _vm.form.private_cid,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "private_cid", $$v)
                      },
                      expression: "form.private_cid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下载链接", prop: "download_url" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "https://xxxxx" },
                      model: {
                        value: _vm.form.download_url,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "download_url", $$v)
                        },
                        expression: "form.download_url"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.adminUpload,
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "on-success": _vm.upSuccess,
                                headers: _vm.utils.upload_headers(),
                                "show-file-list": false
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "华为渠道包", prop: "c_huawei" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "https://xxxxx" },
                      model: {
                        value: _vm.form.c_huawei,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "c_huawei", $$v)
                        },
                        expression: "form.c_huawei"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.adminUpload,
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "on-success": _vm.upSuccessHuaWei,
                                headers: _vm.utils.upload_headers(),
                                "show-file-list": false
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小米渠道包", prop: "c_xiaomi" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "https://xxxxx" },
                      model: {
                        value: _vm.form.c_xiaomi,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "c_xiaomi", $$v)
                        },
                        expression: "form.c_xiaomi"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.adminUpload,
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "on-success": _vm.upSuccessXiaoMi,
                                headers: _vm.utils.upload_headers(),
                                "show-file-list": false
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "腾讯渠道包", prop: "c_yyb" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "https://xxxxx" },
                      model: {
                        value: _vm.form.c_yyb,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "c_yyb", $$v)
                        },
                        expression: "form.c_yyb"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.adminUpload,
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "on-success": _vm.upSuccessYyb,
                                headers: _vm.utils.upload_headers(),
                                "show-file-list": false
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "360渠道包", prop: "c_360" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "https://xxxxx" },
                      model: {
                        value: _vm.form.c_360,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "c_360", $$v)
                        },
                        expression: "form.c_360"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.adminUpload,
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "on-success": _vm.upSuccess360,
                                headers: _vm.utils.upload_headers(),
                                "show-file-list": false
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "OPPO渠道包", prop: "c_oppo" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "https://xxxxx" },
                      model: {
                        value: _vm.form.c_oppo,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "c_oppo", $$v)
                        },
                        expression: "form.c_oppo"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.adminUpload,
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "on-success": _vm.upSuccessOppo,
                                headers: _vm.utils.upload_headers(),
                                "show-file-list": false
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "VIVO渠道包", prop: "c_vivo" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "https://xxxxx" },
                      model: {
                        value: _vm.form.c_vivo,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "c_vivo", $$v)
                        },
                        expression: "form.c_vivo"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.adminUpload,
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "on-success": _vm.upSuccessVivo,
                                headers: _vm.utils.upload_headers(),
                                "show-file-list": false
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Google渠道包", prop: "c_google" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "https://xxxxx" },
                      model: {
                        value: _vm.form.c_google,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "c_google", $$v)
                        },
                        expression: "form.c_google"
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: _vm.adminUpload,
                                "on-preview": _vm.handlePreview,
                                "on-remove": _vm.handleRemove,
                                "before-remove": _vm.beforeRemove,
                                "on-exceed": _vm.handleExceed,
                                "on-success": _vm.upSuccessGoogle,
                                headers: _vm.utils.upload_headers(),
                                "show-file-list": false
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本说明", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "更新说明",
                      type: "textarea",
                      "show-word-limit": true,
                      rows: 5,
                      maxlength: "999"
                    },
                    model: {
                      value: _vm.form.content,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "应用市场上架", prop: "app_market" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { multiple: "", placeholder: "请选择" },
                              model: {
                                value: _vm.form.app_market,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "app_market", $$v)
                                },
                                expression: "form.app_market"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "华为", value: "huawei" }
                              }),
                              _c("el-option", {
                                attrs: { label: "小米", value: "xiaomi" }
                              }),
                              _c("el-option", {
                                attrs: { label: "腾讯", value: "yyb" }
                              }),
                              _c("el-option", {
                                attrs: { label: "360", value: "360" }
                              }),
                              _c("el-option", {
                                attrs: { label: "OPPO", value: "oppo" }
                              }),
                              _c("el-option", {
                                attrs: { label: "VIVO", value: "vivo" }
                              }),
                              _c("el-option", {
                                attrs: { label: "Google", value: "google" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发布平台", prop: "channel" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { multiple: "", placeholder: "请选择" },
                              model: {
                                value: _vm.form.channel,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "channel", $$v)
                                },
                                expression: "form.channel"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "安卓", value: "android" }
                              }),
                              _c("el-option", {
                                attrs: { label: "苹果", value: "ios" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发布时间", prop: "release_time" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              align: "right",
                              "value-format": "yyyy-MM-dd HH:mm",
                              format: "yyyy-MM-dd HH:mm",
                              placeholder: "选择日期时间"
                            },
                            model: {
                              value: _vm.form.release_time,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "release_time", $$v)
                              },
                              expression: "form.release_time"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "更新模式", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type"
                              }
                            },
                            _vm._l(_vm.typeList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      _vm.dialog.visible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading.btnLoading,
                      expression: "loading.btnLoading"
                    }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" " + _vm._s(_vm.dialog.id ? "保存" : "新增") + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }