<template>
  <div class="app-container special-center">
    <el-page-header content="售卡管理详情" class="page-header" @back="returnPage()" />
    <el-tabs v-model="activeName" style="margin-top: 20px;">
      <el-tab-pane label="卡片信息" name="first">
        <el-form ref="form" :model="data" label-width="120px">
          <el-form-item label="商品标题：">{{ data.name }}</el-form-item>
          <el-form-item v-if="data.card" label="关联会员卡：">
            <template>
              <div
                v-loading="card_loading"
                class="card_item"
                :style="{
                  background: 'url(' + card_bg(item.type) + ') center center / 100% no-repeat',
                  color: item.type == 0 ? '#82848a' : '#fff'
                }"
              >
                <div class="cardNumber line-1">
                  <span>{{ item.name }}</span>
                </div>
                <div v-show="item.kind" class="cardKind">
                  <img src="@/assets/images/is_kind.png">
                </div>
                <div class="cardBody">
                  <span>售价 ￥{{ item.price | formatInt }}</span>
                  <span v-if="item.type == 0">
                    有效期 {{ JSON.parse(item.valid_period).digital }}
                    {{ formatTime(JSON.parse(item.valid_period).type) }}
                  </span>
                  <span v-if="item.type == 1">额度 {{ item.limit }} 次</span>
                  <span v-if="item.type == 2">￥{{ JSON.parse(item.valid_period).digital }}</span>
                </div>
                <div class="cardFooter" :class="{ 'row-right': item.type == 0 }">
                  <span v-if="item.type == 1">可预约 {{ item.limit }}次</span>
                  <span v-if="item.type == 2">面额 ￥{{ item.limit }}</span>
                  <div v-if="item.venues_id == currentVenues.id || utils.getVenues().id == -1">
                    非多馆卡
                  </div>
                  <div v-else>
                    多馆卡
                  </div>
                </div>
              </div>
            </template>
          </el-form-item>
          <el-form-item v-else-if="data.membership_card_ids.length > 0" label="关联会员卡：">
            <el-link v-for="(item, index) in data.cards" :key="index" class="color m-x-6 m-b-20" target="_blank" @click="handleToPage(item.id)">
              {{ item.name }}
            </el-link>
          </el-form-item>
          <el-form-item v-else label="关联会员卡：">
            <el-link class="color m-x-6 m-b-20" target="_blank">{{ data.card_combo.name }}（组合卡）</el-link>
          </el-form-item>
          <el-form-item label="售卡封面图：">
            <template>
              <el-image style="width: 120px;" :src="data.images" :preview-src-list="[data.images]" />
            </template>
          </el-form-item>
          <el-form-item label="商品图片：">
            <template>
              <div v-if="data.goods_images.length !== 0">
                <el-image
                  v-for="(item, index) in data.goods_images"
                  :key="index"
                  style="width: 60px; margin-right: 10px;"
                  :src="item"
                  :preview-src-list="[item]"
                />
              </div>
              <div v-else><el-tag>无</el-tag></div>
            </template>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="卡片售价/优惠" name="second">
        <el-descriptions style="margin-top: 20px;" :column="2" border :label-style="labelStyle">
          <el-descriptions-item label="原价">{{ data.line_price }} 元</el-descriptions-item>
          <el-descriptions-item label="售价">{{ data.price }} 元</el-descriptions-item>
          <el-descriptions-item label="限购数量（每人）">
            <template>
              <div v-if="data.restriction !== 0">{{ data.restriction }}</div>
              <el-tag v-else>不限制</el-tag>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="开启秒杀活动">
            <template>
              <el-tag v-if="data.is_seckill === 1">已开启</el-tag>
              <el-tag v-else type="danger">未开启</el-tag>
            </template>
          </el-descriptions-item>
          <el-descriptions-item v-if="data.is_seckill === 1" label="秒杀开启时间">
            {{ data.start_time }} - {{ data.end_time }}
          </el-descriptions-item>
          <el-descriptions-item v-if="data.is_seckill === 1" label="秒杀价格">
            {{ data.seckill_price }} 元
          </el-descriptions-item>
          <el-descriptions-item v-if="data.is_seckill === 1" label="秒杀限购">
            <template>
              <div v-if="data.seckill_restriction !== 0">{{ data.seckill_restriction }}</div>
              <el-tag v-else>不限制</el-tag>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="开卡设置">
            <template>
              <div v-if="data.open_card_type === 0">立即开卡</div>
              <div v-if="data.open_card_type === 1">首次预约开卡</div>
              <div v-if="data.open_card_type === 2">特定时间开卡（购买后：{{ data.open_card_time }}）</div>
              <div v-if="data.open_card_type === 3">首次上课开卡</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="已售数量">{{ data.sales_actual }}</el-descriptions-item>
          <el-descriptions-item label="库存">{{ data.inventory }}</el-descriptions-item>
          <el-descriptions-item label="商城推荐">
            <template>
              <el-tag v-if="data.is_recommend === 1">已开启</el-tag>
              <el-tag v-else type="danger">未开启</el-tag>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="商品上架">
            <template>
              <el-tag v-if="data.is_hidden === 0">已上架</el-tag>
              <el-tag v-else type="danger">已下架</el-tag>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="购卡说明">{{ data.content }}</el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
    </el-tabs>
    <!-- 会员卡详情 -->
    <Dialog :id="membership_card_id_info" :venue="venuesId" :dialog="dialogVisibleMembership" @Close="Close" />
  </div>
</template>

<script>
import { getSeckillInfo } from '@/api/store/seckill.js'
import { getVipCardDetails } from '@/api/store/venues.js'
import { getFieldList } from '@/api/store/config.js'
// 组件
import Dialog from '@/views/store/user/vipCard/components/Dialog.vue'
// 函数
import Utils from '@/utils/utils.js'

export default {
  components: { Dialog },
  data() {
    return {
      data: {},
      activeName: 'first',
      card_loading: false,
      config: [],
      item: {},
      labelStyle: { 'width': '150px' },
      dialogVisibleMembership: false,
      membership_card_id_info: 0,
      venuesId: Utils.getVenues().id
    }
  },
  created() {
    this.getList()
    getFieldList(13)
      .then(res => {
        const list = res.data.list
        const lists = []
        list.forEach((item, index) => {
          lists.push(item.store_config ? item.store_config : item)
        })
        lists.forEach((item, index) => {
          this.config[item.field] = item.value ? item.value : item.default
        })
      })
      .catch(() => {})
  },
  mounted() {},
  methods: {
    getList() {
      getSeckillInfo(this.$route.params.id).then(res => {
        this.data = res.data
        this.cardChange(res.data.membership_card_id)
      })
    },
    card_bg(n) {
      switch (n) {
        case 0:
          return this.config.card_time_bg
        case 1:
          return this.config.card_num_bg
        case 2:
          return this.config.card_stored_bg
        default:
          break
      }
    },
    formatTime(t) {
      switch (t) {
        case 'day':
          return '天'
        case 'month':
          return '月'
        case 'year':
          return '年'
      }
    },
    cardChange(id) {
      this.card_loading = true
      getVipCardDetails(id).then(res => {
        this.item = res.data
        this.card_loading = false
      })
    },
    handleToPage(membership_card_id) {
      this.dialogVisibleMembership = true
      this.membership_card_id_info = membership_card_id
    },
    Close() {
      this.dialogVisibleMembership = false
    }
  }
}
</script>

<style lang="scss" scoped>
.card_item {
  position: relative;

  & .cardKind {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  &:hover {
    box-shadow: 0 2px 12px 0 #82848a;
  }
  width: 300px;
  /* height: 158px; */
  margin-right: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  transition: 0.3s;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  // font-weight: 800;
  // display: flex;
  .cardNumber {
    b {
      letter-spacing: 1em;
    }
    span {
      font-size: 15px;
    }
    padding: 18px 10px 20px;
    font-size: 16px;
  }
  .cardBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    margin-bottom: 5px;
  }
  .cardFooter {
    line-height: 28px;
    display: flex;
    border-top: 1px solid #c0c4cc6e;
    padding: 4px 0px 10px 0px;
    margin: 0 10px;
    justify-content: space-between;
    .card-btn {
      background: none;
      color: gainsboro;
    }
  }
}

.color {
  color: rgb(24, 144, 255);
  /* padding-bottom: 3px;
  border-bottom: 1px solid rgb(24, 144, 255); */
  border: 1px solid rgb(24, 144, 255);
  padding: 4px 6px;
  border-radius: 5px;
}
</style>
