<template>
  <div class="material material-dialog">
    <!-- <div v-cloak class="material material-dialog"> -->
    <el-card shadow="never" body-style="background-color: #f3f3f3;padding:0;">
      <div style="height: 0;overflow: hidden;">
        <canvas id="material-canvas" style="border: 1px solid #ccc;visibility: hidden;" />
      </div>
      <div class="table-body">
        <el-tabs v-model="tabs" @tab-click="dialogOpened">
          <el-tab-pane
            v-for="(v, index) in tab_list"
            :key="index"
            :label="v.label"
            :name="v.name"
          >
            <div class="flex col-center" style="margin-bottom: 12px">
              <el-button
                v-show="btn_access('file_group_add')"
                size="small"
                type="primary"
                @click="showAddGroup(-1)"
              >添加分组</el-button>
              <div style="margin-left: 200px">{{ switchGroupName }}</div>
              <div class="flex col-center" style="margin-left: auto">
                <div class="search" style="margin-right: 12px">
                  <el-input
                    v-model="keyword"
                    placeholder="请输入名称搜索"
                    clearable
                    size="small"
                    class="input-with-select"
                    @clear="dialogOpened"
                    @keyup.enter.native="dialogOpened"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="dialogOpened"
                    />
                  </el-input>
                </div>
                <el-checkbox
                  v-model="selectAll"
                  label="全选"
                  size="small"
                  style="margin-right: 12px; margin-bottom: 0"
                  @change="selectAllChange"
                />
                <el-button
                  v-if="is_recycle"
                  :loading="deleteLoading"
                  size="small"
                  style="margin-right: 12px"
                  @click="deleteItems(2)"
                >还原
                </el-button>
                <el-button
                  v-show="btn_access('file_del')"
                  :loading="deleteLoading"
                  size="small"
                  style="margin-right: 12px"
                  @click="deleteItems(is_recycle ? 3 : 1)"
                >删除
                </el-button>
                <el-dropdown
                  v-show="btn_access('file_group_move')"
                  v-loading="moveLoading"
                  trigger="click"
                  :split-button="true"
                  size="small"
                  @command="moveItems"
                >
                  移动至
                  <el-dropdown-menu
                    slot="dropdown"
                    style="height: 250px; overflow-y: scroll"
                  >
                    <el-dropdown-item
                      v-for="(item, i) in groupList"
                      :key="i"
                      :command="i"
                    >{{ item.name }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>

            <div class="flex col-top" style="margin-bottom: 10px; min-height: 68vh">
              <div style="border: 1px solid #e3e3e3; margin-right: 15px">
                <!-- 分类侧边栏 -->
                <el-menu
                  v-loading="groupListLoading"
                  class="group-menu"
                  mode="vertical"
                  default-active="all"
                >
                  <el-scrollbar style="height: 635px; width: 100%">
                    <el-menu-item index="all" @click="switchGroup(-1)">
                      <i class="el-icon-tickets" />
                      <span>全部</span>
                    </el-menu-item>
                    <div v-for="(item, i) in groupItem" :key="i">
                      <template>
                        <el-menu-item :index="'' + i" @click="switchGroup(i, item.name)">
                          <div flex="dir:left box:last" class="flex row-between">
                            <div style="overflow: hidden; text-overflow: ellipsis">
                              <i class="el-icon-tickets" />
                              <span>{{ item.name }}</span>
                            </div>
                            <div flex="dir:left" class="flex">
                              <el-button v-show="btn_access('file_group_edit')" type="text" @click.stop="showAddGroup(i)">编辑</el-button>
                              <div style="color: #e3e3e3; margin: 0 2px">|</div>
                              <el-button v-show="btn_access('file_group_del')" type="text" @click.stop="deleteGroup(i, 1)">删除</el-button>
                            </div>
                          </div>
                        </el-menu-item>
                      </template>
                    </div>
                  </el-scrollbar>
                </el-menu>
              </div>
              <div v-loading="loading" class="flex-1 box">
                <el-scrollbar>
                  <div class="material-list">
                    <!-- 上传文件 -->
                    <div class="material-item">
                      <el-upload
                        v-loading="uploading"
                        :disabled="uploading"
                        :multiple="true"
                        :max="10"
                        :params="uploadParams"
                        :accept="accept"
                        :action="adminUpload"
                        list-type="picture-card"
                        auto-upload
                        :show-file-list="false"
                        :on-progress="handleStart"
                        :on-success="handleSuccess"
                      >
                        <div v-if="uploading">
                          {{ uploadCompleteFilesNum }}/{{ uploadFilesNum }}
                        </div>
                        <i v-else class="el-icon-upload" />
                      </el-upload>
                    </div>
                    <!-- 文件列表 -->
                    <div v-for="(item, inde) in attachments" :key="inde">
                      <template>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.file_name"
                          placement="top"
                          :open-delay="1"
                        >
                          <div :key="inde" :class=" 'material-item' + (item.selected ? ' selected' : '') " @click="selectItem(item)">
                            <!-- 图片 -->
                            <el-image v-if="matchType(item.path)=='image'" fit="contain" class="material-img" :src="item.path" style="width: 140px; height: 140px" />
                            <!-- 视频 -->
                            <div v-else-if="matchType(item.path)=='video'" class="material-img" style=" width: 140px; height: 140px; position: relative; ">
                              <div v-if="item.cover_pic_src" class="material-video-cover" :style=" 'background-image: url(' + item.cover_pic_src + ');' " />
                              <!-- <video :id="'app_material_' + _uid + '_' + index" style="width: 0; height: 0; visibility: hidden"> -->
                              <video :id="'app_material_' + _uid + '_' + index" style="width: 100%; height: 100%;">
                                <source :src="item.path">
                              </video>
                              <div class="material-video-info">
                                <i class="el-icon-video-play m-r-4" />
                                <span>{{ item.duration ? item.duration : "--:--" }}</span>
                              </div>
                            </div>
                            <!-- 文件 -->
                            <div v-else class="material-img" style=" width: 140px; height: 140px; line-height: 140px; text-align: center; ">
                              <i class="file-type-icon el-icon-document" />
                            </div>
                            <!-- 名称 -->
                            <div class="flex" style="margin-top: 5px">
                              <div class="material-name">{{ item.file_name }}</div>
                              <div style="margin: 0 5px">|</div>
                              <div>
                                <el-button type="text" style="padding: 0" @click.stop="showPicModel(index)">编辑 </el-button>
                              </div>
                            </div>
                          </div>
                        </el-tooltip>
                      </template>
                    </div>
                  </div>
                </el-scrollbar>
                <!-- 分页 -->
                <div style="padding: 5px; text-align: right; margin-top: auto">
                  <pagination v-show="tableData.count>0" :page-sizes="[2, 10, 20,27, 30, 50]" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="loadList" />
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 分类 -->
          <el-dialog
            append-to-body
            title="分组管理"
            :visible.sync="addGroupVisible"
            :close-on-click-modal="false"
            width="30%"
          >
            <el-form
              ref="groupForm"
              label-width="90px"
              :model="groupForm"
              :rules="groupFormRule"
              @submit.native.prevent
            >
              <el-form-item
                label="分组名称"
                prop="name"
                style="margin-bottom: 22px"
              >
                <el-input
                  v-model="groupForm.name"
                  maxlength="8"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item style="text-align: right">
                <el-button
                  type="primary"
                  :loading="groupFormLoading"
                  size="medium"
                  @click="groupFormSubmit('groupForm')"
                >保存
                </el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
          <!-- 名称修改 -->
          <el-dialog
            append-to-body
            :title="tab_list[tabs == 'image' ? 0 : 1]['label'] + '名称修改'"
            :visible.sync="addPicVisible"
            :close-on-click-modal="false"
            width="30%"
          >
            <el-form
              ref="picForm"
              label-width="90px"
              :model="picForm"
              :rules="picFormRule"
              @submit.native.prevent
            >
              <el-form-item
                :label="tab_list[tabs == 'image' ? 0 : 1]['label'] + '名称'"
                prop="name"
                style="margin-bottom: 22px"
              >
                <el-input v-model="picForm.name" />
              </el-form-item>
              <el-form-item style="text-align: right">
                <el-button
                  type="primary"
                  :loading="picFormLoading"
                  size="medium"
                  @click="picFormSubmit('picForm')"
                >保存
                </el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getFiles, delFile, setFile } from '@/api/admin/file.js'
import { getSortType, addSorts, delSorts, editSort } from '@/api/admin/category.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  name: 'Material',
  components: { Pagination },
  data() {
    return {
      tab_list: [
        { label: '图片', name: 'image' },
        { label: '视频', name: 'video' }
      ],
      tabs: 'image',
      is_recycle: 0,
      keyword: '',
      canvas: null,
      uploading: false,
      dialogVisible: false,
      loading: true,
      loadingMore: false,
      noMore: false,
      attachments: [],
      checkedAttachments: [],
      uploadParams: {},
      uploadCompleteFilesNum: 0,
      uploadFilesNum: 0,
      page: 0,
      addGroupVisible: false,
      noMall: true,
      groupList: [],
      groupItem: [], // 分类item
      groupListLoading: false,
      groupForm: {
        pid: 0,
        id: null,
        name: '',
        type: 'files',
        weigh: 0
      },
      groupFormRule: {
        name: [{ required: true, message: '请填写分组名称' }]
      },
      groupFormLoading: false,
      selectAll: false,
      deleteLoading: false,
      moveLoading: false,
      currentAttachmentGroupId: 0,
      video: null,
      pagination: null,

      addPicVisible: false,
      picForm: {
        id: null,
        name: ''
      },
      picFormRule: {
        name: [{ required: true, message: '请填写名称' }]
      },
      picFormLoading: false,
      tableData: {
        list: [],
        count: 0
      },
      listQuery: {
        page: 1,
        limit: 27
      },
      defaultImg: 'https://via.placeholder.com/140x140',
      switchGroupName: '全部'
    }
  },
  computed: {
    accept: {
      get() {
        if (this.tabs === 'image') {
          return 'image/*'
        }
        if (this.tabs === 'video') {
          return 'video/*'
        }
        return '*/*'
      }
    }
  },
  mounted() {
    this.dialogOpened()
  },
  methods: {
    file_type(val) {
      return val.indexOf('image') !== -1 ? 'img' : ''
    },
    recoverClick() {
      this.is_recycle = 1
      this.dialogOpened()
    },
    dialogOpened() {
      this.page = 1
      this.loading = true
      this.loadGroups()
      this.loadList({})
    },
    deleteItems(type) {
      const itemIds = []
      for (const i in this.attachments) {
        if (this.attachments[i].selected) {
          itemIds.push(this.attachments[i].id)
        }
      }
      if (!itemIds.length) {
        this.$message.warning('请先选择需要处理的图片。')
        return
      }

      let title
      switch (type) {
        case 1:
          title = '是否确认将选中素材放入回收站中？删除的素材可通过回收站还原'
          break
        case 2:
          title = '确认还原选择素材？'
          break
        case 3:
          title = '素材删除后将无法恢复，您确认要彻底删除所选素材吗？'
          break
        default:
          title = ''
          break
      }
      this.$confirm(title, '提示', {
        type: 'warning'
      }).then(() => {
        this.deleteLoading = true
        itemIds.forEach((item, index) => {
          delFile(item).then(e => {
            this.$message.success(e.msg)
            this.loadList()
          })
        })
        this.deleteLoading = false
      })
    },
    selectAllChange(value) {
      for (const i in this.attachments) {
        this.attachments[i].selected = value
      }
    },
    selectItem(item) {
      item.selected = !item.selected
    },
    moveItems(index) {
      const itemIds = []
      for (const i in this.attachments) {
        if (this.attachments[i].selected) {
          itemIds.push(this.attachments[i].id)
        }
      }
      if (!itemIds.length) {
        this.$message.warning('请先选择需要移动的图片。')
        return
      }
      this.$confirm('确认移动所选的' + itemIds.length + '张图片？', '提示', {
        type: 'warning'
      }).then(() => {
        this.moveLoading = true

        var data = {
          files_id: itemIds.join(','),
          class_id: this.groupItem[index].id
        }
        console.log('🚀 ~ file: index.vue ~ line 439 ~ moveItems ~ data', data)

        setFile(data).then(res => {
          this.moveLoading = false
          if (res.data.code === 0) {
            this.$messagres.success(res.data.msg)
            this.switchGroup(index)
          } else {
            this.$messagres.error(res.data.msg)
          }
        })
      })
    },
    loadGroups() {
      this.groupListLoading = true
      // is_recycle: this.is_recycle,
      // type: this.tabs
      getSortType('files', 0).then(res => {
        this.groupListLoading = false
        this.noMall = res.data.no_mall ? res.data.no_mall : false
        this.groupItem = res.data
        this.groupList = res.data
      })
    },
    showAddGroup(index) {
      if (index > -1) {
        this.groupForm.id = this.groupItem[index].id
        this.groupForm.name = this.groupItem[index].name
      } else {
        this.groupForm.id = null
        this.groupForm.name = ''
      }
      this.groupForm.edit_index = index
      this.addGroupVisible = true
    },
    deleteGroup(index, type) {
      let title
      switch (type) {
        case 1:
          title = '是否确认将分组放入回收站中？删除的分组可通过回收站还原'
          break
        case 2:
          title = '确认还原选择分组？'
          break
        case 3:
          title = '分组删除后将无法恢复，您确认要彻底删除所选分组吗？'
          break
        default:
          title = ''
          break
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSorts(this.groupItem[index].id).then(res => {
          this.$message.success(res.msg)
          this.groupItem.splice(index, 1)
        })
      }).finally(() => {
        this.groupListLoading = false
      })
    },
    showPicModel(index) {
      this.picForm = {
        id: this.attachments[index].id,
        name: this.attachments[index].name,
        edit_index: index
      }
      this.addPicVisible = true
    },
    // 有可能是图片编辑
    picFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message.error('暂无接口')
          // this.picFormLoading = true
          // this.$request({
          //   params: {
          //     r: 'common/attachment/rename'
          //   },
          //   method: 'post',
          //   data: Object.assign({}, this.picForm)
          // })
          //   .then((e) => {
          //     this.picFormLoading = false
          //     if (e.data.code === 0) {
          //       this.$message.success(e.data.msg)
          //       this.addPicVisible = false
          //       this.attachments[this.picForm.edit_index].name =
          //         this.picForm.name
          //     } else {
          //       this.$message.error(e.data.msg)
          //     }
          //   })
          //   .catch((e) => {
          //     this.picFormLoading = false
          //   })
        }
      })
    },
    groupFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.groupFormLoading = true
          var data = this.groupForm
          console.log('🚀 ~ file: index.vue ~ line 545 ~ this.$refs[formName].validate ~ data', data)
          // return false
          // var data = Object.assign({}, this.groupForm, { type: this.tabs })
          if (this.groupForm.id) {
            var editData = { name: this.groupForm.name }
            editSort(this.groupForm.id, editData).then(res => {
              console.log('🚀 ~ file: index.vue ~ line 553 ~ editSort ~ res', res)
              this.groupFormLoading = false
              this.$message.success(res.msg)
              this.addGroupVisible = false
              this.loadGroups()
              // if (this.groupForm.edit_index > -1) {
              //   this.groupItem[this.groupForm.edit_index] = res.data.data
              // } else {
              //   // this.groupList.push(res.data.data)
              // }
            })
          } else {
            addSorts(data).then(res => {
              this.groupFormLoading = false
              this.$message.success(res.msg)
              this.addGroupVisible = false
              this.loadGroups()
              // if (this.groupForm.edit_index > -1) {
              //   this.groupItem[this.groupForm.edit_index] = res.data.data
              // } else {
              //   // this.groupList.push(res.data.data)
              // }
            })
          }
        }
      })
    },
    // 选中分组
    switchGroup(index, name) {
      if (index === -1) this.switchGroupName = '全部'
      else this.switchGroupName = name
      this.attachments = []
      this.page = 0
      this.noMore = false
      this.loading = true
      this.uploadParams = {
        attachment_group_id: index > -1 ? this.groupItem[index].id : null
      }
      this.currentAttachmentGroupId = index > -1 ? this.groupItem[index].id : 0
      this.loadList({})
    },
    // 文件列表
    loadList(params) {
      this.noMore = false
      this.selectAll = false
      // params['r'] = 'common/attachment/list'
      // params['page'] = this.page
      // params['attachment_group_id'] = this.currentAttachmentGroupId
      // params['type'] = this.tabs
      // params['is_recycle'] = this.is_recycle
      // params['keyword'] = this.keyword

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      // data.file_name = this.keyword

      var querys = []
      if (this.keyword) querys.push({ field: 'file_name', type: 'like', key: this.keyword })
      data.query = this.utils.getQueryParams(querys)

      getFiles(this.currentAttachmentGroupId, data).then(e => {
        if (!e.data.list.length) {
          this.noMore = true
        }
        for (const i in e.data.list) {
          e.data.list[i].checked = false
          e.data.list[i].selected = false
          e.data.list[i].duration = null
        }
        // this.attachments = this.attachments.concat(e.data.list);
        this.attachments = e.data.list
        this.tableData = e.data
        // this.pagination = e.data.pagination
        this.checkedAttachments = []
        this.loading = false
        this.loadingMore = false
        this.updateVideo()
      }).catch(() => {
        this.dialogVisible = false
      })
    },
    confirm() {
      this.$emit('selected', this.checkedAttachments, this.params)
      this.dialogVisible = false
      const urls = []
      for (const i in this.checkedAttachments) {
        urls.push(this.checkedAttachments[i].url)
      }
      for (const i in this.attachments) {
        this.attachments[i].checked = false
      }
      this.checkedAttachments = []
      if (!urls.length) {
        return
      }
      if (this.multiple) {
        this.$emit('input', urls)
      } else {
        this.$emit('input', urls[0])
      }
    },
    handleStart(files) {
      this.uploading = true
      this.uploadFilesNum = files.length
      this.uploadCompleteFilesNum = 0
    },
    handleSuccess(file) {
      if (
        file &&
        file.data &&
        file.status === 200
      ) {
        this.uploadCompleteFilesNum++
        this.updateVideo()
        this.$message.success(file.msg)
      } else {
        this.$message.error(file.msg)
      }
      this.loadList()
      this.uploading = false
    },
    handleComplete(files) {
      this.uploading = false
      if (this.simple) {
        const urls = []
        const attachments = []
        for (const i in files) {
          if (files[i].response.data && files[i].response.data.code === 0) {
            urls.push(files[i].response.data.data.url)
            attachments.push(files[i].response.data.data)
          }
        }
        if (!urls.length) {
          return
        }
        this.dialogVisible = false
        this.$emit('selected', attachments, this.params)
        if (this.multiple) {
          this.$emit('input', urls)
        } else {
          this.$emit('input', urls[0])
        }
      }
    },
    handleLoadMore(currentPage) {
      if (this.noMore) {
        return
      }
      this.page = currentPage
      this.loading = true
      this.loadingMore = true
      this.loadList({})
    },
    updateVideo() {
      if (!this.canvas) {
        this.canvas = document.getElementById('material-canvas')
      }
      for (const i in this.attachments) {
        if (this.matchType(this.attachments[i].path) === 'video') {
          if (this.attachments[i].duration) {
            continue
          }
          let times = 0
          let video = null
          const maxRetry = 10
          const id = 'app_material_' + this._uid + '_' + i
          const timer = setInterval(() => {
            times++
            if (times >= maxRetry) {
              clearInterval(timer)
            }
            if (!video) {
              video = document.getElementById(id)
            }
            if (!video) {
              return
            }
            console.log('🚀 ~ file: index.vue ~ line 710 ~ timer ~ video', video, video.videoWidth)
            try {
              const zoom = 0.15
              this.canvas.width = video.videoWidth * zoom
              this.canvas.height = video.videoHeight * zoom
              this.canvas.getContext('2d').drawImage(video, 0, 0, this.canvas.width, this.canvas.height)
              // const img = this.canvas.toDataURL('image/jpg')
              // console.log('🚀 ~ file: index.vue ~ line 719 ~ timer ~ img', img)
              this.attachments[i].cover_pic_src = this.canvas.toDataURL('image/jpg')
            } catch (e) {
              console.warn('获取视频封面异常: ', e)
            }

            if (video.duration && !isNaN(video.duration)) {
              let m = Math.trunc(video.duration / 60)
              let s = Math.trunc(video.duration) % 60
              m = m < 10 ? `0${m}` : `${m}`
              s = s < 10 ? `0${s}` : `${s}`
              this.attachments[i].duration = `${m}:${s}`
              clearInterval(timer)
            }
          }, 500)
        }
      }
    },
    // 根据文件名后缀区分 文件类型
    /*
       * @param: fileName - 文件名称
       * @param: 数据返回 1) 无后缀匹配 - false
       * @param: 数据返回 2) 匹配图片 - image
       * @param: 数据返回 3) 匹配 txt - txt
       * @param: 数据返回 4) 匹配 excel - excel
       * @param: 数据返回 5) 匹配 word - word
       * @param: 数据返回 6) 匹配 pdf - pdf
       * @param: 数据返回 7) 匹配 ppt - ppt
       * @param: 数据返回 8) 匹配 视频 - video
       * @param: 数据返回 9) 匹配 音频 - radio
       * @param: 数据返回 10) 其他匹配项 - other
       */
    matchType(fileName) {
      // 后缀获取
      var suffix = ''
      // 获取类型结果
      var result = ''
      try {
        var flieArr = fileName.split('.')
        suffix = flieArr[flieArr.length - 1]
      } catch (err) {
        suffix = ''
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false
        return result
      }
      suffix = suffix.toLowerCase()
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      // 进行图片匹配
      result = imglist.some(function(item) {
        return item === suffix
      })
      if (result) {
        result = 'image'
        return result
      }
      // 匹配txt
      var txtlist = ['txt']
      result = txtlist.some(function(item) {
        return item === suffix
      })
      if (result) {
        result = 'txt'
        return result
      }
      // 匹配 excel
      var excelist = ['xls', 'xlsx']
      result = excelist.some(function(item) {
        return item === suffix
      })
      if (result) {
        result = 'excel'
        return result
      }
      // 匹配 word
      var wordlist = ['doc', 'docx']
      result = wordlist.some(function(item) {
        return item === suffix
      })
      if (result) {
        result = 'word'
        return result
      }
      // 匹配 pdf
      var pdflist = ['pdf']
      result = pdflist.some(function(item) {
        return item === suffix
      })
      if (result) {
        result = 'pdf'
        return result
      }
      // 匹配 ppt
      var pptlist = ['ppt']
      result = pptlist.some(function(item) {
        return item === suffix
      })
      if (result) {
        result = 'ppt'
        return result
      }
      // 匹配 视频
      var videolist = ['mp4', 'm2v', 'mkv']
      result = videolist.some(function(item) {
        return item === suffix
      })
      if (result) {
        result = 'video'
        return result
      }
      // 匹配 音频
      var radiolist = ['mp3', 'wav', 'wmv']
      result = radiolist.some(function(item) {
        return item === suffix
      })
      if (result) {
        result = 'radio'
        return result
      }
      // 其他 文件类型
      result = 'other'
      return result
    }
  }
}
</script>

<style>
.table-body {
  padding: 20px;
  background-color: #fff;
}

.material-list {
  /* width: 1240px; */
  min-height: 635px;
  padding: 5px;
}

.material-list * {
  box-sizing: border-box;
}

.material-list:after {
  clear: both;
  display: block;
  content: " ";
}

.material-item {
  display: inline-block;
  cursor: pointer;
  position: relative;
  float: left;
  width: 160px;
  height: 180px;
  margin: 7.5px;
  text-align: center;
  padding: 10px 10px 0;
}
.material-item.checked,
.material-item.selected {
  box-shadow: 0 0 0 1px #1ed0ff;
  background: #daf5ff;
  border-radius: 5px;
}

.material-item .material-img {
  background-color: #f5f7fa;
  display: block;
}

.material-item .file-type-icon {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: #666;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-size: 24px;
}

.material-upload {
  box-shadow: none;
  border: 1px dashed #b2b6bd;
  height: 140px;
  width: 140px;
  margin: 17.5px;
  padding: 0;
}

.material-upload i {
  font-size: 30px;
  color: #909399;
}

.material-upload:hover {
  box-shadow: none;
  border: 1px dashed #409eff;
}

.material-upload:hover i {
  color: #409eff;
}

.material-upload:active {
  border: 1px dashed #20669c;
}

.material-upload:active i {
  color: #20669c;
}

.material-dialog .group-menu {
  border-right: none;
  width: 250px;
}

.material-dialog .group-menu .el-menu-item {
  padding-left: 10px !important;
  padding-right: 10px;
}

.material-dialog .group-menu .el-menu-item .el-button {
  padding: 3px 0;
}

.del-material-dialog .group-menu .el-menu-item .el-button:hover {
  background: #e2e2e2;
}

.material-dialog .group-menu .el-menu-item .el-button i {
  margin-right: 0;
}

.material-simple-upload i {
  font-size: 32px;
}

.material-video-cover {
  background-size: 100% 100%;
  background-position: center;
}

.material-video-info {
  background: rgba(0, 0, 0, 0.35);
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 1px 3px;
  font-size: 14px;
}

.material-dialog .material-name {
  color: #666666;
  font-size: 13px;
  margin-top: 0px;
  margin-right: auto;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.search .el-input__inner {
  border-right: 0;
}

.search .el-input__inner:hover {
  border: 1px solid #dcdfe6;
  border-right: 0;
  outline: 0;
}

.search .el-input__inner:focus {
  border: 1px solid #dcdfe6;
  border-right: 0;
  outline: 0;
}

.search .el-input-group__append {
  background-color: #fff;
  border-left: 0;
  width: 10%;
  padding: 0;
}

.search .el-input-group__append .el-button {
  padding: 0;
}

.search .el-input-group__append .el-button {
  margin: 0;
}

.box {
  border: 1px solid #e3e3e3;
}

.box .el-scrollbar__wrap {
  overflow-y: hidden;
}
/* https://github.com/ElemeFE/element/pull/15359 */
.el-input .el-input__count .el-input__count-inner {
  background: #fff;
  display: inline-block;
  padding: 0 5px;
  line-height: normal;
}
</style>
