var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加邀请好友记录",
        visible: _vm.visible,
        width: "30%",
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "title_title" }, [_vm._v("新增邀请记录")]),
          _c(
            "div",
            {
              staticClass: "title_icon cursor",
              on: { click: _vm.handleCloseDialog }
            },
            [_c("i", { staticClass: "el-icon-close" })]
          )
        ])
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            rules: _vm.rules,
            "label-position": "left",
            model: _vm.form,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "邀请者品牌" } },
            [
              _c("Select", {
                attrs: { type: 1 },
                on: { changeId: _vm.cardIdI }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邀请人", prop: "invite_store_user_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选参与员工",
                    disabled: !_vm.form.inviteAppid
                  },
                  model: {
                    value: _vm.form.invite_store_user_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "invite_store_user_id", $$v)
                    },
                    expression: "form.invite_store_user_id"
                  }
                },
                _vm._l(_vm.inviterData.list, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.real_name, value: item.id },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c(
                                    "el-avatar",
                                    {
                                      staticClass: "m-r-6",
                                      staticStyle: {
                                        width: "25px",
                                        height: "25px"
                                      },
                                      attrs: { src: item.avatar }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.errorAvatarSrc(item, "img")
                                        }
                                      })
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.real_name.length > 6
                                          ? item.real_name.substring(0, 5) +
                                              "..."
                                          : item.real_name
                                      )
                                    )
                                  ]),
                                  _vm._v(" - "),
                                  _c("span", [_vm._v(_vm._s(item.phone))])
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "被邀请者品牌" } },
            [
              _c("Select", {
                attrs: { type: 1 },
                on: { changeId: _vm.cardIdC }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "被邀请人", prop: "invited_store_user_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选参与员工",
                    disabled: !_vm.form.inviteeAppid
                  },
                  model: {
                    value: _vm.form.invited_store_user_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "invited_store_user_id", $$v)
                    },
                    expression: "form.invited_store_user_id"
                  }
                },
                _vm._l(_vm.inviteeData.list, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.real_name, value: item.id },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c(
                                    "el-avatar",
                                    {
                                      staticClass: "m-r-6",
                                      staticStyle: {
                                        width: "25px",
                                        height: "25px"
                                      },
                                      attrs: { src: item.avatar }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.errorAvatarSrc(item, "img")
                                        }
                                      })
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.real_name.length > 6
                                          ? item.real_name.substring(0, 5) +
                                              "..."
                                          : item.real_name
                                      )
                                    )
                                  ]),
                                  _vm._v(" - "),
                                  _c("span", [_vm._v(_vm._s(item.phone))])
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "佣金金额", prop: "cashback" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入佣金金额", type: "number" },
                  model: {
                    value: _vm.form.cashback,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "cashback", $$v)
                    },
                    expression: "form.cashback"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邀请凭证", prop: "proof" } },
            [
              _c(
                "el-upload",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.upLoading,
                      expression: "upLoading"
                    }
                  ],
                  staticClass: "upload-venues",
                  attrs: {
                    action: _vm.adminUpload,
                    "on-success": _vm.upSuccess,
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "file-list": _vm.fileList,
                    "list-type": "picture-card",
                    headers: _vm.utils.upload_headers(),
                    limit: 6,
                    "on-exceed": _vm.handleExceed,
                    "on-progress": _vm.upProgress,
                    "before-upload": _vm.checkFileType,
                    accept: ".jpg,.jpeg,.png"
                  }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _c(
                "el-dialog",
                {
                  staticClass: "dialog_view_image",
                  attrs: { visible: _vm.dialogVisibleS },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisibleS = $event
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading },
              on: { click: _vm.handleCloseDialog }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.buttonLoading },
              on: {
                click: function($event) {
                  return _vm.handleConfirm("ruleForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }