<template>
  <div>
    <!-- 外层 -->
    <el-dialog
      title="场馆设施"
      :visible.sync="dialogVisible"
      width="40%"
      :show-close="is_edit"
      :close-on-click-modal="is_edit"
      :close-on-press-escape="is_edit"
      @open="dialogOpen"
      @close="dialogClose"
    >
      <div class="centerTop">
        <h3>约课段展示设施</h3>
        <div>场馆设施可为场馆设施，特色课程，服务优势等。一下为系统默认添加的标签，可自行修改</div>
      </div>
      <div>
        <span>已展示设施：</span>
        <el-select
          v-model="selectValue"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="请选择已展示设施                                                              "
        >
          <el-option v-for="item in tableList" :key="item.id" :label="item.device_name" :value="item.id" />
        </el-select>
      </div>
      <el-divider />
      <div class="center">
        <h3>添加标签</h3>
        <el-button type="primary" icon="el-icon-plus" @click="innerVisible = true">添加</el-button>
      </div>
      <div v-loading="listLoading" class="contents">
        <div v-for="item in tableList" :key="item.id" class="content">
          <div class="contentss">
            <div class="img">
              <img :src="item.device_image" alt="">
            </div>
            <div class="user">
              <div class="font">{{ item.device_name }}</div>
              <div v-if="item.device_describe" class="fontNum">{{ item.device_describe }}</div>
              <div v-if="!item.device_describe">暂无描述信息~</div>
            </div>
          </div>
          <div class="icon">
            <i class="el-icon-edit-outline cursor" @click="handelEdit(item)" />
            <i class="el-icon-delete cursor" @click="handelDelete(item)" />
          </div>
        </div>
      </div>
      <!-- 内层 Dialog -->
      <el-dialog
        width="30%"
        title="选择设施类型"
        :visible.sync="innerVisible"
        append-to-body
        @open="dialogOpens"
        @close="dialogCloses"
      >
        <div v-loading="divLoading">
          <el-tag
            v-for="item in dataList"
            :key="item.name"
            style="margin: 0 10px 10px 0;"
            type="info"
            effect="plain"
            class="cursor"
            @click="handelAdd(item)"
          >
            {{ item.name }}
          </el-tag>
        </div>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handelCancel">取 消</el-button>
        <el-button type="primary" @click="handelSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 表单 -->
    <el-dialog title="场馆设施" :visible.sync="dialogVisibles" width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="设施类型">
          <el-select v-model="form.device_type" placeholder="请选择设施类型">
            <el-option v-for="item in dataList" :key="item.type" :label="item.name" :value="item.type" />
          </el-select>
        </el-form-item>
        <el-form-item label="设施图像">
          <el-upload
            v-loading="upLoading"
            class="images-uploader"
            :action="storeUpload"
            :show-file-list="false"
            :on-success="upSuccess"
            :headers="utils.upload_headers()"
            :on-progress="upProgress"
            accept=".jpg,.jpeg,.png"
          >
            <img v-if="form.device_image" :src="form.device_image" class="images" title="点击上传图片">
            <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
          </el-upload>
        </el-form-item>
        <el-form-item label="设施名称">
          <el-input v-model="form.device_name" />
        </el-form-item>
        <el-form-item label="设施内容">
          <el-input v-model="form.device_describe" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handelCancels">取 消</el-button>
        <el-button type="primary" :rows="4" @click="handelSures(form.id)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// API
import {
  getList,
  getAdd,
  getDeviceList,
  deleteDevice,
  getDevice,
  putDevice
} from '@/api/store/venues.js'

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisibles: false,
      innerVisible: false,
      is_edit: false,
      selectValue: [],
      dataList: [],
      tableList: [],
      divLoading: false,
      listLoading: false,
      form: {
        id: '',
        device_type: '',
        device_image: '',
        device_name: '',
        device_describe: ''
      },
      yxj: 0
    }
  },
  watch: {},
  created() {},
  methods: {
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.form.device_image = response.data.path
    },
    upProgress() {
      this.upLoading = true
    },
    // 打开的回调
    dialogOpen() {
      this.listLoading = true
      this.selectValue = []
      getDeviceList().then(res => {
        const data = res.data
        const dataList = []
        this.tableList = data.list
        this.tableList.forEach(item => {
          if (item.is_show === 1) {
            dataList.push(item.id)
          }
        })
        this.selectValue = dataList
        this.listLoading = false
      })
    },
    // 关闭的回调
    dialogClose() {
      console.log('关闭的回调')
    },
    // 打开的回调
    dialogOpens() {
      this.divLoading = true
      getList().then(res => {
        this.dataList = res.data
        this.divLoading = false
      })
    },
    // 关闭的回调
    dialogCloses() {
      console.log('内部关闭的回调')
    },
    // 外层确定
    handelSure() {
      this.$emit('data', this.selectValue)
      this.$emit('list', this.tableList)
    },
    // 外层取消
    handelCancel() {
      this.$emit('close')
    },
    // 点击设施打开表单
    handelAdd(item) {
      this.form.id = item.id
      this.form.device_type = item.type
      this.form.device_image = item.icon
      this.form.device_name = item.name
      this.form.device_describe = ''
      this.yxj = 0
      this.dialogVisibles = true
    },
    // 点击编辑打开表单
    handelEdit(item) {
      getDevice(item.id).then(res => {
        this.form.id = res.data.id
        this.form.device_type = res.data.device_type
        this.form.device_image = res.data.device_image
        this.form.device_name = res.data.device_name
        this.form.device_describe = res.data.device_describe
        this.yxj = 1
        this.dialogVisibles = true
      })
    },
    // 表单取消
    handelCancels() {
      this.dialogVisibles = false
    },
    // 表单确定
    handelSures(id) {
      if (this.yxj === 0) {
        getAdd(this.form)
          .then(() => {
            this.dialogOpen()
            this.dialogVisibles = false
          })
          .catch()
      } else if (this.yxj === 1) {
        putDevice(id, this.form)
          .then(() => {
            this.dialogOpen()
            this.dialogVisibles = false
          })
          .catch()
      }
    },
    // 删除按钮
    handelDelete(item) {
      this.$confirm('此操作将永久删除该设施, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteDevice(item.id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.dialogOpen()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.centerTop {
  display: flex;
  align-items: center;
  div {
    margin-left: 10px;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409eff;
}
.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.contents {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  .content {
    width: 40%;
    margin-right: 10%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 20px;
    border: 1px solid #ccc;
    .contentss {
      flex: 3;
      display: flex;
      .img {
        width: 40px;
        margin-right: 10px;
        flex: 1;
        img {
          display: block;
          width: 100%;
        }
      }
      .user {
        flex: 3;
        .font {
          font-size: 16px;
          font-weight: 700;
        }
      }
      .fontNum {
        width: 120px; //宽度
        overflow: hidden; //超出文本隐藏
        white-space: nowrap; //不换行，只显示一行
        text-overflow: ellipsis; //超出部分省略号显示
      }
    }
    .icon {
      flex: 1;
      i {
        margin-right: 10px;
      }
    }
  }
}
</style>
