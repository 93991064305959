import request from '@/utils/request'

// 获取配置
export function getConfig(id, data) {
  return request({
    url: `/admin/v1/config_classify/${id}`,
    method: 'get',
    params: data
  })
}
// 获取配置列表
export function getConfigList(group) {
  return request({
    url: '/admin/v1/config_classify',
    method: 'get',
    params: {
      group
    }
  })
}

// 保存配置分类
export function editConfig(id, data) {
  return request({
    url: `/admin/v1/config_classify/${id}`,
    method: 'put',
    data
  })
}

// 新增配置分类
export function addConfig(data) {
  return request({
    url: '/admin/v1/config_classify',
    method: 'post',
    data
  })
}

// 删除配置分类
export function deleteConfig(id) {
  return request({
    url: `/admin/v1/config_classify/${id}`,
    method: 'delete'
  })
}
// ----------------------------配置字段

// 获取字段列表
export function getFieldList(id, data) {
  return request({
    url: `/admin/v1/config/classify_id/${id}`,
    method: 'get',
    params: data
  })
}
// 保存Field
export function editField(id, data) {
  return request({
    url: `/admin/v1/config/${id}`,
    method: 'put',
    data
  })
}

// 新增Field
export function addField(data) {
  return request({
    url: '/admin/v1/config',
    method: 'post',
    data
  })
}

// 删除Field
export function deleteField(id) {
  return request({
    url: `/admin/v1/config/${id}`,
    method: 'delete'
  })
}

