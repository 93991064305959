<template>
  <div class="app-container" size="medium">
    <!-- 按钮 -->
    <el-tabs v-model="mallType" @tab-click="handleChange">
      <el-tab-pane label="售卡" name="0" />
      <el-tab-pane label="秒杀" name="1" />
    </el-tabs>
    <!-- 搜索 -->
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="80px" label-position="left">
        <el-form-item label="商城推荐">
          <el-select v-model="listQueryParams.is_recommend" placeholder="商城推荐" clearable>
            <el-option label="未开启" :value="0" />
            <el-option label="已开启" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="上下架">
          <el-select v-model="listQueryParams.is_hidden" placeholder="请选上下架类型" clearable>
            <el-option label="上架" :value="0" />
            <el-option label="下架" :value="1" />
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </div>
    </div>
    <div class="filter-container">
      <div class="filter-item">
        <el-input
          v-model="listQueryParams.query"
          placeholder="搜索 活动名称"
          @keyup.enter.native="handleFilter"
        >
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button
        v-show="btn_access('s_seckill_add') && parseInt(mallType) === 0"
        v-waves
        class="filter-item m-l-10"
        type="primary"
        icon="el-icon-plus"
        @click="$router.push({ name: 's_seckill_add', params: { mallType: parseInt(mallType) } })"
      >
        添加
      </el-button>
      <el-button
        v-show="btn_access('s_seckill2_add') && parseInt(mallType) === 1"
        v-waves
        class="filter-item m-l-10"
        type="primary"
        icon="el-icon-plus"
        @click="$router.push({ name: 's_seckill_add', params: { mallType: parseInt(mallType) } })"
      >
        添加
      </el-button>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
      header-align="center"
    >
      <el-table-column prop="name" label="活动名称" align="center" />
      <el-table-column prop="card.name" label="关联会员卡" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.sales_method === 0"><span :class="row.card ? '' : 'text-danger'">{{ row.card ? row.card.name : '暂无会员卡' }}</span></div>
          <div v-else-if="row.sales_method === 1"><span :class="row.cards.length > 0 ? '' : 'text-danger'">{{ row.cards.length > 0 ? `${row.cards[0].name} 等 ${row.cards.length} 张会员卡` : '暂无会员卡' }}</span></div>
          <div v-else><span :class="row.card_combo ? '' : 'text-danger'">{{ row.card_combo ? row.card_combo.name : '暂无会员卡' }}（组合卡）</span></div>
        </template>
      </el-table-column>
      <el-table-column prop="images" label="活动图片" align="center">
        <template slot-scope="{ row }">
          <img :src="row.images" alt="" style="width:50px;">
        </template>
      </el-table-column>
      <!-- <el-table-column prop="seckill_price" label="秒杀价" /> -->
      <el-table-column prop="start_time" label="价格/优惠" min-width="120" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.is_seckill === 1" class="discount">
            秒杀价：{{ row.seckill_price }} 元
            <br>
            秒杀限购：{{ row.seckill_restriction === 0 ? '未开启' : row.seckill_restriction }}
            <div>开始时间：{{ row.start_time }}</div>
            <div>结束时间：{{ row.end_time }}</div>
          </div>
          <div v-else>
            原价：{{ row.line_price }} 元
            <br>
            售价：{{ row.price }} 元
            <br>
            限购数量每人：{{ row.restriction === 0 ? '未开启' : row.restriction }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="inventory" label="库存" width="100" align="center" />
      <el-table-column prop="is_hidden" label="上下架" width="100" align="center">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_hidden == 0" type="success">上架</el-tag>
          <el-tag v-else type="info">下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_hidden" label="商城推荐" width="100" align="center">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_recommend == 1" type="success">已开启</el-tag>
          <el-tag v-else type="info">未开启</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="120px">
        <template slot-scope="scope">
          <el-button
            v-show="btn_access('s_seckill_edit') && parseInt(mallType) === 0"
            class="m-2"
            type="primary"
            icon="el-icon-edit"
            :offset="2"
            @click="$router.push({ name: 's_seckill_edit', params: { id: scope.row.id, mallType: parseInt(mallType) } })"
          >
            编辑
          </el-button>
          <el-button
            v-show="btn_access('s_seckill2_edit') && parseInt(mallType) === 1"
            class="m-2"
            type="primary"
            icon="el-icon-edit"
            :offset="2"
            @click="$router.push({ name: 's_seckill_edit', params: { id: scope.row.id, mallType: parseInt(mallType) } })"
          >
            编辑
          </el-button>
          <el-popconfirm title="这是一段内容确定删除吗？" @confirm="plan_delete(scope.row.id)">
            <el-button
              v-show="btn_access('s_seckill_del') && parseInt(mallType) === 0"
              slot="reference"
              class="m-2"
              type="danger"
              icon="el-icon-delete"
              :offset="2"
            >
              删除
            </el-button>
          </el-popconfirm>
          <el-popconfirm title="这是一段内容确定删除吗？" @confirm="plan_delete(scope.row.id)">
            <el-button
              v-show="btn_access('s_seckill2_del') && parseInt(mallType) === 1"
              slot="reference"
              class="m-2"
              type="danger"
              icon="el-icon-delete"
              :offset="2"
            >
              删除
            </el-button>
          </el-popconfirm>
          <el-dropdown class="course" :hide-on-click="false">
            <el-button type="info">
              更多
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-show="btn_access('s_seckill_info') " @click.native="$router.push({ name: 's_seckill_details', params: { id: scope.row.id } })">查看详情</el-dropdown-item>
              <el-dropdown-item v-show="btn_access('s_seckill_comment')" @click.native="$router.push({ name: 's_seckill_comment', params: { id: scope.row.id } })">卡项评论</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { getSeckillList, delSeckill } from '@/api/store/seckill.js'
import Pagination from '@/components/Pagination' // 分页组件

export default {
  components: { Pagination },
  data() {
    return {
      mallType: '0',
      tableData: {
        list: [],
        count: 0
      },
      loading: { listLoading: false },
      searchLoading: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    setQuery() {
      var querys = []
      if (this.listQueryParams.query) {
        querys.push({ field: 'name', type: 'like', key: this.listQueryParams.query })
      }
      if (this.listQueryParams.is_recommend !== '' && this.listQueryParams.is_recommend !== undefined) {
        querys.push({ field: 'is_recommend', key: this.listQueryParams.is_recommend })
      }
      if (this.listQueryParams.is_hidden !== '' && this.listQueryParams.is_hidden !== undefined) {
        querys.push({ field: 'is_hidden', key: this.listQueryParams.is_hidden })
      }
      querys.push({ field: 'is_seckill', key: parseInt(this.mallType) })
      return this.utils.getQueryParams(querys)
    },
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()

      getSeckillList(data).then(res => {
        this.tableData = res.data
        this.loading.listLoading = false
        this.searchLoading = false
      })
    },
    plan_delete(id) {
      delSeckill(id).then(res => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleChange() {
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.discount {
  color: #ff9945;
}
</style>
