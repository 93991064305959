var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container special-center"
    },
    [
      _c("el-page-header", {
        staticClass: "page-header",
        attrs: { content: "请假详情" },
        on: {
          back: function($event) {
            return _vm.returnPage()
          }
        }
      }),
      _c("div", { staticClass: "image" }, [
        _vm.tableData.user_membership_card
          ? _c(
              "div",
              [
                _c(
                  "el-avatar",
                  {
                    attrs: {
                      shape: "square",
                      size: 100,
                      src: _vm.tableData.user_membership_card.user.avatar
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.errorAvatarSrc(
                          _vm.tableData.user_membership_card.user,
                          "img"
                        )
                      }
                    })
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "warning", icon: "el-icon-edit" },
                on: {
                  click: function($event) {
                    return _vm.handelCZ(_vm.tableData.user_membership_card)
                  }
                }
              },
              [_vm._v("操作")]
            )
          ],
          1
        )
      ]),
      _vm.tableData.user_membership_card
        ? _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "基本信息",
                column: 2,
                "label-style": _vm.rowCenter,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "请假人" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tableData.user_membership_card.user
                        ? _vm.tableData.user_membership_card.user.nickname
                        : "用户数据丢失"
                    ) +
                    " "
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "卡号" } }, [
                _vm._v(_vm._s(_vm.tableData.user_membership_card.number))
              ]),
              _c("el-descriptions-item", { attrs: { label: "卡名称" } }, [
                _vm._v(_vm._s(_vm.tableData.user_membership_card.card.name))
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "请假状态" } },
                [_c("el-tag", [_vm._v(_vm._s(_vm.tableData.ask_status_text))])],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.user_membership_card
        ? _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                title: "请假信息",
                column: 2,
                "label-style": _vm.rowCenter,
                border: ""
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "请假开始时间" } }, [
                _vm._v(_vm._s(_vm.tableData.start_time))
              ]),
              _c("el-descriptions-item", { attrs: { label: "请假结束时间" } }, [
                _vm._v(_vm._s(_vm.tableData.end_time))
              ]),
              _vm.tableData.user_membership_card
                ? _c("el-descriptions-item", { attrs: { label: "请假天数" } }, [
                    _vm._v(" " + _vm._s(_vm.tableData.ask_day) + " 天 ")
                  ])
                : _vm._e(),
              _vm.tableData.user_membership_card.valid_time_text
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "剩余有效期" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tableData.user_membership_card.valid_time_text
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.tableData.user_membership_card.card
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "最多可请假次数" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tableData.user_membership_card.card.setting
                              .leave_number === 0
                              ? "不限"
                              : _vm.tableData.user_membership_card.card.setting
                                  .leave_number
                          ) +
                          " 次 "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.tableData.user_membership_card.card
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "最多可请假天数" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tableData.user_membership_card.card.setting
                              .leave_day === 0
                              ? "不限"
                              : _vm.tableData.user_membership_card.card.setting
                                  .leave_day
                          ) +
                          " 天 "
                      )
                    ]
                  )
                : _vm._e(),
              _c("el-descriptions-item", { attrs: { label: "请假事由" } }, [
                _vm._v(
                  _vm._s(_vm.tableData.remark ? _vm.tableData.remark : "无")
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._m(0),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.tableDataList } },
        [
          _c("el-table-column", {
            attrs: { label: "操作人", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.store_user.real_name))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作类型", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.status.name))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.status.name) +
                        "：" +
                        _vm._s(scope.row.ask_day) +
                        " 天 "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "操作时间" }
          })
        ],
        1
      ),
      _vm.dialog.operation
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog_auto",
              attrs: {
                title: "操作",
                "append-to-body": "",
                "close-on-click-modal": false,
                visible: _vm.dialog.operation,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.dialog, "operation", $event)
                }
              }
            },
            [
              _c("Operation", {
                attrs: { "card-data": _vm.dialog.row },
                on: { callback: _vm.operation_callback },
                model: {
                  value: _vm.dialog.operation,
                  callback: function($$v) {
                    _vm.$set(_vm.dialog, "operation", $$v)
                  },
                  expression: "dialog.operation"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h3", { staticStyle: { "font-size": "16px" } }, [_vm._v("请假记录")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }