import { render, staticRenderFns } from "./signWeChat.vue?vue&type=template&id=2f6087d9&scoped=true&"
import script from "./signWeChat.vue?vue&type=script&lang=js&"
export * from "./signWeChat.vue?vue&type=script&lang=js&"
import style1 from "./signWeChat.vue?vue&type=style&index=1&id=2f6087d9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6087d9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\拾谷科技\\悠然瑜伽\\admin\\yoga-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f6087d9')) {
      api.createRecord('2f6087d9', component.options)
    } else {
      api.reload('2f6087d9', component.options)
    }
    module.hot.accept("./signWeChat.vue?vue&type=template&id=2f6087d9&scoped=true&", function () {
      api.rerender('2f6087d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/merchants/info/weChat/components/weChatAllocation/signWeChat.vue"
export default component.exports