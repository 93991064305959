<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <el-form-item label="时间范围">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索订单号" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="number" label="订单号" />
      <el-table-column prop="pay_price" label="应付金额">
        <template slot-scope="{row}">
          ￥{{ row.pay_price }}
        </template>
      </el-table-column>
      <el-table-column prop="pay_status" label="订单状态">
        <template slot-scope="{row}">
          <el-tag v-if="row.pay_status==1" type="success">已完成</el-tag>
          <el-tag v-if="row.pay_status==0" type="warning">未完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" />
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { getSmsRechargeOrder } from '@/api/store/sms.js'
import Pagination from '@/components/Pagination' // 分页组件
export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      dialog: { visible: false, id: '' },
      loading: { listLoading: false },
      form: {
        name: '',
        price: '',
        line_price: '0',
        time: '',
        time_type: 'month'
      },
      rules: {
        name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' }],
        price: [{ required: true, message: '请输入套餐价格', trigger: 'blur' }],
        time: [{ required: true, message: '请输入时间周期', trigger: 'blur' }]
      },
      timeList: [{
        value: 'day',
        label: '天'
      }, {
        value: 'month',
        label: '月'
      }, {
        value: 'year',
        label: '年'
      }],
      sms_template_list: {},
      searchLoading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'number', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.create_time) querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })

      data.query = this.utils.getQueryParams(querys)

      getSmsRechargeOrder(data).then(res => {
        this.tableData = res.data
        this.loading.listLoading = false
        this.searchLoading = false
      })
    },
    valid_period(item) {
      var valid_period = JSON.parse(item.valid_period)
      const digital = valid_period['digital']
      const type = valid_period['type']
      var time = ''
      switch (type) {
        case 'month':
          time = '个月'
          break
        case 'day':
          time = '天'
          break
        case 'year':
          time = '年'
          break
        default:
          break
      }
      return digital + time
    }
  }
}
</script>

<style  lang="scss" scoped>
.el-input-group__append, .el-input-group__prepend{
  width: 70px;
}
</style>
