var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%", "padding-top": "15px" },
      attrs: { data: _vm.list }
    },
    [
      _c("el-table-column", {
        attrs: { label: "Order_No", "min-width": "200" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("orderNoFilter")(scope.row.order_no)) +
                    " "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Price", width: "195", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  " ¥" +
                    _vm._s(_vm._f("toThousandFilter")(scope.row.price)) +
                    " "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Status", width: "100", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "el-tag",
                  { attrs: { type: _vm._f("statusFilter")(row.status) } },
                  [_vm._v(" " + _vm._s(row.status) + " ")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }