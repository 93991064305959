var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slide_footer" }, [
    _c("div", { staticClass: "flex row-center slide_footer_box text-center" }, [
      _c(
        "div",
        { staticClass: "slide_item" },
        [
          _c("el-image", {
            staticStyle: { width: "80px", height: "80px" },
            attrs: { src: _vm.img1, alt: "", "preview-src-list": [_vm.img1] }
          }),
          _c("div", [_vm._v("官方微信")])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "slide_item" },
        [
          _c("el-image", {
            staticStyle: { width: "80px", height: "80px" },
            attrs: { src: _vm.img2, alt: "", "preview-src-list": [_vm.img2] }
          }),
          _c("div", [_vm._v("手机端APP")])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }