var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", [
    _c(
      "div",
      {
        staticClass: "clearfix flex",
        attrs: { slot: "header" },
        slot: "header"
      },
      [
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.type === 1
                ? "我的相册"
                : _vm.type === 2
                ? "员工相册"
                : _vm.type === 3
                ? "场馆相册"
                : _vm.type === 4
                ? "课程相册"
                : ""
            )
          )
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.btn_access("s_venues_photo_sort") ||
                  _vm.btn_access("s_course_photo_sort"),
                expression:
                  "btn_access('s_venues_photo_sort') || btn_access('s_course_photo_sort')"
              }
            ],
            staticClass: "m-l-10"
          },
          [
            !_vm.is_sort
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini", plain: "" },
                    on: { click: _vm.handleSort }
                  },
                  [_vm._v("排 序")]
                )
              : _vm._e(),
            _vm.is_sort
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "success",
                      size: "mini",
                      plain: "",
                      loading: _vm.buttonLoading
                    },
                    on: { click: _vm.handlePreserve }
                  },
                  [_vm._v("保 存")]
                )
              : _vm._e(),
            _vm.is_sort
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      plain: "",
                      loading: _vm.buttonLoading
                    },
                    on: { click: _vm.handleCancel }
                  },
                  [_vm._v("取 消")]
                )
              : _vm._e()
          ],
          1
        )
      ]
    ),
    !_vm.is_sort
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dialogLoading,
                expression: "dialogLoading"
              }
            ]
          },
          [
            _c(
              "el-upload",
              {
                attrs: {
                  field: "file",
                  action:
                    "" +
                    _vm.url +
                    (_vm.type === 1
                      ? "/store/v2/userInfo/photo"
                      : _vm.type === 2
                      ? "/store/v2/store_user/photo/" + _vm.id
                      : _vm.type === 3
                      ? "/store/v2/venues/photo?venues_id=" + _vm.id
                      : _vm.type === 4
                      ? "/store/v2/course/photo/" + _vm.id
                      : ""),
                  "list-type": "picture-card",
                  "on-preview": _vm.handlePreview,
                  "on-remove": _vm.handleRemove,
                  "on-success": _vm.upSuccesss,
                  headers: _vm.utils.upload_headers(),
                  "file-list": _vm.fileList,
                  limit: 9,
                  "on-exceed": _vm.handleExceed,
                  "on-progress": _vm.upProgresss,
                  "before-upload": _vm.checkFileType,
                  accept: ".jpg,.jpeg,.png"
                }
              },
              [_c("i", { staticClass: "el-icon-plus" })]
            ),
            _c(
              "el-dialog",
              {
                staticClass: "dialog_view_image",
                attrs: { visible: _vm.dialogVisible },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogVisible = $event
                  }
                }
              },
              [
                _c("img", {
                  attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
                })
              ]
            ),
            _c("small", { staticClass: "text-info" }, [
              _vm._v("图片尺寸建议750*1000")
            ])
          ],
          1
        )
      : _vm._e(),
    _vm.is_sort
      ? _c("div", [
          _c(
            "div",
            { ref: "multipleTable", staticClass: "flex flex-wrap" },
            _vm._l(_vm.fileList, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "m-r-8 m-b-8 cursor_move images" },
                [
                  _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: item.url }
                  })
                ]
              )
            }),
            0
          ),
          _c("small", { staticClass: "text-info" }, [
            _vm._v("拖拽图片进行排序")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }