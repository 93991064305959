import request from '@/utils/request'
import Utils from '@/utils/utils.js'
// 场馆、会员

// 获取场馆列表
export function getVenues(data) {
  return request({
    url: '/store/v1/venues',
    method: 'get',
    params: data
  })
}

// 获取专属客服
export function getVenuesService(data) {
  return request({
    url: '/store/v1/accountManager',
    method: 'get',
    params: data
  })
}

// 获取全场馆角色
export function getVenuesRole(data) {
  return request({
    url: '/store/v1/venues_role',
    method: 'get',
    params: data
  })
}

export function getVenuesRoleS(data) {
  return request({
    url: '/store/v1/role',
    method: 'get',
    params: data
  })
}

// 添加场馆
export function addVenues(data) {
  return request({
    url: '/store/v1/venues',
    method: 'post',
    data
  })
}

// 获取场馆详情
export function getVenuesInfo(id) {
  return request({
    url: `/store/v1/venues/${id}`,
    method: 'get'
  })
}

// 更新场馆信息
export function editVenues(id, data) {
  return request({
    url: `/store/v1/venues/${id}`,
    method: 'put',
    data
  })
}

// 场馆放假
export function holidayVenues(data) {
  return request({
    url: `/store/v2/venues_holiday`,
    method: 'post',
    data
  })
}

// 场馆放假记录
export function holidayLogsVenues(venues_id, data) {
  return request({
    url: `/store/v1/venues_holiday/venues_id/${venues_id || Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 场馆取消放假
export function holidayVenuesCancel(id, data) {
  return request({
    url: `/store/v2/venues_holiday/cancel/${id}`,
    method: 'post',
    data
  })
}

// 获取场馆相册
export function getVenueAlbum(params) {
  return request({
    url: `/store/v2/venues/photo`,
    method: 'get',
    params
  })
}
// 删除场馆相册
export function deleteVenueAlbum(id) {
  return request({
    url: `/store/v2/venues/photo/${id}`,
    method: 'delete'
  })
}
// 场馆相册排序
export function putVenueAlbum(data) {
  return request({
    url: `/store/v2/venues/photo/sort`,
    method: 'put',
    data
  })
}

// 根据登录用户获取场馆列表
export function getUserVenues() {
  return request({
    url: `/store/v1/employee/venues`,
    method: 'get'
  })
}

// 员工登录日志
export function getStaffSignIn() {
  return request({
    url: `/store/v1/user_audit_log`,
    method: 'get'
  })
}

// 删除记录
export function delStaffSignIn(id) {
  return request({
    url: `/store/v1/user_audit_log/${id}`,
    method: 'put'
  })
}

// 获取教室列表
export function getClassroom(data) {
  return request({
    url: `/store/v1/classroom/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
// 添加教室
export function addClassroom(data) {
  return request({
    url: '/store/v1/classroom',
    method: 'post',
    data
  })
}
// 获取教室详情
export function getClassroomDetails(id) {
  return request({
    url: `/store/v1/classroom/${id}`,
    method: 'get'
  })
}
// 更改教室详情
export function editClassroom(id, data) {
  return request({
    url: `/store/v1/classroom/${id}`,
    method: 'put',
    data
  })
}
// 删除教室
export function delClassroom(id, sync) {
  return request({
    url: `/store/v1/classroom_delete/${id}/${sync}`,
    method: 'delete'
  })
}
// 同步删除教室
export function delClassroomSync(id) {
  return request({
    url: `/store/v1/classroom_sync/${id}`,
    method: 'delete'
  })
}
// 获取教练列表
export function getCoach(data) {
  return request({
    url: `/store/v1/coach/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 获取员工列表
export function getEmployee(data) {
  return request({
    url: `/store/v1/employee/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
export function getEmployeeAll(data) {
  return request({
    url: `/store/v1/employee/venues_id/-1`,
    method: 'get',
    params: data
  })
}
export function getEmployeeId(id) {
  return request({
    url: `/store/v1/store_user/audit/${id}`,
    method: 'get'
  })
}
// 待审核列表
export function getReviewedList(data) {
  return request({
    url: `/store/v1/store_user/audit/pending`,
    method: 'get',
    params: data
  })
}
// 获取员工详情
export function getEmployeeDetails(id, venues_id) {
  return request({
    url: `/store/v1/employee/${id}/venues_id/${venues_id || Utils.getVenues().id}`,
    method: 'get'
  })
}
// 更改员工详情
export function editEmployee(id, data) {
  return request({
    url: `/store/v1/employee/${id}`,
    method: 'put',
    data
  })
}
// 添加员工
export function addEmployee(data) {
  return request({
    url: '/store/v1/employee',
    method: 'post',
    data
  })
}
// 获取员工约课时段
export function getTimeQuantum(id, venues_id, data) {
  return request({
    url: `/store/v1/employee/time_quantum/${id}/venues_id/${venues_id || Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
// 修改员工上课时段
export function updateTimeQuantum(id, venues_id, data) {
  return request({
    url: `/store/v1/employee/time_quantum/${id}/venues_id/${venues_id || Utils.getVenues().id}`,
    method: 'put',
    data
  })
}
// 获取员工约课时段详情
export function getStoreUserTimeQuantum(id, venues_id, date, data) {
  return request({
    url: `/store/v1/employee/time_quantum/${id}/venues_id/${venues_id || Utils.getVenues().id}/date/${date}`,
    method: 'get',
    params: data
  })
}
/**
 * 场馆end
 */

/**
 * 会员卡start
 */

// 获取会员卡列表
export function getVipCard(data) {
  return request({
    url: `/store/v1/membership_card/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
// 根据场馆获取会员卡列表
export function getVenuesVipCard() {
  return request({
    url: `/store/v1/venues/${Utils.getVenues().id}/card`,
    method: 'get'
  })
}
// 添加会员卡
export function addVipCard(data) {
  return request({
    url: '/store/v1/membership_card',
    method: 'post',
    data
  })
}

// 获取会员卡详情
export function getVipCardDetails(id) {
  return request({
    url: `/store/v1/membership_card/${id}`,
    method: 'get'
  })
}
// 更新会员卡
export function editVipCardDetails(id, data) {
  return request({
    url: `/store/v1/membership_card/${id}`,
    method: 'put',
    data
  })
}
// 升级会员卡为多馆卡
export function upVipCard(id, data) {
  return request({
    url: `/store/v1/membership_card/${id}/upgrade`,
    method: 'put',
    data
  })
}
// 降级会员卡为场馆卡
export function downVipCard(id, data) {
  return request({
    url: `/store/v1/membership_card/${id}/demotion`,
    method: 'put',
    data
  })
}
// 删除会员卡
export function delVipCard(id, data) {
  return request({
    url: `/store/v1/membership_card/${id}`,
    method: 'delete',
    data
  })
}

// 获取默认设施列表
export function getList() {
  return request({
    url: `/store/v1/venues/device/default_list`,
    method: 'get'
  })
}
// 新增设施
export function getAdd(data) {
  return request({
    url: `/store/v1/venues/device`,
    method: 'post',
    data
  })
}
// 获取设施列表
export function getDeviceList() {
  return request({
    url: `/store/v1/venues/device`,
    method: 'get'
  })
}
// 删除设施
export function deleteDevice(id) {
  return request({
    url: `/store/v1/venues/device/${id}`,
    method: 'delete'
  })
}
// 批量显示
export function deleteSetShow(data) {
  return request({
    url: `/store/v1/venues/device/set_show`,
    method: 'post',
    data
  })
}
// 设施详情
export function getDevice(id) {
  return request({
    url: `/store/v1/venues/device/${id}`,
    method: 'get'
  })
}
// 更新设施信息
export function putDevice(id, data) {
  return request({
    url: `/store/v1/venues/device/${id}`,
    method: 'put',
    data
  })
}
// 场馆标签列表
export function getVenueLabelList() {
  return request({
    url: `/store/v1/venues/tag`,
    method: 'get'
  })
}
// 新增场馆标签
export function postVenueLabel(data) {
  return request({
    url: `/store/v1/venues/tag`,
    method: 'post',
    data
  })
}
// 删除标签
export function deleteVenueLabel(id) {
  return request({
    url: `/store/v1/venues/tag/${id}`,
    method: 'delete'
  })
}
// 更新标签
export function putVenueLabel(id, data) {
  return request({
    url: `/store/v1/venues/tag/${id}`,
    method: 'put',
    data
  })
}
// 审批
export function postApproval(id, data) {
  return request({
    url: `/store/v1/store_user/audit/${id}`,
    method: 'post',
    data
  })
}

// 馆内员工不分页列表
export function getEmployeeList() {
  return request({
    url: `/store/v1/store_user/teacher/venues`,
    method: 'get'
  })
}

// 员工排序上传
export function postEmployeeSort(data) {
  return request({
    url: `/store/v1/store_user/teacher/sort`,
    method: 'post',
    data
  })
}
