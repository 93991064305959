<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <!-- Info 自定义检索方法，不与通用检索同步 -->
        <el-form :model="listQuery" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <el-form-item label="时间范围">
            <el-date-picker
              v-model="listQuery.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <!-- 持卡会员 -->
          <el-form-item label="持卡会员" prop="memberID">
            <Select :type="2" @changeId="handleMemberID" />
          </el-form-item>
          <el-form-item label="付款方式">
            <el-select v-model="listQuery.payment_method_id" placeholder="请选择" clearable>
              <el-option v-for="(item, index) in payment_method" :key="index" :label="item" :value="index" />
            </el-select>
          </el-form-item>
          <el-form-item label="渠道">
            <el-select v-model="listQuery.channel" placeholder="请选择" clearable>
              <el-option v-for="(item, index) in channelList" :key="index" :label="item" :value="index" />
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
    </div>
    <div class="total m-y-20 font-16">
      累计发卡
      <span class="text-success p-x-2">
        <count-to :start-val="0" :end-val="tableData.count" :duration="2000" :decimals="0" />
      </span>
      张，发卡实收总金额
      <span class="text-success p-x-2 font-xs">
        <count-to
          :start-val="0"
          :end-val="tableData.sum ? parseInt(tableData.sum.toFixed(2)) : 0"
          :duration="2000"
          :decimals="0"
          separator=","
          prefix="¥ "
        />
      </span>
    </div>
    <el-table ref="multipleTable" v-loading="searchLoading" :data="tableData.list" row-key="id">
      <el-table-column prop="number" label="卡号" min-width="120">
        <template slot-scope="{ row }">
          <el-tag>{{ row.number }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="user.real_name" label="持卡用户" min-width="150px">
        <template slot-scope="{ row }">
          <div v-if="!utils.empty(row.user)" class="flex col-center cursor" @click="toUserInfoPage(row.user.id)">
            <div>
              <el-avatar shape="square" :size="50" :src="row.user.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row.user) }}</span>
              <br>
              <span>{{ row.user.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <el-table-column prop="card.name" label="卡名称" />
      <el-table-column prop="actual_amount" label="实收金额" />
      <el-table-column prop="payment_method_id" label="支付方式">
        <template slot-scope="{ row }">
          {{ payment_method[row.payment_method_id] }}
        </template>
      </el-table-column>
      <el-table-column prop="channel" label="渠道">
        <template slot-scope="{ row }">
          {{ channelList[row.channel] }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="开卡类型">
        <template slot-scope="{ row: { type } }">
          <span>{{ card_open_type[type] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="发卡时间" />
      <el-table-column prop="valid_time" label="到期时间">
        <template slot-scope="{ row }">
          {{ row.valid_time ? row.valid_time : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="valid_time" label="剩余天数">
        <template slot-scope="{ row }">
          <span v-if="getExcessTime(row.valid_time) > 0" class="text-success">{{ getExcessTime(row.valid_time) }}</span>
          <span v-else class="text-danger">{{ getExcessTime(row.valid_time) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      small
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
import { get_card_total } from '@/api/store/record.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Select from '@/components/elSelect/Select'
export default {
  components: { Pagination, CountTo, Select },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      searchLoading: false,
      store_user_list: {},
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      channelList: ['线下', '线上'],
      title: '持卡会员'
    }
  },
  created() {},
  mounted() {
    // this.listQuery.create_time = [this.$moment().subtract(1, 'd').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      this.searchLoading = true
      if (data.create_time) {
        data.start_time = data.create_time[0]
        data.end_time = data.create_time[1]
        delete data['create_time']
      }
      if (!data.user_id) delete data['user_id']
      if (!data.payment_method_id) delete data['payment_method_id']
      if (!data.channel) delete data['channel']
      get_card_total(data)
        .then(res => {
          this.tableData = res.data
          this.searchLoading = false
        })
        .catch(() => {})
    },
    handleMemberID(val) {
      this.listQuery.user_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__label {
  padding: 0 !important;
}
</style>
