<template>
  <div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="value"
      title="课程标签"
      class="dialog_w580"
      :before-close="close"
    >
      <div class="label-box">
        <div v-for="(item,index) in list" :key="index">
          <div v-if="item.category_id!==0">
            <div class="title">{{ item.title }}</div>
            <div class="tags">
              <el-tag v-for="(item1,index1) in item.list" :key="index1" class="m-x-4 m-y-4 cursor" size="medium" :type="isCheck(item1.id)?'primary':'info'" @click="checkItem(item1)">{{ item1.name }}</el-tag>
            </div>
          </div>
          <div v-else>
            <div class="title flex row-between">
              <div>{{ item.title }}</div>
              <div v-if="!is_edit" class="text-primary cursor font-13" @click="is_edit = true">编辑</div>
              <div v-if="is_edit" class="text-success cursor font-13" @click="is_edit = false">完成</div>
            </div>
            <div class="tags">
              <el-tag v-for="(item1,index1) in item.list" :key="index1" :disable-transitions="false" :closable="is_edit" class="m-x-4 m-y-4 cursor" size="medium" :type="isCheck(item1.id)?'primary':'info'" @close="delLabel(item1,item.list,index1)" @click="checkItem(item1)">{{ item1.name }}</el-tag>
              <el-tag v-if="!is_edit" type="success" effect="dark" class="m-x-4 m-y-4 cursor" size="medium" @click="addLabel"><i class="el-icon-plus" /> 添加</el-tag>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button type="text" @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCourseLabel, addCourseLabel, delCourseLabel } from '@/api/store/course.js'
import { deepClone } from '@/utils'
export default {
  props: {
    cList: {
      type: [Object, Array],
      default: () => {}
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      checkList: [],
      checkId: [],
      old_checkList: [],
      old_checkId: [],
      is_edit: false
    }
  },
  watch: {
    value: function(v, o) {
      if (v) {
        this.old_checkList = deepClone(this.checkList)
        this.old_checkId = deepClone(this.checkId)
      }
    },
    cList: function(v, o) {
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 删除标签
    delLabel(v, row, i) {
      this.$confirm('此操作将永久删除标签 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCourseLabel(v.id).then(res => {
          this.$message.success(res.msg)
          // 删除已选中
          if (this.isCheck(v.id)) {
            this.checkId.splice(this.checkId.indexOf(v.id), 1)
            delete this.checkList[v.id]
          }
          // 移除标签
          row.splice(i, 1)
        })
      }).catch(() => {
      })
    },
    addLabel() {
      this.$prompt('请输入标签名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        const data = {
          name: value,
          category_id: 0,
          weigh: 100
        }
        addCourseLabel(data).then(res => {
          this.$message.success(res.msg)
          this.getList()
        })
      }).catch(() => {
      })
    },
    // 设置默认值
    setDefault(v) {
      console.log('🚀 ~ file: index.vue ~ line 66 ~ setDefault ~ v', v)
      if (v) {
        const arr = v.split(',')
        const checkArr = []
        const checkId = []
        this.list.forEach(val => {
          val.list.map(item => {
            if (arr.indexOf(item.name) !== -1) {
              checkArr[item.id] = item
              checkId.push(item.id)
            }
          })
        })
        this.checkList = checkArr
        this.checkId = checkId
        let list = deepClone(this.checkList)
        list = list.filter(function(n) { return n })
        this.$emit('setLabel', list)
      }
    },
    // 检查是否选中
    isCheck(id) {
      return this.checkId.indexOf(id) !== -1
    },
    // 选中/取消
    checkItem(row) {
      if (this.is_edit) return false
      if (this.isCheck(row.id)) {
        this.checkId.splice(this.checkId.indexOf(row.id), 1)
        delete this.checkList[row.id]
      } else {
        if (this.checkId.length >= 6) {
          this.$message.error('最多只能选择6个标签')
          return false
        }
        this.checkId.push(row.id)
        this.checkList[row.id] = row
      }
    },
    // 回调父组件值
    set(row) {
      let list = deepClone(row)
      list = list.filter(function(n) { return n })
      this.$emit('setLabel', list)
    },
    close() {
      this.checkList = this.old_checkList
      this.checkId = this.old_checkId
      this.set(this.old_checkList)
      this.$emit('input', false)
    },
    submit() {
      this.set(this.checkList)
      this.$emit('input', false)
    },
    getList() {
      var that = this
      return new Promise(function(resolve, reject) {
        that.loading = true
        getCourseLabel().then(res => {
          that.list = res.data
          resolve(that.list)
        }).finally(() => {
          that.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.label-box{
  .title{
    font-size: 15px;
    font-weight: 600;
    margin:8px 0px;
  }
}
</style>
