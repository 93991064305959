<template>
  <div class="app-container special-center project">
    <el-form ref="ruleForm" :rules="rules" :model="form" label-position="left" label-width="80px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="账号头像" prop="avatar">
            <div class="avatar-block avatar-box" @click="imagecropperShow = true">
              <el-avatar shape="square" :size="100" :src="form.avatar" :title="form.avatar ? '点击修改' : '点击上传'">
                <img :src="errorImageSrc">
              </el-avatar>
              <div class="avatar-hover">{{ form.avatar ? '更换头像' : '上传头像' }}</div>
            </div>
            <image-cropper
              v-show="imagecropperShow"
              :key="imagecropperKey"
              field="file"
              :width="300"
              :height="300"
              :url="storeUpload"
              lang-type="zh"
              @close="imagecropperClose"
              @crop-upload-success="cropSuccess"
            />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
            <el-form-item label="账号名" prop="account">
              <el-input v-model="form.account" placeholder="请输入账号名" />
            </el-form-item>
          </el-col> -->
        <!-- <el-col :span="12">
          <el-form-item label="密码" :prop="'password'">
            <el-input v-model="form.password" type="text" placeholder="不改请为空" />
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="姓名" prop="real_name">
            <el-input v-model="form.real_name" placeholder="请输入姓名" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone" type="number" placeholder="请输入手机号">
              <div slot="prepend">
                <Code @area_code="area_code" />
              </div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="生日" prop="birthday">
            <el-date-picker v-model="form.birthday" type="date" value-format="yyyy-MM-dd" placeholder="请选择日期" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="form.sex">
              <el-radio v-for="(item, index) in sexList" :key="index" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="星级" prop="star_level">
            <el-rate v-model="form.star_level" :colors="colors" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="团课教练" prop="is_group">
            <el-select v-model="form.is_group" placeholder="请选择">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="私课教练" prop="is_private">
            <el-select v-model="form.is_private" placeholder="请选择">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="课程颜色" prop="color">
            <div class="flex flex-wrap col-center row-left">
              <div><el-color-picker v-model="form.color" :style="{ paddingTop: '10px' }" /></div>
              <div class="m-l-10">
                <el-select
                  v-model="defaultColorValue"
                  placeholder="快速选色"
                  clearable
                  style="width:120px"
                  @change="defaultColor"
                >
                  <el-option v-for="(item, index) in courseDefaultColorList" :key="index" :value="item" :label="item">
                    <div class="defaultColorItem" :style="{ background: item }">{{ item }}</div>
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="金牌教练">
            <el-switch v-model="form.is_gold" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="封面图" prop="images">
            <el-upload
              v-loading="upLoading"
              :action="storeUpload"
              list-type="picture-card"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="upSuccess"
              :headers="utils.upload_headers()"
              :file-list="fileList"
              :limit="1"
              :on-exceed="handleExceed"
              :on-progress="upProgress"
              accept=".jpg,.jpeg,.png"
            >
              <i class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisible" class="dialog_view_image">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <small class="text-info">图片尺寸建议16:9</small>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="员工简介">
            <el-input
              v-model="form.introduce"
              type="textarea"
              :maxlength="200"
              :autosize="{ minRows: 5, maxRows: 5 }"
              placeholder="请输入员工简介"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="所在场馆角色配置" prop="role" label-width="140px">
            <div v-if="utils.empty(roleLists)" class="tal">
              <checkRole :type.sync="form.type" :parent-selected.sync="rs" :list.sync="roleList" />
            </div>
            <div v-else>
              <el-collapse v-for="item in roleLists" :key="item.id" v-model="activeNames">
                <el-collapse-item :title="item.name" :name="item.id">
                  <checkRole :type.sync="form.type" :parent-selected.sync="rs[item.id]" :list.sync="item.role" />
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button @click="$router.push({ name: 's_management_list' })">取消</el-button>
    </div>
  </div>
</template>

<script>
import { addStoreUser } from '@/api/store/management.js'
import { getVenuesRole } from '@/api/store/venues.js'
import ImageCropper from '@/components/ImageCropper' // 头像上传\
import checkRole from '@/components/All/checkRole'
import { deepClone } from '@/utils'
import { getImg } from '@/api/store/config.js'
// 组件
import Code from '@/components/code/index.vue'

export default {
  components: { ImageCropper, checkRole, Code },
  data() {
    return {
      rs: [],
      form: {
        sex: 1,
        is_group: 0,
        is_private: 0,
        avatar: '',
        color: null,
        star_level: 3,
        area_code: '+86',
        is_gold: 0,
        introduce: '',
        cover: 'https://static.yuxiaojiu.cn/resources/swim/coach_cover/4.png'
      },
      rules: ['password', 'real_name', 'phone', 'birthday', 'sex'],
      imagecropperShow: false,
      imagecropperKey: 0,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      typeOptions: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      defaultColorValue: '',
      roleLists: {},
      roleList: {},
      activeNames: ['1'],
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: ''
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.getVenuesRole()
  },
  methods: {
    getVenuesRole() {
      getVenuesRole().then(res => {
        this.roleLists = res.data
      })
      getImg('coach_cover').then(res => {
        // const images = JSON.parse(res.data)
        console.log(res.data[0])
        this.fileList = [{ name: '12312', url: res.data[0] }]
      })
    },
    submit() {
      console.log(this.fileList)
      var roles = []
      if (this.rs) {
        this.rs.forEach((v, i) => {
          v.forEach((v2, i2) => {
            roles.push({
              venues_id: i,
              role_id: v2
              // store_user_id: 1
            })
          })
        })
      }
      var data = deepClone(this.form)
      console.log(roles)
      data.employee = roles
      data.area_code = this.form.area_code === '+86' ? '86' : this.form.area_code.replace(/[^0-9]/gi, '')
      data.scene = this.form.area_code === '+86' ? '' : 'internation'

      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // if (this.utils.empty(data.cover)) {
          //   this.$message.error('请上传活动图片')
          //   return false
          // }
          if (data.cover && typeof data.cover === 'object') data.cover = data.cover.join(',')
          addStoreUser(data)
            .then(res => {
              this.$router.push({ name: 's_management_list' })
              this.$message.success('添加成功')
            })
            .catch(() => {})
        }
      })
    },
    // 头像上传成功
    cropSuccess(resData) {
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      this.$set(this.form, 'avatar', resData.path)
    },
    // 头像上传弹出层关闭
    imagecropperClose() {
      this.imagecropperShow = false
    },
    defaultColor(i, v) {
      this.form.color = i
      // console.log('🚀 ~ file: index.vue ~ line 291 ~ defaultColor ~ i,v', i, v)
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      var imageList = []
      for (var i in fileList) {
        imageList.push(fileList[i].response.data.path)
      }
      this.$set(this.form, 'cover', imageList)
    },
    upProgress() {
      this.upLoading = true
    },
    handleRemove(file, fileList) {
      var imageList = []
      for (var i in fileList) {
        imageList.push(fileList[i].response.data.path)
      }
      this.$set(this.form, 'cover', imageList)
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleExceed(files, fileList) {
      this.$message.warning(`仅支持上传一张活动图片`)
    },
    area_code(val) {
      this.form.area_code = val
    }
  }
}
</script>

<style lang="scss" scoped>
.el-avatar {
  background: none;
}
.defaultColorItem {
  width: 100%;
  background: red;
  text-align: center;
  margin: 1px 0px;
  color: #fff;
}
.avatar-block {
  display: unset;
}
</style>
