var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "filter-container" },
      [
        _c(
          "div",
          { staticClass: "searchBox" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.listQueryParams,
                  "label-width": "60px",
                  "label-position": "left",
                  "label-suffix": "：",
                  inline: true
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "项目" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择项目", clearable: "" },
                        model: {
                          value: _vm.listQueryParams.id,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "id", $$v)
                          },
                          expression: "listQueryParams.id"
                        }
                      },
                      [
                        _c("el-option", { attrs: { label: "APP", value: 0 } }),
                        _c("el-option", { attrs: { label: "PC", value: 1 } }),
                        _c("el-option", {
                          attrs: { label: "约课端", value: 2 }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "m-b-10",
                    attrs: {
                      icon: "el-icon-search",
                      loading: _vm.searchLoading,
                      type: "success"
                    },
                    on: { click: _vm.handleFilter }
                  },
                  [_vm._v(" 搜索 ")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-input",
              {
                attrs: {
                  placeholder:
                    "搜索 错误内容|路径|设备型号|设备机型|设备UA|设备ID|版本|IP地址|系统信息"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQueryParams.query,
                  callback: function($$v) {
                    _vm.$set(_vm.listQueryParams, "query", $$v)
                  },
                  expression: "listQueryParams.query"
                }
              },
              [
                _c("el-button", {
                  attrs: {
                    slot: "append",
                    icon: "el-icon-search",
                    loading: _vm.searchLoading
                  },
                  on: { click: _vm.handleFilter },
                  slot: "append"
                })
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [
          _vm._v(
            "可搜索 错误内容 | 路径 | 设备型号 | 设备机型 | 设备UA | 设备ID | 版本 | IP地址 | 系统信息"
          )
        ]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData.list, stripe: "" }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "uuid",
                label: "UUID",
                align: "center",
                "min-width": "180"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "error", label: "错误内容", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      row.error !== "Error:"
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                width: "400",
                                trigger: "click"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "max-height": "300px",
                                    overflow: "auto"
                                  }
                                },
                                [_vm._v(" " + _vm._s(row.error) + " ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference"
                                },
                                [_vm._v("查看")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "project", label: "项目", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      row.project === 0
                        ? _c("el-tag", [_vm._v("APP")])
                        : _vm._e(),
                      row.project === 1
                        ? _c("el-tag", [_vm._v("PC")])
                        : _vm._e(),
                      row.project === 2
                        ? _c("el-tag", [_vm._v("约课端")])
                        : _vm._e()
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "path",
                label: "路径",
                align: "center",
                "min-width": "120"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "params", label: "请求参数", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      row.params !== "[]" && row.params
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                width: "400",
                                trigger: "click"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "max-height": "300px",
                                    overflow: "auto"
                                  }
                                },
                                [
                                  _vm._v(" { "),
                                  _vm._l(JSON.parse(row.params), function(
                                    value,
                                    key
                                  ) {
                                    return _c("div", { key: key }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            margin: "5px 0 5px 20px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(key) + ": " + _vm._s(value)
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  _vm._v(" } ")
                                ],
                                2
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference"
                                },
                                [_vm._v("查看")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "os", label: "设备型号", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "device", label: "设备机型", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "ua", label: "设备UA", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      row.ua
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                width: "400",
                                trigger: "click"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "max-height": "300px",
                                    overflow: "auto"
                                  }
                                },
                                [
                                  _vm._v(" " + _vm._s(row.ua) + " "),
                                  _vm._l(row.ua.split(" "), function(
                                    item,
                                    index
                                  ) {
                                    return _c("div", { key: index }, [
                                      _vm._v(_vm._s(item))
                                    ])
                                  })
                                ],
                                2
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference"
                                },
                                [_vm._v("查看")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "version", label: "版本", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "ip", label: "IP地址", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "system_info",
                label: "系统信息",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      row.system_info !== "[]"
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                width: "400",
                                trigger: "click"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "max-height": "300px",
                                    overflow: "auto"
                                  }
                                },
                                [
                                  _vm._v(" { "),
                                  _vm._l(JSON.parse(row.system_info), function(
                                    value,
                                    key
                                  ) {
                                    return _c("div", { key: key }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            margin: "5px 0 5px 20px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(key) + ": " + _vm._s(value)
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  _vm._v(" } ")
                                ],
                                2
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference"
                                },
                                [_vm._v("查看")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                label: "日期",
                align: "center",
                "min-width": "100"
              }
            })
          ],
          1
        ),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.count > 0,
              expression: "tableData.count > 0"
            }
          ],
          attrs: {
            total: _vm.tableData.count,
            page: _vm.listQuery.page,
            limit: _vm.listQuery.limit
          },
          on: {
            "update:page": function($event) {
              return _vm.$set(_vm.listQuery, "page", $event)
            },
            "update:limit": function($event) {
              return _vm.$set(_vm.listQuery, "limit", $event)
            },
            pagination: _vm.getList
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }