<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">

          <el-form-item label="分类">
            <el-select v-model="listQueryParams.category_id" placeholder="请选择" filterable clearable>
              <el-option
                v-for="item in categoryOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="可见">
            <el-select v-model="listQueryParams.status" placeholder="请选择" filterable clearable>
              <el-option key="0" label="隐藏" :value="0" />
              <el-option key="1" label="可见" :value="1" />
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索标题" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <router-link v-show="btn_access('system_article_add')" :to="{name: 'Addarticle'}">
        <el-button type="primary" icon="el-icon-plus" class="filter-item m-l-10" @click="toPage()">添加</el-button>
      </router-link>
      <!-- <el-button class="m-l-10 filter-item" type="info" icon="el-icon-search" @click="searchShowBtn">
        高级检索
      </el-button> -->
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData.list"
      row-key="id"
      class="el-table--small"
    >
      <el-table-column prop="id" label="ID" width="80" />
      <el-table-column prop="cover" label="封面图">
        <template slot-scope="{row}">
          <el-image
            v-if="row.cover"
            :preview-src-list="[row.cover]"
            :src="row.cover"
            style="width: 60px; height: 60px"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column prop="category_id" label="分类">
        <template slot-scope="{row}">
          {{ row.category?row.category.name:'' }}
        </template>
      </el-table-column>

      <el-table-column prop="title" label="标题" min-width="150px">
        <template slot-scope="{row}">
          <div>{{ row.title }} <el-tag v-if="row.is_hot" class="m-2" type="warning">热门</el-tag><el-tag v-if="row.is_guide" class="m-2" type="info">入门</el-tag></div>
        </template>
      </el-table-column>

      <el-table-column prop="admin_id" label="发布人">
        <template slot-scope="{row}">
          {{ row.admin ? row.admin.real_name : '暂无发布人' }}
        </template>
      </el-table-column>
      <el-table-column prop="weigh" label="排序" />
      <el-table-column prop="status" label="可见">
        <template slot-scope="{row: {status}}">
          <el-tag v-if="(status === 0)" type="info" effect="dark" class="br0_tag">否</el-tag>
          <el-tag v-else type="success" effect="dark" class="br0_tag">是</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="valid_number" label="反馈数" width="150px">
        <template slot-scope="{row}">
          <div>有效:{{ row.valid_number?row.valid_number:0 }}</div>
          <div>无效:{{ row.invalid_number?row.invalid_number:0 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="200" />
      <el-table-column prop="update_time" label="更新时间" width="200" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('system_article_edit')" class="m-2" type="primary" icon="el-icon-edit" @click="toPage(scope.row.id)" />
          <delPopover v-show="btn_access('system_article_del')" class="m-2" :list-length="tableData.list.length" :api-data="apiData" :page.sync="listQuery.page" :row="scope.row" btn-msg="" @delete="getList" />
          <!-- <el-button :type="scope.row.is_disable ? 'success':'info'" @click="appDR(scope.row)">{{scope.row.is_disable ? '启用':'禁用'}}</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>

</template>

<script>
import { getArticleList } from '@/api/admin/article.js'
import { getSortType } from '@/api/admin/category.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import delPopover from '@/components/All/delPopover'
export default {
  components: { Pagination, delPopover },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      apiData: { url: '/v1/article' },
      loading: false,
      searchLoading: false,
      categoryOptions: []
    }
  },
  mounted() {
    this.getList()
    this.getCategroyList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getCategroyList() {
      getSortType('article').then(res => {
        this.categoryOptions = res.data[0].children
      })
    },
    // 获取列表
    getList() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'title|content', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.status !== '' && this.listQueryParams.status != null) querys.push({ field: 'status', key: this.listQueryParams.status })
      if (this.listQueryParams.category_id) querys.push({ field: 'category_id', key: this.listQueryParams.category_id })

      data.query = this.utils.getQueryParams(querys)
      getArticleList(data).then(res => {
        this.tableData = res.data
      }).catch(() => {}).finally(() => {
        this.searchLoading = false
        this.loading = false
      })
    },
    toPage(id) {
      if (id) { this.$router.push({ name: 'Editarticle', params: { id: id }}) } else { this.$router.push({ name: 'Addarticle' }) }
      // this.$router.push({path:`edit/${id}`});
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
