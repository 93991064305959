<template>
  <el-select v-model="area_code" filterable placeholder="请选择" class="CodeList">
    <el-option-group v-for="(group, index) in dataList" :key="index" :label="group.name" class="min-width:200px">
      <el-option v-for="(item, indexs) in group.options" :key="indexs" :label="`+${item.code}`" :value="item.code">
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px;padding-left:100px">
          +{{ item.code }}
        </span>
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
// API
import { getAreaCode } from '@/api/store/code.js'

export default {
  name: 'Code',
  props: {
    code: {
      type: String,
      default: '86'
    }
  },
  data() {
    return {
      dataList: [],
      area_code: ''
    }
  },
  watch: {
    // 深度监听 code 立即执行
    code: {
      handler(val) {
        this.area_code = (val === '+86') ? '86' : val.replace(/[^0-9]/ig, '')
      },
      immediate: true,
      deep: true
    },
    area_code(val) {
      this.$emit('area_code', val)
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getAreaCode().then(res => {
        this.dataList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.CodeList {
  width: 100px;
  background: transparent;
  border-right: none;
  .el-input {
    .el-input__inner {
      border: none !important;
    }
  }
}
</style>
