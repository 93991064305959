var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "卡升级名称", prop: "membership_card_id" } },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.card_type_change },
                        model: {
                          value: _vm.form.card_type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "card_type", $$v)
                          },
                          expression: "form.card_type"
                        }
                      },
                      _vm._l(_vm.card_type, function(item, index) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: index }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "m-x-10" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.membership_change },
                        model: {
                          value: _vm.form.membership_card_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "membership_card_id", $$v)
                          },
                          expression: "form.membership_card_id"
                        }
                      },
                      _vm._l(_vm.membershipCard_type, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c("div", [
                  _vm._v("售价:" + _vm._s(_vm.form.new_card_info.price) + "元")
                ])
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "有效期至", prop: "valid_time" } },
            [
              _c("el-date-picker", {
                staticClass: "dialog_w100",
                attrs: {
                  type: "date",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.form.valid_time,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "valid_time", $$v)
                  },
                  expression: "form.valid_time"
                }
              })
            ],
            1
          ),
          _vm.form.card_type !== 0
            ? _c(
                "el-form-item",
                { attrs: { label: "加赠", prop: "gift" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "0", type: "number" },
                      on: { input: _vm.gift_change },
                      model: {
                        value: _vm.form.gift,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "gift", $$v)
                        },
                        expression: "form.gift"
                      }
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(
                          _vm._s(
                            _vm.form.card
                              ? _vm.formatCardUnitType(_vm.form.card_type)
                              : "天"
                          )
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "现卡折价（元）", prop: "remaining" } },
            [
              _c("el-input", {
                attrs: { placeholder: "0" },
                on: { input: _vm.remaining_change },
                model: {
                  value: _vm.form.remaining,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remaining", $$v)
                  },
                  expression: "form.remaining"
                }
              })
            ],
            1
          ),
          _vm.form.new_card_info.type !== 0
            ? _c(
                "el-form-item",
                { attrs: { label: "升级后总余额", prop: "remaining" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "0" },
                      on: { input: _vm.remaining_change },
                      model: {
                        value: _vm.form.limit,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "limit", $$v)
                        },
                        expression: "form.limit"
                      }
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(
                          _vm._s(
                            _vm.form.card
                              ? _vm.formatCardUnitType(_vm.form.card_type)
                              : "天"
                          )
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "付款方式", prop: "payment_method_id" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "dialog_w100",
                  attrs: { placeholder: "付款方式" },
                  model: {
                    value: _vm.form.payment_method_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "payment_method_id", $$v)
                    },
                    expression: "form.payment_method_id"
                  }
                },
                _vm._l(_vm.payment_method, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: index }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实收金额（元）", prop: "actual_amount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入费用", type: "number" },
                model: {
                  value: _vm.form.actual_amount,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "actual_amount", $$v)
                  },
                  expression: "form.actual_amount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请填写操作备注",
                  rows: "5",
                  maxlength: "300",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("back", false)
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }