<template>
  <div class="app-container special-center">
    <el-page-header content="新增拼团活动" class="page-header" @back="returnPage()" />
    <el-divider />
    <!-- 表单 -->
    <el-form ref="ruleForm" :rules="rules" :model="form" label-position="right" label-width="120px">
      <p><b>活动信息</b></p>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="封面图">
            <el-upload
              v-loading="upLoadingCover"
              class="images-uploader"
              :action="storeUpload"
              :show-file-list="false"
              :on-success="upSuccessCover"
              :headers="utils.upload_headers()"
              :on-progress="upProgressCover"
              accept=".jpg,.jpeg,.png"
            >
              <img v-if="form.cover" :src="form.cover" class="images" title="点击上传图片">
              <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="商品图片">
            <el-upload
              v-loading="upLoading"
              class="upload-venues"
              :action="storeUpload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture-card"
              :on-success="upSuccess"
              :headers="utils.upload_headers()"
              :limit="6"
              :on-exceed="handleExceed"
              :on-progress="upProgress"
              accept=".jpg,.jpeg,.png"
            >
              <i class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleS" class="dialog_view_image">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="活动名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入活动名称" maxlength="30" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="拼团开始时间" prop="start_time">
            <el-date-picker
              v-model="form.start_time"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              default-time="00:00:00"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12" class="relative">
          <el-tooltip class="item" effect="dark" content="活动结束时间到达后，活动即结束，无法创建新团，未完成的拼团将自动成团" placement="bottom">
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="拼团结束时间" prop="end_time">
            <el-date-picker
              v-model="form.end_time"
              class="m-l-10"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              default-time="23:59:59"
            />
          </el-form-item>
        </el-col>
        <el-col :span="14" class="relative">
          <el-tooltip class="item" effect="dark" content="当团成员人数达到后，该团将自动成团并在30分钟内完成该团所有成员的会员卡下发，已经创建好的团将保持建团人数时的要求，新团遵循新的成团人数要求" placement="bottom">
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="自动成团人数" prop="people_number">
            <el-input v-model="form.people_number" class="m-l-10" type="number" placeholder="请输入自动成团人数">
              <template slot="append">人</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="活动内容">
            <el-button @click="dialogVisibleTask = true">
              {{ form.content ? '编辑活动内容' : '添加活动内容' }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <p><b>商品信息</b></p>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="选择会员卡">
            <el-button @click="dialogVisibleCard = true">{{ form.membership_card_id.length > 0 || form.card_combo_id ? '编辑会员卡' : '选择会员卡' }}</el-button>
          </el-form-item>
          <div v-if="membershipCardListInfo.length > 0" class="m-l-30">
            <el-link v-for="(item, index) in membershipCardListInfo" :key="index" class="color m-x-6 m-b-20" target="_blank" @click="handleToPage(item.id)">
              {{ item.name }}
            </el-link>
          </div>
        </el-col>
        <el-col :span="24">
          <el-form-item label="活动价" prop="price">
            <el-input v-model="form.price" placeholder="请输入活动价">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="relative">
          <i class="el-icon-question cursor absolute" @click="cardType = true" />
          <el-form-item label="开卡类型" prop="open_card_type">
            <el-select v-model="form.open_card_type" class="m-l-10" placeholder="请选择开卡类型">
              <el-option v-for="(item, index) in card_open_type" :key="item" :label="item" :value="index" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="form.open_card_type === 2" :span="12">
          <el-form-item label="购买后开卡时间" prop="open_card_time">
            <el-input v-model="form.open_card_time" type="number">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <p><b>高级设置</b></p>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="可发起开团数">
            <el-radio-group v-model="form.inventory" class="flex">
              <div>
                <el-radio :label="0">无限</el-radio>
                <el-radio label="1">限量</el-radio>
              </div>
              <el-input
                v-model="peopleNumber"
                type="number"
                :disabled="form.inventory == 0"
                style="width: 160px;"
                class="m-l-20"
              >
                <template slot="append">个</template>
              </el-input>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="relative">
          <el-tooltip class="item" effect="dark" content="未设置自动成团天数时，未完成的团将在活动结束时自动成团，如设置自动成团天数，将在团创建后的X天自动成团" placement="bottom">
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="自动成团天数" prop="validity_day">
            <el-radio-group v-model="form.validity_day" class="flex m-l-10">
              <div>
                <el-radio :label="0">无</el-radio>
                <el-radio label="1">自定义</el-radio>
              </div>
              <el-input
                v-model="dayNumber"
                type="number"
                :disabled="form.validity_day == 0"
                style="width: 160px;"
                class="m-l-20"
              >
                <template slot="append">天</template>
              </el-input>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="relative">
          <el-tooltip class="item" effect="dark" content="约课端显示的销量=初始销量+实际销量" placement="bottom">
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="初始销量">
            <el-input v-model="form.sold_number" class="m-l-10" type="number" placeholder="请输入初始销量">
              <template slot="append">人</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="购买人" prop="buy_identity">
            <el-checkbox-group v-model="form.buy_identity">
              <el-checkbox label="member">会员</el-checkbox>
              <el-checkbox label="visitor">访客</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="relative">
          <el-tooltip class="item" effect="dark" content="活动商品详情页展示未成团的团列表，买家可以任选一个团参团，提升成团率，如未开启显示凑团，用户只能通过分享自己的拼团邀请成员参与" placement="bottom">
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="显示凑团">
            <el-switch v-model="form.show_other" class="m-l-10" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-col>
        <el-col :span="12" class="relative">
          <i
            class="el-icon-question cursor absolute"
            style="position: absolute; top: 10px; left: 120px;"
            @click="recommendation = true"
          />
          <el-form-item label="加入推荐">
            <el-switch v-model="form.is_recommend" class="m-l-10" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-col>
        <el-col v-if="form.is_recommend" :span="24">
          <el-form-item label="推荐轮播图">
            <el-upload
              v-loading="upLoading"
              class="images-uploader"
              :action="storeUpload"
              :show-file-list="false"
              :on-success="upSuccessS"
              :headers="utils.upload_headers()"
              :on-progress="upProgressS"
              accept=".jpg,.jpeg,.png"
            >
              <img v-if="form.recommend_image" :src="form.recommend_image" class="images" title="点击上传图片">
              <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 按钮 -->
    <div class="footer">
      <el-button :loading="buttonLoading" @click="$router.push({ name: 's_group_booking' })">取 消</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="submitForm('ruleForm')">提 交</el-button>
    </div>
    <!-- 活动内容 -->
    <el-dialog
      title="活动内容"
      :visible.sync="dialogVisibleTask"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%"
      @open="handleOpen"
    >
      <wangEditor v-model="WangEditorValue" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTask = false">取 消</el-button>
        <el-button type="primary" @click="handleActivityPreserve">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 会员卡 -->
    <el-dialog
      title="会员卡"
      :visible.sync="dialogVisibleCard"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="50%"
      @open="handleOpenCard"
      @close="handleClose"
    >
      <div v-loading="cardLoading">
        <div class="filter-item m-b-10">
          <el-input
            v-model="listQueryParams.query"
            :placeholder="is_card ? '组合卡名称' : '搜索会员卡名称'"
            @keyup.enter.native="handleFilter"
          >
            <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
          </el-input>
        </div>
        <div class="flex m-b-20">
          <div>套餐卡：</div>
          <el-switch v-model="is_card" :active-value="1" :inactive-value="0" @change="handleChangeIsCard" />
        </div>
        <div v-show="!is_card" class="tal">
          <checkboxInput
            :parent-selected.sync="selectedCard"
            :list="supportCard"
            @update:parentSelected="handleParentSelected"
          />
        </div>
        <div v-show="is_card" class="tal">
          <el-row :gutter="20">
            <el-col class="m-b-10" :span="8">
              <el-radio v-model="card_combo_id" :label="0">不选择组合卡</el-radio>
            </el-col>
            <el-col v-for="(item, index) in ComboList" :key="index" class="m-b-10" :span="8">
              <el-radio v-model="card_combo_id" :label="item.id">
                {{ item.name.length > 10 ? item.name.slice(0, 10) + '...' : item.name }}（包含{{ item.card_group ? item.card_group.length : 0 }}张会员卡）
              </el-radio>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCard = false">取 消</el-button>
        <el-button type="primary" @click="handlePreserveCard">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 会员卡详情 -->
    <Dialog :id="membership_card_id_info" :venue="venuesId" :dialog="dialogVisibleMembership" @Close="Close" />
    <!-- 推荐解释 -->
    <el-dialog
      title="加入推荐示列"
      :visible.sync="recommendation"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="20%"
      @open="handleOpen"
    >
      <div class="font-14 text-info" style="text-align: center;">启用加入推荐，即可在特定板块中展示此活动</div>
      <el-image class="m-t-10" style="width: 100%;" :src="recommendationImg" fit="fill" />
      <span slot="footer" class="dialog-footer flex row-center">
        <el-button type="primary" @click="recommendation = false">知道了</el-button>
      </span>
    </el-dialog>
    <!-- 开卡类型 -->
    <el-dialog
      title="开卡类型"
      :visible.sync="cardType"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="20%"
      @open="handleOpen"
    >
      <div class="font-14">
        <div class="el-message__content">立即开卡</div>
        <div class="text-info m-t-4">发卡成功后立即激活会员卡</div>
        <div class="el-message__content m-t-4">首次预约开卡</div>
        <div class="text-info m-t-4">该卡将在首次预约课程时激活会员卡，包括会员自主预约和老师代约课</div>
        <div class="el-message__content m-t-4">特定时间开卡</div>
        <div class="text-info m-t-4">该卡将在指定日期和时间点启用并激活会员卡，在这之前该卡无法使用</div>
        <div class="el-message__content m-t-4">首次上课开卡</div>
        <div class="text-info m-t-4">该卡将在首次预约课程的上课时间激活会员卡，包括会员自主预约和老师代约课</div>
        <el-divider />
        <div class="el-message__content text-warning"><i class="el-icon-warning" /> 注意：</div>
        <div class="text-warning m-t-4">除立即开卡以外的开卡方式，开卡前无到期时间，且无法修改会员卡有效期，有效期将在开卡后计算</div>
      </div>
      <span slot="footer" class="dialog-footer flex row-center">
        <el-button type="primary" @click="cardType = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { addTeamwork } from '@/api/store/teamwork.js'
import { getVipCard } from '@/api/store/venues.js'
import { getComboList } from '@/api/store/combination.js'
import { getImg } from '@/api/store/config.js'
// 组件
import WangEditor from '@/components/wangEditor'
import checkboxInput from './components/checkboxInput'
import Dialog from '@/views/store/user/vipCard/components/Dialog.vue'
// 函数
import { deepClone } from '@/utils'
import Utils from '@/utils/utils.js'

export default {
  components: { WangEditor, checkboxInput, Dialog },
  data() {
    // 自动成团人数验证 最低两人开团
    const numberPeople = (rule, value, callback) => {
      if (!value) return callback(new Error('请输入自动成团人数'))
      else if (value < 2) return callback(new Error('自动成团人数不能小于2人'))
      else callback()
    }
    return {
      form: {
        name: '',
        cover: '',
        recommend_image: '',
        goods_images: [],
        start_time: '',
        end_time: '',
        people_number: '2',
        content: '',
        price: '',
        open_card_type: 0,
        open_card_time: 0,
        inventory: 0,
        validity_day: 0,
        sold_number: '',
        buy_identity: ['member', 'visitor'],
        show_other: 1,
        is_recommend: 0,
        membership_card_id: [], // 自定义组合卡ID
        card_combo_id: 0 // 组合卡套餐ID
      },
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        recommend_image: [{ required: true, message: '请上传推荐图', trigger: 'blur' }],
        start_time: [{ required: true, message: '请选择拼团开始时间', trigger: 'blur' }],
        end_time: [{ required: true, message: '请选择拼团结束时间', trigger: 'blur' }],
        people_number: [{ validator: numberPeople, trigger: 'blur' }],
        price: [{ required: true, message: '请输入活动价', trigger: 'blur' }],
        open_card_type: [{ required: true, message: '请选择开卡类型', trigger: 'blur' }],
        open_card_time: [{ required: true, message: '请输入购买后开卡时间', trigger: 'blur' }],
        validity_day: [{ required: true, message: '请选择自动成团天数', trigger: 'blur' }],
        buy_identity: [{ required: true, message: '请选择购买人', trigger: 'blur' }]
      },
      peopleNumber: '',
      dayNumber: '',
      WangEditorValue: '',
      dialogVisibleTask: false,
      dialogVisibleCard: false,
      cardLoading: false,
      activeNames: ['1', '2', '3'],
      // 支持卡列表
      supportCard: [],
      // 已选择的会员卡
      selectedCard: [],
      is_card: 0,
      ComboList: [],
      membershipCardList: [],
      buttonLoading: false,
      searchLoading: false,
      membershipCardListInfo: [],
      dialogVisibleMembership: false,
      membership_card_id_info: 0,
      venuesId: Utils.getVenues().id,
      fileList: [],
      upLoadingCover: false,
      dialogVisibleS: false,
      dialogImageUrl: '',
      card_combo_id: 0, // 组合卡套餐ID
      recommendation: false,
      cardType: false,
      recommendationImg: require('@/assets/images/exampleImage.png')
    }
  },
  mounted() {
    this.coverImg()
  },
  methods: {
    coverImg() {
      getImg('group_booking_square')
        .then(res => {
          const { data } = res
          this.$set(this.form, 'cover', data[0])
        })
      getImg('group_booking_recommend')
        .then(res => {
          const { data } = res
          this.$set(this.form, 'recommend_image', data[0])
        })
    },
    generateUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
    },
    // 图片上传
    upSuccessCover(response, file, fileList) {
      this.upLoadingCover = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'cover', response.data.path)
    },
    upSuccessS(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'recommend_image', response.data.path)
    },
    upProgressCover() {
      this.upLoadingCover = true
    },
    upProgressS() {
      this.upLoading = true
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          const data = deepClone(this.form)
          data.goods_images = this.fileList.map(item => item.response.data.path)
          // 当 data.inventory 等于字符串1时 则赋值输入框里面的内容
          if (data.inventory === '1') data.inventory = this.peopleNumber
          // 当 data.validity_day 等于字符串1时 则赋值输入框里面的内容
          if (data.validity_day === '1') data.validity_day = this.dayNumber
          // 1. 自定义组合售卡  2. 组合卡套餐售卡
          // 当自定义卡组有卡且组合套餐未选择时 售卡方式应该是 1
          if (this.form.membership_card_id.length > 0 && this.form.card_combo_id) data.sales_method = 1
          else if (this.form.membership_card_id.length > 0 && !this.form.card_combo_id) data.sales_method = 1
          else if (this.form.membership_card_id.length === 0 && this.form.card_combo_id) data.sales_method = 2
          else if (this.form.membership_card_id.length === 0 && !this.form.card_combo_id) data.sales_method = 1
          // data.sales_method
          addTeamwork(data)
            .then(() => {
              this.$message.success('添加成功')
              this.buttonLoading = false
              this.$router.push({ name: 's_group_booking' })
            })
            .finally(() => {
              this.buttonLoading = false
            })
        } else {
          return false
        }
      })
    },
    // 活动打开的回调
    handleOpen() {
      this.WangEditorValue = this.form.content
    },
    // 保存活动
    handleActivityPreserve() {
      this.form.content = this.WangEditorValue
      this.dialogVisibleTask = false
    },
    // 会员卡打开的回调
    handleOpenCard() {
      this.cardLoading = true
      // 获取会员卡列表
      const data = {
        limit: 999
      }
      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'name', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      if (!this.is_card) {
        getVipCard(data)
          .then(res => {
            const { data } = res
            this.supportCard = data.list
            this.selectedCard = this.form.membership_card_id
            this.membershipCardList = this.form.membership_card_id
            this.handleOpenCardInfo()
          })
          .catch(() => {})
          .finally(() => {
            this.cardLoading = false
            this.searchLoading = false
          })
      } else {
        getComboList(data)
          .then(res => {
            const { data } = res
            this.ComboList = data.list
            this.card_combo_id = this.form.card_combo_id
          })
          .finally(() => {
            this.cardLoading = false
            this.searchLoading = false
          })
      }
    },
    // 会员卡关闭的回调
    handleClose() {
      this.listQueryParams.query = ''
      this.is_card = 0
    },
    // 保存选择的会员卡
    handlePreserveCard() {
      if (this.membershipCardList.length > 0 && this.card_combo_id) return this.$message.error('会员卡和套餐卡只能选择一种')
      if (this.membershipCardList.length > 15) {
        this.$message.error('最多只能选择15张会员卡')
        return false
      }
      this.form.membership_card_id = this.membershipCardList
      this.form.card_combo_id = this.card_combo_id
      this.handleOpenCardInfo()
      this.dialogVisibleCard = false
    },
    handleParentSelected(val) {
      this.membershipCardList = val
    },
    handleToPage(membership_card_id) {
      this.dialogVisibleMembership = true
      this.membership_card_id_info = membership_card_id
    },
    Close() {
      this.dialogVisibleMembership = false
    },
    // 遍历会员卡的详情
    handleOpenCardInfo() {
      // 遍历出完整的卡信息
      const data = this.supportCard.filter(item => {
        // 使用 Array.prototype.some 来检查是否有匹配的 supportCard
        return this.membershipCardList.some(item1 => item1 === item.id)
      })
      this.membershipCardListInfo = data
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      // this.listQuery.page = 1
      this.handleOpenCard()
    },
    handleChangeIsCard() {
      this.$nextTick(() => {
        this.listQueryParams.query = ''
        this.handleOpenCard()
      })
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisibleS = true
    },
    // 上传成功事件
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.fileList = fileList
    },
    // 图片移除事件
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleExceed() {
      this.$message.warning('当前限制选择 6 张图片')
    },
    upProgress() {
      this.upLoading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409eff;
}
.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 100px !important;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.color {
  color: rgb(24, 144, 255);
  /* padding-bottom: 3px;
  border-bottom: 1px solid rgb(24, 144, 255); */
  border: 1px solid rgb(24, 144, 255);
  padding: 4px 6px;
  border-radius: 5px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 192px;
  height: 108px;
}
::v-deep .el-upload {
  width: 192px;
  height: 108px;
  line-height: 116px;
}
</style>
