var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material material-dialog" },
    [
      _c(
        "el-card",
        {
          attrs: {
            shadow: "never",
            "body-style": "background-color: #f3f3f3;padding:0;"
          }
        },
        [
          _c("div", { staticStyle: { height: "0", overflow: "hidden" } }, [
            _c("canvas", {
              staticStyle: { border: "1px solid #ccc", visibility: "hidden" },
              attrs: { id: "material-canvas" }
            })
          ]),
          _c(
            "div",
            { staticClass: "table-body" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.dialogOpened },
                  model: {
                    value: _vm.tabs,
                    callback: function($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs"
                  }
                },
                [
                  _vm._l(_vm.tab_list, function(v, index) {
                    return _c(
                      "el-tab-pane",
                      { key: index, attrs: { label: v.label, name: v.name } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex col-center",
                            staticStyle: { "margin-bottom": "12px" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access("file_group_add"),
                                    expression: "btn_access('file_group_add')"
                                  }
                                ],
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.showAddGroup(-1)
                                  }
                                }
                              },
                              [_vm._v("添加分组")]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "200px" } },
                              [_vm._v(_vm._s(_vm.switchGroupName))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex col-center",
                                staticStyle: { "margin-left": "auto" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "search",
                                    staticStyle: { "margin-right": "12px" }
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "input-with-select",
                                        attrs: {
                                          placeholder: "请输入名称搜索",
                                          clearable: "",
                                          size: "small"
                                        },
                                        on: { clear: _vm.dialogOpened },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.dialogOpened($event)
                                          }
                                        },
                                        model: {
                                          value: _vm.keyword,
                                          callback: function($$v) {
                                            _vm.keyword = $$v
                                          },
                                          expression: "keyword"
                                        }
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            slot: "append",
                                            icon: "el-icon-search"
                                          },
                                          on: { click: _vm.dialogOpened },
                                          slot: "append"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("el-checkbox", {
                                  staticStyle: {
                                    "margin-right": "12px",
                                    "margin-bottom": "0"
                                  },
                                  attrs: { label: "全选", size: "small" },
                                  on: { change: _vm.selectAllChange },
                                  model: {
                                    value: _vm.selectAll,
                                    callback: function($$v) {
                                      _vm.selectAll = $$v
                                    },
                                    expression: "selectAll"
                                  }
                                }),
                                _vm.is_recycle
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-right": "12px" },
                                        attrs: {
                                          loading: _vm.deleteLoading,
                                          size: "small"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteItems(2)
                                          }
                                        }
                                      },
                                      [_vm._v("还原 ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.btn_access("file_del"),
                                        expression: "btn_access('file_del')"
                                      }
                                    ],
                                    staticStyle: { "margin-right": "12px" },
                                    attrs: {
                                      loading: _vm.deleteLoading,
                                      size: "small"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteItems(
                                          _vm.is_recycle ? 3 : 1
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除 ")]
                                ),
                                _c(
                                  "el-dropdown",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.btn_access(
                                          "file_group_move"
                                        ),
                                        expression:
                                          "btn_access('file_group_move')"
                                      },
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.moveLoading,
                                        expression: "moveLoading"
                                      }
                                    ],
                                    attrs: {
                                      trigger: "click",
                                      "split-button": true,
                                      size: "small"
                                    },
                                    on: { command: _vm.moveItems }
                                  },
                                  [
                                    _vm._v(" 移动至 "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        staticStyle: {
                                          height: "250px",
                                          "overflow-y": "scroll"
                                        },
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      _vm._l(_vm.groupList, function(item, i) {
                                        return _c(
                                          "el-dropdown-item",
                                          { key: i, attrs: { command: i } },
                                          [_vm._v(_vm._s(item.name))]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex col-top",
                            staticStyle: {
                              "margin-bottom": "10px",
                              "min-height": "68vh"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  border: "1px solid #e3e3e3",
                                  "margin-right": "15px"
                                }
                              },
                              [
                                _c(
                                  "el-menu",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.groupListLoading,
                                        expression: "groupListLoading"
                                      }
                                    ],
                                    staticClass: "group-menu",
                                    attrs: {
                                      mode: "vertical",
                                      "default-active": "all"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-scrollbar",
                                      {
                                        staticStyle: {
                                          height: "635px",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-menu-item",
                                          {
                                            attrs: { index: "all" },
                                            on: {
                                              click: function($event) {
                                                return _vm.switchGroup(-1)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-tickets"
                                            }),
                                            _c("span", [_vm._v("全部")])
                                          ]
                                        ),
                                        _vm._l(_vm.groupItem, function(
                                          item,
                                          i
                                        ) {
                                          return _c(
                                            "div",
                                            { key: i },
                                            [
                                              [
                                                _c(
                                                  "el-menu-item",
                                                  {
                                                    attrs: { index: "" + i },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.switchGroup(
                                                          i,
                                                          item.name
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex row-between",
                                                        attrs: {
                                                          flex:
                                                            "dir:left box:last"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              overflow:
                                                                "hidden",
                                                              "text-overflow":
                                                                "ellipsis"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-tickets"
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "flex",
                                                            attrs: {
                                                              flex: "dir:left"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value: _vm.btn_access(
                                                                      "file_group_edit"
                                                                    ),
                                                                    expression:
                                                                      "btn_access('file_group_edit')"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  type: "text"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.showAddGroup(
                                                                      i
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("编辑")]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#e3e3e3",
                                                                  margin:
                                                                    "0 2px"
                                                                }
                                                              },
                                                              [_vm._v("|")]
                                                            ),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value: _vm.btn_access(
                                                                      "file_group_del"
                                                                    ),
                                                                    expression:
                                                                      "btn_access('file_group_del')"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  type: "text"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteGroup(
                                                                      i,
                                                                      1
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("删除")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ],
                                            2
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loading,
                                    expression: "loading"
                                  }
                                ],
                                staticClass: "flex-1 box"
                              },
                              [
                                _c("el-scrollbar", [
                                  _c(
                                    "div",
                                    { staticClass: "material-list" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "material-item" },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value: _vm.uploading,
                                                  expression: "uploading"
                                                }
                                              ],
                                              attrs: {
                                                disabled: _vm.uploading,
                                                multiple: true,
                                                max: 10,
                                                params: _vm.uploadParams,
                                                accept: _vm.accept,
                                                action: _vm.adminUpload,
                                                "list-type": "picture-card",
                                                "auto-upload": "",
                                                "show-file-list": false,
                                                "on-progress": _vm.handleStart,
                                                "on-success": _vm.handleSuccess
                                              }
                                            },
                                            [
                                              _vm.uploading
                                                ? _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.uploadCompleteFilesNum
                                                        ) +
                                                        "/" +
                                                        _vm._s(
                                                          _vm.uploadFilesNum
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _c("i", {
                                                    staticClass:
                                                      "el-icon-upload"
                                                  })
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._l(_vm.attachments, function(
                                        item,
                                        inde
                                      ) {
                                        return _c(
                                          "div",
                                          { key: inde },
                                          [
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: item.file_name,
                                                    placement: "top",
                                                    "open-delay": 1
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      key: inde,
                                                      class:
                                                        "material-item" +
                                                        (item.selected
                                                          ? " selected"
                                                          : ""),
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectItem(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm.matchType(
                                                        item.path
                                                      ) == "image"
                                                        ? _c("el-image", {
                                                            staticClass:
                                                              "material-img",
                                                            staticStyle: {
                                                              width: "140px",
                                                              height: "140px"
                                                            },
                                                            attrs: {
                                                              fit: "contain",
                                                              src: item.path
                                                            }
                                                          })
                                                        : _vm.matchType(
                                                            item.path
                                                          ) == "video"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "material-img",
                                                              staticStyle: {
                                                                width: "140px",
                                                                height: "140px",
                                                                position:
                                                                  "relative"
                                                              }
                                                            },
                                                            [
                                                              item.cover_pic_src
                                                                ? _c("div", {
                                                                    staticClass:
                                                                      "material-video-cover",
                                                                    style:
                                                                      "background-image: url(" +
                                                                      item.cover_pic_src +
                                                                      ");"
                                                                  })
                                                                : _vm._e(),
                                                              _c(
                                                                "video",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                    height:
                                                                      "100%"
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      "app_material_" +
                                                                      _vm._uid +
                                                                      "_" +
                                                                      index
                                                                  }
                                                                },
                                                                [
                                                                  _c("source", {
                                                                    attrs: {
                                                                      src:
                                                                        item.path
                                                                    }
                                                                  })
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "material-video-info"
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-video-play m-r-4"
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.duration
                                                                          ? item.duration
                                                                          : "--:--"
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "material-img",
                                                              staticStyle: {
                                                                width: "140px",
                                                                height: "140px",
                                                                "line-height":
                                                                  "140px",
                                                                "text-align":
                                                                  "center"
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "file-type-icon el-icon-document"
                                                              })
                                                            ]
                                                          ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "flex",
                                                          staticStyle: {
                                                            "margin-top": "5px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "material-name"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.file_name
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                margin: "0 5px"
                                                              }
                                                            },
                                                            [_vm._v("|")]
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    padding: "0"
                                                                  },
                                                                  attrs: {
                                                                    type: "text"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.showPicModel(
                                                                        index
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "编辑 "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ],
                                          2
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "5px",
                                      "text-align": "right",
                                      "margin-top": "auto"
                                    }
                                  },
                                  [
                                    _c("pagination", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.tableData.count > 0,
                                          expression: "tableData.count>0"
                                        }
                                      ],
                                      attrs: {
                                        "page-sizes": [2, 10, 20, 27, 30, 50],
                                        total: _vm.tableData.count,
                                        page: _vm.listQuery.page,
                                        limit: _vm.listQuery.limit
                                      },
                                      on: {
                                        "update:page": function($event) {
                                          return _vm.$set(
                                            _vm.listQuery,
                                            "page",
                                            $event
                                          )
                                        },
                                        "update:limit": function($event) {
                                          return _vm.$set(
                                            _vm.listQuery,
                                            "limit",
                                            $event
                                          )
                                        },
                                        pagination: _vm.loadList
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        "append-to-body": "",
                        title: "分组管理",
                        visible: _vm.addGroupVisible,
                        "close-on-click-modal": false,
                        width: "30%"
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.addGroupVisible = $event
                        }
                      }
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "groupForm",
                          attrs: {
                            "label-width": "90px",
                            model: _vm.groupForm,
                            rules: _vm.groupFormRule
                          },
                          nativeOn: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "22px" },
                              attrs: { label: "分组名称", prop: "name" }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": ""
                                },
                                model: {
                                  value: _vm.groupForm.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.groupForm, "name", $$v)
                                  },
                                  expression: "groupForm.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.groupFormLoading,
                                    size: "medium"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.groupFormSubmit("groupForm")
                                    }
                                  }
                                },
                                [_vm._v("保存 ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        "append-to-body": "",
                        title:
                          _vm.tab_list[_vm.tabs == "image" ? 0 : 1]["label"] +
                          "名称修改",
                        visible: _vm.addPicVisible,
                        "close-on-click-modal": false,
                        width: "30%"
                      },
                      on: {
                        "update:visible": function($event) {
                          _vm.addPicVisible = $event
                        }
                      }
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "picForm",
                          attrs: {
                            "label-width": "90px",
                            model: _vm.picForm,
                            rules: _vm.picFormRule
                          },
                          nativeOn: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "22px" },
                              attrs: {
                                label:
                                  _vm.tab_list[_vm.tabs == "image" ? 0 : 1][
                                    "label"
                                  ] + "名称",
                                prop: "name"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.picForm.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.picForm, "name", $$v)
                                  },
                                  expression: "picForm.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.picFormLoading,
                                    size: "medium"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.picFormSubmit("picForm")
                                    }
                                  }
                                },
                                [_vm._v("保存 ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }