import request from '@/utils/request'
import Utils from '@/utils/utils.js'

// 会员卡预约记录
export function get_course_subscribe(data) {
  return request({
    url: `/store/v1/course_subscribe`,
    method: 'get',
    params: data
  })
}

// 获取团课预约记录
export function get_group_list(data) {
  return request({
    url: `/store/v1/course_subscribe/group/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 获取精品课预约记录
export function get_fine_list(data) {
  return request({
    url: `/store/v1/course_subscribe/fine/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 私教预约记录
export function get_private_list(data) {
  return request({
    url: `/store/v1/course_subscribe/private/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 课时统计
export function get_statistic(data) {
  return request({
    url: `/store/v1/course_subscribe/statistic/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 操作日志列表
export function get_logs(data) {
  return request({
    url: `/store/v1/operation_log/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 售卡统计
export function get_card_total(data) {
  return request({
    url: `/store/v1/user_membership_card/statistic/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
