<template>
  <div class="app-container">
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
        <el-form-item label="操作时间">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <!-- 操作人 -->
        <el-form-item label="操作人">
          <Select :type="4" @changeId="handleCoachID" />
        </el-form-item>
        <el-form-item label="业务类型">
          <el-select v-model="listQueryParams.user_membership_card_status_id" placeholder="请选择" filterable clearable>
            <el-option v-for="(item,index) in statusList" :key="index" :label="item.name" :value="item.id">{{ item.name }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="记录">
          <el-input v-model="listQueryParams.query" placeholder="搜索记录" style="width:350px" />
        </el-form-item>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
      </el-form>
    </div>
    <!-- 列表 -->
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <!-- <el-table-column prop="id" label="ID" width="80" /> -->
      <el-table-column prop="user_membership_card.number" label="卡号">
        <template slot-scope="{row}">
          <!-- <el-tooltip class="item" effect="dark" content="查看会员卡" placement="bottom"> -->
          <el-tag class="cursor" @click="toCardInfoPage(row.user_membership_card.id)">{{ row.user_membership_card?row.user_membership_card.number:'--' }}</el-tag>
          <!-- </el-tooltip> -->
        </template>
      </el-table-column>
      <el-table-column prop="user_membership_card.user" label="持卡用户" min-width="180px">
        <template slot-scope="{row}">
          <div v-if="!utils.empty(row.user_membership_card)&&!utils.empty(row.user_membership_card.user)" class="flex col-center cursor" @click="toUserInfoPage(row.user_membership_card.user?row.user_membership_card.user.id:'')">
            <div>
              <el-avatar shape="square" :size="50" :src="row.user_membership_card.user.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row.user_membership_card.user) }}</span><br>
              <span>{{ row.user_membership_card.user.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <el-table-column prop="status.name" label="操作类型" />
      <el-table-column prop="status" label="记录" min-width="220">
        <template slot-scope="{row}">
          <small>{{ row.remark }}</small><br>
          <small v-if="row.status.id==1&&row.start_time>0">{{ row.status.name }}时间:<br>{{ row.start_time|formatDate }}</small>
          <small v-if="row.status.id==5&&row.end_time>0">{{ row.status.name }}时间:<br>{{ row.end_time|formatDate }}</small>
          <small v-if="row.status.id==3&&row.end_time>0">{{ row.status.name }}时间:<br>{{ row.start_time|formatDate }} 至 {{ row.end_time|formatDate }}</small>
        </template>
      </el-table-column>
      <el-table-column prop="store_user" label="操作人">
        <template slot-scope="{row}">
          <span v-if="row.store_user">{{ row.store_user.real_name }}</span>
          <span v-else>系统</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="操作时间" />
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
// API
import { getUserMembershipCardChangeLogs, getUserMembershipCardStatus } from '@/api/store/user.js'
// 组件
import Select from '@/components/elSelect/Select'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  components: { Pagination, Select },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      listLoading: false,
      searchLoading: false,
      statusList: {},
      coachType: 3,
      title: '操作人'
    }
  },
  created() {

  },
  mounted() {
    this.getList()
    this.getStatus()
  },
  methods: {
    // 获取列表
    getList() {
      this.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'remark', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.create_time) querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      if (this.listQueryParams.user_membership_card_status_id !== '' && this.listQueryParams.user_membership_card_status_id != null) querys.push({ field: 'user_membership_card_status_id', key: this.listQueryParams.user_membership_card_status_id })
      if (this.listQueryParams.store_user_id !== '' && this.listQueryParams.store_user_id != null) querys.push({ field: 'store_user_id', key: this.listQueryParams.store_user_id })

      data.query = this.utils.getQueryParams(querys)

      getUserMembershipCardChangeLogs(data).then(res => {
        this.tableData = res.data
        this.listLoading = this.searchLoading = false
      }).catch(() => {})
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getStatus() {
      getUserMembershipCardStatus({ limit: 999 }).then(res => {
        this.statusList = res.data.list
      })
    },
    handleCoachID(val) {
      this.listQueryParams.store_user_id = val
    }
  }
}
</script>

<style lang="scss" scoped>

.el-avatar{
  background:none;
}
</style>
