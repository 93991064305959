var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "searchBox" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.listQueryParams,
                  "label-width": "100px",
                  "label-position": "top",
                  "label-suffix": "：",
                  inline: true
                }
              },
              [
                _c(
                  "van-form",
                  {
                    staticClass: "window_phone",
                    attrs: { "label-width": "80px" }
                  },
                  [
                    _c("van-field", {
                      attrs: {
                        readonly: "",
                        clickable: "",
                        name: "calendar",
                        value: _vm.vanCreationTime,
                        label: "创建时间：",
                        placeholder: "点击选择创建时间"
                      },
                      on: {
                        click: function($event) {
                          _vm.vanCreationTimeShow = true
                        }
                      }
                    }),
                    _c("van-calendar", {
                      attrs: {
                        type: "range",
                        "min-date": _vm.minVanCreationTime,
                        "max-date": _vm.maxVanCreationTime,
                        color: "#1989fa",
                        "confirm-text": "确 定",
                        "confirm-disabled-text": "请选择结束时间"
                      },
                      on: { confirm: _vm.onCreation },
                      model: {
                        value: _vm.vanCreationTimeShow,
                        callback: function($$v) {
                          _vm.vanCreationTimeShow = $$v
                        },
                        expression: "vanCreationTimeShow"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "window_pc", attrs: { label: "创建时间" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "picker-options": _vm.pickerOptions
                      },
                      model: {
                        value: _vm.listQueryParams.create_time,
                        callback: function($$v) {
                          _vm.$set(_vm.listQueryParams, "create_time", $$v)
                        },
                        expression: "listQueryParams.create_time"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "审核状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          filterable: "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.listQueryParams.status,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "status", $$v)
                          },
                          expression: "listQueryParams.status"
                        }
                      },
                      _vm._l(_vm.status_list, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "渠道" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          filterable: "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.listQueryParams.source,
                          callback: function($$v) {
                            _vm.$set(_vm.listQueryParams, "source", $$v)
                          },
                          expression: "listQueryParams.source"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "app", value: "app" }
                        }),
                        _c("el-option", {
                          attrs: { label: "web-PC", value: "web-PC" }
                        }),
                        _c("el-option", {
                          attrs: { label: "web-wap", value: "web-wap" }
                        }),
                        _c("el-option", {
                          attrs: { label: "wechat", value: "wechat" }
                        }),
                        _c("el-option", {
                          attrs: { label: "wetch", value: "wetch" }
                        }),
                        _c("el-option", {
                          attrs: { label: "wxMini", value: "wxMini" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "m-b-10",
                    attrs: {
                      icon: "el-icon-search",
                      loading: _vm.searchLoading,
                      type: "success"
                    },
                    on: { click: _vm.handleFilter }
                  },
                  [_vm._v(" 搜索 ")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "m-b-10 window_phone",
                    on: { click: _vm.handleReset }
                  },
                  [_vm._v("重置")]
                ),
                _c(
                  "el-button",
                  { staticClass: "m-10", attrs: { type: "primary" } },
                  [_vm._v("申请数：" + _vm._s(_vm.tableData.count))]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-input",
              {
                attrs: { placeholder: "搜索名称|手机号|IP" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQueryParams.query,
                  callback: function($$v) {
                    _vm.$set(_vm.listQueryParams, "query", $$v)
                  },
                  expression: "listQueryParams.query"
                }
              },
              [
                _c("el-button", {
                  attrs: {
                    slot: "append",
                    icon: "el-icon-search",
                    loading: _vm.searchLoading
                  },
                  on: { click: _vm.handleFilter },
                  slot: "append"
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            "element-loading-text": "拼命加载中",
            data: _vm.tableData.list
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "brand_name",
              label: "品牌名称",
              "min-width": _vm.equipment ? "120px" : "160px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "industry_text",
              label: "行业",
              "min-width": _vm.equipment ? "120px" : "160px"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "手机号", "min-width": "120px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.area_code !== "86"
                      ? _c("span", [_vm._v("(+" + _vm._s(row.area_code) + ")")])
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "styleText cursor",
                        on: {
                          click: function($event) {
                            return _vm.handleCopy(row.phone, "手机号复制成功")
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.phone))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "password",
              label: "密码",
              "min-width": _vm.equipment ? "60px" : "80px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "提交时间",
              "min-width": _vm.equipment ? "120px" : "160px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "update_time",
              label: "更新时间",
              "min-width": _vm.equipment ? "120px" : "160px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "source",
              label: "渠道",
              "min-width": _vm.equipment ? "100px" : "120px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("div", { staticClass: "m-r-10" }, [
                          _vm._v(_vm._s(row.source))
                        ]),
                        row.invite_store_user
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "light",
                                  content: "Right Center 提示文字",
                                  placement: "right"
                                }
                              },
                              [
                                _c("template", { slot: "content" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex col-center" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-avatar",
                                            {
                                              attrs: {
                                                shape: "square",
                                                size: 50,
                                                src:
                                                  row.invite_store_user.avatar
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.errorMemberSrc()
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "p-l-10" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getUserNames(
                                                row.invite_store_user
                                              )
                                            )
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ]),
                                _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("邀请")
                                ])
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "principal",
              label: "联系人",
              "min-width": _vm.equipment ? "80px" : "100px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.principal ? row.principal : "---"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "venues_address",
              label: "地址",
              "min-width": _vm.equipment ? "100px" : "160px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(row.venues_address ? row.venues_address : "---")
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ip",
              label: "IP",
              "min-width": _vm.equipment ? "120px" : "160px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "审核状态",
              "min-width": _vm.equipment ? "180px" : "220px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        row.status === 0
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("未审核")
                            ])
                          : _vm._e(),
                        row.status === 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已通过")
                            ])
                          : _vm._e(),
                        row.status === 2
                          ? _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("已拒绝")
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "m-l-20",
                                    staticStyle: { color: "#ffba00" }
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "拒绝理由：" +
                                          _vm._s(
                                            row.rejection_type_text
                                              ? row.rejection_type_text
                                              : "无"
                                          )
                                      )
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "拒绝原因：" +
                                          _vm._s(
                                            row.rejection_grounds
                                              ? row.rejection_grounds
                                              : "无"
                                          )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": _vm.equipment ? "180px" : "260px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 0 && _vm.btn_access("apply_audit")
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "通过品牌申请，确定继续吗？" },
                            on: {
                              confirm: function($event) {
                                return _vm.apply_status(scope.row.id, 1)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "m-2",
                                attrs: { slot: "reference", type: "primary" },
                                slot: "reference"
                              },
                              [_vm._v("同意")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    scope.row.status === 0 && _vm.btn_access("apply_audit")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: { type: "warning" },
                            on: {
                              click: function($event) {
                                return _vm.handleRefuse(scope.row.id, 2)
                              }
                            }
                          },
                          [_vm._v("拒绝")]
                        )
                      : _vm._e(),
                    _c(
                      "el-popconfirm",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("apply_del"),
                            expression: "btn_access('apply_del')"
                          }
                        ],
                        attrs: { title: "删除申请记录，确定继续吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.apply_del(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: { slot: "reference", type: "danger" },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0 && _vm.equipment,
            expression: "tableData.count > 0 && equipment"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c("van-pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0 && !_vm.equipment,
            expression: "tableData.count > 0 && !equipment"
          }
        ],
        staticClass: "custom-pagination m-t-20",
        attrs: {
          "total-items": _vm.tableData.count,
          "show-page-size": 4,
          "force-ellipses": ""
        },
        on: { change: _vm.handleCurrentChange },
        model: {
          value: _vm.currentPage,
          callback: function($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage"
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: _vm.equipment ? "20%" : "100%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.handleOpen,
            close: _vm.handleClose
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "拒绝类型" } },
                [
                  _c("i", { staticClass: "icon-required" }),
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择拒绝类型" },
                      model: {
                        value: _vm.form.rejection_type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "rejection_type", $$v)
                        },
                        expression: "form.rejection_type"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.rejection_type[1], value: 1 }
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.rejection_type[2], value: 2 }
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.rejection_type[3], value: 3 }
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.rejection_type[4], value: 4 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "驳回理由", prop: "reason" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "【选填】请输入驳回理由"
                    },
                    model: {
                      value: _vm.form.rejection_grounds,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rejection_grounds", $$v)
                      },
                      expression: "form.rejection_grounds"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.apply_status(_vm.refuseId, 2)
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }