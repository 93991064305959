<template>
  <div class="app-container">
    <!-- 通用搜索 -->
    <div class="filter-container m-t-20">
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索菜单名称" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('system_menu_add')" type="primary" class="filter-item m-l-10" @click="handleAdd">
        新增菜单
      </el-button>
    </div>
    <!-- 表格 -->
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      :data="tableData"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      header-align="center"
    >
      <el-table-column label="菜单标题" prop="title" min-width="120" />
      <el-table-column label="菜单图标" prop="icon">
        <template slot-scope="{ row }">
          <i :class="row.icon" />
        </template>
      </el-table-column>
      <el-table-column label="节点名" prop="name" />
      <el-table-column label="重定向地址" prop="redirect" />
      <el-table-column label="路径" prop="path" />
      <el-table-column label="模板路径" prop="component" />
      <el-table-column label="关联路径" prop="activeMenu" />
      <el-table-column label="排序" prop="sort" min-width="60" />
      <el-table-column label="菜单" prop="hidden" min-width="60">
        <template slot-scope="{ row }">
          <el-tag v-if="row.hidden === 0" type="success">是</el-tag>
          <el-tag v-if="row.hidden === 1" type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="根菜单" prop="is_root" min-width="60">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_root === 1" type="success">是</el-tag>
          <el-tag v-if="row.is_root === 0" type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="显示隐藏" prop="is_show" min-width="60">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_show === 1" type="success">显示</el-tag>
          <el-tag v-if="row.is_show === 0" type="danger">隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="180">
        <template slot-scope="{ row }">
          <div>
            <el-link v-show="btn_access('system_menu_set_api_route')" type="success" class="m-4 font-12" @click="handleApiRoute(row.id)">路由</el-link>
            <el-link type="success" class="m-4 font-12" @click="handleAdd(row.id)">添加下级</el-link>
            <el-link v-show="btn_access('system_menu_edit')" type="primary" class="m-4 font-12" @click="handleEdit(row.id)">编辑</el-link>
            <el-link v-show="btn_access('system_menu_del')" type="danger" class="m-4 font-12" @click="handleDelete(row.id)">删除</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <menuDialog
      :dialog-visible-account="dialogVisibleAccount"
      :type="type"
      :type-id="typeId"
      :pid="pid"
      @menuVisible="menuVisible"
    />
    <apiRoute :dialog-visible-api-route="dialogVisibleApiRoute" :type-id="typeId" @apiRouteVisible="apiRouteVisible" />
  </div>
</template>

<script>
// API
import { getMenuList, deleteMenu } from '@/api/admin/account.js'
// 组件
import menuDialog from '../components/menuDialog'
import apiRoute from '../components/apiRoute'

export default {
  components: { menuDialog, apiRoute },
  data() {
    return {
      tableData: [],
      TableLoading: false,
      dialogVisibleAccount: false,
      dialogVisibleApiRoute: false,
      type: '',
      typeId: 0,
      pid: 0
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.TableLoading = true

      const data = {}

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'title', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      getMenuList(data)
        .then(res => {
          const { data } = res
          this.tableData = data
        })
        .finally(() => {
          this.TableLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 新增菜单
    handleAdd(pid = 0) {
      this.type = 'add'
      this.pid = pid
      this.dialogVisibleAccount = true
    },
    // 编辑菜单
    handleEdit(id) {
      this.type = 'edit'
      this.typeId = id
      this.dialogVisibleAccount = true
    },
    // 删除菜单
    handleDelete(id) {
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteMenu(id).then(() => {
          this.getList()
          this.$message.success('删除成功!')
        })
      })
    },
    menuVisible() {
      this.dialogVisibleAccount = false
      this.getList()
    },
    handleApiRoute(id) {
      this.typeId = id
      this.dialogVisibleApiRoute = true
    },
    apiRouteVisible() {
      this.dialogVisibleApiRoute = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
