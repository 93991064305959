<template>
  <div class="p-t-10">
    <SelectSendSms :checked-user-count="checkedUserCount" :multiple-selection="multipleSelection" />
    <!-- 列表 -->
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      :data="tableData.list"
      row-key="id"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column
        type="selection"
        width="55"
      /> -->
      <!-- <el-table-column prop="avatar" label="用户" min-width="120px">
        <template slot-scope="{row}">
          <div class="flex col-center cursor" @click="toUserInfoPage(row.id)">
            <el-avatar shape="square" :size="50" :src="row.avatar">
              <img :src="errorImageSrc">
            </el-avatar>
            <div class="m-l-15">{{ row.nickname }}<br>{{ row.phone }}</div>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="real_name" label="会员">
        <template slot-scope="{ row }">
          <div v-if="!utils.empty(row)" class="flex col-center cursor" @click="toUserInfoPage(row.user.id)">
            <el-avatar shape="square" :size="50" :src="row.avatar">
              <img :src="errorMemberSrc()">
            </el-avatar>
            <div class="m-l-10">
              <span>{{ getUserNames(row) }}</span>
              <br>
              <span>{{ row.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="phone" label="手机号" /> -->
      <el-table-column prop="sex" label="性别" :formatter="formatTableSex" />
      <el-table-column prop="day" label="未来上课天数" :formatter="dayFormat" />
      <el-table-column prop="create_time" label="加入时间" />
      <el-table-column prop="status" label="回访状态">
        <template slot-scope="{row}">
          <el-tag v-if="row.status" type="success">已回访</el-tag>
          <el-tag v-else type="info">未回访</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('s_user_track_list')" icon="el-icon-notebook-2" @click="toVisitorTrackPage(scope.row.id)">回访记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <!-- 用户回访 -->
    <VisitorTrack :id="dialog.trackId" :track-visible.sync="dialog.trackVisible" :is-show="isShow" @trackVisible="trackVisible" />
  </div>
</template>

<script>
import SelectSendSms from '../selectSendSms.vue'
import { getNotClass } from '@/api/store/user.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import VisitorTrack from '@/views/store/user/tracking' // 用户回访
export default {
  components: { Pagination, SelectSendSms, VisitorTrack },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      listLoading: false,
      checkedUserCount: 0,
      multipleSelection: [],
      dialog: { trackId: null, trackVisible: false },
      isShow: 13
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 保存选中结果
    handleSelectionChange(val) {
      // 先清空id数组
      this.multipleSelection = []
      // 去重获取被选中的id值存入到数组中
      for (const selectedItem of val) {
        this.multipleSelection.push(selectedItem.id)
      }
      this.checkedUserCount = this.multipleSelection.length
    },
    // 获取列表
    getList() {
      this.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getNotClass(data).then(res => {
        this.tableData = res.data
        this.listLoading = false
      }).catch(() => {})
    },
    dayFormat(row, coulum) {
      return row.day ? row.day + '天' : '从未'
    },
    realNameFormat(row, colum) {
      return row.real_name ? row.real_name : '--'
    },
    toVisitorTrackPage(id) {
      this.dialog.trackId = id
      this.dialog.trackVisible = true
    },
    trackVisible() {
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
