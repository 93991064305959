var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("el-card", { staticClass: "box-card" }, [
          _c(
            "div",
            {
              staticClass: "flex row-between",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.title) + " （共计 "),
                _c("b", [_vm._v(_vm._s(_vm.tableData.count))]),
                _vm._v(" 条）")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.btn_access("s_workbench_sign_in") && !_vm.display,
                      expression:
                        "btn_access('s_workbench_sign_in') && !display"
                    }
                  ],
                  attrs: {
                    type: "success",
                    loading: _vm.buttonLoading,
                    disabled: _vm.totalSignInS == _vm.totalValidS
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleSignIn(_vm.activeId)
                    }
                  }
                },
                [_vm._v("全部签到")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text item" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: { data: _vm.tableData.list, "row-key": "id" }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "user",
                      label: "会员信息",
                      "min-width": "180px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var ref_row = ref.row
                          var user = ref_row.user
                          var subscribe_channel = ref_row.subscribe_channel
                          var remark = ref_row.remark
                          return [
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                user
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "flex col-center cursor",
                                        on: {
                                          click: function($event) {
                                            return _vm.toUserInfoPage(user.id)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "el-avatar",
                                          {
                                            attrs: {
                                              shape: "square",
                                              size: 50,
                                              src: user.avatar
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: _vm.errorMemberSrc()
                                              }
                                            })
                                          ]
                                        ),
                                        _c("div", { staticClass: "p-l-10" }, [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.getUserNames(user))
                                            )
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(user ? user.phone : "")
                                            )
                                          ])
                                        ])
                                      ],
                                      1
                                    )
                                  : _c("div", { staticClass: "text-danger" }, [
                                      _vm._v("会员信息丢失")
                                    ]),
                                subscribe_channel == "STORE"
                                  ? _c("el-tag", { staticClass: "m-l-10" }, [
                                      _vm._v("代约")
                                    ])
                                  : _vm._e(),
                                remark
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: remark,
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-tag",
                                          { staticClass: "m-l-10" },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-document"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.type === 3
                    ? _c("el-table-column", {
                        attrs: { label: "课程名称", "min-width": "120px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("div", [_vm._v(_vm._s(row.course.name))])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1739231719
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "user_membership_card.number",
                      label: "卡号",
                      "min-width": "120px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  row.user_membership_card
                                    ? row.user_membership_card.number
                                    : "--"
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "limit", label: "扣减" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(row.limit + "" + _vm.getType(row)) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "limit", label: "当前余额" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.user_membership_card
                                    ? row.user_membership_card.limit +
                                        "" +
                                        _vm.getType(row)
                                    : "--"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "limit", label: "剩余天数" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            row.user_membership_card &&
                            _vm.getExcessTime(
                              row.user_membership_card.valid_time
                            ) > 0
                              ? _c("span", { staticClass: "text-success" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getExcessTime(
                                        row.user_membership_card.valid_time
                                      )
                                    ) + "天"
                                  )
                                ])
                              : _c("span", [_vm._v("未开卡")])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.type !== 3
                    ? _c("el-table-column", {
                        attrs: { prop: "queue", label: "排队" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "block" },
                                    [
                                      row.queue_on
                                        ? _c(
                                            "el-tag",
                                            {
                                              attrs: {
                                                type: _vm.getColorType(
                                                  row.queue_status
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(row.queue_status_text)
                                              )
                                            ]
                                          )
                                        : _c(
                                            "el-tag",
                                            { attrs: { type: "info" } },
                                            [_vm._v("非排队")]
                                          )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2857222383
                        )
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var status = ref.row.status
                          return [
                            _c(
                              "div",
                              { staticClass: "block" },
                              [
                                status == 0
                                  ? _c("el-tag", [_vm._v("已预约")])
                                  : _vm._e(),
                                status == 1
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("已签到")]
                                    )
                                  : _vm._e(),
                                status == 2
                                  ? _c("el-tag", { attrs: { type: "info" } }, [
                                      _vm._v("已取消")
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label:
                        _vm.type === 1 || _vm.type === 3
                          ? "上课时间"
                          : "预约时间",
                      "min-width": "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.type === 1 || _vm.type === 3
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        scope.row.subscribe_time
                                      )
                                    )
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        scope.row.create_time
                                      )
                                    )
                                  )
                                ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "操作", "min-width": "160px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access(
                                      "s_course_subscribe_info"
                                    ),
                                    expression:
                                      "btn_access('s_course_subscribe_info')"
                                  }
                                ],
                                staticClass: "m-2",
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.course_details(scope.row)
                                  }
                                }
                              },
                              [_vm._v("详情")]
                            ),
                            scope.row.status === 0 && scope.row.queue === 0
                              ? [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: "确定签到本条预约记录吗？"
                                      },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.course_sign(
                                            scope.row.id,
                                            scope.row.user_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.btn_access(
                                                "s_workbench_sign_in"
                                              ),
                                              expression:
                                                "btn_access('s_workbench_sign_in')"
                                            }
                                          ],
                                          staticClass: "m-2",
                                          attrs: {
                                            slot: "reference",
                                            size: "mini",
                                            type: "success"
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v("签到")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            scope.row.status === 0
                              ? [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: "确定取消本条预约记录吗？"
                                      },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.course_cancel(scope.row.id)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.btn_access(
                                                "s_workbench_cancel_subscribe"
                                              ),
                                              expression:
                                                "btn_access('s_workbench_cancel_subscribe')"
                                            }
                                          ],
                                          staticClass: "m-2",
                                          attrs: {
                                            slot: "reference",
                                            size: "mini",
                                            type: "danger"
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v("取消预约")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            scope.row.status === 1
                              ? [
                                  _c(
                                    "el-popconfirm",
                                    {
                                      attrs: {
                                        title: "确定取消签到本条预约记录吗？"
                                      },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.course_cancelCheck(
                                            scope.row.id,
                                            scope.row.user_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.btn_access(
                                                "s_workbench_cancel_sign"
                                              ),
                                              expression:
                                                "btn_access('s_workbench_cancel_sign')"
                                            }
                                          ],
                                          staticClass: "m-2",
                                          attrs: {
                                            slot: "reference",
                                            size: "mini",
                                            type: "danger"
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v("取消签到")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  small: "",
                  total: _vm.tableData.count,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList
                }
              })
            ],
            1
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      [
        _c("bookInfo", {
          attrs: { logid: _vm.logid },
          on: {
            "update:logid": function($event) {
              _vm.logid = $event
            }
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }