<template>
  <div>
    <!-- <work-table /> -->
  </div>
</template>

<script>
// import WorkTable from '@/views/store/worktable/index.vue'

export default {
  // components: { WorkTable },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
    this.$router.push({ name: 's_work' })
  }
}
</script>

<style lang="scss" scoped>

</style>
