var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wx-config" }, [
    _c("div", { staticClass: "wx-box" }, [
      _c("div", { staticClass: "item " }, [
        _c("div", {}, [
          _c("div", { staticClass: "label text-primary" }, [
            _vm._v("签到二维码")
          ]),
          _c("div", { staticClass: "content flex" }, [
            _vm._m(0),
            _c("div", { staticClass: "p-l-30" }, [
              _c(
                "div",
                { staticClass: "text-center flex" },
                [
                  [
                    _c(
                      "el-skeleton",
                      {
                        attrs: {
                          loading: !_vm.qrcodeInfo.wechat_path,
                          animated: ""
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "template" },
                          [
                            _c("el-skeleton-item", {
                              staticClass: "m-r-10",
                              staticStyle: { width: "180px", height: "180px" },
                              attrs: { variant: "image" }
                            }),
                            _c("div", { staticStyle: { padding: "14px" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-items": "space-between"
                                  }
                                },
                                [
                                  _c("el-skeleton-item", {
                                    attrs: { variant: "text" }
                                  })
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        [
                          _c(
                            "div",
                            { staticClass: "image_box m-r-10" },
                            [
                              _c("el-image", {
                                staticClass: "yueke_image",
                                attrs: {
                                  "preview-src-list": [
                                    _vm.qrcodeInfo.wechat_path +
                                      "?" +
                                      new Date().getTime()
                                  ],
                                  src: _vm.qrcodeInfo.wechat_path,
                                  alt: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-link",
                            {
                              staticClass: "image_down",
                              attrs: {
                                icon: "el-icon-download",
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadCodeImg(
                                    "qrcodeInfo_wechat_path"
                                  )
                                }
                              }
                            },
                            [_vm._v("点击下载二维码")]
                          )
                        ]
                      ],
                      2
                    )
                  ],
                  [
                    _c(
                      "el-skeleton",
                      {
                        attrs: {
                          loading: !_vm.qrcodeInfo.program_path,
                          animated: ""
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "template" },
                          [
                            _c("el-skeleton-item", {
                              staticClass: "m-r-10",
                              staticStyle: { width: "180px", height: "180px" },
                              attrs: { variant: "image" }
                            }),
                            _c("div", { staticStyle: { padding: "14px" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-items": "space-between"
                                  }
                                },
                                [
                                  _c("el-skeleton-item", {
                                    attrs: { variant: "text" }
                                  })
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        [
                          _c(
                            "div",
                            { staticClass: "image_box m-r-10" },
                            [
                              _c("el-image", {
                                staticClass: "yueke_image",
                                attrs: {
                                  "preview-src-list": [
                                    _vm.qrcodeInfo.program_path +
                                      "?" +
                                      new Date().getTime()
                                  ],
                                  src: _vm.qrcodeInfo.program_path,
                                  alt: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-link",
                            {
                              staticClass: "image_down",
                              attrs: {
                                icon: "el-icon-download",
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadCodeImg(
                                    "qrcodeInfo_program_path"
                                  )
                                }
                              }
                            },
                            [_vm._v("点击下载二维码")]
                          )
                        ]
                      ],
                      2
                    )
                  ]
                ],
                2
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips flex-1" }, [
      _vm._v("会员打开"),
      _c("span", { staticClass: "text-primary" }, [_vm._v("微信")]),
      _vm._v(" -> "),
      _c("span", { staticClass: "text-primary" }, [_vm._v("扫描二维码")]),
      _vm._v("进入约课，即可在约课后由系统自动签到。此二维码仅支持预约当日课程")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }