<template>
  <div class="app-container special-center">
    <el-form ref="ruleForm" :model="form" label-width="100px" label-position="left" :rules="rules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="场馆名" prop="name">
            <el-input v-model="form.name" placeholder="请填写场馆名称" maxlength="32" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="场馆图片" prop="images">
            <el-upload
              v-loading="upLoading"
              class="upload-venues"
              :action="storeUpload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture-card"
              :on-success="upSuccess"
              :headers="utils.upload_headers()"
              :limit="1"
              :on-exceed="handleExceed"
              :on-progress="upProgress"
              accept=".jpg,.jpeg,.png"
            >
              <i class="el-icon-plus" />
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人" prop="principal">
            <el-input v-model="form.principal" placeholder="请填写场馆负责人" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone" type="number" placeholder="请填写负责人手机号" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="telephone">
            <el-input v-model="form.telephone" placeholder="请填写场馆联系电话" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="form.longitude" label="坐标">
            <el-input :disabled="true" :value="form.longitude + ',' + form.latitude" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="所在地区">
            <el-cascader v-model="optionsData" class="elCascaderYxj" :options="options" clearable />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="详细地址">
            <el-input
              v-model="form.address"
              placeholder="请输入详细地址"
            />
            <!-- <el-button type="text" icon="el-icon-add-location" @click.native="mapVisible = !mapVisible">
              拾取位置及坐标
            </el-button> -->
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="地图坐标">
            <el-button v-if="form.longitude" type="success" icon="el-icon-add-location" @click.native="mapVisible = !mapVisible">
              已标记
            </el-button>
            <el-button v-else type="primary" icon="el-icon-add-location" @click.native="mapVisible = !mapVisible">
              请选择坐标
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="场馆介绍" prop="abstract">
            <el-input v-model="form.abstract" type="textarea" rows="3" placeholder="请填写场馆介绍" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="营业时间" prop="start_time">
            <el-time-picker
              v-model="form.start_time"
              placeholder="开始时间"
              value-format="HH:mm"
              format="HH:mm"
              arrow-control
            />
            -
            <el-time-picker
              v-model="form.end_time"
              placeholder="结束时间"
              value-format="HH:mm"
              format="HH:mm"
              arrow-control
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="dayparting_config">
            <div>
              <el-checkbox-group v-model="form.business_week">
                <el-checkbox :label="1" class="m-b-10">周一</el-checkbox>
                <el-checkbox :label="2" class="m-b-10">周二</el-checkbox>
                <el-checkbox :label="3" class="m-b-10">周三</el-checkbox>
                <el-checkbox :label="4" class="m-b-10">周四</el-checkbox>
                <el-checkbox :label="5" class="m-b-10">周五</el-checkbox>
                <el-checkbox :label="6" class="m-b-10">周六</el-checkbox>
                <el-checkbox :label="7" class="m-b-10">周日</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <el-form-item label="门店类型" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio :label="10" :value="10">总店</el-radio>
              <el-radio :label="20" :value="20">分店</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="设为默认门店" prop="default">
            <el-radio-group v-model="form.default">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排序" prop="weigh">
            <el-input v-model="form.weigh" type="number" placeholder="请输入场馆排序" />
            <small class="text-warning">排序规则：从大到小</small>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="submit">提交</el-button>
      <el-button @click="backPage">取消</el-button>
    </div>
    <MapDialog :map-visible="mapVisible" @mapLocationClose="mapLocationClose" @mapLocationSave="mapLocationSave" />
  </div>
</template>

<script>
import { addVenues } from '@/api/store/venues.js'
import MapDialog from '@/components/BaiduMap/index.vue' // 百度地图坐标拾取组件
import areaData from './area.json'
import options from './areaData.js'

export default {
  components: {
    MapDialog
  },
  data() {
    return {
      lg_la: '',
      mapVisible: false,
      form: {
        name: '',
        weigh: 1,
        type: 20,
        default: 0,
        start_time: '09:00',
        end_time: '20:00',
        abstract: '',
        principal: '',
        phone: '',
        address: '',
        business_week: []
      },
      rules: ['name', 'type', 'principal', 'phone', 'address', 'longitude', 'start_time'],
      fileList: [],
      areaOptions: [],
      location: [],
      options: options,
      optionsData: []
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.areaOptions = areaData
  },
  methods: {
    mapLocationClose() {
      this.mapVisible = false
    },
    mapLocationSave(e) {
      console.log(e)
      // this.$set(this.form, 'address', e.address)
      this.$set(this.form, 'longitude', e.longitude)
      this.$set(this.form, 'latitude', e.latitude)
      const separator = ','
      this.lg_la = e.longitude + separator + e.latitude
      this.mapVisible = false
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.form.business_week.length !== 0) {
            this.form.business_week = this.form.business_week.join(',').split(',')
          }
          const data = this.form
          data.business_week
          const images = []
          this.fileList.forEach(function(i, v) {
            images.push(i.relativity_url)
          })
          this.form.images = images.join(',')

          if (this.optionsData.length > 0) {
            this.form.country = this.optionsData[0]
            this.form.province = this.optionsData[1]
            this.form.city = this.optionsData[2]
            this.form.area = this.optionsData[3]
          }

          // 地区数据解析
          // var str = this.form.address
          // var reg = /.+?(省|市|自治区|自治州|县|区)/g // 省市区的正则
          // const area_list = str.match(reg)

          // var province = ''

          // if (area_list) {
          //   if (area_list[0].indexOf('北京') !== -1) {
          //     province = '北京市'
          //   } else if (area_list[0].indexOf('天津') !== -1) {
          //     province = '天津市'
          //   } else {
          //     province = area_list[0]
          //   }
          //   this.form.province = province
          //   this.form.city = province === '北京市' || province === '天津市' ? '市辖区' : area_list[1]
          //   this.form.area = province === '北京市' || province === '天津市' ? area_list[1] : area_list[2]
          // }

          // return false
          addVenues(data)
            .then(() => {
              this.$message.success('添加成功')
              this.backPage()
            })
            .catch(() => {})
        }
      })
    },
    // 上传成功事件
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      file.relativity_url = response.data.path
      this.fileList = fileList
      // this.form.src = response.data.path
    },
    upProgress() {
      this.upLoading = true
    },
    // 图片移除事件
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 张图片')
    },
    backPage() {
      this.returnPage(this.utils.getVenues().id === -1 ? { name: 's_venues_list' } : {})
    }
  }
}
</script>

<style lang="scss" scoped>
.dayparting_config {
  padding-left: 115px;
}
</style>
