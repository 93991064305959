<template>
  <div class="">
    <div class="text-right">
      <el-button v-show="btn_access('s_orderRuleForFineSetting')" type="primary" icon="el-icon-edit" @click="$router.push({ name: 's_orderRuleForFineSetting', params: { type: 'orderRuleForFine' } })">
        编辑设置
      </el-button>
    </div>
    <div v-for="(item, index) in infoNode" :key="index" class="m-y-10">
      <div v-if="item.type == 'alert'">
        <el-alert :title="item.text" :closable="false" show-icon type="warning" />
      </div>
      <div v-if="item.type == 'table'">
        <div class="p-y-8 p-x-10 title">
          {{ item.title }}
        </div>
        <div v-if="item.data[0].value === '关闭' || item.data[0].value === '开启'">
          <el-table v-if="item.data[0].value === '开启'" :show-header="false" :data="item.data" stripe style="width: 100%">
            <el-table-column prop="title" label="标题" width="180" />
            <el-table-column prop="value" label="值" />
          </el-table>
          <div v-if="item.data[0].value === '关闭'" class="table-close">
            <span class="table-title">{{ item.data[0].title }}</span>
            <span class="table-value">{{ item.data[0].value }}</span>
          </div>
        </div>
        <div v-else>
          <el-table :show-header="false" :data="item.data" stripe style="width: 100%">
            <el-table-column prop="title" label="标题" width="180" />
            <el-table-column prop="value" label="值" />
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSubscribeSetting } from '@/api/store/subscribe.js'
export default {
  data() {
    return {
      infoNode: '',
      reslist: {
        fine_subscribe: {
          type: 1,
          time_type: 1,
          before_day_1: 1,
          before_day_2: 1,
          before_course_minute_1: 1,
          before_course_minute_2: 1,
          before_start_time_1: '',
          before_start_time_2: '',
          before_end_time: ''
        },
        fine_queue: {
          status: 1,
          max: 5,
          stop_queue_minute: 60,
          stop_alternate_minute: ''
        },
        fine_sign: {
          autonomously_status: 0,
          autonomously_time_type: 1,
          scan_code_status: 1,
          scan_code_time_type: 1,
          before: 5,
          after_1: 15,
          after_2: 15
        },
        fine_cancel_subscribe: {
          status: 1,
          time_type: 1,
          minute_1: 10,
          minute_2: 10,
          minute_3: 10
        },
        fine_other: {
          show_type: 1,
          schoolmate_status: 0
        }
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      getSubscribeSetting().then(res => {
        this.reslist = res.data.res

        this.infoNode = [
          {
            type: 'table',
            title: '预约时间设置',
            data: [
              {
                title: '预约方式',
                value: this.getValue(function(that) {
                  switch (that.reslist.fine_subscribe.type) {
                    case 1:
                      return '不支持会员自主预约'
                    case 2:
                      return '自主预约,不限制预约时间,'
                    case 3:
                      return '自主预约,限制预约时间'
                    default:
                      break
                  }
                })
              },
              {
                title: '预约时间',
                value: this.getValue(function(that) {
                  switch (that.reslist.fine_subscribe.time_type) {
                    case 1:
                      return `提前${
                        that.reslist.fine_subscribe['before_day_' + that.reslist.fine_subscribe.time_type]
                      }天,${that.reslist.fine_subscribe['before_start_time_' + that.reslist.fine_subscribe.time_type]} 接受自主预约`
                    case 2:
                      return `提前${
                        that.reslist.fine_subscribe['before_day_' + that.reslist.fine_subscribe.time_type]
                      }天,${that.reslist.fine_subscribe['before_start_time_' + that.reslist.fine_subscribe.time_type]} 后接受自主预约`
                    case 3:
                      return `开课前 ${that.reslist.fine_subscribe['before_course_minute_1']} 分钟不接受自主预约`
                    default:
                      break
                  }
                })
              },
              {
                title: '预约截止时间',
                value: this.getValue(function(that) {
                  return `课程预约人数未满，会员需早于开课前 ${that.reslist.fine_subscribe['before_course_minute_2']} 分钟自主预约`
                })
              }
            ]
          },
          {
            type: 'table',
            title: '排队候补设置',
            data: [
              {
                title: '排队候补',
                value: this.getValue(function(that) {
                  return that.reslist.fine_queue.status ? '开启' : '关闭'
                })
              },
              {
                title: '最大排队人数',
                value: this.getValue(function(that) {
                  if (that.reslist.fine_queue.max === 0) return '不限制'
                  return `${that.reslist.fine_queue.max}人`
                })
              },
              {
                title: '停止排队时间',
                value: this.getValue(function(that) {
                  if (that.reslist.fine_queue.stop_queue_minute === 0) return '不限制'
                  return `开课前 ${that.reslist.fine_queue.stop_queue_minute} 分钟停止排队`
                })
              },
              {
                title: '停止候补时间',
                value: this.getValue(function(that) {
                  if (that.reslist.fine_queue.stop_alternate_minute === 0) return '不限制'
                  return `开课前 ${that.reslist.fine_queue.stop_alternate_minute} 分钟停止候补`
                })
              }
            ]
          },
          {
            type: 'table',
            title: '自主签到',
            data: [
              {
                title: '自主签到',
                value: this.getValue(function(that) {
                  return that.reslist.fine_sign.autonomously_status ? '开启' : '关闭'
                })
              },
              {
                title: '签到时间',
                value: this.getValue(function(that) {
                  switch (that.reslist.fine_sign.autonomously_time_type) {
                    case 1:
                      return '课程当天，会员可随时签到'
                    case 2:
                      return '课程当天，会员可在课程结束前签到'
                    case 3:
                      return `课程当天，会员可在课程结束后 ${that.reslist.fine_sign.after_1} 分钟内签到`
                    default:
                      break
                  }
                })
              }
            ]
          },
          {
            type: 'table',
            title: '扫码签到',
            data: [
              {
                title: '扫码签到',
                value: this.getValue(function(that) {
                  return that.reslist.fine_sign.scan_code_status ? '开启' : '关闭'
                })
              },
              {
                title: '签到时间',
                value: this.getValue(function(that) {
                  switch (that.reslist.fine_sign.scan_code_time_type) {
                    case 1:
                      return '课程当天，会员可签到两小时内开始的课程'
                    case 2:
                      return '课程当天，会员可在课程结束前扫码签到'
                    case 3:
                      return `课程当天，会员可在课程开始前 ${that.reslist.fine_sign.before} 分钟至课程结束后 ${that.reslist.fine_sign.after_2} 分钟内扫码签到`
                    default:
                      break
                  }
                })
              }
            ]
          },
          {
            type: 'table',
            title: '预约取消设置',
            data: [
              {
                title: '自主取消',
                value: this.getValue(function(that) {
                  return that.reslist.fine_cancel_subscribe.status ? '开启' : '关闭'
                })
              },
              {
                title: '取消时间',
                value: this.getValue(function(that) {
                  switch (that.reslist.fine_cancel_subscribe.time_type) {
                    case 1:
                      return `会员自主取消不晚于开课前 ${that.reslist.fine_cancel_subscribe['minute_' + that.reslist.fine_cancel_subscribe.time_type]} 分钟`
                    case 2:
                      return `会员自主取消不晚于开课后 ${that.reslist.fine_cancel_subscribe['minute_' + that.reslist.fine_cancel_subscribe.time_type]} 分钟`
                    case 3:
                      return `会员自主取消不晚于课程结束后 ${that.reslist.fine_cancel_subscribe['minute_' + that.reslist.fine_cancel_subscribe.time_type]} 分钟`
                    default:
                      break
                  }
                })
              }
            ]
          },
          {
            type: 'table',
            title: '开课人数限制',
            data: [
              {
                title: '开课人数限制',
                value: this.getValue(function(that) {
                  return that.reslist.fine_minimum_member.is_check ? '开启' : '关闭'
                })
              },
              {
                title: '微信端约课人数显示',
                value: this.getValue(function(that) {
                  return `满 ${that.reslist.fine_minimum_member.lower_limit} 人开课，人数不足提前 ${that.reslist.fine_minimum_member.check_time} 分钟自动取消课程`
                })
              }
            ]
          },
          {
            type: 'table',
            title: '其他设置',
            data: [
              {
                title: '查看约课同学',
                value: this.getValue(function(that) {
                  return that.reslist.fine_other.schoolmate_status ? '开启' : '关闭'
                })
              },
              {
                title: '微信端约课人数显示',
                value: this.getValue(function(that) {
                  switch (that.reslist.fine_other.show_type) {
                    case 1:
                      return `“还可预约几人”`
                    case 2:
                      return `“已约约人数/容纳人数”`
                    case 3:
                      return `“预约满几人开课”`
                    default:
                      break
                  }
                })
              }
            ]
          }
        ]

        // 自主预约，不限制预约时间 不显示预约时间
        if (this.reslist.fine_subscribe.type === 2) {
          delete this.infoNode[0]['data'][1]
        }
      })
    },
    getValue(a) {
      return a(this)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-close {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #dfe6ec;
  .table-title,
  .table-value {
    display: inline-block;
    line-height: 20px;
    padding: 10px 10px;
  }
  .table-title {
    width: 180px;
  }
}
</style>
