<template>
  <div v-loading="loading" class="app-container special-center">
    <el-page-header content="会员卡详情" class="page-header" @back="returnPage()" />
    <el-divider />
    <!-- 会员卡信息 -->
    <div v-if="!is_edit" class="cardInfo">
      <!-- 用户信息 -->
      <el-row :gutter="20" class="userHeader flex row-between">
        <el-col v-if="cardInfo.user" :span="12" :offset="0" class="flex">
          <el-avatar shape="square" :size="80" :src="cardInfo.user.avatar">
            <img :src="errorMemberSrc()">
          </el-avatar>
        </el-col>
        <el-col :span="12" :offset="0" class="text-right card_operation">
          <el-button v-show="btn_access('s_user_vipcard_edit')" type="primary" icon="el-icon-edit" @click="is_edit = 1">
            编辑
          </el-button>
          <el-button type="warning" icon="el-icon-edit" @click="operation()">操作</el-button>
          <el-button v-show="btn_access('s_user_vipcard_delete')" icon="el-icon-delete" type="danger" @click="delUserCardId(cardInfo.id)">
            删除
          </el-button>
          <!-- <el-button type="info" icon="el-icon-back" @click="returnPage()">返回</el-button> -->
        </el-col>
      </el-row>
      <el-descriptions direction="vertical" :column="3" border style="padding-top:10px;" :label-style="{ textAlign: 'center' }" :content-style="{ textAlign: 'center' }">
        <el-descriptions-item v-if="cardInfo.user" label="姓名/昵称" :span="2">
          <el-link target="_blank" class="color" @click="toDetailsInfoPage(cardInfo.user ? cardInfo.user.id : '')">
            {{ cardInfo.user.real_name ? cardInfo.user.real_name : '--' }}（{{ cardInfo.user.nickname }}）
          </el-link>
          <!-- <span class="cursor" @click="toDetailsInfoPage(cardInfo.user ? cardInfo.user.id : '')">{{ cardInfo.user.real_name ? cardInfo.user.real_name : '--' }}（{{ cardInfo.user.nickname }}）</span> -->
        </el-descriptions-item>
        <el-descriptions-item v-if="cardInfo.user" label="手机号" :span="2">
          {{ cardInfo.user.phone }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 基本信息 -->
      <h5>基本信息</h5>
      <el-descriptions class="margin-top" :column="2" border :label-style="{ width: '100px' }">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-bank-card" />
            卡号
          </template>
          <el-tag>{{ cardInfo.number }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-postcard" />
            卡名称
          </template>
          <!-- <span v-if="cardInfo.card">{{ cardInfo.card.name }}</span> -->
          <el-link v-if="cardInfo.card" class="color" target="_blank" @click="handleToPage(cardInfo.membership_card_id)">
            {{ cardInfo.card.name }}
          </el-link>
          <span v-else>--</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-connection" />
            卡状态
          </template>
          <el-tag :type="card_status">{{ formatStatus(cardInfo.status_id) }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-collection-tag" />
            卡类型
          </template>
          {{ formatCardType(cardInfo.card.type) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-time" />
            发卡时间
          </template>
          {{ cardInfo.create_time }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-collection-tag" />
            开卡类型
          </template>
          {{ formatCardOpenType(cardInfo.type) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-time" />
            开卡时间
          </template>
          {{ cardInfo.type === 1 && !cardInfo.open_card_time ? '未开卡' : cardInfo.open_card_time }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-time" />
            有效期至
          </template>
          {{ cardInfo.valid_time !== 0 ? cardInfo.valid_time : '未开卡' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-c-scale-to-original" />
            余额
          </template>
          <span v-if="cardInfo.card.type === 0 && cardInfo.status_id !== 14">
            <span v-if="getExcessTime(cardInfo.valid_time) > 0" class="text-success">
              {{ getExcessTime(cardInfo.valid_time) }}
              <b v-if="cardInfo.card">{{ formatCardUnitType(cardInfo.card.type) }}</b>
            </span>
            <span v-else class="text-danger">
              {{ getExcessTime(cardInfo.valid_time) }}
              <b v-if="cardInfo.card">{{ formatCardUnitType(cardInfo.card.type) }}</b>
            </span>
          </span>
          <span v-else>
            {{ cardInfo.limit }}
            <b v-if="cardInfo.card">{{ formatCardUnitType(cardInfo.card.type) }}</b>
          </span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-c-scale-to-original" />
            加赠
          </template>
          <span v-if="cardInfo.gift" class="text-success p-l-5">
            + {{ cardInfo.gift }}
            <b v-if="cardInfo.card">{{ formatCardUnitType(cardInfo.card.type) }}</b>
          </span>
          <span v-else>无</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-money" />
            实收金额
          </template>
          <span>{{ cardInfo.actual_amount }} 元</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user" />
            推荐办卡人
          </template>
          {{ cardInfo.recommend ? cardInfo.recommend : '无' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-information" />
            开卡场馆
          </template>
          <span v-if="cardInfo.venues">{{ cardInfo.venues.name }}</span>
          <span v-else>--</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-news" />
            付款方式
          </template>
          {{ payment_method[cardInfo.payment_method_id] }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets" />
            请假情况
          </template>
          已请假{{ cardInfo.leave_number }}次 共计{{ cardInfo.leave_day }}天
          <span class="text-danger">{{ cardInfo.leave ? '允许' : '不允许' }}自行请假</span>
        </el-descriptions-item>
        <!-- <el-descriptions-item /> -->
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-edit" />
            备注
          </template>
          <div v-html="cardInfo.remark" />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-postcard" />
            耗卡金额
          </template>
          {{ cardInfo.consume }}元
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-money" />
            剩余资产
          </template>
          {{ cardInfo.balance }}元
        </el-descriptions-item>
        <template slot="extra" />
      </el-descriptions>
      <!-- 变更、预约记录 -->
      <div class="cardLog">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="变更记录" name="first">
            <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
              <el-form-item label="操作类型">
                <el-select v-model="listQueryParams.id" placeholder="请选择" clearable>
                  <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
                <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-l-20" @click="handleFilter">搜索</el-button>
              </el-form-item>
            </el-form>
            <el-input v-model="listQueryParams.query" style="width: 300px;" placeholder="搜索备注" @keyup.enter.native="handleFilter">
              <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
            </el-input>
            <el-table ref="multipleTable" v-loading="chang_logs_loading" :data="chang_logs.list" row-key="id" align="center" class="m-t-10">
              <el-table-column prop="venues.name" label="操作人" width="180">
                <template slot-scope="{ row }">
                  <div v-if="row.store_user_id === 0">系统</div>
                  <div v-else>{{ row.store_user ? row.store_user.real_name : '--' }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="status.name" label="操作类型" min-width="100" />
              <el-table-column prop="remark" label="备注" min-width="200">
                <template slot-scope="{ row }">
                  {{ row.remark }}
                  <div v-if="row.create_time && new Date(row.create_time) < new Date('2023-12-01')">
                    <p v-if="row.user_membership_card_status_id == 3" :span="24">
                      {{ row.status.name }}：{{ row.start_time | formatDate('YYYY-MM-DD') }} -
                      {{ row.end_time | formatDate('YYYY-MM-DD') }}
                    </p>
                    <p v-if="row.user_membership_card_status_id == 5" :span="24">{{ row.status.name }}：{{ row.end_time | formatDate('YYYY-MM-DD') }}</p>
                    <p v-if="row.user_membership_card_status_id == 13" :span="24">
                      {{ row.status.name }}：{{ row.start_time | formatDate('YYYY-MM-DD') }} -
                      {{ row.end_time | formatDate('YYYY-MM-DD') }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="create_time" label="操作时间" width="180" />
            </el-table>
            <pagination v-show="chang_logs.count > 0" :total="chang_logs.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getCardChangeLogs" />
          </el-tab-pane>
          <el-tab-pane label="上课记录" name="second">
            <el-table ref="multipleTable" v-loading="course_subscribe_loading" :data="tableData.list" row-key="id" align="center">
              <!-- <el-table-column prop="id" label="ID" width="100" /> -->
              <el-table-column prop="venues.name" label="场馆" />
              <el-table-column prop="course.name" label="课程">
                <template slot-scope="{ row }">
                  <span class="p-b-4 cursor" style="border-bottom: 1px solid #1890ff; color: #1890ff;" @click="course_details(row)">{{ row.course.name }}（{{ course_type[row.type] }}）</span>
                </template>
              </el-table-column>
              <el-table-column label="卡号">
                <template slot-scope="{ row }">
                  <el-tag>{{ row.user_membership_card ? row.user_membership_card.number : '付费约课' }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="store_user.real_name" label="教练" />
              <el-table-column prop="type" label="类型">
                <template slot-scope="{ row }">
                  {{ course_type[row.type] }}
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态" min-width="120">
                <template slot-scope="{ row }">
                  <span v-if="row.status == 0" class="text-primary">「{{ formatCourseStatus(row) }}」</span>
                  <span v-if="row.status == 1" class="text-success">「{{ formatCourseStatus(row) }}」</span>
                  <span v-if="row.status == 2" class="text-info">「{{ formatCourseStatus(row) }}」</span>
                  <div v-if="row.status == 2">
                    取消时间：{{ row.cancel_time | formatDate('YYYY-MM-DD HH:mm') }}
                    <br>
                    取消原因：{{ row.cancel_notice }}
                    <br>
                  </div>
                  <div v-if="row.status !== 2">
                    上课时间：{{ row.subscribe_time | formatDate('YYYY-MM-DD HH:mm') }}
                    <br>
                    预约时间：{{ row.create_time.substr(0, 16) }}
                  </div>
                  <div v-if="row.status == 1">签到时间：{{ row.sign_time | formatDate('YYYY-MM-DD HH:mm') }}</div>
                  <div v-if="row.queue">排队队列：第{{ row.queue }}位</div>
                  <div v-if="row.remark">备注：{{ row.remark }}</div>
                </template>
              </el-table-column>
            </el-table>
            <pagination v-show="tableData.count > 0" :auto-scroll="false" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="get_course_subscribe_list" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 会员卡编辑 -->
    <div v-if="is_edit" class="cardEdit">
      <el-descriptions direction="vertical" :column="3" border style="padding-top:10px;" :label-style="{ textAlign: 'center' }" :content-style="{ textAlign: 'center' }">
        <el-descriptions-item v-if="cardInfo.user" label="姓名">
          {{ cardInfo.user.real_name ? cardInfo.user.real_name : '--' }}
        </el-descriptions-item>
        <el-descriptions-item v-if="cardInfo.user" label="昵称">{{ cardInfo.user.nickname }}</el-descriptions-item>
        <el-descriptions-item v-if="cardInfo.user" label="手机号" :span="2">
          {{ cardInfo.user.phone }}
        </el-descriptions-item>
      </el-descriptions>
      <h5>编辑会员卡</h5>
      <el-form v-if="editForm" ref="ruleForm" label-position="left" label-width="100px" :model="editForm" :rules="rules" size="small">
        <el-form-item label="卡号" prop="number">
          <el-input v-model="editForm.number" placeholder="请填写卡号" maxlength="20" show-word-limit />
        </el-form-item>
        <el-form-item label="开卡时间" prop="open_card_time">
          <el-date-picker v-model="editForm.open_card_time" :disabled="cardInfo.type === 1" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" placeholder="选择日期" />
          <br>
          <small v-if="cardInfo.type === 1" class="text-warning">当前会员卡为首次预约开卡，不能调整开卡时间</small>
        </el-form-item>
        <el-form-item label="有效期至" prop="valid_time">
          <el-date-picker v-model="editForm.valid_time" :disabled="cardInfo.status_id === 14" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" />
          <br>
          <small v-if="(cardInfo.type === 1 || cardInfo.type === 2) && cardInfo.status_id === 14" class="text-warning">
            当前会员卡为{{ cardInfo.type == 1 ? '首次预约开卡' : '特定时间开卡' }}，不能调整会员卡有效期
          </small>
        </el-form-item>
        <el-form-item v-if="cardInfo.card.type != 0" label="余额" prop="limit">
          <el-input v-model="editForm.limit" placeholder="请填写余额">
            <template slot="append">{{ cardInfo.card ? formatCardUnitType(cardInfo.card.type) : '天' }}</template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="可请假次数" prop="leave_number">
          <el-input v-model="editForm.leave_number" type="number" placeholder="请填写可请假次数" />
        </el-form-item>
        <el-form-item label="可请假天数" prop="leave_day">
          <el-input v-model="editForm.leave_day" type="number" placeholder="请填写可请假天数" />
        </el-form-item> -->
        <el-form-item label="推荐办卡人" prop="recommend">
          <el-input v-model="editForm.recommend" placeholder="请填写可推荐办卡人" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="editForm.remark" type="textarea" rows="5" placeholder="请填写备注" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">保存</el-button>
          <el-button @click="edit_cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 会员卡操作 -->
    <el-dialog v-if="operationShow" append-to-body :close-on-click-modal="false" :visible.sync="operationShow" title="操作" class="dialog_auto">
      <Operation v-model="operationShow" :card-data="cardInfo" @callback="operation_callback" />
    </el-dialog>
    <Dialog :id="membership_card_id" :venue="venueId" :dialog="dialogVisible" @Close="Close" />
    <!-- 预约详情 -->
    <bookInfo v-model="dialog" :logid.sync="logid" />
  </div>
</template>

<script>
import Operation from './components/Operation'
import { getUserMembershipCardDetails, delUserMembershipCard, editUserMembershipCard, getUserMembershipCardChangeLogs, getUserMembershipCardStatus } from '@/api/store/user.js'
import { get_course_subscribe } from '@/api/store/record.js'
import { getStoreUser } from '@/api/store/management.js'
import { deepClone } from '@/utils'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Dialog from './components/Dialog.vue'
import bookInfo from '@/views/store/worktable/components/bookInfo'

export default {
  components: { Pagination, Operation, Dialog, bookInfo },
  data() {
    return {
      course_subscribe_loading: false,
      chang_logs_loading: false,
      loading: false,
      cardInfo: {
        card: {}
      },
      is_edit: 0,
      statusData: {},
      card_status: 'success',
      activeName: 'first',
      chang_logs: {
        list: [],
        count: 0
      },
      editForm: {},
      store_list: {},
      rules: ['number', 'valid_time', 'open_card_time'],
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 状态:0=已预约,1=已签到,2=已取消
      course_status: ['已预约', '已签到', '已取消'],
      operationShow: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      dialogVisible: false,
      membership_card_id: 0,
      venueId: 0,
      statusList: {},
      dialog: {},
      logid: 0
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.getCardStatus()
    this.getInfo()
    this.getCardChangeLogs()
    getStoreUser()
      .then(res => {
        this.store_list = res.data.list
      })
      .catch(() => {})
  },
  methods: {
    get_course_subscribe_list() {
      this.course_subscribe_loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      data.query = JSON.stringify({ user_membership_card_id: this.$route.params.id })
      get_course_subscribe(data)
        .then(res => {
          this.tableData = res.data
          this.course_subscribe_loading = false
        })
        .catch(() => {})
    },
    getInfo() {
      this.loading = true
      getUserMembershipCardDetails(this.$route.params.id)
        .then(res => {
          if (res.data.length === 0) {
            this.$message.error('会员卡不存在')
            this.returnPage()
            return
          }
          this.editForm = deepClone(res.data)
          this.cardInfo = res.data
          this.loading = false
          this.venueId = res.data.venues.id
        })
        .catch(() => {})
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getCardChangeLogs()
    },
    // 状态码格式化
    formatStatus(s) {
      if (!this.statusData) {
        this.getCardStatus()
      }
      switch (s) {
        case 1:
        case 2:
          this.card_status = 'success'
          break
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          this.card_status = 'warning'
          break

        default:
          this.card_status = 'danger'
          break
      }
      return this.statusData[s] ? this.statusData[s] : '----'
    },
    getCardStatus() {
      getUserMembershipCardStatus({ limit: 999 })
        .then(res => {
          const new_arr = []
          for (var n = 0; n < res.data.list.length; n++) {
            new_arr[res.data.list[n]['id']] = res.data.list[n]['name']
          }
          this.statusList = res.data.list
          this.statusData = new_arr
        })
        .catch(() => {})
    },
    setQuery() {
      var querys = []
      if (this.listQueryParams.id) querys.push({ field: 'user_membership_card_status_id', key: this.listQueryParams.id })
      if (this.listQueryParams.query) querys.push({ field: 'remark', type: 'like', key: this.listQueryParams.query })
      querys.push({ field: 'user_membership_card_id', key: this.$route.params.id })
      return this.utils.getQueryParams(querys)
    },
    // 获取变更记录
    getCardChangeLogs() {
      this.chang_logs_loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()

      getUserMembershipCardChangeLogs(data)
        .then(res => {
          this.chang_logs = res.data
          console.log('🚀 ~ file: card_info.vue ~ line 365 ~ getUserMembershipCardChangeLogs ~ this.chang_logs', this.chang_logs)
        })
        .catch(() => {})
        .finally(() => {
          this.chang_logs_loading = false
          this.searchLoading = false
        })
    },
    handleClick() {
      console.log(this.activeName)
      if (this.activeName === 'first') this.getCardChangeLogs()
      else this.get_course_subscribe_list()
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = deepClone(this.editForm)
          data.open_card_time = this.$moment(data.open_card_time).unix()
          editUserMembershipCard(this.$route.params.id, data)
            .then(res => {
              this.$message.success(res.msg)
              this.getInfo()
              setTimeout(() => {
                this.is_edit = 0
                this.getCardChangeLogs()
                this.get_course_subscribe_list()
              }, 1000)
            })
            .catch(() => {})
        }
      })
    },
    edit_cancel() {
      this.is_edit = 0
    },
    formatCourseStatus(row, colum) {
      return this.course_status[row.status]
    },
    // 删除用户会员卡
    delUserCardId(id) {
      this.$confirm('删除会员卡操作不可恢复，如该卡当前已预约了课程则自动取消，确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delUserMembershipCard(id).then(res => {
            this.$message.success(res.msg)
            this.returnPage()
          })
        })
        .catch(() => {})
    },
    // 操作回调
    operation_callback(msg) {
      this.$message.success(msg)
      this.getInfo()
      this.operationShow = false
    },
    // 操作
    operation() {
      this.operationShow = true
    },
    handleToPage(membership_card_id) {
      this.dialogVisible = true
      this.membership_card_id = membership_card_id
    },
    Close() {
      this.dialogVisible = false
    },
    // 预约详情
    course_details(row) {
      this.logid = row.id
      const title = row.type === 0 ? '预约团课记录' : row.type === 1 ? '预约私教课记录' : row.type === 2 ? '预约精品课记录' : '预约私教课记录'
      this.dialog = { title: title, visible: true, type: this.type }
    }
  }
}
</script>

<style lang="scss" scoped>
.card_operation button {
  margin-left: 5px;
}
.userInfo {
  padding-top: 10px;
}
.cardLog {
  padding: 20px 0;
}
.el-avatar {
  background: none;
}
.color {
  color: rgb(24, 144, 255);
  /* padding-bottom: 3px;
  border-bottom: 1px solid rgb(24, 144, 255); */
}
</style>
