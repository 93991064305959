<template>
  <div>
    <el-dialog
      id="list"
      append-to-body
      class="dialog_auto"
      :close-on-click-modal="false"
      :visible.sync="trackVisible"
      title="回访列表"
      :before-close="cancelDialog"
    >
      <div class="app-container">
        <div class="filter-container">
          <el-button type="primary" icon="el-icon-plus" class="fr m-b-10" @click="dialogVisible()">添加</el-button>
        </div>
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="tableData.list"
          row-key="id"
        >
          <!-- <el-table-column prop="id" label="ID" /> -->
          <el-table-column label="回访时间">
            <template slot-scope="{ row }">{{ row.create_time }}</template>
          </el-table-column>
          <el-table-column label="回访内容">
            <template slot-scope="{ row }">{{ row.content }}</template>
          </el-table-column>
          <el-table-column label="跟进教练">
            <template slot-scope="{ row }">{{ row.store_user.real_name }}</template>
          </el-table-column>
          <el-table-column prop="track_type" label="跟进方式">
            <template slot-scope="{ row }">
              <span v-if="row.track_type == 0">无</span>
              <span v-else-if="row.track_type == 10">电话</span>
              <span v-else-if="row.track_type == 11">邮件</span>
              <span v-else-if="row.track_type == 12">短信</span>
              <span v-else-if="row.track_type == 13">见面</span>
              <span v-else-if="row.track_type == 14">微信</span>
            </template>
          </el-table-column>
          <el-table-column prop="track_act" label="跟进行为">
            <template slot-scope="{ row }">
              <span v-if="row.track_act == 0">无</span>
              <span v-else-if="row.track_act == 10">会员卡即将到期</span>
              <span v-else-if="row.track_act == 11">会员生日</span>
              <span v-else-if="row.track_act == 12">会员卡余额不足</span>
              <span v-else-if="row.track_act == 13">多天未上课</span>
              <span v-else-if="row.track_act == 14">入会纪念日</span>
              <span v-else-if="row.track_act == 15">即将开卡</span>
              <span v-else-if="row.track_act == 16">请假即将到期</span>
            </template>
          </el-table-column>
          <el-table-column prop="next_time" label="下次跟进时间">
            <template slot-scope="{ row }">
              <span v-if="row.next_time != 0">{{ row.next_time | formatDate('YYYY-MM-DD') }}</span>
              <span v-else>---</span>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="添加用户回访记录"
      class="dialog_w580"
    >
      <el-form ref="ruleForm" v-loading="dialog.loading" :model="form" :rules="rules" :inline="true" label-width="80px">
        <el-form-item label="用户" prop="user_id">
          <el-select v-model="form.user_id" placeholder="请选择" class="dialog_w100" disabled>
            <el-option v-for="item in userList" :key="item.id" :label="getUserNames(item)" :value="item.id" />
          </el-select>
        </el-form-item>
        <!-- 跟进人 -->
        <coach :id="form.store_user_id" :title-s="titleCoach" :type="coachType" @storeUserId="storeUserIds" />
        <!-- 跟进方式 -->
        <el-form-item label="跟进方式">
          <el-select v-model="form.track_type" clearable placeholder="请选择跟进方式">
            <el-option label="无" :value="0" />
            <el-option label="电话" :value="10" />
            <el-option label="邮件" :value="11" />
            <el-option label="短信" :value="12" />
            <el-option label="见面" :value="13" />
            <el-option label="微信" :value="14" />
          </el-select>
        </el-form-item>
        <!-- 跟进行为 -->
        <el-form-item label="跟进行为">
          <el-select v-model="form.track_act" :disabled="!!isShow" clearable placeholder="请选择跟进行为">
            <el-option label="无" :value="0" />
            <el-option label="会员卡即将到期" :value="10" />
            <el-option label="会员生日" :value="11" />
            <el-option label="会员卡余额不足" :value="12" />
            <el-option label="多天未上课" :value="13" />
            <el-option label="入会纪念日" :value="14" />
            <el-option label="即将开卡" :value="15" />
            <el-option label="请假即将到期" :value="16" />
          </el-select>
        </el-form-item>
        <!-- 下次回访时间 -->
        <el-form-item label="下次回访">
          <el-date-picker
            v-model="form.next_time"
            type="datetime"
            placeholder="请选择下次回访时间"
            value-format="yyyy-MM-dd HH:mm"
            clearable
          />
        </el-form-item>
        <el-form-item label="回访内容" prop="content">
          <el-input v-model="form.content" type="textarea" :rows="4" placeholder="请输入回访内容" class="dialog_w100" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { getVisitor } from '@/api/store/course.js'
import { getUserTrack, addUserTrack, getUser, getUserDetails } from '@/api/store/user.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
// 组件
import coach from '@/components/elSelect/coach.vue'
export default {
  name: 'VisitorTrack',
  components: { Pagination, coach },
  props: {
    trackVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    },
    isShow: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10
      },
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 弹窗
      dialog: {
        visible: false,
        loading: false
      },
      // 数据
      form: {
        user_id: this.id,
        content: '',
        track_type: 0,
        track_act: this.isShow ? this.isShow : 0,
        next_time: ''
      },
      userList: [],
      rules: ['content', 'user_id'],
      loading: false,
      userInfo: [],
      coachType: 3,
      titleCoach: '跟进教练'
    }
  },
  watch: {
    id(newVal, oldVal) {
      this.listQuery = { page: 1, limit: 10 }
      this.getList()
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  methods: {
    // 获取列表
    getList() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getUserTrack(this.id, data).then(res => {
        this.tableData = res.data
        this.loading = false
      }).catch(() => {})
    },
    getUserInfo() {
      getUserDetails(this.form.user_id).then(res => {
        this.userInfo = res.data
        if (this.userInfo.is_membership === 0) {
          this.getVUserList()
        } else {
          this.getUserList()
        }
      })
    },
    getUserList() {
      const data = {}
      data.query = JSON.stringify({ id: this.id })
      getUser(data).then(res => {
        this.userList = res.data.list
      }).catch(() => {}).finally(() => {
        this.dialog.loading = false
      })
    },
    getVUserList() {
      const data = {}
      data.query = JSON.stringify({ id: this.id })
      getVisitor(data).then(res => {
        this.userList = res.data.list
      }).catch(() => {}).finally(() => {
        this.dialog.loading = false
      })
    },
    dialogVisible() {
      this.form = {
        user_id: this.id,
        store_user_id: '',
        content: '',
        track_type: 0,
        track_act: this.isShow ? this.isShow : 0,
        next_time: ''
      }
      this.getUserInfo()
      this.dialog = { visible: true, loading: true }
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          addUserTrack(this.form).then(res => {
            this.$message.success('添加成功')
            this.getList()
            this.dialog.visible = false
            this.dialog.loading = false
          }).catch(() => {})
        }
      })
    },
    cancelDialog() {
      this.$emit('update:trackVisible', false)
      this.$emit('trackVisible')
      // this.tableData = {
      // 	list:[],
      // 	count: 0,
      // };
    },
    storeUserIds(val) {
      this.form.store_user_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
	#list{
		::v-deep .el-dialog__body{
			padding: 0;
		}
		.app-container{
			padding: 10px 20px;
		}
	}
</style>
