<template>
  <div v-loading="loading" class="app-container special-center">
    <el-page-header :content="userInfo.is_membership ? '会员详情' : '访客详情'" class="page-header" @back="returnPage()" />
    <el-divider />
    <!-- 用户信息 -->
    <div v-if="!is_edit" class="userInfo">
      <el-row :gutter="20" class="userHeader flex row-between">
        <el-col :span="6" :offset="0" class="flex">
          <el-avatar shape="square" :size="80" :src="userInfo.avatar">
            <img :src="errorMemberSrc()">
          </el-avatar>
          <!-- <span class="userName p-l-15">{{ getUserNames(userInfo) }}</span> -->
        </el-col>
        <el-col :span="18" :offset="0" class="text-right user_operation">
          <el-button v-show="btn_access('s_user_vipcard_add')" type="success" @click="toPage(userInfo.id)">
            发卡
          </el-button>
          <el-popconfirm title="删除后将无法恢复，确定吗？" @confirm="delUserId(userInfo.id)">
            <el-button v-show="btn_access('s_user_delete')" slot="reference" type="danger">删除</el-button>
          </el-popconfirm>

          <el-button v-show="btn_access('s_user_edit')" type="primary" @click="is_edit = 1">编辑</el-button>

          <el-button v-if="utils.getVenues().id === -1 && userInfo.is_membership !== 0" type="warning" @click="openTransfer(userInfo.id)">
            转移
          </el-button>
          <!-- <el-button type="info" @click="returnPage()">返回</el-button> -->
        </el-col>
      </el-row>

      <!-- 基本信息 -->
      <h5>基本信息</h5>
      <el-descriptions class="margin-top" :column="2" border :label-style="{ width: '100px' }">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user" />
            会员名
          </template>
          {{ getUserNames(userInfo) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user" />
            会员ID
            <span class="cursor">
              <el-tooltip class="item" effect="dark" content="该虚拟用户ID可作为用户唯一辨识，如约课端登录用户ID与该ID不一致，则说明他们不是同一账号" placement="top">
                <i class="el-icon-warning" />
              </el-tooltip>
            </span>
          </template>
          {{ userInfo.uuid ? userInfo.uuid : '---' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-custom" />
            性别
          </template>
          {{ formatTableSex(userInfo) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-star-on" />
            会员类型
          </template>
          <!-- 是否为会员 0 否 1 是 -->
          <el-tag v-if="userInfo.is_membership" type="success">会员</el-tag>
          <el-tag v-else>访客</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone" />
            手机号
          </template>
          <el-tag v-if="userInfo.phone">{{ userInfo.area_code == '+86' ? '' : userInfo.area_code + ' - ' }}{{ userInfo.phone }}</el-tag>
          <span v-else>--</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-potato-strips" />
            生日
          </template>
          {{ userInfo.birthday }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-postcard" />
            证件类型
          </template>
          <!-- 证件类型 0、身份证 1、护照 2、港澳通行证 -->
          <span v-if="userInfo.certificate_type == 0">身份证</span>
          <span v-if="userInfo.certificate_type == 1">护照</span>
          <span v-if="userInfo.certificate_type == 2">港澳通行证</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-news" />
            积分
          </template>
          {{ userInfo.integral }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-postcard" />
            证件号
          </template>
          {{ userInfo.certificate_number ? userInfo.certificate_number : '--' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-service" />
            会籍顾问
          </template>
          {{ userInfo.store_user ? userInfo.store_user.real_name : '--' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-service" />
            渠道来源
          </template>
          {{ userInfo.store_user ? source_list_arr[userInfo.source_id] : '--' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building" />
            地区
          </template>
          {{ userInfo.area ? userInfo.area : '--' }}
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label">
            <i class="el-icon-office-building" />
            职位
          </template>
          {{ userInfo.profession ? userInfo.profession : '无' }}
        </el-descriptions-item>
        <el-descriptions-item :span="2">
          <template slot="label">
            <i class="el-icon-office-building" />
            详细地址
          </template>
          {{ userInfo.address ? userInfo.address : '无' }}
        </el-descriptions-item>
        <el-descriptions-item v-if="userInfo.is_membership">
          <template slot="label">
            <i class="el-icon-time" />
            加入时间
          </template>
          {{ userInfo.create_time ? userInfo.create_time : '无' }}
        </el-descriptions-item>
        <el-descriptions-item v-else>
          <template slot="label">
            <i class="el-icon-time" />
            来访时间
          </template>
          {{ userInfo.visitor_time ? userInfo.visitor_time : '无' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-edit" />
            备注
          </template>
          {{ userInfo.remark }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-money" />
            总消费金额
          </template>
          {{ userInfo.user_balance ? `${userInfo.user_balance.total_pay}元` : '---' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-postcard" />
            总耗卡
          </template>
          {{ userInfo.user_balance ? `${userInfo.user_balance.total_consume}元` : '---' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-money" />
            剩余资产
          </template>
          {{ userInfo.user_balance ? `${userInfo.user_balance.total_balance}元` : '---' }}
        </el-descriptions-item>
        <template slot="extra" />
      </el-descriptions>
      <!-- 其他信息导航 -->
      <el-tabs v-model="activeName" class="m-t-20">
        <el-tab-pane label="会员卡信息" name="userCard">
          <div v-if="userCardOptions.count > 0" v-loading="tableLoading" class="card_list">
            <div v-for="item in userCardOptions.list" :key="item.id" class="flex card_item col-top">
              <div class="card_img" :style="{ color: item.card.type == 0 ? '#82848a' : '#e6ebf5' }">
                <div
                  class="c_bg"
                  :style="{
                    background: 'url(' + card_bg(item.card.type) + ') center center / 100% 100% no-repeat',
                    color: item.card.type == 0 ? '#82848a' : '#e6ebf5'
                  }"
                >
                  <div class="c_type">
                    {{ formatCardType(item.card.type) }}
                  </div>
                  <div class="c_status">
                    <div v-if="item.card.venues_id === -1" class="onsale-section">
                      <span class="onsale">多馆卡</span>
                    </div>
                  </div>
                  <div class="c_vip">
                    VIP
                  </div>
                </div>
              </div>
              <div class="card_info">
                <div class="c_name">
                  {{ item.card.name }}
                </div>
                <div class="c_info text-info">
                  <table border="0" cellpadding="0" cellspacing="0">
                    <tr>
                      <td>卡号：{{ item.number }}</td>
                      <td>
                        卡状态：
                        <span :style="{ color: formatStatus(item.status_id, 1) }">
                          {{ formatStatus(item.status_id) }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>售价：{{ item.card.price }} 元</td>
                      <td v-if="item.status_id !== 14">
                        卡余额：
                        <span v-if="item.card.type === 0">
                          <span v-if="getExcessTime(item.valid_time) > 0" class="text-success">
                            {{ getExcessTime(item.valid_time) }}
                            <span v-if="item.card">{{ formatCardUnitType(item.card.type) }}</span>
                          </span>
                          <span v-else class="text-danger">
                            {{ getExcessTime(item.valid_time) }}
                            <span v-if="item.card">{{ formatCardUnitType(item.card.type) }}</span>
                          </span>
                        </span>
                        <span v-else>
                          {{ item.limit }}
                          <span v-if="item.card">{{ formatCardUnitType(item.card.type) }}</span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>开卡类型：{{ formatCardOpenType(item.type) }}</td>
                      <td v-if="item.status_id !== 14">
                        有效期：
                        <span>{{ item.open_card_time | formatDate('YYYY-MM-DD') }}至{{ item.valid_time }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="card_operate text-info flex">
                <div class="m-r-10 cursor" @click="card_info(item.id)">
                  <i class="el-icon-view" />
                  查看详情
                </div>
                <div class="cursor" @click="card_logs(item.id)">
                  <i class="el-icon-tickets" />
                  变更记录
                </div>
              </div>
            </div>
          </div>
          <div v-else class="venuesList_empty text-center p-10">
            <img :src="empty_default_img" alt="">
            <div class="text-info">暂无会员卡信息</div>
          </div>
          <pagination v-show="userCardOptions.count > 0" :total="userCardOptions.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getUserCard" />

          <div class="card_list" style="display:none;">
            <div v-if="userCardOptions.count > 0" class="course_list flex flex-wrap col-center">
              <div v-for="item in userCardOptions.list" :key="item.id" class="card_item m-b-10 p-r-10">
                <div
                  class="UserCardItem"
                  :style="{
                    background: 'url(' + card_bg(item.card.type) + ') center center / 100% no-repeat',
                    color: item.card.type == 0 ? '#82848a' : '#e6ebf5'
                  }"
                >
                  <div class="cardNumber">
                    <b>卡号</b>
                    <span>{{ numberFormat(item.number) }}</span>
                  </div>
                  <div class="cardBody">
                    <span>售价 ￥{{ item.card.price }}</span>
                    <span>剩余 {{ item.limit }}</span>
                  </div>
                  <div class="cardFooter">
                    <el-button round class="card-btn" :style="{ color: item.card.type == 0 ? '#82848a' : '#e6ebf5' }" @click="card_info(item.id)">
                      查看详情
                    </el-button>
                    <el-button round class="card-btn" :style="{ color: item.card.type == 0 ? '#82848a' : '#e6ebf5' }" @click="card_logs(item.id)">
                      变更记录
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="userCardEmpty">
              <div class="text-center m-b-20">
                <img :src="empty_default_img" alt="">
                <br>
                <small class="text-info">暂无会员卡</small>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="上课记录" name="user_classRecords">
          <classRecords :user-id="parseInt($route.params.id)" />
        </el-tab-pane>
        <el-tab-pane label="订单" name="user_orderForm">
          <orderForm :user-id="parseInt($route.params.id)" />
        </el-tab-pane>
        <el-tab-pane v-if="btn_access('s_user_integral')" label="会员积分" name="user_integral">
          <UserIntegral :user-id="parseInt($route.params.id)" @upInfo="getInfo" />
        </el-tab-pane>
        <el-tab-pane v-if="btn_access('s_user_track_list')" label="回访记录" name="visit_record">
          <VisitorTrackList :id="userInfo.id" />
        </el-tab-pane>
        <el-tab-pane v-if="btn_access('s_user_side')" label="体测数据" name="user_fianco">
          <UserFianco :user-id="parseInt($route.params.id)" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 用户编辑 -->
    <div v-if="is_edit" class="userEdit">
      <el-form ref="ruleForm" label-position="right" label-width="80px" :model="editForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="头像" prop="avatar">
              <div class="avatar-block avatar-box" @click="imagecropperShow = true">
                <el-avatar shape="square" :size="100" :src="editForm.avatar" :title="editForm.avatar ? '点击修改' : '点击上传'">
                  <img :src="errorImageSrc">
                </el-avatar>
                <div class="avatar-hover">{{ editForm.avatar ? '更换头像' : '上传头像' }}</div>
              </div>
              <image-cropper
                v-show="imagecropperShow"
                :key="imagecropperKey"
                field="file"
                :width="300"
                :height="300"
                :url="storeUpload"
                lang-type="zh"
                @close="imagecropperClose"
                @crop-upload-success="cropSuccess"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="姓名" prop="real_name">
              <el-input v-model="editForm.real_name" />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="昵称" prop="nickname">
              <el-input v-model="editForm.nickname" />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="editForm.sex">
                <el-radio v-for="(item, index) in sexList" :key="index" :label="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="editForm.phone">
                <div slot="prepend">
                  <Code :code="areaCodeData" @area_code="area_code" />
                </div>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="生日" prop="birthday">
              <el-date-picker v-model="editForm.birthday" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 308px;" />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="积分" prop="integral">
              <el-input v-model="editForm.integral" placeholder="请填写积分" type="number" />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <!-- 会籍顾问 -->
            <el-form-item label="会籍顾问" prop="store_user_id">
              <Select :type="4" :width="308" :exist-name="detailsName" @changeId="handleCoachID" />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="渠道来源" prop="source_id">
              <el-select v-model="editForm.source_id" placeholder="请选择" style="width: 308px;">
                <el-option v-for="item in source_list" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="职业" prop="profession">
              <el-input v-model="editForm.profession" placeholder="请填写职业" />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="证件类型" prop="certificate_type">
              <el-select v-model="editForm.certificate_type" placeholder="请选择" style="width: 308px;">
                <el-option v-for="item in certificate_card_type" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="证件号码" prop="certificate_number">
              <el-input v-model="editForm.certificate_number" placeholder="请填写证件号码" />
            </el-form-item>
          </el-col>

          <el-col :span="12" :offset="0">
            <el-form-item label="所在地区" prop="area">
              <el-input v-model="editForm.area" placeholder="请选择会员所在地区" />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="详细地址" prop="address">
              <el-input v-model="editForm.address" placeholder="请填写会员详细地址" />
            </el-form-item>
          </el-col>
          <el-col v-if="editForm.is_membership === 0" :span="12" :offset="0">
            <el-form-item label="来访时间" prop="visitor_time">
              <el-date-picker v-model="editForm.visitor_time" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" placeholder="用户来访时间" />
            </el-form-item>
          </el-col>
          <el-col :span="24" :offset="0">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="editForm.remark" type="textarea" placeholder="请填写会员备注信息" rows="3" />
            </el-form-item>
          </el-col>
          <el-col :span="24" :offset="0">
            <el-form-item>
              <el-button type="primary" @click="submit">保存</el-button>
              <el-button @click="edit_cancel">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 变更记录弹窗 -->
    <UserCardLogs :id="dialog.card_id" :log-visible.sync="dialog.logVisible" />

    <!-- 用户会员卡转移 -->
    <el-dialog append-to-body :close-on-click-modal="false" :visible.sync="transfer.visible" title="用户会员卡转移" class="dialog_w580">
      <div class="tip">
        此操作将转移用户所有会员卡及相关数据到指定场馆
      </div>

      <el-select v-model="transfer.venues_id" placeholder="请选择需要转移的场馆" style="width:100%">
        <el-option v-for="item in venues_list" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
      <div slot="footer">
        <el-button type="primary" @click="setTransfer">
          提交
        </el-button>
        <el-button type="text" @click="transfer.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserDetails, getUserMembershipCard, editUser, delUser, transferUserMembershipCard, getUserMembershipCardStatus } from '@/api/store/user.js'
import { editVisitor, delVisitor } from '@/api/store/course.js'
import ImageCropper from '@/components/ImageCropper' // 头像上传
import UserCardLogs from './user_card_logs.vue' // 用户会员卡日志
import { deepClone } from '@/utils'
// import VisitorTrack from './tracking' // 用户回访
import VisitorTrackList from './tracking/list' // 用户回访列表
import UserFianco from './fianco' // 用户体测
import UserIntegral from './integral' // 用户体测
import orderForm from './order' // 用户订单
import { getFieldList } from '@/api/store/config.js'
import { getEmployeeDetails, getVenues } from '@/api/store/venues.js'
// 组件
import Code from '@/components/code/index.vue'
import Select from '@/components/elSelect/Select'
import classRecords from './classRecords'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  components: {
    UserCardLogs,
    ImageCropper,
    UserFianco,
    VisitorTrackList,
    UserIntegral,
    orderForm,
    Select,
    Code,
    classRecords,
    Pagination
  },
  data() {
    return {
      loading: true,
      rules: ['real_name', 'phone', 'sex'],
      userInfo: {},
      userCardOptions: {
        count: 0,
        list: []
      },
      dialog: { logVisible: false, card_id: null, trackVisible: false },
      is_edit: false,
      editForm: {
        sex: 0,
        avatar: ''
      },
      tableLoading: false,
      userCardEmpty: false,
      imagecropperShow: false,
      imagecropperKey: 0,
      certificate_card_type: [
        {
          value: 0,
          label: '身份证'
        },
        {
          value: 1,
          label: '护照'
        },
        {
          value: 2,
          label: '港澳通行证'
        }
      ],
      source_list_arr: ['其他门店转入', '微信朋友圈', '老会员转入'],
      source_list: [
        {
          value: 0,
          label: '其他门店转入'
        },
        {
          value: 1,
          label: '微信朋友圈'
        },
        {
          value: 2,
          label: '老会员转入'
        }
      ],
      store_user_name: '',
      activeName: 'userCard',
      config: {
        card_time_bg: '',
        card_num_bg: '',
        card_stored_bg: ''
      },
      venues_list: [],
      transfer: {
        user_id: '',
        visible: false,
        venues_id: ''
      },
      card_status: 'success',
      statusData: {},
      areaCodeData: '+86',
      detailsName: ''
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
    if (this.utils.empty(this.config.card_time_bg)) {
      getFieldList(13)
        .then(res => {
          const list = res.data.list
          const lists = []
          list.forEach((item, index) => {
            lists.push(item.store_config ? item.store_config : item)
          })
          lists.forEach((item, index) => {
            this.config[item.field] = item.value ? item.value : item.default
          })
        })
        .catch(() => {})
    }
  },
  mounted() {
    this.getInfo()
    this.getUserCard()
    this.getCardStatus()
  },
  methods: {
    getInfo() {
      getUserDetails(this.$route.params.id)
        .then(async res => {
          res.data.area_code = '+' + res.data.area_code
          this.editForm = deepClone(res.data)
          console.log('🚀 ~ file: user_info.vue:541 ~ getUserDetails ~ this.editForm:', this.editForm)
          const details = await getEmployeeDetails(this.editForm.store_user_id)
          this.detailsName = details.data.real_name
          this.areaCodeData = this.editForm.area_code
          this.userInfo = res.data
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    // 删除会员
    delUserId(id) {
      if (this.userInfo.is_membership) {
        delUser(id).then(res => {
          this.$message.success(res.msg)
          this.returnPage()
        })
      } else {
        delVisitor(id).then(res => {
          this.$message.success(res.msg)
          this.returnPage()
        })
      }
    },
    getUserCard() {
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = JSON.stringify({ user_id: this.$route.params.id })

      getUserMembershipCard(data)
        .then(res => {
          // 数据嵌入
          this.userCardOptions = res.data
          this.userCardEmpty = !(this.userCardOptions.count.length > 0)
        })
        .catch(() => {})
        .finally(() => {
          this.tableLoading = false
        })
    },
    numberFormat(v) {
      var v1 = v.toString()
      return v1.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')
    },
    card_info(id) {
      console.log('🚀 ~ file: index.vue ~ line 188 ~ card_info ~ id', id)
      this.$router.push({ name: 's_user_vipcard_info', params: { id: id }})
    },
    card_logs(id) {
      this.dialog.card_id = id
      this.dialog.logVisible = true
    },
    // 头像上传成功
    cropSuccess(resData) {
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      this.editForm.avatar = resData.path
    },
    // 头像上传弹出层关闭
    imagecropperClose() {
      this.imagecropperShow = false
    },
    // 会员信息编辑保存
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.editForm.area_code = this.editForm.area_code === '+86' ? '86' : this.editForm.area_code.replace(/[^0-9]/gi, '')
          const data = this.editForm
          delete data['appid']
          if (data.is_membership) {
            editUser(this.$route.params.id, data)
              .then(res => {
                this.$message.success(res.msg)
                this.getInfo()
                this.is_edit = 0
              })
              .catch(() => {})
          } else {
            editVisitor(this.$route.params.id, data)
              .then(res => {
                this.$message.success(res.msg)
                this.getInfo()
                this.is_edit = 0
              })
              .catch(() => {})
          }
        }
      })
    },
    edit_cancel() {
      this.is_edit = 0
    },
    toPage(user_id) {
      this.$router.push({ name: 's_user_vipcard_add', params: { user_id: user_id, card_id: 0 }})
    },
    toVisitorTrackPage() {
      this.dialog.trackVisible = true
    },
    card_bg(n) {
      switch (n) {
        case 0:
          return this.config.card_time_bg
        case 1:
          return this.config.card_num_bg
        case 2:
          return this.config.card_stored_bg
        default:
          break
      }
    },
    getVenuesList(query) {
      const data = query ? this.utils.getQueryParam({ name: '%' + query + '%' }) : {}
      data.limit = 999
      getVenues(data)
        .then(res => {
          this.venues_list = res.data.list
        })
        .catch(() => {})
    },
    openTransfer(id) {
      if (this.utils.empty(this.venues_list)) {
        this.getVenuesList()
      }
      this.transfer.user_id = id
      this.transfer.visible = true
    },
    // 用户转移
    setTransfer() {
      if (!this.transfer.venues_id) {
        this.$message.error('请选择需要转移的场馆')
        return false
      }
      this.$confirm('此操作将转移用户所有会员卡及相关数据到指定场馆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        transferUserMembershipCard(this.transfer.user_id, this.transfer.venues_id).then(res => {
          this.getInfo()
          this.$message.success(res.msg)
          this.transfer.visible = false
        })
      })
    },
    // 状态码格式化
    formatStatus(s, color) {
      if (!this.statusData) {
        this.getCardStatus()
      }
      let c = '#67C23A'
      switch (s) {
        case 1:
        case 2:
          c = '#67C23A'
          break
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          c = '#E6A23C'
          break

        default:
          c = '#F56C6C'
          break
      }
      if (color) return c
      return this.statusData[s] ? this.statusData[s] : '----'
    },
    getCardStatus() {
      getUserMembershipCardStatus({ limit: 999 })
        .then(res => {
          const new_arr = []
          for (var n = 0; n < res.data.list.length; n++) {
            new_arr[res.data.list[n]['id']] = res.data.list[n]['name']
          }
          this.statusData = new_arr
        })
        .catch(() => {})
    },
    handleCoachID(val) {
      this.editForm.store_user_id = val
    },
    area_code(val) {
      this.editForm.area_code = val
    }
  }
}
</script>

<style lang="scss" scoped>
.user_operation button {
  margin-left: 5px;
}

.UserCardItem {
  &:hover {
    box-shadow: 0 2px 3px 0 #c0c4cc;
  }
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%);
  // background: linear-gradient(to bottom right,rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), center center / 100% no-repeat;
  transition: 0.3s;
  width: 255px;
  height: 130px;
  color: rgb(130, 132, 138);
  border-radius: 4px;
  text-align: center;
  .cardNumber {
    b {
      letter-spacing: 1em;
    }
    span {
      font-size: 15px;
    }
    padding: 18px 0 15px 0;
    font-size: 16px;
  }
  .cardBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    margin-bottom: 5px;
  }
  .cardFooter {
    display: flex;
    .card-btn {
      background: none;
      // color: rgb(130, 132, 138);
    }
    border-top: 1px solid #c0c4cc6e;
    padding: 10px 0;
    margin: 0 10px;
    justify-content: space-around;
  }
}
.el-avatar {
  background: none;
}
.avatar-block {
  display: unset;
}

.card_list {
  .card_item {
    border: 1px solid #eeeeee;
    padding: 10px;
    margin: 10px 5px;
    position: relative;
    .card_img {
      border-radius: 10px;
      overflow: hidden;
      padding: 4px;
      margin-right: 14px;
      .c_bg {
        width: 180px;
        height: 100px;
        position: relative;
        .c_type {
          letter-spacing: 2px;
          font-style: italic;
          position: absolute;
          top: 10px;
          left: 15px;
          font-size: 16px;
          font-weight: 600;
        }
        .c_status {
          .onsale-section {
            position: absolute;
            right: -37px;
            top: 6px;
          }
          .onsale {
            position: relative;
            display: inline-block;
            color: #fff;
            background-color: #f29100;
            width: 110px;
            height: 23px;
            font-size: 12px;
            line-height: 23px;
            text-align: center;
            transform: rotate(45deg) scale(0.75);
          }
          .onsale:before,
          .onsale:after {
            content: '';
            position: absolute;
            display: block;
            border-style: solid;
            border-width: 5px;
            border-color: #804f7c transparent transparent transparent;
          }
          .onsale:before {
            left: 0;
            bottom: -10px;
          }
          .onsale:after {
            right: 0em;
            bottom: -10px;
          }
        }
        .c_vip {
          letter-spacing: 1px;
          position: absolute;
          // top: 10px;
          // left:10px;
          right: 10px;
          bottom: 10px;
          font-size: 16px;
        }
      }
    }
    .card_info {
      .c_name {
        color: #666666;
        font-weight: 600;
        font-size: 16px;
      }
      .c_info {
        margin: 12px 0 0 0;
        td {
          height: 22px;
          width: 200px;
        }
      }
    }
    .card_operate {
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }
}
.venuesList_empty {
  min-height: 400px;
  img {
    -webkit-user-drag: none;
  }
}

::v-deep .el-tabs__header {
  padding: 0px !important;
}
</style>
