<template>
  <div class="app-container special-center">
    <el-steps :space="200" align-center :active="active" finish-status="success">
      <el-step title="登录" />
      <el-step title="导入" />
      <el-step title="完成" />
    </el-steps>
    <el-divider />
    <div v-if="active === 0" class="p-t-60 center">
      <div class="login-box col-top">
        <div class="box-right">
          <div class="text-center">
            <div class="font-40">账号登录</div>
          </div>
          <div class=" m-t-30">
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="loginRules"
              class="login-form"
              autocomplete="on"
              size="medium"
              label-position="left"
              @keyup.enter="handleSubmit('loginForm')"
            >
              <el-form-item prop="username">
                <i class="icon-required" />
                <el-input
                  ref="username"
                  v-model="loginForm.username"
                  placeholder="手机号"
                  name="username"
                  type="text"
                  tabindex="1"
                  autocomplete="on"
                  class="input-item"
                />
              </el-form-item>
              <el-form-item prop="password">
                <i class="icon-required" />
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model="loginForm.password"
                  :type="passwordType"
                  :placeholder="$t('login.password')"
                  name="password"
                  tabindex="2"
                  autocomplete="on"
                  class="input-item"
                  @keyup.native="checkCapslock"
                  @blur="capsTooltip = false"
                  @keyup.enter.native="handleLogin('loginForm')"
                />
                <span class="show-pwd" @click="showPwd">
                  <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
                </span>
              </el-form-item>
            </el-form>
            <div class="input-item">
              <el-button
                :loading="loading"
                type="primary"
                size="default"
                class="submit"
                @click.native.prevent="handleLogin('loginForm')"
              >
                {{ $t('login.logIn') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="active === 1" class="searchBox p-b-10">
      <!-- Info 自定义检索方法，不与通用检索同步 -->
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-width="100px"
        label-position="top"
        label-suffix="："
        :inline="true"
      >
        <el-form-item label="导入品牌" prop="app_id">
          <!-- <el-select v-model="form.app_id" placeholder="placeholder="请选择"" filterable clearable>
            <el-option label="瑜小九末次测试" :value="1590" />
          </el-select> -->
          <el-select
            v-model="form.app_id"
            filterable
            remote
            reserve-keyword
            placeholder="请选择导入的品牌，可输入搜索"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="appSelct"
          >
            <el-option v-for="item in options" :key="item.id" :label="('['+item.id+']' + item.name)" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="输入场馆" prop="venues_id">
          <el-select v-model="form.venues_id" placeholder="请选择" filterable clearable>
            <el-option v-for="(item, index) in appVenuesList" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="数据类型" prop="type">
          <el-select
            v-model="form.type"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请选择导入类目"
          >
            <el-option label="场馆" value="venues" disabled />
            <el-option label="员工" value="employee" />
            <el-option label="教室" value="classroom" />
            <el-option label="课程" value="course" />
            <el-option label="会员卡" value="membershipCard" />
            <el-option label="支持卡绑定[耗时较久]" value="membershipCardSupportCourse" />
          </el-select>
        </el-form-item>
        <el-form-item label="导入品牌（三方）" prop="brand_id">
          <el-select v-model="form.brand_id" placeholder="请选择" filterable clearable @change="brandChange">
            <el-option v-for="(item, index) in brandList" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item v-if="brandVenuesList" label="导入场馆（三方）" prop="brand_id">
          <el-select v-model="form.venue_id" placeholder="请选择" filterable clearable>
            <el-option v-for="(item, index) in brandVenuesList[form.brand_id]" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form>

      <div class="text-center">
        <el-button type="info" class="m-10" @click="active = 0">
          上一步
        </el-button>
        <el-popconfirm title="确定导入吗？" @confirm="submitForm('ruleForm')">
          <el-button slot="reference" :loading="searchLoading" type="success" class="m-10">
            导 入
          </el-button>
        </el-popconfirm>
      </div>
    </div>
    <div v-if="active === 3">
      <el-result icon="success" title="成功提示" sub-title="请根据提示进行操作">
        <template slot="extra">
          <el-button type="primary" size="medium" @click="active = 1">继续导入</el-button>
          <el-button type="danger" size="medium" @click="active = 0">关闭</el-button>
        </template>
      </el-result>
    </div>

    <div class="tip">
      <div class="font-26 m-y-10">警告：</div>
      <li>导入时请谨慎选择和操作，防止意外操作导致的数据紊乱</li>
      <li>输入场馆和导入场馆是一对一关系，如需导入多个场馆，请重复操作导入教室和课程</li>
      <li>如需导入支持卡绑定请优先导入课程及会员卡类目，否则将无法导入</li>
      <li>支持卡绑定关系导入随着卡和课程的数量递增而时间递增（建议不导入或最后导入）</li>
      <li>请勿重复导入类目，除了会员卡意外的其他类目都会形成数据堆叠</li>
      <li>如遇到导入失败请及时截图发送给开发人员</li>
    </div>
  </div>
</template>

<script>
// API
import { postLogin, postSubmit } from '@/api/admin/automated'
import { appVenuesList } from '@/api/admin/merchants'
import { getList } from '@/api/admin/merchants.js'

export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号!'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码!'))
      } else {
        callback()
      }
    }
    return {
      active: 0,
      loading: false,
      searchLoading: false,
      passwordType: 'password',
      token: '',
      options: [],
      form: {
        app_id: '',
        brand_id: '',
        venues_id: '',
        venue_id: 1,
        access_token: '',
        type: []
      },
      rules: {
        app_id: [{ required: true, message: '请选择导入品牌', trigger: 'blur' }],
        venues_id: [{ required: true, message: '请选择输入场馆', trigger: 'blur' }],
        brand_id: [{ required: true, message: '请选择导入场馆(三方)', trigger: 'blur' }],
        type: [{ required: true, message: '请选择导入类目', trigger: 'blur' }]
      },
      brandList: [],
      brandVenuesList: [],
      appVenuesList: [],
      loginForm: {
        username: '',
        password: '',
        area_code: '+86'
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: 'blur',
            validator: validateUsername
          }
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword
          }
        ]
      }
    }
  },
  watch: {},
  created() {
    this.getAppList()
  },
  mounted() {},
  methods: {
    // 登录
    handleLogin(loginForm) {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const data = {
            phone: this.loginForm.username,
            pwd: this.loginForm.password
          }
          this.loading = true
          postLogin(data)
            .then(res => {
              this.form.access_token = res.data.access_token
              this.brandList = res.data.venues_list
              this.brandVenuesList = []
              if (!this.utils.empty(res.data.venues_list)) {
                res.data.venues_list.forEach((item, index) => {
                  this.brandVenuesList[item.id] = item.venues
                })
                this.form.brand_id = res.data.venues_list[0].id
                this.brandChange(this.form.brand_id)
              }

              this.loading = false
              this.$message({
                message: '登录成功！',
                type: 'success'
              })
              this.$refs[loginForm].resetFields()
              this.getAppList()
              this.active = 1
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取app品牌列表
    getAppList() {
      getList().then(res => {
        this.options = res.data.list
      })
    },
    // 选择app品牌触发事件
    appSelct(id) {
      this.form.venues_id = ''
      // 通过品牌id获取场馆列表
      appVenuesList(id).then(res => {
        this.appVenuesList = res.data
      })
    },
    brandChange(id) {
      this.form.venue_id = this.brandVenuesList[id][0].id
    },
    // 导入
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.searchLoading = true
          const data = { ...this.form }
          console.log(data)
          postSubmit(data)
            .then(() => {
              this.$message({
                message: '导入成功！',
                type: 'success'
              })
              this.searchLoading = false
              // this.$refs[formName].resetFields()
              this.active = 3
            })
            .finally(() => {
              this.searchLoading = false
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    // 查看密码
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 远程搜索
    remoteMethod(query) {
      console.log(query)
      const data = {}
      const querys = []
      if (query) {
        querys.push({
          field: 'name',
          type: 'like',
          key: query
        })
      }
      data.query = this.utils.getQueryParams(querys)

      getList(data).then(res => {
        this.options = res.data.list
      })
    },
    handleChe(val) {
      console.log(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-steps {
  justify-content: center;
}
.login-box {
  z-index: 1;
  -webkit-box-shadow: 0px 2px 14px 0px rgb(0 0 0 / 6%);
  box-shadow: 0px 2px 14px 0px rgb(0 0 0 / 6%);
  -webkit-animation: scalein 0.3s;
  animation: scalein 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  border-radius: 18px;
  overflow: hidden;
  height: 540px;
  .box-left {
    width: 480px;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      border: 0;
      display: block;
    }
  }
  .box-right {
    background: #ffffff;
    width: 420px;
    padding: 40px;
    height: 100%;
    .login-logo {
      img {
        width: 100%;
        padding: 20px 0px;
      }
    }
    .input-item {
      margin: 10px 0;
      input {
        width: 100%;
        height: 42px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        border: 1px solid #dae0e6;
        border-radius: 4px;
        padding: 0 20px;
        line-height: 42px;
        outline: none;
      }
    }
    .submit {
      &:hover {
        background: linear-gradient(11deg, #01cbca, #1892aa);
      }
      display: block;
      height: 42px;
      // background: #ff6a00;
      background: linear-gradient(145deg, #01cbca, #1892aa);
      border-radius: 4px;
      margin: 0 auto;
      text-align: center;
      // line-height: 42px;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
      width: 100%;
      border: unset;
    }
    .show-pwd {
      position: absolute;
      right: 10px;
      top: 12px;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
