<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="请假时间" prop="ask_time">
        <el-date-picker
          v-model="form.ask_time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          @change="ask_time_change"
        />
        <small class="p-l-5">共计<span class="text-primary">{{ diff_day }}</span>天</small>
      </el-form-item>
      <el-form-item label="手续费" prop="checked">
        <el-checkbox v-model="form.checked">收取请假费用</el-checkbox>
      </el-form-item>
      <el-form-item v-if="form.checked" label="请假手续费" prop="poundage">
        <el-input v-model="form.poundage" placeholder="请输入费用" type="number" />
      </el-form-item>
      <el-form-item v-if="form.checked" label="收费方式" prop="payment_method_id">
        <el-select v-model="form.payment_method_id" placeholder="收费方式" class="dialog_w100">
          <el-option
            v-for="(item,index) in payment_method"
            :key="index"
            :label="item"
            :value="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请填写操作备注"
          rows="5"
          maxlength="300"
          show-word-limit
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button @click="$emit('back', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addUserMembershipCardLeave } from '@/api/store/user.js'
export default {
  name: 'AskOff',
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      diff_day: 0,
      form: {
        diff_day: 0,
        ask_time: '',
        checked: false,
        poundage: '',
        payment_method_id: 0
      },
      rules: {
        ask_time: { required: true, message: '请选择请假时间', trigger: ['blur', 'change'] },
        poundage: { validator: (rule, value, callback) => {
          if (this.form.checked && (this.form.poundage === '' || this.form.poundage < 0)) {
            callback(new Error('请填写正数'))
          }
          callback()
        }, trigger: ['blur', 'change']
        }
      }
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const s = this.$moment(this.form.ask_time[0]).format('yyyy-MM-DD')// 开始时间
          const e = this.$moment(this.form.ask_time[1]).format('yyyy-MM-DD')// 结束时间

          const data = {
            start_time: s,
            end_time: e,
            remark: this.form.remark,
            payment_method_id: this.form.payment_method_id,
            poundage: this.form.poundage
          }
          if (!this.form.checked) {
            data.payment_method_id = 0
            data.poundage = ''
          }
          addUserMembershipCardLeave(this.cardData.id, data).then(res => {
            this.$emit('callback', res.msg)
          }).catch(() => {})
        }
      })
    },
    // 计算请假天数
    ask_time_change(res) {
      this.form.diff_day = this.time_reckon(res)
    },
    // 时间计算（+1天）
    time_reckon(res) {
      if (!res) {
        this.diff_day = 0
        return false
      }
      const s = this.$moment(res[0]).format('yyyy-MM-DD')// 开始时间
      const e = this.$moment(res[1]).format('yyyy-MM-DD')// 结束时间
      console.log('s', s, e)
      let diff_day = this.$moment(e).diff(s, 'days')
      diff_day += 1
      this.diff_day = diff_day
      return diff_day
    }
  }
}
</script>

<style>

</style>
