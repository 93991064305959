<template>
  <div class="dashboard-container">
    <component :is="currentRole" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminDashboard from './admin'
import brandDashboard from './brand'
import storeDashboard from './store'
export default {
  name: 'Dashboard',
  components: { adminDashboard, brandDashboard, storeDashboard },
  data() {
    return {
      currentRole: 'adminDashboard'
      // ctiveTab:''
    }
  },
  computed: {
    ...mapGetters([
      'roles'
    ])
  },
  created() {
    if (this.roles.includes('brand')) {
      this.currentRole = 'brandDashboard'
    } else if (this.roles.includes('store')) {
      this.currentRole = 'storeDashboard'
    }
  },
  // watch : {
  //    ctiveTab:function(val) { //监听切换状态-计划单
  // 		console.log(val)
  //    },
  // },
  // methods: {
  // 	test(){
  // 		console.log(this.getters)
  // 	}
  // },
  mounted() {
    // console.log(this.$store)
  }
}
</script>
