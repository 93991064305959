<template>
  <div class="app-container special-center project">
    <el-form ref="ruleForm" :rules="rules" :model="form" label-position="left" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="账号头像" prop="avatar">
            <div class="avatar-block avatar-box" @click="imagecropperShow = true">
              <el-avatar shape="square" :size="100" :src="form.avatar" :title="form.avatar ? '点击修改' : '点击上传'">
                <img :src="errorImageAvatar">
              </el-avatar>
              <div class="avatar-hover">{{ form.avatar ? '更换头像' : '上传头像' }}</div>
            </div>
            <image-cropper
              v-show="imagecropperShow"
              :key="imagecropperKey"
              field="file"
              :width="300"
              :height="300"
              :url="storeUpload"
              lang-type="zh"
              @close="imagecropperClose"
              @crop-upload-success="cropSuccess"
            />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
            <el-form-item label="账号名" prop="account">
              <el-input v-model="form.account" placeholder="请输入账号名" />
            </el-form-item>
          </el-col> -->
        <!-- <el-col :span="12">
          <el-form-item label="密码" :prop="'password'">
            <el-input v-model="form.password" type="text" placeholder="不改请为空" />
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="姓名" prop="real_name">
            <el-input v-model="form.real_name" placeholder="请输入姓名" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone" type="number" placeholder="请输入手机号">
              <div slot="prepend">
                <Code :code="areaCodeData" @area_code="area_code" />
              </div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="生日" prop="birthday">
            <el-date-picker v-model="form.birthday" type="date" value-format="yyyy-MM-dd" placeholder="请选择日期" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="form.sex">
              <el-radio v-for="(item, index) in sexList" :key="index" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="星级" prop="star_level">
            <el-rate v-model="form.star_level" :colors="colors" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="团课教练" prop="is_group">
            <el-select v-model="form.is_group" placeholder="请选择">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="私课教练" prop="is_private">
            <el-select v-model="form.is_private" placeholder="请选择">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="课程颜色" prop="color">
            <div class="flex flex-wrap col-center row-left">
              <div><el-color-picker v-model="form.color" :style="{ paddingTop: '10px' }" @change="colorChange" /></div>
              <div class="m-l-10">
                <el-select
                  v-model="defaultColorValue"
                  placeholder="快速选色"
                  clearable
                  style="width:120px"
                  @change="defaultColor"
                >
                  <el-option v-for="(item, index) in courseDefaultColorList" :key="index" :value="item" :label="item">
                    <div class="defaultColorItem" :style="{ background: item }">{{ item }}</div>
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="金牌教练" prop="color">
            <el-switch v-model="form.is_gold" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-col>
        <el-col :span="24" />
        <el-col v-if="utils.getVenues().id !== -1" :span="12">
          <el-form-item label="兼职老师" prop="color">
            <el-switch v-model="part_time" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-col>
        <el-col v-if="utils.getVenues().id !== -1" :span="12">
          <el-form-item label="在职状态" prop="color">
            <el-radio-group v-model="state">
              <el-radio :label="1">在职</el-radio>
              <el-radio :label="0">离职</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="员工封面图片" prop="cover">
            <el-upload
              v-loading="upLoading"
              :action="storeUpload"
              list-type="picture-card"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="upSuccess"
              :headers="utils.upload_headers()"
              :file-list="fileList"
              :limit="1"
              :on-exceed="handleExceed"
              :on-progress="upProgress"
              :before-upload="checkFileType"
              accept=".jpg,.jpeg,.png"
            >
              <i class="el-icon-plus" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisible" class="dialog_view_image">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <small class="text-info">图片尺寸建议16:9</small>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="员工简介">
            <el-input
              v-model="form.introduce"
              type="textarea"
              :maxlength="200"
              :autosize="{ minRows: 5, maxRows: 5 }"
              placeholder="请输入员工简介"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item v-if="utils.getVenues().id === -1" label="所在场馆角色配置" prop="role" label-width="140px">
            <div v-if="utils.empty(roleLists)" class="tal">
              <checkRole :parent-selected.sync="rs" :list.sync="roleList" />
            </div>
            <div v-else>
              <el-collapse v-for="item in roleLists" :key="item.id" v-model="activeNames">
                <el-collapse-item :name="item.id">
                  <template slot="title">
                    <div>{{ item.name }}</div>
                    <div v-if="item.state === 0" class="m-l-10"><el-tag type="danger">离职</el-tag></div>
                    <div v-if="item.part_time === 1 && item.state === 1" class="m-l-10"><el-tag type="warning">兼职</el-tag></div>
                  </template>
                  <checkRole :parent-selected.sync="form.rs[item.id]" :list.sync="item.role" />
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-form-item>
          <el-form-item v-else label="所在场馆角色配置" prop="role" label-width="140px">
            <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
            <div style="margin: 15px 0;" />
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="item in roleList.list" :key="item.id" :label="item.id">{{ item.role_name }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button @click="$router.push({ name: 's_venues_employee' })">取消</el-button>
    </div>
  </div>
</template>

<script>
import { editStoreUser, getStoreUserDetails, getRole } from '@/api/store/management.js'
import { getVenuesRole } from '@/api/store/venues.js'
import ImageCropper from '@/components/ImageCropper' // 头像上传\
import checkRole from '@/components/All/checkRole'
import { deepClone } from '@/utils'
import { editEmployee } from '@/api/store/venues.js'
// 组件
import Code from '@/components/code/index.vue'
// import { thisTypeAnnotation } from '@babel/types'
export default {
  components: { ImageCropper, checkRole, Code },
  data() {
    return {
      rs: [],
      id: '',
      form: {
        id: '',
        sex: 1,
        is_group: 0,
        is_private: 0,
        avatar: '',
        color: null,
        star_level: 3,
        rs: [],
        is_gold: 0,
        introduce: '',
        cover: '',
        area_code: ''
      },
      part_time: 0,
      state: 0,
      rules: ['real_name', 'phone', 'birthday', 'sex'],
      imagecropperShow: false,
      imagecropperKey: 0,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      typeOptions: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      defaultColorValue: '',
      roleLists: {},
      roleList: {
        count: 0,
        list: []
      },
      activeNames: ['1'],
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      areaCodeData: '+86',
      checkAll: false,
      isIndeterminate: false,
      checkedCities: []
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.id = this.$route.params.id
    if (!this.id) return false
    // 定时器
    setTimeout(() => {
      this.getVenuesRole()
    }, 100)
    this.getVenuesRole()
    this.getStoreUserDetails()
  },
  methods: {
    handleCheckAllChange(val) {
      const list = []
      this.roleList.list.forEach(item => {
        list.push(item.id)
      })
      this.checkedCities = val ? list : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.roleList.list.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.roleList.list.length
    },
    getVenuesRole() {
      if (this.utils.getVenues().id === -1) {
        getVenuesRole().then(res => {
          this.roleLists = res.data
          this.roleLists.forEach((v, i) => {
            this.form.employee.forEach((v1, i1) => {
              if (v.id === v1.venues_id) {
                v.state = v1.state
                v.part_time = v1.part_time
              }
            })
          })
        })
      } else {
        const data = {
          limit: 999
        }
        getRole(data)
          .then(res => {
            res.data.list = res.data.list.reverse()
            this.roleList = res.data
          }).catch(() => {})
      }
    },
    getStoreUserDetails() {
      getStoreUserDetails(this.id).then(res => {
        var data = res.data
        if (this.utils.getVenues().id !== -1) {
          if (!this.utils.empty(data.role_info)) {
            const numArray = data.role_info.roles_id.split(',').map(function(numStr) {
              return parseInt(numStr, 10) // 使用 parseInt 将字符串转换为整数
            })
            // 赋值给场馆内的角色配置
            this.checkedCities = numArray
            this.part_time = data.role_info.part_time
            this.state = data.role_info.state
          }
        }
        data.password = ''
        data.star_level = parseInt(data.star_level)
        var rols = []
        if (data.employee) {
          data.employee.forEach((v, i) => {
            if (rols[v.venues_id]) {
              rols[v.venues_id].push(v.role_id)
            } else {
              rols[v.venues_id] = [v.role_id]
            }
          })
        }

        data['rs'] = rols

        if (data.cover) {
          var imagesList = data.cover.split(',')
          var imagesArr = []
          imagesList.forEach(function(i) {
            imagesArr.push({
              name: '123123',
              url: i
            })
          })
          this.fileList = imagesArr
        }
        data.area_code = '+' + data.area_code
        this.form = data
        this.areaCodeData = this.form.area_code
        this.$forceUpdate()
      })
    },
    submit() {
      var roles = []
      if (this.form.rs) {
        this.form.rs.forEach((v, i) => {
          v.forEach((v2, i2) => {
            const dataList = this.form.employee.filter(item => {
              return item.role_id === v2 && item.venues_id === i
            })
            if (dataList.length > 0) {
              roles.push(dataList[0])
            } else {
              roles.push({
                venues_id: i,
                role_id: v2,
                state: 1,
                part_time: 0,
                store_user_id: this.id
              })
            }
          })
        })
      }
      var data = deepClone(this.form)
      data.area_code = (this.form.area_code === '+86') ? '86' : this.form.area_code.replace(/[^0-9]/ig, '')
      data.employee = roles
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // if (this.utils.empty(data.cover)) {
          //   this.$message.error('员工封面图片')
          //   return false
          // }
          if (data.cover && typeof data.cover === 'object') data.cover = data.cover.join(',')
          editStoreUser(this.id, data)
            .then(res => {
              if (this.utils.getVenues().id !== -1) {
                const data = {
                  part_time: this.part_time,
                  role_id: this.checkedCities,
                  state: this.state,
                  store_user_id: this.$route.params.id,
                  venues_id: this.utils.getVenues().id
                }
                console.log('🚀 ~ file: edit.vue:394 ~ submit ~ data:', data)
                editEmployee(this.$route.params.id, data)
              }
              this.$message.success('修改成功')
              this.$router.push({ name: 's_venues_employee' })
            })
            .catch(() => {})
        }
      })
    },
    // 头像上传成功
    cropSuccess(resData) {
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      this.$set(this.form, 'avatar', resData.path)
    },
    // 头像上传弹出层关闭
    imagecropperClose() {
      this.imagecropperShow = false
    },
    defaultColor(i, v) {
      this.form.color = i
    },
    colorChange(v) {
      this.defaultColorValue = v
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      var imageList = []
      for (var i in fileList) {
        if (fileList[i].response) {
          imageList.push(fileList[i].response.data.path)
        } else {
          imageList.push(fileList[i].url)
        }
      }
      this.$set(this.form, 'cover', imageList)
    },
    upProgress() {
      this.upLoading = true
    },
    checkFileType(file) {
      if (file.size / 4000 / 4000 > 0.1) {
        this.$message.warning('上传图片过大，请重新上传！')
        return false
      }
    },
    handleRemove(file, fileList) {
      var imageList = []
      for (var i in fileList) {
        if (fileList[i].response) {
          imageList.push(fileList[i].response.data.path)
        } else {
          imageList.push(fileList[i].url)
        }
      }
      this.$set(this.form, 'cover', imageList)
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleExceed(files, fileList) {
      this.$message.warning(`仅支持上传一张活动图片`)
    },
    area_code(val) {
      this.form.area_code = val
    }
  }
}
</script>

<style lang="scss" scoped>
.el-avatar {
  background: none;
}
.defaultColorItem {
  width: 100%;
  background: red;
  text-align: center;
  margin: 1px 0px;
  color: #fff;
}
.avatar-block {
  display: unset;
}
</style>
