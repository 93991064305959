<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleTemplate"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="25%"
      :show-close="false"
      @open="handleOpen"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">选择合同标签</div>
          <div class="title_icon cursor" @click="handleClose">
            <i class="el-icon-close" />
          </div>
        </div>
      </div>
      <div v-loading="dialogLoading" class="tags_layout">
        <div v-for="item in dialogData.list" :key="item.id" class="cursor" :class="idList.includes(item.id) ? 'select font_color' : 'unchecked'" @click="handleSelect(item.id)">{{ item.name }}</div>
        <div class="font_color unchecked cursor" @click="handleAddTag">+ 添 加</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 合同设置 -->
    <settingsDialog :dialog-visible-settings="dialogVisibleSettings" @closeVisibleSettings="closeVisibleSettings" />
  </div>
</template>

<script>
// API
import { getTagList } from '@/api/store/contract'
// 组件
import settingsDialog from '../components/settingsDialog'

export default {
  components: { settingsDialog },
  props: {
    dialogVisibleTemplate: {
      type: Boolean,
      default: false
    },
    templateId: {
      type: Number,
      default: 0
    },
    // 数组包对象
    templateTags: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogData: {
        count: 0,
        list: []
      },
      idList: [],
      dialogLoading: false,
      dialogVisibleSettings: false
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.dialogLoading = true
      getTagList()
        .then(res => {
          const { data } = res
          this.dialogData = data
        })
        .finally(() => {
          this.dialogLoading = false
        })
      // 传过来的tags数组
      if (this.templateTags.length > 0) {
        this.templateTags.forEach(item => {
          this.idList.push(item.id)
        })
      }
    },
    // 关闭的回调
    handleClose() {
      this.$emit('closeVisibleTemplate', this.idList)
      this.idList = []
    },
    handleSelect(id) {
      // 点击保存选中把id保存到idList 再次点击从中去除
      if (this.idList.includes(id)) {
        const index = this.idList.indexOf(id)
        this.idList.splice(index, 1)
      } else {
        if (this.idList.length >= 3) return this.$message.error('最多选择三个标签！')
        this.idList.push(id)
      }
    },
    // 添加标签
    handleAddTag() {
      this.dialogVisibleSettings = true
    },
    closeVisibleSettings() {
      this.dialogVisibleSettings = false
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.tree-height {
  height: 60vh;
  overflow-y: auto;
}
.tags_layout {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 20%;
    text-align: center;
    margin: 10px;
    padding: 10px 0;
  }
  .font_color {
    color: #45d7d7;
  }
  .select {
    background-color: #e9ffff;
  }
  .unchecked {
    background-color: #f8f8f8;
  }
}
</style>
