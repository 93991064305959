var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "m-t-20" },
      [
        _c("timeSelect", {
          attrs: { "date-time": _vm.dateTime },
          on: { updateTime: _vm.updateTime }
        })
      ],
      1
    ),
    _c("div", [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "course_list"
        },
        [
          _vm.venuesList.count > 0
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                _vm._l(_vm.venuesList.list, function(item) {
                  return _c(
                    "el-col",
                    {
                      key: item.id,
                      attrs: { xs: 12, sm: 12, md: 8, lg: 4, xl: 4 }
                    },
                    [
                      _c("div", { staticClass: "course_item" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.course_active(item)
                              }
                            }
                          },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "course_card",
                                class:
                                  (_vm.activeCourse == item.id
                                    ? "active"
                                    : "") + (item.overdue ? " disabled" : ""),
                                attrs: {
                                  "body-style": { padding: "0px" },
                                  shadow: "hover"
                                }
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "130px"
                                  },
                                  attrs: {
                                    src:
                                      item.course && item.course.image
                                        ? item.course.image
                                        : _vm.default_bg,
                                    fit: "cover"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: " course_card_text",
                                    staticStyle: { position: "relative" }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "course_color",
                                      style: { background: item.color }
                                    }),
                                    _c("p", [
                                      _c("span", [_vm._v("教练")]),
                                      _vm._v(
                                        "：" +
                                          _vm._s(
                                            item.store_user
                                              ? item.store_user.real_name
                                              : ""
                                          )
                                      )
                                    ]),
                                    _c("p", { staticClass: "line-1" }, [
                                      _c("span", [_vm._v("课程")]),
                                      _vm._v("："),
                                      item.course && item.course.type == 2
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-danger",
                                              attrs: { title: "精品课" }
                                            },
                                            [_vm._v(_vm._s("[精] "))]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        _vm._s(
                                          item.course ? item.course.name : "--"
                                        )
                                      )
                                    ]),
                                    _c("p", [
                                      _c("span", [_vm._v("教室")]),
                                      _vm._v(
                                        "：" +
                                          _vm._s(
                                            item.classroom
                                              ? item.classroom.name
                                              : "未知教室"
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "p",
                                      [
                                        _c("span", [_vm._v("人数")]),
                                        _vm._v(
                                          "：" +
                                            _vm._s(item.total_sign_in) +
                                            "/" +
                                            _vm._s(item.total_valid) +
                                            "/" +
                                            _vm._s(item.galleryful) +
                                            " "
                                        ),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                "已签到人数/有效预约人数/可容纳人数",
                                              placement: "bottom"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-question text-info m-l-2"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      [
                                        _c("span", [_vm._v("难度")]),
                                        _vm._v("： "),
                                        _c("el-rate", {
                                          style: { display: "inline-flex" },
                                          attrs: {
                                            value: item.difficulty,
                                            disabled: ""
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "bottom flex m-y-12" },
                                      [
                                        _c(
                                          "time",
                                          { staticClass: "time flex-1" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDateLT")(
                                                  item.start_time
                                                )
                                              ) +
                                                " ~ " +
                                                _vm._s(
                                                  _vm._f("formatDateLT")(
                                                    item.end_time
                                                  )
                                                )
                                            )
                                          ]
                                        ),
                                        !item.auto_cancel
                                          ? _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.btn_access(
                                                      "s_course_curriculum_cancel"
                                                    ),
                                                    expression:
                                                      "btn_access('s_course_curriculum_cancel')"
                                                  }
                                                ],
                                                staticClass:
                                                  "button text-warning",
                                                attrs: {
                                                  disabled: !!item.overdue,
                                                  type: "text"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.courseCancel(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("取消课程")]
                                            )
                                          : _vm._e(),
                                        !item.auto_cancel
                                          ? _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.btn_access(
                                                      "s_workbench_subscribe"
                                                    ),
                                                    expression:
                                                      "btn_access('s_workbench_subscribe')"
                                                  }
                                                ],
                                                staticClass: "button",
                                                attrs: {
                                                  disabled: !!item.auto_cancel,
                                                  type: "text"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.courseSubscribe(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("预约")]
                                            )
                                          : _vm._e(),
                                        item.auto_cancel
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "button ",
                                                attrs: {
                                                  disabled: !!item.auto_cancel,
                                                  type: "text"
                                                }
                                              },
                                              [_vm._v("课程已取消")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                }),
                1
              )
            : _c("div", { staticClass: "venuesList_empty text-center p-10" }, [
                _c("img", { attrs: { src: _vm.empty_default_img, alt: "" } }),
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "text-info" }, [
                      _vm._v("当日暂无团课预约记录")
                    ]),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "s_course_curriculum"
                            })
                          }
                        }
                      },
                      [_vm._v("去排课")]
                    )
                  ],
                  1
                )
              ]),
          _c(
            "div",
            { staticClass: "flex row-center" },
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.venuesList.count > 0,
                    expression: "venuesList.count > 0"
                  }
                ],
                attrs: {
                  total: _vm.venuesList.count,
                  page: _vm.flippingPages.page,
                  limit: _vm.flippingPages.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.flippingPages, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.flippingPages, "limit", $event)
                  },
                  pagination: _vm.getVenuesList
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm.activeCourse
      ? _c(
          "div",
          { staticClass: "m-t-30" },
          [
            _c("bookList", {
              ref: "booklist",
              attrs: {
                type: 0,
                title: _vm.booklist_title,
                "total-sign-in": _vm.total_sign_in,
                "total-valid": _vm.total_valid
              },
              on: { refresh: _vm.refresh },
              model: {
                value: _vm.activeCourse,
                callback: function($$v) {
                  _vm.activeCourse = $$v
                },
                expression: "activeCourse"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      [
        _c("groupBook", {
          attrs: { courseid: _vm.courseid },
          on: { updateListMethods: _vm.updateListMethods },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }