<template>
  <div v-loading="loading">
    <div class="filter-container">
      <el-button type="primary" class="fr m-b-10" @click="operation()">操作</el-button>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData.list"
      row-key="id"
    >
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column prop="change_integral" label="积分变动">
        <template slot-scope="{row}">
          {{ row.change_integral }}
        </template>
      </el-table-column>
      <el-table-column prop="exist_integral" label="剩余积分" />
      <el-table-column prop="change_integral" label="增/减">
        <template slot-scope="{row}">
          <el-tag v-if="row.change_integral>0">增加</el-tag>
          <el-tag v-else type="danger">扣减</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="origin_integral" label="变更前" /> -->
      <el-table-column prop="type" label="类型" show-overflow-tooltip />
      <el-table-column prop="remark" label="备注" show-overflow-tooltip />
      <el-table-column prop="create_time" label="操作时间" min-width="120" />
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="会员积分操作"
      class="dialog_w580"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" :inline="true" label-width="80px">
        <el-form-item label="加赠/扣减" prop="change_type">
          <el-radio v-model="form.change_type" :label="1">加赠</el-radio>
          <el-radio v-model="form.change_type" :label="2">扣减</el-radio>
        </el-form-item>
        <el-form-item label="积分数" prop="change_integral">
          <el-input v-model="form.change_integral" type="number" :rows="4" placeholder="请输入操作积分数" class="dialog_w100" />
        </el-form-item>
        <el-form-item label="操作备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" :rows="4" placeholder="请输入操作备注" class="dialog_w100" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" :loading="btn_loading" @click="submit">确认</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserIntegral, setUserIntegral } from '@/api/store/user.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import { deepClone } from '@/utils'
export default {
  name: 'UserIntegral',
  components: { Pagination },
  props: {
    userId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      loading: false,
      // 弹窗
      dialog: {
        visible: false
      },
      form: {
        change_integral: 0, // 增加或者减少积分数
        change_type: 1, // 变动类型:1=增加,2=减少
        remark: ''// 备注
      },
      rules: {
        change_integral: [
          { required: true, message: '请输入操作积分数', trigger: ['blur', 'change'] },
          { validator: (rule, value, callback) => {
            if (this.form.change_integral <= 0) {
              callback(new Error('请填写有效操作积分数'))
            }
            callback()
          }, trigger: ['blur', 'change']
          }
        ]
      },
      btn_loading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      data.query = this.utils.getQueryParams([{ field: 'user_id', key: this.userId }])
      getUserIntegral(data).then(res => {
        this.tableData = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    // 积分操作
    operation() {
      this.dialog.visible = true
    },
    // 积分操作提交
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.btn_loading = true
          const data = deepClone(this.form)
          setUserIntegral(this.userId, data).then(res => {
            this.$message.success(res.msg)
            this.dialog.visible = false
            this.getList()
            this.$emit('upInfo')
          }).finally(() => {
            this.btn_loading = false
          })
        }
      })
    }
  }
}
</script>

<style>

</style>
