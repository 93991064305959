<template>
  <!-- 班级课程管理 -->
  <div class="app-container special-center">
    <!-- 头部导航 -->
    <el-page-header content="查看详情" class="page-header" @back="returnPage()" />
    <!-- 课程信息 -->
    <el-descriptions v-loading="upForm" style="margin-top: 20px;" class="margin-top" :column="2" border>
      <el-descriptions-item label="班级名称">{{ form.name }}</el-descriptions-item>
      <el-descriptions-item label="开课时间">{{ classTime[0] }} - {{ classTime[1] }}</el-descriptions-item>
      <el-descriptions-item label="班级课时">{{ form.class_time }}</el-descriptions-item>
      <el-descriptions-item label="班级简介">{{ form.desc }}</el-descriptions-item>
      <el-descriptions-item label="会员购买">
        {{ form.member_buy === true ? '会员' : '' }} {{ form.guest_buy === true ? '访客' : '' }}
      </el-descriptions-item>

      <el-descriptions-item label="售价">{{ money === 1 ? '免费' : '收费' }}</el-descriptions-item>
      <el-descriptions-item v-if="money === 2" label="售价">{{ form.money }}</el-descriptions-item>
      <el-descriptions-item v-if="money === 2" label="优惠价">
        {{ form.discount_on === 1 ? '开启' : '关闭' }}
      </el-descriptions-item>
      <el-descriptions-item v-if="form.discount_on === 1 && money === 2" label="优惠价费用">
        {{ form.discount }}
      </el-descriptions-item>
      <el-descriptions-item v-if="form.discount_on === 1 && money === 2" label="优惠价时间">
        {{ discounts[0] }} - {{ discounts[1] }}
      </el-descriptions-item>

      <el-descriptions-item label="人数上限">{{ form.people_number === 1 ? '限制' : '不限制' }}</el-descriptions-item>
      <el-descriptions-item v-if="form.people_number === 1" label="限制人数">{{ people_number }} 人</el-descriptions-item>
      <el-descriptions-item label="请假上限">{{ form.ask_number === 1 ? '限制' : '不限制' }}</el-descriptions-item>
      <el-descriptions-item v-if="form.ask_number === 1" label="请假次数">{{ ask_number }} 次</el-descriptions-item>
      <el-descriptions-item label="是否上架">{{ form.shelves === 1 ? '上架' : '下架' }}</el-descriptions-item>
      <el-descriptions-item label="班级描述">{{ form.introduction }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
// API
import { getMclass } from '@/api/store/course'

export default {
  data() {
    return {
      form: {
        id: '',
        name: '',
        cover: '',
        open_time: '',
        end_time: '',
        class_time: '',
        desc: '',
        money: 0,
        discount_on: '0',
        discount: 0,
        discount_start_time: '',
        discount_end_time: '',
        people_number: 0,
        ask_number: 0,
        introduction: '',
        shelves: '',
        guest_buy: false,
        member_buy: false
      },
      classTime: [],
      money: 1,
      discounts: [],
      people_number: 0,
      ask_number: 0,
      upForm: false
    }
  },
  watch: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList() {
      this.upForm = true
      getMclass(this.$route.params.id).then(res => {
        if (res.data.money !== 0) this.money = 2
        if (res.data.people_number !== 0 && res.data.people_number !== 1) {
          this.people_number = res.data.people_number
          res.data.people_number = 1
        }
        if (res.data.ask_number !== 0 && res.data.ask_number !== 1) {
          this.ask_number = res.data.ask_number
          res.data.ask_number = 1
        }
        if (res.data.guest_buy === 1) res.data.guest_buy = true
        else res.data.guest_buy = false
        if (res.data.member_buy === 1) res.data.member_buy = true
        else res.data.member_buy = false
        this.classTime.push(res.data.open_time)
        this.classTime.push(res.data.end_time)
        this.discounts.push(res.data.discount_start_time)
        this.discounts.push(res.data.discount_end_time)
        this.form = res.data
        console.log(this.form)
        this.upForm = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
