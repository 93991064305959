const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  permission_routes: state => state.permission.routes,
  // 异常输出
  errorLogs: state => state.errorLog.logs,
  // 用户头像
  avatar: state => state.user.avatar,
  // 超级用户
  isSuper: state => state.user.is_super,
  token: state => state.user.token,
  account: state => state.user.account,
  // 页面组roles/brand/admin
  roles: state => state.user.roles,
  // 登录用户信息
  userinfo: state => state.user.userinfo,
  // 用户权限节点
  userAccess: state => state.user.access,
  // 当前选中场馆
  venues: state => state.venues.venuesSelect
}
export default getters
