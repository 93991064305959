<template>
  <div v-if="$route.name=='s_membership_card'" class="app-container">
    <div class="filter-container">
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <router-link :to="{name: 's_membership_card_add'}">
        <el-button v-show="btn_access('s_membership_card_add')" type="primary" icon="el-icon-plus" class="filter-item m-l-10">添加</el-button>
      </router-link>
    </div>

    <el-tabs v-model="activeType">
      <el-tab-pane label="期限卡" :value="0" />
      <el-tab-pane label="次数卡" :value="1" />
      <el-tab-pane label="储值卡" :value="2" />
    </el-tabs>

    <div v-if="list.length > 0" v-loading="loading" class="list flex-wrap">
      <div v-for="item in list" :key="item.id">
        <div v-if="item.type == activeType" class="card_item" :style="{background:'url('+card_bg(item.type)+') center center / 100% no-repeat',color:(item.type==0?'#82848a':'#e6ebf5')}">
          <!-- <img v-if="item.type==0" :src="config['card_time_bg']">
            <img v-if="item.type==1" :src="config['card_num_bg']">
            <img v-if="item.type==2" :src="config['card_stored_bg']"> -->
          <div class="cardNumber line-1"><span>{{ item.name }}</span></div>
          <div v-show="item.kind" class="cardKind"><img src="@/assets/images/is_kind.png"></div>
          <div class="cardBody">
            <span>售价 ￥{{ item.price }}</span>
            <span>有效期 {{ JSON.parse(item.valid_period).digital }} {{ formatTime(JSON.parse(item.valid_period).type) }}</span>
            <!-- <span v-if="item.type==1">剩余 {{ JSON.parse(item.valid_period).digital }} 次</span>
            <span v-if="item.type==2">￥{{ JSON.parse(item.valid_period).digital }}</span> -->
          </div>
          <div class="cardFooter" :class="{'row-right':item.type == 0}">
            <span v-if="item.type == 1">可预约 {{ item.limit }}次</span>
            <span v-if="item.type == 2">面额 ￥{{ item.limit }}</span>
            <div v-if="item.venues_id == currentVenues.id||utils.getVenues().id==-1">
              <el-tooltip class="item" effect="dark" content="升级为多馆卡" placement="bottom">
                <el-button v-if="item.venues_id!==-1" v-show="btn_access('s_membership_card_update')" type="success" size="mini" plain @click="upCard(item.id)">升级</el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="降级为场馆卡" placement="bottom">
                <el-button v-if="item.venues_id===-1" v-show="btn_access('s_membership_card_demotion')" type="warning" size="mini" plain @click="downCard(item.id)">降级</el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="编辑会员卡" placement="bottom">
                <el-button v-show="btn_access('s_membership_card_edit')" type="primary" plain size="mini" @click="toEdit(item.id)">编辑</el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除会员卡" placement="bottom">
                <el-button v-show="btn_access('s_membership_card_delete')" v-loading="delLoading" type="danger" size="mini" plain @click="del(item.id)">删除</el-button>
              </el-tooltip>
            </div>
            <div v-else>
              多馆卡
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="venuesList_empty text-center p-10">
      <img :src="empty_default_img" alt="">
      <div>
        <div class="text-info">暂无此卡</div>
      </div>
    </div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="降级为场馆卡"
      class="dialog_w580"
      destroy-on-close
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" :inline="true" label-width="80px">
        <el-form-item label="场馆" prop="venues_id">
          <el-select v-model="form.venues_id" filterable placeholder="可搜索" class="dialog_w100">
            <el-option v-for="(item,index) in venues_list" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="downCardSubmit">提交</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { getVipCard, upVipCard, delVipCard, downVipCard, getVenues } from '@/api/store/venues.js'
import { getFieldList } from '@/api/store/config.js'

export default {
  data() {
    return {
      list: [], // 列表
      activeList: [], // 选中卡列表
      activeType: 0, // 选中卡类型
      loading: false,
      config: [],
      searchLoading: false,
      delLoading: false,
      // 弹窗
      dialog: {
        downLoading: false,
        visible: false,
        id: null
      },
      venues_list: {},
      form: {
        venues_id: ''
      },
      rules: ['venues_id']
    }
  },
  watch: {
    activeType() {
      this.getList()
    }
  },
  created() {
    getFieldList(13).then(res => {
      const list = res.data.list; const lists = []
      list.forEach((item, index) => {
        lists.push(item.store_config ? item.store_config : item)
      })
      lists.forEach((item, index) => {
        this.config[item.field] = item.value ? item.value : item.default
      })
      this.getList()
    }).catch(() => {})
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {

  },
  methods: {
    // 获取场馆列表
    getVenuesList() {
      if (this.utils.empty(this.venues_list)) {
        getVenues({ limit: 999 }).then(res => {
          this.venues_list = res.data.list
        }).catch(() => {

        })
      }
    },
    numberFormat(v) {
      var v1 = v.toString()
      return v1.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.getList()
    },
    // 获取列表
    getList() {
      this.loading = true
      const data = {}

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'name', type: 'like', key: this.listQueryParams.query })

      data.query = this.utils.getQueryParams(querys)
      data.limit = 999
      getVipCard(data).then(res => {
        const dataList = res.data.list.filter(item => {
          return item.type === parseInt(this.activeType)
        })
        // this.list = res.data.list
        this.list = dataList
        this.loading = false
        this.searchLoading = false
      }).catch(() => {})
    },
    toEdit(id) {
      this.$router.push({ name: 's_membership_card_edit', params: { id: id }})
    },
    // 升级为多馆卡
    upCard(id) {
      this.$confirm('此操作将升级会员卡为多馆卡', '提示', {
        confirmButtonText: '升级',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        upVipCard(id).then(res => {
          this.$message.success(res.msg)
          this.getList()
        }).catch(() => {})
      }).catch(() => {

      })
    },
    card_bg(n) {
      switch (n) {
        case 0:
          return this.config.card_time_bg
        case 1:
          return this.config.card_num_bg
        case 2:
          return this.config.card_stored_bg
        default:
          break
      }
    },
    formatTime(t) {
      switch (t) {
        case 'day':
          return '天'
        case 'month':
          return '月'
        case 'year':
          return '年'
      }
    },
    del(id) {
      this.$confirm('删除会员卡名称前请先删除已经发给会员的该种类的卡，此操作不可恢复。确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delLoading = true
        delVipCard(id).then(res => {
          this.$message.success(res.msg)
          this.getList()
        }).finally(() => {
          this.delLoading = false
        })
      }).catch(() => {

      })
    },
    downCard(id) {
      this.getVenuesList()
      this.dialog.visible = true
      this.dialog.id = id
      this.dialog.downLoading = true
    },
    // 降级提交
    downCardSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          downVipCard(this.dialog.id, { venues_id: this.form.venues_id }).then(res => {
            this.$message.success(res.msg)
            this.getList()
            this.dialog = this.$options.data.call(this).dialog
            this.form = this.$options.data.call(this).form
          }).finally(() => {
            this.dialog.downLoading = false
          })
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.list{
  display: flex;
}
.card_item{
  position: relative;

  & .cardKind{
    position: absolute;
    right: 0px;
    top: 0px;
  }
  &:hover{
    box-shadow: 0 2px 12px 0 #82848a;
  }
  width: 300px;
  // height: 158px;
  margin-right: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  // background: linear-gradient(to bottom right,rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), url("https://yogaapi.shigukj.com/uploads/20210716/edc94d789cd804b50b2bd03ebb1536d6.jpg") center center / 100% no-repeat;
  // background: url("/storage/uploads/20210910/160d383b129bb18c685a43acb620bce6.jpg") center center / 100% no-repeat;
  transition: 0.3s;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  // font-weight: 800;
  // display: flex;
  .cardNumber{
    b{
      letter-spacing: 1em;
    }
    span{
      font-size: 15px;
    }
    padding: 38px 10px 20px;
    font-size: 16px;
  }
  .cardBody{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 10px;
    margin-bottom: 5px;
  }
  .cardFooter{
    line-height: 28px;
    display: flex;
    border-top: 1px solid #c0c4cc6e;
    padding:4px 0px 10px 0px;
    margin:0 10px;
   justify-content: space-between;
     .card-btn{
      background: none;
      color: gainsboro;
    }
  }
}
.venuesList_empty{
  min-height: 400px;
  img {
    -webkit-user-drag: none;
  }
}
</style>
