<template>
  <div>
    <!-- 爽约惩罚列表 -->
    <el-table ref="penaltyTable" v-loading="loading" :data="tableData.list" row-key="id">
      <el-table-column prop="id" label="会员信息" min-width="120px">
        <template slot-scope="{ row: { user } }">
          <div v-if="!utils.empty(user)" class="flex col-center cursor" @click="toUserInfoPage(user.id)">
            <div>
              <el-avatar shape="square" :size="50" :src="user.avatar">
                <img :src="errorAvatarSrc(user, 'img')">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(user) }}</span>
              <br>
              <span>{{ user.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="会员卡信息" min-width="120px">
        <template slot-scope="{ row }">
          <div v-if="row.user_membership_card">
            <div v-if="row.user_membership_card.card">
              {{
                `${row.user_membership_card.card.name}（${
                  row.user_membership_card.card.type === 0 ? '期限卡' : row.user_membership_card.card.type === 1 ? '次数卡' : row.user_membership_card.card.type === 2 ? '储值卡' : ''
                }）`
              }}
            </div>
            <div v-else class="text-danger">会员卡信息缺失</div>
          </div>
          <div v-else class="text-danger">会员卡信息缺失</div>
        </template>
      </el-table-column>
      <el-table-column prop="venues.name" label="场馆名称" />
      <el-table-column prop="notice" label="惩罚备注">
        <template slot-scope="scope">
          <div v-if="scope.row.notice">{{ scope.row.notice }}</div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="状态">
        <template slot-scope="scope">
          <div v-if="scope.row.type == 1">
            <el-tag>已惩罚</el-tag>
          </div>
          <div v-if="scope.row.type == 2">
            <el-tag v-if="scope.row.status == 0" type="success">惩罚生效</el-tag>
            <el-tag v-if="scope.row.status == 1" type="info">不生效</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="爽约课程">
        <template slot-scope="{ row }">
          <div v-if="row.course_subscribe_list.length > 0">
            <span v-for="(item, index) in row.course_subscribe_list" :key="index" class="cursor" @click="handleInfo(item.id)">
              <span v-if="index !== 0">、</span>
              <span class="clickText">{{ item.name }}</span>
            </span>
          </div>
          <div v-else>无</div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="限制预约时间" min-width="120px">
        <template slot-scope="{ row }">{{ row.create_time.slice(0, 16) }} - {{ row.end_time.slice(0, 16) }}</template>
      </el-table-column>
      <!-- <el-table-column prop="end_time" label="结束时间" /> -->
      <el-table-column prop="id" label="操作" min-width="120px">
        <template slot-scope="scope">
          <template v-if="scope.row.status == 0 && scope.row.type == 2">
            <el-popconfirm title="确定取消本条爽约记录吗？" @confirm="penalty_cancel(scope.row.id)">
              <el-button v-show="btn_access('s_break_promise_penalty')" slot="reference" type="danger" class="m-r-5">取消爽约</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <pagination small :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <!-- 预约详情 -->
    <div>
      <bookInfo v-model="dialog" :logid.sync="logid" />
    </div>
  </div>
</template>

<script>
import { getPenalty, cancelPenalty } from '@/api/store/worktable.js'
import bookInfo from '@/views/store/worktable/components/bookInfo'
import Pagination from '@/components/Pagination' // 分页组件
export default {
  components: { bookInfo, Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      loading: false,
      dialog: { title: '预约详情', visible: false },
      logid: 0
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /**
     * 取消爽约惩罚
     */
    penalty_cancel(id) {
      cancelPenalty(id).then(res => {
        this.$message.success('操作成功')
        this.getList()
      })
    },
    // 获取爽约惩罚列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      this.loading = true
      getPenalty(data)
        .then(res => {
          this.tableData = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleInfo(id) {
      this.dialog = { title: '预约详情', visible: true }
      this.logid = id
    }
  }
}
</script>

<style lang="scss" scoped>
.clickText {
  color: #418dff;
  border-bottom: 1px solid #418dff;
}
</style>
