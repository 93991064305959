<template>
  <el-card style="margin-bottom:20px;">
    <div slot="header" class="clearfix">
      <div class="header_flex">
        <div class="left">
          <div class="header_title">
            <span>积分排行</span>
          </div>
          <div class="header_dropdown">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                {{ dropdownName }}
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleTime(1)">正序</el-dropdown-item>
                <el-dropdown-item @click.native="handleTime(2)">倒序</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="right">
          <div class="details" @click="is_display = !is_display">
            {{ is_display ? '切换详情' : '切换图表' }}
            <i class="el-icon-sort" />
          </div>
          <div v-if="!is_display" class="details screen" @click="show = !show">
            {{ show ? '收起' : '筛选' }}
          </div>
        </div>
      </div>
      <div>
        <el-collapse-transition>
          <div v-show="show && !is_display">
            <el-form ref="form" style="padding-top: 30px;" :inline="true" :model="listQuery" label-width="80px">
              <el-form-item label="会员" prop="memberID">
                <Select :type="2" :width="216" @changeId="handleMemberID" />
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-transition>
      </div>
    </div>
    <!-- 内容 -->
    <div v-show="is_display" id="pointsRanking" :style="{ height: '500px', width: '100%' }" />
    <el-table
      v-show="!is_display"
      v-loading="loadingTable"
      :data="dataTable.list"
      :height="428"
      style="width: 100%; height: 428px;"
      align="center"
    >
      <el-table-column type="index" label="排名" width="100px" align="center">
        <template slot-scope="scope">
          <div v-if="(listQuery.page - 1) * listQuery.limit + scope.$index + 1 === 1">
            <img src="@/assets/images/ranking/rankingNo1.png" alt="">
          </div>
          <div v-else-if="(listQuery.page - 1) * listQuery.limit + scope.$index + 1 === 2">
            <img src="@/assets/images/ranking/rankingNo2.png" alt="">
          </div>
          <div v-else-if="(listQuery.page - 1) * listQuery.limit + scope.$index + 1 === 3">
            <img src="@/assets/images/ranking/rankingNo3.png" alt="">
          </div>
          <div v-else>{{ (listQuery.page - 1) * listQuery.limit + scope.$index + 1 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="groupCount" label="会员" align="center">
        <template slot-scope="{ row }">
          <div class="flex">
            <el-avatar shape="square" :size="50" :src="row.avatar">
              <img :src="errorMemberSrc()">
            </el-avatar>
            <span class="m-l-10">{{ row.nickname ? row.nickname : row.real_name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="integral" label="积分" width="100px" align="center" />
    </el-table>
    <pagination
      v-show="dataTable.count > 0 && !is_display"
      :total="dataTable.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getTable"
    />
  </el-card>
</template>

<script>
import { getRankingPoints } from '@/api/store/ranking'
// import { timestampToTimes } from '@/utils/takeleave.js'
// 基于el-pagination的二级包
import Pagination from '@/components/Pagination'
import Select from '@/components/elSelect/Select'

export default {
  components: { Pagination, Select },
  data() {
    return {
      dataTable: {
        count: 0,
        integralMax: 0,
        integralSum: 0,
        list: []
      }, // 数据
      option: {}, // echarts
      dropdownName: '倒序', // 排序
      dropdownId: 'desc', // 排序id
      end: '', // 日期
      start: '', // 日期
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }, // 快捷选择日期
      dataName: [], // 人员
      data: [], // 课程数据
      is_display: true,
      show: false,
      loadingTable: false,
      count: 0,
      integralSum: 0
    }
  },
  created() {},
  mounted() {
    // this.start = timestampToTimes(new Date().getTime() - 3600 * 1000 * 24 * 7)
    // this.end = timestampToTimes(Date.parse(new Date()))
    this.getList()
    this.getTable()
  },
  methods: {
    getList() {
      const data = {
        limit: 5,
        offsset: 0,
        order: `integral ${this.dropdownId}`
      }

      this.dataName = []
      this.data = []
      getRankingPoints(data)
        .then(res => {
          this.count = res.data.count
          this.integralSum = res.data.integralSum
          const data = res.data
          data.list.forEach(item => {
            if (item.nickname) {
              this.dataName.push(item.nickname)
            } else if (item.real_name) {
              this.dataName.push(item.real_name)
            }
            this.data.push(item.integral)
          })
        })
        .finally(() => {
          this.getOption()
        })
    },
    getTable() {
      this.loadingTable = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.order = `integral ${this.dropdownId}`

      var querys = []
      if (this.listQuery.user_id) querys.push({ field: 'id', key: this.listQuery.user_id })
      data.query = this.utils.getQueryParams(querys)

      getRankingPoints(data).then(res => {
        this.dataTable = res.data
        this.loadingTable = false
      })
    },
    // echarts
    getOption() {
      // this.option = {
      //   title: {
      //     text: '积分排行',
      //     subtext: `${this.start.slice(0, 10)} - ${this.end.slice(0, 10)}`,
      //     // '本月数据'
      //     left: 'center'
      //   },
      //   tooltip: {},
      //   legend: {
      //     show: false // 是否显示
      //   },
      //   xAxis: {
      //     type: 'category',
      //     data: this.dataName
      //   },
      //   yAxis: {},
      //   series: [
      //     {
      //       name: '排名',
      //       type: 'line',
      //       data: this.data,
      //       itemStyle: {
      //         // 设置标记样式
      //         normal: {
      //           // 设置柱子样式
      //           color: '#00C9C9',
      //           borderWidth: 2,
      //           opacity: 1,
      //           shadowColor: 'rgba(0, 0, 0, 0.5)', // 将阴影颜色设为半透明黑色
      //           shadowBlur: 4 // 将阴影模糊程度设为10px
      //         }
      //       },
      //       lineStyle: {
      //         normal: {
      //           width: 3 // 将折线宽度设置为5px
      //         }
      //       },
      //       symbolSize: 12 // 将标记大小设为10px
      //     }
      //   ]
      // }
      this.option = {
        title: {
          text: '约课排行',
          subtext: `${this.start.slice(0, 10)} - ${this.end.slice(0, 10)}`,
          // '本月数据'
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: this.dataName
        },
        yAxis: {
          type: 'value'
        },
        graphic: {
          elements: [
            {
              type: 'text',
              left: 50,
              top: 20,
              style: {
                text: `会员人数：${this.count}${' '.repeat(6)}总积分：${this.integralSum}`,
                fontSize: 14
              }
            },
            {
              type: 'text',
              left: 'center',
              top: 'middle',
              style: {
                fill: '#999',
                text: '暂无数据',
                font: '14px Arial',
                opacity: this.data.length === 0 ? 1 : 0
              }
            }
          ]
        },
        series: [
          {
            name: '排行',
            type: 'bar',
            showBackground: true,
            data: this.data,
            barWidth: 30, // 设置柱子宽度为20像素
            itemStyle: {
              // 设置柱子样式
              color: '#3fa1ff'
            }
          }
        ]
      }

      const myChart = this.$echarts.init(document.getElementById('pointsRanking'))
      myChart.setOption(this.option)
    },
    // 点击正序或者倒序
    handleTime(val) {
      if (val === 1) {
        this.dropdownName = '正序'
        this.dropdownId = 'asc'
      }
      if (val === 2) {
        this.dropdownName = '倒序'
        this.dropdownId = 'desc'
      }
      this.getList()
      this.getTable()
    },
    // 监听日期变化
    changeTime() {
      this.start = this.listQuery.create_time[0]
      this.end = this.listQuery.create_time[1]
      this.getList()
      this.getTable()
    },
    handleMemberID(val) {
      this.listQuery.user_id = val
      this.getTable()
    }
  }
}
</script>

<style lang="scss" scoped>
.header_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    .header_title {
      font-size: 14px;
    }
    .header_dropdown {
      margin-left: 70px;
      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      .el-icon-arrow-down {
        font-size: 14px;
      }
    }
  }
  .right {
    display: flex;
    .details {
      font-size: 14px;
      /* margin-left: 10px; */
      display: flex;
      align-items: center;
      color: #00d8d7;
      cursor: pointer;
      i {
        font-size: 18px;
      }
    }
    .screen {
      margin-left: 50px;
      color: #000;
    }
  }
}
.pagination-container {
  margin-top: 10px !important;
}
.app-container .el-table {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.venuesList_empty {
  min-height: 500px;
  img {
    -webkit-user-drag: none;
  }
}
</style>
