import request from '@/utils/request'

// 指定分类分组
export function getSortType(type, pid, data) {
  return request({
    url: `/admin/v1/category?type=${type}${pid !== undefined ? '&parent_id=' + pid : ''}`,
    method: 'get',
    params: data
  })
}

// 获取分类
export function getSorts(data) {
  return request({
    url: `/admin/v1/category`,
    method: 'get',
    params: data
  })
}

// 创建分类
export function addSorts(data) {
  return request({
    url: `/admin/v1/category`,
    method: 'post',
    data
  })
}

// 删除分类
export function delSorts(id, data) {
  return request({
    url: `/admin/v1/category/${id}`,
    method: 'delete',
    data
  })
}

// 获取分类信息
export function getSort(id, data) {
  return request({
    url: `/admin/v1/category/${id}`,
    method: 'get',
    params: data
  })
}

// 更新分类信息
export function editSort(id, data) {
  return request({
    url: `/admin/v1/category/${id}`,
    method: 'put',
    data
  })
}

// 获取模板列表
export function getTemplate() {
  return request({
    url: `/admin/v1/poster_template_config`,
    method: 'get'
  })
}

// 创建海报列表
export function postTemplate(data) {
  return request({
    url: `/admin/v1/poster_template_config`,
    method: 'post',
    data
  })
}

// 获取详情
export function getTemplateDetails(id) {
  return request({
    url: `/admin/v1/poster_template_config/${id}`,
    method: 'get'
  })
}

export function deleteTemplate(id) {
  return request({
    url: `/admin/v1/poster_template_config/${id}`,
    method: 'delete'
  })
}
