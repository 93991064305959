// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "#ccc",
	"menuActiveText": "#f4f4f5",
	"subMenuActiveText": "#f4f4f5",
	"menuActiveBg": "#41c3a8",
	"menuBg": "#233140",
	"menuHover": "#263445",
	"subMenuBg": "#000C17",
	"subMenuHover": "#001528",
	"sideBarWidth": "240px"
};
module.exports = exports;
