<template>
  <div>
    <div class="app-container">
      <div class="filter-container">
        <!-- 条件搜索 -->
        <div class="searchBox">
          <!-- Info 自定义检索方法，不与通用检索同步 -->
          <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
            <el-form-item label="品牌" prop="brandID">
              <Select :type="1" @changeId="handleBrandID" />
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="listQueryParams.create_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item label="付款时间">
              <el-date-picker
                v-model="listQueryParams.pay_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item label="支付状态">
              <el-select v-model="listQueryParams.pay_status" placeholder="请选择" filterable clearable>
                <el-option label="待支付" :value="0" />
                <el-option label="已支付" :value="1" />
              </el-select>
            </el-form-item>
          </el-form>
          <div>
            <el-button
              icon="el-icon-search"
              :loading="searchLoading"
              type="success"
              class="m-b-10"
              @click="handleFilter"
            >
              搜索
            </el-button>
          </div>
        </div>
        <!-- 模糊搜索 -->
        <div class="flex">
          <div style="width: 300px; margin: 10px 0 10px 0;">
            <el-input
              v-model="listQueryParams.query"
              placeholder="搜索订单号 | 支付订单号 | 品牌名称"
              @keyup.enter.native="handleFilter"
            >
              <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
            </el-input>
          </div>
          <div class="total m-y-20 font-16 m-l-10">
            金额统计：
            <span class="text-success p-x-2 font-xs">
              <count-to
                :start-val="0"
                :end-val="tableData.pay_price_count"
                :duration="2000"
                :decimals="2"
                separator=","
                prefix="¥ "
              />
            </span>
          </div>
        </div>

      </div>
      <!-- 表格 -->
      <el-table ref="multipleTable" v-loading="listLoading" stripe :data="tableData.list" row-key="id">
        <el-table-column prop="number" label="订单号" />
        <el-table-column prop="appid" label="品牌">
          <template slot-scope="stop">
            <span>{{ stop.row.app_name ? stop.row.app_name : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="store_user_id" label="员工">
          <template slot-scope="stop">
            <span>{{ stop.row.store_user ? stop.row.store_user.real_name : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付金额">
          <template slot-scope="stop">{{ stop.row.pay_price }} 元</template>
        </el-table-column>
        <el-table-column label="付款状态">
          <template slot-scope="stop">
            <el-tag v-if="stop.row.pay_status === 0" type="danger">待支付</el-tag>
            <el-tag v-if="stop.row.pay_status === 1">已支付</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="pay_time" label="付款时间">
          <template slot-scope="stop">
            <span v-if="stop.row.pay_time === 0" />
            <span v-else>{{ stop.row.pay_time | formatDate('YYYY-MM-DD HH:mm') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付订单号" min-width="120">
          <template slot-scope="stop">
            <span v-if="stop.row.transaction_id">{{ stop.row.transaction_id }}</span>
            <span v-else />
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" />
        <!--<el-table-column prop="" label="操作" min-width="100">
          <template slot-scope="stop">
            <!~~ 核销 ~~>
            <el-popconfirm
              v-if="stop.row.pay_status === 0"
              title="确定核销吗？"
              @confirm="handleVerification(stop.row.id)"
            >
              <el-button slot="reference" class="m-2" type="success">
                核销
              </el-button>
            </el-popconfirm>
            <!~~ 删除 ~~>
            <el-popconfirm title="确定删除吗？" @confirm="handleDelete(stop.row.id)">
              <el-button slot="reference" class="m-2" type="danger">
                删除订单
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>-->
      </el-table>
      <pagination
        v-show="tableData.count > 0"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
// API
import { getPackage, putPackage, deletePackage } from '@/api/admin/activity.js'
// 组件
import Pagination from '@/components/Pagination' // 分页组件
import Select from '@/components/elSelect/Select'

export default {
  components: { Pagination, CountTo, Select },
  data: () => ({
    listLoading: false,
    tableData: {
      list: [],
      count: 0,
      pay_price_count: 0
    },
    plan_id: [],
    timer: ''
  }),
  watch: {},
  created() {
    this.getList()
    this.timer = new Date().getTime()
  },
  mounted: {},
  methods: {
    getList() {
      this.listLoading = true
      this.tableData.list = []

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      const querys = []
      if (this.listQueryParams.query) {
        querys.push({
          field: 'a.number|a.transaction_id|app.name',
          type: 'like',
          key: this.listQueryParams.query
        })
      }
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'a.create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.pay_time) {
        querys.push({ field: 'a.pay_time', type: 'between-time', key: this.listQueryParams.pay_time })
      }
      if (this.listQueryParams.plan_id != null && typeof this.listQueryParams.plan_id === 'number') {
        querys.push({ field: 'a.plan_id', key: this.listQueryParams.plan_id })
      }
      if (this.listQueryParams.pay_status != null && typeof this.listQueryParams.pay_status === 'number') {
        querys.push({ field: 'a.pay_status', key: this.listQueryParams.pay_status })
      }
      if (this.listQueryParams.id) {
        querys.push({ field: 'a.appid', key: this.listQueryParams.id })
      }
      data.query = this.utils.getQueryParams(querys)

      getPackage(data).then(res => {
        this.tableData = res.data
        this.listLoading = false
        this.searchLoading = false
      })
    },
    // 核销
    handleVerification(id) {
      putPackage(id).then(() => {
        this.$message({
          type: 'success',
          message: '核销成功!'
        })
        this.getList()
      })
    },
    // 删除
    handleDelete(id) {
      deletePackage(id).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getList()
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleOrder() {
      this.getList()
    },
    handleBrandID(val) {
      this.listQueryParams.id = val
    }
  }
}
</script>

<style lang="scss" scoped></style>
