import request from '@/utils/request'

// TODO 使用vuex读写authority
// var authority = localStorage.getItem('authority') ? localStorage.getItem('authority') : 'store'

export function login(data) {
  return request({
    url: `/${localStorage.getItem('authority')}/v1/login`,
    method: 'post',
    data
    // headers: {
    // 	'Content-Type': 'application/x-www-form-urlencoded'
    // },
  })
}

export function getInfo(url, token) {
  return request({
    url: `/${localStorage.getItem('authority')}` + url,
    method: 'get',
    params: { token }
  })
}

export function logout() {
  return request({
    url: `/${localStorage.getItem('authority')}/v1/logout`,
    method: 'delete'
  })
}

// 修改密码
export function updatePassword(data) {
  return request({
    url: `/${localStorage.getItem('authority')}/v1/update_password`,
    method: 'put',
    data
  })
}

// 全局删除按钮请求
export function deleteApi(url) {
  return request({
    url: `/${localStorage.getItem('authority')}` + url,
    method: 'delete'
  })
}
