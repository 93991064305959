import request from '@/utils/request'
import Utils from '@/utils/utils.js'

// 工作台

// 获取团课当日课程表
export function getGroupVenues(date, data) {
  return request({
    url: `/store/v1/workbench/group/venues_id/${Utils.getVenues().id}/date/${date}`,
    method: 'get',
    params: data
  })
}

// 获取团课预约记录
export function getVenuesRecord(class_schedule_id, data) {
  return request({
    url: `/store/v1/course/group/class_schedule_id/${class_schedule_id}`,
    method: 'get',
    params: data
  })
}

// 预约前置验证
export function addBookValidate(user_id, data) {
  return request({
    url: `/store/v1/workbench/subscribe_validate/user_id/${user_id}`,
    method: 'post',
    data
  })
}

// 添加预约
export function addBook(user_id, data) {
  return request({
    url: `/store/v1/workbench/subscribe/user_id/${user_id}`,
    method: 'post',
    data
  })
}

// 取消预约
export function cancelBook(id, data) {
  return request({
    url: `/store/v1/workbench/cancel_subscribe/${id}`,
    method: 'put',
    data
  })
}

// 签到
export function signBook(e, data) {
  return request({
    url: `/store/v1/workbench/sign_in/${e.sign_in}/user_id/${e.user_id}`,
    method: 'put',
    data
  })
}

// 取消签到
export function cancelCheck(id, user_id) {
  return request({
    url: `/store/v1/workbench/cancel_sign/${id}/user_id/${user_id}`,
    method: 'put'
  })
}

// 获取预约详情
export function getBookInfo(id, data) {
  return request({
    url: `/store/v1/course/subscribe/${id}`,
    method: 'get',
    params: data
  })
}

/**
 * 获取爽约惩罚列表
 */
export function getPenalty(data) {
  return request({
    url: `/store/v1/break_promise_penalty/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

/**
 * 取消爽约惩罚
 */
export function cancelPenalty(id, data) {
  return request({
    url: `/store/v1/break_promise_penalty/${id}`,
    method: 'put',
    params: data
  })
}

/**
 * 获取私教课当日课程表
 */
export function getPrivateVenues(date, data) {
  return request({
    url: `/store/v1/workbench/private/venues_id/${Utils.getVenues().id}/date/${date}`,
    method: 'get',
    params: data
  })
}

/**
 * 获取私教预约记录
 */
export function getPrivateLog(store_user_id, date, data) {
  return request({
    url: `/store/v1/course/private/store_user_id/${store_user_id}/date/${date}`,
    method: 'get',
    params: data
  })
}
