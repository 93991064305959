// import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const RefreshToken = 'RefreshToken'

export function getToken() {
  return localStorage.getItem(TokenKey)
  // return Cookies.get(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
  // return Cookies.set(TokenKey, token)
}

export function setRefreshToken(token) {
  return localStorage.setItem(RefreshToken, token)
  // return Cookies.set(RefreshToken, token)
}

export function getRefreshToken() {
  return localStorage.getItem(RefreshToken)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
  // return Cookies.remove(TokenKey)
}

// export function getVenues() {
//   return Cookies.get(ToVenues)
// }

// export function setVenues(venues) {
//   return Cookies.set(ToVenues,venues)
// }

// export function removeVenue() {
//   return Cookies.remove(ToVenues)
// }
