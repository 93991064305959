<template>
  <div>
    <el-form ref="ruleForm" :inline="true" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm m-t-20">
      <el-form-item label="sku资源包" prop="skuId">
        <el-select v-model="ruleForm.skuId" clearable placeholder="请选择sku资源包">
          <el-option v-for="(item, index) in SkusData.skuList" :key="index" :label="item.skuName" :value="item.skuId">
            <div class="flex">
              <div>{{ item.skuName }}</div>
              <div class="text-info">-{{ item.finalPrice / 100 }}元</div>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单" prop="orderId">
        <el-select v-model="ruleForm.orderId" clearable placeholder="请选择订单">
          <el-option
            v-for="(item, index) in OrderData.orderList"
            v-show="item.status == 2"
            :key="index"
            :label="item.orderProductInfo.spuName"
            :value="item.orderId"
          >
            <div class="flex">
              <div>{{ item.orderProductInfo.spuName }}</div>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button class="m-l-60" type="primary" :loading="buttonLoading" @click="submitForm('ruleForm')">立即分配</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="tableLoading" class="m-t-20" :data="tableData.list" border stripe style="width: 100%">
      <el-table-column prop="appid" label="品牌ID" align="center" />
      <el-table-column prop="orderId" label="订单号" align="center" />
      <el-table-column prop="receiverAppId" label="小程序ApplD" align="center" />
      <el-table-column prop="skuId" label="sku资源包 " align="center" />
      <el-table-column prop="quota" label="配额 " align="center" />
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="info">未生效</el-tag>
          <el-tag v-if="row.status == 1" type="success">已分配</el-tag>
          <el-tag v-if="row.status == 2" type="warning">已撤回</el-tag>
          <el-tag v-if="row.status == 3" type="danger">失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-popconfirm v-if="row.status == 0 || row.status == 1" title="删除后将无法找回，确定继续吗？" @confirm="handleCancel(row)">
            <el-button slot="reference" type="danger" plain>取消分配</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
// API
import { getSkus, getOrderList, setSku, cancelSku, getSkuList } from '@/api/admin/merchants.js'
// 分页组件
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        count: 0,
        list: []
      },
      SkusData: {
        errcode: 0,
        skuList: []
      },
      OrderData: {
        errcode: 0,
        orderList: [],
        total: 0
      },
      tableLoading: false,
      buttonLoading: false,
      ruleForm: {
        skuId: '',
        orderId: ''
      },
      rules: {
        skuId: [{ required: true, message: '请选择sku资源包', trigger: 'change' }],
        orderId: [{ required: true, message: '请选择订单', trigger: 'change' }]
      }
    }
  },
  created() {},
  mounted() {
    getSkus().then(res => {
      this.SkusData = res.data
    })
    getOrderList().then(res => {
      this.OrderData = res.data
    })
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true
      getSkuList(this.$route.params.id).then(res => {
        this.tableData = res.data
        this.tableLoading = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          setSku(this.$route.params.id, this.ruleForm)
            .then(res => {
              this.$message({
                message: '创建成功',
                type: 'success'
              })
              this.getList()
            })
            .finally(() => {
              this.$refs[formName].resetFields()
              this.buttonLoading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCancel(row) {
      cancelSku(row.id).then(res => {
        this.$message({
          message: '取消成功',
          type: 'success'
        })
        this.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
