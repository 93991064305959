<template>
  <div v-loading="loading" class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <!-- Info 自定义检索方法，不与通用检索同步 -->
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <van-form label-width="80px" class="window_phone">
            <!-- 创建时间 -->
            <van-field readonly clickable name="calendar" :value="vanCreationTime" label="创建时间：" placeholder="点击选择创建时间" @click="vanCreationTimeShow = true" />
            <van-calendar
              v-model="vanCreationTimeShow"
              type="range"
              :min-date="minVanCreationTime"
              :max-date="maxVanCreationTime"
              color="#1989fa"
              confirm-text="确 定"
              confirm-disabled-text="请选择结束时间"
              @confirm="onCreation"
            />
            <!-- 到期时间 -->
            <van-field readonly clickable name="calendar" :value="vanExpireTime" label="到期时间：" placeholder="点击选择到期时间" @click="vanExpireTimeShow = true" />
            <van-calendar
              v-model="vanExpireTimeShow"
              type="range"
              :min-date="minVanCreationTime"
              :max-date="maxVanCreationTime"
              color="#1989fa"
              confirm-text="确 定"
              confirm-disabled-text="请选择结束时间"
              @confirm="onExpire"
            />
            <!-- 登录时间 -->
            <van-field readonly clickable name="calendar" :value="vanLoginTime" label="登录时间：" placeholder="点击选择登录时间" @click="vanLoginTimeShow = true" />
            <van-calendar
              v-model="vanLoginTimeShow"
              type="range"
              :min-date="minVanCreationTime"
              :max-date="maxVanCreationTime"
              color="#1989fa"
              confirm-text="确 定"
              confirm-disabled-text="请选择结束时间"
              @confirm="onLogin"
            />
          </van-form>
          <el-form-item label="创建时间" class="window_pc">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-form-item label="到期时间" class="window_pc">
            <el-date-picker
              v-model="listQueryParams.expiration_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-form-item label="登录时间" class="window_pc">
            <el-date-picker
              v-model="listQueryParams.login_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-form-item label="品牌状态">
            <el-select v-model="listQueryParams.is_disable" placeholder="请选择" filterable clearable>
              <el-option v-for="(item, index) in status_list" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="国内短信">
            <el-input v-model="listQueryParams.sms_balance" placeholder="请输入条数" type="number" class="input-with-select">
              <el-select slot="prepend" v-model="listQueryParams.sms_balance_where" placeholder="请选择" style="width:80px">
                <el-option label="小于" value="lt" />
                <el-option label="大于" value="gt" />
                <el-option label="等于" value="=" />
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="国际短信">
            <el-input v-model="listQueryParams.sms_internation_balance" placeholder="请输入条数" type="number" class="input-with-select">
              <el-select slot="prepend" v-model="listQueryParams.sms_internation_balance_where" placeholder="请选择" style="width:80px">
                <el-option label="小于" value="lt" />
                <el-option label="大于" value="gt" />
                <el-option label="等于" value="=" />
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="使用状态">
            <el-select v-model="listQueryParams.status" placeholder="请选择" filterable clearable>
              <el-option v-for="(item, index) in s_status" :key="index" :label="item" :value="index" />
            </el-select>
          </el-form-item>
          <el-form-item label="品牌过期">
            <el-select v-model="listQueryParams.is_expire" placeholder="请选择" filterable clearable>
              <el-option v-for="(item, index) in expire_status" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="客服">
            <el-select v-model="listQueryParams.customer_id" placeholder="请选择" filterable clearable>
              <el-option label="小九" :value="1" />
              <el-option label="兔子" :value="2" />
              <el-option label="嬛嬛" :value="3" />
            </el-select>
          </el-form-item>
          <el-form-item label="套餐版本">
            <el-select v-model="listQueryParams.brand_level" placeholder="请选择" filterable clearable>
              <el-option label="试用版" :value="0" />
              <el-option label="个人版" :value="1" />
              <el-option label="旗舰版" :value="2" />
              <el-option label="定制版" :value="3" />
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
          <el-button class="m-b-10 window_phone" @click="handleReset">重置</el-button>
          <el-button type="primary" class="m-10">品牌数：{{ tableData.count }}</el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索品牌|手机号|标签|来源|IP|地区|编码" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('app_add')" type="primary" icon="el-icon-plus" class="filter-item m-l-10" @click="dialogVisible()">添加</el-button>
      <el-button v-show="btn_access('app_export')" type="warning" icon="el-icon-download" class="filter-item m-l-10" @click="appExport()">导出</el-button>
    </div>
    <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData.list" row-key="id" class="el-table--small" :row-class-name="tableRowClassName">
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="品牌编码：">
              <span>{{ row.encrypt_appid }}</span>
            </el-form-item>
            <el-form-item label="地区：">
              <span>{{ row.location ? row.location : '无' }}</span>
            </el-form-item>
            <el-form-item label="会员数量：">
              <span>{{ row.user_count }} 人</span>
            </el-form-item>
            <el-form-item label="员工数量：">
              <span>{{ row.store_user_count }} 人</span>
            </el-form-item>
            <el-form-item label="短信余额：">
              <el-tag class="m-2">国内:{{ row.sms_balance }}</el-tag>
              <el-tag type="success" class="m-2">国际:{{ row.sms_internation_balance }}</el-tag>
            </el-form-item>
            <el-form-item label="行业">
              <el-tag class="m-2">{{ row.industry_text }}</el-tag>
            </el-form-item>
            <el-form-item label="累计发送短信：">
              <el-tag class="m-2">国内:{{ row.sms_send_count.domestic_count }}</el-tag>
              <el-tag type="success" class="m-2">国际:{{ row.sms_send_count.international_count }}</el-tag>
            </el-form-item>
            <el-form-item label="来源：">
              <div v-if="row.source">
                <el-tag type="success">{{ row.source }}</el-tag>
              </div>
              <div v-else>无</div>
            </el-form-item>
            <el-form-item label="客户标签：">
              <div v-if="row.tags">
                <el-tag v-for="(item, index) in row.tags.split(',')" :key="index" class="m-2" effect="dark">
                  {{ item }}
                </el-tag>
              </div>
              <div v-else>无</div>
            </el-form-item>
            <el-form-item label="上次回访：">
              <span>{{ row.track_time ? row.track_time : '未回访' }}</span>
            </el-form-item>
            <el-form-item label="客户详情：">
              <div v-if="row.content" class="pre-line">{{ row.content }}</div>
              <div v-else>无</div>
            </el-form-item>
            <el-form-item label="进15日活跃次数：">
              <div>{{ row.slog_count }}</div>
            </el-form-item>
            <el-form-item label="最近操作时间：">
              <div>{{ row.last_operation_time ? row.last_operation_time.slice(0, 16) : '无' }}</div>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID" min-width="60px" :render-header="renderId" />
      <el-table-column prop="logo" label="品牌logo" :min-width="equipment ? '120px' : '180px'">
        <template slot-scope="{ row }">
          <div>
            <div v-if="row.logo">
              <el-avatar shape="square" :size="50" :src="row.logo" />
            </div>
            <div class="flex">
              <div :class="{ 'text-danger': row.status == 1, 'text-success': row.status == 2 }">
                {{ row.name }}
              </div>
              <div v-if="row.customized_version.code !== 0" class="m-l-4">
                <el-tooltip class="item" effect="light" :content="row.customized_version.text" placement="right">
                  <el-tag size="mini" class="cursor" @click="toPage(row)">定制版</el-tag>
                </el-tooltip>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="store_user" label="关联账号" :min-width="equipment ? '180px' : '260px'">
        <template slot-scope="{ row }">
          <div class="block line-1" :title="row.real_name">用户名：{{ row.real_name }}</div>
          <div class="block line-1">手机号：{{ row.phone }}</div>
          <div v-if="row.login_time" class="block line-1" :title="row.login_time | formatDate('YYYY-MM-DD')">登录时间：{{ row.login_time | formatDate('YYYY-MM-DD') }}</div>
          <div v-if="row.location_text" class="block" :title="row.location_text + row.login_ip">登录位置：{{ row.location_text }}({{ row.login_ip }})</div>
        </template>
      </el-table-column>
      <el-table-column prop="program_open_path" label="品牌二维码" :min-width="equipment ? '100px' : '120px'">
        <template slot-scope="{ row }">
          <div class="flex ">
            <div v-if="row.program_open_path">
              <el-image style="width: 50px; height: 50px" :src="row.program_open_path" :preview-src-list="[row.program_open_path]" />
            </div>
            <div v-if="row.wechat_open_path" class="m-l-10">
              <el-image style="width: 50px; height: 50px" :src="row.wechat_open_path" :preview-src-list="[row.wechat_open_path]" />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="pay_money_sum" label="付款金额" :min-width="equipment ? '60px' : '80px'">
        <template slot-scope="{ row }">
          <div class="cursor" @click="btn_access('app_pay_log') ? handelPayment(row) : ''">￥{{ row.pay_money_sum }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="可提现余额" :render-header="renderBalanceTime" :min-width="equipment ? '80px' : '100px'">
        <template slot-scope="{ row }">
          <div class="cursor" @click="btn_access('app_money_log') ? handelCapital(row) : ''">￥{{ row.balance }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="expiration_time" label="有效期" width="120" :render-header="renderExpirationTime">
        <template slot-scope="scope">
          <div>
            <el-tag type="info" :title="scope.row.create_time">
              {{ scope.row.create_time | formatDate('YYYY-MM-DD') }}
            </el-tag>
          </div>
          <div class="text-center">至</div>
          <div v-if="scope.row.expiration_time <= 0" class="text-center"><el-tag type="success">永久</el-tag></div>
          <div v-else class="cursor" @click="btn_access('app_set_validity') ? handelValidityPeriod(scope.row) : ''">
            <div v-if="timeRemain(scope.row.expiration_time) <= 90 && timeRemain(scope.row.expiration_time) > 0">
              <el-tag type="warning">{{ scope.row.expiration_time | formatDate('YYYY-MM-DD') }}</el-tag>
            </div>
            <div v-else-if="timeRemain(scope.row.expiration_time) <= 7">
              <el-tag type="danger">{{ scope.row.expiration_time | formatDate('YYYY-MM-DD') }}</el-tag>
            </div>
            <div v-else>
              <el-tag type="success">{{ scope.row.expiration_time | formatDate('YYYY-MM-DD') }}</el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="is_disable" label="状态" :render-header="renderIsDisable" :min-width="equipment ? '60px' : '80px'">
        <template slot-scope="{ row }">
          <el-tag v-if="+row.is_disable === 0" type="success" effect="dark" class="br0_tag cursor" title="点击禁用" @click="appDR(row)">
            启用
          </el-tag>
          <el-tag v-else type="info" effect="dark" class="br0_tag cursor" title="点击启用" @click="appDR(row)">
            禁用
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_disable" label="客服" min-width="60px">
        <template slot-scope="{ row }">
          <div class="cursor" @click="btn_access('app_set_customer') ? handelCustomerService(row) : ''">{{ row.customer_id === 1 ? '小九' : row.customer_id === 2 ? '兔子' : '嬛嬛' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="brand_level" label="版本套餐" min-width="60px">
        <template slot-scope="{ row }">
          <el-tag>{{ row.brand_level == 0 ? '试用版' : row.brand_level == 1 ? '个人版' : row.brand_level == 2 ? '旗舰版' : '定制版' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" :min-width="equipment ? '180' : '260px'">
        <template slot-scope="scope">
          <el-button v-show="btn_access('app_edit')" type="primary" icon="el-icon-edit" class="m-2" @click="dialogVisible(scope.row.id)">
            编辑
          </el-button>
          <el-popconfirm v-show="btn_access('app_del')" title="删除后将无法找回，确定继续吗？" @confirm="app_delete(scope.row.id)">
            <el-button slot="reference" class="m-2" type="danger" icon="el-icon-delete">删除</el-button>
          </el-popconfirm>
          <el-dropdown class="course" :hide-on-click="false">
            <el-button type="info">
              更多
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-show="btn_access('app_refresh')" @click.native="refresh(scope.row.id)">
                <template>
                  <i class="el-icon-refresh" />
                  刷新
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_visit')" @click.native="dialogVisibleV(scope.row.id)">
                <template>
                  <i class="el-icon-notebook-2" />
                  回访
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_login')" :disabled="is_bottom" @click.native="loginStore(scope.row.id)">
                <template>
                  <i class="el-icon-set-up" />
                  登录
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_data')" @click.native="countStore(scope.row)">
                <template>
                  <i class="el-icon-set-up" />
                  数据
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_money_log')" @click.native="handelCapital(scope.row)">
                <template>
                  <i class="el-icon-money" />
                  资金
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_pay_log')" @click.native="handelPayment(scope.row)">
                <template>
                  <i class="el-icon-money" />
                  收款信息
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_operation_log')" @click.native="handelLog(scope.row)">
                <template>
                  <i class="el-icon-s-management" />
                  操作日志
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_set_super')" @click.native="handelSwitch(scope.row)">
                <template>
                  <i class="el-icon-sort" />
                  切换超管
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_set_customer')" @click.native="handelCustomerService(scope.row)">
                <template>
                  <i class="el-icon-sort" />
                  切换客服
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_wechat_config')" @click.native="toPage(scope.row)">
                <template>
                  <div class="flex">
                    <!-- <svg t="1689732380898" style="width: 15px;height: 15px;" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2956" width="16" height="16"><path d="M402.2 734.6c-71.9 0-130.4-54.1-130.4-121 0-20.8 6-41.2 16.9-59.5 16.4-26.8 42.7-46.6 74.4-56 8.4-2.5 14.9-3.5 20.8-3.5 13.9 0 24.8 10.9 24.8 24.8s-10.9 24.8-24.8 24.8c-1 0-3 0-5.5 1-21.3 6-38.2 18.4-47.6 34.7-6.5 10.4-9.4 21.8-9.4 33.7 0 39.2 36.2 71.4 80.4 71.4 15.4 0 30.3-4 43.7-11.4 23.3-13.4 37.2-35.7 37.2-60V405.7c0-42.2 23.3-80.8 62-102.7 20.8-11.9 44.1-17.9 68-17.9 71.9 0 130.4 54.1 130.4 121 0 20.8-6 41.2-16.9 59.5-16.4 26.8-42.7 46.6-74.4 56-8.9 2.5-14.9 3.5-20.8 3.5-13.9 0-24.8-10.9-24.8-24.8s10.9-24.8 24.8-24.8c1 0 3 0 5.5-1 21.3-6.4 38.2-18.9 47.6-34.7 6.4-10.4 9.4-21.8 9.4-33.7 0-39.2-36.2-71.4-80.8-71.4-15.4 0-30.3 4-43.7 11.4-23.3 13.4-37.2 35.7-37.2 60v207.3c0 42.2-23.3 80.9-62 102.7-20.5 12.5-43.8 18.5-67.6 18.5z m504.4-223.2c0-219.2-177.6-396.8-396.8-396.8S113 292.1 113 511.4s177.6 396.8 396.8 396.8 396.8-177.6 396.8-396.8z m49.6 0c0 246.5-199.9 446.4-446.4 446.4-246.5 0-446.4-199.9-446.4-446.4C63.4 264.9 263.3 65 509.8 65c246.5 0 446.4 199.9 446.4 446.4z m0 0" fill="#333333" p-id="2957" /></svg> -->
                    <svg t="1693187126971" style="width: 16px;height: 16px;" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4023" width="16" height="16">
                      <path
                        d="M664.250054 368.541681c10.015098 0 19.892049 0.732687 29.67281 1.795902-26.647917-122.810047-159.358451-214.077703-310.826188-214.077703-169.353083 0-308.085774 114.232694-308.085774 259.274068 0 83.708494 46.165436 152.460344 123.281791 205.78483l-30.80868 91.730191 107.688651-53.455469c38.558178 7.53665 69.459978 15.308661 107.924012 15.308661 9.66308 0 19.230993-0.470721 28.752858-1.225921-6.025227-20.36584-9.521864-41.723264-9.521864-63.862493C402.328693 476.632491 517.908058 368.541681 664.250054 368.541681zM498.62897 285.87389c23.200398 0 38.557154 15.120372 38.557154 38.061874 0 22.846334-15.356756 38.156018-38.557154 38.156018-23.107277 0-46.260603-15.309684-46.260603-38.156018C452.368366 300.994262 475.522716 285.87389 498.62897 285.87389zM283.016307 362.090758c-23.107277 0-46.402843-15.309684-46.402843-38.156018 0-22.941502 23.295566-38.061874 46.402843-38.061874 23.081695 0 38.46301 15.120372 38.46301 38.061874C321.479317 346.782098 306.098002 362.090758 283.016307 362.090758zM945.448458 606.151333c0-121.888048-123.258255-221.236753-261.683954-221.236753-146.57838 0-262.015505 99.348706-262.015505 221.236753 0 122.06508 115.437126 221.200938 262.015505 221.200938 30.66644 0 61.617359-7.609305 92.423993-15.262612l84.513836 45.786813-23.178909-76.17082C899.379213 735.776599 945.448458 674.90216 945.448458 606.151333zM598.803483 567.994292c-15.332197 0-30.807656-15.096836-30.807656-30.501688 0-15.190981 15.47546-30.477129 30.807656-30.477129 23.295566 0 38.558178 15.286148 38.558178 30.477129C637.361661 552.897456 622.099049 567.994292 598.803483 567.994292zM768.25071 567.994292c-15.213493 0-30.594809-15.096836-30.594809-30.501688 0-15.190981 15.381315-30.477129 30.594809-30.477129 23.107277 0 38.558178 15.286148 38.558178 30.477129C806.808888 552.897456 791.357987 567.994292 768.25071 567.994292z"
                        fill="#5D5D5D"
                        p-id="4024"
                      />
                    </svg>
                    <div class="m-l-4">微信配置</div>
                  </div>
                </template>
              </el-dropdown-item>
              <el-dropdown-item v-show="btn_access('app_set_validity')" @click.native="handelValidityPeriod(scope.row)">
                <template>
                  <i class="el-icon-time" />
                  增加有效期
                </template>
              </el-dropdown-item>
              <el-dropdown-item @click.native="handelLimitExport(scope.row.id, scope.row.disable_export)">
                <template>
                  <i class="el-icon-time" />
                  {{ scope.row.disable_export ? '解除导出限制' : '限制导出' }}
                </template>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- <el-popconfirm
            title="刷新品牌信息及二维码，确定继续吗？"
            @confirm="refresh(scope.row.id)"
          >
            <el-button slot="reference" icon="el-icon-refresh" type="warning" class="m-2">刷新</el-button>
          </el-popconfirm>
          <el-button icon="el-icon-notebook-2" class="m-2" @click="dialogVisibleV(scope.row.id)">回访</el-button>
          <el-button icon="el-icon-set-up" class="m-2" type="success" @click="loginStore(scope.row.id)">登录</el-button>
          <el-button icon="el-icon-set-up" class="m-2" type="info" @click="countStore(scope.row)">数据</el-button> -->

          <!-- <delPopover :list-length="tableData.list.length" :api-data="apiData" :page.sync="listQuery.page" :row="scope.row" btn-msg="" @delete="getList" /> -->
          <!-- <el-button :type="scope.row.is_disable ? 'success':'danger'" @click="appDR(scope.row)">{{ scope.row.is_disable ? '设为启用':'设为禁用' }}</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count > 0 && equipment" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <van-pagination
      v-show="tableData.count > 0 && !equipment"
      v-model="currentPage"
      :total-items="tableData.count"
      :show-page-size="4"
      force-ellipses
      class="custom-pagination m-t-20"
      @change="handleCurrentChange"
    />
    <!-- 添加/编辑弹窗 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :before-close="cancelDialog"
      :visible.sync="dialog.visible"
      :title="dialog.id === null ? '添加品牌' : '编辑品牌'"
      :class="equipment ? 'dialog_w580' : 'dialog_auto'"
    >
      <div v-loading="brandLoading">
        <el-form ref="ruleForm" :inline="true" :label-position="equipment ? 'right' : 'top'" :model="form" :rules="rules" size="small" label-width="80px">
          <template v-if="dialog.id === null">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入手机号">
                <div slot="prepend">
                  <Code @area_code="area_code" />
                </div>
              </el-input>
            </el-form-item>
          </template>
          <el-form-item label="品牌名称" prop="name">
            <el-input v-model="form.name" placeholder="品牌名称" :class="equipment ? 'dialog_w100' : 'dialog_w280'" />
          </el-form-item>
          <el-form-item label="行业" prop="industry">
            <el-select v-model="form.industry" :class="equipment ? 'dialog_w100' : 'dialog_w280'" filterable allow-create default-first-option placeholder="请选择文章标签">
              <div slot="prefix">
                <el-tooltip class="item" effect="dark" content="切换行业后场馆默认图片会发生变化，如课程、会员卡封面等请谨慎操作，不影响已发布内容。" placement="bottom">
                  <i class="el-icon-info" />
                </el-tooltip>
              </div>
              <el-option v-for="(item, index) in industry_options" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="地区" prop="location">
            <el-input v-model="form.location" placeholder="所在地区" :class="equipment ? 'dialog_w100' : 'dialog_w280'" maxlength="32" show-word-limit />
          </el-form-item>
          <el-form-item label="来源" prop="source">
            <el-input v-model="form.source" placeholder="来源" :class="equipment ? 'dialog_w100' : 'dialog_w280'" maxlength="32" show-word-limit />
          </el-form-item>

          <el-form-item label="国内短信" prop="sms_balance">
            <el-input v-model="form.sms_balance" placeholder="国内短信" :class="equipment ? 'dialog_w100' : 'dialog_w280'" type="number" />
          </el-form-item>
          <el-form-item label="国际短信" prop="sms_internation_balance">
            <el-input v-model="form.sms_internation_balance" placeholder="国际短信" :class="equipment ? 'dialog_w100' : 'dialog_w280'" type="number" />
          </el-form-item>
          <el-form-item label="到期时间">
            <el-date-picker
              v-if="form.expiration_time !== 0"
              v-model="form.expiration_time"
              :class="equipment ? 'dialog_w80' : 'dialog_w280'"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="equipment ? pickerOptions2 : ''"
              popper-class="dataTimeWithout"
            />
            <el-button v-if="form.expiration_time === 0" class="m-4" type="success">永久</el-button>
            <el-button v-if="form.expiration_time === 0" class="m-4" size="mini" @click="handleChoice">选择日期</el-button>
            <el-button v-if="form.expiration_time !== 0" class="m-l-10" type="success" @click="handlePermanent">永久日期</el-button>
          </el-form-item>
          <el-form-item label="标签" prop="tags">
            <el-select v-model="form.tags" :class="equipment ? 'dialog_w100' : 'dialog_w280'" multiple filterable allow-create default-first-option placeholder="请选择客户标签">
              <el-option v-for="(item, index) in tags_options" :key="index" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="客户信息" prop="content">
            <el-input v-model="form.content" type="textarea" placeholder="请输入内容" maxlength="230" show-word-limit :class="equipment ? 'dialog_w100' : 'dialog_w280'" rows="5" />
          </el-form-item>
          <el-form-item label="使用状态" prop="status">
            <el-radio v-model="form.status" :label="0">未使用</el-radio>
            <el-radio v-model="form.status" :label="1">已使用</el-radio>
            <el-radio v-model="form.status" :label="2">已付款</el-radio>
          </el-form-item>
          <el-form-item label="套餐版本" prop="brand_level">
            <el-radio v-model="form.brand_level" :label="0">试用版</el-radio>
            <el-radio v-model="form.brand_level" :label="1">个人版</el-radio>
            <el-radio v-model="form.brand_level" :label="2">旗舰版</el-radio>
            <el-radio v-model="form.brand_level" :label="3">定制版</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" :loading="handleSubmit" @click="submit">确认</el-button>
        <el-button @click="cancelDialog">取消</el-button>
      </div>
    </el-dialog>

    <!-- 数据统计 -->
    <el-dialog :width="equipment ? '40%' : '100%'" append-to-body :close-on-click-modal="false" :visible.sync="countDialog.visible" :title="`数据统计(${countDialog.title})`">
      <div v-loading="countDialog.loading">
        <div class="m-y-20">
          <el-descriptions title="会员统计" column="5">
            <el-descriptions-item label="今天">{{ countDialog.data.membership.day }}</el-descriptions-item>
            <el-descriptions-item label="本月">{{ countDialog.data.membership.month }}</el-descriptions-item>
            <el-descriptions-item label="本周">{{ countDialog.data.membership.week }}</el-descriptions-item>
            <el-descriptions-item label="年度">{{ countDialog.data.membership.year }}</el-descriptions-item>
            <el-descriptions-item label="总计">{{ countDialog.data.membership.total }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="m-y-20">
          <el-descriptions title="访客统计" column="5">
            <el-descriptions-item label="今天">{{ countDialog.data.visitor.day }}</el-descriptions-item>
            <el-descriptions-item label="本月">{{ countDialog.data.visitor.month }}</el-descriptions-item>
            <el-descriptions-item label="本周">{{ countDialog.data.visitor.week }}</el-descriptions-item>
            <el-descriptions-item label="年度">{{ countDialog.data.visitor.year }}</el-descriptions-item>
            <el-descriptions-item label="总计">{{ countDialog.data.visitor.total }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="m-y-20">
          <el-descriptions title="团课预约记录" column="5">
            <el-descriptions-item label="今天">{{ countDialog.data.group_course_subscribe.day }}</el-descriptions-item>
            <el-descriptions-item label="本月">
              {{ countDialog.data.group_course_subscribe.month }}
            </el-descriptions-item>
            <el-descriptions-item label="本周">{{ countDialog.data.group_course_subscribe.week }}</el-descriptions-item>
            <el-descriptions-item label="年度">{{ countDialog.data.group_course_subscribe.year }}</el-descriptions-item>
            <el-descriptions-item label="总计">
              {{ countDialog.data.group_course_subscribe.total }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="m-y-20">
          <el-descriptions title="精品课预约记录" column="5">
            <el-descriptions-item label="今天">{{ countDialog.data.fine_course_subscribe.day }}</el-descriptions-item>
            <el-descriptions-item label="本月">{{ countDialog.data.fine_course_subscribe.month }}</el-descriptions-item>
            <el-descriptions-item label="本周">{{ countDialog.data.fine_course_subscribe.week }}</el-descriptions-item>
            <el-descriptions-item label="年度">{{ countDialog.data.fine_course_subscribe.year }}</el-descriptions-item>
            <el-descriptions-item label="总计">{{ countDialog.data.fine_course_subscribe.total }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="m-y-20">
          <el-descriptions title="私教课预约记录" column="5">
            <el-descriptions-item label="今天">
              {{ countDialog.data.private_course_subscribe.day }}
            </el-descriptions-item>
            <el-descriptions-item label="本月">
              {{ countDialog.data.private_course_subscribe.month }}
            </el-descriptions-item>
            <el-descriptions-item label="本周">
              {{ countDialog.data.private_course_subscribe.week }}
            </el-descriptions-item>
            <el-descriptions-item label="年度">
              {{ countDialog.data.private_course_subscribe.year }}
            </el-descriptions-item>
            <el-descriptions-item label="总计">
              {{ countDialog.data.private_course_subscribe.total }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="m-y-20">
          <el-descriptions title="用户会员卡统计" column="5">
            <el-descriptions-item label="今天">{{ countDialog.data.user_membership_card.day }}</el-descriptions-item>
            <el-descriptions-item label="本月">{{ countDialog.data.user_membership_card.month }}</el-descriptions-item>
            <el-descriptions-item label="本周">{{ countDialog.data.user_membership_card.week }}</el-descriptions-item>
            <el-descriptions-item label="年度">{{ countDialog.data.user_membership_card.year }}</el-descriptions-item>
            <el-descriptions-item label="总计">{{ countDialog.data.user_membership_card.total }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </el-dialog>

    <!-- 回访 -->
    <VisitorTrack :id="vdialog.trackId" :track-visible.sync="vdialog.trackVisible" @back="getList" />

    <!-- 一键登录窗 -->
    <el-dialog :visible.sync="storeView" width="100%" fullscreen destroy-on-close class="storeViewBox" @close="closestoreViewBox">
      <div v-if="url" v-loading="iframeLoading">
        <iframe ref="storeViews" frameborder="0" width="100%" scrolling="auto" :src="url" @load="sendToken()" />
      </div>
    </el-dialog>
    <span id="message" />

    <!-- 资金 -->
    <capital :id="capitalId" :visible.sync="capitalVisible" @closeDialog="handleCloseDialog" />

    <!-- 收款信息 -->
    <PayRecord :id="paymentId" :visible.sync="paymentVisible" @handleClosePayment="handleClosePayment" />

    <!-- 操作日志 -->
    <operation :id="logId" :visible.sync="logVisible" @closeDialog="handleLog" />

    <!-- 切换超管 -->
    <el-dialog v-loading="account.loading" title="切换超管" :visible.sync="account.dialogVisible" :width="equipment ? '40%' : '100%'">
      <div class="flex flex-wrap">
        <div
          v-for="(item, index) in account.list"
          :key="index"
          :style="{ border: item.id === account.store_user_id ? '2px solid #46a6ff' : '2px solid #ebeef5' }"
          class="borderX flex row-between p-10 cursor"
          :span="12"
          @click="handleAccountId(item.id)"
        >
          <div class="flex">
            <el-avatar shape="square" :src="item.avatar">
              <img :src="errorAvatarSrc(item, 'img')">
            </el-avatar>
            <div class="m-l-10">{{ `${item.real_name}/${item.phone}` }}</div>
          </div>
          <div v-if="item.id === account.store_user_id" style="font-size: 20px;" :style="{ color: item.id === account.store_user_id ? '#46a6ff' : '#ebeef5' }">
            <i class="el-icon-check" />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="account.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
      <pagination v-show="account.count > 10 && equipment" :total="account.count" :page.sync="listQuerySwitch.page" :limit.sync="listQuerySwitch.limit" @pagination="handelSwitchI" />
      <van-pagination
        v-show="account.count > 10 && !equipment"
        v-model="AccountPage"
        :total-items="account.count"
        :show-page-size="4"
        force-ellipses
        class="custom-pagination m-t-20"
        @change="handleAccountChange"
      />
    </el-dialog>

    <!-- 切换客服 -->
    <el-dialog v-loading="CustomerService.loading" title="切换客服" :visible.sync="CustomerService.dialogVisible" :width="equipment ? '40%' : '100%'">
      <div class="flex flex-wrap">
        <div
          v-for="(item, index) in CustomerService.list"
          :key="index"
          :style="{ border: item.id === CustomerService.customer_id ? '2px solid #46a6ff' : '2px solid #ebeef5' }"
          class="borderX flex row-between p-10 cursor"
          :span="12"
          @click="handleCustomerService(item.id)"
        >
          <div class="flex">
            <el-image style="width: 30px; height: 30px" :src="item.qrcode_src" :preview-src-list="[item.qrcode_src]" />
            <div class="m-l-10">{{ `${item.name}/${item.phone}` }}</div>
          </div>
          <div v-if="item.id === CustomerService.customer_id" style="font-size: 20px;" :style="{ color: item.id === CustomerService.customer_id ? '#46a6ff' : '#ebeef5' }">
            <i class="el-icon-check" />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CustomerService.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleCustomerConfirm">确 定</el-button>
      </span>
      <pagination
        v-show="CustomerService.count > 0 && equipment"
        :total="CustomerService.count"
        :page.sync="listQueryService.page"
        :limit.sync="listQueryService.limit"
        @pagination="handelCustomerServiceI"
      />
      <van-pagination
        v-show="CustomerService.count > 10 && !equipment"
        v-model="CustomerPage"
        :total-items="CustomerService.count"
        :show-page-size="4"
        force-ellipses
        class="custom-pagination m-t-20"
        @change="handleCustomerChange"
      />
    </el-dialog>
    <!-- 增加有效期 -->
    <el-dialog title="增加有效期" :visible.sync="validityPeriod.dialogVisible" :close-on-press-escape="false" :close-on-click-modal="false" :width="equipment ? '20%' : '100%'">
      <el-form :model="validityPeriodForm" label-width="80px">
        <el-input v-model="validityPeriodForm.time" placeholder="请输入天数" type="number" class="input-with-select">
          <el-select slot="append" v-model="validityPeriodForm.unit" placeholder="请选择" style="width:80px">
            <el-option label="年" value="year" />
            <el-option label="月" value="month" />
            <el-option label="日" value="day" />
          </el-select>
        </el-input>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="validityPeriod.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleValidityPeriod">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导出 -->
    <Export :success-prompt="successPrompt" :is-judge="is_judge" :animation-display="animation_display" />
  </div>
</template>

<script>
import Moment from 'moment'
import { deepClone } from '@/utils'
import VisitorTrack from './tracking' // 用户回访

import {
  getList,
  addApp,
  editApp,
  appDR,
  getAppDetails,
  refreshApp,
  appDel,
  operateApp,
  industry,
  tags,
  accountList,
  changeSuper,
  serviceList,
  changeService,
  addValidity,
  limitExport,
  removeLimit
} from '@/api/admin/merchants.js'
import { storeLogin } from '@/api/admin/system.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
// import delPopover from '@/components/All/delPopover'
import capital from '@/components/capital/index.vue'
import operation from '@/components/operation/index.vue'
// 组件
import Code from '@/components/code/index.vue'
import PayRecord from '@/components/record/index.vue'
import Export from '@/components/export/index.vue'

export default {
  components: { Pagination, VisitorTrack, capital, operation, Code, PayRecord, Export },
  data() {
    return {
      iframeLoading: false,
      loading: false,
      storeView: false,
      industry_options: [],
      tags_options: [],
      url: '',
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 弹窗
      dialog: {
        visible: false,
        id: null
      },
      vdialog: {
        trackId: 0,
        trackVisible: false
      },
      // 数据弹窗
      countDialog: {
        visible: false,
        id: null,
        title: '品牌数据统计',
        loading: false,
        data: {
          group_course_subscribe: [],
          fine_course_subscribe: [],
          membership: [],
          private_course_subscribe: [],
          user_membership_card: [],
          visitor: []
        }
      },
      // 数据
      form: {
        source: '',
        location: '',
        industry: 10,
        status: 0,
        brand_level: 0,
        phone: '',
        expiration_time: '',
        area_code: '+86',
        sms_balance: 200,
        sms_internation_balance: 200,
        content: '',
        tags: ''
      },
      // 校验
      rules: {
        name: [
          {
            required: true,
            message: '请输入品牌名称',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ],
        // expiration_time: [
        //   {
        //     required: true,
        //     message: '请选择时间',
        //     trigger: 'blur'
        //   }
        // ],
        industry: [
          {
            required: true,
            message: '请选择行业',
            trigger: 'blur'
          }
        ]
      },
      apiData: { url: '/v1/app' },
      status_list: [
        {
          label: '启用',
          value: '0'
        },
        {
          label: '禁用',
          value: '1'
        }
      ],
      expire_status: [
        {
          label: '未过期',
          value: '0'
        },
        {
          label: '已过期',
          value: '1'
        }
      ],
      s_status: ['未使用', '已使用', '已付款'],
      sms_status: [
        {
          label: '已使用',
          value: 1
        },
        {
          label: '未使用',
          value: 0
        }
      ],
      pickerOptions2: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '3天',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(3, 'days')
                  .format('yyyy-MM-DD')
              )
            }
          },
          {
            text: '100天',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(100, 'days')
                  .format('yyyy-MM-DD')
              )
            }
          },
          {
            text: '1周',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(7, 'days')
                  .format('yyyy-MM-DD')
              )
            }
          },
          {
            text: '1月',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(1, 'months')
                  .format('yyyy-MM-DD')
              )
            }
          },
          {
            text: '3月',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(3, 'months')
                  .format('yyyy-MM-DD')
              )
            }
          },
          {
            text: '6月',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(6, 'months')
                  .format('yyyy-MM-DD')
              )
            }
          },
          {
            text: '1年',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(1, 'years')
                  .format('yyyy-MM-DD')
              )
            }
          },
          {
            text: '2年',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(2, 'years')
                  .format('yyyy-MM-DD')
              )
            }
          },
          {
            text: '3年',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(3, 'years')
                  .format('yyyy-MM-DD')
              )
            }
          },
          {
            text: '5年',
            onClick(picker) {
              picker.$emit(
                'pick',
                Moment()
                  .add(5, 'years')
                  .format('yyyy-MM-DD')
              )
            }
          }
        ]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      token: { IframePasswordtoken: '' },
      order_by: 0,
      order_field: '',
      is_id: 0,
      is_time: 0,
      is_disable: 0,
      is_balance: 0,
      capitalId: 0,
      logId: 0,
      capitalVisible: false,
      logVisible: false,
      tableLoading: false,
      is_bottom: false,
      account: {
        dialogVisible: false,
        loading: false,
        count: 0,
        list: [],
        store_user_id: '',
        appid: ''
      },
      CustomerService: {
        dialogVisible: false,
        loading: false,
        count: 0,
        list: [],
        customer_id: '',
        appid: ''
      },
      rowId: '',
      listQuerySwitch: {
        offset: 0,
        page: 1,
        limit: 10
      },
      listQueryService: {
        offset: 0,
        page: 1,
        limit: 10
      },
      paymentVisible: false,
      paymentId: 0,
      equipment: false,
      // 日期格式
      minVanCreationTime: new Date(2000, 0, 1),
      maxVanCreationTime: new Date(2099, 12, 31),
      // 创建时间
      vanCreationTime: '',
      vanCreationTimeShow: false,
      // 到期时间
      vanExpireTime: '',
      vanExpireTimeShow: false,
      // 登录时间
      vanLoginTime: '',
      vanLoginTimeShow: false,
      currentPage: 1,
      AccountPage: 1,
      CustomerPage: 1,
      handleSubmit: false,
      brandLoading: false,
      validityPeriod: {
        id: 0,
        dialogVisible: false
      },
      validityPeriodForm: {
        time: 0,
        unit: 'year'
      },
      successPrompt: false,
      is_judge: 0,
      modifyPointsVisible: false,
      animation_display: true
    }
  },
  mounted() {
    // 获取URL地址
    // window.location.host === 'system.yuxiaojiu.cn'

    if (window.location.host === 'admintest.yuxiaojiu.cn') {
      this.is_bottom = true
    }

    this.getList()
    this.getIndustryList()
    this.getTags()
  },
  created() {
    // 判断页面是否大于768
    if (document.body.clientWidth > 768) {
      this.equipment = true
    } else {
      this.equipment = false
    }
    this.$set(this.listQueryParams, 'sms_balance_where', 'lt')
    this.$set(this.listQueryParams, 'sms_internation_balance_where', 'lt')
    // 返回上一页时删除cookies的值
    this.$cookies.remove('weChat')
    this.$cookies.remove('publicAccount')
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.no_renewal) return 'warning-row'
      else {
        switch (row.status) {
          case 0:
            return ''
          case 1:
            return 'danger-row'
          case 2:
            return 'success-row'
          default:
        }
        return ''
      }
    },
    closestoreViewBox(e) {
      // 销毁iframe
      this.url = ''
      this.$store.dispatch('venues/generalBackendVenue', false)
    },
    // 一键登录
    loginStore(id) {
      this.$store.dispatch('venues/generalBackendVenue', true)
      this.iframeLoading = true
      storeLogin(id).then(res => {
        this.storeView = true
        this.url = process.env.NODE_ENV === 'development' ? 'http://192.168.2.110:8080/login' : 'https://admin.yuxiaojiu.cn/login'
        sessionStorage.setItem('IframePasswordtoken', JSON.stringify(res.data))
        console.log('🚀 ~ storeLogin ~ res.data:', res.data)
        this.$nextTick(function() {
          // 自适应高度
          this.$refs.storeViews.height = document.documentElement.clientHeight
        })
      })
    },
    sendToken() {
      var that = this
      const token = sessionStorage.getItem('IframePasswordtoken')
      this.token.IframePasswordtoken = token

      this.url = process.env.NODE_ENV === 'development' ? 'http://192.168.2.110:8080/login' : 'https://admin.yuxiaojiu.cn/login'
      this.$nextTick(function() {
        this.iframeLoading = false
        that.$refs['storeViews'].contentWindow.postMessage(this.token, '*')
        // window.open(this.url)
        // setTimeout(() => {
        //   this.storeView = false
        // }, 200)
      })
    },
    // 回访弹窗
    dialogVisibleV(id) {
      this.vdialog.trackId = id
      this.vdialog.trackVisible = true
    },
    /**
     * 打开弹窗
     */
    dialogVisible(id) {
      if (id) {
        this.dialog.id = id
        this.brandLoading = true
        getAppDetails(id)
          .then(res => {
            this.form = res.data
            this.form.expiration_time = res.data.expiration_time > 0 ? this.$moment.unix(res.data.expiration_time).format('YYYY-MM-DD') : 0
            this.form.tags = res.data.tags ? res.data.tags.split(',') : []
            this.brandLoading = false
          })
          .catch(() => {})
      } else {
        this.dialog.id = null
        this.form = this.$options.data.call(this).form
      }
      this.dialog.visible = true
    },
    /**
     * 关闭弹窗
     */
    cancelDialog() {
      this.$refs['ruleForm'].resetFields()
      this.dialog.visible = false
    },
    /**
     * 弹窗提交
     */
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.handleSubmit = true
          if (this.dialog.id === null) {
            const data = deepClone(this.form)
            data.area_code = data.area_code === '+86' ? '86' : data.area_code.replace(/[^0-9]/gi, '')
            addApp(data)
              .then(res => {
                this.callback('添加成功')
                this.cancelDialog()
                this.handleSubmit = false
              })
              .catch(() => {})
              .finally(() => {
                this.handleSubmit = false
              })
          } else {
            const data = deepClone(this.form)
            data.tags = data.tags.join(',')
            editApp(this.dialog.id, data)
              .then(res => {
                this.callback('修改成功')
                this.cancelDialog()
                this.handleSubmit = false
              })
              .catch(() => {})
              .finally(() => {
                this.handleSubmit = false
              })
          }
        }
      })
    },
    getQuery() {
      var querys = []
      if (this.listQueryParams.query) {
        querys.push({
          field: 'a.id|a.name|s.phone|s.login_ip|a.location|a.source|a.content|a.tags|a.url',
          type: 'like',
          key: this.listQueryParams.query
        })
      }
      if (this.listQueryParams.is_disable !== '' && this.listQueryParams.is_disable != null) {
        querys.push({ field: 'is_disable', key: this.listQueryParams.is_disable })
      }
      if (this.listQueryParams.sms_balance) {
        querys.push({
          field: 'sms_balance',
          type: this.listQueryParams.sms_balance_where,
          key: this.listQueryParams.sms_balance
        })
      }
      if (this.listQueryParams.sms_internation_balance) {
        querys.push({
          field: 'sms_internation_balance',
          type: this.listQueryParams.sms_internation_balance_where,
          key: this.listQueryParams.sms_internation_balance
        })
      }
      if (this.listQueryParams.status != null && typeof this.listQueryParams.status === 'number') {
        querys.push({ field: 'status', key: this.listQueryParams.status })
      }
      // PC || Phone
      if (document.body.clientWidth > 768) {
        if (this.listQueryParams.create_time) {
          querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
        }
        if (this.listQueryParams.expiration_time) {
          querys.push({ field: 'expiration_time', type: 'between-time', key: this.listQueryParams.expiration_time })
        }
        if (this.listQueryParams.login_time) {
          querys.push({ field: 's.login_time', type: 'between-time', key: this.listQueryParams.login_time })
        }
      } else {
        if (this.vanCreationTime) {
          const vanCreationTime = this.vanCreationTime.split('至')
          querys.push({ field: 'create_time', type: 'between-time', key: vanCreationTime })
        }
        if (this.vanExpireTime) {
          const vanExpireTime = this.vanExpireTime.split('至')
          querys.push({ field: 'expiration_time', type: 'between-time', key: vanExpireTime })
        }
        if (this.vanLoginTime) {
          const vanLoginTime = this.vanLoginTime.split('至')
          querys.push({ field: 's.login_time', type: 'between-time', key: vanLoginTime })
        }
      }
      if (this.listQueryParams.is_expire != null && this.listQueryParams.is_expire !== '') {
        const tt = this.listQueryParams.is_expire === '1' ? 'lt' : 'gt'
        const tts = this.listQueryParams.is_expire === '1' ? 'gt' : ''
        // console.log('🚀 ~ file: index.vue:1025 ~ getList ~ this.listQueryParams.is_expire:', this.listQueryParams.is_expire, tt)
        querys.push({ field: 'expiration_time', type: tt, key: this.$moment().unix() })
        tts && querys.push({ field: 'expiration_time', type: tts, key: 0 })
      }
      if (this.listQueryParams.customer_id) {
        querys.push({ field: 'customer_id', key: this.listQueryParams.customer_id })
      }
      if (this.listQueryParams.brand_level != null && this.listQueryParams.brand_level !== '') {
        querys.push({ field: 'brand_level', key: this.listQueryParams.brand_level })
      }
      return querys
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data['order_field'] = this.order_field
      data['order_by'] = this.order_by === 1 ? 'asc' : this.order_by === 2 ? 'desc' : ''

      const querys = this.getQuery()

      data.query = this.utils.getQueryParams(querys)

      this.tableLoading = true
      getList(data)
        .then(res => {
          this.tableData = res.data
          this.tableLoading = false
        })
        .catch(() => {})
        .finally(() => {
          this.searchLoading = false
          this.tableLoading = false
        })
    },
    // 启用\禁用商户
    appDR(row) {
      const status_id = row.is_disable === 0 ? 1 : 0
      appDR(row.id, status_id)
        .then(res => {
          this.callback(row.is_disable ? '已启用' : '已禁用')
        })
        .catch(() => {})
    },
    // 回调
    callback(msg) {
      this.$message.success(msg)
      this.getList()
    },
    timeRemain(v) {
      const e = this.$moment.unix(v).format('yyyy-MM-DD') // 开始时间
      const s = this.$moment().format('yyyy-MM-DD') // 结束时间
      return this.$moment(e).diff(s, 'days')
    },
    // 刷新品牌
    refresh(id) {
      this.$confirm('刷新品牌信息及二维码，确定继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        refreshApp(id).then(res => {
          this.callback('刷新成功')
        })
      })
    },
    // 导出
    async appExport() {
      const _this = this
      this.is_judge = 0
      this.animation_display = true
      this.successPrompt = true
      this.exportLoading = true
      const data = []
      data['order_field'] = this.order_field
      data['order_by'] = this.order_by === 1 ? 'asc' : this.order_by === 2 ? 'desc' : ''
      const querys = this.getQuery()
      data.query = this.utils.getQueryParams(querys)
      const urlParams = this.utils.converToUrl(data)
      const url = '/admin/v1/appExport' + urlParams
      const progressBar = await this.utils.handleDownload({ url: url, fileName: '品牌列表.xlsx', baseURL: this.baseUrl })

      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.exportLoading = false
          _this.successPrompt = false
        }, 2000)
      }
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 重置
    handleReset() {
      this.listQueryParams = {
        query: '',
        is_disable: '',
        sms_balance: '',
        sms_balance_where: 'lt',
        sms_internation_balance: '',
        sms_internation_balance_where: 'lt',
        status: null,
        create_time: '',
        expiration_time: '',
        login_time: '',
        is_expire: '',
        customer_id: ''
      }
      this.vanCreationTime = ''
      this.vanExpireTime = ''
      this.vanLoginTime = ''
      this.listQuery.page = 1
      this.getList()
    },
    // 品牌删除
    app_delete(id) {
      appDel(id).then(res => {
        this.callback('删除成功')
      })
    },
    countStore(val) {
      this.countDialog.loading = true
      this.countDialog.title = val.name
      this.countDialog.visible = true
      operateApp(val.id)
        .then(res => {
          this.countDialog.data = res.data
        })
        .finally(() => {
          this.countDialog.loading = false
        })
    },
    // 行业列表
    getIndustryList() {
      industry().then(res => {
        var d = []
        Object.keys(res.data).forEach((index, item) => {
          d.push({ value: parseInt(index), label: res.data[index] })
        })
        this.industry_options = d
      })
    },
    getTags() {
      tags().then(res => {
        this.tags_options = res.data
      })
    },
    renderId(h, { column, $index }) {
      return h(
        'div',
        {
          style: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          },
          on: {
            click: this.handleId
          }
        },
        [
          h(
            'div',
            {
              style: {
                marginRight: '5px'
              }
            },
            column.label
          ),
          h('div', {}, [
            h(
              'div',
              {
                style: {
                  marginBottom: '-14px'
                }
              },
              [
                this.is_id === 0 &&
                  h('i', {
                    class: 'el-icon-caret-top'
                  }),
                this.is_id === 1 &&
                  h('i', {
                    class: 'el-icon-caret-top',
                    style: {
                      color: 'rgb(92, 182, 255)'
                    }
                  }),
                this.is_id === 2 &&
                  h('i', {
                    class: 'el-icon-caret-top'
                  })
              ]
            ),
            h(
              'div',
              {
                style: {
                  marginTop: '-14px'
                }
              },
              [
                this.is_id === 0 &&
                  h('i', {
                    class: 'el-icon-caret-bottom'
                  }),
                this.is_id === 1 &&
                  h('i', {
                    class: 'el-icon-caret-bottom'
                  }),
                this.is_id === 2 &&
                  h('i', {
                    class: 'el-icon-caret-bottom',
                    style: {
                      color: 'rgb(92, 182, 255)'
                    }
                  })
              ]
            )
          ])
        ]
      )
    },
    handleId() {
      this.is_time = 0
      this.is_disable = 0
      this.is_balance = 0
      this.is_id += 1
      if (this.is_id === 3) this.is_id = 1
      this.order_by = this.is_id
      this.order_field = ''
      this.order_field = 'id'
      this.getList()
    },
    renderBalanceTime(h, { column, $index }) {
      return h(
        'div',
        {
          style: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          },
          on: {
            click: this.handleBalance
          }
        },
        [
          h(
            'div',
            {
              style: {
                marginRight: '5px'
              }
            },
            column.label
          ),
          h('div', {}, [
            h(
              'div',
              {
                style: {
                  marginBottom: '-14px'
                }
              },
              [
                this.is_balance === 0 &&
                  h('i', {
                    class: 'el-icon-caret-top'
                  }),
                this.is_balance === 1 &&
                  h('i', {
                    class: 'el-icon-caret-top',
                    style: {
                      color: 'rgb(92, 182, 255)'
                    }
                  }),
                this.is_balance === 2 &&
                  h('i', {
                    class: 'el-icon-caret-top'
                  })
              ]
            ),
            h(
              'div',
              {
                style: {
                  marginTop: '-14px'
                }
              },
              [
                this.is_balance === 0 &&
                  h('i', {
                    class: 'el-icon-caret-bottom'
                  }),
                this.is_balance === 1 &&
                  h('i', {
                    class: 'el-icon-caret-bottom'
                  }),
                this.is_balance === 2 &&
                  h('i', {
                    class: 'el-icon-caret-bottom',
                    style: {
                      color: 'rgb(92, 182, 255)'
                    }
                  })
              ]
            )
          ])
        ]
      )
    },
    handleBalance() {
      this.is_id = 0
      this.is_disable = 0
      this.is_time = 0
      this.is_balance += 1
      if (this.is_balance === 3) this.is_balance = 1
      this.order_by = this.is_balance
      this.order_field = ''
      this.order_field = 'balance'
      this.getList()
    },
    renderExpirationTime(h, { column, $index }) {
      return h(
        'div',
        {
          style: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          },
          on: {
            click: this.handleTime
          }
        },
        [
          h(
            'div',
            {
              style: {
                marginRight: '5px'
              }
            },
            column.label
          ),
          h('div', {}, [
            h(
              'div',
              {
                style: {
                  marginBottom: '-14px'
                }
              },
              [
                this.is_time === 0 &&
                  h('i', {
                    class: 'el-icon-caret-top'
                  }),
                this.is_time === 1 &&
                  h('i', {
                    class: 'el-icon-caret-top',
                    style: {
                      color: 'rgb(92, 182, 255)'
                    }
                  }),
                this.is_time === 2 &&
                  h('i', {
                    class: 'el-icon-caret-top'
                  })
              ]
            ),
            h(
              'div',
              {
                style: {
                  marginTop: '-14px'
                }
              },
              [
                this.is_time === 0 &&
                  h('i', {
                    class: 'el-icon-caret-bottom'
                  }),
                this.is_time === 1 &&
                  h('i', {
                    class: 'el-icon-caret-bottom'
                  }),
                this.is_time === 2 &&
                  h('i', {
                    class: 'el-icon-caret-bottom',
                    style: {
                      color: 'rgb(92, 182, 255)'
                    }
                  })
              ]
            )
          ])
        ]
      )
    },
    handleTime() {
      this.is_id = 0
      this.is_disable = 0
      this.is_balance = 0
      this.is_time += 1
      if (this.is_time === 3) this.is_time = 1
      this.order_by = this.is_time
      this.order_field = ''
      this.order_field = 'expiration_time'
      this.getList()
    },
    renderIsDisable(h, { column, $index }) {
      return h(
        'div',
        {
          style: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          },
          on: {
            click: this.handleIsDisable
          }
        },
        [
          h(
            'div',
            {
              style: {
                marginRight: '5px'
              }
            },
            column.label
          ),
          h('div', {}, [
            h(
              'div',
              {
                style: {
                  marginBottom: '-14px'
                }
              },
              [
                this.is_disable === 0 &&
                  h('i', {
                    class: 'el-icon-caret-top'
                  }),
                this.is_disable === 1 &&
                  h('i', {
                    class: 'el-icon-caret-top',
                    style: {
                      color: 'rgb(92, 182, 255)'
                    }
                  }),
                this.is_disable === 2 &&
                  h('i', {
                    class: 'el-icon-caret-top'
                  })
              ]
            ),
            h(
              'div',
              {
                style: {
                  marginTop: '-14px'
                }
              },
              [
                this.is_disable === 0 &&
                  h('i', {
                    class: 'el-icon-caret-bottom'
                  }),
                this.is_disable === 1 &&
                  h('i', {
                    class: 'el-icon-caret-bottom'
                  }),
                this.is_disable === 2 &&
                  h('i', {
                    class: 'el-icon-caret-bottom',
                    style: {
                      color: 'rgb(92, 182, 255)'
                    }
                  })
              ]
            )
          ])
        ]
      )
    },
    handleIsDisable() {
      this.is_id = 0
      this.is_time = 0
      this.is_balance = 0
      this.is_disable += 1
      if (this.is_disable === 3) this.is_disable = 1
      this.order_by = this.is_disable
      this.order_field = ''
      this.order_field = 'is_disable'
      this.getList()
    },
    // 资金
    handelCapital(row) {
      this.capitalVisible = true
      this.capitalId = row.id
    },
    handleCloseDialog() {
      this.capitalVisible = false
    },
    // 收款信息
    handelPayment(row) {
      this.paymentVisible = true
      this.paymentId = row.id
    },
    handleClosePayment() {
      this.paymentVisible = false
    },
    handelLog(row) {
      this.logVisible = true
      this.logId = row.id
    },
    handleLog() {
      this.logVisible = false
    },
    area_code(val) {
      this.form.area_code = val
    },
    // 切换超管
    handelSwitch(row) {
      this.rowId = row.id
      this.account.appid = row.id
      this.handelSwitchI()
    },
    handelSwitchI() {
      this.account.loading = true
      this.account.dialogVisible = true

      const data = JSON.parse(JSON.stringify(this.listQuerySwitch))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      accountList(this.rowId, data).then(res => {
        // is_super
        this.account.list = res.data.list
        this.account.count = res.data.count
        this.account.list.forEach(item => {
          if (item.is_super === 1) {
            this.account.store_user_id = item.id
          }
        })
        this.account.loading = false
      })
    },
    handleConfirm() {
      const data = {
        app_id: this.account.appid,
        store_user_id: this.account.store_user_id
      }
      changeSuper(data).then(() => {
        this.$message({
          type: 'success',
          message: '切换成功'
        })
        this.account.dialogVisible = false
        this.getList()
      })
    },
    handleAccountId(id) {
      this.account.store_user_id = id
    },
    // 切换客服
    handelCustomerService(row) {
      this.CustomerService.appid = row.id
      this.CustomerService.customer_id = row.customer_id
      this.handelCustomerServiceI()
    },
    handelCustomerServiceI() {
      this.CustomerService.dialogVisible = true

      const data = JSON.parse(JSON.stringify(this.listQueryService))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      serviceList(data).then(res => {
        this.CustomerService.list = res.data.list
        this.CustomerService.count = res.data.count
      })
    },
    handleCustomerConfirm() {
      const data = {
        customer_id: this.CustomerService.customer_id
      }
      changeService(this.CustomerService.appid, data).then(() => {
        this.$message({
          type: 'success',
          message: '切换成功'
        })
        this.CustomerService.dialogVisible = false
        this.getList()
      })
    },
    handleCustomerService(id) {
      this.CustomerService.customer_id = id
    },
    // 增加有效期
    handelValidityPeriod(row) {
      this.validityPeriod.id = row.id
      this.validityPeriod.dialogVisible = true
    },
    // 限制/解除限制导出
    handelLimitExport(id, type) {
      this.$confirm(`${type ? '解除品牌导出限制，确定继续吗？' : '品牌限制导出，确定继续吗？'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (type) {
            removeLimit(id).then(res => {
              this.getList()
              this.$message({
                type: 'success',
                message: res.msg
              })
            })
          } else {
            limitExport(id).then(res => {
              this.getList()
              this.$message({
                type: 'success',
                message: res.msg
              })
            })
          }
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // })
        })
    },
    // 确定增加
    handleValidityPeriod() {
      addValidity(this.validityPeriod.id, this.validityPeriodForm).then(() => {
        this.$message({
          type: 'success',
          message: '有效期增加成功'
        })
        this.validityPeriod.dialogVisible = false
        this.getList()
      })
    },
    // 日期格式
    formatDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    // 创建时间
    onCreation(date) {
      const [start, end] = date
      this.vanCreationTimeShow = false
      this.vanCreationTime = `${this.formatDate(start)} 至 ${this.formatDate(end)}`
    },
    // 到期时间
    onExpire(date) {
      const [start, end] = date
      this.vanExpireTimeShow = false
      this.vanExpireTime = `${this.formatDate(start)} 至 ${this.formatDate(end)}`
    },
    // 登录时间
    onLogin(date) {
      const [start, end] = date
      this.vanLoginTimeShow = false
      this.vanLoginTime = `${this.formatDate(start)} 至 ${this.formatDate(end)}`
    },
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
    },
    handleAccountChange(val) {
      this.listQuerySwitch.page = val
      this.handelSwitchI()
    },
    handleCustomerChange(val) {
      this.listQueryService.page = val
      this.handelCustomerServiceI()
    },
    toPage(row) {
      this.$router.push({ name: 'adminWeChatMiniProgram', params: { id: row.id }})
    },
    handlePermanent() {
      this.form.expiration_time = 0
    },
    handleChoice() {
      this.form.expiration_time = ''
    }
  }
}
</script>
<style>
.el-table .info-row {
  background: #95a5a6;
}
.el-table .warning-row {
  background: #fdf5e6;
}
.el-table .danger-row {
  background: #e7d9d9;
}
.el-table .success-row {
  background: #f0f9eb;
}
.custom-pagination .van-pagination__prev,
.custom-pagination .van-pagination__next {
  font-size: 12px; /* 自定义按钮文字大小 */
}
</style>

<style lang="scss" scoped>
.storeViewBox {
  ::v-deep {
    .el-dialog__header {
      background: #ff4949;
      .el-dialog__headerbtn {
        top: 7px !important;
        .el-dialog__close {
          color: #ffffff !important;
        }
      }
    }
    .el-dialog__body {
      padding: unset !important;
    }
  }
}
.el-avatar {
  background: none;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.pre-line {
  white-space: pre-line;
}
.van-cell {
  padding: 10px 0;
  ::v-deep {
    .van-cell__title {
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .window_pc {
    display: none;
  }
  .borderX {
    width: 100%;
    border-radius: 6px;
    margin: 5px 1%;
  }
}
@media screen and (min-width: 768px) {
  .window_phone {
    display: none;
  }

  .borderX {
    width: 48%;
    border-radius: 6px;
    margin: 5px 1%;
  }
}
</style>
