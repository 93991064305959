<template>
  <div class="app-container">
    <!-- 通用搜索 -->
    <div class="filter-container m-t-20">
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索姓名 | 手机号" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('system_user_add')" type="primary" class="filter-item m-l-10" @click="handleAdd">
        新增账号
      </el-button>
    </div>
    <!-- 表格 -->
    <el-table ref="multipleTable" v-loading="TableLoading" :data="tableData.list" row-key="id" header-align="center">
      <el-table-column label="姓名" prop="real_name" />
      <el-table-column label="手机号" prop="phone" />
      <el-table-column label="最后登录IP" prop="last_ip" />
      <el-table-column label="最后登录时间" prop="last_time" />
      <el-table-column label="角色" prop="phone">
        <template slot-scope="{ row }">
          <div v-if="row.role.length === 0">暂无角色</div>
          <div v-else>
            <el-tag v-for="item in row.role" :key="item.id" class="m-x-4">{{ item.name }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button v-show="btn_access('system_user_edit')" class="m-2" type="primary" @click="handleEdit(row.id)">
            编辑账号
          </el-button>
          <el-button v-show="btn_access('system_user_del')" class="m-2" type="danger" @click="handleDelete(row.id)">
            删除账号
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <accountDialog
      :dialog-visible-account="dialogVisibleAccount"
      :type="type"
      :type-id="typeId"
      @accountVisible="accountVisible"
    />
  </div>
</template>

<script>
// API
import { getAccountList, deleteAccount } from '@/api/admin/account.js'
// 组件
import Pagination from '@/components/Pagination'
import accountDialog from './components/accountDialog.vue'

export default {
  components: { Pagination, accountDialog },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      TableLoading: false,
      dialogVisibleAccount: false,
      type: '',
      typeId: 0
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.TableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'real_name | phone', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      getAccountList(data)
        .then(res => {
          const { data } = res
          this.tableData = data
        })
        .finally(() => {
          this.TableLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 新增账号
    handleAdd() {
      this.type = 'add'
      this.dialogVisibleAccount = true
    },
    // 编辑账号
    handleEdit(id) {
      this.type = 'edit'
      this.typeId = id
      this.dialogVisibleAccount = true
    },
    // 删除账号
    handleDelete(id) {
      this.$confirm('此操作将永久删除该账号，且将无法登录总后台, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAccount(id).then(() => {
          this.getList()
          this.$message.success('删除成功!')
        })
      })
    },
    accountVisible() {
      this.dialogVisibleAccount = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
