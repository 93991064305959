var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.name == "s_venues_employee"
    ? _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "搜索姓名|手机号" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleFilter($event)
                        }
                      },
                      model: {
                        value: _vm.listQueryParams.query,
                        callback: function($$v) {
                          _vm.$set(_vm.listQueryParams, "query", $$v)
                        },
                        expression: "listQueryParams.query"
                      }
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          loading: _vm.searchLoading
                        },
                        on: { click: _vm.handleFilter },
                        slot: "append"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.btn_access("s_venues_employee_add"),
                      expression: "btn_access('s_venues_employee_add')"
                    }
                  ],
                  staticClass: "filter-item m-l-10",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "s_venues_employee_add" })
                    }
                  }
                },
                [_vm._v(" 添加 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.utils.getVenues().id != -1,
                      expression: "utils.getVenues().id != -1"
                    }
                  ],
                  staticClass: "filter-item m-l-10",
                  attrs: { type: "success", icon: "el-icon-sort" },
                  on: {
                    click: function($event) {
                      return _vm.handleSort()
                    }
                  }
                },
                [_vm._v(" 排序 ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "originF" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c("el-tab-pane", {
                    attrs: {
                      label: _vm.id === -1 ? "全部员工" : "馆内员工",
                      name: "first"
                    }
                  }),
                  _vm.id !== -1
                    ? _c("el-tab-pane", {
                        attrs: { label: "全部员工", name: "second" }
                      })
                    : _vm._e(),
                  _c("el-tab-pane", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.btn_access("s_store_user_audit"),
                        expression: "btn_access('s_store_user_audit')"
                      }
                    ],
                    attrs: { label: "待审核", name: "third" }
                  })
                ],
                1
              ),
              _vm.badgeValue > 0
                ? _c(
                    "div",
                    {
                      staticClass: "origin",
                      class: _vm.id !== -1 ? "originN" : "originY"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.badgeValue > 99 ? "99+" : _vm.badgeValue) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.handleLoading,
                  expression: "handleLoading"
                }
              ],
              ref: "multipleTable",
              attrs: { data: _vm.tableData.list, "row-key": "key" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "avatar", label: "员工信息" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm.isEmpty(row)
                            ? _c(
                                "div",
                                { staticClass: "flex col-center cursor" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-avatar",
                                        {
                                          attrs: {
                                            shape: "square",
                                            size: 50,
                                            src: row.avatar
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.errorAvatarSrc(
                                                row,
                                                "img"
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "p-l-10" }, [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.getUserNames(row)))
                                    ]),
                                    row.state === 0 &&
                                    _vm.id !== -1 &&
                                    _vm.activeName === "first"
                                      ? _c(
                                          "span",
                                          { staticClass: "user_part_time1" },
                                          [_vm._v("离 职")]
                                        )
                                      : _vm._e(),
                                    row.state === 1 &&
                                    row.part_time === 1 &&
                                    _vm.id !== -1 &&
                                    _vm.activeName === "first"
                                      ? _c(
                                          "span",
                                          { staticClass: "user_part_time" },
                                          [_vm._v(" 兼 职 ")]
                                        )
                                      : _vm._e(),
                                    _c("br"),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(row.phone ? row.phone : "--")
                                      )
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4196080004
                )
              }),
              _vm.activeName === "second"
                ? _c("el-table-column", {
                    attrs: { prop: "sex", label: "性别" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.formatTableSex(row)) + " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3013825379
                    )
                  })
                : _vm._e(),
              _vm.activeName === "second"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "is_private",
                      label: "教练类型",
                      "min-width": "140"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.is_gold === 1
                                ? _c(
                                    "el-tag",
                                    {
                                      staticClass: "m-r-5 m-b-5",
                                      attrs: { type: "danger" }
                                    },
                                    [_vm._v("金牌教练")]
                                  )
                                : _vm._e(),
                              row.is_group
                                ? _c("el-tag", { staticClass: "m-r-5 m-b-5" }, [
                                    _vm._v("团课教练")
                                  ])
                                : _vm._e(),
                              row.is_private
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("私教课教练")
                                  ])
                                : _vm._e(),
                              !row.is_group && !row.is_private
                                ? _c("span", [_vm._v("无")])
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      458751631
                    )
                  })
                : _vm._e(),
              _vm.activeName === "second"
                ? _c("el-table-column", {
                    attrs: { prop: "star_level", label: "星级" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("el-rate", {
                                attrs: {
                                  value:
                                    row.star_level >= 5
                                      ? 5
                                      : parseInt(row.star_level),
                                  disabled: "",
                                  "text-color": "#ff9900"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2608001533
                    )
                  })
                : _vm._e(),
              _vm.activeName === "first"
                ? _c("el-table-column", {
                    attrs: { label: "教练类型" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.is_private === 1
                                ? _c(
                                    "el-tag",
                                    {
                                      staticClass: "m-2",
                                      attrs: { type: "warning" }
                                    },
                                    [_vm._v("私教")]
                                  )
                                : _vm._e(),
                              row.is_group === 1
                                ? _c("el-tag", { staticClass: "m-2" }, [
                                    _vm._v("团课")
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2031133404
                    )
                  })
                : _vm._e(),
              _vm.activeName === "first"
                ? _c("el-table-column", {
                    attrs: { prop: "role_names", label: "角色" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [_c("div", [_vm._v(_vm._s(row.role_names))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2459947084
                    )
                  })
                : _vm._e(),
              _vm.activeName === "first"
                ? _c("el-table-column", {
                    attrs: { prop: "venues_name", label: "场馆" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [_vm._v(" " + _vm._s(row.venues_name) + " ")]
                          }
                        }
                      ],
                      null,
                      false,
                      3442810457
                    )
                  })
                : _vm._e(),
              _vm.activeName === "third"
                ? _c("el-table-column", {
                    attrs: { prop: "audit_status", label: "审批" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.audit_status === 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("通过审批")
                                  ])
                                : _vm._e(),
                              row.audit_status === 0
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("待审批")
                                  ])
                                : _vm._e(),
                              row.audit_status === 2
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("已拒绝")
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1836379431
                    )
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "60" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.activeName === "first",
                                  expression: "activeName === 'first'"
                                }
                              ],
                              attrs: { type: "primary", icon: "el-icon-info" },
                              on: {
                                click: function($event) {
                                  return _vm.toPage(scope.row)
                                }
                              }
                            },
                            [_vm._v(" 详 情 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.btn_access("s_management_delete") &&
                                    _vm.activeName === "second",
                                  expression:
                                    "btn_access('s_management_delete') && activeName === 'second'"
                                }
                              ],
                              staticClass: "m-2",
                              attrs: {
                                slot: "reference",
                                type: "primary",
                                icon: "el-icon-edit",
                                offset: 2
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "s_venues_employee_edit",
                                    params: { id: scope.row.id }
                                  })
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                          scope.row.is_super !== 1 &&
                          _vm.activeName === "second"
                            ? _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    title: "删除后将无法找回，确定继续吗？"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.store_user_delete(scope.row.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.btn_access(
                                            "s_management_delete"
                                          ),
                                          expression:
                                            "btn_access('s_management_delete')"
                                        }
                                      ],
                                      staticClass: "m-2",
                                      attrs: {
                                        slot: "reference",
                                        type: "danger",
                                        icon: "el-icon-delete",
                                        offset: 2
                                      },
                                      slot: "reference"
                                    },
                                    [_vm._v(" 删除 ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.activeName === "third",
                                  expression: "activeName === 'third'"
                                }
                              ],
                              attrs: { type: "primary", icon: "el-icon-info" },
                              on: {
                                click: function($event) {
                                  return _vm.handleCaoZuo(scope.row)
                                }
                              }
                            },
                            [_vm._v(" 操 作 ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3661429777
                )
              })
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableData.count > 0 && _vm.activeName === "first",
                expression: "tableData.count > 0 && activeName === 'first'"
              }
            ],
            attrs: {
              total: _vm.tableData.count,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: function($event) {
                return _vm.getList("first")
              }
            }
          }),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableData.count > 0 && _vm.activeName === "second",
                expression: "tableData.count > 0 && activeName === 'second'"
              }
            ],
            attrs: {
              total: _vm.tableData.count,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: function($event) {
                return _vm.getList("second")
              }
            }
          }),
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.toExamineLoading,
                  expression: "toExamineLoading"
                }
              ],
              attrs: {
                title: "员工审批",
                visible: _vm.editVisible.display,
                width: "40%",
                "custom-class": "import-dialog"
              },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.editVisible, "display", $event)
                },
                close: _vm.handleClose
              }
            },
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    width: "20%",
                    title: "确定拒绝",
                    visible: _vm.editVisible.displayMin,
                    "append-to-body": ""
                  },
                  on: {
                    "update:visible": function($event) {
                      return _vm.$set(_vm.editVisible, "displayMin", $event)
                    },
                    close: _vm.handleCloseMin
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      "show-word-limit": "",
                      rows: 6,
                      maxlength: "100",
                      placeholder: "拒绝原因[选填]"
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea"
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.editVisible.displayMin = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSubmitMin }
                        },
                        [_vm._v("确 认")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "user_avatar" },
                [
                  _c(
                    "el-avatar",
                    { attrs: { size: 80, src: _vm.editVisible.list.avatar } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.errorAvatarSrc(
                            _vm.editVisible.list.sex,
                            "img"
                          )
                        }
                      })
                    ]
                  ),
                  _c("div", [
                    _vm._v("当前：" + _vm._s(_vm.editVisible.list.real_name))
                  ]),
                  _c("div", [
                    _vm._v(
                      "申请时间：" + _vm._s(_vm.editVisible.list.create_time)
                    )
                  ])
                ],
                1
              ),
              _c("h5", [_vm._v("基本信息")]),
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: {
                    column: 2,
                    border: "",
                    "label-style": { width: "100px" }
                  }
                },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-school" }),
                        _vm._v(" 姓名 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.editVisible.list.real_name) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-school" }),
                        _vm._v(" 手机号 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.editVisible.list.phone) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-school" }),
                        _vm._v(" 性别 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.editVisible.list.sex === 1
                              ? "男"
                              : _vm.editVisible.list.sex === 2
                              ? "女"
                              : "保密"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-school" }),
                        _vm._v(" 生日 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.editVisible.list.birthday) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "员工封面图" } },
                    [
                      [
                        _c("el-image", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            src: _vm.editVisible.list.cover
                              ? _vm.editVisible.list.cover
                              : "https://static.yuxiaojiu.cn/resources/yoga/coach_cover/1.png",
                            fit: "cover",
                            "preview-src-list": [_vm.editVisible.list.cover]
                          }
                        })
                      ]
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "m-t-20",
                  attrs: {
                    "label-position": "left",
                    model: _vm.formData,
                    "label-width": "80px"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "角色权限",
                                prop: "role",
                                "label-width": "100px"
                              }
                            },
                            [
                              _vm.utils.empty(_vm.roleLists)
                                ? _c(
                                    "div",
                                    { staticClass: "tal" },
                                    [
                                      _c("checkRole", {
                                        attrs: {
                                          type: _vm.formData.type,
                                          "parent-selected": _vm.rs,
                                          list: _vm.roleList
                                        },
                                        on: {
                                          "update:type": function($event) {
                                            return _vm.$set(
                                              _vm.formData,
                                              "type",
                                              $event
                                            )
                                          },
                                          "update:parentSelected": function(
                                            $event
                                          ) {
                                            _vm.rs = $event
                                          },
                                          "update:parent-selected": function(
                                            $event
                                          ) {
                                            _vm.rs = $event
                                          },
                                          "update:list": function($event) {
                                            _vm.roleList = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    _vm._l(_vm.roleLists, function(item) {
                                      return _c(
                                        "el-collapse",
                                        {
                                          key: item.id,
                                          model: {
                                            value: _vm.activeNames,
                                            callback: function($$v) {
                                              _vm.activeNames = $$v
                                            },
                                            expression: "activeNames"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-collapse-item",
                                            {
                                              attrs: {
                                                title: item.name,
                                                name: item.id
                                              }
                                            },
                                            [
                                              _c("checkRole", {
                                                attrs: {
                                                  type: _vm.form.type,
                                                  "parent-selected":
                                                    _vm.rs[item.id],
                                                  list: item.role
                                                },
                                                on: {
                                                  "update:type": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.form,
                                                      "type",
                                                      $event
                                                    )
                                                  },
                                                  "update:parentSelected": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.rs,
                                                      item.id,
                                                      $event
                                                    )
                                                  },
                                                  "update:parent-selected": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.rs,
                                                      item.id,
                                                      $event
                                                    )
                                                  },
                                                  "update:list": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      item,
                                                      "role",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "兼职老师" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: _vm.formData.part_time,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "part_time", $$v)
                                  },
                                  expression: "formData.part_time"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.formData.part_time === 1,
                                      expression: "formData.part_time === 1"
                                    }
                                  ],
                                  staticClass: "word_text"
                                },
                                [
                                  _vm._v(
                                    "兼职老师无法登录场馆只提供场馆作为临时教练"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { spam: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "教练类型", prop: "guest_buy" } },
                            [
                              _c("el-checkbox", {
                                attrs: {
                                  "true-label": 1,
                                  "false-label": 0,
                                  label: "私教教练"
                                },
                                model: {
                                  value: _vm.formData.is_private,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "is_private", $$v)
                                  },
                                  expression: "formData.is_private"
                                }
                              }),
                              _c("el-checkbox", {
                                attrs: {
                                  "true-label": 1,
                                  "false-label": 0,
                                  label: "团课教练"
                                },
                                model: {
                                  value: _vm.formData.is_group,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "is_group", $$v)
                                  },
                                  expression: "formData.is_group"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { display: "block", "text-align": "center" },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v("通 过")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.handleRefuse }
                    },
                    [_vm._v("拒 绝")]
                  ),
                  _c("el-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v("取 消")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("sortDialog", {
            attrs: { "dialog-sort": _vm.dialogSort },
            on: { sortDialog: _vm.sortDialog }
          })
        ],
        1
      )
    : _c("div", [_c("router-view")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }