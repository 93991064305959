<template>
  <div class="app-container special-center">
    <div class="fr">
      <el-button @click="returnPage()">返回</el-button>
    </div>
    <div style="clear:both;" />
    <router-view :key="key" />
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.box{
    background-color: #ffffff;
    ::v-deep .title{
    border-bottom:1px solid #dfe6ec;
    color: #303133;
    font-weight: 600;
    }
}

</style>
