import request from '@/utils/request'
import Utils from '@/utils/utils.js'
// 课程、访客

// 复制课程表
export function copyCourse(date, data) {
  return request({
    url: `/store/v1/class_schedule/copy/venues_id/${Utils.getVenues().id}/date/${date}`,
    method: 'post',
    data
  })
}
// 清空课程表
export function clearCourse(date, data) {
  return request({
    url: `/store/v1/class_schedule/clear/venues_id/${Utils.getVenues().id}/date/${date}`,
    method: 'delete',
    data
  })
}
// 获取课程列表
export function getCourse(data) {
  return request({
    url: `/store/v1/course/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
// 根据场馆获取课程列表
export function getVenuesCourse() {
  return request({
    url: `/store/v1/venues/${Utils.getVenues().id}/course`,
    method: 'get'
  })
}
// 根据场馆获取课程列表
export function getVenuesCourseId() {
  return request({
    url: `/store/v1/venues/-1/course`,
    method: 'get'
  })
}
// 添加课程
export function addCourse(data) {
  return request({
    url: '/store/v1/course',
    method: 'post',
    data
  })
}
// 删除课程
export function delCourse(id, data) {
  return request({
    url: `/store/v1/course/${id}`,
    method: 'delete',
    data
  })
}
// 获取课程详情
export function getCourseDetails(id) {
  return request({
    url: `/store/v1/course/${id}`,
    method: 'get'
  })
}
// 更改教室详情
export function editCourse(id, data) {
  return request({
    url: `/store/v1/course/${id}`,
    method: 'put',
    data
  })
}
// 获取课程表列表
export function getClassSchedule(data) {
  return request({
    url: `/store/v1/class_schedule/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
// 添加课程表
export function addClass(data) {
  return request({
    url: '/store/v1/class_schedule',
    method: 'post',
    data
  })
}
// 批量添加课程表
export function addBatClass(data) {
  return request({
    url: '/store/v1/class_schedule/bat',
    method: 'post',
    data
  })
}
// 课程表详情
export function getClassInfo(id, data) {
  return request({
    url: `/store/v1/class_schedule/${id}`,
    method: 'get',
    data
  })
}
// 删除课程表
export function delClassInfo(id) {
  return request({
    url: `/store/v1/class_schedule/${id}`,
    method: 'delete'
  })
}
// 更改教室详情
export function editClass(id, data) {
  return request({
    url: `/store/v1/class_schedule/${id}`,
    method: 'put',
    data
  })
}
// 启/停课程表
export function classScheduleStartStop(id, type) {
  return request({
    url: `/store/v1/class_schedule/${id}/${type}`,
    method: 'put'
  })
}
// 取消课程
export function classScheduleCancel(class_schedule_id, force, data) {
  return request({
    url: `/store/v1/class_schedule/${class_schedule_id}/is_cancel/${force}`,
    method: 'delete',
    params: data
  })
}
// 复课
export function classScheduleRecover(class_schedule_id) {
  return request({
    url: `/store/v1/class_schedule/${class_schedule_id}/recover`,
    method: 'put'
  })
}
// 一键签到
export function classScheduleSign(class_schedule_id) {
  return request({
    url: `/store/v1/workbench/sign_ins/${class_schedule_id}`,
    method: 'put'
  })
}
// 获取课程表备注
export function getClassScheduleNotice(data) {
  return request({
    url: `/store/v1/class_schedule/notice`,
    method: 'get',
    params: data
  })
}
// 更新课程表备注
export function editClassScheduleNotice(data) {
  return request({
    url: `/store/v1/class_schedule/notice`,
    method: 'post',
    data
  })
}
/**
 * 课程图片
 */
// 获取课程相册
export function getCurriculum(course_id) {
  return request({
    url: `/store/v2/course/photo/${course_id}`,
    method: 'get'
  })
}
// 删除课程相册
export function deleteCurriculum(id) {
  return request({
    url: `/store/v2/course/photo/${id}`,
    method: 'delete'
  })
}
// 场馆课程排序
export function putCurriculum(id, data) {
  return request({
    url: `/store/v2/course/photo/${id}/sort`,
    method: 'put',
    data
  })
}

/**
 * 课程列表end
 */

// 课时费
export function getClassFee(data) {
  return request({
    url: `/store/v1/class_fee/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 添加课时费
export function addClassFee(data) {
  return request({
    url: `/store/v1/class_fee`,
    method: 'post',
    data
  })
}

// 修改课时费
export function editClassFee(id, data) {
  return request({
    url: `/store/v1/class_fee/${id}`,
    method: 'put',
    data
  })
}

// 删除课时费
export function delClassFee(id, data) {
  return request({
    url: `/store/v1/class_fee/${id}`,
    method: 'delete',
    params: data
  })
}

/**
 * 访客start
 */
// 获取访客列表
export function getVisitor(data) {
  return request({
    url: `/store/v1/visitor/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
// 添加访客
export function addVisitor(data) {
  return request({
    url: '/store/v1/visitor',
    method: 'post',
    data
  })
}
// 更新访客
export function editVisitor(id, data) {
  return request({
    url: `/store/v1/visitor/${id}`,
    method: 'put',
    data
  })
}
// 删除访客
export function delVisitor(id, data) {
  return request({
    url: `/store/v1/visitor/${id}`,
    method: 'delete',
    data
  })
}
// 获取访客跟踪列表
export function getVisitorTrack(data, id) {
  return request({
    url: `/store/v1/visitor_track/visitor_id/${id}`,
    method: 'get',
    params: data
  })
}
// 添加访客跟踪
export function addVisitorTrack(data) {
  return request({
    url: '/store/v1/visitor_track',
    method: 'post',
    data
  })
}
/**
 * 访客end
 */

/**
 * 课程标签
 */

// 获取课程标签列表
export function getCourseLabel(data) {
  return request({
    url: '/store/v1/course_label',
    method: 'get',
    params: data
  })
}

// 修改课程标签
export function editCourseLabel(id, data) {
  return request({
    url: `/store/v1/course_label/${id}`,
    method: 'put',
    params: data
  })
}

// 添加课程标签
export function addCourseLabel(data) {
  return request({
    url: `/store/v1/course_label`,
    method: 'post',
    data
  })
}

// 删除课程标签
export function delCourseLabel(id, data) {
  return request({
    url: `/store/v1/course_label/${id}`,
    method: 'delete',
    params: data
  })
}

// 获取班级列表
export function getDataList(data) {
  return request({
    url: `/store/v1/mclass`,
    method: 'get',
    params: data
  })
}
// 上架下架
export function putShelves(id, num) {
  return request({
    url: `/store/v1/mclass/${id}/status/${num}`,
    method: 'put'
  })
}
// 删除班级
export function deleteMclass(id) {
  return request({
    url: `/store/v1/mclass/${id}`,
    method: 'delete'
  })
}
// 新增班级
export function postAdd(data) {
  return request({
    url: `/store/v1/mclass`,
    method: 'post',
    data
  })
}
// 班级详情
export function getMclass(id) {
  return request({
    url: `/store/v1/mclass/${id}`,
    method: 'get'
  })
}
// 修改班级信息
export function putMclass(id, data) {
  return request({
    url: `/store/v1/mclass/${id}`,
    method: 'put',
    data
  })
}
// 分享链接
export function getShare(id, data) {
  return request({
    url: `/store/v2/mclass/${data}/spread/${id}`,
    method: 'get'
  })
}
// 新增报班
export function postMclassAdd(data) {
  return request({
    url: `/store/v1/mclass/user`,
    method: 'post',
    data
  })
}
// 班课课程列表
export function getCourseList() {
  return request({
    url: `/store/v1/mclass/course`,
    method: 'get'
  })
}
// 班课课程删除
export function deleteCourseDelete(id, data) {
  return request({
    url: `/store/v1/mclass/course/${id}`,
    method: 'delete',
    data
  })
}
// 添加班课课程
export function postCourseAdd(data) {
  return request({
    url: `/store/v1/mclass/course`,
    method: 'post',
    data
  })
}
// 查看班课课程详情
export function getCourseCheck(id) {
  return request({
    url: `/store/v1/mclass/course/${id}`,
    method: 'get'
  })
}
// 修改班课课程详情
export function putCourseEdit(id, data) {
  return request({
    url: `/store/v1/mclass/course/${id}`,
    method: 'put',
    data
  })
}
// 获取班课课程表列表
export function getSchedule(data) {
  return request({
    url: `/store/v1/mclass/schedule`,
    method: 'get',
    params: data
  })
}
// 新增班课课程表
export function postCalssAdd(data) {
  return request({
    url: `/store/v1/mclass/schedule`,
    method: 'post',
    data
  })
}
// 清空班课课程表
export function postClearCourse(date, data) {
  return request({
    url: `/store/v1/mclass/schedule/clear/date/${date}`,
    method: 'post',
    data
  })
}
// 删除班课课程表
export function deleteClearCourse(id) {
  return request({
    url: `/store/v1/mclass/schedule/${id}`,
    method: 'delete'
  })
}
// 课程班课表详情
export function getClearCourse(id) {
  return request({
    url: `/store/v1//mclass/schedule/${id}`,
    method: 'get'
  })
}
// 更改班课教室详情
export function putClearCourse(id, data) {
  return request({
    url: `/store/v1/mclass/schedule/${id}`,
    method: 'put',
    data
  })
}
// 获取班课会员列表
export function getVipList(data) {
  return request({
    url: `/store/v1/mclass/user`,
    method: 'get',
    params: data
  })
}
// 获取班课会员详情
export function getVipCheck(id) {
  return request({
    url: `/store/v1/mclass/user/${id}`,
    method: 'get'
  })
}
// 课程记录
export function getVipRecord(is, id) {
  return request({
    url: `/store/v1/mclass/user/record/${id}/type/${is}`,
    method: 'get'
  })
}
// 班课记录列表
export function getClassRecordList(id) {
  return request({
    url: `/store/v1/mclass/user/class_record/${id}`,
    method: 'get'
  })
}
// 续班
export function postRenewal(id, data) {
  return request({
    url: `/store/v1/mclass/user/renewal/${id}`,
    method: 'post',
    data
  })
}
// 出班
export function postExpend(id, data) {
  return request({
    url: `/store/v1/mclass/user/expend/${id}`,
    method: 'post',
    data
  })
}
// 转班
export function postShift(id, data) {
  return request({
    url: `/store/v1/mclass/user/shift/${id}`,
    method: 'post',
    data
  })
}
// 分课
export function postSplit(id, data) {
  return request({
    url: `/store/v1/mclass/user/split/${id}`,
    method: 'post',
    data
  })
}
// 调整课时
export function postRegulate(id, data) {
  return request({
    url: `/store/v1/mclass/user/regulate/${id}`,
    method: 'post',
    data
  })
}
// 获取班课数据统计
export function getMclassData(type, data) {
  return request({
    url: `/store/v1/mclass/data/${type}`,
    method: 'get',
    params: data
  })
}
// 获取班课班级的销售记录
export function getGradeId(params, id) {
  return request({
    url: `/store/v1/mclass/data/1/grade_id/${id}`,
    method: 'get',
    params
  })
}
// 课程记录
export function getRecord(type, id, data) {
  return request({
    url: `/store/v1/mclass/schedule/record/${type}/grade_id/${id}`,
    method: 'get',
    params: data
  })
}
// 课程记录详情
export function getMclassBench(id, is_log) {
  return request({
    url: `/store/v1/mclass/bench/class_info/class_schedule_id/${id}`,
    method: 'get',
    params: is_log
  })
}
export function getMclassList(id, is_log) {
  return request({
    url: `/store/v1/mclass/bench/class_schedule_id/${id}`,
    method: 'get',
    params: is_log
  })
}
export function postMclassList(type, ids, id) {
  return request({
    url: `/store/v1/mclass/bench/c_operation/mclass_user_id/${id}/class_schedule_id/${ids}/type/${type}`,
    method: 'post'
  })
}
export function oneClickCheckIn(id) {
  return request({
    url: `/store/v1/mclass/bench/sign_all/class_schedule_id/${id}`,
    method: 'post'
  })
}
// 班课记录详情
export function getMclassBenchDate(time, data) {
  return request({
    url: `/store/v1/mclass/bench/date/${time}`,
    method: 'get',
    params: data
  })
}
// 获取精彩瞬间
export function getImgs(id) {
  return request({
    url: `/store/v1/mclass/bench/photo/class_schedule_id/${id}`,
    method: 'get'
  })
}

// 添加精彩瞬间
export function postImgs(id, data) {
  return request({
    url: `/store/v1/mclass/bench/photo/class_schedule_id/${id}`,
    method: 'post',
    data
  })
}

// 删除精彩瞬间
export function deleteImgs(id) {
  return request({
    url: `/store/v1/mclass/bench/photo/${id}`,
    method: 'delete'
  })
}

// 班级作业
export function getWangEditor(id) {
  return request({
    url: `/store/v1/mclass/bench/task/class_schedule_id/${id}`,
    method: 'get'
  })
}

// 修改作业
export function postWangEditor(id, data) {
  return request({
    url: `/store/v1/mclass/bench/task/class_schedule_id/${id}`,
    method: 'post',
    data
  })
}

// 卡管理统计
export function getCardData(params) {
  return request({
    url: `/store/v1/user_membership_card/change_record/data`,
    method: 'get',
    params
  })
}

