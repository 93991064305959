var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse }
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" }
                },
                [
                  _c(
                    "el-skeleton",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { loading: _vm.loading, animated: "" }
                    },
                    [
                      _c(
                        "template",
                        { slot: "template" },
                        [
                          _c("el-skeleton-item", {
                            staticStyle: {
                              width: "80px",
                              height: "80px",
                              margin: "10px auto",
                              "border-radius": "10px"
                            },
                            attrs: { variant: "image" }
                          }),
                          _c("div", { staticStyle: { padding: "10px" } })
                        ],
                        1
                      ),
                      [
                        _c("img", {
                          staticClass: "sidebar-logo",
                          attrs: {
                            src: _vm.list.logo ? _vm.list.logo : _vm.defaultLogo
                          }
                        })
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            : _c(
                "el-skeleton",
                {
                  staticClass: "sidebar-logo-link",
                  staticStyle: { width: "100%" },
                  attrs: { loading: _vm.loading, animated: "" }
                },
                [
                  _c(
                    "template",
                    { slot: "template" },
                    [
                      _c("el-skeleton-item", {
                        staticStyle: {
                          width: "80px",
                          height: "80px",
                          margin: "10px auto",
                          "border-radius": "10px"
                        },
                        attrs: { variant: "image" }
                      }),
                      _c("div", { staticStyle: { padding: "10px" } })
                    ],
                    1
                  ),
                  [
                    _c("router-link", { key: "expand", attrs: { to: "/" } }, [
                      _c("img", {
                        staticClass: "sidebar-logo",
                        attrs: {
                          src: _vm.list.logo ? _vm.list.logo : _vm.defaultLogo
                        }
                      })
                    ]),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.list.name,
                          placement: "right"
                        }
                      },
                      [
                        _c("span", { staticClass: "sidebar-title" }, [
                          _vm._v(_vm._s(_vm.list.name))
                        ])
                      ]
                    )
                  ]
                ],
                2
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }