import request from '@/utils/request'

// 获取充值订单列表
export function getPackage(data) {
  return request({
    url: '/admin/v1/order/plan',
    method: 'get',
    params: data
  })
}
// 核销充值订单
export function putPackage(id) {
  return request({
    url: `/admin/v1/order/plan/confirm/${id}`,
    method: 'put'
  })
}
// 删除充值订单
export function deletePackage(id) {
  return request({
    url: `/admin/v1/order/plan/${id}`,
    method: 'delete'
  })
}
// 获取短信订单列表
export function getSms(data) {
  return request({
    url: '/admin/v1/order/sms',
    method: 'get',
    params: data
  })
}
// 核销充值订单
export function putSms(id) {
  return request({
    url: `/admin/v1/order/sms/confirm/${id}`,
    method: 'put'
  })
}
// 删除充值订单
export function deleteSms(id) {
  return request({
    url: `/admin/v1/order/sms/${id}`,
    method: 'delete'
  })
}
// 获取品牌套餐列表
export function getPlan(data) {
  return request({
    url: '/admin/v1/plan',
    method: 'get',
    params: data
  })
}
// 获取短信套餐列表
export function getPlanSms(data) {
  return request({
    url: '/admin/v1/sms_plan',
    method: 'get',
    params: data
  })
}
