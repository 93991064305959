<template>
  <div class="special-center">
    <el-row :gutter="20">
      <el-col :span="24" :xs="24">
        <el-card>
          <div slot="header" class="font-xxs">
            场馆信息
          </div>
          <el-form ref="ruleForm" :model="form" label-width="100px" label-position="left" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="场馆名" prop="name">
                  <el-input v-model="form.name" placeholder="请填写场馆名称" maxlength="32" show-word-limit />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="场馆图片" prop="images">
                  <el-upload
                    v-loading="upLoading"
                    class="upload-venues"
                    :action="storeUpload"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :file-list="fileList"
                    list-type="picture-card"
                    :on-success="upSuccess"
                    :headers="utils.upload_headers()"
                    :limit="1"
                    :on-exceed="handleExceed"
                    :on-progress="upProgress"
                    accept=".jpg,.jpeg,.png"
                  >
                    <i class="el-icon-plus" />
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisibleS" class="dialog_view_image">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="负责人" prop="principal">
                  <el-input v-model="form.principal" placeholder="请填写场馆负责人" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号" prop="phone">
                  <el-input v-model="form.phone" type="number" placeholder="请填写负责人手机号" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话" prop="telephone">
                  <el-input v-model="form.telephone" placeholder="请填写场馆联系电话" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item v-if="form.longitude" label="坐标">
                  <el-input :disabled="true" :value="form.longitude + ',' + form.latitude" />
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
          <el-form-item label="地级市" prop="region">
            <el-cascader
              v-model="region"
              :options="areaOptions"
              @change="handleChange"
            />
          </el-form-item>
        </el-col> -->
              <!-- <el-col :span="24">
          <el-form-item label="所在国家">
            <el-input
              v-model="form.country"
              placeholder="请输入详细地址"
            />
          </el-form-item>
        </el-col> -->
              <!-- 省市区 -->
              <!-- <el-col :span="24">
          <el-form-item label="所在地区">
            <div class="flex row-between">
              <div style="width: 30%;">
                <el-input
                  v-model="form.province"
                  placeholder="请输入省"
                />
              </div>
              <div style="width: 30%;">
                <el-input
                  v-model="form.city"
                  placeholder="请输入市"
                />
              </div>
              <div style="width: 30%;">
                <el-input
                  v-model="form.area"
                  placeholder="请输入区"
                />
              </div>
            </div>
          </el-form-item>
        </el-col> -->
              <el-col :span="24">
                <el-form-item label="所在地区">
                  <el-cascader v-model="optionsData" class="elCascaderYxj" :options="options" clearable />
                </el-form-item>
              </el-col>
              <!-- 详细地址 -->
              <el-col :span="24">
                <el-form-item label="详细地址">
                  <el-input
                    v-model="form.address"
                    placeholder="请输入详细地址"
                  />
                  <!-- <el-button type="text" icon="el-icon-add-location" @click.native="mapVisible = !mapVisible">
              拾取位置及坐标
            </el-button> -->
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="地图坐标">
                  <el-button v-if="form.longitude" type="success" icon="el-icon-add-location" @click.native="mapVisible = !mapVisible">
                    已标记
                  </el-button>
                  <el-button v-else type="primary" icon="el-icon-add-location" @click.native="mapVisible = !mapVisible">
                    请选择坐标
                  </el-button>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="场馆介绍" prop="abstract">
                  <el-input v-model="form.abstract" type="textarea" rows="3" placeholder="请填写场馆介绍" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="营业时间" prop="start_time">
                  <el-time-picker
                    v-model="form.start_time"
                    placeholder="开始时间"
                    value-format="HH:mm"
                    format="HH:mm"
                    arrow-control
                  />
                  -
                  <el-time-picker
                    v-model="form.end_time"
                    placeholder="结束时间"
                    value-format="HH:mm"
                    format="HH:mm"
                    arrow-control
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <div class="dayparting_config">
                  <div>
                    <el-checkbox-group v-model="form.business_week">
                      <el-checkbox :label="1" class="m-b-10">周一</el-checkbox>
                      <el-checkbox :label="2" class="m-b-10">周二</el-checkbox>
                      <el-checkbox :label="3" class="m-b-10">周三</el-checkbox>
                      <el-checkbox :label="4" class="m-b-10">周四</el-checkbox>
                      <el-checkbox :label="5" class="m-b-10">周五</el-checkbox>
                      <el-checkbox :label="6" class="m-b-10">周六</el-checkbox>
                      <el-checkbox :label="7" class="m-b-10">周日</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <el-form-item label="门店类型" prop="type">
                  <el-radio-group v-model="form.type" :disabled="!$route.params.id">
                    <el-radio :label="10" :value="10">总店</el-radio>
                    <el-radio :label="20" :value="20">分店</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="场馆设施">
                  <template>
                    <span v-for="item in dataList" :key="item.id" class="elTag">
                      <el-tag>{{ item.device_name }}</el-tag>
                    </span>
                    <div>
                      <el-button type="primary" size="mini" @click="selectLabel">选择设施</el-button>
                    </div>
                  </template>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="场馆标签">
                  <template>
                    <span v-for="(item, index) in form.tags" :key="index" class="elTag">
                      <el-tag>{{ item }}</el-tag>
                    </span>
                    <div>
                      <el-button type="primary" size="mini" @click="handleCustomTags">选择标签</el-button>
                    </div>
                  </template>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="默认门店" prop="default">
                  <el-radio-group v-model="form.default" :disabled="!$route.params.id">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="排序" prop="weigh">
                  <el-input v-model="form.weigh" type="number" placeholder="请输入场馆排序" :disabled="!$route.params.id" />
                  <small class="text-warning">排序规则：从大到小</small>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="footer m-t-20">
            <el-button type="primary" @click="submit">保存</el-button>
            <el-button @click="backPage">取消</el-button>
          </div>
          <MapDialog :map-visible="mapVisible" @mapLocationClose="mapLocationClose" @mapLocationSave="mapLocationSave" />
          <!-- 场馆设施选择 -->
          <labelIndex :dialog-visible="dialogVisible" @close="close" @data="data" @list="list" />
        </el-card>
      </el-col>
      <el-col class="m-t-20" :span="24" :xs="24">
        <album :id="venues_id" :type="type" />
      </el-col>
    </el-row>
    <tagsDialog :type-id="venues_id" :dialog-visible-tags="dialogVisibleTags" :select-tags-list="selectTags" @tagsVisible="handleTagsVisible" />
  </div>
</template>

<script>
import { editVenues, getVenuesInfo, getDeviceList } from '@/api/store/venues.js'
import { getArea } from '@/api/store/area.js'
import MapDialog from '@/components/BaiduMap/index.vue' // 百度地图坐标拾取组件
import areaData from './area_uview.json'
import provinces from './province.js'
import citys from './city.js'
import areas from './area.js'
import { deleteSetShow } from '@/api/store/venues.js'
import options from './areaData.js'
import album from '@/views/profile/components/album.vue'
import tagsDialog from './tagsDialog'

export default {
  components: {
    MapDialog,
    labelIndex: () => import('./label/index.vue'),
    album,
    tagsDialog
  },
  data() {
    return {
      lg_la: '',
      mapVisible: false,
      form: {
        weigh: 1,
        type: 20,
        location: '',
        default: 0,
        tags: [],
        business_week: [],
        country: '',
        province: '',
        city: '',
        area: '',
        address: ''
      },
      rules: ['name', 'type', 'principal', 'phone', 'address', 'longitude', 'start_time'],
      fileList: [],
      areaOptions: [],
      region: [],
      location: [],
      venues_id: '',
      dialogVisible: false,
      dialogVisibleS: false,
      dataList: [],
      dataId: [],
      dataIdList: [],
      options: options,
      optionsData: [],
      dialogImageUrl: '',
      type: 3,
      dialogVisibleTags: false,
      selectTags: []
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
    this.getList()
    getDeviceList().then(res => {
      const data = res.data
      data.list.forEach(item => {
        if (item.is_show === 1) {
          this.dataList.push(item)
        }
      })
    })

    if (this.utils.getVenues().id === -1) {
      this.utils.setPageTitle(this.$route, '编辑场馆', 1)
      this.utils.setTagsViewTitle(this.$route, '编辑场馆')
      this.venues_id = this.$route.params.id
    } else {
      this.venues_id = this.utils.getVenues().id
    }
  },
  mounted() {
    // this.get_area_uview()
    this.areaOptions = areaData
    this.getAdd()
  },
  methods: {
    getAdd() {
      getVenuesInfo(this.venues_id)
        .then(res => {
          this.form = res.data
          this.optionsData = [res.data.country, res.data.province, res.data.city, res.data.area]
          var _that = this
          if (res.data.images) {
            const img_arr = res.data.images.split(',')
            const images_arr = []
            img_arr.forEach(function(item, k) {
              const im1 = []
              im1['name'] = _that.getFileName(item)
              im1['url'] = item
              im1['relativity_url'] = item
              images_arr.push(im1)
            })
            this.fileList = images_arr
          }
          if (res.data.business_week !== 0) {
            const week = res.data.business_week
            res.data.business_week = []
            week.forEach(item => res.data.business_week.push(parseInt(item)))
            // res.data.business_week = [1, 2, 3, 4, 5, 6, 7]
            // arr.forEach(item => {
            //   return parseInt(item)
            // })
          }
        })
        .catch(() => {})
    },
    get_area_uview() {
      var new_provinces = []
      var new_citys = {}
      provinces.forEach((v, i) => {
        citys[i].forEach((v1, i1) => {
          areas[i][i1].map(item => {
            item.id = item.value
            item.value = item.label
          })
          if (this.utils.empty(new_citys[i])) {
            new_citys[i] = [
              {
                label: v1.label,
                value: v1.label,
                id: v1.value,
                children: areas[i][i1]
              }
            ]
          } else {
            new_citys[i].push({
              label: v1.label,
              value: v1.label,
              id: v1.value,
              children: areas[i][i1]
            })
          }
        })
        new_provinces.push({
          label: v.label,
          value: v.label,
          id: v.value,
          children: new_citys[i]
        })
      })
    },
    handleChange(value) {
    },
    mapLocationClose() {
      this.mapVisible = false
    },
    mapLocationSave(e) {
      // this.form.address = e.address
      this.form.longitude = e.longitude
      this.form.latitude = e.latitude
      const separator = ','
      this.lg_la = e.longitude + separator + e.latitude
      this.mapVisible = false
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.form.business_week.length !== 0) {
            this.form.business_week = this.form.business_week.join(',').split(',')
          }
          const data = this.form
          data.business_week
          const images = []
          this.fileList.forEach(function(i, v) {
            images.push(i.relativity_url)
          })
          if (this.optionsData.length > 0) {
            this.form.country = this.optionsData[0]
            this.form.province = this.optionsData[1]
            this.form.city = this.optionsData[2]
            this.form.area = this.optionsData[3]
          }

          // 地区数据解析
          // var str = this.form.address
          // var reg = /.+?(省|市|自治区|自治州|县|区)/g // 省市区的正则
          // const area_list = str.match(reg)

          // var province = ''
          // if (area_list) {
          //   if (area_list[0].indexOf('北京') !== -1) {
          //     province = '北京市'
          //   } else if (area_list[0].indexOf('天津') !== -1) {
          //     province = '天津市'
          //   } else {
          //     province = area_list[0]
          //   }
          //   this.form.province = province
          //   this.form.city = province === '北京市' || province === '天津市' ? '市辖区' : area_list[1]
          //   this.form.area = province === '北京市' || province === '天津市' ? area_list[1] : area_list[2]
          // }

          this.form.images = images.join(',')
          editVenues(this.venues_id, data)
            .then(() => {
              if (this.dataId.length <= 0) {
                deleteSetShow({ ids: this.dataId }).then(() => {
                  this.$message.success('保存成功')
                  this.getAdd()
                  this.backPage()
                })
                return false
              }
              if (this.dataId[0].id) {
                const dataIdList = []
                this.dataId.forEach(item => {
                  dataIdList.push(item.id)
                })
                if (dataIdList.length > 8) {
                  this.$message.error('标签最多不能超过八个')
                  return false
                }
                deleteSetShow({ ids: dataIdList }).then(() => {
                  this.$message.success('保存成功')
                  this.getAdd()
                  this.backPage()
                })
              } else {
                if (this.dataId.length > 8) {
                  this.$message.error('标签最多不能超过八个')
                  return false
                }
                deleteSetShow({ ids: this.dataId }).then(() => {
                  this.$message.success('保存成功')
                  this.getAdd()
                  this.backPage()
                })
              }
            })
            .catch(() => {})
        }
      })
    },
    getAreaList(pid) {
      getArea({ query: JSON.stringify({ pid: pid }), limit: 999 })
        .then(res => {
          this.options = res.data.list
        })
        .catch(() => {})
    },
    // 上传成功事件
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      file.relativity_url = response.data.path
      this.fileList = fileList
      // this.form.src = response.data.path
    },
    upProgress() {
      this.upLoading = true
    },
    // 图片移除事件
    handleRemove(file, fileList) {
      this.fileList = []
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisibleS = true
    },
    // 获取文件名
    getFileName(o) {
      var pos = o.lastIndexOf('/')
      return o.substring(pos + 1)
    },
    handleExceed(files, fileList) {
      this.$message.warning('当前限制选择 1 张图片')
    },
    backPage() {
      this.returnPage(this.utils.getVenues().id === -1 ? { name: 's_venues_list' } : {})
    },
    getList() {
      this.dataList = []
      if (this.dataId.length !== 0 && this.dataIdList.length !== 0) {
        this.dataId.forEach(item => {
          this.dataIdList.forEach(item2 => {
            if (item === item2.id) {
              this.dataList.push(item2)
            }
          })
        })
      } else {
        this.dataList = this.dataId
      }
    },
    selectLabel() {
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.getList()
    },
    data(val) {
      this.dataId = []
      this.dialogVisible = false
      this.dataId = val
    },
    list(val) {
      this.dataIdList = []
      this.dialogVisible = false
      this.dataIdList = val
      this.getList()
    },
    // 选择场馆标签
    handleCustomTags() {
      this.dialogVisibleTags = true
      this.selectTags = this.form.tags
    },
    // 关闭的回调
    handleTagsVisible(val) {
      this.dialogVisibleTags = false
      this.form.tags = val
    }
  }
}
</script>

<style lang="scss" scoped>
.dayparting_config {
  padding-left: 115px;
}
.elTag {
  margin-right: 10px;
}
::v-deep .elCascaderYxj {
  .el-input {
    width: 300px;
  }
}

.footer {
  display: flex;
  justify-content: center;
}
</style>
