<template>
  <div>
    <el-dialog
      id="list"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="visibleLog"
      title="放假记录"
      :before-close="cancelDialog"
    >
      <div class="app-container">
        <div class="filter-container">
          <el-button type="primary" icon="el-icon-plus" class="fr" @click="handelDialogVisible()">放 假</el-button>
        </div>
        <el-table ref="multipleTable" v-loading="loading" :data="tableData.list" row-key="id">
          <!-- <el-table-column prop="id" label="ID" align="center" width="80px" /> -->
          <el-table-column prop="venues.name" label="场馆名" width="80px" align="center" />
          <el-table-column prop="start_time" label="放假时间" min-width="160px" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              {{ scope.row.start_time | formatDate('YYYY-MM-DD HH:mm') }} -
              {{ scope.row.end_time | formatDate('YYYY-MM-DD HH:mm') }}
            </template>
          </el-table-column>
          <el-table-column prop="is_cancel" label="状态" align="center" min-width="180px">
            <template slot-scope="{ row }">
              <div
                :class="{
                  'text-danger': row.status == 1,
                  'text-info': row.status == 0,
                  'text-warning': row.status == 3,
                  'text-success': row.status == 2
                }"
              >
                {{ row.status_text }}
                <p v-if="row.is_cancel">取消时间:{{ row.cancel_time | formatDate('YYYY-MM-DD HH:mm') }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="store_user.real_name" label="操作人" align="center" width="80px" />
          <el-table-column prop="create_time" label="操作时间" min-width="150px" align="center" />
          <el-table-column label="操作" min-width="100px" align="center">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status == 0 || scope.row.status == 1"
                slot="reference"
                type="danger"
                @click="cancelHoliday(scope.row.id)"
              >
                取消放假
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="tableData.count > 0"
          :total="tableData.count"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </div>
    </el-dialog>
    <record :id="id" :visible-log="dialogVisible" @visibleCZ="visibleLogNo" />
  </div>
</template>

<script>
import { holidayLogsVenues, holidayVenuesCancel } from '@/api/store/venues.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Record from './record.vue'
export default {
  name: 'VenuesLogs',
  components: { Pagination, Record },
  props: {
    visibleLog: {
      type: Boolean,
      required: true,
      default: false
    },
    id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10
      },
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 数据
      loading: false,
      dialogVisible: false,
      ids: 0
    }
  },
  watch: {
    visibleLog: function(v, ov) {
      if (v) {
        this.getList()
      }
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    cancelHoliday(id) {
      this.$confirm('场馆放假时间截止当日，将扣减会员卡多余放假天数，确定取消场馆放假吗？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          holidayVenuesCancel(id).then(res => {
            this.$message.success(res.msg)
            this.getList()
          })
        })
        .catch(() => {})
    },
    // 获取列表
    getList() {
      this.loading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      holidayLogsVenues(this.id, data)
        .then(res => {
          this.tableData = res.data
          this.loading = false
        })
        .catch(() => {})
    },
    handelDialogVisible() {
      this.dialogVisible = true
      this.ids = this.id
    },
    cancelDialog() {
      this.$emit('update:visibleLog', false)
      // this.tableData = {
      // 	list:[],
      // 	count: 0,
      // };
    },
    visibleLogNo() {
      this.dialogVisible = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
#list {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  .app-container {
    padding: 10px 20px;
  }
}
.el-button--text {
  color: #000;
}

.fr {
  margin: 0 0 10px 0;
}
</style>
