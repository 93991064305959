import defaultSettings from '@/settings'

const { generalBackendVenue } = defaultSettings

const state = {
  venuesSelect: {},
  generalBackendVenue
}

const mutations = {
  SET_VENUES: (state, value) => {
    state.venuesSelect = value
  },
  GENERAL_BACKEND_VENUE: (state, value) => {
    state.generalBackendVenue = value
  }
}

const actions = {
  // 用户登录
  select({ commit }, Venues) {
    commit('SET_VENUES', Venues)
  },
  generalBackendVenue({ commit }, value) {
    commit('GENERAL_BACKEND_VENUE', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
