import request from '@/utils/request'

/**
 * 获取公告列表
 */
export function getHotCourse(params) {
  return request({
    url: `/store/v1/ranking/hot_course`,
    method: 'get',
    params
  })
}

// 约课排行
export function getAppointment(params) {
  return request({
    url: `/store/v1/ranking/subscribe`,
    method: 'get',
    params
  })
}

// 卡消耗CardConsumption排行
export function getCardConsumption(params) {
  return request({
    url: `/store/v1/ranking/card_consume`,
    method: 'get',
    params
  })
}

// 约课排行
export function getRankingPoints(params) {
  return request({
    url: `/store/v1/ranking/integral`,
    method: 'get',
    params
  })
}
