var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleAccount,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "45%",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleAccount = $event
            },
            open: _vm.handleOpen,
            close: _vm.handleClose
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "title_title" }, [
                _vm._v("活动报名活动详情")
              ]),
              _c(
                "div",
                {
                  staticClass: "title_icon cursor",
                  on: { click: _vm.handleClose }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            ])
          ]),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "flex row-right m-b-20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function($event) {
                          _vm.dialogSignIn = true
                        }
                      }
                    },
                    [_vm._v("签到码")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btn_access("s_offline_event_export"),
                          expression: "btn_access('s_offline_event_export')"
                        }
                      ],
                      staticClass: "filter-item m-l-10",
                      attrs: {
                        type: "danger",
                        icon: "el-icon-download",
                        loading: _vm.exportLoading
                      },
                      on: { click: _vm.Download }
                    },
                    [_vm._v(" 导出人员名单 ")]
                  )
                ],
                1
              ),
              _vm.teamworkData
                ? _c(
                    "el-descriptions",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.descriptionsLoading,
                          expression: "descriptionsLoading"
                        }
                      ],
                      staticClass: "margin-top",
                      attrs: {
                        column: 2,
                        border: "",
                        "label-style": { width: "100px" }
                      }
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { span: 2 } },
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 封面图 ")
                          ]),
                          _vm.teamworkData.cover
                            ? _c("el-image", {
                                staticStyle: {
                                  "min-width": "120px",
                                  height: "120px"
                                },
                                attrs: {
                                  src: _vm.teamworkData.cover,
                                  fit: "fill"
                                }
                              })
                            : _c("div", [_vm._v("暂无封面图")])
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 活动名称 ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.teamworkData.name) + " ")
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 状 态 ")
                          ]),
                          _vm.teamworkData.cancelled === 1
                            ? _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("活动已取消")
                              ])
                            : _vm.teamworkData.cancelled === 0 &&
                              _vm.currentTime <
                                new Date(_vm.teamworkData.sign_up_start_time)
                            ? _c("el-tag", { attrs: { type: "warning" } }, [
                                _vm._v(" 未开始 ")
                              ])
                            : _vm.teamworkData.cancelled === 0 &&
                              new Date(_vm.teamworkData.sign_up_start_time) <=
                                _vm.currentTime &&
                              _vm.currentTime <=
                                new Date(_vm.teamworkData.sign_up_end_time)
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v(" 报名中 ")
                              ])
                            : _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("已截至报名时间")
                              ])
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 活动时间 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatTime(
                                  _vm.teamworkData.event_start_time
                                )
                              ) +
                              " - " +
                              _vm._s(
                                _vm.formatTime(_vm.teamworkData.event_end_time)
                              ) +
                              " "
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 活动报名时间 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatTime(
                                  _vm.teamworkData.sign_up_start_time
                                )
                              ) +
                              " - " +
                              _vm._s(
                                _vm.formatTime(
                                  _vm.teamworkData.sign_up_end_time
                                )
                              ) +
                              " "
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 活动报名费 ")
                          ]),
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type:
                                  _vm.teamworkData.fee === "0.00"
                                    ? "success"
                                    : ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.teamworkData.fee === "0.00"
                                      ? "免费"
                                      : _vm.teamworkData.fee + " 元"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 剩余名额 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.teamworkData.max_member == "0"
                                  ? "不限制"
                                  : _vm.teamworkData.max_member -
                                    _vm.teamworkData.sign_up_count +
                                    " 人") + " "
                              ) +
                              " "
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { span: 2 } },
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 活动地点 ")
                          ]),
                          _vm._v(" " + _vm._s(_vm.teamworkData.location) + " ")
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 报名人 ")
                          ]),
                          _vm.teamworkData.buy_identity
                            ? _c(
                                "div",
                                [
                                  _vm.teamworkData.buy_identity.indexOf(
                                    "member"
                                  ) !== -1
                                    ? _c("el-tag", { staticClass: "m-x-2" }, [
                                        _vm._v("会员")
                                      ])
                                    : _vm._e(),
                                  _vm.teamworkData.buy_identity.indexOf(
                                    "visitor"
                                  ) !== -1
                                    ? _c("el-tag", { staticClass: "m-x-2" }, [
                                        _vm._v("团课")
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 是否推荐 ")
                          ]),
                          _c(
                            "div",
                            [
                              _vm.teamworkData.is_recommend == 1
                                ? _c("el-tag", { staticClass: "m-x-2" }, [
                                    _vm._v("已推荐")
                                  ])
                                : _vm._e(),
                              _vm.teamworkData.is_recommend == 0
                                ? _c(
                                    "el-tag",
                                    {
                                      staticClass: "m-x-2",
                                      attrs: { type: "info" }
                                    },
                                    [_vm._v("未推荐")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { span: 2 } },
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(" 活动内容 ")
                          ]),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.dialogVisibleTask = true
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("p", [
                _c("b", { staticClass: "m-r-20 font-16" }, [
                  _vm._v("报名人员")
                ]),
                _c("b", { staticClass: "font-16" }, [
                  _vm._v(" 已报名 "),
                  _c("span", { staticClass: "text-primary" }, [
                    _vm._v(_vm._s(_vm.teamworkData.sign_up_count))
                  ]),
                  _vm._v(" / 已签到 "),
                  _c("span", { staticClass: "text-success" }, [
                    _vm._v(_vm._s(_vm.teamworkData.sign_in_count))
                  ])
                ])
              ]),
              _c("p", { staticClass: "m-r-20 font-16" }, [
                _c("b", [
                  _vm._v(
                    "报名费用：" + _vm._s(_vm.tableData.total_price) + " 元"
                  )
                ]),
                _c("b", [_vm._v("报名人数：" + _vm._s(_vm.tableData.count))])
              ]),
              _c(
                "div",
                { staticClass: "searchBox" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.listQueryParams,
                        "label-width": "100px",
                        "label-position": "top",
                        "label-suffix": "：",
                        inline: true
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                filterable: "",
                                clearable: ""
                              },
                              model: {
                                value: _vm.listQueryParams.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.listQueryParams, "status", $$v)
                                },
                                expression: "listQueryParams.status"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "未签到", value: "no" }
                              }),
                              _c("el-option", {
                                attrs: { label: "已签到", value: "yes" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "报名时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.dateShortcutChooser
                            },
                            model: {
                              value: _vm.listQueryParams.sign_up_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.listQueryParams,
                                  "sign_up_time",
                                  $$v
                                )
                              },
                              expression: "listQueryParams.sign_up_time"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "m-b-10",
                          attrs: {
                            icon: "el-icon-search",
                            loading: _vm.searchLoading,
                            type: "success"
                          },
                          on: { click: _vm.handleFilter }
                        },
                        [_vm._v(" 搜索 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "filter-item m-b-10" },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "300px" },
                          attrs: {
                            placeholder: "搜索 报名人姓名 | 报名手机号"
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleFilter($event)
                            }
                          },
                          model: {
                            value: _vm.listQueryParams.query,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "query", $$v)
                            },
                            expression: "listQueryParams.query"
                          }
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              loading: _vm.searchLoading
                            },
                            on: { click: _vm.handleFilter },
                            slot: "append"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.TableLoading,
                      expression: "TableLoading"
                    }
                  ],
                  ref: "multipleTable",
                  attrs: {
                    data: _vm.tableData.list,
                    "row-key": "id",
                    "header-align": "center"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "用户信息", width: "155" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            row.user
                              ? _c("div", { staticClass: "flex col-center" }, [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-avatar",
                                        {
                                          attrs: {
                                            shape: "square",
                                            size: 50,
                                            src: row.user.avatar
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.errorAvatarSrc(
                                                row.user,
                                                "img"
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "p-l-10" }, [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.getUserNames(row.user)))
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.user.phone ? row.user.phone : "--"
                                        )
                                      )
                                    ])
                                  ])
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报名信息", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            row.user
                              ? _c("div", { staticClass: "flex col-center" }, [
                                  _c("div", { staticClass: "p-l-10" }, [
                                    _c("div", [
                                      _vm._v("姓名：" + _vm._s(row.name))
                                    ]),
                                    row.sex > 0
                                      ? _c("div", [
                                          _vm._v(
                                            "性别：" +
                                              _vm._s(
                                                row.sex === 1 ? "男" : "女"
                                              )
                                          )
                                        ])
                                      : _vm._e(),
                                    row.phone
                                      ? _c("div", [
                                          _vm._v("手机号：" + _vm._s(row.phone))
                                        ])
                                      : _vm._e(),
                                    row.wechat
                                      ? _c("div", [
                                          _vm._v(
                                            "微信号：" + _vm._s(row.wechat)
                                          )
                                        ])
                                      : _vm._e(),
                                    row.sign_in_time
                                      ? _c("div", [
                                          _vm._v(
                                            "签到时间：" +
                                              _vm._s(
                                                _vm.formatTime(row.sign_in_time)
                                              )
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报名费用", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.pay_price === 0
                                    ? "免费"
                                    : row.pay_price + "元"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "报名时间", width: "140" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [_vm._v(" " + _vm._s(row.sign_up_time) + " ")]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "签到状态", width: "140" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            row.status === 0
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("未签到")
                                ])
                              : _vm._e(),
                            row.status === 1
                              ? _c("el-tag", [_vm._v("已签到（扫码签到）")])
                              : _vm._e(),
                            row.status === 3
                              ? _c("el-tag", [_vm._v("已签到（代签）")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            row.status === 0 &&
                            _vm.btn_access("s_offline_event_sign")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleSignIn(row.id, 0)
                                      }
                                    }
                                  },
                                  [_vm._v("代 签")]
                                )
                              : _vm._e(),
                            row.status > 1
                              ? _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.handleSignIn(row.id, 1)
                                      }
                                    }
                                  },
                                  [_vm._v("取消签到")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tableData.count > 0,
                    expression: "tableData.count > 0"
                  }
                ],
                attrs: {
                  total: _vm.tableData.count,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "活动内容",
            visible: _vm.dialogVisibleTask,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleTask = $event
            }
          }
        },
        [
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.teamworkData.details) }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisibleTask = false
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "签到码",
            visible: _vm.dialogSignIn,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSignIn = $event
            },
            close: _vm.handelClose
          }
        },
        [
          _c("div", [
            _c(
              "div",
              {
                staticClass: "font-20 text-center",
                staticStyle: { "font-weight": "600" }
              },
              [_vm._v(_vm._s(_vm.teamworkData.name))]
            ),
            _c("div", { staticClass: "font-16 text-center m-t-10" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.teamworkData.event_start_time) +
                  " - " +
                  _vm._s(_vm.teamworkData.event_end_time) +
                  " "
              )
            ]),
            _c(
              "div",
              { staticClass: "flex flex-col row-center m-t-30" },
              [
                _c("div", { staticClass: "m-b-10 text-info" }, [
                  _vm._v("请参与人员打开微信，扫码进行签到")
                ]),
                _c("el-image", {
                  staticStyle: { width: "300px", height: "300px" },
                  attrs: { src: _vm.teamworkData.sign_in_code, fit: "fill" }
                })
              ],
              1
            ),
            _c("div", { staticClass: "font-16 text-center m-t-20" }, [
              _vm._v(" 已签到： "),
              _c("span", { staticClass: "text-success" }, [
                _vm._v(_vm._s(_vm.teamworkData.sign_in_count))
              ]),
              _vm._v(" / 未签到： "),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v(
                  _vm._s(
                    _vm.teamworkData.sign_up_count -
                      _vm.teamworkData.sign_in_count
                  )
                )
              ])
            ])
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogSignIn = false
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ]
      ),
      _c("Export", {
        attrs: {
          "success-prompt": _vm.successPrompt,
          "is-judge": _vm.is_judge,
          "animation-display": _vm.animation_display
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }