<template>
  <div>
    <el-dropdown v-if="tableData.count" trigger="click" placement="bottom">
      <el-badge :value="tableData.count" class="item" :max="99">
        <div><i class="el-icon-bell cursor font-18" /></div>
      </el-badge>

      <el-dropdown-menu slot="dropdown">
        <div class="notice_title">
          <div class="m-l-20">通知</div>
        </div>
        <div class="dropdown_box">
          <el-dropdown-item v-for="(item,index) in tableData.list" :key="index" class="dropdown_item">
            <div @click="$router.push({ name: 's_sys_notice_info',params:{id:item.id} })">
              <div class="title line-1">{{ item.title }}</div>
              <div class="desc">{{ utils.escapeHTML(item.content) }}</div>
              <div class="time line-1">{{ item.create_time }}</div>
            </div>
          </el-dropdown-item>
        </div>
        <el-dropdown-item v-if="tableData.list.length==0" disabled class="text-center m-y-10">暂无公告</el-dropdown-item>
        <div class="notice_footer text-center font-14 text-primary cursor" @click="toAll()">
          <div>查看全部公告</div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { getSystemNote } from '@/api/store/common.js'
export default {
  name: 'HeaderNotice',
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getSystemNote({ limit: 5 }).then(res => {
        this.tableData = res.data
      })
    },
    toAll() {
      this.$router.push({ name: 's_sys_notice' })
    }
  }
}
</script>

<style scoped lang="scss">
.notice_title{
  font-size: 16px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
  color: #333;
  padding: 8px 0;
}
.notice_footer{
  padding:8px 0 6px 0;
  border-top: 1px solid #ebeef5;
}
.dropdown_box{
  max-height: 300px;
  overflow:scroll;
}
::v-deep {
  .el-dropdown-menu__item{
    padding:0 12px;
  }
}
.el-dropdown-menu{
  width: 360px;
}
.dropdown_item{
  width: 350px;
  color:#9a9a9a;
  font-weight: 400;
  font-size: 14px;

  .title{
    color:#353535;
    // margin: 2px 0;
  }
  .desc{
    line-height: 1.8;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.notice-all{
  border-top: 1px solid #ebeef5;
}
</style>
