<template>
  <div v-loading="downLoading" class="app-container">
    <div class="filter-container time">
      <img src="@/assets/images/back.png" alt="back" title="上一页" @click="backNext(true)">
      <span v-if="weekList[1]">{{ weekList[1].date }}&nbsp;&nbsp;至&nbsp;&nbsp;{{ weekList[7].date }}</span>
      <img src="@/assets/images/next.png" alt="next" title="下一页" @click="backNext(false)">
      <el-button type="primary" icon="el-icon-plus" @click="add_edit({ data: '批量排课' }, 2)">批量排课</el-button>
      <el-button type="primary" icon="el-icon-copy-document" @click="switchStyle">切换样式</el-button>
      <el-popconfirm title="复制当前课程表到下周吗？" @confirm="copy">
        <el-button v-show="btn_access('s_course_curriculum_copy')" slot="reference" type="danger" icon="el-icon-document-copy" class="m-l-5">
          复制课程表
        </el-button>
      </el-popconfirm>
      <el-popconfirm title="确定清空本周课程表吗？" @confirm="clear_curriculum">
        <el-button v-show="btn_access('s_course_curriculum_clear')" slot="reference" type="info" icon="el-icon-delete" class="m-l-5">
          清空课程表
        </el-button>
      </el-popconfirm>
      <el-button type="warning" class="m-l-5" icon="el-icon-download" :loading="downLoading" @click="toImage">
        分享课程表
      </el-button>
    </div>
    <div id="courseView">
      <div v-show="viewInput.noteShow && viewInput.title" class="bg-primary text-center m-x-2">
        <div style="color:#ffffff;" class="font-lg p-y-10">{{ viewInput.title }}</div>
      </div>
      <table v-loading="loading" class="table">
        <thead>
          <tr>
            <th>时间</th>
            <th v-for="(item, index) in weekList" :key="index" class="week_info" :class="{ disable: checkTime(item.date) }">
              <img v-show="btn_access('s_course_curriculum_add') && !downLoading" src="@/assets/images/syllaubs-plus.png" alt="" @click="add_edit(item, 1)">
              <div>{{ item.week }}</div>
              <div>{{ item.date }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableList" :key="index">
            <td>{{ index }}</td>
            <td v-for="(item1, index1) in item" :key="index1">
              <div v-for="(item2, index2) in item1" :key="index2" class="course" :class="[index2 > 0 ? 'special' : '', chooseStyle ? 'color' : '']" :style="getBg(item2)" @click="add_edit(item2, 0)">
                <div v-if="item2.start_time">{{ item2.start_time | formatDate }}~{{ item2.end_time | formatDate }}</div>
                <div :class="{ 'text-danger': !item2.course }">
                  {{ item2.course ? item2.course.name : '关联课程已被删除' }}
                </div>
                <div v-if="item2.store_user">{{ item2.store_user.real_name }}</div>
                <div>{{ item2.classroom ? item2.classroom.name : '未知教室' }}</div>
                <div>
                  <el-rate v-model="item2.difficulty" disabled-void-color="#e9e9e9" disabled />
                </div>
                <div v-if="item2.auto_cancel === 2" class="fine_class stop">停</div>
                <div v-else-if="item2.course && item2.course.type === 2" class="fine_class">精</div>
                <div v-else-if="item2.course && item2.course.type === 1" class="fine_class">私</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex row-between p-t-20 col-top">
        <div>
          <div v-show="viewInput.noteShow && viewInput.note">
            <div class="font-16 m-b-10">备注：</div>
            <div class="text-primary font-16" v-html="viewInput.note.replace(/[\n\r]/g, '<br>')" />
          </div>
          <el-form v-show="!viewInput.noteShow" ref="form" :model="viewInput" label-width="80px" label-suffix=":">
            <el-form-item label="说明">
              <div>课表标题及备注(保存课表时标题和备注会一并显示在课程表图片中)</div>
            </el-form-item>
            <el-form-item label="课程标题">
              <el-input v-model="viewInput.title" placeholder="课程标题" @change="noticeUp" />
              <!-- <small>用于保存课程表时的文字内容展示</small> -->
            </el-form-item>
            <el-form-item label="课程备注">
              <el-input v-model="viewInput.note" placeholder="课程备注" type="textarea" rows="3" @change="noticeUp" />
              <!-- <small>用于保存课程表时的文字内容展示</small> -->
            </el-form-item>
          </el-form>
        </div>
        <div>
          <div>
            <!-- <canvas ref="qrcode_qiandao" /> -->
            <el-image style="width:150px;height:150px" :src="appInfo.program_open_path" />
          </div>
          <div class="text-center font-16" style="font-weight: 600;">扫码约课</div>
        </div>
      </div>
    </div>

    <el-dialog v-if="dialog.visible" append-to-body width="50%" :close-on-click-modal="false" :visible.sync="dialog.visible" :title="chooseDate.title" @close="handleClose">
      <el-form ref="ruleForm" v-loading="chooseDate.loading" :rules="rules" :model="form" label-position="left" label-width="100px">
        <!-- 课程信息 -->
        <el-row :gutter="20">
          <!-- 提示 -->
          <el-col v-if="form.auto_cancel != 0" :span="24">
            <div class="m-y-10">
              <el-alert v-if="form.auto_cancel == 1" title="温馨提示：该排课已自动取消" type="warning" show-icon :closable="false" />
              <el-alert v-if="form.auto_cancel == 2" title="温馨提示：该排课已停课" type="warning" show-icon :closable="false" />
            </div>
          </el-col>
          <!-- 提示 -->
          <el-col :span="24">
            <div class="font-20 m-y-10">课程信息</div>
          </el-col>
          <!-- 课程类型 -->
          <el-col :span="12">
            <el-form-item label="课程类型" prop="type">
              <el-select v-model="form.type" class="w-250" :disabled="whether ? false : true" placeholder="请选择" @change="curriculumType">
                <el-option label="团课" :value="0" />
                <el-option label="精品课" :value="2" />
                <el-option :disabled="is_private" label="私教课" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 课程名称 -->
          <el-col :span="12">
            <el-form-item label="课程名称" prop="course_id">
              <Select ref="curriculumRef" :type="6" :curriculum-type="form.type" :width="250" @changeId="handleCurriculumID" @courseChoose="courseChooseS" />
            </el-form-item>
          </el-col>
          <!-- 课程教练 -->
          <el-col :span="12">
            <el-form-item label="课程教练" prop="store_user_id">
              <Select ref="coachRef" :type="4" :coach-type="form.type === 0 || form.type === 2 ? 0 : 1" :width="250" @changeId="handleCoachID" />
            </el-form-item>
          </el-col>
          <!-- 上课教室 -->
          <el-col :span="12">
            <el-form-item label="上课教室" prop="classroom_id">
              <Select ref="classroomRef" :type="8" :width="250" @changeId="handleClassroomID" />
            </el-form-item>
          </el-col>
          <!-- 容纳人数 -->
          <el-col v-if="form.type != 1" :span="12">
            <el-form-item label="容纳人数" prop="galleryful">
              <el-input v-model="form.galleryful" class="w-250" type="number" placeholder="请输入容纳人数">
                <template slot="append">人</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- 课程颜色 -->
          <el-col :span="12">
            <el-form-item label="课程颜色" prop="color" style="height: 32px;">
              <el-color-picker v-model="form.color" class="curriculum_color" :predefine="courseDefaultColorList" />
            </el-form-item>
          </el-col>
          <!-- 课程难度 -->
          <el-col :span="12">
            <el-form-item label="课程难度" prop="color">
              <el-rate v-model="form.difficulty" class="p-t-4 curriculum_rate" />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 上课时间 -->
        <el-row :gutter="20">
          <!-- 提示 -->
          <el-col :span="24">
            <div class="font-20 m-b-10">上课时间</div>
          </el-col>
          <!-- 排课时间 -->
          <el-col v-if="whether === 2" :span="12">
            <el-form-item label="排课时间" prop="batTime">
              <el-date-picker
                v-model="form.batTime"
                style="width: 300px;"
                :picker-options="dataProhibitSelection"
                type="daterange"
                align="right"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </el-form-item>
          </el-col>
          <!-- 上课时间 -->
          <el-col :span="12">
            <el-form-item label="上课时间" prop="start_time">
              <div class="flex">
                <el-select v-model="stime_h" filterable placeholder="请选择" style="width: 112px;">
                  <el-option v-for="(item, index) in 24" :key="index" :label="(index < 10 ? '0' + index : index).toString()" :value="(index < 10 ? '0' + index : index).toString()" />
                </el-select>
                <div class="m-x-10">:</div>
                <el-select v-model="stime_m" filterable placeholder="请选择" style="width: 112px;">
                  <el-option v-for="(item, index) in 60" :key="index" :label="(index < 10 ? '0' + index : index).toString()" :value="(index < 10 ? '0' + index : index).toString()" />
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <!-- 课程日期 -->
          <el-col v-if="whether === 2" :span="12">
            <el-form-item label="课程日期" prop="time_week">
              <el-switch v-model="is_coclk" active-color="#13ce66" inactive-color="#ff4949" active-text="全选" inactive-text="取消全选" @change="is_switch" />
              <el-checkbox-group v-model="form.time_week" @change="is_checkbox">
                <el-checkbox :label="1" class="m-b-10">周一</el-checkbox>
                <el-checkbox :label="2" class="m-b-10">周二</el-checkbox>
                <el-checkbox :label="3" class="m-b-10">周三</el-checkbox>
                <el-checkbox :label="4" class="m-b-10">周四</el-checkbox>
                <el-checkbox :label="5" class="m-b-10">周五</el-checkbox>
                <el-checkbox :label="6" class="m-b-10">周六</el-checkbox>
                <el-checkbox :label="7" class="m-b-10">周日</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <!-- 课程时长 -->
          <el-col :span="12">
            <el-form-item label="课程时长" prop="duration">
              <el-input v-model="form.duration" class="w-250" type="number" placeholder="请输入时长">
                <template slot="append">分钟</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 付费约课 -->
        <el-row :gutter="20">
          <!-- 提示 -->
          <el-col :span="24">
            <div class="font-20 m-b-10">付费约课</div>
          </el-col>
          <!-- 付费约课 -->
          <el-col :span="8">
            <el-form-item label="付费约课" prop="is_pay">
              <el-select v-model="form.is_pay" placeholder="请选择">
                <el-option label="关闭" :value="0" />
                <el-option label="自定义" :value="1" />
                <el-option label="与课程保持一致" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="form.is_pay == 1" :span="8">
            <el-form-item label="优惠价(元/次)" prop="price">
              <el-input v-model="form.price" type="number" placeholder="请输入优惠价" :disabled="form.is_pay == 1 ? false : true">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form.is_pay == 1" :span="8">
            <el-form-item label="原价(元/次)" prop="original_price">
              <el-input v-model="form.original_price" type="number" placeholder="请输入原价" :disabled="form.is_pay == 1 ? false : true">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form.is_pay == 2 && form.original_price !== 0 && form.price !== 0" :span="8">
            <el-form-item label="优惠价(元/次)" prop="price">
              <el-input v-model="form.price" type="number" placeholder="请输入优惠价" :disabled="form.is_pay == 1 ? false : true">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form.is_pay == 2 && form.original_price !== 0 && form.price !== 0" :span="8">
            <el-form-item label="原价(元/次)" prop="original_price">
              <el-input v-model="form.original_price" type="number" placeholder="请输入原价" :disabled="form.is_pay == 1 ? false : true">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="form.is_pay == 2 && form.original_price === 0 && form.price === 0" :span="16">
            <el-alert title="当前课程未开启付费约课，可选择自定义费用" type="warning" :closable="false" />
          </el-col>
        </el-row>
        <!-- 开课限制 -->
        <el-row v-if="form.type != 1" :gutter="20">
          <!-- 提示 -->
          <el-col :span="24">
            <div class="font-20 m-b-10">开课限制</div>
          </el-col>
          <el-col :span="8">
            <el-form-item label="限制方式" prop="is_check">
              <el-select v-model="form.is_check" placeholder="请选择">
                <el-option label="不限制" :value="0" />
                <el-option label="限制" :value="1" />
                <el-option label="遵循系统" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <div v-show="form.is_check === 1">
              <div class="check-box">
                <div class="flex">
                  <div>满</div>
                  <el-form-item prop="lower_limit" label-width="0px">
                    <el-input v-model="form.lower_limit" placeholder="填数字" class="w-150 m-x-5" type="number" size="mini" />
                  </el-form-item>
                  <div>人开课，人数不足提前</div>
                  <el-form-item prop="check_time" label-width="0px">
                    <el-input v-model="form.check_time" placeholder="填数字" class="w-150 m-x-5" type="number" size="mini" />
                  </el-form-item>
                  <div>分钟自动取消课程</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col v-if="form.is_check == 2" :span="16">
            <el-alert v-if="form.type === 0" :title="group_minimum_member.is_check ? `当约课会员不足 ${group_minimum_member.lower_limit} 人时，在上课前 ${group_minimum_member.check_time} 分钟(取消课程时间)自动取消课程。` : '系统开课限制已关闭'" type="warning" :closable="false" />
            <el-alert v-if="form.type === 2" :title="fine_minimum_member.is_check ? `当约课会员不足 ${fine_minimum_member.lower_limit} 人时，在上课前 ${fine_minimum_member.check_time} 分钟(取消课程时间)自动取消课程。` : '系统开课限制已关闭'" type="warning" :closable="false" />
          </el-col>
        </el-row>
      </el-form>
      <div class="footer text-center m-t-30">
        <el-button v-show="btn_access('s_course_curriculum_add') && !form.overdue" type="primary" :disabled="!!form.disable_edit" :loading="submitLoading" @click="submit">
          保存
        </el-button>
        <el-button @click="dialog.visible = false">关闭</el-button>
        <el-button
          v-if="dialog.id && !form.overdue"
          v-show="btn_access('s_course_curriculum_delete')"
          :disabled="!!form.disable_edit"
          type="danger"
          :loading="submitLoading"
          @click="delete_curriculum(dialog.id)"
        >
          删除
        </el-button>
        <el-button
          v-if="dialog.id && !form.overdue"
          v-show="btn_access('s_course_curriculum_delete')"
          :disabled="!!form.disable_edit"
          :type="form.auto_cancel == 0 ? 'info' : 'primary'"
          :loading="submitLoading"
          @click="cancel_curriculum(dialog.id)"
        >
          {{ form.auto_cancel == 0 ? '停课' : '复课' }}
        </el-button>
        <el-button v-if="dialog.id && form.overdue" disabled type="info">
          课程已结束
        </el-button>
      </div>
    </el-dialog>
    <!-- 保存课程表预览 -->
    <el-dialog title="分享课程表" :visible.sync="downImg" width="30%" center>
      <el-image ref="preview" style="width: 100%" :src="viewUrl" fit="scale-down" :preview-src-list="[viewUrl]" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgView">预 览</el-button>
        <el-button type="primary" @click="imgDown">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getClassSchedule,
  getCourse,
  addClass,
  addBatClass,
  editClass,
  copyCourse,
  clearCourse,
  delClassInfo,
  classScheduleCancel,
  classScheduleRecover,
  classScheduleStartStop,
  getClassScheduleNotice,
  editClassScheduleNotice
} from '@/api/store/course.js'
import { getStoreUserDetails } from '@/api/store/management.js'
import moment from 'moment'
import 'moment/locale/zh-cn' // moment 转中文 http://momentjs.cn/
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
import { deepClone } from '@/utils'
import { getSubscribeSettingInfo } from '@/api/store/subscribe'
import Select from '@/components/elSelect/Select'

export default {
  components: { Select },
  filters: {
    formatDate: value => moment(value).format('HH:mm')
  },
  data() {
    return {
      currentWeek: 0,
      tableList: {},
      weekList: {},
      form: {
        type: 0,
        course_id: '',
        store_user_id: '',
        classroom_id: '',
        duration: '',
        galleryful: 0,
        difficulty: 0,
        color: '#9C4B4B',
        start_time: this.$moment().format('HH:mm'),
        is_check: 0, // 开课人数限制:0=不限制,1=限制
        lower_limit: 1, // 满多少人开课
        check_time: 45, // 人数不足提前多少分钟取消
        is_pay: 0,
        price: 0,
        original_price: 0,
        is_bat: 1,
        time_week: [],
        batTime: ''
      },
      chooseDate: {
        loading: false
      },
      dialog: { visible: false, id: '' },
      rules: {
        type: { required: true, message: '请选择课程类型', trigger: ['blur', 'change'] },
        store_user_id: { required: true, message: '请选择教练', trigger: ['blur', 'change'] },
        duration: {
          validator: (rule, value, callback) => {
            if (this.form.duration < 0 || this.form.duration === '' || this.form.duration === null || this.form.duration % 1 !== 0) {
              callback(new Error('请填正确的时长'))
            }
            callback()
          },
          trigger: ['blur', 'change']
        },
        galleryful: {
          validator: (rule, value, callback) => {
            if (this.form.type !== 1 && (this.form.galleryful < 0 || this.form.galleryful === '' || this.form.galleryful === null || this.form.galleryful % 1 !== 0)) {
              callback(new Error('请填正确的人数'))
            }
            callback()
          },
          trigger: ['blur', 'change']
        },
        course_id: { required: true, message: '请选择课程', trigger: ['blur', 'change'] },
        classroom_id: { required: true, message: '请选择教室', trigger: ['blur', 'change'] },
        start_time: { required: true, message: '请选择开始时间', trigger: ['blur', 'change'] },
        color: { required: true, message: '请选择课程颜色', trigger: ['blur', 'change'] },
        batTime: { required: true, message: '请选择批量排课时间', trigger: ['blur', 'change'] },
        lower_limit: {
          validator: (rule, value, callback) => {
            if (this.form.is_check === 1 && (this.form.lower_limit < 0 || this.form.lower_limit === '' || this.form.lower_limit === null || this.form.lower_limit % 1 !== 0)) {
              callback(new Error('请填正确的人数'))
            }
            callback()
          },
          trigger: ['blur', 'change']
        },
        check_time: {
          validator: (rule, value, callback) => {
            if (this.form.is_check === 1 && (this.form.check_time < 0 || this.form.check_time === '' || this.form.check_time === null || this.form.check_time % 1 !== 0)) {
              callback(new Error('请填写正确的数字'))
            }
            callback()
          },
          trigger: ['blur', 'change']
        },
        is_pay: { required: true, message: '请选择付费约课', trigger: ['blur', 'change'] },
        is_bat: { required: true, message: '请选择是否为批量排课', trigger: ['blur', 'change'] },
        time_week: { required: true, message: '请选择课程日期', trigger: ['blur', 'change'] }
      },
      apiData: { url: '/v1/class_schedule' },
      chooseStyle: true,
      loading: false,
      viewInput: {
        noteShow: false,
        note: ''
      },
      appInfo: this.utils.getApp(),
      downImg: false,
      viewUrl: '',
      downLoading: false,
      qiandao_qr: '',
      stime_h: this.$moment()
        .format('HH')
        .toString(),
      stime_m: this.$moment()
        .format('mm')
        .toString(),
      notice: {
        title: '',
        content: ''
      },
      upForm: false,
      restaurants: [],
      state: '',
      timeout: null,
      whether: null,
      is_disable: true,
      real_name: '',
      classroom_name: '',
      is_coclk: false,
      is_private: true,
      submitLoading: false,
      group_minimum_member: {},
      fine_minimum_member: {}
    }
  },
  created() {
    // this.rules = this.utils.validateFor(this.rules)
  },
  async mounted() {
    const SubscribeSettingInfo = await getSubscribeSettingInfo('private_subscribe')
    if (SubscribeSettingInfo.data.config) {
      if (SubscribeSettingInfo.data.config.type === 1) this.is_private = false
      else this.is_private = true
    } else {
      this.is_private = true
    }

    const groupMinimumMember = await getSubscribeSettingInfo('group_minimum_member')
    if (groupMinimumMember.data.config) {
      this.group_minimum_member = {
        is_check: groupMinimumMember.data.config.is_check,
        lower_limit: groupMinimumMember.data.config.lower_limit,
        check_time: groupMinimumMember.data.config.check_time
      }
    }

    const fineMinimumMember = await getSubscribeSettingInfo('fine_minimum_member')
    if (fineMinimumMember.data.config) {
      this.fine_minimum_member = {
        is_check: fineMinimumMember.data.config.is_check,
        lower_limit: fineMinimumMember.data.config.lower_limit,
        check_time: fineMinimumMember.data.config.check_time
      }
    }

    this.getList()
    // 获取课程列表
    this.getCourseList(this.form.type)
    this.getNotice()
    // 调用vuex中的方法
    this.$store.dispatch('user/display', false)
    // this.buildQr()
  },
  destroyed() {
    this.$store.dispatch('user/display', true)
  },
  methods: {
    // 课程表备注
    getNotice() {
      getClassScheduleNotice().then(res => {
        this.$set(this.viewInput, 'title', res.data.title)
        this.$set(this.viewInput, 'note', res.data.content)
      })
    },
    // 更新课程表备注
    setNotice() {
      editClassScheduleNotice({
        title: this.viewInput.title,
        content: this.viewInput.note
      }).then(res => {
        this.$message.success(res.msg)
      })
    },
    noticeUp() {
      this.setNotice()
    },
    getCourseList(type) {
      getCourse({ limit: 999, type: type })
        .then(res => {
          this.courseList = res.data.list
        })
        .catch(() => {})
        .finally(() => {
          this.chooseDate.loading = false
        })
    },
    // 清空课程表
    clear_curriculum() {
      this.loading = true
      clearCourse(this.weekList[1].date)
        .then(res => {
          this.getList()
          this.$message.success(res.msg)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 复制课程
    copy(t) {
      this.loading = true
      copyCourse(this.weekList[1].date, { force: t ? 1 : 0 })
        .then(res => {
          this.$message.success(res.msg)
        })
        .catch(error => {
          if (error.status === 701) {
            this.$confirm(error.msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.copy(1)
              })
              .catch(() => {})
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    backNext(type) {
      this.loading = true
      if (type) this.currentWeek--
      else this.currentWeek++
      this.getList()
    },
    getList() {
      this.downLoading = true
      getClassSchedule({ currentWeek: this.currentWeek })
        .then(res => {
          this.weekList = res.data.week
          this.tableList = res.data.list
          this.loading = false
          this.downLoading = false
        })
        .catch(() => {})
    },
    // 添加\编辑课程
    add_edit(item, num) {
      this.whether = num
      if (item.store_user) this.real_name = item.store_user.real_name
      if (this.checkTime(item.date)) return false
      if (item.id) {
        // 编辑鉴权
        if (!this.btn_access('s_course_curriculum_edit')) {
          this.$message.error('无权编辑')
          return false
        }
      }
      const form = JSON.parse(JSON.stringify(item))
      let title = '课程信息('
      if (!item.id) {
        // 新增
        this.chooseDate = form
        title = '新增排课('
        this.form = {
          type: 0,
          course_id: '',
          store_user_id: '',
          classroom_id: '',
          duration: '',
          galleryful: 0,
          difficulty: 0,
          color: '#9C4B4B',
          start_time: this.$moment().format('HH:mm'),
          is_check: 2, // 开课人数限制:0=不限制,1=限制,2=遵循系统
          lower_limit: '', // 满多少人开课
          check_time: '', // 人数不足提前多少分钟取消
          is_pay: 0,
          price: 0,
          original_price: 0,
          is_bat: 1,
          time_week: [],
          batTime: ''
        }
        this.is_coclk = false
        this.stime_h = this.$moment()
          .format('HH')
          .toString()
        this.stime_m = this.$moment()
          .format('mm')
          .toString()
        this.$set(this.form, 'type', 0)
      } else {
        // 编辑
        this.form = form
        this.restaurants.forEach(item => {
          if (this.form.store_user_id === item.label) this.form.store_user_id = item.value
        })
        this.$set(this.form, 'store_user_id', this.form.store_user_id)
        this.chooseDate = {
          week: moment(form.start_time).format('dddd'),
          date: moment(form.start_time).format('YYYY-MM-DD')
        }
        this.$set(this.form, 'type', form.course ? form.course.type : 0)
        this.stime_h = this.$moment(this.form['start_time'])
          .format('HH')
          .toString()
        this.stime_m = this.$moment(this.form['start_time'])
          .format('mm')
          .toString()
        this.form['disable_edit'] = this.checkTime(this.form['start_time'])
        this.form['start_time'] = this.form['start_time'].split(' ')[1]
      }
      this.getCourseList(this.form.type)
      if (this.chooseDate.date) this.chooseDate['title'] = `${title}${this.chooseDate.date} ${this.chooseDate.week})`
      else this.chooseDate['title'] = `新增排课（批量排课）`
      this.dialog = { visible: true, id: form.id }
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
        this.$refs.curriculumRef.refreshGetList(this.form.course && this.form.course.name ? this.form.course.name : '')
        this.$refs.coachRef.refreshGetList(this.form.store_user && this.form.store_user.real_name ? this.form.store_user.real_name : '')
        this.$refs.classroomRef.refreshGetList(this.form.classroom && this.form.classroom.name ? this.form.classroom.name : '')
      })
    },
    // 提交
    submit() {
      if (this.is_disable) {
        this.is_disable = false
        this.submitLoading = true
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.chooseDate.date) this.form['start_time'] = `${this.chooseDate.date} ${this.stime_h}:${this.stime_m}`
            else this.form['start_time'] = `${this.form.batTime[0]} ${this.stime_h}:${this.stime_m}`
            this.form['time_hm'] = `${this.stime_h}:${this.stime_m}`
            this.form['end_time'] = moment(this.form.start_time)
              .add(this.form.duration, 'm')
              .format('YYYY-MM-DD HH:mm')
            this.form['venues_id'] = this.utils.getVenues().id
            this.restaurants.forEach(item => {
              if (this.form.store_user_id === item.value) this.form.store_user_id = item.label
            })
            if (!this.dialog.id) {
              if (this.whether !== 2) {
                this.form.is_bat = 0
                addClass(this.form)
                  .then(res => {
                    this.callback('添加成功')
                    this.is_disable = true
                    this.submitLoading = false
                  })
                  .catch(() => {
                    this.restaurants.forEach(item => {
                      if (this.form.store_user_id === item.label) this.form.store_user_id = item.value
                    })
                    this.is_disable = true
                    this.submitLoading = false
                  })
              } else {
                this.form['bat_start_time'] = this.form.batTime[0]
                this.form['bat_end_time'] = this.form.batTime[1]

                const data = deepClone(this.form)

                if (data.time_week.length > 0) {
                  data.time_week = data.time_week.join(',')
                }
                addBatClass(data)
                  .then(res => {
                    this.callback('添加成功')
                    this.is_disable = true
                    this.submitLoading = false
                  })
                  .catch(() => {
                    this.restaurants.forEach(item => {
                      if (this.form.store_user_id === item.label) this.form.store_user_id = item.value
                    })
                    this.is_disable = true
                    this.submitLoading = false
                  })
              }
            } else {
              editClass(this.dialog.id, this.form)
                .then(res => {
                  this.callback('修改成功')
                  this.is_disable = true
                  this.submitLoading = false
                })
                .catch(() => {
                  this.is_disable = true
                  this.submitLoading = false
                })
            }
          } else {
            this.is_disable = true
            this.submitLoading = false
          }
        })
      }
    },
    // 课程选择
    courseChoose(index) {
      // 时长
      this.$set(this.form, 'duration', this.courseList[index].duration)
      // 容纳人数
      this.$set(this.form, 'galleryful', this.courseList[index].galleryful)
      // 课程难度
      this.$set(this.form, 'difficulty', this.courseList[index].difficulty)
      // 教练
      if (this.courseList[index].store_user_id) {
        this.real_name = this.courseList[index].store_user.real_name
        this.setColor(this.courseList[index].store_user_id)
        this.restaurants.forEach(item => {
          if (this.courseList[index].store_user_id === item.label) this.courseList[index].store_user_id = item.value
        })
        this.$set(this.form, 'store_user_id', this.courseList[index].store_user_id)
      }
      // 教室
      if (this.courseList[index].classroom_id) {
        this.classroom_name = this.courseList[index].classroom.name
        this.$set(this.form, 'classroom_id', this.courseList[index].classroom_id)
      }
      // 付费金额
      this.$set(this.form, 'price', this.courseList[index].price)
      // 原价
      this.$set(this.form, 'original_price', this.courseList[index].original_price)
    },
    // 删除课程表
    delete_curriculum(id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.submitLoading = true
          delClassInfo(id)
            .then(res => {
              this.callback(res.msg)
              this.submitLoading = false
            })
            .catch(() => {
              this.submitLoading = false
            })
        })
        .catch(() => {
          this.submitLoading = false
        })
    },
    callback(msg) {
      this.dialog.visible = false
      if (msg) this.$message.success(msg)
      this.getList()
    },
    // 切换样式
    switchStyle() {
      this.chooseStyle = !this.chooseStyle
    },
    // 监听课程变更
    curriculumType(type) {
      this.restaurants = []
      this.form.store_user_id = ''
      this.form.course_id = ''
      this.getCourseList(type)
      this.$nextTick(() => {
        this.$refs.curriculumRef.refreshGetList()
      })
    },
    // 选择员工事件
    storeUserChange(i, v) {
      if (i) {
        this.setColor(i)
      }
    },
    setColor(i) {
      // 获取员工信息
      getStoreUserDetails(i)
        .then(res => {
          const { data } = res
          // 联动员工绑定的课程颜色
          this.form.color = data.color ? data.color : '#9C4B4B'
        })
        .catch(() => {})
    },
    // 生成二维码
    buildQr() {
      const that = this
      const canvas = that.$refs.qrcode_qiandao
      const url = this.appInfo.url
      QRCode.toCanvas(canvas, url, { width: 150, height: 150, margin: 2 }, function(error) {
        if (error) console.error(error)
        that.$forceUpdate()
      })
      // this.qiandao_qr = url
    },
    // 保存课程表
    toImage() {
      this.downLoading = true
      this.viewInput.noteShow = true
      setTimeout(() => {
        // DOM节点主体
        const main = document.getElementById('courseView')
        window.pageYOffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        html2canvas(main, {
          dpi: window.devicePixelRatio * 1,
          width: parseInt(main.offsetWidth + 50),
          height: parseInt(main.offsetHeight + 50),
          scale: 3,
          x: -25,
          y: -25,
          scrollY: 0,
          scrollX: 0,
          useCORS: true,
          allowTaint: false
        }).then(canvas => {
          // 下载
          // const file_name = this.currentVenues.name + '_课程表.png'
          // const link = document.createElement('a')
          // link.href = canvas.toDataURL()// 下载链接
          // link.setAttribute('download', file_name)
          // link.style.display = 'none'// a标签隐藏
          // document.body.appendChild(link)
          // link.click()
          // 预览窗口
          var dataUrl = canvas.toDataURL('image/png', 1.0)
          this.viewUrl = dataUrl
          this.downImg = true
          this.downLoading = false
          this.viewInput.noteShow = false
        })
      }, 1500)
    },
    // 图片预览
    imgView() {
      this.$refs.preview.clickHandler()
    },
    // 图片下载
    imgDown() {
      const file_name = this.currentVenues.name + '_课程表.png'
      const link = document.createElement('a')
      link.href = this.viewUrl // 下载链接
      link.setAttribute('download', file_name)
      link.style.display = 'none' // a标签隐藏
      document.body.appendChild(link)
      link.click()
    },
    getBg(item2) {
      if (this.chooseStyle) {
        let color = !item2.course ? '#c0c4cc' : this.chooseStyle ? item2.color : ''
        if (item2.auto_cancel) color = '#82848a'
        return { background: color }
      }
    },
    checkTime(time) {
      return this.getExcessTime(time) < 0
    },
    // 停止课程
    stop_curriculum(id) {
      const text = this.form.ext ? (this.form.ext.is_stop ? '恢复约课后会员可进行自主约课确认恢复约课？' : '停止约课后会员将无法自主约课确认停止约课？') : '停止约课后会员将无法自主约课确认停止约课？'
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const type = this.form.ext ? (this.form.ext.is_stop ? 0 : 1) : 1
        this.submitLoading = true
        classScheduleStartStop(id, type)
          .then(res => {
            this.callback(res.msg)
          })
          .finally(() => {
            this.submitLoading = false
          })
      })
    },
    // 取消课程
    cancel_curriculum(id) {
      this.$confirm(`${this.form.auto_cancel === 0 ? '取消课程后，已预约会员会被强制取消预约，并且这节课将不能再被预约,是否继续?' : '复课后会员可进行自主约课,确认复课？'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.submitLoading = true
          if (this.form.auto_cancel === 0) {
            classScheduleCancel(id, 1)
              .then(res => {
                this.callback(res.msg)
                this.submitLoading = false
              })
              .catch(() => {
                this.submitLoading = false
              })
          } else {
            classScheduleRecover(id)
              .then(res => {
                this.callback(res.msg)
                this.submitLoading = false
              })
              .catch(() => {
                this.submitLoading = false
              })
          }
        })
        .catch(() => {
          this.submitLoading = false
        })
    },
    createStateFilter(queryString) {
      return state => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    handleCoachID(val) {
      this.form.store_user_id = val
    },
    handleClassroomID(val) {
      this.form.classroom_id = val
    },
    handleCurriculumID(val) {
      this.form.course_id = val
    },
    courseChooseS(val) {
      this.courseChoose(val)
      this.$refs.coachRef.refreshGetList(this.real_name)
      this.$refs.classroomRef.refreshGetList(this.classroom_name)
    },
    handleClose() {
      this.real_name = ''
    },
    is_switch() {
      if (this.is_coclk) this.form.time_week = [1, 2, 3, 4, 5, 6, 7]
      if (!this.is_coclk) this.form.time_week = []
    },
    is_checkbox() {
      if (this.form.time_week.length === 7) this.is_coclk = true
      else this.is_coclk = false
    }
  }
}
</script>

<style lang="scss" scoped>
.check-box {
  .item_title {
    border-left: 5px solid #3498db;
    // font-size: 16px;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 3px !important;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #606266 !important;
  }
}

.time {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  justify-content: center;

  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

.table {
  table-layout: fixed;
  width: 100%;

  thead {
    background-color: #67a1ff;

    th {
      color: #fff;
      line-height: 17px;
      font-weight: normal;
    }

    .week_info {
      position: relative;
      &.disable {
        filter: grayscale(100%);
        img {
          cursor: unset;
        }
      }
      img {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
    }
  }

  tbody {
    background-color: #eaf2ff;

    .course {
      position: relative;
      cursor: pointer;
      padding: 7px 0;

      > div {
        padding-top: 7px;
      }
      ::v-deep .el-rate__icon {
        margin-right: 0;
      }
      .fine_class {
        font-size: 12px;
        padding: 0;
        text-align: center;
        width: 20px;
        height: 20px;
        background: rgb(0 0 0 / 10%);
        right: 0;
        bottom: 0;
        line-height: 20px;
        position: absolute;
        border-radius: 4px 0px 0px 0px;
      }
      .stop {
        background: rgba(174, 174, 174, 1);
      }
    }
    .course.color {
      color: #fff !important;
    }
    .special {
      position: relative;
      padding-top: 6px;

      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
        top: 0;
      }
    }

    td {
      color: #677998;
      line-height: 12px;
    }
  }

  th {
    width: 60px;
    padding: 12px 2px;
    font-size: 12px;
    text-align: center;
  }

  td {
    text-align: center;
    vertical-align: sub;
  }

  tr td:first-child {
    color: #333;
    vertical-align: middle;

    .period {
      font-size: 8px;
    }
  }
}
.text {
  display: block;
  color: #ffbd43;
}
.jzz {
  text-align: center;
  font-size: 16px;
  margin: 6px 0;
  .jzz_span1 {
    margin-left: 10px;
    color: #ccc;
  }
  .jzz_span2 {
    color: #ccc;
  }
}

.el-dialog__body {
  padding: 0px 20px 20px 20px !important;
}
.curriculum_color {
  ::v-deep .el-color-picker__trigger {
    width: 120px;
    height: 36px;
    .el-color-picker__icon {
      top: 54%;
      left: 85%;
    }
  }
}
.curriculum_rate {
  ::v-deep .el-rate__icon {
    font-size: 24px;
  }
}
</style>
