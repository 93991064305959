<template>
  <!-- 班级课程管理 -->
  <div class="app-container">
    <div class="filter-container">
      <el-button type="primary" icon="el-icon-plus" class="filter-item m-l-10" @click="handleAdd">
        新增班课
      </el-button>
    </div>
    <el-table ref="multipleTable" v-loading="loading" :data="tableData.list" row-key="id">
      <el-table-column prop="id" label="ID" />
      <!-- <el-table-column prop="image" label="图片">
        <template slot-scope="{ row }">
          <div class="block">
            <el-avatar shape="square" :size="50" :src="row.image">
              <img :src="errorImageSrc">
            </el-avatar>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="name" label="课程名称" />
      <el-table-column prop="store_user.real_name" label="教练">
        <template slot-scope="{ row: { store_user } }">
          {{ store_user ? store_user.real_name : '教练待更新' }}
        </template>
      </el-table-column>
      <el-table-column prop="duration" label="课程时长(分钟)" />
      <!-- <el-table-column prop="galleryful" label="可容纳人数" /> -->
      <el-table-column prop="type" label="课程类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 3">班课</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="difficulty" label="难度">
        <template slot-scope="{ row: { difficulty } }">
          <el-rate :value="difficulty" disabled text-color="#ff9900" :title="difficulty" />
        </template>
      </el-table-column> -->
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column label="操作" min-width="120">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleCheck(scope.row.id)">
            查看详情
          </el-button>
          <el-button type="warning" @click="handleEdit(scope.row.id)">
            编 辑
          </el-button>
          <el-popconfirm title="确定删除该课程表课程?" @confirm="handleDelete(scope.row.id)">
            <el-button slot="reference" class="m-8" type="danger">删除班课</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <Dialog :id="id" :dialog="dialogVisible" :name="name" @callback="callback" />
  </div>
</template>

<script>
// API
import { getCourseList, deleteCourseDelete } from '@/api/store/course'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Dialog from './dialog.vue'

export default {
  components: { Pagination, Dialog },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      loading: false,
      dialogVisible: false,
      name: '',
      id: 0
    }
  },
  watch: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    // 数据
    getList() {
      this.loading = true
      getCourseList().then(res => {
        this.tableData = res.data
        this.loading = false
      })
    },
    // 增加
    handleAdd() {
      this.dialogVisible = true
      this.name = 'add'
    },
    // 查看
    handleCheck(id) {
      this.dialogVisible = true
      this.name = 'check'
      this.id = id
    },
    // 编辑
    handleEdit(id) {
      this.dialogVisible = true
      this.name = 'edit'
      this.id = id
    },
    // 删除
    handleDelete(id) {
      deleteCourseDelete(id, { force: 0 })
        .then(res => {
          if (res.status === 200) {
            this.$message.success('删除成功')
            this.getList()
          }
        })
        .catch(res => {
          if (res.status === 701) {
            this.$confirm(res.msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              deleteCourseDelete(id, { force: 1 }).then(res => {
                if (res.status === 200) {
                  this.$message.success('删除成功')
                  this.getList()
                }
              })
            })
          }
        })
    },
    // 回调函数
    callback() {
      this.dialogVisible = false
      this.getList()
      this.id = 0
    }
  }
}
</script>

<style lang="scss" scoped></style>
