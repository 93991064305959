var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.utils.getVenues() && _vm.utils.getVenues().id != -1
    ? _c("div", { staticClass: "slide_header" }, [
        _c(
          "div",
          { staticClass: "flex col-center row-center slide_header_box" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.btn_access("s_workbench_sign_qrcode"),
                    expression: "btn_access('s_workbench_sign_qrcode')"
                  }
                ],
                staticClass: "qiandao cursor slide_header_item",
                on: {
                  click: function($event) {
                    return _vm.qiandao_action()
                  }
                }
              },
              [
                _c("div", [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "signin" } })],
                    1
                  )
                ]),
                _c("span", [_vm._v("签到")])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "yuetuanke cursor slide_header_item",
                on: {
                  click: function($event) {
                    return _vm.yuetuanke_action()
                  }
                }
              },
              [
                _c("div", [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "groupclass" } })],
                    1
                  )
                ]),
                _c("span", [_vm._v("约团课")])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "yuesijiao cursor slide_header_item",
                on: {
                  click: function($event) {
                    return _vm.yuesijiao_action()
                  }
                }
              },
              [
                _c("div", [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [
                      _c("svg-icon", {
                        attrs: { "icon-class": "privateclass" }
                      })
                    ],
                    1
                  )
                ]),
                _c("span", [_vm._v("约私教")])
              ]
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "dialog_box" },
          [
            _c(
              "el-dialog",
              {
                staticClass: "dialog_auto",
                attrs: {
                  title: "签到",
                  "append-to-body": "",
                  "modal-append-to-body": "",
                  "close-on-click-modal": false,
                  visible: _vm.dialog.qrcode_visible,
                  center: true
                },
                on: {
                  "update:visible": function($event) {
                    return _vm.$set(_vm.dialog, "qrcode_visible", $event)
                  }
                }
              },
              [_c("QrcodeSign")],
              1
            ),
            _vm.dialog.yuetuanke_visible
              ? _c("GroupBook", {
                  attrs: { "group-visible": _vm.dialog.yuetuanke_visible },
                  on: {
                    "update:groupVisible": function($event) {
                      return _vm.$set(_vm.dialog, "yuetuanke_visible", $event)
                    },
                    "update:group-visible": function($event) {
                      return _vm.$set(_vm.dialog, "yuetuanke_visible", $event)
                    }
                  }
                })
              : _vm._e(),
            _vm.dialog.yuesijiao_visible
              ? _c("PrivateBook", {
                  attrs: { "group-visible": _vm.dialog.yuesijiao_visible },
                  on: {
                    "update:groupVisible": function($event) {
                      return _vm.$set(_vm.dialog, "yuesijiao_visible", $event)
                    },
                    "update:group-visible": function($event) {
                      return _vm.$set(_vm.dialog, "yuesijiao_visible", $event)
                    }
                  }
                })
              : _vm._e(),
            _vm.dialog.yuesijiaoS_visible
              ? _c("PrivateBookS", {
                  attrs: { "group-visible": _vm.dialog.yuesijiaoS_visible },
                  on: {
                    "update:groupVisible": function($event) {
                      return _vm.$set(_vm.dialog, "yuesijiaoS_visible", $event)
                    },
                    "update:group-visible": function($event) {
                      return _vm.$set(_vm.dialog, "yuesijiaoS_visible", $event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }