var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.id === 0 ? "新增菜单" : "编辑菜单",
        visible: _vm.dialogVisible,
        fullscreen: true,
        "close-on-click-modal": false,
        "show-close": false,
        width: "30%"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.handleOpen
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "title_title" }, [
            _vm._v(_vm._s(_vm.id === 0 ? "新增菜单" : "编辑菜单"))
          ]),
          _c(
            "div",
            {
              staticClass: "title_icon cursor",
              on: { click: _vm.handleCloseS }
            },
            [_c("i", { staticClass: "el-icon-close" })]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "container_bg" },
        [
          _c(
            "div",
            { staticClass: "flex m-t-20 p-x-80" },
            [
              _c(
                "el-form",
                {
                  ref: "menuForm",
                  staticClass: "demo-ruleForm",
                  staticStyle: { width: "100%" },
                  attrs: {
                    model: _vm.menuForm,
                    rules: _vm.menuRules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "90%" },
                        model: {
                          value: _vm.menuForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.menuForm, "name", $$v)
                          },
                          expression: "menuForm.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "flex row-center m-t-20", attrs: { span: 7 } },
                [
                  _c("div", { staticClass: "phone_weChat" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.weChatLoading,
                            expression: "weChatLoading"
                          }
                        ],
                        staticClass: "weChat"
                      },
                      [
                        _c("div", {
                          staticStyle: { height: "130vh" },
                          on: { click: _vm.handleClose }
                        }),
                        _c("div", { staticClass: "weChat_bottom flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "weChat_bottomCode flex row-center"
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/code.png"),
                                  alt: ""
                                }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "weChat_bottomList flex row-around"
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.navigationBarList.length > 0,
                                      expression: "navigationBarList.length > 0"
                                    }
                                  ],
                                  ref: "multipleTable",
                                  staticClass: "flex"
                                },
                                _vm._l(_vm.navigationBarList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "cursor weChat_button",
                                      style:
                                        _vm.navigationBarStatus === index
                                          ? "border: 1px solid #00c9c9;"
                                          : "border: 1px solid #e7e7eb;",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClick(item, index)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(" " + _vm._s(item.name) + " "),
                                      _vm.navigationBarStatus === index
                                        ? _c(
                                            "div",
                                            { staticClass: "weChat_option" },
                                            [
                                              (item.sub_button
                                              ? item.sub_button.length < 5
                                              : true)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "weChat_bg",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.handleAddSon(
                                                            item,
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_c("div", [_vm._v("+")])]
                                                  )
                                                : _vm._e(),
                                              item.sub_button
                                                ? _c(
                                                    "div",
                                                    {
                                                      ref: "multipleTableSon",
                                                      refInFor: true,
                                                      staticClass: "weChat_all"
                                                    },
                                                    _vm._l(
                                                      item.sub_button,
                                                      function(item2, index2) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index2,
                                                            staticClass:
                                                              "weChat_asd",
                                                            style:
                                                              _vm.navigationBarStatusSon ===
                                                              index2
                                                                ? "border: 1px solid #00c9c9;"
                                                                : "border: 1px solid #e7e7eb;",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.handleClickSon(
                                                                  item2,
                                                                  index2
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item2.name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                }),
                                0
                              ),
                              _vm.navigationBarList.length < 3
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cursor",
                                      staticStyle: {
                                        border: "1px solid #e7e7eb"
                                      },
                                      style:
                                        _vm.navigationBarList.length === 2
                                          ? "width: 50%;"
                                          : "",
                                      on: { click: _vm.handleAdd }
                                    },
                                    [_vm._v(" + ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                { staticClass: "flex m-t-20", attrs: { span: 17 } },
                [
                  _c("div", { staticClass: "control_panel" }, [
                    _c("div", { staticClass: "control_center" }, [
                      _c("div", { staticClass: "top_label flex row-between" }, [
                        _c("div", [_vm._v("添加菜单")]),
                        _c("div", { staticClass: "flex" }, [
                          _vm.navigationBarStatus > -1
                            ? _c(
                                "div",
                                {
                                  staticClass: "cursor",
                                  staticStyle: { color: "red" },
                                  on: { click: _vm.handleDelete }
                                },
                                [_vm._v(" 删除菜单 ")]
                              )
                            : _vm._e()
                        ])
                      ]),
                      _vm.navigationBarStatus > -1
                        ? _c(
                            "div",
                            { staticClass: "form_list m-t-30" },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "ruleForm",
                                  staticClass: "demo-ruleForm",
                                  attrs: {
                                    "label-suffix": "：",
                                    "label-position": "left",
                                    model: _vm.ruleForm,
                                    "label-width": "120px"
                                  }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "菜单名称", prop: "name" }
                                    },
                                    [
                                      _c("el-input", {
                                        on: { input: _vm.limitInput },
                                        model: {
                                          value: _vm.ruleForm.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.ruleForm, "name", $$v)
                                          },
                                          expression: "ruleForm.name"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "font_color" },
                                        [
                                          _vm._v(
                                            "菜单名称字数不多于4个汉字或8个字母"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.navigationBarDisplay
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "页面内容",
                                                  prop: "type"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    model: {
                                                      value: _vm.ruleForm.type,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm.type"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: { label: "view" }
                                                      },
                                                      [_vm._v("跳转页面")]
                                                    ),
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          label: "miniprogram"
                                                        }
                                                      },
                                                      [_vm._v("跳转小程序")]
                                                    ),
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          label: "media_id"
                                                        }
                                                      },
                                                      [_vm._v("回复素材")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("br"),
                                                _vm.ruleForm.type === "view"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font_color"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "点击该菜单会跳到页面链接"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _vm.ruleForm.type === "miniprogram"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "AppId",
                                                      prop: "appid"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          _vm.ruleForm.appid,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm,
                                                            "appid",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.appid"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.ruleForm.type === "miniprogram"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "小程序路径",
                                                      prop: "pagepath"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      model: {
                                                        value:
                                                          _vm.ruleForm.pagepath,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm,
                                                            "pagepath",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.pagepath"
                                                      }
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font_color"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "小程序路径不可用时跳转页面链接"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.ruleForm.type === "view" ||
                                            _vm.ruleForm.type === "miniprogram"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "网页链接",
                                                      prop: "url"
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      model: {
                                                        value: _vm.ruleForm.url,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm,
                                                            "url",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.url"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.ruleForm.type === "media_id"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "消息类型",
                                                      prop: "messageType"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.handelChange()
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .messageType,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm,
                                                              "messageType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.messageType"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "image"
                                                            }
                                                          },
                                                          [_vm._v("图片")]
                                                        ),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "video"
                                                            }
                                                          },
                                                          [_vm._v("视频")]
                                                        ),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "voice"
                                                            }
                                                          },
                                                          [_vm._v("语音")]
                                                        ),
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: "news"
                                                            }
                                                          },
                                                          [_vm._v("图文")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.ruleForm.type === "media_id"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loading",
                                                        rawName: "v-loading",
                                                        value:
                                                          _vm.formItemLoading,
                                                        expression:
                                                          "formItemLoading"
                                                      }
                                                    ],
                                                    attrs: {
                                                      label: "消息内容",
                                                      prop: "media_id"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%"
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "请选择活动区域"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm
                                                              .media_id,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm,
                                                              "media_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.media_id"
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.messageContentList
                                                          .item,
                                                        function(item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  _vm.ruleForm
                                                                    .messageType ==
                                                                  "news"
                                                                    ? item
                                                                        .content
                                                                        .news_item[0]
                                                                        .title
                                                                    : item.name,
                                                                value:
                                                                  item.media_id
                                                              }
                                                            },
                                                            [
                                                              _vm.ruleForm
                                                                .messageType ==
                                                              "news"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "flex"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .news_item[0]
                                                                                .title
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle: {
                                                                            width:
                                                                              "10px"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle: {
                                                                            color:
                                                                              "#b9b9b9"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item
                                                                                .content
                                                                                .news_item[0]
                                                                                .digest
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "flex"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-image",
                                                                        {
                                                                          staticStyle: {
                                                                            width:
                                                                              "40px",
                                                                            height:
                                                                              "40px",
                                                                            padding:
                                                                              "5px 0px"
                                                                          },
                                                                          attrs: {
                                                                            src:
                                                                              item.url,
                                                                            "preview-src-list": [
                                                                              item.url
                                                                            ],
                                                                            referrerpolicy:
                                                                              "no-referrer"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle: {
                                                                            width:
                                                                              "10px"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.name
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle: {
                                                                            width:
                                                                              "10px"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle: {
                                                                            color:
                                                                              "#b9b9b9"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.media_id
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font_color"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "如果没有数据，请去公众号添加"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c("div", { staticClass: "hintLanguage" }, [
                            _vm._v("点击左侧菜单进行编辑操作")
                          ])
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "div",
            { staticClass: "dialog_button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.buttonLoading },
                  on: {
                    click: function($event) {
                      return _vm.handlePreserve("menuForm")
                    }
                  }
                },
                [_vm._v("保 存")]
              ),
              _c("el-button", { on: { click: _vm.handleCloseS } }, [
                _vm._v("关 闭")
              ])
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }