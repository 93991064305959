<template>
  <div class="reset-container">
    <div class="reset-header">
      <div class="header-logo cursor">
        <img src="@/assets/images/login_logo_dark.svg" alt="" class="logo-img m-t-40 m-l-60">
      </div>
    </div>

    <div class="p-t-60 center">
      <div class="flex reset-box col-top">
        <div class="box-left">
          <img src="@/assets/images/apply_left.jpg" alt="">
        </div>
        <div class="box-right">
          <div class="login-logo cursor" @click="toUrl(web_link)">
            <img src="@/assets/images/login_logo_dark.svg" alt="">
          </div>
          <div class="m-t-70">
            <el-form
              ref="resetPwdForm"
              :model="resetPwdForm"
              :rules="resetPwdRules"
              class="reset-form"
              autocomplete="on"
              size="medium"
              label-position="left"
              @keyup.enter="handleSubmit('resetPwdForm')"
            >
              <el-form-item prop="phone">
                <div class="fix">
                  <i class="icon-required" />
                  <Code @area_code="area_code" />
                  <el-input
                    ref="phone"
                    v-model="resetPwdForm.phone"
                    placeholder="手机号"
                    name="phone"
                    type="text"
                    tabindex="1"
                    autocomplete="on"
                    class="input-item"
                  />
                </div>
              </el-form-item>
              <el-form-item prop="code">
                <div class="fix">
                  <i class="icon-required" />
                  <el-input
                    ref="code"
                    v-model="resetPwdForm.code"
                    placeholder="请输入验证码"
                    name="code"
                    tabindex="2"
                    autocomplete="on"
                    class="input-item"
                    @keyup.native="checkCapslock"
                    @blur="capsTooltip = false"
                    @keyup.enter.native="submit"
                  />
                  <span v-loading="isLoading" class="get-code" :class="{ disabled: sendCoding }" @click="getCodes()">
                    {{ getCodeText }}
                  </span>
                </div>
              </el-form-item>
            </el-form>
            <div class="input-item">
              <el-button :loading="loading" type="primary" size="default" class="submit" @click.native.prevent="submit">
                立即登录
              </el-button>
            </div>
            <!-- <div class="input-item input-register">
              <el-button type="primary" size="default" class="submit submits">
                <a :href="web_link + '/freeRegister'" target="_blank">
                  还没有账号？立即注册
                </a>
              </el-button>
            </div> -->
            <!-- <div class="input-item">
              <a class="submit">{{ $t("login.logIn") }}</a>
            </div> -->
            <div class="foot-btn m-y-20">
              <div class="flex  flex-wrap row-between col-center">
                <div class="register btn_text">
                  <router-link to="/login">
                    <i class="el-icon-receiving" />
                    密码登录
                  </router-link>
                </div>
                <div class="register btn_text">
                  <router-link to="/resetpwd">
                    <i class="el-icon-lock" />
                    忘记密码
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="termsService">
            登录视为您已同意瑜小九
            <a href="https://www.yuxiaojiu.com/userGreement/" target="_blank">《服务条款》</a>
            <a href="https://www.yuxiaojiu.com/privacyaGreement/" target="_blank">《隐私条款》</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 尾部 -->
    <footer>
      <div class="m-y-10" style="color:#848484">
        瑜小九·知识产品与用户服务的数字化工具
      </div>
      <div class="">
        Copyright © 2015-{{ new Date().getFullYear() }}
        <a :href="web_link" target="_blank" class="link" title="瑜小九">瑜小九预约管理系统</a>
        All Rights Reserved.
        <a target="_blank" href="https://beian.miit.gov.cn/" class="link" rel="nofollow">{{ icpNumber }}</a>
      </div>
    </footer>

    <!-- 选择场馆 验证码登录 -->
    <el-dialog
      :visible.sync="show2"
      center
      class="two dialog_auto"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <!-- 标题 -->
      <div slot="title" style="font-size: 18px;">
        <div class="title">{{ title }}</div>
        <div class="logout" @click="logout">
          退出登录
          <i class="el-icon-right" />
        </div>
      </div>
      <div v-if="venues_list.length <= 0 && toExamineList.length <= 0" class="empty ">
        <div class="text-center">
          <div class="venues_list_empty">
            <img :src="empty_app_img" alt="">
            <br>
            <small class="text-info">暂无品牌，您可以联系客服：{{ callNumber }} 申请专属品牌</small>
          </div>
        </div>
      </div>
      <el-row :gutter="10">
        <div v-for="(item, index) in venues_list" :key="index" class="venues_selector m-b-10">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="flex row-between flex-wrap">
              <div class="flex">
                <div class="venues_img"><img :src="item.logo ? item.logo : defaultLogo" :alt="item.name" class="venues_logo"></div>
                <div class="venues_word">
                  <span class="venues_name">{{ item.name }}</span>
                </div>
              </div>
              <div v-if="item.is_super" class="flex flex-wrap">
                <!-- 格式化时间 -->
                <div class="m-r-10">
                  到期时间：
                  <span v-if="item.is_overdue" class="text-danger">已过期</span>
                  <span v-else class="text-success">
                    {{ item.expiration_time ? formatTime(item.expiration_time) : '永久' }}
                  </span>
                </div>
                <div class="venues_app_management" @click="venuesBtn(item, '', 1)">
                  <el-tooltip class="item" effect="dark" :content="management_title" placement="bottom">
                    <span>{{ management_title }}</span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </el-col>
          <!-- 场馆列表 -->
          <div class="venues_list">
            <div v-if="!utils.empty(item.venues)">
              <el-col v-for="items in item.venues" :key="items.id" :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
                <div v-if="items.role.part_time !== 1" class="venues_item" @click="venuesBtn(item, items)">
                  <el-tooltip effect="dark" :content="items.name" placement="bottom">
                    <div>{{ items.name }}</div>
                  </el-tooltip>
                </div>
                <div v-else class="venues_itemTwo">
                  <div>{{ items.name }}</div>
                  <div class="venues_itemTwoZ">兼 职</div>
                </div>
              </el-col>
            </div>
            <div v-else-if="item.is_super">
              <div class="venues_list_empty">
                您当前没有场馆！
                <el-link type="success" @click="venuesAdd(item)">快速创建场馆</el-link>
              </div>
            </div>
            <div v-else>
              <div class="venues_list_empty">
                <img :src="empty_default_img" alt="">
                <br>
                <small class="text-info">暂无场馆</small>
              </div>
            </div>
          </div>
        </div>
      </el-row>
      <!-- 审核状态 -->
      <div v-if="toExamineList.length >= 0">
        <div v-for="(item, index) in toExamineList" :key="index" class="type_center m-b-10">
          <!-- <div v-for="(item, index) in zhangxiaojueDataList" :key="index" class="type_center m-b-10"> -->
          <!-- 审核中 -->
          <div v-if="item.audit_status === 1" />
          <div v-else class="flex row-between type_centerTwo">
            <div class="flex">
              <el-image
                v-if="item.app.logo"
                style="width: 60px; height: 60px"
                :src="item.app.logo"
                fit="cover"
              />
              <img v-else style="width: 60px; height: 60px" src="@/assets/images/logo.png" alt="">
              <div style="margin-left: 20px;" class="venues_word">{{ item.app.name }}</div>
            </div>
            <div :style="{color: item.audit_status === 0 ? '#FB902D' : '#F93535'}">{{ item.audit_status === 0 ? '审核中' : '审核失败' }}</div>
          </div>
          <!-- 审核失败 -->
          <div v-if="item.audit_status === 2" class="type_bottom">
            <div>审核原因：</div>
            <div class="words">{{ item.audit_notice === '' ? '无' : item.audit_notice }}</div>
            <div class="cursor flex row-right">
              <div class="type_button" @click="handleIntroduction(item.id)">知道了</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <Verify
      v-if="showVerify"
      ref="verify"
      mode="pop"
      captcha-type="blockPuzzle"
      :img-size="{ width: '330px', height: '155px' }"
      :phone="resetPwdForm.phone"
      @success="captcha_success"
    />
  </div>
</template>

<script>
import Verify from '@/components/verifition/Verify'
import { smsLogin, SendCode } from '@/api/store/user.js'
import { getUserVenues, getStaffSignIn, delStaffSignIn } from '@/api/store/venues.js'
import { getAppInfo } from '@/api/store/management.js'
// 组件
import Code from '@/components/code/index.vue'

export default {
  components: {
    Verify,
    Code
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号!'))
      } else {
        const reg = /^1[23456789]\d{9}$/
        if (value === '' || value === undefined || value == null) {
          callback()
        } else {
          if (this.resetPwdForm.area_code === '+86' && !reg.test(value) && value !== '') {
            callback(new Error('请输入正确的电话号码'))
          } else {
            callback()
          }
        }
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码!'))
      } else {
        callback()
      }
    }
    return {
      resetPwdForm: {
        phone: '',
        code: '',
        area_code: '+86'
      },
      resetPwdRules: {
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePhone
          }
        ],
        code: [
          {
            required: true,
            trigger: 'blur',
            validator: validateCode
          }
        ]
      },
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      show2: false,
      show3: false,
      venues_list: [],
      is_super: 0,
      modals: false,
      appInfo: {},
      app_loading: true,
      venues_list_loading: true,
      is_dev: process.env.NODE_ENV === 'development',
      getCodeText: '获取验证码',
      sendCoding: false,
      isLoading: false,
      smsTimes: 60,
      showVerify: false,
      title: '请选择您要进入的场馆',
      management_title: '品牌管理',
      empty_app_img: require('@/assets/images/empty_app.png'),
      toExamineList: [],
      zhangxiaojueDataList: [
        {
          app: {
            logo: 'https://static.yuxiaojiu.cn/uploads/t_0575/20230223/872bf670a62515b9c04f6622f4dfd5fa.png',
            name: '玛尼瑜伽玛尼瑜伽玛尼瑜伽'
          },
          audit_status: 0
        },
        {
          app: {
            logo: 'https://static.yuxiaojiu.cn/uploads/t_0575/20230223/872bf670a62515b9c04f6622f4dfd5fa.png',
            name: '玛尼瑜伽玛尼瑜伽玛尼瑜伽'
          },
          audit_status: 0
        },
        {
          app: {
            logo: 'https://static.yuxiaojiu.cn/uploads/t_0575/20230223/872bf670a62515b9c04f6622f4dfd5fa.png',
            name: '玛尼瑜伽玛尼瑜伽玛尼瑜伽'
          },
          audit_status: 2,
          audit_notice: ''
        },
        {
          app: {
            logo: 'https://static.yuxiaojiu.cn/uploads/t_0575/20230223/872bf670a62515b9c04f6622f4dfd5fa.png',
            name: '玛尼瑜伽玛尼瑜伽玛尼瑜伽'
          },
          audit_status: 2,
          audit_notice: 'asdafasnfjasndfasdfasdfa'
        }
      ]
    }
  },
  mounted() {
    if (this.resetPwdForm.phone === '') {
      this.$refs.phone.focus()
    }
  },
  methods: {
    getCodes() {
      // 验证手机号
      this.$refs['resetPwdForm'].validateField('phone', res => {
        if (res) {
          return false
        } else {
          var data = {
            phone: this.resetPwdForm.phone,
            area_code:
              this.resetPwdForm.area_code === '+86' ? '86' : this.resetPwdForm.area_code.replace(/[^0-9]/gi, ''),
            is_login: 1,
            type: 5
          }
          if (this.resetPwdForm.area_code !== '+86') {
            data.scene = 'internation'
          }
          if (!this.sendCoding) {
            this.isLoading = true
            SendCode(data)
              .then(res => {
                this.sendCoding = true
                this.isLoading = false
                this.$message({
                  type: 'success',
                  message: '验证码已发送，请注意查收短信'
                })
                // 开始计时
                var stime = this.smsTimes
                var dtime = setInterval(() => {
                  stime--
                  if (stime === 0) {
                    this.sendCoding = false
                    clearInterval(dtime)
                    this.getCodeText = '获取验证码'
                  } else {
                    this.getCodeText = stime + ' 秒后重试'
                  }
                }, 1000)
              })
              .finally(e => {
                this.isLoading = false
              })
          } else {
            // this.$message('稍候再试')
            return false
          }
        }
      })
    },
    captcha_success() {
      // 验证手机号
      this.$refs['resetPwdForm'].validateField('phone', res => {
        if (res) {
          return false
        } else {
          if (!this.sendCoding) {
            this.$message('正在获取验证码')
            this.sendCoding = true
            this.$message({
              type: 'success',
              message: '验证码已发送，请注意查收短信'
            })
            // 开始计时
            var stime = this.smsTimes
            var dtime = setInterval(() => {
              stime--
              if (stime === 0) {
                this.sendCoding = false
                clearInterval(dtime)
                this.getCodeText = '获取验证码'
              } else {
                this.getCodeText = stime + ' 秒后重试'
              }
            }, 1000)
          } else {
            // this.$message('稍候再试')
            return false
          }
        }
      })
    },
    getCode() {
      // 验证手机号
      this.$refs['resetPwdForm'].validateField('phone', res => {
        if (res) {
          return false
        } else {
          if (!this.sendCoding) {
            this.showVerify = true
            setTimeout(() => {
              this.$refs.verify.show()
            }, 500)
          }
        }
      })
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect' && cur !== 'authority') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    submit() {
      this.$refs.resetPwdForm.validate(valid => {
        if (valid) {
          const data = {
            phone: this.resetPwdForm.phone,
            area_code:
              this.resetPwdForm.area_code === '+86' ? '86' : this.resetPwdForm.area_code.replace(/[^0-9]/gi, ''),
            code: this.resetPwdForm.code
          }
          this.loading = true
          data.phone = data.phone.trim()
          smsLogin(data)
            .then(res => {
              if (res.data.has_app === 1) {
                this.$store.dispatch('user/smsLogin', res.data)
                this.$message({
                  message: '登陆成功',
                  type: 'success'
                })
                this.getVenues()
              } else {
                this.$message({
                  message: res.data.msg + '正在为您跳转到注册页面',
                  type: 'warning',
                  duration: 2000
                })
                setTimeout(() => {
                  window.location = 'https://www.yuxiaojiu.com/freeRegister'
                }, 2000)
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取场馆
    getVenues() {
      getUserVenues()
        .then(res => {
          this.show2 = true
          this.venues_list = res.data
          this.venues_list_loading = false
          this.app_loading = false
        })
        .catch(() => { })
      getStaffSignIn().then(res => {
        this.toExamineList = res.data
      })
    },
    // 退出登录
    logout() {
      this.$confirm('此操作将退出账号登录状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.show2 = false
          this.$store.dispatch('user/logout')
          this.$message({ type: 'success', message: '退出成功!' })
        })
        .catch(() => {})
    },
    handleIntroduction(id) {
      this.show2 = false
      delStaffSignIn(id).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '退出成功!'
          })
          this.$store.dispatch('user/logout')
        }
      })
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          if (this.errorNum >= 2) {
            this.modals = true
          } else {
            this.modals = false
          }
        }
      })
    },
    // 格式化时间
    formatTime(val) {
      return this.$moment.unix(val).format('YYYY-MM-DD')
    },
    // 选择登录的场馆
    venuesBtn(item, Venues, ismanage) {
      // this.show3 = true
      // return false
      let item_s = '{"id":-1}'
      // 选择场馆ID
      localStorage.setItem('Appid', item.id)
      if (!ismanage) {
        if (Venues) item_s = JSON.stringify(Venues)
      } else {
        // 当前选中场馆信息
        localStorage.setItem('Venues', item_s)
        // 跳转到提现页面
        if (this.$route.query.referer_path === '/store/withdraw_apply/index') {
          this.$router.push({ path: this.$route.query.referer_path })
          return false
        }
      }
      // 用户超级管理员
      localStorage.setItem('is_super', item.is_super ? 'true' : 'false')
      // 当前选中场馆信息
      localStorage.setItem('Venues', item_s)
      getAppInfo()
        .then(res => {
          this.appInfo = res.data
          if (!res.data.logo) this.appInfo.logo = this.defaultLogo
          this.app_loading = false
          // APP信息
          localStorage.setItem('App', JSON.stringify(res.data))
          if (
            this.appInfo.expiration_time !== 0 &&
            this.appInfo.expiration_time <
              this.$moment()
                .add(7, 'days')
                .unix() &&
            item.is_super
          ) {
            const e = this.$moment.unix(this.appInfo.expiration_time).format('yyyy-MM-DD') // 开始时间
            const s = this.$moment().format('yyyy-MM-DD') // 结束时间
            var msg = this.appInfo.is_expire
              ? '当前品牌已过期，如需使用，请续费'
              : `当前品牌即将到期，剩余${this.$moment(e).diff(s, 'days')}天，请及时续费！`

            // 套餐过期，显示购买
            this.$confirm(msg, '提示', {
              confirmButtonText: '去续费',
              cancelButtonText: '再想想',
              type: 'warning'
            })
              .then(() => {
                const item_s = '{"id":-1}'
                localStorage.setItem('Venues', item_s)
                this.$router.push({ name: 's_plan' })
              })
              .catch(() => {
                this.$router.push({
                  path: this.redirect || '/',
                  query: this.otherQuery
                })
              })
          } else {
            this.$router.push({
              path: this.redirect || '/',
              query: this.otherQuery
            })
          }
        })
        .catch(() => {})
    },
    area_code(val) {
      this.resetPwdForm.area_code = val
    }
  }
}
</script>

<style lang="scss">
.reset-container {
  .reset-form {
    .el-input {
      input {
        width: 100%;
        height: 42px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        border-color: transparency;
        border: 1px;
        /* border: 1px solid #dae0e6; */
        /* border-radius: 4px; */
        padding: 0 20px;
        line-height: 42px;
        outline: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.reset-container {
  background: #f5f6f9 !important;
  height: inherit;
  // ::v-deep .el-form-item__content{
  //   overflow:hidden;
  // }
  .reset-header {
    .header-logo {
      .logo-img {
        width: 220px;
      }
    }
  }
  @keyframes scalein {
    from {
      -webkit-transform: scale(0.4);
      transform: scale(0.4);
    }
    to {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .reset-box {
    -webkit-box-shadow: 0px 2px 14px 0px rgb(0 0 0 / 6%);
    box-shadow: 0px 2px 14px 0px rgb(0 0 0 / 6%);
    -webkit-animation: scalein 0.3s;
    animation: scalein 0.3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 18px;
    overflow: hidden;
    height: 540px;
    .box-left {
      width: 480px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        display: block;
      }
    }
    .box-right {
      ::v-deep {
        /*** 消除input元素 type="number" 时默认的 加减按钮*/
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
      background: #ffffff;
      width: 420px;
      padding: 40px;
      height: 100%;
      position: relative;
      .termsService {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 12px;
        color: rgb(179, 179, 179);
        a:hover{
          color: rgb(20, 156, 175);
        }
      }
      .reset-logo {
        .reset-title {
          font-size: 38px;
          margin: 10px 0;
          text-align: center;
        }
        img {
          width: 100%;
        }
      }
      .input-item {
        /* margin: 20px 0; */
        input {
          width: 100%;
          height: 42px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          border: 1px solid #dae0e6;
          border-radius: 4px;
          padding: 0 20px;
          line-height: 42px;
          outline: none;
        }
      }
      .input-register {
        margin: 10px 0;
      }
      .submit {
        &:hover {
          background: linear-gradient(11deg, #01cbca, #1892aa);
        }
        display: block;
        height: 42px;
        background: linear-gradient(145deg, #01cbca, #1892aa);
        border-radius: 4px;
        margin: 0 auto;
        text-align: center;
        // line-height: 42px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        width: 100%;
        border: unset;
      }
      .submits {
        &:hover {
          background: #fff;
        }
        background: #fff;
        color: #1892aa;
        border: 1px solid #1892aa;
      }
      .get-code {
        color: #fff;
        position: absolute;
        right: 1px;
        top: 0px;
        cursor: pointer;
        user-select: none;
        background: #1892aa;
        padding: 4px 10px;
        border-radius: 4px;
        &:hover {
          background: #01cbca;
        }
      }
      .get-code.disabled {
        color: #9f9f9f;
        background: #d1f0f0;
      }
      .foot-btn {
        .btn_text {
          &:hover {
            color: #ffac5e;
          }
          cursor: pointer;
          font-size: 14px;
          // margin-bottom: 10px;

          span {
            &:first-of-type {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }

  footer {
    text-align: center;
    margin: 100px 20px 0 20px;
  }
}
.venues_img {
  margin-left: 5px;
}
.venues_word {
  width: 150px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

img {
  -webkit-user-drag: none;
}

.center {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-required {
  &:after {
    content: '*';
    color: red;
    line-height: 46px;
  }
  position: absolute;
  left: 8px;
  top: 2px;
  bottom: 0;
  vertical-align: middle;
  z-index: 99;
}

.two {
  .logout {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #909399;
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;
  }
  .logout:hover {
    color: #ff8719;
  }
  .venues_logo {
    width: 60px;
    height: 60px;
    // border: 1px solid #e2e2e2;
    border-radius: 6px;
  }

  .venues_name {
    padding-left: 20px;
  }

  .venues_app {
    padding-bottom: 10px;
  }

  .venues_app_management {
    background: #ff8719;
    color: #fff;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
  }
  .venues_list {
    .venues_list_empty {
      padding-top: 30px;
      font-size: 15px;
      text-align: center;
      // color:#f1c40f;
    }
    ul {
      list-style: none;
      padding: 0;
    }
    li {
      span {
        cursor: pointer;
        background: #41c3a8;
        color: #fff;
        display: inline-block;
        width: 213px;
        height: 48px;
        border-radius: 4px;
        border: 1px solid #41c3a8;
        line-height: 48px;
      }
      float: left;
      margin: 0;
      margin-right: 10px;
      margin-bottom: 15px;
      text-align: center;
    }
  }
}
.fix {
  height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid #dae0e6;
  border-radius: 4px;
  .CodeList {
    width: 100px;
    background: transparent;
    border-right: none;
    .el-input {
      .el-input__inner {
        border: none !important;
      }
    }
  }
}
.two {
  .logout {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #909399;
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;
  }
  .logout:hover {
    color: #ff8719;
  }
  .venues_logo {
    width: 60px;
    height: 60px;
    // border: 1px solid #e2e2e2;
    border-radius: 6px;
  }
  /* @media screen and (max-width: 460px) {
    .venues_selector {
      padding: 0px !important;
    }
  } */
  .venues_selector {
    display: flex;
    flex-direction: column;
    padding: 18px 12px 12px 12px;
    /* width: 710px; */
    margin: 0 auto;
    background-color: #FFFFFF;
    border-radius: 5px;
  }

  .venues_name {
    padding-left: 20px;
  }

  .venues_app {
    padding-bottom: 10px;
  }

  .venues_app_management {
    background: #ff8719;
    color: #fff;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
  }
  .venues_list {
    margin-top: 5px;
    padding: 0px 5px;
    .venues_item {
      text-align: center;
      cursor: pointer;
      background: #41c3a8;
      color: #fff;
      display: inline-block;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      border: 1px solid #41c3a8;
      line-height: 48px;
      margin: 0px 0px 10px 0px;
      overflow: hidden;
    }
    .venues_itemTwo {
      text-align: center;
      cursor: not-allowed;
      background: #41c3a983;
      color: #fff;
      display: inline-block;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      border: 1px solid #41c3a965;
      line-height: 48px;
      margin: 0px 0px 10px 0px;
      overflow: hidden;
      position: relative;
      .venues_itemTwoZ {
        position: absolute;
        /* z-index: 999; */
        top: -1px;
        right: -1px;
        font-size: 12px;
        width: 50px;
        line-height: 20px;
        background-color: #FFD099;
        border-radius: 4px;
      }
    }
    .venues_list_empty {
      padding-top: 30px;
      font-size: 15px;
      text-align: center;
      // color:#f1c40f;
      img {
        width: 160px;
      }
    }
  }
}
.type_center {
  /* width: 710px; */
  margin: 0 auto;
  border-radius: 5px;
  background-color: #FFFFFF;
  .type_centerTwo {
    padding: 18px 35px 20px 13px;
  }
  .type_bottom {
    border-top: 1px solid #EEEEEE;
    padding: 9px 12px 12px 13px;
    font-size: 14px;
    color: #333333;
    .words {
      font-size: 12px;
      color: #999999;
      padding-top: 8px;
    }
    .type_button {
      width: 100%;
      margin-top: 13px;
      width: 100px;
      line-height: 40px;
      background-color: #01CBCA;
      text-align: center;
      border-radius: 5px;
      color: #FFFFFF;
    }
  }
}
::v-deep .el-dialog__wrapper {
  .el-dialog {
    background: #F8F8F8;
  }
}
@media screen and (max-width: 900px) {
  .box-left {
    display: none;
  }
  .header-logo {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .reset-box {
    box-shadow: unset !important;
    border-radius: unset;
  }
  .reset-container {
    background: unset !important;
  }
}

@media screen and (max-width: 600px) {
  .venues_word {
    width: 100px;
  }
  .type_centerTwo {
    padding: 18px 13px 20px 13px !important;
  }
}
</style>
