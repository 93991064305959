import request from '@/utils/request'

/**
 * 获取列表
 */
export function getBanner(data) {
  return request({
    url: `/admin/v1/slide`,
    method: 'get',
    params: data
  })
}

/**
 * 获取详情
 */
export function getBannerInfo(id, data) {
  return request({
    url: `/admin/v1/slide/${id}`,
    method: 'get',
    params: data
  })
}

/**
 * 添加
 */
export function addBanner(data) {
  return request({
    url: `/admin/v1/slide`,
    method: 'post',
    data
  })
}

// 修改
export function editBanner(id, data) {
  return request({
    url: `/admin/v1/slide/${id}`,
    method: 'put',
    data
  })
}

// 删除
export function delBanner(id, data) {
  return request({
    url: `/admin/v1/slide/${id}`,
    method: 'delete',
    params: data
  })
}
