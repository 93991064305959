<template>
  <div class="app-container">
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="left">
        <el-form-item label="操作时间">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <!-- 选择课程 -->
        <el-form-item label="放假状态">
          <el-select v-model="listQueryParams.status" placeholder="放假状态" clearable>
            <el-option label="待放假" :value="0" />
            <el-option label="放假中" :value="1" />
            <el-option label="已取消" :value="3" />
          </el-select>
        </el-form-item>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </el-form>
    </div>
    <div style="margin-bottom: 20px">
      <el-input
        v-model="listQueryParams.query"
        class="ssl_input"
        placeholder="放假事由"
        style="width: 300px;"
        @keyup.enter.native="handleFilter"
      >
        <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
      </el-input>
      <el-button type="primary" icon="el-icon-plus" class="frs" @click="handelDialogVisible()">放 假</el-button>
    </div>
    <div class="app-container">
      <el-table ref="multipleTable" v-loading="loading" :data="tableData.list" row-key="id">
        <!-- <el-table-column prop="id" label="ID" align="center" width="80px" /> -->
        <el-table-column prop="venues.name" label="场馆名" width="80px" align="center" />
        <el-table-column prop="start_time" label="放假时间" min-width="160px" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            {{ scope.row.start_time | formatDate('YYYY-MM-DD HH:mm') }} -
            {{ scope.row.end_time | formatDate('YYYY-MM-DD HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="is_cancel" label="状态" align="center" min-width="180px">
          <template slot-scope="{ row }">
            <div
              :class="{
                'text-danger': row.status == 1,
                'text-info': row.status == 0,
                'text-warning': row.status == 3,
                'text-success': row.status == 2
              }"
            >
              {{ row.status_text }}
              <p v-if="row.is_cancel">取消时间:{{ row.cancel_time | formatDate('YYYY-MM-DD HH:mm') }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="store_user.real_name" label="操作人" align="center" width="80px" />
        <el-table-column prop="create_time" label="操作时间" min-width="150px" align="center" />
        <el-table-column label="放假事由" min-width="150px" align="center">
          <template slot-scope="scope">
            {{ scope.row.notice ? scope.row.notice : '无' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100px" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 0 || scope.row.status == 1"
              slot="reference"
              type="danger"
              @click="cancelHoliday(scope.row.id)"
            >
              取消放假
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="tableData.count > 0"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
    <record :id="venues_id" :visible-log="dialogVisible" @visibleCZ="visibleLogNo" />
  </div>
</template>

<script>
// API
import { holidayLogsVenues, holidayVenuesCancel } from '@/api/store/venues.js'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Record from './logs/record.vue'

export default {
  components: { Pagination, Record },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10
      },
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      dialogVisible: false,
      venues_id: '',
      searchLoading: false
    }
  },
  created() {
    this.getList()
    if (this.utils.getVenues().id === -1) {
      this.utils.setPageTitle(this.$route, '编辑场馆', 1)
      this.utils.setTagsViewTitle(this.$route, '编辑场馆')
      this.venues_id = this.$route.params.id
    } else {
      this.venues_id = this.utils.getVenues().id
    }
  },
  methods: {
    // 获取列表
    getList() {
      this.loading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      const querys = []
      if (this.listQueryParams.query) {
        querys.push({
          field: 'notice',
          type: 'like',
          key: this.listQueryParams.query
        })
      }
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.status !== null && typeof this.listQueryParams.status === 'number') {
        querys.push({ field: 'status', key: this.listQueryParams.status })
      }

      data.query = this.utils.getQueryParams(querys)

      holidayLogsVenues(this.venues_id, data)
        .then(res => {
          this.tableData = res.data
          this.searchLoading = false
          this.loading = false
        })
        .catch(() => {})
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    cancelHoliday(id) {
      this.$confirm('场馆放假时间截止当日，将扣减会员卡多余放假天数，确定取消场馆放假吗？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          holidayVenuesCancel(id).then(res => {
            this.$message.success(res.msg)
            this.getList()
          })
        })
        .catch(() => {})
    },
    handelDialogVisible() {
      this.dialogVisible = true
    },
    visibleLogNo() {
      this.dialogVisible = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
#list {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  .app-container {
    padding: 10px 20px;
  }
}
.el-button--text {
  color: #000;
}

.frs {
  margin: 0 0 0 10px;
}
</style>
