var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "config_box",
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.configFormData, function(item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { name: item.id.toString() } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                item.icon ? _c("i", { class: item.icon }) : _vm._e(),
                _vm._v(" " + _vm._s(item.name) + " ")
              ]),
              item.children
                ? _c(
                    "div",
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { type: "border-card" },
                          on: { "tab-click": _vm.childrenClick },
                          model: {
                            value: _vm.formID,
                            callback: function($$v) {
                              _vm.formID = $$v
                            },
                            expression: "formID"
                          }
                        },
                        _vm._l(item.children, function(child, i) {
                          return _c(
                            "el-tab-pane",
                            { key: i, attrs: { name: child.id.toString() } },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  child.icon
                                    ? _c("i", { class: child.icon })
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(child.name) + " ")
                                ]
                              ),
                              _c("form-create", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.Loading,
                                    expression: "Loading"
                                  }
                                ],
                                ref: "fc",
                                refInFor: true,
                                staticClass: "formBox",
                                attrs: {
                                  option: _vm.option,
                                  rule: _vm.formData,
                                  "handle-icon": "false"
                                },
                                on: { submit: _vm.onSubmit }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("form-create", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.Loading,
                            expression: "Loading"
                          }
                        ],
                        ref: "fc",
                        refInFor: true,
                        staticClass: "formBox",
                        attrs: {
                          option: _vm.option,
                          rule: _vm.formData,
                          "handle-icon": "false"
                        },
                        on: { submit: _vm.onSubmit }
                      })
                    ],
                    1
                  )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }