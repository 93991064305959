<template>
  <div class="title_style">
    <el-form ref="form" :model="form" :rules="formRules" size="small" label-width="80px">
      <el-form-item label="旧密码" prop="old_password">
        <el-input v-model="form.old_password" type="password" />
      </el-form-item>
      <el-form-item label="新密码" prop="new_password">
        <el-input v-model="form.new_password" type="password" />
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPass">
        <el-input v-model="form.confirmPass" type="password" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updatePassword } from '@/api/user.js'
export default {
  props: {
    user: {
      type: Object,
      default: function() {
        return { name: '' }
      }
    }
  },
  data() {
    const confirmPass = (rule, value, callback) => {
      if (value) {
        if (this.form.new_password !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请再次输入密码'))
      }
    }
    return {
      form: {},
      formRules: {
        old_password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        confirmPass: [{ required: true, validator: confirmPass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          delete this.form['confirmPass']
          updatePassword(this.form)
            .then(res => {
              this.$message.success('修改成功！')
              this.form = {}
            })
            .catch(() => {})
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.title_style {
  width: 45%;
}
@media screen and (max-width: 768px) {
  .title_style {
    width: 100%;
  }
}
</style>
