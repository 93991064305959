<template>
  <div id="bbbb" class="app-container">
    <div class="filter-container">
      <div class="filter-item">
        <el-input v-model="listQuery.query" placeholder="搜索" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button
        type="primary"
        icon="el-icon-plus"
        class="filter-item m-l-10"
        @click="$router.push({ name: 's_management_add' })"
      >
        添加
      </el-button>
      <!-- <el-button type="primary" icon="el-icon-plus" class="filter-item m-l-10" @click="add_edit()">添加</el-button> -->
    </div>
    <el-table ref="multipleTable" :data="tableData.list" row-key="id">
      <!-- <el-table-column prop="id" label="ID" /> -->

      <!-- <el-table-column prop="account" label="账号" /> -->
      <el-table-column prop="avatar" label="头像">
        <template slot-scope="{ row }">
          <div class="block">
            <el-avatar shape="square" :src="row.avatar">
              <img :src="errorAvatarSrc(row, 'img')">
            </el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="real_name" label="姓名" />
      <el-table-column prop="phone" label="电话">
        <template slot-scope="{ row }">
          {{ row.phone }}
        </template>
      </el-table-column>
      <el-table-column prop="sex" label="性别" :formatter="formatTableSex" width="80" />
      <el-table-column prop="is_private" label="教练类型" min-width="140">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_gold === 1" class="m-r-5 m-b-5" type="danger">金牌教练</el-tag>
          <el-tag v-if="row.is_group" class="m-r-5 m-b-5">团课教练</el-tag>
          <el-tag v-if="row.is_private" type="warning">私教课教练</el-tag>
          <span v-if="!row.is_group && !row.is_private">无</span>
        </template>
      </el-table-column>

      <el-table-column prop="star_level" label="星级">
        <template slot-scope="{ row }">
          <el-rate :value="row.star_level >= 5 ? 5 : parseInt(row.star_level)" disabled text-color="#ff9900" />
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column prop="update_time" label="更新时间" />
      <el-table-column label="操作" min-width="160">
        <template slot-scope="scope">
          <el-button
            v-show="btn_access('s_management_delete')"
            slot="reference"
            class="m-2"
            type="primary"
            icon="el-icon-edit"
            :offset="2"
            @click="$router.push({ name: 's_management_edit', params: { id: scope.row.id } })"
          >
            编辑
          </el-button>
          <el-popconfirm
            v-if="scope.row.is_super !== 1"
            title="删除后将无法找回，确定继续吗？"
            @confirm="store_user_delete(scope.row.id)"
          >
            <el-button
              v-show="btn_access('s_management_delete')"
              slot="reference"
              class="m-2"
              type="danger"
              icon="el-icon-delete"
              :offset="2"
            >
              删除
            </el-button>
          </el-popconfirm>
          <!-- 更多 -->
          <el-dropdown class="course" :hide-on-click="false">
            <el-button type="info">
              更多
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="scope.row.is_super !== 1" @click.native="store_user_quit(scope.row.id)">
                <template>
                  <i class="el-icon-s-open" />
                  一键离职
                </template>
              </el-dropdown-item>
              <el-dropdown-item @click.native="handleEdit(scope)">
                <template>
                  <i class="el-icon-user-solid" />
                  作息时间
                </template>
              </el-dropdown-item>
              <el-dropdown-item @click.native="handleAdd(scope)">
                <template>
                  <i class="el-icon-camera-solid" />
                  员工相册
                </template>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.id ? '编辑账号' : '添加账号'"
      class="dialog_w750"
    >
      <el-form ref="ruleForm" :rules="rules" :model="form" label-position="left" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="账号头像" prop="avatar">
              <div class="avatar-block avatar-box" @click="imagecropperShow = true">
                <el-avatar shape="square" :size="100" :src="form.avatar" :title="dialog.id ? '点击修改' : '点击上传'">
                  <img :src="errorImageSrc">
                </el-avatar>
                <div class="avatar-hover">{{ dialog.id ? '更换头像' : '上传头像' }}</div>
              </div>
              <image-cropper
                v-show="imagecropperShow"
                :key="imagecropperKey"
                field="file"
                :width="300"
                :height="300"
                :url="storeUpload"
                lang-type="zh"
                @close="imagecropperClose"
                @crop-upload-success="cropSuccess"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="账号名" prop="account">
              <el-input v-model="form.account" placeholder="请输入账号名" />
            </el-form-item>
          </el-col> -->
          <el-col v-if="dialog.id" :span="12">
            <el-form-item label="密码" :prop="dialog.id ? '' : 'password'">
              <el-input v-model="form.password" type="text" placeholder="不改请为空" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="real_name">
              <el-input v-model="form.real_name" placeholder="请输入姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="form.phone" type="number" placeholder="请输入手机号" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="生日" prop="birthday">
              <el-date-picker v-model="form.birthday" type="date" value-format="yyyy-MM-dd" placeholder="请选择日期" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="form.sex">
                <el-radio v-for="(item, index) in sexList" :key="index" :label="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="星级" prop="star_level">
              <el-rate v-if="dialog.id" v-model="form.star_level" :colors="colors" />
              <el-rate v-else v-model="form.star_level" :colors="colors" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="团课教练" prop="is_group">
              <el-select v-model="form.is_group" placeholder="请选择">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="私课教练" prop="is_private">
              <el-select v-model="form.is_private" placeholder="请选择">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="课程颜色" prop="color">
              <div class="flex flex-wrap row-between col-center">
                <div><el-color-picker v-model="form.color" :style="{ paddingTop: '10px' }" /></div>
                <div>
                  <el-select v-model="defaultColorValue" placeholder="快速选色" clearable @change="defaultColor">
                    <el-option v-for="(item, index) in courseDefaultColorList" :key="index" :value="item" :label="item">
                      <div class="defaultColorItem" :style="{ background: item }">{{ item }}</div>
                    </el-option>
                  </el-select>
                </div>
              </div>

              <br>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <el-dialog title="员工相册" :visible.sync="dialogVisibles" width="30%" :before-close="handleClose">
      <div v-loading="dialogLoading">
        <el-upload
          v-loading="upLoading"
          field="file"
          :action="url + '/store/v2/store_user/photo/' + id"
          list-type="picture-card"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="upSuccess"
          :headers="utils.upload_headers()"
          :file-list="fileList"
          :limit="9"
          :on-exceed="handleExceed"
          :on-progress="upProgress"
          :before-upload="checkFileType"
          accept=".jpg,.jpeg,.png"
        >
          <i class="el-icon-plus" />
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" class="dialog_view_image">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <small class="text-info">图片尺寸建议750*1000</small>
      </div>
    </el-dialog>
    <!-- 作息时间 -->
    <Dialog :dialog-visible-display="dialogVisibleDisplay" :user-id="userId" @close="close" />
  </div>
</template>

<script>
import {
  getStoreUser,
  addStoreUser,
  editStoreUser,
  getStoreUserDetails,
  delStoreUser,
  deleteImges,
  postQuit
} from '@/api/store/management.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import ImageCropper from '@/components/ImageCropper' // 头像上传
import Dialog from './dialog.vue' // 作息时间
import { deepClone } from '@/utils'
import { getImgs } from '@/api/store/config.js'
export default {
  components: { Pagination, ImageCropper, Dialog },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      dialog: { visible: false, id: '' },
      form: {
        sex: 1,
        is_group: 0,
        is_private: 0,
        avatar: '',
        color: null,
        area_code: '+86'
      },
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      rules: ['password', 'real_name', 'phone', 'birthday', 'sex'],
      imagecropperShow: false,
      imagecropperKey: 0,
      typeOptions: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      searchLoading: false,
      defaultColorValue: '',
      dialogVisibles: false,
      dialogImageUrl: '',
      dialogVisible: false,
      dialogVisibleDisplay: false,
      userId: 0,
      fileList: [],
      id: 0,
      url: process.env.VUE_APP_BASE_API,
      dialogLoading: false
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.listQuery.page = 1
      this.searchLoading = true
      this.getList()
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      if (this.listQuery.query) {
        data.query = this.utils.getLikeQueryParams('account|phone|real_name', this.listQuery.query)
      }
      getStoreUser(data)
        .then(res => {
          this.tableData = res.data
          this.searchLoading = false
        })
        .catch(() => {})
    },
    toPage(id) {
      this.$router.push({ name: 's_management_role_edit', params: { id: id }})
    },
    add_edit(id) {
      this.dialog = { visible: true, id: id }
      this.form = {
        sex: 1,
        is_group: 0,
        is_private: 0
      }
      this.defaultColorValue = ''
      if (id) {
        getStoreUserDetails(id)
          .then(res => {
            this.form = res.data
            this.form.password = ''
            this.form.star_level = parseInt(this.form.star_level)
          })
          .catch(() => {})
      }
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.dialog.id) {
            // 修改资料
            const data = deepClone(this.form)
            delete data['appid']
            editStoreUser(this.dialog.id, data)
              .then(res => {
                this.callback('修改成功')
              })
              .catch(() => {})
          } else {
            // 添加管理员
            addStoreUser(this.form)
              .then(res => {
                this.callback('添加成功')
              })
              .catch(() => {})
          }
        }
      })
    },
    callback(msg) {
      this.dialog.visible = false
      this.$message.success(msg)
      this.getList()
    },
    // 头像上传成功
    cropSuccess(resData) {
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      this.$set(this.form, 'avatar', resData.path)
    },
    // 头像上传弹出层关闭
    imagecropperClose() {
      this.imagecropperShow = false
    },
    defaultColor(i, v) {
      this.form.color = i
      // console.log('🚀 ~ file: index.vue ~ line 291 ~ defaultColor ~ i,v', i, v)
    },
    // 删除管理员
    store_user_delete(id) {
      delStoreUser(id).then(res => {
        this.getList()
        this.$message.success(res.msg)
      })
    },
    handleAdd(scope) {
      this.id = scope.row.id
      this.dialogVisibles = true
      this.getVisible()
    },
    getVisible() {
      this.dialogLoading = true
      getImgs(this.id).then(res => {
        var imagesArrs = []
        res.data.list.forEach(function(i) {
          imagesArrs.push({
            name: '123123',
            url: i.image,
            id: i.id
          })
        })
        this.fileList = imagesArrs
        this.dialogLoading = false
      })
    },
    handleClose(done) {
      done()
      this.fileList = []
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        // this.$message.error(response.msg)
        return
      }
      // this.fileList = []
      this.getVisible()
    },
    checkFileType(file) {
      if (file.size / 4000 / 4000 > 0.1) {
        this.$message.warning('上传图片过大，请重新上传！')
        return false
      }
    },
    upProgress() {
      this.upLoading = true
    },
    handleRemove(file, fileList) {
      if (file.id === undefined) return false
      this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteImges(file.id)
        setTimeout(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      }).catch(() => {
        this.getVisible()
      })
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleExceed(files, fileList) {
      this.$message.warning(`仅支持上传九张相册图片`)
    },
    // 作息时间
    handleEdit(scope) {
      this.userId = scope.row.id
      this.dialogVisibleDisplay = true
    },
    close() {
      this.dialogVisibleDisplay = false
    },
    // 一键离职
    store_user_quit(id) {
      this.$confirm('一键离职针对所有场馆,离职后该员工将不能登录该品牌,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        postQuit(id).then(res => {
          this.getList()
          this.$message({
            message: '操作成功',
            type: 'success'
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-avatar {
  background: none;
}
.defaultColorItem {
  width: 100%;
  background: red;
  text-align: center;
  margin: 1px 0px;
  color: #fff;
}
.avatar-block {
  display: unset;
}

::v-deep .el-upload-list__item {
  transition: none !important;
}
</style>
