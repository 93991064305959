import { Message } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'
import axios from 'axios'

const validateRequire = (rule, value, callback) => {
  if (!value && value !== 0) {
    // this.$message({message: rule.label + '为必传项',type: 'error'// })
    callback(new Error('不能为空'))
  } else {
    callback()
  }
}
let timer, flag
export default {
  timer,
  /*
	*验证1.0
	* validator: this.utils.validateRequire
	*/
  validateRequire,
  /*
	*验证2.0
	*this.utils.validate({trigger: 'blur'}) 可以往里面加参数
	*/
  validate(data) {
    let data1 = { required: true, validator: this.validateRequire }
    if (data) data1 = Object.assign({ required: true, validator: this.validateRequire }, data)
    return [data1]
  },
  /*
	*验证3.0
	* 如果要加特殊{trigger: 'blur'}就用validate
	*/
  validateFor(data) {
    const data1 = {}
    data.forEach((val, index) => {
      data1[val] = this.validate()
    })
    return data1
  },
  // 区分json字符串和字符串
  isJsonObj(str) {
    try {
      if (typeof JSON.parse(str) === 'object') {
        return true
      }
    } catch (e) {
      console.log('🚀 ~ file: utils.js ~ line 40 ~ isJsonObj ~ e', e)
    }
    return false
  },
  // form-create josn字符串转换
  fcJosn(data) {
    data.forEach((val, index) => {
      if (this.isJsonObj(val['props'])) { val['props'] = JSON.parse(val['props']) } else { delete val['props'] }
      if (this.isJsonObj(val['options'])) { val['options'] = JSON.parse(val['options']) } else { delete val['options'] }
      if (this.isJsonObj(val['validate'])) { val['validate'] = JSON.parse(val['validate']) } else { delete val['validate'] }
      if (this.isJsonObj(val['value']) && val['type'] !== 'input') { val['value'] = JSON.parse(val['value']) }
      if (val['type'] === 'upload') {
        const config = {}
        if (val.props.limit) config['limit'] = val.props.limit
        if (val.props.format) config['format'] = val.props.format
        val['props'] = Object.assign(val['props'], this.fcUploadProps(config))
      }
    })
    return data
  },
  // form-create上传封装
  fcUploadProps(data) {
    return {
      // uploadType: "file", //image或 file
      name: 'file', // name属性 上传接口的字段名
      // multiple: true, // 是否可多选
      // // accept: "image/*", // 上传文件类型,选择时排除文件
      // limit: 5, // 上传文件数量最大值
      action: '/admin/v1/upload', // 上传后端接收API接口
      // format:['jpg','png'], //文件格式筛选

      // 以上是示例 具体参考 http://www.form-create.com/
      onSuccess: function(res, file) {
        // console.log(res)
        file.url = res.data.file_id
        // return ""; // 上传成功回调函数
      },
      onExceed: (files, fileList) => { // 文件超出个数限制时的钩子
        Message({
          message: `不能超过${data.limit}张!`,
          type: 'error',
          duration: 5 * 1000
        })
      },
      beforeUpload: (file, fileList) => {
        let isFormat = false // 上传图片的格式
        if (data.format) {
          const fileFormat = file.name.split('.').pop()
          data.format.forEach((val, index) => {
            if (fileFormat === val) {
              isFormat = true
            }
          })
          if (!isFormat) {
            Message({
              message: `上传头像图片只能是 ${data.format.join('、')} 格式!`,
              type: 'error',
              duration: 5 * 1000
            })
          }
        }
        return isFormat
      }
    }
  },

  // 详情页设置tagsview标题
  setTagsViewTitle(route, title, type = 0) {
    const route_1 = Object.assign({}, route, { title: `${title}-${route.params.id}` })
    if (type === 1) route_1.title = title
    store.dispatch('tagsView/updateVisitedView', route_1)
  },
  // 详情页设置页面标题
  setPageTitle(route, title, type = 0) {
    if (type === 1) document.title = `${title}`
    else document.title = `${title} - ${route.params.id}`
  },
  // 获取当前场馆信息
  getVenues() {
    return JSON.parse(localStorage.getItem('Venues'))
  },
  getApp() {
    return JSON.parse(localStorage.getItem('App'))
  },
  getAppId() {
    return localStorage.getItem('Appid')
  },
  // 获取api网址
  getWebUrl() {
    const config = require('/vue.config.js')
    return config.devServer.proxy
  },
  /**
   * 判断是否为空
   */
  empty(value) {
    switch (typeof value) {
      case 'undefined':
        return true
      case 'string':
        if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length === 0) return true
        break
      case 'boolean':
        if (!value) return true
        break
      case 'number':
        if (value === 0 || isNaN(value)) return true
        break
      case 'object':
        if (value === null || value.length === 0) return true
        for (var i in value) {
          return false
        }
        return true
    }
    return false
  },
  // 获取当前日期
  getDayTime() {
    var date = new Date()
    // 获取当前月份
    var nowMonth = date.getMonth() + 1
    // 获取当前是几号
    var strDate = date.getDate()
    // 添加分隔符“-”
    var seperator = '-'
    // 对月份进行处理，1-9月在前面添加一个“0”
    if (nowMonth >= 1 && nowMonth <= 9) {
      nowMonth = '0' + nowMonth
    }
    // 对天进行处理，1-9号在前面添加一个“0”
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate
    }
    // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
    var nowDate = date.getFullYear() + seperator + nowMonth + seperator + strDate
    return nowDate
  },
  // 时间加减
  addDate(startDate, days) {
    startDate = new Date(startDate)
    startDate = +startDate + days * 1000 * 60 * 60 * 24
    startDate = new Date(startDate)

    // 获取当前月份
    var Month = startDate.getMonth() + 1
    // 获取当前是几号
    var strDate = startDate.getDate()
    // 对月份进行处理，1-9月在前面添加一个“0”
    if (Month >= 1 && Month <= 9) {
      Month = '0' + Month
    }
    // 对天进行处理，1-9号在前面添加一个“0”
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate
    }
    var nextStartDate = startDate.getFullYear() + '-' + (Month) + '-' + strDate
    return nextStartDate
  },
  /**
   * 获取日期中时分
   */
  getHI(time) {
    var a = new Date(time)
    var hour = a.getHours()
    var minutes = a.getMinutes()
    // 对天进行处理，1-9号在前面添加一个“0”
    if (minutes >= 0 && minutes <= 9) {
      minutes = '0' + minutes
    }
    return hour + ':' + minutes
  },
  /**
   * 调试信息输出
   */
  dd() {
    for (var i = 0, j = arguments.length; i < j; i++) {
      console.log(arguments[i])
    }
  },
  isArray(arr) {
    return Object.prototype.toString.call(arr) === '[object Array]'
  },
  // 构造搜索参数
  getQueryParam(query) {
    return { query: JSON.stringify(query) }
  },
  getLikeQueryParams(field, query) {
    return JSON.stringify([[field, 'like', query + '%']])
  },
  // 搜索关键词
  // [ {field:'store_user_id',key:that.Info.id}, {field:'type',key:1}, ]
  getQueryParams(query) {
    const queryArray = []
    query.forEach(item => {
      let queryKey = item.key
      switch (item.type) {
        case 'like':
        case 'like-a':
          queryKey = '%' + item.key + '%'
          queryArray.push([item.field, 'like', queryKey])
          break
        case 'like-s':
          queryKey = '%' + item.key
          queryArray.push([item.field, 'like', queryKey])
          break
        case 'like-e':
          queryKey = item.key + '%'
          queryArray.push([item.field, 'like', queryKey])
          break
        case 'between-time':
          queryKey = [item.key[0] + ' 00:00:00', item.key[1] + ' 23:59:59']
          queryArray.push([item.field, 'between time', queryKey])
          break
        case 'lt':
          queryKey = item.key
          queryArray.push([item.field, '<', queryKey])
          break
        case 'gt':
          queryKey = item.key
          queryArray.push([item.field, '>', queryKey])
          break
        case 'egt':
          queryKey = item.key
          queryArray.push([item.field, '>=', queryKey])
          break
        case 'elt':
          queryKey = item.key
          queryArray.push([item.field, '<=', queryKey])
          break
        default:
          queryArray.push([item.field, '=', queryKey])
          break
      }
    })
    return JSON.stringify(queryArray)
  },
  errorHandler() {
    return true
  },
  // 判断对象/数组中是否存在指定key
  ObjHasKey(o, k) {
    return Object.prototype.hasOwnProperty.call(o, k)
  },
  // ajax处理下载
  handleDownloads(params) {
    const baseURL = params.baseURL ? params.baseURL : ''
    var xhr = new XMLHttpRequest()
    var formData = new FormData()
    xhr.open('get', baseURL + params.url, true) // url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
    xhr.setRequestHeader('Authorization', 'Bearer ' + getToken())
    xhr.setRequestHeader('Venues-Id', this.getVenues().id)
    xhr.setRequestHeader('App-Id', localStorage.getItem('Appid') ? localStorage.getItem('Appid') : '')
    xhr.responseType = 'blob'
    xhr.onload = function(e) {
      if (this.status === 200) {
        var blob = this.response
        var filename = params.fileName
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename)
        } else {
          var a = document.createElement('a')
          var url = (window.URL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob)
          a.href = url
          a.download = filename
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        }
      }
    }
    xhr.send(formData)
  },
  // axios处理下载
  async handleDownload(params) {
    const baseURL = params.baseURL ? params.baseURL : ''
    const url = baseURL + params.url
    const response = await axios.get(url, {
      headers: {
        'Authorization': 'Bearer ' + getToken(),
        'Venues-Id': this.getVenues() ? this.getVenues().id : '',
        'App-Id': localStorage.getItem('Appid') ? localStorage.getItem('Appid') : ''
      },
      responseType: 'blob'
    })

    console.log('🚀 ~ file: utils.js ~ line 316 ~ handleDownload ~ response', response)

    if (response.data.type === 'application/json') {
      Message({
        message: `下载失败，请稍候重试`,
        type: 'error',
        duration: 5 * 1000
      })
      return {
        code: false
      }
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', params.fileName)
      document.body.appendChild(link)
      link.click()
      return {
        code: true
      }
    }
  },
  downloadIamge(imgsrc, name) { // 下载图片地址和图片名
    var image = new Image()
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = function() {
      var canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      var context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
      var a = document.createElement('a') // 生成一个a元素
      // var event = new MouseEvent('click') // 创建一个单击事件
      a.download = name || 'photo' // 设置图片名称
      a.href = url // 将生成的URL设置为a.href属性

      a.click()
      a.remove()
      URL.revokeObjectURL(a.href)

      // a.dispatchEvent(event) // 触发a的单击事件
    }
    image.src = imgsrc
  },
  // 下载paf文件
  downloadPaf(pdfContent, fileName) {
    fetch(pdfContent)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
  },
  // 类似array_column函数，可将数组中指定值内容作为键名
  getObjects(obj, key, val) {
    var objects = []
    for (var i in obj) {
      if (!this.ObjHasKey(obj, i)) continue
      if (typeof obj[i] === 'object') {
        objects = objects.concat(this.getObjects(obj[i], key, val))
      } else
      // if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
      if (i === key && obj[i] === val || i === key && val === '') { //
        objects.push(obj)
      } else if (obj[i] === val && key === '') {
        // only add if the object is not already in the array
        if (objects.lastIndexOf(obj) === -1) {
          objects.push(obj)
        }
      }
    }
    return objects
  },
  // return an array of values that match on a certain key
  getValues(obj, key) {
    var objects = []
    for (var i in obj) {
      if (!this.ObjHasKey(obj, i)) continue
      if (typeof obj[i] === 'object') {
        objects = objects.concat(this.getValues(obj[i], key))
      } else if (i === key) {
        objects.push(obj[i])
      }
    }
    return objects
  },
  // return an array of keys that match on a certain value
  getKeys(obj, val) {
    var objects = []
    for (var i in obj) {
      if (!this.ObjHasKey(obj, i)) continue
      if (typeof obj[i] === 'object') {
        objects = objects.concat(this.getKeys(obj[i], val))
      } else if (obj[i] === val) {
        objects.push(i)
      }
    }
    return objects
  },
  /**
   * 节流原理：在一定时间内，只能触发一次
   *
   * @param {Function} func 要执行的回调函数
   * @param {Number} wait 延时的时间
   * @param {Boolean} immediate 是否立即执行
   * @return null
   */
  throttle(func, wait = 500, immediate = true) {
    if (immediate) {
      if (!flag) {
        flag = true
        // 如果是立即执行，则在wait毫秒内开始时执行
        typeof func === 'function' && func()
        timer = setTimeout(() => {
          flag = false
        }, wait)
      }
    } else {
      if (!flag) {
        flag = true
        // 如果是非立即执行，则在wait毫秒内的结束处执行
        timer = setTimeout(() => {
          flag = false
          typeof func === 'function' && func()
        }, wait)
      }
    }
  },
  // 上传头
  upload_headers() {
    return {
      Authorization: 'Bearer ' + getToken(),
      'Venues-Id': this.getVenues() ? this.getVenues().id : '',
      'App-Id': localStorage.getItem('Appid') ? localStorage.getItem('Appid') : ''
    }
  },
  // 清除html标签
  escapeHTML(untrusted) {
    let content = untrusted.replace(/<.+?>/g, '')
    /* 去除  */
    content = content.replace(/ /ig, '')
    // 去除nbsp;
    content = content.replace(/&nbsp;/ig, '')
    /* 去除空格 */
    content = content.replace(/\s/ig, '')
    return content
  },
  // base64转二进制
  convertBase64UrlToBlob(urlData) {
    var mimeString = urlData.split(',')[0].split(':')[1].split(';')[0] // mime类型
    var byteString = atob(urlData.split(',')[1]) // base64 解码
    var arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
    var intArray = new Uint8Array(arrayBuffer) // 创建视图
    for (var i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i)
    }
    return new Blob([intArray], { type: mimeString })
  },
  // axios上传
  uploadFile(data) {
    return new Promise((resolve, reject) => {
      var imgFile = this.convertBase64UrlToBlob(data.img)
      var formData = new FormData()
      formData.append('file', imgFile, 'image.png')
      const config = {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          'Venues-Id': this.getVenues() ? this.getVenues().id : '',
          'App-Id': localStorage.getItem('Appid') ? localStorage.getItem('Appid') : ''
        }
      }
      axios.post(data.url, formData, config).then(response => {
        resolve(response.data)
      })
    })
  },
  // 随机数
  getRandomIntInclusive(min, max, type = 0) {
    min = Math.ceil(min)
    max = Math.floor(max)
    const number = Math.floor(Math.random() * (max - min + 1)) + min
    return number.toString().padStart(type, '0')
  },
  converToUrl(requestParams) {
    const params = []
    Object.entries(requestParams).forEach(([key, value]) => {
      const param = key + '=' + value
      params.push(param)
    })
    return '?' + params.join('&')
  },
  /**
   * @description 获取某个对象下的属性，用于通过类似'a.b.c'的形式去获取一个对象的的属性的形式
   * @param {object} obj 对象
   * @param {string} key 需要获取的属性字段
   * @returns {*}
   */
  getProperty(obj, key) {
    if (!obj) {
      return
    }
    if (typeof key !== 'string' || key === '') {
      return ''
    }
    if (key.indexOf('.') !== -1) {
      const keys = key.split('.')
      let firstObj = obj[keys[0]] || {}

      for (let i = 1; i < keys.length; i++) {
        if (firstObj) {
          firstObj = firstObj[keys[i]]
        }
      }
      return firstObj
    }
    return obj[key]
  }

}
