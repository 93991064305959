<template>
  <div v-loading="loading" class="locker-box">
    <div v-if="!is_empty">
      <div class="header flex flex-wrap  row-between m-y-20">
        <div class="flex-1 flex text-left">
          <div class="flex">
            <div class="bg-item bg-item-use" />
            <div class="m-l-5 text-info">已使用</div>
          </div>
          <div class="flex m-l-10">
            <div class="bg-item bg-item-used" />
            <div class="m-l-5 text-info">未使用</div>
          </div>
        </div>
        <div class="flex-1 text-center">
          <div class="font-16">
            已编号储物柜<span class="text-number m-x-4 font-18">{{ tableData.count }}</span>个，已占用<span class="text-danger m-x-4 font-18">{{ use_count }}</span>个，可用<span class="text-number m-x-4 font-18 ">{{ used_count }}</span>个
          </div>
        </div>
        <div class="flex-1 text-right ">
          <el-button v-if="!is_del&&!is_edit" type="danger" @click="is_del = true">删除</el-button>
          <el-button v-if="is_del||is_edit" type="primary" @click="is_del = false;is_edit= false">完成</el-button>
          <el-button v-if="!is_edit&&!is_del" type="warning" @click="is_edit = true">编辑</el-button>

        </div>
      </div>
      <div class="content ">
        <el-row :gutter="10" class="block-box">
          <el-col v-for="(item,index) in tableData.list" :key="index" :xs="12" :sm="12" :md="8" :lg="3" :xl="3">
            <div class="block-item flex row-between cursor" :class="{'active':item.status}" @click="getItemLog(item)">
              <div>
                <div class="number m-b-20 cursor line-1" :title="item.serial_number+'号'">{{ item.serial_number }}号</div>
                <div class="status line-1" :title="(item.record?getUserNames(item.record.user):'')">{{ item.status?(item.record?getUserNames(item.record.user):'--'):'未占用' }}</div>
                <div class="btn" @click.stop>
                  <div v-if="item.status" @click="lease(item,2)">归还</div>
                  <div v-else @click="lease(item,1)">使用</div>
                </div>
              </div>
              <div class="col-center">
                <el-image class="no-img" :src="icon_img" />
              </div>
              <div v-if="is_del" class="del cursor" @click.stop="del(item)">
                <i class="el-icon-close" />
              </div>
              <div v-if="is_edit" class="edit cursor" @click.stop="edit(item)">
                <i class="el-icon-edit" />
              </div>
            </div>
          </el-col>
          <el-col v-if="!is_edit&&!is_del" :xs="12" :sm="12" :md="8" :lg="3" :xl="3">
            <div class="add text-center cursor" @click="dialogVisible = true">
              <div class="add-content">
                <i class="el-icon-plus" />
                <div>新增储物柜</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="log_list m-y-30">
        <Log :id.sync="log_id" ref="log" :info="activeInfo" />
      </div>
    </div>
    <div v-else class="empty ">
      <el-empty description="还没有添加储物柜哦,快去添加吧~" :image="default_img" :image-size="320">
        <el-button round class="btn" @click="dialogVisible = true">添加</el-button>
      </el-empty>
    </div>
    <!-- 新增储物柜 -->
    <Box v-model="dialogVisible" :type.sync="is_edit" :info.sync="editInfo" @getList="getList" />
    <!-- 租赁/归还储物柜 -->
    <Lease :id="dialog.id" v-model="dialog.visible" :type="dialog.type" :user-id="dialog.user_id" :info="dialog.row" @getList="getList" @getLogList="getLogList" />
  </div>
</template>

<script>
import Box from './Box'
import Lease from './Lease'
import Log from './Log'
import { getLockerList, deleteLocker } from '@/api/store/locker.js'
export default {
  components: {
    Box, Lease, Log
  },
  data() {
    return {
      loading: false,
      // 列表数据
      tableData: {
        list: [],
        count: 0
      },
      use_count: 0,
      used_count: 0,
      activeInfo: {},
      log_id: 0,
      dialogVisible: false, // 新增储物柜
      dialog: { visible: false, type: 1 },
      default_img: require('@/assets/images/empty_locker.png'),
      icon_img: require('@/assets/images/locker-icon.png'),
      is_del: false,
      is_edit: false,
      is_empty: false,
      editInfo: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 删除
    del(row) {
      this.$confirm(`此操作将永久删除${row.serial_number}号储物柜，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteLocker(row.id).then(res => {
          this.$message.success(res.msg)
          this.getList()
        })
      }).catch(() => {
      })
    },
    // 租赁/归还
    lease(row, type) {
      this.dialog.visible = true
      this.dialog.type = type
      this.dialog.id = row.id
      this.dialog.row = row
      if (type === 2) this.dialog.user_id = row.record.user_id
      if (type === 1) this.dialog.user_id = 0
    },
    getList() {
      this.use_count = this.used_count = 0
      this.loading = true
      getLockerList({ limit: 999 }).then(res => {
        this.tableData = res.data
        if (!this.utils.empty(this.tableData.list)) {
          this.use_count = this.tableData.list.filter(item => item.status).length
          this.used_count = this.tableData.list.filter(item => !item.status).length
        }
        this.is_empty = this.tableData.list.length <= 0
      }).finally(() => {
        this.loading = false
      })
    },
    // 更新日志列表
    getLogList() {
      // 调用子组件方法
      this.$refs.log.getList()
    },
    getItemLog(row) {
      this.activeInfo = row
      this.log_id = row.id
      console.log('🚀 ~ file: index.vue ~ line 138 ~ getItemLog ~ row', row)
    },
    edit(row) {
      this.editInfo = row
      this.dialogVisible = true
    }
  }

}
</script>

<style lang="scss" scoped>
.locker-box{
  min-height: 750px;
  .bg-item{
    width: 16px;
    height: 16px;
  }
  .bg-item-use{
    background-color: #FF9F50;
  }
  .bg-item-used{
    background-color: #01CBCA;
  }
  .text-number{
    color:#01CBCA;
  }
  .content{
    background-color: #F7F7F7;
    .block-box{
      color:#ffffff;
      padding: 20px;
      .add{
        border-radius: 7px;
        margin: 5px;
        padding: 10px;
        background-color: #EBEBEB;
        color:#909399;
        height: 108px;
        // line-height: 108px;
        .add-content{
          position: relative;
          transform: translateY(50%);
        }
        i{
          font-size: 30px;
        }
      }
      .block-item{
        border-radius: 7px;
        margin: 5px;
        padding: 10px;
        background-color: #01CBCA;
        position: relative;
        &.active{
          background-color: #FF9F50;
          .btn{
            color:#FF9F50;
          }
        }
        .number{
          width:140px;
          height: 25px;
          font-size: 18px;
        }
        .status{
          width:140px;
          height: 18px;
        }
        .btn{
          margin: 4px 0px;
          width:50px;
          height: 20px;
          text-align: center;
          background: #ffffff;
          color:#01CBCA;
          border-radius: 4px;
          line-height: 20px;
        }
        .del,.edit{
          position: absolute;
          right: 10px;
          top: 8px;
        }
      }

    }
  }
}
.empty{
  position:relative;
  top:50%;
  transform: translateY(50%);
  .btn{
    color:#ffffff;
    background-color: #01CBCA;
    width: 120px;
  }
}
</style>
