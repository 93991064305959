<template>
  <div class="app-container">
    <div v-if="user" v-loading="loading">
      <el-row :gutter="20">
        <el-col :span="8" :xs="24">
          <user-card :user="user" />
          <div v-if="is_dispale">
            <el-card v-if="utils.getVenues().id !== -1">
              <div slot="header" class="clearfix">
                <span>作息时间</span>
              </div>
              <TimeQuantum :store-user-id="store_user_id" :venues-id="venues_id" :is-display="is_display" />
            </el-card>
          </div>
        </el-col>
        <el-col :span="16" :xs="24">
          <el-card>
            <el-tabs v-model="activeTab">
              <el-tab-pane v-if="utils.getVenues()" label="修改资料" name="userInfo">
                <user-info :user="user" />
              </el-tab-pane>
              <el-tab-pane label="修改手机号" name="phone">
                <Phone :user="user" />
              </el-tab-pane>
              <el-tab-pane label="修改密码" name="account">
                <account :user="user" />
              </el-tab-pane>
              <!-- <el-tab-pane label="协议与安全" name="agreement">
                <Agreement />
              </el-tab-pane> -->
            </el-tabs>
          </el-card>
          <album class="m-t-20" :type="type" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import UserCard from './components/UserCard'
import Account from './components/Account'
import UserInfo from './components/UserInfo'
import Phone from './components/phone'
// import Agreement from './components/agreement.vue'
import album from './components/album'
import TimeQuantum from '@/views/store/venues/employee/components/TimeQuantum.vue'
import { getUserInfo } from '@/api/store/user.js'

export default {
  name: 'Profile',
  components: {
    UserCard,
    Account,
    UserInfo,
    TimeQuantum,
    Phone,
    album
    // Agreement
  },
  data() {
    return {
      user: this.$store.state.user,
      activeTab: 'userInfo',
      resetpass: parseInt(this.$route.query.resetpass),
      venues_id: this.$route.params.venues_id ? this.$route.params.venues_id : this.utils.getVenues().id === -1 ? '' : this.utils.getVenues().id,
      store_user_id: '',
      is_dispale: false,
      loading: false,
      is_display: 0,
      type: 1
    }
  },
  computed: {},
  watch: {
    $route: {
      // 亲测有效,我是用来监听query参数变化
      handler() {
        this.resetpass = parseInt(this.$route.query.resetpass)
        if (this.resetpass === 1) {
          this.activeTab = 'account'
        } else if (this.resetpass === 2) {
          this.activeTab = 'phone'
        } else {
          this.activeTab = 'userInfo'
        }
      },
      deep: true
    },
    store_user_id(val) {
      this.is_dispale = true
    }
  },
  beforeCreate() {},
  created() {
    this.loading = true
    // 初始化 user
    this.getUser()
    if (!this.utils.getVenues()) {
      this.activeTab = 'account'
    }
    getUserInfo()
      .then(res => {
        this.store_user_id = res.data.storeInfo.id
        setTimeout(() => {
          this.loading = false
        }, 800)
      })
      .catch(() => {})
  },
  mounted() {
    this.resetpass = parseInt(this.$route.query.resetpass)
    if (this.resetpass === 1) this.activeTab = 'account'
    if (this.resetpass === 2) this.activeTab = 'phone'
  },
  methods: {
    getUser() {
      this.user['role'] = this.$store.state.user.roles.join(' | ')
    }
  }
}
</script>

<style lang="scss">
.el-avatar {
  background: none;
}
</style>
