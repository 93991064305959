<template>
  <!-- 卡管理统计 -->
  <div class="app-container">
    <el-radio-group v-model="type" @change="handleChange">
      <el-radio-button :label="1">卡收费明细</el-radio-button>
      <el-radio-button :label="2">卡退费明细</el-radio-button>
    </el-radio-group>
    <!-- 搜索 -->
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="left">
        <el-form-item label="用户会员卡">
          <Select :type="5" @changeId="handleMembershipCardID" />
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <!-- 卡管理类型 -->
        <el-form-item v-if="type === 1" label="卡管理类型">
          <el-select v-model="listQueryParams.user_membership_card_status_id" placeholder="请选择卡管理类型" clearable>
            <el-option label="全部" :value="0" />
            <el-option label="请假" :value="3" />
            <el-option label="延长请假" :value="4" />
            <el-option label="停卡" :value="8" />
            <el-option label="补卡" :value="19" />
            <el-option label="续卡" :value="10" />
            <el-option label="升级" :value="18" />
            <el-option label="转卡" :value="20" />
          </el-select>
        </el-form-item>
        <!-- 支付方式 -->
        <el-form-item v-if="type === 1" label="支付方式">
          <el-select v-model="listQueryParams.payment_method_id" placeholder="请选支付方式" clearable>
            <el-option v-for="(item, index) in payment_method" :key="index" :label="item" :value="index" />
          </el-select>
        </el-form-item>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </el-form>
    </div>
    <!-- 统计 -->
    <div class="total m-y-20 font-16">
      {{ type === 1 ? '卡收费明细' : '卡退费明细' }}
      <span class="text-success p-x-2 font-xs">
        <count-to :start-val="0" :end-val="tableData.amount" :duration="2000" :decimals="0" />
      </span>
    </div>
    <el-button
      :disabled="tableData.list.length <= 0"
      :loading="buttonLoading"
      class="filter-item m-y-10"
      type="danger"
      icon="el-icon-download"
      @click="handleDownload"
    >
      导出
    </el-button>
    <!-- 列表 -->
    <el-table v-loading="tableLoading" :data="tableData.list" style="width: 100%" :stripe="true">
      <!-- 会员 -->
      <el-table-column label="会员">
        <template slot-scope="{ row }">
          <div v-if="row.user || row.user_membership_card.user" class="flex col-center">
            <div>
              <el-avatar shape="square" :size="50" :src="row.user ? row.user.avatar : row.user_membership_card.user.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row.user ? row.user : row.user_membership_card.user) }}</span>
              <br>
              <span>{{ row.user ? row.user.phone : row.user_membership_card.user.phone }}</span>
            </div>
          </div>
          <div v-else style="color: red;">会员信息丢失</div>
        </template>
      </el-table-column>
      <!-- 卡号 -->
      <el-table-column prop="user_membership_card" label="卡号">
        <template slot-scope="{ row }">
          <span>{{ row.user_membership_card.number }}</span>
        </template>
      </el-table-column>
      <!-- 卡管理类型 -->
      <el-table-column prop="user_membership_card_status_id" label="卡管理类型">
        <template slot-scope="{ row }">
          <span>{{ cardStatusId(row.user_membership_card_status_id) }}</span>
        </template>
      </el-table-column>
      <!-- 付款金额 -->
      <el-table-column v-if="type === 1" prop="poundage" label="付款金额" />
      <!-- 退款金额 -->
      <el-table-column v-if="type === 2" prop="refund" label="退款金额">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="light" content="Right Center 提示文字" placement="right">
            <div slot="content">
              实收：{{ row.user_membership_card.actual_amount }}
              <br>
              退费：{{ row.refund }}
              <br>
              折价：{{ row.remaining }}
            </div>
            <span>{{ row.refund }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- 支付方式 -->
      <el-table-column v-if="type === 1" prop="payment_method_id" label="支付方式">
        <template slot-scope="{ row }">
          <span>{{ payment_method[row.payment_method_id] }}</span>
        </template>
      </el-table-column>
      <!-- 时间 -->
      <el-table-column prop="create_time" label="时间">
        <template slot-scope="{ row }">
          <span>{{ row.create_time }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 导出 -->
    <Export :success-prompt="successPrompt" :is-judge="is_judge" :animation-display="animation_display" />
  </div>
</template>

<script>
// API
import { getCardData } from '@/api/store/course'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import CountTo from 'vue-count-to'
import Select from '@/components/elSelect/Select'
import Export from '@/components/export/index.vue'

export default {
  components: { Pagination, CountTo, Select, Export },
  data() {
    return {
      type: 1,
      tableData: {
        list: [],
        count: 0,
        amount: 0
      }, // 数据
      tableLoading: false,
      searchLoading: false, // 搜索按钮加载
      buttonLoading: false, // 导出按钮加载
      successPrompt: false,
      is_judge: 0,
      animation_display: true
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    setQuery() {
      var querys = []
      if (this.listQueryParams.create_time) { querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time }) }
      if (this.listQueryParams.user_membership_card_id) { querys.push({ field: 'user_membership_card_id', key: this.listQueryParams.user_membership_card_id }) }
      if (this.listQueryParams.payment_method_id) { querys.push({ field: 'payment_method_id', key: this.listQueryParams.payment_method_id }) }
      if (this.listQueryParams.user_membership_card_status_id) {
        querys.push({
          field: 'user_membership_card_status_id',
          key: this.listQueryParams.user_membership_card_status_id
        })
      }
      return this.utils.getQueryParams(querys)
    },
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()
      data.type = this.type
      data.is_export = 0

      getCardData(data).then(res => {
        this.tableData = res.data
        this.tableLoading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleChange() {
      this.getList()
    },
    handleMembershipCardID(val) {
      this.listQueryParams.user_membership_card_id = val
    },
    cardStatusId(id) {
      switch (id) {
        case 0:
          return '全部'
        case 3:
          return '请假'
        case 4:
          return '延长请假'
        case 8:
          return '停卡'
        case 9:
          return '退卡'
        case 19:
          return '补卡'
        case 10:
          return '续卡'
        case 18:
          return '升级'
        case 20:
          return '转卡'
      }
    },
    // 导出
    async handleDownload() {
      const _this = this
      this.animation_display = true
      this.is_judge = 0
      this.buttonLoading = true
      this.successPrompt = true
      const query = encodeURIComponent(this.setQuery())
      const progressBar = await this.utils.handleDownload({
        url: `/store/v1/user_membership_card/change_record/data?type=${this.type}&is_export=1&query=${query}`,
        fileName: `卡管理统计（${this.type === 1 ? '收费' : '退费'}明细）.xlsx`,
        baseURL: this.baseUrl
      })
      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.exportLoading = false
          _this.successPrompt = false
        }, 2000)
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
