var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.is_success
      ? _c(
          "div",
          { staticClass: "feedback_box" },
          [
            _c("div", [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  [
                    _c("el-avatar", {
                      attrs: {
                        shape: "square",
                        size: 50,
                        src: _vm.consultant_avatar
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "m-l-10" }, [
                  _c("div", [_vm._v("老师您好，我是您的")]),
                  _c("div", { staticClass: "text-primary font-13" }, [
                    _vm._v("专属顾问 " + _vm._s(_vm.consultant_name))
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "flex col-top m-t-10",
                  staticStyle: { "background-color": "#f0f0f0" }
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "consultant_img m-10" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: { src: _vm.consultant_wx, fit: "fill" }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c("div", { staticClass: "m-l-10 m-t-10 font-14" }, [
                _vm._v("咨询电话：" + _vm._s(_vm.consultant_phone))
              ])
            ]),
            _c("el-divider"),
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.form,
                  "label-width": "80px",
                  "label-position": "top",
                  rules: _vm.rules,
                  "label-suffix": ":"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "反馈类型", prop: "type" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.form.type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type"
                        }
                      },
                      _vm._l(_vm.typeList, function(item, index) {
                        return _c("el-radio", {
                          key: index,
                          staticClass: "m-4",
                          attrs: { border: "", label: item.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "反馈内容", prop: "content" } },
                  [
                    _c("el-input", {
                      attrs: {
                        maxlength: "230",
                        "show-word-limit": "",
                        type: "textarea",
                        rows: "5",
                        placeholder: "写点什么……"
                      },
                      model: {
                        value: _vm.form.content,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "content", $$v)
                        },
                        expression: "form.content"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "问题截图" } },
                  [
                    _c(
                      "el-upload",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.upLoading,
                            expression: "upLoading"
                          }
                        ],
                        staticClass: "upload-demo",
                        attrs: {
                          action: _vm.storeUpload,
                          "on-success": _vm.upSuccess,
                          headers: _vm.utils.upload_headers(),
                          "on-progress": _vm.upProgress,
                          "on-preview": _vm.handlePreview,
                          "on-remove": _vm.handleRemove,
                          "before-remove": _vm.beforeRemove,
                          "on-error": _vm.handleError,
                          multiple: "",
                          limit: 3,
                          "on-exceed": _vm.handleExceed,
                          "file-list": _vm.fileList,
                          "list-type": "picture",
                          accept: ".jpg,.jpeg,.png"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "warning" } },
                          [_vm._v("点击上传")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip text-info",
                            attrs: { slot: "tip" },
                            slot: "tip"
                          },
                          [
                            _vm._v(
                              "附上问题截图，我们能更好的处理您遇到的问题，感谢理解"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "m-t-20 text-center" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          loading: _vm.loading,
                          type: "primary",
                          round: "",
                          size: "medium"
                        },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v("立即提交")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "el-result",
              {
                attrs: {
                  icon: "success",
                  title: "提交成功",
                  "sub-title": "您反馈的内容已提交成功，感谢您的支持~"
                }
              },
              [_c("template", { slot: "extra" })],
              2
            )
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-10" }, [
      _c("div", { staticClass: "m-b-12" }, [
        _c("i", { staticClass: "el-icon-menu m-r-2" }),
        _vm._v("咨询答疑")
      ]),
      _c("div", { staticClass: "m-b-12" }, [
        _c("i", { staticClass: "el-icon-menu m-r-2" }),
        _vm._v("案例推荐")
      ]),
      _c("div", { staticClass: "m-b-12" }, [
        _c("i", { staticClass: "el-icon-menu m-r-2" }),
        _vm._v("订购政策")
      ]),
      _c("div", [
        _c("i", { staticClass: "el-icon-menu m-r-2" }),
        _vm._v("服务支持")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }