var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c("el-page-header", {
        staticClass: "page-header",
        attrs: { content: _vm.type ? "新增电子合同" : "上传模板" },
        on: {
          back: function($event) {
            return _vm.returnPage()
          }
        }
      }),
      _c("el-divider"),
      _c(
        "el-steps",
        {
          attrs: {
            active: _vm.active,
            "finish-status": "success",
            "align-center": ""
          }
        },
        [
          _c("el-step", { attrs: { title: "上传合同" } }),
          _c("el-step", { attrs: { title: "添加签署区" } }),
          _c("el-step", { attrs: { title: "确定文件" } })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 0,
              expression: "active === 0"
            }
          ],
          staticClass: "m-t-40"
        },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-upload",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.upLoading,
                      expression: "upLoading"
                    }
                  ],
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.upSuccess,
                    "on-change": _vm.upChange,
                    headers: _vm.utils.upload_headers(),
                    "file-list": _vm.fileList,
                    limit: 1,
                    name: "template_file",
                    "on-exceed": _vm.handleExceed,
                    "on-progress": _vm.upProgress,
                    "auto-upload": false,
                    drag: "",
                    action: _vm.importUrl,
                    accept: ".doc, .docx, .pdf, .jpg, .jpeg, .png"
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v(" 将文件拖到此处，或 "),
                    _c("em", [_vm._v("点击上传")])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [_vm._v("只能上传doc/docx/pdf/jpg/jpeg/png文件")]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center m-t-20" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-block",
                  attrs: {
                    type: "primary",
                    round: "",
                    loading: _vm.btnLoading
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(_vm._s(_vm.state ? "下一步" : "确认并上传"))]
              )
            ],
            1
          )
        ]
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active === 1,
            expression: "active === 1"
          }
        ]
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 2,
              expression: "active === 2"
            }
          ],
          staticStyle: { width: "60%", margin: "60px auto 0" }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.pageType,
                  expression: "!pageType"
                }
              ],
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                "label-position": "left",
                "label-width": "80px"
              }
            },
            [
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "10px",
                        left: "30px"
                      },
                      attrs: { effect: "dark", placement: "bottom" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("div", [
                            _vm._v(
                              "会员合同：上传此合同后一旦签署将不可更改，只能发送给单个会员签署"
                            )
                          ]),
                          _c("div", [
                            _vm._v(
                              "合同模板：上传此合同可创建为模板，此模板可为多个会员批量发送"
                            )
                          ])
                        ]
                      ),
                      _c("i", { staticClass: "el-icon-question cursor" })
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.is_customized,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "is_customized", $$v)
                            },
                            expression: "form.is_customized"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { disabled: !!!_vm.type, label: 1 } },
                            [_vm._v("会员合同")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { disabled: !!!_vm.type, label: 0 } },
                            [_vm._v("合同模板")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合同名称" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签" } },
                [
                  _vm._l(_vm.form.tag_id, function(item) {
                    return _c("el-tag", { key: item.id, staticClass: "m-6" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  _c(
                    "el-tag",
                    {
                      staticClass: "cursor m-l-6",
                      attrs: { type: "success" },
                      on: { click: _vm.handleTag }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.form.tag_id.length > 0
                            ? "+ 编辑标签"
                            : "+ 添加标签"
                        )
                      )
                    ]
                  )
                ],
                2
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: {
                        click: function($event) {
                          return _vm.handlePreserve(1)
                        }
                      }
                    },
                    [_vm._v("保存并添加会员签署")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handlePreserve(0)
                        }
                      }
                    },
                    [_vm._v("仅保存")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pageType,
                  expression: "pageType"
                }
              ],
              ref: "defaultForm",
              attrs: {
                model: _vm.defaultForm,
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "合同名称" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.defaultForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.defaultForm, "name", $$v)
                      },
                      expression: "defaultForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合同有效期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.defaultForm.contract_expire_time,
                      callback: function($$v) {
                        _vm.$set(_vm.defaultForm, "contract_expire_time", $$v)
                      },
                      expression: "defaultForm.contract_expire_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否短信通知" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.defaultForm.enable_sms,
                      callback: function($$v) {
                        _vm.$set(_vm.defaultForm, "enable_sms", $$v)
                      },
                      expression: "defaultForm.enable_sms"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发送给" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.memberType,
                        callback: function($$v) {
                          _vm.memberType = $$v
                        },
                        expression: "memberType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("会 员")
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("访 客")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择会员" } },
                [
                  _c("Select", {
                    ref: "userRef",
                    attrs: { type: _vm.memberType },
                    on: { changeId: _vm.handleMemberID }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签署截至时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.defaultForm.sign_expire_time,
                      callback: function($$v) {
                        _vm.$set(_vm.defaultForm, "sign_expire_time", $$v)
                      },
                      expression: "defaultForm.sign_expire_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSend }
                    },
                    [_vm._v("发送")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pdfDialog", {
        attrs: { "dialog-visible-pdf": _vm.dialogVisiblePdf, src: _vm.srcPdf },
        on: { closeVisiblePdf: _vm.closeVisiblePdf }
      }),
      _c("tagDialog", {
        attrs: {
          "dialog-visible-template": _vm.dialogVisibleTemplate,
          "template-tags": _vm.templateTags
        },
        on: { closeVisibleTemplate: _vm.closeVisibleTemplate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }