import request from '@/utils/request'
import Utils from '@/utils/utils.js'

// 获取当前登录用户信息
export function getUserInfo(data) {
  return request({
    url: `/store/v1/userInfo`,
    method: 'get',
    params: data
  })
}

// 修改当前登录用户信息
export function editUserInfo(data) {
  return request({
    url: `/store/v1/userInfo`,
    method: 'put',
    data
  })
}

// 获取提现验证码
export function getSendCode(data) {
  return request({
    url: `/store/v1/send_withdrawal_code`,
    method: 'get',
    data
  })
}

// 发送验证码
export function SendCode(data) {
  return request({
    url: `/store/v1/send_code`,
    method: 'post',
    data
  })
}

// 获取用户列表
export function getUser(data) {
  return request({
    url: `/store/v1/user`,
    method: 'get',
    params: data
  })
}
// 添加用户
export function addUser(data) {
  return request({
    url: '/store/v1/user',
    method: 'post',
    data
  })
}
// 修改用户
export function editUser(id, data) {
  return request({
    url: `/store/v1/user/${id}`,
    method: 'put',
    data
  })
}
// 获取用户详情
export function getUserDetails(id) {
  return request({
    url: `/store/v1/user/${id}`,
    method: 'get'
  })
}
// 删除会员
export function delUser(id) {
  return request({
    url: `/store/v1/user/${id}`,
    method: 'delete'
  })
}

// 导出会员卡
export function exportUser(data) {
  return request({
    url: `/store/v1/userExport`,
    method: 'get',
    params: data
  })
}

// 获取用户会员卡列表
export function getUserMembershipCard(data) {
  return request({
    url: `/store/v1/user_membership_card/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
// 获取支持课程卡列表
export function getUserSupportCard(course_id, user_id, params) {
  return request({
    url: `/store/v1/user_membership_card/support_card/${course_id}/${user_id}`,
    method: 'get',
    params
  })
}

// 导出会员卡
export function exportUserMembershipCard(data) {
  return request({
    url: `/store/v1/user_membership_card/export/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}
// 添加用户会员卡
export function addUserMembershipCard(data) {
  return request({
    url: '/store/v1/user_membership_card',
    method: 'post',
    data
  })
}
// 获取用户会员卡详情
export function getUserMembershipCardDetails(id) {
  return request({
    url: `/store/v1/user_membership_card/${id}`,
    method: 'get'
  })
}

// 删除用户会员卡
export function delUserMembershipCard(id) {
  return request({
    url: `/store/v1/user_membership_card/${id}`,
    method: 'delete'
  })
}

// 更改用户会员卡
export function editUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/${id}`,
    method: 'put',
    data
  })
}
// 转移会员卡
export function transferUserMembershipCard(user_id, venues_id, data) {
  return request({
    url: `/store/v1/user/transfer/${user_id}/venues_id/${venues_id}`,
    method: 'put',
    data
  })
}

// 会员卡变更记录
export function getUserMembershipCardChangeLogs(data) {
  return request({
    url: `/store/v1/user_membership_card/change_record`,
    method: 'get',
    params: data
  })
}

// 获取用户生日提醒
export function getUserBirthdayReminder(data) {
  return request({
    url: `/store/v1/user/birthday_remind/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 获取用户会员卡余额提醒
export function getUserCardBalance(data) {
  return request({
    url: `/store/v1/user_membership_card/limit/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 获取用户会员卡开卡提醒
export function getUserCardOpen(data) {
  return request({
    url: `/store/v1/user_membership_card/open/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 获取用户会员卡请假到期提醒
export function getUserCardLeaveExpire(data) {
  return request({
    url: `/store/v1/user_membership_card/leave_expire/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 获取不来上课会员
export function getNotClass(data) {
  return request({
    url: `/store/v1/user/not_class/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 获取用户回访记录
export function getUserTrack(user_id, data) {
  return request({
    url: `/store/v1/user_track/user_id/${user_id}`,
    method: 'get',
    params: data
  })
}

// 添加用户回访记录
export function addUserTrack(data) {
  return request({
    url: `/store/v1/user_track`,
    method: 'post',
    data
  })
}

// 用户会员卡到期提醒
export function getUserMembershipCardExpire(data) {
  return request({
    url: `/store/v1/user_membership_card/expire/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 获取用户会员卡状态列表
export function getUserMembershipCardStatus(data) {
  return request({
    url: `/store/v1/user_membership_card/status`,
    method: 'get',
    params: data
  })
}

// 添加请假
export function addUserMembershipCardLeave(id, data) {
  return request({
    url: `/store/v1/user_membership_card/leave/${id}`,
    method: 'put',
    data
  })
}

// 卡回收
export function recycleUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/recover/${id}`,
    method: 'put',
    data
  })
}

// 销假
export function offUserMembershipLeave(e, data) {
  return request({
    url: `/store/v1/user_membership_card/leave_off/${e.id}`,
    method: 'put',
    data
  })
}

// 取消请假
export function destroyUserMembershipLeave(e, data) {
  return request({
    url: `/store/v1/user_membership_card/cancel_leave/${e.id}`,
    method: 'put',
    data
  })
}

// 用户纪念日提醒
export function getUserSouvenir(data) {
  return request({
    url: `/store/v1/user/souvenir_remind/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

// 根据短信验证码修改密码
export function resetPwd(data) {
  return request({
    url: `/store/v1/user_code_password`,
    method: 'put',
    data
  })
}

// 导入用户会员卡
export function importUser(type, data) {
  return request({
    url: `/user_membership_card/import/venues_id/${Utils.getVenues().id}/type/${type}`,
    method: 'post',
    data
  })
}

// 延长请假
export function extendedLeaveUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/extended_leave/${id}`,
    method: 'put',
    data
  })
}

// 取消请假
export function cancelLeaveUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/cancel_leave/${id}`,
    method: 'put',
    data
  })
}

// 会员卡停卡
export function stopUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/stop/${id}`,
    method: 'put',
    data
  })
}

// 会员卡解除停卡
export function relieveStopUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/relieve_stop/${id}`,
    method: 'put',
    data
  })
}

// 会员卡挂失
export function lossUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/loss/${id}`,
    method: 'put',
    data
  })
}

// 会员卡解挂
export function lossUnregisterUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/loss_unregister/${id}`,
    method: 'put',
    data
  })
}

// 会员卡补卡
export function reissueUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/reissue/${id}`,
    method: 'put',
    data
  })
}

// 会员卡续卡
export function continueUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/continue/${id}`,
    method: 'put',
    data
  })
}

// 会员卡升级
export function upgradeUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/upgrade/${id}`,
    method: 'put',
    data
  })
}

// 会员卡退卡
export function refundUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/refund/${id}`,
    method: 'put',
    data
  })
}

// 会员卡转卡
export function shiftUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/shift/${id}`,
    method: 'put',
    data
  })
}

// 会员卡开卡
export function openUserMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/open/${id}`,
    method: 'put',
    data
  })
}

// 获取会员卡状态
export function isOperationMembershipCard(id, data) {
  return request({
    url: `/store/v1/user_membership_card/is_operation/${id}`,
    method: 'get',
    params: data
  })
}

// 获取用户积分日志
export function getUserIntegral(data) {
  return request({
    url: `/store/v1/user_integral`,
    method: 'get',
    params: data
  })
}

// 修改用户积分
export function setUserIntegral(user_id, data) {
  return request({
    url: `/store/v1/user/change_integral/${user_id}`,
    method: 'put',
    data
  })
}

// 获取用户相册
export function getImg() {
  return request({
    url: `/store/v2/userInfo/photo`,
    method: 'get'
  })
}

// 删除用户相册
export function deleteImg(id) {
  return request({
    url: `/store/v2/userInfo/photo/${id}`,
    method: 'delete'
  })
}
// 修改手机号
export function putChangePhoneNumber(data) {
  return request({
    url: `/store/v1/userChangePhoneNumber`,
    method: 'put',
    data
  })
}
// 短信验证登录
export function smsLogin(data) {
  return request({
    url: `/store/v1/sms_login`,
    method: 'post',
    data
  })
}
// 会员订单
export function getUserOrder(id, params) {
  return request({
    url: `/store/v1/user/${id}/order`,
    method: 'get',
    params
  })
}
// 个人相册排序
export function putAlbumSort(data) {
  return request({
    url: `/store/v2/userInfo/photo/sort`,
    method: 'put',
    data
  })
}
// 会员卡批量删除
export function deleteMembershipCardAll(data) {
  return request({
    url: `/store/v1/user_membership_card/batch_delete`,
    method: 'delete',
    data
  })
}
// 批量修改用户积分
export function postModifyPointsAll(data) {
  return request({
    url: `/store/v1/user/batch_change_integral`,
    method: 'post',
    data
  })
}
// 批量请假
export function postLeaveAll(data) {
  return request({
    url: `/store/v1/user_membership_card/batch_leave`,
    method: 'post',
    data
  })
}
// 取消请假
export function putCancelLeaveAll(data) {
  return request({
    url: `/store/v1/user_membership_card/batch_cancel_leave`,
    method: 'put',
    data
  })
}
// 批量销假
export function postCancellationLeaveAll(data) {
  return request({
    url: `/store/v1/user_membership_card/batch_leave_off`,
    method: 'post',
    data
  })
}
// 批量修改卡余额
export function postModifyCardBalanceAll(data) {
  return request({
    url: `/store/v1/user_membership_card/batch_change_limit`,
    method: 'post',
    data
  })
}
// 批量停卡
export function postStopCardAll(data) {
  return request({
    url: `/store/v1/user_membership_card/batch_stop`,
    method: 'post',
    data
  })
}
// 批量解除会员卡停卡
export function putReleaseStopCardAll(data) {
  return request({
    url: `/store/v1/user_membership_card/batch_relieve_stop`,
    method: 'put',
    data
  })
}
// 批量开卡
export function putActivateBankCardAll(data) {
  return request({
    url: `/store/v1/user_membership_card/batch_open`,
    method: 'put',
    data
  })
}
