<template>
  <div class="app-container">
    <!-- 筛选 -->
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="110px" label-position="left" label-suffix="：">
          <!-- 邀请者品牌 -->
          <el-form-item label="邀请者品牌">
            <Select :type="1" @changeId="cardIdI" />
          </el-form-item>
          <!-- 被邀请者品牌 -->
          <el-form-item label="被邀请者品牌">
            <Select :type="1" @changeId="cardIdC" />
          </el-form-item>
          <!-- 返现时间 -->
          <el-form-item label="返现时间">
            <el-date-picker
              v-model="listQueryParams.cashback_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="dateShortcutChooser"
            />
          </el-form-item>
          <!-- 邀请类型 -->
          <el-form-item label="邀请类型">
            <el-select v-model="listQueryParams.type" placeholder="请选择邀请类型" clearable>
              <el-option label="链接邀请" :value="1" />
              <el-option label="二维码邀请" :value="2" />
              <el-option label="邀请码" :value="0" />
            </el-select>
          </el-form-item>
          <!-- 状态 -->
          <el-form-item label="状态">
            <el-select v-model="listQueryParams.is_complete" placeholder="请选择状态" clearable>
              <el-option label="已完成" :value="1" />
              <el-option label="未完成" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="线上/线下">
            <el-select v-model="listQueryParams.is_offline" placeholder="请选择状态" clearable>
              <el-option label="线上" :value="0" />
              <el-option label="线下" :value="1" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </div>
    </div>
    <!-- 添加按钮 -->
    <div class="filter-container">
      <el-button v-show="btn_access('activity_invite_add')" v-waves class="filter-item m-r-10" type="primary" icon="el-icon-plus" @click="handleOperation">
        添加
      </el-button>
    </div>
    <!-- 表格 -->
    <el-table v-loading="tableLoading" stripe :data="tableData.list" style="width: 100%">
      <el-table-column prop="app" label="邀请者品牌信息">
        <template slot-scope="{ row }">
          <div v-if="row.store_user.app" class="flex">
            <el-avatar v-if="row.store_user.app.logo" shape="square" class="m-r-6" :src="row.store_user.app.logo" />
            <span>{{ row.store_user.app.name }}</span>
          </div>
          <div v-else style="color: red;">品牌信息丢失</div>
        </template>
      </el-table-column>
      <el-table-column prop="app" label="邀请者人员信息">
        <template slot-scope="{ row }">
          <div class="flex">
            <el-avatar style="width: 40px; height: 40px;" class="m-r-6" :src="row.store_user.avatar">
              <img :src="errorMemberSrc()">
            </el-avatar>
            <div>
              <div>{{ row.store_user.real_name }}</div>
              <div>{{ row.store_user.phone }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="app" label="受邀请者品牌信息">
        <template slot-scope="{ row }">
          <div v-if="row.invited_app" class="flex">
            <el-avatar v-if="row.invited_app.logo" shape="square" class="m-r-6" :src="row.invited_app.logo" />
            <span>{{ row.invited_app.name }}</span>
          </div>
          <div v-else style="color: red;">品牌信息丢失</div>
        </template>
      </el-table-column>
      <el-table-column prop="app" label="受邀请者人员信息">
        <template slot-scope="{ row }">
          <div v-if="row.invited_store_user" class="flex">
            <el-avatar style="width: 40px; height: 40px;" class="m-r-6" :src="row.invited_store_user.avatar">
              <img :src="errorMemberSrc()">
            </el-avatar>
            <div>
              <div>{{ row.invited_store_user.real_name }}</div>
              <div>{{ row.invited_store_user.phone }}</div>
            </div>
          </div>
          <div v-else style="color: red;">用户信息丢失</div>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="邀请类型" min-width="40">
        <template slot-scope="{ row }">
          <el-tag v-if="row.type === 0">邀请码</el-tag>
          <el-tag v-if="row.type === 1">链接邀请</el-tag>
          <el-tag v-if="row.type === 2">二维码邀请</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="cashback" label="返现金额" min-width="40">
        <template slot-scope="{ row }">{{ row.cashback }} 元</template>
      </el-table-column>
      <el-table-column prop="cashback" label="返现凭证">
        <template slot-scope="{ row }">
          <el-image
            v-if="row.proof"
            style="width: 100px; height: 100px"
            :src="row.proof ? row.proof[0] : ''"
            :preview-src-list="row.proof"
          />
          <div v-else>暂无凭证</div>
        </template>
      </el-table-column>
      <el-table-column prop="is_complete" label="状态" min-width="60">
        <template slot-scope="{ row }">
          <el-tag :type="row.is_complete ? '' : 'danger'" class="m-2">
            {{ row.is_complete ? '已完成' : '未完成' }}
          </el-tag>
          <el-tag class="m-2">{{ row.is_offline ? '线下' : '线上' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="cashback_time" label="返现时间" min-width="80">
        <template slot-scope="{ row }">{{ row.is_complete ? row.cashback_time : '---' }}</template>
      </el-table-column>
      <el-table-column label="操作" min-width="120">
        <template slot-scope="{ row }">
          <el-button
            v-if="!row.is_offline && !row.is_complete && btn_access('activity_invite_complete')"
            type="primary"
            icon="el-icon-circle-check"
            class="m-2"
            @click="handleWriteOff(row.id)"
          >
            核销
          </el-button>
          <el-popconfirm
            v-if="row.is_offline && !row.is_complete"
            title="确定通过该记录吗？"
            @confirm="handleAudit(row.id)"
          >
            <el-button slot="reference" type="primary" icon="el-icon-circle-check" class="m-2">通过审核</el-button>
          </el-popconfirm>
          <el-popconfirm v-show="btn_access('activity_invite_del')" title="确定删除该记录吗？" @confirm="handleDelete(row.id)">
            <el-button slot="reference" type="danger" icon="el-icon-delete" class="m-2">删 除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 新增/编辑弹窗 -->
    <Dialog v-if="dialogVisible" :visible="dialogVisible" @close="close" />
    <!-- 核销窗口 -->
    <el-dialog
      title="请选择核销的套餐"
      :visible.sync="writeDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="handleOpen"
      @close="handleClose"
    >
      <div v-loading="writeLoading" class="flex flex-wrap">
        <div
          v-for="(item, index) in writeDataList"
          :key="index"
          :style="{ border: selectId === item.id ? '2px solid #01cbca' : '2px solid #dfe6ec' }"
          style="border-radius: 8px;"
          class="p-y-8 p-x-18 m-6 cursor"
          @click="handleClick(item.id)"
        >
          <span>{{ item.name }}</span> - <span class="text-info">{{ packageVersion[item.brand_level] }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="writeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleWriteConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getInvite, deleteInvite, auditInvite, completeInvite } from '@/api/admin/invite.js'
import { getPlan } from '@/api/admin/order_management'
// 组件
import Pagination from '@/components/Pagination' // 分页组件
import Select from '@/components/elSelect/Select'
import Dialog from './Dialog.vue'

export default {
  components: { Pagination, Select, Dialog },
  data() {
    return {
      tableData: {
        count: 0,
        list: []
      },
      writeDataList: [],
      writeId: '',
      selectId: '',
      tableLoading: false,
      searchLoading: false,
      dialogVisible: false,
      writeDialogVisible: false,
      writeLoading: false,
      packageVersion: ['试用版', '个人版', '旗舰版', '定制版']
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      if (this.listQueryParams.appid) data.appid = this.listQueryParams.appid
      if (this.listQueryParams.is_complete != null && this.listQueryParams.is_complete !== '') {
        data.is_complete = this.listQueryParams.is_complete
      }
      if (this.listQueryParams.is_offline != null && this.listQueryParams.is_offline !== '') {
        data.is_offline = this.listQueryParams.is_offline
      }
      var querys = []
      if (this.listQueryParams.invited_store_user_appid) {
        querys.push({ field: 'invited_store_user_appid', key: this.listQueryParams.invited_store_user_appid })
      }
      if (this.listQueryParams.cashback_time) {
        querys.push({ field: 'cashback_time', type: 'between-time', key: this.listQueryParams.cashback_time })
      }
      if (this.listQueryParams.type != null && this.listQueryParams.type !== '') {
        querys.push({ field: 'type', key: this.listQueryParams.type })
      }
      data.query = this.utils.getQueryParams(querys)

      getInvite(data)
        .then(res => {
          this.tableData = res.data
        })
        .finally(() => {
          this.tableLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 删除记录
    handleDelete(id) {
      deleteInvite(id)
        .then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
        .finally(() => {
          this.getList()
        })
    },
    cardIdI(val) {
      this.listQueryParams.appid = val
    },
    cardIdC(val) {
      this.listQueryParams.invited_store_user_appid = val
    },
    handleOperation() {
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.getList()
    },
    handleAudit(id) {
      const data = {
        status: 1
      }
      auditInvite(id, data).then(() => {
        this.$message({
          message: '审核成功!',
          type: 'success'
        })
        this.getList()
      })
    },
    // 打开核销窗口
    handleWriteOff(id) {
      this.writeId = id
      this.writeDialogVisible = true
      this.writeLoading = true
    },
    handleOpen() {
      getPlan()
        .then(res => {
          this.writeDataList = res.data.list
        })
        .catch(() => {})
        .finally(() => {
          this.writeLoading = false
        })
    },
    handleClick(id) {
      this.selectId = id
    },
    handleClose() {
      this.selectId = ''
    },
    handleWriteConfirm() {
      console.log('', this.writeId)
      console.log('核销的套餐ID', this.selectId)
      completeInvite(this.writeId, { plan_id: this.selectId })
        .then(() => {
          this.$message({
            message: '核销成功!',
            type: 'success'
          })
          this.getList()
          this.writeDialogVisible = false
        })
    }
  }
}
</script>
