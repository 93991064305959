<template>
  <div class="app-container special-center">
    <el-form ref="form" :model="form" label-width="120px">
      <!-- 用户头像 -->
      <div class="tx_ing">
        <el-form-item label="用户头像">
          <el-upload
            v-loading="upLoading"
            class="images-uploader"
            :action="storeUpload"
            :show-file-list="false"
            :on-success="upSuccess"
            :headers="utils.upload_headers()"
            :on-progress="upProgress"
            accept=".jpg,.jpeg,.png"
          >
            <img v-if="form.virtual_info.avatar" :src="form.virtual_info.avatar" class="images" title="点击上传图片">
            <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
          </el-upload>
        </el-form-item>
        <el-button class="tx_imgButton" icon="el-icon-refresh" @click="handleGetUsername">随机</el-button>
      </div>
      <!-- 用户名 -->
      <el-row>
        <el-col :span="12">
          <el-form-item label="用户名">
            <el-input v-model="form.virtual_info.username" style="width: 200;" placeholder="用户名" />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 选择评论类型 -->
      <el-form-item label="选择评论类型">
        <el-radio-group v-model="form.type" @change="handleChange">
          <el-radio-button :label="10">课程</el-radio-button>
          <el-radio-button :label="20">售卡</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <!-- 选择课程 -->
      <course v-if="form.type === 10" :id="form.course_id" :type="type" :timer="timer" @courseId="courseId" />
      <!-- 售卡类型 -->
      <card v-if="form.type === 20" :id="form.goods_id" :timer="timer" @cardId="cardId" />
      <!-- 点赞数量 -->
      <el-row>
        <el-col :span="12">
          <el-form-item label="点赞数量">
            <el-input v-model="form.favour_number" type="number" style="width: 200;" />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 评分 -->
      <el-form-item label="评分">
        <el-rate v-model="star_level" style="padding-top: 6px;" show-text :colors="colors" :texts="texts" />
      </el-form-item>
      <!-- 评论内容 -->
      <el-form-item label="评论内容">
        <div>
          <el-tag
            v-for="(item, index) in tagList"
            :key="index"
            class="elTag cursor"
            :type="item.isCheck === true ? 'primary' : 'info'"
            @click="checkItem(item)"
          >
            {{ item.name }}
          </el-tag>
        </div>
      </el-form-item>
      <!-- 评论 -->
      <el-form-item label="评论">
        <el-input v-model="form.content" type="textarea" :rows="5" placeholder="请输入评论内容" />
      </el-form-item>
      <!-- 评论图 -->
      <el-form-item label="评论图">
        <el-upload
          v-loading="upLoading"
          :action="storeUpload"
          list-type="picture-card"
          :on-preview="handlePreviews"
          :on-remove="handleRemoves"
          :on-success="upSuccesss"
          :headers="utils.upload_headers()"
          :file-list="fileLists"
          :limit="9"
          :on-exceed="handleExceeds"
          :on-progress="upProgresss"
          accept=".jpg,.jpeg,.png"
        >
          <i class="el-icon-plus" />
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" class="dialog_view_image">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </el-form-item>
      <el-form-item>
        <el-button class="onSubmit" type="primary" @click="onSubmit">立即创建</el-button>
        <router-link :to="{ name: 's_commentsVirtual_list' }">
          <el-button>取消</el-button>
        </router-link>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { postAdd, getUsername, getIcon } from '@/api/store/evaluation.js'
import course from '@/components/elSelect/course.vue'
import card from '@/components/elSelect/card.vue'

export default {
  components: { course, card },
  data() {
    return {
      form: {
        course_id: '',
        goods_id: '',
        type: 10,
        favour_number: 0,
        tags: [],
        content: '',
        virtual_info: {
          avatar: '',
          username: ''
        },
        images: []
      },
      star_level: 0,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      texts: ['差', '一般', '还不错', '很满意', '强烈推荐'],
      tagList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      fileLists: [],
      upLoading: false,
      timer: '',
      type: 3
    }
  },
  watch: {
    star_level(e) {
      this.getIcon(e)
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList() {
      getIcon().then(res => {
        this.tagData = res.data
      })
    },
    onSubmit() {
      this.tagList.forEach(item => {
        if (item.isCheck === true) {
          this.form.tags.push(item.name)
        }
      })
      this.form.star_level = this.star_level
      postAdd(this.form).then(() => {
        this.returnPage()
      })
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form.virtual_info, 'avatar', response.data.path)
    },
    upProgress() {
      this.upLoading = true
    },
    //
    upSuccesss(response, file, fileLists) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      const imageList = []
      for (const i in fileLists) {
        imageList.push(fileLists[i].response.data.path)
      }
      this.$set(this.form, 'images', imageList)
    },
    upProgresss() {
      this.upLoading = true
    },
    handleRemoves(file, fileLists) {
      const imageList = []
      for (const i in fileLists) {
        imageList.push(fileLists[i].response.data.path)
      }
      this.$set(this.form, 'images', imageList)
    },
    handlePreviews(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleExceeds(files, fileLists) {
      this.$message.warning(`仅支持上传九张商品图片`)
    },
    //
    checkItem(item) {
      this.tagList.forEach(item1 => {
        if (item1.name === item.name) {
          item1.isCheck = !item1.isCheck
        }
      })
    },
    handleGetUsername() {
      getUsername().then(res => {
        const avatar = Math.floor(Math.random() * (35 - 1) + 1)
        const comments = Math.floor(Math.random() * (14 - 1) + 1)
        const user_name = Math.floor(Math.random() * (70 - 1) + 1)
        this.form.virtual_info.avatar = res.data.avatar[avatar]
        this.form.virtual_info.username = res.data.user_name[user_name]
        this.form.content = res.data.comments[comments]
      })
    },
    getIcon(e) {
      this.tagList = []
      this.tagData[e - 1].tags.forEach(item => {
        this.tagList.push({ name: item, isCheck: false })
      })
    },
    handleChange() {
      this.timer = new Date().getTime()
      this.form.course_id = ''
      this.form.goods_id = ''
    },
    courseId(val) {
      this.form.course_id = val
    },
    cardId(val) {
      this.form.goods_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409eff;
}
.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.elTag {
  margin-right: 6px;
}
.tx_ing {
  display: flex;
  .tx_imgButton {
    width: 70px;
    height: 30px;
    margin-left: 10px;
  }
}
.onSubmit {
  margin-right: 10px;
}
.jzz {
  text-align: center;
  font-size: 16px;
  margin: 6px 0;
  .jzz_span1 {
    margin-left: 10px;
    color: #ccc;
  }
  .jzz_span2 {
    color: #ccc;
  }
}
</style>
