<template>
  <div v-loading="weChatLoading">
    <!-- <meta name="referrer" content="no-referrer"> -->
    <div class="weChat_bg">
      <div>尚未注册小程序？点此快速注册小程序</div>
      <el-button class="m-x-20" type="primary" @click="toPage()">立即注册</el-button>
    </div>
    <el-form ref="ruleForm" label-position="right" :rules="rules" class="m-t-20" :model="ruleForm" label-width="140px">
      <el-form-item label="是否使用第三方授权">
        <el-radio-group v-model="accredit" :disabled="third">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="accredit === 0" label="原始ID" prop="mini_program_id">
        <el-input v-model="ruleForm.mini_program_id" />
      </el-form-item>
      <el-form-item v-if="accredit === 0" label="小程序ID" prop="mini_program_appid">
        <el-input v-model="ruleForm.mini_program_appid" />
      </el-form-item>
      <el-form-item v-if="accredit === 0" label="小程序密钥" prop="mini_program_secret">
        <el-input v-model="ruleForm.mini_program_secret" />
      </el-form-item>
      <el-form-item v-if="accredit === 0">
        <el-button type="primary" :loading="btnLoading" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
      <div v-if="accredit === 1 && !third" class="choose flex">
        <div class="left flex row-center">
          <img src="@/assets/images/mini-program.png" alt="">
        </div>
        <div>选择已有小程序</div>
        <el-button class="button-item" type="primary" size="small" :loading="submitLoading" @click="handleAuth">
          立即授权
        </el-button>
      </div>
      <div v-if="accredit === 1 && third" class="">
        <div class="version flex row-between">
          <div class="flex">
            <el-tooltip
              class="item"
              effect="light"
              :content="data.programs ? (data.programs.nickname ? data.programs.nickname : '---') : '---'"
              placement="top"
            >
              <div class="left flex row-center">
                <img src="@/assets/images/mini-program.png" alt="">
              </div>
            </el-tooltip>
            <div>
              <div class="version-big">线上版本</div>
              <div>版本号：{{ data.version.release_info ? data.version.release_info.release_version : '---' }}</div>
            </div>
          </div>
          <div class="">
            <div class="setting-url" @click="handleAllocation">配置业务域名</div>
            <div class="setting-url" @click="handleBindExperience">绑定体验者</div>
          </div>
          <div class="m-r-10">
            <div class="setting-url" @click="handleSetCategory">设置类目</div>
            <div class="setting-url" @click="handleSetPrivacy">设置隐私协议</div>
          </div>
        </div>
        <div v-if="check_status !== 0" v-loading="getMiniInfoLoading" class="program-info">
          <div class="program-tip">
            <div class="flex">
              <div class="number">1</div>
              <div class="flex">提交审核版本一般需要1-7天，请耐心等待</div>
            </div>
            <div class="flex">
              <div class="number">2</div>
              <div class="flex">审核通过后自动发布小程序</div>
            </div>
          </div>
          <div class="choose-version flex">
            <div>选择版本</div>
            <div>
              <div v-for="(item, index) in getTemplateDataList" :key="index" style="margin-bottom: 15px">
                <el-radio v-model="is_plugin" :label="item.template_id" @change="handlePlugin(item)">
                  {{ item.user_desc.split('\n')[0] }}({{ item.user_version }})
                </el-radio>
              </div>
            </div>
          </div>
          <div style="line-height: 38px;" class="to-test button" @click="toUpVersion">生成体验版本</div>
          <el-button
            v-if="check_status == -1 || check_status == 3"
            :loading="submitLoading"
            class="button"
            @click="handleSubmitForReview"
          >
            提交审核
          </el-button>
          <el-button v-if="check_status == 2" :loading="submitLoading" class="button" @click="handleWithdrawalReview">
            审核中，撤回审核
          </el-button>
          <el-button v-if="check_status == 1" :loading="submitLoading" class="button" @click="handleSubmitForReview">
            审核失败，再次提交审核
          </el-button>
          <el-button v-if="check_status == 0" :loading="submitLoading" class="button" @click="handleRelease">
            审核通过，立即发布
          </el-button>
          <div v-if="check_status == 1 && getMiniInfoData.reason" style="color: #ff4544;margin: 8px 20px">
            失败原因：
            <span v-html="getMiniInfoData.reason" />
          </div>
        </div>
        <!-- 小程序信息 -->
        <div v-else v-loading="getMiniInfoLoading" class="program-info">
          <img :src="data.programsInfo.qrcode_url" referrerPolicy="no-referrer">
          <div>
            <div class="program-text">小程序名称：{{ data.programsInfo.nick_name }}</div>
            <!-- <div class="program-text">模版名称：双十一预售</div> -->
            <div class="program-text">版本生成时间：{{ getMiniInfoData.last ? getMiniInfoData.last.create_time : '---' }}</div>
            <div class="program-text">发布时间 ：{{ getMiniInfoData.last ? getMiniInfoData.last.release_time : '---' }}</div>
            <el-button v-if="lastBug" class="button_green" @click="handleVersionFallback">
              版本回退
            </el-button>
            <el-button v-else :loading="submitLoading" class="button" @click="handleRelease">
              手动发布
            </el-button>
            <el-button v-if="is_new" :loading="submitLoading" class="button" @click="check_status = -1">
              发现新版本，去更新
            </el-button>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 配置业务域名弹窗 -->
    <el-dialog title="配置业务域名" :visible.sync="urlVisible" :close-on-click-modal="false" width="785px">
      <div v-loading="dialogLoading" style="padding-left: 30px;">
        <div style="max-height: 400px;overflow: auto;">
          <el-form size="small" label-width="120px" label-position="left">
            <el-form-item v-for="(item, index) in domain" :key="index">
              <label slot="label">
                配置业务域名
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'以https://开头，例:https://www.yuxiaojiu.com'"
                  placement="top"
                >
                  <i class="el-icon-info" />
                </el-tooltip>
              </label>
              <div class="flex">
                <el-input v-model="domain[index]" style="width: 520px; margin-right: 20px;" />
                <el-tooltip effect="dark" content="删除" placement="top">
                  <img style="cursor: pointer;" src="@/assets/images/del.png" alt="" @click="delDomain(index)">
                </el-tooltip>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div style="color: #999999;margin: 20px 0 10px;">最多添加20条域名</div>
        <el-button v-if="domain.length < 20" class="add-btn" size="small" plain @click="addUrl">+添加域名</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" :loading="urlLoading" @click="submitUrl">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 体验版本小程序码 -->
    <el-dialog title="体验版本小程序码" :visible.sync="dialogVisible" :close-on-click-modal="false" width="360px">
      <div v-loading="previewLoading">
        <img
          id="code"
          style="display: block;margin: 15px auto 50px"
          :src="basQrcode ? basQrcode : ''"
          width="180"
          height="180"
          alt=""
        >
      </div>
    </el-dialog>
    <!-- 绑定体验者 -->
    <el-dialog
      title="绑定体验者"
      :visible.sync="bindExperienceVisible"
      :close-on-click-modal="false"
      width="785px"
      :before-close="handleClose"
      @close="cancelBindExperience"
    >
      <div class="flex">
        <el-form size="small" label-width="120px" label-position="right" @submit.native.prevent>
          <el-form-item label="体验者微信号">
            <el-input v-model="bindExperienceValue" style="width: 520px;" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" :loading="urlLoading" @click="handleWeChat(1)">绑 定</el-button>
        <el-button size="small" type="danger" :loading="urlLoading" @click="handleWeChat(0)">解 绑</el-button>
        <el-button size="small" @click="bindExperienceVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 版本回退 -->
    <el-dialog title="版本回退" :visible.sync="versionFallbackVisible" :close-on-click-modal="false" width="380px">
      <div class="flex">
        <el-form size="small" label-width="90px" label-position="right">
          <el-form-item label="版本回退">
            <el-select v-model="versionFallback" placeholder="请选择版本回退">
              <el-option
                v-for="(item, index) in versionFallbackData"
                :key="index"
                :label="item.user_desc.split('\n')[0]"
                :value="item.app_version"
              >
                <div class="flex">
                  <div>{{ item.user_desc.split('\n')[0] }} / {{ item.user_version }}</div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="versionFallbackVisible = false">取 消</el-button>
        <el-button size="small" type="primary" :loading="urlLoading" @click="handelOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import {
  setMiniConfig,
  getAuthInfo,
  getMiniInfo,
  getAuthCode,
  getTemplateList,
  uploadCode,
  getMiniCode,
  submitAudit,
  recallAudit,
  getLastRecord,
  release,
  getDomain,
  setDomain,
  bindTester,
  unbindTester,
  getBackRecord,
  backRecord,
  setCategory,
  setPrivacy
} from '@/api/admin/merchants.js'

export default {
  data() {
    return {
      data: {},
      getMiniInfoData: {},
      getTemplateDataList: [],
      getTemplateData: {},
      accredit: 0,
      ruleForm: {
        mini_program_id: '',
        mini_program_appid: '',
        mini_program_secret: ''
      },
      rules: {
        mini_program_id: [{ required: true, message: '请输入原始ID', trigger: 'blur' }],
        mini_program_appid: [{ required: true, message: '请输入小程序ID', trigger: 'blur' }],
        mini_program_secret: [{ required: true, message: '请输入小程序密钥', trigger: 'blur' }]
      },
      is_new: null, // 是否有新版本
      check_status: -1, // 小程序审核状态 -1 未审核 0 审核中 1 审核失败 2 审核中 3 已提交审核 4 已发布
      weChatLoading: false,
      getMiniInfoLoading: false,
      btnLoading: false,
      urlVisible: false,
      urlLoading: false,
      dialogLoading: false,
      previewLoading: false,
      dialogVisible: false,
      submitLoading: false,
      bindExperienceVisible: false,
      versionFallbackVisible: false,
      third: false,
      is_plugin: '',
      weChatUrl: '',
      host: '',
      domain: [],
      basQrcode: '',
      bindExperienceValue: '',
      versionFallback: '',
      versionFallbackData: [],
      lastBug: true
    }
  },
  created() {
    // 获取当前路由参数
    if (this.$route.query.auth_code) {
      const data = {
        auth_code: this.$route.query.auth_code,
        auth_type: 2
      }
      getAuthCode(this.$route.params.id, data)
        .then(res => {
          this.$message.success(res.msg)
          // 完成之后去除路由参数
          this.$router.replace({ path: this.$route.path, query: {}})
        })
        .catch(() => {
          this.$router.replace({ path: this.$route.path, query: {}})
        })
    }
  },
  mounted() {
    this.host = window.location.host
    this.getList()
  },
  methods: {
    getList() {
      this.weChatLoading = true
      getMiniInfo(this.$route.params.id).then(res => {
        this.data = res.data
        this.ruleForm.mini_program_id = this.data.programsSecretKey.mini_program_id
        this.ruleForm.mini_program_appid = this.data.programsSecretKey.mini_program_appid
        this.ruleForm.mini_program_secret = this.data.programsSecretKey.mini_program_secret

        if (this.data.programsInfo && this.data.programs) {
          this.accredit = 1
          this.third = true
          this.getLastRecordFunction()
        }
        this.weChatLoading = false
      })
    },
    // 获取最后一次审核记录
    async getLastRecordFunction() {
      this.getMiniInfoLoading = true
      const getLastRecordList = await getLastRecord(this.$route.params.id)
      const params = {
        aid: this.$route.params.id
      }
      const getTemplateListData = await getTemplateList(params)
      this.getMiniInfoData = getLastRecordList.data
      // 获取审核记录变动页面交互
      this.check_status = this.getMiniInfoData.status
      // this.getMiniInfoData.last 不为null既正常赋值下一步 否则手动回到刚开始的阶段
      if (this.getMiniInfoData.last) {
        this.lastBug = true
        const nowVersion = this.getMiniInfoData.last.version.split('.')
        // const newVersion = this.getMiniInfoData.user_version.split('.')
        const newVersion = getTemplateListData.data.list.template_list[getTemplateListData.data.list.template_list.length - 1].user_version.split('.')
        for (let i = 0; i < newVersion.length; i++) {
          if (+newVersion[i] > +nowVersion[i]) {
            this.is_new = true
            break
          }
        }
      } else this.lastBug = false
      this.getTemplateDataList = getTemplateListData.data.list.template_list.reverse()
      this.getTemplateData = this.getTemplateDataList[0]
      this.is_plugin = this.getTemplateData.template_id
      this.getMiniInfoLoading = false
    },
    // 保存小程序配置
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.btnLoading = true
          setMiniConfig(this.ruleForm, this.$route.params.id).then(res => {
            if (res.status === 200) this.$message.success(res.msg)
            else this.$message.error(res.msg)
            this.btnLoading = false
          })
        } else {
          return false
        }
      })
    },
    // 授权
    handleAuth() {
      this.submitLoading = true
      const data = {
        redirect_uri: window.document.location.href,
        auth_type: 2
      }
      getAuthInfo(data)
        .then(res => {
          this.$confirm('请在新窗口中完成微信小程序授权', '提示', {
            confirmButtonText: '已成功授权',
            cancelButtonText: '授权失败，重试',
            type: 'warning'
          })
            .then(() => {
              this.$message({
                type: 'success',
                message: '已完成授权!'
              })
              this.submitLoading = false
              this.getList()
            })
            .catch(() => {
              this.submitLoading = false
            })
          this.weChatUrl = res.data.url
          window.open(this.weChatUrl)
        })
        .catch(() => {
          this.submitLoading = false
        })
    },
    // 配置业务域名
    handleAllocation() {
      this.urlVisible = true
      this.dialogLoading = true
      getDomain(this.$route.params.id).then(res => {
        const data = res.data
        this.domain = data.webviewdomain
        this.dialogLoading = false
      })
    },
    delDomain(index) {
      this.domain.splice(index, 1)
    },
    addUrl() {
      this.domain.push('')
    },
    cancel() {
      this.domain = []
      this.urlVisible = false
    },
    submitUrl() {
      for (const item of this.domain) {
        const reg = /(https):\/\/([\w.]+\/?)\S*/
        if (!reg.test(item)) {
          this.$message.error('请输入正确的网址')
          return false
        }
      }
      if (this.domain.length === 0) {
        this.$message.error('请输入网址')
        return false
      }
      this.urlLoading = true
      setDomain(this.$route.params.id, { webviewdomain: this.domain })
        .then(res => {
          this.$message.success('配置成功')
          this.urlLoading = false
          this.urlVisible = false
        })
        .catch(() => {
          this.urlLoading = false
        })
    },
    handlePlugin(item) {
      this.getTemplateData = item
    },
    // 生成体验版本
    toUpVersion() {
      if (this.is_plugin === 0) {
        this.$message.error('请选择版本号')
        return false
      }
      this.previewLoading = true
      this.dialogVisible = true
      const data = {
        template_id: this.is_plugin,
        user_version: this.getTemplateData.user_version,
        user_desc: this.getTemplateData.user_desc
      }
      uploadCode(this.$route.params.id, data).then(res => {
        if (res.status === 200) {
          getMiniCode(this.$route.params.id).then(res => {
            this.basQrcode = res.data.qrcode
            this.previewLoading = false
          })
        }
      })
    },
    // 立即注册
    toPage(row) {
      this.$router.push({ name: 'adminSignWeChat', params: { id: this.$route.params.id }})
    },
    // 提交审核
    handleSubmitForReview() {
      this.submitLoading = true
      const data = {
        template_id: this.is_plugin,
        version: this.getTemplateData.user_version
      }
      submitAudit(this.$route.params.id, data).then(res => {
        if (res.status === 200) this.$message.success(res.msg)
        else this.$message.error(res.msg)
        this.submitLoading = false
        this.getLastRecordFunction()
      })
    },
    // 撤回审核
    handleWithdrawalReview() {
      this.submitLoading = true
      recallAudit(this.$route.params.id).then(res => {
        if (res.status === 200) this.$message.success(res.msg)
        else this.$message.error(res.msg)
        this.submitLoading = false
        this.getLastRecordFunction()
      })
    },
    // 发布
    handleRelease() {
      this.submitLoading = true
      release(this.$route.params.id).then(res => {
        if (res.status === 200) this.$message.success(res.msg)
        else this.$message.error(res.msg)
        this.submitLoading = false
        this.getLastRecordFunction()
      })
    },
    // 绑定体验者
    handleBindExperience() {
      this.bindExperienceVisible = true
    },
    handleClose(done) {
      done()
      this.bindExperienceValue = ''
    },
    cancelBindExperience() {
      this.bindExperienceValue = ''
    },
    // 绑定/解绑体验者
    handleWeChat(type) {
      if (!this.bindExperienceValue) {
        this.$message.error('请输入体验者微信号')
        return false
      }
      this.$confirm(`确定要${type === 1 ? '绑定' : '解绑'}（${this.bindExperienceValue}）此微信号吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.urlLoading = true
          if (type === 1) {
            bindTester(this.$route.params.id, { wechatid: this.bindExperienceValue })
              .then(res => {
                this.$message.success('绑定成功')
                this.urlLoading = false
                this.bindExperienceVisible = false
                this.bindExperienceValue = ''
              })
              .finally(() => {
                this.urlLoading = false
              })
          } else {
            unbindTester(this.$route.params.id, { wechatid: this.bindExperienceValue })
              .then(res => {
                this.$message.success('解绑成功')
                this.urlLoading = false
                this.bindExperienceVisible = false
                this.bindExperienceValue = ''
              })
              .finally(() => {
                this.urlLoading = false
              })
          }
        })
        .catch(() => {})
    },
    // 版本回退
    handleVersionFallback() {
      getBackRecord(this.$route.params.id).then(res => {
        const data = res.data
        this.versionFallbackData = data.version_list
        this.versionFallbackVisible = true
      })
    },
    handelOK() {
      this.urlLoading = true
      if (!this.versionFallback) {
        this.$message.error('请选择版本回退')
        this.urlLoading = false
        return false
      }
      backRecord(this.$route.params.id, { app_version: this.versionFallback })
        .then(res => {
          this.$message.success('版本回退成功')
          this.versionFallbackVisible = false
          this.urlLoading = false
          this.getLastRecordFunction()
        })
        .finally(() => {
          this.urlLoading = false
        })
    },
    // 设置类目
    handleSetCategory() {
      this.$confirm('此操作将给该小程序设置类目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setCategory(this.$route.params.id)
          .then(() => {
            this.$message({
              type: 'success',
              message: '设置类目成功!'
            })
          })
      }).catch(() => {})
    },
    handleSetPrivacy() {
      this.$confirm('此操作将给该小程序设置隐私协议, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setPrivacy(this.$route.params.id)
          .then(() => {
            this.$message({
              type: 'success',
              message: '设置隐私协议成功!'
            })
          })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.weChat_bg {
  width: 100%;
  height: 60px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: rgb(225, 240, 255);
  padding: 10px 24px;
}
.el-input--small {
  width: 40%;
}
</style>

<style scoped>
.form_box {
  margin-top: 10px;
  background-color: #fff;
}

.form_box_box {
  padding: 30px 20px;
}

.form_box_qx {
  padding: 24px 20px 30px;
}

.button-item {
  margin-top: 12px;
  padding: 9px 25px;
}

.el-tabs__header {
  padding: 0 20px;
  height: 56px;
  line-height: 56px;
  background-color: #fff;
  margin-bottom: 0;
}

.choose {
  /* margin-left: 10px; */
  width: 364px;
  height: 93px;
  border-radius: 6px;
  background-color: #e1f0ff;
  color: #3a3a3a;
}

.choose .left {
  width: 57px;
  height: 57px;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 18px;
}

.choose .left img {
  height: 38px;
  width: 38px;
}

.choose .button-item {
  margin-top: 0;
  margin-left: 50px;
}

.version {
  width: 414px;
  height: 110px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  /* background-image: linear-gradient(to right, #409eff, #40b9ff); */
  background-image: linear-gradient(to right, #00d3d3, #00c4c9);
  color: #fff;
  /* margin-left: 10px; */
  position: relative;
}

.version .left {
  width: 57px;
  height: 57px;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 18px;
}

.version .setting-url {
  /* position: absolute; */
  /* right: 24px; */
  /* top: 30px; */
  height: 28px;
  width: 104px;
  margin: 16px 0;
  line-height: 28px;
  text-align: center;
  border-radius: 12px;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  overflow: hidden;
}

.version-big {
  font-size: 16px;
  margin-bottom: 5px;
}

.program-info {
  /* margin-left: 10px; */
  width: 414px;
  border: 1px solid #e2e2e2;
  border-top: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 25px 0;
}

.program-info img {
  height: 148px;
  width: 148px;
  margin: 0 auto 25px;
  display: block;
}

.program-info div .program-text {
  padding: 0 20px;
  color: #606266;
  margin-top: 10px;
  font-size: 14px;
}

.program-info .program-tip div {
  color: #606266;
  height: 50px;
  line-height: 30px;
}

.program-info .program-tip .number {
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  margin-right: 22px;
  color: #409eff;
  background-color: #e1f0ff;
  margin-left: 22px;
}

.program-info .choose-version {
  margin: 10px 20px;
}

.program-info .choose-version > div:first-of-type {
  margin-right: 20px;
}

.program-info .button {
  width: 372px;
  text-align: center;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #409eff;
  margin-left: 20px;
  margin-top: 15px;
  cursor: pointer;
  background-color: #409eff;
  color: #fff;
}

.program-info .button_green {
  width: 372px;
  text-align: center;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #67c23a;
  margin-left: 20px;
  margin-top: 15px;
  cursor: pointer;
  background-color: #67c23a;
  color: #fff;
}

.program-info .to-test {
  margin-top: 30px;
  color: #409eff;
  background-color: #fff;
}

.el-dialog {
  min-width: 360px;
}

.add-btn.el-button.is-plain {
  border-color: #409eff;
  color: #409eff;
}
</style>
