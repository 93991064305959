<template>
  <div class="app-container">
    <div class="toolbar">
      <el-button v-show="btn_access('category_add')" type="primary" icon="el-icon-plus" @click="dialogVisible()">添加</el-button>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      row-key="id"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      class="el-table--small"
      @selection-change="handleSelectionChange"
      @select="selectChange"
      @select-all="selectAllChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="ID" width="80" />
      <el-table-column prop="name" label="分类名" min-width="180px" />
      <el-table-column prop="en_name" label="标识" min-width="180px" />
      <el-table-column prop="image" label="图标">
        <template slot-scope="{row}">
          <el-image
            v-if="row.image"
            style="width: 30px; height: 30px"
            :src="row.image"
            fit="fill"
            :preview-src-list="[row.image]"
          />
        </template>
      </el-table-column>

      <el-table-column prop="weigh" label="排序" />
      <el-table-column prop="status" label="状态">
        <template slot-scope="{row}">
          <el-tag v-if="row.status" type="success">显示</el-tag>
          <el-tag v-else type="warning">隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="160">
        <template slot-scope="scope">
          <el-button v-show="btn_access('category_edit')" type="primary" icon="el-icon-edit" @click="dialogVisible(scope.row.id)">编辑</el-button>
          <el-popconfirm
            v-show="btn_access('category_del')"
            title="删除该分类，确定继续吗？"
            @confirm="category_del(scope.row.id)"
          >
            <el-button slot="reference" type="danger" class="m-2" icon="el-icon-delete">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :before-close="cancelDialog"
      :visible.sync="dialog.visible"
      :title="dialog.id === null ? '添加分类':'编辑分类'"
      class="dialog_w750"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="父级分类" prop="parent_id">
          <SelectTree
            :props="defaultProps"
            :options="tableData"
            :value="form.parent_id"
            :clearable="isClearable"
            :accordion="isAccordion"
            :root-name="'顶级分类'"
            @getValue="getValue($event)"
          />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="form.type"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="分类名" prop="name">
          <el-input v-model="form.name" placeholder="分类名" />
        </el-form-item>
        <el-form-item label="标签名" prop="en_name">
          <el-input v-model="form.en_name" placeholder="标签名" />
        </el-form-item>
        <!-- <el-form-item label="关键词" prop="keywords">
          <el-input v-model="form.keywords" placeholder="关键词" />
        </el-form-item>
        <el-form-item label="简称" prop="nickname">
          <el-input v-model="form.nickname" placeholder="简称" />
        </el-form-item>
        <el-form-item label="自定义名称" prop="diyname">
          <el-input v-model="form.diyname" placeholder="自定义名称" />
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="form.description" placeholder="描述" />
        </el-form-item>
        <el-form-item label="标签" prop="flag">
          <el-input v-model="form.flag" placeholder="标签" />
        </el-form-item> -->
        <el-form-item label="分类图标" prop="image">
          <el-upload
            class="upload-demo"
            :action="adminUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-exceed="handleExceed"
            :on-success="upSuccess"
            :headers="utils.upload_headers()"
            :show-file-list="false"
            accept=".jpg,.jpeg,.png"
          >
            <el-avatar shape="square" :size="50" :src="form.image" title="点击上传">
              <img :src="errorImageSrc">
            </el-avatar>
          </el-upload>
        </el-form-item>
        <el-form-item label="显示隐藏" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio-button :label="1">显示</el-radio-button>
            <el-radio-button :label="0">隐藏</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分类排序" prop="weigh">
          <el-input-number
            v-model.number="form.weigh"
            :min="0"
            :max="999"
            controls-position="right"
            placeholder="分类排序"
          />
        </el-form-item>

      </el-form>
      <div slot="footer">
        <el-button type="text" @click="cancelDialog">取消</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { getSorts, addSorts, getSort, editSort, delSorts } from '@/api/admin/category.js'
import SelectTree from '@/components/TreeSelect/treeSelect.vue'
export default {
  components: {
    SelectTree
  },
  data() {
    return {
      isClearable: false, // 可清空（可选）
      isAccordion: false, // 可收起（可选）
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      // 列表
      tableData: [],
      // 弹窗
      dialog: {
        visible: false,
        id: null
      },
      // 数据
      form: {
        parent_id: 0,
        weigh: 100,
        status: 1,
        image: '',
        en_name: ''
      },
      // 校验
      rules: {
      },
      apiData: {
        url: '/v1/access'
      },
      typeOptions: [{
        value: 'files',
        label: '文件'
      }, {
        value: 'posters',
        label: '普通海报'
      }, {
        value: 'posters_marketing',
        label: '营销海报'
      }, {
        value: 'article',
        label: '帮助中心'
      }]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleSelectionChange(val) {},
    selectChange(selection, row, type = 'noAll') {
      // 如果selection中存在row代表是选中，否则是取消选中
      if (selection.find(val => {
        return val.id === row.id
      })) {
        if (row.children) {
          row.children.forEach(val => {
            this.$refs.multipleTable.toggleRowSelection(val, true)
            if (type === 'noAll') selection.push(val)
            if (val.children) {
              this.selectChange(selection, val, type)
            }
          })
        }
      } else this.toggle(selection, row)
    },
    /**
     * 取消选中状态
     */
    toggle(selection, data) {
      if (data.children) {
        data.children.forEach(val => {
          this.$refs.multipleTable.toggleRowSelection(val, false)
          if (val.children) {
            this.toggle(selection, val)
          }
        })
      }
    },
    /**
     * 用于树形表格多选, 选中所有
     */
    selectAllChange(selection) {
      // 如果选中的数目与请求到的数目相同就选中子节点，否则就清空选中
      if (selection && selection.length === this.tableData.length) {
        selection.forEach(row => {
          this.selectChange(selection, row, 'All')
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    /**
     * 打开弹窗
     */
    dialogVisible(id) {
      if (id) {
        this.dialog.id = id
        getSort(id).then(res => {
          this.form = res.data
          if (this.form.roles) {
            this.form.roles = this.form.roles.split(',')
          }
        }).catch(() => {})
      } else {
        this.dialog.id = null
        this.form = {
          parent_id: 0,
          weigh: 100,
          status: 1
        }
      }
      this.dialog.visible = true
    },
    /**
     * 关闭弹窗
     */
    cancelDialog() {
      this.dialog.visible = false
    },
    /**
     * 弹窗提交
     */
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.dialog.id) {
            const data = this.form
            delete data['create_time']
            delete data['update_time']
            editSort(this.dialog.id, this.form).then(res => {
              this.$message.success('修改成功！')
              this.dialog.visible = false
              // location.reload()
              this.getList()
            }).catch(() => {})
          } else {
            addSorts(this.form).then(res => {
              this.$message.success('添加成功！')
              this.dialog.visible = false
              // location.reload()
              this.getList()
            }).catch(() => {})
          }
        }
      })
    },
    // 取值
    getValue(value) {
      this.form.parent_id = value
    },
    // 权限列表
    getList() {
      getSorts().then(res => {
        this.tableData = res.data
      }).catch(() => {})
    },
    // 删除分类
    category_del(id) {
      delSorts(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      })
    },
    upSuccess(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'image', response.data.path)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
