<template>
  <el-dialog
    :visible.sync="visible"
    :width="equipment ? '60%' : '100%'"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :show-close="false"
    @open="handleOpen"
    @close="handleClose"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">提现余额</div>
        <div class="title_icon cursor" @click="handleCloseDialog">
          <i class="el-icon-close" />
        </div>
      </div>
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <el-form-item label="操作时间" class="window_pc">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <van-form label-width="80px" class="window_phone">
            <!-- 操作时间 -->
            <van-field
              readonly
              clickable
              name="calendar"
              :value="vanCreationTime"
              label="操作时间："
              placeholder="点击选择操作时间"
              @click="vanCreationTimeShow = true"
            />
            <van-calendar
              v-model="vanCreationTimeShow"
              type="range"
              :min-date="minVanCreationTime"
              :max-date="maxVanCreationTime"
              color="#1989fa"
              confirm-text="确 定"
              confirm-disabled-text="请选择结束时间"
              @confirm="onCreation"
            />
          </van-form>
          <el-form-item label="类型">
            <el-select v-model="listQueryParams.operation_type" placeholder="请选择类型" clearable>
              <el-option label="预售秒杀" :value="10" />
              <el-option label="班课" :value="20" />
              <el-option label="充值订单" :value="30" />
              <el-option label="付费约课" :value="40" />
              <el-option label="提现" :value="99" />
              <el-option label="提现驳回" :value="98" />
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
          <el-button class="m-b-10 window_phone" @click="handleReset">重置</el-button>
        </div>
      </div>
      <div class="filter-item" :style="{width: equipment ? '30%' : '80%'}">
        <el-input v-model="listQueryParams.notice" placeholder="搜索备注" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
    </div>
    <div v-loading="capitalLoading">
      <el-table :data="tableData.list" style="width: 100%">
        <el-table-column prop="date" label="品牌LoGo" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.app">
              <img v-if="row.app.logo" :src="row.app.logo" style="width: 60px; height: 60px;">
              <img v-else src="@/assets/images/logo.png" style="width: 60px; height: 60px;" alt="">
              <div class="venues_word">{{ row.app.name }}</div>
            </div>
            <div v-else>
              用户信息丢失
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="变动资金" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.operation_type === 99" style="color: red;">-{{ row.money }}</div>
            <div v-else>{{ row.money }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="money_before" label="变动前" align="center" />
        <el-table-column prop="money_after" label="变动后" align="center" />
        <el-table-column prop="operation_type" label="变动类型" width="120" align="center">
          <template slot-scope="{ row }">
            <el-tag v-if="row.operation_type === 10">预售秒杀</el-tag>
            <el-tag v-if="row.operation_type === 20">班课</el-tag>
            <el-tag v-if="row.operation_type === 30">充值订单</el-tag>
            <el-tag v-if="row.operation_type === 40">付费约课</el-tag>
            <el-tag v-if="row.operation_type === 99">提现</el-tag>
            <el-tag v-if="row.operation_type === 98">提现驳回</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="操作时间" align="center" :min-width="equipment ? '120px' : '180px'" />
        <el-table-column prop="notice" label="备注" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.notice">{{ row.notice }}</div>
            <div v-else>无</div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="tableData.count > 0 && equipment"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="handleOpen"
      />
      <van-pagination
        v-show="tableData.count > 0 && !equipment"
        v-model="tableDataPage"
        :total-items="tableData.count"
        :show-page-size="4"
        force-ellipses
        class="custom-pagination m-t-20"
        @change="handleTableDataChange"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleCloseDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { getBrandLogList } from '@/api/admin/capital.js'
// 分页组件
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination },
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false
    },
    // 传过来的id
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      capitalLoading: false,
      searchLoading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      tableData: {
        list: [],
        count: 0
      },
      title: '',
      timer: '',
      equipment: false,
      // 日期格式
      minVanCreationTime: new Date(2000, 0, 1),
      maxVanCreationTime: new Date(2099, 12, 31),
      // 创建时间
      vanCreationTime: '',
      vanCreationTimeShow: false,
      tableDataPage: 1
    }
  },
  computed: {},
  watch: {},
  created() {
    // 判断页面是否大于768
    if (document.body.clientWidth > 768) {
      this.equipment = true
    } else {
      this.equipment = false
    }
  },
  mounted() {
    this.timer = new Date().getTime()
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.searchLoading = true
      this.capitalLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      var querys = []
      if (this.equipment) {
        if (this.listQueryParams.create_time) {
          querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
        }
      } else {
        if (this.vanCreationTime) {
          const vanCreationTime = this.vanCreationTime.split(' 至 ')
          querys.push({ field: 'create_time', type: 'between-time', key: vanCreationTime })
        }
      }

      if (this.listQueryParams.id) {
        querys.push({ field: 'appid', key: this.listQueryParams.id })
      }
      if (this.listQueryParams.operation_type) {
        querys.push({ field: 'operation_type', key: this.listQueryParams.operation_type })
      }
      if (this.listQueryParams.notice) {
        querys.push({ field: 'notice', type: 'like', key: this.listQueryParams.notice })
      }
      data.query = this.utils.getQueryParams(querys)

      getBrandLogList(data, this.id)
        .then(res => {
          this.tableData = res.data
          // this.title = res.data.list[0].app.name
        })
        .finally(() => {
          this.capitalLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.handleOpen()
    },
    // 关闭的回调
    handleClose() {
      console.log('关闭')
      this.listQueryParams = {
        update_time: '',
        id: '',
        query: ''
      }
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.$emit('closeDialog')
    },
    cardId(val) {
      this.listQueryParams.id = val
    },
    handleTableDataChange(val) {
      this.listQuery.page = val
      this.handleOpen()
    },
    // 日期格式
    formatDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    // 创建时间
    onCreation(date) {
      const [start, end] = date
      this.vanCreationTimeShow = false
      this.vanCreationTime = `${this.formatDate(start)} 至 ${this.formatDate(end)}`
    },
    handleReset() {
      this.listQueryParams = {
        operation_type: '',
        create_time: '',
        notice: ''
      }
      this.vanCreationTime = ''
      this.handleOpen()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.van-cell {
  padding: 10px 0;
  ::v-deep {
    .van-cell__title {
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .window_pc {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .window_phone {
    display: none;
  }

  //弹出层的高度
  ::v-deep .el-dialog.import-dialog {
    height: auto;
    /* max-height: 70vh; */
  }
  //弹出层里内容的高度
  ::v-deep .el-dialog__body {
    max-height: 45vh !important;
    overflow-y: auto;
  }
}
</style>
