import request from '@/utils/request'

// 文件列表
export function getFiles(classify_id, data) {
  return request({
    url: `/admin/v1/file?classify_id=${classify_id}`,
    method: 'get',
    params: data
  })
}

// 文件上传
export function uploadFile(classify_id, data) {
  return request({
    url: `/admin/v1/upload`,
    method: 'post',
    data
  })
}

// 文件删除
export function delFile(id, data) {
  return request({
    url: `/admin/v1/file/${id}`,
    method: 'delete',
    data
  })
}

// 文件信息
export function getFileInfo(id, data) {
  return request({
    url: `/admin/v1/file/${id}`,
    method: 'get',
    params: data
  })
}

// 文件移动分组
export function setFile(data) {
  return request({
    url: `/admin/v1/file`,
    method: 'patch',
    params: data
  })
}
