<template>
  <div class="war">
    <el-row :gutter="30">
      <el-col class="m-t-10" :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <div class="app-container fillet p-30">
          <div class="font-16 el-message__content">
            平台趋势
          </div>
          <platformTrends :general-trend="workbenchData.generalTrend" />
        </div>
      </el-col>
      <el-col class="m-t-10" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="app-container fillet p-30" style="height: 360px;">
          <div class="flex">
            <div class="font-16 el-message__content">
              年度完成计划
            </div>
            <div class="font-14 m-l-10">
              <span>{{ workbenchData.yearPlan.appCount }}</span>
              （
              <span>{{ workbenchData.yearPlan.percentage }}</span>
              %）/
              <span>{{ workbenchData.yearPlan.yearNumber }}</span>
            </div>
          </div>
          <div class="m-t-20">
            <el-progress text-inside :stroke-width="15" :percentage="35" color="#7887fb" />
          </div>
          <div class="flex row-between font-16 m-t-16">
            <div>
              <div>品牌总数</div>
              <div class="m-t-12 font-24 text-warning">{{ workbenchData.appTotal.appCount }}</div>
            </div>
            <div>
              <div>有效品牌</div>
              <div class="m-t-12 font-24 text-success">{{ workbenchData.appTotal.appNotExpiredCount }}</div>
            </div>
            <div>
              <div>即将到期(7天)</div>
              <div class="m-t-12 font-24 text-danger">{{ workbenchData.appTotal.appWillExpireCount }}</div>
            </div>
          </div>
          <div class="m-t-20">
            <div class="font-16">客服客户分布图</div>
            <div class="m-t-10 font-14">
              <div v-for="(item, index) in workbenchData.appTotal.customerAppTotal" :key="index" class="flex row-between m-t-8">
                <div>{{ item.name }}</div>
                <div class="p-6 fillet" style="background: #f0f2ff; color: #9d9bd8;">
                  <span>{{ item.user_count }}</span>
                  /
                  <span>{{ item.pay_count }}</span>
                  (
                  <span>{{ item.percentage }}</span>
                  %)
                </div>
              </div>
              <!-- <div class="flex row-between m-t-8">
                <div>嬛嬛</div>
                <div class="p-6 fillet" style="background: #d3fbfa; color: #97d7da;">450 / 350 (77%)</div>
              </div>
              <div class="flex row-between m-t-8">
                <div>小九</div>
                <div class="p-6 fillet" style="background: #fad7dd; color: #e3a1a9;">1399 / 350 (25%)</div>
              </div> -->
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col class="m-t-20" :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
        <div class="app-container fillet p-30">
          <div class="font-16 el-message__content">今日注册</div>
          <div class="flex row-between col-top m-t-30">
            <div class="font-14">
              待处理
              <span class="text-warning">{{ workbenchData.dayData.day_register_data.pending_count }}</span>
              个，已通过
              <span class="text-success">{{ workbenchData.dayData.day_register_data.success_count }}</span>
              个, 已拒绝
              <span class="text-danger">{{ workbenchData.dayData.day_register_data.refuse_count }}</span>
              个
            </div>
            <div class="font-26">{{ workbenchData.dayData.day_register_data.count }}</div>
          </div>
          <div class="m-t-20">
            <el-progress text-inside :stroke-width="15" :percentage="100" color="#9b70fe" :format="format" />
          </div>
        </div>
      </el-col>
      <el-col class="m-t-20" :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
        <div class="app-container fillet p-30">
          <div class="font-16 el-message__content">今日到期</div>
          <div class="flex row-between col-top m-t-30">
            <div class="font-14">
              待跟进
              <span class="text-danger">{{ workbenchData.dayData.day_expire_data.not_followed_count }}</span>
              个，已跟进
              <span class="text-success">{{ workbenchData.dayData.day_expire_data.followed_count }}</span>
              个
            </div>
            <div class="font-26">{{ workbenchData.dayData.day_expire_data.expire_count }}</div>
          </div>
          <div class="m-t-20">
            <el-progress text-inside :stroke-width="15" :percentage="100" color="#f67385" :format="format" />
          </div>
        </div>
      </el-col>
      <el-col class="m-t-20" :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
        <div class="app-container fillet p-30">
          <div class="font-16 el-message__content">今日成交</div>
          <div class="flex row-between col-top m-t-30">
            <div class="font-14">
              新购
              <span class="text-success">{{ workbenchData.dayData.day_clinch_data.new_buy_count }}</span>
              ，续费{{ workbenchData.dayData.day_clinch_data.renewal_buy_count }}，短信{{ workbenchData.dayData.day_clinch_data.sms_buy_count }}
            </div>
            <div class="font-26">{{ workbenchData.dayData.day_clinch_data.app_clinch_count }}</div>
          </div>
          <div class="m-t-20">
            <el-progress text-inside :stroke-width="15" :percentage="100" color="#6bcbc2" :format="format" />
          </div>
        </div>
      </el-col>
      <el-col class="m-t-20" :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
        <div class="app-container fillet p-30">
          <div class="font-16 el-message__content">今日收入</div>
          <div class="flex row-between col-top m-t-30">
            <div class="font-14">
              新购
              <span class="text-success">{{ workbenchData.dayData.day_clinch_data.new_buy_sum }}</span>
              ，续费{{ workbenchData.dayData.day_clinch_data.renewal_buy_sum }}，短信{{ workbenchData.dayData.day_clinch_data.sms_buy_sum }}
            </div>
            <div class="font-26">{{ workbenchData.dayData.day_clinch_data.clinch_sum }}</div>
          </div>
          <div class="m-t-20">
            <el-progress text-inside :stroke-width="15" :percentage="100" color="#7887fc" :format="format" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col class="m-t-20" :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <div class="app-container fillet p-30">
          <div class="font-16 el-message__content">
            注册趋势
          </div>
          <registerTrends :register-trend="workbenchData.registerTrend" />
        </div>
      </el-col>
      <el-col class="m-t-20" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="app-container fillet p-30" style="height: 500px;">
          <div class="font-16 el-message__content">待办提醒</div>
          <div class="flex m-t-30">
            <div class="box_icon" style="background: #f0f2ff;">
              <img style="width: 40%; height: 40%;" src="@/assets/images/workbench/bird1.png" alt="bird1">
            </div>
            <div class="m-l-20">
              <div class="el-message__content">未完成定制小程序</div>
              <div class="m-t-10">
                总数
                <span>{{ workbenchData.toDoMessages.mini_program.count }}</span>
                个，待注册
                <span>{{ workbenchData.toDoMessages.mini_program.register }}</span>
                个，备案中
                <span>{{ workbenchData.toDoMessages.mini_program.icp }}</span>
                个，商户接入
                <span>{{ workbenchData.toDoMessages.mini_program.sub_mch }}</span>
                个，
                <br>
                未发布
                <span>{{ workbenchData.toDoMessages.mini_program.code_status_0 }}</span>
                个，已发布
                <span>{{ workbenchData.toDoMessages.mini_program.code_status_1 }}</span>
                个，审核中
                <span>{{ workbenchData.toDoMessages.mini_program.code_status_2 }}</span>
                个，审核失败
                <span>{{ workbenchData.toDoMessages.mini_program.code_status_3 }}</span>
                个
              </div>
            </div>
          </div>
          <div class="flex m-t-20">
            <div class="box_icon" style="background: #f0e8ff;">
              <img style="width: 40%; height: 40%;" src="@/assets/images/workbench/information.png" alt="information">
            </div>
            <div class="m-l-20" style="width: 90%;">
              <div class="el-message__content">待处理用户反馈</div>
              <div class="m-t-10">
                <span v-for="(item, index) in workbenchData.toDoMessages.feedback" :key="index">{{ item.type }}{{ item.count }}个，</span>
              </div>
            </div>
          </div>
          <div class="flex m-t-20">
            <div class="box_icon" style="background: #fad7dd;">
              <img style="width: 40%; height: 40%;" src="@/assets/images/workbench/xiyiji.png" alt="xiyiji">
            </div>
            <div class="m-l-20">
              <div class="el-message__content">
                <span>{{ workbenchData.toDoMessages.expire.count }}</span>
                个即将到期客户
              </div>
              <div class="m-t-10">
                未使用
                <span>{{ workbenchData.toDoMessages.expire.status_0 }}</span>
                个，已使用
                <span>{{ workbenchData.toDoMessages.expire.status_1 }}</span>
                个，已付费
                <span>{{ workbenchData.toDoMessages.expire.status_2 }}</span>
                个
              </div>
            </div>
          </div>
          <div class="flex m-t-20">
            <div class="box_icon" style="background: #fff6e1;">
              <img style="width: 40%; height: 40%;" src="@/assets/images/workbench/shoppingBag.png" alt="shoppingBag">
            </div>
            <div class="m-l-20">
              <div class="el-message__content">待处理提现申请</div>
              <div class="m-t-10">
                共
                <span>{{ workbenchData.toDoMessages.withdraw.count }}</span>
                条申请，共计余额
                <span>{{ workbenchData.toDoMessages.withdraw.money_sum }}</span>
                元
              </div>
            </div>
          </div>
          <div class="flex m-t-20">
            <div class="box_icon" style="background: #f0e8ff;">
              <img style="width: 40%; height: 40%;" src="@/assets/images/workbench/bird2.png" alt="bird2">
            </div>
            <div class="m-l-20">
              <div class="el-message__content">待处理开发票</div>
              <div class="m-t-10">
                共
                <span>{{ workbenchData.toDoMessages.invoice }}</span>
                张发票申请未进行处理
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col class="m-t-20" :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <div class="app-container fillet p-30">
          <div class="font-16 el-message__content">待跟进客户</div>
          <el-table class="m-t-20" height="420" :data="workbenchData.toDoMessages.todo_user" style="width: 100%">
            <el-table-column label="用户" :min-width="160">
              <template slot-scope="{ row }">
                <div v-if="!utils.empty(row)" class="flex col-center">
                  <div>
                    <el-avatar shape="square" :size="50" :src="row.logo">
                      <img :src="errorLogo()">
                    </el-avatar>
                  </div>
                  <div class="p-l-10">
                    <span>{{ row.real_name }}</span>
                    <br>
                    <span>{{ row.phone }}</span>
                  </div>
                </div>
                <div v-else class="text-danger">用户信息缺失</div>
              </template>
            </el-table-column>
            <el-table-column label="品牌" prop="name" :min-width="90" />
            <el-table-column label="套餐" prop="brand_level_text" :min-width="90" />
            <el-table-column label="到期时间" :min-width="120">
              <template slot-scope="{ row }">{{ row.expiration_time | formatDate('YYYY-MM-DD HH:mm') }}</template>
            </el-table-column>
            <el-table-column label="跟进事项" prop="todo_content" :min-width="120" />
          </el-table>
        </div>
      </el-col>
      <el-col class="m-t-20" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="app-container fillet p-30">
          <div class="font-16 el-message__content">今日跟进</div>
          <div v-if="workbenchData.dayAppTrackList.length > 0" style="height: 440px; overflow-y: scroll;">
            <div v-for="(item, index) in workbenchData.dayAppTrackList" :key="index" class="flex m-t-20">
              <el-avatar shape="square" :size="50" :src="item.avatar">
                <img :src="errorLogo()">
              </el-avatar>
              <div class="m-l-20" style="width: 86%;">
                <div class="el-message__content">{{ item.name }}</div>
                <div class="m-t-10">
                  {{ item.content }} - <span class="text-info">{{ item.create_time }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center" style="height: 460px;">
            <img :src="empty_default_img" alt="">
            <div class="text-info">当日跟进</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col class="m-y-20" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="app-container fillet p-30">
          <div class="flex row-between">
            <div class="font-16 el-message__content">今日交易排行</div>
            <div class="font-14">今日交易总额+￥{{ workbenchData.appDayPayRanking.money_sum }}</div>
          </div>
          <div v-if="workbenchData.appDayPayRanking.list.length !== 0" style="height: 280px; overflow-y: scroll;">
            <div v-for="(item, index) in workbenchData.appDayPayRanking.list" :key="index" class="flex row-between m-t-20">
              <!-- <div class="box_icon" style="background: #f0e8ff;" /> -->
              <div class="flex" style="width: 80%;">
                <el-avatar shape="square" :size="50" :src="item.logo">
                  <img :src="errorLogo()">
                </el-avatar>
                <div style="width: 70%; margin-left: 20px;">
                  <div class="el-message__content">{{ item.name }}</div>
                  <div class="m-t-10">
                    <span v-if="item.seckill_count != '0.00'">售卡/秒杀{{ item.seckill_count }}￥，</span>
                    <span v-if="item.subscribe_count != '0.00'">付费约课{{ item.subscribe_count }}￥，</span>
                    <span v-if="item.pay_count != '0.00'">{{ item.mclass_count }}￥，</span>
                    <span v-if="item.mclass_count != '0.00'">小班课{{ item.mclass_count }}￥，</span>
                    <span v-if="item.group_booking_count != '0.00'">拼团活动{{ item.group_booking_count }}￥，</span>
                    <span v-if="item.event_signup_count != '0.00'">活动报名{{ item.event_signup_count }}￥，</span>
                  </div>
                </div>
              </div>
              <div class="font-14" style="color: #01cbca;">+￥{{ item.money_count }}</div>
            </div>
          </div>
          <div v-else class="text-center" style="height: 280px;">
            <img :src="empty_default_img" alt="">
            <div class="text-info">当日暂无交易记录</div>
          </div>
        </div>
      </el-col>
      <el-col class="m-y-20" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="app-container fillet p-30">
          <div class="font-16 el-message__content">今日成交排行</div>
          <div v-if="workbenchData.customerRanking.length > 0" style="height: 280px; overflow-y: scroll;">
            <div v-for="(item, index) in workbenchData.customerRanking" :key="index" class="flex m-t-20">
              <div class="box_icon" style="background: #f0e8ff;">
                <img style="width: 50%; height: 50%;" src="@/assets/images/workbench/avatar.png" alt="avatar">
              </div>
              <div class="m-l-20" style="width: 90%;">
                <div class="el-message__content">{{ item.name }}</div>
                <div class="m-t-10">
                  新购（{{ item.new_buy_count }}个）{{ item.new_buy_sum }}￥，续费（{{ item.renewal_buy_count }}个）{{ item.renewal_buy_sum }}￥，短信{{ item.sms_buy_sum }}￥
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center" style="height: 280px;">
            <img :src="empty_default_img" alt="">
            <div class="text-info">当日暂无成交排行</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// API
import { getWorkbenchData } from '@/api/admin/adminWorkbench.js'
// 组件
import platformTrends from './components/platformTrends' // 平台趋势
import registerTrends from './components/registerTrends' // 注册趋势

export default {
  components: { platformTrends, registerTrends },
  data() {
    return {
      workbenchData: {
        appDayPayRanking: {
          list: [],
          money_sum: 0
        }, // 今日交易排行
        appTotal: {}, // 年度计划完成情况 下的 品牌统计
        customerRanking: [], // 客服收入排行
        dayAppTrackList: [], // 今日跟进列表
        dayData: {
          day_register_data: {
            count: 0,
            pending_count: '',
            success_count: '',
            refuse_count: ''
          },
          day_expire_data: {
            expire_count: 0,
            followed_count: '',
            not_followed_count: ''
          },
          day_clinch_data: {
            app_clinch_count: 0,
            clinch_sum: 0,
            new_buy_sum: '',
            renewal_buy_sum: '',
            sms_buy_sum: ''
          }
        }, // 今日数据
        generalTrend: [], // 平台趋势
        registerTrend: [], // 注册趋势
        toDoMessages: {
          mini_program: {},
          feedback: [],
          expire: {},
          withdraw: {},
          invoice: 0,
          todo_user: []
        }, // 待办消息及待跟进客服列表
        yearPlan: {} // 年度计划完成情况
      }
    }
  },
  created() {},
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      getWorkbenchData()
        .then(res => {
          const { data } = res
          console.log('🚀 ~ getData ~ data:', data)
          this.workbenchData = data
        })
        .finally(() => {})
    },
    format(percentage) {
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.war {
  background-color: #f3f4f8;
  .fillet {
    border-radius: 10px;
  }
  .box_icon {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
