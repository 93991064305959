<template>
  <div>
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
        <el-form-item label="类型">
          <el-select v-model="listQueryParams.type" placeholder="请选择" filterable clearable>
            <el-option label="团课" :value="0" />
            <el-option label="精品课" :value="2" />
            <el-option label="私教课" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="listQueryParams.status" placeholder="请选择" filterable clearable>
            <el-option label="已预约" :value="0" />
            <el-option label="已签到" :value="1" />
            <el-option label="已取消" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="上课时间">
          <el-date-picker
            v-model="listQueryParams.subscribe_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </div>
    </div>
    <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData.list" row-key="id">
      <el-table-column label="场馆">
        <template slot-scope="{ row }">{{ utils.getProperty(row, 'venues.name')||'--' }}</template>
      </el-table-column>
      <el-table-column label="课程">
        <template slot-scope="{ row }">
          <span class="p-b-4 cursor" style="border-bottom: 1px solid #1890ff; color: #1890ff;" @click="course_details(row)">{{ row.course.name }}（{{ course_type[row.type] }}）</span>
        </template>
      </el-table-column>
      <el-table-column label="卡号">
        <template slot-scope="{ row }">
          <el-tag>{{ row.order_type!==0?'付费约课':(utils.getProperty(row, 'user_membership_card.number')||'--') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="教练">
        <template slot-scope="{ row }">{{ utils.getProperty(row, 'store_user.real_name')||'--' }}</template>
      </el-table-column>
      <el-table-column prop="status" label="状态" min-width="120">
        <template slot-scope="{ row }">
          <span v-if="row.status == 0" class="text-primary">「{{ formatCourseStatus(row) }}」</span>
          <span v-if="row.status == 1" class="text-success">「{{ formatCourseStatus(row) }}」</span>
          <span v-if="row.status == 2" class="text-info">「{{ formatCourseStatus(row) }}」</span>
          <div v-if="row.status == 2">
            取消时间：{{ row.cancel_time | formatDate('YYYY-MM-DD HH:mm') }}
            <br>
            取消原因：{{ row.cancel_notice }}
            <br>
          </div>
          <div v-if="row.status !== 2">
            上课时间：{{ row.subscribe_time | formatDate('YYYY-MM-DD HH:mm') }}
            <br>
            预约时间：{{ row.create_time.substr(0, 16) }}
          </div>
          <div v-if="row.status == 1">签到时间：{{ row.sign_time | formatDate('YYYY-MM-DD HH:mm') }}</div>
          <div v-if="row.queue">排队队列：第{{ row.queue }}位</div>
          <div v-if="row.remark">备注：{{ row.remark }}</div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 预约详情 -->
    <bookInfo v-model="dialog" :logid.sync="logid" />
  </div>
</template>

<script>
// API
import { get_course_subscribe } from '@/api/store/record.js'
// 组件
import Pagination from '@/components/Pagination' // 分页组件
import bookInfo from '@/views/store/worktable/components/bookInfo'

export default {
  components: { Pagination, bookInfo },
  props: {
    userId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      tableLoading: false,
      // 状态:0=已预约,1=已签到,2=已取消
      course_status: ['已预约', '已签到', '已取消'],
      searchLoading: false,
      dialog: {},
      logid: 0
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      querys.push({ field: 'user_id', key: this.userId })
      if (this.listQueryParams.subscribe_time) {
        querys.push({ field: 'subscribe_time', type: 'between-time', key: this.listQueryParams.subscribe_time })
      }
      if (this.listQueryParams.type !== '' && this.listQueryParams.type != null) {
        querys.push({ field: 'type', key: this.listQueryParams.type })
      }
      if (this.listQueryParams.status !== '' && this.listQueryParams.status != null) {
        querys.push({ field: 'status', key: this.listQueryParams.status })
      }
      data.query = this.utils.getQueryParams(querys)

      get_course_subscribe(data).then(res => {
        this.tableData = res.data
        this.tableLoading = false
        this.searchLoading = false
      })
    },
    formatCourseStatus(row, colum) {
      return this.course_status[row.status]
    },
    // 预约详情
    course_details(row) {
      this.logid = row.id
      const title = row.type === 0 ? '预约团课记录' : row.type === 1 ? '预约私教课记录' : row.type === 2 ? '预约精品课记录' : '预约私教课记录'
      this.dialog = { title: title, visible: true, type: this.type }
    }
  }
}
</script>

<style lang="scss" scoped></style>
