var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择场馆",
            visible: _vm.dialogVisibleDisplay,
            width: "30%",
            "show-close": _vm.is_edit,
            "close-on-click-modal": _vm.is_edit,
            "close-on-press-escape": _vm.is_edit
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleDisplay = $event
            },
            open: _vm.dialogOpen,
            close: _vm.dialogClose
          }
        },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                width: "40%",
                title: "休息时间",
                visible: _vm.innerVisible,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.innerVisible = $event
                }
              }
            },
            [
              _vm.innerVisible
                ? _c(
                    "div",
                    [
                      _c("TimeQuantum", {
                        attrs: {
                          "store-user-id": _vm.userId,
                          "venues-id": _vm.venuesId
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ]
            },
            _vm._l(_vm.venues, function(item, index) {
              return _c(
                "el-tag",
                {
                  key: index,
                  staticClass: "m-10 cursor",
                  on: {
                    click: function($event) {
                      return _vm.handleClick(item.id)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleExit } }, [
                _vm._v("取 消")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }