var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wx-config" }, [
    _c("div", { staticClass: "wx-box" }, [
      _c("div", { staticClass: "item" }, [
        _c(
          "div",
          { staticClass: "label text-primary", attrs: { id: "relevance" } },
          [_vm._v("约课端访问设置")]
        ),
        _c("div", { staticClass: "content" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "m-t-20", staticStyle: { height: "100%" } },
            [
              _c("div", { staticClass: "flex" }, [
                _c("div", [_vm._v("禁止访问课表：")]),
                _c(
                  "div",
                  { staticClass: "m-l-40" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { disabled: !_vm.is_super() },
                        on: { change: _vm.handleNoVisitor },
                        model: {
                          value: _vm.form.no_visitor,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "no_visitor", $$v)
                          },
                          expression: "form.no_visitor"
                        }
                      },
                      [_vm._v("访客")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { disabled: !_vm.is_super() },
                        on: { change: _vm.handleMustLogIn },
                        model: {
                          value: _vm.form.must_log_in,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "must_log_in", $$v)
                          },
                          expression: "form.must_log_in"
                        }
                      },
                      [_vm._v("未登录")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { disabled: !_vm.is_super() },
                        on: { change: _vm.handleMustHavePhone },
                        model: {
                          value: _vm.form.must_have_phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "must_have_phone", $$v)
                          },
                          expression: "form.must_have_phone"
                        }
                      },
                      [_vm._v("未绑定手机号")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _vm._v(" 访客：访客禁止查看课表及课程 "),
      _c("br"),
      _vm._v(" 未登录：未登录用户将强制跳转登录页面 "),
      _c("br"),
      _vm._v(" 未绑定手机号：未绑定手机号的用户禁止查看课表及课程 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }