var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog_auto",
          attrs: {
            id: "list",
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.logVisible,
            title: "变更记录",
            "before-close": _vm.cancelDialog
          },
          on: {
            "update:visible": function($event) {
              _vm.logVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  ref: "multipleTable",
                  attrs: { data: _vm.tableData.list, "row-key": "id" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "user_membership_card_id", label: "卡号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.user_membership_card
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.user_membership_card.number
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "store_user_id", label: "操作人" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.store_user
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.store_user.account))
                                ])
                              : _c("span", [_vm._v("系统")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status.name", label: "操作类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-tag", { attrs: { type: "primary" } }, [
                              _vm._v(_vm._s(scope.row.status.name))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" 、"),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remark",
                      label: "备注",
                      "min-width": "160px"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "create_time", label: "操作时间" }
                  })
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tableData.count > 0,
                    expression: "tableData.count>0"
                  }
                ],
                attrs: {
                  total: _vm.tableData.count,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }