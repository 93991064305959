<template>
  <div v-loading="weChatPayLoading">
    <div class="weChat_bg">
      <div>尚未接入微信支付？点击快速接入服务商体系</div>
      <el-button class="m-x-20" type="primary" @click="handleDialogVisible">立即接入</el-button>
    </div>
    <el-form ref="ruleForm" label-position="right" :rules="rules" class="m-t-20" :model="ruleForm" label-width="140px">
      <el-form-item label="商户号" prop="sub_mch_id">
        <el-input v-model="ruleForm.sub_mch_id" />
      </el-form-item>
      <el-form-item label="服务商ID" prop="service_mch_id">
        <el-select v-model="ruleForm.service_mch_id" clearable placeholder="请选择服务商ID">
          <el-option v-for="(item, index) in getProviderDataList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="服务商ID" prop="service_mch_id">
        <el-input v-model="ruleForm.service_mch_id" />
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" :loading="btnLoading" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
    <!-- 弹出层 -->
    <el-dialog title="扫码接入服务体系" :visible.sync="dialogVisible" :close-on-click-modal="false" width="30%">
      <div class="option">
        <el-select v-model="service_mch_id" placeholder="请选择服务商ID">
          <el-option v-for="(item, index) in getProviderDataList" :key="index" :label="item.name" :value="item" />
        </el-select>
      </div>
      <div class="image">
        <img :src="service_mch_id.qrcode" alt="">
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getPayConfig, getProviderList, setPayConfig } from '@/api/admin/merchants.js'

export default {
  name: 'WeChatPay',
  components: {},
  data() {
    return {
      weChatPayLoading: false,
      dialogVisible: false,
      btnLoading: false,
      ruleForm: {
        sub_mch_id: '',
        service_mch_id: ''
      },
      rules: {
        sub_mch_id: [{ required: true, message: '请输入商户号', trigger: 'blur' }],
        service_mch_id: [{ required: true, message: '请输入服务商ID', trigger: 'blur' }]
      },
      getProviderDataList: [],
      service_mch_id: {}
    }
  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    getList() {
      this.weChatPayLoading = true
      getPayConfig(this.$route.params.id).then(res => {
        const data = res.data
        if (Object.keys(data.config).length > 0) {
          this.ruleForm.sub_mch_id = data.config.sub_mch_id
          // 如果data.config.service_mch_id是数字，就把它转换成数字
          if (typeof data.config.service_mch_id === 'number') {
            this.ruleForm.service_mch_id = data.config.service_mch_id
          } else {
            this.ruleForm.service_mch_id = parseInt(data.config.service_mch_id)
          }
        }
        this.weChatPayLoading = false
      })
      getProviderList().then(res => {
        this.getProviderDataList = res.data.list
      })
    },
    handleDialogVisible() {
      this.dialogVisible = true
      this.service_mch_id = this.getProviderDataList[0]
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.btnLoading = true
          setPayConfig(this.ruleForm, this.$route.params.id).then(res => {
            if (res.status === 200) this.$message.success(res.msg)
            else this.$message.error(res.msg)
            this.btnLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.weChat_bg {
  width: 100%;
  height: 60px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: rgb(225, 240, 255);
  padding: 10px 24px;
}
.el-input--small {
  width: 40%;
}
.option {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.image {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}
</style>
