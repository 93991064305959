<template>
  <!-- 班课课程表 -->
  <div class="app-container">
    <!-- 订单数 -->
    <div class="dd_num">
      <div class="dd">
        <div>
          <span class="text-success p-x-2 font-xs">
            <count-to :start-val="0" :end-val="tableData.count" :duration="2000" :decimals="0" />
          </span>
          单
        </div>
        <div>订单数</div>
      </div>
      <div class="dd">
        <div>
          <span class="text-success p-x-2 font-xs">
            <count-to :start-val="0" :end-val="money" :duration="2000" :decimals="0" />
          </span>
          元
        </div>
        <div>总收入</div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="tableColumn">
      <el-table v-loading="loading" border :data="tableData.list" style="width: 100%" :stripe="true">
        <el-table-column label="姓名" align="center" width="200">
          <template slot-scope="{ row }">
            <div class="user">
              <div class="block">
                <el-avatar shape="square" :size="50" :src="row.user.avatar">
                  <img :src="errorAvatarSrc(row.user, 'img')">
                </el-avatar>
              </div>
              <div class="m-l-10">
                <div>{{ row.user.nickname || row.user.real_name }}</div>
                <div v-if="row.user.phone">{{ row.user.phone }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="order_no" align="center" />
        <el-table-column label="支付方式" prop="charge_type" align="center" />
        <el-table-column label="付款金额" prop="charge_type" align="center">
          <template slot-scope="scope">
            <span>￥ {{ scope.row.charge_money }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="operator_type_text" align="center" />
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
// API
import { getGradeId } from '@/api/store/course'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import CountTo from 'vue-count-to'

export default {
  components: { Pagination, CountTo },
  data() {
    return {
      money: 0,
      loading: false,
      tableData: {
        list: [],
        count: 0
      } // 数据
    }
  },
  watch: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    // 数据
    getList() {
      this.loading = true
      this.money = 0

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      getGradeId(data, this.$route.params.id).then(res => {
        const data = res.data
        data.list.forEach(item => {
          item.charge_type = this.payment_method[item.charge_type]
        })
        data.list.forEach(item => {
          this.money += item.charge_money
        })
        this.tableData = data
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tableColumn {
  margin-top: 20px;
}
.user {
  display: flex;
  align-items: center;
  // justify-content: center;
}
.dd_num {
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
  .dd {
    width: 30%;
    height: 100px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
