<template>
  <div class="app-container">
    <div>
      <el-button v-show="btn_access('system_api_route_add')" class="m-b-20" type="primary" @click="handleAddClass">
        新增分类
      </el-button>
      <el-button v-show="btn_access('system_api_route_add')" class="m-l-20" type="success" @click="handleAddApi">
        新增接口
      </el-button>
      <el-button class="m-l-20" type="info" @click="handleSynchronization">
        同步
      </el-button>
    </div>
    <div v-loading="divLoading" style="display: flex;">
      <el-menu default-active="1-4-1" class="el-menu-vertical-demo" :collapse="false">
        <RecursiveMenu
          v-for="(item, index) in tableData"
          ref="myRecursiveMenu"
          :key="index"
          :menu-data="item"
          @componentsData="handleComponentsData"
          @refresh="handleRefresh"
        />
      </el-menu>
      <div>
        <div v-if="informationData.id" class="m-l-80 m-t-20">
          <div class="flex">
            <div class="font-18" style="font-weight: 600;">接口详情</div>
            <el-button
              v-if="informationData.hasOwnProperty('classify_id') && btn_access('system_api_route_edit')"
              class="m-l-20"
              type="primary"
              @click="handleEditApi(informationData.id)"
            >
              编辑接口
            </el-button>
            <el-button
              v-if="informationData.hasOwnProperty('classify_id') && btn_access('system_api_route_del')"
              class="m-l-20"
              type="danger"
              @click="handleDeleteApi(informationData.id)"
            >
              删除接口
            </el-button>
          </div>
          <div v-if="informationData.hasOwnProperty('classify_id')" class="m-t-40 font-14 content">
            <div class="font-16" style="font-weight: 600;">接口信息</div>
            <div class="m-l-30">
              <div>接口名称：{{ informationData.name ? informationData.name : '---' }}</div>
              <div>
                请求方式：
                <el-tag v-if="informationData.method === 'GET'">GET</el-tag>
                <el-tag v-else-if="informationData.method === 'POST'" type="success">POST</el-tag>
                <el-tag v-else-if="informationData.method === 'DELETE'" type="danger">DELETE</el-tag>
                <el-tag v-else-if="informationData.method === 'PUT'" type="warning">PUT</el-tag>
                <el-tag v-else-if="informationData.method === 'PATCH'" type="info">PATCH</el-tag>
                <span v-else>---</span>
              </div>
              <div>
                状态：
                <el-tag v-if="informationData.status === 0" type="danger">停用</el-tag>
                <el-tag v-else-if="informationData.status === 1" type="success">启用</el-tag>
                <span v-else>---</span>
              </div>
              <div>
                是否鉴权：
                <el-tag v-if="informationData.not_authentication === 1" type="danger">否</el-tag>
                <el-tag v-else-if="informationData.not_authentication === 0" type="success">是</el-tag>
                <span v-else>---</span>
              </div>
              <div>备注：{{ informationData.mark ? informationData.mark : '---' }}</div>
              <div>创建时间：{{ informationData.create_time ? informationData.create_time : '---' }}</div>
              <div>更新时间：{{ informationData.update_time ? informationData.update_time : '---' }}</div>
            </div>
            <div class="font-16" style="font-weight: 600;">调用方式</div>
            <div class="m-l-30">路由地址：{{ informationData.url ? informationData.url : '---' }}</div>
          </div>
          <div v-else class="m-t-40 font-14 content">
            <div class="font-16" style="font-weight: 600;">分类信息</div>
            <div class="m-l-30">分类名称：{{ informationData.name ? informationData.name : '---' }}</div>
          </div>
        </div>
      </div>
      <apiRouteDialog
        :dialog-visible-api-route="dialogVisibleApiRoute"
        :type="type"
        :type-id="typeId"
        @apiRouteVisible="apiRouteVisible"
      />
      <apiRouteDialogList
        :dialog-visible-api-route-list="dialogVisibleApiRouteList"
        :type="type"
        :type-id="typeId"
        @apiRouteVisibleList="apiRouteVisibleList"
      />
    </div>
    <el-pagination
      v-show="totalNumber > 0"
      class="m-t-20"
      :current-page="pages"
      :page-size="15"
      layout="total, prev, pager, next, jumper"
      :total="totalNumber"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
// API
import { getApiRouteList, deleteApiRoute } from '@/api/admin/account.js'
import apiRouteDialog from '../components/apiRouteDialog'
import apiRouteDialogList from '../components/apiRouteDialogList'
import RecursiveMenu from '../components/RecursiveMenu'

export default {
  components: { apiRouteDialog, apiRouteDialogList, RecursiveMenu },
  data() {
    return {
      tableData: [],
      TableLoading: false,
      dialogVisibleApiRoute: false,
      dialogVisibleApiRouteList: false,
      type: '',
      typeId: 0,
      listQueryParams: {
        query: ''
      },
      informationData: {},
      divLoading: false,
      start: 0, // 开始
      finish: 15, // 结束
      totalNumber: 0, // 总数
      pages: 0 // 当前页数
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.start = (val - 1) * 15
      this.finish = val * 15
      console.log(this.start, this.finish)
      this.getList()
    },
    getList() {
      this.TableLoading = true
      this.divLoading = true

      const data = {}

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'name', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      getApiRouteList(data)
        .then(res => {
          const { data } = res
          this.totalNumber = data.length
          const firstTwentyItems = data.slice(this.start, this.finish)
          this.tableData = firstTwentyItems
        })
        .finally(() => {
          this.TableLoading = false
          this.searchLoading = false
          this.divLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 新增分类
    handleAddClass() {
      this.type = 'add'
      this.dialogVisibleApiRoute = true
    },
    // 新增接口
    handleAddApi() {
      this.type = 'add'
      this.dialogVisibleApiRouteList = true
    },
    // 编辑接口
    handleEditApi(id) {
      this.type = 'edit'
      this.typeId = id
      this.dialogVisibleApiRouteList = true
    },
    // 删除接口
    handleDeleteApi(id) {
      this.$confirm('此操作将永久删除该接口, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteApiRoute(id).then(() => {
          this.getList()
          this.$message.success('删除成功!')
        })
      })
    },
    apiRouteVisible() {
      this.dialogVisibleApiRoute = false
      this.getList()
    },
    apiRouteVisibleList() {
      this.dialogVisibleApiRouteList = false
      this.getList()
    },
    // 子组件传过来的数据
    handleComponentsData(val) {
      this.informationData = val
    },
    // 刷新列表
    handleRefresh() {
      this.getList()
    },
    handleSynchronization() {
      this.getList()
    }
  }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 360px;
  min-height: 400px;
}
.el-menu-vertical-demo {
  /* 添加滚动条 */
  height: 76vh; /* 适当设置最大高度，防止菜单过长 */
  overflow-y: auto;
}
</style>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.content > div, .content > div > div {
  margin-top: 20px;
  font-weight: 600;
}
</style>
