<template>
  <div v-loading="isLoading">
    <el-divider content-position="center" class="text-info">会员卡信息</el-divider>
    <div class=" p-b-10">
      <el-descriptions>
        <el-descriptions-item label="卡号">{{ cardData.number }}</el-descriptions-item>
        <el-descriptions-item label="卡名称">{{ cardData.card?cardData.card.name:'--' }}</el-descriptions-item>
        <el-descriptions-item label="卡状态"><div>{{ formatStatus(cardData.status_id) }}</div></el-descriptions-item>
        <el-descriptions-item label="持卡人">
          <div v-if="!utils.empty(cardData.user)">
            {{ getUserNames(cardData.user) }}
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </el-descriptions-item>
        <el-descriptions-item v-if="cardData.status_id!==14" label="有效期至">{{ cardData.valid_time?cardData.valid_time:'' }}</el-descriptions-item>
        <el-descriptions-item v-if="cardData.status_id!==14" label="余额">
          <div>
            <span v-if="cardData.card.type===0">
              <span v-if="getExcessTime(cardData.valid_time)>0" class="text-success">{{ getExcessTime(cardData.valid_time) }} <b v-if="cardData.card">{{ formatCardUnitType(cardData.card.type) }}</b></span>
              <span v-else class="text-danger">{{ getExcessTime(cardData.valid_time) }} <b v-if="cardData.card">{{ formatCardUnitType(cardData.card.type) }}</b></span>
            </span>
            <span v-else>
              {{ cardData.limit }} <b v-if="cardData.card">{{ formatCardUnitType(cardData.card.type) }}</b>
            </span>
          </div>
        </el-descriptions-item>

      </el-descriptions>
    </div>
    <!-- 操作按钮 -->
    <div v-if="operation_type==''">
      <el-divider content-position="left" class="text-info">会员卡操作</el-divider>
      <div class="operation p-b-10">
        <!-- 请假 -->
        <el-button v-show="btn_access('s_user_vipcard_ask')" :disabled="disabledCheck(3)" class="m-5" @click="operation_active('ask_off')">请假</el-button>
        <!-- 取消请假 -->
        <el-button v-show="btn_access('s_user_vipcard_ask_cancels')" title="当会员卡请假还没有生效时，可手动取消请假" :disabled="disabledCheck(13)" class="m-5" @click="operation_ask_cancels">取消请假</el-button>
        <!-- 销假 -->
        <el-button v-show="btn_access('s_user_vipcard_wash')" :disabled="disabledCheck(5)" class="m-5" @click="operation_active('ask_cancel')">销假</el-button>
        <!-- 延长请假 -->
        <el-button v-show="btn_access('s_user_vipcard_extended_leave')" class="m-5" :disabled="disabledCheck(4)" @click="operation_active('extended_leave')">延长请假</el-button>

        <!-- 开卡 -->
        <el-button v-show="btn_access('s_user_vipcard_open')" class="m-5" :disabled="disabledCheck(1)" @click="operation_open()">开卡</el-button>
        <!-- 停卡 -->
        <el-button v-show="btn_access('s_user_vipcard_stop')" class="m-5" :disabled="disabledCheck(8)" @click="operation_active('stop')">停卡</el-button>
        <!-- 解除停卡 -->
        <el-button v-show="btn_access('s_user_vipcard_relieve_stop')" class="m-5" :disabled="disabledCheck(15)" @click="operation_relieve_stop()">解除停卡</el-button>
        <!-- 挂失 -->
        <el-button v-show="btn_access('s_user_vipcard_loss')" class="m-5" :disabled="disabledCheck(16)" @click="operation_active('loss')">挂失</el-button>
        <!-- 解挂 -->
        <el-button v-show="btn_access('s_user_vipcard_loss_unregister')" class="m-5" :disabled="disabledCheck(17)" @click="operation_active('loss_unregister')">解挂</el-button>
        <!-- 补卡 -->
        <el-button v-show="btn_access('s_user_vipcard_reissue')" class="m-5" :disabled="disabledCheck(19)" @click="operation_active('reissue')">补卡</el-button>

        <!-- 续卡 -->
        <el-button v-show="btn_access('s_user_vipcard_continue')" class="m-5" :disabled="disabledCheck(10)" @click="operation_active('continue')">续卡</el-button>
        <!-- 卡升级 -->
        <el-button v-show="btn_access('s_user_vipcard_upgrade')" class="m-5" :disabled="disabledCheck(18)" @click="operation_active('upgrade')">卡升级</el-button>
        <!-- 转卡 -->
        <el-button v-show="btn_access('s_user_vipcard_shift')" class="m-5" :disabled="disabledCheck(20)" @click="operation_active('shift')">转卡</el-button>
        <!-- 退卡 -->
        <el-button v-show="btn_access('s_user_vipcard_refund')" class="m-5" :disabled="disabledCheck(9)" @click="operation_active('refund')">退卡</el-button>
        <!-- 卡回收 -->
        <el-button v-show="btn_access('s_user_vipcard_refund')" class="m-5" :disabled="disabledCheck(24)" @click="operation_active('recovery')">卡回收</el-button>

        <!-- 删除 -->
        <el-button v-show="btn_access('s_user_vipcard_delete')" class="m-5" type="danger" @click="operation_del(cardInfo)">删除</el-button>
        <!-- <el-button v-show="btn_access('s_user_vipcard_config')" @click="operation_ask_setting">请假配置</el-button> -->
      </div>
    </div>

    <!-- 请假 -->
    <div v-if="operation_type=='ask_off'">
      <el-divider content-position="left" class="text-info">请假</el-divider>
      <div class="tips">请假后，卡片将暂停使用。销假后启用。到请假日期未销假自动销假。会员卡请假后将自动增加请假天数同等的会员卡有效期。如在请假期间销假，将扣减未请假天数同等的会员卡有效期。</div>
      <AskOff :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>

    <!-- 销假 -->
    <div v-if="operation_type=='ask_cancel'">
      <el-divider content-position="left" class="text-info">销假</el-divider>
      <div class="tips">销假后，卡片将启用。<span>按照实际销假日期计算本次请假时长。</span></div>
      <AskCancel :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>

    <!-- 延长请假 -->
    <div v-if="operation_type=='extended_leave'">
      <el-divider content-position="left" class="text-info">延长请假</el-divider>
      <ExtendedLeave :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>

    <!-- 停卡 -->
    <div v-if="operation_type=='stop'">
      <el-divider content-position="left" class="text-info">停卡</el-divider>
      <div class="tips">停卡后，卡片将暂停使用。解除停卡后启用。停卡期间如未开启会员卡延期，卡剩余天数将自然扣减，如开启会员卡延期，停卡期间会员卡有效期每天会自动进行增加，直至停卡结束。</div>
      <CardStop :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>
    <div v-if="operation_type=='relieve_stop'">
      <el-divider content-position="left" class="text-info">解除停卡</el-divider>
      解除停卡
    </div>
    <div v-if="operation_type=='loss'">
      <el-divider content-position="left" class="text-info">挂失</el-divider>
      <div class="tips">挂失后，卡片将立即停用。解挂启用。</div>
      <CardLoss :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>
    <div v-if="operation_type=='loss_unregister'">
      <el-divider content-position="left" class="text-info">解挂</el-divider>
      <div class="tips">解挂后，卡片将立即启用。</div>
      <CardUnLoss :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>
    <div v-if="operation_type=='reissue'">
      <el-divider content-position="left" class="text-info">补卡</el-divider>
      <div class="tips">补卡后，旧卡将立即失效。</div>
      <CardReissue :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>
    <div v-if="operation_type=='continue'">
      <el-divider content-position="left" class="text-info">续卡</el-divider>
      <Continue :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>
    <div v-if="operation_type=='upgrade'">
      <el-divider content-position="left" class="text-info">卡升级</el-divider>
      <Upgrade :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>
    <div v-if="operation_type=='refund'">
      <el-divider content-position="left" class="text-info">退卡</el-divider>
      <CardRefund :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>
    <div v-if="operation_type=='shift'">
      <el-divider content-position="left" class="text-info">转卡</el-divider>
      <CardShift :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>
    <div v-if="operation_type=='open'">
      <el-divider content-position="left" class="text-info">开卡</el-divider>
      开卡
    </div>
    <div v-if="operation_type=='recovery'">
      <el-divider content-position="left" class="text-info">卡回收</el-divider>
      <div class="tips">卡片回收后将从会员约课端卡包和支持卡列表中移除，且此操作不可逆，卡片将无法编辑，只能删除。请确认卡片已过期或余额为0且您不再需要，然后谨慎执行回收</div>
      <CardRecovery :id="cardData.id" :card-data="cardInfo" @back="o_back" @callback="operation_callback" />
    </div>
  </div>
</template>

<script>
import Continue from './Continue'
import CardStop from './CardStop'
import CardLoss from './CardLoss'
import ExtendedLeave from './ExtendedLeave'
import AskOff from './AskOff'
import AskCancel from './AskCancel'
import Upgrade from './Upgrade'
import CardUnLoss from './CardUnLoss'
import CardReissue from './CardReissue'
import CardRefund from './CardRefund'
import CardShift from './CardShift'
import CardRecovery from './CardRecovery'
import { getUserMembershipCardStatus, destroyUserMembershipLeave, delUserMembershipCard, isOperationMembershipCard, relieveStopUserMembershipCard, openUserMembershipCard } from '@/api/store/user.js'
export default {
  name: 'Operation',
  components: { Upgrade, Continue, AskOff, AskCancel, ExtendedLeave, CardStop, CardLoss, CardUnLoss, CardReissue, CardRefund, CardShift, CardRecovery },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      operation_type: '',
      o_status: {},
      cardInfo: this.cardData,
      statusData: {},
      statusList: {},
      isLoading: true
    }
  },
  created() {
    this.getCardOperation()
  },
  methods: {
    getCardOperation() {
      isOperationMembershipCard(this.cardData.id).then(res => {
        this.operation_type = ''
        this.o_status = res.data
      }).finally(() => {
        this.isLoading = false
      })
    },
    // 检查操作许可状态
    disabledCheck(n) {
      return !(this.o_status[n] ? this.o_status[n].can_op : false)
    },
    // 会员卡删除操作
    operation_del(row) {
      this.$confirm('删除会员卡操作不可恢复，如该卡当前已预约了课程则自动取消，确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUserMembershipCard(row.id).then(res => {
          this.operation_callback(res.msg)
        })
      }).catch(() => {
      })
    },
    // 解除停卡操作
    operation_relieve_stop() {
      this.$confirm('此操作将解除停卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        relieveStopUserMembershipCard(this.cardData.id).then(res => {
          this.operation_callback(res.msg)
        })
      })
    },
    // 开卡操作
    operation_open() {
      this.$confirm('会员卡开卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        openUserMembershipCard(this.cardData.id).then(res => {
          setTimeout(() => {
            this.operation_callback(res.msg)
          }, 1000)
        })
      })
    },
    // 取消请假操作
    operation_ask_cancels() {
      this.$confirm('此操作将取消会员卡请假, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        destroyUserMembershipLeave({ id: this.cardData.id }).then(res => {
          this.operation_callback('取消请假成功')
        })
      }).catch(() => {

      })
    },
    // 卡操作切换
    operation_active(name) {
      this.operation_type = name
    },
    // 操作成功回调
    operation_callback(v) {
      this.$emit('callback', v)
    },
    // 取消
    o_back(v) {
      this.$emit('input', v)
    },
    getCardStatus() {
      getUserMembershipCardStatus({ limit: 999 }).then(res => {
        const new_arr = []
        for (var n = 0; n < res.data.list.length; n++) {
          new_arr[res.data.list[n]['id']] = res.data.list[n]['name']
        }
        this.statusList = res.data.list
        this.statusData = new_arr
      }).catch(() => {})
    },
    // 状态码格式化
    formatStatus(s) {
      if (this.utils.empty(this.statusData)) {
        this.getCardStatus()
      }
      return this.statusData[s] ? this.statusData[s] : '----'
    }
  }
}
</script>

<style lang="scss" scoped>
.tips{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 15px;
    border: 1px dashed #f49352;
    color: #f49352;
    min-height: 25px;
    line-height: 25px;
}
</style>
