<template>
  <div id="platformTrends" v-loading="loading" element-loading-text="拼命加载中" :style="{ width: '100%', height: '300px' }" />
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    generalTrend: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      option: {
        title: {
          text: '',
          left: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        color: ['#909399', '#b3baff', '#8fe0db'],
        legend: {
          x: 'center',
          y: 'bottom',
          icon: 'circle',
          data: ['未使用', '已使用', '已付款']
        },
        grid: {
          left: '3%',
          right: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false, // 确保不留白
          data: []
        },
        yAxis: {
          type: 'value',
          min: 'dataMin', // 确保 yAxis 从数据最小值开始
          max: 'dataMax' // 确保 yAxis 到数据最大值
        },
        series: [
          {
            name: '未使用',
            type: 'line',
            smooth: true, // 让折线更加平滑（可选）
            symbol: 'circle', // 悬停时显示小圆点
            lineStyle: {
              color: 'rgba(144, 147, 153, 1)', // 折线颜色
              width: 2
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(144, 147, 153, 0.6)' }, // 渐变开始
                  { offset: 1, color: 'rgba(144, 147, 153, 0)' } // 渐变结束
                ])
              }
            },
            data: []
          },
          {
            name: '已使用',
            type: 'line',
            smooth: true, // 让折线更加平滑（可选）
            symbol: 'circle', // 悬停时显示小圆点
            lineStyle: {
              color: 'rgba(179, 186, 255, 1)', // 折线颜色
              width: 2
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(179, 186, 255, 0.6)' }, // 渐变开始
                  { offset: 1, color: 'rgba(179, 186, 255, 0)' } // 渐变结束
                ])
              }
            },
            data: []
          },
          {
            name: '已付款',
            type: 'line',
            smooth: true, // 让折线更加平滑（可选）
            symbol: 'circle', // 悬停时显示小圆点
            lineStyle: {
              color: 'rgba(144, 225, 219, 1)', // 折线颜色
              width: 2
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(144, 225, 219, 0.6)' }, // 渐变开始
                  { offset: 1, color: 'rgba(144, 225, 219, 0)' } // 渐变结束
                ])
              }
            },
            data: []
          }
        ]
      },
      loading: true
    }
  },
  watch: {
    generalTrend: {
      handler(newVal) {
        this.getData()
      },
      deep: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    getData() {
      this.option.xAxis.data = this.generalTrend.map(item => item.date)
      this.option.series[0].data = this.generalTrend.map(item => item.status_0)
      this.option.series[1].data = this.generalTrend.map(item => item.status_1)
      this.option.series[2].data = this.generalTrend.map(item => item.status_2)

      const myChart = this.$echarts.init(document.getElementById('platformTrends'))
      myChart.setOption(this.option)

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
