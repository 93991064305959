<template>
  <div>
    <div v-if="discountActivities" class="discountActivities cursor" @click="handleActivePopup">
      <div class="discount_bg">
        <div class="discount_font">
          <img src="@/assets/images/activity/suspensionFont.png" alt="">
        </div>
        <div class="discount_close" @click.stop="handleClose(0)">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <div v-if="discountPopover" class="discountPopover">
      <div class="popover_bg">
        <div class="popover_button cursor" @click="handlePopover">
          <img src="@/assets/images/activity/popoverButton.png" alt="">
        </div>
        <div class="popover_close cursor" @click="handleClose(1)">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <div v-if="discountPopover" class="discountBg" />
  </div>
</template>

<script>
// API
import { getActivityDetail } from '@/api/store/activity.js'
// vuex
import { mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      discountActivities: state => state.settings.discountActivities,
      discountPopover: state => state.settings.discountPopover
    })
  },
  mounted() {
    // this.getList()
  },
  methods: {
    getList() {
      const id = 1
      getActivityDetail(id).then(res => {
        if (res.data.is_end === 0) {
        // 判断是否有缓存
          const discountPopover = this.$cookies.get('618')
          // 24小时内不再弹出
          if (parseInt(discountPopover) + 172800000 > new Date().getTime()) {
          // 打开右侧弹窗
            this.$store.dispatch('settings/discountActivities', true)
          } else {
          // 定时器
            setTimeout(() => {
            // 打开弹窗
              this.$store.dispatch('settings/discountPopover', true)
            }, 800)
          }
        // if (parseInt(discountPopover) + 10000 > new Date().getTime()) return
        }
      })
    },
    // 右侧弹窗店家事件
    handleActivePopup() {
      // 跳转到折扣活动页面
      // 在新窗口中打开
      window.open('/store/activity', '_blank')
    },
    // 弹窗点击事件
    handlePopover() {
      // 跳转到折扣活动页面
      // 在新窗口中打开
      window.open('/store/activity', '_blank')
      // 跳转之后关闭弹窗
      this.$store.dispatch('settings/discountPopover', false)
      // 设置浏览器缓存 1天后自动清除
      this.$cookies.set('618', new Date().getTime())
      // 打开右侧弹窗
      this.$store.dispatch('settings/discountActivities', true)
    },
    // 关闭右侧弹窗
    handleClose(num) {
      if (num === 0) this.$store.dispatch('settings/discountActivities', false)
      if (num === 1) {
        this.$store.dispatch('settings/discountPopover', false)
        // 设置浏览器缓存 1天后自动清除
        this.$cookies.set('618', new Date().getTime())
        // 打开右侧弹窗
        this.$store.dispatch('settings/discountActivities', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.discountActivities {
  position: fixed;
  top: 50%;
  right: 0px;
  transform: translate(0%, -50%);
  z-index: 2004;
  .discount_bg {
    width: 171px;
    height: 223px;
    background-image: url('~@/assets/images/activity/suspension.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .discount_font {
      position: absolute;
      top: 99px;
      left: 36px;
      animation: myDiscountFont 1.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      img {
        display: inline-block;
        width: 100px;
      }
      /* 图片放大放小的动画 */
      @keyframes myDiscountFont {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.5);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    .discount_close {
      position: absolute;
      bottom: -35px;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 25px;
      height: 25px;
      border: 1px solid #dddddd;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 12px;
        color: #dddddd;
      }
    }
  }
}
.discountPopover {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  z-index: 99999999;
  animation: myFirst 1.8s;
  animation-timing-function: linear;
  /* animation-iteration-count: infinite; */
  .popover_bg {
    width: 442px;
    height: 387px;
    border-radius: 20px;
    background-image: url('~@/assets/images/activity/popover.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .popover_button {
      position: absolute;
      top: 84%;
      left: 52%;
      transform: translate(-50%, -50%);
      img {
        display: inline-block;
        width: 230px;
      }
    }
  }

  .popover_close {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 12px;
      color: #dddddd;
    }
  }
}

/* 图片放大放小的动画 */
@keyframes myFirst {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

.discountBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.42);
  z-index: 9999999;
}
</style>
