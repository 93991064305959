<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button v-show="btn_access('plan_add')" v-waves class="filter-item m-r-10" type="primary" icon="el-icon-plus" @click="add_edit('')">
        添加
      </el-button>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="name" label="套餐名称" />
      <el-table-column prop="brand_level" label="版本名称">
        <template slot-scope="{row}">
          {{ packageVersion[row.brand_level] ? packageVersion[row.brand_level] : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" />
      <el-table-column prop="valid_period" label="周期" :formatter="valid_period" />
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column prop="update_time" label="更新时间" />
      <el-table-column prop="allow_deduction" label="允许抵扣">
        <template slot-scope="{row}">
          <el-tag v-if=" row.allow_deduction == 1">允许</el-tag>
          <el-tag v-if=" row.allow_deduction == 0" type="danger">不允许</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('plan_edit')" type="primary" icon="el-icon-edit" class="m-2" @click="add_edit(scope.row.id)">编辑</el-button>
          <el-popconfirm
            v-show="btn_access('plan_del')"
            title="这是一段内容确定删除吗？"
            @confirm="plan_delete(scope.row.id)"
          >
            <el-button slot="reference" type="danger" icon="el-icon-delete" class="m-2">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.id ? '编辑套餐' : '添加套餐'"
      class="dialog_w580"
    >
      <el-form ref="ruleForm" v-loading="packageLoading" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请填写套餐名称" />
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="form.price" placeholder="请填写套餐价格" type="number" />
        </el-form-item>
        <el-form-item label="原价" prop="line_price">
          <el-input v-model="form.line_price" placeholder="请填写套餐原价" type="number" />
        </el-form-item>
        <el-form-item label="时间周期" prop="time">
          <el-input v-model="form.time" placeholder="请填写时间周期" type="number">
            <template slot="append">
              <el-select v-model="form.time_type" placeholder="请选择" style="width:70px;">
                <el-option
                  v-for="item in timeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="立省金额" prop="economy">
          <el-input v-model="form.economy" placeholder="请填写立省金额" type="number" />
        </el-form-item>
        <el-form-item label="套餐描述" prop="describe">
          <el-input v-model="form.describe" placeholder="请填写套餐描述" />
        </el-form-item>
        <el-form-item label="排序" prop="weigh">
          <el-input v-model="form.weigh" placeholder="请填写排序" type="number" />
        </el-form-item>
        <el-form-item label="套餐版本" prop="brand_level">
          <el-radio v-model="form.brand_level" :label="0">试用版</el-radio>
          <el-radio v-model="form.brand_level" :label="1">个人版</el-radio>
          <el-radio v-model="form.brand_level" :label="2">旗舰版</el-radio>
          <el-radio v-model="form.brand_level" :label="3">定制版</el-radio>
        </el-form-item>
        <el-form-item label="允许抵扣" prop="allow_deduction">
          <el-radio v-model="form.allow_deduction" :label="0">不允许</el-radio>
          <el-radio v-model="form.allow_deduction" :label="1">允许</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
        <el-button v-loading="loading.btnLoading" type="primary" @click="submit">
          {{ dialog.id ? '保存' : '新增' }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPlan, delPlan, addPlan, editPlan, infoPlan } from '@/api/admin/plan.js'
import Pagination from '@/components/Pagination' // 分页组件
export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      dialog: { visible: false, id: '' },
      loading: { listLoading: false },
      form: {
        name: '',
        price: '',
        line_price: '0',
        time: '',
        time_type: 'month',
        economy: 0,
        describe: '',
        weigh: 0,
        brand_level: 0,
        allow_deduction: 0
      },
      rules: {
        name: [{ required: true, message: '请输入套餐名称', trigger: 'blur' }],
        price: [{ required: true, message: '请输入套餐价格', trigger: 'blur' }],
        time: [{ required: true, message: '请输入时间周期', trigger: 'blur' }]
      },
      timeList: [{
        value: 'day',
        label: '天'
      }, {
        value: 'month',
        label: '月'
      }, {
        value: 'year',
        label: '年'
      }],
      packageLoading: false,
      packageVersion: ['试用版', '个人版', '旗舰版', '定制版']
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getPlan(data).then(res => {
        this.tableData = res.data
        this.loading.listLoading = false
      })
    },
    valid_period(item) {
      var valid_period = JSON.parse(item.valid_period)
      const digital = valid_period['digital']
      const type = valid_period['type']
      var time = ''
      switch (type) {
        case 'month':
          time = '个月'
          break
        case 'day':
          time = '天'
          break
        case 'year':
          time = '年'
          break
        default:
          break
      }
      return digital + time
    },
    plan_delete(id) {
      delPlan(id).then(res => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    add_edit(id) {
      if (id) {
        this.packageLoading = true
        infoPlan(id)
          .then(res => {
            var a = JSON.parse(res.data.valid_period)
            this.form = {
              name: res.data.name,
              price: res.data.price,
              line_price: res.data.line_price,
              time: a['digital'],
              time_type: a['type'],
              economy: res.data.economy,
              describe: res.data.describe,
              weigh: res.data.weigh,
              brand_level: res.data.brand_level,
              allow_deduction: res.data.allow_deduction
            }
          })
          .finally(() => {
            this.packageLoading = false
          })
      } else {
        this.form = this.$options.data.call(this).form
      }
      this.dialog.visible = true
      this.dialog.id = id
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var data = {}
          data.name = this.form.name
          data.price = this.form.price
          data.line_price = this.form.line_price
          data.valid_period = JSON.stringify({
            digital: this.form.time,
            type: this.form.time_type
          })
          data.economy = this.form.economy
          data.describe = this.form.describe
          data.weigh = this.form.weigh
          data.brand_level = this.form.brand_level
          data.allow_deduction = this.form.allow_deduction
          if (this.dialog.id) {
            editPlan(this.dialog.id, data).then(res => {
              this.$message.success('更新成功')
              this.getList()
            })
          } else {
            addPlan(data).then(res => {
              this.$message.success('添加成功')
              this.getList()
            })
          }
          this.dialog.id = ''
          this.dialog.visible = false
        }
      })
    }
  }
}
</script>

<style  lang="scss" scoped>
.el-input-group__append, .el-input-group__prepend{
  width: 70px;
}
</style>
