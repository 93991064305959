<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!-- 约课排行 -->
      <el-col :span="12" :xs="24">
        <classAppointment />
      </el-col>
      <!-- 卡消耗排行 -->
      <el-col :span="12" :xs="24">
        <cardConsumption />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- 热门课程 -->
      <el-col :span="12" :xs="24">
        <topRanking />
      </el-col>
      <!-- 积分排行 -->
      <el-col :span="12" :xs="24">
        <pointsRanking />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import classAppointment from './components/classAppointment.vue'
import cardConsumption from './components/cardConsumption.vue'
import topRanking from './components/topRanking.vue'
import pointsRanking from './components/pointsRanking.vue'

export default {
  components: { classAppointment, cardConsumption, topRanking, pointsRanking },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
