import { render, staticRenderFns } from "./Relevance.vue?vue&type=template&id=ab66b2be&scoped=true&"
import script from "./Relevance.vue?vue&type=script&lang=js&"
export * from "./Relevance.vue?vue&type=script&lang=js&"
import style0 from "./Relevance.vue?vue&type=style&index=0&id=ab66b2be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab66b2be",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\拾谷科技\\悠然瑜伽\\admin\\yoga-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab66b2be')) {
      api.createRecord('ab66b2be', component.options)
    } else {
      api.reload('ab66b2be', component.options)
    }
    module.hot.accept("./Relevance.vue?vue&type=template&id=ab66b2be&scoped=true&", function () {
      api.rerender('ab66b2be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/store/settings/wxconfig/Relevance.vue"
export default component.exports