<template>
  <el-form-item v-loadmore="loadMore" :label="title">
    <el-select
      v-model="goods_id"
      filterable
      clearable
      remote
      reserve-keyword
      :placeholder="'请选择' + title"
      :remote-method="selectChanged"
      :disabled="dispense"
      @visible-change="visibleChanged"
    >
      <el-option
        v-for="(item, index) in cardList"
        :key="index"
        :label="item.name"
        :value="item.id"
      />
      <div v-if="is_hide === 0" class="jzz">
        <i v-if="is_Yes === 1" class="el-icon-loading" />
        <span v-if="is_Yes === 1" class="jzz_span1">玩命加载中</span>
        <span v-if="is_Yes === 0" class="jzz_span2">----- 我也是有底线的 -----</span>
      </div>
    </el-select>
  </el-form-item>
</template>

<script>
import { getListTwo } from '@/api/admin/merchants.js'

export default {
  props: ['id', 'timer', 'titleS', 'name'],
  data() {
    return {
      cardList: [], // 品牌列表
      goods_id: '',
      selectPage: 1, // 当前页数
      is_Yes: 1,
      is_hide: 0,
      query: '',
      title: this.titleS ? this.titleS : '品牌',
      dispense: false,
      delayTimer: null
    }
  },
  computed: {},
  watch: {
    goods_id(val) {
      let value = ''
      if (this.name) {
        value = this.id
        this.goods_id = this.name
        this.dispense = true
      } else {
        value = val
      }
      this.$emit('cardId', value)
    },
    timer: {
      immediate: true, // 立即执行
      handler: function(newVal, oldVal) {
        this.goods_id = this.id
      }
    }
  },
  created() {
    this.getList(0)
  },
  mounted() {},
  methods: {
    getList(num) {
      let data = {}
      data = {
        offset: this.selectPage - 1,
        limit: 8
      }

      var querys = []
      if (this.query) querys.push({ field: 'name', type: 'like', key: this.query })
      data.query = this.utils.getQueryParams(querys)

      getListTwo(data)
        .then(res => {
          if (num === 0) {
            if (this.cardList.length === 0) {
              this.cardList = res.data.list
              if (res.data.list.length < 8) this.is_Yes = 3
            } else {
              if (res.data.list.length === 0) {
                this.is_Yes = 0
              } else {
                res.data.list.forEach(item => {
                  this.cardList.push(item)
                })
              }
            }
          } else if (num === 1) {
            this.cardList = res.data.list
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$emit('loading')
        })
    },
    selectChanged(val) {
      this.query = val
      this.is_Yes = 0
      this.getList(1)
      // if (val.length > 0) {
      //   if (this.delayTimer) {
      //     clearTimeout(this.delayTimer)
      //   }
      //   this.query = val
      //   this.delayTimer = setTimeout(() => {
      //     this.cardList = []
      //     this.is_hide = 1
      //     this.selectPage = 1
      //     this.getList(1)
      //   }, 500)
      // } else if (val.length === 0) {
      //   if (this.delayTimer) {
      //     clearTimeout(this.delayTimer)
      //   }
      //   this.delayTimer = setTimeout(() => {
      //     this.cardList = []
      //     this.query = null
      //     this.is_hide = 0
      //     this.is_Yes = 1
      //     this.selectPage = 1
      //     this.getList(0)
      //   }, 600)
      // }
    },
    visibleChanged(val) {
      if (val === false) {
        this.cardList = []
        this.query = null
        this.is_hide = 0
        this.is_Yes = 1
        this.selectPage = 1
        this.getList(0)
      }
    },
    loadMore() {
      this.selectPage += 8
      this.getList(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.jzz {
  text-align: center;
  font-size: 16px;
  margin: 6px 0;
  .jzz_span1 {
    margin-left: 10px;
    color: #ccc;
  }
  .jzz_span2 {
    color: #ccc;
  }
}
</style>
