<template>
  <div>
    <el-dialog
      id="list"
      append-to-body
      class="dialog_auto"
      :close-on-click-modal="false"
      :visible.sync="trackVisible"
      title="回访列表"
      :before-close="cancelDialog"
    >
      <div class="app-container">
        <div class="filter-container">
          <el-button v-if="currentVenues.id != -1" type="primary" icon="el-icon-plus" class="fr m-b-10" @click="dialogVisible()">添加</el-button>
        </div>
        <el-table ref="multipleTable" v-loading="loading" :data="tableData.list" row-key="id">
          <!-- <el-table-column prop="id" label="ID" width="50" /> -->
          <el-table-column prop="content" label="回访内容" min-width="150px" show-overflow-tooltip />
          <el-table-column prop="create_time" label="创建时间" />
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                v-show="btn_access('s_course_info')"
                class="m-2"
                type="primary"
                @click="dialogVisible(scope.row)"
              >编辑</el-button>
              <el-popconfirm title="删除后将无法恢复，确定吗？" @confirm="delInquireTrack(scope.row.id)">
                <el-button v-show="btn_access('s_visitors_delete')" slot="reference" class="m-2" type="danger">删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>

        </el-table>
        <pagination
          v-show="tableData.count > 0"
          :total="tableData.count"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </div>
    </el-dialog>
    <el-dialog
      v-loading="dialog.loading"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="添加客户回访记录"
      class="dialog_w580"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" :inline="true" label-width="80px">
        <el-form-item label="客户" prop="inquire_user_id">
          <el-input v-model="user.name" disabled class="dialog_w100" />
        </el-form-item>
        <el-form-item label="回访内容" prop="content">
          <el-input v-model="form.content" type="textarea" :rows="4" placeholder="请输入回访内容" class="dialog_w100" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button :loading="isLoading" type="primary" @click="submit">确认</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getInquireInfo,
  InquireTrack,
  delInquireTrack,
  editInquireTrack,
  addInquireTrack
} from '@/api/store/inquire.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  name: 'VisitorTrack',
  components: { Pagination },
  props: {
    trackVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10
      },
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 弹窗
      dialog: {
        visible: false,
        loading: false
      },
      // 数据
      form: {
        inquire_user_id: this.id,
        content: ''
      },
      user: {},
      rules: ['content', 'inquire_user_id'],
      loading: false,
      userInfo: [],
      isLoading: false
    }
  },
  watch: {
    id(newVal, oldVal) {
      this.listQuery = { page: 1, limit: 10 }
      this.loading = true
      this.getList()
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  methods: {
    // 删除
    delInquireTrack(id) {
      delInquireTrack(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      })
    },
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      var querys = []
      querys.push({
        field: 'inquire_user_id',
        type: '=',
        key: this.id
      })
      data.query = this.utils.getQueryParams(querys)
      InquireTrack(data)
        .then(res => {
          this.tableData = res.data
          this.loading = false
        })
        .catch(() => { })
    },
    getUserInfo() {
      getInquireInfo(this.id)
        .then(res => {
          this.user = res.data
        })
        .catch(() => { })
        .finally(() => {
          this.dialog.loading = false
        })
    },

    dialogVisible(data) {
      this.form = data ? {
        id: data.id,
        inquire_user_id: data.inquire_user_id,
        content: data.content
      } : {
        inquire_user_id: this.id,
        content: ''
      }
      this.getUserInfo()
      this.dialog = { visible: true, loading: true }
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true
          if (this.form.id) {
            editInquireTrack(this.form.id, this.form)
              .then(res => {
                this.$message.success('修改成功')
                this.getList()
                this.dialog.visible = false
                this.dialog.loading = false
                this.isLoading = false
              })
              .catch(() => { this.isLoading = false })
          } else {
            addInquireTrack(this.form)
              .then(res => {
                this.$message.success('添加成功')
                this.getList()
                this.dialog.visible = false
                this.dialog.loading = false
                this.isLoading = false
              })
              .catch(() => { this.isLoading = false })
          }
        }
      })
    },
    cancelDialog() {
      this.$emit('update:trackVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
#list {
  ::v-deep .el-dialog__body {
    padding: 0;
  }

  .app-container {
    padding: 10px 20px;
  }
}
</style>
