var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.loading,
        expression: "loading"
      }
    ],
    style: { width: "100%", height: "300px" },
    attrs: { id: "platformTrends", "element-loading-text": "拼命加载中" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }