import request from '@/utils/request'

// 付款记录列表
export function getPayRecordList(params) {
  return request({
    url: `/admin/v1/app/pay_log`,
    method: 'get',
    params
  })
}

// 新增付款记录
export function addPayRecord(data) {
  return request({
    url: '/admin/v1/app/pay_log',
    method: 'post',
    data
  })
}

// 付款记录详情
export function getPayRecordDetail(id) {
  return request({
    url: `/admin/v1/app/pay_log/${id}`,
    method: 'get'
  })
}

// 编辑付款记录
export function editPayRecord(id, data) {
  return request({
    url: `/admin/v1/app/pay_log/${id}`,
    method: 'put',
    data
  })
}

// 删除付款记录
export function deletePayRecord(id) {
  return request({
    url: `/admin/v1/app/pay_log/${id}`,
    method: 'delete'
  })
}
