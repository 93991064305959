var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "special-center"
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24, xs: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "div",
                    {
                      staticClass: "font-xxs",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_vm._v(" 课程信息 ")]
                  ),
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.variable.active == 1,
                          expression: "variable.active == 1"
                        }
                      ],
                      staticClass: "m-t-10",
                      attrs: { inline: true }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                            _c("h3", [_vm._v("课程信息")])
                          ]),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-right",
                              attrs: { span: 18, offset: 0 }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.modify(0)
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "info", icon: "el-icon-back" },
                                  on: {
                                    click: function($event) {
                                      return _vm.returnPage()
                                    }
                                  }
                                },
                                [_vm._v("返回")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "margin-top",
                          attrs: { column: 3, border: "" }
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-s-order" }),
                                _vm._v(" 课程名称 ")
                              ]),
                              _vm._v(" " + _vm._s(_vm.form.name) + " ")
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "" } },
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-s-flag" }),
                                _vm._v(" 课程类型 ")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.course_type[_vm.form.type]) +
                                  " "
                              )
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-s-custom" }),
                                _vm._v(" 教练 ")
                              ]),
                              [
                                _vm.form.store_user
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.store_user.real_name)
                                      )
                                    ])
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("教练待更新")]
                                    )
                              ]
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-s-home" }),
                                _vm._v(" 教室 ")
                              ]),
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.form.classroom
                                        ? _vm.form.classroom.name
                                        : "未知教室"
                                    ) +
                                    " "
                                )
                              ]
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-user" }),
                                _vm._v(" 容纳人数 ")
                              ]),
                              _vm._v(" " + _vm._s(_vm.form.galleryful) + "人 ")
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-star-on" }),
                                _vm._v(" 课程难度 ")
                              ]),
                              _c("el-rate", {
                                attrs: {
                                  value: _vm.form.difficulty,
                                  disabled: "",
                                  "text-color": "#ff9900",
                                  title: _vm.form.difficulty
                                }
                              })
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "" } },
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-time" }),
                                _vm._v(" 课程时长 ")
                              ]),
                              _vm._v(" " + _vm._s(_vm.form.duration) + " 分钟 ")
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "" } },
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-paperclip" }),
                                _vm._v(" 课程标签 ")
                              ]),
                              _vm._v(" " + _vm._s(_vm.form.label) + " ")
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "" } },
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-paperclip" }),
                                _vm._v(" 允许付费约课 ")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.is_pay == 1 ? "已开启" : "未开启"
                                  ) +
                                  " "
                              )
                            ],
                            2
                          ),
                          _vm.form.is_pay == 1
                            ? _c(
                                "el-descriptions-item",
                                { attrs: { label: "" } },
                                [
                                  _c("template", { slot: "label" }, [
                                    _c("i", {
                                      staticClass: "el-icon-paperclip"
                                    }),
                                    _vm._v(" 优惠价(元/节) ")
                                  ]),
                                  _vm._v(" " + _vm._s(_vm.form.price) + " ")
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.form.is_pay == 1
                            ? _c(
                                "el-descriptions-item",
                                { attrs: { label: "" } },
                                [
                                  _c("template", { slot: "label" }, [
                                    _c("i", {
                                      staticClass: "el-icon-paperclip"
                                    }),
                                    _vm._v(" 原价(元/节) ")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.form.original_price) + " "
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "margin-top",
                          attrs: {
                            column: 1,
                            border: "",
                            direction: "vertical"
                          }
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _c("i", { staticClass: "el-icon-edit" }),
                                _vm._v(" 课程简介 ")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.abstract ? _vm.form.abstract : "无"
                                  ) +
                                  " "
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.variable.active == 1,
                          expression: "variable.active == 1"
                        }
                      ],
                      attrs: { model: _vm.form }
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 6, offset: 0 } }, [
                            _c("h3", [_vm._v("支持卡类型")])
                          ]),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-right m-t-10",
                              attrs: { span: 18, offset: 0 }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-edit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.modify(1)
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "margin-top",
                          attrs: {
                            column: 3,
                            direction: "vertical",
                            border: ""
                          }
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "期限卡" } },
                            _vm._l(_vm.selectedCard[0], function(item) {
                              return _c("span", { key: item.id }, [
                                _c("i", { staticClass: "el-icon-bank-card" }),
                                _vm._v(" " + _vm._s(item.name) + " "),
                                _c("br")
                              ])
                            }),
                            0
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "次数卡" } },
                            _vm._l(_vm.selectedCard[1], function(item) {
                              return _c("span", { key: item.id }, [
                                _c("i", { staticClass: "el-icon-bank-card" }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.name) +
                                    "(每节课收费 " +
                                    _vm._s(item.charge) +
                                    " 次/人) "
                                ),
                                _c("br")
                              ])
                            }),
                            0
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "储值卡" } },
                            _vm._l(_vm.selectedCard[2], function(item) {
                              return _c("span", { key: item.id }, [
                                _c("i", { staticClass: "el-icon-bank-card" }),
                                _vm._v(
                                  " " +
                                    _vm._s(item.name) +
                                    "(每节课收费￥ " +
                                    _vm._s(item.charge) +
                                    " 元/人) "
                                ),
                                _c("br")
                              ])
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.variable.type === 0,
                          expression: "variable.type === 0"
                        }
                      ],
                      ref: "ruleForm",
                      attrs: {
                        rules: _vm.rules,
                        "label-position": "left",
                        model: _vm.form,
                        "label-width": "120px"
                      }
                    },
                    [
                      _c("h3", [_vm._v("课程信息")]),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "line-clear",
                                  attrs: { label: "课程图片", prop: "image" }
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.upLoading,
                                          expression: "upLoading"
                                        }
                                      ],
                                      staticClass: "images-uploader",
                                      attrs: {
                                        action: _vm.storeUpload,
                                        "show-file-list": false,
                                        "on-success": _vm.upSuccess,
                                        headers: _vm.utils.upload_headers(),
                                        "on-progress": _vm.upProgress,
                                        accept: ".jpg,.jpeg,.png"
                                      }
                                    },
                                    [
                                      _vm.form.image
                                        ? _c("img", {
                                            staticClass: "images",
                                            attrs: {
                                              src: _vm.form.image,
                                              title: "点击上传图片"
                                            }
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-plus images-uploader-icon",
                                            staticStyle: {
                                              border: "1px dashed #d9d9d9"
                                            }
                                          })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "line-clear",
                                  attrs: { label: "课程名称", prop: "name" }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "w-660",
                                    attrs: { placeholder: "请输入课程名称" },
                                    model: {
                                      value: _vm.form.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "line-clear",
                                  attrs: { label: "课程类型", prop: "type" }
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 0, disabled: "" },
                                      model: {
                                        value: _vm.form.type,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "type", $$v)
                                        },
                                        expression: "form.type"
                                      }
                                    },
                                    [_vm._v("团课")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 2, disabled: "" },
                                      model: {
                                        value: _vm.form.type,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "type", $$v)
                                        },
                                        expression: "form.type"
                                      }
                                    },
                                    [_vm._v("精品课")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 1, disabled: "" },
                                      model: {
                                        value: _vm.form.type,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "type", $$v)
                                        },
                                        expression: "form.type"
                                      }
                                    },
                                    [_vm._v("私教课")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "line-clear",
                                  attrs: { label: "课程难度", prop: "type" }
                                },
                                [
                                  _c("el-rate", {
                                    staticClass: "p-t-4 curriculum_rate",
                                    model: {
                                      value: _vm.form.difficulty,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "difficulty", $$v)
                                      },
                                      expression: "form.difficulty"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "教练",
                                    prop: "store_user_id"
                                  }
                                },
                                [
                                  _c("Select", {
                                    ref: "coachRef",
                                    attrs: {
                                      type: 4,
                                      width: 250,
                                      "coach-type": _vm.coachType
                                    },
                                    on: { changeId: _vm.handleCoachID }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "line-clear",
                                  attrs: { label: "教室", prop: "type" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w-250",
                                      attrs: { placeholder: "请选择教室" },
                                      model: {
                                        value: _vm.form.classroom_id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "classroom_id",
                                            $$v
                                          )
                                        },
                                        expression: "form.classroom_id"
                                      }
                                    },
                                    _vm._l(_vm.classroom, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "line-clear",
                                  attrs: { label: "课程时长", prop: "duration" }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "w-250",
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入课程时长"
                                      },
                                      model: {
                                        value: _vm.form.duration,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "duration", $$v)
                                        },
                                        expression: "form.duration"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("分钟")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _vm.form.type != 1
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "line-clear",
                                      attrs: {
                                        label: "容纳人数",
                                        prop: "galleryful"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticClass: "w-250",
                                          attrs: {
                                            type: "number",
                                            placeholder: "请输入容纳人数"
                                          },
                                          model: {
                                            value: _vm.form.galleryful,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "galleryful",
                                                $$v
                                              )
                                            },
                                            expression: "form.galleryful"
                                          }
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("人")
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "line-clear",
                                  attrs: { label: "课程标签" }
                                },
                                [
                                  _c("div", [
                                    !_vm.utils.empty(_vm.labelList)
                                      ? _c(
                                          "div",
                                          _vm._l(_vm.labelList, function(
                                            item,
                                            index
                                          ) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: index,
                                                staticClass: "m-2",
                                                attrs: { type: "info" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item ? item.name : ""
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "mini"
                                            },
                                            on: { click: _vm.selectLabel }
                                          },
                                          [_vm._v("选择标签")]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "允许付费约课",
                                    prop: "is_pay"
                                  }
                                },
                                [
                                  _c("el-switch", {
                                    model: {
                                      value: _vm.form.is_pay,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "is_pay", $$v)
                                      },
                                      expression: "form.is_pay"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.form.is_pay == true
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "优惠价(元/次)",
                                        prop: "price"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            type: "number",
                                            oninput: "if(value<0)value=0",
                                            placeholder: "请输入优惠价",
                                            disabled:
                                              _vm.form.is_pay == 1
                                                ? false
                                                : true
                                          },
                                          model: {
                                            value: _vm.form.price,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "price", $$v)
                                            },
                                            expression: "form.price"
                                          }
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("元")
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.is_pay == true
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "原价(元/次)",
                                        prop: "original_price"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            type: "number",
                                            oninput: "if(value<0)value=0",
                                            placeholder: "请输入原价",
                                            disabled:
                                              _vm.form.is_pay == 1
                                                ? false
                                                : true
                                          },
                                          model: {
                                            value: _vm.form.original_price,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "original_price",
                                                $$v
                                              )
                                            },
                                            expression: "form.original_price"
                                          }
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("元")
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "line-clear",
                                  attrs: { label: "简介" }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "w-660",
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入简介",
                                      rows: "3"
                                    },
                                    model: {
                                      value: _vm.form.abstract,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "abstract", $$v)
                                      },
                                      expression: "form.abstract"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.variable.type == 1,
                          expression: "variable.type == 1"
                        }
                      ],
                      attrs: { model: _vm.form }
                    },
                    [
                      _c("h3", [_vm._v("支持卡类型")]),
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames"
                          }
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "期限卡", name: "1" } },
                            [
                              _c("el-form-item", [
                                _c(
                                  "div",
                                  { staticClass: "tal" },
                                  [
                                    _c("checkboxInput", {
                                      attrs: {
                                        type: 0,
                                        "parent-selected": _vm.selectedCard[0],
                                        list: _vm.supportCard[0],
                                        "is-course": true
                                      },
                                      on: {
                                        "update:parentSelected": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.selectedCard,
                                            0,
                                            $event
                                          )
                                        },
                                        "update:parent-selected": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.selectedCard,
                                            0,
                                            $event
                                          )
                                        },
                                        "update:list": function($event) {
                                          return _vm.$set(
                                            _vm.supportCard,
                                            0,
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames"
                          }
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "次数卡", name: "2" } },
                            [
                              _c("el-form-item", [
                                _c(
                                  "div",
                                  { staticClass: "tal" },
                                  [
                                    _c("checkboxInput", {
                                      attrs: {
                                        type: 1,
                                        "parent-selected": _vm.selectedCard[1],
                                        list: _vm.supportCard[1],
                                        "is-course": true
                                      },
                                      on: {
                                        "update:parentSelected": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.selectedCard,
                                            1,
                                            $event
                                          )
                                        },
                                        "update:parent-selected": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.selectedCard,
                                            1,
                                            $event
                                          )
                                        },
                                        "update:list": function($event) {
                                          return _vm.$set(
                                            _vm.supportCard,
                                            1,
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames"
                          }
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "储值卡", name: "3" } },
                            [
                              _c("el-form-item", [
                                _c(
                                  "div",
                                  { staticClass: "tal" },
                                  [
                                    _c("checkboxInput", {
                                      attrs: {
                                        type: 2,
                                        "parent-selected": _vm.selectedCard[2],
                                        list: _vm.supportCard[2],
                                        "is-course": true
                                      },
                                      on: {
                                        "update:parentSelected": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.selectedCard,
                                            2,
                                            $event
                                          )
                                        },
                                        "update:parent-selected": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.selectedCard,
                                            2,
                                            $event
                                          )
                                        },
                                        "update:list": function($event) {
                                          return _vm.$set(
                                            _vm.supportCard,
                                            2,
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.variable.active == 2,
                          expression: "variable.active == 2"
                        }
                      ],
                      staticClass: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("修改")]
                      ),
                      _c("el-button", { on: { click: _vm.returnClick } }, [
                        _vm._v("返回")
                      ])
                    ],
                    1
                  ),
                  _c("courseLabelView", {
                    ref: "courseLabelView",
                    attrs: { "c-list": _vm.labelList },
                    on: { setLabel: _vm.setLabel },
                    model: {
                      value: _vm.labelShow,
                      callback: function($$v) {
                        _vm.labelShow = $$v
                      },
                      expression: "labelShow"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.variable.active == 1
            ? _c(
                "el-col",
                { staticClass: "m-t-20", attrs: { span: 24, xs: 24 } },
                [_c("album", { attrs: { id: _vm.id, type: _vm.type } })],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }