<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- 筛选 -->
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <el-form-item label="品牌" prop="brandID">
            <Select :type="1" @changeId="handleBrandID" />
          </el-form-item>
          <el-form-item label="操作时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索详情|URL" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-table v-loading="tableLoading" :data="tableData.list" stripe style="width: 100%">
        <el-table-column prop="date" label="品牌" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.app">
              <img v-if="row.app.logo" :src="row.app.logo" style="width: 60px; height: 60px;">
              <img v-else src="@/assets/images/logo.png" style="width: 60px; height: 60px;" alt="">
              <div class="venues_word">{{ row.app.name }}</div>
            </div>
            <div v-else style="color: red;">
              品牌信息丢失
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="场馆" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.venues">
              <div class="venues_word">{{ row.venues.name }}</div>
            </div>
            <div v-else style="color: red;">
              场馆信息丢失
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="store_user_id" label="操作用户" align="center" />
        <!-- <el-table-column prop="store_user_id" label="操作用户" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.store_user">
              <div class="venues_word">{{ row.store_user.real_name }}</div>
            </div>
            <div v-else style="color: red;">
              用户信息丢失
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="title" label="URL标题" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.title">
              <div class="venues_word">{{ row.title }}</div>
            </div>
            <div v-else style="color: red;">
              操作信息丢失
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="URL" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.url">
              <div class="venues_word">{{ row.url }}</div>
            </div>
            <div v-else style="color: red;">
              暂无url信息
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="detail" label="详情" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.detail">
              <div class="venues_word">{{ row.detail }}</div>
            </div>
            <div v-else style="color: red;">
              暂无详情信息
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="操作时间" align="center" />
      </el-table>
      <pagination
        v-show="tableData.count > 0"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
// API
import { getOperateLogList } from '@/api/admin/capital.js'
// 分页组件
import Pagination from '@/components/Pagination'
// 组件
import Select from '@/components/elSelect/Select'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'

export default {
  components: { Pagination, Select },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      searchLoading: false,
      tableLoading: false,
      appid: 0,
      timer: '',
      listQueryParams: {
        create_time: []
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    this.listQueryParams.create_time = [timestampToTimes(start.getTime()).slice(0, 10), timestampToTimes(end.getTime()).slice(0, 10)]
  },
  mounted() {
    this.getList()
    this.timer = new Date().getTime()
  },
  methods: {
    getList() {
      this.searchLoading = true
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      var querys = []
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.id) {
        querys.push({ field: 'appid', key: this.listQueryParams.id })
      }
      if (this.listQueryParams.query) {
        querys.push({ field: 'detail|url', type: 'like', key: this.listQueryParams.query })
      }
      data.query = this.utils.getQueryParams(querys)

      getOperateLogList(data)
        .then(res => {
          this.tableData = res.data
          this.searchLoading = false
          this.tableLoading = false
        })
        .catch(() => {
          this.searchLoading = false
          this.tableLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleBrandID(val) {
      this.listQueryParams.id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.venues_word {
  width: 200px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
</style>
