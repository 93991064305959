var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popover_btn" },
    [
      _c(
        "el-popover",
        {
          ref: "popover",
          attrs: {
            placement: "top",
            width: "180",
            icon: "el-icon-info",
            "icon-color": "red"
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.msg))]),
          _c(
            "div",
            { staticStyle: { "text-align": "right", margin: "0" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "text" },
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.del }
                },
                [_vm._v("确定")]
              )
            ],
            1
          ),
          _vm.btnMsg
            ? _c(
                "el-button",
                {
                  class: _vm.btnStyle,
                  attrs: {
                    slot: "reference",
                    type: "danger",
                    icon: "el-icon-delete"
                  },
                  slot: "reference"
                },
                [_vm._v(_vm._s(_vm.btnMsg))]
              )
            : _c("el-button", {
                class: _vm.btnStyle,
                attrs: {
                  slot: "reference",
                  type: "danger",
                  icon: "el-icon-delete"
                },
                slot: "reference"
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }