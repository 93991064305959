<template>
  <el-dialog
    :visible.sync="dialogSort"
    width="60%"
    :close-on-click-modal="false"
    :show-close="false"
    @open="handleOpen"
  >
    <div slot="title" class="title">
      <div class="title_title">排序</div>
      <div class="title_icon cursor" @click="handleCloseDialog(0)">
        <i class="el-icon-close" />
      </div>
    </div>
    <el-table ref="multipleTable" v-loading="handleLoading" :data="sortData" :row-key="row => row.id">
      <el-table-column prop="avatar" label="员工信息">
        <template slot-scope="{row}">
          <div v-if="isEmpty(row)" class="flex col-center cursor">
            <div>
              <el-avatar shape="square" :size="50" :src="row.avatar">
                <img :src="errorAvatarSrc(row, 'img')">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row) }}</span>
              <br>
              <span>{{ row.phone ? row.phone : '--' }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="教练类型">
        <template slot-scope="{row}">
          <el-tag v-if="row.is_private === 1" type="warning" class="m-2">私教</el-tag>
          <el-tag v-if="row.is_group === 1" class="m-2">团课</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="role_names" label="角色" /> -->
      <el-table-column prop="venues_name" label="场馆">
        <template slot-scope="{ row }">
          {{ row.venues_name }}
        </template>
      </el-table-column>
      <el-table-column min-width="30" align="center">
        <template>
          <i style="font-size: 16px;" class="el-icon-rank cursor_move" />
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer" style="display: block; text-align: center;">
      <el-button type="primary" @click="handlePreserve">保 存</el-button>
      <el-button @click="handleReset">重 置</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getEmployeeList, postEmployeeSort } from '@/api/store/venues.js'
import Sortable from 'sortablejs'

export default {
  props: {
    dialogSort: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortData: [],
      handleLoading: false,
      idArr: []
    }
  },
  mounted() {},
  methods: {
    // 打开的回调
    handleOpen() {
      this.handleLoading = true
      getEmployeeList().then(res => {
        this.sortData = res.data
        this.handleLoading = false
      })

      this.$nextTick(() => {
        console.log(this.$refs.multipleTable.$el.querySelector('.el-table__body-wrapper table tbody'))
        const tableElement = this.$refs.multipleTable.$el.querySelector('.el-table__body-wrapper table tbody')
        // const rows = tableElement.getElementsByTagName('tr')

        // for (let index = 0; index < rows.length; index++) {
        //   console.log(rows[index])
        // }

        new Sortable(tableElement, {
          animation: 600, // 拖拽动画时间（以毫秒为单位）
          onChoose: this.handleChoose, // 选择元素时的回调函数
          onEnd: this.handleEnd // 拖拽结束时的回调函数
        })
      })
    },
    // 关闭弹窗
    handleCloseDialog(num) {
      if (!num) {
        this.$confirm('你似乎还未保存排序，确定退出排序吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('sortDialog')
          this.idArr = []
        })
      } else {
        this.$emit('sortDialog')
        this.idArr = []
      }
    },
    handleEnd(evt) {
      this.idArr = []
      // 初始位置
      const oldIndex = evt.oldIndex
      // 更换后的位置
      const newIndex = evt.newIndex
      // 交换数据
      const oldData = this.sortData[oldIndex]
      this.sortData.splice(oldIndex, 1)
      this.sortData.splice(newIndex, 0, oldData)

      setTimeout(() => {
        // 获取每个元素的id
        this.sortData.forEach(item => {
          this.idArr.push(item.store_user_id)
        })
      }, 100)
    },
    handlePreserve() {
      if (this.idArr.length === 0) {
        this.sortData.forEach(item => {
          this.idArr.push(item.store_user_id)
        })
        console.log(this.idArr, 1)
      }

      // 保存排序
      postEmployeeSort({ store_user_ids: this.idArr }).then(res => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.handleCloseDialog(1)
      })
    },
    // 重置
    handleReset() {
      this.handleOpen()
      this.idArr = []
      // 滚动条回到顶部
      document.querySelector('.el-dialog__body').scrollTop = 0
    },
    handleChoose(evt) {
      console.log(evt)
    },
    isEmpty(v) {
      return !this.utils.empty(v)
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
