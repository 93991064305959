<template>
  <div class="p-b-10 cursor" style="margin: 0 auto;" :style="{width: badge ? '30px' : '80px'}" @click="toPage">
    <el-image v-if="type !== ''" :src="badge ? imageS[type] : image[type]" fit="fill" />
  </div>
</template>

<script>
import { getAppInfo } from '@/api/store/management.js'

export default {
  props: {
    badge: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      image: [
        require('@/assets/images/invite/huizhang01.png'),
        require('@/assets/images/invite/huizhang02.png'),
        require('@/assets/images/invite/huizhang03.png'),
        require('@/assets/images/invite/huizhang04.png')
      ],
      imageS: [
        require('@/assets/images/invite/huizhangS01.png'),
        require('@/assets/images/invite/huizhangS02.png'),
        require('@/assets/images/invite/huizhangS03.png'),
        require('@/assets/images/invite/huizhangS04.png')
      ],
      type: ''
    }
  },
  created() {},
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      getAppInfo()
        .then(res => {
          this.type = res.data.brand_level.level
        })
        .catch(() => {})
        .finally(() => {})
    },
    toPage() {
      this.$router.push({ name: 's_plan' })
    }
  }
}
</script>

<style lang="scss" scoped></style>
