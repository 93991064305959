<template>
  <div class="wx-config">
    <div class="wx-box">
      <div class="item ">
        <div class="">
          <div class="label text-primary">签到二维码</div>
          <div class="content flex">
            <div class="tips flex-1">会员打开<span class="text-primary">微信</span> -> <span class="text-primary">扫描二维码</span>进入约课，即可在约课后由系统自动签到。此二维码仅支持预约当日课程</div>
            <div class="p-l-30">
              <div class="text-center flex">
                <template>
                  <el-skeleton :loading="(!qrcodeInfo.wechat_path)" animated>
                    <template slot="template">
                      <el-skeleton-item variant="image" class="m-r-10" style="width: 180px; height: 180px;" />
                      <div style="padding: 14px;">
                        <div
                          style="display: flex; align-items: center; justify-items: space-between;"
                        >
                          <el-skeleton-item variant="text" />
                        </div>
                      </div>
                    </template>
                    <template>
                      <div class="image_box m-r-10"><el-image :preview-src-list="[qrcodeInfo.wechat_path+'?'+new Date().getTime()]" :src="qrcodeInfo.wechat_path" alt="" class="yueke_image" /></div>
                      <el-link icon="el-icon-download" class="image_down" type="primary" @click="downloadCodeImg('qrcodeInfo_wechat_path')">点击下载二维码</el-link>
                    </template>
                  </el-skeleton>
                </template>
                <template>
                  <el-skeleton :loading="(!qrcodeInfo.program_path)" animated>
                    <template slot="template">
                      <el-skeleton-item variant="image" class="m-r-10" style="width: 180px; height: 180px;" />
                      <div style="padding: 14px;">
                        <div
                          style="display: flex; align-items: center; justify-items: space-between;"
                        >
                          <el-skeleton-item variant="text" />
                        </div>
                      </div>
                    </template>
                    <template>
                      <div class="image_box m-r-10"><el-image :preview-src-list="[qrcodeInfo.program_path+'?'+new Date().getTime()]" :src="qrcodeInfo.program_path" alt="" class="yueke_image" /></div>
                      <el-link icon="el-icon-download" class="image_down" type="primary" @click="downloadCodeImg('qrcodeInfo_program_path')">点击下载二维码</el-link>
                    </template>
                  </el-skeleton>
                </template>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getQrcode } from '@/api/store/qrcode.js'
export default {
  name: 'SignIn',
  data() {
    return {
      qrcodeInfo: {
        wechat_path: ''
      }
    }
  },
  mounted() {
    this.getSignBook()
  },
  methods: {
    downloadCodeImg(name) {
      // const link = document.createElement('a')
      var file_name; var url = ''
      if (name === 'qrcodeInfo_wechat_path') {
        url = this.qrcodeInfo.wechat_path // codeIMG  要下载的路径
        file_name = this.utils.getApp().name + '_签到二维码.jpg'
      } else if (name === 'qrcodeInfo_program_path') {
        url = this.qrcodeInfo.program_path // codeIMG  要下载的路径
        file_name = this.utils.getApp().name + '_签到二维码.jpg'
      } else {
        url = ''
      }
      // this.utils.handleDownload({ url: '/store/v1/download?filePath=' + url, fileName: file_name, baseURL: this.baseUrl })
      this.utils.downloadIamge(url, file_name)
      // // 这里是将url转成blob地址，
      // fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
      //   link.href = URL.createObjectURL(blob)
      //   console.log(link.href)
      //   link.download = file_name
      //   document.body.appendChild(link)
      //   link.click()
      // })
    },
    getSignBook() {
      getQrcode().then(res => {
        console.log('🚀 ~ file: index.vue ~ line 76 ~ getAppInfo ~ res', res)
        this.qrcodeInfo = res.data
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.wx-config{
  padding-top:15px;

  .wx-box{
    border:1px solid #d9d9d9;
    border-radius: 4px;
    padding-bottom: 20px;
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .item{
      .image_down{
        padding-top: 5px;
      }
      .yueke_image{
        display: block;
        width: 180px;
      }
    }
    // margin: 0 40px;
    .label{
      width: 160px;
      height: 40px;
      background-color: #efeff2;
      padding: 10px;
      text-align: center;
    }
    .tips{
    color: #787878;
    margin: 0 40px;

    font-size: 12px;
    line-height: 1.5;
    }
  }

}
</style>
