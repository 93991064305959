<template>
  <div class="app-container">
    <div class="filter-container">
      <!-- 筛选 -->
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <!-- 小程序模板 -->
          <el-form-item label="小程序模板">
            <el-select v-model="listQueryParams.release_template_id" placeholder="请选择小程序模板" clearable>
              <el-option
                v-for="(item, index) in getTemplateDataList"
                :key="index"
                :label="item.user_version"
                :value="item.template_id"
              >
                <span class="text-info">{{ item.user_version }}</span>
                -
                <span>{{ item.user_desc.split('\n')[0] }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 状态 -->
          <el-form-item label="状态">
            <el-select v-model="listQueryParams.code_status" placeholder="请选择状态" clearable>
              <el-option label="未发布" :value="0" />
              <el-option label="已发布" :value="1" />
              <el-option label="审核中" :value="2" />
              <el-option label="审核失败" :value="3" />
              <el-option label="已撤回" :value="4" />
            </el-select>
          </el-form-item>
          <!-- 版本号 -->
          <el-form-item label="版本号">
            <el-input v-model="listQueryParams.release_version" placeholder="请输入条数" style="width: 300px">
              <el-select
                slot="prepend"
                v-model="listQueryParams.release_version_where"
                placeholder="请选择"
                style="width: 100px"
              >
                <el-option label="大于" value="gt" />
                <el-option label="小于" value="lt" />
                <el-option label="等于" value="=" />
              </el-select>
            </el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
      <div class="filter-item">
        <el-input
          v-model="listQueryParams.query"
          placeholder="搜索 品牌名|品牌ID|小程序名"
          @keyup.enter.native="handleFilter"
        >
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-dropdown class="course filter-item m-l-10" :hide-on-click="false">
        <el-button type="success">
          批量操作
          <i class="el-icon-arrow-down el-icon--right" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in tagDataList"
            v-show="btn_access(item.btn_access)"
            :key="index"
            :disabled="checkedCities.length === 0"
            @click.native="handleOperation(index)"
          >
            <template>{{ item.name }}</template>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 表格 -->
      <el-table
        v-loading="tableLoading"
        class="m-t-10"
        :data="dataList.list"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="60" :selectable="isSelectable" />
        <el-table-column prop="id" label="ID" min-width="60" />
        <el-table-column prop="name" label="品牌" />
        <el-table-column prop="name" label="注册" min-width="140">
          <template slot-scope="{ row }">
            <!-- 注册信息 -->
            <div class="flex m-y-10">
              <div>注册信息：</div>
              <div v-if="Array.isArray(row.register_status.fast_create)" class="text-danger">暂无注册信息</div>
              <div v-else class="flex">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.register_status.fast_create.name"
                  placement="right"
                >
                  <div>
                    {{ row.register_status.fast_create.legal_persona_name }} -
                    {{ row.register_status.fast_create.component_phone }}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <!-- 小程序信息 -->
            <div class="flex m-y-10">
              <div>小程序信息：</div>
              <el-tooltip
                v-if="row.register_status.program_name"
                class="item"
                effect="light"
                :content="row.register_status.fast_create.name"
                placement="right"
              >
                <template slot="content">
                  <div class="flex">
                    <div v-if="row.program_open_path" class="m-4">
                      <!-- <img style="width: 100px; height: 100px" :src="row.program_open_path" referrerPolicy="no-referrer"> -->
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="row.program_open_path"
                        :preview-src-list="[row.program_open_path]"
                      />
                      <div class="m-t-4 text-info" style="text-align: center;">品牌码</div>
                    </div>
                    <div v-if="row.qrcode_url" class="m-4">
                      <!-- <img style="width: 100px; height: 100px" :src="row.qrcode_url" referrerPolicy="no-referrer"> -->
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="row.qrcode_url"
                        :preview-src-list="[row.qrcode_url]"
                      />
                      <div class="m-t-4 text-info" style="text-align: center;">小程序码</div>
                    </div>
                    <div v-if="row.register_status.program_head" class="m-4">
                      <!-- <img style="width: 100px; height: 100px" :src="row.register_status.program_head" referrerPolicy="no-referrer"> -->
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="row.register_status.program_head"
                        :preview-src-list="[row.register_status.program_head]"
                      />
                      <div class="m-t-4 text-info" style="text-align: center;">头像</div>
                    </div>
                  </div>
                </template>
                <div class="m-r-6">{{ row.register_status.program_name }}</div>
              </el-tooltip>
              <div v-else>---</div>
            </div>
            <!-- 备案信息 -->
            <div class="flex m-y-10">
              <div>备案信息：</div>
              <div v-if="Array.isArray(row.register_status.program_icp)" class="text-danger">暂无备案信息</div>
              <div v-else>
                <div>
                  {{ row.register_status.program_icp.status_text }}（{{ row.register_status.program_icp.status }}）
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="发布" min-width="140">
          <template slot-scope="{ row }">
            <!-- 状态 -->
            <div class="flex m-y-10">
              <div>状态：</div>
              <div v-if="row.release_status.status">
                <el-tag :type="check(row.release_status.status).type">
                  {{ check(row.release_status.status).state }}
                </el-tag>
              </div>
              <div v-else>---</div>
            </div>
            <!-- 最新发布的版本 -->
            <div class="flex m-y-10">
              <div>最新发布的版本：</div>
              <div v-if="Array.isArray(row.release_status.code_push)" class="text-danger">暂无已发布记录</div>
              <div v-else>
                {{ row.release_status.code_push.version }}
              </div>
            </div>
            <!-- 已发布的版本 -->
            <div class="flex m-y-10">
              <div>已发布的版本：</div>
              <div v-if="Array.isArray(row.release_status.code_release)" class="text-danger">暂无已发布记录</div>
              <div v-else>
                {{ row.release_status.code_release.version }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商户号">
          <template slot-scope="{ row }">
            <!-- 商户号信息 -->
            <div class="flex m-y-10">
              <div v-if="Array.isArray(row.wechat_pay)" class="text-danger">暂无商户号信息</div>
              <div v-else>
                <div>商户号：{{ row.wechat_pay.sub_mch_id }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="状态">
          <template slot-scope="{ row }">
            <div v-if="row.code_status">
              <el-tag :type="conversion(row.code_status).type">{{ conversion(row.code_status).state }}</el-tag>
            </div>
            <div v-else>---</div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" @click="toPage(row)">查 看</el-button>
            <el-button v-show="btn_access('wxapp_update')" type="success" @click="refresh(row)">更 新</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 翻页组件 -->
      <pagination
        v-show="dataList.count > 0"
        :total="dataList.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
    <!-- 配置业务域名弹窗 -->
    <el-dialog title="配置业务域名" :visible.sync="urlVisible" :close-on-click-modal="false" width="785px">
      <div style="padding-left: 30px;">
        <div style="max-height: 400px;overflow: auto;">
          <el-form size="small" label-width="120px" label-position="left">
            <el-form-item v-for="(item, index) in domain" :key="index">
              <label slot="label">
                配置业务域名
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'以https://开头，例:https://www.yuxiaojiu.com'"
                  placement="top"
                >
                  <i class="el-icon-info" />
                </el-tooltip>
              </label>
              <div class="flex">
                <el-input v-model="domain[index]" style="width: 520px; margin-right: 20px;" />
                <el-tooltip effect="dark" content="删除" placement="top">
                  <img style="cursor: pointer;" src="@/assets/images/del.png" alt="" @click="delDomain(index)">
                </el-tooltip>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div style="color: #999999;margin: 20px 0 10px;">最多添加20条域名</div>
        <el-button v-if="domain.length < 20" class="add-btn" size="small" plain @click="addUrl">+添加域名</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="submitUrl">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 绑定体验者 -->
    <el-dialog
      title="绑定体验者"
      :visible.sync="bindExperienceVisible"
      :close-on-click-modal="false"
      width="785px"
      @close="cancelBindExperience"
    >
      <div class="flex">
        <el-form size="small" label-width="120px" label-position="right" @submit.native.prevent>
          <el-form-item label="体验者微信号">
            <el-input v-model="bindExperienceValue" style="width: 520px;" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="handleWeChat(1)">绑 定</el-button>
        <el-button size="small" type="danger" @click="handleWeChat(0)">解 绑</el-button>
        <el-button size="small" @click="bindExperienceVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 上传代码 -->
    <el-dialog title="上传代码" :visible.sync="uploadCodeVisible" :close-on-click-modal="false" width="20%">
      <div v-loading="visibleLoading">
        <div class="font-14" style="font-weight: 600;">选择版本</div>
        <div class="flex-col p-l-20 m-t-20">
          <div v-for="(item, index) in getTemplateDataList" :key="index" style="margin-bottom: 15px">
            <el-radio v-model="is_plugin" :label="item.template_id" @change="handlePlugin(item)">
              {{ item.user_desc.split('\n')[0] }}({{ item.user_version }})
            </el-radio>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="uploadCodeVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSubmitUploadCode">提交上传</el-button>
      </span>
    </el-dialog>
    <!-- 提交审核 -->
    <el-dialog title="提交审核" :visible.sync="auditVisible" :close-on-click-modal="false" width="20%">
      <div v-loading="visibleLoading">
        <div class="font-14" style="font-weight: 600;">选择版本</div>
        <div class="flex-col p-l-20 m-t-20">
          <div v-for="(item, index) in getTemplateDataList" :key="index" style="margin-bottom: 15px">
            <el-radio v-model="is_plugin" :label="item.template_id" @change="handlePlugin(item)">
              {{ item.user_desc.split('\n')[0] }}({{ item.user_version }})
            </el-radio>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="auditVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSubmitForReview">提交审核</el-button>
      </span>
    </el-dialog>
    <!-- 加载条 -->
    <el-dialog
      :title="loadComplete ? '加载完成' : '加载中'"
      :visible.sync="loadVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="30%"
      @close="handleClose"
    >
      <div>
        <el-progress v-if="!loadComplete" :text-inside="true" :stroke-width="26" :percentage="loadNumber" />
        <div v-if="loadComplete">
          <el-table :data="errorData" style="width: 100%">
            <el-table-column prop="id" label="小程序名称">
              <template slot-scope="{ row }">
                {{ nameFunction(row.id) }}
              </template>
            </el-table-column>
            <el-table-column prop="err" label="错误信息" />
          </el-table>
        </div>
      </div>
      <span v-if="loadComplete" slot="footer" class="dialog-footer">
        <el-button size="small" @click="loadVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import {
  getListMiniPrograms,
  setDomain,
  bindTester,
  unbindTester,
  getTemplateList,
  submitAudit,
  recallAudit,
  release,
  getBackRecord,
  updateMiniInfo,
  uploadCode,
  setCategory,
  setPrivacy
} from '@/api/admin/merchants.js'
// 分页组件
import Pagination from '@/components/Pagination'

export default {
  components: { Pagination },
  data() {
    return {
      dataList: {
        list: [],
        count: 0
      },
      loadNumber: 0,
      listLoading: false,
      tagDataList: [
        { name: '配置业务域名', btn_access: 'wxapp_business_domain' },
        { name: '绑定体验者', btn_access: 'wxapp_bind_tester' },
        { name: '上传代码', btn_access: 'wxapp_upload' },
        { name: '提交审核', btn_access: 'wxapp_submit_review' },
        { name: '撤回审核', btn_access: 'wxapp_un_do_code_audit' },
        { name: '立即发布', btn_access: 'wxapp_release' },
        { name: '版本回退', btn_access: 'wxapp_un_release' },
        { name: '设置类目', btn_access: 'wxapp_add_category' },
        { name: '设置隐私协议', btn_access: 'wxapp_set_privacy_setting' },
        { name: '更新', btn_access: 'wxapp_update' }
      ],
      checkedCities: [], // 选中的id
      tableLoading: false, // 表格的Loading
      visibleLoading: false, // 窗口Loading
      loadVisible: false,
      loadComplete: false,
      searchLoading: false,
      errorData: [],
      getTemplateDataList: [],
      getTemplateData: {
        template_id: 1,
        user_version: '2.3.3',
        user_desc: '修复班课优惠价格显示问题\n拾谷.℡¹⁸²⁵⁸⁷⁷¹⁹⁵⁷ 在 2023年6月27日下午3点25分 提交上传'
      },
      is_plugin: '',
      urlVisible: false, // 配置业务域名弹窗
      bindExperienceVisible: false, // 绑定体验者弹窗
      uploadCodeVisible: false, // 上传代码弹窗
      auditVisible: false, // 提交审核弹窗
      bindExperienceValue: '',
      domain: [],
      numberIndex: 0
    }
  },
  computed: {
    theSum() {
      return 100 / this.checkedCities.length
    }
  },
  created() {},
  mounted() {
    this.getList()
    this.getInfo()
  },
  methods: {
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      var querys = []
      if (this.listQueryParams.query) { querys.push({ field: 'a.name|a.id|wa.nick_name', type: 'like', key: this.listQueryParams.query }) }
      if (this.listQueryParams.release_template_id) { querys.push({ field: ' wa.release_template_id', key: this.listQueryParams.release_template_id }) }
      if (this.listQueryParams.code_status) { querys.push({ field: 'wa.code_status', key: this.listQueryParams.code_status }) }
      if (this.listQueryParams.release_version) {
        querys.push({
          field: 'wa.release_version',
          type: this.listQueryParams.release_version_where,
          key: this.listQueryParams.release_version
        })
      }
      data.query = this.utils.getQueryParams(querys)

      getListMiniPrograms(data)
        .then(res => {
          this.dataList = res.data
        })
        .finally(() => {
          this.tableLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getInfo() {
      this.visibleLoading = true
      getTemplateList()
        .then(res => {
          this.getTemplateDataList = res.data.list.template_list.reverse()
          this.getTemplateData = this.getTemplateDataList[0]
          this.is_plugin = this.getTemplateData.template_id
        })
        .finally(() => {
          this.visibleLoading = false
        })
    },
    handleOperation(index) {
      switch (index) {
        case 0:
          this.urlVisible = true
          break
        case 1:
          this.bindExperienceVisible = true
          break
        case 2:
          this.uploadCodeVisible = true
          this.getInfo()
          break
        case 3:
          this.auditVisible = true
          this.getInfo()
          break
        case 4:
          this.handleWithdrawalReview()
          break
        case 5:
          this.handleRelease()
          break
        case 6:
          this.handleVersionFallback()
          break
        case 7:
          this.handleSetCategory()
          break
        case 8:
          this.handleSetPrivacy()
          break
        case 9:
          this.handleUpdate()
          break
        default:
          break
      }
    },
    // 配置业务域名操作
    delDomain(index) {
      this.domain.splice(index, 1)
    },
    addUrl() {
      this.domain.push('')
    },
    cancel() {
      this.domain = []
      this.urlVisible = false
    },
    submitUrl() {
      for (const item of this.domain) {
        const reg = /(https):\/\/([\w.]+\/?)\S*/
        if (!reg.test(item)) {
          this.$message.error('请输入正确的网址')
          return false
        }
      }
      if (this.domain.length === 0) {
        this.$message.error('请输入网址')
        return false
      }
      this.loadVisible = true
      this.urlVisible = false
      this.checkedCities.forEach((item, index) => {
        setDomain(this.checkedCities[index], { webviewdomain: this.domain })
          .then(() => {
            this.errorData.push({
              id: this.checkedCities[index],
              err: '操作成功'
            })
          })
          .catch(err => {
            this.errorData.push({
              id: this.checkedCities[index],
              err: err.message
            })
          })
          .finally(() => {
            this.loadFunction(index)
          })
      })
    },
    // 绑定/解绑体验者
    handleWeChat(type) {
      if (!this.bindExperienceValue) {
        this.$message.error('请输入体验者微信号')
        return false
      }
      this.$confirm(`确定要${type === 1 ? '绑定' : '解绑'}（${this.bindExperienceValue}）此微信号吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loadVisible = true
          this.bindExperienceVisible = false
          this.checkedCities.forEach((item, index) => {
            if (type === 1) {
              bindTester(this.checkedCities[index], { wechatid: this.bindExperienceValue })
                .then(() => {
                  this.errorData.push({
                    id: this.checkedCities[index],
                    err: '操作成功'
                  })
                })
                .catch(err => {
                  this.errorData.push({
                    id: this.checkedCities[index],
                    err: err.message
                  })
                })
                .finally(() => {
                  this.loadFunction(index)
                })
            } else {
              unbindTester(this.$route.params.id, { wechatid: this.bindExperienceValue })
                .then(() => {
                  this.errorData.push({
                    id: this.checkedCities[index],
                    err: '操作成功'
                  })
                })
                .catch(err => {
                  this.errorData.push({
                    id: this.checkedCities[index],
                    err: err.message
                  })
                })
                .finally(() => {
                  this.loadFunction(index)
                })
            }
          })
        })
        .catch(() => {})
    },
    // 上传代码
    handleSubmitUploadCode() {
      this.loadVisible = true
      this.uploadCodeVisible = false
      const data = {
        template_id: this.is_plugin,
        user_version: this.getTemplateData.user_version,
        user_desc: this.getTemplateData.user_desc
      }
      this.checkedCities.forEach((item, index) => {
        uploadCode(this.checkedCities[index], data)
          .then(() => {
            this.errorData.push({
              id: this.checkedCities[index],
              err: '操作成功'
            })
          })
          .catch(err => {
            this.errorData.push({
              id: this.checkedCities[index],
              err: err.message
            })
          })
          .finally(() => {
            this.loadFunction(index)
          })
      })
    },
    // 提交审核
    handleSubmitForReview() {
      this.loadVisible = true
      this.auditVisible = false
      const data = {
        template_id: this.is_plugin,
        version: this.getTemplateData.user_version
      }
      this.checkedCities.forEach((item, index) => {
        submitAudit(this.checkedCities[index], data)
          .then(() => {
            this.errorData.push({
              id: this.checkedCities[index],
              err: '操作成功'
            })
          })
          .catch(err => {
            this.errorData.push({
              id: this.checkedCities[index],
              err: err.message
            })
          })
          .finally(() => {
            this.loadFunction(index)
          })
      })
    },
    handlePlugin(item) {
      this.getTemplateData = item
    },
    // 撤回审核
    handleWithdrawalReview() {
      this.$confirm('该操作会撤回小程序审核，确定吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loadVisible = true
          this.checkedCities.forEach((item, index) => {
            recallAudit(this.checkedCities[index])
              .then(() => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: '操作成功'
                })
              })
              .catch(err => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: err.message
                })
              })
              .finally(() => {
                this.loadFunction(index)
              })
          })
        })
        .catch(() => {})
    },
    // 发布
    handleRelease() {
      this.$confirm('该操作会发布小程序，确定吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loadVisible = true
          this.checkedCities.forEach((item, index) => {
            release(this.checkedCities[index])
              .then(() => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: '操作成功'
                })
              })
              .catch(err => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: err.message
                })
              })
              .finally(() => {
                this.loadFunction(index)
              })
          })
        })
        .catch(() => {})
    },
    // 版本回退
    handleVersionFallback() {
      this.$confirm('确定要小程序版本回退到上一个版本吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loadVisible = true
          this.checkedCities.forEach((item, index) => {
            getBackRecord(this.checkedCities[index])
              .then(() => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: '操作成功'
                })
              })
              .catch(err => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: err.message
                })
              })
              .finally(() => {
                this.loadFunction(index)
              })
          })
        })
        .catch(() => {})
    },
    // 设置类目
    handleSetCategory() {
      this.$confirm('此操作将给该小程序设置类目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loadVisible = true
          this.checkedCities.forEach((item, index) => {
            setCategory(this.checkedCities[index])
              .then(() => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: '操作成功'
                })
              })
              .catch(err => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: err.message
                })
              })
              .finally(() => {
                this.loadFunction(index)
              })
          })
        })
        .catch(() => {})
    },
    // 设置隐私协议
    handleSetPrivacy() {
      this.$confirm('此操作将给该小程序设置隐私协议, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loadVisible = true
          this.checkedCities.forEach((item, index) => {
            setPrivacy(this.checkedCities[index])
              .then(() => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: '操作成功'
                })
              })
              .catch(err => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: err.message
                })
              })
              .finally(() => {
                this.loadFunction(index)
              })
          })
        })
        .catch(() => {})
    },
    // 批量更新
    handleUpdate() {
      this.$confirm('更新定制小程序信息，确定继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loadVisible = true
          this.checkedCities.forEach((item, index) => {
            updateMiniInfo(this.checkedCities[index])
              .then(() => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: '操作成功'
                })
              })
              .catch(err => {
                this.errorData.push({
                  id: this.checkedCities[index],
                  err: err.message
                })
              })
              .finally(() => {
                this.loadFunction(index)
              })
          })
        })
        .catch(() => {})
    },
    // 加载函数
    loadFunction(index) {
      if (this.numberIndex === this.checkedCities.length - 1) {
        this.loadNumber = 100
        setTimeout(() => {
          if (this.errorData.length > 0) this.loadComplete = true
          else {
            this.loadVisible = false
            this.$message.success('操作成功')
          }
        }, 800)
      } else {
        this.loadNumber += this.theSum
      }
      this.numberIndex += 1
    },
    // 最后弹窗关闭的回调
    handleClose() {
      this.numberIndex = 0
      this.loadNumber = 0
      this.loadComplete = false
      this.domain = []
      this.bindExperienceValue = ''
      this.errorData = []
      this.getList()
    },
    cancelBindExperience() {
      this.bindExperienceValue = ''
    },
    conversion(type) {
      switch (type) {
        case 0:
          return { type: 'info', state: '未发布' }
        case 1:
          return { type: '', state: '已发布' }
        case 2:
          return { type: 'warning', state: '审核中' }
        case 3:
          return { type: 'danger', state: '审核失败' }
        case 4:
          return { type: 'success', state: '已撤回' }
        default:
          return
      }
    },
    nameFunction(id) {
      const name = this.dataList.list.filter(item => item.id === id)[0].name
      return name
    },
    check(type) {
      switch (type) {
        case 0:
          return { type: 'info', state: '未发布' }
        case 1:
          return { type: 'danger', state: '审核失败' }
        case 2:
          return { type: 'warning', state: '审核中' }
        case 3:
          return { type: 'success', state: '已提交审核' }
        case 4:
          return { type: '', state: '已发布' }
        default:
          return
      }
    },
    toPage(row) {
      this.$router.push({ name: 'adminWeChatMiniProgram', params: { id: row.id }})
    },
    // 更新定制小程序
    refresh(row) {
      this.$confirm('更新定制小程序信息，确定继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateMiniInfo(row.id).then(res => {
          this.$message.success('刷新成功')
          this.getList()
        })
      })
    },
    handleSelectionChange(val) {
      const arr = []
      val.forEach(item => {
        arr.push(item.id)
      })
      this.checkedCities = arr
    },
    isSelectable(row, index) {
      // 根据你的判断逻辑决定是否可选择
      // 返回 true 表示可选择，返回 false 表示不可选择
      // 例如，以下是一个简单的示例，当 row 中的某个属性为 'disabled' 时不可选择
      return row.customize_type === 2
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style scoped></style>
