<template>
  <!-- 班课课程表 -->
  <div class="app-container">
    <el-radio-group v-model="type" @change="handleChange">
      <el-radio-button :label="0">未完成</el-radio-button>
      <el-radio-button :label="1">已完成</el-radio-button>
    </el-radio-group>
    <!-- 搜索 -->
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="left">
        <!-- 开课日期 -->
        <el-form-item label="开课日期：">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            align="right"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <!-- 销售状态 -->
        <el-form-item class="line-clear" label="授课老师" prop="type">
          <el-select v-model="listQueryParams.store_user_id" placeholder="请选择授课老师">
            <el-option v-for="item in store_user" :key="item.id" :label="item.real_name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </el-form>
    </div>
    <!-- 会员搜索 -->
    <div class="filter-item">
      <el-input
        v-model="listQueryParams.q"
        placeholder="课程名称"
        style="width: 300px;"
        @keyup.enter.native="handleFilter"
      >
        <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
      </el-input>
    </div>
    <!-- 列表 -->
    <div class="tableColumn">
      <el-table v-loading="loading" :data="tableData.list" style="width: 100%" :stripe="true">
        <el-table-column label="名称" prop="name" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.course.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="时间" prop="update_time" align="center">
          <template slot-scope="scope">
            {{ scope.row.start_time.slice(0, 16) }} ~ {{ scope.row.end_time.slice(11, 16) }}
          </template>
        </el-table-column>
        <el-table-column label="授课老师" prop="store_user_id" align="center" />
        <el-table-column label="应到" prop="total_due" align="center" />
        <el-table-column label="签到" prop="total_sign_in" align="center" />
        <el-table-column label="请假" prop="total_ask" align="center" />
        <el-table-column v-if="type === 1" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="success" @click="handleSign(scope.row.id)">
              补签到
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 弹出框 -->
    <el-dialog title="签到详情" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-button v-if="MclassList.length !== 0" style="margin-bottom: 10px;" type="success" @click="handleSignIn">
        全部签到
      </el-button>
      <el-table v-loading="loaDialog" :data="MclassList" border style="width: 100%" :stripe="true">
        <el-table-column label="姓名" align="center" width="230px">
          <template slot-scope="{row}">
            <div v-if="!utils.empty(row.user)" class="flex col-center">
              <div>
                <el-avatar shape="square" :size="50" :src="row.user.avatar">
                  <img :src="errorMemberSrc()">
                </el-avatar>
              </div>
              <div class="p-l-10">
                <span>{{ getUserNames(row.user) }}</span>
                <br>
                <span>{{ row.user.phone }}</span>
              </div>
            </div>
            <div v-else class="text-danger">用户信息缺失</div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1">正常</el-tag>
            <el-tag v-if="scope.row.status === 2">请假</el-tag>
            <el-tag v-if="scope.row.status === 3">签到</el-tag>
            <el-tag v-if="scope.row.status === 4">旷课</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="220px">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.status === 2 || scope.row.status === 4 || scope.row.status === 1 || scope.row.status === 0
              "
              type="primary"
              @click="handleOperation(3, scope.row.id)"
            >
              签到
            </el-button>
            <el-button
              v-if="
                scope.row.status === 3 || scope.row.status === 4 || scope.row.status === 1 || scope.row.status === 0
              "
              type="primary"
              @click="handleOperation(2, scope.row.id, scope.row)"
            >
              请假
            </el-button>
            <el-button v-if="scope.row.status === 2" type="primary" @click="handleOperation(0, scope.row.id)">
              取消请假
            </el-button>
            <el-button
              v-if="scope.row.status === 3 || scope.row.status === 1 || scope.row.status === 0"
              type="primary"
              @click="handleOperation(4, scope.row.id)"
            >
              旷课
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
// API
import { getCoach } from '@/api/store/venues.js'
import { getRecord, getMclassBench, getMclassList, postMclassList, oneClickCheckIn } from '@/api/store/course'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  components: { Pagination },
  data() {
    return {
      store_user: [],
      dialogVisible: false,
      type: 0,
      searchLoading: false, // 搜索按钮加载
      loading: false, // 表格加载
      loaDialog: false,
      tableData: {
        list: [],
        count: 0
      }, // 数据
      MclassBench: {},
      MclassList: [],
      id: 0
    }
  },
  watch: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    // 数据
    getList() {
      this.loading = true
      // 获取教练列表
      getCoach({ limit: 999 })
        .then(res => {
          this.store_user = res.data.list
        })
        .catch(() => {})

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.q) querys.push({ field: 'q', key: this.listQueryParams.q })
      if (this.listQueryParams.store_user_id) {
        querys.push({ field: 'store_user_id', key: this.listQueryParams.store_user_id })
      }
      data.query = this.utils.getQueryParams(querys)

      getRecord(this.type, this.$route.params.id, data).then(res => {
        const data = res.data
        res.data.list.forEach(item => {
          this.store_user.forEach(item2 => {
            if (item.store_user_id === item2.id) {
              item.store_user_id = item2.real_name
            }
          })
        })
        this.tableData = data
        this.loading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    handleChange() {
      this.getList()
    },
    // 操作
    handleSign(id) {
      this.id = id
      this.loaDialog = true
      this.dialogVisible = true
      this.abc()
    },
    abc() {
      getMclassBench(this.id, { is_log: 1 }).then(res => {
        this.MclassBench = res.data
      })
      getMclassList(this.id, { is_log: 1 }).then(res => {
        this.MclassList = res.data.list
        this.loaDialog = false
      })
    },
    handleClose(done) {
      done()
      this.getList()
    },
    handleOperation(type, id, ask) {
      if (type === 2) {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          message: h('div', null, [
            h('div', null, `所在班级：${this.MclassBench.course.name}`),
            h('div', null, `请假课程：${this.MclassBench.mclass_grade.name}`),
            h(
              'div',
              null,
              `课程时间：${this.MclassBench.update_time.slice(0, 16)} 至 ${this.MclassBench.start_time.slice(11, 16)}`
            ),
            h('div', null, `剩余请假：${ask.remain_ask_number}`)
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              postMclassList(type, this.id, id).then(() => {
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                })
                done()
                this.abc()
                this.getList()
              })
            } else {
              done()
            }
          }
        })
      } else {
        postMclassList(type, this.id, id).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.abc()
          this.getList()
        })
      }
    },
    handleSignIn() {
      console.log(this.id)
      this.$confirm('是否确定为全部学员签到? （课时不足、成员状态异常、请假学员无法签到）', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        oneClickCheckIn(this.id).then(() => {
          this.$message({
            type: 'success',
            message: '签到成功!'
          })
          this.abc()
          this.getList()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tableColumn {
  margin-top: 20px;
}
.user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .user_img {
    margin-right: 10px;
  }
}
</style>
