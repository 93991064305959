<template>
  <div class="app-container">
    <!-- 筛选 -->
    <div class="filter-container clearfix">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <!-- 回访人 -->
          <el-form-item label="回访人">
            <Select :type="2" @changeId="handleMemberID" />
          </el-form-item>
          <!-- 教练 -->
          <el-form-item label="跟进人">
            <Select :type="4" @changeId="handleCoachID" />
          </el-form-item>
          <!-- 回访时间 -->
          <el-form-item label="回访时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              clearable
            />
          </el-form-item>
          <!-- 跟进方式 -->
          <el-form-item label="跟进方式">
            <el-select v-model="listQueryParams.track_type" clearable placeholder="请选择跟进方式">
              <el-option label="无" :value="0" />
              <el-option label="电话" :value="10" />
              <el-option label="邮件" :value="11" />
              <el-option label="短信" :value="12" />
              <el-option label="见面" :value="13" />
              <el-option label="微信" :value="14" />
            </el-select>
          </el-form-item>
          <!-- 跟进行为 -->
          <el-form-item label="跟进行为">
            <el-select v-model="listQueryParams.track_act" clearable placeholder="请选择跟进行为">
              <el-option label="无" :value="0" />
              <el-option label="会员卡即将到期" :value="10" />
              <el-option label="会员生日" :value="11" />
              <el-option label="会员卡余额不足" :value="12" />
              <el-option label="多天未上课" :value="13" />
              <el-option label="入会纪念日" :value="14" />
              <el-option label="即将开卡" :value="15" />
              <el-option label="请假即将到期" :value="16" />
            </el-select>
          </el-form-item>
          <div class="m-b-10">
            <el-button icon="el-icon-search" :loading="searchLoading" type="success" @click="handleFilter">
              搜索
            </el-button>
          </div>
        </el-form>
      </div>
      <div class="flex col-top m-t-20">
        <div class="filter-item">
          <el-input v-model="listQueryParams.query" placeholder="搜索回访内容" @keyup.enter.native="handleFilter">
            <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
          </el-input>
        </div>
        <div class="m-l-10">
          <el-button v-show="btn_access('s_user_track_add')" type="primary" icon="el-icon-plus" @click="handleAdd">添加回访</el-button>
          <el-button v-show="btn_access('s_user_track_export')" type="danger" icon="el-icon-download" :loading="exportLoading" @click="handleDownload">导出回访记录</el-button>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <el-table v-loading="tableLoading" :data="tableData.list" stripe style="width: 100%">
      <el-table-column prop="user.real_name" label="回访会员">
        <template slot-scope="{ row }">
          <div v-if="!utils.empty(row.user)" class="flex col-center">
            <div>
              <el-avatar shape="square" :size="50" :src="row.user.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row.user) }}</span>
              <br>
              <span>{{ row.user.phone }}</span>
            </div>
          </div>
          <div v-else class="text-danger">用户信息缺失</div>
        </template>
      </el-table-column>
      <el-table-column label="回访内容">
        <template slot-scope="{ row }">{{ row.content }}</template>
      </el-table-column>
      <el-table-column label="跟进教练">
        <template slot-scope="{ row }">{{ row.store_user ? row.store_user.real_name : '---' }}</template>
      </el-table-column>
      <el-table-column prop="track_type" label="跟进方式">
        <template slot-scope="{ row }">
          <span v-if="row.track_type == 0">无</span>
          <span v-else-if="row.track_type == 10">电话</span>
          <span v-else-if="row.track_type == 11">邮件</span>
          <span v-else-if="row.track_type == 12">短信</span>
          <span v-else-if="row.track_type == 13">见面</span>
          <span v-else-if="row.track_type == 14">微信</span>
        </template>
      </el-table-column>
      <el-table-column prop="track_act" label="跟进行为">
        <template slot-scope="{ row }">
          <span v-if="row.track_act == 0">无</span>
          <span v-else-if="row.track_act == 10">会员卡即将到期</span>
          <span v-else-if="row.track_act == 11">会员生日</span>
          <span v-else-if="row.track_act == 12">会员卡余额不足</span>
          <span v-else-if="row.track_act == 13">多天未上课</span>
          <span v-else-if="row.track_act == 14">入会纪念日</span>
          <span v-else-if="row.track_act == 15">即将开卡</span>
          <span v-else-if="row.track_act == 16">请假即将到期</span>
        </template>
      </el-table-column>
      <el-table-column label="回访时间">
        <template slot-scope="{ row }">{{ row.create_time }}</template>
      </el-table-column>
      <el-table-column prop="next_time" label="下次跟进时间">
        <template slot-scope="{ row }">
          <span v-if="row.next_time != 0">{{ row.next_time | formatDate('YYYY-MM-DD HH:mm') }}</span>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="90px">
        <template slot-scope="{ row }">
          <el-button icon="el-icon-edit" class="m-2" type="primary" @click="handleEdit(row.id)">编辑</el-button>
          <el-popconfirm v-show="btn_access('s_user_track_del')" title="删除后将无法恢复，确定吗？" @confirm="handleDelete(row.id)">
            <el-button slot="reference" icon="el-icon-delete" class="m-2" type="danger">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 添加回访的弹窗 -->
    <el-dialog
      v-if="dialogVisible"
      :title="!addEdit ? '添加回访' : '编辑回访'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      @close="handleClose"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="100px">
        <el-form-item label="回访人类型">
          <el-radio-group v-model="visitors" @change="handleChange">
            <el-radio :disabled="!!addEdit" :label="0">会员</el-radio>
            <el-radio :disabled="!!addEdit" :label="1">访客</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 回访人 -->
        <el-form-item label="回访人" prop="user_id">
          <Select v-if="visitors === 0" ref="member" :type="2" :exist-name="user_name" :disabled="!!addEdit ? true : false" @changeId="handleMemberIDS" />
          <Select v-else ref="visitor" :type="3" :exist-name="user_name" :disabled="!!addEdit ? true : false" @changeId="handleMemberIDS" />
        </el-form-item>
        <!-- 跟进人 -->
        <el-form-item label="跟进人" prop="store_user_id">
          <Select :type="4" :exist-name="real_name" @changeId="handleCoachIDS" />
        </el-form-item>
        <!-- 跟进方式 -->
        <el-form-item label="跟进方式">
          <el-select v-model="form.track_type" clearable placeholder="请选择跟进方式">
            <el-option label="无" :value="0" />
            <el-option label="电话" :value="10" />
            <el-option label="邮件" :value="11" />
            <el-option label="短信" :value="12" />
            <el-option label="见面" :value="13" />
            <el-option label="微信" :value="14" />
          </el-select>
        </el-form-item>
        <!-- 跟进行为 -->
        <el-form-item label="跟进行为">
          <el-select v-model="form.track_act" clearable placeholder="请选择跟进行为">
            <el-option label="无" :value="0" />
            <el-option label="会员卡即将到期" :value="10" />
            <el-option label="会员生日" :value="11" />
            <el-option label="会员卡余额不足" :value="12" />
            <el-option label="多天未上课" :value="13" />
            <el-option label="入会纪念日" :value="14" />
            <el-option label="即将开卡" :value="15" />
            <el-option label="请假即将到期" :value="16" />
          </el-select>
        </el-form-item>
        <!-- 下次回访时间 -->
        <el-form-item label="下次回访时间">
          <el-date-picker
            v-model="form.next_time"
            type="datetime"
            placeholder="请选择下次回访时间"
            value-format="yyyy-MM-dd HH:mm"
            clearable
          />
        </el-form-item>
        <!-- 回访内容 -->
        <el-form-item label="回访内容" prop="content">
          <el-input v-model="form.content" type="textarea" :rows="4" placeholder="请输入回访内容" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button v-if="!addEdit" type="primary" @click="handleDetermine('form')">确 定</el-button>
        <el-button v-else type="primary" @click="handlePreserve('form')">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 导出 -->
    <Export :success-prompt="successPrompt" :is-judge="is_judge" :animation-display="animation_display" />
  </div>
</template>

<script>
// API
import { getReturnVisitList, deleteReturnVisit, addReturnVisit, getReturnVisitDetail, updateReturnVisit } from '@/api/store/returnVisit'
// 翻页组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
// 组件
import Export from '@/components/export/index.vue'
import Select from '@/components/elSelect/Select'
// 方法函数
import { timestampToTimes } from '@/utils/takeleave'

export default {
  components: { Pagination, Export, Select },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      tableLoading: false,
      searchLoading: false,
      title: '回访人',
      titleCoach: '跟进教练',
      visitors: 0,
      dialogVisible: false,
      form: {
        user_id: '',
        store_user_id: '',
        content: '',
        track_type: 0,
        track_act: 0,
        next_time: ''
      },
      rules: {
        user_id: [{ required: true, message: '请选择回访人', trigger: 'change' }],
        store_user_id: [{ required: true, message: '请选择跟进教练', trigger: 'change' }],
        content: [{ required: true, message: '请输入回访内容', trigger: 'blur' }]
      },
      exportLoading: false,
      coachType: 3,
      successPrompt: false,
      is_judge: 0,
      addEdit: 0,
      real_name: '',
      user_name: '',
      animation_display: true
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    setQuery() {
      var querys = []
      if (this.listQueryParams.store_user_id) querys.push({ field: 'a.store_user_id', key: this.listQueryParams.store_user_id })
      if (this.listQueryParams.query) querys.push({ field: 'content', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.user_id) querys.push({ field: 'user_id', key: this.listQueryParams.user_id })
      if (this.listQueryParams.create_time) querys.push({ field: 'a.create_time', type: 'between-time', key: this.listQueryParams.create_time })
      if (this.listQueryParams.track_type || this.listQueryParams.track_type === 0) querys.push({ field: 'track_type', key: this.listQueryParams.track_type })
      if (this.listQueryParams.track_act || this.listQueryParams.track_act === 0) querys.push({ field: 'track_act', key: this.listQueryParams.track_act })
      return this.utils.getQueryParams(querys)
    },
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = this.setQuery()

      getReturnVisitList(data).then(res => {
        this.tableData = res.data
        this.tableLoading = false
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 删除
    handleDelete(id) {
      deleteReturnVisit(id).then(res => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    // 添加回访
    handleAdd() {
      this.dialogVisible = true
    },
    // 弹窗关闭的回调
    handleClose() {
      this.form = {
        store_user_id: '',
        user_id: '',
        content: '',
        track_type: 0,
        track_act: 0,
        next_time: ''
      }
      this.addEdit = 0
      this.real_name = ''
      this.user_name = ''
    },
    handleCancel() {
      this.dialogVisible = false
      this.handleClose()
    },
    handleMemberID(val) {
      this.listQueryParams.user_id = val
    },
    handleMemberIDS(val) {
      this.form.user_id = val
    },
    // 提交表单
    handleDetermine(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          addReturnVisit(this.form).then(() => {
            this.$message.success('添加成功')
            this.dialogVisible = false
            this.getList()
            this.handleClose()
          })
        } else {
          return false
        }
      })
    },
    // 保存表单
    handlePreserve(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          updateReturnVisit(this.addEdit, this.form).then(() => {
            this.$message.success('保存成功')
            this.dialogVisible = false
            this.getList()
            this.handleClose()
          })
        } else {
          return false
        }
      })
    },
    // 导出回访记录
    async handleDownload() {
      const _this = this
      this.animation_display = true
      this.is_judge = 0
      this.successPrompt = true
      this.exportLoading = true
      const query = encodeURIComponent(this.setQuery())
      const progressBar = await this.utils.handleDownload({
        url: '/store/v1/user_track/export?query=' + query,
        fileName: '回访管理列表.xlsx',
        baseURL: this.baseUrl
      })

      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.exportLoading = false
          _this.successPrompt = false
        }, 2000)
      }
    },
    handleCoachID(val) {
      this.listQueryParams.store_user_id = val
    },
    handleCoachIDS(val) {
      this.form.store_user_id = val
    },
    // 编辑回访
    handleEdit(id) {
      this.addEdit = id
      getReturnVisitDetail(id)
        .then(res => {
          const { user_id, store_user_id, content, track_type, track_act, next_time } = res.data
          this.form = {
            user_id,
            store_user_id,
            content,
            track_type,
            track_act,
            next_time: timestampToTimes(next_time)
          }
          this.real_name = res.data.store_user.real_name
          this.user_name = res.data.user.nickname
          this.form.user_id = res.data.user_id
          this.form.store_user_id = res.data.store_user_id
          this.dialogVisible = true
        })
    },
    handleChange(val) {
      if (val === 0) this.$refs.member.refreshGetList()
      if (val === 1) this.$refs.visitor.refreshGetList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
