<template>
  <el-form-item v-loadmore="loadMore" label="课程">
    <el-select
      v-model="course_id"
      filterable
      clearable
      remote
      reserve-keyword
      placeholder="请选择课程"
      :remote-method="selectChanged"
      @visible-change="visibleChanged"
    >
      <el-option
        v-for="(item, index) in courseList"
        :key="item.id"
        :label="item.name"
        :value="item.id"
        @click.native="courseChoose(index)"
      />
      <div v-if="is_hide === 0" class="jzz">
        <i v-if="is_Yes === 1" class="el-icon-loading" />
        <span v-if="is_Yes === 1" class="jzz_span1">玩命加载中</span>
        <span v-if="is_Yes === 0" class="jzz_span2">----- 我也是有底线的 -----</span>
      </div>
    </el-select>
  </el-form-item>
</template>

<script>
import { getCourse } from '@/api/store/course.js'

export default {
  props: ['type', 'id', 'timer'],
  data() {
    return {
      courseList: [], // 课程列表
      course_id: '',
      selectPage: 1, // 当前页数
      is_Yes: 1,
      is_hide: 0,
      query: '',
      delayTimer: null
    }
  },
  computed: {},
  watch: {
    course_id(val) {
      this.$emit('courseId', val)
    },
    timer: {
      immediate: true, // 立即执行
      handler: function(newVal, oldVal) {
        this.course_id = this.id
      }
    },
    type(val) {
      this.getList(val, 0)
      this.courseList = []
    }
  },
  created() {
    this.getList(this.type, 0)
  },
  mounted() {},
  methods: {
    getList(type, num) {
      let data = {}
      if (type === 3) {
        data = {
          offset: this.selectPage - 1,
          limit: 8
        }
      } else if (type === 0 || type === 1 || type === 2) {
        data = {
          type: type,
          offset: this.selectPage - 1,
          limit: 8
        }
      }

      var querys = []
      if (this.query) querys.push({ field: 'name', type: 'like', key: this.query })
      data.query = this.utils.getQueryParams(querys)

      getCourse(data)
        .then(res => {
          if (num === 0) {
            if (this.courseList.length === 0) {
              this.courseList = res.data.list
              if (res.data.list.length < 8) this.is_Yes = 3
            } else {
              if (res.data.list.length === 0) {
                this.is_Yes = 0
              } else {
                res.data.list.forEach(item => {
                  this.courseList.push(item)
                })
              }
            }
          } else if (num === 1) {
            this.courseList = res.data.list
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$emit('loading')
        })
    },
    selectChanged(val) {
      this.query = val
      this.is_Yes = 0
      this.getList(this.type, 1)
      // if (val.length > 0) {
      //   if (this.delayTimer) {
      //     clearTimeout(this.delayTimer)
      //   }
      //   this.query = val
      //   this.delayTimer = setTimeout(() => {
      //     this.courseList = []
      //     this.is_hide = 1
      //     this.selectPage = 1
      //     this.getList(this.type, 1)
      //   }, 500)
      // } else if (val.length === 0) {
      //   if (this.delayTimer) {
      //     clearTimeout(this.delayTimer)
      //   }
      //   this.delayTimer = setTimeout(() => {
      //     this.courseList = []
      //     this.query = null
      //     this.is_hide = 0
      //     this.is_Yes = 1
      //     this.selectPage = 1
      //     this.getList(this.type, 0)
      //   }, 600)
      // }
    },
    visibleChanged(val) {
      if (val === false) {
        this.courseList = []
        this.query = null
        this.is_hide = 0
        this.is_Yes = 1
        this.selectPage = 1
        this.getList(this.type, 0)
      }
    },
    loadMore() {
      this.selectPage += 8
      this.getList(this.type, 0)
    },
    courseChoose(index) {
      this.$emit('courseChoose', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.jzz {
  text-align: center;
  font-size: 16px;
  margin: 6px 0;
  .jzz_span1 {
    margin-left: 10px;
    color: #ccc;
  }
  .jzz_span2 {
    color: #ccc;
  }
}
</style>
