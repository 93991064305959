<template>
  <div class="app-container">
    <!-- 列表 -->
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="id" label="ID" />
      <el-table-column prop="user" label="用户">
        <template slot-scope="{row}" class="">
          <div v-if="row.user" class="flex col-center">
            <el-avatar shape="square" :size="50" :src="row.user.avatar">
              <img :src="errorAvatarSrc(row.user,'img')">
            </el-avatar>
            <div class="m-l-10">{{ row.user?getUserNames(row.user):'--' }}<br>{{ row.user.phone }}</div>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" />
      <el-table-column prop="content" label="反馈内容" />
      <el-table-column prop="create_time" label="反馈时间" />
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import { getFeedback } from '@/api/store/management.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  components: { Pagination },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      listLoading: false
    }
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取列表
    getList() {
      this.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getFeedback(data).then(res => {
        this.tableData = res.data
        this.listLoading = false
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
