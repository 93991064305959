<template>
  <div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.title"
      class="dialog_auto"
      :destroy-on-close="true"
      @close="handleCloseS"
    >
      <el-form ref="ruleForm" v-loading="loading" :model="form" :rules="rules" label-width="80px" label-position="top">
        <!-- 老师信息 -->
        <div class="flex">
          <div class="block">
            <el-avatar shape="square" :size="50" :src="form.privateTeacherInfo.avatar">
              <img :src="errorImageSrc">
            </el-avatar>
          </div>
          <span class="p-10">{{ form.privateTeacherInfo.real_name }}</span>
        </div>
        <!-- 会员信息 -->
        <h3>预约会员</h3>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="会员信息" prop="user_id">
              <Select ref="userRef" :type="2" :width="345" @changeId="handleMemberID" />
              <!-- <el-select
                v-model="form.user_id"
                v-loading="searchLoading"
                filterable
                remote
                reserve-keyword
                placeholder="请选择会员"
                :remote-method="remoteUserMethod"
                style="width:100%"
                :clearable="true"
                no-data-text="暂无数据"
                no-match-text="无搜索项,重新搜索"
                @change="userChange"
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="getUserNames(item)"
                  :value="item.id"
                >
                  <div class="flex">
                    <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="item.avatar">
                      <img :src="errorMemberSrc()">
                    </el-avatar>
                    <span>{{ getUserNames(item) }}</span>-
                    <span>{{ item.phone }}</span>
                  </div>
                </el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <!-- 选择时间 -->
          <!-- <el-col v-show="time_select_show" :span="24" class="text-center m-b-20">
            <el-button v-for="(item,index) in privateTimeList" :key="index" class="time_select" plain :type="timeIsDisable(item) && checkTime(item)?'':'info'" @click="select_the_time(item)">{{ item }}</el-button>
          </el-col> -->
          <el-col :span="12" :offset="0">
            <!-- 预约课程 -->
            <el-form-item label="预约课程" prop="course_id">
              <el-select v-model="form.course_id" placeholder="请选择课程" style="width:100%" @change="course_change">
                <el-option v-for="item in private_options" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
              <small v-if="private_options.length <= 0" class="text-danger">当前老师暂无课程</small>
              <small v-if="handleCurriculum" class="text-danger">请选择预约课程</small>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="会员卡" prop="user_membership_card_id">
              <el-select
                v-model="form.user_membership_card_id"
                remote
                reserve-keyword
                placeholder="请选择会员卡"
                style="width:100%"
                :clearable="true"
                empty="暂无内容"
                no-data-text="暂无数据"
                :disabled="is_display"
                @change="userCardChange"
              >
                <el-option-group v-for="group in userCardOptions" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.id"
                    :label="item.card.name + ' / ' + item.number"
                    :value="item.id"
                    :disabled="!item.course_limit"
                  >
                    <div class="flex">
                      <!-- <el-tag v-if="item.course_limit && item.status_id == 2">正常</el-tag> -->
                      <el-tag v-if="item.course_limit && item.status_id != 2" type="warning">
                        {{ item.status.name }}
                      </el-tag>
                      <el-tag v-if="!item.course_limit" type="danger">不支持</el-tag>
                      <div class="m-l-6">{{ item.card.name + ' / ' + item.number }}</div>
                    </div>
                  </el-option>
                </el-option-group>
              </el-select>
              <small v-if="text_display && form.user_id" class="text-danger">当前会员暂无会员卡</small>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="上课时间" prop="start_time_d">
              <el-select v-model="form.start_time_d" placeholder="请选择" style="width:100%" filterable>
                <el-option
                  v-for="(item, index) in timeQuantum"
                  :key="index"
                  :label="item.start"
                  :value="item.start"
                  :disabled="!item.status"
                />
              </el-select>
              <div>
                <small v-if="form.start_time_d" class="text-info">{{ dateTime + ' ' + form.start_time_d }}</small>
              </div>
              <!-- <el-button type="primary" plain title="点击选择预约时间" @click="time_select_show = !time_select_show">{{ select_time_btn }}</el-button> -->
            </el-form-item>
          </el-col>
          <el-col :span="24" :offset="0">
            <!-- 会员详情 -->
            <div
              v-show="form.user_membership_card_id && form.user_id"
              v-loading="userinfoLoading"
              class="userInfo m-b-20"
            >
              <el-descriptions class="margin-top" :column="3" border>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-user" />
                    会员
                  </template>
                  <!-- <el-avatar shape="square"  :src="form.userInfo.avatar" /> -->
                  <el-tag>{{ getUserNames(form.userInfo) }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-mobile-phone" />
                    手机号
                  </template>
                  {{ form.userInfo.phone }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-bank-card" />
                    类型
                  </template>
                  {{ formatCardType(form.userCardInfo.card ? form.userCardInfo.card.type : '') }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-bank-card" />
                    卡号
                  </template>
                  {{ form.userCardInfo.number }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-connection" />
                    卡状态
                  </template>
                  <el-tag :type="card_status">{{ formatStatus(form.userCardInfo.status_id) }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-collection-tag" />
                    开卡类型
                  </template>
                  {{ formatCardOpenType(form.userCardInfo.type) }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-time" />
                    开卡时间
                  </template>
                  <div v-if="form.userCardInfo.type == 2">
                    {{ form.userCardInfo.specific_time | formatDate('YYYY-MM-DD') }}
                  </div>
                  <div v-else>
                    {{
                      form.userCardInfo.type === 1 && !form.userCardInfo.open_card_time
                        ? '未开卡'
                        : form.userCardInfo.open_card_time === 0
                          ? '未开卡'
                          : form.userCardInfo.open_card_time
                    }}
                  </div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-money" />
                    余额
                  </template>
                  <div v-if="form.userCardInfo.status_id !== 14">
                    <span v-if="form.userCardInfo.card.type === 0">
                      <span v-if="getExcessTime(form.userCardInfo.valid_time) > 0" class="text-success">
                        {{ getExcessTime(form.userCardInfo.valid_time) }}
                        <b v-if="form.userCardInfo.card">{{ formatCardUnitType(form.userCardInfo.card.type) }}</b>
                      </span>
                      <span v-else class="text-danger">
                        {{ getExcessTime(form.userCardInfo.valid_time) }}
                        <b v-if="form.userCardInfo.card">{{ formatCardUnitType(form.userCardInfo.card.type) }}</b>
                      </span>
                    </span>
                    <span v-else>
                      {{ form.userCardInfo.limit }}
                      <b v-if="form.userCardInfo.card">{{ formatCardUnitType(form.userCardInfo.card.type) }}</b>
                    </span>
                  </div>
                  <div v-else>未开卡</div>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-time" />
                    有效期至
                  </template>
                  {{ form.userCardInfo.status_id == 14 ? '未开卡' : form.userCardInfo.valid_time }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </el-col>
          <el-col :span="8" :offset="0">
            <!-- 预约人数 -->
            <el-form-item label="预约人数" prop="subscribe_number">
              <el-input v-model="form.subscribe_number" type="number" :value="form.subscribe_number" />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入约课备注信息" rows="3" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getStoreUserTimeQuantum } from '@/api/store/venues.js'
import { getStoreUserDetails } from '@/api/store/management.js'
import {
  getUser,
  getUserDetails,
  getUserSupportCard,
  getUserMembershipCardDetails,
  getUserMembershipCardStatus
} from '@/api/store/user.js'
import { addBook } from '@/api/store/worktable.js'
import Utils from '@/utils/utils.js'
import { getCourse } from '@/api/store/course.js'
import Select from '@/components/elSelect/Select'
export default {
  name: 'GroupBook',
  components: { Select },
  props: {
    value: {
      type: Object,
      default: function() {
        return {}
      }
    },
    storeUserId: {
      type: [String, Number],
      default: '0'
    },
    dateTime: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      form: {
        store_user_id: 1, // 教练ID
        venues_id: Utils.getVenues().id, // 场馆
        subscribe_number: 1, // 预约人数
        userInfo: {}, // 用户信息
        userCardInfo: { card: {}}, // 用户会员卡信息
        privateTeacherInfo: {}, // 私教老师信息
        user_membership_card_id: '',
        user_id: '',
        course_id: '' // 课程ID
      },
      rules: ['start_time_d', 'user_id', 'subscribe_number', 'user_membership_card_id', 'course_id'],
      searchLoading: false,
      dialog: this.value,
      userOptions: [],
      userCardOptions: [],
      user_info_show: false,
      // 私教课列表
      private_options: [],
      select_time_btn: '选择时间',
      time_select_show: false,
      privateTimeList: [
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30'
      ],
      dprivateTimeList: ['12:00', '12:30', '18:00', '18:30'],
      loading: false,
      userinfoLoading: false,
      statusData: [],
      card_status: 'success',
      timeQuantum: [],
      text_display: false,
      is_display: true,
      handleCurriculum: false
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.dialog = newVal
      },
      deep: true
    },
    storeUserId: {
      handler(newVal) {
        if (!newVal) return false
        this.loading = true
        this.form.store_user_id = newVal
        this.getPrivateTeacherList(newVal)
        this.getStoreUserInfo(newVal)
        this.getStoreUserTimeQuantumInfo()
      },
      deep: true
    }
  },
  created() {
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() {
    this.getUserList()
    this.getCardStatus()
  },
  methods: {
    course_change() {
      this.getStoreUserTimeQuantumInfo()
      this.getUserCardList()
      this.form.user_membership_card_id = ''
      this.handleCurriculum = false
    },
    // 私教预约时间获取
    getStoreUserTimeQuantumInfo() {
      getStoreUserTimeQuantum(this.storeUserId, this.form.venues_id, this.dateTime, {
        course_id: this.form.course_id
      }).then(res => {
        if (res.data) {
          this.timeQuantum = res.data
        }
      })
    },
    /**
     * 用户列表
     */
    getUserList() {
      this.searchLoading = true
      const data = {}
      if (this.listQuery.query) { data.query = this.utils.getLikeQueryParams('real_name|nickname|phone', this.listQuery.query) }
      data.limit = 999
      getUser(data).then(res => {
        // 数据嵌入
        this.searchLoading = false
        this.userOptions = res.data.list
      })
    },
    /**
     * 添加预约提交
     */
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const postData = {
            user_id: this.form.userInfo.id,
            store_user_id: this.form.store_user_id,
            course_id: this.form.course_id,
            user_membership_card_id: this.form.user_membership_card_id,
            subscribe_number: this.form.subscribe_number,
            venues_id: this.form.venues_id,
            remark: this.form.remark
          }

          let startTime = ''
          startTime = this.dateTime
          postData.start_time = startTime + ' ' + this.form.start_time_d
          this.submitBook(postData.user_id, postData)
        }
      })
    },
    submitBook(user_id, postData, force = 0) {
      postData.force = force
      addBook(user_id, postData)
        .then(res => {
          this.$message.success('添加成功')
          // 添加成功后联动获取预约记录
          this.$emit('updateListMethods', true)
          this.$parent.getPrivateVenuesList()
          this.dialog.visible = false
        })
        .catch(error => {
          if (error.status === 701) {
            this.$confirm(error.msg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.submitBook(user_id, postData, 1)
            })
          }
        })
    },
    /**
     * 获取选中会员
     */
    userChange(id) {
      this.form.user_membership_card_id = ''
      this.userCardOptions = []
      if (!id) {
        // 重载会员列表
        this.listQuery.query = ''
        this.form.userInfo = {}
        this.user_info_show = false
        this.getUserList()
        this.getUserCardList()
        return
      }
      this.user_info_show = true
      // 请求API获取用户信息
      getUserDetails(id).then(res => {
        this.form.userInfo = res.data
        this.getUserCardList()
        this.form.user_id = id
      })
    },
    /**
     * 获取选中会员会员卡
     */
    userCardChange(id) {
      if (!id) {
        this.form.userCardInfo = { card: {}}
        return
      }
      this.form.user_membership_card_id = id
      this.user_info_show = true
      this.userinfoLoading = true
      // 请求API获取用户会员卡信息
      getUserMembershipCardDetails(id)
        .then(res => {
          this.form.userCardInfo = res.data
        })
        .finally(() => {
          this.userinfoLoading = false
        })
    },
    /**
     * 搜索会员卡
     */
    // remoteUserCardMethod(query) {
    //   if (query !== '') {
    //     this.searchLoading = true
    //     // 获取指定会员下的会员卡列表
    //     this.getUserCardList()
    //   } else {
    //     this.userCardOptions = []
    //   }
    // },
    /**
     * 获取会员卡列表
     */
    getUserCardList() {
      if (!this.form.userInfo.id) {
        // this.$message.error('请先选择会员')
        this.is_display = true
        return
      }
      if (!this.form.course_id) {
        // this.$message.error('请先选择课程')
        this.handleCurriculum = true
        this.is_display = true
        return
      }
      this.is_display = false
      this.loading = true
      // getUserMembershipCard({ query: JSON.stringify({ user_id: this.form.userInfo.id }) }).then(res => {
      //   // 数据嵌入
      //   this.searchLoading = false
      //   this.userCardOptions = res.data.list
      // }).finally(() => {
      //   this.loading = false
      // })
      const data = {
        offset: 0,
        limit: 999
      }
      getUserSupportCard(this.form.course_id, this.form.userInfo.id, data)
        .then(res => {
          const data = res.data.list
          if (data.length <= 0) this.text_display = true
          else this.text_display = false
          const optionsY = data.filter(item => item.course_limit)
          const optionsN = data.filter(item => !item.course_limit)
          this.userCardOptions = [
            { label: '可用', options: optionsY },
            { label: '不支持', options: optionsN }
          ]
        })
        .finally(() => {
          this.searchLoading = false
          this.loading = false
        })
    },
    /**
     * 搜索用户
     */
    remoteUserMethod(query) {
      this.listQuery.query = query
      this.getUserList()
    },
    /**
     * 获取指定老师私教课程列表
     */
    getPrivateTeacherList(id) {
      this.form.course_id = ''
      const search = { query: JSON.stringify({ store_user_id: id, type: 1 }), limit: 99 }
      getCourse(search).then(res => {
        this.private_options = res.data.list
      })
    },
    // 预约时间选择事件
    select_the_time(t) {
      if (this.in_array(t, this.dprivateTimeList) || !this.checkTime(t)) {
        this.$message.error('暂不可预约')
        return
      }
      this.select_time_btn = this.dateTime + ' ' + t
      this.$nextTick(() => {
        this.$set(this.form, 'start_time_d', t)
      })

      this.time_select_show = !this.time_select_show
    },
    timeIsDisable(t) {
      return !this.in_array(t, this.dprivateTimeList)
    },
    in_array(search, array) {
      for (var i in array) {
        if (array[i] === search) {
          return true
        }
      }
      return false
    },
    checkTime(t) {
      const s = this.dateTime + ' ' + t // 选中时间
      const e = this.$moment().format('YYYY-MM-DD HH:mm') // 当前时间
      return this.$moment(s).isSameOrAfter(e)
    },
    getStoreUserInfo(id) {
      getStoreUserDetails(id)
        .then(res => {
          this.form.privateTeacherInfo = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 状态码格式化
    formatStatus(s) {
      if (!this.statusData.length <= 0) {
        this.getCardStatus()
      }
      switch (s) {
        case 1:
        case 2:
          this.card_status = 'success'
          break
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          this.card_status = 'warning'
          break

        default:
          this.card_status = 'danger'
          break
      }
      return this.statusData[s] ? this.statusData[s] : '----'
    },
    getCardStatus() {
      if (this.statusData.length <= 0) {
        getUserMembershipCardStatus({ limit: 999 })
          .then(res => {
            const new_arr = []
            for (var n = 0; n < res.data.list.length; n++) {
              new_arr[res.data.list[n]['id']] = res.data.list[n]['name']
            }
            this.statusData = new_arr
          })
          .catch(() => {})
      }
    },
    handleCloseS() {
      this.form = {
        store_user_id: 1, // 教练ID
        venues_id: Utils.getVenues().id, // 场馆
        subscribe_number: 1, // 预约人数
        userInfo: {}, // 用户信息
        userCardInfo: { card: {}}, // 用户信息
        courseInfo: {}, // 课程信息
        privateTeacherInfo: {}, // 私教老师信息
        user_membership_card_id: ''
      }
      this.$refs.userRef.refreshGetList()
      this.$emit('updateListMethods', true)
    },
    handleMemberID(val) {
      this.$set(this.form, 'user_id', val)
      this.userChange(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.time_select {
  text-align: center;
  margin: 5px 10px;
}
</style>
