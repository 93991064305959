var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fromData
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.fromData.title,
                visible: _vm.modals,
                width: "700",
                "before-close": _vm.cancel,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.modals = $event
                }
              }
            },
            [
              _c("form-create", {
                ref: "fc",
                staticClass: "formBox",
                attrs: {
                  option: _vm.config,
                  rule: _vm.fromData.rules,
                  "handle-icon": "false"
                },
                on: { submit: _vm.onSubmit }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }