<template>
  <el-dialog
    :visible.sync="dialogVisibleApiRoute"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="55%"
    :show-close="false"
    @open="handleOpen"
    @close="handleClose"
  >
    <div slot="title" class="title">
      <div class="title_title">设置接口路由</div>
      <div class="title_icon cursor" @click="handleClose">
        <i class="el-icon-close" />
      </div>
    </div>
    <div>

      <div v-loading="dialogLoading">
        <div class="war">
          <div class="menu">
            <!-- <div
              v-for="(item, index) in tableData"
              :key="index"
              class="menu-item"
              :class="item.id === colorTypeId ? 'color-click' : ''"
              @click="handleClick(item.id, item.api)"
            >
              <i v-if="item.children" class="el-icon-caret-right m-r-4 text-info" />
              <span>{{ item.name }}</span>
            </div> -->
            <el-tree :data="tableData" :props="defaultProps" @node-click="handleNodeClick" />
          </div>
          <div class="api">
            <el-row :gutter="20">
              <el-col v-for="(item, index) in apiData" :key="index" :span="12">
                <div
                  class="api-item"
                  :class="colorTypeData.indexOf(item.id) !== -1 ? 'api-confirm' : ''"
                  @click="handleAPiClick(item.id)"
                >
                  <div>接口名称：{{ item.name }}</div>
                  <div>请求方式：{{ item.method }}</div>
                  <div>接口地址：{{ item.url }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="buttonLoading" @click="handleClose">取 消</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { getApiRouteList, getMenuDetail, updateApiRouteS } from '@/api/admin/account.js'
// 组件
// 函数
// import { deepClone } from '@/utils'

export default {
  components: {},
  props: {
    dialogVisibleApiRoute: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableData: [],
      apiData: [],
      dialogLoading: false,
      buttonLoading: false,
      colorTypeId: 0,
      colorTypeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.apiList()
      this.getDetails()
    },
    // 关闭的回调
    handleClose() {
      this.colorTypeData = []
      this.apiData = []
      this.$emit('apiRouteVisible')
    },
    apiList() {
      this.dialogLoading = true

      getApiRouteList()
        .then(res => {
          const { data } = res
          this.tableData = data
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    getDetails() {
      getMenuDetail(this.typeId)
        .then(res => {
          const { data } = res
          this.colorTypeData = data.role_ids
        })
    },
    // 保存
    handleSubmit() {
      this.buttonLoading = true
      const data = {
        role_ids: this.colorTypeData
      }
      updateApiRouteS(this.typeId, data)
        .then(() => {
          this.handleClose()
          this.$message.success('操作成功!')
        })
        .finally(() => {
          this.buttonLoading = false
        })
    },
    handleClick(id, api) {
      this.colorTypeId = id
      this.apiData = api
    },
    handleAPiClick(id) {
      // 点击将id储存在数组中，再次点击则从数组中删除
      const index = this.colorTypeData.indexOf(id)
      if (index !== -1) this.colorTypeData.splice(index, 1)
      else this.colorTypeData.push(id)
    },
    handleNodeClick(data) {
      if (data.api) this.apiData = data.api
      else this.apiData = []
    }
  }
}
</script>

<style scoped>
/* 修改滚动条的宽度和颜色 */
::-webkit-scrollbar {
  width: 1px; /* 设置滚动条宽度 */
}
::-webkit-scrollbar-thumb {
  background-color: #fff; /* 设置滚动条 thumb 的颜色 */
}
</style>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.war {
  display: flex;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  .menu {
    width: 200px;
    border-right: 1px solid #f8f8f8;
    /* 添加滚动条 */
    max-height: 60vh; /* 适当设置最大高度，防止菜单过长 */
    overflow-y: auto;
    .menu-item {
      padding: 20px;
      width: 100%;
      /* height: 50px; */
      /* line-height: 50px; */
    }
    .menu-item:hover {
      cursor: pointer;
      background-color: #f5f7fa;
    }
  }
  .api {
    width: 800px;
    margin-left: 10px;
    /* 添加滚动条 */
    max-height: 60vh; /* 适当设置最大高度，防止菜单过长 */
    overflow-y: auto;
    .api-item {
      background-color: #f2f2f2;
      margin: 5px 0px;
      padding: 10px;
      border-radius: 4px;
    }
    .api-item:hover {
      cursor: pointer;
      background-color: #e8f4ff;
    }
    .api-confirm {
      background-color: #e8f4ff;
    }
  }
}

.color-click {
  color: #276fff;
  background-color: #e5eeff;
  border-right: 3px solid #0256ff;
}
</style>
