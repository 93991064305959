<template>
  <el-tabs v-model="activeName" tab-position="top">
    <el-tab-pane label="小程序信息" name="weChatInformation">
      <weChatInformation v-if="activeName == 'weChatInformation'" @toPage="toPage" />
    </el-tab-pane>
    <el-tab-pane label="小程序配置" name="weChatAllocation">
      <weChatAllocation v-if="activeName == 'weChatAllocation'" />
    </el-tab-pane>
    <el-tab-pane label="小程序服务商" name="WeChatServiceProvider">
      <WeChatServiceProvider v-if="activeName == 'WeChatServiceProvider'" />
    </el-tab-pane>
    <el-tab-pane label="草稿箱" name="WeChatDrafts">
      <WeChatDrafts v-if="activeName == 'WeChatDrafts'" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import weChatAllocation from './components/weChatAllocation'
import weChatInformation from './components/weChatInformation'
import WeChatServiceProvider from './components/WeChatServiceProvider'
import WeChatDrafts from './components/WeChatDrafts'

export default {
  components: {
    weChatAllocation, weChatInformation, WeChatServiceProvider, WeChatDrafts
  },
  data() {
    return {
      activeName: 'weChatInformation'
    }
  },
  watch: {
    activeName(val) {
      this.$cookies.set('weChat', val)
    }
  },
  created() {
    this.activeName = this.$cookies.get('weChat') || 'weChatInformation'
  },
  mounted() {},
  methods: {
    toPage() {
      this.activeName = 'weChatAllocation'
    }
  }
}
</script>

<style lang="scss" scoped></style>
