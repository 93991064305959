<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleInitiateSign"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30%"
      :show-close="false"
      @open="handleOpen"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">签署合同</div>
          <div class="title_icon cursor" @click="handleClose">
            <i class="el-icon-close" />
          </div>
        </div>
      </div>
      <div class="tags_layout">
        <el-form ref="defaultForm" :model="defaultForm" label-position="left" label-width="100px">
          <el-form-item label="合同名称">
            <el-input v-model="defaultForm.name" clearable />
          </el-form-item>
          <el-form-item label="合同有效期">
            <el-date-picker
              v-model="defaultForm.contract_expire_time"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="是否短信通知">
            <el-switch v-model="defaultForm.enable_sms" :active-value="1" :inactive-value="0" />
          </el-form-item>
          <el-form-item label="发送给">
            <el-radio-group v-model="memberType" @change="curriculumType">
              <el-radio :label="2">会 员</el-radio>
              <el-radio :label="3">访 客</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选择会员">
            <Select ref="userRef" :type="memberType" @changeId="handleMemberID" />
          </el-form-item>
          <el-form-item label="签署截至时间">
            <el-date-picker
              v-model="defaultForm.sign_expire_time"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSend">发 送</el-button>
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { startContractSign, getDefaultForm } from '@/api/store/contract'
// 组件
import Select from '@/components/elSelect/Select'
// 函数
import { deepClone } from '@/utils'

export default {
  components: { Select },
  props: {
    dialogVisibleInitiateSign: {
      type: Boolean,
      default: false
    },
    editInitiateSignId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      defaultForm: {
        contract_template_id: '',
        name: '',
        contract_expire_time: '',
        enable_sms: 0,
        user_id: '',
        sign_expire_time: ''
      },
      memberType: 2
    }
  },
  mounted() {},
  methods: {
    // 打开的回调
    handleOpen() {
      getDefaultForm(this.editInitiateSignId).then(res => {
        const { data: { name, enable_sms }} = res
        this.defaultForm.contract_template_id = this.editInitiateSignId
        this.defaultForm.name = name
        this.defaultForm.enable_sms = enable_sms
      })
    },
    // 关闭的回调
    handleClose() {
      this.$emit('editInitiateSignVisible')
    },
    handleMemberID(val) {
      this.defaultForm.user_id = val
    },
    // 发送
    handleSend() {
      const data = deepClone(this.defaultForm)
      data.user_id = [data.user_id]
      startContractSign(data).then(() => {
        this.$message.success('发送成功')
        // 跳转页面
        this.handleClose()
      })
    },
    curriculumType() {
      this.$refs.userRef.refreshGetList()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
