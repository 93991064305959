<template>
  <div class="app-container">
    <!-- 通用搜索 -->
    <div class="filter-container m-t-20">
      <div class="filter-item">
        <el-input
          v-model="listQueryParams.query"
          placeholder="搜索"
          @keyup.enter.native="handleFilter"
        >
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('s_card_combo_add')" type="primary" class="filter-item m-l-10" @click="handleAdd">新增组合卡套餐</el-button>
    </div>
    <!-- 表格 -->
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="name" label="套餐卡名称" />
      <el-table-column label="包含卡项目">
        <template slot-scope="{row}">
          {{ row.card_group ? row.card_group.length : '0' }} 张卡
        </template>
      </el-table-column>
      <el-table-column label="最后修改人">
        <template slot-scope="{row}">
          {{ row.store_user.real_name }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="最后修改时间" />
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button class="m-4" @click="handleDetails(row.id)">
            详 情
          </el-button>
          <el-button v-show="btn_access('s_card_combo_edit')" class="m-4" type="primary" @click="handleEdit(row.id)">
            编 辑
          </el-button>
          <el-popconfirm
            v-show="btn_access('s_card_combo_del')"
            class="m-4"
            title="删除后将无法恢复，确定吗？"
            @confirm="delUserId(row.id)"
          >
            <el-button slot="reference" type="danger">
              删 除
            </el-button>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 弹窗组件 -->
    <Dialog :dialog-visible="dialogVisible" :type-id="typeId" :type="type" @CloseDialog="handleCloseDialog" />
  </div>
</template>

<script>
// API
import { getComboList, delCombo } from '@/api/store/combination.js'
// 组件
import Pagination from '@/components/Pagination'
import Dialog from './Dialog.vue'

export default {
  components: { Pagination, Dialog },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      TableLoading: false,
      searchLoading: false,
      dialogVisible: false, // 弹窗显示
      typeId: 0,
      type: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.TableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'name', type: 'like', key: this.listQueryParams.query })
      data.query = this.utils.getQueryParams(querys)

      getComboList(data)
        .then(res => {
          this.tableData = res.data
        })
        .finally(() => {
          this.TableLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 新增
    handleAdd() {
      this.type = 'add'
      this.dialogVisible = true
    },
    // 编辑
    handleEdit(id) {
      this.type = 'edit'
      this.typeId = id
      this.dialogVisible = true
    },
    // 详情
    handleDetails(id) {
      this.type = 'details'
      this.typeId = id
      this.dialogVisible = true
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false
      this.getList()
    },
    // 删除套餐卡组合
    delUserId(id) {
      delCombo(id)
        .then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
