<template>
  <div class="popover_btn">
    <el-popover
      ref="popover"
      v-model="visible"
      placement="top"
      width="180"
      icon="el-icon-info"
      icon-color="red"
    >
      <p>{{ msg }}</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="del">确定</el-button>
      </div>
      <el-button v-if="btnMsg" slot="reference" type="danger" icon="el-icon-delete" :class="btnStyle">{{ btnMsg }}</el-button>
      <el-button v-else slot="reference" type="danger" icon="el-icon-delete" :class="btnStyle" />
    </el-popover>
  </div>
</template>

<script>
import { deleteApi } from '@/api/user.js'
export default {
  props: {
    msg: {
      type: String,
      default: '确定删除本条数据吗？'
    },
    btnMsg: {
      type: String,
      default: '删除'
    },
    // 按钮样式
    btnStyle: {
      type: String,
      default: 'el-button--mini'
    },
    // 请求参数
    apiData: {
      type: Object
    },
    // 当前码数
    page: {
			  type: Number
    },
    // 当前列表数
    listLength: {
			  type: Number
    },
    // id参数等
    row: {
      type: Object
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    del() {
      let url = this.apiData.url
      if (!this.apiData.type) url = `${url}/${this.row.id}`
      else url = `${url}?id=${this.row.id}`
      deleteApi(url).then(res => {
        if (this.listLength == 1) {
          this.$emit('update:page', this.page == 1 ? 1 : this.page - 1)
        }
        this.$message.success('删除成功！')
        this.$emit('delete') // 回调\重新加载列表
        this.$refs.popover.doClose()// 隐藏popover，以防有些页面调用组件无法隐藏
      }).catch(() => {})
      // this.$emit('delete', this.row)
    }
  }
  // mounted() {
  // 	console.log(this.page)
  // }
}
</script>

<style scoped>
	.popover_btn {
		display: inline-block;
		margin: 0 10px;
	}
</style>
