<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="请假日期">
        <div v-if="is_display && diff_day" class="flex">
          <div>
            {{ cardStatusLog.start_time | formatDate('YYYY-MM-DD') }} 至
            {{ cardStatusLog.end_time | formatDate('YYYY-MM-DD') }}
          </div>
          <div class="m-l-10">
            共
            <span class="text-primary p-x-4">{{ diff_day }}</span>
            天
          </div>
        </div>
        <div v-else class="flex">无请假记录</div>
      </el-form-item>
      <el-form-item label="销假日期" prop="end_time">
        <div class="flex">
          <div>
            <el-date-picker
              v-model="form.end_time"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              :clearable="false"
              :picker-options="dataProhibitSelection"
              @change="end_time_change"
            />
          </div>
          <div v-if="is_display" class="m-l-10">
            本次实际请假
            <span class="text-primary p-x-4">{{ c_diff_day }}</span>
            天
          </div>
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请填写操作备注"
          rows="5"
          maxlength="300"
          show-word-limit
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button @click="$emit('back', false)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { deepClone } from '@/utils'
import { offUserMembershipLeave, getUserMembershipCardChangeLogs } from '@/api/store/user.js'
export default {
  name: 'AskCancel',
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
      validator: function(value) {
        return value >= 0
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      form: {},
      cardStatusLog: {
        start_time: 0,
        end_time: 0
      },
      rules: {
        end_time: { required: true, message: '请选择销假时间', trigger: ['blur', 'change'] }
      },
      diff_day: 0,
      c_diff_day: 0,
      is_display: true
    }
  },
  created() {
    this.form = deepClone(this.cardData)
    this.$set(this.form, 'remark', '')
    // 获取卡变更记录
    this.getCardLog(this.cardData.id)
  },
  methods: {
    // 销假提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = { end_time: this.form.end_time, remark: this.form.remark }
          offUserMembershipLeave({ id: this.form.id }, data)
            .then(res => {
              this.$emit('callback', res.msg)
            })
            .catch(() => {})
        }
      })
    },
    checkTime() {
      const a = deepClone(this.cardStatusLog)
      const s = this.$moment.unix(a.start_time).format('yyyy-MM-DD') // 开始时间
      const e = this.$moment.unix(a.end_time).format('yyyy-MM-DD') // 结束时间
      if (s === e) return true
      return false
    },
    // 获取卡状态列表
    getCardLog(card_id) {
      getUserMembershipCardChangeLogs(
        this.utils.getQueryParam({ user_membership_card_id: card_id, user_membership_card_status_id: 3 }),
        { limit: 999 }
      )
        .then(res => {
          if (res.data.list[0]) {
            this.cardStatusLog = res.data.list[0]
            this.diff_day = this.time_reckon(
              [
                this.$moment.unix(this.cardStatusLog.start_time).format('yyyy-MM-DD'),
                this.$moment.unix(this.cardStatusLog.end_time).format('yyyy-MM-DD')
              ],
              1
            )
          } else {
            // this.$message.error('当前会员卡请假状态异常')
            console.log('没有请假记录哦')
            this.is_display = false
          }
        })
        .catch(() => {})
    },
    // 销假时间变更
    end_time_change(v) {
      if (v) {
        const s = this.$moment.unix(this.cardStatusLog.start_time).format('yyyy-MM-DD') // 开始时间
        this.c_diff_day = this.time_reckon([s, v])
      } else {
        this.c_diff_day = 0
      }
    },
    // 时间计算（+1天）
    time_reckon(res, add = 0) {
      if (!res) return 0
      const s = this.$moment(res[0]).format('yyyy-MM-DD') // 开始时间
      const e = this.$moment(res[1]).format('yyyy-MM-DD') // 结束时间
      let diff_day = this.$moment(e).diff(s, 'days')
      diff_day += add
      return diff_day
    }
  }
}
</script>

<style></style>
