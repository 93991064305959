var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "持卡会员手机号", prop: "phone" } },
            [
              _c(
                "el-input",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "phone", $$v)
                    },
                    expression: "form.phone"
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "prepend" }, slot: "prepend" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { "min-width": "84px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择",
                            disabled: ""
                          },
                          model: {
                            value: _vm.form.area_code,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "area_code", $$v)
                            },
                            expression: "form.area_code"
                          }
                        },
                        _vm._l(_vm.areaCodeList, function(group, index) {
                          return _c(
                            "el-option-group",
                            {
                              key: index,
                              staticClass: "min-width:200px",
                              attrs: { label: group.label }
                            },
                            _vm._l(group.options, function(item, indexs) {
                              return _c(
                                "el-option",
                                {
                                  key: indexs,
                                  attrs: {
                                    label: item.value,
                                    value: item.value
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.label))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "13px",
                                        "padding-left": "100px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.value))]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-button",
                        {
                          class: { disabled: _vm.sendCoding },
                          attrs: { loading: _vm.isLoading, type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.sendCode("shift")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.getCodeText))]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "验证码", prop: "verify_code" } },
            [
              _c("el-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.verify_code,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "verify_code", $$v)
                  },
                  expression: "form.verify_code"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("div", { staticClass: "tips" }, [
                _c("span", [_vm._v("转让人信息：")]),
                _vm._v(
                  _vm._s(_vm.form.name) +
                    " | " +
                    _vm._s(_vm.form.phone) +
                    " | " +
                    _vm._s(_vm.form.sex)
                )
              ]),
              _c(
                "div",
                [
                  _c("member", {
                    attrs: { "title-s": _vm.title },
                    on: { memberId: _vm.memberId }
                  })
                ],
                1
              ),
              _vm.form.user_id
                ? _c("div", { staticClass: "tips" }, [
                    _c("span", [_vm._v("收卡人信息：")]),
                    _vm._v(
                      _vm._s(_vm.getUserNames(_vm.form.shift_user_info)) +
                        " | " +
                        _vm._s(_vm.form.shift_user_info.phone) +
                        " | " +
                        _vm._s(_vm.formatTableSex(_vm.form.shift_user_info))
                    )
                  ])
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "手续费", prop: "checked" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.form.checked,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "checked", $$v)
                        },
                        expression: "form.checked"
                      }
                    },
                    [_vm._v("收取转让手续费")]
                  )
                ],
                1
              ),
              _vm.form.checked
                ? _c(
                    "el-form-item",
                    { attrs: { label: "转让手续费", prop: "poundage" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手续费", type: "number" },
                        model: {
                          value: _vm.form.poundage,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "poundage", $$v)
                          },
                          expression: "form.poundage"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.checked
                ? _c(
                    "el-form-item",
                    { attrs: { label: "收费方式", prop: "payment_method_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "dialog_w100",
                          attrs: { placeholder: "收费方式" },
                          model: {
                            value: _vm.form.payment_method_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "payment_method_id", $$v)
                            },
                            expression: "form.payment_method_id"
                          }
                        },
                        _vm._l(_vm.payment_method, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: index }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请填写操作备注",
                      rows: "5",
                      maxlength: "300",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.form.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("back", false)
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }