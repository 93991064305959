var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "title_style" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            size: "small",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "头像", prop: "avatar" } },
            [
              _c(
                "div",
                {
                  staticClass: "avatar-box",
                  on: {
                    click: function($event) {
                      _vm.imagecropperShow = true
                    }
                  }
                },
                [
                  _c("el-avatar", {
                    attrs: {
                      shape: "square",
                      size: 100,
                      src: _vm.form.avatar ? _vm.form.avatar : _vm.defaultLogo,
                      title: "点击修改"
                    }
                  }),
                  _c("div", { staticClass: "avatar-hover" }, [
                    _vm._v(_vm._s(_vm.form.avatar ? "更换头像" : "上传头像"))
                  ])
                ],
                1
              ),
              _c("image-cropper", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imagecropperShow,
                    expression: "imagecropperShow"
                  }
                ],
                key: _vm.imagecropperKey,
                attrs: {
                  field: "file",
                  width: 300,
                  height: 300,
                  url: _vm.storeUpload,
                  "lang-type": "zh"
                },
                on: {
                  close: _vm.imagecropperClose,
                  "crop-upload-success": _vm.cropSuccess
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "真实姓名", prop: "real_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写真实姓名" },
                model: {
                  value: _vm.form.real_name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "real_name", $$v)
                  },
                  expression: "form.real_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "性别", prop: "sex" } },
            _vm._l(_vm.sexList, function(item, index) {
              return _c(
                "el-radio",
                {
                  key: index,
                  attrs: { label: item.value },
                  model: {
                    value: _vm.form.sex,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "sex", $$v)
                    },
                    expression: "form.sex"
                  }
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "简介" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请填简介" },
                model: {
                  value: _vm.form.introduce,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "introduce", $$v)
                  },
                  expression: "form.introduce"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "line-clear",
              attrs: { label: "个人封面", prop: "cover" }
            },
            [
              _c(
                "el-upload",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.upLoading,
                      expression: "upLoading"
                    }
                  ],
                  staticClass: "images-uploader",
                  attrs: {
                    action: _vm.storeUpload,
                    "show-file-list": false,
                    "on-success": _vm.upSuccess,
                    headers: _vm.utils.upload_headers(),
                    "on-progress": _vm.upProgress,
                    accept: ".jpg,.jpeg,.png"
                  }
                },
                [
                  _vm.form.cover
                    ? _c("img", {
                        staticClass: "images",
                        attrs: { src: _vm.form.cover, title: "点击上传图片" }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus images-uploader-icon",
                        staticStyle: { border: "1px dashed #d9d9d9" }
                      })
                ]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("更新")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }