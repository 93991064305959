var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "bbbb" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "搜索" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleFilter($event)
                    }
                  },
                  model: {
                    value: _vm.listQuery.query,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "query", $$v)
                    },
                    expression: "listQuery.query"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      loading: _vm.searchLoading
                    },
                    on: { click: _vm.handleFilter },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item m-l-10",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ name: "s_management_add" })
                }
              }
            },
            [_vm._v(" 添加 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "avatar", label: "头像" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c(
                          "el-avatar",
                          { attrs: { shape: "square", src: row.avatar } },
                          [
                            _c("img", {
                              attrs: { src: _vm.errorAvatarSrc(row, "img") }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "real_name", label: "姓名" }
          }),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "电话" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.phone) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sex",
              label: "性别",
              formatter: _vm.formatTableSex,
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "is_private",
              label: "教练类型",
              "min-width": "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.is_gold === 1
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "m-r-5 m-b-5",
                            attrs: { type: "danger" }
                          },
                          [_vm._v("金牌教练")]
                        )
                      : _vm._e(),
                    row.is_group
                      ? _c("el-tag", { staticClass: "m-r-5 m-b-5" }, [
                          _vm._v("团课教练")
                        ])
                      : _vm._e(),
                    row.is_private
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("私教课教练")
                        ])
                      : _vm._e(),
                    !row.is_group && !row.is_private
                      ? _c("span", [_vm._v("无")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "star_level", label: "星级" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-rate", {
                      attrs: {
                        value:
                          row.star_level >= 5 ? 5 : parseInt(row.star_level),
                        disabled: "",
                        "text-color": "#ff9900"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "创建时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "update_time", label: "更新时间" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("s_management_delete"),
                            expression: "btn_access('s_management_delete')"
                          }
                        ],
                        staticClass: "m-2",
                        attrs: {
                          slot: "reference",
                          type: "primary",
                          icon: "el-icon-edit",
                          offset: 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "s_management_edit",
                              params: { id: scope.row.id }
                            })
                          }
                        },
                        slot: "reference"
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    scope.row.is_super !== 1
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "删除后将无法找回，确定继续吗？" },
                            on: {
                              confirm: function($event) {
                                return _vm.store_user_delete(scope.row.id)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.btn_access(
                                      "s_management_delete"
                                    ),
                                    expression:
                                      "btn_access('s_management_delete')"
                                  }
                                ],
                                staticClass: "m-2",
                                attrs: {
                                  slot: "reference",
                                  type: "danger",
                                  icon: "el-icon-delete",
                                  offset: 2
                                },
                                slot: "reference"
                              },
                              [_vm._v(" 删除 ")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "course",
                        attrs: { "hide-on-click": false }
                      },
                      [
                        _c("el-button", { attrs: { type: "info" } }, [
                          _vm._v(" 更多 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right"
                          })
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            scope.row.is_super !== 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.store_user_quit(scope.row.id)
                                      }
                                    }
                                  },
                                  [
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-s-open"
                                      }),
                                      _vm._v(" 一键离职 ")
                                    ]
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handleEdit(scope)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", {
                                    staticClass: "el-icon-user-solid"
                                  }),
                                  _vm._v(" 作息时间 ")
                                ]
                              ],
                              2
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.handleAdd(scope)
                                  }
                                }
                              },
                              [
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid"
                                  }),
                                  _vm._v(" 员工相册 ")
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog_w750",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.dialog.visible,
            title: _vm.dialog.id ? "编辑账号" : "添加账号"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "left",
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账号头像", prop: "avatar" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "avatar-block avatar-box",
                              on: {
                                click: function($event) {
                                  _vm.imagecropperShow = true
                                }
                              }
                            },
                            [
                              _c(
                                "el-avatar",
                                {
                                  attrs: {
                                    shape: "square",
                                    size: 100,
                                    src: _vm.form.avatar,
                                    title: _vm.dialog.id
                                      ? "点击修改"
                                      : "点击上传"
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.errorImageSrc }
                                  })
                                ]
                              ),
                              _c("div", { staticClass: "avatar-hover" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.dialog.id ? "更换头像" : "上传头像"
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c("image-cropper", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.imagecropperShow,
                                expression: "imagecropperShow"
                              }
                            ],
                            key: _vm.imagecropperKey,
                            attrs: {
                              field: "file",
                              width: 300,
                              height: 300,
                              url: _vm.storeUpload,
                              "lang-type": "zh"
                            },
                            on: {
                              close: _vm.imagecropperClose,
                              "crop-upload-success": _vm.cropSuccess
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.dialog.id
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "密码",
                                prop: _vm.dialog.id ? "" : "password"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "不改请为空"
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "real_name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入姓名" },
                            model: {
                              value: _vm.form.real_name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "real_name", $$v)
                              },
                              expression: "form.real_name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "phone" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "请输入手机号"
                            },
                            model: {
                              value: _vm.form.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生日", prop: "birthday" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "请选择日期"
                            },
                            model: {
                              value: _vm.form.birthday,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "birthday", $$v)
                              },
                              expression: "form.birthday"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别", prop: "sex" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.sex,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sex", $$v)
                                },
                                expression: "form.sex"
                              }
                            },
                            _vm._l(_vm.sexList, function(item, index) {
                              return _c(
                                "el-radio",
                                { key: index, attrs: { label: item.value } },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "星级", prop: "star_level" } },
                        [
                          _vm.dialog.id
                            ? _c("el-rate", {
                                attrs: { colors: _vm.colors },
                                model: {
                                  value: _vm.form.star_level,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "star_level", $$v)
                                  },
                                  expression: "form.star_level"
                                }
                              })
                            : _c("el-rate", {
                                attrs: { colors: _vm.colors },
                                model: {
                                  value: _vm.form.star_level,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "star_level", $$v)
                                  },
                                  expression: "form.star_level"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "团课教练", prop: "is_group" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.is_group,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "is_group", $$v)
                                },
                                expression: "form.is_group"
                              }
                            },
                            _vm._l(_vm.typeOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "私课教练", prop: "is_private" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.is_private,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "is_private", $$v)
                                },
                                expression: "form.is_private"
                              }
                            },
                            _vm._l(_vm.typeOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "课程颜色", prop: "color" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-wrap row-between col-center"
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("el-color-picker", {
                                    style: { paddingTop: "10px" },
                                    model: {
                                      value: _vm.form.color,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "color", $$v)
                                      },
                                      expression: "form.color"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "快速选色",
                                        clearable: ""
                                      },
                                      on: { change: _vm.defaultColor },
                                      model: {
                                        value: _vm.defaultColorValue,
                                        callback: function($$v) {
                                          _vm.defaultColorValue = $$v
                                        },
                                        expression: "defaultColorValue"
                                      }
                                    },
                                    _vm._l(_vm.courseDefaultColorList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: index,
                                          attrs: { value: item, label: item }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "defaultColorItem",
                                              style: { background: item }
                                            },
                                            [_vm._v(_vm._s(item))]
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("br")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialog.visible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "员工相册",
            visible: _vm.dialogVisibles,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibles = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ]
            },
            [
              _c(
                "el-upload",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.upLoading,
                      expression: "upLoading"
                    }
                  ],
                  attrs: {
                    field: "file",
                    action: _vm.url + "/store/v2/store_user/photo/" + _vm.id,
                    "list-type": "picture-card",
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.upSuccess,
                    headers: _vm.utils.upload_headers(),
                    "file-list": _vm.fileList,
                    limit: 9,
                    "on-exceed": _vm.handleExceed,
                    "on-progress": _vm.upProgress,
                    "before-upload": _vm.checkFileType,
                    accept: ".jpg,.jpeg,.png"
                  }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _c(
                "el-dialog",
                {
                  staticClass: "dialog_view_image",
                  attrs: { visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
                  })
                ]
              ),
              _c("small", { staticClass: "text-info" }, [
                _vm._v("图片尺寸建议750*1000")
              ])
            ],
            1
          )
        ]
      ),
      _c("Dialog", {
        attrs: {
          "dialog-visible-display": _vm.dialogVisibleDisplay,
          "user-id": _vm.userId
        },
        on: { close: _vm.close }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }