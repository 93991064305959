import request from '@/utils/request'

// 提醒设置列表
export function getRemind(data) {
  return request({
    url: `/store/v1/remind_setting`,
    method: 'get',
    params: data
  })
}

// 获取提醒设置详情
export function getRemindInfo(id, data) {
  return request({
    url: `/store/v1/remind_setting/${id}`,
    method: 'get',
    params: data
  })
}

// 修改提醒设置
export function editRemindInfo(id, data) {
  return request({
    url: `/store/v1/remind_setting/${id}`,
    method: 'put',
    data
  })
}
