var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "dd_num" }, [
        _c("div", { staticClass: "dd" }, [
          _c("div", [
            _c(
              "span",
              { staticClass: "text-success p-x-2 font-xs" },
              [
                _c("count-to", {
                  attrs: {
                    "start-val": 0,
                    "end-val": _vm.tableData.count,
                    duration: 2000,
                    decimals: 0
                  }
                })
              ],
              1
            ),
            _vm._v(" 单 ")
          ]),
          _c("div", [_vm._v("订单数")])
        ]),
        _c("div", { staticClass: "dd" }, [
          _c("div", [
            _c(
              "span",
              { staticClass: "text-success p-x-2 font-xs" },
              [
                _c("count-to", {
                  attrs: {
                    "start-val": 0,
                    "end-val": _vm.money,
                    duration: 2000,
                    decimals: 0
                  }
                })
              ],
              1
            ),
            _vm._v(" 元 ")
          ]),
          _c("div", [_vm._v("总收入")])
        ])
      ]),
      _c(
        "div",
        { staticClass: "tableColumn" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableData.list, stripe: true }
            },
            [
              _c("el-table-column", {
                attrs: { label: "姓名", align: "center", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "user" }, [
                          _c(
                            "div",
                            { staticClass: "block" },
                            [
                              _c(
                                "el-avatar",
                                {
                                  attrs: {
                                    shape: "square",
                                    size: 50,
                                    src: row.user.avatar
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.errorAvatarSrc(row.user, "img")
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "m-l-10" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(row.user.nickname || row.user.real_name)
                              )
                            ]),
                            row.user.phone
                              ? _c("div", [_vm._v(_vm._s(row.user.phone))])
                              : _vm._e()
                          ])
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "订单编号", prop: "order_no", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "支付方式",
                  prop: "charge_type",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "付款金额",
                  prop: "charge_type",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v("￥ " + _vm._s(scope.row.charge_money))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  prop: "operator_type_text",
                  align: "center"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }