var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "p-b-10 cursor",
      staticStyle: { margin: "0 auto" },
      style: { width: _vm.badge ? "30px" : "80px" },
      on: { click: _vm.toPage }
    },
    [
      _vm.type !== ""
        ? _c("el-image", {
            attrs: {
              src: _vm.badge ? _vm.imageS[_vm.type] : _vm.image[_vm.type],
              fit: "fill"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }