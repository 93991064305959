<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisibleSettings"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="35%"
      :show-close="false"
      @open="handleOpen"
    >
      <div slot="title">
        <div class="title">
          <div class="title_title">合同设置</div>
          <div class="title_icon cursor" @click="handleClose">
            <i class="el-icon-close" />
          </div>
        </div>
      </div>
      <div v-loading="dialogLoading" class="tags_layout">
        <!-- 表单 -->
        <el-form ref="ruleForm" :model="form" label-position="left" label-width="160px">
          <p><b>通知设置</b></p>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="会员签署后通知我">
                <el-switch v-model="form.notify_store_user" :active-value="1" :inactive-value="0" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="短信通知会员签署合同">
                <el-switch v-model="form.enable_sms" :active-value="1" :inactive-value="0" />
              </el-form-item>
            </el-col>
          </el-row>
          <p><b>签署设置</b></p>
          <el-row :gutter="20">
            <el-col style="position: relative;" :span="12">
              <el-form-item label="会员合同签署截至期限">
                <el-switch v-model="form.sign_valid_period" :active-value="1" :inactive-value="0" />
              </el-form-item>
              <div class="text-info" style="position: absolute; top: 30px; left: 10px;">
                (开启后会员需在规定时间签署，否则将逾期)
              </div>
            </el-col>
            <el-col v-if="form.sign_valid_period" :span="12">
              <el-form-item label="未签署逾期时间">
                <el-input v-model="day">
                  <template slot="append">天内</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col style="position: relative;" :span="12">
              <el-form-item label="会员签署时强制阅读">
                <el-switch v-model="form.verify_read_seconds" :active-value="1" :inactive-value="0" />
              </el-form-item>
              <div class="text-info" style="position: absolute; top: 30px; left: 10px;">
                (开启后会员签署时需要阅读规定时间才能签署)
              </div>
            </el-col>
            <el-col v-if="form.verify_read_seconds" :span="12">
              <el-form-item label="阅读时间">
                <el-input v-model="second">
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="flex row-between m-t-20">
          <div class="font-title">合同标签</div>
          <el-button
            :type="is_edit ? 'success' : 'primary'"
            size="mini"
            icon="el-icon-edit"
            plain
            @click="is_edit = !is_edit"
          >
            {{ is_edit ? '完 成' : '编 辑' }}
          </el-button>
        </div>
        <el-tag
          v-for="item in tagData.list"
          :key="item.id"
          class="m-t-10 m-r-10 m-b-10 cursor"
          :closable="is_edit"
          @close="handleEditTags(item.id)"
        >
          {{ item.name }}
        </el-tag>
        <el-button v-if="!is_edit" type="success" size="mini" icon="el-icon-plus" plain @click="handleAddTags">
          添 加
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlePreserve">保 存</el-button>
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getContractSettingDetail, editContractSetting, getTagList, addTag, deleteTag } from '@/api/store/contract'
// 函数
import { deepClone } from '@/utils'

export default {
  components: {},
  props: {
    dialogVisibleSettings: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        notify_store_user: 0,
        enable_sms: 0,
        sign_valid_period: 0,
        verify_read_seconds: 0
      },
      tagData: {
        count: 0,
        list: []
      },
      day: 1,
      second: 3,
      dialogLoading: false,
      is_edit: false
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.dialogLoading = true

      getTagList()
        .then(res => {
          const { data } = res
          this.tagData = data
        })
        .finally(() => {})

      getContractSettingDetail()
        .then(res => {
          const { data } = res
          const { notify_store_user, enable_sms, sign_valid_period, verify_read_seconds } = data
          this.form = {
            notify_store_user,
            enable_sms,
            sign_valid_period,
            verify_read_seconds
          }
          if (!sign_valid_period) this.form.sign_valid_period = 0
          else {
            this.day = this.form.sign_valid_period
            this.form.sign_valid_period = 1
          }
          if (verify_read_seconds > 0) {
            this.second = this.form.verify_read_seconds
            this.form.verify_read_seconds = 1
          }
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    // 关闭的回调
    handleClose() {
      this.$emit('closeVisibleSettings', this.idList)
    },
    // 保存
    handlePreserve() {
      const data = deepClone(this.form)
      if (data.sign_valid_period === 0) data.sign_valid_period = -1
      else data.sign_valid_period = this.day
      if (data.verify_read_seconds > 0) data.verify_read_seconds = this.second
      editContractSetting(data).then(() => {
        this.$message.success('修改成功')
        this.handleClose()
      })
    },
    // 新增标签
    handleAddTags() {
      this.$prompt('请输入标签名称', '自定义标签名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          const data = {
            name: value
          }
          addTag(data).then(() => {
            this.$message.success('新增成功')
            this.handleOpen()
          })
        })
        .catch(() => {})
    },
    // 删除标签
    handleEditTags(id) {
      this.$confirm('此操作将永久删除标签 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteTag(id).then(() => {
            this.$message.success('删除成功')
            this.handleOpen()
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.font-title {
  font-size: 16px;
  font-weight: bold;
}
</style>
