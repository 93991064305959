import request from '@/utils/request'

// 手机修改日志列表
export function getPhoneJournal(data) {
  return request({
    url: '/admin/v1/store/user_phone_change',
    method: 'get',
    params: data
  })
}
