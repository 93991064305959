import request from '@/utils/request'
import Utils from '@/utils/utils.js'

/**
 * 获取公告列表
 */
export function getNotice(data) {
  return request({
    url: `/store/v1/notice/venues_id/${Utils.getVenues().id}`,
    method: 'get',
    params: data
  })
}

/**
 * 新增公告
 */
export function addNotice(data) {
  return request({
    url: `/store/v1/notice`,
    method: 'post',
    data
  })
}

/**
 * 更新公告内容
 */
export function updateNotice(id, data) {
  return request({
    url: `/store/v1/notice/${id}`,
    method: 'put',
    data
  })
}

/**
 * 获取公告详情
 */
export function getNoticeInfo(id, data) {
  return request({
    url: `/store/v1/notice/${id}`,
    method: 'get',
    params: data
  })
}

/**
 * 删除公告
 */
export function delNotice(id, data) {
  return request({
    url: `/store/v1/notice/${id}`,
    method: 'delete',
    params: data
  })
}
