import request from '@/utils/request'

/**
 * 获取列表
 */
export function getArea(data) {
  return request({
    url: `/store/v1/area`,
    method: 'get',
    params: data
  })
}

/**
 * 获取详情
 */
export function getAreaInfo(id, data) {
  return request({
    url: `/store/v1/area/${id}`,
    method: 'get',
    params: data
  })
}

/**
 * 删除
 */
export function delArea(id, data) {
  return request({
    url: `/store/v1/area/${id}`,
    method: 'delete',
    params: data
  })
}

/**
 * 新增
 */
export function addArea(data) {
  return request({
    url: `/store/v1/area`,
    method: 'post',
    data
  })
}

/**
 * 更新
 */
export function updateArea(id, data) {
  return request({
    url: `/store/v1/area/${id}`,
    method: 'put',
    data
  })
}

