var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "createPost-container" },
      [
        _c(
          "el-form",
          {
            ref: "postForm",
            staticClass: "form-container",
            attrs: {
              model: _vm.postForm,
              rules: _vm.rules,
              "label-width": "80px",
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "分类", prop: "category_id" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.postForm.category_id,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "category_id", $$v)
                      },
                      expression: "postForm.category_id"
                    }
                  },
                  _vm._l(_vm.categoryOptions, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "标题", prop: "title" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "标题" },
                  model: {
                    value: _vm.postForm.title,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "title", $$v)
                    },
                    expression: "postForm.title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "内容", prop: "content" } },
              [
                _c("Tinymce", {
                  ref: "editor",
                  model: {
                    value: _vm.postForm.content,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "content", $$v)
                    },
                    expression: "postForm.content"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "文章封面", prop: "cover" } },
              [
                _c(
                  "el-upload",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.upLoading,
                        expression: "upLoading"
                      }
                    ],
                    staticClass: "images-uploader",
                    attrs: {
                      action: _vm.adminUpload,
                      "show-file-list": false,
                      "on-success": _vm.upSuccess,
                      headers: _vm.utils.upload_headers(),
                      "on-progress": _vm.upProgress,
                      accept: ".jpg,.jpeg,.png"
                    }
                  },
                  [
                    _vm.postForm.cover
                      ? _c("img", {
                          staticClass: "images",
                          attrs: {
                            src: _vm.postForm.cover,
                            title: "点击上传图片"
                          }
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus images-uploader-icon",
                          staticStyle: { border: "1px dashed #d9d9d9" }
                        })
                  ]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "视频地址", prop: "video_file" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "视频地址" },
                  model: {
                    value: _vm.postForm.video_file,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "video_file", $$v)
                    },
                    expression: "postForm.video_file"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "视频封面", prop: "video_cover" } },
              [
                _c(
                  "el-upload",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.upVideoCoverLoading,
                        expression: "upVideoCoverLoading"
                      }
                    ],
                    staticClass: "images-uploader",
                    attrs: {
                      action: _vm.adminUpload,
                      "show-file-list": false,
                      "on-success": _vm.upSuccess1,
                      headers: _vm.utils.upload_headers(),
                      "on-progress": _vm.upProgress1,
                      accept: ".jpg,.jpeg,.png"
                    }
                  },
                  [
                    _vm.postForm.video_cover
                      ? _c("img", {
                          staticClass: "images",
                          attrs: {
                            src: _vm.postForm.video_cover,
                            title: "点击上传图片"
                          }
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus images-uploader-icon",
                          staticStyle: { border: "1px dashed #d9d9d9" }
                        })
                  ]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "排序", prop: "weigh" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "排序", type: "number" },
                  model: {
                    value: _vm.postForm.weigh,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "weigh", $$v)
                    },
                    expression: "postForm.weigh"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "热门" } },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.postForm.is_hot,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "is_hot", $$v)
                    },
                    expression: "postForm.is_hot"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "入门引导" } },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.postForm.is_guide,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "is_guide", $$v)
                    },
                    expression: "postForm.is_guide"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "显示" } },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.postForm.status,
                    callback: function($$v) {
                      _vm.$set(_vm.postForm, "status", $$v)
                    },
                    expression: "postForm.status"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer"
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.submitForm }
              },
              [_vm._v("保存")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }