<template>
  <el-dialog
    :visible.sync="dialogVisibleAccount"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="30%"
    :show-close="false"
    @open="handleOpen"
    @close="handleClose('ruleForm')"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">{{ type === 'add' ? '新增菜单' : '编辑菜单' }}</div>
        <div class="title_icon cursor" @click="handleClose('ruleForm')">
          <i class="el-icon-close" />
        </div>
      </div>
      <el-form
        ref="ruleForm"
        v-loading="formLoading"
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm m-t-40"
      >
        <el-form-item label="上级ID">
          <!-- <el-cascader v-model="ruleForm.parent_id" style="width: 100%;" :options="catalogData" :props="{ checkStrictly: true }" clearable filterable /> -->
          <SelectTree
            :props="defaultProps"
            :options="catalogData"
            :value="ruleForm.parent_id"
            :clearable="isClearable"
            :accordion="isAccordion"
            @getValue="getValue($event)"
          />
        </el-form-item>
        <el-form-item label="title" prop="title">
          <el-input v-model="ruleForm.title" clearable placeholder="请输入菜单标题" />
        </el-form-item>
        <el-form-item label="icon">
          <el-input v-model="ruleForm.icon" clearable placeholder="请输入菜单图标" />
        </el-form-item>
        <el-form-item label="name">
          <el-input v-model="ruleForm.name" clearable placeholder="请输入节点名" />
        </el-form-item>
        <el-form-item label="redirect">
          <el-input v-model="ruleForm.redirect" clearable placeholder="请输入重定向地址" />
        </el-form-item>
        <el-form-item label="path">
          <el-input v-model="ruleForm.path" clearable placeholder="请输入路径" />
        </el-form-item>
        <el-form-item label="component">
          <el-input v-model="ruleForm.component" clearable placeholder="请输入模版路径" />
        </el-form-item>
        <el-form-item label="activeMenu">
          <el-input v-model="ruleForm.activeMenu" clearable placeholder="请输入关联路径" />
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="ruleForm.sort" type="number" clearable placeholder="请输入排序" />
        </el-form-item>
        <el-form-item label="hidden">
          <el-radio-group v-model="ruleForm.hidden">
            <el-radio-button :label="1">否</el-radio-button>
            <el-radio-button :label="0">是</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="根菜单">
          <el-radio-group v-model="ruleForm.is_root">
            <el-radio-button :label="0">否</el-radio-button>
            <el-radio-button :label="1">是</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="显示隐藏">
          <el-radio-group v-model="ruleForm.is_show">
            <el-radio-button :label="0">隐藏</el-radio-button>
            <el-radio-button :label="1">显示</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group v-model="ruleForm.type">
            <el-radio-button :label="0">菜单</el-radio-button>
            <el-radio-button :label="1">按钮</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="ruleForm.mark" type="textarea" :rows="3" clearable placeholder="请输入备注" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="buttonLoading" @click="handleClose('ruleForm')">取 消</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// API
import { getMenuList, addMenu, updateMenu, getMenuDetail } from '@/api/admin/account.js'
// 组件
import SelectTree from '@/components/TreeSelect/treeSelect.vue'
// 函数
import { deepClone } from '@/utils'

export default {
  components: { SelectTree },
  props: {
    dialogVisibleAccount: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    typeId: {
      type: Number,
      default: 0
    },
    pid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: {
        title: '',
        parent_id: 0,
        icon: '',
        name: '',
        redirect: '',
        path: '',
        component: '',
        activeMenu: '',
        sort: 0,
        hidden: 1,
        is_root: 0,
        is_show: 0,
        type: 0,
        mark: ''
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }]
      },
      buttonLoading: false,
      formLoading: false,
      catalogData: [],
      isClearable: false, // 可清空（可选）
      isAccordion: false, // 可收起（可选）
      defaultProps: {
        children: 'children',
        label: 'title',
        value: 'id'
      }
    }
  },
  watch: {
    pid(newValue, oldValue) {
      this.ruleForm.parent_id = newValue
    }
  },
  methods: {
    // 打开的回调
    handleOpen() {
      this.getCatalogData()
      if (this.type === 'edit') this.getDetails()
    },
    // 关闭的回调
    handleClose(formName) {
      this.$emit('menuVisible')
      this.ruleForm = { title: '', parent_id: 0, icon: '', name: '', redirect: '', path: '', component: '', activeMenu: '', sort: 0, hidden: 1, is_root: 0, is_show: 0, type: 0, mark: '' }
    },
    // 保存表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          const data = deepClone(this.ruleForm)
          // data.parent_id = data.parent_id[data.parent_id.length - 1]
          if (this.type === 'add') {
            addMenu(data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('新增成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          } else {
            updateMenu(this.typeId, data)
              .then(() => {
                this.handleClose(formName)
                this.$message.success('编辑成功!')
              })
              .finally(() => {
                this.buttonLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    // 目录数据
    getCatalogData() {
      getMenuList()
        .then(res => {
          const { data } = res
          // const dataList = {
          //   value: 0,
          //   label: '顶级路由'
          // }
          // this.catalogData = this.convertToCascaderData(data)
          // this.catalogData.unshift(dataList)
          this.catalogData = data
        })
    },
    getDetails() {
      this.formLoading = true
      getMenuDetail(this.typeId)
        .then(res => {
          const {
            title,
            parent_id,
            icon,
            name,
            redirect,
            path,
            component,
            activeMenu,
            sort,
            hidden,
            is_root,
            is_show,
            type,
            mark
          } = res.data
          this.ruleForm = {
            title,
            parent_id,
            icon,
            name,
            redirect,
            path,
            component,
            activeMenu,
            sort,
            hidden,
            is_root,
            is_show,
            type,
            mark
          }
        })
        .finally(() => {
          this.formLoading = false
        })
    },
    // 取值
    getValue(value) {
      this.ruleForm.parent_id = value
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
