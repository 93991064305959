import request from '@/utils/request'

// 获取异常日志列表
export function getAbnormalLogList(data) {
  return request({
    url: '/admin/v1/log/error',
    method: 'get',
    params: data
  })
}
