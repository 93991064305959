var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container special-center" },
    [
      _c("el-page-header", {
        staticClass: "page-header",
        attrs: { content: "编辑会员卡" },
        on: {
          back: function($event) {
            return _vm.returnPage()
          }
        }
      }),
      _c("el-divider"),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "ruleForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "left",
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员卡名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入会员卡名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "种类", prop: "kind" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.form.kind,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "kind", $$v)
                            },
                            expression: "form.kind"
                          }
                        },
                        [_vm._v("常规")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.form.kind,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "kind", $$v)
                            },
                            expression: "form.kind"
                          }
                        },
                        [_vm._v("体验")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员卡类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.typeChange },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        _vm._l(_vm.card_type, function(item, index) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item, value: index }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "售价", prop: "price" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { type: "number", placeholder: "请输入售价" },
                          model: {
                            value: _vm.form.price,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "price", $$v)
                            },
                            expression: "form.price"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元")])],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.type_hidden,
                      expression: "!type_hidden"
                    }
                  ],
                  attrs: { span: 12 }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "卡额度", prop: "limit" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "number",
                            placeholder: "请输入卡额度"
                          },
                          model: {
                            value: _vm.form.limit,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "limit", $$v)
                            },
                            expression: "form.limit"
                          }
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(_vm._s(_vm.limit_type))
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期", prop: "digital" } },
                    [
                      _c(
                        "el-input",
                        {
                          ref: "v_digital",
                          staticClass: "input-with-select",
                          attrs: {
                            onkeyup:
                              "value=value.replace(/^0+(\\d)|[^\\d]+/g,'')",
                            min: "1",
                            type: "number",
                            placeholder: "有效期"
                          },
                          model: {
                            value: _vm.form.valid_period.digital,
                            callback: function($$v) {
                              _vm.$set(_vm.form.valid_period, "digital", $$v)
                            },
                            expression: "form.valid_period.digital"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "v_type",
                              staticStyle: { width: "60px" },
                              attrs: { slot: "append", placeholder: "请选择" },
                              slot: "append",
                              model: {
                                value: _vm.form.valid_period.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.form.valid_period, "type", $$v)
                                },
                                expression: "form.valid_period.type"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "天",
                                  value: "day",
                                  checked: _vm.form.valid_period.type == "day"
                                }
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "月",
                                  value: "month",
                                  checked: _vm.form.valid_period.type == "month"
                                }
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "年",
                                  value: "year",
                                  checked: _vm.form.valid_period.type == "year"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "适用场馆及课程", prop: "venuesCourse" }
                    },
                    [
                      _vm.utils.empty(_vm.courseLists)
                        ? _c(
                            "div",
                            { staticClass: "tal" },
                            [
                              _c(
                                "el-collapse",
                                {
                                  model: {
                                    value: _vm.activeNames,
                                    callback: function($$v) {
                                      _vm.activeNames = $$v
                                    },
                                    expression: "activeNames"
                                  }
                                },
                                [
                                  _c(
                                    "el-collapse-item",
                                    {
                                      attrs: {
                                        title: "点击折叠/展开",
                                        name: "1"
                                      }
                                    },
                                    [
                                      _c("checkboxInputSon", {
                                        attrs: {
                                          type: _vm.form.type,
                                          "parent-selected":
                                            _vm.form.venuesCourse,
                                          list: _vm.courseList
                                        },
                                        on: {
                                          "update:type": function($event) {
                                            return _vm.$set(
                                              _vm.form,
                                              "type",
                                              $event
                                            )
                                          },
                                          "update:parentSelected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.form,
                                              "venuesCourse",
                                              $event
                                            )
                                          },
                                          "update:parent-selected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.form,
                                              "venuesCourse",
                                              $event
                                            )
                                          },
                                          "update:list": function($event) {
                                            _vm.courseList = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            _vm._l(_vm.courseLists, function(item) {
                              return _c(
                                "el-collapse",
                                {
                                  key: item.id,
                                  model: {
                                    value: _vm.activeNames,
                                    callback: function($$v) {
                                      _vm.activeNames = $$v
                                    },
                                    expression: "activeNames"
                                  }
                                },
                                [
                                  _c(
                                    "el-collapse-item",
                                    {
                                      attrs: { title: item.name, name: item.id }
                                    },
                                    [
                                      _c("checkboxInputSon", {
                                        attrs: {
                                          type: _vm.form.type,
                                          "parent-selected":
                                            _vm.form.venuesCourse[item.id],
                                          list: item.list
                                        },
                                        on: {
                                          "update:type": function($event) {
                                            return _vm.$set(
                                              _vm.form,
                                              "type",
                                              $event
                                            )
                                          },
                                          "update:parentSelected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.form.venuesCourse,
                                              item.id,
                                              $event
                                            )
                                          },
                                          "update:parent-selected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.form.venuesCourse,
                                              item.id,
                                              $event
                                            )
                                          },
                                          "update:list": function($event) {
                                            return _vm.$set(
                                              item,
                                              "list",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    staticClass: "flex cursor font-14 m-y-10",
                    on: {
                      click: function($event) {
                        _vm.settingShow = !_vm.settingShow
                      }
                    }
                  },
                  [
                    _c("div", [
                      _vm._v(" 高级设置 "),
                      _c("i", { staticClass: "el-icon-arrow-down" })
                    ]),
                    _c("div", { staticClass: "m-l-55" }, [
                      _vm._v(
                        "（提示：高级设置可设置该卡的预约限额、可用时间等，注：该限制仅针对客户端预约）"
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.settingShow,
                        expression: "settingShow"
                      }
                    ],
                    staticClass: "m-y-10 settings"
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" 卡相关设置 ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "config" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "请假设置" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 0 },
                                  model: {
                                    value: _vm.form.setting.leave_option,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.setting,
                                        "leave_option",
                                        $$v
                                      )
                                    },
                                    expression: "form.setting.leave_option"
                                  }
                                },
                                [_vm._v("不允许")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.form.setting.leave_option,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.setting,
                                        "leave_option",
                                        $$v
                                      )
                                    },
                                    expression: "form.setting.leave_option"
                                  }
                                },
                                [_vm._v("不限制")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 2 },
                                  model: {
                                    value: _vm.form.setting.leave_option,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.setting,
                                        "leave_option",
                                        $$v
                                      )
                                    },
                                    expression: "form.setting.leave_option"
                                  }
                                },
                                [_vm._v("允许")]
                              )
                            ],
                            1
                          ),
                          _vm.form.setting.leave_option == 2
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "可请假次数(次)",
                                            "label-width": "110px"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticStyle: { width: "180px" },
                                              attrs: {
                                                type: "number",
                                                placeholder: "请输入请假次数"
                                              },
                                              model: {
                                                value:
                                                  _vm.form.setting.leave_number,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form.setting,
                                                    "leave_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.setting.leave_number"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("次")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "可请假天数(天)",
                                            "label-width": "110px"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticStyle: { width: "180px" },
                                              attrs: {
                                                type: "number",
                                                placeholder: "请输入请假天数"
                                              },
                                              model: {
                                                value:
                                                  _vm.form.setting.leave_day,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form.setting,
                                                    "leave_day",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.setting.leave_day"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("天")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "单节课可约人数上限",
                                prop: "single_subscribe_limit",
                                "inline-message": true,
                                "label-width": "150px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "140px" },
                                attrs: {
                                  type: "number",
                                  placeholder: "默认单课可约1人",
                                  min: "1",
                                  max: "10"
                                },
                                model: {
                                  value:
                                    _vm.form.setting.single_subscribe_limit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.setting,
                                      "single_subscribe_limit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.setting.single_subscribe_limit"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "每日可约次数上限",
                                prop: "every_day_subscribe_limit",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "140px" },
                                attrs: {
                                  type: "number",
                                  placeholder: "不限制预约次数"
                                },
                                model: {
                                  value:
                                    _vm.form.setting.every_day_subscribe_limit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.setting,
                                      "every_day_subscribe_limit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.setting.every_day_subscribe_limit"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "每周可约次数上限",
                                prop: "every_week_subscribe_limit",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "140px" },
                                attrs: {
                                  type: "number",
                                  placeholder: "不限制预约次数"
                                },
                                model: {
                                  value:
                                    _vm.form.setting.every_week_subscribe_limit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.setting,
                                      "every_week_subscribe_limit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.setting.every_week_subscribe_limit"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "每月可约次数上限",
                                prop: "every_month_subscribe_limit",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "140px" },
                                attrs: {
                                  type: "number",
                                  placeholder: "不限制预约次数"
                                },
                                model: {
                                  value:
                                    _vm.form.setting
                                      .every_month_subscribe_limit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.setting,
                                      "every_month_subscribe_limit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.setting.every_month_subscribe_limit"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "可预约天数",
                                prop: "subscribe_day"
                              }
                            },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c("div", [_vm._v("会员最多可预约")]),
                                _c(
                                  "div",
                                  { staticClass: "m-x-5" },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        type: "number",
                                        placeholder: "不限制"
                                      },
                                      model: {
                                        value: _vm.form.setting.subscribe_day,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.setting,
                                            "subscribe_day",
                                            $$v
                                          )
                                        },
                                        expression: "form.setting.subscribe_day"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", [_vm._v("天的课程，0表示当天")])
                              ])
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "可用时间",
                                prop: "dayparting_select"
                              }
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.form.setting.dayparting_select,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.setting,
                                        "dayparting_select",
                                        $$v
                                      )
                                    },
                                    expression: "form.setting.dayparting_select"
                                  }
                                },
                                [_vm._v("全时段")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 2 },
                                  model: {
                                    value: _vm.form.setting.dayparting_select,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form.setting,
                                        "dayparting_select",
                                        $$v
                                      )
                                    },
                                    expression: "form.setting.dayparting_select"
                                  }
                                },
                                [_vm._v("自定义时段")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.form.setting.dayparting_select == 2,
                                  expression:
                                    "form.setting.dayparting_select == 2"
                                }
                              ],
                              staticClass: "dayparting_config"
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("可用时间内才可预约")
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.dayparting_days,
                                        callback: function($$v) {
                                          _vm.dayparting_days = $$v
                                        },
                                        expression: "dayparting_days"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 1 }
                                        },
                                        [_vm._v("周一")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 2 }
                                        },
                                        [_vm._v("周二")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 3 }
                                        },
                                        [_vm._v("周三")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 4 }
                                        },
                                        [_vm._v("周四")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 5 }
                                        },
                                        [_vm._v("周五")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 6 }
                                        },
                                        [_vm._v("周六")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 7 }
                                        },
                                        [_vm._v("周日")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("div", { staticClass: "title" }, [
                                _vm._v("可用时段(最多3个)")
                              ]),
                              _c(
                                "div",
                                {},
                                [
                                  _vm._l(_vm.dayparting_time_config, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "flex m-y-5" },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("el-time-picker", {
                                              staticStyle: { width: "120px" },
                                              attrs: {
                                                clearable: false,
                                                format: "HH:mm",
                                                "value-format": "HH:mm",
                                                "arrow-control": ""
                                              },
                                              model: {
                                                value: item.stime,
                                                callback: function($$v) {
                                                  _vm.$set(item, "stime", $$v)
                                                },
                                                expression: "item.stime"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "m-x-5" }, [
                                          _vm._v("至")
                                        ]),
                                        _c(
                                          "div",
                                          [
                                            _c("el-time-picker", {
                                              staticStyle: { width: "120px" },
                                              attrs: {
                                                clearable: false,
                                                format: "HH:mm",
                                                "value-format": "HH:mm",
                                                "arrow-control": ""
                                              },
                                              model: {
                                                value: item.etime,
                                                callback: function($$v) {
                                                  _vm.$set(item, "etime", $$v)
                                                },
                                                expression: "item.etime"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        index != 0
                                          ? _c(
                                              "div",
                                              { staticClass: "m-l-5" },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      icon: "el-icon-delete",
                                                      type: "danger"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.delTime(item)
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "m-y-10" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.dayparting_time_config
                                                  .length < 3,
                                              expression:
                                                "dayparting_time_config.length < 3"
                                            }
                                          ],
                                          attrs: {
                                            icon: "el-icon-plus",
                                            plain: "",
                                            type: "success"
                                          },
                                          on: { click: _vm.addTime }
                                        },
                                        [_vm._v(" 新增可用时段 ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("用卡人相关设置")
                      ]),
                      _c(
                        "div",
                        { staticClass: "config" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "每日约课间隔时间限制",
                                prop: "every_interval_limit",
                                "label-width": "160px"
                              }
                            },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "m-x-5" },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        type: "number",
                                        placeholder: "不限制"
                                      },
                                      model: {
                                        value:
                                          _vm.form.setting.every_interval_limit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.setting,
                                            "every_interval_limit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.setting.every_interval_limit"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", [_vm._v("分钟内不可连续约课")])
                              ])
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "预约次数限制",
                                prop: "not_finished_subscribe_limit",
                                "label-width": "160px"
                              }
                            },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c("div", [_vm._v("未结束课程预约次数限制为")]),
                                _c(
                                  "div",
                                  { staticClass: "m-x-5" },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        type: "number",
                                        placeholder: "不限制"
                                      },
                                      model: {
                                        value:
                                          _vm.form.setting
                                            .not_finished_subscribe_limit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.setting,
                                            "not_finished_subscribe_limit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.setting.not_finished_subscribe_limit"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", [_vm._v("次，0表示不限制")])
                              ])
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "会员取消预约次数限制",
                                prop: "cancel_subscribe_limit",
                                "label-width": "160px"
                              }
                            },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "120px" },
                                      attrs: {
                                        type: "number",
                                        placeholder: "不限取消预约次数"
                                      },
                                      model: {
                                        value:
                                          _vm.form.setting
                                            .cancel_subscribe_limit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.setting,
                                            "cancel_subscribe_limit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.setting.cancel_subscribe_limit"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "m-x-5" }, [
                                  _vm._v("次，每")
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value:
                                            _vm.form.setting
                                              .cancel_subscribe_cycle,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.setting,
                                              "cancel_subscribe_cycle",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.setting.cancel_subscribe_cycle"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-option",
                                          { attrs: { label: "日", value: 1 } },
                                          [_vm._v("日")]
                                        ),
                                        _c(
                                          "el-option",
                                          { attrs: { label: "周", value: 2 } },
                                          [_vm._v("周")]
                                        ),
                                        _c(
                                          "el-option",
                                          { attrs: { label: "月", value: 3 } },
                                          [_vm._v("月")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.returnPage()
                }
              }
            },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }