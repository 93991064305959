var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules }
        },
        [
          _c("el-form-item", { attrs: { label: "请假日期" } }, [
            _vm.is_display && _vm.diff_day
              ? _c("div", { staticClass: "flex" }, [
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatDate")(
                            _vm.cardStatusLog.start_time,
                            "YYYY-MM-DD"
                          )
                        ) +
                        " 至 " +
                        _vm._s(
                          _vm._f("formatDate")(
                            _vm.cardStatusLog.end_time,
                            "YYYY-MM-DD"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "m-l-10" }, [
                    _vm._v(" 共 "),
                    _c("span", { staticClass: "text-primary p-x-4" }, [
                      _vm._v(_vm._s(_vm.diff_day))
                    ]),
                    _vm._v(" 天 ")
                  ])
                ])
              : _c("div", { staticClass: "flex" }, [_vm._v("无请假记录")])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "销假日期", prop: "end_time" } },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  [
                    _c("el-date-picker", {
                      attrs: {
                        format: "yyyy 年 MM 月 dd 日",
                        "value-format": "yyyy-MM-dd",
                        clearable: false,
                        "picker-options": _vm.dataProhibitSelection
                      },
                      on: { change: _vm.end_time_change },
                      model: {
                        value: _vm.form.end_time,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "end_time", $$v)
                        },
                        expression: "form.end_time"
                      }
                    })
                  ],
                  1
                ),
                _vm.is_display
                  ? _c("div", { staticClass: "m-l-10" }, [
                      _vm._v(" 本次实际请假 "),
                      _c("span", { staticClass: "text-primary p-x-4" }, [
                        _vm._v(_vm._s(_vm.c_diff_day))
                      ]),
                      _vm._v(" 天 ")
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请填写操作备注",
                  rows: "5",
                  maxlength: "300",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("back", false)
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }