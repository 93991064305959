var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c("el-row", { staticClass: "text-center", attrs: { gutter: 20 } }, [
        _c(
          "div",
          { staticClass: "m-b-1 qrcode_images" },
          [
            _c(
              "el-col",
              { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } },
              [
                _c(
                  "div",
                  { staticClass: "qr-box" },
                  [
                    _c("div", { staticClass: "m-b-10 font-18 qr-text" }, [
                      _vm._v("公众号")
                    ]),
                    _c("el-image", {
                      staticClass: "image_item wechat_path ",
                      attrs: {
                        src: _vm.data.wechat_path,
                        "preview-src-list": [_vm.data.wechat_path]
                      }
                    }),
                    _c("br"),
                    _c(
                      "el-link",
                      {
                        staticClass: "image_down",
                        attrs: { icon: "el-icon-download" },
                        on: {
                          click: function($event) {
                            return _vm.downloadCodeImg("wechat_path")
                          }
                        }
                      },
                      [_vm._v("点击下载二维码 ")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-col",
              { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } },
              [
                _c(
                  "div",
                  { staticClass: "qr-box" },
                  [
                    _c("div", { staticClass: "m-b-10 font-18 qr-text" }, [
                      _vm._v("小程序")
                    ]),
                    _c("el-image", {
                      staticClass: "image_item program_path ",
                      attrs: {
                        src: _vm.data.program_path,
                        "preview-src-list": [_vm.data.program_path]
                      }
                    }),
                    _c("br"),
                    _c(
                      "el-link",
                      {
                        staticClass: "image_down",
                        attrs: { icon: "el-icon-download" },
                        on: {
                          click: function($event) {
                            return _vm.downloadCodeImg("program_path")
                          }
                        }
                      },
                      [_vm._v("点击下载二维码")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-col",
              { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
              [
                _c("div", { staticClass: "p-14 m-t-10" }, [
                  _c("span", [_vm._v("访客手机扫一扫录入信息")]),
                  _c("span", [_vm._v(" - " + _vm._s(_vm.Venues.name))])
                ])
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }