<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button v-show="btn_access('version_add')" v-waves class="filter-item m-r-10" type="primary" icon="el-icon-plus" @click="add_edit('')">
        添加
      </el-button>
    </div>
    <el-table ref="multipleTable" v-loading="loading.listLoading" :data="tableData.list" row-key="id">
      <el-table-column prop="code" label="版本号" />
      <el-table-column prop="name" label="版本名称" />
      <el-table-column prop="content" label="版本说明" />
      <el-table-column prop="type" label="迭代类型" :formatter="typeFilter" />
      <el-table-column prop="private_ip" label="内测IP" />
      <el-table-column prop="channel" label="发布平台" />
      <el-table-column prop="download_url" label="下载链接" />
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('version_edit')" type="primary" icon="el-icon-edit" :offset="2" @click="add_edit(scope.row.id)">编辑</el-button>
          <el-popconfirm
            v-show="btn_access('version_del')"
            title="确定要删除此版本吗？"
            @confirm="plan_delete(scope.row.id)"
          >
            <el-button slot="reference" type="danger" icon="el-icon-delete" :offset="2" class="m-l-10">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.id ? '编辑版本' : '添加版本'"
      class="dialog_w750"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="110px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="版本号" prop="code">
              <el-input v-model="form.code" placeholder="20211230" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="版本名称" prop="name">
              <el-input v-model="form.name" placeholder="1.0.0" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="内测IP" prop="private_ip">
          <el-input v-model="form.private_ip" placeholder="0.0.0.0" />
        </el-form-item>
        <el-form-item label="内测CID" prop="private_cid">
          <el-input v-model="form.private_cid" placeholder="6efe4442ca1070a73dc3129df1f5191d" />
        </el-form-item>
        <el-form-item label="下载链接" prop="download_url">
          <el-input v-model="form.download_url" placeholder="https://xxxxx">
            <template slot="append">
              <el-upload
                class="upload-demo"
                :action="adminUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :on-success="upSuccess"
                :headers="utils.upload_headers()"
                :show-file-list="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="华为渠道包" prop="c_huawei">
          <el-input v-model="form.c_huawei" placeholder="https://xxxxx">
            <template slot="append">
              <el-upload
                class="upload-demo"
                :action="adminUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :on-success="upSuccessHuaWei"
                :headers="utils.upload_headers()"
                :show-file-list="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="小米渠道包" prop="c_xiaomi">
          <el-input v-model="form.c_xiaomi" placeholder="https://xxxxx">
            <template slot="append">
              <el-upload
                class="upload-demo"
                :action="adminUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :on-success="upSuccessXiaoMi"
                :headers="utils.upload_headers()"
                :show-file-list="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="腾讯渠道包" prop="c_yyb">
          <el-input v-model="form.c_yyb" placeholder="https://xxxxx">
            <template slot="append">
              <el-upload
                class="upload-demo"
                :action="adminUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :on-success="upSuccessYyb"
                :headers="utils.upload_headers()"
                :show-file-list="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="360渠道包" prop="c_360">
          <el-input v-model="form.c_360" placeholder="https://xxxxx">
            <template slot="append">
              <el-upload
                class="upload-demo"
                :action="adminUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :on-success="upSuccess360"
                :headers="utils.upload_headers()"
                :show-file-list="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="OPPO渠道包" prop="c_oppo">
          <el-input v-model="form.c_oppo" placeholder="https://xxxxx">
            <template slot="append">
              <el-upload
                class="upload-demo"
                :action="adminUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :on-success="upSuccessOppo"
                :headers="utils.upload_headers()"
                :show-file-list="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="VIVO渠道包" prop="c_vivo">
          <el-input v-model="form.c_vivo" placeholder="https://xxxxx">
            <template slot="append">
              <el-upload
                class="upload-demo"
                :action="adminUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :on-success="upSuccessVivo"
                :headers="utils.upload_headers()"
                :show-file-list="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="Google渠道包" prop="c_google">
          <el-input v-model="form.c_google" placeholder="https://xxxxx">
            <template slot="append">
              <el-upload
                class="upload-demo"
                :action="adminUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :on-success="upSuccessGoogle"
                :headers="utils.upload_headers()"
                :show-file-list="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="版本说明" prop="content">
          <!-- <tinymce v-if="dialog.visible" v-model="form.content" :height="200" class="content_edit" /> -->
          <el-input
            v-model="form.content"
            placeholder="更新说明"
            type="textarea"
            :show-word-limit="true"
            :rows="5"
            maxlength="999"
          />
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="应用市场上架" prop="app_market">
              <el-select v-model="form.app_market" multiple placeholder="请选择">
                <el-option label="华为" value="huawei" />
                <el-option label="小米" value="xiaomi" />
                <el-option label="腾讯" value="yyb" />
                <el-option label="360" value="360" />
                <el-option label="OPPO" value="oppo" />
                <el-option label="VIVO" value="vivo" />
                <el-option label="Google" value="google" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发布平台" prop="channel">
              <el-select v-model="form.channel" multiple placeholder="请选择">
                <el-option label="安卓" value="android" />
                <el-option label="苹果" value="ios" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发布时间" prop="release_time">
              <el-date-picker
                v-model="form.release_time"
                type="datetime"
                align="right"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
                placeholder="选择日期时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新模式" prop="type">
              <el-select v-model="form.type" placeholder="请选择">
                <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
        <el-button v-loading="loading.btnLoading" type="primary" @click="submit">
          {{ dialog.id ? '保存' : '新增' }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getVersion, addVersion, versionInfo, editVersion, deleteVersion } from '@/api/admin/version.js'
import Pagination from '@/components/Pagination' // 分页组件
import { deepClone } from '@/utils'
export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      dialog: { visible: false, id: '' },
      loading: { listLoading: false },
      form: {
        code: '',
        name: '',
        content: '',
        private_ip: '',
        channel: ['android', 'ios'],
        type: 'solicit',
        download_url: '',
        app_market: [],
        c_huawei: '',
        c_xiaomi: '',
        c_yyb: '',
        c_360: '',
        c_oppo: '',
        c_vivo: '',
        c_google: '',
        release_time: '',
        private_cid: ''
      },
      rules: {
        code: [{ required: true, message: '请输入版本号', trigger: ['blur', 'change'] }],
        name: [{ required: true, message: '请输入版本名称', trigger: ['blur', 'change'] }],
        content: [{ required: true, message: '请输入更新说明', trigger: ['blur', 'change'] }],
        type: [{ required: true, message: '请选择更新模式', trigger: ['blur', 'change'] }],
        download_url: [{ required: true, message: '请输入下载链接', trigger: ['blur', 'change'] }],
        channel: [{ required: true, message: '请选择发布平台', trigger: ['blur', 'change'] }]
      },
      typeList: [
        {
          value: 'solicit',
          label: '弹窗更新'
        },
        {
          value: 'forcibly',
          label: '强制更新'
        },
        {
          value: 'silent',
          label: '静默更新'
        },
        {
          value: 'click',
          label: '自主更新'
        },
        {
          value: 'none',
          label: '不更新'
        }
      ]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    typeFilter(status) {
      const typeMap = {
        solicit: '弹窗更新',
        forcibly: '强制更新',
        silent: '静默更新',
        click: '自主更新',
        none: '不更新'
      }
      return typeMap[status.type]
    },
    upSuccess(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'download_url', response.data.path)
    },
    upSuccessHuaWei(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'c_huawei', response.data.path)
    },
    upSuccessXiaoMi(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'c_xiaomi', response.data.path)
    },
    upSuccessYyb(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'c_yyb', response.data.path)
    },
    upSuccess360(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'c_360', response.data.path)
    },
    upSuccessOppo(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'c_oppo', response.data.path)
    },
    upSuccessVivo(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'c_vivo', response.data.path)
    },
    upSuccessGoogle(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'c_google', response.data.path)
    },
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getVersion(data).then(res => {
        this.tableData = res.data
        this.loading.listLoading = false
      })
    },
    valid_period(item) {
      var valid_period = JSON.parse(item.valid_period)
      const digital = valid_period['digital']
      const type = valid_period['type']
      var time = ''
      switch (type) {
        case 'month':
          time = '个月'
          break
        case 'day':
          time = '天'
          break
        case 'year':
          time = '年'
          break
        default:
          break
      }
      return digital + time
    },
    plan_delete(id) {
      deleteVersion(id).then(res => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    add_edit(id) {
      if (id) {
        versionInfo(id).then(res => {
          const { data } = res
          if (data.channel) {
            data.channel = data.channel.split(',')
          }
          this.form = data
        })
      } else {
        this.form = {
          code: '',
          name: '',
          content: '',
          private_ip: '',
          channel: ['android', 'ios'],
          type: 'solicit',
          download_url: '',
          app_market: [],
          c_huawei: '',
          c_xiaomi: '',
          c_yyb: '',
          c_360: '',
          c_oppo: '',
          c_vivo: '',
          c_google: '',
          release_time: '',
          private_cid: ''
        }
      }
      this.dialog.visible = true
      this.dialog.id = id
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var data = deepClone(this.form)
          data.channel = data.channel.join(',')
          if (this.dialog.id) {
            editVersion(this.dialog.id, data).then(res => {
              this.$message.success('更新成功')
              this.getList()
            })
          } else {
            addVersion(data).then(res => {
              this.$message.success('添加成功')
              this.getList()
            })
          }
          this.dialog.id = ''
          this.dialog.visible = false
        }
      })
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-input-group__append,
.el-input-group__prepend {
  width: 70px;
}
</style>
