var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "备注（选填）", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入内容",
                  rows: "5",
                  maxlength: "30",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("back", false)
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }