var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.btn_access("s_user_fianco_add"),
              expression: "btn_access('s_user_fianco_add')"
            }
          ],
          staticClass: "fr m-b-10",
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: {
            click: function($event) {
              return _vm.$router.push({
                name: "s_user_fianco_add",
                params: { id: _vm.userId }
              })
            }
          }
        },
        [_vm._v("新增")]
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData.list, stripe: "" } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "体测时间",
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "身高" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.content.height.value
                            ? row.content.height.value
                            : "--"
                        ) +
                        _vm._s(
                          row.content.height.value
                            ? row.content.height.unit
                            : ""
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "体重" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.content.weight.value
                            ? row.content.weight.value
                            : "--"
                        ) +
                        _vm._s(
                          row.content.weight.value
                            ? row.content.weight.unit
                            : ""
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "体脂率" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.content.bodyfat.value
                            ? row.content.bodyfat.value
                            : "--"
                        ) +
                        _vm._s(
                          row.content.bodyfat.value
                            ? row.content.bodyfat.unit
                            : ""
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "BMI" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.content.bmi.value ? row.content.bmi.value : "--"
                        ) +
                        _vm._s(
                          row.content.bmi.value ? row.content.bmi.unit : ""
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "体型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.content.shape.value
                            ? row.content.shape.value
                            : "--"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btn_access("s_user_side_info"),
                            expression: "btn_access('s_user_side_info')"
                          }
                        ],
                        staticClass: "m-r-10",
                        attrs: {
                          type: "primary",
                          icon: "el-icon-info",
                          offset: 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.fianco_info(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确定要删除该条体测记录吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.fianco_del(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.btn_access("s_user_side_del"),
                                expression: "btn_access('s_user_side_del')"
                              }
                            ],
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete",
                              offset: 2
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count>0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }