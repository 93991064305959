export default {
  route: {
    dashboard: '工作台',
    errorLog: '错误日志',
    profile: '个人中心',
    profileAdmin: '个人中心',

    adminSystem: '路由管理',
    adminCategory: '分类管理',
    adminWorkbench: '工作台',
    adminMerchants: '品牌管理',
    adminAccount: '账号管理',
    adminAccountList: '账号列表',
    adminRoleList: '角色列表',
    adminMenuList: '菜单列表',
    adminApiRouteList: '接口列表',
    adminList: '品牌列表',
    admin_change: '品牌变更记录',
    adminAppletRegistrationList: '小程序注册列表',
    adminMiniProgramList: '定制小程序列表',
    adminMiniProgram: '小程序管理',
    adminWeChatMiniProgram: '微信小程序',
    adminWeChatOfficialAccount: '微信公众号',
    adminWeChatPay: '微信支付',
    adminTikTok: '抖音/头条小程序',
    adminSignWeChat: '快速注册小程序',
    adminMerchantsApply: '申请列表',
    automated_import: '自动化导入',

    adminConfig: '系统配置',
    ConfigList: '配置列表',
    announcement: '系统公告',

    log: '系统日志',
    phoneJournal: '手机号修改日志',
    brand_funding_log: '品牌资金日志',
    operation_log: '操作日志',
    abnormal_log: '异常日志',

    feedback: '反馈处理',
    editAnnouncement: '编辑公告',
    AddAnnouncement: '添加公告',
    article: '帮助中心',
    Editarticle: '编辑文章',
    Addarticle: '添加文章',
    user: '用户',
    material: '媒体文件管理',

    package: '套餐管理',
    plan: '品牌套餐管理',
    sms_plan: '短信套餐管理',
    activityManagement: '预付定金活动',
    invite: '邀请好友详情',

    version: '系统版本管理',
    banner: '轮播图管理',
    sms_record: '短信发送记录',

    money_management: '资金管理',
    money_withdrawal_audit: '提现审核',
    money_invite_withdrawal: '拉新佣金审核',

    poster_template: '海报模版管理',
    poster_template_add: '海报模版添加',
    poster_template_edit: '海报模版编辑',
    material_management: '物料管理',
    code_management: '区号管理',
    course_label: '课程标签管理',

    order_management: '订单管理',
    order_package: '套餐充值订单',
    order_sms: '短信充值订单',
    order_orderList: '售卡订单列表',
    order_activity: '活动订单列表',

    s_sys_notice: '通知中心',
    s_sys_notice_info: '通知内容',
    s_setting: '系统设置',
    s_store_config: '系统配置',
    s_worktable: '工作台',
    s_notice: '公告管理',
    s_banner: '轮播图管理',
    s_qrcode: '二维码签到',
    s_management: '账号管理',
    s_management_list: '员工账号',
    s_management_edit: '员工编辑',
    s_management_add: '添加员工账号',
    s_management_role: '角色权限',
    s_management_role_add: '添加角色',
    s_management_role_edit: '编辑角色',
    s_venues: '场馆管理',
    s_venues_list: '场馆列表',
    s_venues_add: '添加场馆',
    s_venues_edit: '编辑场馆',
    s_venues_setting: '场馆设置',
    s_venues_holiday: '场馆放假',
    s_venues_classroom: '教室管理',
    s_venues_employee: '员工管理',
    s_venues_employee_info: '员工详情',
    s_venues_employee_edit: '员工编辑',
    s_venues_employee_add: '员工添加',
    s_add_employee: '添加员工',
    s_course: '课程管理',
    s_course_list: '全部课程',
    s_course_edit: '课程详情',
    s_course_curriculum: '课程表',
    s_course_add: '添加课程',

    s_mclass: '小班课',
    s_mclass_classmanagement: '班级管理',
    s_mclass_classmanagement_operate: '班级操作',
    s_mclass_classmanagement_check: '班级操作查看详情',
    s_mclass_classmanagement_signup: '报班',
    s_mclass_classmanagement_record: '课程记录',
    s_mclass_classmanagement_sales: '销售记录',
    s_mclass_coursemanagement: '班级课程管理',
    s_mclass_classschedule: '班课课程表',
    s_mclass_vipmanagement: '班课会员管理',
    s_mclass_datastatistics: '班课数据统计',

    s_membership: '会员卡管理',
    s_user_info: '会员信息',
    s_membership_card: '制作会员卡',
    s_combination_card: '组合卡套餐',
    s_membership_cardS: '多馆会员卡',
    s_user_vipcard_info: '会员卡信息',
    s_membership_card_add: '添加会员卡',
    s_membership_card_edit: '编辑会员卡',
    s_visitors: '访客',
    s_visitors_info: '访客详情',
    s_user: '会员管理',
    s_user_list: '会员',
    s_user_vipcard_import: '会员导入',
    s_user_vipcard: '会员卡',
    s_user_vipcard_change_record: '变更记录',
    s_user_vipcard_add: '发卡',
    s_user_birthday: '用户生日提醒',
    s_record: '综合查询',
    s_group_list: '团课预约记录',
    s_fine_list: '精品课预约记录',
    s_record_private_list: '私教预约记录',
    s_class_statistics: '课时费统计',
    s_ranking: '综合排行',
    s_asset_statistics: '资产统计',
    s_bill_details: '资金日志',
    s_card_total: '售卡统计',
    s_card_management_statistics: '卡管理统计',
    s_group_statisticList: '团课课时统计',
    s_private_statisticList: '私课课时统计',
    s_feedback_list: '反馈管理',

    s_comments: '评价管理',
    s_comments_list: '实时评价',
    s_commentsVirtual_list: '自定义评价',
    s_commentsVirtual_add: '添加评论',

    s_classfee_list: '课时费设置',
    s_log: '操作日志',
    s_background_log: '后台操作日志',
    s_orderRule: '预约设置',
    s_orderRuleForCommon: '通用设置',
    s_orderRuleForLeague: '团课设置',
    s_orderRuleForFine: '精品课设置',
    s_orderRuleForPrivate: '私教课设置',
    s_orderRuleSetting: '预约设置',
    s_orderRuleForCommonSetting: '通用设置',
    s_orderRuleForLeagueSetting: '团课设置',
    s_orderRuleForFineSetting: '精品课设置',
    s_orderRuleForPrivateSetting: '私教课设置',
    s_console_panel: '控制台',
    s_store_wxconfig: '微信端设置',
    s_data: '数据统计',
    s_worktable_remind: '今日消息提醒',
    s_user_card_validity: '会员卡有效期',
    s_user_souvenir: '入会纪念日',
    s_user_notclass: '多天未上课',
    s_user_card_balance: '会员卡余额',
    s_user_card_open: '开卡',
    s_user_card_leave_expire: '请假到期',
    s_side_template: '体测模版',
    s_user_fianco_edit: '编辑体测数据',
    s_user_fianco_add: '新增体测数据',
    s_remind_setting: '提醒设置',
    s_plan: '套餐购买',
    s_sms: '短信管理',
    s_sms_config: '自动发送短信配置',
    s_sms_log: '短信发送记录',
    s_sms_buy: '短信充值',
    s_sms_buy_log: '短信充值记录',
    s_store_wxconfig_template: '微信通知配置',
    s_seckill: '商城售卡',
    s_seckill_details: '查看详情',
    s_seckill_comment: '卡项评论',
    s_seckill_add: '添加售卡',
    s_seckill_edit: '编辑售卡',
    s_seckill_list: '商城',
    s_flashSale: '秒杀',
    s_flashSale_add: '添加秒杀',
    s_flashSale_edit: '编辑秒杀',
    s_sellingCards: '售卡',
    s_sellingCards_add: '添加售卡',
    s_sellingCards_edit: '编辑售卡',
    s_seckill_order: '订单管理',
    s_poster_generation: '海报生成',
    s_group_booking: '拼团活动',
    s_group_booking_add: '新增拼团活动',
    s_group_booking_edit: '编辑拼团活动',
    s_teamwork_activity_details: '拼团活动详情',
    s_offline_event: '活动报名',
    s_offline_event_add: '新增活动报名',
    s_offline_event_edit: '编辑活动报名',
    s_activity_signUp_details: '活动报名详情',
    s_poster_generation_edit: '海报编辑',
    s_contract: '电子合同',
    s_contract_add: '新增电子合同',
    s_contract_list: '会员列表',
    s_contract_template: '合同模板',
    s_withdraw: '提现记录',
    s_withdraw_apply: '提现申请',
    s_work_table: '',
    s_work_index: '',
    s_work_group: '团课预约记录',
    s_work_boutique: '精品课程预约记录',
    s_work_private: '私教预约记录',
    s_work_class: '班课签到',
    s_work_penalty: '爽约惩罚',
    s_work_locker: '储物柜',
    s_work_inquire: '客询管理',
    s_work_returnVisit: '回访管理',
    s_inquire_track: '客询回访',
    s_inquire_track_list: '客户回访记录',

    s_takeLeave: '请假管理',
    s_leaveManagement: '请假管理',
    s_leaveStatistics: '请假统计',
    s_takeLeave_edit: '请假详情',
    s_takeLeave_edits: '请假详情'
  },
  side_template: {
    basics: '基本体测数据',
    girth: '围度数据',
    modify: '肌肉脂肪数据',
    body_composition: '人体成分数据',
    healthy: '健康数据',
    custom: '自定义体测数据'
  },
  sms_template: {
    group_subscribe_success: '约团课成功提醒',
    group_queue_success: '候补排队成功短信提醒',
    group_subscribe_cancel: '取消团课完成提醒',
    group_open_before: '团课开课前提醒',
    group_sign_success: '团课签到提醒',
    private_subscribe_success: '约私教课成功提醒',
    private_queue_success: '私教课候补排队成功短信提醒',
    private_subscribe_cancel: '取消私教课完成提醒',
    private_open_before: '私教课开课前提醒',
    private_sign_success: '私教课签到提醒'
  },
  wechat_template: {
    group_subscribe_success: '约团课成功提醒',
    group_queue_success: '候补排队成功提醒',
    group_subscribe_cancel: '取消团课完成提醒',
    group_open_before: '团课开课前提醒',
    group_sign_success: '团课签到提醒',
    fine_subscribe_success: '约精品课成功提醒',
    fine_queue_success: '候补精品课排队成功提醒',
    fine_subscribe_cancel: '取消精品课完成提醒',
    fine_open_before: '精品课开课前提醒',
    fine_sign_success: '精品课签到提醒',
    private_subscribe_success: '约私教课成功提醒',
    private_queue_success: '私教课候补排队成功提醒',
    private_subscribe_cancel: '取消私教课完成提醒',
    private_open_before: '私教课开课前提醒',
    private_sign_success: '私教课签到提醒',
    issue_card: '发卡'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    phone: '修改手机号',
    resetPass: '修改密码',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: process.env.VUE_APP_TITLE,
    logIn: '立即登录',
    username: '账号',
    password: '密码',
    any: '随便填',
    thirdparty: '第三方登录',
    thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！'
  },
  documentation: {
    documentation: '文档',
    github: 'Github 地址'
  },
  permission: {
    addRole: '新增角色',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '确定',
    cancel: '取消'
  },
  guide: {
    description: '引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于',
    button: '打开引导'
  },
  components: {
    documentation: '文档',
    tinymceTips: '富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
    dropzoneTips: '由于我司业务有特殊需求，而且要传七牛 所以没用第三方，选择了自己封装。代码非常的简单，具体代码你可以在这里看到 @/components/Dropzone',
    stickyTips: '当页面滚动到预设的位置会吸附在顶部',
    backToTopTips1: '页面滚动到指定位置会在右下角出现返回顶部按钮',
    backToTopTips2: '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    imageUploadTips: '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。'
  },
  table: {
    dynamicTips1: '固定表头, 按照表头顺序排序',
    dynamicTips2: '不固定表头, 按照点击顺序排序',
    dragTips1: '默认顺序',
    dragTips2: '拖拽后顺序',
    title: '标题',
    importance: '重要性',
    type: '类型',
    remark: '点评',
    search: '搜索',
    add: '添加',
    export: '导出',
    reviewer: '审核人',
    id: '序号',
    date: '时间',
    author: '作者',
    readings: '阅读数',
    status: '状态',
    actions: '操作',
    edit: '编辑',
    publish: '发布',
    draft: '草稿',
    delete: '删除',
    cancel: '取 消',
    confirm: '确 定'
  },
  example: {
    warning: '创建和编辑页面是不能被 keep-alive 缓存的，因为keep-alive 的 include 目前不支持根据路由来缓存，所以目前都是基于 component name 来进行缓存的。如果你想类似的实现缓存效果，可以使用 localStorage 等浏览器缓存方案。或者不要使用 keep-alive 的 include，直接缓存所有页面。详情见'
  },
  errorLog: {
    tips: '请点击右上角bug小图标',
    description: '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '请输入文件名(默认excel-list)'
  },
  zip: {
    export: '导出',
    placeholder: '请输入文件名(默认file)'
  },
  pdf: {
    tips: '这里使用   window.print() 来实现下载pdf的功能'
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  }
}
