<template>
  <div v-loading="bgLoading" v-prevent-ctrl-scroll class="activity_bg">
    <!-- 版心 -->
    <div class="activity_center">
      <!-- 按钮 -->
      <!-- 活动未开始 -->
      <div v-if="activityStatusTime === 0" class="activity_button3 cursor">
        <img src="@/assets/images/activePage/but_main_notstarted.png" draggable="false" alt="">
      </div>
      <!-- 活动已结束 -->
      <div v-else-if="activityStatusTime === 2" class="activity_button3 cursor">
        <img src="@/assets/images/activePage/but_main_ended.png" draggable="false" alt="">
      </div>
      <!-- 6.18活动 -->
      <div v-else-if="activityStatusTime ===1 && activityStatus === 0" class="activity_button cursor" @click="handleActivity">
        <img src="@/assets/images/activePage/but_main_start.png" draggable="false" alt="">
        <div>{{ activityQuota > 100 ? '限时名额' : `仅剩${activityQuota}名` }}</div>
      </div>
      <!-- 活动未开始 -->
      <div v-else class="activity_button2 cursor" @click="handleMask">
        <img src="@/assets/images/activePage/but_main_end.png" draggable="false" alt="">
        <div>联系客服</div>
      </div>
      <!-- 轮播 -->
      <div class="activity_rotation">
        <div>
          <div v-for="(item, index) in activityOrders" :key="index" class="rotation">
            <!-- <img v-if="item.logo" :src="item.logo" alt="" draggable="false">
            <img v-else src="@/assets/images/poster/logo.png" alt="" draggable="false"> -->
            <div>{{ item.name }}</div>
            <div class="rotationGG">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <!-- 活动内容 -->
      <div class="activity_content">
        <div><img src="@/assets/images/activePage/img_ac_title.png" alt="" draggable="false"></div>
        <div>2023年6月8日-2023年6月18日</div>
        <div>在系统中支付<span>6.18</span>元即可抢占限时特惠名额，抢占成功后还可任选超值618活动福利。</div>
        <div>活动名额仅限前618名，先到先得。</div>
      </div>
      <!-- 活动套餐 -->
      <div class="activity_package">
        <div><img src="@/assets/images/activePage/img_tc_title.png" alt="" draggable="false"></div>
        <div>
          <div v-for="item in imgData" :key="item.id" class="cursor" @mouseover="handleMouseover(item.id)">
            <img :class="item.Select ? 'img_select' : ''" :src="item.Select ? item.imgSelect : item.img" alt="" draggable="false">
            <img v-if="item.id === 2" :class="item.Select ? 'img_subscript1' : 'img_subscript2'" src="@/assets/images/activePage/img_tc_subscript.png" alt="" draggable="false">
          </div>
        </div>
        <div>
          <img v-if="imgId === 1" src="@/assets/images/activePage/img_1year.png" alt="" draggable="false">
          <img v-else-if="imgId === 2" src="@/assets/images/activePage/img_3year.png" alt="" draggable="false">
          <img v-else-if="imgId === 3" src="@/assets/images/activePage/img_5year.png" alt="" draggable="false">
        </div>
      </div>
      <!-- 活动细则 -->
      <div class="activity_rule">
        <div><img src="@/assets/images/activePage/img_xz_title.png" alt="" draggable="false"></div>
        <div>
          1. 活动时间：2023年6月8日—2023年6月18日。<br>
          2. 每个品牌仅可选择购买一个套餐，不可重复参与。<br>
          3. 抢占名额成功后该订单将被锁定，不支持退款。<br>
          4. 选择了盲盒福利的馆主请提供正确的收货地址，因地址错误产生的额外物流费用需自行承担。<br>
          5. 瑜小九定制盲盒不允许兑换现金。<br>
          6. 现金抵扣福利在购买指定活动套餐时立即抵扣使用，使用期限为活动时间内，且不能与其他优惠同时使用。<br>
          7. 赠送短信用于提醒会员、老师上课相关信息，建立更好的服务体系，提高瑜伽馆的服务质量。短信使用完后需自行在APP内的【个人中心】选择套餐购买。<br>
          8. 在购买活动套餐后，系统将开放所有功能，且无需支付任何附加费用（除购买短信之外）。<br>
        </div>
      </div>
      <!-- 兑换方式 -->
      <div class="activity_exchange">
        <div><img src="@/assets/images/activePage/img_way_title.png" alt="" draggable="false"></div>
        <div>
          1. 成功购买套餐后系统使用时长和赠送短信立即生效，登录绑定账号即可使用。<br>
          2. 成功购买任意套餐后，请联系您的专属客服领取瑜伽馆金牌销售话术礼包。<br>
          3. 活动结束后按照订单顺序依次进行盲盒礼包的发货，礼包内容随机发货，均不支持自选和退换。<br>

          偏远地区不包邮：内蒙古、甘肃、青海、宁夏、新疆、西藏<br>
          以下地区不发货：港澳台地区、海外地区<br>
          本次活动指定快递：顺丰快递<br>
        </div>
      </div>
      <div class="YuXIaoJiu">*本次活动最终解释权归瑜小九所有</div>
    </div>
    <!-- 支付弹窗 -->
    <div v-if="activity_dialog" v-loading="divLoading" class="activity_dialog">
      <div>
        <div>订单支付</div>
        <i class="el-icon-close cursor" @click="handleClose(0)" />
      </div>
      <div>
        <div>订单号：</div>
        <div>{{ orderInfo.order.number }}</div>
      </div>
      <div>
        <div>流水号：</div>
        <div>{{ orderInfo.prepay_id }}</div>
      </div>
      <div>
        <div>支付金额：</div>
        <div>{{ orderInfo.order.pay_price }}元</div>
      </div>
      <div>
        <div>支付状态：</div>
        <div :style="{color: orderInfo.paid ? '#42d3b5' : '#fbb677'}">{{ orderInfo.paid ? '已支付' : '未支付' }}</div>
      </div>
      <div>
        <canvas ref="qrcode_ban" />
        <div class="pay-text flex flex-nowrap row-center m-t-10">
          <div><img src="@/assets/images/wxpay_icon.png" alt="" class="pay-icon" draggable="false"></div>
          <div class="m-l-10">微信支付</div>
        </div>
      </div>
    </div>
    <!-- 客服 -->
    <div v-if="customerService" class="customerService">
      <img :src="qrcode_src" alt="" draggable="false">
      <div class="cursor" @click="handleClose(1)">
        <i class="el-icon-close" />
      </div>
    </div>
    <!-- 蒙版 -->
    <div v-if="activity_mask" class="activity_mask" />
  </div>
</template>
<script>
// API
import { getActivityDetail, depositOrder, getOrderDetail } from '@/api/store/activity.js'
import { getVenuesService } from '@/api/store/venues.js'
// 二维码
import QRCode from 'qrcode'

export default {
  data() {
    return {
      imgId: 2,
      imgData: [
        { id: 1, img: require('@/assets/images/activePage/img_tc_01_nor.png'), imgSelect: require('@/assets/images/activePage/img_tc_01_sel.png'), Select: false },
        { id: 2, img: require('@/assets/images/activePage/img_tc_02_nor.png'), imgSelect: require('@/assets/images/activePage/img_tc_02_sel.png'), Select: true },
        { id: 3, img: require('@/assets/images/activePage/img_tc_03_nor.png'), imgSelect: require('@/assets/images/activePage/img_tc_03_sel.png'), Select: false }
      ],
      activity_dialog: false,
      customerService: false,
      activity_mask: false,
      activityStatus: '',
      activityOrders: [],
      activityStatusTime: 0,
      activityQuota: 0,
      orderInfo: {
        order: {}
      },
      divLoading: false,
      bgLoading: false,
      qrcode_src: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      const id = 1
      this.bgLoading = true
      getActivityDetail(id).then(res => {
        this.activityStatus = res.data.participation.status
        this.activityOrders = res.data.orders
        this.activityStatusTime = res.data.activity_status
        this.activityQuota = res.data.surplus_quota
        this.bgLoading = false
      })
    },
    // 点击抢占
    handleActivity() {
      this.activity_dialog = true
      this.activity_mask = true
      this.divLoading = true
      // 生成订单
      const data = {
        activity_id: 1,
        trade_type: 'NATIVE'
      }
      depositOrder(data).then(res => {
        const { data } = res
        this.orderInfo = data
        this.useqrcode('qrcode_ban', data.code_url)
        this.check_order()
        this.divLoading = false
      })
    },
    useqrcode(name, url) {
      const that = this
      const canvas = that.$refs[name]
      // 去掉qrcode的边框（建议留1px；否则浏览器识别有些困难）
      QRCode.toCanvas(canvas, url, { width: 180, height: 180, margin: 2 }, function(error) {
        if (error) console.error(error)
        that.$forceUpdate()
      })
    },
    check_order() {
      let check
      getOrderDetail(this.orderInfo.order.number).then(res => {
        // 切换页面后销毁轮询
        if (res.data.pay_status) {
          this.$set(this.orderInfo, 'paid', true)
          this.$message.success('支付成功')
          setTimeout(() => {
            location.reload()
          }, 1000)
          clearTimeout(check)
        } else {
          check = setTimeout(() => {
            this.check_order()
          }, 5000)
        }
      })
    },
    // 联系客服
    handleMask() {
      getVenuesService().then(res => {
        this.qrcode_src = res.data.qrcode_src
        this.activity_mask = true
        this.customerService = true
      })
    },
    // 套餐选项卡鼠标移入
    handleMouseover(id) {
      this.imgId = id
      this.imgData.forEach(item => {
        if (item.id === id) {
          item.Select = true
        } else {
          item.Select = false
        }
      })
    },
    // 关闭弹窗
    handleClose(num) {
      if (num === 0) {
        this.activity_dialog = false
        this.activity_mask = false
        // 刷新页面
        location.reload()
      } else if (num === 1) {
        this.activity_mask = false
        this.customerService = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  padding: none !important;
}
.activity_bg {
  width: 100%;
  height: 100%;
  min-height: 3480px;
  margin: 0 auto;
  overflow: hidden;
  background-image: url('~@/assets/images/activePage/img_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  .activity_center {
    width: 1240px;
    margin: 0 auto;
    .activity_button {
      width: 479px;
      margin: 777px auto 0;
      animation: myButton 1.4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      position: relative;
      img {
        display: block;
        width: 100%;
      }
      div {
        position: absolute;
        top: 0;
        right: 0;
        width: 125px;
        line-height: 43px;
        text-align: center;
        font-size: 22px;
        color: #FFFFFF;
        background: linear-gradient(-90deg, #FF8185, #f01d25cc);
        border-radius: 39px 39px 39px 0px;
      }
    }
    .activity_button:hover {
      opacity: 0.8;
    }
    /* 图片放大放小的动画 */
    @keyframes myButton {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1);
      }
    }
    .activity_button2 {
      width: 479px;
      margin: 777px auto 0;
      position: relative;
      img {
        display: block;
        width: 100%;
      }
      div {
        position: absolute;
        top: 0;
        right: 0;
        width: 125px;
        line-height: 43px;
        text-align: center;
        font-size: 22px;
        color: #FFFFFF;
        background: linear-gradient(-90deg, #FF8185, #F01D25);
        border-radius: 39px 39px 39px 0px;
        animation: myButton2 0.5s;
        animation-timing-function: linear;
        /* animation-iteration-count: infinite; */
      }
      @keyframes myButton2 {
        0% {
          transform: rotate(0deg);
        }
        10% {
          transform: rotate(15deg);
        }
        20% {
          transform: rotate(0deg);
        }
        30% {
          transform: rotate(-15deg);
        }
        40% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(10deg);
        }
        60% {
          transform: rotate(0deg);
        }
        70% {
          transform: rotate(-10deg);
        }
        80% {
          transform: rotate(0deg);
        }
        90% {
          transform: rotate(5deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    }
    .activity_button2:hover {
      opacity: 0.8;
    }
    .activity_button3 {
      width: 479px;
      margin: 777px auto 0;
      position: relative;
      img {
        display: block;
        width: 100%;
      }
      img:hover {
        opacity: 0.8;
      }
      div {
        position: absolute;
        top: 0;
        right: 0;
        width: 125px;
        line-height: 43px;
        text-align: center;
        font-size: 22px;
        color: #FFFFFF;
        background: linear-gradient(-90deg, #FF8185, #F01D25);
        border-radius: 39px 39px 39px 0px;
      }
    }
    /* 轮播 */
    .activity_rotation {
      width: 100%;
      height: 30px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 1px;
    }
    .activity_rotation > div:nth-child(1) {
      margin: 0px 11px 0 0;
      font-size: 20px;
      color: #FF5D8A;
      .rotation {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-40px);
        animation: myDiscountRotation 20s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        img {
          width: 26px;
          margin-right: 9px;
          border-radius: 50%;
        }
      }
      @keyframes myDiscountRotation {
        0% {
          transform: translateY(68px);
        }
        10% {
          transform: translateY(30px);
        }
        20% {
          transform: translateY(30px);
        }
        30% {
          transform: translateY(-6px);
        }
        40% {
          transform: translateY(-6px);
        }
        50% {
          transform: translateY(-42px);
        }
        60% {
          transform: translateY(-42px);
        }
        70% {
          transform: translateY(-77px);
        }
        80% {
          transform: translateY(-77px);
        }
        90% {
          transform: translateY(68px);
        }
        100% {
          transform: translateY(68px);
        }
      }
      .rotationGG {
        margin-left: 11px;
        font-size: 20px;
        color: #CEA3AC;
      }
    }
    /* 活动内容 */
    .activity_content {
      width: 1240px;
      height: 310px;
      margin-top: 20px;
      background-image: url('~@/assets/images/activePage/img_ac_bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
    }
    .activity_content > div:nth-child(1) {
      width: 270px;
      margin: 42px auto 0;
      img {
        display: block;
        width: 100%;
      }
    }
    .activity_content > div:nth-child(2) {
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #301408;
      margin-top: 28px;
      letter-spacing: 2px;
    }
    .activity_content > div:nth-child(3) {
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #301408;
      margin-top: 5px;
      letter-spacing: 2px;
      span {
        font-size: 34px;
        font-weight: bold;
        font-style: italic;
        color: #980D2C;
        background: linear-gradient(0deg, #E91A16 0%, #FC7924 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .activity_content > div:nth-child(4) {
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #301408;
      margin-top: 10px;
      letter-spacing: 2px;
      span {
        color: #F03B1BFF;
      }
    }
    /* 活动套餐 */
    .activity_package {
      width: 1240px;
      height: 1120px;
      background-image: url('~@/assets/images/activePage/img_tc_bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
    }
    .activity_package > div:nth-child(1) {
      width: 270px;
      margin: 42px auto 0;
      img {
        display: block;
        width: 100%;
      }
    }
    .activity_package > div:nth-child(2) {
      width: 1200px;
      margin: 60px auto 0;
      display: flex;
      justify-content: space-evenly;
      div {
        width: 280px;
        position: relative;
        img:nth-child(1) {
          display: block;
          width: 100%;
        }
        img:nth-child(1):hover {
          opacity: 0.8;
        }
        img:nth-child(2) {
          width: 80px;
          display: block;
        }
      }
      .img_select {
        transform: scale(1.1);
        top: -50px;
        right: -30px;
      }
      .img_subscript1 {
        transform: scale(1.1);
        position: absolute;
        top: -50px;
        right: -28px;
      }
      .img_subscript2 {
        transform: scale(0.8);
        position: absolute;
        top: -35px;
        right: -20px;
      }
    }
    .activity_package > div:nth-child(3) {
      width: 1014px;
      margin: 30px 0 0 117px;
      img {
        display: block;
        width: 100%;
      }
    }
    /* 活动细则 */
    .activity_rule {
      width: 1240px;
      height: 530px;
      background-image: url('~@/assets/images/activePage/img_xz_bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
    }
    .activity_rule > div:nth-child(1) {
      width: 270px;
      margin: 42px auto 0;
      img {
        display: block;
        width: 100%;
      }
    }
    .activity_rule > div:nth-child(2) {
      width: 1100px;
      height: 159px;
      font-size: 20px;
      font-weight: 500;
      color: #301408;
      line-height: 35px;
      margin: 32px 0 0 64px;
      letter-spacing: 2px;
    }
    /* 兑换方式 */
    .activity_exchange {
      width: 1240px;
      height: 410px;
      background-image: url('~@/assets/images/activePage/img_xz_bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
    }
    .activity_exchange > div:nth-child(1) {
      width: 270px;
      margin: 42px auto 0;
      img {
        display: block;
        width: 100%;
      }
    }
    .activity_exchange > div:nth-child(2) {
      width: 1100px;
      height: 159px;
      font-size: 20px;
      font-weight: 500;
      color: #301408;
      line-height: 35px;
      margin: 32px 0 0 64px;
      letter-spacing: 2px;
    }
    .YuXIaoJiu {
      width: 300px;
      height: 17px;
      font-size: 18px;
      font-weight: 500;
      color: #DB89BC;
      line-height: 70px;
      margin: 15px auto 0;
      text-align: center;
      letter-spacing: 1px;
    }
  }
  /* 弹窗 */
  .activity_dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 520px;
    background-image: url('~@/assets/images/activePage/img_pc_payment.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 999;
  }
  .activity_dialog > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    font-size: 22px;
    color: #000000;
    div {
      margin-left: 40px;
    }
    i {
      margin-right: 20px;
      color: #d59f91;
      font-size: 30px;
    }
  }
  .activity_dialog > div:nth-child(2),.activity_dialog > div:nth-child(3),.activity_dialog > div:nth-child(4),.activity_dialog > div:nth-child(5) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-size: 20px;
    color: #3f3e3f;
    div:nth-child(1) {
      margin-left: 40px;
    }
    div:nth-child(2) {
      margin-right: 35px;
      color: #8a8783;
    }
  }
  .activity_dialog > div:nth-child(2) {
    margin-top: 38px;
  }
  .activity_dialog > div:nth-child(4) {
    div:nth-child(2) {
      color: #ff5654;
    }
  }
  .activity_dialog > div:nth-child(6) {
    margin: 10px auto 20px;
    &,
    img {
      width: 180px;
      height: 180px;
      box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.42);
    }

    .pay-icon {
      width: 32px;
      height: 30px;
    }
  }
  /* 客服 */
  .customerService {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 514px;
    height: 617px;
    background-image: url('~@/assets/images/activePage/img_pc_popup.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 999;
    img {
      position: fixed;
      top: 175px;
      left: 98px;
      width: 300px;
    }
    div {
      position: fixed;
      bottom: -60px;
      left: 230px;
      width: 35px;
      height: 35px;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 22px;
        color: #FFFFFF;
      }
    }
  }
  /* 蒙版 */
  .activity_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.42);
  }
}
</style>
