import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken, setToken, setRefreshToken, getRefreshToken } from '@/utils/auth'
import { refreshToken } from '@/api/commom'
import Utils from '@/utils/utils.js'

// 创建一个axios实例
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: (process.env.NODE_ENV === 'development' ? `` : process.env.VUE_APP_BASE_API),
  // withCredentials: true, // 当跨域请求时发送cookie
  timeout: 100000 // request timeout
})

// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []

const AuthorizationHeader = 'Bearer '

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前执行某些操作
    if (store.getters.token) {
      // 让每个请求携带 token
      // ['X-Token'] is a custom headers key
      // 请根据实际情况修改
      config.headers['Authorization'] = AuthorizationHeader + getToken()
      config.headers['Venues-Id'] = Utils.getVenues() ? Utils.getVenues().id : 0
      config.headers['App-Id'] = localStorage.getItem('Appid') ? localStorage.getItem('Appid') : ''
      config.headers['version'] = process.env.VUE_APP_Version
      config.headers['is_pc'] = 1
      config.headers['Platform'] = 'PC'
    }
    config.data = requestFilter(config.data)
    return config
  },
  error => {
    // 对请求错误采取措施
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

/**
 * 请求参数过滤
 * @param {*} data
 * @returns
 */
function requestFilter(data) {
  if (data) {
    delete data['appid']
    return data
  }
}

// 响应拦截器
service.interceptors.response.use(
  /**
   * 如果您想要获取http信息，如标头或状态
   * 请返回响应=>响应
  */

  /**
   * 通过自定义代码确定请求状态
   * 这里只是一个例子
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // 如果自定义代码不是200，则判断为错误
    console.log('🚀 ~ file: request.js ~ line 59 ~ res.status', res.status)
    // 系统维护
    if (res.status === 410) {
      localStorage.setItem('410Msg', res.msg)
      window.location.href = '/410'
      return false
    }
    // 品牌禁止
    if (res.status === 601) {
      localStorage.setItem('601Msg', res.msg)
      store.dispatch('user/logout').then(() => {
        window.location.href = '/601'
      })
      return false
    }
    // 品牌过期
    if (res.status === 602) {
      localStorage.setItem('602Msg', res.msg)
      store.dispatch('user/logout').then(() => {
        window.location.href = '/602'
      })
      return false
    }
    // 升级品牌
    if (res.status === 603) {
      localStorage.setItem('603Msg', res.msg)
      // store.dispatch('user/logout').then(() => {
      //   window.location.href = '/603'
      // })
      return false
    }
    if (res.status === 408) {
      const config = response.config
      if (!isRefreshing) {
        isRefreshing = true
        const authority = localStorage.getItem('authority') ? localStorage.getItem('authority') : 'store'
        return refreshToken(authority, { token: getToken(), refresh_token: getRefreshToken() }).then(res => {
          const { token, refresh_token } = res.data
          // console.log('🚀 ~ file: request.js ~ line 66 ~ returnrefreshToken ~ token', token)
          setToken(token)
          setRefreshToken(refresh_token)
          config.headers['Authorization'] = AuthorizationHeader + token
          // 已经刷新了token，将所有队列中的请求进行重试
          requests.forEach(cb => cb(token))
          requests = []
          return service(config)
        }).catch(res => {
          console.error('refreshtoken error =>', res)
          store.dispatch('user/logout').then(() => {
            location.reload()
          })
          window.location.href = '/'
        }).finally(() => {
          isRefreshing = false
        })
      } else {
        // 正在刷新token，将返回一个未执行resolve的promise
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push((token) => {
            config.headers['Authorization'] = AuthorizationHeader + token
            resolve(service(config))
          })
        })
      }
    }
    if (res.status !== 200) {
      if (res.status === 701 || res.status === 702) return Promise.reject(res)
      if (res.status !== 401) {
        Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      // 50008: 非法 token; 50012: 其他已登录的客户端; 401: Token 过期的;
      if (res.status === 50008 || res.status === 50012 || res.status === 401) {
        // to re-login
        MessageBox.alert('您已经登出，您可以选择退出再次登录', '确认注销', {
          confirmButtonText: '重新登录',
          showClose: false
        }).then(() => {
          store.dispatch('user/logout').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
      // return false;
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
