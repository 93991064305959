<template>
  <div>
    <el-dialog
      id="list"
      append-to-body
      class="dialog_auto"
      :close-on-click-modal="false"
      :visible.sync="trackVisible"
      title="回访列表"
      :before-close="cancelDialog"
    >
      <div class="app-container">
        <div class="filter-container">
          <el-button type="primary" icon="el-icon-plus" class="fr m-b-10" @click="dialogVisible()">添加</el-button>
        </div>
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="tableData.list"
          row-key="id"
        >
          <el-table-column prop="content" label="回访内容" min-width="160px" show-overflow-tooltip />
          <el-table-column prop="create_time" label="回访时间" min-width="160px" />
        </el-table>
        <pagination v-show="tableData.count>0&&equipment" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
        <van-pagination
          v-show="tableData.count > 0 && !equipment"
          v-model="AccountPage"
          :total-items="tableData.count"
          :show-page-size="4"
          force-ellipses
          class="custom-pagination m-t-20"
          @change="handleAccountChange"
        />
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      title="添加回访"
      :class="equipment ? 'dialog_w580' : 'dialog_auto'"
    >
      <el-form ref="ruleForm" :model="form" :label-position="equipment ? 'right' : 'top'" :rules="rules" :inline="true" label-width="80px">
        <card :id="form.brand_id" :timer="timer" @cardId="cardId" />
        <el-form-item label="回访内容" prop="content">
          <el-input v-model="form.content" type="textarea" :rows="4" placeholder="请输入回访内容" :class="equipment ? 'dialog_w100' : 'dialog_w280'" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">确认</el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { addAppTrack, appTrackList } from '@/api/admin/merchants.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
// 组件
import card from '@/components/elSelect/capital.vue'

export default {
  name: 'VisitorTrack',
  components: { Pagination, card },
  props: {
    trackVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10
      },
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 弹窗
      dialog: {
        visible: false
      },
      // 数据
      form: {
        brand_id: '',
        content: ''
      },
      rules: ['content', 'brand_id'],
      loading: false,
      timer: '',
      equipment: false,
      AccountPage: 1
    }
  },
  watch: {
    id(newVal, oldVal) {
      this.listQuery = { page: 1, limit: 10 }
      this.loading = true
      this.getList()
      this.form.brand_id = newVal
      this.timer = new Date().getTime()
    }
  },
  created() {
    // 判断页面是否大于768
    if (document.body.clientWidth > 768) {
      this.equipment = true
    } else {
      this.equipment = false
    }
    this.rules = this.utils.validateFor(this.rules)
  },
  mounted() { },
  methods: {
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      appTrackList(this.id, data).then(res => {
        this.tableData = res.data
      }).catch(() => {}).finally(() => {
        this.loading = false
      })
    },
    dialogVisible() {
      this.dialog = { visible: true }
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // this.form['brand_id'] = this.id
          console.log('🚀 ~ file: index.vue ~ line 134 ~ addAppTrack ~ this.form', this.form)
          addAppTrack(this.form).then(res => {
            this.$message.success('添加成功')
            this.getList()
            this.$emit('back')
            this.dialog.visible = false
          }).catch(() => {})
        }
      })
    },
    cancelDialog() {
      this.$emit('update:trackVisible', false)
      // this.tableData = {
      // 	list:[],
      // 	count: 0,
      // };
    },
    cardId(val) {
      this.form.brand_id = val
    },
    handleAccountChange(val) {
      this.listQuery.page = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
	#list{
		::v-deep .el-dialog__body{
			padding: 0;
		}
		.app-container{
			padding: 10px 20px;
		}
	}
</style>
