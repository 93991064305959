<template>
  <div class="wx-config">
    <div class="wx-box">
      <div class="item">
        <div class="label text-success">品牌有微信公众号</div>
        <div class="content">
          <div class="tips">方式一：通过小程序约课。登录公众号平台 -> <a href="#relevance" class="text-primary">公众号关联小程序</a> -> 设置自定义菜单 -> 选择跳转小程序跳转路径为下方URL -> 备用网页可用方式二的网址 -> 保存发布</div>
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="">
              <el-input v-model="appidUrl" style="width:300px" :disabled="true" />
            </el-form-item>
            <el-form-item>
              <el-button v-clipboard:copy="appidUrl" v-clipboard:success="clipboardSuccess" type="primary" icon="el-icon-document">复制URL地址</el-button>
            </el-form-item>
          </el-form>
          <div class="tips">方式二：通过微信网页约课。 复制下方URL，去微信公众号里设置菜单，会员关注公众号，验证身份即可自主约课</div>
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="">
              <el-input v-model="appInfo.url" style="width:300px" :disabled="true" />
            </el-form-item>
            <el-form-item>
              <el-button v-clipboard:copy="appInfo.url" v-clipboard:success="clipboardSuccess" type="primary" icon="el-icon-document">复制URL地址</el-button>
            </el-form-item>
          </el-form>
          <div class="tips p-t-0">方式三： 保存约课二维码图片，在<span class="text-primary">公众号</span> -> <span class="text-primary">自定义菜单</span>中配置该图片，会员点击该菜单即可扫描约课二维码预约</div>
        </div>
      </div>
      <div class="item flex">
        <div class="flex-1">
          <div class="label text-warning">品牌没有微信公众号</div>
          <div class="content">
            <div class="tips">
              <div class="m-b-6">打开<span class="text-primary">微信</span> -> <span class="text-primary">扫描二维码</span>进入约课，点击右上角“…”分享给会员，会员点击即可查看课表并约课</div>
              <div class="text-danger m-b-6 tip">您也可以新建自己的微信公众号，注册地址: <a href="https://mp.weixin.qq.com" target="_blank" class="text-primary">https://mp.weixin.qq.com</a>，个人公众号（免费）、企业公众号（300元/年，由微信平台收取）</div>
            </div>
          </div>
        </div>
      </div>

      <div class="item">
        <div class="label text-info">约课二维码</div>
        <div class="content">
          <div class="text-center">
            <template>
              <el-skeleton :loading="(!appInfo.wechat_open_path)" animated>
                <template slot="template">
                  <div class="flex">
                    <div class="m-r-10">
                      <el-skeleton-item variant="image" style="width: 180px; height: 180px;" />
                      <div style="padding: 14px;">
                        <div
                          style="display: flex; align-items: center; justify-items: space-between;"
                        >
                          <el-skeleton-item variant="text" />
                        </div>
                      </div>
                    </div>
                    <div class="m-r-10">
                      <el-skeleton-item variant="image" style="width: 180px; height: 180px;" />
                      <div style="padding: 14px;">
                        <div
                          style="display: flex; align-items: center; justify-items: space-between;"
                        >
                          <el-skeleton-item variant="text" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="flex row-around flex-wrap">
                  <div class="m-r-10">
                    <div ref="wechat_open_path" class="image_box"><el-image :preview-src-list="[appInfo.wechat_open_path+'?'+new Date().getTime()]" :src="appInfo.wechat_open_path+'?'+new Date().getTime()" alt="" class="yueke_image" /></div>
                    <el-link icon="el-icon-download" class="image_down" type="primary" @click="downloadCodeImg('wechat_open_path')">点击下载二维码</el-link>
                  </div>
                  <div class="m-r-10">
                    <div ref="program_open_path" class="image_box"><el-image :preview-src-list="[appInfo.program_open_path+'?'+new Date().getTime()]" :src="appInfo.program_open_path+'?'+new Date().getTime()" alt="" class="yueke_image" /></div>
                    <el-link icon="el-icon-download" class="image_down" type="primary" @click="downloadCodeImg('program_open_path')">点击下载二维码</el-link>
                  </div>
                </div>
              </el-skeleton>
            </template>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import clipboard from '@/directive/clipboard/index.js'
export default {
  name: 'WxGuide',
  directives: { clipboard },
  data() {
    return {
      appInfo: this.utils.getApp(),
      appidUrl: 'pages/index/index?appid=' + (localStorage.getItem('Appid') ? localStorage.getItem('Appid') : '').toString()
    }
  },
  mounted() {
  },
  methods: {
    clipboardSuccess() {
      this.$message({
        message: '复制成功',
        type: 'success',
        duration: 1500
      })
    },

    kssstart(name, file_name) {
      // mymap你要截取的元素 也可以是body  整个页面  **
      const canvasID = this.$refs[name]
      const that = this
      const a = document.createElement('a')
      html2canvas(canvasID, { useCORS: true, scrollY: 0, removeContainer: false }).then(canvas => {
        const dom = document.body.appendChild(canvas)
        dom.style.display = 'none'
        a.style.display = 'none'
        document.body.removeChild(dom)
        const blob = that.dataURLToBlob(dom.toDataURL('image/jpg'))
        a.setAttribute('href', URL.createObjectURL(blob))
        a.setAttribute('download', file_name)
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(blob)
        document.body.removeChild(a)
      })
    },
    dataURLToBlob(dataurl) { // ie 图片转格式
      var arr = dataurl.split(','); var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1]); var n = bstr.length; var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    downloadCodeImg(name) {
      // const link = document.createElement('a')
      var file_name; var url = ''
      if (name === 'wechat_open_path') {
        url = this.appInfo.wechat_open_path // codeIMG  要下载的路径
        file_name = this.appInfo.name + '_品牌公众号二维码.jpg'
      } else if (name === 'program_open_path') {
        url = this.appInfo.program_open_path // codeIMG  要下载的路径
        file_name = this.appInfo.name + '_品牌小程序二维码.jpg'
      } else {
        url = ''
      }
      // this.utils.handleDownload({ url: '/store/v1/download?filePath=' + url, fileName: file_name, baseURL: this.baseUrl })
      // this.kssstart(name, file_name)
      this.utils.downloadIamge(url, file_name)
      // // 这里是将url转成blob地址，
      // fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
      //   link.href = URL.createObjectURL(blob)
      //   console.log(link.href)
      //   link.download = file_name
      //   document.body.appendChild(link)
      //   link.click()
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.wx-config{
  .wx-box{
    border:1px solid #d9d9d9;
    border-radius: 4px;
    padding-bottom: 20px;
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .item{
      .image_down{
        padding-top: 5px;
      }
      .yueke_image{
        display: block;
        width: 180px;
      }
    }
    // margin: 0 40px;
    .label{
      width: 160px;
      height: 40px;
      background-color: #efeff2;
      padding: 10px;
      text-align: center;
    }
    .content{
      margin: 0 40px;
      padding: 15px 0;
    }
    .tips{
    color: #787878;

    font-size: 12px;
    line-height: 1.5;
    padding-top: 10px;
    margin-bottom: 10px;
    }
  }

}
</style>
