import request from '@/utils/request'
// import Utils from '@/utils/utils.js'

// 获取配置分类列表
export function getConfigList(group) {
  return request({
    url: '/store/v1/config_classify',
    method: 'get',
    params: {
      group
    }
  })
}

// 获取字段列表
export function getFieldList(id) {
  return request({
    url: `/store/v1/config/classify_id/${id}`,
    method: 'get'
  })
}
// 获取配置详情
export function getFieldInfo(field) {
  return request({
    url: `/store/v1/config/${field}`,
    method: 'get'
  })
}
// 保存Field
export function saveConfig(data) {
  return request({
    url: '/store/v1/config',
    method: 'post',
    data
  })
}

// 获取系统配置
export function getSystem() {
  return request({
    url: `/store/v1/system`,
    method: 'get'
  })
}

// 获取指定名称资源图
export function getImg(name) {
  return request({
    url: `/store/v2/file/resources/name/${name}`,
    method: 'get'
  })
}

// 获取图片
export function getImgs(id) {
  return request({
    url: `/store/v2/store_user/photo/${id}`,
    method: 'get'
  })
}

// 删除员工相册
export function deleteImges(id) {
  return request({
    url: `/store/v2/store_user/photo/${id}`,
    method: 'delete'
  })
}

// 员工相册排序
export function staffSort(store_user_id, data) {
  return request({
    url: `/store/v2/store_user/photo/${store_user_id}/sort`,
    method: 'put',
    data
  })
}

