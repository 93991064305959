var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleFilter },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "全部", name: "-1" } }),
              _c("el-tab-pane", {
                attrs: { name: "0" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("div", [
                          _vm._v(
                            "待签署（" +
                              _vm._s(
                                _vm.tableData.statistic[0]
                                  ? _vm.tableData.statistic[0].status_count
                                  : "0"
                              ) +
                              "）"
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("el-tab-pane", {
                attrs: { name: "1" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("div", [
                          _vm._v(
                            "已签署（" +
                              _vm._s(
                                _vm.tableData.statistic[1]
                                  ? _vm.tableData.statistic[1].status_count
                                  : "0"
                              ) +
                              "）"
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("el-tab-pane", {
                attrs: { name: "2" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("div", [
                          _vm._v(
                            "逾期未签（" +
                              _vm._s(
                                _vm.tableData.statistic[2]
                                  ? _vm.tableData.statistic[2].status_count
                                  : "0"
                              ) +
                              "）"
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("el-tab-pane", {
                attrs: { name: "3" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("div", [
                          _vm._v(
                            "合同到期（" +
                              _vm._s(
                                _vm.tableData.statistic[3]
                                  ? _vm.tableData.statistic[3].status_count
                                  : "0"
                              ) +
                              "）"
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("el-tab-pane", {
                attrs: { name: "4" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("div", [
                          _vm._v(
                            "作废（" +
                              _vm._s(
                                _vm.tableData.statistic[4]
                                  ? _vm.tableData.statistic[4].status_count
                                  : "0"
                              ) +
                              "）"
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("el-tab-pane", {
                attrs: { name: "5" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c("div", [
                          _vm._v(
                            "待添加签署人（" +
                              _vm._s(
                                _vm.tableData.statistic[5]
                                  ? _vm.tableData.statistic[5].status_count
                                  : "0"
                              ) +
                              "）"
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "searchBox" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    inline: true,
                    model: _vm.listQueryParams,
                    "label-width": "100px",
                    "label-position": "left",
                    "label-suffix": "："
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "筛选条件" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQueryParams.filter,
                            callback: function($$v) {
                              _vm.$set(_vm.listQueryParams, "filter", $$v)
                            },
                            expression: "listQueryParams.filter"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "标签", value: "tag" }
                          }),
                          _c("el-option", {
                            attrs: { label: "状态", value: "status" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        filterable: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.listQueryParams.order,
                        callback: function($$v) {
                          _vm.$set(_vm.listQueryParams, "order", $$v)
                        },
                        expression: "listQueryParams.order"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label:
                            _vm.listQueryParams.filter === "tag"
                              ? "按标签（升序）排序"
                              : "按状态（升序）排序",
                          value: "asc"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label:
                            _vm.listQueryParams.filter === "tag"
                              ? "按标签（降序）排序"
                              : "按状态（降序）排序",
                          value: "desc"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m-l-20",
                      attrs: {
                        icon: "el-icon-search",
                        loading: _vm.searchLoading,
                        type: "success"
                      },
                      on: { click: _vm.handleFilter }
                    },
                    [_vm._v(" 搜索 ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "260px" },
                  attrs: { placeholder: "搜索 合同名称 | 会员名 | 手机号" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleFilter($event)
                    }
                  },
                  model: {
                    value: _vm.listQueryParams.name,
                    callback: function($$v) {
                      _vm.$set(_vm.listQueryParams, "name", $$v)
                    },
                    expression: "listQueryParams.name"
                  }
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      loading: _vm.searchLoading
                    },
                    on: { click: _vm.handleFilter },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "router-link",
            { attrs: { to: { name: "s_contract_add", params: { type: 1 } } } },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item m-l-10",
                  attrs: { type: "primary", icon: "el-icon-document-add" }
                },
                [_vm._v(" 添加合同 ")]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item m-l-10",
              attrs: { type: "info", icon: "el-icon-setting" },
              on: { click: _vm.handleSettings }
            },
            [_vm._v(" 合同设置 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          ref: "multipleTable",
          attrs: { data: _vm.tableData.list, "row-key": "id", fit: true }
        },
        [
          _c("el-table-column", { attrs: { label: "合同名称", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "签署人" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.user
                      ? _c("div", { staticClass: "flex col-center" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-avatar",
                                {
                                  attrs: {
                                    shape: "square",
                                    size: 50,
                                    src: row.user.avatar
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: _vm.errorMemberSrc() }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "p-l-10" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.getUserNames(row.user)))
                            ]),
                            _c("br"),
                            _c("span", [_vm._v(_vm._s(row.user.phone))])
                          ])
                        ])
                      : _c("div", { staticClass: "text-danger" }, [
                          _vm._v("待添加签署人")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: _vm.status[row.status].type } },
                      [_vm._v(_vm._s(_vm.status[row.status].label))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.create_time.slice(0, 16)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "m-2",
                        on: {
                          click: function($event) {
                            return _vm.handleDetails(row.id, row.status)
                          }
                        }
                      },
                      [_vm._v("详细信息")]
                    ),
                    row.status == 0 || row.status == 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleVoid(row.id)
                              }
                            }
                          },
                          [_vm._v(" 合同作废 ")]
                        )
                      : _vm._e(),
                    row.status == 4
                      ? _c(
                          "el-button",
                          {
                            staticClass: "m-2",
                            attrs: { type: "warning" },
                            on: {
                              click: function($event) {
                                return _vm.handleRevokeVoid(row.id)
                              }
                            }
                          },
                          [_vm._v(" 撤销作废 ")]
                        )
                      : _vm._e(),
                    row.status == 5
                      ? _c(
                          "el-dropdown",
                          [
                            _c("el-button", { attrs: { type: "info" } }, [
                              _vm._v(" 更多 "),
                              _c("i", {
                                staticClass: "el-icon-arrow-down el-icon--right"
                              })
                            ]),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.handleEditContract(row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑合同")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.handleChangeName(
                                          row.id,
                                          row.name
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("重命名")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.handleDelete(row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("删除合同")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.handleInitiateSign(row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("发起签署")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count > 0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c("detailsDialog", {
        attrs: {
          "dialog-visible-contract": _vm.dialogVisibleContract,
          "contract-id": _vm.contractId,
          "contract-status": _vm.contractStatus
        },
        on: {
          closeVisible: function($event) {
            _vm.dialogVisibleContract = false
          }
        }
      }),
      _c("settingsDialog", {
        attrs: { "dialog-visible-settings": _vm.dialogVisibleSettings },
        on: { closeVisibleSettings: _vm.closeVisibleSettings }
      }),
      _c("editDialog", {
        attrs: {
          "dialog-visible-edit-contract": _vm.dialogVisibleEditContract,
          "edit-contract-id": _vm.editContractId,
          "edit-type": _vm.editType
        },
        on: { editContractVisible: _vm.editContractVisible }
      }),
      _c("initiateSignDialog", {
        attrs: {
          "dialog-visible-initiate-sign": _vm.dialogVisibleInitiateSign,
          "edit-initiate-sign-id": _vm.editInitiateSignId
        },
        on: { editInitiateSignVisible: _vm.editInitiateSignVisible }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }