var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  _vm.showAddFrom = true
                }
              }
            },
            [_vm._v(" 添加配置 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "multipleTable",
          attrs: { data: _vm.tableData.list, "row-key": "id" }
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "配置名称" } }),
          _c("el-table-column", {
            attrs: { prop: "field", label: "配置字段" }
          }),
          _c("el-table-column", { attrs: { prop: "type", label: "字段类型" } }),
          _c("el-table-column", { attrs: { prop: "info", label: "配置简介" } }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-edit",
                          offset: 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.FieldDetails(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        staticClass: "m-l-10",
                        attrs: { title: "确定删除吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.deleteField(scope.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete",
                              offset: 2
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tableData.count > 0,
            expression: "tableData.count>0"
          }
        ],
        attrs: {
          total: _vm.tableData.count,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加配置字段",
            visible: _vm.showAddFrom,
            width: "600",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.showAddFrom = $event
            }
          }
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeType,
                callback: function($$v) {
                  _vm.activeType = $$v
                },
                expression: "activeType"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "文本框", name: "text" } },
                [
                  _c("form-create", {
                    ref: "fc",
                    staticClass: "formBox",
                    attrs: { rule: _vm.rules.text, "handle-icon": "false" },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "多行文本框", name: "textarea" } },
                [
                  _c("form-create", {
                    ref: "fc",
                    staticClass: "formBox",
                    attrs: { rule: _vm.rules.textarea, "handle-icon": "false" },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "单选框", name: "radio" } },
                [
                  _c("form-create", {
                    ref: "fc",
                    staticClass: "formBox",
                    attrs: { rule: _vm.rules.radio, "handle-icon": "false" },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "文件上传", name: "upload" } },
                [
                  _c("form-create", {
                    ref: "fc",
                    staticClass: "formBox",
                    attrs: { rule: _vm.rules.upload, "handle-icon": "false" },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "多选框", name: "checkbox" } },
                [
                  _c("form-create", {
                    ref: "fc",
                    staticClass: "formBox",
                    attrs: { rule: _vm.rules.checkbox, "handle-icon": "false" },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "下拉框", name: "select" } },
                [
                  _c("form-create", {
                    ref: "fc",
                    staticClass: "formBox",
                    attrs: { rule: _vm.rules.select, "handle-icon": "false" },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "富文本", name: "editor" } },
                [
                  _c("form-create", {
                    ref: "fc",
                    staticClass: "formBox",
                    attrs: { rule: _vm.rules.editor, "handle-icon": "false" },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "数组", name: "group" } },
                [
                  _c("form-create", {
                    ref: "fc",
                    staticClass: "formBox",
                    attrs: { rule: _vm.rules.group, "handle-icon": "false" },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("edit-from", {
        ref: "edits",
        attrs: { "from-data": _vm.FromData },
        on: { onSubmit: _vm.setConfig }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }