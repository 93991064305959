import request from '@/utils/request'

// 拼团活动列表
export function getTeamworkList(id, params) {
  return request({
    url: `/store/v1/group_book?status=${id}`,
    method: 'get',
    params
  })
}

// 添加编辑活动
export function addTeamwork(data) {
  return request({
    url: `/store/v1/group_book`,
    method: 'post',
    data
  })
}

// 拼团活动详情
export function getTeamworkDetails(id) {
  return request({
    url: `/store/v1/group_book/${id}`,
    method: 'get'
  })
}

// 活动停止/启动
export function stopTeamwork(id, type) {
  return request({
    url: `/store/v1/group_book/${id}/${type}`,
    method: 'put'
  })
}

// 更行活动
export function updateTeamwork(id, data) {
  return request({
    url: `/store/v1/group_book/${id}`,
    method: 'put',
    data
  })
}

// 团队列表
export function getTeamList(activity_id, params) {
  return request({
    url: `/store/v1/group_book/team/${activity_id}`,
    method: 'get',
    params
  })
}

// 团队详情
export function getTeamDetails(activity_id, team_id) {
  return request({
    url: `/store/v1/group_book/team/${activity_id}/${team_id}`,
    method: 'get'
  })
}
