var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container special-center" }, [
    _c(
      "div",
      { staticClass: "userInfo" },
      [
        _c(
          "el-form",
          { ref: "form", attrs: { model: _vm.form, "label-width": "140px" } },
          [
            _c(
              "el-descriptions",
              {
                staticClass: "p-t-10 m-b-20",
                attrs: {
                  direction: "vertical",
                  column: 3,
                  border: "",
                  "label-style": { textAlign: "center" },
                  "content-style": { textAlign: "center" }
                }
              },
              [
                _c("el-descriptions-item", { attrs: { label: "姓名" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.userInfo.real_name ? _vm.userInfo.real_name : "--"
                    )
                  )
                ]),
                _c("el-descriptions-item", { attrs: { label: "昵称" } }, [
                  _vm._v(_vm._s(_vm.userInfo.nickname))
                ]),
                _c(
                  "el-descriptions-item",
                  { attrs: { label: "手机号", span: 2 } },
                  [_vm._v(_vm._s(_vm.userInfo.phone))]
                )
              ],
              1
            ),
            _vm._l(_vm.templateList, function(item, index) {
              return _c("div", { key: index }, [
                _vm.isShow[index]
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "p-y-5 p-x-10 title template_group" },
                        [
                          _vm._v(_vm._s(_vm.$t("side_template." + index))),
                          index == "custom"
                            ? _c(
                                "span",
                                { staticClass: "text-danger m-l-10" },
                                [
                                  _vm._v(
                                    "（在系统设置-体测表模版设置中可以添加）"
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap" },
                        _vm._l(item, function(v, i) {
                          return _c(
                            "div",
                            { key: i },
                            [
                              v.en_name == "shape" && v.status
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "m-b-10",
                                      attrs: { label: v.name }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-250",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.form[v.id],
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, v.id, $$v)
                                            },
                                            expression: "form[v.id]"
                                          }
                                        },
                                        _vm._l(JSON.parse(v.unit), function(
                                          it
                                        ) {
                                          return _c("el-option", {
                                            key: it,
                                            attrs: { label: it, value: it }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              v.en_name != "shape" && v.status
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "m-b-10",
                                      attrs: {
                                        label:
                                          v.name +
                                          (v.unit ? "(" + v.unit + ")" : "")
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "w-250",
                                        model: {
                                          value: _vm.form[v.id],
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, v.id, $$v)
                                          },
                                          expression: "form[v.id]"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ])
            }),
            _c(
              "el-form-item",
              { attrs: { label: "体测照片" } },
              [
                _c(
                  "el-upload",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.upLoading,
                        expression: "upLoading"
                      }
                    ],
                    attrs: {
                      action: _vm.storeUpload,
                      "list-type": "picture-card",
                      "on-preview": _vm.handlePreview,
                      "on-remove": _vm.handleRemove,
                      "on-success": _vm.upSuccess,
                      headers: _vm.utils.upload_headers(),
                      "on-progress": _vm.upProgress,
                      accept: ".jpg,.jpeg,.png"
                    }
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _c(
                  "el-dialog",
                  {
                    staticClass: "dialog_view_image",
                    attrs: { visible: _vm.dialogVisible },
                    on: {
                      "update:visible": function($event) {
                        _vm.dialogVisible = $event
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
                    })
                  ]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "体测时间" } },
              [
                _c("el-date-picker", {
                  staticClass: "w-250",
                  attrs: {
                    type: "date",
                    placeholder: "选择日期",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd"
                  },
                  model: {
                    value: _vm.form.create_time,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "create_time", $$v)
                    },
                    expression: "form.create_time"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "指导建议" } },
              [
                _c("el-input", {
                  attrs: { type: "textarea", rows: "5" },
                  model: {
                    value: _vm.form.proposal,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "proposal", $$v)
                    },
                    expression: "form.proposal"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                  [_vm._v("提交")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.returnPage()
                      }
                    }
                  },
                  [_vm._v("取消")]
                )
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }