<template>
  <div class="iconBox">
    <el-input ref="search" v-model="iconVal" placeholder="输入关键词搜索,注意全是英文" @keyup.enter.native="upIcon(iconVal)">
      <el-button slot="append" icon="el-icon-search" @click="upIcon(iconVal)" />
    </el-input>
    <div class="trees-coadd">
      <div class="scollhide">
        <div class="trees">
          <ul class="list-inline">
            <li v-for="(item,i) in list" :key="i" class="icons-item">
              <i :class="item.type" @click="iconChange(item.type)" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      iconVal: '',
      list: [],
      search: [
        { 'type': 'el-icon-platform-eleme' },
        { 'type': 'el-icon-eleme' },
        { 'type': 'el-icon-delete-solid' },
        { 'type': 'el-icon-delete' },
        { 'type': 'el-icon-s-tools' },
        { 'type': 'el-icon-setting' },
        { 'type': 'el-icon-user-solid' },
        { 'type': 'el-icon-user' },
        { 'type': 'el-icon-phone' },
        { 'type': 'el-icon-phone-outline' },
        { 'type': 'el-icon-more' },
        { 'type': 'el-icon-more-outline' },
        { 'type': 'el-icon-star-on' },
        { 'type': 'el-icon-star-off' },
        { 'type': 'el-icon-s-goods' },
        { 'type': 'el-icon-goods' },
        { 'type': 'el-icon-warning' },
        { 'type': 'el-icon-warning-outline' },
        { 'type': 'el-icon-question' },
        { 'type': 'el-icon-info' },
        { 'type': 'el-icon-remove' },
        { 'type': 'el-icon-circle-plus' },
        { 'type': 'el-icon-success' },
        { 'type': 'el-icon-error' },
        { 'type': 'el-icon-zoom-in' },
        { 'type': 'el-icon-zoom-out' },
        { 'type': 'el-icon-remove-outline' },
        { 'type': 'el-icon-circle-plus-outline' },
        { 'type': 'el-icon-circle-check' },
        { 'type': 'el-icon-circle-close' },
        { 'type': 'el-icon-s-help' },
        { 'type': 'el-icon-help' },
        { 'type': 'el-icon-minus' },
        { 'type': 'el-icon-plus' },
        { 'type': 'el-icon-check' },
        { 'type': 'el-icon-close' },
        { 'type': 'el-icon-picture' },
        { 'type': 'el-icon-picture-outline' },
        { 'type': 'el-icon-picture-outline-round' },
        { 'type': 'el-icon-upload' },
        { 'type': 'el-icon-upload2' },
        { 'type': 'el-icon-download' },
        { 'type': 'el-icon-camera-solid' },
        { 'type': 'el-icon-camera' },
        { 'type': 'el-icon-video-camera-solid' },
        { 'type': 'el-icon-video-camera' },
        { 'type': 'el-icon-message-solid' },
        { 'type': 'el-icon-bell' },
        { 'type': 'el-icon-s-cooperation' },
        { 'type': 'el-icon-s-order' },
        { 'type': 'el-icon-s-platform' },
        { 'type': 'el-icon-s-fold' },
        { 'type': 'el-icon-s-unfold' },
        { 'type': 'el-icon-s-operation' },
        { 'type': 'el-icon-s-promotion' },
        { 'type': 'el-icon-s-home' },
        { 'type': 'el-icon-s-release' },
        { 'type': 'el-icon-s-ticket' },
        { 'type': 'el-icon-s-management' },
        { 'type': 'el-icon-s-open' },
        { 'type': 'el-icon-s-shop' },
        { 'type': 'el-icon-s-marketing' },
        { 'type': 'el-icon-s-flag' },
        { 'type': 'el-icon-s-comment' },
        { 'type': 'el-icon-s-finance' },
        { 'type': 'el-icon-s-claim' },
        { 'type': 'el-icon-s-custom' },
        { 'type': 'el-icon-s-opportunity' },
        { 'type': 'el-icon-s-data' },
        { 'type': 'el-icon-s-check' },
        { 'type': 'el-icon-s-grid' },
        { 'type': 'el-icon-menu' },
        { 'type': 'el-icon-share' },
        { 'type': 'el-icon-d-caret' },
        { 'type': 'el-icon-caret-left' },
        { 'type': 'el-icon-caret-right' },
        { 'type': 'el-icon-caret-bottom' },
        { 'type': 'el-icon-caret-top' },
        { 'type': 'el-icon-bottom-left' },
        { 'type': 'el-icon-bottom-right' },
        { 'type': 'el-icon-back' },
        { 'type': 'el-icon-right' },
        { 'type': 'el-icon-bottom' },
        { 'type': 'el-icon-top' },
        { 'type': 'el-icon-top-left' },
        { 'type': 'el-icon-top-right' },
        { 'type': 'el-icon-arrow-left' },
        { 'type': 'el-icon-arrow-right' },
        { 'type': 'el-icon-arrow-down' },
        { 'type': 'el-icon-arrow-up' },
        { 'type': 'el-icon-d-arrow-left' },
        { 'type': 'el-icon-d-arrow-right' },
        { 'type': 'el-icon-video-pause' },
        { 'type': 'el-icon-video-play' },
        { 'type': 'el-icon-refresh' },
        { 'type': 'el-icon-refresh-right' },
        { 'type': 'el-icon-refresh-left' },
        { 'type': 'el-icon-finished' },
        { 'type': 'el-icon-sort' },
        { 'type': 'el-icon-sort-up' },
        { 'type': 'el-icon-sort-down' },
        { 'type': 'el-icon-rank' },
        { 'type': 'el-icon-loading' },
        { 'type': 'el-icon-view' },
        { 'type': 'el-icon-c-scale-to-original' },
        { 'type': 'el-icon-date' },
        { 'type': 'el-icon-edit' },
        { 'type': 'el-icon-edit-outline' },
        { 'type': 'el-icon-folder' },
        { 'type': 'el-icon-folder-opened' },
        { 'type': 'el-icon-folder-add' },
        { 'type': 'el-icon-folder-remove' },
        { 'type': 'el-icon-folder-delete' },
        { 'type': 'el-icon-folder-checked' },
        { 'type': 'el-icon-tickets' },
        { 'type': 'el-icon-document-remove' },
        { 'type': 'el-icon-document-delete' },
        { 'type': 'el-icon-document-copy' },
        { 'type': 'el-icon-document-checked' },
        { 'type': 'el-icon-document' },
        { 'type': 'el-icon-document-add' },
        { 'type': 'el-icon-printer' },
        { 'type': 'el-icon-paperclip' },
        { 'type': 'el-icon-takeaway-box' },
        { 'type': 'el-icon-search' },
        { 'type': 'el-icon-monitor' },
        { 'type': 'el-icon-attract' },
        { 'type': 'el-icon-mobile' },
        { 'type': 'el-icon-scissors' },
        { 'type': 'el-icon-umbrella' },
        { 'type': 'el-icon-headset' },
        { 'type': 'el-icon-brush' },
        { 'type': 'el-icon-mouse' },
        { 'type': 'el-icon-coordinate' },
        { 'type': 'el-icon-magic-stick' },
        { 'type': 'el-icon-reading' },
        { 'type': 'el-icon-data-line' },
        { 'type': 'el-icon-data-board' },
        { 'type': 'el-icon-pie-chart' },
        { 'type': 'el-icon-data-analysis' },
        { 'type': 'el-icon-collection-tag' },
        { 'type': 'el-icon-film' },
        { 'type': 'el-icon-suitcase' },
        { 'type': 'el-icon-suitcase-1' },
        { 'type': 'el-icon-receiving' },
        { 'type': 'el-icon-collection' },
        { 'type': 'el-icon-files' },
        { 'type': 'el-icon-notebook-1' },
        { 'type': 'el-icon-notebook-2' },
        { 'type': 'el-icon-toilet-paper' },
        { 'type': 'el-icon-office-building' },
        { 'type': 'el-icon-school' },
        { 'type': 'el-icon-table-lamp' },
        { 'type': 'el-icon-house' },
        { 'type': 'el-icon-no-smoking' },
        { 'type': 'el-icon-smoking' },
        { 'type': 'el-icon-shopping-cart-full' },
        { 'type': 'el-icon-shopping-cart-1' },
        { 'type': 'el-icon-shopping-cart-2' },
        { 'type': 'el-icon-shopping-bag-1' },
        { 'type': 'el-icon-shopping-bag-2' },
        { 'type': 'el-icon-sold-out' },
        { 'type': 'el-icon-sell' },
        { 'type': 'el-icon-present' },
        { 'type': 'el-icon-box' },
        { 'type': 'el-icon-bank-card' },
        { 'type': 'el-icon-money' },
        { 'type': 'el-icon-coin' },
        { 'type': 'el-icon-wallet' },
        { 'type': 'el-icon-discount' },
        { 'type': 'el-icon-price-tag' },
        { 'type': 'el-icon-news' },
        { 'type': 'el-icon-guide' },
        { 'type': 'el-icon-male' },
        { 'type': 'el-icon-female' },
        { 'type': 'el-icon-thumb' },
        { 'type': 'el-icon-cpu' },
        { 'type': 'el-icon-link' },
        { 'type': 'el-icon-connection' },
        { 'type': 'el-icon-open' },
        { 'type': 'el-icon-turn-off' },
        { 'type': 'el-icon-set-up' },
        { 'type': 'el-icon-chat-round' },
        { 'type': 'el-icon-chat-line-round' },
        { 'type': 'el-icon-chat-square' },
        { 'type': 'el-icon-chat-dot-round' },
        { 'type': 'el-icon-chat-dot-square' },
        { 'type': 'el-icon-chat-line-square' },
        { 'type': 'el-icon-message' },
        { 'type': 'el-icon-postcard' },
        { 'type': 'el-icon-position' },
        { 'type': 'el-icon-turn-off-microphone' },
        { 'type': 'el-icon-microphone' },
        { 'type': 'el-icon-close-notification' },
        { 'type': 'el-icon-bangzhu' },
        { 'type': 'el-icon-time' },
        { 'type': 'el-icon-odometer' },
        { 'type': 'el-icon-crop' },
        { 'type': 'el-icon-aim' },
        { 'type': 'el-icon-switch-button' },
        { 'type': 'el-icon-full-screen' },
        { 'type': 'el-icon-copy-document' },
        { 'type': 'el-icon-mic' },
        { 'type': 'el-icon-stopwatch' },
        { 'type': 'el-icon-medal-1' },
        { 'type': 'el-icon-medal' },
        { 'type': 'el-icon-trophy' },
        { 'type': 'el-icon-trophy-1' },
        { 'type': 'el-icon-first-aid-kit' },
        { 'type': 'el-icon-discover' },
        { 'type': 'el-icon-place' },
        { 'type': 'el-icon-location' },
        { 'type': 'el-icon-location-outline' },
        { 'type': 'el-icon-location-information' },
        { 'type': 'el-icon-add-location' },
        { 'type': 'el-icon-delete-location' },
        { 'type': 'el-icon-map-location' },
        { 'type': 'el-icon-alarm-clock' },
        { 'type': 'el-icon-timer' },
        { 'type': 'el-icon-watch-1' },
        { 'type': 'el-icon-watch' },
        { 'type': 'el-icon-lock' },
        { 'type': 'el-icon-unlock' },
        { 'type': 'el-icon-key' },
        { 'type': 'el-icon-service' },
        { 'type': 'el-icon-mobile-phone' },
        { 'type': 'el-icon-bicycle' },
        { 'type': 'el-icon-truck' },
        { 'type': 'el-icon-ship' },
        { 'type': 'el-icon-basketball' },
        { 'type': 'el-icon-football' },
        { 'type': 'el-icon-soccer' },
        { 'type': 'el-icon-baseball' },
        { 'type': 'el-icon-wind-power' },
        { 'type': 'el-icon-light-rain' },
        { 'type': 'el-icon-lightning' },
        { 'type': 'el-icon-heavy-rain' },
        { 'type': 'el-icon-sunrise' },
        { 'type': 'el-icon-sunrise-1' },
        { 'type': 'el-icon-sunset' },
        { 'type': 'el-icon-sunny' },
        { 'type': 'el-icon-cloudy' },
        { 'type': 'el-icon-partly-cloudy' },
        { 'type': 'el-icon-cloudy-and-sunny' },
        { 'type': 'el-icon-moon' },
        { 'type': 'el-icon-moon-night' },
        { 'type': 'el-icon-dish' },
        { 'type': 'el-icon-dish-1' },
        { 'type': 'el-icon-food' },
        { 'type': 'el-icon-chicken' },
        { 'type': 'el-icon-fork-spoon' },
        { 'type': 'el-icon-knife-fork' },
        { 'type': 'el-icon-burger' },
        { 'type': 'el-icon-tableware' },
        { 'type': 'el-icon-sugar' },
        { 'type': 'el-icon-dessert' },
        { 'type': 'el-icon-ice-cream' },
        { 'type': 'el-icon-hot-water' },
        { 'type': 'el-icon-water-cup' },
        { 'type': 'el-icon-coffee-cup' },
        { 'type': 'el-icon-cold-drink' },
        { 'type': 'el-icon-goblet' },
        { 'type': 'el-icon-goblet-full' },
        { 'type': 'el-icon-goblet-square' },
        { 'type': 'el-icon-goblet-square-full' },
        { 'type': 'el-icon-refrigerator' },
        { 'type': 'el-icon-grape' },
        { 'type': 'el-icon-watermelon' },
        { 'type': 'el-icon-cherry' },
        { 'type': 'el-icon-apple' },
        { 'type': 'el-icon-pear' },
        { 'type': 'el-icon-orange' },
        { 'type': 'el-icon-coffee' },
        { 'type': 'el-icon-ice-tea' },
        { 'type': 'el-icon-ice-drink' },
        { 'type': 'el-icon-milk-tea' },
        { 'type': 'el-icon-potato-strips' },
        { 'type': 'el-icon-lollipop' },
        { 'type': 'el-icon-ice-cream-square' },
        { 'type': 'el-icon-ice-cream-round' }
      ]
    }
  },
  mounted() {
    this.list = this.search
  },
  methods: {
    // 搜索
    upIcon(n) {
      const arrs = []
      for (var i = 0; i < this.search.length; i++) {
        if (this.search[i].type.indexOf(n) !== -1) {
          arrs.push(this.search[i])
          this.list = arrs
        }
      }
    },
    iconChange(n) {
      if (this.$route.query.fodder === 'icon') {
        /* eslint-disable */
                form_create_helper.set('icon', n)
                form_create_helper.close('icon');
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .iconBox{
        margin: 0 20px;
         background:#fff
    }
    .icons-item{
        float: left;
        margin: 6px 6px 6px 0;
        width: 60px;
        text-align: center;
        list-style: none;
        cursor: pointer;
        height: 50px;
        color: #5c6b77;
        transition: all .2s ease;
        position: relative;
        padding-top: 10px;
        i{
            font-size: 32px;
        }
    }

    .trees-coadd{
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
    }
    .trees .list-inline{
        padding: 0;
    }
       

    // .scollhide{
    //     width: 100%;
    //     height: 100%;
    //     overflow: auto;
    //     margin-left: 18px;
    //     padding: 10px 0 10px 0;
    //     box-sizing: border-box;
    // }
       

        .content{
             font-size:12px;
        }
           

        .time{
            font-size:12px;
            color: #2d8cf0
        }
            
</style>
