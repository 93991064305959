<template>
  <div class="slide_footer">
    <div>
      <el-link v-if="windows && !browser" type="info" :underline="false" @click="windowsDownload">电脑版下载</el-link>
    </div>
    <div>
      <el-link v-if="windows && browser" type="info" :underline="false" @click="websiteDownload">添加瑜小九到桌面</el-link>
    </div>
    <div class="m-l-10">
      <el-link type="info" :underline="false">版本号：{{ Version }}</el-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img1: require('@/assets/images/qrcode-wx.png'),
      img2: require('@/assets/images/qrcode-app.png'),
      browser: false,
      deferredPrompt: null,
      showInstallButton: false,
      windows: false
    }
  },
  mounted() {
    // 检测是否为谷歌浏览器
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    // 输出结果
    if (isChrome) this.browser = true
    else this.browser = false
    // 判断是否是windows系统
    if (navigator.userAgent.indexOf('Windows') > -1) this.windows = true
    // 监听 beforeinstallprompt 事件
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt)
  },
  beforeDestroy() {
    // 在组件销毁时移除事件监听器
    window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt)
  },
  methods: {
    handleBeforeInstallPrompt(event) {
      console.log('AAAAAAA', event)
      // 阻止浏览器默认行为，延迟触发安装
      event.preventDefault()
      // 保存事件对象
      this.deferredPrompt = event
      // 在适当的时机显示安装按钮
      this.showInstallButton = true
    },
    websiteDownload() {
      // 触发安装
      this.deferredPrompt.prompt()
      // 等待用户的决策
      this.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt')
        } else {
          console.log('User dismissed the install prompt')
        }
        // 重置 deferredPrompt
        this.deferredPrompt = null
        // 隐藏安装按钮
        this.showInstallButton = false
      })
    },
    windowsDownload() {
      const downloadLink = 'https://static.yuxiaojiu.cn/app/download/windows/yuxiaojiu-release.exe'
      window.open(downloadLink, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.slide_footer{
  margin-bottom: 100px;
  height: 180px;
  // background: red;
  color: #fff;
  padding-top: 20px;
  font-size: 12px;
  display: flex !important;
  justify-content: center;
  ::v-deep{
    .el-image__preview{
      border-radius: 4px;
    }
  }

  img{
      width: 80px;
      height: 80px;
      border-radius: 4px;
    }
  .slide_item{
    img{
      margin-bottom: 3px;
    }
    div{
      text-align: center;
      color:#999;
      font-size: 13px;
      padding-top:3px;
    }
    padding: 10px;
  }
}
.hideSidebar .slide_footer{
  display: none;
}
.openSidebar .slide_footer{
  display: block;
}
</style>
