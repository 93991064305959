<template>
  <el-form-item v-loadmore="loadMore" :label="title">
    <el-select
      v-model="data_id"
      filterable
      clearable
      remote
      reserve-keyword
      :placeholder="'请选择' + title"
      :remote-method="selectChanged"
      @visible-change="visibleChanged"
    >
      <el-option v-for="(item, index) in dataList" :key="index" :label="item.real_name" :value="item.id" :disabled="Utils != -1 && displays && item.role_info.state === 0">
        <template v-slot:default>
          <div class="flex">
            <el-avatar style="width: 25px; height: 25px;" class="m-r-6" :src="item.avatar">
              <img :src="errorAvatarSrc(item, 'img')">
            </el-avatar>
            <span>{{ item.real_name }}</span>-
            <span>{{ item.phone }}</span>
          </div>
        </template>
      </el-option>
      <div v-if="is_hide === 0" class="jzz">
        <i v-if="is_Yes === 1" class="el-icon-loading" />
        <span v-if="is_Yes === 1" class="jzz_span1">玩命加载中</span>
        <span v-if="is_Yes === 0" class="jzz_span2">----- 我也是有底线的 -----</span>
      </div>
    </el-select>
  </el-form-item>
</template>

<script>
import { getCoach } from '@/api/store/venues.js'
import { getStoreUser } from '@/api/store/management.js'
import Utils from '@/utils/utils.js'

export default {
  props: ['type', 'id', 'timer', 'titleS', 'display', 'name'],
  data() {
    return {
      dataList: [], // 教练列表
      data_id: '',
      selectPage: 1, // 当前页数
      is_Yes: 1,
      is_hide: 0,
      query: '',
      title: this.titleS ? this.titleS : '教练',
      displays: this.display,
      real_name: '',
      delayTimer: null,
      Utils: null
    }
  },
  computed: {},
  watch: {
    data_id(val) {
      let value = ''
      // 如果val是字符串
      if (typeof val === 'string') {
        value = this.id
      } else {
        value = val
      }
      this.$emit('storeUserId', value)
    },
    timer: {
      immediate: true, // 立即执行
      handler: function(newVal, oldVal) {
        this.data_id = this.id
        this.selectPage = 1
        this.dataList = []
        this.getList(this.type, 0)
        this.real_name = this.name
        if (this.real_name) this.data_id = this.real_name
      }
    }
  },
  created() {

  },
  mounted() {
    this.Utils = Utils.getVenues().id
  },
  methods: {
    getList(type, num) {
      let data = {}
      if (type === 3) {
        data = {
          offset: this.selectPage - 1,
          limit: 8
        }
      } else if (type === 0 || type === 1) {
        data = {
          type: type,
          offset: this.selectPage - 1,
          limit: 8
        }
      }

      // if (this.data_id) {
      //   console.log(this.data_id + '❤️❤️❤️')
      //   this.query = this.data_id
      // }

      var querys = []
      if (this.query) querys.push({ field: 'real_name|phone', type: 'like', key: this.query })
      // if (this.query) querys.push({ field: 'id', key: this.query })
      data.query = this.utils.getQueryParams(querys)

      if (Utils.getVenues().id !== -1) {
        getCoach(data)
          .then(res => {
            if (num === 0) {
              if (this.dataList.length === 0) {
                this.dataList = res.data.list
                if (res.data.list.length < 8) this.is_Yes = 3
              } else {
                if (res.data.list.length === 0) {
                  this.is_Yes = 0
                } else {
                  res.data.list.forEach(item => {
                    this.dataList.push(item)
                  })
                }
              }
            } else if (num === 1) {
              this.dataList = res.data.list
            }
          })
          .catch(() => {})
          .finally(() => {
            this.$emit('loading')
          })
      } else {
        getStoreUser(data)
          .then(res => {
            if (num === 0) {
              if (this.dataList.length === 0) {
                this.dataList = res.data.list
                if (res.data.list.length < 8) this.is_Yes = 3
              } else {
                if (res.data.list.length === 0) {
                  this.is_Yes = 0
                } else {
                  res.data.list.forEach(item => {
                    this.dataList.push(item)
                  })
                }
              }
            } else if (num === 1) {
              this.dataList = res.data.list
            }
          })
          .catch(() => {})
          .finally(() => {
            this.$emit('loading')
          })
      }
    },
    selectChanged(val) {
      this.query = val
      this.is_Yes = 0
      this.getList(this.type, 1)
      // if (val.length > 0) {
      //   if (this.delayTimer) {
      //     clearTimeout(this.delayTimer)
      //   }
      //   this.query = val
      //   this.delayTimer = setTimeout(() => {
      //     this.dataList = []
      //     this.is_hide = 1
      //     this.selectPage = 1
      //     this.getList(this.type, 1)
      //   }, 500)
      // } else if (val.length === 0) {
      //   if (this.delayTimer) {
      //     clearTimeout(this.delayTimer)
      //   }
      //   this.delayTimer = setTimeout(() => {
      //     this.dataList = []
      //     this.query = null
      //     this.is_hide = 0
      //     this.is_Yes = 1
      //     this.selectPage = 1
      //     this.getList(this.type, 0)
      //   }, 600)
      // }
    },
    visibleChanged(val) {
      if (val === false) {
        this.dataList = []
        this.query = null
        this.is_hide = 0
        this.is_Yes = 1
        this.selectPage = 1
        this.getList(this.type, 0)
      }
    },
    loadMore() {
      this.selectPage += 8
      this.getList(this.type, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.jzz {
  text-align: center;
  font-size: 16px;
  margin: 6px 0;
  .jzz_span1 {
    margin-left: 10px;
    color: #ccc;
  }
  .jzz_span2 {
    color: #ccc;
  }
}
</style>
