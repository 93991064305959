<template>
  <div>
    <el-card v-loading="assetsLoading" class="m-t-20" element-loading-text="玩命加载中" shadow="hover">
      <div slot="header">
        <div class="flex row-between">
          <div class="flex">
            <div class="font-18">资产统计</div>
            <div class="w-20 h-20 m-l-4 m-t-2 cursor" @click="handleDialogVisible(5)">
              <img style="width: 100%; height: 100%;" src="@/assets/images/asset_statistics/doubt.png" alt="">
            </div>
            <div class="flex m-l-20">
              <div class="font-14">会员：</div>
              <Select :type="9" @changeId="handleMember" />
            </div>
            <div class="flex m-l-20">
              <div class="font-14">会籍顾问：</div>
              <Select :type="4" @changeId="handleCoach" />
            </div>
            <div class="flex m-l-20">
              <div class="font-14">客户类型：</div>
              <el-select v-model="listQueryParams.is_membership">
                <el-option label="全部" :value="''" />
                <el-option label="会员" :value="1" />
                <el-option label="访客" :value="0" />
              </el-select>
            </div>
            <el-button
              icon="el-icon-search"
              :loading="searchLoading"
              type="success"
              class="filter-item m-l-20"
              @click="handleFilter"
            >
              搜索
            </el-button>
            <el-button
              v-show="btn_access('s_finance_export')"
              :disabled="data.list.length <= 0"
              type="danger"
              class="filter-item m-l-10"
              icon="el-icon-download"
              :loading="exportLoading"
              @click="Download"
            >
              导出
            </el-button>
          </div>
          <el-select v-model="listQueryParams.order_field" @change="handleChange">
            <el-option label="消费金额" value="total_pay" />
            <el-option label="耗卡资产" value="total_consume" />
            <el-option label="剩余耗卡" value="total_balance" />
          </el-select>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <div>
            <div>
              <div class="font-14">剩余总耗卡(元)</div>
              <div class="font-30">¥ {{ data.total_balance_sum }}</div>
            </div>
            <div class="m-y-10" style="border-bottom: 1px solid #f0f0f0;" />
            <div class="flex row-between">
              <div class="flex font-14">
                <div class="w-10 h-10 m-r-10" style="border-radius: 50%; background: #ff5a15;" />
                <div>总消费金额</div>
              </div>
              <div class="font-20">¥ {{ data.total_pay_sum }}</div>
            </div>
            <div class="flex row-between m-t-10">
              <div class="flex font-14">
                <div class="w-10 h-10 m-r-10" style="border-radius: 50%; background: #00d8d7;" />
                <div>总耗卡金额</div>
              </div>
              <div class="font-20">¥ {{ data.total_consume_sum }}</div>
            </div>
          </div>
        </el-col>
        <el-col :span="18">
          <el-table :data="data.list" style="width: 100%;">
            <el-table-column label="排名" fixed="left">
              <template slot-scope="scope">
                <div v-if="ranking((listQuery.page - 1) * listQuery.limit + scope.$index + 1)" class="p-l-2">
                  <img :src="ranking((listQuery.page - 1) * listQuery.limit + scope.$index + 1)" alt="">
                </div>
                <div v-else class="p-l-6">{{ (listQuery.page - 1) * listQuery.limit + scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="姓名" fixed="left" min-width="120">
              <template slot-scope="{ row }">
                <div>{{ getUserNames(row) }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="total_pay" label="消费金额" min-width="120">
              <template slot-scope="{ row }">
                <div class="text-warning">{{ row.total_pay }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="total_consume" label="耗卡资产" min-width="120">
              <template slot-scope="{ row }">
                <div class="text-warning">{{ row.total_consume }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="total_balance" label="剩余耗卡" min-width="120">
              <template slot-scope="{ row }">
                <div class="text-warning">{{ row.total_balance }}</div>
              </template>
            </el-table-column>
            <el-table-column label="手机号" min-width="120">
              <template slot-scope="{ row }">
                <div>{{ row.phone ? row.phone : '---' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="客户类型" min-width="120">
              <template slot-scope="{ row }">
                <div>{{ row.is_membership ? '会员' : '访客' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="会籍顾问" min-width="120">
              <template slot-scope="{ row }">
                <div>{{ row.consultant_name ? row.consultant_name : '---' }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="card_pay" label="办卡消费" min-width="120" />
            <el-table-column prop="subscribe_pay" label="预约消费" min-width="120" />
            <el-table-column prop="mclass_pay" label="班课消费" min-width="120" />
            <el-table-column prop="other_pay" label="其他消费" min-width="120" />
            <el-table-column prop="card_refund" label="退卡退款金额" min-width="120" />
            <el-table-column prop="mclass_refund" label="出班退款金额" min-width="120" />
            <el-table-column prop="subscribe_consume" label="预约耗卡" min-width="120" />
            <el-table-column prop="time_consume" label="期限卡耗卡" min-width="120" />
            <el-table-column prop="other_consume" label="其他耗卡" min-width="120" />
          </el-table>
          <pagination
            v-show="data.count > 0"
            :total="data.count"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </el-card>
    <!-- 导出 -->
    <Export :success-prompt="successPrompt" :is-judge="is_judge" :animation-display="animation_display" />
    <!-- 弹窗提示 -->
    <Dialog :dialog-visible="dialogVisible" :type="type" @dialogVisibleClose="dialogVisibleClose" />
  </div>
</template>

<script>
// API
import { getAssetStatisticsList } from '@/api/store/assetStatistics.js'
// 组件
import Select from '@/components//elSelect/Select'
import Export from '@/components/export/index'
import Dialog from './dialog'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  components: { Select, Export, Dialog, Pagination },
  data() {
    return {
      // 资产统计数据
      data: {
        count: 0,
        list: [],
        total_balance_sum: '', // 剩余总耗卡
        total_pay_sum: '', // 总消费金额
        total_consume_sum: '' // 总耗卡金额
      },
      listQueryParams: {
        order_field: 'total_pay',
        user_id: '',
        store_user_id: '',
        is_membership: ''
      },
      type: '',
      dialogVisible: false, // 弹窗提示
      assetsLoading: false, // 资产统计加载
      searchLoading: false, // 搜索加载
      exportLoading: false, // 导出
      successPrompt: false,
      is_judge: 0,
      animation_display: true
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    ranking(No) {
      switch (No) {
        case 1:
          return require('@/assets/images/ranking/rankingNo1.png')
        case 2:
          return require('@/assets/images/ranking/rankingNo2.png')
        case 3:
          return require('@/assets/images/ranking/rankingNo3.png')
        default:
          return ''
      }
    },
    getList() {
      this.assetsLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data['order_field'] = this.listQueryParams.order_field
      data['user_id'] = this.listQueryParams.user_id
      data['store_user_id'] = this.listQueryParams.store_user_id
      if (this.listQueryParams.is_membership !== '' && this.listQueryParams.is_membership !== null) {
        data['is_membership'] = this.listQueryParams.is_membership
      }

      getAssetStatisticsList(data)
        .then(res => {
          this.data = res.data
        })
        .finally(() => {
          this.assetsLoading = false
          this.searchLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 筛选
    handleChange(val) {
      this.listQueryParams.order_field = val
      this.listQuery.page = 1
      this.getList()
    },
    // 会员
    handleMember(val) {
      this.listQueryParams.user_id = val
    },
    // 教练
    handleCoach(val) {
      this.listQueryParams.store_user_id = val
    },
    // 导出
    async Download() {
      const _this = this
      this.animation_display = true
      this.is_judge = 0
      this.successPrompt = true
      this.exportLoading = true
      const progressBar = await this.utils.handleDownload({
        url: '/store/v1/data/export' + this.params(this.listQueryParams),
        fileName: '资产统计列表' + this.utils.getDayTime() + '.xlsx',
        baseURL: this.baseUrl
      })

      // 等待下载完成
      if (progressBar) {
        if (progressBar.code) {
          setTimeout(function() {
            _this.animation_display = false
            _this.is_judge = 1
          }, 800)
        } else {
          _this.is_judge = 2
        }
        setTimeout(function() {
          _this.exportLoading = false
          _this.successPrompt = false
        }, 2000)
      }
    },
    handleDialogVisible(type) {
      this.type = type
      this.dialogVisible = true
    },
    dialogVisibleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
