<template>
  <div class="app-container">
    <div class="toolbar">
      <el-button v-show="btn_access('store_route_add')" type="primary" icon="el-icon-plus" @click="dialogVisible()">添加</el-button>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      :data="tableData"
      row-key="id"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      class="el-table--small"
      @selection-change="handleSelectionChange"
      @select="selectChange"
      @select-all="selectAllChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="title" label="菜单标题" min-width="180px" />
      <el-table-column prop="icon" label="图标">
        <template slot-scope="scope">
          <i :class="scope.row.icon" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="路由节点" min-width="180px" />
      <el-table-column prop="roles" label="权限组" min-width="180px">
        <template slot-scope="scope">
          <div v-if="scope.row.roles">
            <el-tag v-for="(item,index) in scope.row.roles.split(',')" :key="index" class="m-r-5">{{ item }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="path" label="路径" width="180" />
      <el-table-column prop="url" label="接口地址" width="180" />
      <el-table-column prop="method" label="请求方式" />
      <el-table-column prop="sort" label="排序" />
      <el-table-column prop="hidden" label="菜单">
        <template slot-scope="{row: {hidden}}">
          <el-tag v-if="hidden" type="danger">否</el-tag>
          <el-tag v-else type="success">是</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="is_show" label="显示隐藏">
        <template slot-scope="{row: {is_show}}">
          <el-tag v-if="is_show" type="success">显示</el-tag>
          <el-tag v-else type="danger">隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="160">
        <template slot-scope="scope">
          <el-button v-show="btn_access('store_route_edit')" type="primary" icon="el-icon-edit" @click="dialogVisible(scope.row.id)" />
          <delPopover v-show="btn_access('store_route_del')" :row="scope.row" btn-msg="" :api-data="apiData" @delete="getAccess" />
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :before-close="cancelDialog"
      :visible.sync="dialog.visible"
      :title="dialog.id === null ? '添加菜单':'编辑菜单'"
      class="dialog_w750"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="上级类目" prop="parent_id">
          <SelectTree
            :props="defaultProps"
            :options="tableData"
            :value="form.parent_id"
            :clearable="isClearable"
            :accordion="isAccordion"
            @getValue="getValue($event)"
          />
        </el-form-item>
        <el-form-item label="主菜单" prop="hidden">
          <el-radio-group v-model="form.hidden">
            <el-radio-button :label="1">否</el-radio-button>
            <el-radio-button :label="0">是</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="title" prop="title">
          <el-input v-model="form.title" placeholder="title" />
        </el-form-item>
        <el-form-item label="name" prop="name">
          <el-input v-model="form.name" placeholder="name" />
        </el-form-item>
        <el-form-item label="icon" prop="icon">
          <el-input v-model="form.icon" placeholder="icon" />
        </el-form-item>
        <el-form-item label="path" prop="path">
          <el-input v-model="form.path" placeholder="path" />
        </el-form-item>
        <el-form-item label="模版地址" prop="component">
          <el-input v-model="form.component" placeholder="模版地址" />
        </el-form-item>
        <el-form-item label="关联路径" prop="activeMenu">
          <el-input v-model="form.activeMenu" placeholder="关联路径" />
        </el-form-item>
        <el-form-item label="重定向地址" prop="redirect">
          <el-input v-model="form.redirect" placeholder="重定向地址" />
        </el-form-item>
        <el-form-item label="权限组" prop="roles">
          <el-select v-model="form.roles" multiple placeholder="请选择">
            <el-option
              v-for="item in rolesOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="请求方式" prop="method">
          <el-select v-model="form.method" placeholder="请选择">
            <el-option v-for="item in method" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="后端路由地址" prop="url">
          <el-input v-model="form.url" placeholder="后端路由地址" />
        </el-form-item>
        <el-form-item label="显示隐藏" prop="is_show">
          <el-radio-group v-model="form.is_show">
            <el-radio-button :label="1">显示</el-radio-button>
            <el-radio-button :label="0">隐藏</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="根菜单" prop="is_root">
          <el-radio-group v-model="form.is_root">
            <el-radio-button :label="1">是</el-radio-button>
            <el-radio-button :label="0">否</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单排序" prop="sort">
          <el-input-number
            v-model.number="form.sort"
            :min="0"
            :max="999"
            controls-position="right"
            placeholder="菜单排序"
          />
        </el-form-item>

      </el-form>
      <div slot="footer">
        <el-button type="text" @click="cancelDialog">取消</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { getAccess, addAccess, getDetails, editDetails } from '@/api/admin/system.js'
import delPopover from '@/components/All/delPopover'
import SelectTree from '@/components/TreeSelect/treeSelect.vue'
export default {
  components: {
    SelectTree,
    delPopover
  },
  data() {
    return {
      TableLoading: false,
      isClearable: false, // 可清空（可选）
      isAccordion: false, // 可收起（可选）
      defaultProps: {
        children: 'children',
        label: 'title',
        value: 'id'
      },
      // 列表
      tableData: [],
      // 弹窗
      dialog: {
        visible: false,
        id: null
      },
      method: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
      // 数据
      form: {
        hidden: 1,
        method: 'GET',
        parent_id: 0,
        sort: 100,
        is_show: 1,
        is_root: 0
      },
      // 校验
      rules: {
        // name: [{
        //   required: true,
        //   message: '请输入标题',
        //   trigger: 'blur'
        // }]
        // url: [{
        //   required: true,
        //   message: '请输入地址',
        //   trigger: 'blur'
        // }],
        // sort: [{
        //   required: true,
        //   message: '请输入排序',
        //   trigger: 'blur'
        // }]
      },
      apiData: {
        url: '/v1/access'
      },
      rolesOptions: [{
        value: 'store',
        label: 'store'
      }, {
        value: 'brand',
        label: 'brand'
      }]
    }
  },
  mounted() {
    this.getAccess()
  },
  methods: {
    handleSelectionChange(val) {},
    selectChange(selection, row, type = 'noAll') {
      // console.log(selection.find(val => { return val.id === row.id }))
      // 如果selection中存在row代表是选中，否则是取消选中
      if (selection.find(val => {
        return val.id === row.id
      })) {
        if (row.children) {
          row.children.forEach(val => {
            this.$refs.multipleTable.toggleRowSelection(val, true)
            if (type === 'noAll') selection.push(val)
            if (val.children) {
              this.selectChange(selection, val, type)
            }
          })
        }
      } else this.toggle(selection, row)
    },
    /**
			 * 取消选中状态
			 */
    toggle(selection, data) {
      if (data.children) {
        data.children.forEach(val => {
          this.$refs.multipleTable.toggleRowSelection(val, false)
          if (val.children) {
            this.toggle(selection, val)
          }
        })
      }
    },
    /**
			 * 用于树形表格多选, 选中所有
			 */
    selectAllChange(selection) {
      // 如果选中的数目与请求到的数目相同就选中子节点，否则就清空选中
      if (selection && selection.length === this.tableData.length) {
        selection.forEach(row => {
          this.selectChange(selection, row, 'All')
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    /**
			 * 打开弹窗
			 */
    dialogVisible(id) {
      if (id) {
        this.dialog.id = id
        getDetails(id).then(res => {
          this.form = res.data
          if (this.form.roles) {
            this.form.roles = this.form.roles.split(',')
          }
        }).catch(() => {})
      } else {
        this.dialog.id = null
        this.form = {
          hidden: 1,
          is_show: 1,
          method: 'GET',
          parent_id: 0,
          sort: 100,
          roles: ['store', 'brand']
        }
      }
      this.dialog.visible = true
    },
    /**
			 * 关闭弹窗
			 */
    cancelDialog() {
      this.dialog.visible = false
    },
    /**
			 * 弹窗提交
			 */
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.dialog.id) {
            const data = this.form
            delete data['create_time']
            delete data['update_time']
            editDetails(this.dialog.id, this.form).then(res => {
              this.$message.success('修改成功！')
              this.dialog.visible = false
              // location.reload()
              this.getAccess()
            }).catch(() => {})
          } else {
            addAccess(this.form).then(res => {
              this.$message.success('添加成功！')
              this.dialog.visible = false
              // location.reload()
              this.getAccess()
            }).catch(() => {})
          }
        }
      })
    },
    // 取值
    getValue(value) {
      this.form.parent_id = value
    },
    // 权限列表
    getAccess() {
      this.TableLoading = true
      getAccess()
        .then(res => {
          console.log('🚀 ~ file: index.vue ~ line 266 ~ getAccess ~ res', res)
          this.tableData = res.data
        })
        .catch(() => {})
        .finally(() => {
          this.TableLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
