var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "app-container special-center"
    },
    [
      _c("el-page-header", {
        staticClass: "page-header",
        attrs: {
          content: _vm.userInfo.is_membership ? "会员详情" : "访客详情"
        },
        on: {
          back: function($event) {
            return _vm.returnPage()
          }
        }
      }),
      _c("el-divider"),
      !_vm.is_edit
        ? _c(
            "div",
            { staticClass: "userInfo" },
            [
              _c(
                "el-row",
                {
                  staticClass: "userHeader flex row-between",
                  attrs: { gutter: 20 }
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "flex", attrs: { span: 6, offset: 0 } },
                    [
                      _c(
                        "el-avatar",
                        {
                          attrs: {
                            shape: "square",
                            size: 80,
                            src: _vm.userInfo.avatar
                          }
                        },
                        [_c("img", { attrs: { src: _vm.errorMemberSrc() } })]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "text-right user_operation",
                      attrs: { span: 18, offset: 0 }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.btn_access("s_user_vipcard_add"),
                              expression: "btn_access('s_user_vipcard_add')"
                            }
                          ],
                          attrs: { type: "success" },
                          on: {
                            click: function($event) {
                              return _vm.toPage(_vm.userInfo.id)
                            }
                          }
                        },
                        [_vm._v(" 发卡 ")]
                      ),
                      _c(
                        "el-popconfirm",
                        {
                          attrs: { title: "删除后将无法恢复，确定吗？" },
                          on: {
                            confirm: function($event) {
                              return _vm.delUserId(_vm.userInfo.id)
                            }
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.btn_access("s_user_delete"),
                                  expression: "btn_access('s_user_delete')"
                                }
                              ],
                              attrs: { slot: "reference", type: "danger" },
                              slot: "reference"
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.btn_access("s_user_edit"),
                              expression: "btn_access('s_user_edit')"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.is_edit = 1
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _vm.utils.getVenues().id === -1 &&
                      _vm.userInfo.is_membership !== 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "warning" },
                              on: {
                                click: function($event) {
                                  return _vm.openTransfer(_vm.userInfo.id)
                                }
                              }
                            },
                            [_vm._v(" 转移 ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h5", [_vm._v("基本信息")]),
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: {
                    column: 2,
                    border: "",
                    "label-style": { width: "100px" }
                  }
                },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-user" }),
                        _vm._v(" 会员名 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.getUserNames(_vm.userInfo)) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-user" }),
                        _vm._v(" 会员ID "),
                        _c(
                          "span",
                          { staticClass: "cursor" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "该虚拟用户ID可作为用户唯一辨识，如约课端登录用户ID与该ID不一致，则说明他们不是同一账号",
                                  placement: "top"
                                }
                              },
                              [_c("i", { staticClass: "el-icon-warning" })]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.userInfo.uuid ? _vm.userInfo.uuid : "---"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-s-custom" }),
                        _vm._v(" 性别 ")
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.formatTableSex(_vm.userInfo)) + " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-star-on" }),
                        _vm._v(" 会员类型 ")
                      ]),
                      _vm.userInfo.is_membership
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v("会员")
                          ])
                        : _c("el-tag", [_vm._v("访客")])
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-mobile-phone" }),
                        _vm._v(" 手机号 ")
                      ]),
                      _vm.userInfo.phone
                        ? _c("el-tag", [
                            _vm._v(
                              _vm._s(
                                _vm.userInfo.area_code == "+86"
                                  ? ""
                                  : _vm.userInfo.area_code + " - "
                              ) + _vm._s(_vm.userInfo.phone)
                            )
                          ])
                        : _c("span", [_vm._v("--")])
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-potato-strips" }),
                        _vm._v(" 生日 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.userInfo.birthday) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v(" 证件类型 ")
                      ]),
                      _vm.userInfo.certificate_type == 0
                        ? _c("span", [_vm._v("身份证")])
                        : _vm._e(),
                      _vm.userInfo.certificate_type == 1
                        ? _c("span", [_vm._v("护照")])
                        : _vm._e(),
                      _vm.userInfo.certificate_type == 2
                        ? _c("span", [_vm._v("港澳通行证")])
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-news" }),
                        _vm._v(" 积分 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.userInfo.integral) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v(" 证件号 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.userInfo.certificate_number
                              ? _vm.userInfo.certificate_number
                              : "--"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-service" }),
                        _vm._v(" 会籍顾问 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.userInfo.store_user
                              ? _vm.userInfo.store_user.real_name
                              : "--"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-service" }),
                        _vm._v(" 渠道来源 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.userInfo.store_user
                              ? _vm.source_list_arr[_vm.userInfo.source_id]
                              : "--"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-office-building" }),
                        _vm._v(" 地区 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.userInfo.area ? _vm.userInfo.area : "--") +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { span: 2 } },
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-office-building" }),
                        _vm._v(" 职位 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.userInfo.profession
                              ? _vm.userInfo.profession
                              : "无"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { span: 2 } },
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-office-building" }),
                        _vm._v(" 详细地址 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.userInfo.address ? _vm.userInfo.address : "无"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _vm.userInfo.is_membership
                    ? _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c("i", { staticClass: "el-icon-time" }),
                            _vm._v(" 加入时间 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.userInfo.create_time
                                  ? _vm.userInfo.create_time
                                  : "无"
                              ) +
                              " "
                          )
                        ],
                        2
                      )
                    : _c(
                        "el-descriptions-item",
                        [
                          _c("template", { slot: "label" }, [
                            _c("i", { staticClass: "el-icon-time" }),
                            _vm._v(" 来访时间 ")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.userInfo.visitor_time
                                  ? _vm.userInfo.visitor_time
                                  : "无"
                              ) +
                              " "
                          )
                        ],
                        2
                      ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-edit" }),
                        _vm._v(" 备注 ")
                      ]),
                      _vm._v(" " + _vm._s(_vm.userInfo.remark) + " ")
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-money" }),
                        _vm._v(" 总消费金额 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.userInfo.user_balance
                              ? _vm.userInfo.user_balance.total_pay + "元"
                              : "---"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-postcard" }),
                        _vm._v(" 总耗卡 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.userInfo.user_balance
                              ? _vm.userInfo.user_balance.total_consume + "元"
                              : "---"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _c("i", { staticClass: "el-icon-money" }),
                        _vm._v(" 剩余资产 ")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.userInfo.user_balance
                              ? _vm.userInfo.user_balance.total_balance + "元"
                              : "---"
                          ) +
                          " "
                      )
                    ],
                    2
                  ),
                  _c("template", { slot: "extra" })
                ],
                2
              ),
              _c(
                "el-tabs",
                {
                  staticClass: "m-t-20",
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "会员卡信息", name: "userCard" } },
                    [
                      _vm.userCardOptions.count > 0
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.tableLoading,
                                  expression: "tableLoading"
                                }
                              ],
                              staticClass: "card_list"
                            },
                            _vm._l(_vm.userCardOptions.list, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "flex card_item col-top"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card_img",
                                      style: {
                                        color:
                                          item.card.type == 0
                                            ? "#82848a"
                                            : "#e6ebf5"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "c_bg",
                                          style: {
                                            background:
                                              "url(" +
                                              _vm.card_bg(item.card.type) +
                                              ") center center / 100% 100% no-repeat",
                                            color:
                                              item.card.type == 0
                                                ? "#82848a"
                                                : "#e6ebf5"
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "c_type" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatCardType(
                                                    item.card.type
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "c_status" },
                                            [
                                              item.card.venues_id === -1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "onsale-section"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "onsale"
                                                        },
                                                        [_vm._v("多馆卡")]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c("div", { staticClass: "c_vip" }, [
                                            _vm._v(" VIP ")
                                          ])
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "card_info" }, [
                                    _c("div", { staticClass: "c_name" }, [
                                      _vm._v(" " + _vm._s(item.card.name) + " ")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "c_info text-info" },
                                      [
                                        _c(
                                          "table",
                                          {
                                            attrs: {
                                              border: "0",
                                              cellpadding: "0",
                                              cellspacing: "0"
                                            }
                                          },
                                          [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "卡号：" + _vm._s(item.number)
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(" 卡状态： "),
                                                _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      color: _vm.formatStatus(
                                                        item.status_id,
                                                        1
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatStatus(
                                                            item.status_id
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "售价：" +
                                                    _vm._s(item.card.price) +
                                                    " 元"
                                                )
                                              ]),
                                              item.status_id !== 14
                                                ? _c("td", [
                                                    _vm._v(" 卡余额： "),
                                                    item.card.type === 0
                                                      ? _c("span", [
                                                          _vm.getExcessTime(
                                                            item.valid_time
                                                          ) > 0
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-success"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getExcessTime(
                                                                          item.valid_time
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  item.card
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.formatCardUnitType(
                                                                                item
                                                                                  .card
                                                                                  .type
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getExcessTime(
                                                                          item.valid_time
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  item.card
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.formatCardUnitType(
                                                                                item
                                                                                  .card
                                                                                  .type
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.limit
                                                              ) +
                                                              " "
                                                          ),
                                                          item.card
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.formatCardUnitType(
                                                                      item.card
                                                                        .type
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ])
                                                  ])
                                                : _vm._e()
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "开卡类型：" +
                                                    _vm._s(
                                                      _vm.formatCardOpenType(
                                                        item.type
                                                      )
                                                    )
                                                )
                                              ]),
                                              item.status_id !== 14
                                                ? _c("td", [
                                                    _vm._v(" 有效期： "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("formatDate")(
                                                            item.open_card_time,
                                                            "YYYY-MM-DD"
                                                          )
                                                        ) +
                                                          "至" +
                                                          _vm._s(
                                                            item.valid_time
                                                          )
                                                      )
                                                    ])
                                                  ])
                                                : _vm._e()
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card_operate text-info flex"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "m-r-10 cursor",
                                          on: {
                                            click: function($event) {
                                              return _vm.card_info(item.id)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-view"
                                          }),
                                          _vm._v(" 查看详情 ")
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "cursor",
                                          on: {
                                            click: function($event) {
                                              return _vm.card_logs(item.id)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" 变更记录 ")
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "venuesList_empty text-center p-10"
                            },
                            [
                              _c("img", {
                                attrs: { src: _vm.empty_default_img, alt: "" }
                              }),
                              _c("div", { staticClass: "text-info" }, [
                                _vm._v("暂无会员卡信息")
                              ])
                            ]
                          ),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.userCardOptions.count > 0,
                            expression: "userCardOptions.count > 0"
                          }
                        ],
                        attrs: {
                          total: _vm.userCardOptions.count,
                          page: _vm.listQuery.page,
                          limit: _vm.listQuery.limit
                        },
                        on: {
                          "update:page": function($event) {
                            return _vm.$set(_vm.listQuery, "page", $event)
                          },
                          "update:limit": function($event) {
                            return _vm.$set(_vm.listQuery, "limit", $event)
                          },
                          pagination: _vm.getUserCard
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "card_list",
                          staticStyle: { display: "none" }
                        },
                        [
                          _vm.userCardOptions.count > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "course_list flex flex-wrap col-center"
                                },
                                _vm._l(_vm.userCardOptions.list, function(
                                  item
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "card_item m-b-10 p-r-10"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "UserCardItem",
                                          style: {
                                            background:
                                              "url(" +
                                              _vm.card_bg(item.card.type) +
                                              ") center center / 100% no-repeat",
                                            color:
                                              item.card.type == 0
                                                ? "#82848a"
                                                : "#e6ebf5"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "cardNumber" },
                                            [
                                              _c("b", [_vm._v("卡号")]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.numberFormat(
                                                      item.number
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "cardBody" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "售价 ￥" +
                                                    _vm._s(item.card.price)
                                                )
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  "剩余 " + _vm._s(item.limit)
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "cardFooter" },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "card-btn",
                                                  style: {
                                                    color:
                                                      item.card.type == 0
                                                        ? "#82848a"
                                                        : "#e6ebf5"
                                                  },
                                                  attrs: { round: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.card_info(
                                                        item.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" 查看详情 ")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "card-btn",
                                                  style: {
                                                    color:
                                                      item.card.type == 0
                                                        ? "#82848a"
                                                        : "#e6ebf5"
                                                  },
                                                  attrs: { round: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.card_logs(
                                                        item.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" 变更记录 ")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.userCardEmpty,
                                  expression: "userCardEmpty"
                                }
                              ]
                            },
                            [
                              _c("div", { staticClass: "text-center m-b-20" }, [
                                _c("img", {
                                  attrs: { src: _vm.empty_default_img, alt: "" }
                                }),
                                _c("br"),
                                _c("small", { staticClass: "text-info" }, [
                                  _vm._v("暂无会员卡")
                                ])
                              ])
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "上课记录", name: "user_classRecords" } },
                    [
                      _c("classRecords", {
                        attrs: { "user-id": parseInt(_vm.$route.params.id) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "订单", name: "user_orderForm" } },
                    [
                      _c("orderForm", {
                        attrs: { "user-id": parseInt(_vm.$route.params.id) }
                      })
                    ],
                    1
                  ),
                  _vm.btn_access("s_user_integral")
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "会员积分", name: "user_integral" } },
                        [
                          _c("UserIntegral", {
                            attrs: {
                              "user-id": parseInt(_vm.$route.params.id)
                            },
                            on: { upInfo: _vm.getInfo }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.btn_access("s_user_track_list")
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "回访记录", name: "visit_record" } },
                        [
                          _c("VisitorTrackList", {
                            attrs: { id: _vm.userInfo.id }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.btn_access("s_user_side")
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "体测数据", name: "user_fianco" } },
                        [
                          _c("UserFianco", {
                            attrs: { "user-id": parseInt(_vm.$route.params.id) }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.is_edit
        ? _c(
            "div",
            { staticClass: "userEdit" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    "label-position": "right",
                    "label-width": "80px",
                    model: _vm.editForm,
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "头像", prop: "avatar" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "avatar-block avatar-box",
                                  on: {
                                    click: function($event) {
                                      _vm.imagecropperShow = true
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "el-avatar",
                                    {
                                      attrs: {
                                        shape: "square",
                                        size: 100,
                                        src: _vm.editForm.avatar,
                                        title: _vm.editForm.avatar
                                          ? "点击修改"
                                          : "点击上传"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: _vm.errorImageSrc }
                                      })
                                    ]
                                  ),
                                  _c("div", { staticClass: "avatar-hover" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.editForm.avatar
                                          ? "更换头像"
                                          : "上传头像"
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("image-cropper", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.imagecropperShow,
                                    expression: "imagecropperShow"
                                  }
                                ],
                                key: _vm.imagecropperKey,
                                attrs: {
                                  field: "file",
                                  width: 300,
                                  height: 300,
                                  url: _vm.storeUpload,
                                  "lang-type": "zh"
                                },
                                on: {
                                  close: _vm.imagecropperClose,
                                  "crop-upload-success": _vm.cropSuccess
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名", prop: "real_name" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.editForm.real_name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editForm, "real_name", $$v)
                                  },
                                  expression: "editForm.real_name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "昵称", prop: "nickname" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.editForm.nickname,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editForm, "nickname", $$v)
                                  },
                                  expression: "editForm.nickname"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "性别", prop: "sex" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.editForm.sex,
                                    callback: function($$v) {
                                      _vm.$set(_vm.editForm, "sex", $$v)
                                    },
                                    expression: "editForm.sex"
                                  }
                                },
                                _vm._l(_vm.sexList, function(item, index) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: index,
                                      attrs: { label: item.value }
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "手机号", prop: "phone" } },
                            [
                              _c(
                                "el-input",
                                {
                                  model: {
                                    value: _vm.editForm.phone,
                                    callback: function($$v) {
                                      _vm.$set(_vm.editForm, "phone", $$v)
                                    },
                                    expression: "editForm.phone"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "prepend" },
                                      slot: "prepend"
                                    },
                                    [
                                      _c("Code", {
                                        attrs: { code: _vm.areaCodeData },
                                        on: { area_code: _vm.area_code }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "生日", prop: "birthday" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "308px" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.editForm.birthday,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editForm, "birthday", $$v)
                                  },
                                  expression: "editForm.birthday"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "积分", prop: "integral" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请填写积分",
                                  type: "number"
                                },
                                model: {
                                  value: _vm.editForm.integral,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editForm, "integral", $$v)
                                  },
                                  expression: "editForm.integral"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "会籍顾问",
                                prop: "store_user_id"
                              }
                            },
                            [
                              _c("Select", {
                                attrs: {
                                  type: 4,
                                  width: 308,
                                  "exist-name": _vm.detailsName
                                },
                                on: { changeId: _vm.handleCoachID }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "渠道来源", prop: "source_id" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "308px" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.editForm.source_id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.editForm, "source_id", $$v)
                                    },
                                    expression: "editForm.source_id"
                                  }
                                },
                                _vm._l(_vm.source_list, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "职业", prop: "profession" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写职业" },
                                model: {
                                  value: _vm.editForm.profession,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editForm, "profession", $$v)
                                  },
                                  expression: "editForm.profession"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件类型",
                                prop: "certificate_type"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "308px" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.editForm.certificate_type,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.editForm,
                                        "certificate_type",
                                        $$v
                                      )
                                    },
                                    expression: "editForm.certificate_type"
                                  }
                                },
                                _vm._l(_vm.certificate_card_type, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件号码",
                                prop: "certificate_number"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写证件号码" },
                                model: {
                                  value: _vm.editForm.certificate_number,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editForm,
                                      "certificate_number",
                                      $$v
                                    )
                                  },
                                  expression: "editForm.certificate_number"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所在地区", prop: "area" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请选择会员所在地区" },
                                model: {
                                  value: _vm.editForm.area,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editForm, "area", $$v)
                                  },
                                  expression: "editForm.area"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "详细地址", prop: "address" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写会员详细地址" },
                                model: {
                                  value: _vm.editForm.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editForm, "address", $$v)
                                  },
                                  expression: "editForm.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.editForm.is_membership === 0
                        ? _c(
                            "el-col",
                            { attrs: { span: 12, offset: 0 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "来访时间",
                                    prop: "visitor_time"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "datetime",
                                      format: "yyyy-MM-dd HH:mm",
                                      "value-format": "yyyy-MM-dd HH:mm",
                                      placeholder: "用户来访时间"
                                    },
                                    model: {
                                      value: _vm.editForm.visitor_time,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "visitor_time",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.visitor_time"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请填写会员备注信息",
                                  rows: "3"
                                },
                                model: {
                                  value: _vm.editForm.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editForm, "remark", $$v)
                                  },
                                  expression: "editForm.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.submit }
                                },
                                [_vm._v("保存")]
                              ),
                              _c(
                                "el-button",
                                { on: { click: _vm.edit_cancel } },
                                [_vm._v("取消")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("UserCardLogs", {
        attrs: { id: _vm.dialog.card_id, "log-visible": _vm.dialog.logVisible },
        on: {
          "update:logVisible": function($event) {
            return _vm.$set(_vm.dialog, "logVisible", $event)
          },
          "update:log-visible": function($event) {
            return _vm.$set(_vm.dialog, "logVisible", $event)
          }
        }
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog_w580",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.transfer.visible,
            title: "用户会员卡转移"
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.transfer, "visible", $event)
            }
          }
        },
        [
          _c("div", { staticClass: "tip" }, [
            _vm._v(" 此操作将转移用户所有会员卡及相关数据到指定场馆 ")
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "请选择需要转移的场馆" },
              model: {
                value: _vm.transfer.venues_id,
                callback: function($$v) {
                  _vm.$set(_vm.transfer, "venues_id", $$v)
                },
                expression: "transfer.venues_id"
              }
            },
            _vm._l(_vm.venues_list, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.setTransfer } },
                [_vm._v(" 提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function($event) {
                      _vm.transfer.visible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }