var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "rightPanel",
      staticClass: "rightPanel-container",
      class: { show: _vm.value }
    },
    [
      _c("div", { staticClass: "rightPanel-background" }),
      _c(
        "div",
        { staticClass: "rightPanel", style: { maxWidth: _vm.boxWidth } },
        [
          !_vm.text
            ? _c(
                "div",
                {
                  staticClass: "handle-button",
                  style: {
                    top: _vm.buttonTop + "px",
                    "background-color": _vm.theme
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.showOn($event)
                    }
                  }
                },
                [
                  _c("i", {
                    class: _vm.value ? "el-icon-close" : "el-icon-setting"
                  })
                ]
              )
            : _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "handle-text",
                    style: {
                      bottom: _vm.buttonBottom + "px",
                      "background-color": "#ff4949"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.showOn($event)
                      }
                    }
                  },
                  [
                    _vm.value
                      ? _c("i", {
                          class: _vm.value ? "el-icon-close" : "el-icon-edit"
                        })
                      : _c("span", [_vm._v("反馈")])
                  ]
                )
              ]),
          _c("div", { staticClass: "rightPanel-items" }, [_vm._t("default")], 2)
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }