var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.downLoading,
          expression: "downLoading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "div",
        { staticClass: "filter-container time" },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/back.png"),
              alt: "back",
              title: "上一页"
            },
            on: {
              click: function($event) {
                return _vm.backNext(true)
              }
            }
          }),
          _vm.weekList[1]
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.weekList[1].date) +
                    " 至 " +
                    _vm._s(_vm.weekList[7].date)
                )
              ])
            : _vm._e(),
          _c("img", {
            attrs: {
              src: require("@/assets/images/next.png"),
              alt: "next",
              title: "下一页"
            },
            on: {
              click: function($event) {
                return _vm.backNext(false)
              }
            }
          }),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.add_edit({ data: "批量排课" }, 2)
                }
              }
            },
            [_vm._v("批量排课")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-copy-document" },
              on: { click: _vm.switchStyle }
            },
            [_vm._v("切换样式")]
          ),
          _c(
            "el-popconfirm",
            {
              attrs: { title: "复制当前课程表到下周吗？" },
              on: { confirm: _vm.copy }
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.btn_access("s_course_curriculum_copy"),
                      expression: "btn_access('s_course_curriculum_copy')"
                    }
                  ],
                  staticClass: "m-l-5",
                  attrs: {
                    slot: "reference",
                    type: "danger",
                    icon: "el-icon-document-copy"
                  },
                  slot: "reference"
                },
                [_vm._v(" 复制课程表 ")]
              )
            ],
            1
          ),
          _c(
            "el-popconfirm",
            {
              attrs: { title: "确定清空本周课程表吗？" },
              on: { confirm: _vm.clear_curriculum }
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.btn_access("s_course_curriculum_clear"),
                      expression: "btn_access('s_course_curriculum_clear')"
                    }
                  ],
                  staticClass: "m-l-5",
                  attrs: {
                    slot: "reference",
                    type: "info",
                    icon: "el-icon-delete"
                  },
                  slot: "reference"
                },
                [_vm._v(" 清空课程表 ")]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "m-l-5",
              attrs: {
                type: "warning",
                icon: "el-icon-download",
                loading: _vm.downLoading
              },
              on: { click: _vm.toImage }
            },
            [_vm._v(" 分享课程表 ")]
          )
        ],
        1
      ),
      _c("div", { attrs: { id: "courseView" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.viewInput.noteShow && _vm.viewInput.title,
                expression: "viewInput.noteShow && viewInput.title"
              }
            ],
            staticClass: "bg-primary text-center m-x-2"
          },
          [
            _c(
              "div",
              {
                staticClass: "font-lg p-y-10",
                staticStyle: { color: "#ffffff" }
              },
              [_vm._v(_vm._s(_vm.viewInput.title))]
            )
          ]
        ),
        _c(
          "table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "table"
          },
          [
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th", [_vm._v("时间")]),
                  _vm._l(_vm.weekList, function(item, index) {
                    return _c(
                      "th",
                      {
                        key: index,
                        staticClass: "week_info",
                        class: { disable: _vm.checkTime(item.date) }
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.btn_access("s_course_curriculum_add") &&
                                !_vm.downLoading,
                              expression:
                                "btn_access('s_course_curriculum_add') && !downLoading"
                            }
                          ],
                          attrs: {
                            src: require("@/assets/images/syllaubs-plus.png"),
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.add_edit(item, 1)
                            }
                          }
                        }),
                        _c("div", [_vm._v(_vm._s(item.week))]),
                        _c("div", [_vm._v(_vm._s(item.date))])
                      ]
                    )
                  })
                ],
                2
              )
            ]),
            _c(
              "tbody",
              _vm._l(_vm.tableList, function(item, index) {
                return _c(
                  "tr",
                  { key: index },
                  [
                    _c("td", [_vm._v(_vm._s(index))]),
                    _vm._l(item, function(item1, index1) {
                      return _c(
                        "td",
                        { key: index1 },
                        _vm._l(item1, function(item2, index2) {
                          return _c(
                            "div",
                            {
                              key: index2,
                              staticClass: "course",
                              class: [
                                index2 > 0 ? "special" : "",
                                _vm.chooseStyle ? "color" : ""
                              ],
                              style: _vm.getBg(item2),
                              on: {
                                click: function($event) {
                                  return _vm.add_edit(item2, 0)
                                }
                              }
                            },
                            [
                              item2.start_time
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(item2.start_time)
                                      ) +
                                        "~" +
                                        _vm._s(
                                          _vm._f("formatDate")(item2.end_time)
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { class: { "text-danger": !item2.course } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item2.course
                                          ? item2.course.name
                                          : "关联课程已被删除"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              item2.store_user
                                ? _c("div", [
                                    _vm._v(_vm._s(item2.store_user.real_name))
                                  ])
                                : _vm._e(),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    item2.classroom
                                      ? item2.classroom.name
                                      : "未知教室"
                                  )
                                )
                              ]),
                              _c(
                                "div",
                                [
                                  _c("el-rate", {
                                    attrs: {
                                      "disabled-void-color": "#e9e9e9",
                                      disabled: ""
                                    },
                                    model: {
                                      value: item2.difficulty,
                                      callback: function($$v) {
                                        _vm.$set(item2, "difficulty", $$v)
                                      },
                                      expression: "item2.difficulty"
                                    }
                                  })
                                ],
                                1
                              ),
                              item2.auto_cancel === 2
                                ? _c(
                                    "div",
                                    { staticClass: "fine_class stop" },
                                    [_vm._v("停")]
                                  )
                                : item2.course && item2.course.type === 2
                                ? _c("div", { staticClass: "fine_class" }, [
                                    _vm._v("精")
                                  ])
                                : item2.course && item2.course.type === 1
                                ? _c("div", { staticClass: "fine_class" }, [
                                    _vm._v("私")
                                  ])
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    })
                  ],
                  2
                )
              }),
              0
            )
          ]
        ),
        _c("div", { staticClass: "flex row-between p-t-20 col-top" }, [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.viewInput.noteShow && _vm.viewInput.note,
                      expression: "viewInput.noteShow && viewInput.note"
                    }
                  ]
                },
                [
                  _c("div", { staticClass: "font-16 m-b-10" }, [
                    _vm._v("备注：")
                  ]),
                  _c("div", {
                    staticClass: "text-primary font-16",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.viewInput.note.replace(/[\n\r]/g, "<br>")
                      )
                    }
                  })
                ]
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.viewInput.noteShow,
                      expression: "!viewInput.noteShow"
                    }
                  ],
                  ref: "form",
                  attrs: {
                    model: _vm.viewInput,
                    "label-width": "80px",
                    "label-suffix": ":"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "说明" } }, [
                    _c("div", [
                      _vm._v(
                        "课表标题及备注(保存课表时标题和备注会一并显示在课程表图片中)"
                      )
                    ])
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "课程标题" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "课程标题" },
                        on: { change: _vm.noticeUp },
                        model: {
                          value: _vm.viewInput.title,
                          callback: function($$v) {
                            _vm.$set(_vm.viewInput, "title", $$v)
                          },
                          expression: "viewInput.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "课程备注" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "课程备注",
                          type: "textarea",
                          rows: "3"
                        },
                        on: { change: _vm.noticeUp },
                        model: {
                          value: _vm.viewInput.note,
                          callback: function($$v) {
                            _vm.$set(_vm.viewInput, "note", $$v)
                          },
                          expression: "viewInput.note"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              [
                _c("el-image", {
                  staticStyle: { width: "150px", height: "150px" },
                  attrs: { src: _vm.appInfo.program_open_path }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "text-center font-16",
                staticStyle: { "font-weight": "600" }
              },
              [_vm._v("扫码约课")]
            )
          ])
        ])
      ]),
      _vm.dialog.visible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": "",
                width: "50%",
                "close-on-click-modal": false,
                visible: _vm.dialog.visible,
                title: _vm.chooseDate.title
              },
              on: {
                "update:visible": function($event) {
                  return _vm.$set(_vm.dialog, "visible", $event)
                },
                close: _vm.handleClose
              }
            },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.chooseDate.loading,
                      expression: "chooseDate.loading"
                    }
                  ],
                  ref: "ruleForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.form,
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _vm.form.auto_cancel != 0
                        ? _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              { staticClass: "m-y-10" },
                              [
                                _vm.form.auto_cancel == 1
                                  ? _c("el-alert", {
                                      attrs: {
                                        title: "温馨提示：该排课已自动取消",
                                        type: "warning",
                                        "show-icon": "",
                                        closable: false
                                      }
                                    })
                                  : _vm._e(),
                                _vm.form.auto_cancel == 2
                                  ? _c("el-alert", {
                                      attrs: {
                                        title: "温馨提示：该排课已停课",
                                        type: "warning",
                                        "show-icon": "",
                                        closable: false
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "font-20 m-y-10" }, [
                          _vm._v("课程信息")
                        ])
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "课程类型", prop: "type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w-250",
                                  attrs: {
                                    disabled: _vm.whether ? false : true,
                                    placeholder: "请选择"
                                  },
                                  on: { change: _vm.curriculumType },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "团课", value: 0 }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "精品课", value: 2 }
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      disabled: _vm.is_private,
                                      label: "私教课",
                                      value: 1
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "课程名称", prop: "course_id" } },
                            [
                              _c("Select", {
                                ref: "curriculumRef",
                                attrs: {
                                  type: 6,
                                  "curriculum-type": _vm.form.type,
                                  width: 250
                                },
                                on: {
                                  changeId: _vm.handleCurriculumID,
                                  courseChoose: _vm.courseChooseS
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "课程教练",
                                prop: "store_user_id"
                              }
                            },
                            [
                              _c("Select", {
                                ref: "coachRef",
                                attrs: {
                                  type: 4,
                                  "coach-type":
                                    _vm.form.type === 0 || _vm.form.type === 2
                                      ? 0
                                      : 1,
                                  width: 250
                                },
                                on: { changeId: _vm.handleCoachID }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "上课教室", prop: "classroom_id" }
                            },
                            [
                              _c("Select", {
                                ref: "classroomRef",
                                attrs: { type: 8, width: 250 },
                                on: { changeId: _vm.handleClassroomID }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.form.type != 1
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "容纳人数",
                                    prop: "galleryful"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "w-250",
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入容纳人数"
                                      },
                                      model: {
                                        value: _vm.form.galleryful,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "galleryful", $$v)
                                        },
                                        expression: "form.galleryful"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("人")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { height: "32px" },
                              attrs: { label: "课程颜色", prop: "color" }
                            },
                            [
                              _c("el-color-picker", {
                                staticClass: "curriculum_color",
                                attrs: {
                                  predefine: _vm.courseDefaultColorList
                                },
                                model: {
                                  value: _vm.form.color,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "color", $$v)
                                  },
                                  expression: "form.color"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "课程难度", prop: "color" } },
                            [
                              _c("el-rate", {
                                staticClass: "p-t-4 curriculum_rate",
                                model: {
                                  value: _vm.form.difficulty,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "difficulty", $$v)
                                  },
                                  expression: "form.difficulty"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "font-20 m-b-10" }, [
                          _vm._v("上课时间")
                        ])
                      ]),
                      _vm.whether === 2
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "排课时间", prop: "batTime" }
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      "picker-options":
                                        _vm.dataProhibitSelection,
                                      type: "daterange",
                                      align: "right",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      "unlink-panels": "",
                                      "range-separator": "至",
                                      "start-placeholder": "开始时间",
                                      "end-placeholder": "结束时间"
                                    },
                                    model: {
                                      value: _vm.form.batTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "batTime", $$v)
                                      },
                                      expression: "form.batTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "上课时间", prop: "start_time" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "112px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择"
                                      },
                                      model: {
                                        value: _vm.stime_h,
                                        callback: function($$v) {
                                          _vm.stime_h = $$v
                                        },
                                        expression: "stime_h"
                                      }
                                    },
                                    _vm._l(24, function(item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: (index < 10
                                            ? "0" + index
                                            : index
                                          ).toString(),
                                          value: (index < 10
                                            ? "0" + index
                                            : index
                                          ).toString()
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c("div", { staticClass: "m-x-10" }, [
                                    _vm._v(":")
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "112px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择"
                                      },
                                      model: {
                                        value: _vm.stime_m,
                                        callback: function($$v) {
                                          _vm.stime_m = $$v
                                        },
                                        expression: "stime_m"
                                      }
                                    },
                                    _vm._l(60, function(item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: (index < 10
                                            ? "0" + index
                                            : index
                                          ).toString(),
                                          value: (index < 10
                                            ? "0" + index
                                            : index
                                          ).toString()
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.whether === 2
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "课程日期",
                                    prop: "time_week"
                                  }
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ff4949",
                                      "active-text": "全选",
                                      "inactive-text": "取消全选"
                                    },
                                    on: { change: _vm.is_switch },
                                    model: {
                                      value: _vm.is_coclk,
                                      callback: function($$v) {
                                        _vm.is_coclk = $$v
                                      },
                                      expression: "is_coclk"
                                    }
                                  }),
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: { change: _vm.is_checkbox },
                                      model: {
                                        value: _vm.form.time_week,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "time_week", $$v)
                                        },
                                        expression: "form.time_week"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 1 }
                                        },
                                        [_vm._v("周一")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 2 }
                                        },
                                        [_vm._v("周二")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 3 }
                                        },
                                        [_vm._v("周三")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 4 }
                                        },
                                        [_vm._v("周四")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 5 }
                                        },
                                        [_vm._v("周五")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 6 }
                                        },
                                        [_vm._v("周六")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "m-b-10",
                                          attrs: { label: 7 }
                                        },
                                        [_vm._v("周日")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "课程时长", prop: "duration" } },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "w-250",
                                  attrs: {
                                    type: "number",
                                    placeholder: "请输入时长"
                                  },
                                  model: {
                                    value: _vm.form.duration,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "duration", $$v)
                                    },
                                    expression: "form.duration"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("分钟")
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "font-20 m-b-10" }, [
                          _vm._v("付费约课")
                        ])
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "付费约课", prop: "is_pay" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.form.is_pay,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "is_pay", $$v)
                                    },
                                    expression: "form.is_pay"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "关闭", value: 0 }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "自定义", value: 1 }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "与课程保持一致", value: 2 }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.form.is_pay == 1
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "优惠价(元/次)",
                                    prop: "price"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入优惠价",
                                        disabled:
                                          _vm.form.is_pay == 1 ? false : true
                                      },
                                      model: {
                                        value: _vm.form.price,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "price", $$v)
                                        },
                                        expression: "form.price"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("元")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.is_pay == 1
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "原价(元/次)",
                                    prop: "original_price"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入原价",
                                        disabled:
                                          _vm.form.is_pay == 1 ? false : true
                                      },
                                      model: {
                                        value: _vm.form.original_price,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "original_price",
                                            $$v
                                          )
                                        },
                                        expression: "form.original_price"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("元")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.is_pay == 2 &&
                      _vm.form.original_price !== 0 &&
                      _vm.form.price !== 0
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "优惠价(元/次)",
                                    prop: "price"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入优惠价",
                                        disabled:
                                          _vm.form.is_pay == 1 ? false : true
                                      },
                                      model: {
                                        value: _vm.form.price,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "price", $$v)
                                        },
                                        expression: "form.price"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("元")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.is_pay == 2 &&
                      _vm.form.original_price !== 0 &&
                      _vm.form.price !== 0
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "原价(元/次)",
                                    prop: "original_price"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "number",
                                        placeholder: "请输入原价",
                                        disabled:
                                          _vm.form.is_pay == 1 ? false : true
                                      },
                                      model: {
                                        value: _vm.form.original_price,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "original_price",
                                            $$v
                                          )
                                        },
                                        expression: "form.original_price"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("元")
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.is_pay == 2 &&
                      _vm.form.original_price === 0 &&
                      _vm.form.price === 0
                        ? _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c("el-alert", {
                                attrs: {
                                  title:
                                    "当前课程未开启付费约课，可选择自定义费用",
                                  type: "warning",
                                  closable: false
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.form.type != 1
                    ? _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "font-20 m-b-10" }, [
                              _vm._v("开课限制")
                            ])
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "限制方式", prop: "is_check" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.form.is_check,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "is_check", $$v)
                                        },
                                        expression: "form.is_check"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "不限制", value: 0 }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "限制", value: 1 }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "遵循系统", value: 2 }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 16 } }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.form.is_check === 1,
                                    expression: "form.is_check === 1"
                                  }
                                ]
                              },
                              [
                                _c("div", { staticClass: "check-box" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("div", [_vm._v("满")]),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "lower_limit",
                                            "label-width": "0px"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "w-150 m-x-5",
                                            attrs: {
                                              placeholder: "填数字",
                                              type: "number",
                                              size: "mini"
                                            },
                                            model: {
                                              value: _vm.form.lower_limit,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "lower_limit",
                                                  $$v
                                                )
                                              },
                                              expression: "form.lower_limit"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("div", [
                                        _vm._v("人开课，人数不足提前")
                                      ]),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "check_time",
                                            "label-width": "0px"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "w-150 m-x-5",
                                            attrs: {
                                              placeholder: "填数字",
                                              type: "number",
                                              size: "mini"
                                            },
                                            model: {
                                              value: _vm.form.check_time,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "check_time",
                                                  $$v
                                                )
                                              },
                                              expression: "form.check_time"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("分钟自动取消课程")])
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm.form.is_check == 2
                            ? _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _vm.form.type === 0
                                    ? _c("el-alert", {
                                        attrs: {
                                          title: _vm.group_minimum_member
                                            .is_check
                                            ? "当约课会员不足 " +
                                              _vm.group_minimum_member
                                                .lower_limit +
                                              " 人时，在上课前 " +
                                              _vm.group_minimum_member
                                                .check_time +
                                              " 分钟(取消课程时间)自动取消课程。"
                                            : "系统开课限制已关闭",
                                          type: "warning",
                                          closable: false
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.form.type === 2
                                    ? _c("el-alert", {
                                        attrs: {
                                          title: _vm.fine_minimum_member
                                            .is_check
                                            ? "当约课会员不足 " +
                                              _vm.fine_minimum_member
                                                .lower_limit +
                                              " 人时，在上课前 " +
                                              _vm.fine_minimum_member
                                                .check_time +
                                              " 分钟(取消课程时间)自动取消课程。"
                                            : "系统开课限制已关闭",
                                          type: "warning",
                                          closable: false
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "footer text-center m-t-30" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.btn_access("s_course_curriculum_add") &&
                            !_vm.form.overdue,
                          expression:
                            "btn_access('s_course_curriculum_add') && !form.overdue"
                        }
                      ],
                      attrs: {
                        type: "primary",
                        disabled: !!_vm.form.disable_edit,
                        loading: _vm.submitLoading
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialog.visible = false
                        }
                      }
                    },
                    [_vm._v("关闭")]
                  ),
                  _vm.dialog.id && !_vm.form.overdue
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.btn_access(
                                "s_course_curriculum_delete"
                              ),
                              expression:
                                "btn_access('s_course_curriculum_delete')"
                            }
                          ],
                          attrs: {
                            disabled: !!_vm.form.disable_edit,
                            type: "danger",
                            loading: _vm.submitLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.delete_curriculum(_vm.dialog.id)
                            }
                          }
                        },
                        [_vm._v(" 删除 ")]
                      )
                    : _vm._e(),
                  _vm.dialog.id && !_vm.form.overdue
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.btn_access(
                                "s_course_curriculum_delete"
                              ),
                              expression:
                                "btn_access('s_course_curriculum_delete')"
                            }
                          ],
                          attrs: {
                            disabled: !!_vm.form.disable_edit,
                            type:
                              _vm.form.auto_cancel == 0 ? "info" : "primary",
                            loading: _vm.submitLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.cancel_curriculum(_vm.dialog.id)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.form.auto_cancel == 0 ? "停课" : "复课"
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.dialog.id && _vm.form.overdue
                    ? _c(
                        "el-button",
                        { attrs: { disabled: "", type: "info" } },
                        [_vm._v(" 课程已结束 ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分享课程表",
            visible: _vm.downImg,
            width: "30%",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.downImg = $event
            }
          }
        },
        [
          _c("el-image", {
            ref: "preview",
            staticStyle: { width: "100%" },
            attrs: {
              src: _vm.viewUrl,
              fit: "scale-down",
              "preview-src-list": [_vm.viewUrl]
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.imgView } }, [
                _vm._v("预 览")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.imgDown } },
                [_vm._v("下 载")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }