var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.cardLoading,
              expression: "cardLoading"
            }
          ],
          staticStyle: { border: "0" },
          attrs: {
            shadow: "never",
            "body-style": "background-color: #f3f3f3;padding: 0 0;"
          }
        },
        [
          _vm.ruleForm
            ? _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    size: "small",
                    "label-width": "20%"
                  }
                },
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label:
                              _vm.form.type === 1
                                ? "非活动海报"
                                : "营销活动海报",
                            name: "first"
                          }
                        },
                        [
                          _c("div", { staticStyle: { display: "flex" } }, [
                            _c("div", { staticClass: "mobile-box" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "bg-box",
                                  attrs: { id: "posterView" }
                                },
                                [
                                  _c("img", {
                                    staticClass: "img-box",
                                    attrs: {
                                      src: _vm.ruleForm.share.bg_pic.url
                                    }
                                  }),
                                  _vm.ruleForm.share.head.is_show == 1
                                    ? _c("el-image", {
                                        class:
                                          _vm.ruleForm.share.head.align ===
                                          "left"
                                            ? "display-left"
                                            : _vm.ruleForm.share.head.align ===
                                              "right"
                                            ? "display-right"
                                            : _vm.ruleForm.share.head.align ===
                                              "center"
                                            ? "display-center"
                                            : "",
                                        style: {
                                          position: "absolute",
                                          top:
                                            _vm.ruleForm.share.head.top +
                                            _vm.unit,
                                          left:
                                            _vm.ruleForm.share.head.left +
                                            _vm.unit,
                                          width:
                                            _vm.ruleForm.share.head.size +
                                            _vm.unit,
                                          height:
                                            _vm.ruleForm.share.head.size +
                                            _vm.unit,
                                          borderRadius: "50%"
                                        },
                                        attrs: {
                                          radius: "50%",
                                          src: _vm.poster.default_head
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.ruleForm.share.xj_logo.is_show == 1
                                    ? _c("el-image", {
                                        style: {
                                          position: "absolute",
                                          top:
                                            _vm.ruleForm.share.xj_logo.top +
                                            _vm.unit,
                                          left:
                                            _vm.ruleForm.share.xj_logo.left +
                                            _vm.unit,
                                          width:
                                            _vm.ruleForm.share.xj_logo.size +
                                            _vm.unit
                                        },
                                        attrs: {
                                          src: _vm.ruleForm.share.xj_logo.src
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.ruleForm.share.qr_code.is_show == 1 &&
                                  _vm.ruleForm.share.qr_code.type == 2 &&
                                  this.$route.params.activityId != 0
                                    ? _c("canvas", {
                                        ref: "qrcode_box",
                                        class:
                                          _vm.ruleForm.share.qr_code.align ===
                                          "left"
                                            ? "display-left"
                                            : _vm.ruleForm.share.qr_code
                                                .align === "right"
                                            ? "display-right"
                                            : _vm.ruleForm.share.qr_code
                                                .align === "center"
                                            ? "display-center"
                                            : "",
                                        style: {
                                          position: "absolute",
                                          top:
                                            _vm.ruleForm.share.qr_code.top +
                                            _vm.unit,
                                          left:
                                            _vm.ruleForm.share.qr_code.left +
                                            _vm.unit,
                                          width:
                                            _vm.ruleForm.share.qr_code.size +
                                            _vm.unit,
                                          height:
                                            _vm.ruleForm.share.qr_code.size +
                                            _vm.unit
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.ruleForm.share.qr_code.is_show == 1 &&
                                  _vm.ruleForm.share.qr_code.type == 1 &&
                                  this.$route.params.activityId != 0
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.imgLoading,
                                              expression: "imgLoading"
                                            }
                                          ],
                                          class:
                                            _vm.ruleForm.share.qr_code.align ===
                                            "left"
                                              ? "display-left"
                                              : _vm.ruleForm.share.qr_code
                                                  .align === "right"
                                              ? "display-right"
                                              : _vm.ruleForm.share.qr_code
                                                  .align === "center"
                                              ? "display-center"
                                              : "",
                                          style: {
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.qr_code.top +
                                              _vm.unit,
                                            left:
                                              _vm.ruleForm.share.qr_code.left +
                                              _vm.unit,
                                            width:
                                              _vm.ruleForm.share.qr_code.size +
                                              _vm.unit,
                                            height:
                                              _vm.ruleForm.share.qr_code.size +
                                              _vm.unit
                                          },
                                          attrs: { alt: "" }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { width: "100%" },
                                            attrs: { src: _vm.qrcodeUrl }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  this.$route.params.activityId == 0 &&
                                  _vm.imageUrl
                                    ? _c("img", {
                                        class:
                                          _vm.ruleForm.share.qr_code.align ===
                                          "left"
                                            ? "display-left"
                                            : _vm.ruleForm.share.qr_code
                                                .align === "right"
                                            ? "display-right"
                                            : _vm.ruleForm.share.qr_code
                                                .align === "center"
                                            ? "display-center"
                                            : "",
                                        style: {
                                          position: "absolute",
                                          top:
                                            _vm.ruleForm.share.qr_code.top +
                                            _vm.unit,
                                          left:
                                            _vm.ruleForm.share.qr_code.left +
                                            _vm.unit,
                                          width:
                                            _vm.ruleForm.share.qr_code.size +
                                            _vm.unit,
                                          height:
                                            _vm.ruleForm.share.qr_code.size +
                                            _vm.unit
                                        },
                                        attrs: { src: _vm.imageUrl, alt: "" }
                                      })
                                    : _vm._e(),
                                  _vm.ruleForm.share.desc.is_show == 1
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.desc.align ===
                                            "left"
                                              ? "display-left text-left"
                                              : _vm.ruleForm.share.desc
                                                  .align === "right"
                                              ? "display-right text-right"
                                              : _vm.ruleForm.share.desc
                                                  .align === "center"
                                              ? "display-center text-centers"
                                              : "",
                                          style: {
                                            width:
                                              _vm.ruleForm.share.desc.width +
                                              _vm.unit,
                                            wordWrap: "break-word",
                                            wordBreak: "normal",
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.desc.top +
                                              _vm.unit,
                                            left:
                                              _vm.ruleForm.share.desc.left +
                                              _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.desc.font * 2 +
                                              _vm.unit,
                                            color: _vm.ruleForm.share.desc.color
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share.desc.text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.activity_name.is_show == 1
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.activity_name
                                              .align === "left"
                                              ? "display-left text-left"
                                              : _vm.ruleForm.share.activity_name
                                                  .align === "right"
                                              ? "display-right text-right"
                                              : _vm.ruleForm.share.activity_name
                                                  .align === "center"
                                              ? "display-center text-centers"
                                              : "",
                                          style: {
                                            width:
                                              _vm.ruleForm.share.activity_name
                                                .width + _vm.unit,
                                            wordWrap: "break-word",
                                            wordBreak: "normal",
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.activity_name
                                                .top + _vm.unit,
                                            left:
                                              _vm.ruleForm.share.activity_name
                                                .left + _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.activity_name
                                                .font *
                                                2 +
                                              _vm.unit,
                                            color:
                                              _vm.ruleForm.share.activity_name
                                                .color
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share.activity_name
                                                  .text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.original_price.is_show == 1
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.original_price
                                              .align === "left"
                                              ? "display-left text-left"
                                              : _vm.ruleForm.share
                                                  .original_price.align ===
                                                "right"
                                              ? "display-right text-right"
                                              : _vm.ruleForm.share
                                                  .original_price.align ===
                                                "center"
                                              ? "display-center text-centers"
                                              : "",
                                          style: {
                                            width:
                                              _vm.ruleForm.share.original_price
                                                .width + _vm.unit,
                                            wordWrap: "break-word",
                                            wordBreak: "normal",
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.original_price
                                                .top + _vm.unit,
                                            left:
                                              _vm.ruleForm.share.original_price
                                                .left + _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.original_price
                                                .font *
                                                2 +
                                              _vm.unit,
                                            color:
                                              _vm.ruleForm.share.original_price
                                                .color,
                                            textDecoration:
                                              _vm.ruleForm.share.activity_price
                                                .is_show == 1
                                                ? "line-through"
                                                : "none"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share
                                                  .original_price.text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.activity_price.is_show == 1
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.activity_price
                                              .align === "left"
                                              ? "display-left text-left"
                                              : _vm.ruleForm.share
                                                  .activity_price.align ===
                                                "right"
                                              ? "display-right text-right"
                                              : _vm.ruleForm.share
                                                  .activity_price.align ===
                                                "center"
                                              ? "display-center text-centers"
                                              : "",
                                          style: {
                                            width:
                                              _vm.ruleForm.share.activity_price
                                                .width + _vm.unit,
                                            wordWrap: "break-word",
                                            wordBreak: "normal",
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.activity_price
                                                .top + _vm.unit,
                                            left:
                                              _vm.ruleForm.share.activity_price
                                                .left + _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.activity_price
                                                .font *
                                                2 +
                                              _vm.unit,
                                            color:
                                              _vm.ruleForm.share.activity_price
                                                .color
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share
                                                  .activity_price.text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.activity_price.is_show == 1
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.activity_price
                                              .align === "left"
                                              ? "display-left text-left"
                                              : _vm.ruleForm.share
                                                  .activity_price.align ===
                                                "right"
                                              ? "display-right text-right"
                                              : _vm.ruleForm.share
                                                  .activity_price.align ===
                                                "center"
                                              ? "display-center text-centers"
                                              : "",
                                          style: {
                                            width:
                                              _vm.ruleForm.share.activity_price
                                                .width + _vm.unit,
                                            wordWrap: "break-word",
                                            wordBreak: "normal",
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.activity_price
                                                .top + _vm.unit,
                                            left:
                                              _vm.ruleForm.share.activity_price
                                                .left + _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.activity_price
                                                .font *
                                                2 +
                                              _vm.unit,
                                            color:
                                              _vm.ruleForm.share.activity_price
                                                .color
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share
                                                  .activity_price.text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.activity_time.is_show ==
                                    1 && _vm.activityIdData.is_seckill
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.activity_time
                                              .align === "left"
                                              ? "display-left text-left"
                                              : _vm.ruleForm.share.activity_time
                                                  .align === "right"
                                              ? "display-right text-right"
                                              : _vm.ruleForm.share.activity_time
                                                  .align === "center"
                                              ? "display-center text-centers"
                                              : "",
                                          style: {
                                            width:
                                              _vm.ruleForm.share.activity_time
                                                .width + _vm.unit,
                                            wordWrap: "break-word",
                                            wordBreak: "normal",
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.activity_time
                                                .top + _vm.unit,
                                            left:
                                              _vm.ruleForm.share.activity_time
                                                .left + _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.activity_time
                                                .font *
                                                2 +
                                              _vm.unit,
                                            color:
                                              _vm.ruleForm.share.activity_time
                                                .color
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share.activity_time
                                                  .text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.activity_text1.is_show == 1
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.activity_text1
                                              .align === "left"
                                              ? "display-left text-left"
                                              : _vm.ruleForm.share
                                                  .activity_text1.align ===
                                                "right"
                                              ? "display-right text-right"
                                              : _vm.ruleForm.share
                                                  .activity_text1.align ===
                                                "center"
                                              ? "display-center text-centers"
                                              : "",
                                          style: {
                                            width:
                                              _vm.ruleForm.share.activity_text1
                                                .width + _vm.unit,
                                            wordWrap: "break-word",
                                            wordBreak: "normal",
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.activity_text1
                                                .top + _vm.unit,
                                            left:
                                              _vm.ruleForm.share.activity_text1
                                                .left + _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.activity_text1
                                                .font *
                                                2 +
                                              _vm.unit,
                                            color:
                                              _vm.ruleForm.share.activity_text1
                                                .color
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share
                                                  .activity_text1.text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.call_text.is_show == 1
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.call_text
                                              .align === "left"
                                              ? "display-left text-left"
                                              : _vm.ruleForm.share.call_text
                                                  .align === "right"
                                              ? "display-right text-right"
                                              : _vm.ruleForm.share.call_text
                                                  .align === "center"
                                              ? "display-center text-centers"
                                              : "",
                                          style: {
                                            width:
                                              _vm.ruleForm.share.call_text
                                                .width + _vm.unit,
                                            wordWrap: "break-word",
                                            wordBreak: "normal",
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.call_text.top +
                                              _vm.unit,
                                            left:
                                              _vm.ruleForm.share.call_text
                                                .left + _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.call_text
                                                .font *
                                                2 +
                                              _vm.unit,
                                            color:
                                              _vm.ruleForm.share.call_text.color
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share.call_text
                                                  .text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.address_text.is_show == 1
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.address_text
                                              .align === "left"
                                              ? "display-left text-left"
                                              : _vm.ruleForm.share.address_text
                                                  .align === "right"
                                              ? "display-right text-right"
                                              : _vm.ruleForm.share.address_text
                                                  .align === "center"
                                              ? "display-center text-centers"
                                              : "",
                                          style: {
                                            width:
                                              _vm.ruleForm.share.address_text
                                                .width + _vm.unit,
                                            wordWrap: "break-word",
                                            wordBreak: "normal",
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.address_text
                                                .top + _vm.unit,
                                            left:
                                              _vm.ruleForm.share.address_text
                                                .left + _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.address_text
                                                .font *
                                                2 +
                                              _vm.unit,
                                            color:
                                              _vm.ruleForm.share.address_text
                                                .color
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share.address_text
                                                  .text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.name.is_show == 1
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.ruleForm.share.name.align ===
                                            "left"
                                              ? "display-left"
                                              : _vm.ruleForm.share.name
                                                  .align === "right"
                                              ? "display-right"
                                              : _vm.ruleForm.share.name
                                                  .align === "center"
                                              ? "display-center"
                                              : "",
                                          style: {
                                            position: "absolute",
                                            top:
                                              _vm.ruleForm.share.name.top +
                                              _vm.unit,
                                            left:
                                              _vm.ruleForm.share.name.left +
                                              _vm.unit,
                                            fontSize:
                                              _vm.ruleForm.share.name.font * 2 +
                                              _vm.unit,
                                            color:
                                              _vm.ruleForm.share.name.color,
                                            textAlign: "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ruleForm.share.name.text
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.ruleForm.share.line.is_show == 1
                                    ? _c("div", {
                                        class:
                                          _vm.ruleForm.share.line.align ===
                                          "left"
                                            ? "display-left"
                                            : _vm.ruleForm.share.line.align ===
                                              "right"
                                            ? "display-right"
                                            : _vm.ruleForm.share.line.align ===
                                              "center"
                                            ? "display-center"
                                            : "",
                                        style: {
                                          position: "absolute",
                                          backgroundColor:
                                            _vm.ruleForm.share.line.color,
                                          width:
                                            _vm.ruleForm.share.line.width +
                                            _vm.unit,
                                          height:
                                            _vm.ruleForm.share.line.height +
                                            _vm.unit,
                                          top:
                                            _vm.ruleForm.share.line.top +
                                            _vm.unit,
                                          left:
                                            _vm.ruleForm.share.line.left +
                                            _vm.unit
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]),
                            _vm.ruleForm.share.bg_pic.url
                              ? _c(
                                  "div",
                                  { staticClass: "form-body" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-wrap" },
                                      _vm._l(_vm.shareComponent, function(
                                        item,
                                        index
                                      ) {
                                        return _c("div", { key: index }, [
                                          (item.uId !== 0 && index !== 6) ||
                                          _vm.activityIdData.is_seckill == 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "component-item flex row-center col-center flex-wrap",
                                                  class:
                                                    _vm.shareComponentKey ==
                                                    item.key
                                                      ? "active"
                                                      : "",
                                                  staticStyle: {
                                                    "flex-flow": "column"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.componentItemClick(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "component-item-img",
                                                    attrs: {
                                                      src: item.icon_url
                                                    }
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "m-t-5" },
                                                    [_vm._v(_vm._s(item.title))]
                                                  ),
                                                  _vm.test(index)
                                                    ? _c("img", {
                                                        staticClass:
                                                          "component-item-remove",
                                                        attrs: {
                                                          src:
                                                            _vm.poster
                                                              .icon_delete
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.componentItemRemove(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      }),
                                      0
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "m-y-15",
                                        staticStyle: {
                                          "font-size": "13px",
                                          color: "#9b9797"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "注：如有特殊需求，可点击上方再添加新内容"
                                        )
                                      ]
                                    ),
                                    this.$route.params.activityId == 0
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "13px",
                                              color: "#9b9797"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "注：描述词，活动名称和地址以及二维码可自行添加/更改"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-card",
                                      {
                                        staticClass: "box-card",
                                        staticStyle: { width: "100%" },
                                        attrs: { shadow: "never" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "header" },
                                            slot: "header"
                                          },
                                          [
                                            _vm.shareComponentKey == "head"
                                              ? _c("span", [_vm._v("头像设置")])
                                              : _vm._e(),
                                            _vm.shareComponentKey == "name"
                                              ? _c("span", [_vm._v("昵称设置")])
                                              : _vm._e(),
                                            _vm.shareComponentKey == "qr_code"
                                              ? _c("span", [
                                                  _vm._v("二维码设置")
                                                ])
                                              : _vm._e(),
                                            _vm.shareComponentKey == "desc"
                                              ? _c("span", [
                                                  _vm._v("描述词设置")
                                                ])
                                              : _vm._e(),
                                            _vm.shareComponentKey ==
                                            "activity_name"
                                              ? _c("span", [_vm._v("活动名称")])
                                              : _vm._e(),
                                            _vm.shareComponentKey ==
                                            "original_price"
                                              ? _c("span", [_vm._v("原价")])
                                              : _vm._e(),
                                            _vm.shareComponentKey ==
                                            "activity_price"
                                              ? _c("span", [_vm._v("活动价格")])
                                              : _vm._e(),
                                            _vm.shareComponentKey ==
                                            "activity_time"
                                              ? _c("span", [_vm._v("活动时间")])
                                              : _vm._e(),
                                            _vm.shareComponentKey ==
                                            "activity_text1"
                                              ? _c("span", [_vm._v("扫码开抢")])
                                              : _vm._e(),
                                            _vm.shareComponentKey == "call_text"
                                              ? _c("span", [_vm._v("联系电话")])
                                              : _vm._e(),
                                            _vm.shareComponentKey ==
                                            "address_text"
                                              ? _c("span", [_vm._v("联系地址")])
                                              : _vm._e(),
                                            _vm.shareComponentKey == "line"
                                              ? _c("span", [
                                                  _vm._v("分割线设置")
                                                ])
                                              : _vm._e(),
                                            _vm.shareComponentKey == "xj_logo"
                                              ? _c("span", [_vm._v("水印设置")])
                                              : _vm._e()
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _vm.shareComponentKey == "head"
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "大小" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 40,
                                                          max: 300,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .head.size,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .head,
                                                              "size",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.head.size"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "上间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 0,
                                                          max:
                                                            _vm.ruleForm.share
                                                              .divHeight -
                                                            _vm.ruleForm.share
                                                              .head.size,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .head.top,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .head,
                                                              "top",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.head.top"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "左间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.ruleForm.share
                                                              .head.align ===
                                                              "left" ||
                                                            _vm.ruleForm.share
                                                              .head.align ===
                                                              "center" ||
                                                            _vm.ruleForm.share
                                                              .head.align ===
                                                              "right"
                                                              ? true
                                                              : _vm.ruleForm
                                                                  .share.head
                                                                  .align ===
                                                                "none"
                                                              ? false
                                                              : "",
                                                          min: 0,
                                                          max:
                                                            _vm.divWidth -
                                                            _vm.ruleForm.share
                                                              .head.size,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .head.left,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .head,
                                                              "left",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.head.left"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "对齐方式"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "left"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .head.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.head,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.head.align"
                                                          }
                                                        },
                                                        [_vm._v("左对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "center"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .head.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.head,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.head.align"
                                                          }
                                                        },
                                                        [_vm._v("居中对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "right"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .head.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.head,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.head.align"
                                                          }
                                                        },
                                                        [_vm._v("右对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "none"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .head.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.head,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.head.align"
                                                          }
                                                        },
                                                        [_vm._v("自定义")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              : _vm.shareComponentKey == "name"
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "大小" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 12,
                                                          max: 40,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .name.font,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .name,
                                                              "font",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.name.font"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "上间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 0,
                                                          max:
                                                            _vm.ruleForm.share
                                                              .divHeight -
                                                            _vm.ruleForm.share
                                                              .name.font,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .name.top,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .name,
                                                              "top",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.name.top"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "左间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.ruleForm.share
                                                              .name.align ===
                                                              "left" ||
                                                            _vm.ruleForm.share
                                                              .name.align ===
                                                              "center" ||
                                                            _vm.ruleForm.share
                                                              .name.align ===
                                                              "right"
                                                              ? true
                                                              : _vm.ruleForm
                                                                  .share.name
                                                                  .align ===
                                                                "none"
                                                              ? false
                                                              : "",
                                                          min: 0,
                                                          max:
                                                            _vm.divWidth -
                                                            _vm.ruleForm.share
                                                              .name.font,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .name.left,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .name,
                                                              "left",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.name.left"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "颜色" }
                                                    },
                                                    [
                                                      _c("el-color-picker", {
                                                        staticStyle: {
                                                          "margin-left": "20px"
                                                        },
                                                        attrs: {
                                                          "color-format": "rgb",
                                                          predefine:
                                                            _vm.predefineColors
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .name.color,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .name,
                                                              "color",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.name.color"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "对齐方式"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "left"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .name.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.name,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.name.align"
                                                          }
                                                        },
                                                        [_vm._v("左对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "center"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .name.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.name,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.name.align"
                                                          }
                                                        },
                                                        [_vm._v("居中对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "right"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .name.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.name,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.name.align"
                                                          }
                                                        },
                                                        [_vm._v("右对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "none"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .name.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.name,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.name.align"
                                                          }
                                                        },
                                                        [_vm._v("自定义")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              : _vm.shareComponentKey ==
                                                "qr_code"
                                              ? [
                                                  this.$route.params
                                                    .activityId != 0
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "样式"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label: 1
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.officialAccount
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.ruleForm
                                                                    .share
                                                                    .qr_code
                                                                    .type,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .share
                                                                      .qr_code,
                                                                    "type",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "ruleForm.share.qr_code.type"
                                                              }
                                                            },
                                                            [_vm._v("小程序")]
                                                          ),
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label: 2
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.qrcodeChange()
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.ruleForm
                                                                    .share
                                                                    .qr_code
                                                                    .type,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .share
                                                                      .qr_code,
                                                                    "type",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "ruleForm.share.qr_code.type"
                                                              }
                                                            },
                                                            [_vm._v("公众号")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  this.$route.params
                                                    .activityId == 0
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "图片"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-upload",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "loading",
                                                                  rawName:
                                                                    "v-loading",
                                                                  value:
                                                                    _vm.upLoading,
                                                                  expression:
                                                                    "upLoading"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "images-uploader",
                                                              attrs: {
                                                                action:
                                                                  _vm.storeUpload,
                                                                "show-file-list": false,
                                                                "on-success":
                                                                  _vm.upSuccesss,
                                                                headers: _vm.utils.upload_headers(),
                                                                "on-progress":
                                                                  _vm.upProgresss,
                                                                accept:
                                                                  ".jpg,.jpeg,.png"
                                                              }
                                                            },
                                                            [
                                                              _vm.imageUrl
                                                                ? _c("img", {
                                                                    staticClass:
                                                                      "images",
                                                                    attrs: {
                                                                      src:
                                                                        _vm.imageUrl,
                                                                      title:
                                                                        "点击上传图片"
                                                                    }
                                                                  })
                                                                : _c("i", {
                                                                    staticClass:
                                                                      "el-icon-plus images-uploader-icon",
                                                                    staticStyle: {
                                                                      border:
                                                                        "1px dashed #d9d9d9"
                                                                    }
                                                                  })
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "大小" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 80,
                                                          max: _vm.divWidth,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .qr_code.size,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .qr_code,
                                                              "size",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.qr_code.size"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "上间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 0,
                                                          max:
                                                            _vm.ruleForm.share
                                                              .divHeight -
                                                            _vm.ruleForm.share
                                                              .qr_code.size,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .qr_code.top,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .qr_code,
                                                              "top",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.qr_code.top"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "左间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.ruleForm.share
                                                              .qr_code.align ===
                                                              "left" ||
                                                            _vm.ruleForm.share
                                                              .qr_code.align ===
                                                              "center" ||
                                                            _vm.ruleForm.share
                                                              .qr_code.align ===
                                                              "right"
                                                              ? true
                                                              : _vm.ruleForm
                                                                  .share.qr_code
                                                                  .align ===
                                                                "none"
                                                              ? false
                                                              : "",
                                                          min: 0,
                                                          max:
                                                            _vm.divWidth -
                                                            _vm.ruleForm.share
                                                              .qr_code.size,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .qr_code.left,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .qr_code,
                                                              "left",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.qr_code.left"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "颜色" }
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "light",
                                                            content:
                                                              "当小程序颜色调整后依旧是黑色时，说明不推荐使用该颜色，请另外调整。",
                                                            placement: "top"
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-question icon-question"
                                                          })
                                                        ]
                                                      ),
                                                      _c("el-color-picker", {
                                                        staticStyle: {
                                                          "margin-left": "20px"
                                                        },
                                                        attrs: {
                                                          "color-format": "hex",
                                                          predefine:
                                                            _vm.predefineColors
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.qrcodeChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .qr_code.color,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .qr_code,
                                                              "color",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.qr_code.color"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.ruleForm.share.qr_code
                                                    .type === 2 &&
                                                  this.$route.params
                                                    .activityId != 0
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "背景颜色"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-color-picker",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "20px"
                                                              },
                                                              attrs: {
                                                                "color-format":
                                                                  "hex",
                                                                predefine:
                                                                  _vm.predefineColors
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.qrcodeChange
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.ruleForm
                                                                    .share
                                                                    .qr_code
                                                                    .light_color,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm
                                                                      .share
                                                                      .qr_code,
                                                                    "light_color",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "ruleForm.share.qr_code.light_color"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "开启透明背景"
                                                      }
                                                    },
                                                    [
                                                      _c("el-switch", {
                                                        attrs: {
                                                          "active-value": "1",
                                                          "inactive-value": "0"
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.qrcodeChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .qr_code
                                                              .transparency,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .qr_code,
                                                              "transparency",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.qr_code.transparency"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "对齐方式"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "left"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .qr_code.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share
                                                                  .qr_code,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.qr_code.align"
                                                          }
                                                        },
                                                        [_vm._v("左对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "center"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .qr_code.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share
                                                                  .qr_code,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.qr_code.align"
                                                          }
                                                        },
                                                        [_vm._v("居中对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "right"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .qr_code.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share
                                                                  .qr_code,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.qr_code.align"
                                                          }
                                                        },
                                                        [_vm._v("右对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "none"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .qr_code.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share
                                                                  .qr_code,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.qr_code.align"
                                                          }
                                                        },
                                                        [_vm._v("自定义")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              : _vm.shareComponentKey == "desc"
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "文本内容"
                                                      }
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .desc.text,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .desc,
                                                              "text",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.desc.text"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "大小" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 12,
                                                          max: 200,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .desc.font,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .desc,
                                                              "font",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.desc.font"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "文本宽度"
                                                      }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 30,
                                                          max: _vm.divWidth,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .desc.width,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .desc,
                                                              "width",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.desc.width"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "上间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 0,
                                                          max:
                                                            _vm.ruleForm.share
                                                              .divHeight -
                                                            _vm.ruleForm.share
                                                              .desc.font,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .desc.top,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .desc,
                                                              "top",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.desc.top"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "左间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.ruleForm.share
                                                              .desc.align ===
                                                              "left" ||
                                                            _vm.ruleForm.share
                                                              .desc.align ===
                                                              "center" ||
                                                            _vm.ruleForm.share
                                                              .desc.align ===
                                                              "right"
                                                              ? true
                                                              : _vm.ruleForm
                                                                  .share.desc
                                                                  .align ===
                                                                "none"
                                                              ? false
                                                              : "",
                                                          min: 0,
                                                          max:
                                                            _vm.divWidth -
                                                            _vm.ruleForm.share
                                                              .desc.font,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .desc.left,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .desc,
                                                              "left",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.desc.left"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "颜色" }
                                                    },
                                                    [
                                                      _c("el-color-picker", {
                                                        staticStyle: {
                                                          "margin-left": "20px"
                                                        },
                                                        attrs: {
                                                          "color-format": "rgb",
                                                          predefine:
                                                            _vm.predefineColors
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share
                                                              .desc.color,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm.share
                                                                .desc,
                                                              "color",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share.desc.color"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "对齐方式"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "left"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .desc.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.desc,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.desc.align"
                                                          }
                                                        },
                                                        [_vm._v("左对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "center"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .desc.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.desc,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.desc.align"
                                                          }
                                                        },
                                                        [_vm._v("居中对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "right"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .desc.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.desc,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.desc.align"
                                                          }
                                                        },
                                                        [_vm._v("右对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "none"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm.share
                                                                .desc.align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share.desc,
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share.desc.align"
                                                          }
                                                        },
                                                        [_vm._v("自定义")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              : _vm.ruleForm["share"][
                                                  _vm.shareComponentKey
                                                ] &&
                                                _vm.ruleForm["share"][
                                                  _vm.shareComponentKey
                                                ]["file_type"] == "text"
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "文本内容"
                                                      }
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].text,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "text",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].text"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "大小" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 12,
                                                          max: 200,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].font,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "font",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].font"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "文本宽度"
                                                      }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 30,
                                                          max: _vm.divWidth,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].width,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "width",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].width"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "上间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 0,
                                                          max:
                                                            _vm.ruleForm.share
                                                              .divHeight -
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].font,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].top,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "top",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].top"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "左间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].align ===
                                                              "left" ||
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].align ===
                                                              "center" ||
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].align === "right"
                                                              ? true
                                                              : _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ].align ===
                                                                "none"
                                                              ? false
                                                              : "",
                                                          min: 0,
                                                          max:
                                                            _vm.divWidth -
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].font,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].left,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "left",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].left"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "颜色" }
                                                    },
                                                    [
                                                      _c("el-color-picker", {
                                                        staticStyle: {
                                                          "margin-left": "20px"
                                                        },
                                                        attrs: {
                                                          "color-format": "rgb",
                                                          predefine:
                                                            _vm.predefineColors
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].color,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "color",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].color"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "对齐方式"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "left"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ].align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ],
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share[shareComponentKey].align"
                                                          }
                                                        },
                                                        [_vm._v("左对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "center"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ].align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ],
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share[shareComponentKey].align"
                                                          }
                                                        },
                                                        [_vm._v("居中对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "right"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ].align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ],
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share[shareComponentKey].align"
                                                          }
                                                        },
                                                        [_vm._v("右对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "none"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ].align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ],
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share[shareComponentKey].align"
                                                          }
                                                        },
                                                        [_vm._v("自定义")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              : _vm.ruleForm["share"][
                                                  _vm.shareComponentKey
                                                ] &&
                                                _vm.ruleForm["share"][
                                                  _vm.shareComponentKey
                                                ]["file_type"] == "line"
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "宽度" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 1,
                                                          max: _vm.divWidth,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].width,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "width",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].width"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "高度" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 1,
                                                          max:
                                                            _vm.ruleForm.share
                                                              .divHeight,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].height,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "height",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].height"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "上间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 0,
                                                          max:
                                                            _vm.ruleForm.share
                                                              .divHeight -
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].height,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].top,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "top",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].top"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "左间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].align ===
                                                              "left" ||
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].align ===
                                                              "center" ||
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].align === "right"
                                                              ? true
                                                              : _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ].align ===
                                                                "none"
                                                              ? false
                                                              : "",
                                                          min: 0,
                                                          max:
                                                            _vm.divWidth -
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].width,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].left,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "left",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].left"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "颜色" }
                                                    },
                                                    [
                                                      _c("el-color-picker", {
                                                        staticStyle: {
                                                          "margin-left": "20px"
                                                        },
                                                        attrs: {
                                                          "color-format": "rgb",
                                                          predefine:
                                                            _vm.predefineColors
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].color,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "color",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].color"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "对齐方式"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "left"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ].align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ],
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share[shareComponentKey].align"
                                                          }
                                                        },
                                                        [_vm._v("左对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "center"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ].align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ],
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share[shareComponentKey].align"
                                                          }
                                                        },
                                                        [_vm._v("居中对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "right"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ].align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ],
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share[shareComponentKey].align"
                                                          }
                                                        },
                                                        [_vm._v("右对齐")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label: "none"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ].align,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ],
                                                                "align",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share[shareComponentKey].align"
                                                          }
                                                        },
                                                        [_vm._v("自定义")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              : _vm.ruleForm["share"][
                                                  _vm.shareComponentKey
                                                ] &&
                                                _vm.ruleForm["share"][
                                                  _vm.shareComponentKey
                                                ]["file_type"] == "image"
                                              ? [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "图片地址"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-input",
                                                        {
                                                          model: {
                                                            value:
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ].src,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleForm
                                                                  .share[
                                                                  _vm
                                                                    .shareComponentKey
                                                                ],
                                                                "src",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleForm.share[shareComponentKey].src"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [
                                                              _c(
                                                                "el-upload",
                                                                {
                                                                  staticClass:
                                                                    "upload-demo",
                                                                  attrs: {
                                                                    action:
                                                                      _vm.storeUpload,
                                                                    "before-remove":
                                                                      _vm.beforeRemove,
                                                                    "on-exceed":
                                                                      _vm.handleExceed,
                                                                    "on-success":
                                                                      _vm.upSuccess,
                                                                    "on-progress":
                                                                      _vm.upProgress,
                                                                    headers: _vm.utils.upload_headers(),
                                                                    "show-file-list": false
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        size:
                                                                          "small",
                                                                        type:
                                                                          "primary"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "点击上传"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "大小" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 80,
                                                          max: _vm.divWidth,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].size,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "size",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].size"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "上间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 0,
                                                          max:
                                                            _vm.ruleForm.share
                                                              .divHeight,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].top,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "top",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].top"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: { label: "左间距" }
                                                    },
                                                    [
                                                      _c("el-slider", {
                                                        attrs: {
                                                          min: 0,
                                                          max:
                                                            _vm.divWidth -
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].size,
                                                          "show-input": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.share[
                                                              _vm
                                                                .shareComponentKey
                                                            ].left,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm
                                                                .share[
                                                                _vm
                                                                  .shareComponentKey
                                                              ],
                                                              "left",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.share[shareComponentKey].left"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "p-y-20" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "button-item",
                                            attrs: {
                                              loading: _vm.btnLoading,
                                              type: "info",
                                              size: "small"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.returnPage()
                                              }
                                            }
                                          },
                                          [_vm._v(" 返 回 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "button-item",
                                            attrs: {
                                              loading: _vm.btnLoading,
                                              type: "warning",
                                              size: "small"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.buildPreview(1)
                                              }
                                            }
                                          },
                                          [_vm._v(" 生 成 ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预览海报",
            visible: _vm.downImg,
            width: "30%",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.downImg = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("el-image", {
                ref: "preview",
                staticStyle: { width: "260px" },
                attrs: {
                  src: _vm.viewUrl,
                  fit: "scale-down",
                  "preview-src-list": [_vm.viewUrl]
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.preview.clickHandler()
                    }
                  }
                },
                [_vm._v("预 览")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.imgDown } },
                [_vm._v("下 载")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }