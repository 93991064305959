<template>
  <div class="title_style">
    <el-form ref="ruleForm" :model="form" :rules="formRules" label-position="left" label-width="140px">
      <el-form-item label="手机号码" class="formItemMargin">
        <el-input v-model="users.phone" placeholder="请输入手机号" disabled>
          <div slot="prepend">
            <el-select v-model="users.area_code" disabled filterable placeholder="请选择" style="min-width:80px;">
              <el-option-group
                v-for="(group, index) in areaCodeList"
                :key="index"
                :label="group.label"
                class="min-width:200px"
              >
                <el-option
                  v-for="(item, indexs) in group.options"
                  :key="indexs"
                  :label="item.value"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;padding-left:100px">{{ item.value }}</span>
                </el-option>
              </el-option-group>
            </el-select>
          </div>
        </el-input>
      </el-form-item>
      <el-form-item label="请输入原密码" prop="password" class="formItemMargin">
        <el-input v-model="form.password" placeholder="请输入原密码" show-password />
      </el-form-item>
      <el-form-item label="输入新的手机号码" prop="phone" class="formItemMargin warningInput">
        <el-input v-model="form.phone" placeholder="输入新的手机号码">
          <div slot="prepend">
            <el-select v-model="area_code" filterable placeholder="请选择" style="min-width:80px;">
              <el-option-group
                v-for="(group, index) in areaCodeList"
                :key="index"
                :label="group.label"
                class="min-width:200px"
              >
                <el-option
                  v-for="(item, indexs) in group.options"
                  :key="indexs"
                  :label="item.value"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;padding-left:100px">{{ item.value }}</span>
                </el-option>
              </el-option-group>
            </el-select>
          </div>
        </el-input>
        <div class="warning cursor">
          <el-tooltip
            class="item"
            effect="dark"
            content="警告：修改手机号后，将自动重置登录密码并发送到新手机号中，老密码将无法登录"
            placement="top"
          >
            <i class="el-icon-warning" />
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item label="验证码" prop="code" class="formItemMargin">
        <el-input v-model="form.code" placeholder="请输入验证码">
          <div slot="append" class="verificationCode cursor" @click="getCodes">
            {{ getCodeText }}
          </div>
        </el-input>
      </el-form-item>
      <el-form-item class="formItemMargin">
        <el-button type="primary" @click="onSubmit('ruleForm')">立即修改</el-button>
        <!-- <el-button>取消</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import areaCode from '@/components/commonData/area_code.json'
import { getUserInfo, putChangePhoneNumber } from '@/api/store/user.js'
import { SendCode } from '@/api/store/user.js'

export default {
  props: {
    user: {
      type: Object,
      default: function() {
        return { name: '' }
      }
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空！'))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('验证码不能为空！'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('原密码不能为空！'))
      } else {
        callback()
      }
    }
    return {
      users: {
        phone: '',
        area_code: ''
      },
      form: {
        area_code: '',
        code: '',
        phone: '',
        password: ''
      },
      area_code: '+86',
      formRules: {
        code: [{ validator: validateCode, trigger: 'blur' }],
        phone: [{ validator: validatePhone, trigger: 'blur' }],
        password: [{ validator: validatePassword, trigger: 'blur' }]
      },
      getCodeText: '获取验证码',
      areaCodeList: areaCode,
      is_whether: true
    }
  },
  mounted() {
    this.getUserDetails()
  },
  methods: {
    getUserDetails() {
      getUserInfo()
        .then(res => {
          this.users.phone = res.data.phone
          this.users.area_code = '+' + res.data.area_code
        })
        .catch(() => {})
    },
    getCodes() {
      if (!this.form.phone) {
        this.$message({
          type: 'error',
          message: '请输入新的手机号码'
        })
        return
      }
      if (this.is_whether) {
        this.is_whether = !this.is_whether
        const data = {
          phone: this.form.phone,
          // phone: 17687927154,
          type: 1,
          area_code: (this.form.area_code = this.area_code === '+86' ? '86' : this.area_code.replace(/[^0-9]/gi, '')),
          is_login: 0
        }
        SendCode(data).then(res => {
          this.$message({
            type: 'success',
            message: '验证码已发送，请注意查收短信'
          })
          // 开始计时
          var stime = 60
          var dtime = setInterval(() => {
            stime--
            if (stime === 0) {
              clearInterval(dtime)
              this.is_whether = !this.is_whether
              this.getCodeText = '重新获取验证码'
            } else {
              this.getCodeText = stime + ' 秒后重试'
            }
          }, 1000)
        })
      } else if (!this.is_whether) {
        return
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.form.area_code = this.area_code === '+86' ? '86' : this.area_code.replace(/[^0-9]/gi, '')
          console.log(this.form)
          putChangePhoneNumber(this.form).then(() => {
            this.$store.dispatch('user/logout')
            this.$router.push(`/login`)
            this.$message({ type: 'success', message: '手机号码修改成功!' })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.verificationCode {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formItemMargin {
  margin-top: 30px;
}
.warningInput {
  position: relative;
}
.warning {
  position: absolute;
  top: 0px;
  left: 100%;
  font-size: 16px;
  color: red;
}
.title_style {
  width: 45%;
}
@media screen and (max-width: 768px) {
  .title_style {
    width: 100%;
  }
}
</style>
