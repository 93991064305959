/**
 * 商户路由
 */

import Layout from '@/layout'
import Layout2 from '@/layout/page'

const store = [
  // 数据页
  // {
  //   path: '/store/worktable',
  //   component: Layout,
  //   redirect: '/store/worktable',
  //   meta: {
  //     title: 's_worktable',
  //     icon: 'el-icon-notebook-2',
  //     roles: ['store']
  //   },
  //   children: [{
  //     path: 'index',
  //     component: () => import('@/views/store/worktable/index'),
  //     name: 's_worktable',
  //     meta: {
  //       title: 's_worktable'
  //     }
  //   }
  //   ]
  // },
  {
    name: 's_remind',
    path: '/store',
    component: Layout,
    redirect: '/store/remind/userCardValidity',
    meta: {
      title: 's_worktable_remind',
      icon: 'el-icon-bell',
      roles: ['store'],
      noCache: true
    },
    children: [
      {
        path: 'remind',
        name: 's_worktable_remind',
        redirect: '/store/userCardValidity',
        component: () => import('@/views/store/remind/index'),
        hidden: true,
        children: [{
          path: 'userCardValidity',
          component: () => import('@/views/store/remind/userCardValidity/index'),
          name: 's_user_card_validity',
          meta: {
            title: 's_user_card_validity',
            activeMenu: '/store'
          },
          hidden: true
        }, {
          path: 'userCardBalance',
          component: () => import('@/views/store/remind/userCardBalance/index'),
          name: 's_user_card_balance',
          meta: {
            title: 's_user_card_balance',
            activeMenu: '/store'
          },
          hidden: true
        }, {
          path: 'userBirthday',
          component: () => import('@/views/store/remind/userBirthday/index'),
          name: 's_user_birthday',
          meta: {
            title: 's_user_birthday',
            activeMenu: '/store'
          },
          hidden: true
        }, {
          path: 'userNotClass',
          component: () => import('@/views/store/remind/userNotClass/index'),
          name: 's_user_notclass',
          meta: {
            title: 's_user_notclass',
            activeMenu: '/store'
          },
          hidden: true
        }, {
          path: 'userCardOpen',
          component: () => import('@/views/store/remind/userCardOpen/index'),
          name: 's_user_card_open',
          meta: {
            title: 's_user_card_open',
            activeMenu: '/store'
          },
          hidden: true
        }, {
          path: 'userCardLeaveExpire',
          component: () => import('@/views/store/remind/userCardLeaveExpire/index'),
          name: 's_user_card_leave_expire',
          meta: {
            title: 's_user_card_leave_expire',
            activeMenu: '/store'
          },
          hidden: true
        }, {
          path: 'userSouvenir',
          component: () => import('@/views/store/remind/userSouvenir/index'),
          name: 's_user_souvenir',
          meta: {
            title: 's_user_souvenir',
            activeMenu: '/store'
          },
          hidden: true
        }]
      }

    ]
  },
  {
    name: 's_record',
    path: '/store/record',
    component: Layout,
    redirect: '/store/record',
    meta: {
      title: 's_record',
      icon: 'el-icon-data-line',
      roles: ['store']
    },
    children: [{
      path: 'group',
      component: () => import('@/views/store/record/group/index'),
      name: 's_group_list',
      meta: {
        title: 's_group_list'
      }
    },
    {
      path: 'fine',
      component: () => import('@/views/store/record/fine/index'),
      name: 's_fine_list',
      meta: {
        title: 's_fine_list'
      }
    },
    {
      path: 'private',
      component: () => import('@/views/store/record/private/index'),
      name: 's_record_private_list',
      meta: {
        title: 's_record_private_list'
      }
    },
    {
      path: 'vipCardChangeRecord',
      component: () => import('@/views/store/user/vipCard/change_record'),
      name: 's_user_vipcard_change_record',
      meta: {
        title: 's_user_vipcard_change_record'
      }
    },
    {
      path: 'returnVisit',
      component: () => import('@/views/store/worktable/returnVisit'),
      name: 's_work_returnVisit',
      meta: {
        title: 's_work_returnVisit'
      }
    }]
  },
  {
    name: 's_management',
    path: '/store/management',
    component: Layout,
    redirect: '/store/management/index',
    alwaysShow: true,
    meta: {
      title: 's_management',
      icon: 'el-icon-s-custom',
      roles: ['brand']
    },
    children: [{
      path: 'index',
      component: () => import('@/views/store/management/index'),
      name: 's_management_list',
      meta: {
        title: 's_management_list'
      }
    }, {
      path: 'add',
      component: () => import('@/views/store/management/add'),
      name: 's_management_add',
      meta: {
        title: 's_management_add',
        activeMenu: '/store/management/index'
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: () => import('@/views/store/management/edit'),
      name: 's_management_edit',
      meta: {
        title: 's_management_edit',
        activeMenu: '/store/management/index'
      },
      hidden: true
    }
    ]
  },
  {
    name: 's_user',
    path: '/store/user',
    component: Layout,
    redirect: '/store/user/index',
    meta: {
      title: 's_user',
      icon: 'el-icon-user',
      roles: ['store']
    },
    children: [{
      path: 'index',
      component: () => import('@/views/store/user/index'),
      name: 's_user_list',
      meta: {
        title: 's_user_list'
      }
    }, {
      path: 'user_info/:id(\\d+)',
      component: () => import('@/views/store/user/user_info'),
      name: 's_user_info',
      meta: {
        title: 's_user_info',
        activeMenu: '/store/user/index'
      },
      hidden: true
    }, {
      path: 'visitors_info/:id(\\d+)',
      component: () => import('@/views/store/user/user_info'),
      name: 's_visitors_info',
      meta: {
        title: 's_visitors_info',
        activeMenu: '/store/user/visitors'
      },
      hidden: true
    },
    {
      path: 'vipCard',
      component: () => import('@/views/store/user/vipCard/index'),
      name: 's_user_vipcard_list',
      meta: {
        title: 's_user_vipcard'
      }
    }, {
      path: 'visitors',
      component: () => import('@/views/store/visitors/index'),
      name: 's_visitors_list',
      meta: {
        title: 's_visitors'
        // roles: ['store']
      }
    },
    // {
    //   path: 'inquire',
    //   name: 's_work_inquire',
    //   component: () => import('@/views/store/worktable/inquire'),
    //   meta: {
    //     title: 's_work_inquire',
    //     activeMenu: '/store/user/inquire'
    //   }
    // },
    {
      path: 'card',
      component: () => import('@/views/store/membership/card/index'),
      name: 's_membership_card',
      meta: {
        title: 's_membership_card'
      }
    },
    {
      path: 'card/edit/:id(\\d+)',
      component: () => import('@/views/store/membership/card/edit'),
      name: 's_membership_card_edit',
      meta: {
        title: 's_membership_card_edit',
        noCache: true,
        activeMenu: '/store/user/card'
      },
      hidden: true
    },
    {
      path: 'card/add',
      component: () => import('@/views/store/membership/card/add'),
      name: 's_membership_card_add',
      meta: {
        title: 's_membership_card_add',
        noCache: true,
        activeMenu: '/store/user/card'
      },
      hidden: true
    },
    {
      path: 'teamwork/index',
      component: () => import('@/views/store/membership/combination/index'),
      name: 's_combination_card',
      meta: {
        title: 's_combination_card'
      }
    },
    {
      path: 'vipCardAdd/:user_id(\\d+)/:card_id(\\d+)',
      component: () => import('@/views/store/user/vipCard/add'),
      name: 's_user_vipcard_add',
      meta: {
        title: 's_user_vipcard_add',
        activeMenu: '/store/user/vipCard'
      },
      hidden: true
    },
    // {
    //   path: 'vipCardAdd/leaveManagement',
    //   component: () => import('@/views/store/takeleave/leaveManagement'),
    //   name: 's_leaveManagement',
    //   meta: {
    //     title: 's_leaveManagement',
    //     activeMenu: '/store/user/vipCard'
    //   },
    //   hidden: true
    // },
    // {
    //   path: 'vipCardAdd/leaveManagement/edit/:id(\\d+)',
    //   component: () => import('@/views/store/takeleave/edit'),
    //   name: 's_takeLeave_edit',
    //   meta: {
    //     title: 's_takeLeave_edit'
    //   },
    //   hidden: true
    // },
    {
      path: 'card_info/:id(\\d+)',
      component: () => import('@/views/store/user/vipCard/card_info'),
      name: 's_user_vipcard_info',
      meta: {
        title: 's_user_vipcard_info',
        activeMenu: '/store/user/vipCard'
      },
      hidden: true
    }, {
      path: 'fianco/add/:id(\\d+)',
      component: () => import('@/views/store/user/fianco/add'),
      name: 's_user_fianco_add',
      meta: {
        title: 's_user_fianco_add',
        activeMenu: '/store/user/index'
      },
      hidden: true
    }, {
      path: 'fianco/edit/:id(\\d+)',
      component: () => import('@/views/store/user/fianco/edit'),
      name: 's_user_fianco_edit',
      meta: {
        title: 's_user_fianco_edit',
        activeMenu: '/store/user/index'
      },
      hidden: true
    }, {
      path: 'import',
      component: () => import('@/views/store/user/import/member'),
      name: 's_user_vipcard_import',
      meta: {
        title: 's_user_vipcard_import',
        activeMenu: '/store/user/index'
      },
      hidden: true
    }]
  },
  {
    name: 's_user',
    path: '/store/user',
    component: Layout,
    redirect: '/store/user/index',
    meta: {
      title: 's_user',
      icon: 'el-icon-user',
      roles: ['brand']
    },
    children: [
      {
        path: 'card',
        component: () => import('@/views/store/membership/card/index'),
        name: 's_membership_card',
        meta: {
          title: 's_membership_cardS'
        }
      },
      {
        path: 'card/edit/:id(\\d+)',
        component: () => import('@/views/store/membership/card/edit'),
        name: 's_membership_card_edit',
        meta: {
          title: 's_membership_card_edit',
          noCache: true,
          activeMenu: '/store/user/card'
        },
        hidden: true
      },
      {
        path: 'card/add',
        component: () => import('@/views/store/membership/card/add'),
        name: 's_membership_card_add',
        meta: {
          title: 's_membership_card_add',
          noCache: true,
          activeMenu: '/store/user/card'
        },
        hidden: true
      }
    ]
  },
  {
    name: 's_course',
    path: '/store/course',
    component: Layout,
    redirect: '/store/course/index',
    meta: {
      title: 's_course',
      icon: 'el-icon-date',
      roles: ['store']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/store/course/index'),
        name: 's_course_list',
        meta: {
          title: 's_course_list',
          noCache: true,
          activeMenu: '/store/course/index'
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import('@/views/store/course/edit'),
        name: 's_course_edit',
        meta: {
          title: 's_course_edit',
          noCache: true,
          activeMenu: '/store/course/index'
        },
        hidden: true
      },
      {
        path: 'add',
        component: () => import('@/views/store/course/add'),
        name: 's_course_add',
        meta: {
          title: 's_course_add',
          noCache: true,
          activeMenu: '/store/course/index'
        },
        hidden: true
      },
      {
        path: 'curriculum',
        component: () => import('@/views/store/course/curriculum/index'),
        name: 's_course_curriculum',
        meta: {
          title: 's_course_curriculum'
        }
      },
      {
        path: 'classfee',
        component: () => import('@/views/store/course/classfee/index'),
        name: 's_classfee_list',
        meta: {
          title: 's_classfee_list'
        }
      },
      {
        path: 'small',
        name: 's_mclass',
        redirect: '/store/course/small/classmanagement',
        component: () => import('@/views/store/course/small/index'),
        meta: {
          title: 's_mclass'
        },
        children: [
          {
            path: 'classmanagement',
            component: () => import('@/views/store/course/small/classmanagement/index'),
            name: 's_mclass_classmanagement',
            meta: {
              title: 's_mclass_classmanagement',
              activeMenu: '/store/course/small'
            },
            hidden: true
          },
          {
            path: 'classmanagement/:operate/:id',
            component: () => import('@/views/store/course/small/classmanagement/operate'),
            name: 's_mclass_classmanagement_operate',
            meta: {
              title: 's_mclass_classmanagement_operate',
              noCache: true,
              activeMenu: '/store/course/small'
            },
            hidden: true
          },
          {
            path: 'classmanagement/:id',
            component: () => import('@/views/store/course/small/classmanagement/check'),
            name: 's_mclass_classmanagement_check',
            meta: {
              title: 's_mclass_classmanagement_check',
              noCache: true,
              activeMenu: '/store/course/small'
            },
            hidden: true
          },
          {
            path: 'classmanagement/:id',
            component: () => import('@/views/store/course/small/classmanagement/signup'),
            name: 's_mclass_classmanagement_signup',
            meta: {
              title: 's_mclass_classmanagement_signup',
              noCache: true,
              activeMenu: '/store/course/small'
            },
            hidden: true
          },
          {
            path: 'classmanagement/record/:id',
            component: () => import('@/views/store/course/small/classmanagement/record'),
            name: 's_mclass_classmanagement_record',
            meta: {
              title: 's_mclass_classmanagement_record',
              noCache: true,
              activeMenu: '/store/course/small'
            },
            hidden: true
          },
          {
            path: 'classmanagement/sales/:id',
            component: () => import('@/views/store/course/small/classmanagement/sales'),
            name: 's_mclass_classmanagement_sales',
            meta: {
              title: 's_mclass_classmanagement_sales',
              noCache: true,
              activeMenu: '/store/course/small'
            },
            hidden: true
          },
          {
            path: 'coursemanagement',
            component: () => import('@/views/store/course/small/coursemanagement/index'),
            name: 's_mclass_coursemanagement',
            meta: {
              title: 's_mclass_coursemanagement',
              activeMenu: '/store/course/small'
            },
            hidden: true
          },
          {
            path: 'classschedule',
            component: () => import('@/views/store/course/small/classschedule/index'),
            name: 's_mclass_classschedule',
            meta: {
              title: 's_mclass_classschedule',
              activeMenu: '/store/course/small'
            },
            hidden: true
          },
          {
            path: 'vipmanagement',
            component: () => import('@/views/store/course/small/vipmanagement/index'),
            name: 's_mclass_vipmanagement',
            meta: {
              title: 's_mclass_vipmanagement',
              activeMenu: '/store/course/small'
            },
            hidden: true
          },
          {
            path: 'datastatistics',
            component: () => import('@/views/store/course/small/datastatistics/index'),
            name: 's_mclass_datastatistics',
            meta: {
              title: 's_mclass_datastatistics',
              activeMenu: '/store/course/small'
            },
            hidden: true
          }
        ]
      }
    ]
  },
  {
    name: 's_venues',
    path: '/store/venues',
    component: Layout,
    redirect: '/store/venues/index', // 商户端实际配置跳转地址为/store/venues/edit
    meta: {
      title: 's_venues',
      icon: 'el-icon-school',
      roles: ['store', 'brand']
    },
    children: [{
      path: 'index',
      component: () => import('@/views/store/venues/index'),
      name: 's_venues_list',
      meta: {
        title: 's_venues_list',
        roles: ['brand']
      }
    },
    {
      path: 'add',
      component: () => import('@/views/store/venues/add'),
      name: 's_venues_add',
      meta: {
        title: 's_venues_add',
        noCache: true,
        activeMenu: '/store/venues/index'
      },
      hidden: true
    },
    {
      path: 'edit/:id(\\d+)',
      component: () => import('@/views/store/venues/edit'),
      name: 's_venues_edit',
      meta: {
        title: 's_venues_edit',
        noCache: true,
        activeMenu: '/store/venues/index'
      },
      hidden: true
    },
    {
      path: `edit`,
      component: () => import('@/views/store/venues/edit'),
      name: 's_venues_setting',
      meta: {
        title: 's_venues_setting',
        noCache: true,
        activeMenu: '/store/venues/edit',
        roles: ['store']
      }
    },
    {
      path: `holiday`,
      component: () => import('@/views/store/venues/holiday'),
      name: 's_venues_holiday',
      meta: {
        title: 's_venues_holiday',
        noCache: true,
        activeMenu: '/store/venues/holiday',
        roles: ['store']
      }
    },
    {
      path: 'classroom',
      component: () => import('@/views/store/venues/classroom/index'),
      name: 's_venues_classroom',
      meta: {
        title: 's_venues_classroom',
        roles: ['store']
      }
    },
    {
      path: 'notice',
      component: () => import('@/views/store/notice/index'),
      name: 's_notice',
      meta: {
        title: 's_notice',
        roles: ['store', 'brand']
      }
    },
    // 场馆管理 ----> 轮播图管理
    // {
    //   path: 'banner',
    //   component: () => import('@/views/store/banner/index'),
    //   name: 's_banner',
    //   meta: {
    //     title: 's_banner',
    //     roles: ['store', 'brand']
    //   }
    // },
    {
      path: 'employee',
      component: () => import('@/views/store/venues/employee/index'),
      name: 's_venues_employee',
      meta: {
        title: 's_venues_employee',
        roles: ['store', 'brand']
      }
    },
    {
      path: 'employee/add',
      component: () => import('@/views/store/venues/employee/add'),
      name: 's_venues_employee_add',
      meta: {
        title: 's_venues_employee_add',
        activeMenu: '/store/venues/employee'
      },
      hidden: true
    },
    {
      path: 'employee/edit/:id(\\d+)',
      component: () => import('@/views/store/venues/employee/edit'),
      name: 's_venues_employee_edit',
      meta: {
        title: 's_venues_employee_edit',
        noCache: true,
        activeMenu: '/store/venues/employee'
      },
      hidden: true
    },
    {
      path: 'employee/details/:id(\\d+)/:store_user_id(\\d+)/:venues_id(\\d+)',
      component: () => import('@/views/store/venues/employee/details'),
      name: 's_venues_employee_info',
      meta: {
        title: 's_venues_employee_info',
        noCache: true,
        activeMenu: '/store/venues/employee'
      },
      hidden: true
    }
    ]
  },
  {
    name: 's_setting',
    path: '/store/setting',
    component: Layout,
    redirect: '/',
    alwaysShow: true, // 总是显示根菜单吗
    meta: {
      title: 's_setting',
      icon: 'el-icon-setting',
      roles: ['store', 'brand']
    },
    children: [
      {
        path: 'config',
        component: () => import('@/views/brand/config/index'),
        name: 's_store_config',
        meta: {
          title: 's_store_config',
          roles: ['brand']
        }
      },
      {
        path: 'wxconfig',
        component: () => import('@/views/store/settings/wxconfig/index'),
        name: 's_store_wxconfig',
        meta: {
          title: 's_store_wxconfig',
          roles: ['store', 'brand']
        }
      },
      {
        path: 'wxconfig_template',
        component: () => import('@/views/store/settings/wxconfig/template'),
        name: 's_store_wxconfig_template',
        meta: {
          title: 's_store_wxconfig_template',
          roles: ['store', 'brand']
        }
      },
      {
        path: 'remind_setting',
        component: () => import('@/views/store/settings/remind_setting/index'),
        name: 's_remind_setting',
        meta: {
          title: 's_remind_setting'
        }
      },
      {
        path: 'role',
        component: () => import('@/views/store/management/role/index'),
        name: 's_management_role',
        meta: {
          title: 's_management_role',
          roles: ['brand']
        }
      },
      {
        path: 'role/add',
        component: () => import('@/views/store/management/role/add'),
        name: 's_management_role_add',
        meta: {
          title: 's_management_role_add',
          noCache: true,
          activeMenu: '/store/setting/role',
          roles: ['brand']
        },
        hidden: true
      }, {
        path: 'role/edit/:id(\\d+)',
        component: () => import('@/views/store/management/role/edit'),
        name: 's_management_role_edit',
        meta: {
          title: 's_management_role_edit',
          noCache: true,
          activeMenu: '/store/setting/role',
          roles: ['brand']
        },
        hidden: true
      },
      {
        path: 'orderRule',
        name: 's_orderRule',
        redirect: '/store/setting/orderRule/orderRuleForCommon',
        component: () => import('@/views/store/settings/orderRule/index'),
        meta: {
          title: 's_orderRule'
        },
        children: [
          {
            path: 'orderRuleForCommon',
            component: () => import('@/views/store/settings/orderRule/orderRuleForCommon/index'),
            name: 's_orderRuleForCommon',
            meta: {
              title: 's_orderRuleForCommon',
              activeMenu: '/store/setting/orderRule'
            },
            hidden: true
          },
          {
            path: 'orderRuleForLeague',
            component: () => import('@/views/store/settings/orderRule/orderRuleForLeague/index'),
            name: 's_orderRuleForLeague',
            meta: {
              title: 's_orderRuleForLeague',
              activeMenu: '/store/setting/orderRule'
            },
            hidden: true
          },
          {
            path: 'orderRuleForFine',
            component: () => import('@/views/store/settings/orderRule/orderRuleForFine/index'),
            name: 's_orderRuleForFine',
            meta: {
              title: 's_orderRuleForFine',
              activeMenu: '/store/setting/orderRule'
            },
            hidden: true
          },
          {
            path: 'orderRuleForPrivate',
            component: () => import('@/views/store/settings/orderRule/orderRuleForPrivate/index'),
            name: 's_orderRuleForPrivate',
            meta: {
              title: 's_orderRuleForPrivate',
              activeMenu: '/store/setting/orderRule'
            },
            hidden: true
          }
        ]
      },
      {
        path: 'orderRuleSetting',
        name: 's_orderRuleSetting',
        redirect: '/store/setting/orderRule/orderRuleForCommon',
        component: () => import('@/views/store/settings/orderRule/setting'),
        meta: {
          title: 's_orderRuleSetting'
        },
        hidden: true,
        children: [
          {
            path: 'orderRuleForCommonSetting',
            component: () => import('@/views/store/settings/orderRule/orderRuleForCommon/setting'),
            name: 's_orderRuleForCommonSetting',
            meta: {
              title: 's_orderRuleForCommonSetting',
              activeMenu: '/store/setting/orderRule'
            },
            hidden: true
          },
          {
            path: 'orderRuleForLeagueSetting',
            component: () => import('@/views/store/settings/orderRule/orderRuleForLeague/setting'),
            name: 's_orderRuleForLeagueSetting',
            meta: {
              title: 's_orderRuleForLeagueSetting',
              activeMenu: '/store/setting/orderRule'
            },
            hidden: true
          },
          {
            path: 'orderRuleForFineSetting',
            component: () => import('@/views/store/settings/orderRule/orderRuleForFine/setting'),
            name: 's_orderRuleForFineSetting',
            meta: {
              title: 's_orderRuleForFineSetting',
              activeMenu: '/store/setting/orderRule'
            },
            hidden: true
          },
          {
            path: 'orderRuleForPrivateSetting',
            component: () => import('@/views/store/settings/orderRule/orderRuleForPrivate/setting'),
            name: 's_orderRuleForPrivateSetting',
            meta: {
              title: 's_orderRuleForPrivateSetting',
              activeMenu: '/store/setting/orderRule'
            },
            hidden: true
          }
        ]
      },
      {
        path: 'log',
        component: () => import('@/views/store/record/log/index'),
        name: 's_log',
        meta: {
          title: 's_log'
        }
      },
      {
        path: 'side_template',
        component: () => import('@/views/store/settings/side_template/index'),
        name: 's_side_template',
        meta: {
          title: 's_side_template'
        }
      }
    ]
  },
  {
    name: 's_data',
    path: '/store/data',
    component: Layout,
    redirect: '/',
    alwaysShow: true,
    meta: {
      title: 's_data',
      icon: 'el-icon-data-analysis',
      roles: ['store', 'brand']
    },
    children: [{
      path: 'class_statistics',
      component: () => import('@/views/store/data/class_statistics/index'),
      name: 's_class_statistics',
      meta: {
        title: 's_class_statistics',
        roles: ['store', 'brand']
      }
    }, {
      path: 'card_total',
      component: () => import('@/views/store/data/card_total/index'),
      name: 's_card_total',
      meta: {
        title: 's_card_total',
        roles: ['store', 'brand']
      }
    }, {
      path: 'card_management_statistics',
      component: () => import('@/views/store/data/card_management_statistics/index'),
      name: 's_card_management_statistics',
      meta: {
        title: 's_card_management_statistics',
        roles: ['store', 'brand']
      }
    }, {
      path: 'leaveStatistics',
      component: () => import('@/views/store/takeleave/leaveStatistics'),
      name: 's_leaveStatistics',
      meta: {
        title: 's_leaveStatistics',
        roles: ['store', 'brand']
      }
    }, {
      path: 'leaveStatistics/edit/:id(\\d+)',
      component: () => import('@/views/store/takeleave/edit'),
      name: 's_takeLeave_edits',
      meta: {
        title: 's_takeLeave_edits',
        activeMenu: '/store/data/leaveStatistics'
      },
      hidden: true
    }, {
      path: 'comprehensive_ranking',
      component: () => import('@/views/store/data/comprehensive_ranking/index'),
      name: 's_ranking',
      meta: {
        title: 's_ranking',
        roles: ['store']
      }
    }, {
      path: 'asset_statistics',
      component: () => import('@/views/store/data/asset_statistics/index'),
      name: 's_finance_overview',
      meta: {
        title: 's_asset_statistics',
        roles: ['store']
      }
    }]
  },
  {
    name: 's_withdraw',
    path: '/store/withdraw',
    component: Layout,
    redirect: '/store/withdraw',
    children: [{
      path: 'index',
      component: () => import('@/views/store/withdraw/index'),
      name: 's_withdraw',
      meta: {
        title: 's_withdraw',
        icon: 'el-icon-goods',
        roles: ['brand']
      }
    }]
  },
  {
    name: 's_withdraw_apply',
    path: '/store/withdraw_apply',
    component: Layout2,
    children: [{
      path: 'index',
      component: () => import('@/views/store/withdraw/apply'),
      name: 's_withdraw_apply',
      meta: {
        title: 's_withdraw_apply',
        icon: 'el-icon-goods',
        roles: ['brand', 'store']
      },
      hidden: true
    }]
  },
  {
    name: 's_comments',
    path: '/store/evaluation',
    component: Layout,
    redirect: '/store/evaluation',
    meta: {
      title: 's_comments',
      icon: 'el-icon-s-comment',
      roles: ['store']
    },
    children: [
      {
        path: 'comments',
        component: () => import('@/views/store/evaluation/comments'),
        name: 's_comments_list',
        meta: {
          title: 's_comments_list'
        }
      },
      {
        path: 'commentsVirtual',
        component: () => import('@/views/store/evaluation/commentsVirtual'),
        name: 's_commentsVirtual_list',
        meta: {
          title: 's_commentsVirtual_list'
        }
      },
      {
        path: 'add',
        component: () => import('@/views/store/evaluation/add'),
        name: 's_commentsVirtual_add',
        meta: {
          title: 's_commentsVirtual_add',
          noCache: true,
          activeMenu: '/store/evaluation/commentsVirtual'
        },
        hidden: true
      }
    ]
  },
  {
    name: 's_seckill',
    path: '/store/seckill',
    component: Layout,
    redirect: '/store/seckill/index',
    meta: {
      icon: 'el-icon-goods',
      title: 's_seckill',
      roles: ['store']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/store/seckill'),
        name: 's_seckill_list',
        meta: {
          title: 's_seckill_list'
        }
      },
      {
        path: 'details/:id(\\d+)',
        component: () => import('@/views/store/seckill/details'),
        name: 's_seckill_details',
        meta: {
          title: 's_seckill_details',
          activeMenu: '/store/seckill/index'
        },
        hidden: true
      },
      {
        path: 'comment/:id(\\d+)',
        component: () => import('@/views/store/seckill/comment'),
        name: 's_seckill_comment',
        meta: {
          title: 's_seckill_comment',
          activeMenu: '/store/seckill/index'
        },
        hidden: true
      },
      {
        path: 'add',
        component: () => import('@/views/store/seckill/add'),
        name: 's_seckill_add',
        meta: {
          title: 's_seckill_add',
          activeMenu: '/store/seckill/index'
        },
        hidden: true
      },
      {
        path: 'edit/:id(\\d+)/:mallType(\\d+)',
        component: () => import('@/views/store/seckill/edit'),
        name: 's_seckill_edit',
        meta: {
          title: 's_seckill_edit',
          activeMenu: '/store/seckill/index'
        },
        hidden: true
      },
      // {
      //   path: 'sellingCards',
      //   component: () => import('@/views/store/seckill/sellingCards/index'),
      //   name: 's_sellingCards',
      //   meta: {
      //     title: 's_sellingCards'
      //   }
      // },
      // {
      //   path: 'sellingCards/add',
      //   component: () => import('@/views/store/seckill/sellingCards/add'),
      //   name: 's_sellingCards_add',
      //   meta: {
      //     title: 's_sellingCards_add',
      //     activeMenu: '/store/seckill/sellingCards'
      //   },
      //   hidden: true
      // },
      // {
      //   path: 'sellingCards/edit/:id(\\d+)',
      //   component: () => import('@/views/store/seckill/sellingCards/edit'),
      //   name: 's_sellingCards_edit',
      //   meta: {
      //     title: 's_sellingCards_edit',
      //     activeMenu: '/store/seckill/sellingCards'
      //   },
      //   hidden: true
      // },
      // {
      //   path: 'flashSale',
      //   component: () => import('@/views/store/seckill/flashSale/'),
      //   name: 's_flashSale',
      //   meta: {
      //     title: 's_flashSale'
      //   }
      // },
      // {
      //   path: 'flashSale/add',
      //   component: () => import('@/views/store/seckill/flashSale/add'),
      //   name: 's_flashSale_add',
      //   meta: {
      //     title: 's_flashSale_add',
      //     activeMenu: '/store/seckill/flashSale'
      //   },
      //   hidden: true
      // },
      // {
      //   path: 'flashSale/edit/:id(\\d+)',
      //   component: () => import('@/views/store/seckill/flashSale/edit'),
      //   name: 's_flashSale_edit',
      //   meta: {
      //     title: 's_flashSale_edit',
      //     activeMenu: '/store/seckill/flashSale'
      //   },
      //   hidden: true
      // },
      {
        path: 'order',
        component: () => import('@/views/store/seckill/order/'),
        name: 's_seckill_order',
        meta: {
          title: 's_seckill_order'
        }
      }]
  },
  {
    name: 's_group_booking',
    path: '/store/teamwork',
    component: Layout,
    redirect: '/store/teamwork/index',
    meta: {
      icon: 'el-icon-shopping-bag-1',
      title: 's_group_booking',
      roles: ['store']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/store/teamwork/index'),
        name: 's_group_booking',
        meta: {
          title: 's_group_booking'
        }
      },
      {
        path: 'add',
        component: () => import('@/views/store/teamwork/add'),
        name: 's_group_booking_add',
        meta: {
          title: 's_group_booking_add',
          activeMenu: '/store/teamwork/index'
        },
        hidden: true
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import('@/views/store/teamwork/edit'),
        name: 's_group_booking_edit',
        meta: {
          title: 's_group_booking_edit',
          activeMenu: '/store/teamwork/index'
        },
        hidden: true
      },
      {
        path: 'details/:id(\\d+)',
        component: () => import('@/views/store/teamwork/details'),
        name: 's_teamwork_activity_details',
        meta: {
          title: 's_teamwork_activity_details',
          activeMenu: '/store/teamwork/index'
        },
        hidden: true
      }
    ]
  },
  {
    name: 's_offline_event',
    path: '/store/activitySignUp',
    component: Layout,
    redirect: '/store/activitySignUp/index',
    meta: {
      icon: 'el-icon-takeaway-box',
      title: 's_offline_event',
      roles: ['store']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/store/activitySignUp/index'),
        name: 's_offline_event',
        meta: {
          title: 's_offline_event'
        }
      },
      {
        path: 'add',
        component: () => import('@/views/store/activitySignUp/add'),
        name: 's_offline_event_add',
        meta: {
          title: 's_offline_event_add',
          activeMenu: '/store/activitySignUp/index'
        },
        hidden: true
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import('@/views/store/activitySignUp/edit'),
        name: 's_offline_event_edit',
        meta: {
          title: 's_offline_event_edit',
          activeMenu: '/store/activitySignUp/index'
        },
        hidden: true
      },
      {
        path: 'details/:id(\\d+)',
        component: () => import('@/views/store/activitySignUp/details'),
        name: 's_activity_signUp_details',
        meta: {
          title: 's_activity_signUp_details',
          activeMenu: '/store/activitySignUp/index'
        },
        hidden: true
      }
    ]
  },
  {
    name: 's_contract',
    path: '/store/contract',
    component: Layout,
    redirect: '/store/contract/index',
    meta: {
      icon: 'el-icon-document',
      title: 's_contract',
      roles: ['store']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/store/contract/index'),
        name: 's_contract_list',
        meta: {
          title: 's_contract_list'
        }
      },
      {
        path: 'add',
        component: () => import('@/views/store/contract/add'),
        name: 's_contract_add',
        meta: {
          title: 's_contract_add',
          activeMenu: '/store/contract/index'
        },
        hidden: true
      },
      {
        path: 'template',
        component: () => import('@/views/store/contract/template'),
        name: 's_contract_template',
        meta: {
          title: 's_contract_template',
          activeMenu: '/store/contract/template'
        }
        // hidden: true
      }
    ]
  },
  // {
  //   path: '/store/posterGeneration',
  //   component: Layout,
  //   redirect: '/store/posterGeneration',
  //   meta: {
  //     roles: ['store']
  //   },
  //   hidden: false,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/store/posterGeneration/index.vue'),
  //       name: 's_poster_generation',
  //       meta: {
  //         title: 's_poster_generation',
  //         icon: 'el-icon-picture-outline',
  //         roles: ['store'],
  //         activeMenu: '/store/posterGeneration/index'
  //       }
  //     },
  //     {
  //       path: 'edit/:id(\\d+)/:activityId(\\d+)/:uId(\\d+)',
  //       component: () => import('@/views/store/posterGeneration/components/edit.vue'),
  //       name: 's_poster_generation_edit',
  //       meta: {
  //         title: 's_poster_generation_edit',
  //         roles: ['store'],
  //         activeMenu: '/store/posterGeneration/index'
  //       },
  //       hidden: true
  //     }
  //   ]
  // },
  {
    name: 's_feedback',
    path: '/store/feedback',
    component: Layout,
    redirect: '/store/feedback',
    children: [{
      path: 'index',
      component: () => import('@/views/store/feedback/index'),
      name: 's_feedback_list',
      meta: {
        title: 's_feedback_list',
        icon: 'el-icon-edit-outline',
        roles: ['store', 'brand']
      }
    }]
  }
]

export default store
