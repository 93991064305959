<template>
  <div class="box p-20">
    <div class=" rela">
      <el-menu :default-active="key" class="el-menu-demo" mode="horizontal" :router="true">
        <el-menu-item v-show="btn_access('adminWeChatMiniProgram')" :index="'/admin/merchants/info/' + aid + '/WeChatMiniProgram'">微信小程序</el-menu-item>
        <el-menu-item :index="'/admin/merchants/info/' + aid + '/WeChatOfficialAccount'">微信公众号</el-menu-item>
        <el-menu-item v-show="btn_access('adminWeChatPay')" :index="'/admin/merchants/info/' + aid + '/WeChatPay'">微信支付</el-menu-item>
        <el-menu-item v-show="btn_access('adminTikTok')" :index="'/admin/merchants/info/' + aid + '/TikTok'">抖音/头条小程序</el-menu-item>
      </el-menu>
      <!-- 子路由嵌套必加 -->
      <div class="p-y-10">
        <router-view :key="key" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aid: this.$route.params.id
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.box {
  background-color: #ffffff;
  ::v-deep .title {
    /* border-bottom: 1px solid #dfe6ec; */
    /* color: #303133; */
    /* font-weight: 600; */
  }
}
</style>

<style lang="scss" scoped>
.table-close {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #dfe6ec;
  .table-title,
  .table-value {
    display: inline-block;
    line-height: 20px;
    padding: 10px 10px;
  }
  .table-title {
    width: 180px;
  }
}
</style>
