import request from '@/utils/request'
// 权限、公告、用户

// 获取权限列表
export function getAccess() {
  return request({
    url: '/admin/v1/access',
    method: 'get'
  })
}

// 添加权限
export function addAccess(data) {
  return request({
    url: '/admin/v1/access',
    method: 'post',
    data
  })
}

// 获取权限详情
export function getDetails(id) {
  return request({
    url: `/admin/v1/access/${id}`,
    method: 'get'
  })
}

// 更新权限
export function editDetails(id, data) {
  return request({
    url: `/admin/v1/access/${id}`,
    method: 'put',
    data
  })
}

/**
 * 权限end
 */

/**
 * 公告start
 */

// 获取公告列表
export function getNoticeList(data) {
  return request({
    url: '/admin/v1/notice',
    method: 'get',
    params: data
  })
}

// 获取公告详情
export function getNoticeDetails(id) {
  return request({
    url: `/admin/v1/notice/${id}`,
    method: 'get'
  })
}

// 更新公告
export function editNotice(id, data) {
  return request({
    url: `/admin/v1/notice/${id}`,
    method: 'put',
    data
  })
}

// 添加公告
export function addNotice(data) {
  return request({
    url: '/admin/v1/notice',
    method: 'post',
    data
  })
}
/**
 * 公告end
 */

/**
 * 用户start
 */

// 获取用户列表
export function getUser(data) {
  return request({
    url: '/admin/v1/user',
    method: 'get',
    params: data
  })
}

// 获取用户详情
export function getUserDetails(id) {
  return request({
    url: `/admin/v1/user/${id}`,
    method: 'get'
  })
}

// 更新公告
export function editUser(id, data) {
  return request({
    url: `/admin/v1/user/${id}`,
    method: 'put',
    data
  })
}
// 添加公告
export function addUser(data) {
  return request({
    url: '/admin/v1/user',
    method: 'post',
    data
  })
}

// 商户超级管理员登录
export function storeLogin(id, data) {
  return request({
    url: `/admin/v1/app/store_login/${id}`,
    method: 'get',
    params: data
  })
}
