<template>
  <div class="app-container special-center">
    <el-page-header content="会员批量导入" class="page-header" @back="returnPage()" />
    <!-- <el-divider /> -->
    <template>
      <el-tabs v-model="typeL" style="margin-top: 20px;">
        <el-tab-pane label="瑜小九" :name="1">
          <div class="import_box">
            <!-- excel模版下载 -->
            <div class="file_demo">
              <div class="demo_list flex row-around">
                <el-radio v-model="form.type" :label="1" class="text-center">
                  <div class="demo_item flex">
                    <div class="file_icon"><svg-icon class="icon" icon-class="excel" /></div>
                    <div class="file_name cursor">
                      <el-tooltip class="item" effect="dark" content="下载模版文件" placement="bottom">
                        <el-link type="primary" @click="down_file(1)">批量导入会员（有卡）模版.xls</el-link>
                      </el-tooltip>
                    </div>
                  </div>
                </el-radio>
                <el-radio v-model="form.type" :label="2" class="text-center">
                  <div class="demo_item flex">
                    <div class="file_icon"><svg-icon class="icon" icon-class="excel" /></div>
                    <div class="file_name cursor">
                      <el-tooltip class="item" effect="dark" content="下载模版文件" placement="bottom">
                        <el-link type="primary" @click="down_file(0)">批量导入会员（无卡）模版.xls</el-link>
                      </el-tooltip>
                    </div>
                  </div>
                </el-radio>
              </div>
            </div>
          </div>
          <!-- <el-divider /> -->
          <!-- 文件上传 -->
          <div class="file_upload text-center">
            <el-upload
              ref="upload"
              v-loading="upLoading"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="upSuccess"
              :on-change="upChange"
              :headers="utils.upload_headers()"
              :file-list="fileList"
              :limit="1"
              name="excel"
              :on-exceed="handleExceed"
              :on-progress="upProgress"
              :auto-upload="false"
              class="upload-demo"
              drag
              :action="importUrl"
              accept=".xlsx, .xls"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
            </el-upload>
          </div>
          <!-- 提交按钮 -->
          <div class="text-center">
            <el-button class="btn-block" type="primary" round :loading="btnLoading" @click="submit">提交</el-button>
          </div>
          <div class="info">
            <div>
              <div class="title">
                批量导入会员（有卡）需知
              </div>
              <div class="content">
                <li>1、支持Excel（.xls/.xlsx）格式的文件；</li>
                <li>2、多馆卡请单独录入，批量导入会导致数据错误；</li>
                <li>3、模板中 * 为必填项，其余项可以不填；如果该会员持有卡， * 也为必填项；</li>
                <li>4、支持一人多卡，详细格式参照模板；不支持导入请假状态的卡，请假状态的卡手动录入，或者恢复正常后再导入；</li>
                <li>5、请确保你已经在系统里添加了正确的会籍顾问信息，系统不支持重名的员工，如果重名的员工请调整表格里名字与录入员工的姓名一致；</li>
                <li>6、请确保手机号格式的正确性：13800138000；出生日期格式：1986/10/10。不正确的格式将不能成功导入；</li>
                <li>7、每次最多导入500条数据，如您的会员超出500人，请分批次导入；</li>
                <li>8、开卡日期可填写具体的开卡时间或 “立即开卡”、“首次预约开卡”、“首次上课开卡” ；</li>
                <li>9、卡类型可选“期限卡”、“次数卡”、“储值卡”，期限卡“余额”项不填，次数卡与储值卡必填；</li>
                <li>10、卡名称会匹配已有会员卡名称，如不存在将自动创建对应卡类型的会员卡名称，默认为1年有效期；</li>
                <li>11、如数据导入有疑问，可联系专属客服进行处理；</li>
              </div>
            </div>
            <div>
              <div class="title">
                批量导入会员（无卡）需知
              </div>
              <div class="content">
                <li>1、支持Excel（.xls/.xlsx）格式的文件；</li>
                <li>2、模板中 * 为必填项，其余项可以不填；</li>
                <li>3、请确保你已经在系统里添加了正确的会籍顾问信息，系统不支持重名的员工，如果重名的员工请调整表格里名字与录入员工的姓名一致；</li>
                <li>4、请确保手机号格式的正确性：13800138000；出生日期格式：1986/10/10。不正确的格式将不能成功导入；</li>
                <li>5、每次最多导入500条数据，如您的会员超出500人，请分批次导入；</li>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="其他模板" :name="2">
          <!-- 选择其他 -->
          <div>
            <el-radio-group v-model="typeI" @change="handleChange">
              <el-radio-button :label="1">随心瑜</el-radio-button>
              <el-radio-button :label="2">菲特云</el-radio-button>
            </el-radio-group>
          </div>
          <div style="margin-top: 20px;" class="import_box">
            <!-- excel模版下载 -->
            <div class="file_demo">
              <div class="demo_list flex row-around">
                <el-radio v-model="form.type" :label="1" class="text-center">
                  <div class="demo_item flex">
                    <div class="file_name">
                      <span type="primary">批量导入有卡会员</span>
                    </div>
                  </div>
                </el-radio>
                <el-radio v-model="form.type" :label="2" class="text-center">
                  <div class="demo_item flex">
                    <div class="file_name">
                      <span type="primary">批量导入无卡会员</span>
                    </div>
                  </div>
                </el-radio>
              </div>
            </div>
          </div>
          <!-- <el-divider /> -->
          <!-- 文件上传 -->
          <div class="file_upload text-center">
            <el-upload
              ref="upload"
              v-loading="upLoading"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="upSuccess"
              :on-change="upChange"
              :headers="utils.upload_headers()"
              :file-list="fileList"
              :limit="1"
              name="excel"
              :on-exceed="handleExceed"
              :on-progress="upProgress"
              :auto-upload="false"
              class="upload-demo"
              drag
              :action="importUrl"
              accept=".xlsx, .xls"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
            </el-upload>
          </div>
          <!-- 提交按钮 -->
          <div class="text-center">
            <el-button class="btn-block" type="primary" round :loading="btnLoading" @click="submit">提交</el-button>
          </div>
          <!-- <div class="info">
            <div v-if="form.type === 1">
              <div class="title">
                批量导入有卡会员（需知）
              </div>
              <div class="content">
                <li>123</li>
              </div>
            </div>
            <div v-if="form.type === 0">
              <div class="title">
                批量导入无卡会员（需知）
              </div>
              <div class="content">
                <li>456</li>
              </div>
            </div>
          </div> -->
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
import Utils from '@/utils/utils.js'
// import { importUser } from '@/api/store/user.js'
export default {
  data() {
    return {
      form: { type: 1 },
      typeI: 1,
      typeL: 1,
      import_type: 1,
      upLoading: false,
      fileList: [],
      importUrl: '',
      btnLoading: false,
      file: ['/static/user_membership.xlsx', '/static/user_membership_card.xlsx']
    }
  },
  watch: {
    form: {
      handler: function(v, ov) {
        this.importUrl = `${
          process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_BASE_API
        }/store/v1/user_membership_card/import/venues_id/${Utils.getVenues().id}/type/${v.type}/import_type/${this.import_type}`
      },
      deep: true
    },
    typeL(val) {
      if (val === 1) {
        this.import_type = 1
        this.form.type = 1
      }
    }
  },
  created() {
    if (this.$route.params.type) {
      this.form.type = this.$route.params.type
    } else {
      this.form.type = 1
    }
  },
  mounted() {
    this.importUrl = `${
      process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_BASE_API
    }/store/v1/user_membership_card/import/venues_id/${Utils.getVenues().id}/type/${this.form.type}/import_type/${this.import_type}`
  },

  methods: {
    handlePreview(file) {},
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    upProgress(event, file, fileList) {
      this.upLoading = true
    },
    handleExceed(files, fileList) {
      this.$message.warning(`单次上传限制一个文件`)
    },
    upChange(file, fileList) {
      this.fileList = fileList
    },
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status === 200) {
        this.$message.success(response.msg)
      } else {
        this.$message.error(response.msg)
      }
      this.fileList = []
      this.$refs.upload.clearFiles()
      this.btnLoading = false
    },
    submit() {
      if (this.fileList === undefined || this.fileList.length <= 0) {
        this.$message.error('请选择导入模版excel文件')
        return false
      }
      this.btnLoading = true

      this.$refs.upload.submit()
    },
    down_file(type) {
      // let file_name = '模版文件.xlsx'
      // switch (type) {
      //   case 0:
      //     file_name = '批量导入会员（无卡）模版.xlsx'
      //     break
      //   case 1:
      //     file_name = '批量导入会员（有卡）模版.xlsx'
      //     break
      //   default:
      //     this.$message.error('请选择模版文件')
      //     return false
      // }
      window.open(this.baseUrl + this.file[type], '_self')
      // this.utils.handleDownload({ url: this.file[type], fileName: file_name, baseURL: this.baseUrl })
    },
    handleChange(val) {
      if (val === 1) this.import_type = 1
      if (val === 2) this.import_type = 2
      this.form.type = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.import_box {
  .file_icon {
    margin: 0px 10px;
    .icon {
      font-size: 35px;
      color: #1abc9c;
    }
  }
  .file_name {
    font-size: 16px;
  }
}
.file_upload {
  padding: 20px;
}
.btn-block {
  width: 300px;
}
.info {
  margin-top: 10px;
  .title {
    font-size: 14px;
    color: #787878;
    padding: 20px 0px;
  }
  .content {
    background-color: #f6f6f6;
    padding: 20px;
    li {
      list-style: none;
      font-size: 15px;
      line-height: 20px;
    }
  }
}
</style>
