<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button v-show="btn_access('sms_plan_add')" v-waves class="filter-item m-r-10" type="primary" icon="el-icon-plus" @click="add_edit('')">
        添加
      </el-button>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column prop="name" label="商品名称" />
      <el-table-column prop="price" label="商品价格" />
      <el-table-column prop="line_price" label="商品原价" />
      <el-table-column prop="number" label="短信条数" />
      <el-table-column prop="type" label="类型">
        <template slot-scope="{row}">
          {{ row.type===1?'国内':'国际' }}
        </template>
      </el-table-column>
      <el-table-column prop="tag" label="标签">
        <template slot-scope="{row}">
          <el-tag v-if="row.is_hot" class="m-2">热门</el-tag>
          <el-tag v-if="row.is_recommended" class="m-2" type="success">推荐</el-tag>
          <el-tag v-if="row.use_rate>0" class="m-2" type="warning">{{ row.use_rate }}%馆主推荐</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" />
      <el-table-column prop="update_time" label="更新时间" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('sms_plan_edit')" type="primary" icon="el-icon-edit" class="m-2" @click="add_edit(scope.row.id)">编辑</el-button>
          <el-popconfirm
            v-show="btn_access('sms_plan_del')"
            title="这是一段内容确定删除吗？"
            @confirm="plan_delete(scope.row.id)"
          >
            <el-button slot="reference" type="danger" icon="el-icon-delete" class="m-2">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.id ? '编辑商品' : '添加商品'"
      class="dialog_w580"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请填写商品名称" />
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="form.price" placeholder="请填写商品价格" type="number" />
        </el-form-item>
        <el-form-item label="原价" prop="line_price">
          <el-input v-model="form.line_price" placeholder="请填写商品原价" type="number" />
        </el-form-item>
        <el-form-item label="条数" prop="number">
          <el-input v-model="form.number" placeholder="请填写短信条数" type="number" />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-radio v-model="form.type" :label="1">国内</el-radio>
          <el-radio v-model="form.type" :label="2">国际</el-radio>
        </el-form-item>
        <el-form-item label="标签" prop="tag">
          <el-checkbox v-model="form.is_hot">热门</el-checkbox>
          <el-checkbox v-model="form.is_recommended">推荐</el-checkbox>
          <div>推荐比值：<el-slider v-model="form.use_rate" :min="0" :max="100" /></div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
        <el-button v-loading="loading.btnLoading" type="primary" @click="submit">
          {{ dialog.id ? '保存' : '新增' }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPlan, delPlan, addPlan, editPlan, infoPlan } from '@/api/admin/sms.js'
import Pagination from '@/components/Pagination' // 分页组件
export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      dialog: { visible: false, id: '' },
      loading: { listLoading: false },
      form: {
        name: '',
        price: 0,
        line_price: 0,
        number: 0,
        type: 1,
        is_hot: false,
        is_recommended: false,
        use_rate: 0
      },
      rules: {
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        price: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
        line_price: [{ required: true, message: '请输入商品原价', trigger: 'blur' }],
        number: [{ required: true, message: '请输入短信条数', trigger: 'blur' }]
      },
      timeList: [{
        value: 'day',
        label: '天'
      }, {
        value: 'month',
        label: '月'
      }, {
        value: 'year',
        label: '年'
      }]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']
      getPlan(data).then(res => {
        this.tableData = res.data
        this.loading.listLoading = false
      })
    },
    valid_period(item) {
      var valid_period = JSON.parse(item.valid_period)
      const digital = valid_period['digital']
      const type = valid_period['type']
      var time = ''
      switch (type) {
        case 'month':
          time = '个月'
          break
        case 'day':
          time = '天'
          break
        case 'year':
          time = '年'
          break
        default:
          break
      }
      return digital + time
    },
    plan_delete(id) {
      delPlan(id).then(res => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    add_edit(id) {
      if (id) {
        infoPlan(id).then(res => {
          this.form = res.data
          this.form.is_hot = !!res.data.is_hot
          this.form.is_recommended = !!res.data.is_recommended
        })
      } else {
        this.form = this.$options.data.call(this).form
        // console.log('🚀 ~ file: index.vue ~ line 175 ~ add_edit ~ this.$options.data.call(this)', this.$options.data.call(this))
      }
      this.dialog.visible = true
      this.dialog.id = id
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var data = this.form
          if (this.dialog.id) {
            editPlan(this.dialog.id, data).then(res => {
              this.$message.success('更新成功')
              this.getList()
            })
          } else {
            addPlan(data).then(res => {
              this.$message.success('添加成功')
              this.getList()
            })
          }
          this.dialog.id = ''
          this.dialog.visible = false
        }
      })
    }
  }
}
</script>

<style  lang="scss" scoped>
.el-input-group__append, .el-input-group__prepend{
  width: 70px;
}
</style>
