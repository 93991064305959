<template>
  <div class="app-container special-center">
    <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px" label-position="right">
      <p><b>卡信息</b></p>
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="会员卡号" prop="number">
            <el-input v-model="form.number" placeholder="请输入会员卡号" maxlength="20" show-word-limit />
          </el-form-item>
        </el-col>
        <el-col v-if="utils.getVenues().id==-1" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="所属场馆" prop="venues_id">
            <el-select v-model="form.venues_id" filterable placeholder="请选择所属场馆" class="w100">
              <el-option v-for="item in venues_list" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="会员卡" prop="membership_card_id">
            <el-select v-model="form.membership_card_id" filterable placeholder="可搜索" class="w100" @change="membership_card_change">
              <el-option v-for="item in membershipCard" :key="item.id" :label="`[${card_type[item.type]}] `+item.name" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <!-- 持卡用户 -->
          <el-form-item label="持卡用户" prop="user_id">
            <Select ref="userRef" :type="this.$route.params.card_id" :width="294" :disabled="this.$route.params.user_id != 0 ? true : false" @changeId="handleMemberID" />
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="开卡类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择开卡类型" class="w100" @change="card_open_type_change">
              <el-option v-for="(item,index) in card_open_type" :key="item" :label="item" :value="index" />
            </el-select>
            <span v-if="form.type === 3" style="font-size: 12px; color: #ffbd43">该卡将在会员第一次上课时间自动开卡</span>
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item v-if="form.type == 2" label="开卡时间" prop="specific_time">
            <el-date-picker
              v-model="form.specific_time"
              type="datetime"
              placeholder="请选择开卡时间"
              class="w100"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :picker-options="pickerOptions"
              @change="specific_time_change"
            />
          </el-form-item>
        </el-col>
        <el-col v-show="form.type==0" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="到期时间" prop="valid_time">
            <el-date-picker
              v-model="form.valid_time"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="到期时间"
              :clearable="false"
              class="w100"
              :picker-options="dataProhibitSelection"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" style="position: relative;">
          <el-tooltip v-if="form.type !== 0 && cardType === 0" class="item icon_info cursor" effect="dark" content="非立即开卡，将在开卡时加赠" placement="bottom">
            <i class="el-icon-question font-16 " />
          </el-tooltip>
          <el-form-item label="加赠" prop="gift">
            <el-input v-model="form.gift" placeholder="加赠" type="number" onkeyup="value=value.replace(/^0+(\d)|[^\d]+/g,'')" @input="giftChange">
              <template slot="append">{{ unit }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="cardType!==0" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="余额" prop="limit">
            <el-input v-model="form.limit" placeholder="可用余额" type="number">
              <template slot="append">{{ unit }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="推荐办卡人" prop="recommend">
            <el-input v-model="form.recommend" placeholder="请填写可推荐办卡人" />
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <p><b>收费信息</b></p>
        </el-col>

        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="付款方式">
            <el-select v-model="form.payment_method_id" placeholder="付款方式" clearable class="w100">
              <el-option v-for="(item,index) in payment_method" :key="index" :label="item" :value="index" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="实收金额">
            <el-input v-model="form.actual_amount" placeholder="实收金额(元)" type="number" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <p><b>其他</b></p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark" type="textarea" placeholder="请填写备注" rows="5" />
          </el-form-item>
        </el-col>

      </el-row>

    </el-form>
    <div class="footer">
      <el-button type="primary" @click="submit">提交</el-button>
      <el-button @click="returnPage()">取消</el-button>
    </div>
  </div>
</template>

<script>
import { addUserMembershipCard, getUserDetails } from '@/api/store/user.js'
import { getVenues } from '@/api/store/venues.js'
import { getVipCard, getVipCardDetails } from '@/api/store/venues.js'
import Select from '@/components/elSelect/Select'

export default {
  components: { Select },
  data() {
    var limitV = (rule, value, callback) => {
      if (this.cardType !== 0 && this.form.limit < 0) {
        callback(new Error('请填写整数'))
      }
      callback()
    }
    var giftV = (rule, value, callback) => {
      if (this.form.gift < 0) {
        callback(new Error('请填写整数'))
      }
      callback()
    }
    return {
      user: {},
      membershipCard: {},
      form: {
        number: this.buildNumber(),
        user_id: '',
        venues_id: '',
        type: 0,
        recommend: '',
        gift: 0,
        specific_time: this.$moment().format('YYYY-MM-DD 00:00:00')
      },
      rules: {
        number: [{ required: true, message: '请输入卡号', trigger: ['blur', 'change'] }],
        membership_card_id: [{ required: true, message: '请选择会员卡', trigger: ['blur', 'change'] }],
        user_id: [{ required: true, message: '请选择此卡用户', trigger: ['blur', 'change'] }],
        type: [{ required: true, message: '请选择开卡类型', trigger: ['blur', 'change'] }],
        specific_time: [{ required: true, message: '请选择开卡时间', trigger: ['blur', 'change'] }],
        venues_id: [{ required: true, message: '请选择所属场馆', trigger: ['blur', 'change'] }],
        limit: [{ validator: limitV, trigger: ['blur', 'change'] }],
        gift: [{ validator: giftV, trigger: ['blur', 'change'] }]
      },
      loading: false,
      userQuery: {},
      venues_list: [],
      unit: '天',
      cardType: 0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= (Date.now() - (24 * 60 * 60 * 1000))
        }
      },
      userInfo: [],
      checkCardInfo: {},
      visitors: 0,
      user_name: ''
    }
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.getInfo()
    // 获取会员卡列表
    getVipCard({ limit: 9999 }).then(res => {
      this.membershipCard = res.data.list
    }).catch(() => {})
    // 获取场馆列表
    this.getVenuesList()
  },
  methods: {
    // 获取用户详情
    async getInfo() {
      if (parseInt(this.$route.params.user_id) === 0) return false
      this.form.user_id = parseInt(this.$route.params.user_id)
      const { data } = await getUserDetails(this.form.user_id)
      this.user_name = data.real_name || data.nickname
      this.$refs.userRef.refreshGetList(this.user_name)
    },
    // 生成会员卡卡号
    buildNumber() {
      var myDate = new Date()
      var year = myDate.getYear()
      year = year < 2000 ? year + 1900 : year
      var yy = year.toString().substr(2, 2)
      var t = yy + this.$moment().format('MMDDss') + this.utils.getRandomIntInclusive('01', '99', 2)
      return t
    },
    getVenuesList(query) {
      const data = query ? this.utils.getQueryParam({ name: '%' + query + '%' }) : {}
      data.limit = 9999
      getVenues(data).then(res => {
        this.venues_list = res.data.list
      }).catch(() => {})
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.form.user_id === 0 || !this.form.user_id) {
            console.log(this.form.user_id)
            this.$message.error('请选择持卡用户')
            return false
          }
          const data = JSON.parse(JSON.stringify(this.form))
          if (this.utils.getVenues().id !== -1) {
            data['venues_id'] = this.utils.getVenues().id
          }
          if (data.type === 1) delete data['valid_time']
          addUserMembershipCard(data).then(res => {
            this.$message.success(res.msg)
            this.returnPage()
          }).catch(() => {})
        }
      })
    },
    membership_card_change(v) {
      // 自动计算到期时间
      if (v !== '') {
        getVipCardDetails(v).then(res => {
          const { data } = res
          this.checkCardInfo = data
          this.setValidTime()
        }).catch(() => {})
      }
    },
    // 加赠计算
    giftChange(v) {
      this.setValidTime()
    },
    specific_time_change(v) {
      this.setValidTime()
    },
    card_open_type_change() {
      this.setValidTime()
    },
    setValidTime() {
      var valid_time = ''
      let unit = '天'
      var dateTime = this.$moment()
      if (this.form.type === 2) {
        dateTime = this.$moment(this.form.specific_time)
      }
      var valid_period = JSON.parse(this.checkCardInfo.valid_period)
      switch (this.checkCardInfo.type) {
        case 0:
          unit = '天'
          break
        case 1:
          unit = '次'
          // 关联额度
          this.$set(this.form, 'limit', Number(this.checkCardInfo.limit) + Number(this.form.gift))
          break
        case 2:
          unit = '元'
          this.$set(this.form, 'limit', Number(this.checkCardInfo.limit) + Number(this.form.gift))
          break
      }
      dateTime = dateTime.add(valid_period['digital'], valid_period['type'])

      this.cardType = this.checkCardInfo.type
      if (this.cardType === 0) {
        dateTime = dateTime.add(this.form.gift, 'day')
      }
      this.unit = unit
      if (this.form.type !== 1) {
        valid_time = dateTime.format('YYYY-MM-DD')
        this.$set(this.form, 'valid_time', valid_time)
      } else {
        this.$set(this.form, 'valid_time', '')
      }
    },
    handleMemberID(val) {
      this.form.user_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.select-page{
  span{
    width:120px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }

  .text{
    padding-left:10px;
    font-size:14px;
    font-weight:bold;
    cursor:pointer;
    color:cornflowerblue;
  }
}
::v-deep {
  .el-input{
    height: 32px;
  }
}

.icon_info {
  position: absolute;
  top: 8px;
  left: 45px;
}
</style>
