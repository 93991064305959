import { login, getInfo } from '@/api/user'
import { getToken, setToken, removeToken, setRefreshToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import Utils from '@/utils/utils.js'
import defaultLogo from '@/assets/images/logo_round.svg'

const state = {
  token: getToken(),
  avatar: '',
  roles: [],
  isSuper: '',
  userinfo: {},
  is_display: true // 是否显示反馈弹窗
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ISSUPER: (state, is_super) => {
    state.isSuper = is_super
  },
  SET_USERINFO: (state, userinfo) => {
    state.userinfo = userinfo
  },
  SET_DISPLAY: (state, is_display) => {
    state.is_display = is_display
  }
}

const actions = {
  // 用户登录
  login({ commit }, userInfo) {
    userInfo.phone = userInfo.phone.trim()
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
        const {
          data
        } = response
        // console.log('🚀 ~ file: user.js ~ line 46 ~ returnnewPromise ~ data', data)
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        setRefreshToken(data.refresh_token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  smsLogin({ commit }, userInfo) {
    commit('SET_TOKEN', userInfo.token)
    setToken(userInfo.token)
    setRefreshToken(userInfo.refresh_token)
  },
  display({ commit }, is_display) {
    commit('SET_DISPLAY', is_display)
  },
  // 快速登录
  fastLogin({ commit }, userInfo) {
    const {
      token,
      refresh_token
    } = userInfo
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', token)
      setToken(token)
      setRefreshToken(refresh_token)
      resolve()
    })
  },
  // 获取用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      let url = '/v1/admin'
      const authority = localStorage.getItem('authority')
      if (authority === 'store') url = '/v1/userInfo'
      getInfo(url, state.token).then(response => {
        const { data } = response
        // console.log('🚀 ~ file: user.js ~ line 77 ~ getInfo ~ data', data)
        if (!data) reject('验证失败，请重新登录。')
        data['roles'] = [authority]
        if (authority === 'store' && Utils.getVenues().id === -1) data['roles'] = ['brand']
        // roles必须是非空数组
        if (!data.roles || data.roles.length <= 0) reject('角色必须是一个非空数组!')
        commit('SET_ROLES', data.roles) // 角色
        commit('SET_ISSUPER', data.is_super ? data.is_super : false) // 超级管理员
        commit('SET_AVATAR', data.storeInfo ? data.storeInfo.avatar : defaultLogo) // 头像
        commit('SET_USERINFO', data) // 用户信息

        if (authority === 'store') localStorage.setItem('userAccess', JSON.stringify(data.access ? data.access : [])) // 用户权限
        else localStorage.setItem('adminAccess', JSON.stringify(data.menu_route.length > 0 ? data.menu_route : [])) // 用户权限
        localStorage.setItem('is_super', !!data.is_super) // 超级管理员
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 用户退出
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      // logout(state.token).then(() => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()
      localStorage.removeItem('Venues')
      localStorage.removeItem('access_list')
      localStorage.removeItem('userAccess')
      localStorage.removeItem('adminAccess')
      localStorage.removeItem('App')
      localStorage.removeItem('RefreshToken')
      localStorage.removeItem('is_super')
      localStorage.removeItem('Appid')
      // 重置访问的视图和缓存的视图
      // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      dispatch('tagsView/delAllViews', null, {
        root: true
      })
      resolve()
      // }).catch(error => {
      //   reject(error)
      // })
    })
  },

  // 删除 token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
      localStorage.removeItem('Venues')
    })
  },

  // 动态地修改权限
  async changeRoles({
    commit,
    dispatch
  }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const {
      roles
    } = await dispatch('getInfo')

    resetRouter()

    // 生成基于角色的可访问路由图
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {
      root: true
    })
    // 动态添加可访问的路由
    router.addRoutes(accessRoutes)

    // 重置已访问视图和缓存视图
    dispatch('tagsView/delAllViews', null, {
      root: true
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
