<template>
  <div>
    <el-dialog
      id="list"
      class="dialog_auto"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="logVisible"
      title="变更记录"
      :before-close="cancelDialog"
    >
      <div class="app-container">
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="tableData.list"
          row-key="id"
        >
          <!-- <el-table-column prop="id" label="ID" /> -->
          <el-table-column prop="user_membership_card_id" label="卡号">
            <template slot-scope="scope">
              <span v-if="scope.row.user_membership_card">{{ scope.row.user_membership_card.number }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="store_user_id" label="操作人">
            <template slot-scope="scope">
              <span v-if="scope.row.store_user">{{ scope.row.store_user.account }}</span>
              <span v-else>系统</span>
            </template>
          </el-table-column>
          <el-table-column prop="status.name" label="操作类型">
            <template slot-scope="scope">
              <el-tag type="primary">{{ scope.row.status.name }}</el-tag>
            </template>
          </el-table-column>
          、<el-table-column prop="remark" label="备注" min-width="160px" />
          <el-table-column prop="create_time" label="操作时间" />
        </el-table>
        <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
      </div>
    </el-dialog>
  </div>

</template>

<script>
import { getUserMembershipCardChangeLogs } from '@/api/store/user.js'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
export default {
  name: 'UserCardLogs',
  components: { Pagination },
  props: {
    logVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10
      },
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      // 弹窗
      dialog: {
        visible: false
      },
      // 数据
      form: {},
      visitor: [],
      loading: false
    }
  },
  watch: {
    id(newVal, oldVal) {
      this.listQuery = { page: 1, limit: 10 }
      this.loading = true
      this.getList()
    }
  },
  created() {
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取列表
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data.query = JSON.stringify({ user_membership_card_id: this.id })
      getUserMembershipCardChangeLogs(data).then(res => {
        this.tableData = res.data
        this.loading = false
      }).catch(() => {})
    },
    cancelDialog() {
      this.$emit('update:logVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
	#list{
		::v-deep .el-dialog__body{
			padding: 0;
		}
		.app-container{
			padding: 10px 20px;
		}
	}
</style>
