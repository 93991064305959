import request from '@/utils/request'

// 获取发起签署默认表单
export function getDefaultForm(id) {
  return request({
    url: `/store/v1/contract/template/${id}/default_save_form`,
    method: 'get'
  })
}

// 获取合同列表
export function getInquireList(params) {
  return request({
    url: '/store/v1/contract',
    method: 'get',
    params
  })
}

// 合同详情
export function getContractDetail(id, params) {
  return request({
    url: `/store/v1/contract/${id}`,
    method: 'get',
    params
  })
}

// 合同作废
export function cancelContract(id, data) {
  return request({
    url: `/store/v1/contract/${id}/dispose`,
    method: 'put',
    data
  })
}

// 撤销合同作废
export function revokeCancelContract(id) {
  return request({
    url: `/store/v1/contract/${id}/undo_dispose`,
    method: 'put'
  })
}

// 删除合同
export function deleteContract(id) {
  return request({
    url: `/store/v1/contract/template/${id}`,
    method: 'delete'
  })
}

// 重属名
export function renameContract(id, data) {
  return request({
    url: `/store/v1/contract/${id}/rename`,
    method: 'put',
    data
  })
}

// 模板列表
export function getTemplateList(params) {
  return request({
    url: `/store/v1/contract/template`,
    method: 'get',
    params
  })
}

// 获取合同/模板详情
export function getTemplateDetail(id) {
  return request({
    url: `/store/v1/contract/template/${id}/edit`,
    method: 'get'
  })
}

// 编辑合同/模板
export function editTemplate(id, data) {
  return request({
    url: `/store/v1/contract/template/${id}`,
    method: 'put',
    data
  })
}

// 标签列表
export function getTagList() {
  return request({
    url: `/store/v1/contract/tag`,
    method: 'get'
  })
}

// 添加标签
export function addTag(data) {
  return request({
    url: `/store/v1/contract/tag`,
    method: 'post',
    data
  })
}

// 删除标签
export function deleteTag(id) {
  return request({
    url: `/store/v1/contract/tag/${id}`,
    method: 'delete'
  })
}

// 合同设置详情
export function getContractSettingDetail() {
  return request({
    url: `/store/v1/contract/template/default_setting`,
    method: 'get'
  })
}

// 合同设置修改
export function editContractSetting(data) {
  return request({
    url: `/store/v1/contract/template/default_setting`,
    method: 'put',
    data
  })
}

// 保存会员合同/模板
export function saveContractTemplate(data) {
  return request({
    url: `/store/v1/contract/template`,
    method: 'post',
    data
  })
}

// 发起签署
export function startContractSign(data) {
  return request({
    url: `/store/v1/contract`,
    method: 'post',
    data
  })
}
