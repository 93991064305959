var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.key == "/store/plan/index" || _vm.level != 1
        ? _c(
            "section",
            { staticClass: "app-main" },
            [
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [
                  _c(
                    "keep-alive",
                    { attrs: { include: _vm.cachedViews } },
                    [_c("router-view", { key: _vm.key })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("flagship")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }