<template>
  <!-- 外层 -->
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    :show-close="false"
    :width="equipment ? '60%' : '100%'"
    @open="handleOpen"
    @close="handleClose"
  >
    <!-- 内层 -->
    <el-dialog
      :title="handleId === 0 ? '添加收款信息' : '编辑收款信息'"
      :visible.sync="innerVisible"
      :close-on-click-modal="false"
      append-to-body
      :class="equipment ? 'dialog_w580' : 'dialog_auto'"
      @open="handleInnerOpen"
      @close="handleInnerClose"
    >
      <!-- 表单 -->
      <el-form ref="form" :rules="rules" :label-position="equipment ? 'right' : 'top'" :model="form" label-width="80px">
        <el-form-item label="商品类型">
          <el-radio-group v-model="form.product_type">
            <el-radio v-for="item in productType" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 购买时长 -->
        <el-form-item label="购买时长" prop="buy_number">
          <el-input v-model="form.buy_number" type="number" placeholder="请输入购买时长">
            <template slot="append">
              <el-select ref="v_type" v-model="buy_number" placeholder="请选择" style="width:60px">
                <el-option v-if="form.product_type !== 2" label="年" value="年" />
                <el-option v-if="form.product_type !== 2" label="月" value="月" />
                <el-option v-if="form.product_type !== 2" label="天" value="天" />
                <el-option v-if="form.product_type === 2" label="条" value="条" />
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <!-- 付款金额 -->
        <el-form-item label="付款金额" prop="pay_money">
          <el-input v-model="form.pay_money" type="number" placeholder="请输入付款金额">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <!-- 付款方式 -->
        <el-form-item label="付款方式">
          <el-select
            v-model="form.pay_type"
            filterable
            allow-create
            default-first-option
            placeholder="请选择付款方式"
            :class="equipment ? 'dialog_w100' : 'dialog_w280'"
          >
            <el-option label="现金" value="现金" />
            <el-option label="微信" value="微信" />
            <el-option label="淘宝" value="淘宝" />
            <el-option label="支付宝" value="支付宝" />
            <el-option label="银行卡" value="银行卡" />
            <el-option label="信用卡" value="信用卡" />
          </el-select>
        </el-form-item>
        <!-- 购买时间 -->
        <el-form-item label="购买时间" prop="pay_time">
          <el-date-picker
            v-model="form.pay_time"
            type="datetime"
            placeholder="选择购买时间"
            default-time="00:00:00"
            value-format="yyyy-MM-dd HH:mm"
            :class="equipment ? 'dialog_w100' : 'dialog_w280'"
          />
        </el-form-item>
        <el-form-item v-if="handleId !== 0" label="退款">
          <el-radio-group v-model="form.is_refund">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="handleId !== 0 && form.is_refund === 1" label="退款时间" prop="refund_time">
          <el-date-picker
            v-model="form.refund_time"
            type="datetime"
            placeholder="选择退款时间"
            default-time="00:00:00"
            value-format="yyyy-MM-dd HH:mm"
            :class="equipment ? 'dialog_w100' : 'dialog_w280'"
          />
        </el-form-item>
        <el-form-item v-if="handleId !== 0 && form.is_refund === 1" label="退款金额" prop="refund_money">
          <el-input v-model="form.refund_money" type="number" placeholder="请输入退款金额">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="form.notice" type="textarea" placeholder="请输入备注信息" rows="5" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">取 消</el-button>
        <el-button v-if="handleId === 0" type="primary" @click="handleAdd('form')">确 定</el-button>
        <el-button v-else type="primary" @click="handleModify('form')">修 改</el-button>
      </span>
    </el-dialog>
    <div slot="title">
      <div class="title">
        <div class="title_title">收款信息</div>
        <div class="title_icon cursor" @click="handleCloseDialog">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <!-- 添加按钮 -->
    <el-button type="primary" icon="el-icon-plus" @click="innerVisible = true">添加</el-button>
    <div v-loading="tableLoading">
      <!-- 列表 -->
      <el-table class="m-t-20" :data="tableData.list" stripe style="width: 100%">
        <el-table-column prop="product_type" label="类型" align="center">
          <template slot-scope="{ row }">
            <span>{{ productType[row.product_type].name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="buy_number" label="购买时长" align="center" />
        <el-table-column prop="pay_money" label="购买金额" align="center">
          <template slot-scope="{ row }">
            <span :class="row.is_refund ? 'text-danger' : ''">{{ `${row.pay_money}元${row.is_refund ? `（已退款：${row.refund_money}）` : ''}` }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_type" label="购买方式" align="center" />
        <el-table-column prop="pay_time" label="购买时间" align="center" :min-width="equipment ? '' : '160'" />
        <el-table-column prop="notice" label="备注" align="center">
          <template slot-scope="{ row }">
            {{ row.notice ? row.notice : '暂无备注信息' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" :min-width="equipment ? '' : '180'">
          <template slot-scope="{ row }">
            <el-button type="primary" icon="el-icon-edit" class="m-2" @click="handleEdit(row.id)">编辑</el-button>
            <el-popconfirm title="删除后将无法找回，确定继续吗？" @confirm="handleDelete(row.id)">
              <el-button slot="reference" type="danger" class="m-2" icon="el-icon-delete">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="tableData.count > 0 && equipment"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="handleOpen"
      />
      <van-pagination
        v-show="tableData.count > 0 && !equipment"
        v-model="AccountPage"
        :total-items="tableData.count"
        :show-page-size="4"
        force-ellipses
        class="custom-pagination m-t-20"
        @change="handleAccountChange"
      />
    </div>
  </el-dialog>
</template>

<script>
// 分页组件
import Pagination from '@/components/Pagination'
// API
import { getPayRecordList, addPayRecord, deletePayRecord, getPayRecordDetail, editPayRecord } from '@/api/admin/pay_record.js'

export default {
  name: 'PayRecord',
  components: { Pagination },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      },
      form: {
        product_type: 0,
        is_refund: 0,
        buy_number: '',
        pay_money: '',
        refund_money: '',
        pay_type: '银行卡',
        pay_time: '',
        refund_time: '',
        notice: ''
      },
      rules: {
        buy_number: [{ required: true, message: '请输入购买时长', trigger: 'blur' }],
        pay_money: [{ required: true, message: '请输入付款金额', trigger: 'blur' }],
        pay_type: [{ required: true, message: '请选择付款方式', trigger: 'blur' }],
        pay_time: [{ required: true, message: '请选择付款时间', trigger: 'blur' }]
      },
      buy_number: '年',
      tableLoading: false,
      innerVisible: false,
      handleId: 0,
      equipment: false,
      AccountPage: 1,
      productType: [
        { id: 0, name: '新购' },
        { id: 1, name: '续费' },
        { id: 2, name: '短信' },
        { id: 3, name: '国际短信' }
      ]
    }
  },
  created() {
    // 判断页面是否大于768
    this.equipment = document.body.clientWidth > 768
  },
  mounted() {},
  methods: {
    // 打开外层的回调
    handleOpen() {
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      const queryS = []
      if (this.id) {
        queryS.push({ field: 'appid', key: this.id })
      }
      data.query = this.utils.getQueryParams(queryS)

      getPayRecordList(data).then(res => {
        this.tableData = res.data
        this.tableLoading = false
      })
    },
    // 关闭外层的回调
    handleClose() {
      console.log('关闭')
    },
    // 打开内层的回调
    handleInnerOpen() {
      console.log('打开内层')
    },
    // 关闭内层的回调
    handleInnerClose() {
      this.form = {
        product_type: 0,
        is_refund: 0,
        buy_number: '',
        pay_money: '',
        refund_money: '',
        pay_type: '银行卡',
        pay_time: '',
        refund_time: '',
        notice: ''
      }
      this.handleId = 0
      this.handleOpen()
    },
    // 添加
    handleAdd(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.form.aid = this.id
          this.form.buy_number = this.form.buy_number + this.buy_number
          addPayRecord(this.form).then(() => {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.innerVisible = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 修改
    handleModify(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.form.aid = this.id
          this.form.buy_number = this.form.buy_number + this.buy_number
          editPayRecord(this.handleId, this.form).then(() => {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.innerVisible = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 编辑
    handleEdit(id) {
      this.handleId = id
      getPayRecordDetail(id).then(res => {
        this.form.product_type = res.data.product_type
        this.form.is_refund = res.data.is_refund
        this.buy_number = res.data.buy_number.slice(-1)
        this.form.buy_number = res.data.buy_number.substring(0, res.data.buy_number.length - 1)
        this.form.pay_money = res.data.pay_money
        this.form.refund_money = res.data.refund_money
        this.form.pay_type = res.data.pay_type
        this.form.pay_time = res.data.pay_time
        this.form.refund_time = res.data.refund_time
        this.form.notice = res.data.notice
        this.innerVisible = true
      })
    },
    // 删除
    handleDelete(id) {
      deletePayRecord(id).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.handleOpen()
      })
    },
    // 回调关闭
    handleCloseDialog() {
      this.$emit('handleClosePayment')
    },
    handleAccountChange(page) {
      this.listQuery.page = page
      this.handleOpen()
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
