var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleTemplate,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "25%",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleTemplate = $event
            },
            open: _vm.handleOpen
          }
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "title_title" }, [
                _vm._v("选择合同标签")
              ]),
              _c(
                "div",
                {
                  staticClass: "title_icon cursor",
                  on: { click: _vm.handleClose }
                },
                [_c("i", { staticClass: "el-icon-close" })]
              )
            ])
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ],
              staticClass: "tags_layout"
            },
            [
              _vm._l(_vm.dialogData.list, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "cursor",
                    class: _vm.idList.includes(item.id)
                      ? "select font_color"
                      : "unchecked",
                    on: {
                      click: function($event) {
                        return _vm.handleSelect(item.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "font_color unchecked cursor",
                  on: { click: _vm.handleAddTag }
                },
                [_vm._v("+ 添 加")]
              )
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭")
              ])
            ],
            1
          )
        ]
      ),
      _c("settingsDialog", {
        attrs: { "dialog-visible-settings": _vm.dialogVisibleSettings },
        on: { closeVisibleSettings: _vm.closeVisibleSettings }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }