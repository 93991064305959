import request from '@/utils/request'

// 区号列表
export function getAreaCodeList(params) {
  return request({
    url: '/admin/v1/area_code',
    method: 'get',
    params
  })
}

// 区号详情
export function getAreaCodeDetail(id) {
  return request({
    url: `/admin/v1/area_code/${id}`,
    method: 'get'
  })
}

// 区号添加
export function addAreaCode(data) {
  return request({
    url: '/admin/v1/area_code',
    method: 'post',
    data
  })
}

// 区号编辑
export function editAreaCode(id, data) {
  return request({
    url: `/admin/v1/area_code/${id}`,
    method: 'put',
    data
  })
}

// 区号删除
export function delAreaCode(id) {
  return request({
    url: `/admin/v1/area_code/${id}`,
    method: 'delete'
  })
}
