<template>
  <div class="app-container">
    <!-- 筛选 -->
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="物料类型">
            <el-select v-model="listQueryParams.type" placeholder="请选择" filterable clearable>
              <el-option label="约课端场馆码" :value="1" />
              <el-option label="签到码" :value="3" />
              <el-option label="客询码" :value="5" />
            </el-select>
          </el-form-item>
          <el-form-item label="二维码类型">
            <el-select v-model="listQueryParams.qr_type" placeholder="请选择" filterable clearable>
              <el-option label="公众号" value="wechat" />
              <el-option label="小程序" value="program" />
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input
          v-model="listQueryParams.query"
          placeholder="搜索物料名称|物料模板"
          @keyup.enter.native="handleFilter"
        >
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('material_management_add')" type="primary" class="filter-item m-l-10" icon="el-icon-plus" @click="handleAdd">添加</el-button>
    </div>
    <el-table v-loading="loading" :data="tableData.list" border style="width: 100%">
      <el-table-column prop="name" label="物料名称" min-width="150px" />
      <el-table-column prop="template" label="物料模版" min-width="150px" />
      <el-table-column prop="view" label="预览图" min-width="150px">
        <template slot-scope="{ row }">
          <el-image style="width: 150px;" :src="row.view" :preview-src-list="[row.view]" />
        </template>
      </el-table-column>
      <el-table-column prop="type" label="物料类型" min-width="150px">
        <template slot-scope="{ row }">
          <el-tag>
            {{ row.type === 1 ? '约课端场馆码' : row.type === 3 ? '签到码' : row.type === 5 ? '课询码' : '---' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="qr_type" label="二维码类型" min-width="150px">
        <template slot-scope="{ row }">
          <span v-if="row.qr_type === 'wechat'">{{ row.qr_type }}（公众号）</span>
          <span v-if="row.qr_type === 'program'">{{ row.qr_type }}（小程序）</span>
        </template>
      </el-table-column>
      <el-table-column prop="weigh" label="排序" min-width="150px" />
      <el-table-column label="操作" min-width="160px">
        <template slot-scope="{ row }">
          <el-button v-show="btn_access('material_management_edit')" type="primary" icon="el-icon-edit" class="m-2" @click="handleEdit(row.id)">编辑</el-button>
          <el-popconfirm v-show="btn_access('material_management_del')" title="删除该物料，确定继续吗？" @confirm="handleDelete(row.id)">
            <el-button slot="reference" type="danger" class="m-2" icon="el-icon-delete">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <el-dialog
      :title="id ? '编辑物料' : '添加物料'"
      width="30%"
      :visible.sync="outerVisible"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <el-dialog
        width="25%"
        title="选择物料模板"
        :visible.sync="innerVisible"
        append-to-body
        custom-class="import-dialog"
      >
        <!-- 内层内容 -->
        <div class="industryList">
          <div
            v-for="(item, index) in templateList"
            :key="index"
            class="industry"
            :style="{
              border: item.name === industry_code ? '4px solid rgb(1, 197, 1)' : '4px solid #FFF;'
            }"
            @click="handelIndustry(item.name)"
          >
            <!-- <img :src="item.bg_src" alt=""> -->
            <el-image style="width: 100%; height: 100%" :src="item.bg_src" fit="cover" />
            <div class="text-center">
              <div>{{ item.name }}</div>
              <!-- <div>{{ item.en_name }}</div> -->
            </div>
            <!-- <div
                class="industry_icon"
                :style="
                  item.name === industry_code
                    ? 'color: rgb(1, 197, 1); border: 1px solid rgb(1, 197, 1);'
                    : 'color: #FFF; border: 1px solid #FFF'
                "
              >
                <i class="el-icon-check" />
              </div> -->
          </div>
        </div>
      </el-dialog>
      <!-- 外层内容 -->
      <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="100px">
        <!-- 物料名称 -->
        <el-form-item label="物料名称" prop="name">
          <el-input v-model="form.name" clearable placeholder="物料名称" />
        </el-form-item>
        <!-- 物料名称 -->
        <el-form-item label="物料模版" prop="template">
          <div class="flex">
            <el-input v-model="form.template" :disabled="true" clearable placeholder="物料模版" />
            <el-button class="m-4" type="success" @click="handleInnerMaterial">选择物料模板</el-button>
          </div>
        </el-form-item>
        <!-- 预览图地址 -->
        <el-form-item label="预览图地址" prop="view">
          <el-input v-model="form.view" clearable placeholder="https://xxxxx">
            <template slot="append">
              <el-upload
                class="upload-demo"
                :action="adminUpload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                :on-success="upSuccess"
                :headers="utils.upload_headers()"
                :show-file-list="false"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </template>
          </el-input>
        </el-form-item>
        <!-- 物料类型 -->
        <el-form-item label="物料类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">约课端场馆码</el-radio>
            <el-radio :label="3">签到码</el-radio>
            <el-radio :label="5">客询码</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 二维码类型 -->
        <el-form-item label="二维码类型" prop="qr_type">
          <el-radio-group v-model="form.qr_type">
            <el-radio label="wechat">公众号</el-radio>
            <el-radio label="program">小程序</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 排序 -->
        <el-form-item label="排序" prop="weigh">
          <el-input
            v-model="form.weigh"
            type="number"
            placeholder="排序"
            oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
          />
        </el-form-item>
      </el-form>
      <div v-if="!id" slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddMaterial('form')">新 增</el-button>
      </div>
      <div v-if="id" slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAddMaterial('form')">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// API
import { getList, getDataList, postAdd, getDetails, putToUpdate, deleteMaterial } from '@/api/admin/material_management'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包

export default {
  components: { Pagination },
  data() {
    return {
      tableData: {
        list: [],
        count: 0
      }, // 列表
      loading: false, // 列表加载
      templateList: [], // 模板列表
      outerVisible: false, // 外层
      innerVisible: false, // 内层
      form: {
        name: '',
        template: '',
        view: '',
        type: '',
        weigh: 50,
        qr_type: ''
      }, // 表单
      rules: {
        name: [{ required: true, message: '请输入物料名称', trigger: 'blur' }],
        template: [{ required: true, message: '请输入物料模版', trigger: 'blur' }],
        view: [{ required: true, message: '请输入预览图地址', trigger: 'blur' }],
        type: [{ required: true, message: '请输入物料类型', trigger: 'blur' }],
        qr_type: [{ required: true, message: '请输入二维码类型', trigger: 'blur' }],
        weigh: [{ required: true, message: '请输入排序', trigger: 'blur' }]
      }, // 表单验证
      industry_code: '',
      id: '',
      searchLoading: false // 搜索
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    getList() {
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) {
        querys.push({ field: 'name|template', type: 'like', key: this.listQueryParams.query })
      }
      if (this.listQueryParams.qr_type) querys.push({ field: 'qr_type', key: this.listQueryParams.qr_type })
      if (this.listQueryParams.type) querys.push({ field: 'type', key: this.listQueryParams.type })
      data.query = this.utils.getQueryParams(querys)

      this.loading = true
      getDataList(data).then(res => {
        const data = res.data
        this.tableData = data
        this.loading = false
        this.searchLoading = false
      })
    },
    // 添加物料
    handleAdd() {
      this.outerVisible = true
    },
    // 新增/修改
    handleAddMaterial(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 新增
          if (!this.id) {
            postAdd(this.form)
              .then(res => {
                this.getList()
                this.outerVisible = false
                this.$message({
                  message: res.msg,
                  type: 'success'
                })
              })
              .catch(res => {
                this.$message({
                  message: res.msg,
                  type: 'error'
                })
              })
          }
          // 修改
          if (this.id) {
            putToUpdate(this.id, this.form)
              .then(res => {
                this.getList()
                this.outerVisible = false
                this.$message({
                  message: res.msg,
                  type: 'success'
                })
              })
              .catch(res => {
                this.$message({
                  message: res.msg,
                  type: 'error'
                })
              })
          }
        } else {
          return false
        }
      })
    },
    // 选择物料模版
    handleInnerMaterial() {
      // 点击之后获取模板列表
      this.templateList = []
      getList().then(res => {
        this.templateList = res.data
        this.innerVisible = true
      })
    },
    // 选择的模板
    handelIndustry(name) {
      this.industry_code = name
      this.form.template = name
      this.innerVisible = false
    },
    // 确认关闭外层
    handleClose() {
      this.form.name = ''
      this.form.template = ''
      this.form.view = ''
      this.form.type = ''
      this.form.weigh = 50
      this.form.qr_type = ''
      this.industry_code = ''
      this.id = ''
    },
    // 删除物料
    handleDelete(id) {
      deleteMaterial(id)
        .then(res => {
          this.getList()
          this.$message({
            message: res.msg,
            type: 'success'
          })
        })
        .catch(res => {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        })
    },
    // 编辑
    handleEdit(id) {
      this.id = id
      getDetails(id).then(res => {
        this.form.name = res.data.name
        this.form.template = res.data.template
        this.form.view = res.data.view
        this.form.type = res.data.type
        this.form.weigh = res.data.weigh
        this.form.qr_type = res.data.qr_type
        this.outerVisible = true
      })
    },
    handlePreview(file) {
      console.log(file)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    upSuccess(response, file, fileList) {
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'view', response.data.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.industryList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .industry {
    width: 120px;
    height: 120px;
    margin-top: 30px;
    margin-left: 18px;
    /* background-size: cover;
    background-position: center; */
    position: relative;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
    .industry_text {
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      .industry_title {
        margin: 10px 0 0 20px;
        div {
          font-size: 14px;
          color: #000;
        }
      }
      .industry_icon {
        margin-right: 20px;
        width: 25px;
        height: 25px;
        border: 1px solid #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
//弹出层的高度
::v-deep .el-dialog.import-dialog {
  height: auto;
  max-height: 80vh;
}
//弹出层里内容的高度
::v-deep .el-dialog__body {
  max-height: 70vh !important;
  overflow-y: auto;
}
</style>
