<template>
  <div class="app-container">
    <div class="searchBox">
      <el-form :model="listQueryParams" label-width="100px" label-position="left">
        <el-form-item label="日期筛选">
          <el-date-picker
            v-model="listQueryParams.create_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <!-- 选择课程 -->
        <el-form-item label="课程">
          <Select :type="6" @changeId="handleCurriculumID" />
        </el-form-item>
        <!-- 售卡类型 -->
        <el-form-item label="售卡" prop="sellingCardsID">
          <Select :type="7" @changeId="handleSellingCardsID" />
        </el-form-item>
        <!-- 评分 -->
        <el-form-item label="评分">
          <el-rate
            v-model="listQueryParams.star_level"
            style="padding-top: 6px;"
            show-text
            :texts="texts"
            :colors="colors"
          />
        </el-form-item>
        <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
          搜索
        </el-button>
      </el-form>
    </div>
    <div class="ssk_top tableColumn">
      <el-input v-model="listQueryParams.query" class="ssl_input" placeholder="搜索" @keyup.enter.native="handleFilter">
        <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
      </el-input>
      <router-link :to="{ name: 's_commentsVirtual_add' }">
        <el-button type="primary" icon="el-icon-plus">添加评论</el-button>
      </router-link>
    </div>
    <!-- 评价 -->
    <div class="tableColumn">
      <el-table v-loading="loading" :data="tableData.list" border style="width: 100%" :stripe="true">
        <el-table-column label="买家" width="240">
          <template slot-scope="scope">
            <div class="photo">
              <!-- <el-avatar size="large" :src="scope.row.avatar : https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" /> -->
              <el-avatar shape="square" :size="50" :src="scope.row.user ? scope.row.user.avatar : scope.row.virtual_info.avatar" />
              <span>{{ scope.row.user ? scope.row.user.nickname : scope.row.virtual_info.username }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="评论内容" min-width="140">
          <template slot-scope="scope">
            <div class="content">
              <div>
                <el-tag v-for="(item, index) in scope.row.tags" :key="index" class="pl_elTag" type="info">
                  {{ item }}
                </el-tag>
              </div>
              <div>{{ scope.row.content }}</div>
              <div style="display: flex;">
                <!-- 品论的图 -->
                <div v-for="(item, index) in scope.row.images" :key="index" class="imgUsername">
                  <el-avatar v-if="index <= 5" shape="square" size="small" :src="item" />
                  <div v-if="index === scope.row.images.length - 1">共{{ index + 1 }}张</div>
                </div>
              </div>
              <div v-if="scope.row.reply.length != 0" class="cghf">[场馆回复]: {{ scope.row.reply[0].content }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="星级" min-width="120">
          <template slot-scope="scope">
            <el-rate
              v-model="scope.row.star_level"
              disabled
              show-text
              score-template="{value}"
              :texts="texts"
              :colors="colors"
            />
          </template>
        </el-table-column>
        <el-table-column prop="favour_number" label="点赞" width="80" />
        <el-table-column label="设为精选">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_sift"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="is_siftOff(scope.row.id, scope.row.is_sift)"
            />
          </template>
        </el-table-column>
        <el-table-column label="隐藏评论">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_hidden"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="is_hiddenOff(scope.row.id, scope.row.is_hidden)"
            />
          </template>
        </el-table-column>
        <el-table-column label="交易商品">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 10">[课程]：{{ scope.row.course.name }}</span>
            <span v-if="scope.row.type === 20">[{{ scope.row.goods ? scope.row.goods.is_seckill ? '秒杀' : '售卡' : '---' }}]：{{ scope.row.goods.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="评论时间" />
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button type="danger" @click="handleDeletePin(scope.row.id)">删除评论</el-button>
            <el-button v-if="scope.row.reply.length != 0" type="danger" @click="handleDeletePin(scope.row.reply[0].id)">
              删除评论回复
            </el-button>
            <el-button v-if="scope.row.reply.length == 0" type="warning" @click="handleHF(scope.row.id)">
              回复评论
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="tableData.count > 0"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-input v-model="content" type="textarea" placeholder="请输入评论内容" rows="5" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleYes">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// API
import {
  getCommentsVirtual,
  putSift,
  putUnSift,
  putHidden,
  putUnHidden,
  deleteComments,
  postReply
} from '@/api/store/evaluation'
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import Select from '@/components/elSelect/Select'

export default {
  components: { Pagination, Select },
  data() {
    return {
      // 列表
      tableData: {
        list: [],
        count: 0
      },
      search: '',
      loading: false,
      searchLoading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      texts: ['差', '一般', '还不错', '很满意', '强烈推荐'],
      dialogVisible: false,
      content: '',
      type: 3
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'content', type: 'like', key: this.listQueryParams.query })
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.course_id) querys.push({ field: 'course_id', key: this.listQueryParams.course_id })
      if (this.listQueryParams.goods_id) querys.push({ field: 'goods_id', key: this.listQueryParams.goods_id })
      if (this.listQueryParams.star_level) querys.push({ field: 'star_level', key: this.listQueryParams.star_level })

      data.query = this.utils.getQueryParams(querys)

      getCommentsVirtual(data).then(res => {
        const data = res.data
        this.tableData = data
        this.searchLoading = false
        this.loading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 设为精选
    is_siftOff(row, is_sift) {
      console.log(is_sift)
      if (is_sift === 1) {
        putSift(row).then(() => {
          this.getList()
        })
      } else {
        putUnSift(row).then(() => {
          this.getList()
        })
      }
    },
    // 隐藏评论
    is_hiddenOff(row, is_hidden) {
      if (is_hidden === 1) {
        putHidden(row).then(() => {
          this.getList()
        })
      } else {
        putUnHidden(row).then(() => {
          this.getList()
        })
      }
    },
    // 删除评论
    handleDeletePin(row) {
      this.$confirm('此操作将永久删除该评论, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteComments(row).then(() => {
          this.getList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    },
    // 回复评论
    handleHF(row) {
      this.dialogVisible = true
      this.row = row
    },
    handleClose(done) {
      done()
      this.content = ''
    },
    handleYes() {
      postReply({ content: this.content }, this.row).then(() => {
        this.dialogVisible = false
        this.content = ''
        this.getList()
      })
    },
    handleCurriculumID(val) {
      this.listQueryParams.course_id = val
    },
    handleSellingCardsID(val) {
      this.listQueryParams.goods_id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.tableColumn {
  margin-top: 20px;
}
.ssk_top {
  display: flex;
}

.ssl_input {
  width: 300px;
  margin-right: 20px;
}

.photo {
  display: flex;
  align-items: center;
  span {
    display: block;
    margin-left: 10px;
  }
}
.pl_elTag {
  margin-right: 6px;
  margin-top: 6px;
}
.cghf {
  color: #e6a23c;
  span {
    display: block;
    color: red;
  }
}
.content {
  div {
    margin-top: 6px;
  }
}
.imgUsername {
  display: flex;
  margin-right: 6px;
  div {
    margin-left: 6px;
  }
}
</style>
