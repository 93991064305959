<template>
  <div class="login-container">
    <div class="login-header">
      <div class="header-logo cursor" @click="toUrl(web_link)">
        <img src="@/assets/images/login_logo_dark.svg" alt="" class="logo-img m-t-40 m-l-60">
      </div>
    </div>

    <div class="p-t-60 center">
      <div class="flex login-box col-top">
        <div class="box-left">
          <img src="@/assets/images/apply_left.jpg" alt="">
        </div>
        <div class="box-right">
          <div class="login-logo cursor" @click="toUrl(web_link)">
            <img src="@/assets/images/login_logo_dark.svg" alt="">
          </div>
          <div class=" m-t-30">
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="loginRules"
              class="login-form"
              autocomplete="on"
              size="medium"
              label-position="left"
              @keyup.enter="handleSubmit('loginForm')"
            >
              <el-form-item prop="username">
                <div class="fix">
                  <i class="icon-required" />
                  <Code @area_code="area_code" />
                  <el-input
                    ref="username"
                    v-model="loginForm.username"
                    placeholder="手机号"
                    name="username"
                    type="text"
                    tabindex="1"
                    autocomplete="on"
                    class="input-item"
                  />
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <div class="fix">
                  <i class="icon-required" />
                  <el-input
                    :key="passwordType"
                    ref="password"
                    v-model="loginForm.password"
                    :type="passwordType"
                    :placeholder="$t('login.password')"
                    name="password"
                    tabindex="2"
                    autocomplete="on"
                    class="input-item"
                    @keyup.native="checkCapslock"
                    @blur="capsTooltip = false"
                    @keyup.enter.native="handleLogin"
                  />
                  <span class="show-pwd" @click="showPwd">
                    <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
                  </span>
                </div>
              </el-form-item>
            </el-form>
            <div class="input-item">
              <el-button
                :loading="loading"
                type="primary"
                size="default"
                class="submit"
                @click.native.prevent="handleLogin"
              >
                {{ $t('login.logIn') }}
              </el-button>
            </div>
            <div class="input-item">
              <a :href="web_link + '/freeRegister'" target="_blank">
                <el-button type="primary" size="default" class="submit submits">
                  还没有账号？立即注册
                </el-button>
              </a>
            </div>
            <div class="foot-btn m-y-20">
              <div class="flex  flex-wrap row-between col-center">
                <div class="register btn_text">
                  <router-link to="/verificationCode">
                    <i class="el-icon-receiving" />
                    验证码登录
                  </router-link>
                </div>
                <div class="register btn_text">
                  <router-link to="/resetpwd">
                    <i class="el-icon-lock" />
                    忘记密码
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="termsService">
            登录视为您已同意瑜小九
            <a href="https://www.yuxiaojiu.com/userGreement/" target="_blank">《服务条款》</a>
            <a href="https://www.yuxiaojiu.com/privacyaGreement/" target="_blank">《隐私条款》</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 尾部 -->
    <footer>
      <div class="m-y-10" style="color:#848484">
        瑜小九·知识产品与用户服务的数字化工具
      </div>
      <div class="">
        Copyright © 2015-{{ new Date().getFullYear() }}
        <a :href="web_link" target="_blank" class="link" title="瑜小九">瑜小九预约管理系统</a>
        All Rights Reserved.
        <a target="_blank" href="https://beian.miit.gov.cn/" class="link" rel="nofollow">{{ icpNumber }}</a>
      </div>
      <div>
        <a target="_blank" href="http://www.beian.gov.cn/portal/recordQuery">
          <div class="flex row-center m-y-10">
            <div class="m-r-2"><img :src="filing_img"></div>
            <div>{{ gongan_icp }}</div>
          </div>
        </a>
      </div>
    </footer>

    <!-- 选择场馆 密码登录 -->
    <el-dialog
      :visible.sync="show2"
      center
      class="two dialog_auto my-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div slot="title" style="font-size: 18px;">
        <div class="title">{{ title }}</div>
        <div class="logout" @click="logout">
          退出登录
          <i class="el-icon-right" />
        </div>
      </div>
      <div v-if="venues_list.length <= 0 && toExamineList.length <= 0" class="empty ">
        <div class="text-center">
          <div class="venues_list_empty">
            <img :src="empty_app_img" alt="">
            <br>
            <small class="text-info">暂无品牌，您可以联系客服：{{ callNumber }} 申请专属品牌</small>
          </div>
        </div>
      </div>
      <el-row :gutter="10">
        <div v-for="(item, index) in venues_list" :key="index" class="venues_selector m-b-10">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="flex row-between flex-wrap">
              <div class="flex">
                <div class="venues_img"><img :src="item.logo ? item.logo : defaultLogo" :alt="item.name" class="venues_logo"></div>
                <div class="venues_word">
                  <span class="venues_name">{{ item.name }}</span>
                </div>
              </div>
              <div v-if="item.is_super" class="flex flex-wrap">
                <!-- 格式化时间 -->
                <div class="m-r-10">
                  到期时间：
                  <span v-if="item.is_overdue" class="text-danger">已过期</span>
                  <span v-else class="text-success">
                    {{ item.expiration_time ? formatTime(item.expiration_time) : '永久' }}
                  </span>
                </div>
                <div class="venues_app_management" @click="venuesBtn(item, '', 1)">
                  <el-tooltip class="item" effect="dark" :content="management_title" placement="bottom">
                    <span>{{ management_title }}</span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </el-col>
          <!-- 场馆列表 -->
          <div class="venues_list">
            <div v-if="!utils.empty(item.venues)">
              <el-col v-for="items in item.venues" :key="items.id" :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
                <div v-if="items.role.part_time !== 1" class="venues_item" @click="venuesBtn(item, items)">
                  <el-tooltip effect="dark" :content="items.name" placement="bottom">
                    <div>{{ items.name }}</div>
                  </el-tooltip>
                </div>
                <div v-else class="venues_itemTwo">
                  <div>{{ items.name }}</div>
                  <div class="venues_itemTwoZ">兼 职</div>
                </div>
              </el-col>
            </div>
            <div v-else-if="item.is_super">
              <div class="venues_list_empty">
                您当前没有场馆！
                <el-link type="success" @click="venuesAdd(item)">快速创建场馆</el-link>
              </div>
            </div>
            <div v-else>
              <div class="venues_list_empty">
                <img :src="empty_default_img" alt="">
                <br>
                <small class="text-info">暂无场馆</small>
              </div>
            </div>
          </div>
        </div>
      </el-row>
      <div v-if="toExamineList.length >= 0">
        <div v-for="(item, index) in toExamineList" :key="index" class="type_center m-b-10">
          <!-- <div v-for="(item, index) in zhangxiaojueDataList" :key="index" class="type_center m-b-10"> -->
          <div v-if="item.audit_status === 1" />
          <div v-else class="flex row-between type_centerTwo">
            <div class="flex">
              <el-image
                v-if="item.app.logo"
                style="width: 60px; height: 60px"
                :src="item.app.logo"
                fit="cover"
              />
              <img v-else style="width: 60px; height: 60px" src="@/assets/images/logo.png" alt="">
              <div style="margin-left: 20px;" class="venues_word">{{ item.app.name }}</div>
            </div>
            <div :style="{color: item.audit_status === 0 ? '#FB902D' : '#F93535'}">{{ item.audit_status === 0 ? '审核中' : '审核失败' }}</div>
          </div>
          <div v-if="item.audit_status === 2" class="type_bottom">
            <div>审核原因：</div>
            <div class="words">{{ item.audit_notice === '' ? '无' : item.audit_notice }}</div>
            <div class="cursor flex row-right">
              <div class="type_button" @click="handleIntroduction(item.id)">知道了</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 场馆停用/过期提示框 -->
    <el-dialog
      :visible.sync="show3"
      center
      class="stop_box dialog_auto"
      :close-on-click-modal="false"
      :close-on-press-escape="true"
    >
      <div class="text-center">
        <div class="image">
          <img style="width: 410px; height: 260px;" :src="empty_pause_img">
        </div>
        <div class="text1">
          您当前品牌已被暂停
        </div>
        <div class="text2">您可以通过切换其他场馆或联系客服解决问题</div>
        <div>
          <div class="wx_qrcode">
            <img style="width: 113px; height: 113px" :src="wx_qrcode_img">
          </div>
          <div class="call_number">
            <svg-icon icon-class="phone" />
            {{ callNumber }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserVenues, getStaffSignIn, delStaffSignIn } from '@/api/store/venues.js'
import { getAppInfo } from '@/api/store/management.js'
// 组件
import Code from '@/components/code/index.vue'
// import { getInfo } from '@/api/user'
// import store from '@/store'

export default {
  name: 'Login',
  components: { Code },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号!'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码!'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: '',
        area_code: '+86'
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: 'blur',
            validator: validateUsername
          }
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword
          }
        ]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      show2: false,
      show3: false,
      venues_list: [],
      is_super: 0,
      modals: false,
      appInfo: {},
      app_loading: true,
      venues_list_loading: true,
      is_dev: process.env.NODE_ENV === 'development',
      management_title: '品牌管理',
      empty_app_img: require('@/assets/images/empty_app.png'),
      empty_pause_img: require('@/assets/images/empty_pause.png'),
      wx_qrcode_img: require('@/assets/images/qrcode-app.png'),
      filing_img: require('@/assets/images/filing.png'),
      gongan_icp: '浙公网安备 33021202002015号',
      title: '请选择您要进入的场馆',
      toExamineList: [],
      zhangxiaojueDataList: [
        {
          app: {
            logo: 'https://static.yuxiaojiu.cn/uploads/t_0575/20230223/872bf670a62515b9c04f6622f4dfd5fa.png',
            name: '玛尼瑜伽玛尼瑜伽玛尼瑜伽'
          },
          audit_status: 0
        },
        {
          app: {
            logo: 'https://static.yuxiaojiu.cn/uploads/t_0575/20230223/872bf670a62515b9c04f6622f4dfd5fa.png',
            name: '玛尼瑜伽玛尼瑜伽玛尼瑜伽'
          },
          audit_status: 0
        },
        {
          app: {
            logo: 'https://static.yuxiaojiu.cn/uploads/t_0575/20230223/872bf670a62515b9c04f6622f4dfd5fa.png',
            name: '玛尼瑜伽玛尼瑜伽玛尼瑜伽'
          },
          audit_status: 2,
          audit_notice: ''
        },
        {
          app: {
            logo: 'https://static.yuxiaojiu.cn/uploads/t_0575/20230223/872bf670a62515b9c04f6622f4dfd5fa.png',
            name: '玛尼瑜伽玛尼瑜伽玛尼瑜伽'
          },
          audit_status: 2,
          audit_notice: 'asdafasnfjasndfasdfasdfa'
        }
      ]
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          // console.log(this.redirect)
          this.otherQuery = this.getOtherQuery(query)
          if (query.referer_path === '/store/withdraw_apply/index') {
            this.management_title = '去提现'
          }
        }
      },
      immediate: true
    }
  },
  created() {},
  mounted() {
    // console.log(this.$store)
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
    setTimeout(() => {
      if (this.$store.getters.token) {
        this.getVenues()
        localStorage.removeItem('Appid')
      }
    }, 300)
    localStorage.setItem('roles_refresh', false)
  },
  methods: {
    // 退出登录
    logout() {
      this.$confirm('此操作将退出账号登录状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.show2 = false
          this.$store.dispatch('user/logout')
          this.$message({ type: 'success', message: '退出成功!' })
        })
        .catch(() => {})
    },
    handleIntroduction(id) {
      this.show2 = false
      delStaffSignIn(id).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '退出成功!'
          })
          this.$store.dispatch('user/logout')
        }
      })
    },
    // 创建场馆
    venuesAdd(item) {
      const item_s = '{"id":-1}'
      localStorage.setItem('Venues', item_s)
      // 选择场馆ID
      localStorage.setItem('Appid', item.id)
      this.$router.push({ name: 's_venues_add' })
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    // 查看密码
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 获取场馆
    getVenues() {
      // 不能调用 this.$store.dispatch('user/getInfo'  这样permission.js 会判断已经有了角色，只跳转，不生成路由了
      // getInfo('/v1/userInfo', this.$store.getters.token).then((res) => {
      //   this.is_super = res.data.is_super
      //   localStorage.setItem('is_super', this.is_super ? 'true' : 'false')
      // })
      getUserVenues()
        .then(res => {
          this.show2 = true
          this.venues_list = res.data
          this.venues_list_loading = false
          this.app_loading = false
        })
        .catch(() => { })
      getStaffSignIn().then(res => {
        this.toExamineList = res.data
      })
    },
    // 登录
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const data = {
            phone: this.loginForm.username,
            password: this.loginForm.password,
            area_code: this.loginForm.area_code === '+86' ? '86' : this.loginForm.area_code.replace(/[^0-9]/gi, '')
          }
          this.loading = true
          // 调用了store下面的user里面的login方法
          this.$store
            .dispatch('user/login', data)
            .then(() => {
              if (localStorage.getItem('authority') === 'store') {
                this.getVenues()
              } else {
                this.$router.push({
                  path: this.redirect || '/',
                  query: this.otherQuery
                })
              }

              // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect' && cur !== 'authority') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    // 选择登录的场馆
    venuesBtn(item, Venues, ismanage) {
      // this.show3 = true
      // return false
      let item_s = '{"id":-1}'
      // 选择场馆ID
      localStorage.setItem('Appid', item.id)
      if (!ismanage) {
        if (Venues) item_s = JSON.stringify(Venues)
      } else {
        // 当前选中场馆信息
        localStorage.setItem('Venues', item_s)
        // 跳转到提现页面
        if (this.$route.query.referer_path === '/store/withdraw_apply/index') {
          this.$router.push({ path: this.$route.query.referer_path })
          return false
        }
      }
      // 用户超级管理员
      localStorage.setItem('is_super', item.is_super ? 'true' : 'false')
      // 当前选中场馆信息
      localStorage.setItem('Venues', item_s)
      getAppInfo()
        .then(res => {
          this.appInfo = res.data
          if (!res.data.logo) this.appInfo.logo = this.defaultLogo
          this.app_loading = false
          // APP信息
          localStorage.setItem('App', JSON.stringify(res.data))
          if (
            this.appInfo.expiration_time !== 0 &&
            this.appInfo.expiration_time <
              this.$moment()
                .add(7, 'days')
                .unix()
          ) {
            const e = this.$moment.unix(this.appInfo.expiration_time).format('yyyy-MM-DD') // 开始时间
            const s = this.$moment().format('yyyy-MM-DD') // 结束时间
            var msg = this.appInfo.is_expire
              ? '当前品牌已过期，如需使用，请续费'
              : `当前品牌即将到期，剩余${this.$moment(e).diff(s, 'days')}天，请及时续费！`

            // 套餐过期，显示购买
            this.$confirm(msg, '提示', {
              confirmButtonText: '去续费',
              cancelButtonText: '再想想',
              type: 'warning'
            })
              .then(() => {
                // const item_s = '{"id":-1}'
                // localStorage.setItem('Venues', item_s)
                console.log('去续费')
                this.$router.push({ name: 's_plan' })
              })
              .catch(() => {
                this.$router.push({
                  path: this.redirect || '/',
                  query: this.otherQuery
                })
              })
          } else {
            this.$router.push({
              path: this.redirect || '/',
              query: this.otherQuery
            })
          }
        })
        .catch(() => {})
    },
    // 验证表单
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          if (this.errorNum >= 2) {
            this.modals = true
          } else {
            this.modals = false
          }
        }
      })
    },
    // 格式化时间
    formatTime(val) {
      return this.$moment.unix(val).format('YYYY-MM-DD')
    },
    area_code(val) {
      this.loginForm.area_code = val
    }
  }
}
</script>

<style lang="scss">
.login-container {
  .login-form {
    .el-input {
      input {
        width: 100%;
        height: 42px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        border-color: transparency;
        border: 1px;
        /* border: 1px solid #dae0e6; */
        /* border-radius: 4px; */
        padding: 0 20px;
        line-height: 42px;
        outline: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.login-container {
  background: #f5f6f9 !important;
  height: inherit;
  .login-header {
    .header-logo {
      .logo-img {
        width: 220px;
      }
    }
  }
  @keyframes scalein {
    from {
      -webkit-transform: scale(0.4);
      transform: scale(0.4);
    }
    to {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .login-box {
    z-index: 1;
    -webkit-box-shadow: 0px 2px 14px 0px rgb(0 0 0 / 6%);
    box-shadow: 0px 2px 14px 0px rgb(0 0 0 / 6%);
    -webkit-animation: scalein 0.3s;
    animation: scalein 0.3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 18px;
    overflow: hidden;
    height: 540px;
    .box-left {
      width: 480px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        display: block;
      }
    }
    .box-right {
      background: #ffffff;
      width: 420px;
      padding: 40px;
      height: 100%;
      position: relative;
      .termsService {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 12px;
        color: rgb(179, 179, 179);
        a:hover{
          color: rgb(20, 156, 175);
        }
      }
      .login-logo {
        img {
          width: 100%;
          padding: 20px 0px;
        }
      }
      .input-item {
        margin: 20px 0 20px 0;
        input {
          width: 100%;
          height: 42px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          border: 1px solid #dae0e6;
          border-radius: 4px;
          padding: 0 20px;
          line-height: 42px;
          outline: none;
        }
      }
      .submit {
        &:hover {
          background: linear-gradient(11deg, #01cbca, #1892aa);
        }
        display: block;
        height: 42px;
        // background: #ff6a00;
        background: linear-gradient(145deg, #01cbca, #1892aa);
        border-radius: 4px;
        margin: 0 auto;
        text-align: center;
        // line-height: 42px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        width: 100%;
        border: unset;
      }
      .submits {
        &:hover {
          background: #fff;
        }
        background: #fff;
        color: #1892aa;
        border: 1px solid #1892aa;
      }
      .show-pwd {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
      }
      .foot-btn {
        .btn_text {
          &:hover {
            color: #ffac5e;
          }
          cursor: pointer;
          font-size: 14px;
          // margin-bottom: 10px;

          span {
            &:first-of-type {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }

  footer {
    text-align: center;
    margin: 100px 20px 0 20px;
  }
}
.venues_img {
  margin-left: 5px;
}
.venues_word {
  width: 150px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

img {
  -webkit-user-drag: none;
}

.center {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-required {
  &:after {
    content: '*';
    color: red;
    line-height: 46px;
  }
  position: absolute;
  left: 8px;
  top: 3px;
  bottom: 0;
  vertical-align: middle;
  z-index: 99;
}

.two {
  .logout {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #909399;
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;
  }
  .logout:hover {
    color: #ff8719;
  }
  .venues_logo {
    width: 60px;
    height: 60px;
    // border: 1px solid #e2e2e2;
    border-radius: 6px;
  }
  /* @media screen and (max-width: 460px) {
    .venues_selector {
      padding: 0px !important;
    }
  } */
  .venues_selector {
    display: flex;
    flex-direction: column;
    padding: 18px 12px 12px 12px;
    /* width: 710px; */
    margin: 0 auto;
    background-color: #FFFFFF;
    border-radius: 5px;
  }

  .venues_name {
    padding-left: 20px;
  }

  .venues_app {
    padding-bottom: 10px;
  }

  .venues_app_management {
    background: #ff8719;
    color: #fff;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
  }
  .venues_list {
    margin-top: 5px;
    padding: 0px 5px;
    .venues_item {
      text-align: center;
      cursor: pointer;
      background: #41c3a8;
      color: #fff;
      display: inline-block;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      border: 1px solid #41c3a8;
      line-height: 48px;
      margin: 0px 0px 10px 0px;
      overflow: hidden;
    }
    .venues_itemTwo {
      text-align: center;
      cursor: not-allowed;
      background: #41c3a983;
      color: #fff;
      display: inline-block;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      border: 1px solid #41c3a965;
      line-height: 48px;
      margin: 0px 0px 10px 0px;
      overflow: hidden;
      position: relative;
      .venues_itemTwoZ {
        position: absolute;
        /* z-index: 999; */
        top: -1px;
        right: -1px;
        font-size: 12px;
        width: 50px;
        line-height: 20px;
        background-color: #FFD099;
        border-radius: 4px;
      }
    }
    .venues_list_empty {
      padding-top: 30px;
      font-size: 15px;
      text-align: center;
      // color:#f1c40f;
      img {
        width: 160px;
      }
    }
  }
}

.stop_box {
  .image {
  }
  .text1 {
    color: #09aeb7;
    font-size: 22px;
    letter-spacing: 5px;
    margin: 12px;
  }
  .text2 {
    color: #999999;
    font-size: 15px;
    margin-bottom: 25px;
    letter-spacing: 2px;
  }
  .call_number {
    color: #999999;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 10px 0px 10px 0px;
  }
}
.fix {
  height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid #dae0e6;
  border-radius: 4px;
  .CodeList {
    width: 100px;
    background: transparent;
    border-right: none;
    .el-input {
      .el-input__inner {
        border: none !important;
      }
    }
  }
}
.type_center {
  /* width: 710px; */
  margin: 0 auto;
  border-radius: 5px;
  background-color: #FFFFFF;
  .type_centerTwo {
    padding: 18px 35px 20px 13px;
  }
  .type_bottom {
    border-top: 1px solid #EEEEEE;
    padding: 9px 12px 12px 13px;
    font-size: 14px;
    color: #333333;
    .words {
      font-size: 12px;
      color: #999999;
      padding-top: 8px;
    }
    .type_button {
      width: 100%;
      margin-top: 13px;
      width: 100px;
      line-height: 40px;
      background-color: #01CBCA;
      text-align: center;
      border-radius: 5px;
      color: #FFFFFF;
    }
  }
}
::v-deep .el-dialog__wrapper {
  .el-dialog {
    background: #F8F8F8;
  }
}
@media screen and (max-width: 900px) {
  .box-left {
    display: none;
  }
  .header-logo {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .login-box {
    box-shadow: unset !important;
    border-radius: unset;
  }
  .login-container {
    background: unset !important;
  }
  .title {
    font-size: 14px;
    margin-top: 5px;
    text-align: left;
  }
}
@media screen and (max-width: 600px) {
  .venues_word {
    width: 100px;
  }
  .type_centerTwo {
    padding: 18px 13px 20px 13px !important;
  }
}
</style>
