<template>
  <div class="app-container special-center">
    <el-page-header content="新增报名活动" class="page-header" @back="returnPage()" />
    <el-divider />
    <!-- 表单 -->
    <el-form ref="ruleForm" :rules="rules" :model="form" label-position="right" label-width="120px">
      <p><b>活动信息</b></p>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="封面图" prop="cover">
            <el-upload
              v-loading="upLoading"
              class="images-uploader"
              :action="storeUpload"
              :show-file-list="false"
              :on-success="upSuccess"
              :headers="utils.upload_headers()"
              :on-progress="upProgress"
              accept=".jpg,.jpeg,.png"
            >
              <img v-if="form.cover" :src="form.cover" class="images" title="点击上传图片">
              <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="活动名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入活动名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="活动地点" prop="location">
            <el-input v-model="form.location" placeholder="请输入活动地点" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="活动详情" prop="details">
            <el-button @click="dialogVisibleTask = true">
              {{ form.details ? '编辑活动详情' : '添加活动详情' }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <p><b>活动设置</b></p>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="活动开始时间" prop="event_start_time">
            <el-date-picker
              v-model="form.event_start_time"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              default-time="00:00:00"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="活动结束时间" prop="event_end_time">
            <el-date-picker
              v-model="form.event_end_time"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              default-time="23:59:59"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="报名收费">
            <el-radio-group v-model="form.fee" class="flex">
              <div>
                <el-radio :label="0">免费</el-radio>
                <el-radio label="1">收费</el-radio>
              </div>
              <el-input
                v-model="moneyNumber"
                type="number"
                :disabled="form.fee == 0"
                style="width: 160px;"
                class="m-l-20"
              >
                <template slot="append">元</template>
              </el-input>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <p><b>高级设置</b></p>
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="报名人" prop="buy_identity">
            <el-checkbox-group v-model="form.buy_identity">
              <el-checkbox label="member">会员</el-checkbox>
              <el-checkbox label="visitor">访客</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="14" class="relative">
          <el-tooltip
            class="item"
            effect="dark"
            content="设定报名人数上限后，报名人数将受到限制不得超过设定的人数"
            placement="bottom"
          >
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="总人数限制">
            <el-radio-group v-model="form.max_member" class="flex m-l-10">
              <div>
                <el-radio :label="0">不限</el-radio>
                <el-radio label="1">限制</el-radio>
              </div>
              <el-input
                v-model="peopleNumber"
                type="number"
                :disabled="form.max_member == 0"
                style="width: 160px;"
                class="m-l-20"
              >
                <template slot="append">人</template>
              </el-input>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="报名开始时间">
            <el-radio-group v-model="form.sign_up_start_time" class="flex">
              <div>
                <el-radio :label="0">活动创建时间</el-radio>
                <el-radio label="1">自定义时间</el-radio>
              </div>
              <el-date-picker
                v-model="activity_start_time"
                class="m-l-20"
                :disabled="form.sign_up_start_time == 0"
                type="datetime"
                placeholder="选择日期时间"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                default-time="00:00:00"
              />
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="报名截至时间">
            <el-radio-group v-model="form.sign_up_end_time" class="flex">
              <div>
                <el-radio :label="0">截至活动结束</el-radio>
                <el-radio label="1">自定义时间</el-radio>
              </div>
              <el-date-picker
                v-model="activity_finish_time"
                class="m-l-20"
                :disabled="form.sign_up_end_time == 0"
                type="datetime"
                placeholder="选择日期时间"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                default-time="23:59:59"
              />
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="relative">
          <el-tooltip
            class="item"
            effect="dark"
            content="一旦选择了相应的表单，将在约课端显示，并由报名人填写;若未选择，将不会展示"
            placement="bottom"
          >
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="活动表单">
            <el-select v-model="form.required_info" class="m-l-10" multiple placeholder="请选择">
              <el-option label="姓名" value="name" />
              <el-option label="手机号" value="phone" />
              <el-option label="微信号" value="wechat" />
              <el-option label="性别" value="sex" />
              <el-option label="年龄" value="age" />
              <el-option label="身份证" value="id_card" />
              <el-option label="邮箱" value="email" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="relative">
          <el-tooltip
            class="item"
            effect="dark"
            content="启用后短信通知后，将会在活动开始前两小时为报名人发送“活动即将开启”的通知。示例:尊敬的用户，您在“(场馆名)”报名的活动“(活动名)”将在两小时后开始，请注意准时参加"
            placement="bottom"
          >
            <i class="el-icon-question absolute" />
          </el-tooltip>
          <el-form-item label="活动开始通知">
            <el-switch v-model="form.sms_notify_start" class="m-l-10" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-col>
        <el-col :span="12" class="relative">
          <i class="el-icon-question cursor absolute" @click="recommendation = true" />
          <el-form-item label="加入推荐">
            <el-switch v-model="form.is_recommend" class="m-l-10" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-col>
        <el-col v-if="form.is_recommend" :span="24">
          <el-form-item label="推荐轮播图">
            <el-upload
              v-loading="upLoading"
              class="images-uploader"
              :action="storeUpload"
              :show-file-list="false"
              :on-success="upSuccessS"
              :headers="utils.upload_headers()"
              :on-progress="upProgressS"
              accept=".jpg,.jpeg,.png"
            >
              <img v-if="form.recommend_image" :src="form.recommend_image" class="images" title="点击上传图片">
              <i v-else class="el-icon-plus images-uploader-icon" style="border: 1px dashed #d9d9d9;" />
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 按钮 -->
    <div class="footer">
      <el-button :loading="buttonLoading" @click="$router.push({ name: 's_offline_event' })">取 消</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="submitForm('ruleForm')">提 交</el-button>
    </div>
    <!-- 活动详情 -->
    <el-dialog
      title="活动详情"
      :visible.sync="dialogVisibleTask"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%"
      @open="handleOpen"
    >
      <wangEditor v-model="WangEditorValue" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTask = false">取 消</el-button>
        <el-button type="primary" @click="handleActivityPreserve">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 推荐解释 -->
    <el-dialog
      title="加入推荐示列"
      :visible.sync="recommendation"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="20%"
      @open="handleOpen"
    >
      <div class="font-14 text-info" style="text-align: center;">启用加入推荐，即可在特定板块中展示此活动</div>
      <el-image class="m-t-10" style="width: 100%;" :src="recommendationImg" fit="fill" />
      <span slot="footer" class="dialog-footer flex row-center">
        <el-button type="primary" @click="recommendation = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// API
import { addActivity } from '@/api/store/activitySignUp.js'
import { getImg } from '@/api/store/config.js'
// 组件
import WangEditor from '@/components/wangEditor'
// 函数
import { deepClone } from '@/utils'

export default {
  components: { WangEditor },
  data() {
    return {
      form: {
        cover: '',
        recommend_image: '',
        name: '',
        location: '',
        details: '',
        event_start_time: '',
        event_end_time: '',
        fee: 0,
        buy_identity: ['member', 'visitor'],
        max_member: 0,
        sign_up_start_time: 0,
        sign_up_end_time: 0,
        is_recommend: 0,
        sms_notify_start: 0,
        required_info: ['name', 'phone']
      },
      rules: {
        cover: [{ required: true, message: '请上传活动封面图', trigger: 'blur' }],
        recommend_image: [{ required: true, message: '请上传推荐图', trigger: 'blur' }],
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        location: [{ required: true, message: '请输入活动地点', trigger: 'blur' }],
        details: [{ required: true, message: '请输入活动详情', trigger: 'blur' }],
        event_start_time: [{ required: true, message: '请选择活动开始时间', trigger: 'blur' }],
        event_end_time: [{ required: true, message: '请选择活动结束时间', trigger: 'blur' }],
        buy_identity: [{ required: true, message: '请选择报名人', trigger: 'blur' }]
      },
      dialogVisibleTask: false,
      WangEditorValue: '',
      buttonLoading: false,
      moneyNumber: '',
      peopleNumber: '',
      activity_start_time: '',
      activity_finish_time: '',
      recommendation: false,
      recommendationImg: require('@/assets/images/exampleImage.png')
    }
  },
  mounted() {
    this.coverImg()
  },
  methods: {
    coverImg() {
      getImg('offline_event').then(res => {
        const { data } = res
        if (!this.form.cover) this.$set(this.form, 'cover', data[0])
      })
      getImg('offline_event_recommend')
        .then(res => {
          const { data } = res
          this.$set(this.form, 'recommend_image', data[0])
        })
    },
    // 图片上传
    upSuccess(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'cover', response.data.path)
    },
    upSuccessS(response, file, fileList) {
      this.upLoading = false
      if (response.status !== 200) {
        this.$message.error(response.msg)
        return false
      }
      this.$set(this.form, 'recommend_image', response.data.path)
    },
    upProgress() {
      this.upLoading = true
    },
    upProgressS() {
      this.upLoading = true
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.buttonLoading = true
          const data = deepClone(this.form)
          // 当 data.fee 等于字符串1时 则赋值输入框里面的内容
          if (data.fee === '1') data.fee = this.moneyNumber
          // 当 data.max_member 等于字符串1时 则赋值输入框里面的内容
          if (data.max_member === '1') data.max_member = this.peopleNumber
          // 当 data.sign_up_start_time 等于字符串1时 则赋值输入框里面的内容
          if (data.sign_up_start_time === '1') data.sign_up_start_time = this.activity_start_time
          else data.sign_up_start_time = ''
          // 当 data.sign_up_end_time 等于字符串1时 则赋值输入框里面的内容
          if (data.sign_up_end_time === '1') data.sign_up_end_time = this.activity_finish_time
          else data.sign_up_end_time = ''
          if (data.required_info.length > 0) data.required_info = data.required_info.join(',')
          else data.required_info = ''
          addActivity(data)
            .then(() => {
              this.$message.success('添加成功')
              this.$router.push({ name: 's_offline_event' })
            })
            .finally(() => {
              this.buttonLoading = false
            })
        } else {
          return false
        }
      })
    },
    // 活动打开的回调
    handleOpen() {
      this.WangEditorValue = this.form.details
    },
    // 保存活动
    handleActivityPreserve() {
      this.form.details = this.WangEditorValue
      this.dialogVisibleTask = false
    }
  }
}
</script>

<style lang="scss" scoped>
.images-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.images-uploader .el-upload:hover {
  border-color: #409eff;
}
.images-uploader-icon,
.images {
  font-size: 28px;
  color: #8c939d;
  min-width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
</style>
