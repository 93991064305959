import request from '@/utils/request'

// 储物柜接口列表

// 获取储物柜列表
export function getLockerList(data) {
  return request({
    url: `/store/v1/locker`,
    method: 'get',
    params: data
  })
}

// 获取储物柜详情
export function getLockerInfo(id, data) {
  return request({
    url: `/store/v1/locker/${id}`,
    method: 'get',
    params: data
  })
}

// 添加储物柜
export function addLocker(data) {
  return request({
    url: `/store/v1/locker`,
    method: 'post',
    data
  })
}

// 修改储物柜
export function updataLocker(id, data) {
  return request({
    url: `/store/v1/locker/${id}`,
    method: 'put',
    data
  })
}

// 删除储物柜
export function deleteLocker(id, data) {
  return request({
    url: `/store/v1/locker/${id}`,
    method: 'delete',
    data
  })
}

// 储物柜租赁/归还
export function lockerLeaseRecord(data) {
  return request({
    url: `/store/v1/locker_lease_record`,
    method: 'post',
    data
  })
}

// 储物柜日志
export function getLeaseRecord(data) {
  return request({
    url: `/store/v1/locker_lease_record`,
    method: 'get',
    params: data
  })
}
