import { render, staticRenderFns } from "./SideFooter.vue?vue&type=template&id=2692eaf2&scoped=true&"
import script from "./SideFooter.vue?vue&type=script&lang=js&"
export * from "./SideFooter.vue?vue&type=script&lang=js&"
import style0 from "./SideFooter.vue?vue&type=style&index=0&id=2692eaf2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2692eaf2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\拾谷科技\\悠然瑜伽\\admin\\yoga-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2692eaf2')) {
      api.createRecord('2692eaf2', component.options)
    } else {
      api.reload('2692eaf2', component.options)
    }
    module.hot.accept("./SideFooter.vue?vue&type=template&id=2692eaf2&scoped=true&", function () {
      api.rerender('2692eaf2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/Sidebar/SideFooter.vue"
export default component.exports