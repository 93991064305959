<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :show-close="false"
    width="30%"
    @open="handleOpen"
  >
    <div slot="title">
      <div class="title">
        <div class="title_title">提示</div>
        <div class="title_icon cursor" @click="handleClose">
          <i class="el-icon-close" />
        </div>
      </div>
    </div>
    <div class="dialog_box">
      <el-alert :title="data.title" type="info" show-icon :closable="false" />
      <div v-for="(item, index) in data.list" :key="index" class="m-t-20">
        <div class="flex">
          <div class="w-6 h-24" style="border-radius: 9px; background: #01CBCA;" />
          <div class="font-16 m-l-10">{{ item.title }}</div>
        </div>
        <div class="m-t-10"><el-alert :title="item.content" type="info" :closable="false" /></div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    // 是否显示
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      data: {
        title: '',
        list: []
      }
    }
  },
  methods: {
    handleOpen() {
      switch (this.type) {
        case 1:
          this.data = {
            title: '统计数据仅针对当前场馆；已删除会员的相关数据不计入',
            list: [
              { title: '发卡', content: '所有会员卡实收金额总和（不含已删除的卡，且如果发卡时选择付款方式为“储值卡支付”则不计入）' },
              { title: '付费约课', content: '所有付费约课订单金额总和' },
              { title: '班课收入', content: '所有小班课报班收费，以及续班、转班手续费总和（操作时选择付款方式为“储值卡支付”则不计入）' },
              { title: '班课收入', content: '包括会员卡请假、续卡等卡操作的手续费收入（操作时选择“储值卡支付”则不计入），活动报名订单的收入，以及加入统计的记账收入' },
              { title: '支出', content: '退卡退款金额 + 出班退款金额 + 加入统计的记账支出' }
            ]
          }
          break
        case 2:
          this.data = {
            title: '统计数据仅针对当前场馆；已删除会员的相关数据不计入',
            list: [
              { title: '预约耗卡', content: '对每张未退卡的储值卡以及次数卡计算：预约总扣减额度 ÷ (预约总扣减额度 + 卡当前额度) × 卡实付金额，然后求和' },
              { title: '期限卡耗卡', content: '对每张已开卡且未退卡的期限卡计算：消耗的有效期 ÷ 总有效期 × 卡实付金额，然后求和' },
              { title: '其他', content: '会员卡退卡后，如果卡实付金额大于实际退款金额，那么多出的金额算入耗卡' }
            ]
          }
          break
        case 5:
          this.data = {
            title: '统计数据仅针对当前场馆；已删除会员的相关数据不计入',
            list: [
              { title: '消费金额', content: '办卡消费 + 预约消费 + 班课消费 + 其他消费 - 退卡退款金额 - 出班退款金额' },
              { title: '耗卡资产', content: '预约耗卡 + 期限卡自然耗卡 + 其他耗卡' },
              { title: '剩余耗卡', content: '办卡消费 - 退卡退款金额 - 总耗卡（结果为负数时取零）' },
              { title: '办卡消费', content: '该会员所有会员卡实收金额总和（不含已删除的卡，且如果发卡时选择付款方式为“储值卡支付”则不计入）' },
              { title: '预约消费', content: '该会员所有付费约课订单金额总和' },
              { title: '班课消费', content: '该会员所有小班课报班收费，以及续班、转班手续费总和（操作时选择付款方式为“储值卡支付”则不计入）' },
              { title: '其他消费', content: '包括会员卡请假、续卡等卡操作的手续费（操作时选择付款方式为“储值卡支付”则不计入），和活动报名订单的消费' },
              { title: '退卡退款金额', content: '该会员所有退卡的实际退费总和（操作时选择付款方式为“储值卡支付”则不计入）' },
              { title: '出班退款金额', content: '该会员所有出班退费金额总和（操作时选择付款方式为“储值卡支付”则不计入）' },
              { title: '预约耗卡', content: '对该会员每张未退卡的储值卡以及次数卡计算：预约总扣减额度 ÷ (预约总扣减额度 + 卡当前额度) × 卡实付金额，然后求和' },
              { title: '期限卡耗卡', content: '对该会员每张已开卡且未退卡的期限卡计算：当前已消耗的有效期 ÷ 总有效期 × 卡实付金额，然后求和' },
              { title: '其他耗卡', content: '会员卡退卡后，如果卡实付金额大于实际退款金额，那么多出的金额算入耗卡 ' }
            ]
          }
          break
        default:
          break
      }
    },
    handleClose() {
      this.$emit('dialogVisibleClose')
    }
  }
}
</script>

<style lang="scss" scoped>
/* 标题 */
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_title {
    font-size: 16px;
  }
  .title_icon {
    width: 25px;
    height: 25px;
    border: 1px solid rgb(81, 90, 110);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dialog_box {
  height: 60vh;
  overflow-y: auto;
}
</style>
