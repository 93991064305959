<template>
  <div class="app-container">
    <!-- 筛选 -->
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="top" label-suffix="：" :inline="true">
          <el-form-item label="品牌" prop="brandID">
            <Select :type="1" @changeId="handleBrandID" />
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="listQueryParams.operation_type" placeholder="请选择类型" clearable>
              <el-option label="预售秒杀" :value="10" />
              <el-option label="班课" :value="20" />
              <el-option label="充值订单" :value="30" />
              <el-option label="付费约课" :value="40" />
              <el-option label="提现" :value="99" />
              <el-option label="提现驳回" :value="98" />
            </el-select>
          </el-form-item>
          <el-form-item label="操作时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-form>
        <div>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">
            搜索
          </el-button>
        </div>
      </div>
      <div class="filter-item flex">
        <el-input v-model="listQueryParams.notice" placeholder="搜索备注" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <div class="total m-y-20 font-16">
        剩余提现金额：
        <span class="text-success p-x-2 font-xs">
          <count-to
            :start-val="0"
            :end-val="tableData.balanceCount"
            :duration="2000"
            :decimals="2"
            separator=","
            prefix="¥ "
          />
        </span>
      </div>
      <!-- 表格 -->
      <el-table v-loading="tableLoading" :data="tableData.list" stripe style="width: 100%">
        <el-table-column prop="date" label="品牌LoGo" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.app">
              <img v-if="row.app.logo" :src="row.app.logo" style="width: 60px; height: 60px;">
              <img v-else src="@/assets/images/logo.png" style="width: 60px; height: 60px;" alt="">
              <div class="venues_word">{{ row.app.name }}</div>
            </div>
            <div v-else>
              用户信息丢失
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="变动资金" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.operation_type === 99" style="color: red;">-{{ row.money }}</div>
            <div v-else>{{ row.money }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="money_before" label="变动前" align="center" />
        <el-table-column prop="money_after" label="变动后" align="center" />
        <el-table-column prop="operation_type" label="变动类型" width="120" align="center">
          <template slot-scope="{ row }">
            <el-tag v-if="row.operation_type === 10">预售秒杀</el-tag>
            <el-tag v-if="row.operation_type === 20">班课</el-tag>
            <el-tag v-if="row.operation_type === 30">充值订单</el-tag>
            <el-tag v-if="row.operation_type === 40">付费约课</el-tag>
            <el-tag v-if="row.operation_type === 99">提现</el-tag>
            <el-tag v-if="row.operation_type === 98">提现驳回</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="操作时间" align="center" />
        <el-table-column prop="notice" label="备注" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.notice">{{ row.notice }}</div>
            <div v-else>无</div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="tableData.count > 0"
        :total="tableData.count"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script>
// API
import { getLogList, getLogDetails } from '@/api/admin/capital.js'
// 分页组件
import Pagination from '@/components/Pagination'
// 组件
import Select from '@/components/elSelect/Select'
import CountTo from 'vue-count-to'

export default {
  components: { Pagination, Select, CountTo },
  data() {
    return {
      tableData: {
        list: [],
        count: 0,
        balanceCount: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      searchLoading: false,
      tableLoading: false,
      appid: 0,
      timer: ''
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getList()
    this.timer = new Date().getTime()
  },
  methods: {
    getList() {
      this.searchLoading = true
      this.tableLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit

      var querys = []
      if (this.listQueryParams.create_time) {
        querys.push({ field: 'create_time', type: 'between-time', key: this.listQueryParams.create_time })
      }
      if (this.listQueryParams.id) {
        querys.push({ field: 'appid', key: this.listQueryParams.id })
      }
      if (this.listQueryParams.operation_type) {
        querys.push({ field: 'operation_type', key: this.listQueryParams.operation_type })
      }
      if (this.listQueryParams.notice) {
        querys.push({ field: 'notice', type: 'like', key: this.listQueryParams.notice })
      }
      data.query = this.utils.getQueryParams(querys)

      getLogList(data)
        .then(res => {
          this.tableData = res.data
          this.searchLoading = false
          this.tableLoading = false
        })
        .catch(() => {
          this.searchLoading = false
          this.tableLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 日志详情
    handleDetails(id) {
      getLogDetails(id).then(res => {
        console.log(res.data)
      })
    },
    handleBrandID(val) {
      this.listQueryParams.id = val
    }
  }
}
</script>

<style lang="scss" scoped>
.venues_word {
  width: 200px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
</style>
