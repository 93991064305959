<template>
  <div v-loading="pageloading" class="plan-buy-box">
    <div class="header flex flex-wrap row-between" :style="{backgroundImage:'url('+defaultHeader+')'}">
      <div class="header-title">短信充值</div>
      <div class="app-info flex flex-nowrap row-center">
        <div class="app-s ">
          <div class="flex-1 logs" @click="$router.push({ name: 's_sms_buy_log'})"><i class="el-icon-s-order m-r-2" />查看充值记录</div>
        </div>
      </div>
    </div>
    <div>
      <el-alert
        v-if="appInfo.expiration_time != 0 && appInfo.expiration_time < $moment().unix()"
        :closable="false"
        title="短信剩余不足，请及时购买！"
        type="warning"
        show-icon
      />
    </div>
    <div>
      <el-alert
        :closable="false"
        title="限时活动，先到先得"
        type="success"
        show-icon
      />
    </div>
    <div class="plan-content">
      <div class="plan-list ">
        <div class="plan-residue-box flex">
          <div class="residue-item item-internal">
            <div class="title">国内余量：</div>
            <div><div class="remain-number">{{ appInfo.sms_balance }} <span>条</span></div></div>
          </div>
          <div class="lines" />
          <div class="residue-item item-internation">
            <div class="title">国际余量：</div>
            <div><div class="remain-number">{{ appInfo.sms_internation_balance }} <span>条</span></div></div>
          </div>
        </div>

        <div class="text-info m-t-20">目前仅支持中国大陆、中国澳门地区短信发送，如需支持其他地区请联系客服经理（{{ callNumber }}）咨询开通</div>
      </div>
    </div>
    <div class="plan-content">
      <div class="plan-list flex flex-wrap col-top">
        <div class="label m-y-30 font-lg">选择充值套餐</div>
        <div class="plan-box flex flex-wrap">
          <div v-for="(item,index) in planlist" :key="index" class="plan-item" :class="item.id==check_id?'active':''" @click="check_plan(item.id)">
            <div class="name p-b-5">{{ item.name }}</div>
            <div class="price-s p-b-5 flex flex-wrap"><div class="price">{{ item.price }}元</div><div class="line-price">{{ item.line_price }}元</div></div>
            <div class="validity p-b-5">有效条数：{{ item.number }}条</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="check_id" class="order-content">
      <div class="order-list">
        <div class="order-item flex flex-nowrap col-top">
          <div class="label">订单号：</div>
          <div class="order-id">{{ orderInfo.order.number }}</div>
        </div>
        <div class="order-item flex flex-nowrap col-top">
          <div class="label">流水号：</div>
          <div class="order-prepay-id">{{ orderInfo.prepay_id }}</div>
        </div>
        <div class="order-item flex flex-nowrap col-top">
          <div class="label">支付金额：</div>
          <div class="order-price">{{ orderInfo.order.pay_price }}元</div>
        </div>
        <div class="order-item flex flex-nowrap col-top">
          <div class="label">支付状态：</div>
          <div class="order-type" :class="orderInfo.paid?'text-success':'text-warning'">{{ orderInfo.paid?'已支付':'未支付' }}</div>
        </div>
      </div>
    </div>
    <div v-if="check_id" class="pay-content flex flex-nowrap col-top">
      <div class="label">支付方式</div>
      <div class=" flex flex-wrap">
        <div class="pay-item m-r-40">
          <div class="pay-qrcode"><canvas ref="qrcode_ban" /></div>
          <div class="pay-text flex flex-nowrap row-center">
            <div><img src="@/assets/images/wxpay_icon.png" alt="" class="pay-icon"></div>
            <div class=" m-l-10">微信支付</div>
          </div>
        </div>
        <!-- <div class="pay-item m-r-40">
          <div class="pay-qrcode"><canvas ref="qrcode_ban" /></div>
          <div class="pay-text flex flex-nowrap row-center">
            <div><img src="@/assets/images/alipay_icon.png" alt="" class="pay-icon"></div>
            <div class="m-l-10">支付宝支付</div>
          </div>
        </div> -->
      </div>
    </div>

  </div>
</template>

<script>
import QRCode from 'qrcode'
import { buySmsPlan, getSmsPlan } from '@/api/store/sms.js'
import { getAppInfo } from '@/api/store/management.js'
import { getSmsOrderInfo } from '@/api/store/order.js'
export default {
  data() {
    return {
      planlist: {},
      appInfo: {
        name: '品牌名称',
        logo: ''
      },
      pageloading: false,
      check_id: '',
      orderInfo: {
        order: {}
      },
      defaultHeader: require('@/assets/images/plan_header_bg2.png')
    }
  },
  mounted() {
    this.getAppInfo()
    this.getList()
  },
  methods: {
    getAppInfo() {
      this.pageloading = true
      getAppInfo().then(res => {
        this.appInfo = res.data
        this.pageloading = false
      })
    },
    getList() {
      getSmsPlan().then(res => {
        this.planlist = res.data.list
      })
    },
    check_plan(id) {
      if (this.check_id === id) return false
      this.pageloading = true

      this.check_id = id
      // 生成订单
      buySmsPlan({ plan_id: this.check_id }).then(res => {
        const { data } = res
        this.orderInfo = data
        this.useqrcode('qrcode_ban', data.code_url)
        this.check_order()
      }).finally(() => {
        this.pageloading = false
      })
    },
    useqrcode(name, url) {
      const that = this
      const canvas = that.$refs[name]
      // 去掉qrcode的边框（建议留1px；否则浏览器识别有些困难）
      QRCode.toCanvas(canvas, url, { width: 180, height: 180, margin: 2 }, function(error) {
        if (error) console.error(error)
        that.$forceUpdate()
      })
    },
    check_order() {
      let check
      if (!localStorage.getItem('Appid')) return false
      getSmsOrderInfo(this.orderInfo.order.number).then(res => {
        // 切换页面后销毁轮询
        if (this.$route.name === 's_sms_buy') {
          if (res.data.pay_status) {
            this.$set(this.orderInfo, 'paid', true)
            this.$message.success('支付成功')
            clearTimeout(check)
          } else {
            check = setTimeout(() => {
              this.check_order()
            }, 5000)
          }
        }
      })
    },
    plan_validity(item) {
      var valid_period = JSON.parse(item.valid_period)
      const digital = valid_period['digital']
      const type = valid_period['type']
      var time = ''
      switch (type) {
        case 'month':
          time = '个月'
          break
        case 'day':
          time = '天'
          break
        case 'year':
          time = '年'
          break
        default:
          break
      }
      return digital + time
    },
    formatTime(val) {
      return this.$moment.unix(val).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-buy-box{
  margin: 0 auto;
  width: 1022px;
  min-height: 807px;
  background: #FFFFFF;

  .label{
    font-size: 16px;
    color:#666666;
    width: 150px;
  }
  .remain-number{
    color:#ff8723;
    font-size:30px;
    margin: 10px 10px 10px 5px;
    padding-left: 10px;
    span{
      color:#909399;
      font-size: 14px;
    }
  }
  .header{
    padding: 15px 40px;
    background-size: cover;
    min-height: 72px;
    .header-title{
      font-size: 25px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #FFFFFF;
    }
    .app-info{
      .app-name{
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
      }
      .app-expire{
        color:#a2ffec;
        font-size: 12px;
        display: flex;
      }
      .app-logo{
        margin-right: 5px;
      }
    }
    .app-s{
      // width: 140px;

      .logs{
        cursor: pointer;
      }
    }
  }
}
.plan-content{
  padding:30px 60px 0px;

  .plan-residue-box{
    .lines{
      height: 70px;
      border-right:1px solid rgb(0 0 0 / 10%);
      margin: 0px 80px;
    }
    .item-internal{

      .title{
        border-left: 4px solid #2792f0;
      }
    }
    .item-internation{
      .title{
        border-left: 4px solid #37bd37;
      }
    }
    .residue-item{
    }
    .title{
      font-size: 16px;
      color:#666666;
      border-left: 4px solid #2792f0;
      padding-left: 10px;
    }
  }

  .plan-list{
    padding-bottom: 20px;
    border-bottom:1px solid #EEEEEE;

    .plan-box{
      .plan-item{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.active{
          box-sizing:border-box;
          position: relative;
          border:1px solid #41C3A8;
        }
        &.active::after{
          content: '';
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAhCAYAAAC803lsAAAAAXNSR0IArs4c6QAAAyJJREFUWEfN11lIVGEUwPH/NzOOjeZU2IJiZpvZHpZZZHFnjPYNaaOH6KWEoF4ionqsl6QXe6geokAt70wrWZGtOGOSliGUEUK7RYWWrdaY94srZe5znWa0eT7nzO+e+53D/QT/wc/pVfeK3nY4itUsJNt7DyKlcNxyZSPZojejdyA6oth1CMj880Z6HLLK7TbXxmpHpGRDy2PRoxAdUROj5QDr2p7NHoOsqnRba9/L4xK5sqMB6RGIjqiplScRcllnUxpyyMwSt61PozwDckFXqyKkkHmFOZENkeHnkdLpb1+FDLLwdp79e4P5Iog0f4iQ7RHl5tn+wvLjMpBqBBESSHrpmWjN57sCJBtFBB0y71bO4AbNehWY1B1EUCGzPbkxYSLsuoSx3UUEDTKnKH+o2SSuAYmBIIICUW7mJwiLuAEMN4qIt9l5Uf+pVfg/ja9S4h4lGrXrQLxRxHj7QPZPUFhUchqJbE4LGKKUnEgSjSYdEWsUEW214UpZysGnFZx9XdWCEeD3yJwi90SzSdOnY4hRhB53OnU5VV8+sLPS0y6t2x1xetRkibyCENFGEQLBnnGzSB0QS0bpOT7/9P0bxOHNTwWhb8z+RhF6XGbCZNbEJbG+/BLV9Z87TDXcEcXrThNoFwF7dxBzBw1j15gZ5L18yNHn9ztNNQRJL3Y5NCkLgMiWleJsUQwOj+Be3dsO/yCmT1+OT1vMk68fyawopFH+nZK2CX4hDo9rPkL/niCibfKKmNFsHjGFOx/eND3t4691zSFRFis5UxfRLyyc1WXnqfHVd9nILiGKV10i4BQQ3lmVifZB7EiczkCrjcJ3z3BXP+LV9y8cS15IfISdHQ+KuFv3xu/b7BTiKFYzkOQDVr9VgE0Jk1kbl9Q0ETazBYswNcH2VZUaSe/4XqN41LVCkAtYDFX5fUFKi45j68hk9MVV66tndVlBq+3ZVa12HXF41fXAUcBsFNH2AG8blcKBx+U8/fbRcIlWEKfHtVEKeRgwGa4QpMBmiNOjbpGC7N66hjZBHB51O4KsID1cQGWE4lV3C9gbUHZwkjSkuPALaFL1ZUHLIu8AAAAASUVORK5CYII=) no-repeat right bottom;
          position: absolute;
          left: 147px;
          top: 20px;
          bottom: -1px;
          right: -1px;
          background-size: 50%;
        }
        border-radius: 2px;
        width: 200px;
        border:1px solid #EEEEEE;
        padding: 10px 20px;
        margin:0px 20px 10px 0;
        .name{
          font-size: 18px;
          font-weight: 600;
        }
        .price-s{
          align-items:baseline;
          .price{
            color:#FF5252;
            font-size: 18px;
            font-weight: 500;
            margin-right: 10px;
          }
          .line-price{
            color:#BBBBBB;
            text-decoration: line-through;
            font-size: 10px;
          }
        }
        .validity{
          color: #999999;
        }
      }
    }
  }
}
.order-content{
  padding:0px 60px 20px 60px;
  .order-list{
    border-bottom:1px solid #EEEEEE;
    .order-item{
        font-size: 18px;
        font-weight: 500;
        color:#666666;
        padding: 15px 0px;
        .order-price{
          color:#FF5252;
        }
      }
  }
}
.pay-content{
  padding:0px 60px 50px;
  .pay-item{
    .pay-icon{
      width: 32px;
    }
  }
  .pay-qrcode{
    margin-bottom:20px;
    &,img{
      width: 180px;
      height: 180px;
      box-shadow: 0px 5px 10px 0px rgba(204, 204, 204, 0.42);
    }

  }
}
</style>
