import request from '@/utils/request'

/**
 * 账号API
 */

// 添加账号
export function addAccount(data) {
  return request({
    url: `/admin/v1/system/user`,
    method: 'post',
    data
  })
}

// 编辑账号
export function editAccount(id, data) {
  return request({
    url: `/admin/v1/system/user/${id}`,
    method: 'put',
    data
  })
}

// 账号详情
export function getAccountDetail(id) {
  return request({
    url: `/admin/v1/system/user/${id}`,
    method: 'get'
  })
}

// 获取账号列表
export function getAccountList(params) {
  return request({
    url: `/admin/v1/system/user`,
    method: 'get',
    params
  })
}

// 删除账号
export function deleteAccount(id) {
  return request({
    url: `/admin/v1/system/user/${id}`,
    method: 'delete'
  })
}

/**
 * 角色API
 */

// 获取角色列表
export function getRoleList(params) {
  return request({
    url: `/admin/v1/system/role`,
    method: 'get',
    params
  })
}

// 新增角色
export function addRole(data) {
  return request({
    url: `/admin/v1/system/role`,
    method: 'post',
    data
  })
}

// 角色信息
export function getRoleDetail(id) {
  return request({
    url: `/admin/v1/system/role/${id}`,
    method: 'get'
  })
}
// 编辑角色
export function editRole(id, data) {
  return request({
    url: `/admin/v1/system/role/${id}`,
    method: 'put',
    data
  })
}

// 删除角色
export function deleteRole(id) {
  return request({
    url: `/admin/v1/system/role/${id}`,
    method: 'delete'
  })
}

/**
 * 菜单API
 */

// 新增菜单
export function addMenu(data) {
  return request({
    url: `/admin/v1/system/menu`,
    method: 'post',
    data
  })
}

// 获取菜单列表
export function getMenuList(params) {
  return request({
    url: `/admin/v1/system/menu`,
    method: 'get',
    params
  })
}

// 更新菜单
export function updateMenu(id, data) {
  return request({
    url: `/admin/v1/system/menu/${id}`,
    method: 'put',
    data
  })
}

// 菜单详情
export function getMenuDetail(id) {
  return request({
    url: `/admin/v1/system/menu/${id}`,
    method: 'get'
  })
}

// 删除菜单
export function deleteMenu(id) {
  return request({
    url: `/admin/v1/system/menu/${id}`,
    method: 'delete'
  })
}

// 更新接口路由
export function updateApiRouteS(id, data) {
  return request({
    url: `/admin/v1/system/menu/${id}/api_route`,
    method: 'put',
    data
  })
}

/**
 * 接口路由分类API
 */

// 路由分类列表
export function getApiRouteCategoryList(params) {
  return request({
    url: `/admin/v1/system/route/classify`,
    method: 'get',
    params
  })
}

// 分类详情
export function getApiRouteCategoryDetail(id) {
  return request({
    url: `/admin/v1/system/route/classify/${id}`,
    method: 'get'
  })
}

// 新建分类
export function addApiRouteCategory(data) {
  return request({
    url: `/admin/v1/system/route/classify`,
    method: 'post',
    data
  })
}

// 编辑分类
export function updateApiRouteCategory(id, data) {
  return request({
    url: `/admin/v1/system/route/classify/${id}`,
    method: 'put',
    data
  })
}

// 删除分类
export function deleteApiRouteCategory(id) {
  return request({
    url: `/admin/v1/system/route/classify/${id}`,
    method: 'delete'
  })
}

/**
 * 接口路由API
 */

// 接口路由列表
export function getApiRouteList(params) {
  return request({
    url: `/admin/v1/system/route`,
    method: 'get',
    params
  })
}

// 新建接口路由
export function addApiRoute(data) {
  return request({
    url: `/admin/v1/system/route`,
    method: 'post',
    data
  })
}

// 更新接口路由
export function updateApiRoute(id, data) {
  return request({
    url: `/admin/v1/system/route/${id}`,
    method: 'put',
    data
  })
}

// 接口路由详情
export function getApiRouteDetail(id) {
  return request({
    url: `/admin/v1/system/route/${id}`,
    method: 'get'
  })
}

// 删除接口
export function deleteApiRoute(id) {
  return request({
    url: `/admin/v1/system/route/${id}`,
    method: 'delete'
  })
}
