var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "show-close": false,
        width: _vm.titleName === "posters_marketing" ? "40%" : "25%"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.handleOpen,
        close: _vm.handleClose
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "title_title" }, [
            _vm._v(
              _vm._s(
                _vm.titleName === "posters_marketing"
                  ? "选择营销活动"
                  : "选择非活动海报"
              )
            )
          ]),
          _c(
            "div",
            {
              staticClass: "title_icon cursor",
              on: { click: _vm.handleCancel }
            },
            [_c("i", { staticClass: "el-icon-close" })]
          )
        ])
      ]),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              attrs: { span: _vm.titleName === "posters_marketing" ? 10 : 24 }
            },
            [
              _c(
                "el-skeleton",
                { attrs: { loading: _vm.skeletonLoading, animated: "" } },
                [
                  _c(
                    "template",
                    { slot: "template" },
                    [
                      _c("el-skeleton-item", {
                        staticStyle: { width: "250px", height: "440px" },
                        style: {
                          margin:
                            _vm.titleName === "posters_marketing"
                              ? "10px"
                              : "0 0 0 90px"
                        },
                        attrs: { variant: "image" }
                      })
                    ],
                    1
                  ),
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-col" },
                      [
                        _vm.is_img
                          ? _c("el-image", {
                              staticClass: "cursor",
                              staticStyle: { width: "250px", margin: "'10px'" },
                              attrs: {
                                src: _vm.imgDataList.preview_image,
                                "preview-src-list": [
                                  _vm.imgDataList.preview_image
                                ]
                              }
                            })
                          : _c("el-image", {
                              staticClass: "cursor",
                              staticStyle: { width: "250px", margin: "'10px'" },
                              attrs: {
                                src: _vm.imgDataList.background_image,
                                "preview-src-list": [
                                  _vm.imgDataList.background_image
                                ]
                              }
                            }),
                        _c(
                          "div",
                          { staticClass: "m-t-10" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "info",
                                  icon: "el-icon-download"
                                },
                                on: { click: _vm.handleDownload }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.is_img ? "下载模板" : "下载原图"
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  icon: "el-icon-picture-outline"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.is_img = !_vm.is_img
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.is_img ? "查看原图" : "查看模板"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _vm.titleName === "posters_marketing"
            ? _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "el-skeleton",
                    { attrs: { loading: _vm.skeletonLoading, animated: "" } },
                    [
                      _c("template", { slot: "template" }, [
                        _c(
                          "div",
                          { staticClass: "m-b-10" },
                          [
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "50%" },
                              attrs: { variant: "p" }
                            }),
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "100%" },
                              attrs: { variant: "p" }
                            }),
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "100%" },
                              attrs: { variant: "p" }
                            }),
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "100%" },
                              attrs: { variant: "p" }
                            }),
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "40%" },
                              attrs: { variant: "p" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "m-b-10" },
                          [
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "50%" },
                              attrs: { variant: "p" }
                            }),
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "100%" },
                              attrs: { variant: "p" }
                            }),
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "100%" },
                              attrs: { variant: "p" }
                            }),
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "100%" },
                              attrs: { variant: "p" }
                            }),
                            _c("el-skeleton-item", {
                              staticClass: "m-b-10",
                              staticStyle: { width: "40%" },
                              attrs: { variant: "p" }
                            })
                          ],
                          1
                        )
                      ]),
                      [
                        _vm.marketList.list.length !== 0
                          ? _c(
                              "div",
                              {
                                staticClass: "p-r-20",
                                staticStyle: {
                                  height: "55vh",
                                  "overflow-y": "scroll"
                                }
                              },
                              _vm._l(_vm.marketList.list, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "m-b-10 cursor div_box",
                                    style: {
                                      border:
                                        item.id === _vm.activityId
                                          ? "2px solid #46a6ff"
                                          : "2px solid #ebeef5"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleId(item.id)
                                      }
                                    }
                                  },
                                  [
                                    item.is_seckill === 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "m-b-8",
                                            staticStyle: { color: "#82848a" }
                                          },
                                          [
                                            _vm._v(
                                              " 活动时间：" +
                                                _vm._s(
                                                  item.start_time +
                                                    " - " +
                                                    item.end_time
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("div", { staticClass: "m-b-8" }, [
                                      _vm._v(_vm._s(item.name))
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex row-between",
                                        staticStyle: { color: "#e67e22" }
                                      },
                                      [
                                        item.is_seckill === 1
                                          ? _c("div", [
                                              _vm._v(
                                                "秒杀价：" +
                                                  _vm._s(item.seckill_price)
                                              )
                                            ])
                                          : _c("div", [
                                              _vm._v(
                                                "活动价：" + _vm._s(item.price)
                                              )
                                            ]),
                                        _c("div", [
                                          _vm._v(
                                            "进度：" +
                                              _vm._s(
                                                item.sales_actual +
                                                  "/" +
                                                  item.inventory
                                              )
                                          )
                                        ])
                                      ]
                                    ),
                                    item.is_seckill === 1
                                      ? _c(
                                          "div",
                                          { staticClass: "div_label" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "div_labelZ",
                                                style: {
                                                  "background-color":
                                                    new Date().getTime() <
                                                    new Date(
                                                      item.start_time
                                                    ).getTime()
                                                      ? "#FF6B6B"
                                                      : new Date().getTime() >
                                                        new Date(
                                                          item.end_time
                                                        ).getTime()
                                                      ? "#52C41A"
                                                      : "#FFD700"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      new Date().getTime() <
                                                        new Date(
                                                          item.start_time
                                                        ).getTime()
                                                        ? "未开始"
                                                        : new Date().getTime() >
                                                          new Date(
                                                            item.end_time
                                                          ).getTime()
                                                        ? "已结束"
                                                        : "进行中"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "venuesList_empty text-center p-10"
                              },
                              [
                                _c("img", {
                                  attrs: { src: _vm.empty_default_img, alt: "" }
                                }),
                                _c("div", [
                                  _c("div", { staticClass: "text-info" }, [
                                    _vm._v("暂无营销活动")
                                  ])
                                ])
                              ]
                            )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-right" },
              on: { click: _vm.handleNextStep }
            },
            [_vm._v("下一步")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }