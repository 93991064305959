var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisibleAccount,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "30%",
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisibleAccount = $event
        },
        open: _vm.handleOpen,
        close: function($event) {
          return _vm.handleClose("ruleForm")
        }
      }
    },
    [
      _c(
        "div",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "title_title" }, [
              _vm._v(_vm._s(_vm.type === "add" ? "新增菜单" : "编辑菜单"))
            ]),
            _c(
              "div",
              {
                staticClass: "title_icon cursor",
                on: {
                  click: function($event) {
                    return _vm.handleClose("ruleForm")
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-close" })]
            )
          ]),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm m-t-40",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级ID" } },
                [
                  _c("SelectTree", {
                    attrs: {
                      props: _vm.defaultProps,
                      options: _vm.catalogData,
                      value: _vm.ruleForm.parent_id,
                      clearable: _vm.isClearable,
                      accordion: _vm.isAccordion
                    },
                    on: {
                      getValue: function($event) {
                        return _vm.getValue($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "title", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入菜单标题" },
                    model: {
                      value: _vm.ruleForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "title", $$v)
                      },
                      expression: "ruleForm.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "icon" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入菜单图标" },
                    model: {
                      value: _vm.ruleForm.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "icon", $$v)
                      },
                      expression: "ruleForm.icon"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "name" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入节点名" },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "redirect" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入重定向地址" },
                    model: {
                      value: _vm.ruleForm.redirect,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "redirect", $$v)
                      },
                      expression: "ruleForm.redirect"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "path" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入路径" },
                    model: {
                      value: _vm.ruleForm.path,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "path", $$v)
                      },
                      expression: "ruleForm.path"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "component" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入模版路径" },
                    model: {
                      value: _vm.ruleForm.component,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "component", $$v)
                      },
                      expression: "ruleForm.component"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "activeMenu" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入关联路径" },
                    model: {
                      value: _vm.ruleForm.activeMenu,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "activeMenu", $$v)
                      },
                      expression: "ruleForm.activeMenu"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      clearable: "",
                      placeholder: "请输入排序"
                    },
                    model: {
                      value: _vm.ruleForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "sort", $$v)
                      },
                      expression: "ruleForm.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "hidden" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.hidden,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "hidden", $$v)
                        },
                        expression: "ruleForm.hidden"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("否")
                      ]),
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("是")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "根菜单" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.is_root,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "is_root", $$v)
                        },
                        expression: "ruleForm.is_root"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("否")
                      ]),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("是")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示隐藏" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.is_show,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "is_show", $$v)
                        },
                        expression: "ruleForm.is_show"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("隐藏")
                      ]),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("显示")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("菜单")
                      ]),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("按钮")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      clearable: "",
                      placeholder: "请输入备注"
                    },
                    model: {
                      value: _vm.ruleForm.mark,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "mark", $$v)
                      },
                      expression: "ruleForm.mark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading },
              on: {
                click: function($event) {
                  return _vm.handleClose("ruleForm")
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading, type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("ruleForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }