<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索标题|内容" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('s_notice_add')" class="filter-item m-l-10" type="primary" icon="el-icon-plus" @click="add_edit">
        添加
      </el-button>
    </div>
    <!-- 列表 -->
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <!-- <el-table-column prop="id" label="ID" /> -->
      <el-table-column prop="title" label="标题" />
      <el-table-column prop="venues_id" label="场馆">
        <template slot-scope="{row}">
          <span v-if="row.venues_id===-1">所有</span>
          <span v-if="row.venues">{{ row.venues.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="发布时间" />
      <el-table-column prop="update_time" label="更新时间" />
      <!-- 暂无更新字段权限 -->
      <!-- <el-table-column prop="is_hidden" label="显示">
        <template slot-scope="{row: {is_hidden}}">
          <el-tag v-if="is_hidden==0" type="success">显示</el-tag>
          <el-tag v-else type="danger">隐藏</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column prop="status" label="状态">
        <template slot-scope="{row: {status}}">
          <el-tag v-if="status==0" type="danger">待发布</el-tag>
          <el-tag v-if="status==1" type="success">正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="weigh" label="排序" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-if="utils.getVenues().id==scope.row.venues_id||utils.getVenues().id==-1" v-show="btn_access('s_notice_edit')" class="m-2" type="primary" icon="el-icon-edit" @click="add_edit(scope.row.id)">编辑</el-button>
          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="notice_delete(scope.row.id)"
          >
            <el-button v-if="utils.getVenues().id==scope.row.venues_id||utils.getVenues().id==-1" v-show="btn_access('s_notice_delete')" slot="reference" class="m-2" type="danger" icon="el-icon-delete">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <!-- 添加、编辑公告弹窗 -->
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialog.visible"
      :title="dialog.id ? '编辑公告' : '添加公告'"
      class="dialog_w750"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px" label-position="left">
        <el-form-item label="场馆" prop="venues_id">
          <el-select v-model="form.venues_id" placeholder="请选择场馆" :disabled="true">
            <el-option v-if="utils.getVenues().id===-1" :key="-1" label="所有" :value="-1" />
            <el-option
              v-for="item in venuesOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="请填写标题" />
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <tinymce v-if="dialog.visible" v-model="form.content" :height="200" class="content_edit" />
          <!-- <el-input v-model="form.content" type="textarea" rows="3" placeholder="请填写内容" /> -->
        </el-form-item>
        <el-form-item label="排序" prop="weigh">
          <el-input v-model="form.weigh" type="number" placeholder="请填写内容" />
        </el-form-item>
        <!-- 暂无更新字段权限 -->
        <!-- <el-form-item label="隐藏" prop="is_hidden">
          <el-radio v-model="form.is_hidden" :label="1">是</el-radio>
          <el-radio v-model="form.is_hidden" :label="0">否</el-radio>
        </el-form-item> -->
        <!-- <el-form-item label="状态" prop="status">
          <template>
            <el-radio v-model="form.status" :label="0">待发布</el-radio>
            <el-radio v-model="form.status" :label="1">发布</el-radio>
          </template>
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submit">
          {{ dialog.id ? '保存' : '新增' }}
        </el-button>
        <el-button type="text" @click="dialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->

  </div>
</template>

<script>
import { getNotice, addNotice, updateNotice, getNoticeInfo, delNotice } from '@/api/store/notice.js'
import { getUserVenues } from '@/api/store/venues.js'
import Pagination from '@/components/Pagination' // 分页组件
import Tinymce from '@/components/Tinymce'
export default {
  components: { Pagination, Tinymce },
  data() {
    return {
      form: {
        category_id: 0,
        weigh: 100,
        venues_id: this.utils.getVenues().id,
        status: 0,
        is_hidden: 0
      },
      tableData: {
        list: [],
        count: 0
      },
      is_edit: 0,
      dialog: { visible: false, id: '', addVisible: false, editVisible: false, infoVisible: false },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请填写内容', trigger: 'blur' }
        ],
        venues_id: [
          { required: true, message: '请选择场馆', trigger: 'change' }
        ]
      },
      searchLoading: false,
      loading: { listLoading: true },
      venuesOptions: []
    }
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    remoteMethod() {
      // 远程获取

    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    add_edit(id) {
      this.is_edit = 0
      this.dialog = { visible: true, id: id, addVisible: false, editVisible: false, infoVisible: false }
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
      // 获取场馆列表
      getUserVenues().then(res => {
        this.venuesOptions = res.data[0]['venues']
        console.log('🚀 ~ file: index.vue ~ line 176 ~ getUserVenues ~ this.venuesOptions', this.venuesOptions)
      }).catch(() => {})

      if (id > 0) {
        this.is_edit = 1
        getNoticeInfo(id).then(res => {
          this.form = res.data
        }).catch(() => {})
      } else {
        this.form = { category_id: 0, weigh: 1, venues_id: this.utils.getVenues().id, status: 0, is_hidden: 0 }
        this.dialog.id = 0
      }
    },
    // 公告列表
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      var querys = []
      if (this.listQueryParams.query) querys.push({ field: 'title|content', type: 'like', key: this.listQueryParams.query })

      data.query = this.utils.getQueryParams(querys)

      getNotice(data).then(res => {
        // 数据嵌入
        this.tableData = res.data
        this.loading.listLoading = false
        this.searchLoading = false
      }).catch(() => {})
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = this.form

          if (this.is_edit) {
            updateNotice(this.dialog.id, data).then(res => {
              this.$message.success(res.msg)
              // 重载列表
              this.getList()
              this.dialog.visible = false
            }).catch(() => {})
          } else {
            addNotice(data).then(res => {
              this.$message.success(res.msg)
              // 重载列表
              this.getList()
              this.dialog.visible = false
            }).catch(() => {})
          }
        }
      })
    },
    // 获取详情
    getInfo(id) {
      getNoticeInfo(id).then(res => {
        // 数据嵌入
        this.dialog.addVisible = true
      }).catch(() => {})
    },
    // 删除公告
    notice_delete(id) {
      delNotice(id).then(res => {
        this.$message.success(res.msg)
        this.getList()
      }).catch(() => {})
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
