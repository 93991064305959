import request from '@/utils/request'

// 获取权限列表
export function getPlan(data) {
  return request({
    url: '/admin/v1/plan',
    method: 'get',
    params: data
  })
}

// 删除套餐
export function delPlan(id, data) {
  return request({
    url: `/admin/v1/plan/${id}`,
    method: 'delete',
    params: data
  })
}

// 新增套餐
export function addPlan(data) {
  return request({
    url: '/admin/v1/plan',
    method: 'post',
    data
  })
}

// 修改套餐
export function editPlan(id, data) {
  return request({
    url: `/admin/v1/plan/${id}`,
    method: 'put',
    data
  })
}

// 套餐详情
export function infoPlan(id, data) {
  return request({
    url: `/admin/v1/plan/${id}`,
    method: 'get',
    params: data
  })
}
