<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="searchBox">
        <el-form :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="listQueryParams.create_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="分类">
            <SelectTree
              :props="defaultProps"
              :options="categoryList"
              :value="listQueryParams.category_id"
              :clearable="false"
              :accordion="false"
              :root-name="'全部分类'"
              @getValue="getCategoryValue($event)"
            />
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="listQueryParams.type" placeholder="请选择" filterable clearable>
              <el-option v-for="(item,index) in typeList" :key="index" :label="item" :value="index">{{ item }}</el-option>
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-b-10" @click="handleFilter">搜索</el-button>
        </el-form>
      </div>
      <div class="filter-item">
        <el-input v-model="listQueryParams.query" placeholder="搜索海报名称" @keyup.enter.native="handleFilter">
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <el-button v-show="btn_access('poster_add')" class="filter-item m-l-10" type="primary" icon="el-icon-plus" @click="toPage('')">
        添加
      </el-button>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading.listLoading"
      :data="tableData.list"
      row-key="id"
    >
      <el-table-column align="center" prop="name" label="名称" />
      <el-table-column align="center" prop="background_image" label="背景图">
        <template slot-scope="{row}">
          <div>
            <el-image
              style="width: 100px; height: 100px"
              :src="row.background_image"
              fit="contain"
              :preview-src-list="[row.background_image]"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="preview_image" label="效果图">
        <template slot-scope="{row}">
          <div>
            <el-image
              style="width: 100px; height: 100px"
              :src="row.preview_image"
              fit="contain"
              :preview-src-list="[row.preview_image]"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="category.name" label="分类" />
      <el-table-column align="center" prop="type" label="类型">
        <template slot-scope="{row}">
          {{ typeList[row.type] }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="is_hidden" label="显示/隐藏">
        <template slot-scope="{row}">
          <el-tag v-if="row.is_hidden" type="danger">隐藏</el-tag>
          <el-tag v-else type="success">显示</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="visits" label="访问次数" :render-header="renderId" />
      <el-table-column align="center" prop="weigh" label="排序" />
      <el-table-column align="center" prop="create_time" label="创建时间" />
      <el-table-column align="center" min-width="140" label="操作">
        <template slot-scope="scope">
          <el-button v-show="btn_access('poster_edit')" class="m-2" type="primary" icon="el-icon-edit" @click="toPage(scope.row.id)">编辑</el-button>
          <el-popconfirm
            v-show="btn_access('poster_copy')"
            title="复制这个模版？"
            @confirm="copyTemplate(scope.row)"
          >
            <el-button slot="reference" class="m-2" type="warning" icon="el-icon-copy-document">拷贝</el-button>
          </el-popconfirm>

          <delPopover v-show="btn_access('poster_del')" class="m-2" :list-length="tableData.list.length" :api-data="apiData" :page.sync="listQuery.page" :row="scope.row" btn-msg="删除" @delete="getList" />
        </template>
      </el-table-column>

    </el-table>
    <pagination v-show="tableData.count>0" :total="tableData.count" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import SelectTree from '@/components/TreeSelect/treeSelect.vue'
import { deepClone } from '@/utils'
import { getList, Add } from '@/api/admin/p_template.js'
import Pagination from '@/components/Pagination' // 分页组件
import delPopover from '@/components/All/delPopover'
import { getSorts } from '@/api/admin/category.js'
export default {
  components: { Pagination, delPopover, SelectTree },
  data() {
    return {
      listQueryParams: {
        category_id: 0
      },
      tableData: {
        list: [],
        count: 0
      },
      dialog: { visible: false, id: '' },
      loading: { listLoading: false },
      typeList: ['营销活动海报', '非活动海报'],
      searchLoading: false,
      apiData: { url: '/v1/poster_template' },
      categoryList: [{
        id: 10,
        name: '',
        parent_id: 0
      }],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      order_field: '',
      is_id: 0,
      is_time: 0,
      is_disable: 0
    }
  },
  mounted() {
    this.getList()
    this.getCategory()
  },
  methods: {
    // 取值
    getCategoryValue(value) {
      this.listQueryParams.category_id = value
      console.log('🚀 ~ file: index.vue:157 ~ getCategoryValue ~ value:', value)
    },
    // 获取分类列表
    getCategory() {
      getSorts().then(res => {
        this.categoryList = res.data
        console.log('🚀 ~ file: index.vue ~ line 150 ~ getSortType ~ this.categoryList', this.categoryList)
      })
    },
    getList() {
      this.loading.listLoading = true
      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      data['order_field'] = this.order_field
      data['order_by'] = this.order_by === 1 ? 'asc' : this.order_by === 2 ? 'desc' : ''

      const t = deepClone(this.listQueryParams)

      var querys = []
      if (t.query) querys.push({ field: 'name', type: 'like', key: t.query })
      if (t.type !== undefined && t.type !== '') querys.push({ field: 'type', key: t.type })
      if (t.category_id) querys.push({ field: 'category_id', key: t.category_id })
      if (t.create_time) querys.push({ field: 'create_time', type: 'between-time', key: t.create_time })

      data.query = this.utils.getQueryParams(querys)

      getList(data).then(res => {
        this.tableData = res.data
        this.loading.listLoading = false
      }).finally(() => {
        this.searchLoading = false
      })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 添加编辑
    toPage(id) {
      if (id) {
        this.$router.push({ name: 'poster_template_edit', params: { id: id }})
      } else {
        this.$router.push({ name: 'poster_template_add' })
      }
    },
    // 复制模版
    copyTemplate(item) {
      const data = deepClone(item)
      delete data.id
      delete data.category
      data.name = data.name + '_副本'
      delete data.create_time
      delete data.update_time
      Add(data).then(res => {
        this.$message.success('拷贝成功')
        this.getList()
      })
    },
    // 排序
    renderId(h, { column, $index }) {
      return h(
        'div',
        {
          style: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            justifyContent: 'center'
          },
          on: {
            click: this.handleId
          }
        },
        [
          h(
            'div',
            {
              style: {
                marginRight: '5px'
              }
            },
            column.label
          ),
          h('div', {}, [
            h(
              'div',
              {
                style: {
                  marginBottom: '-14px'
                }
              },
              [
                this.is_id === 0 &&
                h('i', {
                  class: 'el-icon-caret-top'
                }),
                this.is_id === 1 &&
                h('i', {
                  class: 'el-icon-caret-top',
                  style: {
                    color: 'rgb(92, 182, 255)'
                  }
                }),
                this.is_id === 2 &&
                h('i', {
                  class: 'el-icon-caret-top'
                })
              ]
            ),
            h(
              'div',
              {
                style: {
                  marginTop: '-14px'
                }
              },
              [
                this.is_id === 0 &&
                h('i', {
                  class: 'el-icon-caret-bottom'
                }),
                this.is_id === 1 &&
                h('i', {
                  class: 'el-icon-caret-bottom'
                }),
                this.is_id === 2 &&
                h('i', {
                  class: 'el-icon-caret-bottom',
                  style: {
                    color: 'rgb(92, 182, 255)'
                  }
                })
              ]
            )
          ])
        ]
      )
    },
    handleId() {
      this.is_time = 0
      this.is_disable = 0
      this.is_id += 1
      if (this.is_id === 3) this.is_id = 1
      this.order_by = this.is_id
      this.order_field = ''
      this.order_field = 'visits'
      this.getList()
    }
  }
}
</script>

<style  lang="scss" scoped>
.el-input-group__append, .el-input-group__prepend{
  width: 70px;
}
</style>
