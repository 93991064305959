<template>
  <div v-loading="weChatLoading">
    <div v-if="!is_showId" class="weChat_bg m-b-15">
      <div>尚未注册公众号？点此快速注册公众号</div>
      <el-button class="m-x-20" type="primary" @click="toPage()">立即注册</el-button>
    </div>
    <el-row>
      <el-col :span="14">
        <!-- 场馆信息 -->
        <el-card class="m-r-20">
          <div slot="header" class="clearfix">
            <span>场馆信息</span>
            <el-button type="primary" class="m-l-10" size="mini" @click="refresh">刷新品牌二维码</el-button>
          </div>
          <div class="information">
            <div class="information_line flex">
              <div class="information_name">品牌ID</div>
              <div class="information_content">{{ $route.params.id }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">品牌名称</div>
              <div class="information_content">{{ appData.name }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">地区</div>
              <div class="information_content">{{ appData.location ? appData.location : '---' }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">到期时间</div>
              <div class="information_content">{{ appData.expiration_time | formatDate('YYYY-MM-DD') }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">品牌编码</div>
              <div class="information_content">{{ appData.url ? appData.url.split('=')[1] : '---' }}</div>
            </div>
            <div class="information_line flex">
              <div class="information_name">品牌二维码</div>
              <div class="information_content">
                <el-image class="m-r-10" style="width: 50px; height: 50px;" :src="appData.program_open_path" :preview-src-list="[appData.program_open_path]" />
                <el-image style="width: 50px; height: 50px;" :src="appData.wechat_open_path" :preview-src-list="[appData.wechat_open_path]" />
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card>
          <div slot="header" class="clearfix">
            <span>公开信息</span>
          </div>
          <div v-if="is_showId" class="information">
            <div class="information_line flex">
              <div class="information_name">AppID(公众号ID)</div>
              <div class="information_content">{{ getPublicData.appid }}</div>
            </div>
            <!-- <div class="information_line flex">
              <div class="information_name">原始ID</div>
              <div class="information_content">{{ getPublicInfoData.user_name }}</div>
            </div> -->
            <div v-if="is_showUrl">
              <div class="information_line flex">
                <div class="information_name">头像</div>
                <div class="information_content">
                  <div class="avatar">
                    <el-image :src="getPublicInfoData.head_img" :preview-src-list="[getPublicInfoData.head_img]" />
                  </div>
                </div>
              </div>
              <div class="information_line flex">
                <div class="information_name">名称</div>
                <div class="information_content">{{ getPublicInfoData.nick_name }}</div>
              </div>
              <div class="information_line flex">
                <div class="information_name">二维码</div>
                <div class="information_content">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="getPublicInfoData.qrcode_url"
                    :preview-src-list="[getPublicInfoData.qrcode_url]"
                    :referrerpolicy="'no-referrer'"
                  />
                </div>
              </div>
              <div class="information_line flex">
                <div class="information_name">介绍</div>
                <div class="information_content">{{ getPublicInfoData.signature }}</div>
              </div>
              <div class="information_line flex">
                <div class="information_name">认证情况</div>
                <div class="information_content">
                  <div>{{ getPublicInfoData.verify_type_info === -1 ? '未认证' : '微信认证' }}</div>
                </div>
              </div>
              <div class="information_line flex">
                <div class="information_name">主体信息</div>
                <div class="information_content">
                  <div>
                    {{ getPublicData.principal_name }}
                    <span>({{ getPublicInfoData.principal_name }})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>无法获取公众号信息</div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// API
import { getAppDetails, getPublicInfo } from '@/api/admin/merchants.js'
import { refreshApp } from '@/api/admin/merchants.js'

export default {
  components: {},
  data() {
    return {
      appData: {},
      getPublicData: {},
      getPublicInfoData: {},
      officialAccountsSecretKeyData: {},
      weChatLoading: false,
      is_showUrl: false,
      is_showId: false
    }
  },
  created() {
    getAppDetails(this.$route.params.id).then(res => {
      this.appData = res.data
    })
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.weChatLoading = true
      getPublicInfo(this.$route.params.id).then(res => {
        const data = res.data
        if (Object.keys(data.officialAccountsSecretKey).length > 0) {
          this.officialAccountsSecretKeyData = data.officialAccountsSecretKey
          this.is_showId = true
        }
        if (Object.keys(data.officialAccounts).length > 0) {
          this.getPublicData = data.officialAccounts
          this.getPublicInfoData = data.officialAccountsInfo
          this.is_showUrl = true
          this.is_showId = true
        }
        this.weChatLoading = false
      })
    },
    toPage() {
      // this.$router.push({ name: 'adminSignWeChat', params: { id: this.$route.params.id }})
      // 子传父
      this.$emit('toPage')
    },
    refresh() {
      this.$confirm('刷新品牌信息及二维码，确定继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        refreshApp(this.$route.params.id).then(res => {
          this.$message.success('刷新成功')
          this.getList()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.weChat_bg {
  width: 100%;
  height: 60px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: rgb(225, 240, 255);
  padding: 10px 24px;
}
.information {
  width: 100%;
  .information_line {
    /* margin-bottom: 20px; */
    font-size: 13px;
    .information_name {
      /* width: 100px; */
      text-align: left;
      color: #9a9a9a;
      flex: 0.2;
    }
    .information_content {
      padding: 16px 0 16px 20px;
      color: #353535;
      flex: 0.8;
      /* text-align: right; */
      /* border-bottom: 1px solid #e7e7eb; */
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
      }
      .url {
        margin: 5px 0px;
      }
      div:nth-child(1) {
        span {
          color: #9a9a9a;
        }
      }
    }
  }
}
</style>
