/**
 * 总后台路由
 */

import Layout from '@/layout'

const admin = [
  {
    path: '/admin/workbench',
    component: Layout,
    redirect: '/admin/workbench/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/workbench/index'),
        name: 'adminWorkbench',
        meta: {
          title: 'adminWorkbench',
          icon: 'el-icon-odometer',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/admin/merchants',
    component: Layout,
    redirect: '/admin/merchants/index',
    alwaysShow: true, // 总是显示根菜单吗
    meta: {
      title: 'adminMerchants',
      icon: 'peoples',
      roles: ['admin']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/merchants/index'),
        name: 'adminList',
        meta: {
          title: 'adminList',
          activeMenu: '/admin/merchants/index'
        }
      },
      {
        path: 'changeLog/index',
        component: () => import('@/views/admin/merchants/changeLog/index'),
        name: 'admin_change',
        meta: {
          title: 'admin_change',
          activeMenu: '/admin/merchants/changeLog/index'
        }
      },
      {
        path: 'info',
        name: 'adminMiniProgram',
        // redirect: '/admin/merchants/info/miniProgram',
        component: () => import('@/views/admin/merchants/info/index'),
        meta: {
          title: 'adminMiniProgram'
        },
        hidden: true,
        children: [
          // {
          //   path: ':id',
          //   component: () => import('@/views/admin/merchants/info/weChat/index'),
          //   name: 'info',
          //   meta: {
          //     title: 'appInfo',
          //     noCache: true,
          //     activeMenu: '/admin/merchants/index'
          //   },
          //   hidden: true
          // },
          {
            path: ':id/WeChatMiniProgram',
            component: () => import('@/views/admin/merchants/info/weChat/index'),
            name: 'adminWeChatMiniProgram',
            meta: {
              title: 'adminWeChatMiniProgram',
              noCache: true,
              activeMenu: '/admin/merchants/index'
            },
            hidden: true
          },
          {
            path: ':id/WeChatOfficialAccount',
            component: () => import('@/views/admin/merchants/info/publicAccount/index'),
            name: 'adminWeChatOfficialAccount',
            meta: {
              title: 'adminWeChatOfficialAccount',
              noCache: true,
              activeMenu: '/admin/merchants/index'
            },
            hidden: true
          },
          {
            path: ':id/WeChatPay',
            component: () => import('@/views/admin/merchants/info/payment/index'),
            name: 'adminWeChatPay',
            meta: {
              title: 'adminWeChatPay',
              noCache: true,
              activeMenu: '/admin/merchants/index'
            },
            hidden: true
          },
          {
            path: ':id/TikTok',
            component: () => import('@/views/admin/merchants/info/tikTok/index'),
            name: 'adminTikTok',
            meta: {
              title: 'adminTikTok',
              noCache: true,
              activeMenu: '/admin/merchants/index'
            },
            hidden: true
          },
          {
            path: 'weChatAllocation/:id',
            component: () => import('@/views/admin/merchants/info/weChat/components/weChatAllocation/signWeChat.vue'),
            name: 'adminSignWeChat',
            meta: {
              title: 'adminSignWeChat',
              noCache: true,
              activeMenu: '/admin/merchants/index'
            },
            hidden: true
          }
        ]
      },
      {
        path: 'apply',
        component: () => import('@/views/admin/merchants/apply/index'),
        name: 'adminMerchantsApply',
        meta: {
          title: 'adminMerchantsApply'
        }
      },
      {
        path: 'appletRegistration/index',
        component: () => import('@/views/admin/merchants/appletRegistration/index'),
        name: 'adminAppletRegistrationList',
        meta: {
          title: 'adminAppletRegistrationList',
          activeMenu: '/admin/merchants/appletRegistration/index'
        }
      },
      {
        path: 'miniProgram/index',
        component: () => import('@/views/admin/merchants/miniProgram/index'),
        name: 'adminMiniProgramList',
        meta: {
          title: 'adminMiniProgramList',
          activeMenu: '/admin/merchants/miniProgram/index'
        }
      }
    ]
  },
  {
    path: '/admin/account',
    component: Layout,
    redirect: '/admin/account/index',
    alwaysShow: true, // 总是显示根菜单吗
    meta: {
      title: 'adminAccount',
      icon: 'el-icon-s-custom',
      roles: ['admin']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/account/index'),
        name: 'adminAccountList',
        meta: {
          title: 'adminAccountList',
          activeMenu: '/admin/account/index'
        }
      },
      {
        path: 'role',
        component: () => import('@/views/admin/account/role/index'),
        name: 'adminRoleList',
        meta: {
          title: 'adminRoleList',
          activeMenu: '/admin/account/role'
        }
      },
      {
        path: 'menu',
        component: () => import('@/views/admin/account/menu/index'),
        name: 'adminMenuList',
        meta: {
          title: 'adminMenuList',
          activeMenu: '/admin/account/menu'
        }
      },
      {
        path: 'apiRoute',
        component: () => import('@/views/admin/account/apiRoute/index'),
        name: 'adminApiRouteList',
        meta: {
          title: 'adminApiRouteList',
          activeMenu: '/admin/account/apiRoute'
        }
      }
    ]
  },
  {
    path: '/admin/category',
    component: Layout,
    redirect: '/admin/category/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/category/index'),
        name: 'adminCategory',
        meta: {
          title: 'adminCategory',
          icon: 'el-icon-document',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/admin/system',
    component: Layout,
    redirect: '/admin/system/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/system/index'),
        name: 'adminSystem',
        meta: {
          title: 'adminSystem',
          icon: 'el-icon-guide',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/admin/config',
    component: Layout,
    redirect: '/admin/config/index',
    name: 'adminConfig',
    // alwaysShow: true, // 总是显示根菜单吗
    meta: {
      title: 'adminConfig',
      roles: ['admin']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/config/index'),
        name: 'adminConfig',
        meta: {
          title: 'adminConfig',
          icon: 'el-icon-setting'
        }
      },
      {
        path: 'list/:id(\\d+)',
        component: () => import('@/views/admin/config/list'),
        name: 'ConfigList',
        meta: {
          title: 'ConfigList',
          noCache: true,
          activeMenu: '/admin/config/index'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/admin/announcement',
    component: Layout,
    redirect: '/admin/announcement/index',
    meta: {
      roles: ['admin']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/announcement/index'),
        name: 'announcement',
        meta: {
          title: 'announcement',
          icon: 'el-icon-bell'
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import('@/views/admin/announcement/edit'),
        name: 'EditAnnouncement',
        meta: {
          title: 'editAnnouncement',
          noCache: true,
          activeMenu: '/admin/announcement/index'
        },
        hidden: true
      },
      {
        path: 'add',
        component: () => import('@/views/admin/announcement/add'),
        name: 'AddAnnouncement',
        meta: {
          title: 'AddAnnouncement',
          noCache: true,
          activeMenu: '/admin/announcement/index'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/admin/article',
    component: Layout,
    redirect: '/admin/article/index',
    meta: {
      roles: ['admin']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/article/index'),
        name: 'article',
        meta: {
          title: 'article',
          icon: 'el-icon-reading'
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import('@/views/admin/article/edit'),
        name: 'Editarticle',
        meta: {
          title: 'Editarticle',
          activeMenu: '/admin/article/index'
        },
        hidden: true
      },
      {
        path: 'add',
        component: () => import('@/views/admin/article/add'),
        name: 'Addarticle',
        meta: {
          title: 'Addarticle',
          activeMenu: '/admin/article/index'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/admin/feedback',
    component: Layout,
    redirect: '/admin/feedback/index',
    meta: {
      roles: ['admin']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/feedback/index'),
        name: 'feedback',
        meta: {
          title: 'feedback',
          icon: 'el-icon-chat-dot-round'
        }
      }
    ]
  },
  // {
  //   path: '/admin/user',
  //   component: Layout,
  //   redirect: '/admin/user/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/admin/user/index'),
  //       name: 'user',
  //       meta: {
  //         title: 'user',
  //         icon: 'el-icon-user-solid',
  //         roles: ['admin']
  //       }
  //     }
  //   ]
  // },
  {
    path: '/admin/material',
    component: Layout,
    redirect: '/material/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/material/index'),
        name: 'material',
        meta: {
          title: 'material',
          icon: 'el-icon-files',
          roles: ['admin']
        }
      }
    ]
  },
  // 套餐管理
  {
    path: '/admin/package',
    component: Layout,
    redirect: '/admin/plan/index',
    alwaysShow: true, // 总是显示根菜单吗
    meta: {
      title: 'package',
      icon: 'el-icon-takeaway-box',
      roles: ['admin']
    },
    children: [
      {
        path: 'plan',
        component: () => import('@/views/admin/plan/index'),
        name: 'plan',
        meta: {
          title: 'plan'
        }
      },
      {
        path: 'sms_plan',
        component: () => import('@/views/admin/sms_plan/index'),
        name: 'sms_plan',
        meta: {
          title: 'sms_plan'
        }
      },
      {
        path: 'activityManagement',
        component: () => import('@/views/admin/activityManagement/index'),
        name: 'activityManagement',
        meta: {
          title: 'activityManagement'
        }
      },
      {
        path: 'invite',
        component: () => import('@/views/admin/invite/index'),
        name: 'invite',
        meta: {
          title: 'invite'
        }
      }
    ]
  },
  {
    path: '/admin/version',
    component: Layout,
    redirect: '/version/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/version/index'),
        name: 'version',
        meta: {
          title: 'version',
          icon: 'el-icon-tickets',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/admin/banner',
    component: Layout,
    redirect: '/banner/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/banner/index'),
        name: 'banner',
        meta: {
          title: 'banner',
          icon: 'el-icon-picture-outline',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/admin/poster_template',
    component: Layout,
    redirect: '/poster_template/index',
    meta: {
      roles: ['admin']
    },
    hidden: false,
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/poster_template/index'),
        name: 'poster_template',
        meta: {
          title: 'poster_template',
          icon: 'el-icon-picture-outline',
          roles: ['admin'],
          activeMenu: '/admin/poster_template/index'
        }
      },
      {
        path: 'add',
        component: () => import('@/views/admin/poster_template/add'),
        name: 'poster_template_add',
        meta: {
          title: 'poster_template_add',
          roles: ['admin'],
          activeMenu: '/admin/poster_template/index'
        },
        hidden: true
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import('@/views/admin/poster_template/edit'),
        name: 'poster_template_edit',
        meta: {
          title: 'poster_template_edit',
          roles: ['admin'],
          activeMenu: '/admin/poster_template/index'
        },
        hidden: true
      }
    ]
  },
  // 资金管理
  {
    path: '/admin/money_management',
    component: Layout,
    redirect: '/admin/withdrawal_audit/index',
    alwaysShow: true, // 总是显示根菜单吗
    meta: {
      title: 'money_management',
      icon: 'el-icon-money',
      roles: ['admin']
    },
    children: [
      {
        path: 'withdrawal_audit/index',
        component: () => import('@/views/admin/withdrawal_audit/index'),
        name: 'money_withdrawal_audit',
        meta: {
          title: 'money_withdrawal_audit'
        }
      },
      {
        path: 'invite_withdrawal/index',
        component: () => import('@/views/admin/invite_withdrawal/index'),
        name: 'money_invite_withdrawal',
        meta: {
          title: 'money_invite_withdrawal'
        }
      }
    ]
  },
  {
    path: '/admin/sms_record',
    component: Layout,
    redirect: '/sms_record/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/sms_record/index'),
        name: 'sms_record',
        meta: {
          title: 'sms_record',
          icon: 'el-icon-chat-line-square',
          roles: ['admin']
        }
      }
    ]
  },
  // 自动化导入
  {
    path: '/admin/automated_import',
    component: Layout,
    redirect: '/automated_import/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/automated_import/index'),
        name: 'automated_import',
        meta: {
          title: 'automated_import',
          icon: 'el-icon-upload',
          roles: ['admin']
        }
      }
    ]
  },
  // 订单管理
  {
    path: '/admin/order_management',
    component: Layout,
    redirect: '/admin/order_management/package/index',
    alwaysShow: true, // 总是显示根菜单吗
    meta: {
      title: 'order_management',
      icon: 'el-icon-goods',
      roles: ['admin']
    },
    children: [
      {
        path: 'package',
        component: () => import('@/views/admin/order_management/package/index'),
        name: 'order_package',
        meta: {
          title: 'order_package'
        }
      },
      {
        path: 'sms',
        component: () => import('@/views/admin/order_management/sms/index'),
        name: 'order_sms',
        meta: {
          title: 'order_sms'
        }
      },
      {
        path: 'orderList',
        component: () => import('@/views/admin/order_management/orderList/index'),
        name: 'order_orderList',
        meta: {
          title: 'order_orderList'
        }
      },
      {
        path: 'activity',
        component: () => import('@/views/admin/order_management/activity/index'),
        name: 'order_activity',
        meta: {
          title: 'order_activity'
        }
      }
    ]
  },
  // 日志
  {
    path: '/admin/log',
    component: Layout,
    redirect: '/admin/operationLog/index',
    alwaysShow: true, // 总是显示根菜单吗
    meta: {
      title: 'log',
      icon: 'el-icon-notebook-2',
      roles: ['admin']
    },
    children: [
      {
        path: 'operationLog',
        component: () => import('@/views/admin/operationLog/index'),
        name: 'operation_log',
        meta: {
          title: 'operation_log'
        }
      },
      {
        path: 'abnormalLog',
        component: () => import('@/views/admin/abnormalLog/index'),
        name: 'abnormal_log',
        meta: {
          title: 'abnormal_log'
        }
      },
      {
        path: 'backgroundLog',
        component: () => import('@/views/admin/operationLog/operationLog'),
        name: 's_background_log',
        meta: {
          title: 's_background_log'
        }
      },
      {
        path: 'brandFundingLog',
        component: () => import('@/views/admin/brandFundingLog/index'),
        name: 'brand_funding_log',
        meta: {
          title: 'brand_funding_log'
        }
      },
      {
        path: 'phoneJournal',
        component: () => import('@/views/admin/phoneJournal/index'),
        name: 'phoneJournal',
        meta: {
          title: 'phoneJournal'
        }
      }
    ]
  },
  // 区号管理code_management
  {
    path: '/admin/code_management',
    component: Layout,
    redirect: '/code_management/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/code_management/index'),
        name: 'code_management',
        meta: {
          title: 'code_management',
          icon: 'el-icon-phone-outline',
          roles: ['admin']
        }
      }
    ]
  },
  // 物料管理
  {
    path: '/admin/material_management',
    component: Layout,
    redirect: '/material_management/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/material_management/index'),
        name: 'material_management',
        meta: {
          title: 'material_management',
          icon: 'el-icon-s-grid',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/admin/course_label',
    component: Layout,
    redirect: '/course_label/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/course_label/index'),
        name: 'course_label',
        meta: {
          title: 'course_label',
          icon: 'el-icon-discount',
          roles: ['admin']
        }
      }
    ]
  },
  {
    path: '/admin/widget/IconFrom', // 选择图标弹窗
    component: () => import('@/components/IconFrom/index')
  }
]

export default admin
