import Vue from 'vue'
import Cookies from 'js-cookie'

Vue.directive('prevent-ctrl-scroll', {
  inserted(el) {
    el.addEventListener('wheel', (event) => {
      if (event.ctrlKey && event.deltaY !== 0) {
        event.preventDefault()
      }
    }, { passive: false })
  }
})

// import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import 'element-ui/lib/theme-chalk/icon.css'
// import 'element-ui/lib/theme-chalk/lib/icon.css'
// import '~element-ui/packages/theme-chalk/src/icon.scss'

import '@/styles/element-variables.scss'
import Element from 'element-ui'

// 引入form-create 表单生成器
import formCreate from '@form-create/element-ui'
import FcEditor from '@form-create/component-wangeditor'
formCreate.component('editor', FcEditor)

import vWaves from '@/directive/waves' // 点击水波纹效果

import '@/styles/index.scss' // global css

import 'default-passive-events'

// 引入Echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 按需引入Vant
import 'vant/lib/index.css'
import vant from 'vant'
Vue.use(vant)

// 引入工具类
import Utils from '@/utils/utils.js'

import App from './App'
import store from './store'
import router from './router'

import i18n from './lang' // internationalization
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

import Moment from 'moment'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// 下拉加载更多
import loadMore from '@/directive/loadMore/loadMore' // 下拉加载更多
Vue.use(loadMore)

// 接收异常信息
import { error } from '@/api/err'

if (window.location.host === 'admin.yuxiaojiu.cn') {
  const errorHandler = (err) => {
    const errJson = JSON.stringify(err, Object.getOwnPropertyNames(err), 2)
    error({
      error: errJson,
      project: 1,
      path: window.location.pathname,
      params: window.location.search,
      user_id: '',
      appid: '',
      version: process.env.VUE_APP_Version,
      os: '',
      device: '',
      system_info: ''
    })
  }
  // 调用
  Vue.config.errorHandler = errorHandler
  // axios手动捕获使用 绑定 prototype
  Vue.prototype.$throw = (error) => errorHandler(error, this)
}

/**
 * 如果你不想使用mock-server
 * 你想为模拟api使用MockJs
 * 你可以执行: mockXHR()
 *
 * 目前MockJs将用于生产环境,
 * 请在上线前删除它 ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  const { mockXHR } = require('../mock')
  mockXHR()
}

Vue.use(Element, {
  size: Cookies.get('size') || 'small', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(formCreate)
Vue.use(vWaves) // 全局水波纹效果

Vue.prototype.utils = Utils
Vue.prototype.$moment = Moment

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false
const vueDefalut = require('@/mixin.js')
Vue.mixin(vueDefalut)

// 全局img默认图
// import defaultImg from '@/utils/defaultImg'
// Vue.use(defaultImg)

// 百度统计代码
router.afterEach((to, from, next) => {
  setTimeout(() => {
    var _hmt = _hmt || [];
    (() => {
      // 每次执行前，先移除上次插入的代码
      document.getElementById('baiduCount') && document.getElementById('baiduCount').remove()
      var hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?793377ae82970b58afcddd681cc310b7'
      hm.id = 'baiduCount'
      var s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    })()
  }, 0)
})

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})

