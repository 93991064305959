import request from '@/utils/request'

/**
 * 获取活动详情
 */
export function getActivityDetail(id) {
  return request({
    url: `/store/v1/system_activity/${id}`,
    method: 'get'
  })
}

/**
 * 预付定金下单
 */
export function depositOrder(data) {
  return request({
    url: '/store/v1/deposit/order',
    method: 'post',
    data
  })
}

/**
 * 订单详情
 */
export function getOrderDetail(id) {
  return request({
    url: `/store/v1/deposit/order/${id}`,
    method: 'get'
  })
}
