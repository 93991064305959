<template>
  <div v-loading="divLoading" class="app-container">
    <!-- <el-page-header content="合同模板" class="page-header" @back="returnPage()" /> -->
    <!-- <el-divider /> -->
    <div class="filter-container">
      <el-tabs v-model="activeName" @tab-click="handleFilter">
        <el-tab-pane label="全部" name="-1" />
        <el-tab-pane v-for="item in tableData.filter_data" :key="item.tag_id" :name="item.tag_id.toString()">
          <template v-slot:label>
            <div>
              {{ item.tag_name }}
              <span>（{{ item.tag_count }}）</span>
            </div>
          </template>
        </el-tab-pane>
      </el-tabs>
      <!-- 筛选 -->
      <div class="searchBox">
        <el-form :inline="true" :model="listQueryParams" label-width="100px" label-position="left" label-suffix="：">
          <el-form-item label="排序">
            <el-select v-model="listQueryParams.order_by" placeholder="请选择" filterable clearable>
              <el-option label="升序" value="asc" />
              <el-option label="降序" value="desc" />
            </el-select>
          </el-form-item>
          <el-button icon="el-icon-search" :loading="searchLoading" type="success" class="m-l-20" @click="handleFilter">
            搜索
          </el-button>
        </el-form>
      </div>
      <!-- 搜索 -->
      <div class="filter-item">
        <el-input
          v-model="listQueryParams.name"
          placeholder="搜索 合同名称"
          style="width: 260px;"
          @keyup.enter.native="handleFilter"
        >
          <el-button slot="append" icon="el-icon-search" :loading="searchLoading" @click="handleFilter" />
        </el-input>
      </div>
      <router-link :to="{ name: 's_contract_add', params: { type: 0 } }">
        <el-button class="filter-item m-l-10" type="primary" icon="el-icon-document-add">
          添加模板
        </el-button>
      </router-link>
    </div>
    <!-- 列表 -->
    <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData.list" row-key="id" :fit="true">
      <el-table-column label="合同名称" prop="name" />
      <el-table-column prop="cover" label="页面图片">
        <template slot-scope="{ row }">
          <el-image v-if="row.thumbnail.length > 0" style="width: 80px;" :src="row.thumbnail[0]" :preview-src-list="[row.thumbnail[0]]" />
          <div v-else class="text-danger">暂无封面图</div>
        </template>
      </el-table-column>
      <el-table-column label="创建人">
        <template slot-scope="{ row }">
          <div v-if="row.store_user" class="flex col-center">
            <div>
              <el-avatar shape="square" :size="50" :src="row.store_user.avatar">
                <img :src="errorMemberSrc()">
              </el-avatar>
            </div>
            <div class="p-l-10">
              <span>{{ getUserNames(row.store_user) }}</span>
            </div>
          </div>
          <div v-else class="text-danger">创建人丢失</div>
        </template>
      </el-table-column>
      <el-table-column label="标签">
        <template slot-scope="{ row }">
          <div class="flex">
            <div v-if="row.tags.length > 0">
              <el-tag v-for="item in row.tags" :key="item.id" class="m-2">{{ item.name }}</el-tag>
            </div>
            <div v-else class="text-info">暂无标签</div>
            <div class="cursor text-success m-l-20" @click="handleAddTags(row.tags, row.id)">
              <i class="el-icon-plus font-16" />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template slot-scope="{ row }">
          <el-button class="m-2" @click.native="handleEditContract(row.id, row.name)">编辑模板</el-button>
          <el-button class="m-2" type="primary" @click="handleInitiateSign(row.id)">发起签署</el-button>
          <el-dropdown>
            <el-button type="info">
              更多
              <i class="el-icon-arrow-down el-icon--right" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleChangeName(row.id)">重属名</el-dropdown-item>
              <el-dropdown-item @click.native="handleDownload(row.file_path, row.name)">下载模板</el-dropdown-item>
              <el-dropdown-item @click.native="handleDelete(row.id)">删除模板</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="tableData.count > 0"
      :total="tableData.count"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!-- 标签 -->
    <tagDialog
      :dialog-visible-template="dialogVisibleTemplate"
      :template-tags="templateTags"
      @closeVisibleTemplate="closeVisibleTemplate"
    />
    <!-- 编辑合同 -->
    <editDialog
      :dialog-visible-edit-contract="dialogVisibleEditContract"
      :edit-contract-id="editContractId"
      :edit-type="editType"
      @editContractVisible="editContractVisible"
    />
    <!-- 签署合同 -->
    <initiateSignDialog
      :dialog-visible-initiate-sign="dialogVisibleInitiateSign"
      :edit-initiate-sign-id="editInitiateSignId"
      @editInitiateSignVisible="editInitiateSignVisible"
    />
  </div>
</template>

<script>
// API
import { getTemplateList, deleteContract, editTemplate } from '@/api/store/contract'
// 组件
import Pagination from '@/components/Pagination' // 基于el-pagination的二级包
import tagDialog from './components/tagDialog'
import editDialog from './components/editDialog'
import initiateSignDialog from './components/initiateSignDialog'

export default {
  components: { Pagination, tagDialog, editDialog, initiateSignDialog },
  data() {
    return {
      listQueryParams: {
        name: '',
        order_by: 'desc'
      },
      // 列表
      tableData: {
        count: 0,
        filter_data: {},
        list: []
      },
      activeName: '-1',
      divLoading: false,
      tableLoading: false,
      searchLoading: false,
      dialogVisibleTemplate: false,
      templateTags: [],
      contractId: 0,
      dialogVisibleEditContract: false,
      editContractId: 0,
      editType: 1,
      dialogVisibleInitiateSign: false,
      editInitiateSignId: 0
    }
  },
  created() {
    this.divLoading = true
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true

      const data = JSON.parse(JSON.stringify(this.listQuery))
      data['offset'] = (data.page - 1) * data.limit
      delete data['page']

      if (this.listQueryParams.name) data.name = this.listQueryParams.name
      const tag_id = Number(this.activeName)
      if (tag_id > -1) data.tag_id = tag_id
      if (this.listQueryParams.order_by) data.order_by = this.listQueryParams.order_by

      getTemplateList(data)
        .then(res => {
          const data = res.data
          this.tableData = data
        })
        .finally(() => {
          this.searchLoading = false
          this.divLoading = false
          this.tableLoading = false
        })
    },
    // 搜索
    handleFilter() {
      this.searchLoading = true
      this.listQuery.page = 1
      this.getList()
    },
    // 删除合同
    handleDelete(id) {
      this.$confirm('是否确认删除模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteContract(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    // 重属名
    handleChangeName(id, name) {
      this.$prompt('请输入内容', '重属名', {
        inputValue: name,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          const data = {
            name: value,
            is_customized: 0
          }
          editTemplate(id, data).then(() => {
            this.$message.success('重属名成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    // 下载合同
    handleDownload(file_path, name) {
      this.utils.downloadPaf(file_path, `${name}合同模板`)
    },
    // 添加标签
    handleAddTags(tags, id) {
      if (tags.length > 0) this.templateTags = tags
      else this.templateTags = []
      this.contractId = id
      this.dialogVisibleTemplate = true
    },
    closeVisibleTemplate(val) {
      this.dialogVisibleTemplate = false
      console.log(val)
      let value = ''
      if (val.length > 0) value = val.toString()
      const data = {
        tag_id: value
      }
      editTemplate(this.contractId, data).then(() => {
        this.$message.success('修改成功')
        this.getList()
      })
    },
    // 编辑合同
    handleEditContract(id) {
      this.dialogVisibleEditContract = true
      this.editContractId = id
      this.editType = 0
    },
    editContractVisible() {
      this.dialogVisibleEditContract = false
      this.getList()
    },
    // 发起签署
    handleInitiateSign(id) {
      this.dialogVisibleInitiateSign = true
      this.editInitiateSignId = id
    },
    editInitiateSignVisible() {
      this.dialogVisibleInitiateSign = false
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped></style>
